import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { Router } from '@angular/router';

export interface PageMenuItem {
    label: string;
    badge?: string | number;
    routerLink?: string | string[];
    routerLinkActive?: string;
    expanded?: boolean;
    children?: PageMenuItem[];
}

@Component({
    selector: 'page-menu',
    template: `
        <ul>
            <li *ngFor="let item of menuItems" [class.childMenuContainer]="item.children?.length">
                <a
                    [routerLink]="item.routerLink"
                    routerLinkActive="active"
                    [class.activeParent]="item.children?.length && parentLink.isActive"
                    [class.parentExpanded]="item.expanded"
                    (click)="item.expanded = !item.expanded"
                    #parentLink="routerLinkActive">
                    {{ item.label }}
                    <ng-container *ngIf="item.children?.length">
                        <fa-icon [icon]="item.expanded ? 'chevron-up' : 'chevron-down'"></fa-icon>
                    </ng-container>
                    <ng-container *ngIf="!item.children?.length">
                        <span class="badge badge-pill badge-primary">{{ item.badge }}</span>
                    </ng-container>
                </a>
                <ng-container *ngFor="let childItem of item.children">
                    <a [routerLink]="childItem.routerLink" routerLinkActive="active">
                        {{ childItem.label }}
                        <span class="badge badge-pill badge-primary">
                            {{ childItem.badge }}
                        </span>
                    </a>
                </ng-container>
            </li>
        </ul>
    `,
    styles: [
        `
            ul {
                margin: 0;
                padding: 0;
                list-style-type: none;
                width: 200px;
                li {
                    a {
                        padding: 4px 8px;
                        border-radius: 4px;
                        display: flex;
                        justify-content: space-between;
                        gap: 8px;
                        font-size: 12px;
                        line-height: 20px;
                        font-weight: 400;
                        color: var(--text-primary);

                        &.active {
                            font-weight: 700;
                            background-color: rgba(var(--rft-orange-rgb), 0.2);
                        }
                        &.activeParent.active {
                            font-weight: 500;
                            background-color: transparent;
                        }

                        &:not(.active):hover {
                            background-color: #f7f7f7;
                        }
                    }
                    &.childMenuContainer {
                        a.parentExpanded:first-of-type ~ a {
                            display: flex;
                        }
                        a.activeParent {
                            font-weight: 500;
                            background-color: transparent;
                        }
                        a:not(:first-of-type) {
                            margin-left: 8px;
                            display: none;
                        }
                    }
                }
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageMenuComponent {
    @Input() menuItems: PageMenuItem[] = [];
    router = inject(Router);

    ngOnInit() {
        /* run through children to set parent as expanded if a child is active */
        this.menuItems.forEach((mi) => {
            mi.children?.forEach((mc) => {
                if (typeof mc.routerLink === 'string') {
                    mc.routerLink = mc.routerLink.split('/');
                }
                mc.routerLink.shift(); // remove first '.' or '..' this may be an issue in the future depending on what else needs to be added
                let url = mc.routerLink.join('/');
                if (this.router.url.includes(url)) {
                    mi.expanded = true;
                }
            });
        });
    }
}
