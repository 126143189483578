import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { UiService } from 'app/core/ui.service';
import { Site } from 'app/sites/shared/site.model';
import { AuthService, SiteRoles } from 'app/core/auth';
import { MessageService } from 'app/core/message.service';
import { PING_SITE, SITE } from 'app/sites/shared/constants';
import { SiteService } from 'app/sites';
import { SiteSettingService } from 'app/sites/site/settings/site-settings.service';
import { TableSorting } from 'app/shared/sortable-table/sortable-table.component';
import { NotificationService } from '../../../../../shared/itc/notification/notification.service';
import { SiteDashboardService } from 'app/sites/shared/site-dashboard.service';

@Component({
    selector: 'sds-cmgrc-data-collectors',
    templateUrl: './cm-data-collectors.component.html',
    styleUrls: ['./cm-data-collectors.component.css'],
})
export class CmDataCollectorsComponent implements OnInit {
    showRemoteDataCollectors = false;
    loadingComplete: boolean = false;

    title = 'Data Collectors';

    pageSize: number = 20;
    pageNumber: number = 1;
    initPage: boolean;
    site: Site;
    connector: any;
    mainApp: any = null;
    rdcs: any[] = [];
    sortedRDCs: any[] = [];
    pagedRDCs: any[] = [];
    notInstalled: boolean;

    editedApp: any;
    dcDescCtrl: UntypedFormControl = new UntypedFormControl('', Validators.maxLength(100));
    @ViewChild('editDataCollectorDescriptionModal', { static: true })
    editDataCollectorDescriptionModal: any;

    isProvisioning: boolean = false;
    isDeleting: boolean = false;
    appsToDelete: any[] = [];
    @ViewChild('deleteRdcModal', { static: true }) deleteRdcModal: any;
    @ViewChild('provisionConfirmModal', { static: true }) provisionConfirmModal: any;

    subs: any[] = [];
    isSiteAdmin: boolean;

    friendlyTypes: any = {
        RPS: 'Reporter',
        CH: 'Cyber Hawk',
        AG: 'Compliance Manager',
        RDC: 'Remote Data Collector',
        KVS: 'Vulnerability Scanner',
        IVS: 'Internal Vulnerability Scanner',
        EVS: 'External Vulnerability Scanner',
        PVS: 'Portable Vulnerability Scanner',
    };

    breadcrumbs = [
        { path: '..', text: 'Home' },
        { path: '.', text: this.title },
    ];

    constructor(
        private uiService: UiService,
        private authService: AuthService,
        private messageService: MessageService,
        private notificationService: NotificationService,
        private siteSettingService: SiteSettingService,
        private siteService: SiteService,
        private siteDashboardService: SiteDashboardService
    ) {}

    ngOnInit() {
        this.uiService.setTitle(this.title);
        this.subs.push(
            this.messageService.on(SITE).subscribe((site: Site) => {
                if (!site) return;

                this.site = site;
                this.siteService.app_setCurrentSite(this.site);
                this.uiService.setTitle('Appliance', site.Name);
                this.loadAppliances();
                this.siteSettingService.getVsaConnector(this.site.Id).then((res) => {
                    this.connector = res;
                });

                let user = this.authService.getIdentity();

                let siteRoles = this.authService.getCurrentSiteRoles();

                this.isSiteAdmin = siteRoles && siteRoles.indexOf(SiteRoles.SiteAdmin) > -1;

                this.siteDashboardService.setDashboardItem(this.site.Id, 'Appliance');
            })
        );

        this.messageService.broadcast(PING_SITE);
    }

    loadAppliances() {
        this.siteSettingService
            .getApplianceInfoForReal(this.site.Id)
            .then((appliances) => {
                console.log(appliances);
                if (!appliances || !appliances.length) {
                    // appliance not installed
                    this.notInstalled = true;
                } else {
                    for (let i = 0; i < appliances.length; i++) {
                        let info = appliances[i]; // TODO update this to work with more than one appliance

                        let updateStatus = 'unknown';
                        let updateAvailable = false;
                        if (info.UpdateStatus == 1) {
                            updateStatus = 'up-to-date';
                            updateAvailable = false;
                        } else if (info.UpdateStatus == 0) {
                            updateStatus = 'update available';
                            updateAvailable = true;
                        }
                        //The time from info.LastCheckin is in UTC, but for some reason, using it to create a new Date() will keep the same exact time, but as the locale time.
                        // i.e., 6:00 UTC becomes 6:00 EST. Use Date.UTC() to explicitly make info.LastCheckin UTC and then it will convert to the locale time.
                        let dateString = new String(info.LastCheckin);
                        let dateAndTime: any[] = dateString.split('T'); //info.LastCheckin's format is YYYY-MM-DDThh:mm:ss. Split at T
                        let checkinDate: number[] = dateAndTime[0].split('-'); //split up the date
                        let checkinTime: number[] = dateAndTime[1].split(':'); //split up the time
                        let localeCheckin = new Date(
                            Date.UTC(
                                checkinDate[0],
                                checkinDate[1] - 1,
                                checkinDate[2],
                                checkinTime[0],
                                checkinTime[1],
                                checkinTime[2]
                            )
                        ); //Date.UTC has months run from 0 to 11

                        let lastCheckin = new Date(info.LastCheckin);
                        let noCheckin = lastCheckin.getFullYear() < 2000;

                        let types = '';
                        let isRDC = false;
                        let isMain = false;
                        for (let j = 0; j < info.Types.length; j++) {
                            if (types) types += ', ';
                            if (info.Types[j] == 'RDC') isRDC = true;
                            else if (info.Types[j] == 'AG') isMain = true;
                            if (info.Types[j] == 'KVS') {
                                types +=
                                    info.KvsType != ''
                                        ? this.friendlyTypes[info.KvsType]
                                        : this.friendlyTypes['IVS'];
                            } else {
                                types += this.friendlyTypes[info.Types[j]];
                            }
                        }
                        let app = {
                            Id: info.Id,
                            Description: info.Description ? info.Description : '',
                            Type: types,
                            LastCheckin: lastCheckin,
                            UpdateStatus: updateStatus,
                            UpdateAvailable: updateAvailable,
                            isVirtual: info.IsVirtual,
                            IsServer: info.IsServer,
                            IsCheckedIn: !noCheckin,
                            Status: info.IsOnline ? 'Online' : 'Offline',
                            Selected: false,
                        };
                        if (isRDC) {
                            this.rdcs.push(app);
                        } else if (isMain) {
                            this.mainApp = app;
                        }
                    }
                }
                this.sortRDCs();
                this.provisionConfirmModal.hide();
                this.isProvisioning = false;
                this.loadingComplete = true;
            })
            .catch((err) => {});
    }

    ngOnDestroy() {
        for (let sub of this.subs) sub.unsubscribe();
    }

    sortRDCs(sorting?: TableSorting) {
        if (!sorting)
            this.sortedRDCs = this.rdcs.sort((a, b) =>
                a.Id.toLowerCase().localeCompare(b.Id.toLowerCase())
            );
        else {
            switch (sorting.sortColumn) {
                case 'id':
                    this.sortedRDCs = this.rdcs.sort((a, b) =>
                        a.Id.toLowerCase().localeCompare(b.Id.toLowerCase())
                    );
                    break;
                case 'description':
                    this.sortedRDCs = this.rdcs.sort((a, b) =>
                        a.Description.toLowerCase().localeCompare(b.Description.toLowerCase())
                    );
                    break;
                case 'status':
                    this.sortedRDCs = this.rdcs.sort((a, b) =>
                        a.Status.toLowerCase().localeCompare(b.Status.toLowerCase())
                    );
                    break;
                default:
                    this.sortedRDCs = this.rdcs.sort((a, b) =>
                        a.Id.toLowerCase().localeCompare(b.Id.toLowerCase())
                    );
                    break;
            }

            if (sorting.sortDirection == 'desc')
                //descending
                this.sortedRDCs.reverse();
        }

        this.pageRDCs();
    }

    pageRDCs(ev?: any) {
        if (ev) {
            this.pageNumber = ev.pageNumber;
            this.pageSize = ev.pageSize;
        }
        this.pagedRDCs = this.sortedRDCs.slice(
            (this.pageNumber - 1) * this.pageSize,
            (this.pageNumber - 1) * this.pageSize + this.pageSize
        );
    }

    copy(val: string) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.notificationService.toast.success('Copied', 'Successfully copied to clipboard.');
    }

    showEditDescModal(app: any) {
        this.editedApp = app;
        this.editDataCollectorDescriptionModal.show({ closable: false });
        this.dcDescCtrl.setValue(app.Description);
    }

    saveDcDesc() {
        if (this.dcDescCtrl.value == null) this.dcDescCtrl.setValue('');
        this.editedApp.Description = this.dcDescCtrl.value;
        this.siteSettingService.setApplianceDescription(this.editedApp.Id, this.dcDescCtrl.value);
        this.editDataCollectorDescriptionModal.hide();
        this.editedApp = null;
    }

    showRDCProvisionModal() {
        this.isProvisioning = false;
        this.provisionConfirmModal.show({ closable: false });
    }

    provisionRDC() {
        this.isProvisioning = true;
        this.siteService.provisionRDCApplianceOnSite(this.site).then((res) => {
            this.notificationService.toast.success('Success', res + ' Provisioned');
            this.rdcs = [];
            this.loadAppliances();
        });
    }

    checkAllRDC(event) {
        for (let j = 0; j < this.rdcs.length; j++) {
            this.rdcs[j].Selected = event.target.checked;
        }
        console.log(event);
    }

    checkAnySelected() {
        for (let j = 0; j < this.rdcs.length; j++) {
            if (this.rdcs[j].Selected) return true;
        }
        return false;
    }

    startMultiDelete() {
        this.appsToDelete = [];
        let showModal = false;
        for (let j = 0; j < this.rdcs.length; j++) {
            if (this.rdcs[j].Selected) {
                this.appsToDelete.push(this.rdcs[j]);
                if (this.rdcs[j].Status == 'Online') {
                    showModal = true;
                }
            }
        }
        if (this.appsToDelete.length > 0) {
            if (showModal) {
                this.deleteRdcModal.show({ closable: false });
            } else {
                this.deleteAppliance();
            }
        }
    }

    showDeleteModal(app: any) {
        this.appsToDelete = [];
        this.appsToDelete.push(app);
        if (app.Status != 'Online') {
            this.deleteAppliance();
        } else {
            this.deleteRdcModal.show({ closable: false });
        }
    }

    deleteAppliance() {
        if (this.appsToDelete && this.appsToDelete.length > 0) {
            this.isDeleting = true;
            let deleteCount = 0;
            for (let i = 0; i < this.appsToDelete.length; i++) {
                this.siteService
                    .deleteKVSAppliance(this.appsToDelete[i].Id, this.site.Id)
                    .then((res) => {
                        deleteCount++;
                        for (let j = 0; j < this.rdcs.length; j++) {
                            if (this.rdcs[j].Id == this.appsToDelete[i].Id) this.rdcs.splice(j, 1);
                        }
                        if (deleteCount == this.appsToDelete.length) {
                            //wait to execute final code until all have returned
                            this.appsToDelete = [];
                            this.sortRDCs();
                            this.deleteRdcModal.hide();
                            this.isDeleting = false;
                            this.notificationService.toast.success(
                                'Success',
                                'Appliance' + (deleteCount > 1 ? 's' : '') + ' Deleted'
                            );
                        }
                    })
                    .catch((res) => {
                        deleteCount++;
                        if (deleteCount == this.appsToDelete.length) {
                            //wait to execute final code until all have returned
                            this.appsToDelete = [];
                            this.sortRDCs();
                            this.deleteRdcModal.hide();
                            this.isDeleting = false;
                            this.notificationService.toast.success(
                                'Success',
                                'Appliance' + (deleteCount > 1 ? 's' : '') + ' Deleted'
                            );
                        }
                    });
            }
        }
    }
}
