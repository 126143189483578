import { Component, OnInit } from '@angular/core';
import { PrintService } from 'app/sites/shared/print.service';
import { AllTrainingsInfo } from './alltrainings.model';

@Component({
    selector: 'sds-printable-all-trainings',
    templateUrl: './printable-all-trainings.component.html',
    styleUrls: ['./printable-all-trainings.component.scss'],
})
export class PrintableAllTrainingsComponent implements OnInit {
    allTrainingsInfo: AllTrainingsInfo;
    constructor(private printService: PrintService) {}

    ngOnInit() {
        this.allTrainingsInfo = this.printService.AllTrainingsData;

        Promise.resolve(this.allTrainingsInfo).then(() => {
            setTimeout(() => {
                this.printService.onDataReady();
            }, 500);
        });
    }
}
