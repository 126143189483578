import { Component, OnInit } from '@angular/core';

@Component({
    template: '<div style="padding: 20px;"><router-outlet></router-outlet></div>',
})
export class GlobalTodoWrapperComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
