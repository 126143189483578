import {
    Component,
    Directive,
    ElementRef,
    Input,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Menu } from 'primeng/menu';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
    selector: 'dropdown-button',
    template: `
        <ng-container>
            <p-menu
                [model]="menuOptions"
                [popup]="true"
                appendTo="body"
                *ngIf="menuOnly"
                [style]="style"
                #dropdownMenu></p-menu>
            <p-overlayPanel
                [styleClass]="!menuOnly ? 'panelMenu' : null"
                appendTo="body"
                #dropdownPanelMenu>
                <div #header (click)="closePanel()">
                    <ng-content select="dropdown-header"></ng-content>
                </div>
                <p-menu [model]="menuOptions" (click)="closePanel()"></p-menu>
                <div #footer>
                    <ng-content select="dropdown-footer"></ng-content>
                </div>
            </p-overlayPanel>
        </ng-container>
        <itc-button
            [type]="buttonType"
            [icon]="buttonIcon"
            [label]="buttonLabel"
            [size]="buttonSize"
            [isDropdownButton]="showDropdownIcon"
            [disabled]="disabled"
            [displayChevronDownIcon]="displayChevronDownIcon"
            [class]="classList"
            (onclick)="toggleMenu($event)"></itc-button>
    `,
})
export class DropdownButtonComponent {
    @Input() menuOptions: MenuItem[];
    @Input() classList: string;
    @Input() buttonType: string;
    @Input() buttonIcon: string;
    @Input() buttonLabel: string;
    @Input() disabled = false;
    @Input() buttonSize: string;
    @Input() displayChevronDownIcon = true;
    @Input() showDropdownIcon = true;
    @Input() style: object;

    menuOnly = true;
    @ViewChild('header', { read: ElementRef }) header: ElementRef;
    @ViewChild('footer', { read: ElementRef }) footer: ElementRef;
    @ViewChildren('dropdownMenu') dropdownMenu: QueryList<Menu>;
    @ViewChildren('dropdownPanelMenu') dropdownPanelMenu: QueryList<OverlayPanel>;

    ngAfterViewInit() {
        setTimeout(() => {
            this.menuOnly =
                !this.header.nativeElement.childNodes.length &&
                !this.footer.nativeElement.childNodes.length;
        });
    }

    toggleMenu(evt) {
        if (this.menuOnly) {
            this.dropdownMenu.first.toggle(evt);
        } else {
            this.dropdownPanelMenu.first.toggle(evt);
        }
    }

    closePanel() {
        this.dropdownPanelMenu.first.hide();
    }
}

@Directive({ selector: 'dropdown-header, dropdown-footer' })
export class DropdownButtonTagsDirective {
    // No behavior
    // The only purpose is to "declare" the tag in Angular2
}
