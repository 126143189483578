import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';
import { AuthHttp } from 'app/core/auth';
import { ErrorService } from 'app/core/error/error.service';
import { Organization } from './organization.model';
import { BehaviorSubject, catchError, lastValueFrom, Observable } from 'rxjs';
import { Connector } from 'app/sites/site/network-detective-pro/ndpro.model';

@Injectable({ providedIn: 'root' })
export class OrganizationsService {
    private apiUrl: string;
    private orgList$: BehaviorSubject<Organization[]> = new BehaviorSubject<Organization[]>(null);
    public orgListError$: BehaviorSubject<Error> = new BehaviorSubject<Error>(null);
    public selectedOrg$: BehaviorSubject<Organization> = new BehaviorSubject<Organization>(
        new Organization()
    );
    public selectedOrgName$: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public selectedOrgRole$: BehaviorSubject<string> = new BehaviorSubject<string>('');

    constructor(private http: AuthHttp, private E: ErrorService) {
        this.apiUrl = environment.apiUrl;
    }

    getOrganizationsObs(): Observable<Organization[]> {
        return this.orgList$.asObservable();
    }

    getAllOrganizations() {
        if (this.orgList$.getValue() === null) {
            const url: string = this.apiUrl + `Organizations`;
            this.http
                .get(url)
                .pipe(
                    catchError((err) => {
                        console.log(err);
                        this.orgList$.next([]);
                        this.orgListError$.next(err);
                        return [];
                    })
                )
                .subscribe((orgs) => {
                    this.orgList$.next(this.sortOrganizations(orgs));
                });
        } else {
            console.log('not hitting server', this.orgList$.getValue());
            // remove sites
            let removedFilteredSites = this.orgList$.getValue();
            removedFilteredSites.forEach((o) => {
                o.FilteredSites = [];
            });
            this.orgList$.next(removedFilteredSites);
        }
    }

    ngOnDestroy() {
        console.log('destroyed');
    }

    resetObs(): void {
        this.orgList$.next(null);
        this.orgListError$.next(null);
    }

    getSelectedOrganization(): Organization {
        return this.selectedOrg$.getValue();
    }
    getSelectedOrgRole(): string {
        return this.selectedOrgRole$.getValue();
    }

    async setSelectedOrganization(orgName: string): Promise<Organization> {
        // fix ExpressionChangedAfterItHasBeenCheckedError error
        setTimeout(() => {
            this.selectedOrgName$.next(orgName);
        });
        let selectedOrg = orgName
            ? this.orgList$.getValue()?.find((o) => o.Name === orgName)
            : null;
        this.selectedOrg$.next(selectedOrg);
        if (selectedOrg != null)
            await this.getUserOrgRole(orgName).then((role) => this.selectedOrgRole$.next(role));
        // returning so you can get the org object if you want by just passing in the name
        return selectedOrg;
    }

    getOrganizations(): Promise<Organization[]> {
        const url: string = this.apiUrl + `Organizations`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    addOrganization(organization: Organization): Promise<Organization> {
        const url: string = this.apiUrl + `Organization`;
        return this.http
            .post(url, organization)
            .toPromise()
            .then((res) => res);
    }
    addOrganizationObs(
        organization: Organization | Organization[]
    ): Promise<Organization | Organization[]> {
        let url: string = this.apiUrl;
        // to handle adding single or multiple organizations
        url += Array.isArray(organization) ? `Organizations` : `Organization`;
        return lastValueFrom(this.http.post(url, organization)).then((res) => {
            res = !Array.isArray(res) ? [res] : res;
            let newOrgs = [...this.orgList$.getValue(), ...res];
            this.orgList$.next(this.sortOrganizations(newOrgs));
            return res;
        });
    }

    addOrganizations(organization: Organization[]): Promise<Organization[]> {
        const url: string = this.apiUrl + `Organizations`;
        return this.http
            .post(url, organization)
            .toPromise()
            .then((res) => res);
    }

    updateOrganization(organization: Organization): Promise<Organization> {
        const url: string = this.apiUrl + `Organization`;
        return this.http
            .put(url, organization)
            .toPromise()
            .then((res) => res);
    }

    updateOrganizationObs(organization: Organization): Promise<Organization> {
        const url: string = this.apiUrl + `Organization`;
        return lastValueFrom(this.http.put(url, organization)).then((res: Organization) => {
            let newOrgs = this.orgList$.value.map((org: Organization) =>
                org.Id === res.Id ? res : org
            );
            this.orgList$.next(this.sortOrganizations(newOrgs));
            return res;
        });
    }

    updateOrganizations(organization: Organization[]): Promise<Organization[]> {
        const url: string = this.apiUrl + `Organizations`;
        return this.http
            .put(url, organization)
            .toPromise()
            .then((res) => res);
    }

    deleteOrganization(organizationId: number): Promise<any> {
        const url: string = this.apiUrl + `Organization/${organizationId}`;
        return this.http
            .delete(url)
            .toPromise()
            .then((res) => res);
    }

    deleteOrganizationObs(organizationId: number): Promise<any> {
        const url: string = this.apiUrl + `Organization/${organizationId}`;
        return lastValueFrom(this.http.delete(url)).then((res: Organization) => {
            this.orgList$.next(
                this.orgList$.value.filter((org: Organization) => org.Id !== organizationId)
            );
        });
    }

    moveSitesToOrganization(organizationName: string, siteIds: number[]): Promise<any> {
        const url: string = this.apiUrl + `SiteOrganization`;
        return this.http
            .post(url, { organizationName: organizationName, siteIds: siteIds })
            .toPromise()
            .then((res) => res);
    }

    rotateInstallKey(organizationId: number): Promise<any> {
        const url: string = this.apiUrl + `Organization/${organizationId}/RotateInstallKey`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    setInstallKeyEnabled(organizationId: number, enabled: boolean): Promise<any> {
        const url: string = this.apiUrl + `Organization/${organizationId}/InstallKeyEnabled`;
        let postbody: any = { enabled: enabled };
        return this.http
            .post(url, postbody)
            .toPromise()
            .then((res) => res);
    }

    getInstallKey(organizationId: number): Promise<any> {
        const url: string = this.apiUrl + `Organization/${organizationId}/InstallKey`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => {
                if (!res) return [];
                return res;
            });
    }

    sortOrganizations(orgs) {
        return orgs.sort((a, b) =>
            a.Name.toLowerCase().localeCompare(b.Name.toLowerCase(), undefined, {
                numeric: true,
                sensitivity: 'base',
                ignorePunctuation: true,
            })
        );
    }

    getLatest() {
        this.orgList$.next(null);
        this.getOrganizationsObs();
    }

    getOrgDiscoveryAgentInfo(organizationName: string): Promise<any> {
        const url: string =
            this.apiUrl + `Organization/${window.btoa(organizationName)}/OrgDiscoveryAgent`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    hasOrgDiscoveryAgentAccess(organizationName: string): Promise<boolean> {
        const url: string =
            this.apiUrl + `Organization/${window.btoa(organizationName)}/HasDiscoveryAgentAccess`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    getUserOrgRole(organizationName: string): Promise<string> {
        const url: string = this.apiUrl + `Organization/${window.btoa(organizationName)}/UserRole`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    hasCSPro(org: Organization): boolean {
        return org.Sites.some((o) => o.IsCSPro);
    }

    getDeepFileScanSettings(organizationName: string, robotIDs: string[]): Promise<any> {
        const url: string =
            this.apiUrl + `Organization/${window.btoa(organizationName)}/GetDeepFileScanSettings`;
        let postbody: any = { robotsID: robotIDs };
        return this.http
            .post(url, postbody)
            .toPromise()
            .then((res) => res);
    }

    GetDeepFileScanSettingsStatus(organizationName: string, robotIDs: string[]): Promise<any> {
        const url: string =
            this.apiUrl +
            `Organization/${window.btoa(organizationName)}/GetDeepFileScanSettingsStatus`;
        let postbody: any = { robotsID: robotIDs };
        return this.http
            .post(url, postbody)
            .toPromise()
            .then((res) => res);
    }

    Get3ppScanSetting(organizationId: number, robotIDs: string[]): Promise<any> {
        const url: string = this.apiUrl + `Organization/${organizationId}/Get3ppScanSetting`;
        let postbody: any = { robotsID: robotIDs };
        return lastValueFrom(this.http.post(url, postbody)).then((res) => res);
    }

    UpdateAgentFlags(
        organizationId: number,
        robotIDs: string[],
        settingDeepScan: string
    ): Promise<any> {
        const url: string = this.apiUrl + `Organization/${organizationId}/UpdateAgentFlags`;
        let postbody: any = {
            robotsID: robotIDs,
            settingDeepScan: settingDeepScan,
        };
        return this.http
            .post(url, postbody)
            .toPromise()
            .then((res) => res);
    }

    GetOrganizationConnectors(organizationId: number, siteId: number = -1): Promise<any> {
        const url: string = this.apiUrl + `Organization/${organizationId}/Connectors/${siteId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => {
                if (!res) return [];
                return res;
            });
    }

    /*
   * Removing support for install key based end-user data collector apis.
   * Am told this will be re-added in Q1 2023 so leaving as comment instead of deleting
   *
   getManualDataCollectorUrl(organizationId: number) {
    const url: string = this.apiUrl + `ManualDataCollectorUrl/${organizationId}`;
     return lastValueFrom(this.http.get(url))
       .then((res) => res)
       .catch(err => {});

  }

  redirectToManualDataCollectorUrl(collectorGuid: string): Promise<any> {
    const url: string = this.apiUrl + `RedirectToManualDataCollectorUrl/${collectorGuid}`;
   return lastValueFrom(this.http.get(url))
      .then((res) => res)
      .catch(err => {

        if (err.status == 302) {
          window.location.href = err.error;
        }

      });

  }

  generateManualDataCollectorUrl(organizationId: number) {
    const url: string = this.apiUrl + `GenerateManualDataCollectorUrl`;
    let postbody: any = { organizationId: organizationId };
    return this.http.post(url, postbody)
      .toPromise()
      .then(res => res)
  }

  getManualDataCollectorFiles(organizationId: number) {
    const url: string = this.apiUrl + `ManualDataCollector/Files/${organizationId}`;
    return lastValueFrom(this.http.get(url))
      .then((res) => res)
      .catch(err => { });

  }

  deleteManualDataCollectorFile(organizationId: number, filename: string) {
    let encFilename = btoa(filename);
    console.log('encfilename: ' + encFilename);
    const url: string = this.apiUrl + `ManualDataCollector/Files/${organizationId}/${encFilename}`;
    return lastValueFrom(this.http.delete(url))
      .then((res) => res)
      .catch(err => { });

  }
  */
}
