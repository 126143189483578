import { Notes } from '../../sites/shared/notes/notes.model';

export class ScanDataPrinter {
    public Id: number;
    public Name: string;
    public Domain: string;
    public IpAddresses: string[];
    public AccessedFrom: string;
    public Location: string;
    public Hostname: string;
    public UserGroupPermissions: any;
    public IpAddressesString?: string;

    public Notes: Notes;
    public Procedures: Notes[];
    public RelatedItems: string[];
    public SmartTags: any[];
}
