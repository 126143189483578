<div class="ui warning message" *ngIf="lockedUser || isReadOnly">
    <div class="content" *ngIf="lockedUser">
        {{ lockedUser.FirstName }} ({{ lockedUser.Username }}) is
        currently working on this assessment and this assessment is locked. You can view in
        Read-only mode.
    </div>
    <div class="content" *ngIf="!lockedUser">
        You have not made changes to this assessment for over 20 minutes. You can still view in
        Read-only mode, or reload the assessment to check it out again.
    </div>
</div>
<sds-loader [complete]="loadingComplete">
    <div [hidden]="state !== 1" class="vendorWizard">
        <cm-wizard [options]="wizardOptions"
                   [questions]="assessment?.Controls"
                   [questionDescription]="wizardDescription"
                   [answers]="wizardAnswers"
                   [culture]="culture"
                   [isReadOnly]="isReadOnly"
                   [waitForSaveComplete]="true"
                   (onResponseUpdate)="saveResponses($event)"
                   (onViewResults)="processResults()"
                   #grcWizard></cm-wizard>
    </div>
</sds-loader>

<!-- Warning Modal -->
<sm-modal title="Timeout Warning" class="sm" #warningModal>
    <modal-content>
        <div class="ui warning icon message">
            <i class="warning sign icon"></i>
            <div class="content">
                Warning:
                <br />
                You have been inactive for 15 minutes. At 20 minutes, this assessment will become
                available for other users to edit.
            </div>
        </div>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" size="large" class="cancel">Ok</itc-button>
    </modal-actions>
</sm-modal>

<!-- TimedOut Modal -->
<sm-modal title="Timed Out" class="sm" #timedOutModal>
    <modal-content>
        <div class="ui warning icon message">
            <i class="unlock icon"></i>
            <div class="content">
                You have been inactive for 20 minutes. This assessment is now available for others
                to edit. Reload the assessment to check it out again or continue to view it in
                Read-only mode.
            </div>
        </div>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" size="large" class="cancel">Ok</itc-button>
    </modal-actions>
</sm-modal>
