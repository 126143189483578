export class ApplianceInfoRequest {
    Username: string;
    Password: string;
    EncryptedPassword: string;
    Domain: string;
    // ApplianceId: string;
    SiteName: string;
    ScanHostUsername: string;
    ScanHostPassword: string;
    ScanHostDomain: string;
    ScanHostList: string;
    ApplianceId: string;
}
