import { Component, Input, OnInit } from '@angular/core';

import { CM_COLORS } from '../../shared/cm-summary.constants';

@Component({
    selector: 'cm-unit-donut',
    templateUrl: './cm-unit-donut.component.html',
    styleUrls: ['./cm-unit-donut.component.scss'],
})
export class CmUnitDonutComponent implements OnInit {
    @Input() status: number;
    @Input() numComplete: number;
    @Input() percentComplete: number;
    @Input() label: string;
    @Input() secondaryLabel?: string;
    @Input() secondaryNumComplete?: number;

    CM_COLORS = CM_COLORS; // need this to be able to pass into template
    _chartData: chartData[];
    loadComplete = false;
    public flooredPercentComplete = '0';
    constructor() {}

    ngOnInit() {
        if (this.percentComplete > 1 && this.percentComplete < 100) {
            this.flooredPercentComplete = Math.floor(this.percentComplete).toString() + ' %';
        } else if (this.percentComplete == 0) {
            this.flooredPercentComplete = '0 %';
        } else if (this.percentComplete < 1) {
            this.flooredPercentComplete = '< 1 %';
        } else if (this.percentComplete >= 100) {
            this.flooredPercentComplete = '100 %';
        }

        let completeColor =
            this.status == 1
                ? '#2b7153'
                : this.status == 2
                ? '#7c561c'
                : this.status == 3
                ? '#953d3b'
                : '#606060';
        // let name = this.status == 1 ? 'Accepted': this.status == 2 ? 'Needs attention' : 'Rejected expired';
        this._chartData = [
            {
                bindingName: this.label,
                count: this.percentComplete,
                legendColor: '',
                color: completeColor,
            },
            {
                bindingName: 'Other',
                count: 100 - this.percentComplete,
                legendColor: '#ffffff',
                color: '#ffffff',
            },
        ];
        this.loadComplete = true;
    }
}

interface chartData {
    bindingName: string;
    count: number;
    color: string;
    legendColor: string;
}
