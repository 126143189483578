import { Component } from '@angular/core';
import { SettingService } from '../shared/setting.service';

@Component({
    template: `
        <sds-breadcrumb-trail [crumbs]="breadcrumbs"></sds-breadcrumb-trail>

        <header class="pageHeader">
            <h1>IT Complete</h1>
        </header>

        <div class="itc tabs container outletTabs">
            <div class="tabs_header" style="width: 100%">
                <a class="tab" routerLink="./kaseyaone" routerLinkActive="tab-active">KaseyaOne</a>
                <a class="tab" routerLink="./bullphish-id" routerLinkActive="tab-active">
                    Bullphish ID
                </a>
            </div>
        </div>

        <div class="content">
            <router-outlet></router-outlet>
        </div>
    `,
})
export class ItCompleteComponent {
    constructor(private settingService: SettingService) {}

    breadcrumbs = [{ text: 'Admin' }, { text: 'IT Complete' }, { text: 'Settings' }];
    // breadcrumbs$ = this.settingService.getITCompleteBreadcrumbs();
}
