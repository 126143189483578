import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { DashboardComponent } from './dashboard/dashboard.component';

import { VpGuard } from './vp.guard';
import { VendportalService } from './vendportal.service';
import { VpAuthService } from './vp-auth.service';
import { LayoutComponent } from './layout/layout.component';
import { PortalLayoutComponent } from './portallayout/portal-layout.component';
import { AssessmentComponent } from './assessment/assessment.component';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './vptoken.interceptor';
import { StatusPipe } from './status.pipe';

import { ComplianceManagerSharedModule } from 'app/sites/site/compliance-manager/cm-shared-module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        HttpClientModule,
        FormsModule,
        CoreModule,
        SharedModule,
        ComplianceManagerSharedModule,
    ],
    declarations: [
        LoginComponent,
        DashboardComponent,
        LayoutComponent,
        PortalLayoutComponent,
        AssessmentComponent,
        StatusPipe,
    ],
    providers: [
        VpGuard,
        VendportalService,
        VpAuthService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
    ],
})
export class VendportalModule {}
