<sds-loader [complete]="loadingComplete">
    <div class="alert-outer-container">
        <sds-breadcrumb-trail [crumbs]="breadcrumbs"></sds-breadcrumb-trail>
        <div id="alert">
            <div class="ui stackable grid">
                <div class="nine wide column">
                    <div class="ui message" *ngIf="isTech">
                        <sds-alert-status-badge
                            [alert]="alert"
                            style="
                                margin-right: 1em;
                                display: inline-block;
                                height: 24px;
                                vertical-align: bottom;
                            "
                            [expandTodo]="true"></sds-alert-status-badge>
                        <span style="font-size: 1.1em; font-weight: bold">
                            {{ statusMessage }}
                        </span>
                    </div>

                    <div class="alertMessage">
                        <div class="content">
                            <div class="header">
                                <i class="circle info icon"></i>
                                <p>{{ alert?.Message }}</p>
                            </div>
                            <div class="messageContent">
                                <p [innerHTML]="alert?.Recommendation"></p>

                                <!-- SWITCH on alert.Status -->
                                <div
                                    [ngSwitch]="alert?.Status"
                                    style="padding-top: 1em"
                                    class="todoButtons">
                                    <!-- GDPR Buttons -->
                                    <div *ngSwitchCase="statuses.TASK.value">
                                        <span *ngFor="let button of alert?.Buttons">
                                            <itc-button
                                                [label]="button.Label"
                                                *ngIf="
                                                    button.Url.substring(0, 1) != '!' &&
                                                    button.Url.substring(0, 1) != '^'
                                                "
                                                type="primary"
                                                [routerLink]="getButtonHref(button)"
                                                (onclick)="setPrevAlert()"></itc-button>
                                            <itc-button
                                                [label]="button.Label"
                                                *ngIf="button.Url.substring(0, 1) == '!'"
                                                type="primary"
                                                (onclick)="
                                                    customClickCommand(button.Url.substring(2))
                                                "
                                                [disableAfterClick]="
                                                    button.Url != '!!uploadscan'
                                                "></itc-button>
                                        </span>
                                    </div>

                                    <div *ngSwitchCase="statuses.TASK_PENDING.value">
                                        <span *ngFor="let button of alert?.Buttons">
                                            <itc-button
                                                [label]="button.Label"
                                                *ngIf="
                                                    button.Url.substring(0, 1) != '!' &&
                                                    button.Url.substring(0, 1) != '^'
                                                "
                                                type="primary"
                                                [routerLink]="getButtonHref(button)"
                                                (onclick)="setPrevAlert()"></itc-button>
                                            <itc-button
                                                [label]="button.Label"
                                                *ngIf="button.Url.substring(0, 1) == '!'"
                                                type="primary"
                                                (onclick)="
                                                    customClickCommand(button.Url.substring(2))
                                                "
                                                [disableAfterClick]="
                                                    button.Url != '!!uploadscan'
                                                "></itc-button>
                                        </span>
                                    </div>

                                    <div *ngSwitchCase="statuses.TASK_COMPLETE.value">
                                        <span *ngFor="let button of alert?.Buttons">
                                            <itc-button
                                                [label]="button.Label"
                                                *ngIf="
                                                    button.Url.substring(0, 1) != '!' &&
                                                    button.Url.substring(0, 1) != '^'
                                                "
                                                type="primary"
                                                [routerLink]="getButtonHref(button)"
                                                (onclick)="setPrevAlert()"></itc-button>
                                            <itc-button
                                                [label]="button.Label"
                                                *ngIf="button.Url.substring(0, 1) == '!'"
                                                [disabled]="true"
                                                type="primary"></itc-button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <form class="ui form" name="form" #alertForm="ngForm">
                        <alert-data-view
                            *ngIf="
                                alert &&
                                !alert?.AlertType.includes('todo') &&
                                alert?.Status != statuses.TASK.value &&
                                alert?.Status != statuses.TASK_PENDING.value &&
                                alert?.Status != statuses.TASK_COMPLETE.value
                            "
                            [alert]="alert"
                            [readonly]="(isTech && alert?.Status != statuses.TODO.value) || !isTech"
                            [hideHeader]="!isTech"
                            [hasError]="listError"
                            #dataControl></alert-data-view>

                        <!-- SWITCH on alert.Status -->
                        <div [ngSwitch]="alert?.Status">
                            <!-- NEW -->
                            <div *ngSwitchCase="statuses.NEW.value">
                                <br />
                                <itc-button
                                    type="primary"
                                    icon="tasks icon"
                                    (onclick)="submitNew('todo')">
                                    Create To Do
                                </itc-button>
                                <itc-button
                                    type="primary"
                                    (onclick)="submitNew('ticket')"
                                    icon="ticket icon"
                                    [disabled]="!connection"
                                    tooltip="Associate a PSA connection with the site to create tickets"
                                    [ttHidden]="!!connection"
                                    [ttLink]="{
                                        href: '/settings/connections',
                                        text: 'View Connection Settings',
                                        newTab: true
                                    }">
                                    Create Ticket
                                </itc-button>
                            </div>

                            <!-- TO DO -->
                            <div *ngSwitchCase="statuses.TODO.value">
                                <div *ngIf="isTech">
                                    <br />
                                    <div>
                                        <!-- Actions -->
                                        <h4 [class.red]="actionError">Action(s):</h4>
                                        <div
                                            style="display: flex; flex-direction: column"
                                            [class.error]="actionError">
                                            <itc-checkbox
                                                *ngFor="
                                                    let action of suggestedActions;
                                                    let i = index
                                                "
                                                label="{{ action.label }}"
                                                [control]="actionControls[i]"></itc-checkbox>
                                        </div>

                                        <div
                                            class="ui compact negative message"
                                            *ngIf="actionError">
                                            You must select an action to mark this as completed.
                                        </div>
                                    </div>

                                    <br />

                                    <itc-button
                                        type="primary"
                                        icon="send"
                                        (onclick)="todoAction('submit')"
                                        [disabled]="completing"
                                        *ngIf="!isSME">
                                        Submit Action
                                        <br />
                                        <span style="font-size: 12px">and mark complete</span>
                                    </itc-button>
                                    <itc-button
                                        type="primary"
                                        icon="fa-check"
                                        (onclick)="todoAction('complete')"
                                        [disabled]="completing"
                                        *ngIf="!isSME">
                                        Mark Complete
                                        <br />
                                        <span style="font-size: 12px">and take no action</span>
                                    </itc-button>
                                </div>

                                <div *ngIf="!isTech">
                                    <div class="ui message" style="margin-top: 14px">
                                        <p style="font-size: 1.1em; font-weight: bold">
                                            Another user has already requested the issue be
                                            investigated/ignored.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <!-- END USER -->
                            <div *ngSwitchCase="statuses.END_USER.value">
                                <br *ngIf="!isTech" />

                                <div *ngIf="params?.action">
                                    <div class="field">
                                        <label for="notes">Notes (Optional)</label>
                                        <textarea
                                            name="notes"
                                            [(ngModel)]="userNotes"
                                            rows="4"
                                            style="max-width: 550px"></textarea>
                                    </div>

                                    <div *ngIf="params?.action == 'investigate'">
                                        <itc-button
                                            type="secondary"
                                            icon="search"
                                            (onclick)="endUserSubmit()">
                                            Request Investigation
                                        </itc-button>
                                    </div>

                                    <div *ngIf="params?.action == 'ignore'">
                                        <!--<h4 class="ui header" [ngClass]="{ 'red': reasonError }">Reason:</h4>-->
                                        <div
                                            class="grouped fields"
                                            [ngClass]="{ error: reasonError }">
                                            <label for="reason">Reason:</label>
                                            <div class="field">
                                                <div>
                                                    <itc-radio
                                                        name="reason"
                                                        [(ngModel)]="ignoreReason"
                                                        value="once"
                                                        (ngModelChange)="
                                                            reasonError = false
                                                        "></itc-radio>
                                                    <label>
                                                        Just ignore this one time. I'm aware of it.
                                                    </label>
                                                </div>
                                            </div>
                                            <div
                                                class="field"
                                                *ngIf="alert?.Status != statuses.END_USER.value">
                                                <div>
                                                    <itc-radio
                                                        name="reason"
                                                        [(ngModel)]="ignoreReason"
                                                        value="falsep"
                                                        (ngModelChange)="
                                                            reasonError = false
                                                        "></itc-radio>
                                                    <label>
                                                        False Positive. Please tag appropriately.
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="field">
                                                <div>
                                                    <itc-radio
                                                        name="reason"
                                                        [(ngModel)]="ignoreReason"
                                                        value="always"
                                                        (ngModelChange)="
                                                            reasonError = false
                                                        "></itc-radio>
                                                    <label>
                                                        I do not wish to receive this notification
                                                        anymore.
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            class="ui compact negative message"
                                            *ngIf="reasonError">
                                            Please select from the reasons above!
                                        </div>

                                        <br />

                                        <itc-button
                                            type="secondary"
                                            icon="fa-ban"
                                            (onclick)="endUserSubmit()">
                                            Request Ignore
                                        </itc-button>
                                    </div>
                                </div>
                            </div>

                            <!-- COMPLETED -->
                            <div *ngSwitchCase="statuses.COMPLETED.value">
                                <itc-button
                                    type="secondary"
                                    icon="fa-arrow-left-rotate"
                                    *ngIf="isTech"
                                    (onclick)="submitNew('todo')">
                                    Revert
                                </itc-button>

                                <div *ngIf="!isTech">
                                    <div class="ui message" style="margin-top: 14px">
                                        <p style="font-size: 1.1em; font-weight: bold">
                                            This alert has already been completed by a technician.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <!-- TICKET -->
                            <div *ngSwitchCase="statuses.TICKET.value">
                                <itc-button
                                    type="primary"
                                    icon="tasks"
                                    (onclick)="submitNew('todo')"
                                    *ngIf="isTech">
                                    Create To Do
                                </itc-button>
                            </div>

                            <!-- TASK -->
                            <div *ngSwitchCase="statuses.TASK.value">
                                <itc-button type="primary" icon="fa-check" [disabled]="true">
                                    Mark Complete{{ isSME ? ' is not available to SMEs' : '' }}
                                </itc-button>
                            </div>

                            <div *ngSwitchCase="statuses.TASK_PENDING.value">
                                <itc-button
                                    type="primary"
                                    icon="fa-check"
                                    (onclick)="markGdprComplete()"
                                    [disabled]="isSME"
                                    [tooltip]="
                                        isSME ? 'Mark Complete is not available to SMEs' : ''
                                    ">
                                    Mark Complete
                                </itc-button>
                            </div>

                            <div *ngSwitchCase="statuses.TASK_COMPLETE.value">
                                <itc-button type="primary" icon="fa-check" [disabled]="true">
                                    Completed
                                </itc-button>
                                <itc-button
                                    *ngIf="alert.CompletionType == 'ACTION'"
                                    type="primary"
                                    (onclick)="rerunAction()">
                                    Re-run
                                </itc-button>
                                <itc-button
                                    *ngIf="alert.CompletionType == 'WORKSHEET'"
                                    type="primary"
                                    (onclick)="modifyWorksheet()">
                                    Modify
                                </itc-button>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="seven wide column">
                    <!-- Additional Info (needs to be out here for reasons) -->
                    <div
                        class="ui styled fluid accordion"
                        [hidden]="alert?.Status == statuses.NEW.value || !isTech"
                        #infoBox>
                        <div class="title" [ngClass]="{ active: alert?.Notes }">
                            <i class="dropdown icon"></i>
                            Additional Information
                        </div>
                        <div
                            class="content"
                            [ngClass]="{ active: alert?.Notes }"
                            style="line-height: 1.5em; padding-bottom: 1em">
                            <span *ngIf="alert?.Notes">
                                <b>Notes:</b>
                                <pre style="margin: 5px 1em">{{ alert?.Notes }}</pre>
                            </span>
                            <b><fa-icon icon="hard-drive"></fa-icon></b>
                            {{
                                alert?.ApplianceId +
                                    (isAG
                                        ? ' (Compliance Manager Appliance)'
                                        : isCH
                                        ? alert?.ApplianceId?.includes(',')
                                            ? ' (Appliances)'
                                            : ' (Appliance)'
                                        : '')
                            }}
                            <br />
                            <b><fa-icon icon="calendar"></fa-icon></b>
                            {{ alert?.Created | date : 'medium' }}
                        </div>
                    </div>
                    <div
                        class="ui styled fluid accordion historyAccordion"
                        [hidden]="
                            !(alert?.Status != statuses.NEW.value && alertHistory?.length && isTech)
                        "
                        #historyBox>
                        <div class="title">
                            <i class="dropdown icon"></i>
                            History
                        </div>
                        <div class="content" style="line-height: 1.5em; padding-bottom: 1em">
                            <table class="itc-table">
                                <tr>
                                    <th>Time</th>
                                    <th>User</th>
                                    <th>Message</th>
                                </tr>
                                <tr *ngFor="let row of alertHistory">
                                    <td class="collapsing">
                                        {{ row.ModifiedDate | date : 'medium' }}
                                    </td>
                                    <td class="collapsing">{{ row.ModifiedBy }}</td>
                                    <td style="word-break: break-word">{{ row.Message }}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <!-- Response SOP's -->
                    <div
                        class="ui styled fluid accordion"
                        [hidden]="
                            !isTech || !alert?.PublicSop || alert?.Status == statuses.NEW.value
                        "
                        #sopBox>
                        <!-- Win requested this be active as default. <div class="title" [ngClass]="{'active': alert?.Notes}"> -->
                        <div class="title active">
                            <i class="dropdown icon"></i>
                            Recommended Response Plans
                        </div>
                        <div class="content active">
                            <b><i class="wrench icon"></i></b>
                            <a href="javascript:" (click)="sopModal.modal.show()">
                                {{ alert?.SopTitle }}
                            </a>
                        </div>
                    </div>
                    <div
                        class="ui styled fluid accordion"
                        [hidden]="!(similarAlerts && similarAlerts.length)"
                        #similarBox>
                        <div class="title">
                            <i class="dropdown icon"></i>
                            Related To Do ({{ similarAlerts?.length }})
                        </div>
                        <div
                            class="content relatedTodoTable"
                            style="line-height: 1.5em; padding-bottom: 1em">
                            <sds-todo-table
                                [todos]="similarAlerts"
                                [forSite]="true"
                                [parentRelative]="true"
                                (sortBy)="processSort($event)"
                                linksRoot=".."></sds-todo-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</sds-loader>

<sm-modal title="Confirm Batch Action" class="sm" #batchConfirmModal>
    <modal-content>
        <div *ngIf="batchAction == 'submit' || batchAction == 'complete'">
            {{ similarAlerts.length }} related To Do item(s) will be marked as completed.
        </div>
        <div *ngIf="batchAction == 'cancel'">
            {{ similarAlerts.length }} related To Do item(s) will also be canceled.
        </div>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" (onclick)="cancelBatch()">Cancel</itc-button>
        <itc-button type="primary" (onclick)="multiAction()">Confirm</itc-button>
    </modal-actions>
</sm-modal>

<sm-modal title="Warning" class="sm" #rollbackActionModal>
    <modal-content>
        {{ rollbackType }} the currently completed step
        {{
            rollbackImpact?.length > 0
                ? 'will invalidate the following items:'
                : 'will not affect other tasks.'
        }}
        <ul>
            <li *ngFor="let impact of rollbackImpact">{{ impact }}</li>
        </ul>
        Proceed?
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" (onclick)="confirmRollback(false)">Cancel</itc-button>
        <itc-button type="primary" (onclick)="confirmRollback(true)">Confirm</itc-button>
    </modal-actions>
</sm-modal>

<sm-modal title="Error" class="sm" #rollbackErrorModal>
    <modal-content>
        The request to modify this item cannot be completed because:
        <ul>
            <li>{{ rollbackError }}</li>
        </ul>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" (onclick)="confirmRollbackError()">Close</itc-button>
    </modal-actions>
</sm-modal>

<sm-modal title="Upload CDF File" class="sm" #uploadCdfModal>
    <modal-content>
        <input
            type="file"
            (change)="fileChange($event)"
            placeholder="Upload CDF"
            accept=".cdf, .zip" />
        <br />
        <br />
        Upload Local computer scans produced by the Local Computer Data Collector contained in
        either .CDF or .ZIP file format. After scans are uploaded, Compliance Manager will merge
        your files into your assessment within 15 minutes.
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" class="cancel">Done</itc-button>
    </modal-actions>
</sm-modal>

<sds-alert-sop-modal
    *ngIf="alert?.PublicSop"
    [note]="alert?.PublicSop"
    [title]="alert?.SopTitle"
    #sopModal></sds-alert-sop-modal>

<sm-modal title="RMM Connector Files" class="lg" #connectorModal>
    <modal-content>
        <sds-loader [complete]="connectorModalLoadingComplete">
            <p>Select one or more files to merge into the assessment.</p>

            <sds-connector-files
                [reload]="doReload"
                [site]="site"
                (selectedConnectorFiles)="selectedConnectorFiles = $event"></sds-connector-files>
        </sds-loader>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" class="cancel">Cancel</itc-button>
        <itc-button
            type="primary"
            [disabled]="!selectedConnectorFiles?.length"
            [loading]="loadingZip"
            (onclick)="downloadSelectedConnectorFiles()">
            Download Selected
        </itc-button>
        <itc-button
            type="danger"
            [disabled]="!selectedConnectorFiles?.length"
            [loading]="deletingFiles"
            (onclick)="deleteSelectedConnectorFiles()">
            Delete Selected
        </itc-button>
        <itc-button
            type="primary"
            [disabled]="!selectedConnectorFiles?.length"
            [tooltip]="
                !selectedConnectorFiles?.length ? 'Please select at least one file to attach' : ''
            "
            class="approve"
            (onclick)="addSelectedConnectorFiles()">
            Add {{ selectedConnectorFiles?.length }} files
        </itc-button>
    </modal-actions>
</sm-modal>
