import { Directive } from '@angular/core';
import { Dropdown } from 'primeng/dropdown';
import { Subject, takeUntil } from 'rxjs';

@Directive({
    selector: 'p-dropdown',
})
export class PrimengDropdownDirective {
    ngUnsubscribe$ = new Subject<void>();

    constructor(private dropdown: Dropdown) {
        this.dropdown.dropdownIcon = 'fa6-chevron-down';
        if (dropdown.styleClass !== 'not-appended-to-body') {
            this.dropdown.appendTo = 'body';
        }
        this.dropdown.onShow.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((evt) => {
            // set width of the dropdown menu container to match the select
            let dropdownContainerWidth = this.dropdown.el.nativeElement.offsetWidth;
            evt.element.style.width = `${dropdownContainerWidth}px`;
            evt.element.parentNode.style.left = `${this.dropdown.el.nativeElement.getBoundingClientRect().x}px`;
            // add the KDS 4px offset above dropdown
            evt.element.style.paddingTop = '4px';

            // add title attribute to list items that are overflowing
            evt.element.querySelectorAll('li').forEach((li) => {
                if (li.querySelector('span').scrollWidth > li.clientWidth) {
                    li.setAttribute('title', li.querySelector('span').innerText);
                }
            });
            // scroll to highlighted element because it has a bug that won't let
            // it scroll to the highlighted element when the dropdown is opened
            // and appended to the body
            let container = evt.element.querySelector('.p-dropdown-items-wrapper');
            let options = evt.element.querySelector('.p-dropdown-item.p-highlight');
            if (container && options) {
                container.scrollTop = options.offsetTop;
            }
        });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next(void 0);
        this.ngUnsubscribe$.complete();
    }
}
