import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UiService } from 'app/core/ui.service';
import { SettingService } from 'app/settings/shared/setting.service';
import { NotificationService } from 'app/shared/itc/notification/notification.service';
import { Breadcrumb } from 'app/shared/breadcrumbs/breadcrumb.model';

@Component({
    templateUrl: './bullphish-id.component.html',
    styleUrls: ['./bullphish-id.component.scss'],
})
export class BullphishIdComponent {
    constructor(
        private uiService: UiService,
        private settingService: SettingService,
        private notificationService: NotificationService
    ) {}

    @Output() breadCrumbs: EventEmitter<Breadcrumb[]> = new EventEmitter<Breadcrumb[]>();

    loadingComplete: boolean;
    bullPhishEnableCtrl: FormControl<boolean> = new FormControl();
    bullPhishDetails?: BullPihishDetails;
    showBullPhishBanner = false;
    disableAll = false;
    isProvisioned = false;
    disableEmail = false;

    breadcrumbs = [{ text: 'Admin' }, { text: 'IT Complete' }, { text: 'BullPhish ID' }];

    ngOnInit() {
        this.uiService.setTitle('IT Complete - BullPhish ID');
        this.settingService.setITCompleteBreadcrumbs(this.breadcrumbs);

        this.loadingComplete = false;

        this.settingService.isBullPhishEnabled().then((res: BullPihishDetails) => {
            this.bullPhishDetails = res;
            this.disableAll = !res.BullPhish_OPS;
            this.isProvisioned = res.BullPhish_OPS;
            this.bullPhishEnableCtrl.setValue((res.Account_Global && res.BullPhish_OPS) || false);
        });
    }

    bullPhishValueChanged() {
        this.settingService
            .setBullPhishValue(this.bullPhishEnableCtrl.value)
            .then((res) => {
                this.notificationService.toast.success('Success', 'BullPhish setting was updated.');
            })
            .catch((err) =>
                this.notificationService.toast.error(
                    'Error',
                    'Error occured while updating settings.'
                )
            );
    }

    sendEmailBullPhishClicked() {
        this.notificationService.toast.info('Bullphish request being sent.', '');
        this.settingService
            .requestBullphishEmail()
            .then((res) => {
                this.showBullPhishBanner = true;
                this.disableEmail = true;
            })
            .catch((err) => {
                this.notificationService.toast.error(
                    'Error',
                    'Error occured while requesting provision.'
                );
                this.disableEmail = false;
            });
    }

    onBannerBullPhishClose() {
        this.showBullPhishBanner = false;
    }
}

interface BullPihishDetails {
    Account_Global: boolean;
    BullPhish_OPS: boolean;
}
