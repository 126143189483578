import { Topic } from './topic.model';
export class Category {
    Enabled: boolean;
    Id: string;
    Instructions: string;
    InstructionsLocalized: any;
    Name: string;
    NameLocalized: any;
    SectionNumber: string;
    Topics: Topic[];
    viewModel: CategoryViewModel;
    IsFilteredOut: boolean = false;

    constructor() {
        this.viewModel = new CategoryViewModel();
    }
}

export class CategoryViewModel {
    isExpanded: boolean;
    hasChildFiltered: boolean;
    bulkResponseChecked: boolean;
    requiredRemaining: number;
    isCompleted: boolean = false;
    questionsRemaining: any[];
}
