<div
    class="itcCard {{ class }}"
    [class.hasShadow]="hasShadow"
    [class.topBorder]="hasTopBorder"
    (click)="emitClick($event)">
    <div class="title">
        <ng-content select="card-title"></ng-content>
    </div>
    <div class="content">
        <ng-content select="card-content"></ng-content>
    </div>
    <div class="actions">
        <ng-content select="card-actions"></ng-content>
    </div>
</div>
