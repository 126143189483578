import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { NotificationMethod } from './notification.model';

import { NotificationService } from './notification.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'itc-notification-toast-area',
    template: `
        <div id="toastAreaITC"><div #toastArea></div></div>
    `,
    styles: [
        `
            #toastAreaITC {
                position: fixed;
                left: 32px;
                bottom: 32px;
                width: 350px;
                z-index: 9001;
                user-select: none;
            }
        `,
    ],
})
export class ItcNotificationToastAreaComponent implements OnInit {
    constructor(private notificationService: NotificationService) {}

    @ViewChild('toastArea', { read: ViewContainerRef, static: true })
    private toastArea: ViewContainerRef;
    private ngUnsubscribe$ = new Subject<void>();

    ngOnInit() {
        this.notificationService
            .getObservable()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((toast) => {
                // ignore notification if it's not a toast
                if (toast.method == NotificationMethod.Toast) {
                    this.notificationService.makeToast(this.toastArea, toast);
                }
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }
}
