import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MessageService } from 'app/core/message.service';
import { Subscription } from 'rxjs';
import { AuthService } from '../auth';

import {
    Announcement,
    AnnouncementService,
    ANNOUNCEMENT,
    PING_ANNOUNCEMENT,
} from './announcement.service';

export const ignoreAccounts = ['@D3M170307A', '@D3M170307C', '@D3M240306', '@D3M230828']; // used to not show announcements for specific accountIds

@Component({
    selector: 'sds-announcements',
    templateUrl: './announcements.component.html',
    styleUrls: ['./announcements.component.css'],
})
export class AnnouncementsComponent implements OnInit {
    announcements: Announcement[];

    @ViewChild('modal', { static: true }) modal: any;

    hideAfterViewCtrl: UntypedFormControl = new UntypedFormControl();

    viewIndex: number = 0;
    canClose: boolean;
    msgSub: Subscription;

    constructor(
        private announcementService: AnnouncementService,
        private msgService: MessageService,
        private authService: AuthService
    ) {}

    ngOnInit() {
        let user = this.authService.getIdentity();
        if (ignoreAccounts.includes(user.accountId)) {
            return;
        }

        this.msgSub = this.msgService
            .on(ANNOUNCEMENT)
            .subscribe((announcements: Announcement[]) => {
                this.announcements = announcements;
                console.log(this.announcements);
                if (this.announcementService.newAnnouncements > 0) {
                    this.modal.show({ closable: false });
                    this.announcementService.setLastViewed(this.announcements[0].Id);
                }
            });

        this.msgService.broadcast(PING_ANNOUNCEMENT);
    }

    prev() {
        if (this.viewIndex > 0) this.viewIndex = this.viewIndex - 1;
    }

    next() {
        if (this.viewIndex < this.announcements.length - 1) this.viewIndex = this.viewIndex + 1;

        this.announcementService.setLastViewed(this.announcements[this.viewIndex].Id);

        if (this.viewIndex == this.announcements.length - 1) this.canClose = true;
    }
    ngOnDestroy() {
        this.msgSub?.unsubscribe();
    }
}
