import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
    SimpleChanges,
} from '@angular/core';

declare var $: any;

@Component({
    selector: 'sds-paginator',
    templateUrl: './paginator.component.html',
    styleUrls: ['./paginator.component.css'],
})
export class PaginatorComponent implements OnChanges, OnInit {
    constructor() {}

    @Input() data: any[];
    @Input() sizes: number[];
    @Input() pageSize: number;
    @Input() pageNumber: number;

    @Output() onPage: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('pager', { static: true }) pager: ElementRef;
    @ViewChild('pageNumberInput', { static: true }) pageNumberInput: ElementRef;

    pages = [];
    lastPage: number = 0;

    ngOnInit() {
        $(this.pager.nativeElement)
            .children('.ui.button')
            .click(function () {
                this.blur();
            });
    }

    // These are a hacky way to prevent an infinite looping problem with the page() function.
    // When using this component, be sure there is only one initial value given by the parent component for each of these. Any subsequent value setting by the parent will be ignored.
    initialPageSizeSet: boolean;
    initialPageNumberSet: boolean;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.data) this.data = changes.data.currentValue;

        if (changes.pageSize && !this.initialPageSizeSet) {
            this.pageSize = changes.pageSize.currentValue;
            this.initialPageSizeSet = true;
        }

        if (changes.pageNumber && !this.initialPageNumberSet) {
            let val = changes.pageNumber.currentValue;

            if (val >= 0 && val <= this.lastPage - 1) {
                this.pageNumber = val;
            }
            this.initialPageNumberSet = true;
        }

        if (changes.sizes) this.sizes = changes.sizes.currentValue;

        this.page();
    }

    setPage(page: number) {
        if (page >= 0 && page <= this.lastPage) this.pageNumber = page;
        else $(this.pageNumberInput.nativeElement).val(this.pageNumber + 1);

        this.page();
    }

    // NOTE: this function has the potential for looping infinitely if the component is not used carefully.
    // This can happen if the following conditions are met, and the guards above are removed:
    // 1. The parent component initializes values for pageSize and/or pageNumber (i.e. sets a value on declaration)
    // 2. The parent component subsequently changes one of those values (likely pulled from the UI service)
    // 3. The parent component is listening for the emitted event and uses the emitted values to update the very same values that are passed into this component as @Inputs
    // This results in two simultaneous loops which alternately set the @Input in question (probably pageSize) to two different values, which causes the ngOnChanges to run and call this function.
    // This problem should be mitigated by the "initialPageSizeSet" and "initialPageNumberSet" checks above, but if those are removed, it may present itself again.
    page() {
        if (this.data) {
            this.lastPage =
                Math.floor(this.data.length / this.pageSize) -
                (this.data.length % this.pageSize ? 0 : 1);
            if (this.pageNumber > this.lastPage) this.pageNumber = 0;

            this.pages = [].constructor(this.lastPage + 1);

            this.pageSize = Number.parseInt(this.pageSize.toString()); // Seems silly but somehow this is being set to a string anyway?

            this.onPage.emit({
                pageSize: this.pageSize,
                pageNumber: this.pageNumber,
                lastPage: this.lastPage,
            });
        }
    }
}
