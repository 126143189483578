import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

import { AuthHttp } from 'app/core/auth';
import { RelatedItems } from './site-related-items.model';
import { environment } from 'environments/environment';

//import { ErrorService } from 'app/core/error/error.service';

@Injectable({ providedIn: 'root' })
export class RelatedItemsService {
    private apiUrl: string;

    constructor(
        private http: AuthHttp //, //private E: ErrorService
    ) {
        this.apiUrl = environment.apiUrl;
    }

    getUserRelatedItems(siteId: number, uid: string): Promise<RelatedItems[]> {
        const url: string =
            this.apiUrl + 'Sites/' + siteId + '/UserRelatedItems/' + window.btoa(uid);

        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as RelatedItems[]);
    }

    getComputerRelatedItems(siteId: number, uid: string): Promise<RelatedItems[]> {
        const url: string =
            this.apiUrl + 'Sites/' + siteId + '/ComputerRelatedItems/' + window.btoa(uid);

        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as RelatedItems[]);
    }
}
