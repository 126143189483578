import { CommonModule } from '@angular/common';
import {
    Component,
    Input,
    forwardRef,
    EventEmitter,
    Output,
    OnInit,
    ViewChild,
    ElementRef,
} from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
    standalone: true,
    imports: [CommonModule],
    selector: 'itc-radio',
    template: `
        <div
            class="kaseya-ui-radio-button {{ className }}"
            [ngClass]="{ 'radio-button--disabled': disabled, 'radio-button--nested': nested }">
            <label class="radio-button__label">
                <span *ngIf="label && labelPosition != 'after'">{{ label }}</span>
                <span *ngIf="!label && labelPosition != 'after'"><ng-content></ng-content></span>
                <input
                    [name]="name"
                    type="radio"
                    [value]="value"
                    [checked]="checked"
                    [disabled]="disabled ? disabled : null"
                    (change)="radioClicked()" />
                <span *ngIf="label && labelPosition == 'after'">{{ label }}</span>
                <span *ngIf="!label && labelPosition == 'after'"><ng-content></ng-content></span>
            </label>
        </div>
    `,
    styleUrls: ['./itc-radio.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ItcRadioComponent),
            multi: true,
        },
    ],
})
export class ItcRadioComponent implements OnInit, ControlValueAccessor {
    @Input() control: UntypedFormControl;
    @Input() className: string;
    @Input() name: string;
    @Input() value: string;
    @Input() label: string;
    @Input() labelPosition = 'after';
    @Input() checked: boolean;
    @Input() nested = false;
    @Input() disabled = false;
    @Output() onChanged = new EventEmitter<any>();

    controlSub: Subscription;

    ngOnInit() {
        if (this.control) {
            this.control.registerOnDisabledChange((ds) => {
                this.setDisabledState(ds);
            });
            this.controlSub = this.control.valueChanges.subscribe((val) => {
                this.writeValue(val);
            });
            if (this.control.disabled) {
                this.setDisabledState(this.control.disabled);
            }
            this.writeValue(this.control.value);
        }
    }

    ngOnDestroy() {
        if (this.controlSub) {
            this.controlSub.unsubscribe();
        }
    }

    /* ngModal Access boilerplate */
    onChange = (_) => {};
    onBlur = (_) => {};
    writeValue(obj: any): void {
        if (this.disabled) {
            return;
        }
        this.checked = obj == this.value ? true : false;
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onBlur = fn;
    }
    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    radioClicked() {
        if (this.disabled) {
            return;
        }

        if (this.control) {
            this.control.setValue(this.value);
            this.control.markAsDirty();
            this.control.markAsTouched();
        } else {
            this.onChange(this.value);
        }
        this.onChanged.emit(this.value);
    }
}
