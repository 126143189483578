import { Directive } from '@angular/core';
import { InputNumber } from 'primeng/inputnumber';

@Directive({
    selector: 'p-inputNumber',
})
export class PrimengInputNumberDirective {
    constructor(private input: InputNumber) {
        this.input.showButtons = true;
        // this.input.buttonLayout = 'horizontal';
        this.input.incrementButtonIcon = 'fa6-plus';
        this.input.decrementButtonIcon = 'fa6-minus';
    }
}
