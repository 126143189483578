import {
    Component,
    Input,
    AfterViewInit,
    ViewChild,
    ElementRef,
    OnDestroy,
    Output,
    EventEmitter,
    ChangeDetectionStrategy,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

declare var jQuery: any;

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'sm-select',
    template: `
        <div
            class="field"
            [ngClass]="{ error: !control?.valid && control?.touched, required: required }">
            <label *ngIf="label">{{ label }}</label>
            <select [formControl]="control" class="ui {{ class }} dropdown" #select>
                <option value="">{{ placeholder }}</option>
                <ng-content></ng-content>
            </select>
        </div>
    `,
})
export class SemanticSelectComponent implements AfterViewInit, OnDestroy {
    @Input() control: UntypedFormControl = new UntypedFormControl();
    @Input() class: string;
    @Input() label: string;

    @Input('disabled')
    set disabled(data: boolean) {
        setTimeout(() => {
            if (data) {
                jQuery(this.select.nativeElement.parentNode).addClass('disabled');
            } else {
                jQuery(this.select.nativeElement.parentNode).removeClass('disabled');
            }
        }, 1);
    }

    @Input() options: {} = {};
    @Output() modelChange: EventEmitter<string | number> = new EventEmitter<string | number>();
    @Output() onChange: EventEmitter<string | number> = new EventEmitter<string | number>();
    @ViewChild('select', { static: true }) select: ElementRef;

    @Input() required: boolean;

    private _placeholder: string;
    @Input()
    set placeholder(val: string) {
        this._placeholder = val;
        setTimeout(() => {
            jQuery(this.select.nativeElement.parentNode)
                .find('.text')
                .addClass('default')
                .text(val);
        }, 1);
    }
    get placeholder(): string {
        return this._placeholder;
    }

    @Input('model')
    set model(data: string | number) {
        if (data) {
            setTimeout(() => {
                jQuery(this.select.nativeElement).dropdown('set selected', data);
            }, 1);
        }
    }

    private _loading: boolean;
    @Input()
    set loading(l: boolean) {
        setTimeout(() => {
            if (l) {
                jQuery(this.select.nativeElement.parentNode).addClass('loading');
            } else {
                jQuery(this.select.nativeElement.parentNode).removeClass('loading');
            }
        }, 1);
    }

    private _hasError: boolean;
    @Input()
    set error(e: boolean) {
        setTimeout(() => {
            if (e) {
                jQuery(this.select.nativeElement.parentNode).addClass('error');
            } else {
                jQuery(this.select.nativeElement.parentNode).removeClass('error');
            }
        }, 1);
    }

    private multiple: boolean = false;
    private isInitialState: boolean = false;

    private controlSub: any;
    ngAfterViewInit(): void {
        if (typeof this.class === 'string' && this.class.search('multiple') >= 0) {
            this.select.nativeElement.setAttribute('multiple', true);
        }

        const options: {} = Object.assign(
            {
                onChange: (value: string | number) => {
                    this.modelChange.emit(value);
                    this.onChange.emit(value);
                },
                onHide: () => this.control.markAsTouched(),
            },
            this.options
        );

        this.controlSub = this.control.valueChanges.subscribe((value) => {
            if (value !== void 0) this.setSelected('' + value);
            else if (!this.isInitialState) this.reset();
        });

        jQuery(this.select.nativeElement).dropdown(options);

        this.isInitialState = true;
    }

    ngOnDestroy() {
        if (this.controlSub) this.controlSub.unsubscribe();
    }

    setText(text: string) {
        jQuery(this.select.nativeElement).dropdown('set text', text);
    }

    setSelected(value: string) {
        setTimeout(() => {
            jQuery(this.select.nativeElement)
                .parent()
                .find('.item.selected')
                .removeClass('active')
                .removeClass('selected');
            let item = jQuery(this.select.nativeElement)
                .parent()
                .find(`.item[data-value="${value}"]`);
            if (item) {
                item.addClass('active');
                item.addClass('selected');
                jQuery(this.select.nativeElement)
                    .parent()
                    .find('div.text')
                    .removeClass('default')
                    .text(item.text());
            }

            this.isInitialState = false;
        }, 1);
    }

    setMultiSelected(value: string) {
        setTimeout(() => {
            let item = jQuery(this.select.nativeElement)
                .parent()
                .find(`.item[data-value="${value}"]`);
            if (item) {
                item.addClass('active');
                item.addClass('selected');
                jQuery(this.select.nativeElement)
                    .parent()
                    .find('div.text')
                    .removeClass('default')
                    .text(item.text());
            }

            this.isInitialState = false;
        }, 1);
    }

    reset(newStyle = false): void {
        console.log('Resetting Dropdown');
        if (newStyle) {
            jQuery(this.select.nativeElement).dropdown('remove search term');
            jQuery(this.select.nativeElement).dropdown('restore defaults');
            setTimeout(() => {
                jQuery(this.select.nativeElement).dropdown('restore placeholder text');
                jQuery(this.select.nativeElement).dropdown('refresh');
                this.removeOrphans();
                this.isInitialState = true;
            }, 1);
        } else {
            // original way so I don't have to test them all right now
            setTimeout(() => {
                jQuery(this.select.nativeElement).dropdown('restore default text'); // this might also need to be updated in order to prevent looping
                this.isInitialState = true;
            }, 1);
        }
    }

    // run behaviors listed https://semantic-ui.com/modules/dropdown.html#behavior
    runBehavior(behavior, ...args): void {
        jQuery(this.select.nativeElement).dropdown(behavior, ...args);
    }

    // If a select modal is updated, it can orphan selected elements, remove those.
    removeOrphans(): void {
        let options = Array.from(this.select.nativeElement.options)
            .map((opt: HTMLOptionElement) => opt.value)
            .filter((opt) => opt);
        let chiclets = this.select.nativeElement.parentNode.querySelectorAll('a.ui.label');

        chiclets.forEach((c) => {
            if (!options.includes(c.dataset['value'])) {
                c.parentNode.removeChild(c);
            }
        });
    }
}
