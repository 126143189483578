<sds-loader [complete]="finishedLoadingAgents">
    <table style="width: 710px; padding: 16px">
        <h3 style="
                width: 74px;
                height: 24px;
                margin-bottom: 8px;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
            ">
            Install Key
        </h3>
        <tr style="margin-bottom: 16px">
            <td style="padding: 0px">
                <div style="display: flex">
                    <input type="text"
                           readonly
                           value="{{ installKey }}"
                           style="
                            width: 468px;
                            height: 32px;
                            border-radius: 4px;
                            padding: 7px, 12px, 7px, 12px;
                            display: inline-block;
                            flex: 1;
                        " />
                    <itc-button style="display: inline-block; margin-left: 16px"
                                type="secondary"
                                tooltip="Copy to Clipboard"
                                [disabled]="!(installKey?.length > 0)"
                                (onclick)="copyToClipboard(installKey)">
                        Copy
                    </itc-button>
                    <itc-button style="display: inline-block; margin-left: 16px"
                                type="primary"
                                (onclick)="onGenerateInstallKeyButton()">
                        {{
                            installKey == '' || installKey == null
                                ? 'Generate New Key'
                                : 'Regenerate'
                        }}
                    </itc-button>
                </div>
            </td>
        </tr>
        <tr>
            <div style="margin-top: 16px">
                <itc-toggle [(ngModel)]="isDailyScan"
                            (ngModelChange)="EnableRunScanChange()"
                            labelPosition="right"
                            label="Daily Scan"
                            style="
                        display: flex;
                        margin-bottom: 8px;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                    "></itc-toggle>
                <div style="padding-left: 52px">
                    <span class="inline fields">
                        <itc-checkbox *ngFor="let day of weekdays; index as i"
                                      [control]="weekDaysCtrls[i]"
                                      [disabled]="!isDailyScan || !(agentApps?.length > 0)"
                                      labelPosition="after"
                                      [label]="day"
                                      (change)="checkCheckBoxvalue(i)"
                                      style="margin-right: 16px"></itc-checkbox>
                    </span>
                    <div style="margin-top: 16px; margin-bottom: 8px">
                        <h5 style="
                                width: 42px;
                                height: 22px;
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 22px;
                            ">
                            Option
                        </h5>
                    </div>
                    <itc-checkbox [disabled]="
                                  !isVulScanProduct ||
                                  !isDailyScan ||
                                  !weekDaysDropDown.length ||
                                  !(agentApps?.length>
                        0)
                        "
                        labelPosition="after"
                        label="Application Vulnerability Scan (Windows)"
                        style="margin-right: 4px"
                        [(ngModel)]="is3ppScan">
                    </itc-checkbox>
                    <help-button *ngIf="!isVulScanProduct"
                                 title="Requires a VulScan subscription"
                                 text="Contact your account manager to add VulScan to your subscription for enabling the Application Vulnerability Scanning."
                                 [noLink]="true"></help-button>
                </div>
            </div>
            <div style="margin-top: 16px" *ngIf="isCMGRCProduct || isNDProProduct">
                <itc-toggle [(ngModel)]="isDeepFileScan"
                            (ngModelChange)="EnableRunScanChange()"
                            [disabled]="!isDailyScan || !weekDaysDropDown.length"
                            labelPosition="right"
                            label="Deep File Scan"
                            tooltip="Deep File Scan requires Daily Scan to be enabled"
                            ttPosition="bottom-right"
                            [ttHidden]="isDailyScan && weekDaysDropDown.length"
                            style="
                        display: flex;
                        margin-bottom: 8px;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                    "></itc-toggle>
                <div style="padding-left: 52px">
                    <label style="margin-bottom: 8px">Repeat Weekly</label>
                    <div>
                        <sm-select name="selectScheduledDeepScan"
                                   id="selectScheduledDeepScan"
                                   style="display: inline-block; margin-right: 16px"
                                   [disabled]="!isDeepFileScan || !(agentApps?.length > 0)"
                                   [control]="dayScheduledCtrl">
                            <option *ngFor="let day of weekDaysDropDown" [value]="day">
                                {{ day }}
                            </option>
                        </sm-select>
                        <itc-button type="secondary"
                                    [disabled]="!(agentApps?.length > 0)"
                                    (onclick)="loadDeepFileScanSetting(true)">
                            Configure
                        </itc-button>
                    </div>
                </div>
            </div>
        </tr>
        <tr>
            <td>
                <itc-button style="margin-top: 24px"
                            type="secondary"
                            [disabled]="!(agentApps?.length > 0)"
                            (onclick)="showAdvancedOptionsModal()">
                    Advanced Options (Windows)
                </itc-button>
            </td>
        </tr>
        <tr>
            <td>
                <itc-button style="margin-top: 24px"
                            type="primary"
                            [disabled]="!(agentApps?.length > 0)"
                            (onclick)="showConfirmAgentScheduleModal()">
                    Apply
                </itc-button><span style="padding-left:16px" *ngIf="isAdvancedOptionsDirty"><fa-icon icon="exclamation-circle" style="padding-right: 16px; color: var(--rft-orange)"></fa-icon>You have unsaved changes</span>
            </td>
        </tr>

        <div *ngIf="!(agentApps?.length > 0)" style="color: var(--text-critical); margin-top: 8px">
            Scan Schedule can be applied after a Discovery Agent is installed
        </div>
    </table>
    <br />
    <div class="flex-container">
        <div>
            <input type="text"
                   id="textboxQuickFilter"
                   placeholder="Filter..."
                   [(ngModel)]="quickFilter"
                   (ngModelChange)="doQuickFilter()"
                   debounce="420"
                   style="width: 360px" />
        </div>
    </div>
    <div class="bulkTableMinHeightcontainer" style="overflow: hidden; min-height: 300px">
        <table sortable-table
               class="itc-table bulkTable"
               (sorted)="onSortAgents($event)"
               sortColumn="computer"
               sortDirection="asc"
               style="margin-top: 0; overflow: unset">
            <thead>
                <tr>
                    <th>
                        <itc-bulk-actions [menuOptions]="bulkActions"
                                          [selectedCount]="selectedAgents.length"
                                          [totalCount]="agentApps.length"
                                          (checkboxClicked)="checkAllAgents($event)"></itc-bulk-actions>
                    </th>
                    <th sortable-column="status" class="status"></th>
                    <th sortable-column="computer">Device Name</th>
                    <th sortable-column="last-scan">Last Scan</th>
                    <th sortable-column="agentId">Discovery Agent ID</th>
                    <th sortable-column="description">Comment</th>
                    <th sortable-column="label">Label</th>
                    <th sortable-column="updateStatus">Update Status</th>
                    <th sortable-column="last-checkin">Last Check-in</th>
                    <th width="100px"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let app of pagedAgents">
                    <td (click)="checkAgent(app)">
                        <itc-checkbox [checked]="app.Checked"></itc-checkbox>
                    </td>
                    <td class="status {{ app?.Status ? app.Status : 'N/A' }}">
                        <a>
                            <i class="icon circle {{
                                    app?.Status == 'Online'
                                        ? 'green'
                                        : app?.Status == 'Offline'
                                        ? 'red'
                                        : 'grey'
                                }}"
                               data-title="{{
                                    app?.Status == 'Online'
                                        ? 'Online'
                                        : app?.Status == 'Offline'
                                        ? 'Offline'
                                        : 'Status Unknown'
                                }}"></i>
                        </a>
                    </td>
                    <td>{{ app.Computer }}</td>
                    <td>{{ app.HasScans ? (app.LastScan | accountDateTime) : 'NEVER' }}</td>
                    <td>{{ app.Id }}</td>
                    <td>{{ app.Description }}</td>
                    <td>{{ app.Label }}</td>
                    <td>{{ app.UpdateStatus }}</td>
                    <td>{{ app.IsCheckedIn ? (app.LastCheckin | accountDateTime) : 'NEVER' }}</td>
                    <td>
                        <itc-button type="icon"
                                    icon="fa-pencil"
                                    [style.visibility]="app.Status != 'Offline' ? 'visible' : 'hidden'"
                                    tooltip="Edit"
                                    (onclick)="showManageAgentModal(app)"></itc-button>
                        <itc-button type="icon"
                                    icon="fa-trash-can"
                                    tooltip="Delete"
                                    (onclick)="showRemoveAgentModal(app)"></itc-button>
                        <sds-manage-appliance [app]="app"
                                              [displayType]="icon"></sds-manage-appliance>
                    </td>
                </tr>
                <tr *ngIf="pagedAgents?.length == 0">
                    <td colspan="10">No Discovery Agents installed</td>
                </tr>
            </tbody>
        </table>

        <itc-paginator [data]="filteredAgents" (onPage)="pagedAgents = $event"></itc-paginator>
    </div>
</sds-loader>

<sm-modal class="xs" title="Regenerate Install Key" #confirmRegenerateInstallKeyModal>
    <modal-content>
        <p>
            Regenerating the Install Key will invalidate the prior install key. Please be sure to
            update any install scripts to use the new install key.
        </p>
        <p>Do you wish to proceed?</p>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary"
                    (onclick)="confirmRegenerateInstallKeyModal.hide()"
                    [disabled]="isDeleting">
            Cancel
        </itc-button>
        <itc-button type="primary"
                    (onclick)="generateInstallKey(); confirmRegenerateInstallKeyModal.hide()">
            OK
        </itc-button>
    </modal-actions>
</sm-modal>

<sm-modal class="xs" title="Remove Selected" #confirmRemoveAgentModal>
    <modal-content>
        <itc-banner type="error" *ngIf="!selectedAgent.CanRemove">
            <p>
                The following Discovery Agents cannot be removed. Either wait for the Discovery
                Agent to come online or manually uninstall it from the computer:
            </p>
            <p>{{ selectedAgent.Id }}</p>
        </itc-banner>

        <itc-banner type="warning" *ngIf="selectedAgent.CanRemove">
            <p>
                Removing a Discovery Agent does not uninstall it from the computer. You will need to
                uninstall the service on the computer itself. Are you sure you want to remove the
                following Discovery Agents:
            </p>
            <p>{{ selectedAgent.Id }}</p>
            <p>This action cannot be undone.</p>
        </itc-banner>
    </modal-content>
    <modal-actions>
        <itc-button *ngIf="selectedAgent.CanRemove"
                    type="secondary"
                    (onclick)="hideRemoveAgentModal()"
                    [disabled]="isDeleting">
            Cancel
        </itc-button>
        <itc-button *ngIf="selectedAgent.CanRemove"
                    type="primary"
                    (onclick)="removeAgent(selectedAgent)">
            Delete
        </itc-button>
        <itc-button *ngIf="!selectedAgent.CanRemove"
                    type="primary"
                    (onclick)="hideRemoveAgentModal()">
            Ok
        </itc-button>
    </modal-actions>
</sm-modal>

<sm-modal class="xs" title="Remove Selected" #confirmBulkRemoveAgentModal>
    <modal-content>
        <itc-banner type="error" *ngIf="offlineAgents?.length > 0">
            <p>
                The following Discovery Agents cannot be removed. Either wait for the Discovery
                Agent to come online or manually uninstall it from the computer:
            </p>
            <p>{{ offlineAgents }}</p>
        </itc-banner>

        <itc-banner type="warning" *ngIf="offlineAgents?.length == 0">
            <p>
                Removing a Discovery Agent does not uninstall it from the computer. You will need to
                uninstall the service on the computer itself. Are you sure you want to remove the
                following Discovery Agents:
            </p>
            <p>{{ agentsToRemove }}</p>
            <p>This action cannot be undone.</p>
        </itc-banner>
    </modal-content>
    <modal-actions>
        <itc-button *ngIf="offlineAgents?.length == 0"
                    type="secondary"
                    (onclick)="confirmBulkRemoveAgentModal.hide()"
                    [disabled]="isDeleting">
            Cancel
        </itc-button>
        <itc-button *ngIf="offlineAgents?.length == 0" type="primary" (onclick)="removeBulkAgent()">
            Delete
        </itc-button>
        <itc-button *ngIf="offlineAgents?.length > 0"
                    type="primary"
                    (onclick)="confirmBulkRemoveAgentModal.hide()">
            Ok
        </itc-button>
    </modal-actions>
</sm-modal>

<sm-modal class="xs" title="Discovery Agent Run Now Confirmation" #confirmAgentRunNowModal>
    <modal-content>{{ runNowModalText }}</modal-content>
    <modal-actions>
        <itc-button type="secondary" (onclick)="hideAgentRunNowModal()">Cancel</itc-button>
        <itc-button type="primary" (onclick)="agentRunNow()">Yes</itc-button>
    </modal-actions>
</sm-modal>

<sm-modal class="xs" title="Cancel Scans Confirmation" #confirmCancelScansModal>
    <modal-content>Cancel All Running Scans?</modal-content>
    <modal-actions>
        <itc-button type="secondary" (onclick)="confirmCancelScansModal.hide()">Cancel</itc-button>
        <itc-button type="primary" (onclick)="cancelScans()">Yes</itc-button>
    </modal-actions>
</sm-modal>

<sm-modal class="xs" title="Discovery Agent Update Confirmation" #confirmAgentUpdateModal>
    <modal-content>
        Issuing a command to update the data collector will cancel all running scans. Do you wish to
        update now?
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" (onclick)="confirmAgentUpdateModal.hide()">Cancel</itc-button>
        <itc-button type="primary" (onclick)="agentBulkUpdate()">Yes</itc-button>
    </modal-actions>
</sm-modal>

<sm-modal class="xs" title="Confirm Update" #confirmAgentScheduleModal>
    <modal-content>
        <div>{{ modalContent }}</div>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary"
                    (onclick)="confirmAgentScheduleModal.hide()"
                    [disabled]="savingAgentSchedule">
            Cancel
        </itc-button>
        <itc-button type="primary" (onclick)="onApplySchedule()" [loading]="savingAgentSchedule">
            OK
        </itc-button>
    </modal-actions>
</sm-modal>

<sm-modal class="manage-modal sm"
          title="Manage Discovery Agent {{ selectedAgent.Id }}"
          #manageAgentModal>
    <modal-content>
        <sds-loader [complete]="!manageRunning">
            <div *ngIf="manageType == ''">
                <table class="ui very basic collapsing table">
                    <tbody>
                        <tr>
                            <td style="width: 100%">Update</td>
                            <td>
                                <itc-button type="primary" (onclick)="runManage('Update')">
                                    Run
                                </itc-button>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 100%">Set Auto-Update</td>
                            <td>
                                <itc-button type="primary" (onclick)="runManage('Set Auto-Update')">
                                    Run
                                </itc-button>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 100%">Download Audit</td>
                            <td>
                                <itc-button type="primary" (onclick)="runManage('Download Audit')">
                                    Run
                                </itc-button>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 100%">Download Logs</td>
                            <td>
                                <itc-button type="primary" (onclick)="runManage('Download Logs')">
                                    Run
                                </itc-button>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 100%">Update Comment</td>
                            <td>
                                <itc-button type="primary" (onclick)="runManage('Update Comment')">
                                    Run
                                </itc-button>
                            </td>
                        </tr>
                        <tr style="border: none">
                            <td style="width: 100%">Update Label</td>
                            <td>
                                <itc-button type="primary" (onclick)="runManage('Update Label')">
                                    Run
                                </itc-button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div *ngIf="manageType == 'Update' || manageType == 'Set Auto-Update'">
                {{ modalContent }}
            </div>
            <div *ngIf="manageType == 'Update Comment'">
                <div>Add description of data collector.</div>
                <input type="text"
                       [formControl]="commentCtrl"
                       maxlength="100"
                       style="margin-bottom: 0" />
            </div>
            <div *ngIf="manageType == 'Update Label'">
                <div>Add label of data collector.</div>
                <input type="text"
                       [formControl]="labelCtrl"
                       maxlength="100"
                       style="margin-bottom: 0" />
            </div>
        </sds-loader>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary"
                    *ngIf="manageType != ''"
                    (onclick)="showOverview()"
                    [disabled]="manageRunning">
            Back
        </itc-button>
        <itc-button type="secondary" (onclick)="manageAgentModal.hide()" [disabled]="manageRunning">
            Close
        </itc-button>
        <itc-button type="primary"
                    (onclick)="doManage()"
                    *ngIf="
                manageType == 'Update' ||
                manageType == 'Set Auto-Update' ||
                manageType == 'Update Comment' ||
                manageType == 'Update Label'
            "
                    [disabled]="manageRunning">
            OK
        </itc-button>
    </modal-actions>
</sm-modal>

<sm-modal class="xs" title="Discovery Agents Comment" #editDiscoveryAgentCommentBulkModal>
    <modal-content>
        <div>Add comment for Discovery Agents.</div>
        <input type="text"
               [formControl]="bulkCommentCtrl"
               maxlength="100"
               style="margin-bottom: 0" />
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" (onclick)="editDiscoveryAgentCommentBulkModal.hide()">
            Cancel
        </itc-button>
        <itc-button type="primary" (onclick)="saveBulkComment()">Save</itc-button>
    </modal-actions>
</sm-modal>

<sm-modal class="xs" title="Discovery Agents Label" #editDiscoveryAgentLabelBulkModal>
    <modal-content>
        <div>Add label for Discovery Agents.</div>
        <input type="text" [formControl]="bulkLabelCtrl" maxlength="100" style="margin-bottom: 0" />
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" (onclick)="editDiscoveryAgentLabelBulkModal.hide()">
            Cancel
        </itc-button>
        <itc-button type="primary" (onclick)="saveBulkLabel()">Save</itc-button>
    </modal-actions>
</sm-modal>

<sm-modal class="sm" title="Deep File Scan Settings" #deepFileScanSettingModal>
    <modal-content>
        <form [formGroup]="settingScanForm">
            <div>
                <label>Perform Computer Drive Encryption Detection</label>
                <div class="grouped fields">
                    <itc-checkbox formControlName="driveEncryptCheckBox"
                                  label="Enable"></itc-checkbox>
                </div>
            </div>
            <br />
            <div>
                <Label style="display: block">Sensitive Data Scan Types</Label>
                <div class="grouped fields">
                    <div class="field">
                        <div>
                            <itc-checkbox formControlName="hipaaCheckBox"
                                          label="ePHi (HIPAA)"></itc-checkbox>
                        </div>
                    </div>
                    <div class="field">
                        <div>
                            <itc-checkbox formControlName="pciCheckBox"
                                          label="Cardholder Data (PCI)"></itc-checkbox>
                        </div>
                    </div>
                    <div class="field">
                        <div>
                            <itc-checkbox formControlName="perDataCheckBox"
                                          label="GDPR Personal Data (PD)"></itc-checkbox>
                        </div>
                    </div>
                    <div class="field">
                        <div>
                            <itc-checkbox formControlName="perIdentInforCheckBox"
                                          label="Personally Identifiable Information (PII)"></itc-checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div>
                <label>Sensitive Data Scan Options</label>
                <div class="grouped fields">
                    <div class="field">
                        <div>
                            <itc-checkbox formControlName="scanningZipCheckBox"
                                          [disabled]="sensitiveDataStatus"
                                          label="Include the scanning of ZIP files"></itc-checkbox>
                        </div>
                    </div>
                    <div class="field">
                        <div>
                            <itc-checkbox formControlName="scanningPdfCheckBox"
                                          [disabled]="sensitiveDataStatus"
                                          label="Include the scanning of PDF files"></itc-checkbox>
                        </div>
                    </div>
                    <div style="margin-top: -10px">
                        <label style="display: inline-block; margin-left: 50px">
                            PDF timeout
                            <input type="number"
                                   formControlName="pdfTimeOutValue"
                                   min="1"
                                   max="60"
                                   style="width: 50px"
                                   [attr.disabled]="
                                    sensitiveDataStatus ||
                                    !settingScanForm.get('scanningPdfCheckBox').value
                                        ? true
                                        : null
                                " />
                            minutes (min=1,max=60)
                        </label>
                    </div>
                </div>
            </div>
        </form>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" (onclick)="deepFileScanSettingModal.hide()">Cancel</itc-button>
        <itc-button type="primary"
                    [loading]="savingAgentSchedule"
                    [disabled]="!settingScanForm.get('pdfTimeOutValue').valid"
                    (onclick)="updateDeepFileScanAgentFlags()">
            OK
        </itc-button>
    </modal-actions>
</sm-modal>

<sm-modal class="sm" title="Weekly Scan Day List Change" #deepfilesScanDayGuard>
    <modal-content>
        <p>
            The change in the Daily Scan Schedule will remove
            <b>{{ selectedDay }}</b>
            from the days available to run a Deep scan.
        </p>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" (onclick)="cancelDayListChange()">Cancel</itc-button>
        <itc-button type="primary" (onclick)="confirmDayListChange()">OK</itc-button>
    </modal-actions>
</sm-modal>
<sm-modal class="sm" title="Advanced Options (Windows)"
          #advancedOptionsModal>
    <modal-content>

        <div>
            <div class="inline field">
                <div>
                    <itc-checkbox id="checkboxUrlTesting"
                                  [(ngModel)]="modalAdvancedOptions.urlTesting"
                                  (ngModelChange)="urlOptionChanged()"
                                  label="URL Testing"></itc-checkbox>
                </div>
            </div>

            <div style="padding-left:20px">
                <div class="inline field">
                    <div>
                        <itc-checkbox id="checkboxTestEntertainment"
                                      [(ngModel)]="modalAdvancedOptions.testEntertainment"
                                      (ngModelChange)="urlSubOptionChanged()"
                                      label="Entertainment"></itc-checkbox>
                    </div>
                </div>
                <div class="inline field">
                    <div>
                        <itc-checkbox id="checkboxTestPornography"
                                      [(ngModel)]="modalAdvancedOptions.testPornography"
                                      (ngModelChange)="urlSubOptionChanged()"
                                      label="Pornography"></itc-checkbox>
                    </div>
                </div>
                <div class="inline field">
                    <div>
                        <itc-checkbox id="checkboxTestShareware"
                                      [(ngModel)]="modalAdvancedOptions.testShareware"
                                      (ngModelChange)="urlSubOptionChanged()"
                                      label="Shareware"></itc-checkbox>
                    </div>
                </div>
                <div class="inline field">
                    <div>
                        <itc-checkbox id="checkboxTestSocialMedia"
                                      [(ngModel)]="modalAdvancedOptions.testSocialMedia"
                                      (ngModelChange)="urlSubOptionChanged()"
                                      label="Social Media"></itc-checkbox>
                    </div>
                </div>
                <div class="inline field">
                    <div>
                        <itc-checkbox id="checkboxTestWarez"
                                      [(ngModel)]="modalAdvancedOptions.testWarez"
                                      (ngModelChange)="urlSubOptionChanged()"
                                      label="Warez"></itc-checkbox>
                    </div>
                </div>
                <div class="inline field">
                    <div>
                        <itc-checkbox id="checkboxTestWebMail"
                                      [(ngModel)]="modalAdvancedOptions.testWebMail"
                                      (ngModelChange)="urlSubOptionChanged()"
                                      label="Web Mail"></itc-checkbox>
                    </div>
                </div>

            </div>
        </div>
        <div>
            <div class="inline field">
                <div>
                    <itc-checkbox id="checkboxPortTesting"
                                  [(ngModel)]="modalAdvancedOptions.portTesting"
                                  label="Port Testing"></itc-checkbox>
                </div>
            </div>
            <div class="inline field">
                <div>
                    <itc-checkbox id="checkboxWifiData"
                                  [(ngModel)]="modalAdvancedOptions.wifiData"
                                  label="Wifi Data"></itc-checkbox>
                </div>
            </div>
            <div class="inline field">
                <div>
                    <itc-checkbox id="checkboxPolicies"
                                  [(ngModel)]="modalAdvancedOptions.policies"
                                  label="Policies (RSOP)"></itc-checkbox>
                </div>
            </div>
            <div class="inline field">
                <div>
                    <itc-checkbox id="checkboxScreenSaverSettings"
                                  [(ngModel)]="modalAdvancedOptions.screenSaverSettings"
                                  label="Screen Saving Settings"></itc-checkbox>
                </div>
            </div>
            <div class="inline field">
                <div>
                    <itc-checkbox id="checkboxUsbDetection"
                                  [(ngModel)]="modalAdvancedOptions.usbDetection"
                                  label="USB Detection"></itc-checkbox>
                </div>
            </div>
            <div class="field">
                <div>
                    <itc-checkbox id="checkboxInternetAccessibility"
                                  [(ngModel)]="modalAdvancedOptions.internetAccessibility"
                                  label="Internet Accessibility"></itc-checkbox>
                </div>
            </div>
            <div class="inline field">
                <div>
                    <itc-checkbox id="checkboxLoginDetails"
                                  [(ngModel)]="modalAdvancedOptions.loginDetails"
                                  label="Login Details (LDF)"></itc-checkbox>
                </div>
            </div>
        </div>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" (onclick)="cancelAdvancedOptionsModalAction()">Cancel</itc-button>
        <itc-button type="primary" (onclick)="closeAdvancedOptionsModalAction()">Confirm</itc-button>
    </modal-actions>

</sm-modal>
