<h4>Computer Info</h4>
<table class="ui table">
    <tbody>
        <tr>
            <td><b>Computer Name</b></td>
            <td>{{ diag?.ComputerDomain }}\{{ diag?.ComputerName }}</td>
        </tr>
        <tr>
            <td><b>IP Address</b></td>
            <td>{{ diag?.IPAddress }}</td>
        </tr>
    </tbody>
</table>

<h4>Profiles</h4>
<p>
    The following lists all profiles currently on {{ diag?.ComputerName }}. The highlighted profiles
    were identified as newly created.
</p>
<table class="ui table">
    <thead>
        <tr>
            <th>Profile</th>
            <th>Last Used</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let profile of diag?.Children" [class.highlight]="profile.IsNew == 'true'">
            <td>{{ profile.UserDisplayName }} ({{ profile.Username }})</td>
            <td>
                {{ profile.LastUseTime ? (fixDate(profile.LastUseTime) | date : 'medium') : 'N/A' }}
            </td>
        </tr>
        <!--
        <tr ng-if="diag.Children.length > diagTableLimit" ng-click="increaseLimit()">
            <td colspan="3" style="text-align: center">
                <a href="#"><i class="plus icon"></i>{{diag.Children.length - diagTableLimit}} More...</a>
            </td>
        </tr>
        -->
    </tbody>
</table>
