import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'sds-site-details',
    templateUrl: './site-details.component.html',
    styleUrls: ['./site-details.component.scss'],
})
export class SiteDetailsComponent implements OnInit {
    constructor() {}

    @Input() site: any;
    openApp = 0;
    tabPage = 0;

    ngOnInit() {}

    isEmpty(obj) {
        for (var item in obj) {
            return false;
        }
        return true;
    }

    tab_page(dir) {
        if (dir === 'up') {
            this.tabPage++;
        } else {
            this.tabPage--;
        }
        this.openApp = this.tabPage;
    }
}
