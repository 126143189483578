import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { AuthHttp } from 'app/core/auth';
import { ScanDataApplication } from './scan-data-application.model';
import { ScanDataComputer } from './scan-data-computer.model';
import { ScanDataDatabase } from './scan-data-database.model';
import { ScanDataDNSEntry } from './scan-data-dns-entry.model';
import { ScanDataExchange } from './scan-data-exchange.model';
import { ScanDataNonADDevice } from './scan-data-nonad-device.model';
import { ScanDataIpRange } from './scan-data-iprange.model';
import { ScanDataPrinter } from './scan-data-printer.model';
import { ScanDataSecurityGroup } from './scan-data-security-group.model';
import { ScanDataSSID } from './scan-data-ssid.model';
import { ScanDataUser } from './scan-data-user.model';
import { ScanDataSharePoint } from './scan-data-sharepoint.model';
import { ScanDataOneDrive } from './scan-data-onedrive.model';
import { ScanDataOutlook } from './scan-data-outlook.model';
import { ScanDataTeams } from './scan-data-teams.model';
import { ScanDataAadDomain } from './scan-data-aad-domain.model';
import { ScanDataAadSupportedServices } from './scan-data-aad-supported-services.model';
import { ScanDataAadProxyAddress } from './scan-data-aad-proxy-address.model';
import { ScanDataAadServicePlan } from './scan-data-aad-service-plan.model';
import { ScanDataAadSubscribedSku } from './scan-data-aad-sunscribed-sku.model';
import { ScanDataAadApplication } from './scan-data-aad-application.model';
import { ScanDataAadGroupsCloudOnly } from './scan-data-aad-group-cloud-only';
import { ScanDataAadWebUrl } from './scan-data-aad-web-url.model copy';
import { ScanDataAadUser } from './scan-data-aad-user.model';
import { ScanDataAadOrganizationContact } from './scan-data-aad-organization-contact.model';
import { ScanDataAadGroupsOnPremiseSynced } from './scan-data-aad-group-on-premise-sync';
import { ScanDataAadGuestUser } from './scan-data-aad-guest-user.model';
import { ScanDataAadDevice } from './scan-data-aad-device.model';
import { lastValueFrom } from 'rxjs';
import { SiteScanItem } from 'app/sites/shared/site-scan-item.model';

@Injectable({ providedIn: 'root' })
export class ScanDataService {
    private apiUrl: string;

    constructor(private http: AuthHttp) {
        this.apiUrl = environment.apiUrl;
    }

    getApplications(siteId: number, assessmentId: number = null): Promise<ScanDataApplication[]> {
        const url: string =
            this.apiUrl +
            `ScanData/${siteId}/Applications` +
            this.getAssessmentParam(assessmentId, '?');
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as ScanDataApplication[]);
    }

    getApplicationDetail(
        siteId: number,
        uid: string,
        assessmentId: number = null
    ): Promise<ScanDataApplication> {
        const url: string =
            this.apiUrl +
            `ScanData/${siteId}/Applications/${uid}` +
            this.getAssessmentParam(assessmentId, '?');
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as ScanDataApplication);
    }

    getAvailableSmartTagsForItemType(siteId: number, type: string): Promise<any> {
        const url: string = this.apiUrl + `Scandata/${siteId}/${type}s/SmartTags`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any);
    }

    getComputers(
        siteId: number,
        includeSmartTags: boolean = false,
        assessmentId: number = null
    ): Promise<ScanDataComputer[]> {
        const url: string =
            this.apiUrl +
            `ScanData/${siteId}/Computers?includeSmartTags=${includeSmartTags}` +
            this.getAssessmentParam(assessmentId, '&');
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as ScanDataComputer[]);
    }

    getComputerById(
        siteId: number,
        computerId: number,
        assessmentId: number = null
    ): Promise<ScanDataComputer> {
        const url: string =
            this.apiUrl +
            `ScanData/${siteId}/Computers/${computerId}` +
            this.getAssessmentParam(assessmentId, '?');
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as ScanDataComputer);
    }

    getComputerIdFromUID(siteId: number, uid: string): Promise<ScanDataComputer> {
        if (uid) {
            var parsedUID = JSON.parse(uid);

            const url: string =
                this.apiUrl +
                `ScanData/${siteId}/Domain/${parsedUID.Computer.Domain}/Users/${parsedUID.Computer.Name}`;
            return this.http
                .get(url)
                .toPromise()
                .then((res) => res as ScanDataComputer);
        }
    }

    getDatabases(siteId: number, assessmentId: number = null): Promise<ScanDataDatabase[]> {
        const url: string =
            this.apiUrl +
            `ScanData/${siteId}/Databases` +
            this.getAssessmentParam(assessmentId, '?');
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as ScanDataDatabase[]);
    }

    getDatabaseDetail(
        siteId: number,
        uid: string,
        assessmentId: number = null
    ): Promise<ScanDataDatabase> {
        const url: string =
            this.apiUrl +
            `ScanData/${siteId}/Databases/${uid}` +
            this.getAssessmentParam(assessmentId, '?');
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as ScanDataDatabase);
    }

    getDNSEntries(siteId: number, assessmentId: number = null): Promise<ScanDataDNSEntry[]> {
        const url: string =
            this.apiUrl + `ScanData/${siteId}/DNSs` + this.getAssessmentParam(assessmentId, '?');
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as ScanDataDNSEntry[]);
    }

    getDNSEntry(
        siteId: number,
        uid: string,
        assessmentId: number = null
    ): Promise<ScanDataDNSEntry> {
        const url: string =
            this.apiUrl +
            `ScanData/${siteId}/DNSs/${uid}` +
            this.getAssessmentParam(assessmentId, '?');
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as ScanDataDNSEntry);
    }

    getDomainInfo(siteId: number, assessmentId: number = null): Promise<any> {
        const url: string =
            this.apiUrl + `Scandata/${siteId}/Domain` + this.getAssessmentParam(assessmentId, '?');
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getCyberhawkDomainInfo(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `Cyberhawk/Scandata/${siteId}/Domain`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getManagementPlans(siteId: number, assessmentId: number = null): Promise<any> {
        const url: string =
            this.apiUrl +
            `Scandata/${siteId}/ManagementPlans` +
            this.getAssessmentParam(assessmentId, '?');
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getCyberhawkManagementPlans(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `Scandata/${siteId}/CyberhawkManagementPlans`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getIndocManagementPlans(siteId: number): Promise<any> {
        console.log('this.apiUrl: ' + this.apiUrl);
        const url: string = this.apiUrl + `Scandata/${siteId}/IndocManagementPlans`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getNonADDevices(siteId: number, assessmentId: number = null): Promise<ScanDataNonADDevice[]> {
        const url: string =
            this.apiUrl +
            `ScanData/${siteId}/NonAD-Devices` +
            this.getAssessmentParam(assessmentId, '?');
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as ScanDataNonADDevice[]);
    }

    getNonADDeviceById(
        siteId: number,
        deviceId: string,
        assessmentId: number = null
    ): Promise<ScanDataNonADDevice> {
        const url: string =
            this.apiUrl +
            `Scandata/${siteId}/NonAD-Devices/${deviceId}` +
            this.getAssessmentParam(assessmentId, '?');
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as ScanDataNonADDevice);
    }

    getExchanges(siteId: number): Promise<ScanDataExchange[]> {
        const url: string = this.apiUrl + `ScanData/${siteId}/Exchanges`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as ScanDataExchange[]);
    }

    getPatchOp(op: string, path: string, value: any): PatchOp {
        return {
            op: op,
            path: path,
            value: value,
        } as PatchOp;
    }
    getSharePointData(siteId: number, assessmentId: number = null): Promise<ScanDataSharePoint[]> {
        const url: string =
            this.apiUrl +
            `Scandata/${siteId}/SharePoint` +
            this.getAssessmentParam(assessmentId, '?');
        return lastValueFrom(this.http.get(url)).then((res) => res as ScanDataSharePoint[]);
    }
    getOneDriveData(
        siteId: number,
        assessmentId: number = null,
        dateRange: number = null
    ): Promise<ScanDataOneDrive[]> {
        const url: string =
            this.apiUrl +
            `Scandata/${siteId}/OneDrive` +
            this.getAssessmentParam(assessmentId, '?') +
            this.getdateRangeParam(dateRange, '&');
        return lastValueFrom(this.http.get(url)).then((res) => res as ScanDataOneDrive[]);
    }
    getOutlookData(
        siteId: number,
        assessmentId: number = null,
        dateRange: number = null
    ): Promise<ScanDataOutlook[]> {
        const url: string =
            this.apiUrl +
            `Scandata/${siteId}/Outlook` +
            this.getAssessmentParam(assessmentId, '?') +
            this.getdateRangeParam(dateRange, '&');
        return lastValueFrom(this.http.get(url)).then((res) => res as ScanDataOutlook[]);
    }
    getTeamsData(siteId: number, assessmentId: number = null): Promise<ScanDataTeams[]> {
        const url: string =
            this.apiUrl + `Scandata/${siteId}/Teams` + this.getAssessmentParam(assessmentId, '?');
        return lastValueFrom(this.http.get(url)).then((res) => res as ScanDataTeams[]);
    }
    getAadDomains(siteId: number, assessmentId: number = null): Promise<ScanDataAadDomain[]> {
        const url: string =
            this.apiUrl +
            `Scandata/${siteId}/AzureAd/Domains` +
            this.getAssessmentParam(assessmentId, '?');
        return lastValueFrom(this.http.get(url)).then((res) => res as ScanDataAadDomain[]);
    }
    getAadSupportedServices(
        siteId: number,
        assessmentId: number = null
    ): Promise<ScanDataAadSupportedServices[]> {
        const url: string =
            this.apiUrl +
            `Scandata/${siteId}/AzureAd/SupportedServices` +
            this.getAssessmentParam(assessmentId, '?');
        return lastValueFrom(this.http.get(url)).then(
            (res) => res as ScanDataAadSupportedServices[]
        );
    }
    getAadApplications(
        siteId: number,
        assessmentId: number = null
    ): Promise<ScanDataAadApplication[]> {
        const url: string =
            this.apiUrl +
            `Scandata/${siteId}/AzureAd/Applications` +
            this.getAssessmentParam(assessmentId, '?');
        return lastValueFrom(this.http.get(url)).then((res) => res as ScanDataAadApplication[]);
    }
    getAadGroupsCloudOnly(
        siteId: number,
        assessmentId: number = null
    ): Promise<ScanDataAadGroupsCloudOnly[]> {
        const url: string =
            this.apiUrl +
            `Scandata/${siteId}/AzureAd/GroupsCloudOnly` +
            this.getAssessmentParam(assessmentId, '?');
        return lastValueFrom(this.http.get(url)).then((res) => res as ScanDataAadGroupsCloudOnly[]);
    }
    getAadGroupsOnPremiseSynced(
        siteId: number,
        assessmentId: number = null
    ): Promise<ScanDataAadGroupsOnPremiseSynced[]> {
        const url: string =
            this.apiUrl +
            `Scandata/${siteId}/AzureAd/GroupsOnPremiseSynced` +
            this.getAssessmentParam(assessmentId, '?');
        return lastValueFrom(this.http.get(url)).then(
            (res) => res as ScanDataAadGroupsOnPremiseSynced[]
        );
    }
    getAadWebUrls(siteId: number, assessmentId: number = null): Promise<ScanDataAadWebUrl[]> {
        const url: string =
            this.apiUrl +
            `Scandata/${siteId}/AzureAd/WebUrls` +
            this.getAssessmentParam(assessmentId, '?');
        return lastValueFrom(this.http.get(url)).then((res) => res as ScanDataAadWebUrl[]);
    }
    getAadOrganizationContacts(
        siteId: number,
        assessmentId: number = null
    ): Promise<ScanDataAadOrganizationContact[]> {
        const url: string =
            this.apiUrl +
            `Scandata/${siteId}/AzureAd/OrganizationContacts` +
            this.getAssessmentParam(assessmentId, '?');
        return lastValueFrom(this.http.get(url)).then(
            (res) => res as ScanDataAadOrganizationContact[]
        );
    }
    getAadProxyAddresses(
        siteId: number,
        assessmentId: number = null
    ): Promise<ScanDataAadProxyAddress[]> {
        const url: string =
            this.apiUrl +
            `Scandata/${siteId}/AzureAd/ProxyAddresses` +
            this.getAssessmentParam(assessmentId, '?');
        return lastValueFrom(this.http.get(url)).then((res) => res as ScanDataAadProxyAddress[]);
    }
    getAadServicePlans(
        siteId: number,
        assessmentId: number = null
    ): Promise<ScanDataAadServicePlan[]> {
        const url: string =
            this.apiUrl +
            `Scandata/${siteId}/AzureAd/ServicePlans` +
            this.getAssessmentParam(assessmentId, '?');
        return lastValueFrom(this.http.get(url)).then((res) => res as ScanDataAadServicePlan[]);
    }
    getAadSubscribedSkus(
        siteId: number,
        assessmentId: number = null
    ): Promise<ScanDataAadSubscribedSku[]> {
        const url: string =
            this.apiUrl +
            `Scandata/${siteId}/AzureAd/SubscribedSkus` +
            this.getAssessmentParam(assessmentId, '?');
        return lastValueFrom(this.http.get(url)).then((res) => res as ScanDataAadSubscribedSku[]);
    }
    getAadUsers(siteId: number, assessmentId: number = null): Promise<ScanDataAadUser[]> {
        const url: string =
            this.apiUrl +
            `Scandata/${siteId}/AzureAd/Users` +
            this.getAssessmentParam(assessmentId, '?');
        return lastValueFrom(this.http.get(url)).then((res) => res as ScanDataAadUser[]);
    }
    getAadGuestUsers(siteId: number, assessmentId: number = null): Promise<ScanDataAadGuestUser[]> {
        const url: string =
            this.apiUrl +
            `Scandata/${siteId}/AzureAd/GuestUsers` +
            this.getAssessmentParam(assessmentId, '?');
        return lastValueFrom(this.http.get(url)).then((res) => res as ScanDataAadGuestUser[]);
    }
    getAadDevices(siteId: number, assessmentId: number = null): Promise<ScanDataAadDevice[]> {
        const url: string =
            this.apiUrl +
            `Scandata/${siteId}/AzureAd/Devices` +
            this.getAssessmentParam(assessmentId, '?');
        return lastValueFrom(this.http.get(url)).then((res) => res as ScanDataAadDevice[]);
    }
    getPrinters(
        siteId: number,
        includeSmartTags: boolean = false,
        assessmentId: number = null
    ): Promise<ScanDataPrinter[]> {
        const url: string =
            this.apiUrl +
            `Scandata/${siteId}/Printers?includeSmartTags=${includeSmartTags}` +
            this.getAssessmentParam(assessmentId, '&');
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as ScanDataPrinter[]);
    }

    getPrinterDetail(
        siteId: number,
        printerId: string,
        assessmentId: number = null
    ): Promise<ScanDataPrinter> {
        const url: string =
            this.apiUrl +
            `Scandata/${siteId}/Printers/${printerId}` +
            this.getAssessmentParam(assessmentId, '?');
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as ScanDataPrinter);
    }

    getScannedCategories(siteId: number, assessmentId: number = null): Promise<any> {
        const url: string =
            this.apiUrl +
            `Scandata/${siteId}/ScannedCategories` +
            this.getAssessmentParam(assessmentId, '?');
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getSecurityGroups(
        siteId: number,
        assessmentId: number = null
    ): Promise<ScanDataSecurityGroup[]> {
        const url: string =
            this.apiUrl +
            `Scandata/${siteId}/SecurityGroups` +
            this.getAssessmentParam(assessmentId, '?');
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as ScanDataSecurityGroup[]);
    }

    getSecurityGroupById(
        siteId: number,
        groupId: string,
        assessmentId: number = null
    ): Promise<ScanDataSecurityGroup> {
        const url: string =
            this.apiUrl +
            `Scandata/${siteId}/SecurityGroups/${groupId}` +
            this.getAssessmentParam(assessmentId, '?');
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as ScanDataSecurityGroup);
    }

    getSSIDs(
        siteId: number,
        includeSmartTags: boolean = false,
        assessmentId: number = null
    ): Promise<ScanDataSSID[]> {
        const url: string =
            this.apiUrl +
            `Scandata/${siteId}/SSIDs?includeSmartTags=${includeSmartTags}` +
            this.getAssessmentParam(assessmentId, '&');
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as ScanDataSSID[]);
    }

    getUsers(
        siteId: number,
        includeSmartTags: boolean = false,
        assessmentId: number = null
    ): Promise<ScanDataUser[]> {
        const url: string =
            this.apiUrl +
            `Scandata/${siteId}/Users?includeSmartTags=${includeSmartTags}` +
            this.getAssessmentParam(assessmentId, '&');
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as ScanDataUser[]);
    }

    getUserById(
        siteId: number,
        userId: string,
        assessmentId: number = null
    ): Promise<ScanDataUser> {
        const url: string =
            this.apiUrl +
            `Scandata/${siteId}/Users/${userId}` +
            this.getAssessmentParam(assessmentId, '?');
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as ScanDataUser);
    }

    getTaggedIpRanges(
        siteId: number,
        includeSmartTags: boolean = false
    ): Promise<ScanDataIpRange[]> {
        const url: string =
            this.apiUrl + `Scandata/${siteId}/IpRanges?includeSmartTags=${includeSmartTags}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as ScanDataIpRange[]);
    }

    patchItem(
        siteId: number,
        type: string,
        itemId: string,
        patch: PatchOp[],
        assessmentId: number = null
    ): Promise<any> {
        const url: string =
            this.apiUrl +
            `Scandata/${siteId}/${type}s/${itemId}` +
            this.getAssessmentParam(assessmentId, '?');
        return this.http
            .patch(url, patch)
            .toPromise()
            .then((res) => res as any);
    }

    searchScanDataItems(siteId: number, key: string, assessmentId: number = null) {
        const url: string =
            this.apiUrl +
            `Scandata/${siteId}/Search?key=${encodeURIComponent(key)}` +
            this.getAssessmentParam(assessmentId, '&');
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any[]);
    }

    getAssessmentParam(assessmentId: number, queryChar: '?' | '&' = '?'): string {
        return assessmentId != null ? queryChar + `assessmentId=${assessmentId}` : '';
    }
    getdateRangeParam(dateRange: number, queryChar: '?' | '&' = '?'): string {
        return dateRange != null ? queryChar + `dateRange=${dateRange}` : '';
    }

    getEmptyStateText(dataSet: any[], mergeInProgress: boolean, dataName: string) {
        let emptyStateText = '';
        if (dataSet?.length) {
            emptyStateText = `No ${dataName} items were found matching the given criteria.`;
        } else {
            emptyStateText = 'There is no scan data in the current assessment. ';
            if (mergeInProgress) {
                emptyStateText += 'Data is being merged and will be available shortly.';
            } else {
                emptyStateText +=
                    'Please use the Data Collection page(s) to collect and upload scan data.';
            }
        }
        return emptyStateText;
    }

    combineScanItemsByGuid(scanItems: SiteScanItem[]): SiteScanItem[] {
        let rValue: SiteScanItem[] = [];
        let guidItemMap: Map<string, SiteScanItem[]> = this.getMapOfGuidAndScanItems(scanItems);
        guidItemMap.forEach((groupedItems) => {
            let status: string = groupedItems[0].Status;
            let firstStartedScanIndex: number = 0;
            let lastFinishedScanIndex: number = 0;
            let targets: string[] = [];
            if (groupedItems.length > 1) {
                for (let i = 0; i < groupedItems.length; i++) {
                    //set the status
                    if (status.toLowerCase().includes('running')) {
                        status = 'Running';
                    } else {
                        status = groupedItems[i].Status;
                    }

                    //set index for first started scan
                    if (
                        groupedItems[i].StartDateTime <
                        groupedItems[firstStartedScanIndex].StartDateTime
                    ) {
                        firstStartedScanIndex = i;
                    }

                    //set index for last finished scan
                    if (
                        groupedItems[i].EndDateTime >
                        groupedItems[lastFinishedScanIndex].EndDateTime
                    ) {
                        lastFinishedScanIndex = i;
                    }

                    targets.push(groupedItems[i].Target);
                }
            }

            rValue.push({
                Id: -1,
                Guid: groupedItems[0].Guid,
                Type: groupedItems[0].Type,
                ScannerName: groupedItems[0].ScannerName,
                StartDate: groupedItems[firstStartedScanIndex].StartDate,
                EndDate: groupedItems[lastFinishedScanIndex].EndDate,
                StartDateTime: groupedItems[firstStartedScanIndex].StartDateTime,
                EndDateTime: groupedItems[lastFinishedScanIndex].EndDateTime,
                Target: targets.length > 0 ? targets.join(', ') : groupedItems[0].Target,
                Status: status,
                LastScanDetails: '',
            });
        });

        return rValue;
    }

    getMapOfGuidAndScanItems(scanItems: SiteScanItem[]): Map<string, SiteScanItem[]> {
        let rValue: Map<string, SiteScanItem[]> = new Map<string, SiteScanItem[]>();
        scanItems.forEach((item) => {
            if (!rValue.has(item.Guid)) {
                rValue.set(item.Guid, []);
            }
            rValue.get(item.Guid).push(item);
        });

        return rValue;
    }
}

export class PatchOp {
    public op: string;
    public path: string;
    public value: any;
}
