import { CommonModule, NgClass } from '@angular/common';
import {
    Component,
    Input,
    ChangeDetectionStrategy,
    Output,
    ViewContainerRef,
    EventEmitter,
    OnInit,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';

import { Subject, timer } from 'rxjs';
import { debounce } from 'rxjs/operators';

/** code based off of ng-semantic tweaked for ITC */

/**
 * Implementation of Input element
 *
 * @link http://semantic-ui.com/elements/input.html
 *
 * @example
 * <sm-input icon="dollar" type="number" [(model)]="model" class="right fluid" placeholder="Enter a sum..."></sm-input>
 */
@Component({
    selector: 'itc-input',
    standalone: true,
    imports: [CommonModule, FormsModule, ReactiveFormsModule],
    template: `
        <div class="field" [ngClass]="{ error: !control.valid && control.dirty && isInsideForm }">
            <label *ngIf="label && isInsideForm">{{ label }}</label>
            <div
                class="itc input {{ class }}"
                [ngClass]="{
                    icon: icon,
                    disabled: disabled,
                    error: !control.valid && control.dirty && !isInsideForm
                }">
                <label *ngIf="label && !isInsideForm" class="ui label">{{ label }}</label>
                <input
                    [type]="type"
                    [formControl]="control"
                    [maxlength]="maxLength ? maxLength : ''"
                    (input)="onModelChange(input.value)"
                    [id]="inputId ? inputId : null"
                    #input
                    placeholder="{{ placeholder }}"
                    [style]="inputStyle"
                    [readonly]="readonly" />
                <i *ngIf="icon && !working" class="{{ icon }} icon"></i>
                <i *ngIf="working" class="ui loadingAnim loader"></i>
            </div>
        </div>
    `,
})
export class ItcInputComponent implements OnInit {
    @Input() label: string;
    @Input() class: string;
    @Input() icon: string;
    @Input() type: string = 'text';
    @Input() placeholder: string;
    @Input() model: {};
    @Input() disabled: boolean = false;
    @Input() control: UntypedFormControl = new UntypedFormControl();
    @Input() debounce: number;
    @Input() readonly: boolean;
    @Input() working = false;
    @Input() maxLength: number;
    @Input() inputId: string;
    @Input() inputStyle: any;
    @Output() modelChange: EventEmitter<string | number> = new EventEmitter<string | number>();

    debouncer: Subject<string | number> = new Subject<string | number>();

    public isInsideForm: boolean = false;

    constructor(public viewRef: ViewContainerRef) {}

    ngOnInit() {
        this.debouncer
            .pipe(debounce(() => timer(this.debounce)))
            .subscribe((val) => this.modelChange.emit(val));

        // if input field is inside form
        if (this.inForm(this.viewRef.element.nativeElement, 'form')) {
            this.isInsideForm = true;
        }
    }

    inForm(el: Node, classname: string): boolean {
        if (el.parentNode) {
            if (el.parentNode.nodeName.toLowerCase() === classname.toLowerCase()) {
                return !!el.parentNode;
            } else {
                return this.inForm(el.parentNode, classname);
            }
        } else {
            return false;
        }
    }

    onModelChange(val: string | number) {
        this.debouncer.next(val);
    }
}

/**
 * Implementation of Textarea element
 *
 * @link http://semantic-ui.com/collections/form.html#text-area
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'itc-textarea',
    template: `
        <div class="field" [ngClass]="{ error: !control.valid && control.dirty }">
            <label *ngIf="label">{{ label }}</label>
            <textarea rows="{{ rows }}" [formControl]="control"></textarea>
        </div>
    `,
})
export class ItcTextareaComponent {
    @Input() control: UntypedFormControl = new UntypedFormControl();
    @Input() label: string;
    @Input() rows: string;
}
