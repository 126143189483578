<sds-loader [complete]="loadingComplete" [hideVisibility]="true">
    <div
        class="donut-chart-container"
        style="display: flex; align-items: center; height: 100%"
        [style.flex-wrap]="legendPosition !== 'right' ? 'wrap' : 'no-wrap'"
        [style.justify-content]="showLegend && legendPosition == 'right' ? '' : 'center'">
        <div *ngIf="!chartData?.length">
            <itc-banner type="info">No Scan Results Found.</itc-banner>
        </div>
        <div
            class="donut-chart"
            *ngIf="chartData?.length"
            [style.width]="diameter ? diameter + 'px' : ''"
            [style.height]="diameter ? diameter + 'px' : ''"
            style="max-height: 100%">
            <div class="overlapping">
                {{ overlapTextSmall }}
            </div>
            <wj-flex-pie
                #chart
                [itemsSource]="chartData"
                [palette]="palette ? palette : chartColors"
                [bindingName]="'bindingName'"
                [binding]="'count'"
                [innerRadius]="innerRadius"
                [startAngle]="90"
                [tooltipContent]="tooltipContent"
                (rendered)="chartRendered(chart, $event)">
                <wj-flex-chart-legend [position]="'None'"></wj-flex-chart-legend>
                <wj-flex-pie-data-label [position]="'None'"></wj-flex-pie-data-label>
            </wj-flex-pie>
        </div>
        <div
            [class.legendSeparator-top]="legendSeparator[0]"
            [class.legendSeparator-right]="legendSeparator[1]"
            [class.legendSeparator-bottom]="legendSeparator[2]"
            [class.legendSeparator-left]="legendSeparator[3]">
            <div
                class="legend {{ legendType }} legend-{{ legendPosition }}"
                *ngIf="showLegend"
                [ngSwitch]="legendType">
                <ul *ngSwitchCase="'bars'">
                    <li *ngFor="let cat of chartData; let i = index">
                        <div
                            [style.background-color]="chartColors[i]"
                            *ngIf="
                                cat.bindingName !== 'Placeholder' &&
                                cat.bindingName !== 'EmptyDonut' &&
                                !cat.hideFromLegend
                            ">
                            <div class="legendCategory">{{ cat.bindingName }}</div>
                            <div class="legendCount">{{ cat.count }}</div>
                        </div>
                    </li>
                </ul>
                <ul *ngSwitchCase="'ovals'">
                    <li *ngFor="let cat of chartData; let i = index">
                        <ng-container
                            *ngIf="
                                cat.bindingName !== 'Placeholder' &&
                                cat.bindingName !== 'EmptyDonut' &&
                                !cat.hideFromLegend
                            ">
                            <span [style.background-color]="forceRGBColor(chartColors[i], 0.5)">
                                {{ cat.count }}
                            </span>
                            {{ cat.bindingName }}
                        </ng-container>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</sds-loader>
