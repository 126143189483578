import {
    AfterViewChecked,
    Component,
    ContentChildren,
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
} from '@angular/core';

import { SortableColumnComponent } from './sortable-column.component';

@Component({
    selector: '[sortable-table]',
    template: '<ng-content></ng-content>',
    styles: [],
})
export class SortableTableComponent implements AfterViewChecked, OnInit {
    constructor(private el: ElementRef) {
        this.nativeElement = el.nativeElement;
    }

    @Input() class: string;

    nativeElement: ElementRef;
    private _nowColumn: string | number = '';
    private _nowDirection: string = '';
    private _sortColumn: string | number;
    private _sortDirection: 'asc' | 'desc' = 'asc';

    @Input() set sortColumn(value: string | number) {
        this._sortColumn = value;
        this.emitSort();
    }
    get sortColumn(): string | number {
        return this._sortColumn;
    }

    @Input() set sortDirection(value: 'asc' | 'desc') {
        this._sortDirection = value;
        this.emitSort();
    }
    get sortDirection(): 'asc' | 'desc' {
        return this._sortDirection;
    }

    @Output('sorted') sortEmitter: EventEmitter<TableSorting> = new EventEmitter<TableSorting>();

    @ContentChildren(SortableColumnComponent, { descendants: true })
    columns: QueryList<SortableColumnComponent>;

    ngOnInit() {}
    // changed from ngAfterViewInit so that *ngIf columns would be included in the registration.
    ngAfterViewChecked() {
        for (let column of this.columns.toArray()) {
            column.registerParent(this);
        }

        //    this.emitSort();
    }

    // Called by children
    updateSort(sorting: TableSorting) {
        this._sortColumn = sorting.sortColumn;
        this._sortDirection = sorting.sortDirection;
        this.emitSort();
    }

    emitSort() {
        if (this._sortColumn != this._nowColumn || this._sortDirection != this._nowDirection) {
            if (this._sortColumn != this._nowColumn) {
                this._sortDirection = 'asc';
            }
            this.sortEmitter.emit({
                sortColumn: this.sortColumn,
                sortDirection: this.sortDirection,
            });
            this._nowColumn = this._sortColumn;
            this._nowDirection = this._sortDirection;
            if (this.columns)
                this.columns.forEach((col) => {
                    col.setActive(this.sortColumn == col.columnName, this.sortDirection);
                });
        }
    }
}

export class TableSorting {
    public sortColumn: string | number;
    public sortDirection: 'asc' | 'desc';
}

@Directive({ selector: 'table-header, table-body' })
export class SortableTableDirective {
    // Exists only to declare these selectors
}
