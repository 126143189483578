import { Component, OnInit, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { MessageService } from 'app/core/message.service';
import { Notes } from '../../../shared/notes/notes.model';
import { NotesService } from '../../../shared/notes/notes.service';
import { UiService } from 'app/core/ui.service';
import { Site } from 'app/sites/shared/site.model';
import { ScanDataService, ScanDataUser } from 'app/scans';
import { ScanDataHelper } from 'app/scans/shared/scan-data-helper.service';
import { Tags, TagsService } from 'app/tags';
import { ScanDataType } from 'app/sites/site/inform/enums';

import { SITE, PING_SITE } from 'app/sites/shared/constants';
import { SiteService } from 'app/sites/shared/site.service';
import { NdpService } from 'app/sites/shared/ndp.service';

// import { SIDEBAR_LOADER } from 'app/shared/sidebar/layout/sidebar-layout.component';

const USER_NOTES_LOADER: string = 'USER_NOTES_LOADER';

@Component({
    selector: 'sds-site-data-user-detail',
    templateUrl: './site-data-user-detail.component.html',
    styleUrls: ['./site-data-user-detail.component.css'],
})
export class SiteDataUserDetailComponent implements OnInit, OnDestroy {
    constructor(
        private messageService: MessageService,
        private notesService: NotesService,
        private scanDataService: ScanDataService,
        private scanDataHelper: ScanDataHelper,
        private tagsService: TagsService,
        private route: ActivatedRoute,
        private datePipe: DatePipe,
        private uiService: UiService,
        private siteService: SiteService,
        private ndpService: NdpService,
        private router: Router
    ) {}

    site: Site;
    notes: Notes = new Notes({
        Id: '',
        SiteId: -1,
        ItemId: '{"User":{"Domain":"","UserName":"","LocalAccount":false,"Computer":{}}}',
        Body: '',
        PrivateBody: '',
        Title: '',
        Category: 'Notes',
    });
    SOPs: Notes[] = [];
    userId: any;
    selectedTab: string = 'summary';
    itemJSON: string;
    notesId: string;
    notesJSON: string;

    user: ScanDataUser;
    loginType = 'InteractiveLogon';

    loadingComplete: boolean;
    sub: any;
    riskScore: number = 0;

    assessmentId: number = -1;
    dataExplorerType = 'Indoc';

    breadcrumbs = [
        { text: 'Network Detective' },
        { text: 'Data Explorer' },
        { path: '../..', text: 'Users', link: true },
        { text: 'Detail' },
    ];

    ngOnInit() {
        this.dataExplorerType = this.ndpService.getDataExplorerType();
        this.route.params.subscribe((params: Params) => {
            if (params['id'] == this.userId) return;

            this.userId = params['id'];

            if (this.site) this.getUserDetails();
        });

        this.sub = this.messageService.on(SITE).subscribe((site) => this.onSite(site));
        this.messageService.broadcast(PING_SITE);

        this.ndpService.createDataExplorerBreadcrumbs('Users', 'users');
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    onSite(site: Site) {
        if (site && (!this.site || site.Id != this.site.Id)) {
            this.site = site;
            this.uiService.setTitle('Detail', site.Name);
            if (this.userId) this.getUserDetails();
        }
    }

    async getUserDetails() {
        this.loadingComplete = false;
        this.assessmentId = await this.ndpService.getAssessmentIdForDataExplorer(this.site, this.dataExplorerType);
        this.scanDataService
            .getUserById(this.site.Id, this.userId, this.assessmentId)
            .then((user) => {
                this.processDetail(user);
                this.loadingComplete = true;
            })
            .catch((err) => {
                this.loadingComplete = true;
                console.error(err);
            });
    }

    processDetail(user: ScanDataUser) {
        this.user = user;
        console.log(this.user);

        let initSite = this.site;
        this.notesJSON = window.atob(this.userId);
        this.notesId = JSON.stringify(JSON.parse(this.notesJSON)['User'])
            .replace(/^{/, '')
            .replace(/}$/, '');

        if (this.site && initSite.Id == this.site.Id && this.user.Notes != null) {
            this.notes = this.user.Notes;
        } else {
            this.notes = new Notes({
                Id: '',
                SiteId: this.site.Id,
                ItemId: this.notesJSON,
                Body: '',
                PrivateBody: '',
                Title: '',
                Category: 'Notes',
            });
        }

        if (this.site && initSite.Id == this.site.Id && this.user.Procedures != null) {
            this.SOPs = this.user.Procedures;
        } else {
            this.SOPs = [];
        }
    }

    getDateText(dateString: string) {
        if (dateString) {
            let date = new Date(dateString);
            if (date.getTime() < new Date(1601, 1, 1).getTime()) return 'Never';
            else return this.datePipe.transform(date, 'medium');
        } else return '';
    }

    saveNotes(notes: Notes) {
        let pop = this.scanDataService.getPatchOp('replace', '/Notes', JSON.stringify(notes));
        this.scanDataService
            .patchItem(this.site.Id, 'user', this.userId, [pop], this.assessmentId)
            .then((updatedObject: ScanDataUser) => {
                // process new data
                updatedObject.Notes.PrivateBody = this.notes.PrivateBody; // so it is not replaced with the encrypted value
                this.processDetail(updatedObject);
            })
            .catch((err) => {
                console.error(err);
            });
    }

    saveProcedures(procedures: Notes[]) {
        let pop = this.scanDataService.getPatchOp(
            'replace',
            '/Procedures',
            JSON.stringify(procedures)
        );
        this.scanDataService
            .patchItem(this.site.Id, 'user', this.userId, [pop], this.assessmentId)
            .then((updatedObject: ScanDataUser) => {
                this.processDetail(updatedObject);
            })
            .catch((err) => {
                console.error(err);
            });
    }
}
