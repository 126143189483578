<header class="pageHeader">
    <h1>
        Alerts
        <span *ngIf="alerts" class="circleLabelCount">{{ filteredAlerts?.length || 0 }}</span>
    </h1>

    <div class="item">
        <sds-alert-status-filter
            [alerts]="alerts"
            (onFiltered)="statusFiltered = $event"></sds-alert-status-filter>
    </div>

    <div class="item">
        <sds-alert-time-filter
            [alerts]="statusFiltered"
            (onFiltered)="timeFiltered = $event"></sds-alert-time-filter>
    </div>

    <div class="item flexGrow">
        <sds-alert-search-filter
            [alerts]="timeFiltered"
            (onFiltered)="filteredAlerts = $event; sortAlerts()"></sds-alert-search-filter>
    </div>
</header>

<sds-loader [complete]="loadingComplete">
    <div class="fullWidthContent" *ngIf="filteredAlerts?.length == 0">
        <itc-banner type="info" title="No Alerts Found">
            No alerts were found matching the given criteria.
        </itc-banner>
    </div>

    <div
        class="fullWidthContent"
        *ngIf="filteredAlerts?.length > 0"
        style="min-width: 100%; width: 100%; max-width: 100%">
        <sds-alerts-table [alerts]="pagedAlerts" (sortBy)="processSort($event)"></sds-alerts-table>
        <itc-paginator [data]="sortedAlerts" (onPage)="pagedAlerts = $event"></itc-paginator>
    </div>
</sds-loader>
