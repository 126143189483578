import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();

    if (environment.envSuffix != '-dev' && environment.envSuffix != '-uat') {
        window.console.log = () => {}; // overwrite console.log for production builds
        window.console.group = () => {};
        window.console.groupCollapsed = () => {};
        window.console.groupEnd = () => {};
    }
}

platformBrowserDynamic().bootstrapModule(AppModule);
