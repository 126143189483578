import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { LicenseService } from 'app/core/license.service';
import { TableSorting } from 'app/shared/sortable-table/sortable-table.component';
import { AuthService } from 'app/core/auth';
import { UiService } from 'app/core/ui.service';

@Component({
    selector: 'sds-license-usage',
    templateUrl: './license-usage.component.html',
    styleUrls: ['./license-usage.component.css'],
})
export class LicenseUsageComponent implements OnInit {
    constructor(
        private authService: AuthService,
        private licenseService: LicenseService,
        private uiService: UiService
    ) {}
    filterCtrl = new UntypedFormControl();
    products: any;
    loadingComplete: boolean = false;
    licenseCaps: any;
    currentCap: string = '';
    licenseList: any[] = [];
    filteredLicenses: any[] = [];
    sortedLicenses: any[] = [];
    pagedLicenses: any[] = [];
    licenseSummary: number = 0;
    isSMB: boolean;

    initPage: boolean;

    @ViewChild('provisionmodal', { static: true }) provisionModal: any;

    breadcrumbs = [
        { path: '..', text: 'Admin' },
        { path: '.', text: 'License Usage' },
    ];

    ngOnInit() {
        this, this.licenseService.getProvisioning().then((prov) => {
            this.isSMB = prov.isSMB;
        });

        this.products = this.authService.getProducts();
        if (this.products.KVS) {
            this.filterCtrl.setValue('KVS');
        } else if (this.products.CM2019SEP || this.products.CM2) {
            this.filterCtrl.setValue('GDPR');
        } else if (this.products.NdProWeb) {
            this.filterCtrl.setValue('NDP');
        } else if (this.products.CH) {
            this.filterCtrl.setValue('CH');
        }
        this.licenseService.getLicenseCaps().then((licenseCaps) => {
            this.licenseCaps = licenseCaps;
            this.licenseService.getLicenses().then((licenses) => {
                console.log(licenses);
                this.licenseList = licenses;
                this.initPage = true;
                this.filterLicenses();
                this.loadingComplete = true;
            });
        });

        this.uiService.setTitle('License Usage');
    }

    filterLicenses(filteredData?: any) {
        if (filteredData) this.filteredLicenses = filteredData;
        else this.filteredLicenses = this.licenseList;
        if (this.filterCtrl.value != 'all') {
            this.filteredLicenses = this.checkFilter();
        }
        //this.filtered1 = this.filteredLicenses;
        this.sortLicenses();
    }

    checkFilter() {
        this.licenseSummary = 0;
        this.currentCap = (this.isSMB) ? this.licenseCaps['DEVICES'] : this.licenseCaps[this.filterCtrl.value];
        let filtered: any[] = [];
        for (let lic of this.filteredLicenses) {
            if (lic.Model.includes(this.filterCtrl.value)) {
                if (this.isSMB) {
                    this.licenseSummary += lic.Computers;
                } else {
                    if (this.filterCtrl.value == 'KVS' || lic.MaximumComputers == -1) {
                        lic.MaximumComputers = 'N/A';
                    }
                    if (lic.Computers == -1) {
                        lic.Computers = 'N/A';
                    }
                    if (lic.Computers == 'N/A' || lic.MaximumComputers == 'N/A') {
                        lic.Cost = 1;
                    } else {
                        lic.Cost =
                            lic.MaximumComputers == 0
                                ? 1
                                : Math.floor(lic.Computers / lic.MaximumComputers) +
                                (lic.Computers % lic.MaximumComputers == 0 && lic.Computers != 0
                                    ? 0
                                    : 1);
                    }
                    this.licenseSummary += lic.Cost;
                }
                
                filtered.push(lic);
            }
        }
        return filtered;
    }

    sortLicenses(sorting?: TableSorting) {
        if (!sorting)
            this.sortedLicenses = this.filteredLicenses.sort((a, b) =>
                a.Site
                    ? b.Site
                        ? a.Site.Name.toLowerCase().localeCompare(b.Site.Name.toLowerCase())
                        : -1
                    : 1
            );
        else {
            switch (sorting.sortColumn) {
                case 'name':
                    this.sortedLicenses = this.filteredLicenses.sort((a, b) =>
                        a.Site
                            ? b.Site
                                ? a.Site.Name.toLowerCase().localeCompare(b.Site.Name.toLowerCase())
                                : -1
                            : 1
                    );
                    break;
                case 'cap':
                    this.sortedLicenses = this.filteredLicenses.sort((a, b) =>
                        a.MaximumComputers > b.MaximumComputers
                            ? 1
                            : a.MaximumComputers < b.MaximumComputers
                            ? -1
                            : 0
                    );
                    break;
                case 'computers':
                    this.sortedLicenses = this.filteredLicenses.sort((a, b) =>
                        a.Computers > b.Computers ? 1 : a.Computers < b.Computers ? -1 : 0
                    );
                    break;
                case 'usage':
                    this.sortedLicenses = this.filteredLicenses.sort((a, b) =>
                        a.Cost > b.Cost ? 1 : a.Cost < b.Cost ? -1 : 0
                    );
                    break;
                default:
                    this.sortedLicenses = this.filteredLicenses.sort((a, b) =>
                        a.Site
                            ? b.Site
                                ? a.Site.Name.toLowerCase().localeCompare(b.Site.Name.toLowerCase())
                                : -1
                            : 1
                    );
                    break;
            }

            if (sorting.sortDirection == 'desc')
                //descending
                this.sortedLicenses.reverse();
        }

        this.filteredLicenses = [...this.sortedLicenses];
    }

    closeModal(isOk: boolean) {
        if (isOk) {
        } else {
            this.provisionModal.hide();
        }
    }
    openModal() {
        this.provisionModal.show();
    }
}
