<div class="paginator" *ngIf="!hidden">
    <div class="results">
        <ng-container *ngIf="itemCount === 0; else showResults">
            <strong>{{ itemCount }}</strong>
            Items
        </ng-container>
        <ng-template #showResults>
            Showing
            <strong>
                {{ (currentPage - 1) * pageSize + 1 }} -
                {{ currentPage * pageSize > itemCount ? itemCount : currentPage * pageSize }}
            </strong>
            of
            <strong>{{ itemCount }}</strong>
            Items
        </ng-template>
    </div>
    <div>
        <button
            class="prevPage"
            [disabled]="currentPage <= 1 ? true : null"
            (click)="setPage('prev')">
            <fa-icon icon="chevron-left"></fa-icon>
        </button>

        <button
            class="pageNum"
            *ngFor="let page of pageList"
            [class.active-page]="page === currentPage"
            [class.disabled-page]="page < 0"
            (click)="setPage(page)">
            {{ page < 0 ? '&hellip;' : page }}
        </button>

        <button [disabled]="currentPage >= lastPage ? true : null" (click)="setPage('next')">
            <fa-icon icon="chevron-right"></fa-icon>
        </button>

        <p-dropdown
            [(ngModel)]="_pageSize"
            [options]="_pageSizeOptions"
            dropdownIcon="fa6-chevron-down"
            appendTo="body"
            [disabled]="false"
            (onChange)="updatePageSize()"
            class="rowsPerPage"></p-dropdown>
        rows per page
    </div>
</div>
