import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormControl } from '@angular/forms';
import { SettingService } from 'app/settings/shared/setting.service';
import { AuthService } from 'app/core/auth/auth.service';
import { PreferencesService } from './preferences.service';
import { MfaEnableComponent } from './mfa-enable/mfa-enable.component';
import { takeUntil } from 'rxjs/operators';
import { NotificationService } from '../../shared/itc/notification/notification.service';
import { notBlank } from 'app/shared/not-blank.validator';
import { Subject } from 'rxjs';
import { SemanticModalComponent } from 'app/semantic-legacy/semantic-legacy.module';

@Component({
    selector: 'sds-user-preferences',
    templateUrl: './preferences.component.html',
    styles: [
        `
            form[name='changePasswordForm'] {
                max-width: 400px;
            }
        `,
    ],
})
export class PreferencesComponent implements OnInit {
    cuTodoSeparateCtrl = new UntypedFormControl();

    preferences: Map<string, any> = new Map<string, any>();
    state: 'default' | 'changePassword' | 'setupTwoFactor' | 'deleteTwoFactor' = 'default';

    passwordForm = this.fb.group({
        currentPass: ['', notBlank],
        newPass: ['', notBlank],
    });
    passwordFormError = '';

    setupTwoFactor: boolean;
    twoFactorCtrl = new UntypedFormControl();
    isTwoFactorEnabled: boolean;
    isMfaRequired: boolean;
    ngUnsubscribe$ = new Subject<void>();
    passwordError: string;

    @ViewChild('mfaEnable') mfaEnableComponent: MfaEnableComponent;
    @ViewChild('modal') modal: SemanticModalComponent;

    constructor(
        private authService: AuthService,
        private prefService: PreferencesService,
        private settingService: SettingService,
        private notificationService: NotificationService,
        private fb: FormBuilder
    ) {}

    ngOnInit() {
        this.restoreValues();

        this.twoFactorCtrl.valueChanges.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((value) => {
            if (value) {
                this.setupTwoFactor = true;
                this.state = 'setupTwoFactor';
            } else if (!value && this.isTwoFactorEnabled) {
                this.deleteTwoFactor = true;
                this.state = 'deleteTwoFactor';
            }
        });

        // only check this if authorized, or else it will error on end-user alerts
        if (this.authService.isAuth()) {
            this.prefService.checkTOTP().then((enabled) => {
                this.isTwoFactorEnabled = enabled;

                if (
                    this.preferences &&
                    this.preferences.get('AUTH_2FA') != this.isTwoFactorEnabled
                ) {
                    this.preferences.set('AUTH_2FA', this.isTwoFactorEnabled);
                    this.restoreValues();
                }
            });

            this.setIsMfaRequired();
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    setIsMfaRequired() {
        this.settingService.getSetting('MFA_REQUIRED').then((mfaRequired) => {
            if (mfaRequired) {
                this.isMfaRequired = mfaRequired.Value === 'TRUE';
            }
        });
    }

    showModal() {
        this.restoreValues();
        this.modal.refresh();
        this.modal.show({ closable: false });
    }

    hideModal() {
        this.modal.hide();
    }

    restoreValues() {
        this.state = 'default';
        if (!this.preferences.size) {
            this.preferences = this.prefService.getPreferences();
        }

        if (this.preferences.get('AUTH_2FA')) {
            this.twoFactorCtrl.setValue(this.preferences.get('AUTH_2FA'), { emitEvent: false });
        } else {
            this.twoFactorCtrl.setValue(false, { emitEvent: false });
        }
    }

    savePrefs() {
        // Store control values to this.preferences
        this.preferences.set('CU_TODO_SEPARATE', this.cuTodoSeparateCtrl.value);

        this.prefService.savePreferences(this.preferences).then(() => {
            this.notificationService.toast.success('Success', 'User Settings were updated.');
        });
    }

    hideChangePassword() {
        // wait for the modal to close
        setTimeout(() => {
            this.passwordForm.reset();
            this.state = 'default';
            this.passwordError = '';
            this.modal.refresh();
        }, 200);
    }

    changingPassword: boolean;
    changePasswordSuccess: boolean;

    doChangePassword() {
        this.changingPassword = true;
        let currentPass = this.passwordForm.get('currentPass').value;
        let newPass = this.passwordForm.get('newPass').value;
        this.prefService
            .changePassword(currentPass, newPass)
            .then((res) => {
                this.changingPassword = false;

                this.notificationService.toast.success('Success', 'Password was changed.');
                this.hideChangePassword();
            })
            .catch((res) => {
                this.changingPassword = false;

                if (res.status == 403) {
                    this.notificationService.toast.error(
                        'Error',
                        'The password you entered was incorrect. Please change it and try again.'
                    );
                } else {
                    this.notificationService.toast.error(
                        'Error',
                        'There was a problem changing your password.'
                    );
                }
            });
    }

    confirmCancel() {
        if (
            confirm(
                'Two-Factor Authentication has not been enabled. Are you sure you want to go back?'
            )
        )
            this.cancelTwoFactorSetup();
    }

    cancelTwoFactorSetup() {
        this.twoFactorCtrl.setValue(this.isTwoFactorEnabled);
        if (this.mfaEnableComponent) {
            this.mfaEnableComponent.setTwoFactorKeyCtrl('');
        }

        this.totpCtrl.setValue('');
        this.setupTwoFactor = false;
        this.deleteTwoFactor = false;
        this.state = 'default';
    }

    onIsTwoFactorEnabledSet(val: any) {
        this.isTwoFactorEnabled = val;
        if (!this.isTwoFactorEnabled) {
            this.cancelTwoFactorSetup();
        }
    }

    totpCtrl: UntypedFormControl = new UntypedFormControl();

    deleteTwoFactor: boolean;
    deletingTwoFactorKey: boolean;

    finishTwoFactorSetup() {
        this.setupTwoFactor = false;
        this.totpCtrl.setValue('');
        this.hideModal();
    }

    resetMfa() {
        this.isTwoFactorEnabled = false;
        this.setupTwoFactor = true;
        this.state = 'deleteTwoFactor';
    }
}
