import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ItcNotificationBannerComponent } from './notification-banner.component';
import { ItcNotificationToastAreaComponent } from './notification-toast-area.component';
import { ItcNotificationToastComponent } from './notification-toast.component';
import { ItcBannerComponent } from '../banner/banner.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
    imports: [CommonModule, FontAwesomeModule],
    declarations: [
        ItcNotificationBannerComponent,
        ItcNotificationToastAreaComponent,
        ItcNotificationToastComponent,
        ItcBannerComponent,
    ],
    providers: [],
    exports: [
        ItcNotificationBannerComponent,
        ItcNotificationToastAreaComponent,
        ItcNotificationToastComponent,
        ItcBannerComponent,
    ],
})
export class NotificationModule {}
