<sds-loader [complete]="loadingComplete">
    <sds-breadcrumb-trail
        [crumbs]="breadcrumbs"
        *ngIf="dataExplorerType === 'indoc'"></sds-breadcrumb-trail>
    <header class="pageHeader">
        <h1>Databases</h1>
        <div class="item searchInput">
            <sds-data-search-filter
                [data]="databases"
                (filteredData)="onFiltered($event)"
                [fields]="['Domain', 'Server']"></sds-data-search-filter>
        </div>
        <div class="item">
            <sds-data-filter
                [data]="databases"
                [filterOptions]="filterOptions"
                (filteredData)="filteredDbs = $event; sortDbs()"></sds-data-filter>
        </div>
    </header>

    <itc-banner *ngIf="mergeInProgress" type="info">
        <strong>Database update is in progress.</strong>
        {{ databases?.length ? 'New scan' : 'Scan' }} data has been uploaded successfully. Check in
        a few minutes after database update is complete.
    </itc-banner>

    <table sortable-table class="itc-table sortable" id="dbsTable" (sorted)="onSorted($event)">
        <thead>
            <tr>
                <th sortable-column="Domain">Domain</th>
                <th sortable-column="Server" sort-direction="asc">Server</th>
                <th sortable-column="Instance">Instance</th>
                <th sortable-column="Version">Version</th>
                <th sortable-column="Databases"># Databases</th>
                <th sortable-column="Jobs"># Jobs</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let db of pagedDbs" [routerLink]="db.Id + '/detail'">
                <td>{{ db.Domain }}</td>
                <td>{{ db.Server }}</td>
                <td>{{ db.Instance }}</td>
                <td>{{ db.Version }}</td>
                <td>{{ db.Databases }}</td>
                <td>{{ db.Jobs }}</td>
            </tr>
        </tbody>
    </table>
    <itc-paginator
        [data]="filteredDbs"
        (onPage)="pagedDbs = $event"
        [hidden]="!filteredDbs.length"></itc-paginator>
    <empty-state
        icon="results"
        title="No Database Items Found"
        [text]="emptyStateText"
        *ngIf="!filteredDbs.length"></empty-state>
</sds-loader>
