import { Component, OnInit, isDevMode } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';
import { ResourcesService } from './resources.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UiService } from 'app/core/ui.service';
import * as saveAs from 'file-saver';
import { NotificationService } from '../../shared/itc/notification/notification.service';
import { RFT_URL } from '../constants';

declare var $: any;

@Component({
    selector: 'sds-resources',
    templateUrl: './resources.component.html',
    styleUrls: ['./resources.component.css'],
})
export class ResourcesComponent implements OnInit {
    selectedCategory: string = 'all';
    categories: Array<string>;
    resources: any;
    isDownloadingPricelist: boolean = false;
    isKaseya: boolean = this.authService.userIsKaseya();
    product: string = '';
    rft_url = RFT_URL;

    constructor(
        private authService: AuthService,
        private resourceService: ResourcesService,
        private notificationService: NotificationService,
        private route: ActivatedRoute,
        private router: Router,
        private uiService: UiService
    ) {}

    ngOnInit() {
        let prods = this.authService.getProducts();

        this.route.params.subscribe((ps: Params) => {
            if (ps['product']) {
                this.uiService.setTitle('Resources');
                this.product = ps['product'].toLowerCase();
                // not in yet, need to figure out logic so kcm and ucm and whatever can still view
                // if (
                //     (this.product == 'gdpr' && !prods.AGGDPR) ||
                //     (this.product == 'hipaa' && !prods.AGHIPAA) ||
                //     (this.product == 'cyber-insurance' && !prods.AGCYBERINSURANCE) ||
                //     (this.product == 'nist' && !prods.AGNIST) ||
                //     (this.product == 'cyberhawk' && !prods.CH)
                // ) { this.router.navigate(['/']); }
            } else {
                // default to gdpr if they have it, otherwise try hipaa otherwise redirect home
                if (prods.AGGDPR) {
                    this.router.navigate(['/resources/gdpr']);
                } else if (prods.AGHIPAA) {
                    this.router.navigate(['/resources/hipaa']);
                } else {
                    this.router.navigate(['/']);
                }
            }
        });
        if (this.product) {
            let resourcejson = isDevMode()
                ? 'resources.portal-dev.v4.json?v=' + Math.floor(Math.random() * Math.floor(10))
                : 'resources.portal.v4.json';
            $.getJSON(`${this.rft_url}/data/${resourcejson}`, (data) => {
                this.resources = data[this.product.toLowerCase()];
                this.product = this.resources['Display Name'];
                this.uiService.setTitle(this.product + ' Resources');

                delete this.resources['Display Name'];

                this.categories = Object.keys(this.resources);

                this.categories.forEach((value, i) => {
                    if (
                        this.resources[value][0].enabled === false ||
                        (this.isKaseya && value === 'Price List')
                    ) {
                        this.categories.splice(i, 1);
                    }
                });
                this.categories.push('All ' + this.product);
                this.resources['All ' + this.product] = [];
                this.resources['All ' + this.product][0] = {
                    class: 'all',
                    enabled: true,
                    items: [],
                };
            });
        }
    }

    customClickCommand(action: string, link: string) {
        if (action) {
            if (action === 'pricelist' && this.isDownloadingPricelist !== true) {
                this.getPriceSheet();
            } else if (action === 'link') {
                window.open(link, '_blank');
            } else if (action === 'download') {
                if (link.lastIndexOf('.pptx') > -1) {
                    this.getFileFromServer(link, 'pptx');
                } else if (link.lastIndexOf('.docx') > -1) {
                    this.getFileFromServer(link, 'docx');
                } else if (link.lastIndexOf('.xlsx') > -1) {
                    this.getFileFromServer(link, 'xlsx');
                }
            }
        }
    }

    /* files are uploaded to AWS so they can be downloaded by grabbing the bites
  instead of having a link someone can more easily share
  In sdsstaticfrankfurt bucket */
    getFileFromServer(fileName: string, type: string) {
        this.notificationService.toast.download('Downloading', 'Downloading ' + fileName);
        this.resourceService.getBrochure(fileName).then((res) => {
            let raw = window.atob(res.Bytes);
            let bytes = new Uint8Array(raw.length);
            let mimetype;
            for (let i = 0; i < raw.length; i++) {
                bytes[i] = raw.charCodeAt(i);
            }
            if (type === 'docx') {
                mimetype =
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
            } else if (type === 'pptx') {
                mimetype =
                    'application/vnd.openxmlformats-officedocument.presentationml.presentation';
            } else if (type === 'xlsx') {
                mimetype = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            }
            saveAs(new Blob([bytes], { type: mimetype }), res.Filename);
            this.isDownloadingPricelist = false;
        });
    }

    getPriceSheet() {
        this.isDownloadingPricelist = true;
        this.notificationService.toast.download('Downloading', 'Downloading Pricelist.');
        this.resourceService.getPriceSheet().then((res) => {
            let raw = window.atob(res.Bytes);
            let bytes = new Uint8Array(raw.length);
            for (let i = 0; i < raw.length; i++) {
                bytes[i] = raw.charCodeAt(i);
            }
            saveAs(new Blob([bytes], { type: 'application/pdf' }), res.Filename);
            this.isDownloadingPricelist = false;
        });
    }

    chooseCategory(category) {
        this.selectedCategory = category;
    }
}
