import { Component } from '@angular/core';

@Component({
    selector: 'task-checklist',
    template: `
		<ul class='stepsToComplete'>
			<ng-content></ng-content>
		</ul>
    `,
})
export class TaskChecklistComponent {

}
