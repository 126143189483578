<sds-breadcrumb-trail [crumbs]="breadcrumbs"></sds-breadcrumb-trail>

<header class="pageHeader">
    <h1 class="section_title">Manage Risk Update Reports</h1>
</header>

<sds-loader [complete]="loadingComplete">
    <form class="ui form settingsPage smallerContainer">
        <div class="itc-vertical-menu" style="margin-bottom: 20px">
            <div class="item">
                <div class="ui left labeled input">
                    <div
                        class="ui label darker"
                        style="border-top-right-radius: 0; border-bottom-right-radius: 0">
                        Report Set
                    </div>
                    <sm-select [control]="reportSetCtrl" #reportSetSelect>
                        <option
                            *ngFor="let set of reportSets"
                            value="{{ set }}"
                            selected="{{ selectedReportSet == set ? 'selected' : '' }}">
                            {{ set }}
                        </option>
                    </sm-select>
                </div>
            </div>

            <div class="item" style="margin-right: 6px">
                <itc-button
                    label="Download .zip"
                    type="secondary"
                    icon="fa-download"
                    [disabled]="!selectedReportSet"
                    [loading]="downloadingReportSetZip"
                    (onclick)="downloadReportSetZip()"></itc-button>
            </div>

            <div class="item">
                <itc-button
                    label="Delete Report Set"
                    type="danger"
                    icon="fa-trash-can"
                    [disabled]="!selectedReportSet"
                    [loading]="deletingReportSet"
                    (onclick)="showDeleteReportSetConfirm()"></itc-button>
            </div>
        </div>

        <itc-banner type="info" *ngIf="loadingComplete && !reportSets?.length">
            No Reports Found. Published reports can be generated by manually performing a Risk
            Update Assessment or by scheduling a Monthly Scan that will generate Risk Update
            assessment reports.
        </itc-banner>

        <h5 class="ui top attached header" *ngIf="listLoading" style="background: #f9fafb">
            &nbsp;
        </h5>
        <div
            class="ui attached segment loading"
            *ngIf="listLoading"
            style="min-height: 250px"></div>

        <table
            class="itc-table"
            *ngIf="reportList?.length && !listLoading"
            (sorted)="onSort($event)"
            sortable-table
            sortColumn="filename"
            sortDirection="asc">
            <thead>
                <tr>
                    <th sortable-column="filename">Filename</th>
                    <th sortable-column="lastmodifiedformatted">Last Modified (UTC)</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let report of reportList">
                    <td>{{ report.filename }}</td>
                    <td>{{ report.lastmodifiedformatted }}</td>
                    <td class="collapsing">
                        <itc-button
                            type="icon"
                            icon="fa-arrow-down-to-square"
                            tooltip="Download"
                            ttposition="top"
                            [disabled]="report.deleting"
                            [loading]="report.loading"
                            (onclick)="downloadReport(report)"></itc-button>
                        &nbsp;
                        <itc-button
                            type="icon"
                            icon="fa-trash-can"
                            tooltip="Delete"
                            ttposition="top"
                            [disabled]="report.loading"
                            [loading]="report.deleting"
                            (onclick)="showDeleteReportConfirm(report)"
                            style="background-color: transparent"></itc-button>
                    </td>
                </tr>
            </tbody>
        </table>

        <itc-banner type="info" *ngIf="selectedReportSet && !reportList?.length && !listLoading">
            No reports found in the selected report set.
        </itc-banner>

        <div
            class="ui dashboard"
            style="display: flex; justify-content: space-between; margin-top: 20px"
            *ngIf="!listLoading">
            <div class="ui card" style="width: 45%">
                <div class="content">Risk Profile Settings</div>
                <div class="content" style="padding: 10px">
                    <div>
                        <itc-checkbox
                            name="useCompensatingControls"
                            id="useCompensatingControls"
                            [(ngModel)]="useCompensatingControls">
                            Use Compensating Controls Worksheet from Last Assessment
                        </itc-checkbox>
                    </div>
                    <div>
                        <itc-checkbox
                            name="useConnector"
                            id="useConnector"
                            [(ngModel)]="useConnector"
                            [disabled]="!isVsaConnectorEnabled">
                            Autoimport from RMM Connector Scans during Risk Update
                        </itc-checkbox>
                    </div>
                    <itc-button
                        label="Save"
                        type="primary"
                        (onclick)="saveUseCompensatingControl()"></itc-button>
                </div>
            </div>

            <div class="ui card" style="width: 45%">
                <div class="content">Perform Risk Update Assessment</div>
                <div class="content" style="padding: 10px">
                    <itc-button
                        label="Start Scans and Generate Reports"
                        type="primary"
                        (onclick)="startRiskSnapshotScans()"
                        [disabled]="isAssessmentStarted || scansRunning"></itc-button>
                </div>
            </div>
        </div>
    </form>
</sds-loader>
