import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { AuthService } from 'app/core/auth/auth.service';
import { FilesService } from './files.service';
import { MessageService } from 'app/core/message.service';
import { UiService } from 'app/core/ui.service';

import { ASSESSMENT, PING_ASSESSMENT } from '../site-assessments.component';
import { Site } from 'app/sites/shared/site.model';
import { SITE, PING_SITE } from 'app/sites/shared/constants';
import { TableSorting } from 'app/shared/sortable-table/sortable-table.component';

@Component({
    selector: 'sds-files-list',
    templateUrl: './files-list.component.html',
    styleUrls: ['./files-list.component.css'],
})
export class FilesListComponent implements OnInit, OnDestroy {
    constructor(
        private authService: AuthService,
        private filesService: FilesService,
        private messageService: MessageService,
        private uiService: UiService
    ) {}

    site: Site;
    selectedAssessment: any;
    files: any[];
    loadingComplete: boolean;
    aSub: any;
    sSub: any;
    downloading: boolean;
    checkingLoadingComplete: boolean = false;

    fileIdToDelete: string;

    breadcrumbs = [
        { path: '../..', text: 'Compliance Manager' },
        { path: '..', text: 'Assessments' },
        { path: '.', text: 'Files' },
    ];

    noAssessment = false;

    @ViewChild('attachmentUsedModal', { static: true }) attachmentUsedModal: any;
    @ViewChild('attachmentNotUsedModal', { static: true }) attachmentNotUsedModal: any;
    @ViewChild('checkingAttachmentModal', { static: true }) checkingAttachmentModal: any;

    ngOnInit() {
        console.log('files list');
        this.loadingComplete = false;

        this.aSub = this.messageService.on(ASSESSMENT).subscribe((assessment) => {
            this.selectedAssessment = assessment;

            if (this.site && this.selectedAssessment && !this.selectedAssessment.isEmpty) {
                this.getFilesList();
                this.noAssessment = false;
            } else if (this.site && this.selectedAssessment && this.selectedAssessment.isEmpty) {
                this.noAssessment = true;
                this.loadingComplete = true;
            }
        });

        this.sSub = this.messageService.on(SITE).subscribe((site: Site) => {
            if (!site || site == this.site) {
                return;
            }

            this.site = site;
            this.uiService.setTitle('Files', site.Name);
            this.messageService.broadcast(PING_ASSESSMENT);
        });

        this.messageService.broadcast(PING_SITE);
    }

    getFilesList() {
        this.loadingComplete = false;
        this.filesService
            .getFilesMetadata(this.site.Id, this.selectedAssessment.Name)
            .then((metas) => {
                this.files = metas;
                console.log('files length: ' + this.files.length);
                for (let file of this.files) {
                    (file as any).link = file.fileid; // the file extension must be included during encoding
                }

                this.loadingComplete = true;
            })
            .catch((err) => {
                this.loadingComplete = true;
                console.error(err);
            });
    }

    onSorted(sorting: TableSorting) {
        let files = this.files.sort((a, b) => {
            if (sorting.sortColumn == 'lastmodified') {
                return a.lastmodified < b.lastmodified ? -1 : 1;
            } else if (sorting.sortColumn) {
                let aVal = a[sorting.sortColumn];
                let bVal = b[sorting.sortColumn];
                if (aVal && bVal) {
                    return aVal
                        .toLowerCase()
                        .localeCompare(bVal.toLowerCase());
                } else if (!aVal && !bVal) {
                    return 0;
                } else {
                    return aVal ? -1 : 1;
                }
            }
        });
        
        if (sorting.sortDirection == 'desc') this.files = files.reverse();
        else this.files = files;
    }

    removeAttachment(fileid: string) {
        // determine if file is in use
        try {
            console.log('remove attachment: ' + fileid);
            this.checkingLoadingComplete = false;
            this.checkingAttachmentModal.show({ closable: false });
            this.filesService
                .getFilesWithAttachmentIdCount(this.site.Id, fileid)
                .then((s) => {
                    // show appropriate modal
                    if (s > 0) {
                        this.fileIdToDelete = null;
                        this.attachmentUsedModal.show();
                    } else {
                        this.fileIdToDelete = fileid;
                        this.attachmentNotUsedModal.show();
                    }
                    this.checkingLoadingComplete = true;
                })
                .catch((err) => {
                    this.checkingLoadingComplete = true;
                    this.checkingAttachmentModal.hide();
                    console.log('File List Error: ' + err);
                });
        } catch (e) {
            this.loadingComplete = true;
            console.log('File List Error: ' + e);
        }
    }

    removeAttachmentPermanently() {
        if (this.fileIdToDelete) {
            this.filesService.deleteFile(this.site.Id, this.fileIdToDelete).then((s) => {
                this.ngOnInit();
            });
        }
    }

    /*
  downloadFormZip() {
      this.downloading = true;
      this.filesService.downloadFormZip(this.site.Id, this.selectedAssessment.Name)
          .then((b64) => {
              this.downloading = false;

              let raw = window.atob(b64);
              let bytes = new Uint8Array(raw.length);
              for (let i = 0; i < raw.length; i++) {
                  bytes[i] = raw.charCodeAt(i);
              }
              let blob = new Blob([bytes]);

              saveAs(blob, this.site.Name + ' - Form Data.zip');
          })
          .catch((err) => {
              this.downloading = false;
              console.error(err);
          });
  }
  */
    ngOnDestroy() {
        this.aSub.unsubscribe();
        this.sSub.unsubscribe();
    }
}
