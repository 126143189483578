import { inject } from '@angular/core';
import {
    Router,
    RouterStateSnapshot,
    ActivatedRouteSnapshot,
    CanActivateFn,
    CanMatchFn,
    UrlTree,
} from '@angular/router';

import { AuthService, Roles, SiteRoles, OrgRoles } from 'app/core/auth/auth.service';
import { SiteService } from 'app/sites/shared/site.service';
import { Site } from 'app/sites/shared/site.model';
import { OrganizationsService } from '../../../organizations/organizations.service';

let organizationsService;
let router;

export const RolesGuard: CanActivateFn | CanMatchFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
): Promise<boolean | UrlTree> | boolean | UrlTree => {
    let authService = inject(AuthService);
    router = inject(Router);
    organizationsService = inject(OrganizationsService);
    let siteService = inject(SiteService);
    console.log('Roles Guard');
    console.log('Roles Guard: Allowed Roles: ', route.data.roles);

    /* For only master guards */
    if (route.data.roles.length === 1 && route.data.roles[0] === Roles.Master) {
        if (authService.userIsRole(Roles.Master)) {
            console.log('Roles Guard: User is Master');
            console.log('Roles Guard: Guard Result: ', true);
            return true;
        } else {
            console.log('Roles Guard: User is NOT Master');
            console.log('Roles Guard: Guard Result: ', false);
            router.navigate(['/error', '403', 'RFTE105']);
            return false;
        }
    }
    /* otherwise, always allow Roles.Admin and Roles.Master */
    let hasAccess = authService.userIsRoles([Roles.Admin, Roles.Master]);
    if (hasAccess) {
        console.log('Roles Guard: User is Master or Admin');
        console.log('Roles Guard: Guard Result: ', hasAccess);
        return true;
    }

    /* if Roles.Restricted is allowed, check for that */
    if (route.data.roles.indexOf(Roles.Restricted) > -1) {
        hasAccess = authService.userIsRole(Roles.Restricted);
        if (hasAccess) {
            console.log('Roles Guard: User is Restricted');
            console.log('Roles Guard Result: ', hasAccess);
            return true;
        }
    }
    let org, routeOrgName;

    /* Go through site roles IF this is a site component */
    let site, routeSiteName;

    // getting site from url because route.pathFromRoot is mangled because of a security feature in production
    let splitURL = state.url.split('/');
    routeSiteName = splitURL[1] == 'site' ? decodeURIComponent(splitURL[2]) : '';
    routeOrgName = splitURL[1] == 'organizations' ? decodeURIComponent(splitURL[2]) : '';
    if (routeOrgName == '__all') routeOrgName = '';

    if (routeOrgName) {
        org = organizationsService.getSelectedOrganization();
        if (org && org.Name == routeOrgName) {
            console.log('Roles Guard: Got current org', org);
            return rolesGuardLogic(
                route.data.roles,
                organizationsService.getSelectedOrgRole(),
                org.Name
            );
        } else {
            console.log("Roles Guard: Couldn't find current org, or doesn't match route");
            if (routeOrgName != '') {
                if (organizationsService.setSelectedOrganization(routeOrgName) != null) {
                    return rolesGuardLogic(
                        route.data.roles,
                        organizationsService.getSelectedOrgRole(),
                        routeOrgName
                    );
                }
            } else {
                console.groupEnd();
                return false;
            }
        }
    }

    // not a site component route, so need to look into this.
    if (routeSiteName) {
        // get site
        site = siteService.getCurrentSite();
        // I had to add this check because we want to make sure the current site we get back is the correct one we're going for
        if (site && site.Name == routeSiteName) {
            console.log('Roles Guard: Got current site', site);
            return rolesGuardLogic(route.data.roles, site.UserRoles, site.Organization);
        } else {
            // no currentsite set, get it from name
            console.log("Roles Guard: Couldn't find current site, or doesn't match route");
            if (routeSiteName != '') {
                return siteService
                    .getSiteByNameLimitedData(routeSiteName)
                    .then((site: Site) => {
                        console.log('Roles Guard: Got site by name, saving currentSite', site);
                        siteService.setCurrentSite(site);
                        return rolesGuardLogic(route.data.roles, site.UserRoles, site.Organization);
                    })
                    .catch((e) => {
                        console.log('Roles Guard: failed to get site');
                        console.log('error', e);
                        console.groupEnd();
                        return false;
                    });
            } else {
                console.groupEnd();
                return false;
            }
        }
    }
};

function rolesGuardLogic(roles: any[], targetRoles: string, orgName = ''): boolean {
    console.log('Roles Guard: route Roles', roles);
    console.log('Roles Guard: owned Roles', targetRoles);
    //Discovery agent access is complex, it can be shortcut with an account parameter of if it's an admin on any child site.
    if (roles.indexOf(OrgRoles.DiscoveryAgent) > -1) {
        organizationsService.hasOrgDiscoveryAgentAccess(orgName).then((hasAccess) => {
            if (hasAccess) {
                console.log('Roles Guard: Guard result: ', true);
                return true;
            } else {
                return rolesGuardLogicInner(roles, targetRoles);
            }
        });
    } else {
        return rolesGuardLogicInner(roles, targetRoles);
    }
}
function rolesGuardLogicInner(roles: any[], targetRoles: string): boolean {
    let hasAccess = false;
    if (targetRoles != null) {
        roles.forEach((role) => {
            if (targetRoles.indexOf(role) > -1) {
                hasAccess = true;
            }
        });
    }
    console.log('Roles Guard: Guard result: ', hasAccess);
    if (!hasAccess) {
        router.navigate(['/error', '403', 'RFTE105']);
    }
    return hasAccess;
}
