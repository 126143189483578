import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/core/auth';
import { Site } from 'app/sites/shared/site.model';
import { SITE, PING_SITE } from 'app/sites/shared/constants';
import { MessageService } from 'app/core/message.service';
import { Subscription } from 'rxjs';
import { KvsFalsePositivesComponent } from 'app/settings/shared/false-positive/kvs-false-positives.component';

@Component({
    selector: 'kvs-site-false-positive-settings',
    templateUrl: './kvs-false-positive-management.component.html',
    styleUrls: ['./kvs-false-positive-management.component.css'],
})
export class KvsFalsePositiveManagementComponent implements OnInit {
    kvsNotProvisioned: boolean = false;

    loadingComplete: boolean;
    site: Site;
    msgSub: Subscription;
    breadcrumbs = [
        { path: '../..', text: 'VulScan' },
        { path: '..', text: 'Settings' },
        { path: '', text: 'Exclusion Rules' },
    ];
    constructor(private messageService: MessageService, private authService: AuthService) {}

    ngOnInit() {
        this.loadingComplete = false;

        let products = this.authService.getProducts();
        if (!products.KVS) {
            this.loadingComplete = true;
            this.kvsNotProvisioned = true;
        }

        this.msgSub = this.messageService.on(SITE).subscribe((site: Site) => {
            if (!site) return;

            this.site = site;
        });

        this.messageService.broadcast(PING_SITE);

        this.loadingComplete = true;
    }

    ngOnDestroy() {
        this.msgSub.unsubscribe();
    }
}
