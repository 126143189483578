import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';
import { AuthHttp } from 'app/core/auth';

@Injectable({ providedIn: 'root' })
export class ClientViewService {
    private apiUrl: string;

    constructor(private http: AuthHttp) {
        this.apiUrl = environment.apiUrl;
    }

    getClientReportSets(siteId: number): Promise<any[]> {
        let url: string = this.apiUrl + `Sites/${siteId}/Client/ReportSets`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any[]);
    }

    getClientReportList(siteId: number, reportSetName: string): Promise<any[]> {
        let url: string = this.apiUrl + `Sites/${siteId}/Client/Reports/${reportSetName}/List`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any[]);
    }

    downloadClientReport(siteId: number, reportSetName: string, reportName: string): Promise<any> {
        let url: string =
            this.apiUrl +
            `Sites/${siteId}/Client/Reports/${reportSetName}/Download?reportName=${reportName}`;
        return this.http
            .get(url, { observe: 'response' })
            .toPromise()
            .then((res) => (res.status == 200 ? res.body : null));
    }

    downloadClientReportSetZip(siteId: number, reportSetName: string): Promise<any> {
        let url: string =
            this.apiUrl + `Sites/${siteId}/Client/Reports/${reportSetName}/DownloadZip`;
        return this.http
            .get(url, { observe: 'response' })
            .toPromise()
            .then((res) => (res.status == 200 ? res.body : null));
    }

    deletePublishedReport(siteId: number, reportSetName: string, reportName: string): Promise<any> {
        let url: string =
            this.apiUrl +
            `Sites/${siteId}/Client/Reports/${reportSetName}/Report?reportName=${reportName}`;
        return this.http
            .delete(url)
            .toPromise()
            .then((res) => res);
    }

    deletePublishedReportSet(siteId: number, reportSetName: string): Promise<any> {
        let url: string = this.apiUrl + `Sites/${siteId}/Client/Reports/${reportSetName}`;
        return this.http
            .delete(url)
            .toPromise()
            .then((res) => res);
    }
}
