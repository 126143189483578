import { Component, Input, OnInit } from '@angular/core';

@Component({
    templateUrl: './UnauthorizedWirelessConnection.component.html',
})
export class UnauthorizedWirelessConnectionComponent implements OnInit {
    constructor() {}

    @Input()
    set diagData(value) {
        if (!value) return;

        this.diag = value;
    }

    diag: any;

    ngOnInit() {}

    fixListDates(list: any) {
        if (list) {
            for (let item of list) {
                if (
                    item.Timestamp.match(
                        /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/
                    ) &&
                    !isNaN(parseInt(list[item].Timestamp))
                ) {
                    item.Timestamp = parseInt(item.Timestamp) * 1000;
                }

                //if (isNaN(Date.parse(list[item].Timestamp)))
                try {
                    item.Timestamp = new Date(item.Timestamp).toISOString();
                } catch (e) {
                    item.Timestamp = new Date(item.Timestamp * 1000).toISOString();
                }
            }
        }
        return list;
    }
}
