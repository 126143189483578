import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ipRangeRegex } from 'app/shared/regex.constants';
@Injectable({
    providedIn: 'root',
})
export class IpRangeService {
    isValid = new BehaviorSubject<boolean>(false);

    constructor() {}

    setValid(valid: boolean): void {
        this.isValid.next(valid);
    }
    getValid(): Observable<boolean> {
        return this.isValid.asObservable();
    }

    sortIPs(ipRanges): void {
        ipRanges.sort((a, b) => {
            let aParts: string[];
            let bParts: string[];
            if (typeof a === 'string') {
                aParts = a.split('-');
                bParts = b.split('-');
            } else {
                aParts = a.ip.split('-');
                bParts = b.ip.split('-');
            }
            return this.ipv4ToNum(aParts[0]) - this.ipv4ToNum(bParts[0]);
        });
    }

    countIPs(ipRanges: string[], allowHostnames?: boolean): number {
        let ipCount = 0;
        ipRanges.forEach((ip) => {
            ip = ip.trim();
            if (ipRangeRegex.test(ip)) {
                let rangeArray = ip.split('-');
                if (rangeArray.length === 1) {
                    ipCount++;
                } else if (rangeArray.length === 2) {
                    ipCount += this.getIpsInRange(rangeArray[0], rangeArray[1]);
                }
            } else if (allowHostnames && ip.match(/^[a-zA-Z0-9.-]+$/)) {
                ipCount++;
            }
        });
        return ipCount;
    }

    getIpsInRange(start: string, end: string): number {
        let beginning = this.ipv4ToNum(start);
        let ending = this.ipv4ToNum(end);
        if (this.ipv4ToNum(start) > this.ipv4ToNum(end)) {
            beginning = this.ipv4ToNum(end);
            ending = this.ipv4ToNum(start);
        }
        return ending - beginning + 1;
    }

    ipv4ToNum(ip: string): number {
        return ip.split('.').reduce((acc, cur) => (acc << 8) + parseInt(cur, 10), 0);
    }

    numToIpv4(num: number): string {
        return [(num >>> 24) & 255, (num >>> 16) & 255, (num >>> 8) & 255, num & 255].join('.');
    }
}
