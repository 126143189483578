import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

import { EulaService } from './eula.service';

@Component({
    selector: 'sds-eula',
    templateUrl: './eula.component.html',
    styleUrls: ['./eula.component.css'],
})
export class EulaComponent implements OnInit {
    eula: string;

    constructor(private eulaService: EulaService) {}

    ngOnInit() {
        this.eulaService.getEula().then((res) => {
            this.eula = res;
        });
    }
}
