<div class="outerContainer" style="padding: 20px; height: 100vh; overflow: auto">
    <div class="examples">
        <h2>ITC Components</h2>
        <ul>
            <li (click)="selectedView = 'button'" [class.active]="selectedView == 'button'">
                Button
            </li>
            <li (click)="selectedView = 'input'" [class.active]="selectedView == 'input'">Input</li>
            <li (click)="selectedView = 'form'" [class.active]="selectedView == 'form'">Form</li>
            <li (click)="selectedView = 'banner'" [class.active]="selectedView == 'banner'">
                Banner
            </li>
            <li
                (click)="selectedView = 'notification'"
                [class.active]="selectedView == 'notification'">
                Notifications (alerts/toast)
            </li>
            <li (click)="selectedView = 'tooltip'" [class.active]="selectedView == 'tooltip'">
                Tooltip
            </li>
            <li
                (click)="selectedView = 'donut-chart'"
                [class.active]="selectedView == 'donut-chart'">
                Donut Chart
            </li>
            <li (click)="selectedView = 'tabs'" [class.active]="selectedView == 'tabs'">Tabs</li>
            <li (click)="selectedView = 'dialogs'" [class.active]="selectedView == 'dialogs'">
                Dialogs/modals
            </li>
            <li (click)="selectedView = 'tags'" [class.active]="selectedView == 'tags'">Tags</li>
            <li
                (click)="selectedView = 'progress-tracker'"
                [class.active]="selectedView == 'progress-tracker'">
                Progress Tracker
            </li>
            <li (click)="selectedView = 'iprange'" [class.active]="selectedView == 'iprange'">
                IP-Range
            </li>
            <li
                (click)="selectedView = 'status-icon'"
                [class.active]="selectedView == 'status-icon'">
                Status Icons
            </li>
            <li
                (click)="selectedView = 'cmo-status-card'"
                [class.active]="selectedView == 'cmo-status-card'">
                Compliance Monitor Status Card
            </li>
        </ul>
    </div>
    <div class="itc-examples-content">
        <div *ngIf="selectedView == 'button'">
            <h3>ITC Button Component</h3>
            <p>
                <strong>Component</strong>
                : button.component
                <br />
                <strong>Location</strong>
                : app/shared/itc/button/button.component
                <br />
                <strong>KDS Link</strong>
                :
                <a
                    href="https://kaseya.invisionapp.com/dsm/kaseya/kaseya-design-system/nav/5ffd140fa3de61d36eee2dc7/asset/5ffe46d6a3de617615eedf81/tab/design?version=616dd27ab30c5e1b152898d8&mode=preview"
                    target="_blank">
                    https://kaseya.invisionapp.com
                </a>
            </p>
            <div style="max-width: 1000px">
                <table>
                    <tr>
                        <td colspan="6" class="strong">
                            Primary Button - &lt;itc-button type="primary"&gt;
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Button
                            <br />
                            <itc-button type="primary">Primary</itc-button>
                        </td>
                        <td>
                            Disable
                            <br />
                            <itc-button type="primary" [disabled]="true">Primary</itc-button>
                        </td>
                        <td>
                            With Icon
                            <br />
                            <itc-button type="primary" icon="fa-plus">Primary</itc-button>
                        </td>
                        <td>
                            With IconPlacement=after
                            <br />
                            <itc-button type="primary" icon="fa-plus" iconPlacement="after">
                                Primary
                            </itc-button>
                        </td>
                        <td>
                            Loading
                            <br />
                            <itc-button type="primary" [loading]="true">Primary</itc-button>
                        </td>
                        <td>
                            <itc-button type="primary" size="small">Small</itc-button>
                            <br />
                            <br />
                            <itc-button type="primary" size="regular">Regular</itc-button>
                            <br />
                            <br />
                            <itc-button type="primary" size="large">Large</itc-button>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6" class="strong">
                            Secondary Button - &lt;itc-button type="secondary"&gt;
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Button
                            <br />
                            <itc-button type="secondary">Secondary</itc-button>
                        </td>
                        <td>
                            Disabled
                            <br />
                            <itc-button type="secondary" [disabled]="true">Secondary</itc-button>
                        </td>
                        <td>
                            With Icon
                            <br />
                            <itc-button type="secondary" icon="fa-plus">Secondary</itc-button>
                        </td>
                        <td>
                            With IconPlacement=after
                            <br />
                            <itc-button type="secondary" icon="fa-plus" iconPlacement="after">
                                Secondary
                            </itc-button>
                        </td>
                        <td>
                            Loading
                            <br />
                            <itc-button type="secondary" [loading]="true">Secondary</itc-button>
                        </td>
                        <td>
                            <itc-button type="secondary" size="small">Small</itc-button>
                            <br />
                            <br />
                            <itc-button type="secondary" size="regular">Regular</itc-button>
                            <br />
                            <br />
                            <itc-button type="secondary" size="large">Large</itc-button>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6" class="strong">
                            Tertiary Button - &lt;itc-button type="tertiary"&gt;
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Button
                            <br />
                            <itc-button type="tertiary">Tertiary</itc-button>
                        </td>
                        <td>
                            Disabled
                            <br />
                            <itc-button type="tertiary" [disabled]="true">Tertiary</itc-button>
                        </td>
                        <td>
                            With Icon
                            <br />
                            <itc-button type="tertiary" icon="fa-plus">Tertiary</itc-button>
                        </td>
                        <td>
                            With IconPlacement=after
                            <br />
                            <itc-button type="tertiary" icon="fa-plus" iconPlacement="after">
                                Tertiary
                            </itc-button>
                        </td>
                        <td>
                            Loading
                            <br />
                            <itc-button type="tertiary" [loading]="true">Tertiary</itc-button>
                        </td>
                        <td>
                            <itc-button type="tertiary" size="small">Small</itc-button>
                            <br />
                            <br />
                            <itc-button type="tertiary" size="regular">Regular</itc-button>
                            <br />
                            <br />
                            <itc-button type="tertiary" size="large">Large</itc-button>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6" class="strong">
                            Ghost Button - &lt;itc-button type="ghost"&gt;
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Button
                            <br />
                            <itc-button type="ghost">Ghost</itc-button>
                        </td>
                        <td>
                            Disabled
                            <br />
                            <itc-button type="ghost" [disabled]="true">Ghost</itc-button>
                        </td>
                        <td>
                            With Icon
                            <br />
                            <itc-button type="ghost" icon="fa-pencil">Ghost</itc-button>
                        </td>
                        <td>
                            With IconPlacement=after
                            <br />
                            <itc-button type="ghost" icon="fa-pencil" iconPlacement="after">
                                Ghost
                            </itc-button>
                        </td>
                        <td>
                            Loading
                            <br />
                            <itc-button type="ghost" [loading]="true">Ghost</itc-button>
                        </td>
                        <td>
                            <itc-button type="ghost" size="small">Small</itc-button>
                            <br />
                            <br />
                            <itc-button type="ghost" size="regular">Regular</itc-button>
                            <br />
                            <br />
                            <itc-button type="ghost" size="large">Large</itc-button>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6" class="strong">
                            Danger Button - &lt;itc-button type="danger"&gt;
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Button
                            <br />
                            <itc-button type="danger">Danger</itc-button>
                        </td>
                        <td>
                            Disabled
                            <br />
                            <itc-button type="danger" [disabled]="true">Danger</itc-button>
                        </td>
                        <td>
                            With Icon
                            <br />
                            <itc-button type="danger" icon="fa-pencil">Danger</itc-button>
                        </td>
                        <td>
                            With IconPlacement=after
                            <br />
                            <itc-button type="danger" icon="fa-pencil" iconPlacement="after">
                                Danger
                            </itc-button>
                        </td>
                        <td>
                            Loading
                            <br />
                            <itc-button type="danger" [loading]="true">Danger</itc-button>
                        </td>
                        <td>
                            <itc-button type="danger" size="small">Small</itc-button>
                            <br />
                            <br />
                            <itc-button type="danger" size="regular">Regular</itc-button>
                            <br />
                            <br />
                            <itc-button type="danger" size="large">Large</itc-button>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6" clss="strong">
                            Color Button - &lt;itc-button type="color"&gt;
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Color Button
                            <br />
                            <itc-button type="color" color="#77aa77">Color</itc-button>
                        </td>
                        <td>
                            Disabled
                            <br />
                            <itc-button type="color" color="#77aa77" [disabled]="true">
                                Color
                            </itc-button>
                        </td>
                        <td>
                            With Icon
                            <br />
                            <itc-button type="color" color="#77aa77" icon="fa-pencil">
                                Color
                            </itc-button>
                        </td>
                        <td>
                            With IconPlacement=after
                            <br />
                            <itc-button
                                type="color"
                                color="#77aa77"
                                icon="fa-pencil"
                                iconPlacement="after">
                                Color
                            </itc-button>
                        </td>
                        <td>
                            Loading
                            <br />
                            <itc-button type="color" color="#77aa77" [loading]="true">
                                Color
                            </itc-button>
                        </td>
                        <td>
                            <itc-button type="color" color="#77aa77" size="small">Small</itc-button>
                            <br />
                            <br />
                            <itc-button type="color" color="#77aa77" size="regular">
                                Regular
                            </itc-button>
                            <br />
                            <br />
                            <itc-button type="color" color="#77aa77" size="large">Large</itc-button>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6" class="strong">
                            Icon Button - &lt;itc-button type="icon"&gt;
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Icon Button
                            <br />
                            <itc-button type="icon" icon="fa-check"></itc-button>
                        </td>
                        <td>
                            Disabled
                            <Br />
                            <itc-button type="icon" icon="fa-check" [disabled]="true"></itc-button>
                        </td>
                        <td>
                            Loading
                            <Br />
                            <itc-button type="icon" icon="fa-check" [loading]="true"></itc-button>
                        </td>
                        <td>
                            Primary Icon Button
                            <br />
                            (type="primary icon")
                            <br />
                            <itc-button type="primary icon" icon="fa-arrow-left"></itc-button>
                        </td>
                        <td>
                            Secondary Icon Button
                            <br />
                            (type="secondary icon")
                            <br />
                            <itc-button type="secondary icon" icon="fa-arrow-left"></itc-button>
                        </td>
                        <td>
                            Tertiary Icon Button
                            <br />
                            (type="tertiary icon")
                            <br />
                            <itc-button type="tertiary icon" icon="fa-arrow-left"></itc-button>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6" class="strong">
                            Text Button - &lt;itc-button type="text"&gt;
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Text Button
                            <br />
                            <itc-button type="text">Text Button</itc-button>
                        </td>
                        <td>
                            Disabled
                            <Br />
                            <itc-button type="text" [disabled]="true">Text Button</itc-button>
                        </td>
                        <td>
                            Loading
                            <Br />
                            <itc-button type="text" [loading]="true">Text Button</itc-button>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td colspan="6" class="strong">Danger variations</td>
                    </tr>
                    <tr>
                        <td>
                            Danger
                            <br />
                            <itc-button type="danger">Danger</itc-button>
                        </td>
                        <td>
                            Secondary Danger
                            <br />
                            <itc-button type="secondary danger">Secondary</itc-button>
                        </td>
                        <td>
                            Disabled Secondary Danger
                            <br />
                            <itc-button type="secondary danger" [disabled]="true">
                                Secondary
                            </itc-button>
                        </td>
                        <td>
                            Ghost Danger
                            <br />
                            <itc-button type="ghost danger" icon="fa-trash-can">
                                Ghost Button
                            </itc-button>
                        </td>
                        <td>
                            Disabled Ghost Danger
                            <br />
                            <itc-button type="ghost danger" icon="fa-trash-can" [disabled]="true">
                                Ghost Button
                            </itc-button>
                        </td>
                        <td></td>
                    </tr>
                </table>

                <h2 style="margin-top: 20px">Options</h2>
                <table class="options-table ui celled striped table">
                    <tr><td colspan="2">Inputs</td></tr>
                    <tr>
                        <td>[class]</td>
                        <td>
                            Pass in a class to display *NOTE: If using in a modal, with class
                            'approve' or 'cancel' or any of the default classes for the modal, and
                            the button may be disabled, you need to enter it like this...
                            [class]="'approve'" or it'll close when clicked even if disabled
                        </td>
                    </tr>
                    <tr>
                        <td>[type]</td>
                        <td>
                            Type of button: primary (default), secondary, tertiary, color, icon,
                            text, danger, ghost, ghost danger, ghost secondary
                            <br />
                            Icon type is just icon with no text and smaller padding and margins, and
                            Text type is just text, only a tiny bit of top and bottom padding,
                            nothing left and right.
                            <br />
                            Note: Most types can be combined, like 'primary icon' will give you an
                            orange icon, let me know any we need that don't work together.
                        </td>
                    </tr>
                    <tr>
                        <td>[color]</td>
                        <td>
                            If type='color' you need to pass in a color for the button. If the type
                            is not color this will be ignored. The button component will add a hover
                            color of the passed in color darkened by 20%. This will work for hex,
                            rgb and rgba.
                        </td>
                    </tr>
                    <tr>
                        <td>[disabled]</td>
                        <td>Disable button and don't allow clicking</td>
                    </tr>
                    <tr>
                        <td>[size]</td>
                        <td>Size of button: small, regular (default) large</td>
                    </tr>
                    <tr>
                        <td>[icon]</td>
                        <td>Icon to display</td>
                    </tr>
                    <tr>
                        <td>[iconPlacement]</td>
                        <td>Location of icon, before (default) or after</td>
                    </tr>
                    <tr>
                        <td>[loading]</td>
                        <td>Show loading animation: false (default) or true</td>
                    </tr>
                    <tr>
                        <td>[link]</td>
                        <td>Link to click to if it's a link (not sure if we need this)</td>
                    </tr>
                    <tr>
                        <td>[params]</td>
                        <td>Params to attach to link (not sure if we need this)</td>
                    </tr>
                    <tr>
                        <td>[label]</td>
                        <td>
                            Added to match sds-button, but I'd prefer if we used the text content
                            (ng-content) in the button. But just in case someone goes that route
                            when copying over from sds-button I'm adding this in.
                        </td>
                    </tr>
                </table>
                <table class="options-table ui celled striped table">
                    <tr><td colspan="2">Outputs</td></tr>
                    <tr>
                        <td>(onclick)</td>
                        <td>
                            Method to run when button is clicked (has a 500ms timeout, and will not
                            work if button is disabled or loading)
                        </td>
                    </tr>
                </table>
            </div>
        </div>

        <div *ngIf="selectedView == 'input'">
            <h3>ITC Input Components</h3>
            <p>
                <strong>Component</strong>
                :
                <br />
                <strong>Location</strong>
                : app/shared/itc/input/*
                <br />
                <strong>KDS Link</strong>
                :
                <a
                    href="https://kaseya.invisionapp.com/dsm/kaseya/kaseya-design-system/nav/5ffd140fa3de61d36eee2dc7/asset/5ffe46d6a3de617615eedf81/tab/design?version=616dd27ab30c5e1b152898d8&mode=preview"
                    target="_blank">
                    https://kaseya.invisionapp.com
                </a>
            </p>
            <div style="max-width: 1000px">
                <table>
                    <tr>
                        <td colspan="4" class="strong">Checkbox - &lt;itc-checkbox&gt;</td>
                    </tr>
                    <tr>
                        <td>
                            <itc-checkbox
                                label="ngModel Example"
                                [(ngModel)]="checkboxModel"></itc-checkbox>
                            <br />
                            <button
                                (click)="checkboxModel = true"
                                style="margin-top: 4px; white-space: nowrap">
                                Update model to true
                            </button>
                            <br />
                            <button
                                (click)="checkboxModel = false"
                                style="margin-top: 4px; white-space: nowrap">
                                Update model to false
                            </button>
                            <br />
                            <button
                                (click)="checkboxModel = 'mixed'"
                                style="margin-top: 4px; white-space: nowrap">
                                Update model to 'mixed'
                            </button>
                            <br />
                            Model value: {{ checkboxModel }}
                        </td>
                        <td>
                            <itc-checkbox
                                label="FormControl Example"
                                [control]="checkboxControl"></itc-checkbox>
                            <br />
                            <button
                                (click)="checkboxControl.setValue(true)"
                                style="margin-top: 4px; white-space: nowrap">
                                Update control to true
                            </button>
                            <br />
                            <button
                                (click)="checkboxControl.setValue(false)"
                                style="margin-top: 4px; white-space: nowrap">
                                Update control to false
                            </button>
                            <br />
                            <button
                                (click)="checkboxControl.setValue('mixed')"
                                style="margin-top: 4px; white-space: nowrap">
                                Update control to 'mixed'
                            </button>
                            <br />
                            Control Value: {{ checkboxControl.value }}
                        </td>
                        <td>
                            <itc-checkbox
                                label="Disabled checkbox"
                                [(ngModel)]="checkboxModelDisabled"
                                [disabled]="true"></itc-checkbox>
                            <br />
                            <itc-checkbox
                                label="Disabled checked checkbox"
                                [(ngModel)]="checkboxModelDisabledTrue"
                                [disabled]="true"></itc-checkbox>
                        </td>
                        <td>
                            <itc-checkbox
                                label="Label pos. before"
                                labelPosition="before"></itc-checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <itc-checkbox
                                label="Select All"
                                [(ngModel)]="checkboxModelIndeterminate"></itc-checkbox>
                            <br />
                            <itc-checkbox label="Option 1" [nested]="true"></itc-checkbox>
                            <br />
                            <itc-checkbox
                                label="Option 2 (disabled)"
                                [disabled]="true"
                                [nested]="true"></itc-checkbox>
                            <br />
                            <itc-checkbox label="Option 3" [nested]="true"></itc-checkbox>
                        </td>
                        <td colspan="3">
                            This component doesn't handle the logic for if an item should be
                            inderminate or not when you click other items. If the control or modal
                            are set to 'mixed' it will display as indertimate. To nest the items
                            under a parent one set [nested]="true"
                        </td>
                    </tr>
                    <tr>
                        <td colspan="4">&lt;itc-toggle&gt;</td>
                    </tr>
                    <tr>
                        <td>
                            <itc-toggle labelOff="Label Off" labelOn="Label On"></itc-toggle>
                            <br />
                            <itc-toggle
                                labelOff="Small Size On"
                                labelOn="Small Size Off"
                                [small]="true"></itc-toggle>
                        </td>
                        <td>
                            <itc-toggle
                                [(ngModel)]="toggleModel"
                                labelOff="Using Model"
                                labelOn="Using Model"></itc-toggle>
                            <button (click)="toggleModel = !toggleModel" style="margin-top: 5px">
                                Toggle the toggle model.
                            </button>
                            <div>Model Value: {{ toggleModel }}</div>
                        </td>
                        <td>
                            <itc-toggle
                                [control]="toggleCtrl"
                                labelOff="Using FormControl"
                                labelOn="Using FormControl"></itc-toggle>
                            <button
                                (click)="toggleCtrl.setValue(!toggleCtrl.value)"
                                style="margin-top: 5px">
                                Toggle the FormControl value.
                            </button>
                            <div>Control Value: {{ toggleCtrl.value }}</div>
                        </td>
                        <td>
                            <itc-toggle
                                labelOff="Disabled toggle"
                                labelOn="Disabled toggle"
                                [disabled]="true"></itc-toggle>
                            <br />
                            <itc-toggle
                                [(ngModel)]="toggleOnDisabledModel"
                                labelOff="Disabled toggle"
                                labelOn="Disabled toggle"
                                [disabled]="true"></itc-toggle>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="4">&lt;itc-radio&gt;</td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            ngModel: {{ radio1Model }}
                            <button (click)="radio1Model = 3">Set ngModel to 3</button>
                            <br />
                            <itc-radio
                                [(ngModel)]="radio1Model"
                                name="var1"
                                value="1"
                                className="testClass"
                                label="Label for Radio 1"></itc-radio>
                            <br />
                            <itc-radio
                                [(ngModel)]="radio1Model"
                                name="var1"
                                value="2"
                                className="testClass"
                                label="Label for Radio 2"
                                [disabled]="true"></itc-radio>
                            <br />
                            <itc-radio
                                [(ngModel)]="radio1Model"
                                name="var1"
                                value="3"
                                className="testClass"
                                label="Label for Radio 3"></itc-radio>
                            <br />
                            <itc-radio
                                [(ngModel)]="radio1Model"
                                name="var1"
                                value="4"
                                className="testClass"
                                label="Label for Radio 4"></itc-radio>
                        </td>
                        <td colspan="2">
                            formControl: {{ radio1Ctrl.value }}
                            <button (click)="radio1Ctrl.setValue(3)">Set formcontrol to 3</button>
                            <br />
                            <itc-radio
                                [control]="radio1Ctrl"
                                name="var2"
                                value="1"
                                className="testClass"
                                label="Label for Radio 1"></itc-radio>
                            <br />
                            <itc-radio
                                [control]="radio1Ctrl"
                                name="var2"
                                value="2"
                                className="testClass"
                                label="Label for Radio 2"
                                [disabled]="true"></itc-radio>
                            <br />
                            <itc-radio
                                [control]="radio1Ctrl"
                                name="var2"
                                value="3"
                                className="testClass"
                                label="Label for Radio 3"></itc-radio>
                            <br />
                            <itc-radio
                                [control]="radio1Ctrl"
                                name="var2"
                                value="4"
                                className="testClass"
                                label="Label for Radio 4"></itc-radio>
                        </td>
                    </tr>
                </table>

                <h2 style="margin-top: 20px">Checkbox Options</h2>
                <table class="options-table ui celled striped table">
                    <tr><td colspan="2">Inputs</td></tr>
                    <tr>
                        <td>[control]</td>
                        <td>
                            Pass in a FormControl if you want to use that.
                            <br />
                            Values accepted: true (checked), false (not checked), 'mixed'
                            (indeterminate);
                        </td>
                    </tr>
                    <tr>
                        <td>[(ngModel)]</td>
                        <td>
                            You can use either a formcontrol or model with this component
                            <br />
                            Values accepted: true (checked), false (not checked), 'mixed'
                            (indeterminate);
                        </td>
                    </tr>
                    <tr>
                        <td>[label]</td>
                        <td>
                            Label text to display, this can also be based in as ng-content in the
                            element
                        </td>
                    </tr>
                    <tr>
                        <td>[labelPosition]</td>
                        <td>Placement of label: before, after (default)</td>
                    </tr>
                    <tr>
                        <td>[disbled]</td>
                        <td>
                            Disable checkbox. If using reactive forms you should disable that way
                            instead of using this label.
                        </td>
                    </tr>
                    <tr>
                        <td>[name]</td>
                        <td>Input name</td>
                    </tr>
                    <tr>
                        <td>[value]</td>
                        <td>Input Value</td>
                    </tr>
                    <tr>
                        <td>[nested]</td>
                        <td>
                            Whether the checkbox should be nested because it's under a parent item.
                            True, False (default)
                        </td>
                    </tr>
                    <tr>
                        <td>[checked]</td>
                        <td>
                            This is an option for places we have checkboxes that don't deal with an
                            ngmodel or control. For example, on Standards and Controls in GRC. We
                            are not using the value of the checkbox, we're instead using an
                            onChanged() to tweak the variable in the control object.
                        </td>
                    </tr>
                    <tr>
                        <td>[className]</td>
                        <td>Extra class to add to parent item</td>
                    </tr>
                </table>
                <table class="options-table ui celled striped table">
                    <tr><td colspan="2">Outputs</td></tr>
                    <tr>
                        <td>(onChanged)</td>
                        <td>
                            Method to run when checkbox value is changed. Returns whether checked or
                            not.
                        </td>
                    </tr>
                </table>

                <h2 style="margin-top: 20px">Toggle Options</h2>
                <table class="options-table ui celled striped table">
                    <tr><td colspan="2">Inputs</td></tr>
                    <tr>
                        <td>[control]</td>
                        <td>
                            Pass in a FormControl if you want to use that.
                            <br />
                            Values accepted: true (checked), false (not checked), 'mixed'
                            (indeterminate);
                        </td>
                    </tr>
                    <tr>
                        <td>[(ngModel)]</td>
                        <td>
                            You can use either a formcontrol or model with this component
                            <br />
                            Values accepted: true (checked), false (not checked), 'mixed'
                            (indeterminate);
                        </td>
                    </tr>
                    <tr>
                        <td>[label]</td>
                        <td>Label text to display whether off or on</td>
                    </tr>
                    <tr>
                        <td>[labelOn]</td>
                        <td>Label text to display when the toggle is On</td>
                    </tr>
                    <tr>
                        <td>[labelOff]</td>
                        <td>Label text to display when the toggle is Off</td>
                    </tr>
                    <tr>
                        <td>[labelPosition]</td>
                        <td>Placement of label: before, after (default)</td>
                    </tr>
                    <tr>
                        <td>[disbled]</td>
                        <td>Disable checkbox</td>
                    </tr>
                    <tr>
                        <td>[className]</td>
                        <td>Extra class to add to parent item</td>
                    </tr>
                </table>
                <table class="options-table ui celled striped table">
                    <tr><td colspan="2">Outputs</td></tr>
                    <tr>
                        <td>(onChanged)</td>
                        <td>Method to run when toggle is changed. Will return boolean.</td>
                    </tr>
                </table>

                <h2 style="margin-top: 20px">Radio Options</h2>
                <table class="options-table ui celled striped table">
                    <tr><td colspan="2">Inputs</td></tr>
                    <tr>
                        <td>[control]</td>
                        <td>Pass in a FormControl if you want to use that.</td>
                    </tr>
                    <tr>
                        <td>[(ngModel)]</td>
                        <td>You can use either a formcontrol or model with this component</td>
                    </tr>
                    <tr>
                        <td>[label]</td>
                        <td>
                            Label text to display, this can also be based in as ng-content in the
                            element
                        </td>
                    </tr>
                    <tr>
                        <td>[labelPosition]</td>
                        <td>Placement of label: before, after (default)</td>
                    </tr>
                    <tr>
                        <td>[disbled]</td>
                        <td>
                            Disable checkbox. If using reactive forms you should disable that way
                            instead of using this label.
                        </td>
                    </tr>
                    <tr>
                        <td>[name]</td>
                        <td>Input name</td>
                    </tr>
                    <tr>
                        <td>[value]</td>
                        <td>Input value</td>
                    </tr>
                    <tr>
                        <td>[className]</td>
                        <td>Extra css classes to add to parent container</td>
                    </tr>
                </table>
                <table class="options-table ui celled striped table">
                    <tr><td colspan="2">Outputs</td></tr>
                    <tr>
                        <td>(onChanged)</td>
                        <td>Method to run when radio button is changed.</td>
                    </tr>
                </table>
            </div>
        </div>

        <div *ngIf="selectedView == 'form'">
            <h3>ITC Form Styles</h3>
            <p>
                <strong>KDS Link</strong>
                :
                <a
                    href="https://kaseya.invisionapp.com/dsm/kaseya/kaseya-design-system/nav/5ffd140fa3de61d36eee2dc7/asset/5ffe46d6a3de617615eedf81/tab/design?version=616dd27ab30c5e1b152898d8&mode=preview"
                    target="_blank">
                    https://kaseya.invisionapp.com
                </a>
            </p>
            <div style="max-width: 1000px">
                <table style="width: 600px">
                    <tr>
                        <td class="strong">Form</td>
                    </tr>
                    <tr>
                        <td style="padding: 8px 16px">
                            <label>Label</label>
                            <input type="text" placeholder="Placeholder text" />
                        </td>
                    </tr>
                    <tr>
                        <td style="padding: 8px 16px">
                            <label>Label</label>
                            <input type="text" placeholder="Placeholder text" value="Text" />
                        </td>
                    </tr>
                    <tr>
                        <td style="padding: 8px 16px">
                            <label>Disabled Field</label>
                            <input type="text" placeholder="Disabled placeholder text" disabled />
                        </td>
                    </tr>
                    <tr>
                        <td style="padding: 8px 16px">
                            <label>Read Only</label>
                            <input
                                type="text"
                                placeholder="Placeholder text"
                                value="Office 365"
                                readonly />
                        </td>
                    </tr>
                    <tr>
                        <td style="padding: 8px 16px">
                            <label>Select Dropdown</label>
                            <sm-select>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                                <option value="4">Four</option>
                            </sm-select>
                        </td>
                    </tr>
                </table>
            </div>
        </div>

        <div *ngIf="selectedView == 'banner'">
            <h3>ITC Banner Component</h3>
            <p>
                <strong>Component</strong>
                : banner.component
                <br />
                <strong>Location</strong>
                : app/shared/itc/banner/banner.component
                <br />
                <strong>KDS Link</strong>
                :
                <a
                    href="https://kaseya.invisionapp.com/dsm/kaseya/kaseya-design-system/nav/5ffd140fa3de61d36eee2dc7/asset/6021bf9add7744f5d03c33e7/tab/design?version=616dd27ab30c5e1b152898d8&mode=preview"
                    target="_blank">
                    https://kaseya.invisionapp.com
                </a>
            </p>

            <div style="max-width: 1000px">
                <table>
                    <tr>
                        <td>
                            &lt;itc-banner type="info"&gt;
                            <br />
                            <itc-banner type="info" title="Info">Info text.</itc-banner>
                            <itc-banner
                                type="info"
                                title="Closable info"
                                closable="true"
                                (onClose)="bannerOnClose('Info banner closed')">
                                An info banner you can close.
                                <a href="#">With a link</a>
                            </itc-banner>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            &lt;itc-banner type="success"&gt;
                            <br />
                            <itc-banner type="success" title="success">success text.</itc-banner>
                            <itc-banner
                                type="success"
                                title="Closable Success"
                                closable="true"
                                (onClose)="bannerOnClose('Success banner closed')">
                                A success banner you can close.
                            </itc-banner>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            &lt;itc-banner type="warning"&gt;
                            <br />
                            <itc-banner type="warning" title="Warning">Warning text.</itc-banner>
                            <itc-banner
                                type="warning"
                                title="Closable Warning"
                                closable="true"
                                (onClose)="bannerOnClose('Warning banner closed')">
                                A warning banner you can close.
                            </itc-banner>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            &lt;itc-banner type="error"&gt;
                            <br />
                            <itc-banner type="error" title="Error">Error text.</itc-banner>
                            <itc-banner
                                type="error"
                                title="Closable Error"
                                closable="true"
                                (onClose)="bannerOnClose('Error banner closed')">
                                An error banner you can close.
                            </itc-banner>
                        </td>
                    </tr>
                </table>

                <h2 style="margin-top: 20px">Options</h2>
                <table class="options-table ui celled striped table">
                    <tr><td colspan="2">Inputs</td></tr>
                    <tr>
                        <td>[className]: string</td>
                        <td>Default: ""</td>
                        <td>Pass in a class to display</td>
                    </tr>
                    <tr>
                        <td>[title]: string</td>
                        <td>Default: ""</td>
                        <td>Title to display in bold</td>
                    </tr>
                    <tr>
                        <td>[type]: string</td>
                        <td>Default: "info"</td>
                        <td>Type of banner, 'info', 'success', 'warning', 'error'</td>
                    </tr>
                    <tr>
                        <td>[closable]: boolean</td>
                        <td>Default: false</td>
                        <td>Whether to allow banner to be closed. If true show X.</td>
                    </tr>
                    <tr>
                        <td>[notification]</td>
                        <td></td>
                        <td>
                            Don't do anyhting with this, it's used for displaying a banner as a
                            notification type
                        </td>
                    </tr>
                </table>
                <table class="options-table ui celled striped table">
                    <tr><td colspan="2">Outputs</td></tr>
                    <tr>
                        <td>(onClose)</td>
                        <td>Method to run when button is clicked (as long as it's not disabled)</td>
                    </tr>
                </table>
            </div>
        </div>

        <div *ngIf="selectedView == 'notification'">
            <h3>ITC Notification Service/Components</h3>
            <p>
                <strong>Service</strong>
                : notfication.service
                <br />
                <strong>Location</strong>
                : app/shared/itc/notification/notification.service
                <br />
                <strong>KDS Link</strong>
                :
                <a
                    href="https://kaseya.invisionapp.com/dsm/kaseya/kaseya-design-system/nav/5ffd140fa3de61d36eee2dc7/asset/6021bf9add7744f5d03c33e7?version=616dd27ab30c5e1b152898d8&mode=preview"
                    target="_blank">
                    https://kaseya.invisionapp.com
                </a>
            </p>
            <p>
                The notification service will allow you to display an alert notification at a
                specific location (Ex, login page with wrong password) or display a toast.
            </p>
            <div style="max-width: 1000px">
                <h5>Notification Service Banners</h5>
                <p>
                    To display a banner notification, you have to add the component
                    &lt;itc-notification-banner&gt;&lt;/itc-notification-banner&gt; at the location
                    you wish the banners to show up.
                </p>
                <fieldset style="border: 1px solid #ccc; margin-bottom: 20px">
                    <legend>&nbsp;&nbsp; Example: &nbsp;&nbsp;</legend>
                    <form [formGroup]="notificationBannerForm">
                        <select formControlName="bannerType" style="margin-bottom: 10px">
                            <option selected="selected">info</option>
                            <option>success</option>
                            <option>warning</option>
                            <option>error</option>
                            <option>download</option>
                        </select>
                        <input
                            type="text"
                            placeholder="Banner Title"
                            formControlName="bannerTitle" />
                        <input type="text" placeholder="Banner Text" formControlName="bannerText" />
                        <label>
                            <input
                                type="radio"
                                value="location1"
                                name="bannerLocation"
                                formControlName="bannerLocation" />
                            Location 1
                        </label>
                        <label>
                            <input
                                type="radio"
                                value="location2"
                                name="bannerLocation"
                                formControlName="bannerLocation" />
                            Location 2
                        </label>
                        <br />
                        <button (click)="showBannerNotification()" style="margin-top: 10px">
                            Display banner notification
                        </button>
                    </form>
                </fieldset>
                <div style="margin-bottom: 20px; min-height: 40px; border: 1px solid #ccc">
                    Location 1 (allowMultiple="true"):
                    <itc-notification-banner
                        id="location1"
                        [allowMultiple]="true"></itc-notification-banner>
                </div>
                <div style="margin-bottom: 20px; min-height: 40px; border: 1px solid #ccc">
                    Location 2 (allowMultiple="false"):
                    <itc-notification-banner id="location2"></itc-notification-banner>
                </div>
                <table class="options-table ui celled striped table">
                    <tr><td colspan="3">Inputs</td></tr>
                    <tr>
                        <td>[id]: string</td>
                        <td>Default: 'default-notification'</td>
                        <td>
                            Any calls without a location will default to 'default-notification' so
                            you do not need to add an id unless you have multiple places that might
                            display the banners in.
                        </td>
                    </tr>
                    <tr>
                        <td>[allowMultiple]: boolean</td>
                        <td>Default: false</td>
                        <td>
                            If set to true, multiple banners can show up in the same spot. If set to
                            false, the new banner overwrites the old one.
                        </td>
                    </tr>
                </table>

                <table class="options-table ui celled striped table">
                    <tr><td colspan="3">Methods</td></tr>
                    <tr>
                        <td>
                            this.notificationService.banner.info(title: string, message: string,
                            id?: string)
                        </td>
                        <td>Displays an info banner</td>
                    </tr>
                    <tr>
                        <td>
                            this.notificationService.banner.success(title: string, message: string,
                            id?: string)
                        </td>
                        <td>Displays a success banner</td>
                    </tr>
                    <tr>
                        <td>
                            this.notificationService.banner.warning(title: string, message: string,
                            id?: string)
                        </td>
                        <td>Displays a warning banner</td>
                    </tr>
                    <tr>
                        <td>
                            this.notificationService.banner.error(title: string, message: string,
                            id?: string)
                        </td>
                        <td>Displays an error banner</td>
                    </tr>
                </table>
            </div>

            <hr style="margin: 40px 20px" />

            <div style="max-width: 1000px">
                <h5>Notification Service Toasts</h5>
                <p>The notification service also allows us to display alerts as toasts.</p>
                <fieldset style="border: 1px solid #ccc; margin-bottom: 20px">
                    <legend>&nbsp;&nbsp; Example: &nbsp;&nbsp;</legend>
                    <form [formGroup]="notificationToastForm">
                        <select formControlName="toastType" style="margin-bottom: 10px">
                            <option selected="selected">info</option>
                            <option>success</option>
                            <option>warning</option>
                            <option>error</option>
                            <option>download</option>
                        </select>
                        <input type="text" placeholder="Toast Title" formControlName="toastTitle" />
                        <input type="text" placeholder="Toast Text" formControlName="toastText" />
                        <button (click)="showToastNotification()" style="margin-top: 10px">
                            Display Toast notification
                        </button>
                    </form>
                </fieldset>

                <table class="options-table ui celled striped table">
                    <tr><td colspan="3">Methods</td></tr>
                    <tr>
                        <td>this.notificationService.toast.info(title: string, message: string)</td>
                        <td>Displays an info banner</td>
                    </tr>
                    <tr>
                        <td>
                            this.notificationService.toast.success(title: string, message: string)
                        </td>
                        <td>Displays a success banner</td>
                    </tr>
                    <tr>
                        <td>
                            this.notificationService.toast.warning(title: string, message: string)
                        </td>
                        <td>Displays a warning banner</td>
                    </tr>
                    <tr>
                        <td>
                            this.notificationService.toast.error(title: string, message: string)
                        </td>
                        <td>Displays an error banner</td>
                    </tr>
                    <tr>
                        <td>this.notificationService.download(title: string, message: string)</td>
                        <td>Displays a downloads banner</td>
                    </tr>
                </table>
            </div>
        </div>

        <div *ngIf="selectedView == 'tooltip'">
            <h3>ITC Tooltip Directive</h3>
            <p>
                <strong>Directive</strong>
                : itc-tooltip.directive
                <br />
                <strong>Location</strong>
                : app/shared/itc/itc-tooltip.directive.ts
                <br />
                <strong>KDS Link</strong>
                :
                <a
                    href="https://kaseya.invisionapp.com/dsm/kaseya/kaseya-design-system/nav/5ffd140fa3de61d36eee2dc7/asset/60399c6f6bdd4c3d5eaf9b95/tab/design?version=61b3e2c6df4ff6998daaa6ce&mode=preview"
                    target="_blank">
                    https://kaseya.invisionapp.com
                </a>
            </p>
            <div style="max-width: 1000px">
                <table>
                    <tr>
                        <td colspan="3" class="strong">
                            Tooltips /
                            ttPosition="left|top-left|top|top-right|right|bottom-right|bottom|bottom-left"
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3">
                            <table class="itc-tooltip-example">
                                <tr>
                                    <td>
                                        <button
                                            tooltip="Top-left tooltip with a longer tooltip"
                                            ttPosition="top-left">
                                            Top-Left
                                        </button>
                                    </td>
                                    <td>
                                        <button
                                            tooltip="Top tooltip with a longer tooltip"
                                            ttPosition="top">
                                            Top
                                        </button>
                                    </td>
                                    <td>
                                        <button
                                            tooltip="Top-right tooltip with a longer tooltip"
                                            ttPosition="top-right">
                                            Top-right
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <button
                                            tooltip="Left tooltip with a longer tooltip"
                                            ttPosition="left">
                                            Left
                                        </button>
                                    </td>
                                    <td></td>
                                    <td>
                                        <button
                                            tooltip="Right tooltip with a longer tooltip"
                                            ttPosition="right">
                                            Right
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <button
                                            tooltip="Bottom-left tooltip with a longer tooltip"
                                            ttPosition="bottom-left">
                                            Bottom-Left
                                        </button>
                                    </td>
                                    <td>
                                        <button
                                            tooltip="Bottom tooltip with a longer tooltip"
                                            ttPosition="bottom">
                                            Bottom
                                        </button>
                                    </td>
                                    <td>
                                        <button
                                            tooltip="Bottom-right tooltip with a longer tooltip"
                                            ttPosition="bottom-right">
                                            Bottom-Right
                                        </button>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3" class="strong">Tooltip with titles and link</td>
                    </tr>
                    <tr>
                        <td colspan="3">
                            <button
                                tooltip="Import IT Glue Checklists to standardize and track your progress of critical tasks in your BMS ticket."
                                ttPosition="top"
                                ttTitle="IT Glue Checklists"
                                [ttLink]="{
                                    href: 'https://www.kaseya.com',
                                    text: 'Learn More',
                                    newTab: true
                                }">
                                Tooltip with all the things
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3" class="strong">
                            Tooltip Triggers / ttTrigger="hover|click|focus"
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3" style="text-align: left">
                            <button tooltip="On hover tooltip" ttPosition="right" ttTrigger="hover">
                                On Hover Tooltip
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3" style="text-align: left">
                            <button tooltip="On click tooltip" ttPosition="right" ttTrigger="click">
                                On Click Tooltip
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3" style="text-align: left">
                            <input
                                type="text"
                                tooltip="Here's a tooltip on focus"
                                ttPosition="right"
                                ttTrigger="focus"
                                placeholder="Tooltip on focus"
                                style="width: 500px" />
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3">
                            <button
                                tooltip="Multiple links test."
                                ttPosition="top"
                                ttTitle="Multiple links"
                                [ttLink]="[{
                                    href: 'https://www.rapidfiretools.com/',
                                    text: 'RFT',
                                    newTab: true
                                },{
                                    href: 'https://www.kaseya.com',
                                    text: 'Kaseya',
                                    newTab: true
                                }]">
                                Tooltip with multiple links
                            </button>
                        </td>
                    </tr>
                </table>

                <h2 style="margin-top: 20px">Options</h2>
                <table class="options-table ui celled striped table">
                    <tr><td colspan="3">Inputs</td></tr>
                    <tr>
                        <td style="width: 190px">[tooltip]: string</td>
                        <td style="width: 130px">Default: ""</td>
                        <td>Text to display in tooltip. (text only, no HTML)</td>
                    </tr>
                    <tr>
                        <td>[ttTrigger]: string</td>
                        <td>Default: "hover"</td>
                        <td>How to trigger the tooltip. hover, click, focus</td>
                    </tr>
                    <tr>
                        <td>[ttPosition]: string</td>
                        <td>Default: "top"</td>
                        <td>
                            Location to display tooltip. left, top-left, top, top-right, right,
                            bottom-right, bottom, bottom-left
                        </td>
                    </tr>
                    <tr>
                        <td>[ttHidden]: boolean</td>
                        <td>Default: false</td>
                        <td>
                            A variable to decide if tooltip should show or not. Added for sidebar
                            component, to only show tooltips when sidebar is collapsed.
                        </td>
                    </tr>
                    <tr>
                        <td>[ttOffset]: number</td>
                        <td>Default: 4</td>
                        <td>How far to offset pointer from host</td>
                    </tr>
                    <tr>
                        <td>[ttDelay]: number</td>
                        <td>Default: 250</td>
                        <td>How long to wait before displaying tooltip</td>
                    </tr>
                    <tr>
                        <td>[ttTitle]: string</td>
                        <td>Default: ''</td>
                        <td>Title to display at top of tooltip</td>
                    </tr>
                    <tr>
                        <td>
                            [ttLink]: string | &#123; href: string, text: string, newTab?: boolean &#125; | [&#123; href: string, text: string, newTab?: boolean, returnValue?: any &#125;]
                        </td>
                        <td>Default: No link</td>
                        <td>Link to display in bottom right of tooltip<br>Links array to display in bottom right of tooltip</td>
                    </tr>
                </table>
            </div>
        </div>

        <div *ngIf="selectedView == 'donut-chart'">
            <h3>ITC Donut Chart</h3>
            <p>
                <strong>Component</strong>
                : donut-chart.component
                <br />
                <strong>Location</strong>
                : app/shared/itc/donut-chart/
                <br />
                <strong>KDS Link</strong>
                :
                <a
                    href="https://www.figma.com/design/kvZYpc6UmBKR0P8B1lF68q/Components?node-id=28335-21795&t=VvAbW3nidMfgofhs-0"
                    target="_blank">
                    https://www.figma.com
                </a>
            </p>
            <div style="max-width: 1000px">
                <table>
                    <tr>
                        <td colspan="2"><strong>ITC Donut Chart</strong></td>
                    </tr>
                    <tr>
                        <td colspan="2">150px x 150px</td>
                        <td colspan="2">200px X 200px</td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <itc-donut-chart
                                [data]="{
                                    labels: ['Purple Beans', 'Green Beans'],
                                    datasets: [{
                                        data: [20, 20],
                                        backgroundColor: ['#9b0a83', '#3d9d3a'],
                                    }]
                                }"
                                height="150px"
                                width="150px"></itc-donut-chart>
                        </td>
                        <td colspan="2">
                            <div class="testsizechange">
                                <itc-donut-chart
                                    [data]="{
                                    labels: ['Purple Beans', 'Green Beans'],
                                    datasets: [{
                                        data: [75, 25],
                                        backgroundColor: ['#9b0a83', '#3d9d3a'],
                                    }]
                                }"
                                    height="200px"
                                    width="200px"></itc-donut-chart>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="4">
                            <strong>Donut Chart with options overridden</strong>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            Pie Chart (Cutout: 0)
                        </td>
                        <td colspan="2">
                            Fat doughnut chart (Cutout: 25%)
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <itc-donut-chart
                                [data]="{
                                    labels: ['Purple Beans', 'Green Beans'],
                                    datasets: [{
                                        data: [50, 20],
                                        backgroundColor: ['#9b0a83', '#3d9d3a'],
                                    }]
                                }"
                                [option]="{
                                    cutout: '0'
                                }"
                                height="150px" width="150px"></itc-donut-chart>
                        </td>
                        <td colspan="2">
                            <itc-donut-chart
                                [data]="{
                                    labels: ['Purple Beans', 'Green Beans'],
                                    datasets: [{
                                        data: [10, 10],
                                        backgroundColor: ['#9b0a83', '#3d9d3a'],
                                    }]
                                }"
                                [option]="{
                                    cutout: '25%',
                                }"
                                height="150px"
                                width="150px"></itc-donut-chart>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="4">
                            <strong>
                                Donut Chart with Center Text
                            </strong>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            Center Text
                        </td>
                        <td colspan="2">
                            Center Text with Offset
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <itc-donut-chart
                                [data]="{
                                    labels: ['Purple Beans', 'Green Beans', 'Yellow Beans', 'Red Beans'],
                                    datasets: [{
                                        data: [20, 20, 35, 21],
                                        backgroundColor: ['#9b0a83', '#3d9d3a', '#FFD700', '#FF00FF'],
                                    }]
                                }"
                                (totalVisibleNumber)="centerTextDonutChartTotalNumber = $event"
                                [centerTexts]="[{
                                    font: '500 18px Roboto',
                                    value: centerTextDonutChartTotalNumber?.toLocaleString(),
                                }]"
                                height="150px"
                                width="250px"></itc-donut-chart>
                        </td>
                        <td colspan="2">
                            <itc-donut-chart
                                [data]="{
                                    labels: ['Purple Beans', 'Green Beans', 'Yellow Beans', 'Red Beans'],
                                    datasets: [{
                                        data: [20, 20, 35, 21],
                                        backgroundColor: ['#9b0a83', '#3d9d3a', '#FFD700', '#FF00FF'],
                                    }]
                                }"
                                (totalVisibleNumber)="centerTextDonutChartTotalNumber = $event"
                                [centerTexts]="[{
                                    font: '500 18px Roboto',
                                    value: centerTextDonutChartTotalNumber?.toLocaleString(),
                                    offsetX: -10,
                                    offsetY: -10
                                }, {
                                    font: '500 12px Roboto',
                                    value: 'Beans',
                                    offsetX: 15,
                                    offsetY: 20
                                }]"
                                height="150px"
                                width="150px"></itc-donut-chart>
                        </td>
                    </tr>
                </table>
            </div>

            <h2 style="margin-top: 20px">Options</h2>
            <table class="options-table ui celled striped table">
                <tr><td colspan="2">Inputs</td></tr>
                <tr>
                    <td style="width: 20%">
                        [data]: &#123; <br />
                        labels: string[],<br />
                        datasets: &#123;<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;data: number[],<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;backgroundColor: string[] <br />
                        &#125;[] <br />
                        &#125;
                    </td>
                    <td style="width: 15%">Required</td>
                    <td>
                        Chart data to display, must be an array of objects each object consisting
                        of...
                        <br />
                        labels: string[] - The label for each data
                        <br />
                        datasets: ChartData<'doughnut'> (See Note <sup>1</sup>) <br />
                        datasets.data: number[] - Number of items for part of chart
                        <br />
                        datasets.backgroundColor: string[] - Color to display for this part of chart
                        <br />
                        length of labels, datasets.data, datasets.backgroundColor must be equal.
                    </td>
                </tr>
                <tr>
                    <td>[height]: string</td>
                    <td>Required</td>
                    <td>Height of the Chart (must include unit like px).</td>
                </tr>
                <tr>
                    <td>[width]: string</td>
                    <td>Required</td>
                    <td>
                        Weight of the Chart in px (must include unit like px).<br />
                        If height and width differs, chart will center itself.
                    </td>
                </tr>

                <tr>
                    <td>totalVisibleNumber</td>
                    <td>Output&lt;number&gt;</td>
                    <td>
                        Event emitter that returns the total number of data affected by legend click (see note
                        <sup>2</sup>
                        )
                    </td>
                </tr>
                <tr>
                    <td>[centerTexts]: &#123; <br />
                        value:string,<br />
                        font: string,<br />
                        fillStyle?: string,<br />
                        offsetX?: number<br />
                        offsetY?: number<br />
                        &#125;[]
                    </td>
                    <td>Required</td>
                    <td>
                        Center text to display inside donut, you can include as many texts as you like with different
                        font, fillStyle, offsetX, offsetY.
                        <br />
                        value: string - Text to display
                        font: string - Font style for text (e.g. 500 Roboto 16px)
                        fillStyle: string - Color of text (default: #001D30)
                        offsetX: number - Offset from center in x direction (default: 0)
                        offsetY: number - Offset from center in y direction (default: 0)
                    </td>
                </tr>
                <tr>
                    <td>[emptyChartColor]: string</td>
                    <td>Default: '#BFC5CB'</td>
                    <td>Empty donut chart color</td>
                </tr>
                <tr>
                    <td>[showLegend]: boolean</td>
                    <td>Default: false</td>
                    <td>Whether to display legend or not.</td>
                </tr>
                <tr>
                    <td>[plugins]: Plugin[]</td>
                    <td>Optional</td>
                    <td>Any additional plugins to chartjs. See <a>https://www.chartjs.org/docs/latest/developers/plugins.html</a>
                    </td>
                </tr>
            </table>
            <table class="options-table ui celled striped table">
                <tr>
                    <td>Notes</td>
                </tr>
                <tr>
                    <td>
                        If an empty grey donut is desired you can send an empty array to the data
                        input or send 0 in the count attribute for all objects in the array.
                    </td>
                </tr>
                <tr>
                    <td>
                        <sup>1</sup>
                        There are other fields you can customize for doughnut chart like borderColor,
                        hoverBackgroundColor, etc. For the full list, see
                        <a>https://www.chartjs.org/docs/latest/charts/doughnut.html#dataset-properties</a>
                    </td>
                </tr>
                <tr>
                    <td>
                        <sup>2</sup>
                        You might want to use a setter with changeDetectionStrategy if you want to pass in the total
                        number back to the chart
                        <br />
                        <pre>
private _centerTextDonutChartTotalNumber: number;
get centerTextDonutChartTotalNumber(): number &#123;
                        return this._centerTextDonutChartTotalNumber;
&#125;
set centerTextDonutChartTotalNumber(value: number) &#123;
                        this._centerTextDonutChartTotalNumber = value;
                        this.cdr.detectChanges();
&#125;
</pre>
                    </td>
                </tr>
            </table>
        </div>

        <div *ngIf="selectedView == 'tabs'">
            <h3>ITC Tabs Component</h3>
            <p>
                <strong>Component</strong>
                : tabs.component
                <br />
                <strong>Location</strong>
                : app/shared/itc/tabs.component
                <br />
                <strong>KDS Link</strong>
                :
                <a
                    href="https://kaseya.invisionapp.com/dsm/kaseya/kaseya-design-system/nav/5ffd140fa3de61d36eee2dc7/asset/600f8bc75a841d1dc6ce5a0f/tab/design?version=61b3e2c6df4ff6998daaa6ce&mode=preview"
                    target="_blank">
                    https://kaseya.invisionapp.com
                </a>
            </p>
            <div style="max-width: 1000px">
                <table>
                    <tr>
                        <td colspan="2" class="strong">ITC Tabs - &lt;itc-tabs&gt;</td>
                    </tr>
                    <tr>
                        <td>
                            ITC Tabs 'inline' style
                            <br />
                            <itc-tabs [containerStyle]="false">
                                <itc-tab tabTitle="Tab 1">Tab 1 Content</itc-tab>
                                <itc-tab tabTitle="Tab 2">Tab 2 content.</itc-tab>
                                <itc-tab
                                    tabTitle="Tab 3 (disabled)"
                                    [disabled]="true"
                                    tooltip="This is disabled">
                                    Tab 3 content, but it's disabled
                                </itc-tab>
                            </itc-tabs>
                        </td>
                        <td>
                            <pre>
                            &lt;itc-tabs [containerStyle]="false">
                                &lt;itc-tab tabTitle="Tab 1"&gt;
                                    Tab 1 Content
                                &lt;/itc-tab&gt;
                                &lt;itc-tab tabTitle="Tab 2"&gt;
                                    Tab 2 content.
                                &lt;/itc-tab&gt;
                                &lt;itc-tab tabTitle="Tab 3 (disabled)" [disabled]="true"&gt;
                                    Tab 3 content, but it's disabled
                                &lt;/itc-tab&gt;
                            &lt;/itc-tabs&gt;
                            </pre>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            ITC Tabs 'container' style
                            <br />
                            <itc-tabs [containerStyle]="true" (tabChanged)="tabChangedYo($event)">
                                <itc-tab tabTitle="Tab 1">Tab 1 Content</itc-tab>
                                <itc-tab tabTitle="Tab 2">Tab 2 content.</itc-tab>
                                <itc-tab tabTitle="Tab 3 (disabled)" [disabled]="true">
                                    Tab 3 content, but it's disabled
                                </itc-tab>
                            </itc-tabs>
                        </td>
                        <td>
                            <pre>
                                &lt;itc-tabs [containerStyle]="true"&gt;
                                    &lt;itc-tab tabTitle="Tab 1"&gt;
                                        Tab 1 Content
                                    &lt;/itc-tab&gt;
                                    &lt;itc-tab tabTitle="Tab 2"&gt;
                                        Tab 2 content.
                                    &lt;/itc-tab&gt;
                                    &lt;itc-tab tabTitle="Tab 3 (disabled)" [disabled]="true"&gt;
                                        Tab 3 content, but it's disabled
                                    &lt;/itc-tab&gt;
                                &lt;/itc-tabs&gt;
                            </pre>
                        </td>
                    </tr>
                </table>

                <h2 style="margin-top: 20px">Options</h2>
                <table class="options-table ui celled striped table">
                    <tr><td colspan="2">TabS Inputs</td></tr>
                    <tr>
                        <td>[containerStyle]</td>
                        <td>
                            This will determine if we show the "inline/default (what they call it)"
                            style, but we're primarily using the container style so it will default
                            to that. To get the inline style just add this attribute with false.
                        </td>
                    </tr>
                    <tr><td colspan="2">Tab Input</td></tr>
                    <tr>
                        <td>[tabTitle]</td>
                        <td>The title to display on the tab.</td>
                    </tr>
                    <tr>
                        <td>[disabled]</td>
                        <td>Whether the tab should be dsabled or not.</td>
                    </tr>
                </table>
                <table class="options-table ui celled striped table">
                    <tr><td colspan="2">Outputs</td></tr>
                    <tr>
                        <td>(tabChanged)</td>
                        <td>
                            Method returns an object containing the tabTitle, tabIndex, and
                            tabNumber (tabIndex+1) if it's easier to think about as the first tab,
                            second tab, etc. when changed
                        </td>
                    </tr>
                </table>
            </div>
        </div>

        <div *ngIf="selectedView == 'tabs'">
            <h3>ITC Input Components</h3>
            <p>
                <strong>Component</strong>
                :
                <br />
                <strong>Location</strong>
                : app/shared/itc/input/*
                <br />
                <strong>KDS Link</strong>
                :
                <a
                    href="https://kaseya.invisionapp.com/dsm/kaseya/kaseya-design-system/nav/5ffd140fa3de61d36eee2dc7/asset/5ffe46d6a3de617615eedf81/tab/design?version=616dd27ab30c5e1b152898d8&mode=preview"
                    target="_blank">
                    https://kaseya.invisionapp.com
                </a>
            </p>
            <div style="max-width: 1000px">
                <table>
                    <tr>
                        <td colspan="4" class="strong">Checkbox - &lt;itc-checkbox&gt;</td>
                    </tr>
                    <tr>
                        <td>
                            <itc-checkbox
                                label="ngModel Example"
                                [(ngModel)]="checkboxModel"></itc-checkbox>
                            <br />
                            <button
                                (click)="checkboxModel = true"
                                style="margin-top: 4px; white-space: nowrap">
                                Update model to true
                            </button>
                            <br />
                            <button
                                (click)="checkboxModel = false"
                                style="margin-top: 4px; white-space: nowrap">
                                Update model to false
                            </button>
                            <br />
                            <button
                                (click)="checkboxModel = 'mixed'"
                                style="margin-top: 4px; white-space: nowrap">
                                Update model to 'mixed'
                            </button>
                            <br />
                            Model value: {{ checkboxModel }}
                        </td>
                        <td>
                            <itc-checkbox
                                label="FormControl Example"
                                [control]="checkboxControl"></itc-checkbox>
                            <br />
                            <button
                                (click)="checkboxControl.setValue(true)"
                                style="margin-top: 4px; white-space: nowrap">
                                Update control to true
                            </button>
                            <br />
                            <button
                                (click)="checkboxControl.setValue(false)"
                                style="margin-top: 4px; white-space: nowrap">
                                Update control to false
                            </button>
                            <br />
                            <button
                                (click)="checkboxControl.setValue('mixed')"
                                style="margin-top: 4px; white-space: nowrap">
                                Update control to 'mixed'
                            </button>
                            <br />
                            Control Value: {{ checkboxControl.value }}
                        </td>
                        <td>
                            <itc-checkbox
                                label="Disabled checkbox"
                                [(ngModel)]="checkboxModelDisabled"
                                [disabled]="true"></itc-checkbox>
                            <br />
                            <itc-checkbox
                                label="Disabled checked checkbox"
                                [(ngModel)]="checkboxModelDisabledTrue"
                                [disabled]="true"></itc-checkbox>
                        </td>
                        <td>
                            <itc-checkbox
                                label="Label pos. before"
                                labelPosition="before"></itc-checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <itc-checkbox
                                label="Select All"
                                [(ngModel)]="checkboxModelIndeterminate"></itc-checkbox>
                            <br />
                            <itc-checkbox label="Option 1" [nested]="true"></itc-checkbox>
                            <br />
                            <itc-checkbox
                                label="Option 2 (disabled)"
                                [disabled]="true"
                                [nested]="true"></itc-checkbox>
                            <br />
                            <itc-checkbox label="Option 3" [nested]="true"></itc-checkbox>
                        </td>
                        <td colspan="3">
                            This component doesn't handle the logic for if an item should be
                            inderminate or not when you click other items. If the control or modal
                            are set to 'mixed' it will display as indertimate. To nest the items
                            under a parent one set [nested]="true"
                        </td>
                    </tr>
                    <tr>
                        <td colspan="4">&lt;itc-toggle&gt;</td>
                    </tr>
                    <tr>
                        <td>
                            <itc-toggle labelOff="Label Off" labelOn="Label On"></itc-toggle>
                            <br />
                            <itc-toggle
                                labelOff="Small Size On"
                                labelOn="Small Size Off"
                                [small]="true"></itc-toggle>
                        </td>
                        <td>
                            <itc-toggle
                                [(ngModel)]="toggleModel"
                                labelOff="Using Model"
                                labelOn="Using Model"></itc-toggle>
                            <button (click)="toggleModel = !toggleModel" style="margin-top: 5px">
                                Toggle the toggle model.
                            </button>
                            <div>Model Value: {{ toggleModel }}</div>
                        </td>
                        <td>
                            <itc-toggle
                                [control]="toggleCtrl"
                                labelOff="Using FormControl"
                                labelOn="Using FormControl"></itc-toggle>
                            <button
                                (click)="toggleCtrl.setValue(!toggleCtrl.value)"
                                style="margin-top: 5px">
                                Toggle the FormControl value.
                            </button>
                            <div>Control Value: {{ toggleCtrl.value }}</div>
                        </td>
                        <td>
                            <itc-toggle
                                labelOff="Disabled toggle"
                                labelOn="Disabled toggle"
                                [disabled]="true"></itc-toggle>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="4">&lt;itc-radio&gt;</td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            ngModel: {{ radio1Model }}
                            <button (click)="radio1Model = 3">Set ngModel to 3</button>
                            <br />
                            <itc-radio
                                [(ngModel)]="radio1Model"
                                name="var1"
                                value="1"
                                className="testClass"
                                label="Label for Radio 1"></itc-radio>
                            <br />
                            <itc-radio
                                [(ngModel)]="radio1Model"
                                name="var1"
                                value="2"
                                className="testClass"
                                label="Label for Radio 2"
                                [disabled]="true"></itc-radio>
                            <br />
                            <itc-radio
                                [(ngModel)]="radio1Model"
                                name="var1"
                                value="3"
                                className="testClass"
                                label="Label for Radio 3"></itc-radio>
                            <br />
                            <itc-radio
                                [(ngModel)]="radio1Model"
                                name="var1"
                                value="4"
                                className="testClass"
                                label="Label for Radio 4"></itc-radio>
                        </td>
                        <td colspan="2">
                            formControl: {{ radio1Ctrl.value }}
                            <button (click)="radio1Ctrl.setValue(3)">Set formcontrol to 3</button>
                            <br />
                            <itc-radio
                                [control]="radio1Ctrl"
                                name="var2"
                                value="1"
                                className="testClass"
                                label="Label for Radio 1"></itc-radio>
                            <br />
                            <itc-radio
                                [control]="radio1Ctrl"
                                name="var2"
                                value="2"
                                className="testClass"
                                label="Label for Radio 2"
                                [disabled]="true"></itc-radio>
                            <br />
                            <itc-radio
                                [control]="radio1Ctrl"
                                name="var2"
                                value="3"
                                className="testClass"
                                label="Label for Radio 3"></itc-radio>
                            <br />
                            <itc-radio
                                [control]="radio1Ctrl"
                                name="var2"
                                value="4"
                                className="testClass"
                                label="Label for Radio 4"></itc-radio>
                        </td>
                    </tr>
                </table>

                <h2 style="margin-top: 20px">Checkbox Options</h2>
                <table class="options-table ui celled striped table">
                    <tr><td colspan="2">Inputs</td></tr>
                    <tr>
                        <td>[control]</td>
                        <td>
                            Pass in a FormControl if you want to use that.
                            <br />
                            Values accepted: true (checked), false (not checked), 'mixed'
                            (indeterminate);
                        </td>
                    </tr>
                    <tr>
                        <td>[(ngModel)]</td>
                        <td>
                            You can use either a formcontrol or model with this component
                            <br />
                            Values accepted: true (checked), false (not checked), 'mixed'
                            (indeterminate);
                        </td>
                    </tr>
                    <tr>
                        <td>[label]</td>
                        <td>
                            Label text to display, this can also be based in as ng-content in the
                            element
                        </td>
                    </tr>
                    <tr>
                        <td>[labelPosition]</td>
                        <td>Placement of label: before, after (default)</td>
                    </tr>
                    <tr>
                        <td>[disbled]</td>
                        <td>
                            Disable checkbox. If using reactive forms you should disable that way
                            instead of using this label.
                        </td>
                    </tr>
                    <tr>
                        <td>[name]</td>
                        <td>Input name</td>
                    </tr>
                    <tr>
                        <td>[value]</td>
                        <td>Input Value</td>
                    </tr>
                    <tr>
                        <td>[nested]</td>
                        <td>
                            Whether the checkbox should be nested because it's under a parent item.
                            True, False (default)
                        </td>
                    </tr>
                    <tr>
                        <td>[checked]</td>
                        <td>
                            This is an option for places we have checkboxes that don't deal with an
                            ngmodel or control. For example, on Standards and Controls in GRC. We
                            are not using the value of the checkbox, we're instead using an
                            onChanged() to tweak the variable in the control object.
                        </td>
                    </tr>
                    <tr>
                        <td>[className]</td>
                        <td>Extra class to add to parent item</td>
                    </tr>
                </table>
                <table class="options-table ui celled striped table">
                    <tr><td colspan="2">Outputs</td></tr>
                    <tr>
                        <td>(onChanged)</td>
                        <td>
                            Method to run when checkbox value is changed. Returns whether checked or
                            not.
                        </td>
                    </tr>
                </table>

                <h2 style="margin-top: 20px">Toggle Options</h2>
                <table class="options-table ui celled striped table">
                    <tr><td colspan="2">Inputs</td></tr>
                    <tr>
                        <td>[control]</td>
                        <td>
                            Pass in a FormControl if you want to use that.
                            <br />
                            Values accepted: true (checked), false (not checked), 'mixed'
                            (indeterminate);
                        </td>
                    </tr>
                    <tr>
                        <td>[(ngModel)]</td>
                        <td>
                            You can use either a formcontrol or model with this component
                            <br />
                            Values accepted: true (checked), false (not checked), 'mixed'
                            (indeterminate);
                        </td>
                    </tr>
                    <tr>
                        <td>[label]</td>
                        <td>Label text to display whether off or on</td>
                    </tr>
                    <tr>
                        <td>[labelOn]</td>
                        <td>Label text to display when the toggle is On</td>
                    </tr>
                    <tr>
                        <td>[labelOff]</td>
                        <td>Label text to display when the toggle is Off</td>
                    </tr>
                    <tr>
                        <td>[labelPosition]</td>
                        <td>Placement of label: before, after (default)</td>
                    </tr>
                    <tr>
                        <td>[disbled]</td>
                        <td>Disable checkbox</td>
                    </tr>
                    <tr>
                        <td>[className]</td>
                        <td>Extra class to add to parent item</td>
                    </tr>
                </table>
                <table class="options-table ui celled striped table">
                    <tr><td colspan="2">Outputs</td></tr>
                    <tr>
                        <td>(onChanged)</td>
                        <td>Method to run when toggle is changed. Will return boolean.</td>
                    </tr>
                </table>

                <h2 style="margin-top: 20px">Radio Options</h2>
                <table class="options-table ui celled striped table">
                    <tr><td colspan="2">Inputs</td></tr>
                    <tr>
                        <td>[control]</td>
                        <td>Pass in a FormControl if you want to use that.</td>
                    </tr>
                    <tr>
                        <td>[(ngModel)]</td>
                        <td>You can use either a formcontrol or model with this component</td>
                    </tr>
                    <tr>
                        <td>[label]</td>
                        <td>
                            Label text to display, this can also be based in as ng-content in the
                            element
                        </td>
                    </tr>
                    <tr>
                        <td>[labelPosition]</td>
                        <td>Placement of label: before, after (default)</td>
                    </tr>
                    <tr>
                        <td>[disbled]</td>
                        <td>
                            Disable checkbox. If using reactive forms you should disable that way
                            instead of using this label.
                        </td>
                    </tr>
                    <tr>
                        <td>[name]</td>
                        <td>Input name</td>
                    </tr>
                    <tr>
                        <td>[value]</td>
                        <td>Input value</td>
                    </tr>
                    <tr>
                        <td>[className]</td>
                        <td>Extra css classes to add to parent container</td>
                    </tr>
                </table>
                <table class="options-table ui celled striped table">
                    <tr><td colspan="2">Outputs</td></tr>
                    <tr>
                        <td>(onChanged)</td>
                        <td>Method to run when radio button is changed.</td>
                    </tr>
                </table>
            </div>
        </div>

        <div *ngIf="selectedView == 'tags'">
            <h3>ITC Tags Component</h3>
            <p>
                <strong>Component</strong>
                : itc-tag.component
                <strong>Location</strong>
                : app/shared/itc/tag/itc-tag.component
                <br />
                <strong>KDS Link</strong>
                :
                <a
                    href="https://kaseya.invisionapp.com/dsm/kaseya/kaseya-design-system/nav/5ffd140fa3de61d36eee2dc7/asset/5ffe46d6a3de617615eedf81/tab/design?version=616dd27ab30c5e1b152898d8&mode=preview"
                    target="_blank">
                    https://kaseya.invisionapp.com
                </a>
            </p>
            <div>
                <table>
                    <tr>
                        <td colspan="11">
                            <strong>Solid Status</strong>
                            - &lt;itc-tag type="solid" status="status"&gt;
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <h5>Success</h5>
                            <itc-tag type="solid" status="success">Label</itc-tag>
                            <itc-tag type="solid" status="success" size="large">Label</itc-tag>
                            <itc-tag type="solid" status="success" icon="alien">Label</itc-tag>
                            <itc-tag type="solid" status="success" [closable]="true">Label</itc-tag>
                            <itc-tag type="solid" status="success" icon="alien" [closable]="true">
                                Label
                            </itc-tag>
                            <itc-tag type="solid" status="success" icon="alien" [closable]="true">
                                Too long of a text label, max width is 180px
                            </itc-tag>
                            status="success"
                        </td>
                        <td>
                            <h5>Low Severity</h5>
                            <itc-tag type="solid" status="low-severity">Label</itc-tag>
                            <itc-tag type="solid" status="low-severity" size="large">Label</itc-tag>
                            <itc-tag type="solid" status="low-severity" icon="alien">Label</itc-tag>
                            <itc-tag type="solid" status="low-severity" [closable]="true">
                                Label
                            </itc-tag>
                            <itc-tag
                                type="solid"
                                status="low-severity"
                                icon="alien"
                                [closable]="true">
                                Label
                            </itc-tag>
                            status="low-severity"
                        </td>
                        <td>
                            <h5>Med. Severity</h5>
                            <itc-tag type="solid" status="med-severity">Label</itc-tag>
                            <itc-tag type="solid" status="med-severity" size="large">Label</itc-tag>
                            <itc-tag type="solid" status="med-severity" icon="alien">Label</itc-tag>
                            <itc-tag type="solid" status="med-severity" [closable]="true">
                                Label
                            </itc-tag>
                            <itc-tag
                                type="solid"
                                status="med-severity"
                                icon="alien"
                                [closable]="true">
                                Label
                            </itc-tag>
                            status="med-severity"
                        </td>
                        <td>
                            <h5>High Severity</h5>
                            <itc-tag type="solid" status="high-severity">Label</itc-tag>
                            <itc-tag type="solid" status="high-severity" size="large">
                                Label
                            </itc-tag>
                            <itc-tag type="solid" status="high-severity" icon="alien">
                                Label
                            </itc-tag>
                            <itc-tag type="solid" status="high-severity" [closable]="true">
                                Label
                            </itc-tag>
                            <itc-tag
                                type="solid"
                                status="high-severity"
                                icon="alien"
                                [closable]="true">
                                Label
                            </itc-tag>
                            status="high-severity"
                        </td>
                        <td>
                            <h5>Critical</h5>
                            <itc-tag type="solid" status="critical">Label</itc-tag>
                            <itc-tag type="solid" status="critical" size="large">Label</itc-tag>
                            <itc-tag type="solid" status="critical" icon="alien">Label</itc-tag>
                            <itc-tag type="solid" status="critical" [closable]="true">
                                Label
                            </itc-tag>
                            <itc-tag type="solid" status="critical" icon="alien" [closable]="true">
                                Label
                            </itc-tag>
                            status="critical"
                        </td>
                        <td>
                            <h5>New</h5>
                            <itc-tag type="solid" status="new">Label</itc-tag>
                            <itc-tag type="solid" status="new" size="large">Label</itc-tag>
                            <itc-tag type="solid" status="new" icon="alien">Label</itc-tag>
                            <itc-tag type="solid" status="new" [closable]="true">Label</itc-tag>
                            <itc-tag type="solid" status="new" icon="alien" [closable]="true">
                                Label
                            </itc-tag>
                            status="new"
                        </td>
                        <td>
                            <h5>Active</h5>
                            <itc-tag type="solid" status="active">Label</itc-tag>
                            <itc-tag type="solid" status="active" size="large">Label</itc-tag>
                            <itc-tag type="solid" status="active" icon="alien">Label</itc-tag>
                            <itc-tag type="solid" status="active" [closable]="true">Label</itc-tag>
                            <itc-tag type="solid" status="active" icon="alien" [closable]="true">
                                Label
                            </itc-tag>
                            status="active"
                        </td>
                        <td>
                            <h5>Inactive</h5>
                            <itc-tag type="solid" status="inactive">Label</itc-tag>
                            <itc-tag type="solid" status="inactive" size="large">Label</itc-tag>
                            <itc-tag type="solid" status="inactive" icon="alien">Label</itc-tag>
                            <itc-tag type="solid" status="inactive" [closable]="true">
                                Label
                            </itc-tag>
                            <itc-tag type="solid" status="inactive" icon="alien" [closable]="true">
                                Label
                            </itc-tag>
                            status="inactive"
                        </td>
                        <td>
                            <h5>Open</h5>
                            <itc-tag type="solid" status="open">Label</itc-tag>
                            <itc-tag type="solid" status="open" size="large">Label</itc-tag>
                            <itc-tag type="solid" status="open" icon="alien">Label</itc-tag>
                            <itc-tag type="solid" status="open" [closable]="true">Label</itc-tag>
                            <itc-tag type="solid" status="open" icon="alien" [closable]="true">
                                Label
                            </itc-tag>
                            status="open"
                        </td>
                        <td>
                            <h5>Closed</h5>
                            <itc-tag type="solid" status="closed">Label</itc-tag>
                            <itc-tag type="solid" status="closed" size="large">Label</itc-tag>
                            <itc-tag type="solid" status="closed" icon="alien">Label</itc-tag>
                            <itc-tag type="solid" status="closed" [closable]="true">Label</itc-tag>
                            <itc-tag type="solid" status="closed" icon="alien" [closable]="true">
                                Label
                            </itc-tag>
                            status="closed"
                        </td>
                        <td>
                            <h5>Pending</h5>
                            <itc-tag type="solid" status="pending">Label</itc-tag>
                            <itc-tag type="solid" status="pending" size="large">Label</itc-tag>
                            <itc-tag type="solid" status="pending" icon="alien">Label</itc-tag>
                            <itc-tag type="solid" status="pending" [closable]="true">Label</itc-tag>
                            <itc-tag type="solid" status="pending" icon="alien" [closable]="true">
                                Label
                            </itc-tag>
                            status="pending"
                        </td>
                    </tr>
                </table>
                <table>
                    <tr>
                        <td colspan="11">
                            <strong>Solid Color</strong>
                            - &lt;itc-tag type="solid" color="color"&gt;
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <h5>Dark Green</h5>
                            <itc-tag type="solid" status="dark-green">Label</itc-tag>
                            <br />
                            <itc-tag type="solid" status="dark-green" size="large">Label</itc-tag>
                            <br />
                            <itc-tag type="solid" status="dark-green" icon="alien">Label</itc-tag>
                            <br />
                            <itc-tag type="solid" status="dark-green" [closable]="true">
                                Label
                            </itc-tag>
                            <br />
                            <itc-tag
                                type="solid"
                                status="dark-green"
                                icon="alien"
                                [closable]="true">
                                Label
                            </itc-tag>
                            <br />
                            color="dark-green"
                        </td>
                        <td>
                            <h5>Teal</h5>
                            <itc-tag type="solid" status="teal">Label</itc-tag>
                            <br />
                            <itc-tag type="solid" status="teal" size="large">Label</itc-tag>
                            <br />
                            <itc-tag type="solid" status="teal" icon="alien">Label</itc-tag>
                            <br />
                            <itc-tag type="solid" status="teal" [closable]="true">Label</itc-tag>
                            <br />
                            <itc-tag type="solid" status="teal" icon="alien" [closable]="true">
                                Label
                            </itc-tag>
                            <br />
                            color="teal"
                        </td>
                        <td>
                            <h5>Light Blue</h5>
                            <itc-tag type="solid" status="light-blue">Label</itc-tag>
                            <br />
                            <itc-tag type="solid" status="light-blue" size="large">Label</itc-tag>
                            <br />
                            <itc-tag type="solid" status="light-blue" icon="alien">Label</itc-tag>
                            <br />
                            <itc-tag type="solid" status="light-blue" [closable]="true">
                                Label
                            </itc-tag>
                            <br />
                            <itc-tag
                                type="solid"
                                status="light-blue"
                                icon="alien"
                                [closable]="true">
                                Label
                            </itc-tag>
                            <br />
                            color="light-blue"
                        </td>
                        <td>
                            <h5>Dark Blue</h5>
                            <itc-tag type="solid" status="dark-blue">Label</itc-tag>
                            <br />
                            <itc-tag type="solid" status="dark-blue" size="large">Label</itc-tag>
                            <br />
                            <itc-tag type="solid" status="dark-blue" icon="alien">Label</itc-tag>
                            <br />
                            <itc-tag type="solid" status="dark-blue" [closable]="true">
                                Label
                            </itc-tag>
                            <br />
                            <itc-tag type="solid" status="dark-blue" icon="alien" [closable]="true">
                                Label
                            </itc-tag>
                            <br />
                            color="dark-blue"
                        </td>
                        <td>
                            <h5>Blue Purple</h5>
                            <itc-tag type="solid" status="blue-purple">Label</itc-tag>
                            <br />
                            <itc-tag type="solid" status="blue-purple" size="large">Label</itc-tag>
                            <br />
                            <itc-tag type="solid" status="blue-purple" icon="alien">Label</itc-tag>
                            <br />
                            <itc-tag type="solid" status="blue-purple" [closable]="true">
                                Label
                            </itc-tag>
                            <br />
                            <itc-tag
                                type="solid"
                                status="blue-purple"
                                icon="alien"
                                [closable]="true">
                                Label
                            </itc-tag>
                            <br />
                            color="blue-purple"
                        </td>
                        <td>
                            <h5>Violet</h5>
                            <itc-tag type="solid" status="violet">Label</itc-tag>
                            <br />
                            <itc-tag type="solid" status="violet" size="large">Label</itc-tag>
                            <br />
                            <itc-tag type="solid" status="violet" icon="alien">Label</itc-tag>
                            <br />
                            <itc-tag type="solid" status="violet" [closable]="true">Label</itc-tag>
                            <br />
                            <itc-tag type="solid" status="violet" icon="alien" [closable]="true">
                                Label
                            </itc-tag>
                            <br />
                            color="violet"
                        </td>
                        <td>
                            <h5>Pink</h5>
                            <itc-tag type="solid" status="pink">Label</itc-tag>
                            <br />
                            <itc-tag type="solid" status="pink" size="large">Label</itc-tag>
                            <br />
                            <itc-tag type="solid" status="pink" icon="alien">Label</itc-tag>
                            <br />
                            <itc-tag type="solid" status="pink" [closable]="true">Label</itc-tag>
                            <br />
                            <itc-tag type="solid" status="pink" icon="alien" [closable]="true">
                                Label
                            </itc-tag>
                            <br />
                            color="pink"
                        </td>
                        <td>
                            <h5>Magenta</h5>
                            <itc-tag type="solid" status="magenta">Label</itc-tag>
                            <br />
                            <itc-tag type="solid" status="magenta" size="large">Label</itc-tag>
                            <br />
                            <itc-tag type="solid" status="magenta" icon="alien">Label</itc-tag>
                            <br />
                            <itc-tag type="solid" status="magenta" [closable]="true">Label</itc-tag>
                            <br />
                            <itc-tag type="solid" status="magenta" icon="alien" [closable]="true">
                                Label
                            </itc-tag>
                            <br />
                            color="magenta"
                        </td>
                        <td>
                            <h5>Salmon Pink</h5>
                            <itc-tag type="solid" status="salmon-pink">Label</itc-tag>
                            <br />
                            <itc-tag type="solid" status="salmon-pink" size="large">Label</itc-tag>
                            <br />
                            <itc-tag type="solid" status="salmon-pink" icon="alien">Label</itc-tag>
                            <br />
                            <itc-tag type="solid" status="salmon-pink" [closable]="true">
                                Label
                            </itc-tag>
                            <br />
                            <itc-tag
                                type="solid"
                                status="salmon-pink"
                                icon="alien"
                                [closable]="true">
                                Label
                            </itc-tag>
                            <br />
                            color="salmon-pink"
                        </td>
                        <td>
                            <h5>Charcoal Gray</h5>
                            <itc-tag type="solid" status="charcoal-gray">Label</itc-tag>
                            <br />
                            <itc-tag type="solid" status="charcoal-gray" size="large">
                                Label
                            </itc-tag>
                            <br />
                            <itc-tag type="solid" status="charcoal-gray" icon="alien">
                                Label
                            </itc-tag>
                            <br />
                            <itc-tag type="solid" status="charcoal-gray" [closable]="true">
                                Label
                            </itc-tag>
                            <br />
                            <itc-tag
                                type="solid"
                                status="charcoal-gray"
                                icon="alien"
                                [closable]="true">
                                Label
                            </itc-tag>
                            <br />
                            color="charcoal-gray"
                        </td>
                        <td>
                            <h5>Default</h5>
                            <itc-tag type="solid" status="default">Label</itc-tag>
                            <br />
                            <itc-tag type="solid" status="default" size="large">Label</itc-tag>
                            <br />
                            <itc-tag type="solid" status="default" icon="alien">Label</itc-tag>
                            <br />
                            <itc-tag type="solid" status="default" [closable]="true">Label</itc-tag>
                            <br />
                            <itc-tag type="solid" status="default" icon="alien" [closable]="true">
                                Label
                            </itc-tag>
                            <br />
                            color="default"
                        </td>
                        <td></td>
                    </tr>
                </table>
                <table>
                    <tr>
                        <td colspan="11">
                            <strong>Subtle Status</strong>
                            - &lt;itc-tag type="subtle" status="status"&gt;
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <h5>Success</h5>
                            <itc-tag type="subtle" status="success">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="success" size="large">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="success" icon="alien">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="success" [closable]="true">
                                Label
                            </itc-tag>
                            <br />
                            <itc-tag type="subtle" status="success" icon="alien" [closable]="true">
                                Label
                            </itc-tag>
                        </td>
                        <td>
                            <h5>Low Severity</h5>
                            <itc-tag type="subtle" status="low-severity">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="low-severity" size="large">
                                Label
                            </itc-tag>
                            <br />
                            <itc-tag type="subtle" status="low-severity" icon="alien">
                                Label
                            </itc-tag>
                            <br />
                            <itc-tag type="subtle" status="low-severity" [closable]="true">
                                Label
                            </itc-tag>
                            <br />
                            <itc-tag
                                type="subtle"
                                status="low-severity"
                                icon="alien"
                                [closable]="true">
                                Label
                            </itc-tag>
                        </td>
                        <td>
                            <h5>Med. Severity</h5>
                            <itc-tag type="subtle" status="med-severity">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="med-severity" size="large">
                                Label
                            </itc-tag>
                            <br />
                            <itc-tag type="subtle" status="med-severity" icon="alien">
                                Label
                            </itc-tag>
                            <br />
                            <itc-tag type="subtle" status="med-severity" [closable]="true">
                                Label
                            </itc-tag>
                            <br />
                            <itc-tag
                                type="subtle"
                                status="med-severity"
                                icon="alien"
                                [closable]="true">
                                Label
                            </itc-tag>
                        </td>
                        <td>
                            <h5>High Severity</h5>
                            <itc-tag type="subtle" status="high-severity">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="high-severity" size="large">
                                Label
                            </itc-tag>
                            <br />
                            <itc-tag type="subtle" status="high-severity" icon="alien">
                                Label
                            </itc-tag>
                            <br />
                            <itc-tag type="subtle" status="high-severity" [closable]="true">
                                Label
                            </itc-tag>
                            <br />
                            <itc-tag
                                type="subtle"
                                status="high-severity"
                                icon="alien"
                                [closable]="true">
                                Label
                            </itc-tag>
                        </td>
                        <td>
                            <h5>Critical</h5>
                            <itc-tag type="subtle" status="critical">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="critical" size="large">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="critical" icon="alien">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="critical" [closable]="true">
                                Label
                            </itc-tag>
                            <br />
                            <itc-tag type="subtle" status="critical" icon="alien" [closable]="true">
                                Label
                            </itc-tag>
                        </td>
                        <td>
                            <h5>New</h5>
                            <itc-tag type="subtle" status="new">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="new" size="large">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="new" icon="alien">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="new" [closable]="true">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="new" icon="alien" [closable]="true">
                                Label
                            </itc-tag>
                        </td>
                        <td>
                            <h5>Active</h5>
                            <itc-tag type="subtle" status="active">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="active" size="large">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="active" icon="alien">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="active" [closable]="true">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="active" icon="alien" [closable]="true">
                                Label
                            </itc-tag>
                        </td>
                        <td>
                            <h5>Inactive</h5>
                            <itc-tag type="subtle" status="inactive">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="inactive" size="large">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="inactive" icon="alien">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="inactive" [closable]="true">
                                Label
                            </itc-tag>
                            <br />
                            <itc-tag type="subtle" status="inactive" icon="alien" [closable]="true">
                                Label
                            </itc-tag>
                        </td>
                        <td>
                            <h5>Open</h5>
                            <itc-tag type="subtle" status="open">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="open" size="large">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="open" icon="alien">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="open" [closable]="true">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="open" icon="alien" [closable]="true">
                                Label
                            </itc-tag>
                        </td>
                        <td>
                            <h5>Closed</h5>
                            <itc-tag type="subtle" status="closed">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="closed" size="large">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="closed" icon="alien">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="closed" [closable]="true">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="closed" icon="alien" [closable]="true">
                                Label
                            </itc-tag>
                        </td>
                        <td>
                            <h5>Pending</h5>
                            <itc-tag type="subtle" status="pending">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="pending" size="large">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="pending" icon="alien">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="pending" [closable]="true">
                                Label
                            </itc-tag>
                            <br />
                            <itc-tag type="subtle" status="pending" icon="alien" [closable]="true">
                                Label
                            </itc-tag>
                        </td>
                        <td></td>
                    </tr>
                </table>
                <table>
                    <tr>
                        <td colspan="12">
                            <strong>Subtle Color</strong>
                            - &lt;itc-tag type="subtle" color="color"&gt;
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <h5>Green</h5>
                            <itc-tag type="subtle" status="green">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="green" size="large">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="green" icon="alien">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="green" [closable]="true">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="green" icon="alien" [closable]="true">
                                Label
                            </itc-tag>
                        </td>
                        <td>
                            <h5>Ocean Blue</h5>
                            <itc-tag type="subtle" status="ocean-blue">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="ocean-blue" size="large">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="ocean-blue" icon="alien">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="ocean-blue" [closable]="true">
                                Label
                            </itc-tag>
                            <br />
                            <itc-tag
                                type="subtle"
                                status="ocean-blue"
                                icon="alien"
                                [closable]="true">
                                Label
                            </itc-tag>
                        </td>
                        <td>
                            <h5>Red</h5>
                            <itc-tag type="subtle" status="red">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="red" size="large">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="red" icon="alien">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="red" [closable]="true">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="red" icon="alien" [closable]="true">
                                Label
                            </itc-tag>
                        </td>
                        <td>
                            <h5>Yellow</h5>
                            <itc-tag type="subtle" status="yellow">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="yellow" size="large">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="yellow" icon="alien">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="yellow" [closable]="true">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="yellow" icon="alien" [closable]="true">
                                Label
                            </itc-tag>
                        </td>
                        <td>
                            <h5>Orange</h5>
                            <itc-tag type="subtle" status="orange">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="orange" size="large">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="orange" icon="alien">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="orange" [closable]="true">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="orange" icon="alien" [closable]="true">
                                Label
                            </itc-tag>
                        </td>
                        <td>
                            <h5>Bittersweet</h5>
                            <itc-tag type="subtle" status="bittersweet">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="bittersweet" size="large">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="bittersweet" icon="alien">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="bittersweet" [closable]="true">
                                Label
                            </itc-tag>
                            <br />
                            <itc-tag
                                type="subtle"
                                status="bittersweet"
                                icon="alien"
                                [closable]="true">
                                Label
                            </itc-tag>
                        </td>
                        <td>
                            <h5>Violet</h5>
                            <itc-tag type="subtle" status="violet">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="violet" size="large">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="violet" icon="alien">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="violet" [closable]="true">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="violet" icon="alien" [closable]="true">
                                Label
                            </itc-tag>
                        </td>
                        <td>
                            <h5>Sky Blue</h5>
                            <itc-tag type="subtle" status="sky-blue">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="sky-blue" size="large">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="sky-blue" icon="alien">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="sky-blue" [closable]="true">
                                Label
                            </itc-tag>
                            <br />
                            <itc-tag type="subtle" status="sky-blue" icon="alien" [closable]="true">
                                Label
                            </itc-tag>
                        </td>
                        <td>
                            <h5>Purple</h5>
                            <itc-tag type="subtle" status="purple">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="purple" size="large">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="purple" icon="alien">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="purple" [closable]="true">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="purple" icon="alien" [closable]="true">
                                Label
                            </itc-tag>
                        </td>
                        <td>
                            <h5>Black</h5>
                            <itc-tag type="subtle" status="black">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="black" size="large">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="black" icon="alien">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="black" [closable]="true">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="black" icon="alien" [closable]="true">
                                Label
                            </itc-tag>
                        </td>
                        <td>
                            <h5>Turquoise</h5>
                            <itc-tag type="subtle" status="turquoise">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="turquoise" size="large">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="turquoise" icon="alien">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="turquoise" [closable]="true">
                                Label
                            </itc-tag>
                            <br />
                            <itc-tag
                                type="subtle"
                                status="turquoise"
                                icon="alien"
                                [closable]="true">
                                Label
                            </itc-tag>
                        </td>
                        <td>
                            <h5>Lavender</h5>
                            <itc-tag type="subtle" status="lavender">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="lavender" size="large">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="lavender" icon="alien">Label</itc-tag>
                            <br />
                            <itc-tag type="subtle" status="lavender" [closable]="true">
                                Label
                            </itc-tag>
                            <br />
                            <itc-tag type="subtle" status="lavender" icon="alien" [closable]="true">
                                Label
                            </itc-tag>
                        </td>
                    </tr>
                </table>

                <h2 style="margin-top: 20px">Options</h2>
                <table class="options-table ui celled striped table">
                    <tr><td colspan="2">Inputs</td></tr>
                    <tr>
                        <td>[class]</td>
                        <td>
                            Pass in a class to display *NOTE: If using in a modal, with class
                            'approve' or 'cancel' or any of the default classes for the modal, and
                            the button may be disabled, you need to enter it like this...
                            [class]="'approve'" or it'll close when clicked even if disabled
                        </td>
                    </tr>
                    <tr>
                        <td>[type]</td>
                        <td>
                            Type of button: primary (default), secondary, tertiary, color, icon,
                            text, danger, ghost, ghost danger, ghost secondary
                            <br />
                            Icon type is just icon with no text and smaller padding and margins, and
                            Text type is just text, only a tiny bit of top and bottom padding,
                            nothing left and right.
                            <br />
                            Note: Most types can be combined, like 'primary icon' will give you an
                            orange icon, let me know any we need that don't work together.
                        </td>
                    </tr>
                    <tr>
                        <td>[color]</td>
                        <td>
                            If type='color' you need to pass in a color for the button. If the type
                            is not color this will be ignored. The button component will add a hover
                            color of the passed in color darkened by 20%. This will work for hex,
                            rgb and rgba.
                        </td>
                    </tr>
                    <tr>
                        <td>[disabled]</td>
                        <td>Disable button and don't allow clicking</td>
                    </tr>
                    <tr>
                        <td>[size]</td>
                        <td>Size of button: small, regular (default) large</td>
                    </tr>
                    <tr>
                        <td>[icon]</td>
                        <td>Icon to display</td>
                    </tr>
                    <tr>
                        <td>[iconPlacement]</td>
                        <td>Location of icon, before (default) or after</td>
                    </tr>
                    <tr>
                        <td>[loading]</td>
                        <td>Show loading animation: false (default) or true</td>
                    </tr>
                    <tr>
                        <td>[link]</td>
                        <td>Link to click to if it's a link (not sure if we need this)</td>
                    </tr>
                    <tr>
                        <td>[params]</td>
                        <td>Params to attach to link (not sure if we need this)</td>
                    </tr>
                    <tr>
                        <td>[label]</td>
                        <td>
                            Added to match sds-button, but I'd prefer if we used the text content
                            (ng-content) in the button. But just in case someone goes that route
                            when copying over from sds-button I'm adding this in.
                        </td>
                    </tr>
                </table>
                <table class="options-table ui celled striped table">
                    <tr><td colspan="2">Outputs</td></tr>
                    <tr>
                        <td>(onclick)</td>
                        <td>
                            Method to run when button is clicked (has a 500ms timeout, and will not
                            work if button is disabled or loading)
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div *ngIf="selectedView == 'progress-tracker'">
            <h3>ITC Progress Tracker Component</h3>
            <p>
                <strong>Component</strong>
                : progress-tracker.component
                <strong>Location</strong>
                : app/shared/itc/progress-tracker/progress-trackerr.component
                <br />
                <strong>KDS Link</strong>
                :
                <a
                    href="https://www.figma.com/file/kvZYpc6UmBKR0P8B1lF68q/KDS-Components?node-id=2%3A34&mode=dev"
                    target="_blank">
                    https://www.figma.com
                </a>
            </p>
            <progress-tracker
                [steps]="progressTrackerExample"
                size="compact"
                maxDisplayedSteps="5"></progress-tracker>
            <br />
            <br />
            <itc-button type="secondary" (onclick)="progressTrackerService.previousStep()">
                Previous
            </itc-button>
            <itc-button type="primary" (onclick)="progressTrackerService.nextStep()">
                Next
            </itc-button>
            <itc-button type="ghost" (onclick)="progressTrackerService.setStepState(5, 'error')">
                Set State 5 as error
            </itc-button>
            <itc-button type="ghost" (onclick)="progressTrackerService.resetTracker()">
                Reset Tracker
            </itc-button>
        </div>

        <div *ngIf="selectedView === 'iprange'">
            <rft-ip-range [(ngModel)]="iprangeModel"></rft-ip-range>
        </div>

        <div *ngIf="selectedView === 'status-icon'">
            <status-icon status="success" tooltip="status='success'"></status-icon>
            <status-icon status="success-circle" tooltip="status='success-circle'"></status-icon>
            <status-icon status="partial-success" tooltip="status='partial-success'"></status-icon>
            <status-icon status="warning" tooltip="status='warning'"></status-icon>
            <status-icon status="failed" tooltip="status='failed'"></status-icon>
            <status-icon status="failed-circle" tooltip="status='failed-circle'"></status-icon>
            <status-icon status="no-data" tooltip="status='no-data'"></status-icon>
            <status-icon status="no-attempt" tooltip="status='no-attempt'"></status-icon>
            <status-icon status="confirmed" tooltip="status='confirmed'"></status-icon>
            <status-icon status="not-confirmed" tooltip="status='not-confirmed'"></status-icon>
            <status-icon status="loading" tooltip="status='loading'"></status-icon>
        </div>

        <div *ngIf="selectedView === 'cmo-status-card'">
            <h3>Compliance Monitor Status Card</h3>
            <p>
                <strong>Component</strong>
                : cmo-status-card.component
                <br />
                <strong>Location</strong>
                : app/sites/site/compliance-manager/compliance-monitor/shared/cmo-status-card
                <br />
                <strong>KDS Link</strong>
                :
                <a
                    href="https://www.figma.com/design/kKsK3Sss3YTG5zCScm28A2/Compliance-Monitor?node-id=1-385&t=6kQJCy5e30ZpPXDl-0"
                    target="_blank">
                    https://www.figma.com
                </a>
            </p>
            <cmo-status-card
                standard="CIS Controls v8"
                variant="IG1"
                icon="cis"
                status="20"
                devices="270"
                devicesTotal="270"
                (onclick)="cardClicked()"></cmo-status-card>

            <h2 style="margin-top: 20px">Options</h2>
            <table class="options-table ui celled striped table">
                <tr><td colspan="2">Inputs</td></tr>
                <tr>
                    <td>[standard]: string</td>
                    <td>Standard name</td>
                </tr>
                <tr>
                    <td>[variant]: string</td>
                    <td>Standard variant name</td>
                </tr>
                <tr>
                    <td>[status]: number</td>
                    <td>Current status of readiness (ex. 100, 80)</td>
                </tr>
                <tr>
                    <td>[devices]: number</td>
                    <td>Number of devices this matches</td>
                </tr>
                <tr>
                    <td>[devicesTotal]: number</td>
                    <td>Total count of devices</td>
                </tr>
            </table>
            <table class="options-table ui celled striped table">
                <tr><td colspan="2">Outputs</td></tr>
                <tr>
                    <td>(onclick)</td>
                    <td>Event Emitter when the card is clicked.</td>
                </tr>
            </table>
        </div>

        <!-- <div style="margin: 20px; background-color: lime">
        <itc-loader [complete]="loaderComplete">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam fuga provident illum ex
            et nihil sint perferendis quaerat dolorem, incidunt accusamus aspernatur quibusdam
            temporibus ut repellendus harum? Nisi, eaque facilis.
        </itc-loader>
        <button (click)="loaderComplete = !loaderComplete">MarkComplete</button>
    </div>
    <br />
    <br />
    <itc-spinner></itc-spinner>
    <itc-spinner size="large"></itc-spinner> -->
    </div>
</div>
