import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { VideoCategory } from '../video-tutorials.model';

@Component({
    selector: 'itc-video-menu',
    template: `
        <ul>
        <li *ngFor="let category of categoryList">
            <a
                (click)="categoryClicked.emit(category.cat)"
                [class.selected]="selectedCategory === category.cat">
                {{ category.name }}
            </a>
        </li>
        </ul>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoMenuComponent {
    @Input() categoryList: Observable<VideoCategory[]>;
    @Input() selectedCategory = 'all';
    @Output() categoryClicked: EventEmitter<string> = new EventEmitter();
}
