import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

import { AuthService } from '../../../core/auth/auth.service';
import { SiteService } from '../../shared/site.service';
import { SiteSettingService } from '../../site/settings/site-settings.service';
import { NotificationService } from '../../../shared/itc/notification/notification.service';

@Component({
    selector: 'sds-data-collector-info',
    templateUrl: './data-collector-info.component.html',
    styleUrls: ['./data-collector-info.component.scss'],
})
export class DataCollectorInfoComponent implements OnInit {
    @Input() showAgentsTable = false;

    @Input() set apps(value: any) {
        if (value) {
            if (!Array.isArray(value)) {
                this._apps.push(value);
            } else {
                this._apps = value;
            }
        }
    }

    get apps() {
        return this._apps;
    }
    @Input() siteId: number; // siteId must be passed in if showManageSection = true;
    @Input() allowDelete = false;
    @Input() showManageSection = true;

    @ViewChild('editDataCollectorDescriptionModal', { static: true })
    editDataCollectorDescriptionModal: any;
    @ViewChild('afterUpdateModal', { static: true }) afterUpdateModal: any;
    @ViewChild('deleteKvsModal', { static: true }) deleteKvsModal: any;

    _apps = [];
    dcDescCtrl: UntypedFormControl = new UntypedFormControl('', Validators.maxLength(100));
    editedApp: any;
    appToDelete: any;
    isDeleting = false;
    isVulScan = false;

    constructor(
        private authService: AuthService,
        private siteSettingService: SiteSettingService,
        private siteService: SiteService,
        private notificationService: NotificationService
    ) {}

    ngOnInit() {}

    showEditDescModal(app: any) {
        this.editedApp = app;
        this.editDataCollectorDescriptionModal.show({ closable: false });
        this.dcDescCtrl.setValue(app.Description);
    }

    saveDcDesc() {
        if (this.dcDescCtrl.value == null) this.dcDescCtrl.setValue('');
        this.editedApp.Description = this.dcDescCtrl.value;
        this.siteSettingService.setApplianceDescription(this.editedApp.Id, this.dcDescCtrl.value);
        this.editDataCollectorDescriptionModal.hide();
        this.editedApp = null;
    }

    showDeleteModal(app: any) {
        this.appToDelete = app;
        this.deleteKvsModal.show();
    }

    deleteKVS() {
        if (this.appToDelete) {
            this.isDeleting = true;
            this.siteService.deleteKVSAppliance(this.appToDelete.Id, this.siteId).then((res) => {
                // this.apps = [];
                // this.loadAppliances();
                this.apps = this.apps.filter((a) => a.Id !== this.appToDelete.Id);
                this.deleteKvsModal.hide();
                this.isDeleting = false;
                this.notificationService.toast.success('Success', 'Appliance Deleted');
            });
        }
    }

    sendUpdate() {
        this.siteSettingService
            .sendUpdateToAppliance(this.siteId)
            .then((res) => {
                this.afterUpdateModal.show();
            })
            .catch((err) => {
                this.notificationService.toast.error(
                    'Error',
                    'There was a problem sending the update request to the data collector.'
                );
            });
    }
}
