import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

type Status =
    | 'success'
    | 'success-circle'
    | 'partial-success'
    | 'warning'
    | 'failed'
    | 'failed-circle'
    | 'no-data'
    | 'no-attempt'
    | 'confirmed'
    | 'not-confirmed'
    | 'loading';

@Component({
    selector: 'status-icon',
    template: `
        <span [ngSwitch]="status" (click)="onclick.emit()">
            <!-- success -->
            <fa-icon
                *ngSwitchCase="'success'"
                [icon]="['fas', 'circle']"
                style="color: #29A72F"></fa-icon>

            <!-- success-circle -->
            <fa-icon
                *ngSwitchCase="'success-circle'"
                [icon]="['fas', 'circle-check']"
                [fixedWidth]="true"
                style="color: #29A72F"></fa-icon>

            <!-- partial-success -->
            <fa-icon
                *ngSwitchCase="'partial-success'"
                [icon]="['fas', 'circle-half-stroke']"
                [fixedWidth]="true"
                [rotate]="270"
                style="color: #29A72F"></fa-icon>

            <!-- warning -->
            <fa-icon
                *ngSwitchCase="'warning'"
                [icon]="['fas', 'circle']"
                [fixedWidth]="true"
                style="color: #D97008"></fa-icon>

            <!-- failed -->
            <fa-icon
                *ngSwitchCase="'failed'"
                [icon]="['far', 'xmark']"
                [fixedWidth]="true"
                style="color: #D44431"></fa-icon>

            <!-- failed-circle -->
            <fa-icon
                *ngSwitchCase="'failed-circle'"
                [icon]="['fas', 'circle-xmark']"
                [fixedWidth]="true"
                style="color: var(--icon-critical)"></fa-icon>

            <!-- no-data -->
            <fa-icon
                *ngSwitchCase="'no-data'"
                [icon]="['far', 'circle']"
                [fixedWidth]="true"
                style="color: #A5ADB7"></fa-icon>

            <!-- no-attempt -->
            <fa-icon
                *ngSwitchCase="'no-attempt'"
                [icon]="['fas', 'circle']"
                [fixedWidth]="true"
                style="color: #A5ADB7"></fa-icon>

            <!-- confirmed -->
            <fa-icon
                *ngSwitchCase="'confirmed'"
                [icon]="['far', 'square-check']"
                [fixedWidth]="true"
                style="color: #29A72F"></fa-icon>

            <!-- not-confirmed -->
            <fa-icon
                *ngSwitchCase="'not-confirmed'"
                [icon]="['far', 'square-check']"
                [fixedWidth]="true"
                style="color: #D97008"></fa-icon>

            <!-- loading -->
            <itc-spinner
                *ngSwitchCase="'loading'"
                size="15"
                style="width: 24px; height: 24px; display: inline-block; text-align: center;
                vertical-align: text-bottom; margin-bottom: 2px"></itc-spinner>
        </span>
    `,
    styles: [
        `
            :host,
            span {
                display: inline-block;
                width: 24px;
                height: 24px;
            }
            span {
                display: inline-flex;
                align-items: center;
                justify-content: center;
            }
            fa-icon::ng-deep svg {
                width: 14px;
                height: 14px;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusIconComponent {
    @Input() status: Status;
    @Output() onclick: EventEmitter<void> = new EventEmitter<void>();
}
