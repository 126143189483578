<sds-breadcrumb-trail [crumbs]="breadcrumbs"></sds-breadcrumb-trail>

<header class="pageHeader">
    <h1>General</h1>
</header>

<sds-loader [complete]="loadingComplete">
    <form class="ui form settingsPage smallerContainer">
        <div style="margin-bottom: 15px">
            <!-- Time Zone -->
            <label>
                Time Zone
                <help-button
                    title="Time Zone"
                    text="Set your time zone to schedule automated scans at your preferred local time."
                    url="/portal/rft-portal/set-time-zone.htm"></help-button>
            </label>

            <div>
                <p-dropdown
                    [formControl]="timeZoneCtrl"
                    [options]="timeZones"
                    optionLabel="DisplayName"
                    optionValue="Id"></p-dropdown>
            </div>
        </div>

        <div>
            <!-- Date Format -->
            <label>
                Date Format
                <help-button
                    title="Date Format"
                    text="Here you can set the Date Format for your reports and assessment documentation."
                    url="/portal/rft-portal/portal-report-date-format.htm"></help-button>
            </label>
            <div>
                <p-dropdown [formControl]="dateFmtCtrl" [options]="dateFormats"></p-dropdown>
            </div>
        </div>

        <div class="right floated" style="margin-top: 20px">
            <itc-button
                label="Save"
                type="primary"
                [disabled]="saving || !timeZoneCtrl.value"
                [loading]="saving"
                (onclick)="saveSettings()"></itc-button>
        </div>
    </form>
</sds-loader>
