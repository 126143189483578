import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'prettify',
})
export class PrettifyPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        let result = '';

        if (!value) return result;

        let split = value.split('_'),
            i = 0;
        for (let s of split) {
            result += s[0].toUpperCase() + s.slice(1);

            if (i < split.length) result += ' ';
        }

        return result;
    }
}
