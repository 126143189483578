import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ClientViewService } from 'app/sites/shared/client/client-view.service';
import { MessageService } from 'app/core/message.service';
import { SiteParam, SiteSettingService } from 'app/sites/site/settings/site-settings.service';
import { SettingService } from 'app/settings/shared/setting.service';
import { Site } from 'app/sites/shared/site.model';
import { PING_SITE, SITE } from 'app/sites/shared/constants';
import { UiService } from 'app/core/ui.service';
import { AuthService, Roles, SiteRoles } from 'app/core/auth';
import { SiteService } from 'app/sites/shared/site.service';
import { SemanticModalComponent } from 'app/semantic-legacy/components/modal/modal';

import * as saveAs from 'file-saver';
import { TableSorting } from '../../../../shared/sortable-table/sortable-table.component';
import { NotificationService } from '../../../../shared/itc/notification/notification.service';

const CV: any = {
    SHOW_HOME: 'SHOW_HOME',
    SHOW_REPORTS: 'SHOW_REPORTS',
};

@Component({
    selector: 'sds-client-view-settings',
    templateUrl: './client-view-settings.component.html',
    styleUrls: ['./client-view-settings.component.css'],
})
export class ClientViewSettingsComponent implements OnInit, OnDestroy {
    site: Site;
    isClient: boolean;

    showHomeCtrl: UntypedFormControl = new UntypedFormControl();
    showReportsCtrl: UntypedFormControl = new UntypedFormControl();

    reportSetCtrl: UntypedFormControl = new UntypedFormControl();
    reportSets: string[];
    selectedReportSet: string;
    reportList: any[];
    timeDateFormat: string;
    deleteMessage: string;
    reportToDelete: any;
    oldSettings: any = {};
    removing: boolean = false;
    listLoading: boolean;
    loadingComplete: boolean;
    downloadingReportSetZip: boolean;
    deletingReportSet: boolean;
    subs: any[] = [];

    breadcrumbs = [
        { text: 'Network Detective' },
        { path: '..', text: 'Settings' },
        { path: '.', text: 'Client View' },
    ];

    @ViewChild('undoModal', { static: true }) undoModal: any;
    @ViewChild('reportSetSelect', { static: true }) reportSetSelect: any;

    @ViewChild('confirmModal')
    confirmModal: SemanticModalComponent;

    constructor(
        private clientViewService: ClientViewService,
        private messageService: MessageService,
        private siteSettingService: SiteSettingService,
        private settingService: SettingService,
        private notificationService: NotificationService,
        private uiService: UiService,
        private authService: AuthService,
        private siteService: SiteService
    ) {}

    ngOnInit() {
        this.subs.push(
            this.messageService.on(SITE).subscribe((site: Site) => {
                this.onSite(site);
            })
        );

        this.reportSetCtrl.valueChanges.subscribe((value) => {
            if (!this.site || !value) return;

            this.selectedReportSet = value;
            this.selectedReportSet = this.replaceSpecialCharacters(this.selectedReportSet);
            this.selectedReportSet = encodeURIComponent(this.selectedReportSet);
            this.listLoading = true;
            this.clientViewService
                .getClientReportList(this.site.Id, this.selectedReportSet)
                .then((reportList) => {
                    this.reportList = reportList;
                    this.listLoading = false;
                });
        });

        this.settingService.getSettings().then((settings) => this.process(settings));

        this.messageService.broadcast(PING_SITE);
    }

    //attempting to encode '&' and some other special charcaters would still give a 400 Bad Request error, so replacing it before the request and placing it back after the request is a workaround.
    replaceSpecialCharacters(value: any) {
        let rValue = value
            .replace('&', '-replaceAmpersand-')
            .replace('+', '-replacePlusSign-')
            .replace('%', '-replacePercentSign-')
            .replace('<', '-replaceLeftAngleBracket-')
            .replace('>', '-replaceRightAngleBracket-')
            .replace('[', '-replaceLeftSquareBracket-')
            .replace(']', '-replaceRightSquareBracket-')
            .replace('{', '-replaceLeftCurlyBracket-')
            .replace('}', '-replaceRightCurlyBracket-')
            .replace('(', '-replaceLeftParenthesis-')
            .replace(')', '-replaceRightParenthesis-');
        return rValue;
    }

    ngOnDestroy() {
        for (let sub of this.subs) {
            sub.unsubscribe();
        }
    }

    onSite(site: Site) {
        if (!site) return;

        this.site = site;
        if (this.siteService.isNDPro(site)) {
            this.breadcrumbs[0].text = 'Network Detective Pro';
        }
        this.isClient =
            !this.authService.userIsRoles([Roles.Admin, Roles.Master]) &&
            this.authService.getCurrentSiteRoles().includes(SiteRoles.Client);

        this.uiService.setTitle('Client View', site.Name);
        this.loadingComplete = false;
        this.clientViewService.getClientReportSets(site.Id).then((reportSets) => {
            this.reportSets = reportSets;

            if (reportSets.length) {
                this.reportSetCtrl.setValue(reportSets[0]);
            }

            this.loadingComplete = true;
        });
    }

    downloadReport(report: any) {
        report.loading = true;
        this.clientViewService
            .downloadClientReport(this.site.Id, this.selectedReportSet, report.filename)
            .then((b64) => {
                report.loading = false;

                let raw = window.atob(b64);
                let bytes = new Uint8Array(raw.length);
                for (let i = 0; i < raw.length; i++) {
                    bytes[i] = raw.charCodeAt(i);
                }
                let blob = new Blob([bytes]);

                saveAs(blob, report.filename);
            })
            .catch((err) => {
                report.loading = false;
                console.error(err);
            });
    }

    downloadReportSetZip() {
        this.downloadingReportSetZip = true;
        this.clientViewService
            .downloadClientReportSetZip(this.site.Id, this.selectedReportSet)
            .then((b64) => {
                this.downloadingReportSetZip = false;

                let raw = window.atob(b64);
                let bytes = new Uint8Array(raw.length);
                for (let i = 0; i < raw.length; i++) {
                    bytes[i] = raw.charCodeAt(i);
                }
                let blob = new Blob([bytes]);

                saveAs(blob, this.site.Name + ' - Client Reports.zip');
            })
            .catch((err) => {
                this.downloadingReportSetZip = false;

                console.error(err);
            });
    }

    showDeleteReportConfirm(report: any) {
        this.deleteMessage="Are you sure you want to delete this published report?"
        this.confirmModal.show();
        this.reportToDelete = report;
    }

    cancelDeleteReport() {
        this.reportToDelete = null;
    }

    showDeleteReportSetConfirm() {
        this.deleteMessage="Are you sure you want to delete this published report set?"
        this.confirmModal.show();
    }

    deleteReport(report: any) {
        this.removing = true;
        report.deleting = true;
        this.clientViewService
            .deletePublishedReport(this.site.Id, this.selectedReportSet, report.filename)
            .then((res) => {
                let idx = this.reportList.findIndex((r) => r.filename == report.filename);
                this.reportList.splice(idx, 1);
                console.log(this.reportList);

                this.notificationService.toast.success(
                    'Success',
                    `Report '${report.filename}' deleted from report set.`
                );
                this.removing = false;
                this.confirmModal.hide();
            });
    }

    deleteReportSet() {
        this.deletingReportSet = true;
        this.clientViewService
            .deletePublishedReportSet(this.site.Id, this.selectedReportSet)
            .then((res) => {
                this.deletingReportSet = false;
                let idx = this.reportSets.indexOf(this.selectedReportSet);
                this.reportSets.splice(idx, 1);
                if (this.reportSets.length) this.reportSetCtrl.setValue(this.reportSets[0]);
                else {
                    this.reportSetCtrl.setValue('');
                    this.reportSetSelect.reset();
                    this.selectedReportSet = '';
                    this.reportList = [];
                    this.confirmModal.hide();
                }

                this.notificationService.toast.success(
                    'Success',
                    `Report set '${this.selectedReportSet}' deleted.`
                );
            });
    }

    resetSettings() {
        this.showHomeCtrl.setValue(this.oldSettings[CV.SHOW_HOME] != 'false');
        this.showReportsCtrl.setValue(this.oldSettings[CV.SHOW_REPORTS] != 'false');
    }

    savingSettings: boolean;

    saveSettings() {
        let newSettings: SiteParam[] = [];

        newSettings.push({
            SiteId: this.site.Id,
            Name: CV.SHOW_HOME,
            Value: this.showHomeCtrl.value ? 'true' : 'false',
        });
        newSettings.push({
            SiteId: this.site.Id,
            Name: CV.SHOW_REPORTS,
            Value: this.showReportsCtrl.value ? 'true' : 'false',
        });

        this.savingSettings = true;
        this.siteSettingService
            .upsertSiteParams(this.site.Id, newSettings)
            .then((res) => {
                this.savingSettings = false;

                this.notificationService.toast.success(
                    'Success',
                    'InDoc Client View settings were saved.'
                );
            })
            .catch((res) => {
                this.savingSettings = false;

                this.notificationService.toast.error('Error', 'An unspecified error occurred.');
            });
    }

    undoChanges() {
        this.savingSettings = true;
        this.resetSettings();
        this.savingSettings = false;

        this.notificationService.toast.success(
            'Success',
            'Changes to Client View settings were undone.'
        );

        this.undoModal.hide();
    }

    process(settings: any) {
        for (let s of settings) {
            if (s.Name == 'DateFormat') {
                this.timeDateFormat = s.Value + ' hh:mm:ss a';
                console.log(this.timeDateFormat);
            }
        }
    }

    onSort(sorting: TableSorting): void {
        this.reportList.sort((a, b) =>
            a[sorting.sortColumn].toLowerCase().localeCompare(b[sorting.sortColumn].toLowerCase())
        );

        if (sorting.sortDirection === 'desc') {
            this.reportList.reverse();
        }
    }
}
