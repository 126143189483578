<div class="orgFolderSidebar" *ngIf="selectedOrgName$ | async as orgName">
    <div class="leftPanel" [class.collapsed]="isSidebarCollapsed">
        <div class="sidebarToggle" (click)="toggleSidebar()" #collapseButton>
            <div class="sidebarToggleButton">
                <i class="fachevronleft"></i>
            </div>
        </div>

        <div class="sidebarMenuContents" style="overflow: hidden">
            <div
                class="organizationHeader"
                style="padding: 16px 8px 0"
                [hidden]="isSidebarCollapsed">
                <itc-input
                    placeholder="Filter Organizations..."
                    [control]="searchKey"
                    (modelChange)="filterOrganizations()"
                    debounce="420"
                    [working]="filteringOrgs"
                    class="quickFilter"></itc-input>
                <!-- <sm-input placeholder="Search..." [(model)]="searchKey" (modelChange)="filterOrganizations()" debounce="420" [working]="filteringOrgs" class="quickFilter" id="searchOrganizationInput"></sm-input> -->
            </div>

            <ul data-qa="orgfoldersList">
                <li>
                    <a
                        (click)="selectedOrgName$.next('all-orgs')"
                        [routerLink]="['./']"
                        routerLinkActive="activeMenuItem"
                        [routerLinkActiveOptions]="{ exact: true }"
                        class="sidebarMenuItem"
                        tooltip="All Organizations"
                        [ttHidden]="!isSidebarCollapsed"
                        ttPosition="right">
                        <fa-icon icon="house-blank" class="fa-lg"></fa-icon>
                        <span>All Organizations</span>
                    </a>
                </li>

                <li>
                    <a
                        (click)="selectedOrgName$.next('all-sites')"
                        [routerLink]="['./', all_sites]"
                        routerLinkActive="activeMenuItem"
                        class="sidebarMenuItem"
                        tooltip="All Sites"
                        [ttHidden]="!isSidebarCollapsed"
                        ttPosition="right"
                        [class.activeParent]="orgName == all_sites || orgName === 'all_sites'">
                        <fa-icon icon="folder" class="fa-lg"></fa-icon>
                        <span>All Sites</span>
                    </a>
                </li>

                <ng-container
                    *ngIf="filteredOrganizations$ | async as filteredOrganizations; else loading">
                    <li *ngFor="let organization of filteredOrganizations">
                        <a
                            (click)="selectedOrgName$.next(organization.Name)"
                            [routerLink]="['./', organization.Name | siteEncode]"
                            routerLinkActive="activeMenuItem"
                            [routerLinkActiveOptions]="{ exact: true }"
                            class="sidebarMenuItem"
                            [tooltip]="organization.Name"
                            [ttHidden]="!isSidebarCollapsed"
                            ttPosition="right">
                            <fa-icon icon="folder" class="fa-lg"></fa-icon>
                            <span>{{ organization.Name }}</span>
                        </a>
                        <ul
                            *ngIf="
                                orgName == organization.Name &&
                                organization.orgLevelDiscoveryAgents &&
                                (isSuperUser || hasAgentAccess[orgName])
                            ">
                            <li>
                                <a
                                    [routerLink]="[
                                        './',
                                        organization.Name | siteEncode,
                                        'discovery-agents'
                                    ]"
                                    class="sidebarMenuItem childMenuItem"
                                    routerLinkActive="activeMenuItem"
                                    (isActiveChange)="this.onRouterLinkActive($event)">
                                    Discovery Agents
                                </a>
                            </li>
                        </ul>
                    </li>
                </ng-container>
            </ul>
        </div>
    </div>
    <div class="rightPanel">
        <router-outlet></router-outlet>
    </div>
</div>

<ng-template #loading>
    <itc-loader class="orgSidebarDimmer"></itc-loader>
</ng-template>
