<div id="routeTestContainer">
    <div class="userContainer">
        UserName: {{ user.email }}
        <a [routerLink]="['/logout']" style="padding-left: 20px">Log Out</a>
        <br />
        Role: {{ userRole }}
        <br />
        SiteRoles: {{ currentTestSite?.UserRoles }}
        <br />
        Site Type: {{ currentTestSite?.Type }}
        <br />
        Site:
        <span *ngIf="!sites">{{ currentTestSite?.Name }}</span>
        <span *ngIf="sites">
            <select (change)="chooseSite($event.target.value)" [(ngModel)]="siteDropdown">
                <option *ngFor="let site of sites">{{ site }}</option>
            </select>
        </span>
        <span *ngIf="sitesLoading">Loading site list.</span>
        <itc-button (onclick)="resetSite()">Choose different site</itc-button>
    </div>

    <table class="itc-table">
        <tr>
            <td>Link</td>
            <td>On Fail</td>
            <td>ND Role Master</td>
            <td>ND Role Admin</td>
            <td>Internal Auditor</td>
            <td>Site Admin</td>
            <td>Tech</td>
            <td>SME</td>
            <td>ClientView</td>
        </tr>
        <tr *ngFor="let route of routes">
            <ng-container *ngIf="route.type == 'separator'">
                <td colspan="10">{{ route.message }}</td>
            </ng-container>
            <ng-container *ngIf="!route.type || route.type != 'separator'">
                <td>
                    <a [routerLink]="processRoute(route)" *ngIf="!route.type">{{ route.link }}</a>
                    {{ route.message }}
                </td>
                <td>{{ route.onFail }}</td>
                <td [class.yes]="route.perms[0] == 1" [class.no]="route.perms[0] == 0"></td>
                <td [class.yes]="route.perms[1] == 1" [class.no]="route.perms[1] == 0"></td>
                <td [class.yes]="route.perms[2] == 1" [class.no]="route.perms[2] == 0"></td>
                <td [class.yes]="route.perms[3] == 1" [class.no]="route.perms[3] == 0"></td>
                <td [class.yes]="route.perms[4] == 1" [class.no]="route.perms[4] == 0"></td>
                <td [class.yes]="route.perms[5] == 1" [class.no]="route.perms[5] == 0"></td>
                <td [class.yes]="route.perms[6] == 1" [class.no]="route.perms[6] == 0"></td>
            </ng-container>
        </tr>
    </table>

    <br />
    <br />
    Following routes all removed, should no longer go to the page (regardless of role or site), and
    should redirect to /sites
    <br />
    <br />
    <a [routerLink]="['/resources']">/resources</a>
    (just /resources, /resources/product will still work)
    <br />
    <a [routerLink]="['/site', escapedSiteName, 'compliance-manager', 'settings', 'advanced']">
        /site/:name/compliance-manager/settings/advanced
    </a>
    <br />
    <a [routerLink]="['/site', escapedSiteName, 'settings', 'overview']">
        /site/:name/settings/overview
    </a>
    <br />
    <a [routerLink]="['/site', escapedSiteName, 'settings', 'scanwizard']">
        /site/:name/settings/scanwizard
    </a>
    <br />
    <a [routerLink]="['/site', escapedSiteName, 'settings', 'scan-schedules']">
        /site/:name/settings/scan-schedules
    </a>
    <br />
    <a [routerLink]="['/site', escapedSiteName, 'settings', 'appliance']">
        /site/:name/settings/appliance
    </a>
    <br />
    <a [routerLink]="['/site', escapedSiteName, 'settings', 'users']">/site/:name/settings/users</a>
    <br />
    <a [routerLink]="['/site', escapedSiteName, 'settings', 'roles']">/site/:name/settings/roles</a>
    <br />
    <a [routerLink]="['/site', escapedSiteName, 'settings', 'reports']">
        /site/:name/settings/reports
    </a>
    <br />
    <a [routerLink]="['/site', escapedSiteName, 'settings', 'reports', 'theme']">
        /site/:name/settings/reports/:panel
    </a>
    <br />
    <a [routerLink]="['/site', escapedSiteName, 'settings', 'invites']">
        /site/:name/settings/invites
    </a>
    <br />
    <a [routerLink]="['/site', escapedSiteName, 'settings', 'admin-alerts']">
        /site/:name/settings/admin-alerts
    </a>
    <br />
    <a [routerLink]="['/site', escapedSiteName, 'settings', 'client-view']">
        /site/:name/settings/client-view
    </a>
    <br />
    <a [routerLink]="['/site', escapedSiteName, 'settings', 'advanced']">
        /site/:name/settings/advanced
    </a>
    <br />
    <a [routerLink]="['/site', escapedSiteName, 'settings', 'addons']">
        /site/:name/settings/addons
    </a>
    <br />
</div>
