import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Site, SiteService } from 'app/sites';
import { MessageService } from 'app/core/message.service';
import { NotificationService } from '../../../shared/itc/notification/notification.service';

@Component({
    selector: 'sds-restart-site',
    templateUrl: './restart-site.component.html',
})
export class RestartSiteComponent implements OnInit {
    constructor(
        private siteService: SiteService,
        private notificationService: NotificationService,
        private messageService: MessageService
    ) {}

    @Input() siteId: any;
    @Input() loadingLicense: boolean;
    @Input() restarted: boolean;

    @ViewChild('selectRestartTypeModal', { static: true }) selectRestartTypeModal: any;
    @ViewChild('confirmRestartModal', { static: true }) confirmRestartModal: any;
    @ViewChild('restartButton', { static: true }) restartButton: any;

    site: Site;

    restartType: string;
    warningMessage: string;
    module: string;
    moduleValidationWorksheet: string;

    ngOnInit() {
        this.siteService.app_getCurrentSite().then((site: Site) => {
            if (!site) return;

            this.site = site;

            if (this.site.Type == 'HIPAA')
                this.moduleValidationWorksheet = 'Regenerate EPHI Scan System Selection Worksheet';
            else if (this.site.Type == 'CYBERINSURANCE')
                this.moduleValidationWorksheet = 'Regenerate File Scan Validation Worksheet';
            else this.moduleValidationWorksheet = 'Regenerate Personal Data Validation Worksheet';
        });
    }

    restartAssessment() {
        this.restartType = 'Entire Assessment';
        (this.selectRestartTypeModal as any).show();
    }

    closeSelectRestartTypeModalAction(isOk: boolean) {
        (this.selectRestartTypeModal as any).hide();

        if (isOk) {
            this.warningMessage =
                'Restarting the assessment will remove previously create reports and some worksheets as they may be invalid.   Reports and worksheets will be re-generated during the assessment process.<br><br>Proceed with restart?';

            if (this.restartType == 'Entire Assessment') {
                //                this.warningMessage = 'Restarting an assessment will lose all current progress and initiate new scans. Are you sure you want to restart this assessment?';
                try {
                    (this.confirmRestartModal as any).show();
                } catch (err) {
                    console.log('err: ' + err);
                }
            } else if (this.restartType == 'Internal Scans') {
                //                this.warningMessage = 'Restarting internal scans will remove internal scan related work items. Are you sure you want to restart the internal scans?';
                (this.confirmRestartModal as any).show();
            } else if (this.restartType == 'External Vulnerability Scan') {
                //                this.warningMessage = 'Restarting the external scan will remove external scan related work items. Are you sure you want to restart the external scan?';
                (this.confirmRestartModal as any).show();
            } else if (this.restartType == 'Network PD Scan') {
                (this.confirmRestartModal as any).show();
            }

            if (this.restartType == this.moduleValidationWorksheet) {
                (this.confirmRestartModal as any).show();
            }
        }
    }

    closeConfirmModalAction(isConfirmed: boolean): Promise<any> {
        return new Promise((resolve, reject) => {
            (this.confirmRestartModal as any).hide();
            if (isConfirmed) {
                if (this.siteId > 0) {
                    // this.processingRestart = true; // set to disable restart button

                    if (this.restartType == 'Entire Assessment') {
                        this.notificationService.toast.info(
                            'Please Wait',
                            'Requested Operation In Progress'
                        );
                        this.messageService.broadcast('ASSESSMENT_RESTARTING');
                        this.siteService.restartCurrentAssessment(this.siteId).then((s) => {
                            this.notificationService.toast.success(
                                'Success',
                                'Assessment Restarted (Entire assessment)'
                            );
                            this.messageService.broadcast('ASSESSMENT_RESTARTED');
                            resolve(true);
                        });
                    } else if (this.restartType == 'Internal Scans') {
                        this.notificationService.toast.info(
                            'Please Wait',
                            'Requested Operation In Progress'
                        );
                        this.siteService.restartInternalScans(this.siteId).then((s) => {
                            this.notificationService.toast.success(
                                'Success',
                                'Assessment Restarted (Internal Scans)'
                            );
                            this.messageService.broadcast('ASSESSMENT_RESTARTED');
                            resolve(true);
                        });
                    } else if (this.restartType == 'External Vulnerability Scan') {
                        this.notificationService.toast.info(
                            'Please Wait',
                            'Requested Operation In Progress'
                        );
                        this.siteService.restartExternalScan(this.siteId).then((s) => {
                            this.notificationService.toast.success(
                                'Success',
                                'Assessment Restarted (External Vulnerability Scan)'
                            );
                            this.messageService.broadcast('ASSESSMENT_RESTARTED');
                            resolve(true);
                        });
                    } else if (this.restartType == 'Network PD Scan') {
                        this.notificationService.toast.info(
                            'Please Wait',
                            'Requested Operation In Progress'
                        );
                        this.siteService.restartPersonalDataScan(this.siteId).then((s) => {
                            this.notificationService.toast.success(
                                'Success',
                                'Assessment Restarted (Network PD Scan)'
                            );
                            this.messageService.broadcast('ASSESSMENT_RESTARTED');
                            resolve(true);
                        });
                    } else if (this.restartType == this.moduleValidationWorksheet) {
                        this.notificationService.toast.info(
                            'Please Wait',
                            'Requested Operation In Progress'
                        );

                        this.siteService.restartPersonalDataWorksheet(this.siteId).then((s) => {
                            this.notificationService.toast.success(
                                'Success',
                                'Assessment Restarted ' + this.moduleValidationWorksheet
                            );
                            this.messageService.broadcast('ASSESSMENT_RESTARTED');
                            resolve(true);
                        });
                    }

                    // disable button after restarting
                    (this.restartButton as any).disabled = 'true';
                } else {
                    resolve(true);
                }
            } else {
                resolve(true);
            }
        });
    }
}
