<div #donut_chart class="donut-chart-container">
    <p-chart [data]="data"
             [height]="height"
             [options]="option"
             [plugins]="plugins"
             [width]="width"
             type="doughnut">
    </p-chart>
    <div #legend_container class="legend-container"></div>
</div>
