<sds-loader [complete]="loadingComplete">
    <sds-breadcrumb-trail [crumbs]="breadcrumbs"></sds-breadcrumb-trail>

    <div class="ui grid dashboard" *ngIf="kvsNotProvisioned">
        <div class="nine wide column">
            <div class="ui grid">
                <div class="twelve wide column">
                    <itc-banner type="info" title="KVS Not Provisioned">
                        <ng-container *ngIf="chNotProvisioned">
                            This functionality is only associated with the KVS product.
                            <br />
                            Contact your Account Representative to learn more about this product.
                        </ng-container>
                        <ng-container *ngIf="chNoSites">
                            There are no KVS sites available to associate with this functionality.
                            <br />
                            Create a new KVS site to access this functionality.
                        </ng-container>
                    </itc-banner>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!kvsNotProvisioned">
        <header class="pageHeader">
            <h1>Exclusion Rules</h1>
            <help-button
                title="Exclusion Rules"
                text="Exclusion rules allow you to define specific issues to exclude from risk reporting - filters include OID, date range, site name, and so on."
                url="/vs/help/vulscan/site-false-positive-management.htm"></help-button>
        </header>

        <kvs-false-positives
            [siteId]="site?.Id"
            [siteName]="site?.Name"
            pageType="site"></kvs-false-positives>
    </div>
</sds-loader>
