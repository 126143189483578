/* these are rgba because the pie chart won't display them properly without */
export const KvsCriticalColor = 'rgba(145, 36, 62, 1)';
export const KvsHighColor = 'rgba(221, 75, 80, 1)';
export const KvsMedColor = 'rgba(241, 140, 67, 1)';
export const KvsLowColor = 'rgba(248, 200, 81, 1)';
export const KvsNoneColor = 'rgba(218, 218, 218, 1)';
export const KvsCriticalThreshold = 9;
export const KvsHighThreshold = 7;
export const KvsMedThreshold = 4;
export const KvsLowThreshold = 0.1;
