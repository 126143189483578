import { Injectable } from '@angular/core';
import { AuthHttp } from 'app/core/auth';
import { ErrorService } from 'app/core/error/error.service';
import { environment } from 'environments/environment';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DiscoveryAgentService {
    private apiUrl: string;
    constructor(private http: AuthHttp, private E: ErrorService) {
        this.apiUrl = environment.apiUrl;
    }

    getOrgDiscoveryAgentDefaultSettings(organizationId: number): Promise<any> {
        const url: string =
            this.apiUrl + `DiscoveryAgent/Settings/${organizationId}`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    sendUpdateToDiscoveryAgentById(organizationId: number,applianceId: string): Promise<any> {
        const url: string = this.apiUrl + `DiscoveryAgent/${organizationId}/Appliances/${applianceId}/Update`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any);
    }

    getLogsForApplianceById(organizationId: number, applianceId: string): Promise<any> {
        const url: string = this.apiUrl + `DiscoveryAgent/${organizationId}/Appliances/${applianceId}/DownloadLogs`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any);
    }

    getAuditForApplianceById(organizationId: number, applianceId: string): Promise<any> {
        const url: string = this.apiUrl + `DiscoveryAgent/${organizationId}/Appliances/${applianceId}/DownloadAudit`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any);
    }

    getAutoUpdateForApplianceById(organizationId: number, applianceId: string): Promise<any> {
        const url: string = this.apiUrl + `DiscoveryAgent/${organizationId}/Appliances/${applianceId}/AutoUpdate`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any);
    }
    sendAutoUpdateToApplianceById(organizationId: number, applianceId: string, enabled: boolean): Promise<any> {
        const url: string = this.apiUrl + `DiscoveryAgent/${organizationId}/Appliances/${applianceId}/AutoUpdate`;
        return this.http
            .post(url, { Enabled: enabled })
            .toPromise()
            .then((res) => res as any);
    }
}
