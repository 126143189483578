<div class="login_container pwd-reset-form" *ngIf="!hashLoading">
    <ng-container *ngIf="!isHashInvalid && !resetSuccessful">
        <div>
            <h2 style="font-weight:500;">Reset Password</h2>
            <p style="margin-top:16px;">
                Enter a new password for your account.
            </p>
        </div>

        <form class="ui form"
              name="changePasswordForm"
              [formGroup]="passwordForm"
              (submit)="submit()">
            <div>

                <password-form-fields formControlName="newPass"
                                      (onError)="passwordCheckMessage = $event"></password-form-fields>
                <itc-banner type="error" *ngIf="passwordCheckMessage">
                    {{ passwordCheckMessage }}
                </itc-banner>

                <button class="itc fluid button large"
                        [class.disabled]="!passwordForm.valid"
                        name="pwdresetButton"
                        [ngClass]="{ loading: loading }"
                        type="submit"
                        tabindex="3"
                        [disabled]="!passwordForm.valid"
                        >
                    Reset
                </button>


                <div id="backToLogin">
                    <a routerLink="./login">
                        Back to Login
                    </a>
                </div>
            </div>
        </form>

    </ng-container>


    <ng-container *ngIf="isHashInvalid && !resetSuccessful">
        <div>
            <div class="big-icon">
                <fa-icon icon="link"></fa-icon>
            </div>
            <h2 style="font-weight:500;">Link Expired</h2>
            <p style="margin-top:16px;">
                Sorry, your password change request has expired. Please ensure that you are using the most recent link emailed to you or request the change again.
            </p>
        </div>

        <button class="itc fluid button large"
                name="backtoLoginBtn"
                type="button"
                routerLink="./login">
            Back to Login
        </button>

    </ng-container>

    <ng-container *ngIf="resetSuccessful">
        <div>
            <div class="big-icon">
                <fa-icon icon="check"></fa-icon>
            </div>
            <h2 style="font-weight:500;">Password Reset</h2>
            <p style="margin-top:16px;">
                Your password has been reset. Use your new password to log in.
            </p>
        </div>

        <button class="itc fluid button large"
                name="backtoLoginBtn"
                type="button"
                routerLink="./login">
            Back to Login
        </button>

    </ng-container>

</div>


