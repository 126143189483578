import { DataMap } from '@grapecity/wijmo.grid';

export class ColumnProxy {
    header: string;
    choiceMap: DataMap;
    choices: any[];
    showDropDown: boolean;
    colId: string;
    isReadOnly: boolean;
    isCheckBox: boolean;
    width: number;
    class: string;
}
