import { Component, OnInit } from '@angular/core';
import { Setting, SettingService, SDSP_SETTINGS } from 'app/settings';
import { UiService } from 'app/core/ui.service';

@Component({
    selector: 'sds-download',
    templateUrl: './download.component.html',
    styleUrls: ['./download.component.css'],
})
export class DownloadComponent implements OnInit {
    constructor(private settingService: SettingService, private uiService: UiService) {}

    customBranding: boolean;
    logoImage: string;
    theme: string;

    ngOnInit() {
        this.uiService.setTitle('Download');
        let localSettings = JSON.parse(localStorage.getItem(SDSP_SETTINGS) || '{}');
        this.settingService.getBranding().then((settings) => {
            let customBranding = settings.find((s: Setting) => s.Name == 'EnableCustomBranding');
            if (customBranding && customBranding.Value == 'true') {
                this.customBranding = true;
                localSettings['EnableCustomBranding'] = true;
            }

            let theme = settings.find((s: Setting) => s.Name == 'Theme');
            if (theme) {
                this.theme = theme.Value;
                localSettings['Theme'] = theme.Value;
            }

            if (this.customBranding) {
                //let cname = settings.find((s: Setting) => s.Name == 'CompanyName');
                //if (cname) {
                //  this.message = cname.Value;
                //  localSettings['CompanyName'] = cname.Value;
                //}

                let logo = settings.find((s: Setting) => s.Name == 'CompanyLogo');
                if (logo) {
                    this.logoImage = logo.Value;
                    localSettings['CompanyLogo'] = logo.Value;
                }
            }

            // Write back any new values to the local settings
            localStorage.setItem(SDSP_SETTINGS, JSON.stringify(localSettings));
        });
    }

    downloadFile(file) {
        window.open('https://download.rapidfiretools.com/download/' + file);
    }
}
