import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { inject } from '@angular/core';
import { SiteService } from '../../../sites';
import { Site } from 'app/sites/shared/site.model';

export const ExpiredSiteGuard: CanActivateFn = async (route, state): Promise<UrlTree | boolean> => {
    let siteService = inject(SiteService);
    let site = siteService.getCurrentSite();
    let router = inject(Router);

    if (!site) {
        let splitURL = state.url.split('/');
        let routeSiteName = splitURL[1] == 'site' ? decodeURIComponent(splitURL[2]) : '';
        site = await siteService.getSiteByNameLimitedData(routeSiteName);
    }
    return checkLicenseViolation(site, router);
};

function checkLicenseViolation(site: Site, router: Router): boolean | UrlTree {
    if (site.IsLicenseInactive) {
        return router.createUrlTree(['/']);
    } else {
        return true;
    }
}
