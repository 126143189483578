import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { AuthHttp } from 'app/core/auth';
import { NotificationService } from '../../shared/itc/notification/notification.service';

@Injectable({ providedIn: 'root' })
export class ErrorService {
    private apiUrl: string;
    constructor(
        private router: Router,
        private notificationService: NotificationService,
        private http: AuthHttp
    ) {
        this.apiUrl = environment.apiUrl;
    }

    private currentError: AppError;

    error(err?: AppError): Promise<any> {
        if (err) this.currentError = err;
        else
            this.currentError = {
                title: 'Oops!',
                description: 'Something went wrong.',
            };
        this.router.navigateByUrl('/error');

        return Promise.reject(err);
    }

    errorToast(err: AppError) {
        this.notificationService.toast.error(err.title, err.description);
    }

    getError(): AppError {
        if (this.currentError) return this.currentError;
        else
            return {
                title: 'Oops!',
                description: 'Something went wrong.',
            };
    }

    logEvent(type: 'info' | 'error' | 'trace' | 'debug' | 'fatal', message: string, args: any[]) {
        const url: string = this.apiUrl + `Logger/${type}`;
        let postbody = { message: message, args: args };
        return this.http
            .post(url, postbody)
            .toPromise()
            .then((res) => res);
    }
}

export class AppError {
    title: string;
    description: string;
}
