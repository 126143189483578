import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[autoResize]',
    standalone: true,
})
export class TextareaAutoResizeDirective {
    constructor(private elementRef: ElementRef) {}

    @HostListener('input')
    onInput() {
        this.resize();
    }

    @HostListener('refreshHeight')
    onRefresh() {
        this.resize();
    }

    ngOnInit() {
        if (this.elementRef.nativeElement.scrollHeight) {
            setTimeout(() => this.resize());
        }
    }

    resize() {
        this.elementRef.nativeElement.style.height = '0';
        this.elementRef.nativeElement.style.height =
            this.elementRef.nativeElement.scrollHeight + 2 + 'px';
    }
}
