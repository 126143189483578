import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';

import { AuthService, SiteRoles } from 'app/core/auth';
import { LoginRequest, LoginService } from './login.service';
import { SDSP_SETTINGS, Setting, SettingService } from 'app/settings';
import { NavService } from 'app/core/nav/nav.service';
import { UiService } from 'app/core/ui.service';

import { EulaService } from '../core/eula/eula.service';
import { KvsService } from '../sites/shared/kvs.service';

import { NotificationService } from '../shared/itc/notification/notification.service';
import { OrganizationsService } from 'app/organizations/organizations.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthService,
        private loginService: LoginService,
        private settingService: SettingService,
        private navService: NavService,
        private uiService: UiService,
        private eulaService: EulaService,
        private kvsService: KvsService,
        private notificationService: NotificationService,
        private organizationsService: OrganizationsService
    ) {}

    loading: boolean = false;
    // version: string = 'Dev';
    rememberCtrl: UntypedFormControl = new UntypedFormControl();
    @ViewChild('2FAinput', { static: true }) _2FAinput: any;
    @ViewChild('itcUserMapFailureModal', { static: true }) itcUserMapFailureModal: any;
    @ViewChild('eulaModal', { static: true }) eulaModal: any;
    @ViewChild('setupMfaModal', { static: true }) setupMfaModal: any;

    customBranding: boolean;
    logoImage: string;
    theme: string;
    allowCustomBranding = true;

    resetView: boolean;
    showResetPasswordForm: boolean;
    rpUsername: string;
    resetError: any;
    resetSuccess = false;
    collectOemIdView: boolean;
    oemToken: string;
    externalAccountId: string;
    externalApiHost: string;
    vsaUserDoesNotExistView: boolean;
    vsaActivationFailureView: boolean;
    activationFailureMessage: string;
    vsaActivationSuccessView: boolean;
    collectPasswordView: boolean;
    mapItcUserView: boolean;
    accountDisabledView: boolean;
    disabledMessage: string;
    newOemPassword: string;
    newOemPasswordConfirm: string;
    kaseyausername: string;
    doItcMapping: boolean;
    itcEnabled: boolean;
    itcError: string;
    currentYear: number;
    //accountNeeded: boolean;
    accountId: string;

    eula: string;
    isTwoFactorSetupComplete: boolean;

    info: LoginRequest = {
        username: '',
        password: '',
        remember: false,
    } as LoginRequest;

    kaseyaInfo: LoginRequest = {
        username: '',
        password: '',
        remember: false,
    } as LoginRequest;

    isKVSPortal: boolean = false;

    ngUnsubscribe$: Subject<boolean> = new Subject();

    ngOnInit() {
        this.uiService.setTitle('Login');
        this.currentYear = new Date().getFullYear();

        this.isKVSPortal = this.kvsService.isKVSPortal();
        if (this.isKVSPortal) {
            this.allowCustomBranding = false;
        } // KVS doesn't allow custom branding

        let localSettings = JSON.parse(localStorage.getItem(SDSP_SETTINGS) || '{}');
        if (this.allowCustomBranding) {
            if (
                localSettings['EnableCustomBranding'] == 'true' ||
                localSettings['EnableCustomBranding'] == true
            ) {
                if (!this.isKVSPortal) {
                    this.customBranding = true;
                }
            }

            if (localSettings['CompanyLogo']) {
                this.logoImage = localSettings['CompanyLogo'];
            }

            if (localSettings['Theme']) {
                this.theme = localSettings['Theme'];
            }
        }
        this.settingService.getBranding().then((settings) => {
            let customBranding = settings.find((s: Setting) => s.Name == 'EnableCustomBranding');
            if (customBranding && customBranding.Value == 'true') {
                if (this.allowCustomBranding) {
                    this.customBranding = true;
                }
                localSettings['EnableCustomBranding'] = true;

                let logo = settings.find((s: Setting) => s.Name == 'CompanyLogo');
                if (logo) {
                    if (this.allowCustomBranding) {
                        this.logoImage = logo.Value;
                    }
                    localSettings['CompanyLogo'] = logo.Value;
                }
            }

            let theme = settings.find((s: Setting) => s.Name == 'Theme');
            if (theme) {
                if (this.allowCustomBranding) {
                    this.theme = theme.Value;
                }
                localSettings['Theme'] = theme.Value;
            }

            let itCompleteEnabled = settings.find((s: Setting) => s.Name == 'ITCOMPLETE_ENABLED');
            if (itCompleteEnabled) {
                this.itcEnabled = itCompleteEnabled.Value == 'True';
            } else {
                this.itcEnabled = true;
            }

            // Write back any new values to the local settings
            localStorage.setItem(SDSP_SETTINGS, JSON.stringify(localSettings));
        });

        this.route.fragment.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((frag: string) => {
            this.resetView = frag == 'reset-password';
        });

        this.route.url.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((segments: UrlSegment[]) => {
            this.showResetPasswordForm = segments?.length && segments[0].path == 'passwordreset';
        });

        this.route.queryParams.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((params) => {
            this.checkForKaseyaOAuth(params);
            this.checkForK1Saml(params);
            this.checkForK1Mapping(params);
            this.checkForErrors(params);
        });

        this.navService.saveReturnState('login');
    }

    getCookie(name) {
        var value = '; ' + document.cookie;
        var parts = value.split('; ' + name + '=');
        if (parts.length == 2) return parts.pop().split(';').shift();
    }

    checkForErrors(params: any) {
        if (params['err']) {
            if (params['err'] === 'k1u') {
                setTimeout(() => {
                    this.notificationService.banner.error(
                        '',
                        'There was an error logging into the correct user. Contact your administrator to confirm your login username and email are unique and try again.'
                    );
                }, 500);
            }
            if (params['err'] === 'k1unknown') {
                setTimeout(() => {
                    this.notificationService.banner.error(
                        '',
                        'An unknown error occured while logging in with SSO.  Please contact support if this issue persists.'
                    );
                }, 500);
            }
        }
    }

    checkForK1Saml(params: any) {
        // if code is sent in query params then Kaseya is trying to OAuth into our portal
        if (params['k1s']) {
            let _sK1S: string = params['k1s'];
            let _sK1Db64: string = params['k1d'];

            let _sK1D = '';
            if (_sK1Db64) {
                _sK1D = window.atob(_sK1Db64);
                this.authService.saveTargetUrl(_sK1D);
            }
            this.kaseyaInfo.oemid = _sK1S;

            this.loginService
                .postK1S(this.kaseyaInfo)
                .then(() => this.success())
                .catch((err) => {
                    this.error(err);
                });
        }
    }

    checkForK1Mapping(params: any) {
        // if code is sent in query params then it complete is trying to map an existing user into our portal

        if (params['k1m']) {
            let _sK1M: string = params['k1m'];

            this.kaseyaInfo.oemid = _sK1M;
            this.mapItcUserView = true;
        }
    }

    checkForKaseyaOAuth(params: any) {
        // if code is sent in query params then Kaseya is trying to OAuth into our portal
        //if (params['showaccountid']) {
        //    this.accountNeeded = true;
        //}

        if (params['kul'] && params['koid']) {
            let _sB64KaseyaUserLogin: string = params['kul'];
            let _sB64KaseyaOrganizationId: string = params['koid'];

            this.kaseyaInfo.username = _sB64KaseyaUserLogin;
            this.kaseyaInfo.oemid = _sB64KaseyaOrganizationId;
            this.kaseyaInfo.oemtoken = params['kat'];
            this.oemToken = params['kat']; // store for later because we will have to pass the value if the account still needs activating
            this.externalApiHost = params['kah'];
            this.loginService
                .postKaseyaOAuthLogin(this.kaseyaInfo)
                .then(() => this.success())
                .catch((err) => {
                    if (err.error == 'ShowExternalAccount') {
                        this.collectOemIdView = true;
                    } else if (err.error == 'ShowVsaUserDoesNotExist') {
                        this.vsaUserDoesNotExistView = true;
                    } else if (err.error.startsWith('ShowSetPassword')) {
                        this.kaseyausername = err.error.split('|')[1];

                        this.collectPasswordView = true;
                    } else {
                        //for (var propName in err) {
                        //    console.log(propName, err[propName]);
                        // }
                        this.error(err);
                    }
                });
        }
    }

    mapItcUser() {
        this.mapItcUserView = false;
        this.doItcMapping = true;
        this.submit();
    }

    itcUserMapFailure() {
        this.itcUserMapFailureModal.hide();
        this.authService.clearToken();
    }

    submit() {
        this.notificationService.banner.clear();
        this.loading = true;
        this.info.remember = this.rememberCtrl.value;
        this.loginService
            .postLogin(this.info)
            .then(() => {
                this.loading = false;

                if (this.authService.isAccountDisabled) {
                    this.disabledMessage = this.authService.disabledMessage;
                    this.accountDisabledView = true;
                }

                if (this.authService.k1Required) {
                    this.k1SsoRequired();
                }

                if (this.authService.twoFactorRequired && this.authService.twoFactorEnabled) {
                    this.goToTwoFactor();
                }

                if (this.authService.twoFactorRequired && !this.authService.twoFactorEnabled) {
                    this.setupTwoFactor();
                }

                if (!this.authService.twoFactorRequired && this.authService.getToken()) {
                    this.success();
                }
            })
            .catch((err) => this.error(err));
    }

    twoFactorLogin: boolean;
    totpCode: string;
    twoFactorError: boolean;

    k1SsoRequired() {
        setTimeout(() => {
            this.notificationService.banner.error(
                '',
                'Could not log in. Please click the “Log in with KaseyaOne” button to authenticate'
            );
        }, 500);
    }

    goToTwoFactor() {
        this.twoFactorLogin = true;
        setTimeout(() => this._2FAinput.nativeElement.focus(), 0);
    }

    setupTwoFactor() {
        this.isTwoFactorSetupComplete = false;
        this.setupMfaModal.show({ closable: false });
    }

    closeSetupMfaModal() {
        this.setupMfaModal.hide();
        this.success();
    }

    cancelSetupMfaModal() {
        this.setupMfaModal.hide();
        this.authService.setToken('');
    }

    submitTwoFactor() {
        this.twoFactorError = false;
        this.loading = true;
        var remember = this.rememberCtrl.value;
        this.loginService
            .TOTPLogin({ username: this.info.username, totp: this.totpCode })
            .then(() => {
                if (this.authService.getToken()) this.success();
            })
            .catch((err) => {
                this.loading = false;
                this.twoFactorError = true;
                this.totpCode = '';
                this.notificationService.banner.error(
                    '',
                    'The login code is invalid or may have expired'
                );
            });
    }

    resetPassword() {
        if (this.resetSuccess) return;

        this.resetError = false;
        this.loading = true;
        this.loginService
            .resetPassword(this.rpUsername)
            .then(() => {
                this.loading = false;
                this.resetSuccess = true;
            })
            .catch((err) => {
                this.loading = false;
                this.resetSuccess = true;
            });
    }

    submitOemId() {
        //this.kaseyaInfo.oemtoken = this.oemToken;
        this.kaseyaInfo.oemid = this.externalAccountId;
        this.kaseyaInfo.password = this.externalApiHost;

        this.loginService
            .postKaseyaId(this.kaseyaInfo)
            .then(() => {
                this.collectOemIdView = false;
                this.vsaActivationSuccessView = true;
            })
            .catch((err) => {
                this.activationFailureMessage = err.error;
                this.collectOemIdView = false;
                this.vsaActivationFailureView = true;
                //if (err.error == 'ShowExternalAccount') {
                //    this.collectOemIdView = true;
                //}
                //for (var propName in err) {
                //    console.log(propName, err[propName]);
                // }
                this.error(err);
            });
    }

    useStandardCredentials() {
        this.vsaUserDoesNotExistView = false;
        this.resetView = false;
        this.accountDisabledView = false;
    }

    retryActivation() {
        this.activationFailureMessage = '';
        this.collectOemIdView = true;
        this.vsaActivationFailureView = false;
    }

    continueSuccessfulActivation() {
        this.loginService
            .postKaseyaOAuthLogin(this.kaseyaInfo)
            .then(() => this.success())
            .catch((err) => {
                if (err.error == 'ShowExternalAccount') {
                    //this.collectOemIdView = true;
                } else if (err.error == 'ShowVsaUserDoesNotExist') {
                    //this.vsaUserDoesNotExistView = true;
                } else {
                    //for (var propName in err) {
                    //    console.log(propName, err[propName]);
                    // }
                    this.error(err);
                }
            });
    }

    submitNewOemPassword() {
        let newPasswordInfo: LoginRequest = new LoginRequest();

        if (!this.newOemPassword || !this.newOemPasswordConfirm) {
            this.notificationService.banner.error('', 'Passwords do not match');
            return;
        }
        if (this.newOemPassword === this.newOemPasswordConfirm) {
        } else {
            this.notificationService.banner.error('', 'Passwords do not match');
            return;
        }

        newPasswordInfo.username = this.kaseyaInfo.username;
        newPasswordInfo.password = this.newOemPassword;
        this.loginService
            .postKaseyaNewPassword(newPasswordInfo)
            .then(() => {
                this.collectPasswordView = false;
                this.loginService
                    .postKaseyaOAuthLogin(this.kaseyaInfo)
                    .then(() => this.success())
                    .catch((err) => {
                        if (err.error == 'ShowExternalAccount') {
                            //this.collectOemIdView = true;
                        } else if (err.error == 'ShowVsaUserDoesNotExist') {
                            //this.vsaUserDoesNotExistView = true;
                        } else {
                            //for (var propName in err) {
                            //    console.log(propName, err[propName]);
                            // }
                            this.error(err);
                        }
                    });
            })
            .catch((err) => {
                this.error(err);
            });
    }

    private success() {
        this.loading = false;

        if (this.doItcMapping) {
            this.doItcMapping = false;
            this.loginService
                .postK1M(this.kaseyaInfo)
                .then((res) => {
                    this.checkEula();
                })
                .catch((err) => {
                    this.itcError = err.error;
                    this.itcUserMapFailureModal.show();
                });
        } else {
            this.checkEula();
        }
    }

    checkEula() {
        // check if we need to show EULA
        this.eulaService.getEulaToAccept().then((res) => {
            // parse as json because we're getting a strong instead of json object
            res = JSON.parse(res);
            if (res.id > 0) {
                this.eula = res.eula;
                this.eulaModal.show({ closable: false });
            } else {
                this.navigateToDestination();
            }
        });
    }

    acceptEula(accepted) {
        if (accepted) {
            // eula accepted
            // run service call to accept
            this.eulaService.acceptEula().then(() => {
                // send them on their way
                this.eulaModal.hide();
                this.navigateToDestination();
            });
        } else {
            // eula declined
            // log user out
            this.eulaModal.hide();
            this.router.navigate(['logout']);
        }
    }

    navigateToDestination() {
        let url = this.authService.getSavedUrl();

        if (url) this.router.navigateByUrl(url);
        else {
            this.organizationsService.getOrganizations().then((res) => {
                if (res.length == 0) {
                    this.router.navigate(['organizations', '__all']);
                } else {
                    this.router.navigate(['organizations']);
                }
            });
        }
    }

    private error(err: any) {
        this.loading = false;
        if (err.status == 401) {
            this.notificationService.banner.error('', 'Invalid login credentials');
        } else {
            this.notificationService.banner.error('', 'There was a problem logging you in.');
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next(true);
        this.ngUnsubscribe$.complete();
    }
}
