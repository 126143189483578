import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AuthService } from 'app/core/auth/auth.service';
import { PreferencesService } from '../preferences.service';
import { NotificationService } from '../../../shared/itc/notification/notification.service';

@Component({
    selector: 'sds-mfa-enable',
    templateUrl: './mfa-enable.component.html',
    styleUrls: ['./mfa-enable.component.css'],
})
export class MfaEnableComponent implements OnInit {
    twoFactorKeyCtrl = new FormControl();
    twoFactorSecret: string;
    twoFactorQRCode: string;

    @Input() preferences: Map<string, any> = new Map<string, any>();
    @Output() onPreferenceChange = new EventEmitter<Map<string, any>>();

    @Input() isTwoFactorEnabled: boolean;
    @Output() isTwoFactorEnabledChange = new EventEmitter<boolean>();

    @Input() isRegenerate: boolean;

    constructor(
        private authService: AuthService,
        private prefService: PreferencesService,
        private notificationService: NotificationService
    ) {}

    ngOnInit() {}

    totpCtrl: FormControl = new FormControl();
    enablingTwoFactor: boolean;
    enableTwoFactor() {
        this.totpCtrl.setErrors(null);

        if (!this.totpCtrl.value) {
            this.totpCtrl.setErrors({ empty: 'value is empty' });
            return;
        }

        this.enablingTwoFactor = true;
        this.prefService.enableTwoFactor(this.totpCtrl.value).then((res) => {
            this.enablingTwoFactor = false;
            if (res.enabled) {
                this.isTwoFactorEnabled = true;
                this.isTwoFactorEnabledChange.emit(this.isTwoFactorEnabled);
            } else {
                // there was an error, likely invalid code
                console.log(res);
                this.notificationService.toast.error(
                    'Invalid Login Code',
                    'Please check the authentication code in Google Authenticator and try again. The code may have expired, and a new code issued.'
                );
            }
        });
    }

    generatingKey: boolean;
    generateSecretKey() {
        this.generatingKey = true;
        this.prefService.generateTwoFactorSecret().then((key) => {
            this.onKeyReturned(key);
        });
    }

    regenerateSecretKey() {
        this.generatingKey = true;
        this.prefService.regenerateTwoFactorSecret().then((key) => {
            this.onKeyReturned(key);
        });
    }

    onKeyReturned(key: any) {
        this.generatingKey = false;
        this.twoFactorSecret = key;
        this.twoFactorKeyCtrl.setValue(key);
        let issuer = 'RapidFireTools';
        let username = this.authService.getIdentity().username;
        this.twoFactorQRCode = encodeURI(
            `otpauth://totp/${issuer}:${username}?secret=${this.twoFactorSecret}&issuer=${issuer}`
        );
    }

    setTwoFactorKeyCtrl(val: string) {
        this.twoFactorKeyCtrl.setValue(val);
    }
}
