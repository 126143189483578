import {
    Injectable,
    ViewContainerRef,
    ComponentRef,
    ComponentFactoryResolver,
    Injector,
} from '@angular/core';
import { Subject, Observable } from 'rxjs';

import { Toast, ToastType } from './toast.model';
import { ToastComponent } from './toast.component';
import { ANIMATE_DURATION, TOAST_DURATION } from './toast.config';

declare var $: any;

@Injectable()
export class ToastService {
    constructor(private resolver: ComponentFactoryResolver) {}

    private sub: Subject<Toast> = new Subject<Toast>();
    private toasts: ComponentRef<ToastComponent>[] = [];

    toast(type: ToastType, title: string, message: string) {
        let toast: Toast = {
            type: type,
            title: title,
            message: message,
        };

        this.sub.next(toast);
    }

    error(title: string, message: string) {
        this.toast(ToastType.Error, title, message);
    }

    info(title: string, message: string) {
        this.toast(ToastType.Info, title, message);
    }

    success(title: string, message: string) {
        this.toast(ToastType.Success, title, message);
    }

    warning(title: string, message: string) {
        this.toast(ToastType.Warning, title, message);
    }

    download(title: string, message: string) {
        this.toast(ToastType.Download, title, message);
    }

    getObservable(): Observable<Toast> {
        return this.sub.asObservable();
    }

    makeToast(container: ViewContainerRef, toast: Toast) {
        // get the factory for the component
        let factory = this.resolver.resolveComponentFactory(ToastComponent);

        // provide the injected parameter
        let providers = [{ provide: Toast, useValue: toast }];

        // get the injector
        // let injector = ReflectiveInjector.fromResolvedProviders(ReflectiveInjector.resolve(providers), container.injector);
        let injector = Injector.create({ providers: providers, parent: container.injector });

        // create the component
        let component = factory.create(injector);
        container.insert(component.hostView);

        this.toasts.push(component);
        let _t = this.toasts;

        // set timeout function for destroying the toast
        window.setTimeout(function () {
            let element = component.location.nativeElement;

            if ($(element).is(':hover')) {
                // if use is hovering over toast, don't remove until they stop
                $(element).on('mouseleave', function () {
                    component.instance.hide();

                    window.setTimeout(function () {
                        component.destroy();
                        _t.splice(_t.indexOf(component), 1); // remove from this.toasts
                    }, ANIMATE_DURATION);
                });
            } else {
                component.instance.hide();

                window.setTimeout(function () {
                    component.destroy();
                    _t.splice(_t.indexOf(component), 1); // remove from this.toasts
                }, ANIMATE_DURATION);
            }
        }, TOAST_DURATION);
    }

    clear() {
        for (let toast of this.toasts) {
            toast.destroy();
        }
    }
}
