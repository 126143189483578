import { Component, isDevMode, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SemanticModalComponent } from 'app/semantic-legacy/semantic-legacy.module';
import {
    Observable,
    of,
    switchMap,
    map,
    Subject,
    distinctUntilChanged,
    debounceTime,
    takeUntil,
    tap,
    shareReplay,
    catchError,
} from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import { AuthService } from '../auth';
import { SiteService } from 'app/sites';
import { VideoJson, Video } from './video-tutorials.model';
import { RFT_URL } from '../constants';
@Component({
    selector: 'itc-video-tutorials',
    templateUrl: './video-tutorials.component.html',
})
export class VideoTutorialsComponent implements OnInit {
    VideoObj$: Observable<VideoJson>; // holds JSON object gotten by fetch
    displayedVideos$: Observable<Video[]>; // holds videos that are being displayed in the grid
    selectedCategory = 'all'; // which category is selected for display
    products: { [key: string]: boolean }; // a list of products to know what the user has access to
    hasCMSites = false; // This is used along with products to see if we should show CM videos
    totalVideoCount = 0; // Total count of videos that are in a category
    displayedVideoCount = 0; // Total count of videos that are actually being display
    currentVideo: Video; // Video that user clicked on to play
    nowPlaying = false; // Whether we should show the pleyer or not.
    searchCtrl: FormControl<string> = new FormControl('');
    hasError = false;
    ngUnsubscribe$: Subject<VideoTutorialsComponent> = new Subject();
    rft_url = RFT_URL;
    @ViewChild('videoModal') videoModal: SemanticModalComponent;

    constructor(private authService: AuthService, private siteService: SiteService) {}

    ngOnInit() {}

    initVideoTutorials(): void {
        this.products = this.authService.getProducts();
        this.hasCMSites = this.siteService.hasCMSites;

        this.getVideoJSON();

        this.searchCtrl.valueChanges
            .pipe(distinctUntilChanged(), debounceTime(400), takeUntil(this.ngUnsubscribe$))
            .subscribe((key) => {
                this.searchVideos(key.toLowerCase().trim());
            });
    }

    resetVideoTutorials(): void {
        this.nowPlaying = false;
        this.currentVideo = null;
        this.hasError = false;
        this.searchCtrl.setValue('', { emitEvent: false });
        this.ngUnsubscribe$.next(void 0);
        this.ngUnsubscribe$.complete();
    }

    display(): void {
        this.initVideoTutorials();
        this.videoModal.show({
            autofocus: false,
            onHide: () => this.resetVideoTutorials(),
        });
    }

    getVideoJSON(): void {
        const rfturl = `${this.rft_url}/data/`;
        const videoJSON = isDevMode()
            ? 'videotutorials-dev.json?v=' + Math.floor(Math.random() * Math.floor(10))
            : 'videotutorials.json';

        this.VideoObj$ = fromFetch(rfturl + videoJSON).pipe(
            switchMap((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Problem getting video list');
                }
            }),
            catchError((err) => {
                this.hasError = true;
                return of(null);
            }),
            map((all) => {
                if (all) {
                    // remove categories that the account doesn't have
                    all.categories = all.categories.filter((c) => {
                        if (
                            (!this.products.CMGRCProduct && c.name === 'Compliance Manager GRC') ||
                            ((!this.products.CMProduct || !this.hasCMSites) &&
                                c.name === 'Compliance Manager') ||
                            (!this.products.CHProduct && c.name === 'Cyber Hawk') ||
                            (!this.products.VulScanProduct && c.name === 'VulScan')
                        ) {
                            return false;
                        }
                        return true;
                    });
                    // remove videos that the account doesn't have
                    all.videos = all.videos.filter((v) => {
                        if (
                            (!this.products.CMGRCProduct &&
                                v.product === 'Compliance Manager GRC') ||
                            ((!this.products.CMProduct || !this.hasCMSites) &&
                                v.product === 'Compliance Manager') ||
                            (!this.products.CHProduct && v.product === 'Cyber Hawk') ||
                            (!this.products.VulScanProduct && v.product === 'VulScan')
                        ) {
                            return false;
                        }
                        return true;
                    });
                    this.totalVideoCount = all.videos.length;
                    this.displayedVideoCount = all.videos.length;
                }
                return all;
            }),
            shareReplay({ bufferSize: 1, refCount: true }), // so it doesn't keep firing the fromFetch
            takeUntil(this.ngUnsubscribe$)
        );

        this.displayedVideos$ = this.VideoObj$.pipe(map((all) => all.videos));
    }

    // called to update category when sidebar menu clicked
    updateVideoCategory(cat: string): void {
        if (cat === this.selectedCategory) {
            return;
        }
        console.log('updating category', cat);
        this.selectedCategory = cat;
        if (cat === 'all') {
            this.displayedVideos$ = this.VideoObj$.pipe(
                map((all) => all.videos),
                tap((r) => {
                    setTimeout(() => {
                        this.totalVideoCount = r.length;
                        this.displayedVideoCount = r.length;
                    });
                })
            );
        } else {
            this.displayedVideos$ = this.VideoObj$.pipe(
                map((all) => all.videos.filter((v) => v.categories.includes(cat))),
                tap((r) => {
                    setTimeout(() => {
                        this.totalVideoCount = r.length;
                        this.displayedVideoCount = r.length;
                    });
                })
            );
        }
        this.searchCtrl.setValue('', { emitEvent: false });
    }

    searchVideos(key: string): void {
        console.log('searching for key', key);
        if (this.selectedCategory === 'all') {
            // No selected category, and the search bar was cleared, get everything.
            if (key === '') {
                this.displayedVideos$ = this.VideoObj$.pipe(
                    map((all) => all.videos),
                    tap((r) =>
                        setTimeout(() => {
                            this.displayedVideoCount = r.length;
                        })
                    )
                );
            } else {
                // there's a search key, filter sites
                this.displayedVideos$ = this.VideoObj$.pipe(
                    map((all) => {
                        return all.videos.filter(
                            (v) =>
                                v.title.toLowerCase().indexOf(key) >= 0 ||
                                v.caption.toLowerCase().indexOf(key) >= 0
                        );
                    }),
                    tap((r) => {
                        setTimeout(() => {
                            this.displayedVideoCount = r.length;
                        });
                    })
                );
            }
        } else {
            // only search in the selected category
            if (key === '') {
                this.displayedVideos$ = this.VideoObj$.pipe(
                    map((all) =>
                        all.videos.filter((v) => v.categories.includes(this.selectedCategory))
                    ),
                    tap((r) =>
                        setTimeout(() => {
                            this.displayedVideoCount = r.length;
                        })
                    )
                );
            } else {
                this.displayedVideos$ = this.VideoObj$.pipe(
                    map((all) => {
                        return all.videos.filter(
                            (v) =>
                                (v.title.toLowerCase().indexOf(key) >= 0 ||
                                    v.caption.toLowerCase().indexOf(key) >= 0) &&
                                v.categories.includes(this.selectedCategory)
                        );
                    }),
                    tap((r) => {
                        setTimeout(() => {
                            this.displayedVideoCount = r.length;
                        });
                    })
                );
            }
        }
    }

    trackByFn(index, item) {
        return item.id;
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next(void 0);
        this.ngUnsubscribe$.complete();
    }
}
