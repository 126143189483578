<div class="ui card notesItem">
    <div class="content" style="padding-left: 20px; padding-right: 20px">
        <div class="header">
            <fa-icon icon="note"></fa-icon>
            Notes
            <a
                *ngIf="_notes?.HasPrivate"
                title="Confidential Note Available"
                alt="Confidential Note Available"
                (click)="viewMore()"
                class="lock icon lockLink"
                style="text-align: right">
                <span class="icon-lock"></span>
                View More
            </a>
            <a *ngIf="!_notes?.HasPrivate" (click)="viewMore()" class="viewMoreLink">View More</a>
        </div>
    </div>
    <div class="notesContainer" style="padding: 10px 20px">
        <div
            [innerHTML]="
                (_notes?.Body ? _notes?.Body : 'No notes yet; click View More -> Edit to add some')
                    | safeHtml
            "></div>
    </div>
</div>

<sm-modal
    class="md"
    title="{{ editMode ? 'Edit Notes' : 'Notes' }}"
    #modal
    (onModalShow)="modal.refresh()">
    <modal-content>
        <div>
            <div
                *ngIf="!editMode"
                [innerHTML]="
                    (_notes?.Body ? _notes?.Body : 'No notes yet; click Edit to add some')
                        | safeHtml
                "
                style="word-break: break-word; word-wrap: break-word"></div>
            <div [hidden]="!editMode">
                <div style="display: block">
                    <textarea
                        id="ckedit"
                        name="ckedit"
                        style="max-width: 547px; max-height: 138px"
                        rows="20"
                        cols="2000"
                        #ckedit></textarea>
                </div>
            </div>
        </div>
        <itc-button
            type="color"
            color="rgb(128,128,128)"
            *ngIf="hasPrivacyKey || (!hasPublicKey && !hasPrivacyKey)"
            (onclick)="togglePrivate()"
            style="margin: 30px 0 10px 0">
            {{ privateMode ? 'Hide' : 'View' }} Confidential Notes
        </itc-button>
        <itc-button
            type="secondary"
            *ngIf="!hasPrivacyKey && hasPublicKey"
            [disabled]="disableRequiredButton"
            (onclick)="requestAccess()"
            style="margin: 30px 0 10px 0">
            Request Access to Confidential Notes
        </itc-button>

        <div [hidden]="!privateMode">
            <div
                *ngIf="!editMode"
                [innerHTML]="
                    (_notes?.PrivateBody
                        ? _notes?.PrivateBody
                        : 'No confidential notes yet; click Edit to add some'
                    ) | safeHtml
                "></div>
            <div [hidden]="!editMode">
                <div style="display: block">
                    <textarea
                        id="ckeditPrivate"
                        style="max-width: 547px; max-height: 138px"
                        rows="20"
                        cols="2000"
                        #ckeditPrivate></textarea>
                </div>
            </div>
        </div>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" class="cancel" (onclick)="closeModal()">
            {{ editMode ? 'Cancel' : 'Close' }}
        </itc-button>
        <itc-button type="primary" (onclick)="editNotes(true)">
            {{ editMode ? 'Reset' : 'Edit' }}
        </itc-button>
        <itc-button *ngIf="editMode" type="primary" (onclick)="saveNotes()">Save</itc-button>
    </modal-actions>
</sm-modal>

<!--
<sm-modal title="Privacy Setup" #privacymodal>
    <modal-content>
        <div>
            <p>We need to set up some cryptographic keys for your account.  Please enter your portal password in order to verify your identity</p>
        </div>
        <form>
            <div class="field">
                Password: <input type="password" id="textboxPassword1" name="textboxPassword1" placeholder="Enter Password" [(ngModel)]="password1" autocomplete="off" />
            </div>
        </form>
    </modal-content>
    <modal-actions>
        <div class="ui button primary" (click)="closePrivacyModal(true)">
            OK
        </div>
        <div class="ui button primary" (click)="closePrivacyModal(false)">
            Cancel
        </div>
    </modal-actions>
</sm-modal>
    -->

<sm-modal class="md" title="Portal Password Setup" #passcodesetupmodal>
    <modal-content>
        <div>
            <p>
                You have been given permission to see private data for your account, however you
                have not yet viewed private data. Please enter your portal password to verify your
                identity
            </p>
        </div>
        <form (ngSubmit)="closePasscodeSetupModal(true)">
            <div
                class="ui input"
                style="line-height: 40px; margin-top: 10px"
                [class.error]="passwordInvalid">
                Password:
                <input
                    type="password"
                    id="textboxPrivacyCode1"
                    name="textboxPrivacyCode1"
                    placeholder="Enter Password"
                    [(ngModel)]="password1"
                    autocomplete="off"
                    style="margin-left: 15px" />
                <span class="errorText" *ngIf="passwordInvalid">Password Failed</span>
            </div>
        </form>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" class="cancel" (onclick)="closePasscodeSetupModal(false)">
            Cancel
        </itc-button>
        <itc-button type="primary" (onclick)="closePasscodeSetupModal(true)">OK</itc-button>
    </modal-actions>
</sm-modal>

<sm-modal class="xs" title="Secure Access Verification" #passcodemodal>
    <modal-content>
        <form (ngSubmit)="closePasscodeModal()">
            <label for="textboxDecryptPrivacyCode">Enter Password:</label>
            <div class="ui input" [class.error]="passwordInvalid" style="display: block">
                <input
                    type="password"
                    id="textboxDecryptPrivacyCode"
                    name="textboxDecryptPrivacyCode"
                    [(ngModel)]="privacyCode"
                    autocomplete="off" />
                <span
                    class="errorText"
                    *ngIf="passwordInvalid"
                    style="
                        padding-left: 0;
                        font-size: 12px;
                        color: #d9534f;
                        margin-top: -12px;
                        display: block;
                    ">
                    Password Failed
                </span>
            </div>
        </form>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" class="cancel">Cancel</itc-button>
        <itc-button type="primary" (onclick)="closePasscodeModal()">OK</itc-button>
    </modal-actions>
</sm-modal>

<sm-modal class="md" title="Error Sending Request" #requesterrormodal>
    <modal-content>
        <div>
            <p>
                An error occurred while attempting to send your request to view information. Please
                notify your RapidFire Tools Portal administrator and try again later.
            </p>
        </div>
    </modal-content>
    <modal-actions>
        <itc-button type="primary" (onclick)="requestErrorModal.hide()">OK</itc-button>
    </modal-actions>
</sm-modal>
