import { Component, OnDestroy, OnInit, isDevMode } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { Alert } from 'app/alerts';
import { AlertService } from 'app/alerts/shared/alert.service';
import { ErrorService } from 'app/core/error/error.service';
import { MessageService } from 'app/core/message.service';
import { UiService } from 'app/core/ui.service';

import { Site } from 'app/sites/shared/site.model';
import { SITE, PING_SITE } from 'app/sites/shared/constants';

const UI_KEY_PAGENO: string = 'site_todo_page_number';
const UI_KEY_PAGESIZE: string = 'site_todo_page_size';
const UI_KEY_HIDERELATED: string = 'site_todo_hide_related';

@Component({
    templateUrl: './site-todo.component.html',
    styleUrls: ['./site-todo.component.css'],
})
export class SiteTodoComponent implements OnInit, OnDestroy {
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private alertService: AlertService,
        private errorService: ErrorService,
        private messageService: MessageService,
        private uiService: UiService
    ) {}

    todos: Alert[];
    allTodos: Alert[];
    site: Site;

    filtered1: Alert[];
    filteredTodos: Alert[];

    sortedTodos: Alert[];

    pageSize: number;
    pageNumber: number;
    pagedTodos: Alert[];

    hideRelated: boolean;
    hideRelatedCtrl = new UntypedFormControl();

    loadingComplete: boolean;
    sub: Subscription;

    sortMethod: string = 'Created';
    sortDirection: string = 'desc';
    invertDefault: boolean = false;
    breadcrumbs = [];

    ngOnInit() {
        let crumbs = [{ path: '.', text: 'To Do' }];

        if (this.router.url.includes('cyber-hawk')) {
            crumbs.unshift({ path: '..', text: 'Cyber Hawk' });
            this.invertDefault = true;
        } else if (this.router.url.includes('compliance-manager')) {
            crumbs.unshift({ path: '..', text: 'Compliance Manager' });
            this.sortDirection = 'asc';
        }

        this.breadcrumbs = crumbs;

        let hideR = this.uiService.getControlValue(UI_KEY_HIDERELATED);
        this.hideRelated = hideR != void 0 ? hideR == 'true' : false;

        this.hideRelatedCtrl.setValue(this.hideRelated);
        this.hideRelatedCtrl.valueChanges.subscribe((value) => {
            this.hideRelated = value;
            this.uiService.setControlValue(UI_KEY_HIDERELATED, this.hideRelated);
            this.processRelated(this.allTodos);
        });

        this.sub = this.messageService.on(SITE).subscribe((site: Site) => {
            if (!site || (this.site && site.Id == this.site.Id)) return;
            if (
                site.UserRoles != null &&
                (site.UserRoles == 'IND_CLIENT' || site.UserRoles == 'AG_SME')
            ) {
                this.router.navigateByUrl(`/sites`);
            }

            this.site = site;
            this.uiService.setTitle('To Do', site.Name);
            //if (this.site.HasLicenseViolation) {
            //  this.router.navigate(['/site', this.site.Name, 'settings', 'advanced']);
            //}
            //else {

            this.loadingComplete = false;

            this.alertService
                .getSiteTodos(this.site.Id)
                .then((todos) => this.process(todos))
                .catch((err) => {
                    this.loadingComplete = true;
                    this.errorService.error();
                });
            //}
        });

        let pn = this.uiService.getControlValue(UI_KEY_PAGENO);
        this.pageNumber = pn ? parseInt(pn, 10) : 1;

        let ps = this.uiService.getControlValue(UI_KEY_PAGESIZE);
        this.pageSize = ps ? parseInt(ps, 10) : 25;

        this.messageService.broadcast(PING_SITE);
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    process(todos: Alert[]) {
        for (let todo of todos) {
            if (todo.AlertData != '') todo.DataObject = JSON.parse(todo.AlertData);
        }

        this.allTodos = todos;
        this.processRelated(todos);

        this.loadingComplete = true;
    }

    error(err: any) {
        console.error(err);
    }

    sortTodos() {
        if (this.sortMethod === 'Created' || this.sortMethod === undefined) {
            this.sortedTodos = this.filteredTodos.sort((a, b) => {
                if (this.sortDirection === 'asc') {
                    return (
                        new Date(a.Created).getTime() - new Date(b.Created).getTime() ||
                        a.AlertType.localeCompare(b.AlertType)
                    );
                } else {
                    return (
                        new Date(b.Created).getTime() - new Date(a.Created).getTime() ||
                        b.AlertType.localeCompare(a.AlertType)
                    );
                }
            });
        } else {
            this.sortedTodos = this.filteredTodos.sort((a, b) => this.sortByColumn(a, b));
        }

        this.filteredTodos = [...this.sortedTodos];
    }

    processRelated(todos: Alert[]) {
        if (this.hideRelated) {
            let map = new Map<string, Alert[]>();

            for (let todo of todos) {
                if (todo.AlertData != '') todo.DataObject = JSON.parse(todo.AlertData);
                else todo.DataObject = { UID: todo.SiteId };

                let key = todo.AlertType + '|' + todo.DataObject.UID;

                if (!map.has(key)) map.set(key, [todo]);
                else {
                    let a = map.get(key);
                    a.push(todo);
                    map.set(key, a);
                }
            }

            let ts = [];
            map.forEach((group) => {
                let latest =
                    group.length > 1
                        ? group.sort(
                              (a, b) =>
                                  new Date(b.Created).valueOf() - new Date(a.Created).valueOf()
                          )[0]
                        : group[0];
                ts.push(latest);
            });

            this.todos = ts;
        } else {
            this.todos = todos;
        }
    }

    processSort(sortBy: string) {
        this.sortDirection = sortBy.charAt(0) === '-' ? 'desc' : 'asc';
        this.sortMethod = sortBy.replace('-', '');
        if (isDevMode()) {
            console.log('sortBy: ' + this.sortMethod + ', Direction: ' + this.sortDirection);
        }
        this.sortTodos();
    }

    savePaginationState(data) {
        this.pagedTodos = data.items;
        this.pageNumber = data.pageIndex + 1;
        this.pageSize = data.pageSize;
        this.uiService.setControlValue(UI_KEY_PAGENO, this.pageNumber);
        this.uiService.setControlValue(UI_KEY_PAGESIZE, this.pageSize);
    }

    cmp(a, b) {
        if (a > b) {
            return this.sortDirection === 'asc' ? 1 : -1;
        }
        if (a < b) {
            return this.sortDirection === 'asc' ? -1 : 1;
        }
        return 0;
    }
    cmpTime(a, b) {
        if (this.sortDirection === 'asc') {
            return new Date(a).getTime() - new Date(b).getTime();
        } else {
            return new Date(b).getTime() - new Date(a).getTime();
        }
    }

    sortByColumn(a, b) {
        a[this.sortMethod] = a[this.sortMethod] === undefined ? '' : a[this.sortMethod];
        b[this.sortMethod] = b[this.sortMethod] === undefined ? '' : b[this.sortMethod];
        return (
            this.cmp(a[this.sortMethod], b[this.sortMethod]) || this.cmpTime(a.Created, b.Created)
        );
    }
}
