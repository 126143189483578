import { Component, isDevMode, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuditService } from 'app/sites/site/audit/shared/audit.service';
import { ErrorService } from 'app/core/error/error.service';
import { ExportService } from 'app/core/export.service';
import { MessageService } from 'app/core/message.service';
import { UiService } from 'app/core/ui.service';
import { Site } from 'app/sites/shared/site.model';
import { PING_SITE, SITE } from 'app/sites/shared/constants';
import { Audit } from 'app/sites/site/audit/shared/audit.model';
import { environment } from 'environments/environment';

@Component({
    selector: 'sds-site-audit',
    templateUrl: './audit.component.html',
    styleUrls: ['./audit.component.css'],
})
export class SiteAuditComponent implements OnInit, OnDestroy {
    constructor(
        private auditService: AuditService,
        private errorService: ErrorService,
        private messageService: MessageService,
        private exportService: ExportService,
        private uiService: UiService
    ) {}

    filteredAudits: Audit[];
    sortedAudits: Audit[];
    audits: Audit[];
    pagedAudits: Audit[];
    site: Site;

    loadingComplete: boolean;
    sub: Subscription;
    sortMethod: string = 'ModifiedDate';
    sortDirection: string = 'desc';
    exporting: boolean;

    @ViewChild('AuditLogTable') auditLogTable: any;

    ngOnInit() {
        this.sub = this.messageService.on(SITE).subscribe((site: Site) => {
            if (!site || (this.site && site.Id == this.site.Id)) return;

            this.site = site;
            this.uiService.setTitle('Audit Log', site.Name);

            this.loadingComplete = false;

            this.auditService
                .getAlertHistoryBySite(site.Id)
                .then((audits) => this.process(audits, false))
                .catch(() => {
                    this.errorService.error();
                });
        });

        this.messageService.broadcast(PING_SITE);
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    error(err: any) {
        this.loadingComplete = true;
    }

    process(audits: Audit[], isFilter: boolean) {
        this.audits = audits;
        this.filteredAudits = audits;

        if (!environment.production) console.log(this.audits);

        this.loadingComplete = true;
    }

    sortAudits() {
        if (this.filteredAudits) {
            this.filteredAudits = this.filteredAudits.filter(
                (a) => a.ModificationType != 'BATCH_UPDATE'
            );
            if (this.sortMethod === 'ModifiedDate' || this.sortMethod === undefined) {
                this.sortedAudits = this.filteredAudits.sort((a, b) => {
                    if (this.sortDirection === 'asc') {
                        return (
                            new Date(a.ModifiedDate).getTime() - new Date(b.ModifiedDate).getTime()
                        );
                    } else {
                        return (
                            new Date(b.ModifiedDate).getTime() - new Date(a.ModifiedDate).getTime()
                        );
                    }
                });
            } else {
                this.sortedAudits = this.filteredAudits.sort((a, b) => this.sortByColumn(a, b));
            }
        }

        this.filteredAudits = this.sortedAudits ? [...this.sortedAudits] : [];
    }

    processSort(sortBy: string) {
        this.sortDirection = sortBy.charAt(0) === '-' ? 'desc' : 'asc';
        this.sortMethod = sortBy.replace('-', '');
        if (isDevMode()) {
            console.log('sortBy: ' + this.sortMethod + ', Direction: ' + this.sortDirection);
        }
        this.sortAudits();
    }

    cmp(a, b) {
        if (a > b) {
            return this.sortDirection === 'asc' ? 1 : -1;
        }
        if (a < b) {
            return this.sortDirection === 'asc' ? -1 : 1;
        }
        return 0;
    }
    cmpTime(a, b) {
        if (this.sortDirection === 'asc') {
            return new Date(a).getTime() - new Date(b).getTime();
        } else {
            return new Date(b).getTime() - new Date(a).getTime();
        }
    }

    sortByColumn(a, b) {
        a[this.sortMethod] = a[this.sortMethod] === undefined ? '' : a[this.sortMethod];
        b[this.sortMethod] = b[this.sortMethod] === undefined ? '' : b[this.sortMethod];
        return (
            this.cmp(a[this.sortMethod], b[this.sortMethod]) ||
            this.cmpTime(a.ModifiedDate, b.ModifiedDate)
        );
    }
    exportTable(fileName: string) {
        this.exportService.tableExport(this.auditLogTable.auditLogTable, fileName, 'csv');
    }
}
