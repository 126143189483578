import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'linkify',
})
export class LinkifyPipe implements PipeTransform {
    transform(value: string, target?: string): any {
        let regex =
            /(((http[s]?):\/\/)([^:\/\s]+)((\/\w+)*\/)([\w\-\.~:\/\?\[\]@!\$&'\*\+;=]+[^#?\s,\(\)]+)?(#[\w\-\.]+)?)/g;

        return value.replace(regex, '<a href="$1" target="' + (target || '_blank') + '">$1</a>');
    }
}
