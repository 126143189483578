import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

import { NavService } from 'app/core/nav/nav.service';
import { ScanDataService } from 'app/scans';
import { Site } from 'app/sites/shared/site.model';
import { SiteService } from 'app/sites/shared/site.service';
import { SITE, PING_SITE } from 'app/sites/shared/constants';
import { SettingService } from 'app/settings/shared/setting.service';
import { Setting } from 'app/settings/shared/setting.model';

@Component({
    selector: 'sds-site-indoc',
    templateUrl: './site-indoc.component.html',
    styleUrls: ['./site-indoc.component.css'],
})
export class SiteIndocComponent implements OnInit {
    settings: any = {};
    scanDate: string;
    publishDate: string;

    constructor(
        private navService: NavService,
        private scandataService: ScanDataService,
        private settingService: SettingService,
        private siteService: SiteService,
        private router: Router,
        private datePipe: DatePipe
    ) {}

    sidebarMenu: any = [
        {
            href: 'dashboard',
            icon: 'dashboard',
            label: 'Dashboard',
        },
        {
            href: 'users',
            icon: 'user',
            label: 'Users',
        },
        {
            href: 'computers',
            icon: 'computer',
            label: 'Computers',
        },
        {
            href: 'databases',
            icon: 'database',
            label: 'Databases',
        },
        {
            href: 'applications',
            icon: 'browser',
            label: 'Applications',
        },
        {
            href: 'printers',
            icon: 'print',
            label: 'Printers',
        },
        {
            href: 'nonad-devices',
            icon: 'tablet alternate',
            label: 'Non-AD Devices',
        },
        {
            href: 'security-groups',
            icon: 'users',
            label: 'Security Groups',
        },
        {
            href: 'dns',
            icon: 'sitemap',
            label: 'DNS',
        },
        /*
    {
      href: 'exchange',
      icon: 'mail',
      label: 'Exchange'
    },
    {
      href: 'licenses',
      icon: 'key',
      label: 'Licenses'
    }
    */
    ];

    site: Site;

    ngOnInit() {
        this.siteService.app_getCurrentSite().then((site: Site) => {
            if (site && this.site != site) {
                this.site = site;

                this.setCooperFlag();
            }
        });
    }

    ngOnDestroy() {}

    setCooperFlag() {
        let sets: Setting[] = [];
        sets.push({
            AccountId: this.site.AccountId,
            Name: 'COOPER_DATAEXPLORE',
            Value: 'TRUE',
        });

        this.settingService
            .updateSettings(sets)
            .then(() => {})
            .catch(() => {});
    }

    process(settings: any) {
        if (!settings || !Object.keys(settings).length) return;

        this.settings = settings;
        console.log(settings);

        // format the dates based on what's specified in Admin Settings, General, Date Format
        for (let s of settings) {
            if (s.Name == 'DateFormat') {
                this.scanDate = this.scanDate
                    ? this.datePipe.transform(
                          new Date(this.scanDate).toISOString(),
                          s.Value + ' hh:mm:ss a'
                      )
                    : 'N/A';
                this.publishDate = this.publishDate
                    ? this.datePipe.transform(
                          new Date(this.publishDate).toISOString(),
                          s.Value + ' hh:mm:ss a'
                      )
                    : 'N/A';
                break;
            }
        }
    }
}
