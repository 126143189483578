<sds-breadcrumb-trail [crumbs]="breadcrumbs"></sds-breadcrumb-trail>
<header class="pageHeader">
    <ng-container *ngIf="loadingComplete">
        <div class="licenseHeader">
            <h1>License Usage</h1>
            <itc-tag type="subtle" color="orange">{{ licenseSummary }} of {{ currentCap }}</itc-tag>
            <h1 *ngIf="isSMB">Devices</h1>
        </div>
        <div class="ui labeled input">
            <sm-select [control]="filterCtrl" (modelChange)="filterLicenses()">
                <option *ngIf="products.CM2019SEP || products.CM2" value="GDPR">
                    Compliance Manager
                </option>
                <option *ngIf="products.KVS" value="KVS">VulScan</option>
                <option *ngIf="products.NdProWeb" value="NDP">Network Detective Pro</option>
                <option *ngIf="products.CH" value="CH">Cyber Hawk</option>
            </sm-select>
        </div>
        <a [routerLink]="null" (click)="openModal()" style="margin-left: 12px">
            Request More Licenses
        </a>
    </ng-container>
</header>
<sds-loader [complete]="loadingComplete">
    <div style="display: block; overflow-x: auto">
        <table
            class="itc-table sortable sortable-table-titles-scroll"
            *ngIf="pagedLicenses && pagedLicenses.length"
            sortable-table
            sortColumn="name"
            sortDirection="asc"
            (sorted)="sortLicenses($event)">
            <thead>
                <tr>
                    <th sortable-column="name" class="status">Site Name</th>
                    <th sortable-column="computers" class="thRight"># Devices</th>
                    <th *ngIf="!isSMB" sortable-column="usage" class="thRight">License Usage</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let lic of pagedLicenses">
                    <td>{{ lic.Site != null ? lic.Site.Name : '&lt;Unassigned&gt;' }}</td>
                    <td style="text-align: right">{{ lic.Computers }}</td>
                    <td *ngIf="!isSMB" style="text-align: right">{{ lic.Cost }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <itc-paginator [data]="filteredLicenses" (onPage)="pagedLicenses = $event"></itc-paginator>
</sds-loader>

<sm-modal title="Request More Licenses" class="sm" #provisionmodal>
    <modal-content>
        <div>
            <p>Please contact your Account Manager to acquire additional licenses.</p>
        </div>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" class="approve">Close</itc-button>
    </modal-actions>
</sm-modal>
