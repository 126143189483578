import { Injectable } from '@angular/core';
import { Notes } from '../../sites/shared/notes/notes.model';

@Injectable()
export class ScanDataUser {
    public Id: number;
    public Domain: string;
    public Username: string;
    public DisplayName: string;
    public LastLogin: Date;
    public LastLoginFormatted: string;
    public Active: boolean;
    public Enabled: boolean;
    public Details?: any;
    public PasswordLastSet: Date;

    public Notes: Notes;
    public Procedures: Notes[];
    public RelatedItems: string[];
    public SmartTags: any[];
}
