import { Directive, Input } from '@angular/core';
import {
    AbstractControl,
    NG_VALIDATORS,
    ValidationErrors,
    Validator,
    ValidatorFn,
} from '@angular/forms';

@Directive({
    selector: '[cannotStartWithSpace]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: CannotStartWithSpaceValidatorDirective,
            multi: true,
        },
    ],
})
export class CannotStartWithSpaceValidatorDirective implements Validator {
    validate(control: AbstractControl): ValidationErrors | null {
        let errors = {};
        let invalid = false;

        if (control.value?.charAt(0) === ' ') {
            errors['cannotStartWithSpace'] = true;
            invalid = true;
        }

        return invalid ? errors : null;
    }
}

export function cannotStartWithSpace(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => validationTest(control.value);
}

function validationTest(val): ValidationErrors | null {
    // replaces the required validator to require a value and the value can't be just spaces
    return val && val.value?.charAt(0) === ' ' ? { cannotStartWithSpace: true } : null;
}
