<sds-breadcrumb-trail [crumbs]="breadcrumbs"></sds-breadcrumb-trail>

<header class="pageHeader cm1HasSelectAssessment">
    <h1>Forms</h1>
    <div class="flex-right" *ngIf="loadingComplete">
        <itc-button
            label="Download .zip"
            type="primary"
            icon="fa-download"
            (onclick)="downloadFormZip()"
            [loading]="downloading"
            *ngIf="forms?.length && !isOnlySME"></itc-button>
    </div>
</header>

<sds-loader [complete]="loadingComplete">
    <itc-banner type="info" *ngIf="forms && !forms.length">
        No InForms found for the current assessment.
    </itc-banner>

    <itc-banner type="info" title="No Assessments Found" *ngIf="noAssessment">
        There are not currently any assessments for this site. You can start the assessment from the
        To Do list, after installing the Compliance Manager Server.
    </itc-banner>

    <table
        class="itc-table clickable"
        *ngIf="forms?.length"
        sortable-table
        (sorted)="onSorted($event)">
        <thead>
            <tr>
                <th sortable-column="Filename">Name</th>
                <th sortable-column="LastModified">Last Modified</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let form of forms" [routerLink]="form.link">
                <td>{{ form.Filename }}</td>
                <td>{{ form.LastModified | date : 'medium' }}</td>
            </tr>
        </tbody>
    </table>
</sds-loader>
