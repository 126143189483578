import { Component, OnInit } from '@angular/core';
import { VendportalService } from '../vendportal.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from '../../shared/itc/notification/notification.service';

import { switchMap } from 'rxjs/operators';
import { UiService } from '../../core/ui.service';

@Component({
    selector: 'sds-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    constructor(
        private service: VendportalService,
        private router: Router,
        private route: ActivatedRoute,
        private notificationService: NotificationService,
        private uiService: UiService
    ) {}

    ngOnInit() {
        this.route.paramMap.subscribe((p) => {
            if (p.get('inviteguid')) {
                this.service
                    .login(p.get('inviteguid'))
                    .then((ok) => this.router.navigateByUrl('/vendor/home'))
                    .catch((err) =>
                        this.notificationService.banner.error('', 'Invalid Invite ID.')
                    );
            }
        });
        this.uiService
            .getVersionUpdated()
            .then((version) => {
                this.version = version.replace(/Dev-|Beta-/, '');
                console.log('Version: ', version);
            })
            .catch((err) => {});
    }
    currentYear: string = '' + new Date().getFullYear();
    inviteid: string;
    version: string = 'LOCAL';
    submit() {
        this.service
            .login(this.inviteid)
            .then((ok) => this.router.navigateByUrl('/vendor/home'))
            .catch((err) => this.notificationService.banner.error('', 'Invalid Invite ID.'));
    }
}
