import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { WjRadialGauge, WjGaugeModule } from '@grapecity/wijmo.angular2.gauge';

declare var $: any;

@Component({
    selector: 'sds-risk-score-card',
    templateUrl: './risk-score-card.component.html',
    styleUrls: ['./risk-score-card.component.css'],
})
export class RiskScoreCardComponent implements OnInit {
    @ViewChild(WjRadialGauge, { static: true }) flexgauge: WjRadialGauge;

    constructor() {}

    @Input() set riskScore(val: number) {
        this.setRiskScore(val);
    }

    _rs: number;

    setRiskScore(val: number, i?: number) {
        if (!i) i = 0;
        if (i <= val) {
            this._rs = i;
            //let x = (i / val) * Math.PI;
            window.setTimeout(() => {
                this.setRiskScore(val, i + 1);
            }, Math.round(1000 / val));
            //}, Math.round((5000 * (1 - Math.sin(x))) / val));
        }
    }

    GetGaugeColor(gaugeamount: number): string {
        var redVal = 0;
        var greenVal = 0;
        if (gaugeamount < 50) {
            redVal = (245 / 50) * gaugeamount + 0;
            greenVal = 200;
        } else {
            redVal = 245;
            greenVal = (-200 / 50) * gaugeamount + 400;
        }

        return (
            '#' +
            ('0' + Math.round(redVal).toString(16)).slice(-2) +
            ('0' + Math.round(greenVal).toString(16)).slice(-2) +
            '00'
        );
    }

    ngOnInit() {
        $('.wj-pointer').css('fill', this.GetGaugeColor(this._rs));
    }

    gaugeChanged() {
        $('.wj-pointer').css('fill', this.GetGaugeColor(this._rs));
    }
}
