import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'sds-badged-icon',
    templateUrl: './badged-icon.component.html',
    styleUrls: ['./badged-icon.component.css'],
})
export class BadgedIconComponent implements OnInit {
    constructor() {}

    @Input() icon: string;
    @Input() badgeColor: string;
    @Input() badgeText: string;
    @Input() hideZero: boolean = false;

    ngOnInit() {}
}
