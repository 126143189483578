import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'fileSize',
    standalone: true,
})
export class FileSizePipe implements PipeTransform {
    transform(value: any): string {
        if (typeof value !== 'number') {
            return value;
        }

        const k = 1000;
        const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(value) / Math.log(k));

        return `${parseFloat((value / Math.pow(k, i)).toFixed(1))}${sizes[i]}`;
    }
}
