import { Component, OnInit } from '@angular/core';
import { AlertSOPInfo } from './alert-sop.model';
import { PrintService } from '../../../../sites/shared/print.service';

@Component({
    //   templateUrl: './printable-alert-sop.component.html',
    template: `
        <span [innerHtml]="alertSOPDContent?.SOPData | safeHtml"></span>
    `,
})
export class PrintableAlertSopComponent implements OnInit {
    alertSOPDContent: AlertSOPInfo;

    constructor(private printService: PrintService) {}

    ngOnInit() {
        this.alertSOPDContent = this.printService.AlertSOPData;
        Promise.resolve(this.alertSOPDContent).then(() => {
            setTimeout(() => {
                this.printService.onDataReady();
            }, 500);
        });
    }
}
