import { inject } from '@angular/core';
import {
    Router,
    RouterStateSnapshot,
    ActivatedRouteSnapshot,
    UrlTree,
    CanActivateFn,
    CanMatchFn,
} from '@angular/router';

import { AuthService, Roles } from 'app/core/auth/auth.service';
import { NotificationService } from '../../../shared/itc/notification/notification.service';

export const GlobalSettingsGuard: CanActivateFn | CanMatchFn = (): boolean | UrlTree => {
    let authService = inject(AuthService);
    let router = inject(Router);
    let notificationService = inject(NotificationService);

    let hasAccess = authService.userIsRoles([Roles.Admin, Roles.Master]);
    if (!hasAccess) {
        console.log('Access Denied.');
        notificationService.toast.warning(
            'Access Denied',
            'Your assigned permissions do not allow access to this page.'
        );
        return router.createUrlTree(['/']);
    }
    return hasAccess;
};
