import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'textarea-char-limit',
    template: `
        <div class="textarea-char-limit">
            <textarea id="limitedTextarea" [maxlength]="[limit]" [formControl]="control"></textarea>
            <div id="counter">
                <span id="current">
                    {{ control.value != null ? control.value.length.toLocaleString() : 0 }}
                </span>
                <span id="maximum">/{{ limit.toLocaleString() }}</span>
            </div>
        </div>
    `,
    styles: [
        `
            .textarea {
                width: 100%;
                min-height: 100px;
            }

            #counter {
                display: flex;
                justify-content: right;
                color: var(--text-secondary);
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class TextareaCharLimitComponent {
    @Input() limit: number;
    @Input() control = new FormControl('');
}
