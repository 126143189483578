import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';

import { Notes } from '../../notes.model';
import { NotesService } from '../../notes.service';
import { MessageService } from 'app/core/message.service';
import { PING_SITE, SITE } from 'app/sites/shared/constants';
import { Site } from 'app/sites/shared/site.model';
import { NotificationService } from '../../../../../shared/itc/notification/notification.service';

declare var CKEDITOR: any;

@Component({
    selector: 'sds-notes-card',
    templateUrl: './notes-card.component.html',
    styleUrls: ['./notes-card.component.css'],
})
export class NotesCardComponent implements OnInit, AfterViewInit {
    constructor(
        private messageService: MessageService,
        private notesService: NotesService,
        private notificationService: NotificationService
    ) {}

    @ViewChild('modal') modal: any;

    @ViewChild('passcodesetupmodal') passcodesetupModal: any;
    @ViewChild('passcodemodal') passcodeModal: any;
    @ViewChild('ckedit') ckedit: any;
    @ViewChild('ckeditPrivate') ckeditPrivate: any;
    @ViewChild('requesterrormodal') requestErrorModal: any;

    editMode: boolean = false;
    privateMode: boolean = false;
    disableRequiredButton: boolean = false;
    site: Site;
    subs: any[] = [];

    password1: string;
    password2: string;

    isMaster: boolean = false;
    hasPublicKey: boolean = false;
    hasPrivacyKey: boolean = false;
    hasPrivacyHash: boolean = false;
    privacyCode: string = '';
    privacyCodeB: string = '';
    passwordInvalid: boolean = false;

    modalSettings: any = {
        onHide: () => {
            this.resetModalState();
        },
        closable: false,
    };

    _notes: Notes;

    @Input()
    set notes(notes: Notes) {
        if (notes) {
            this._notes = notes;
        }
    }

    @Input() itemType: string = '';
    @Input() itemValue: string = '';

    @Output() onUpdate = new EventEmitter<Notes>();

    ngOnInit() {
        for (var name in CKEDITOR.instances) {
            CKEDITOR.instances[name].destroy(true);
        }

        this.subs.push(
            this.messageService.on(SITE).subscribe((site) => {
                this.onSite(site);
            })
        );
        this.messageService.broadcast(PING_SITE);

        this.setPrivacyStatus();
    }

    ngAfterViewInit() {}

    ngOnDestroy() {
        for (let sub of this.subs) sub.unsubscribe();
    }

    onSite(site: Site) {
        this.site = site;
    }

    setPrivacyStatus() {
        this.notesService.getPrivacyStatus().then((res) => {
            let _sHasPublicKey = res.headers.get('X-HasPublicKey');
            let _sHasPrivacyKey = res.headers.get('X-HasPrivacyKey');
            let _sHasPrivacyHash = res.headers.get('X-HasPrivacyHash');
            let _sIsMaster = res.headers.get('X-IsMaster');

            this.hasPublicKey = _sHasPublicKey === 'True';
            this.hasPrivacyKey = _sHasPrivacyKey === 'True';
            this.hasPrivacyHash = _sHasPrivacyHash === 'True';
            this.isMaster = _sIsMaster === 'True';
        });
    }

    viewMore(): void {
        this.privacyCode = '';
        this._notes.PrivateBody = '';
        (this.modal as any).show(this.modalSettings);
        if (CKEDITOR.instances.ckedit == null) {
            CKEDITOR.replace(this.ckedit.nativeElement);
            CKEDITOR.replace(this.ckeditPrivate.nativeElement);
        }
    }

    togglePrivate(): void {
        console.log("it's running");
        // if currently viewing private mode, close
        if (this.privateMode) {
            this.privateMode = !this.privateMode;
            this._notes.PrivateBody = '';
            this.privacyCode = '';
            this.refreshModal();
        } else {
            let notesCategory = '';
            if (this._notes) {
                notesCategory = this._notes.Category;
            }
            // if attemptint to view private note, attempt to retrieve private note and
            // take necessary action if response indicates it to be necessary
            // use return headers to pass back small bits of information.  this allows for a single GET instead of multiple GETS

            this.notesService.getPrivacyStatus().then((res) => {
                let _sHasPublicKey = res.headers.get('X-HasPublicKey');
                let _sHasPrivacyKey = res.headers.get('X-HasPrivacyKey');
                let _sHasPrivacyHash = res.headers.get('X-HasPrivacyHash');
                let _sIsMaster = res.headers.get('X-IsMaster');

                this.hasPublicKey = _sHasPublicKey === 'True';
                this.hasPrivacyKey = _sHasPrivacyKey === 'True';
                this.hasPrivacyHash = _sHasPrivacyHash === 'True';
                this.isMaster = _sIsMaster === 'True';

                if (this.isMaster && !this.hasPublicKey) {
                    return;
                }

                if (!this.hasPrivacyKey || !this.hasPublicKey) {
                    alert(
                        'You do not have permission to view private data for this account.  A Master User can grant permission upon request.'
                    );
                    return;
                }

                if (this.hasPrivacyKey && this.hasPublicKey && !this.hasPrivacyHash) {
                    this.passcodesetupModal.show();
                    return;
                }

                if (this.hasPublicKey && this.hasPrivacyKey) {
                    this.passcodeModal.show();
                }

                if (this.privateMode) {
                    //audit log �Viewed confidential notes for �<object name>�.�
                }
                this.refreshModal();
            });
        }
    }

    refreshModal(): void {
        (this.modal as any).refresh();
    }

    editNotes(toggle: boolean): void {
        CKEDITOR.instances.ckeditPrivate.setData(this._notes.PrivateBody);
        CKEDITOR.instances.ckedit.setData(this._notes.Body);
        this.editMode = toggle;
        this.refreshModal();
    }

    saveNotes(): void {
        //console.log('notes-card saveNotes()');
        if (this._notes.PrivateBody != CKEDITOR.instances.ckeditPrivate.getData()) {
            //audit log �Edit Confidential notes for �<object name>�.�
        }

        this._notes.Body = CKEDITOR.instances.ckedit.getData();

        //console.log('notes-card saveNotes() privatenote: ' + this.privateMode);
        if (this.privateMode) {
            this._notes.PrivateBody = CKEDITOR.instances.ckeditPrivate.getData();
            this._notes.PrivateCode = atob(this.privacyCodeB);
            //console.log('notes-card saveNotes() privatecode: ' + this._notes.PrivateCode);
        } else {
            this._notes.PrivateBody = '<NOTPRIVATE>';
        }

        //if (!this._notes.Id)
        //  this.notesService.postNotes(this._notes).then((res) => {
        //    this._notes.Id = res.headers.get('X-Note-Id');
        //  });
        //else
        //  this.notesService.putNotes(this._notes);

        this.onUpdate.emit(this._notes);

        this.editMode = false;
        this.refreshModal();
    }

    closePasscodeSetupModal(isOk: boolean) {
        if (isOk) {
            this.passwordInvalid = false;
            // if no public key, create public key, save it
            // save privacy key

            let formData: FormData = new FormData();
            formData.append('siteid', '' + this.site.Id);
            formData.append('pwd', this.password1);

            this.notesService
                .postPrivacyPassword(formData)
                .then((s) => {
                    this.passcodesetupModal.hide();
                    this.modal.show(this.modalSettings);
                })
                .catch((e) => {
                    if (e.status == 417) {
                        this.passwordInvalid = true;
                    }
                });
        } else {
            this.passcodesetupModal.hide();
            this.modal.show(this.modalSettings);
        }
    }

    closePasscodeModal() {
        this.passwordInvalid = false;

        this.notesService
            .getPrivateNotes(
                this.site.Id,
                this._notes.Category,
                this.itemType,
                this.itemValue,
                this.privacyCode
            )
            .then((privatenotes) => {
                try {
                    this.privacyCodeB = btoa(this.privacyCode);
                    this._notes.PrivateBody = privatenotes.body[0].PrivateBody;
                    CKEDITOR.instances.ckeditPrivate.setData(this._notes.PrivateBody);
                    this.privateMode = !this.privateMode;
                } catch (e) {
                    this.privateMode = false;
                    if (e.status == 417) {
                        // alert(e.error);
                        this.passwordInvalid = true;
                    }
                }
            })
            .catch((e) => {
                this.privateMode = false;
                if (e.status == 417) {
                    // alert(e.error);
                    this.passwordInvalid = true;
                }
            })
            .then(() => {
                if (!this.passwordInvalid) {
                    this.privacyCode = '';
                    this.passcodeModal.hide();
                    this.modal.show(this.modalSettings);
                }
            });
    }

    resetModalState() {
        this.privateMode = false;
        this._notes.PrivateBody = '';
        this.privacyCodeB = '';
    }

    closeModal() {
        if (this.editMode) {
            this.editNotes(false);
        } else {
            this.resetModalState();
            this.modal.hide();
        }
    }

    requestAccess() {
        this.disableRequiredButton = true;
        if (this.site) {
            this.notesService
                .requestPrivacyAccess(this.site.Id)
                .then(() => {
                    this.notificationService.toast.success(
                        'Success',
                        'Request for access to confidential information sent.'
                    );
                })
                .catch((e) => {
                    this.privateMode = false;
                    this.disableRequiredButton = false;
                    if (e.status == 417) {
                        this.requestErrorModal.show();
                    }
                });
        }
    }
}
