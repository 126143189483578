import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

const EXP: number = 300;
const PREFIX: string = 'sdsportal_ui_';

@Injectable({ providedIn: 'root' })
export class UiService {
    constructor(private http: HttpClient) {}

    store = new Map<string, string>();

    getControlValue(key: string): string {
        /*let value = this.store.get(PREFIX + key);
    let exp = new Date(this.store.get(PREFIX + key + '_exp')), now = new Date();

    if (now.valueOf() > exp.valueOf())
      return null;
    else
      return value;*/
        return this.store.get(key);
    }

    setControlValue(key: string, value: any, exp?: number) {
        //let now = new Date(), later = new Date();
        //later.setSeconds(now.getSeconds() + exp ? exp : EXP);
        this.store.set(key, value.toString());
        //this.store.set(PREFIX + key + '_exp', later.toISOString());
    }

    getVersion(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.get('/assets/version.txt', { responseType: 'text' }).subscribe(
                (res) => {
                    resolve(res);
                },
                (error) => {
                    resolve('LOCAL');
                }
            );
        });
    }

    getVersionUpdated(): Promise<string> {
        return lastValueFrom(this.http.get('/assets/version.txt', { responseType: 'text' }));
    }

    setTitle(title: string, siteName?: string) {
        let pageTitle: string = '';
        if (siteName) {
            pageTitle = 'Your IT Portal - ' + title + ' - ' + siteName;
        } else {
            pageTitle = 'Your IT Portal - ' + title;
        }
        document.title = pageTitle;
    }

    getDateFormat(): string {
        let info = JSON.parse(this.getControlValue('accountTimeInfo'));
        return info.DateFormat || 'MM/dd/yyyy';
    }
}
