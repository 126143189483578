<sds-loader [complete]="loadingComplete">
    <sds-breadcrumb-trail
        [crumbs]="breadcrumbs"
        *ngIf="dataExplorerType === 'indoc'"></sds-breadcrumb-trail>
    <div class="ui grid fluid dashboard indocDetail">
        <div class="ten wide column">
            <!-- Left Column -->
            <div class="ui grid">
                <div class="sixteen wide column">
                    <div class="ui card userInfoCard indocDetailCard">
                        <a href="#" class="settingsIcon"><i class="setting icon"></i></a>
                        <h1 class="breakAll">
                            {{ user?.Domain }}{{ user ? '\\' : '' }}{{ user?.Username }}
                        </h1>
                        <div class="meta">{{ user?.DisplayName }}</div>
                        <div
                            class="ui top attached secondary menu siteDetailTabs"
                            id="siteDataUsersTabs">
                            <a
                                href="javascript:void 0"
                                class="item"
                                [class.active]="selectedTab == 'summary'"
                                (click)="selectedTab = 'summary'">
                                Summary
                            </a>
                            <a
                                href="javascript:void 0"
                                class="item"
                                [class.active]="selectedTab == 'loginHistory'"
                                (click)="selectedTab = 'loginHistory'">
                                Login History
                            </a>

                            <a
                                class="item"
                                [class.active]="selectedTab == 'shares'"
                                (click)="selectedTab = 'shares'">
                                Shares
                            </a>

                            <a
                                class="item"
                                [class.active]="selectedTab == 'groups'"
                                (click)="selectedTab = 'groups'">
                                Groups
                            </a>
                        </div>

                        <!-- Summary -->
                        <div
                            class="ui bottom attached tab segment"
                            [class.active]="selectedTab == 'summary'">
                            <table class="ui small table breakAllButLabel">
                                <tbody>
                                    <tr>
                                        <td><b>Domain</b></td>
                                        <td>{{ user?.Domain }}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Name</b></td>
                                        <td>{{ user?.Username }}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Access</b></td>
                                        <td>{{ user?.Details.Overview.Access }}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Last Login</b></td>
                                        <td>{{ user?.LastLoginFormatted == 'Never' ? 'Never' : (user?.LastLoginFormatted+'+00:00' | accountDateTime) }}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Last Login Timestamp</b></td>
                                        <td>
                                            {{ user?.Details.Overview.LastLoginTimestampFormatted == 'Never' ? 'Never' : (user?.Details.Overview.LastLoginTimestampFormatted+'+00:00' | accountDateTime) }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>Distinguished Name</b></td>
                                        <td>{{ user?.Details.Overview.DistinguishedName }}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Password Last Set</b></td>
                                        <td>
                                            {{ user?.Details.Overview.PasswordLastSetFormatted == 'Never' ? 'Never' : (user?.Details.Overview.PasswordLastSetFormatted+'+00:00' | accountDateTime)  }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>Password Expires</b></td>
                                        <td>
                                            {{
                                                user?.Details.Overview.PasswordExpires == 'True'
                                                    ? 'Yes'
                                                    : 'No'
                                            }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>Password Expires Date</b></td>
                                        <td>
                                            {{
                                                user?.Details.Overview.PasswordExpiresDateFormatted
                                            }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <!-- Login History -->
                        <div
                            class="ui bottom attached tab segment"
                            [class.active]="selectedTab == 'loginHistory'">
                            <sm-select [(model)]="loginType">
                                <option value="InteractiveLogon">Interactive Login</option>
                                <option value="NetworkLogon">Network Login</option>
                                <option value="RemoteLogon">Remote Login</option>
                            </sm-select>

                            <table class="itc-table">
                                <thead>
                                    <tr>
                                        <th>Computer</th>
                                        <th colspan="2">Past 30 Days</th>
                                        <th colspan="2">Past 7 Days</th>
                                        <th colspan="2">Past 24 Hours</th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>Success</th>
                                        <th>Failure</th>
                                        <th>Success</th>
                                        <th>Failure</th>
                                        <th>Success</th>
                                        <th>Failure</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="
                                            let row of user?.Details.LoginHistory[loginType]
                                                | mapToIterable
                                        ">
                                        <td>{{ row.ComputerName }}</td>
                                        <td>{{ row.Success30 }}</td>
                                        <td>{{ row.Failed30 }}</td>
                                        <td>{{ row.Success7 }}</td>
                                        <td>{{ row.Failed7 }}</td>
                                        <td>{{ row.Success24 }}</td>
                                        <td>{{ row.Failed24 }}</td>
                                    </tr>
                                    <tr
                                        *ngIf="
                                            (user?.Details.LoginHistory[loginType] | mapToIterable)
                                                .length == 0
                                        "
                                        style="text-align: center">
                                        <td colspan="7" style="text-align: left">
                                            <em>None Detected</em>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <!-- Shares -->
                        <div
                            class="ui bottom attached tab segment"
                            [class.active]="selectedTab == 'shares'">
                            <table class="itc-table">
                                <thead>
                                    <tr>
                                        <th>Share</th>
                                        <th>Share Type</th>
                                        <th>File Permissions</th>
                                        <th colspan="3" style="text-align: center">
                                            Share Permissions
                                        </th>
                                    </tr>
                                    <tr>
                                        <th colspan="3"></th>
                                        <th>Full Control</th>
                                        <th>Change</th>
                                        <th>Read</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let row of user?.Details.Shares">
                                        <td>{{ row.Share }}</td>
                                        <td>{{ row.ShareType }}</td>
                                        <td>{{ row.FileSystemPermissions }}</td>
                                        <td>
                                            <i
                                                class="green check icon"
                                                [hidden]="!row.FullControl"></i>
                                        </td>
                                        <td>
                                            <i class="green check icon" [hidden]="!row.Change"></i>
                                        </td>
                                        <td>
                                            <i class="green check icon" [hidden]="!row.Read"></i>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <!-- Groups -->
                        <div
                            class="ui bottom attached tab segment"
                            [class.active]="selectedTab == 'groups'">
                            <table class="itc-table">
                                <thead>
                                    <tr>
                                        <th>Group</th>
                                        <th>OU</th>
                                        <th>Type</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let group of user?.Details.Groups">
                                        <td>{{ group.GroupName }}</td>
                                        <td>{{ group.OU }}</td>
                                        <td>{{ group.Type }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="six wide column">
            <!-- Right column -->
            <div class="ui grid">
                <div class="sixteen wide column" style="display: none">
                    <!--TODO add this back in after we have data to feed it-->
                    <sds-risk-score-card [riskScore]="riskScore"></sds-risk-score-card>
                    <!-- End Risk Score Card -->
                </div>

                <div class="sixteen wide column">
                    <sds-notes-card
                        [(notes)]="notes"
                        [itemType]="'User'"
                        [itemValue]="notesId"
                        (onUpdate)="saveNotes($event)"></sds-notes-card>
                </div>

                <div class="sixteen wide column">
                    <sds-sop-card
                        [SOPs]="SOPs"
                        [siteId]="site?.Id"
                        [itemJSON]="notesJSON"
                        (onUpdate)="saveProcedures($event)"></sds-sop-card>
                </div>
                <div class="sixteen wide column">
                    <sds-related-items-card
                        [parentId]="userId"
                        [parentType]="'user'"
                        [site]="site"
                        [items]="user?.RelatedItems"></sds-related-items-card>
                </div>
                <div class="sixteen wide column">
                    <!-- disabled for now while we work out things -->
                    <sds-tags-card
                        [parentId]="userId"
                        [siteId]="site?.Id"
                        [tags]="user?.SmartTags"></sds-tags-card>
                </div>
            </div>
        </div>
    </div>
</sds-loader>
