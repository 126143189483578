import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProgressStep, ProgressStepState, ProgressPagination } from './progress-tracker.model';

@Injectable({ providedIn: 'root' })
export class ItcProgressTrackerService {
    progressSteps$: BehaviorSubject<ProgressStep[]> = new BehaviorSubject<ProgressStep[]>([]);
    progressPagination$: BehaviorSubject<ProgressPagination> =
        new BehaviorSubject<ProgressPagination>({
            currentPage: 0,
            currentStep: 1,
            totalPages: 1,
            maximumPageSize: 4,
            hasNextPage: false,
            hasPreviousPage: false,
        });

    setSteps(steps: ProgressStep[]) {
        if (!steps || steps.length == 0) {
            return;
        }
        steps.forEach((step) => {
            if (!step.state) {
                step.state = 'incomplete';
            }
        });
        if (!steps.some((step) => step.state === 'current')) {
            steps[0].state = 'current';
        }
        let paginationValue = this.progressPagination$.getValue();
        let totalPages = Math.ceil(steps.length / this.progressPagination$.value.maximumPageSize);
        let hasNextPage = totalPages > 1 ? true : false;
        let currentStepIndex = steps.findIndex((step) => step.state === 'current');
        this.progressPagination$.next({
            ...paginationValue,
            currentStep: currentStepIndex + 1,
            totalPages: totalPages,
            hasNextPage: hasNextPage,
        });
        this.progressSteps$.next(steps);
    }
    getSteps(): Observable<ProgressStep[]> {
        return this.progressSteps$.asObservable();
    }

    getPagination(): Observable<ProgressPagination> {
        return this.progressPagination$.asObservable();
    }

    setMaximumPageSize(max: number) {
        let paginationValue = this.progressPagination$.getValue();
        this.progressPagination$.next({
            ...paginationValue,
            maximumPageSize: max,
        });
    }

    setStep(index: number) {
        let steps = this.progressSteps$.getValue();
        let currentStepIndex = index;
        if (currentStepIndex > steps.length) {
            return;
        }
        steps[currentStepIndex - 1].state = 'current';
        for (let i = index; i < steps.length; i++) {
            steps[i].state = 'incomplete';
        }
        this.setCurrentStep();
        let pagination = this.progressPagination$.getValue();
        let firstIndexOnPage = pagination.currentPage * pagination.maximumPageSize + 1;

        if (currentStepIndex < firstIndexOnPage) {
            this.updatePage('prev');
        }
        if (currentStepIndex + 2 > (pagination.currentPage + 1) * pagination.maximumPageSize) {
            this.updatePage('next');
        }
    }
    nextStep(state?: ProgressStepState): void {
        let steps = this.progressSteps$.getValue();
        let currentStepIndex = steps.findIndex((step) => step.state === 'current');
        if (currentStepIndex >= steps.length - 1) {
            return;
        }
        steps[currentStepIndex].state = state ? state : 'complete';
        steps[currentStepIndex + 1].state = 'current';
        this.setCurrentStep();
        this.progressSteps$.next(steps);
        let pagination = this.progressPagination$.getValue();
        if (currentStepIndex + 2 > (pagination.currentPage + 1) * pagination.maximumPageSize) {
            this.updatePage('next');
        }
    }

    previousStep(state?: ProgressStepState): void {
        let steps = this.progressSteps$.getValue();
        let currentStepIndex = steps.findIndex((step) => step.state === 'current');
        if (currentStepIndex <= 0) {
            return;
        }

        steps[currentStepIndex].state = state ? state : 'incomplete';
        steps[currentStepIndex - 1].state = 'current';
        this.setCurrentStep();

        this.progressSteps$.next(steps);
        let pagination = this.progressPagination$.getValue();

        let firstIndexOnPage = pagination.currentPage * pagination.maximumPageSize + 1;

        if (currentStepIndex < firstIndexOnPage) {
            this.updatePage('prev');
        }
    }

    updatePage(direction) {
        let pagination = this.progressPagination$.getValue();
        let newPage =
            direction === 'prev' ? pagination.currentPage - 1 : pagination.currentPage + 1;
        let hasPrevious = newPage > 0;
        let hasNext = newPage < pagination.totalPages - 1;
        let currentPage =
            direction === 'prev' ? pagination.currentPage - 1 : pagination.currentPage + 1;
        this.progressPagination$.next({
            ...pagination,
            currentPage: currentPage,
            hasPreviousPage: hasPrevious,
            hasNextPage: hasNext,
        });
    }

    setStepState(step: number, state: ProgressStepState): void {
        let steps = this.progressSteps$.getValue();
        steps[step - 1].state = state;
        this.progressSteps$.next(steps);
    }

    setCurrentStep(): void {
        let steps = this.progressSteps$.getValue();
        let pagination = this.progressPagination$.getValue();
        let currentStepIndex = steps.findIndex((step) => step.state === 'current');
        this.progressPagination$.next({
            ...pagination,
            currentStep: currentStepIndex + 1,
        });
    }

    resetTracker(): void {
        let steps = this.progressSteps$.getValue();
        steps?.forEach((step) => {
            step.state = 'incomplete';
        });
        this.setSteps(steps);
        let paginationValue = this.progressPagination$.getValue();
        this.progressPagination$.next({
            ...paginationValue,
            currentPage: 0,
            hasPreviousPage: false,
        });
    }
}
