import { CM_COLORS } from '../../sites/site/compliance-manager/shared/cm-summary.constants';
import {
    Answer,
    QuestionDescription,
    WizardOptions,
} from '../../sites/site/compliance-manager/shared/cm-wizard/cm-wizard.model';

export let VPWizardAnswers: Answer[] = [
    {
        title: 'Yes, Fully',
        color: CM_COLORS.COVERED_BRIGHT,
        colorMuted: CM_COLORS.COVERED,
        value: 1,
        dbval: 'yes',
    },
    {
        title: 'Yes, Partially',
        color: CM_COLORS.PARTIAL_BRIGHT,
        colorMuted: CM_COLORS.PARTIAL,
        value: 2,
        dbval: 'partially',
    },
    {
        title: 'No',
        color: CM_COLORS.NOTCOVERED_BRIGHT,
        colorMuted: CM_COLORS.NOTCOVERED,
        value: 3,
        dbval: 'no',
    },
    {
        title: 'Unsure',
        color: CM_COLORS.UNSURE_BRIGHT,
        colorMuted: CM_COLORS.UNSURE,
        value: 4,
        dbval: 'unsure',
    },
];

export let VPWizardDescription: QuestionDescription[] = [
    {
        title: 'Does your organization handle:',
        content: 'Control.ShortDesc',
        localized: true,
    },
    {
        title: 'Description',
        content: 'Control.LongDesc',
        localized: true,
        ifBlank: '[There is currently no description for this control.]',
    },
    {
        title: 'Guidance',
        content: 'Control.Guidance',
        localized: true,
        ifBlank: '[There is currently no guidance for this control.]',
    },
];

export let VPWizardOptions: WizardOptions = {
    assessment: 'vendorPortal',
    type: 'control',
    showFilesTab: false,
    showResultsButton: false,
    assessmentCompleteText: 'Mark assessment as complete?',
    listViewColumns: ['check', 'control', 'response', 'comment'],
    firstSort: { active: 'control', direction: 'asc' },
    allowAssignments: false,
    showCSGuidance: false,
};
