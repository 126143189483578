import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    inject,
} from '@angular/core';
import { ReactiveFormsModule, FormsModule, FormBuilder } from '@angular/forms';
import { SemanticModalComponent } from 'app/semantic-legacy/components/modal/modal';
import { StandardsService } from 'app/sites/shared/standards/standards.service';
import { Site } from 'app/sites';
import { SharedModule } from 'app/shared/shared.module';
import { CommonModule } from '@angular/common';
import { SelectStandardsService } from './select-standards.service';
import { Observable, Subject, takeUntil } from 'rxjs';
import {
    DisplayType,
    SimpleStandard,
} from 'app/sites/site/compliance-manager/compliance-manager.model';
@Component({
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, FormsModule, SharedModule],
    selector: 'select-standards-modal',
    templateUrl: './select-standards-modal.component.html',
    styleUrls: ['./select-standards-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectStandardsModalComponent implements OnInit, OnDestroy {
    private standardsService = inject(StandardsService);
    public selectStandardsService = inject(SelectStandardsService);
    private fb = inject(FormBuilder);

    @Input() set allStandards(standards: SimpleStandard[]) {
        if (standards) {
            this.originalStandards = JSON.parse(JSON.stringify(standards));
            this.selectStandardsService.setAllStandards(JSON.parse(JSON.stringify(standards)));
        }
    }

    @Input() site: Site;
    @Input() allowFilterByType = true;
    @Output() saveStandards = new EventEmitter<SimpleStandard[]>();
    @ViewChild('selectStandardsModal') selectStandardsModal: SemanticModalComponent;
    @ViewChild('scrollTable') scrollTable: ElementRef;

    modalForm = this.fb.group({
        searchKey: [''],
        controlsOptionSelected: ['showAll'],
        showSelected: [false],
    });

    originalStandards: SimpleStandard[];
    acknowledging: boolean = false;
    url: string = '';
    externalContent: boolean = false;
    standardLicenseToCheck: string = 'ISO 27002';
    checkingAcknowledgement: boolean = false;
    standards$: Observable<any>;

    combinedArray: SimpleStandard[] = [];
    dropdownOptions = [
        { value: 'showAll', label: 'All' },
        { value: 'showSysStd', label: 'System Standard' },
        { value: 'showUserStd', label: 'User Created Standard' },
        { value: 'showDepStd', label: 'Deprecated Standard' },
    ];
    modalContent: 'select' | 'acknowledge' | 'externalContent' = 'select';
    ngUnsubscribe$ = new Subject<void>();

    ngOnInit(): void {
        this.standards$ = this.selectStandardsService.getAllStandards();

        this.modalForm
            .get('searchKey')
            .valueChanges.pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((value) => {
                this.selectStandardsService.setSearchKey(value.toLowerCase().trim());
            });
        this.modalForm
            .get('controlsOptionSelected')
            .valueChanges.pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((value: DisplayType) => {
                this.selectStandardsService.setSelectedTypes(value);
            });
        this.modalForm
            .get('showSelected')
            .valueChanges.pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => {
                this.selectStandardsService.toggleSelectedOnly();
            });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    /** Show the modal with some default modal options */
    showModal(): void {
        this.selectStandardsModal.show({
            onHide: () => {
                setTimeout(() => {
                    this.selectStandardsModalClosed();
                }, 200);
            },
            autofocus: false,
            closable: false,
        });
        setTimeout(() => {
            this.scrollTable.nativeElement.scrollTop = 0;
        }, 1);
    }

    /** User closed the modal so reset modal */
    selectStandardsModalClosed(): void {
        // reset modal when closing
        this.selectStandardsService.resetModal();
        this.selectStandardsService.setAllStandards(this.originalStandards);
        this.modalForm.get('searchKey').setValue('', { emitEvent: false });
        this.modalForm.get('controlsOptionSelected').setValue('showAll', { emitEvent: false });
        this.modalForm.get('showSelected').setValue(false, { emitEvent: false });
        this.modalContent = 'select';
    }

    /** User clicked on save standards button, emit the standards to the parent */
    onSaveStandards(): void {
        let allStandards = this.selectStandardsService.saveStandards();
        let returnedStandards = [
            ...allStandards.systemStandards,
            ...allStandards.nonSystemStandards,
            ...allStandards.deprecatedStandards,
        ];
        this.saveStandards.emit(returnedStandards);
        this.selectStandardsModal.hide();
    }

    checkAcknowledgement(): void {
        let allStandards = this.selectStandardsService.saveStandards();
        let returnedStandards = [
            ...allStandards.systemStandards,
            ...allStandards.nonSystemStandards,
            ...allStandards.deprecatedStandards,
        ];
        if (
            returnedStandards?.find((s) => s.StandardName == this.standardLicenseToCheck)?.Selected
        ) {
            this.acknowledging = true;
            this.standardsService
                .getAcknowledgeStandardLicense(this.site.Id, this.standardLicenseToCheck)
                .then((res) => {
                    if (res) {
                        this.onSaveStandards();
                    } else {
                        this.modalContent = 'acknowledge';
                    }
                    this.acknowledging = false;
                });
        } else {
            this.onSaveStandards();
        }
    }

    cancelAcknowledge(): void {
        this.modalContent = 'select';
    }

    onAcknowledge(): void {
        this.standardsService
            .setAcknowledgeStandardLicense(this.site.Id, this.standardLicenseToCheck)
            .then(() => {
                this.onSaveStandards();
            });
    }

    navigateTo(): void {
        window.open(this.url, '_blank');
        this.modalContent = 'acknowledge';
    }

    viewExternalContent(link): void {
        this.modalContent = 'externalContent';
        this.url = link;
    }

    cancelExternalContent(): void {
        this.modalContent = 'acknowledge';
    }
}
