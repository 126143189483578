<div class="fullWidthContent" style="padding-bottom: 0">
    <table
        class="itc-table"
        sortable-table
        style="min-width: 100%; width: 100%"
        sortColumn="Created"
        sortDirection="{{ invertDefault ? 'desc' : 'asc' }}"
        (sorted)="onSorted($event)">
        <thead>
            <tr>
                <th sortable-column="ActionType" style="width: 95px">Action</th>

                <th sortable-column="Created">Date</th>

                <th *ngIf="!forSite" sortable-column="SiteName">Site</th>

                <th sortable-column="Message">Message</th>

                <th sortable-column="requestedBy" style="min-width: 250px">Requested By</th>
            </tr>
        </thead>
        <tr
            *ngFor="let alert of todos"
            (click)="detail(alert.Guid)"
            [class.isCompleted]="isCompleted(alert)">
            <td>
                <sds-todo-type-badge [alert]="alert"></sds-todo-type-badge>
            </td>
            <td class="collapsing">{{ alert.Created | accountDateTime }}</td>
            <td *ngIf="!forSite" class="collapsing">{{ alert.SiteName }}</td>
            <td>
                <a routerLink="{{ linksRoot ? linksRoot + '/' + alert.Guid : alert.Guid }}">
                    <b>{{ alert.Message }}</b>
                    <br />
                    <span style="font-style: italic; color: #555">
                        {{ alert.DataObject?.Primary?.ObjectName }}
                    </span>
                </a>
            </td>
            <td class="collapsing">{{ alert.requestedBy }}</td>
        </tr>
    </table>
</div>
