<div class="paginationionion" #pager>
    <div class="ui input">
        <sm-select
            [(model)]="pageSize"
            class="compact pageSizeDropdown"
            (modelChange)="page()"
            [options]="{ direction: 'downward' }"
            [disabled]="!pages.length">
            <option *ngFor="let size of sizes">{{ size }}</option>
        </sm-select>
    </div>

    <div class="rowsPerPage">rows per page</div>

    <div class="inputContainer" [class.disabled]="!pages.length">
        <input
            type="number"
            [ngModel]="pageNumber + 1"
            (ngModelChange)="setPage($event - 1)"
            min="1"
            [max]="pages.length"
            #pageNumberInput />
        <span>of {{ lastPage + 1 }}</span>
    </div>
    <div class="buttonContainer">
        <button
            class="flipH"
            (click)="setPage(0)"
            [class.disabled]="pageNumber == 0 || !pages.length">
            <i class="icon-beginning-end beginning"></i>
        </button>
        <button
            class="flipH"
            (click)="setPage(pageNumber - 1)"
            [class.disabled]="pageNumber == 0 || !pages.length">
            <i class="icon-chevron"></i>
        </button>
        <button
            (click)="setPage(pageNumber + 1)"
            [class.disabled]="pageNumber == lastPage || !pages.length">
            <i class="icon-chevron right"></i>
        </button>
        <button
            (click)="setPage(lastPage)"
            [class.disabled]="pageNumber == lastPage || !pages.length">
            <i class="icon-beginning-end end"></i>
        </button>
    </div>
</div>
