export const CM_COLORS = {
    COVERED_BRIGHT: 'rgba(0, 194, 132, 1)',
    PARTIAL_BRIGHT: 'rgba(248, 206, 26, 1)',
    NOTCOVERED_BRIGHT: 'rgba(250, 62, 62, 1)',
    UNSURE_BRIGHT: 'rgba(153, 153, 153,1)',
    NOTASSESSED_BRIGHT: 'rgba(235, 235, 235, 1)',
    COVERED: 'rgb(171, 223, 196)',
    PARTIAL: 'rgb(248, 231, 165)',
    NOTCOVERED: 'rgb(243, 167, 163)',
    UNSURE: 'rgb(204, 204, 204)',
    NOTASSESSED: 'rgb(245, 245, 245)',
    FOREGROUND: 'rgba(0, 0, 0, 1)',
};

/* Previous values: Win wanted this change, the charts will use the full saturated version, and the legends
    buttons, etc will use the lighter versions. Previous colors kept for the record
    // COVERED_LIGHT: 'rgba(0, 194, 132, 0.5)', 
    // PARTIAL_LIGHT: 'rgba(248, 206, 26, 0.5)',
    // NOTCOVERED: 'rgba(250, 62, 62, 0.5)',
    // UNSURE: 'rgba(153, 153, 153, 0.5)',
    // NOTASSESSED: 'rgba(235, 235, 235, 0.5)'
*/
