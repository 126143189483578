<header>
    <div class="headerLogo">
        <a
            routerLink="/"
            class="ir site_logo"
            *ngIf="!settings?.EnableCustomBranding || settings?.EnableCustomBranding == 'false'">
            RapidFire Tools
        </a>
        <a
            routerLink="/"
            style="font-size: 1.2em"
            *ngIf="
                settings?.EnableCustomBranding == 'true' || settings?.EnableCustomBranding == true
            ">
            {{ settings?.CompanyName }}
        </a>
    </div>
    <div class="menuItems">
        <a *ngFor="let item of menuItems" [routerLink]="item.href" routerLinkActive="active">
            <span>{{ item.text }}</span>
        </a>
    </div>
    <div class="rightMenuItems" *ngIf="user">
        <div class="ui dropdown helpMenu" [class.hasAlert]="hasNewWhatsNew" #helpMenu>
            <div class="menuIcon" tooltip="Help" ttPosition="bottom" [ttHidden]="helpMenuOpen">
                <fa-icon icon="circle-question" style="font-size: 24px"></fa-icon>
            </div>

            <div class="menu">
                <div class="header">Help</div>
                <div
                    class="ui left pointing link dropdown item"
                    *ngIf="
                        products.CMProduct ||
                        products.CHProduct ||
                        products.VulScanProduct ||
                        products.NdProWeb
                    ">
                    Knowledge Base
                    <fa-icon icon="chevron-right" class="right floated"></fa-icon>
                    <div class="menu">
                        <a
                            href="{{ rft_url }}/cm-grc/help"
                            *ngIf="products.CMGRCProduct"
                            class="item"
                            target="_blank">
                            Compliance Manager GRC Help
                        </a>
                        <a
                            href="{{ rft_url }}/cm/cmonlinehelp"
                            *ngIf="products.CMProduct && hasCMSites"
                            class="item"
                            target="_blank">
                            Compliance Manager Help
                        </a>
                        <a
                            href="{{ rft_url }}/ch/help"
                            *ngIf="products.CHProduct"
                            class="item"
                            target="_blank">
                            Cyber Hawk Help
                        </a>
                        <a
                            href="{{ rft_url }}/networkdetective/help/ndp-home.htm"
                            *ngIf="products.NdProWeb"
                            class="item"
                            target="_blank">
                            Network Detective Pro Help
                        </a>
                        <a
                            href="{{ rft_url }}/vs/help"
                            *ngIf="products.VulScanProduct"
                            class="item"
                            target="_blank">
                            VulScan Help
                        </a>
                    </div>
                </div>
                <a [routerLink]="null" (click)="videoTutorials.display()" class="item">
                    Video Tutorials
                </a>
                <div
                    class="ui left pointing link dropdown item"
                    *ngIf="
                        !isSiteRestricted &&
                        (products.CHProduct || products.CMProduct || products.VulScanProduct)
                    ">
                    Resources
                    <fa-icon icon="chevron-right" class="right floated"></fa-icon>

                    <div class="menu">
                        <ng-container *ngIf="products.CMGRCProduct">
                            <div class="header">Compliance Manager GRC</div>
                            <a href="/resources/grc" class="item" target="_blank">GRC Resources</a>
                        </ng-container>

                        <ng-container *ngIf="products.CMProduct && hasCMSites">
                            <div class="header">Compliance Manager</div>
                            <a
                                href="/resources/cmmc"
                                *ngIf="products.AGCMMC"
                                class="item"
                                target="_blank">
                                CMMC Resources
                            </a>
                            <a
                                href="/resources/cyber-insurance"
                                *ngIf="products.AGCYBERINSURANCE"
                                class="item"
                                target="_blank">
                                Cyber Insurance Resources
                            </a>
                            <a
                                href="/resources/gdpr"
                                *ngIf="products.AGGDPR"
                                class="item"
                                target="_blank">
                                EU GDPR Resources
                            </a>
                            <a
                                href="/resources/hipaa"
                                *ngIf="products.AGHIPAA"
                                class="item"
                                target="_blank">
                                HIPAA Resources
                            </a>
                            <a
                                href="/resources/nist-csf"
                                *ngIf="products.AGNIST"
                                class="item"
                                target="_blank">
                                NIST CSF Resources
                            </a>
                            <a
                                href="/resources/ukgdpr"
                                *ngIf="products.AGUKGDPR"
                                class="item"
                                target="_blank">
                                UK GDPR Resources
                            </a>
                        </ng-container>

                        <ng-container *ngIf="products.CHProduct">
                            <div class="header">Cyber Hawk</div>
                            <a href="/resources/cyber-hawk" class="item" target="_blank">
                                Cyber Hawk Resources
                            </a>
                        </ng-container>

                        <ng-container *ngIf="products.VulScanProduct">
                            <div class="header">VulScan</div>
                            <a href="/resources/vulscan" class="item" target="_blank">
                                VulScan Resources
                            </a>
                        </ng-container>
                    </div>
                </div>
                <a href="https://status.kaseya.com/" target="_blank" class="item">Status</a>
                <div
                    class="ui left pointing link dropdown item"
                    *ngIf="
                        !isSiteRestricted &&
                        (products.CMProduct ||
                            products.CHProduct ||
                            products.NdPro ||
                            products.VulScanProduct)
                    ">
                    Suggest a Feature
                    <fa-icon icon="chevron-right" class="right floated"></fa-icon>
                    <div class="menu">
                        <a
                            href="https://community.kaseya.com/ideas/categories/compliance-manager-ideas-portal"
                            *ngIf="products.CMProduct"
                            class="item"
                            target="_blank">
                            Compliance Manager
                        </a>
                        <a
                            href="https://community.kaseya.com/ideas/categories/cyberhawk-ideas"
                            *ngIf="products.CHProduct"
                            class="item"
                            target="_blank">
                            Cyber Hawk
                        </a>
                        <a
                            href="https://community.kaseya.com/ideas/categories/network-detective-pro-ideas"
                            *ngIf="products.NdPro"
                            class="item"
                            target="_blank">
                            Network Detective Pro
                        </a>
                        <a
                            href="https://community.kaseya.com/ideas/categories/vulscan-ideas"
                            *ngIf="products.VulScanProduct"
                            class="item"
                            target="_blank">
                            VulScan
                        </a>
                    </div>
                </div>
                <a [routerLink]="[]" class="item" (click)="showReleaseNotes()">Release Notes</a>

                <!-- walkme items hidden until future update per 23934 -->
                <!-- <ng-container *ngIf="allowWalkMe">
                    <div class="header">Interactive Guides</div>
                    <a [routerLink]="[]" class="item" (click)="openWalkMe('showMe')">Show Me</a>
                    <a [routerLink]="[]" class="item" (click)="openWalkMe('onboarding')">
                        Onboarding Tasks
                    </a>
                </ng-container> -->

                <div class="header">Legal</div>
                <a
                    href="https://www.kaseya.com/legal/website-terms-of-use/"
                    target="_blank"
                    class="item">
                    Terms of Use
                </a>
                <a
                    href="https://www.kaseya.com/legal/kaseya-privacy-statement/"
                    target="_blank"
                    class="item">
                    Privacy Statement
                </a>
                <a [routerLink]="[]" class="item" (click)="noticesModal.show()">Notices</a>
                <div class="divider"></div>
                <div class="version header">
                    <span class="newDot" *ngIf="hasNewWhatsNew"></span>
                    V{{ version }}
                    <a href="https://www.rapidfiretools.com/products/whats-new/" target="_blank">
                        <itc-button
                            size="small"
                            class="item whatsnewbutton"
                            style="padding: 0 !important; margin-left: 24px">
                            What's New
                        </itc-button>
                    </a>
                </div>
            </div>
        </div>

        <div *ngIf="allowSettings" class="ui dropdown left settingsMenu" #settingsMenu>
            <div
                class="menuIcon"
                [class.active]="rla.isActive"
                tooltip="Settings"
                ttPosition="bottom"
                [ttHidden]="settingsMenuOpen">
                <fa-icon icon="gear" style="font-size: 24px"></fa-icon>
            </div>

            <div class="menu">
                <div class="header">SETTINGS</div>
                <a
                    [routerLink]="['/', 'settings']"
                    routerLinkActive
                    class="item"
                    [class.activeDDItem]="rla.isActive"
                    #rla="routerLinkActive">
                    Admin
                </a>
            </div>
        </div>

        <div class="ui dropdown userMenu" #userMenu>
            <div
                class="menuIcon"
                tooltip="My Account"
                ttPosition="bottom"
                [ttHidden]="userMenuOpen">
                <div class="usernameMenuItem">
                    <i class="user-menu-icon"></i>
                    <span truncateText>{{ user?.displayname }}</span>
                </div>
            </div>

            <div class="menu userInfoMenu">
                <div class="userinfo header">
                    <i class="large icon-account-circle"></i>
                    <div class="userinfo">
                        <div class="username" truncateText *ngIf="user?.userFullName">
                            {{ user?.userFullName }}
                        </div>
                        <div
                            [class.useremail]="user?.userFullName"
                            [innerHTML]="user.truncatedEmail"
                            truncateText
                            [attr.data-qa]="user?.username"></div>
                    </div>
                </div>
                <div class="divider"></div>
                <a class="item" [routerLink]="null" (click)="showPrefsModal()">My Settings</a>
                <div class="divider"></div>
                <a class="item" routerLink="/logout">Log Out</a>
            </div>
        </div>

        <div class="ui dropdown k1Menu" #k1Menu *ngIf="!showLauncher">
            <div
                class="menuIcon"
                tooltip="Log In with KaseyaOne"
                ttPosition="bottom-left"
                [ttHidden]="k1MenuOpen">
                <i class="k1Icon"></i>
            </div>
            <div class="menu">
                <div class="ssoContent">
                    <div class="ssoHeader">SINGLE SIGN-ON</div>

                    <!-- SSO not enabled and user is admin -->
                    <ng-container *ngIf="!kaseyaOneV2Enabled && canChangeKaseyaOneSetting">
                        <itc-button type="primary" (onclick)="onEnableKaseyaOneClick($event)">
                            Enable Log In with KaseyaOne
                        </itc-button>
                        <p>
                            Once enabled, your users can enable Log In with KaseyaOne for
                            themselves.
                        </p>
                    </ng-container>

                    <!-- SSO is not enabled and user is NOT admin-->
                    <ng-container *ngIf="!kaseyaOneV2Enabled && !canChangeKaseyaOneSetting">
                        <p>
                            You do not have access to Unified Login. Contact your Admin to enable
                            Log In with KaseyaOne.
                        </p>
                    </ng-container>

                    <!-- SSO is enabled -->
                    <ng-container *ngIf="kaseyaOneV2Enabled">
                        <itc-button type="primary" (onclick)="openK1SSO()">
                            Log In with KaseyaOne
                        </itc-button>
                        <p>Don't have a KaseyaOne login? Contact your IT admin.</p>
                    </ng-container>

                    <a
                        class="learnMore item"
                        target="_blank"
                        href="https://help.one.kaseya.com/help/Default_CSH.htm#1015">
                        Learn More
                    </a>
                </div>
            </div>
        </div>

        <div style="display: inline-block" *ngIf="showLauncher">
            <itc-app-launcher [products]="k1Products" [theme]="settings.Theme"></itc-app-launcher>
        </div>
    </div>
</header>

<sds-user-preferences #prefsComponent></sds-user-preferences>

<sm-modal
    title="Release Notes"
    class="md"
    #releaseNotesModal
    (onModalShow)="releaseNotesModal.refresh()">
    <modal-content>
        <sds-loader [complete]="releaseNotesLoaded">
            <div [innerHTML]="releaseNotes | safeHtml"></div>
        </sds-loader>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" class="cancel">Close</itc-button>
    </modal-actions>
</sm-modal>

<sm-modal title="Notices" class="md" #noticesModal (onModalShow)="noticesModal.refresh()">
    <modal-content>
        <div>
            <p>
                This product uses the NIST NVD API but is not endorsed or certified by the National
                Institute of Standards and Technology National Vulnerability Database (NIST NVD).
            </p>
        </div>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" class="cancel">Close</itc-button>
    </modal-actions>
</sm-modal>

<sm-modal class="md" title="Disable IT Complete" #itcompleteDeleteModal>
    <modal-content>
        <div class="ui warning icon message">
            <i class="warning sign icon"></i>
            <div class="content">
                <div class="header">Warning</div>
                <p>Are you sure you wish to disable Log in with KaseyaOne for your account?</p>
            </div>
        </div>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" (onclick)="itcompleteDeleteModal.hide()">Cancel</itc-button>
        <itc-button type="danger" (onclick)="disableItComplete()">Disable</itc-button>
    </modal-actions>
</sm-modal>

<itc-video-tutorials #videoTutorials></itc-video-tutorials>
