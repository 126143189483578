import {
    AfterViewChecked,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ComponentRef,
    ElementRef,
    HostListener,
    Input,
    isDevMode,
    OnInit,
    ViewChild,
} from '@angular/core';
import { InformOldService } from './inform.service';
import { Survey } from './survey.model';
import { Category, CategoryViewModel } from './category.model';
import { Topic, TopicViewModel } from './topic.model';
import { InformTable } from './informtable.model';
import { Filter } from './filter.model';
import { BulkResponder } from './bulkresponder.model';
import { ActivatedRoute, Params, Router,RouterEvent } from '@angular/router';
import { InformRequest } from './informrequest.model';
import { MessageService } from 'app/core/message.service';
import { Site } from 'app/sites/shared/site.model';
import { PING_SITE } from 'app/sites/shared/constants';
import { ASSESSMENT, PING_ASSESSMENT } from '../assessments/site-assessments.component';
import { AlertService } from '../../../alerts/shared/alert.service';
import { AuthService } from 'app/core/auth';
import { SiteService } from 'app/sites/shared/site.service';
import { SiteUsersService } from 'app/sites/shared/site-users.service';
import { InviteSmeComponent } from '../../../shared/invite-sme/invite-sme.component';
import { ColumnProxy } from './columnproxy.model';
import { WjFlexGrid, WjFlexGridColumn } from '@grapecity/wijmo.angular2.grid';
import {
    AllowResizing,
    AutoSizeMode,
    DataMap,
    HeadersVisibility,
    SelectionMode,
} from '@grapecity/wijmo.grid';
import { ColumnStyle } from './enums';
import { Subscription, timer } from 'rxjs';
import { NavService } from 'app/core/nav/nav.service';
import { UntypedFormControl } from '@angular/forms';
import { CanComponentDeactivate } from 'app/deactivate-guard.service';
import { SiteSettingService } from 'app/sites/site/settings/site-settings.service';
import { UiService } from 'app/core/ui.service';

import * as saveAs from 'file-saver';
import { NotificationService } from '../../../shared/itc/notification/notification.service';
import { RFT_URL } from 'app/core/constants';
import { SiteDashboardService } from 'app/sites/shared/site-dashboard.service';
import { GrcAssessmentArchiveModel } from '../../shared/GrcAssessmentArchiveModel';
import { GrcService } from '../../shared/grc.service';
declare var $: any;

@Component({
    selector: 'cm-inform',
    templateUrl: './inform.component.html',
    styleUrls: ['./inform.component.css'],
})
export class InformOldComponent
    implements OnInit, AfterViewInit, AfterViewChecked, CanComponentDeactivate
{
    archiveAssessmentData$ = this.grcService.getAssessments();
    @ViewChild('inviteSME', { static: true }) inviteSME: ComponentRef<InviteSmeComponent>;
    @ViewChild('previousFlex', { static: true }) previousFlex: WjFlexGrid;
    @ViewChild('previousModal', { static: true }) previousModal: any;
    @ViewChild('closeGuardModal', { static: true }) closeGuardModal: any;
    @ViewChild('previousResponseWarningModal', { static: true }) previousResponseWarningModal: any;
    @ViewChild('sideDrawer', { static: true }) sideDrawer: ElementRef;
    isInArchived: boolean;
    site: Site;
    @Input() worksheetLink: string;

    selectedArchivedAssessment: GrcAssessmentArchiveModel;
    survey: Survey;
    previousSurvey: Survey;
    totalRequiredRemaining: number;
    showNumbers: boolean;
    showBulkResponder: boolean;
    filter: Filter = new Filter();
    hasChanges: boolean = false;
    questionsRemaining = [];
    requiredRemaining = [];
    topicDictionary = {};
    totalCategories: number;
    maxCategories: number = 20;
    displayedTopicCount: number;
    //category: Category;
    //topicsPerPage: number = 50;
    //displayedTopics: Topic[];
    //currentCategory: number = 0;
    bulkResponder: BulkResponder;
    informName: string;
    informDecrypted: string;
    topicsUpdated: number = 0;
    focusedTopic: Topic;
    questionRemainingIndex: number = -1;
    siteName: string = '';
    siteId: number;
    siteType: string = '';
    previousAlertUrl: string;
    isSaving: boolean;
    lid: number;
    sSub: any;
    aSub: any;
    tSub: any;
    selectedAssessment: any;
    // siteRole: string;
    isSme: boolean;
    inviteButtonLoading: boolean = false;
    hasPrevious: boolean = false;

    populateResponse: boolean;

    previousTableData: any[];
    previousTableTagData: any[];
    mergedTableData: any[];
    mergedTableTagData: any[];
    previousColumns: ColumnProxy[];
    newCategoryName: string;
    newTopicId: string;
    mergeSubscriptions: Subscription[];

    loadingComplete: boolean = false;

    helpLoadingComplete: boolean = true;
    helpOpen: boolean = false;
    helpOpenID: string;
    helpPageInfo: any;
    helpIframeElement: HTMLIFrameElement;
    helpDisplayed: boolean = false;

    page_content: HTMLElement;

    doPaging: boolean;
    suggestedPageSize: number;
    currentPage: number;
    pagedCategories: any[];
    previousCategoryIndex: number;
    breadcrumbs = [
        { path: '../../..', text: 'Compliance Manager' },
        { path: '../..', text: 'Assessments' },
        { path: '..', text: 'InForm' },
    ];
    breadcrumbsGRC = [
        { path: '../../..', text: 'Compliance Manager GRC' },
        { path: '../..', text: 'Results & Evidence' },
        { path: '..', text: 'Worksheets' },
        { path: '.', text: 'Inform' },
    ];

    languageCtrl: UntypedFormControl = new UntypedFormControl();
    languageMap: any = [
        { value: 'nl-NL', label: 'Dutch' },
        { value: 'en-US', label: 'English (US)' },
        { value: 'fr-FR', label: 'French' },
        //{ value: 'fr-CA', label: 'French (Canada)' },
        { value: 'de-DE', label: 'German' },
        { value: 'it-IT', label: 'Italian' },
        //{ value: 'pt-BR', label: 'Portuguese (Brasil)' },
        { value: 'es-ES', label: 'Spanish' },
    ];
    showLanguageCtrl: boolean = true;

    //culture: string = "de-DE";
    culture: string = 'en-US';
    topicTables: any[];
    isFormDirty: boolean = false;
    itGlueSettingName = 'ITGLUE_ENABLED';
    showItGlueAttachmentButton: boolean = false;
    enableItGlueAttachmentButton: boolean = false;
    itglueOauthReturnTopic: string;
    itglueButtonTip: string;
    pSub: any;

    lockGUID: string = '';
    pageClosed: boolean = false;
    lockedUser: any = null;

    // handle if the user tries to leave the page via refresh or typing a different url
    @HostListener('window:beforeunload', ['$event'])
    checkForUnsavedChanges($event) {
        this.deleteModuleLock();

        if (!this.isFormDirty) {
            if (!this.survey.IsReadOnly) {
                // try to release form when it's not dirty, is not readonly and window is closed
                this.informService
                    .releaseInform(this.siteName, this.informName)
                    .then((relres) => {});
            }
        } else {
            $event.returnValue = true;
        }
    }

    constructor(
        private informService: InformOldService,
        private route: ActivatedRoute,
        private ref: ChangeDetectorRef,
        private notificationService: NotificationService,
        private alertService: AlertService,
        private router: Router,
        private messageService: MessageService,
        private authService: AuthService,
        private siteService: SiteService,
        private siteUsersService: SiteUsersService,
        private navService: NavService,
        private uiService: UiService,
        private siteSettingService: SiteSettingService,
        private siteDashboardService: SiteDashboardService,
        private grcService: GrcService
    ) {
        this.totalRequiredRemaining = 0;
        this.bulkResponder = new BulkResponder();
        this.showNumbers = true;
        this.mergeSubscriptions = [];
        this.helpPageInfo = [];
    }

    ngOnInit() {
        this.loadingComplete = false;
        this.isInArchived = this.router.url.includes('archived-assessments');
        if (this.isInArchived) {
            this.archiveAssessmentData$.subscribe((data) => {
                this.loadingComplete = false;
                if (data) {
                    this.site = data['site'];
                    this.selectedArchivedAssessment = data['selectedAssessment'];
                    this.loadArchivedWorkSheet();
                    this.router.events.subscribe((event) => {
                        if (event instanceof RouterEvent) {
                            this.setDefaultBreadCrumb();
                        }
                    });
                }
            });
        } else {
            // add class to body so we can style the sidebar and make it shorter for the inform bottom bar
            document.body.classList.add('informPage');
            this.checkParams();
            this.topicTables = [];
            this.previousAlertUrl = this.navService.getReturnUrl();

            let jsonfile = isDevMode()
                ? `${RFT_URL}/data/inform-help-dev.v3.json`
                : `${RFT_URL}/data/inform-help.v3.json`;

            this.languageCtrl.setValue(this.culture);

            $.getJSON(jsonfile, (data) => {
                const informName = window.atob(this.informName).slice(0, -5);
                //console.log(data);
                if (data[informName]) {
                    this.helpPageInfo = data[informName];
                }
            });

            let mytimer = timer(600000, 600000);
            this.tSub = mytimer.subscribe((t) => this.renewFormLock(t));

            this.aSub = this.messageService.on(ASSESSMENT).subscribe((assessment) => {
                if (this.siteType === 'CM2') {
                    //        if (!this.selectedAssessment) {
                    //          this.selectedAssessment = {};
                    //          this.selectedAssessment.Name = '__current';
                    //        }
                } else {
                    if (
                        !assessment ||
                        (this.selectedAssessment && this.selectedAssessment.Name == assessment.Name)
                    )
                        return;

                    this.selectedAssessment = assessment;
                }

                this.loadInform();
            });

            this.siteService.app_getCurrentSite().then((site: Site) => {
                this.site = site;
                this.siteName = site.Name;
                this.uiService.setTitle('InForm', site.Name);
                this.siteId = site.Id;
                if (this.siteService.isComplianceManagerGRC(site)) {
                    this.breadcrumbs = this.breadcrumbsGRC;
                }
                this.isSme = !site.UserRoles || site.UserRoles.indexOf('AG_SME') < 0 ? false : true;
                this.siteSettingService.getSiteParam(site.Id, this.itGlueSettingName).then((sp) => {
                    this.showItGlueAttachmentButton = true;
                    this.enableItGlueAttachmentButton = this.isTrueSet(sp.Value);

                    this.itglueButtonTip = this.enableItGlueAttachmentButton
                        ? ''
                        : 'Enable in Site Settings > IT Complete';
                });
                this.siteType = site.Type;
                this.messageService.broadcast(PING_ASSESSMENT);
            });

            this.pSub = this.route.queryParams.subscribe((params) => {
                this.checkForItglueOAuth(params);
            });

            this.messageService.broadcast(PING_SITE);
        }
    }

    loadArchivedWorkSheet() {
        document.body.classList.add('informPage');
        this.informName = decodeURIComponent(this.worksheetLink);
        let jsonfile = isDevMode()
            ? `${RFT_URL}/data/inform-help-dev.v3.json`
            : `${RFT_URL}/data/inform-help.v3.json`;

        this.languageCtrl.setValue(this.culture);

        this.uiService.setTitle('InForm', this.site.Name);
        this.siteId = this.site.Id;
        if (this.siteService.isComplianceManagerGRC(this.site)) {
            this.breadcrumbs = this.breadcrumbsGRC;
        }
        this.isSme =
            !this.site.UserRoles || this.site.UserRoles.indexOf('AG_SME') < 0 ? false : true;

        this.siteType = this.site.Type;
        this.siteName = this.site.Name;
        // this.selectedAssessment = { Name: '__current' };
        this.loadInform();
    }
    isTrueSet(v: any) {
        return v === 'true' || v === 'True';
    }

    canDeactivate() {
        if (!this.isFormDirty) {
            return true;
        } else {
            if (
                confirm(
                    'This form contains unsaved data. Do you want to leave form without saving?'
                )
            )
                return true;
            else return false;
        }
    }

    checkParams() {
        this.route.params.subscribe((ps: Params) => {
            if (ps['formname']) {
                this.informName = ps['formname'];
            }
            if (ps['assessment']) {
                this.selectedAssessment = {};
                this.selectedAssessment.Name = atob(ps['assessment']);
            }

            //else
            // some kind of error?
        });

        // I'm commenting this out because this does not always represent the survey name.  I'm replacing it with the survey name after the survey is loaded.  If I cause something to break because of this, let me know - TH
        //this.informDecrypted = window.atob(this.informName).slice(0, -5); // decrypt and remove the .idf4 extension on the end

        // This is not needed as siteName is now set in the subscription in ngOnInit
        //this.siteName = this.route.parent.parent.parent.firstChild.snapshot.paramMap.get('name');
    }

    ngAfterViewInit() {
        //this.loadInform();
        this.helpIframeElement = <HTMLIFrameElement>(
            document.querySelector('.sideDrawerHelp iframe')
        );
        // this.page_content = <HTMLElement>document.querySelector('.sidebarContent');

        // /* Sticky sidebar help drawer on scroll */
        this.page_content = <HTMLElement>document.querySelector('.sidebarContent');
        const sideHelp = <HTMLElement>document.querySelector('.sideDrawerHelp');

        this.page_content.addEventListener('scroll', () => {
            var sTop = this.page_content.scrollTop;
            if (sTop == 0) {
                if (sideHelp.classList.contains('midScroll'))
                    sideHelp.classList.remove('midScroll');
                if (sideHelp.classList.contains('fixedTop')) sideHelp.classList.remove('fixedTop');
            } else if (sTop >= 10 && sTop < 200) {
                if (sideHelp.classList.contains('fixedTop')) sideHelp.classList.remove('fixedTop');
                sideHelp.classList.add('midScroll');
            } else if (sTop >= 200) {
                if (sideHelp.classList.contains('midScroll'))
                    sideHelp.classList.remove('midScroll');
                sideHelp.classList.add('fixedTop');
            }
        });
    }

    ngAfterViewChecked() {
        //    /* Sticky survey name and menu */
        //    const informHeader = <HTMLElement>document.querySelector('.informHeader');
        //    const informHeaderContainer = <HTMLElement>document.querySelector('.informHeaderContainer');
        //
        //    informHeaderContainer.style.height = informHeader.offsetHeight + 10 + 'px';
        //    // informHeaderContainer.style.width = informHeader.offsetWidth + 'px';
    }

    initPaging() {
        this.previousCategoryIndex = 0;
        this.doPaging = true; // turn off to disable paging
        this.suggestedPageSize = 40; // try to fit as many categories into a page having less than this many topics.  If a category has more than this many topics then put it into its own page
        this.currentPage = 0; // set page to first page
        this.pagedCategories = [];

        if (this.doPaging && this.survey) {
            for (let category of this.survey.Categories) {
                if (category.IsFilteredOut) {
                    continue;
                }

                let lastPageIndex: number = this.pagedCategories.length - 1;
                let topicCount: number = 0;

                if (this.pagedCategories.length > 0) {
                    let proposedPageLength: number =
                        this.pagedCategories[lastPageIndex].length + category.Topics.length;
                    let pageCategories: Category[] = this.pagedCategories[lastPageIndex];

                    for (let category of pageCategories) {
                        topicCount += category.Topics.filter(
                            (topic) => !topic.IsFilteredOut
                        ).length;
                    }
                }

                if (
                    this.pagedCategories.length == 0 ||
                    topicCount + category.Topics.length > this.suggestedPageSize
                ) {
                    let newCategoryPage: Category[] = [];
                    newCategoryPage.push(category);

                    this.pagedCategories.push(newCategoryPage);
                } else {
                    this.pagedCategories[lastPageIndex].push(category);
                }
            }
        }

        // don't page if there is 1 or 0 pages
        if (this.pagedCategories.length < 2) {
            this.doPaging = false;
        }
    }

    updatePreviousCategoryIndex() {
        var i;

        this.previousCategoryIndex = 0;
        for (i = 0; i < this.currentPage; i++) {
            this.previousCategoryIndex += this.pagedCategories[i].length;
        }
    }

    pageBack() {
        //this.loadingComplete = false;
        setTimeout(() => {
            this.currentPage--;
            this.updatePreviousCategoryIndex();
            this.page_content.scrollTop = 0;
        }, 250);
        //this.updatePreviousCategoryIndex();
    }

    pageNext() {
        //this.loadingComplete = false;

        setTimeout(() => {
            this.currentPage++;
            this.updatePreviousCategoryIndex();
            this.page_content.scrollTop = 0;
        }, 250);
    }

    loadInform() {
        if (!this.informName) {
            return;
        }

        this.loadingComplete = false;

        let req: InformRequest = new InformRequest();
        req.InformName = this.informName;
        req.SiteName = this.siteName;

        if (this.selectedAssessment) {
            req.AssessmentName = this.selectedAssessment.Name;
        }
        //console.log(req);

        if (this.isInArchived) {
            req.AssessmentGuid = this.selectedArchivedAssessment.AssessmentGuid;
            this.informService
                .getArchivedInformByName(req)
                .then((response) => {
                    if (response) {
                        this.survey = response;
                        this.informDecrypted = this.survey.Name;
                        this.initSurvey();
                        this.initPaging();
                        this.survey.Categories.forEach((category) => {
                            category.Topics.forEach((topic) => {
                                this.validateTopic(topic);
                            });
                        });
                        this.setBreadCrumb();
                        this.loadingComplete = true;
                    }
                })
                .catch((err) => {
                    this.notificationService.toast.error(
                        'Error',
                        'There was a problem loading this worksheet.'
                    );
                });
        } else {
            this.informService
                .getInformByName(req)
                .then((s) => {
                    this.survey = s;
                    this.informDecrypted = this.survey.Name;
                    this.initSurvey();
                    this.initPaging();
                    this.survey.Categories.forEach((category) => {
                        category.Topics.forEach((topic) => {
                            this.validateTopic(topic);
                        });
                    });

                    if (!this.survey.IsReadOnly && this.siteService.isComplianceManagerGRC(this.site)) {
                        this.lockGUID = crypto.randomUUID();
                        this.checkLock();
                    }
                })
                .catch((e) => {
                    console.log('error', e);
                    this.notificationService.toast.error(
                        'Error',
                        'There was a problem loading this worksheet.'
                    );
                });
        }
    }

    initSurvey() {
        let i: number = 1;

        if (this.survey != null) {
            // this.category = this.survey.Categories[0];
            this.totalCategories = this.survey.Categories.length;

            if (!this.survey.NameLocalized) this.showLanguageCtrl = false;
            else if (this.survey.NameLocalized && this.survey.NameLocalized[this.culture] == null)
                this.showLanguageCtrl = false;

            // this.displayedTopics = [];
            // this.displayedTopics.push(...this.category.Topics);

            // console.log("displayedTopics: " + this.displayedTopics);
            // this.displayedTopicCount = this.category.Topics.length;
            for (let cat of this.survey.Categories) {
                cat.viewModel = new CategoryViewModel();

                let j: number = 1;
                for (let topic of cat.Topics) {
                    topic.viewModel = new TopicViewModel();
                    topic.SectionNumber = i.toString() + '.' + j.toString();
                    if (
                        topic.PreviousAnswer != '' &&
                        topic.PreviousAnswer !== null &&
                        !this.hasPrevious
                    ) {
                        this.hasPrevious = true;
                    }

                    if (this.previousSurvey == null) {
                        topic.viewModel.hasPreviousResponse = false;
                        topic.viewModel.previousResponseDisplay = '';
                        topic.viewModel.showPreviousResponse = false;
                        topic.viewModel.isValid = true;
                    }

                    topic.viewModel.notesVisible = topic.Note != undefined && topic.Note.length > 0;

                    j++;
                }
                i++;
            }
            this.loadingComplete = true;
        }
    }

    error(err: any) {
        console.log('ERROR: ', err);
        // this.logObject(err);
    }

    movePreviousPaginated() {
        try {
            if (this.questionsRemaining.length < 1) {
                return;
            }

            if (this.focusedTopic) {
                this.questionRemainingIndex = this.findClosestUnansweredQuestion(-1);
            } else {
                this.questionRemainingIndex =
                    (this.questionRemainingIndex + this.questionsRemaining.length - 1) %
                    this.questionsRemaining.length;
            }
            this.gotoUnansweredPaginatedQuestion();
        } catch (e) {
            this.error(e);
        }
    }

    findClosestUnansweredQuestion(targetDiff: number) {
        try {
            if (!this.focusedTopic) {
                return this.questionRemainingIndex;
            }

            var nextNum = undefined;
            var nextTopicIndex = -1;
            var minValue = undefined;
            var minValueIndex = undefined;
            var maxValue = undefined;
            var maxValueIndex = undefined;
            var targetNum = this.focusedTopic.viewModel.topicNumber + targetDiff;
            for (var i = 0; i < this.questionsRemaining.length; i++) {
                if (this.questionsRemaining[i] == this.focusedTopic) {
                    continue;
                }

                var currentNum = this.questionsRemaining[i].viewModel.number;
                if (minValue == undefined || minValue > currentNum) {
                    minValue = currentNum;
                    minValueIndex = i;
                }
                if (maxValue == undefined || maxValue < currentNum) {
                    maxValue = currentNum;
                    maxValueIndex = i;
                }

                // Skip numbers that are in the wrong direction (opposite of the direction we're going)
                if (
                    (targetDiff == 1 && currentNum < targetNum) ||
                    (targetDiff == -1 && currentNum > targetNum)
                ) {
                    continue;
                }

                if (!nextNum) {
                    nextNum = currentNum;
                    nextTopicIndex = i;
                } else if (
                    (targetDiff == 1 && currentNum >= targetNum && nextNum > currentNum) ||
                    (targetDiff == -1 && currentNum <= targetNum && nextNum < currentNum)
                ) {
                    nextNum = currentNum;
                    nextTopicIndex = i;
                }
            }

            if (nextTopicIndex == -1) {
                nextTopicIndex = targetDiff == 1 ? minValueIndex : maxValueIndex;
            }
            return nextTopicIndex;
        } catch (e) {
            this.error(e);

            return this.questionRemainingIndex;
        }
    }

    gotoUnansweredPaginatedQuestion() {
        var useTimeout = false;
        var topic = this.questionsRemaining[this.questionRemainingIndex];
        // We need special pagination logic if the topic part of another category
        /*
    if (topic.viewModel.category != this.category) {
        var categoryIndex = this.survey.Categories.indexOf(topic.viewModel.category);
        if (categoryIndex != -1) {
            this.currentCategory = categoryIndex + 1;
            this.category = topic.viewModel.category;
            this.displayedTopicCount = this.category.Topics.length;
            // this.showTopicPagination = this.displayedTopicCount > $scope.topicsPerPage;
            useTimeout = true;
        }
    }
    */
    }

    moveNextPaginated() {
        try {
            if (this.questionsRemaining.length < 1) {
                // toastr.success('All questions have been completed');
                return;
            }

            if (this.focusedTopic) {
                this.questionRemainingIndex = this.findClosestUnansweredQuestion(1);
            } else {
                this.questionRemainingIndex =
                    (this.questionRemainingIndex + 1) % this.questionsRemaining.length;
            }
            this.gotoUnansweredPaginatedQuestion();
        } catch (e) {
            this.error(e);
        }
    }

    filterTopics() {
        const disFilter = this.filter.keyWords;
        this.clearFilter();
        this.filter.keyWords = disFilter;
        let key = this.filter.keyWords.toLowerCase().replace(/^\s+/g, '').replace(/\s+$/g, '');
        this.survey.Categories.forEach((category) => {
            let categoryFilteredOut = true;
            //            console.log(category.Name);
            //            console.log(category.Instructions);
            if (
                category.Name.toLowerCase().indexOf(key) > -1 ||
                (category.Instructions !== null &&
                    category.Instructions.toLowerCase().indexOf(key) > -1)
            ) {
                // keyword is in category name or instructions, don't filter out category
                categoryFilteredOut = false;
            } else {
                // keyword is not in category name, check topics
                category.Topics.forEach((topic) => {
                    if (
                        topic.Name.toLowerCase().indexOf(key) > -1 ||
                        topic.Instructions.toLowerCase().indexOf(key) > -1
                    ) {
                        // keyword is in topic, so don't filter it out
                        topic.IsFilteredOut = false;
                        categoryFilteredOut = false;
                    } else {
                        // keyword is not in topic, so filter it out
                        topic.IsFilteredOut = true;
                    }
                });
            }
            category.IsFilteredOut = categoryFilteredOut ? true : false;
            categoryFilteredOut = true;
        });

        this.initPaging(); // recalculate pages (so we aren't only searching the current page)
    }

    clearFilter() {
        this.survey.Categories.forEach((category) => {
            category.IsFilteredOut = false;
            category.Topics.forEach((topic) => {
                topic.IsFilteredOut = false;
            });
        });
        this.filter.keyWords = '';
    }

    toggleBulkResponder() {
        this.showBulkResponder = !this.showBulkResponder;
    }

    createWordResponseForm(p1: boolean) {}

    importWordResponseForm() {}

    generateResponseReport() {}

    generateITSwotReport() {}

    saveInForm(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.closeGuardModal.hide();
            this.notificationService.toast.info('Processing', 'Saving Survey.');
            this.isSaving = true;

            let req = new InformRequest();

            req.InformName = this.informName;
            req.InformSurvey = this.survey;
            req.SiteName = this.siteName;
            req.UpdateGdprModel = true;
            req.IsSaveAndReturn = false;

            this.informService
                .saveInformByName(req)
                .then((res) => {
                    if (res) {
                        this.notificationService.toast.success('Success', 'Survey was saved.');
                        this.isSaving = false;
                        this.isFormDirty = false;

                        this.siteDashboardService.setDashboardItem(
                            this.siteId,
                            'TechnicalReviewWorksheets'
                        );
                        resolve('saved');
                    }
                })
                .catch(this.error);
        });
    }

    renewFormLock(tick) {
        if (!this.survey.IsReadOnly) {
            this.informService.renewInformLock(this.siteName, this.informName).then((relres) => {
                // do nothing
            });
        }
    }

    closeInForm() {}

    closeInFormAndReturn() {
        if (!this.isFormDirty) {
            this.isSaving = true; //not really saving, just for the loading classes
            this.informService.releaseInform(this.siteName, this.informName).then((relres) => {
                if (this.previousAlertUrl) {
                    this.router.navigateByUrl(this.previousAlertUrl);
                } else {
                    this.router.navigate(['../'], { relativeTo: this.route });
                }
                this.isSaving = false;
            });
        } else {
            if (this.previousAlertUrl) {
                this.router.navigateByUrl(this.previousAlertUrl);
            } else {
                this.router.navigate(['../'], { relativeTo: this.route });
            }
        }
    }

    checkRequiredBeforeClosing() {
        if (this.totalRequiredRemaining) {
            this.closeGuardModal.show();
        } else {
            this.saveAndCloseInForm();
        }
    }

    saveAndCloseInForm() {
        this.notificationService.toast.info('Processing', 'Saving Survey.');
        this.isSaving = true;
        let req = new InformRequest();
        req.InformName = this.informName;
        req.InformSurvey = this.survey;
        req.SiteName = this.siteName;
        req.UpdateGdprModel = true;
        req.IsSaveAndReturn = true;

        this.informService
            .saveInformByName(req)
            .then((res) => {
                if (res) {
                    this.notificationService.toast.success('Success', 'Survey was saved.');
                    this.isFormDirty = false;
                    this.siteDashboardService.setDashboardItem(
                        this.siteId,
                        'TechnicalReviewWorksheets'
                    );
                    this.closeInFormAndReturn();
                }
            })
            .catch(this.error);
    }

    expandAll() {
        const accordions = document.querySelectorAll('.ui.accordion');
        for (let i = 0; i < accordions.length; i++) {
            accordions[i].querySelector('.title').classList.add('active');
            accordions[i].querySelector('.content').classList.add('active');
            try {
                accordions[i].querySelector('.accordion-content').classList.remove('hidden');
            } catch (e) {}
        }
        return false;
    }

    collapseAll() {
        const accordions = document.querySelectorAll('.ui.accordion');
        for (let i = 0; i < accordions.length; i++) {
            accordions[i].querySelector('.title').classList.remove('active');
            accordions[i].querySelector('.content').classList.remove('active');
        }
        return false;
    }

    getQuestionsRemainingForCategory(category: Category) {
        category.viewModel.questionsRemaining = [];
        for (var i = 0; i < category.Topics.length; i++) {
            this.getQuestionsRemainingForTopic(category.Topics[i], category);
        }

        // Calculate the required remaining based on how many remaining questions are mandatory
        var requiredRemaining = 0;
        for (i = 0; i < category.viewModel.questionsRemaining.length; i++) {
            if (category.viewModel.questionsRemaining[i].IsMandatory) {
                requiredRemaining++;
            }
        }
        category.viewModel.requiredRemaining = requiredRemaining;
        category.viewModel.isCompleted = requiredRemaining == 0;
        return category.viewModel.questionsRemaining.length;
    }

    getTopicFollowUps(topic: Topic) {
        // if it hasn't been initialized, return an empty list
        if (!topic || !topic.viewModel) {
            return [];
        }

        // This returns a list of follow-up topics that are visible
        if (topic.viewModel.showBlankResponseFollowUpTopics) {
            return topic.BlankResponseFollowUpTopics;
        } else if (topic.viewModel.showNotBlankResponseFollowUpTopics) {
            return topic.NotBlankResponseFollowUpTopics;
        } else if (topic.viewModel.showCheckedFollowUpTopics) {
            return topic.CheckedFollowUpTopics;
        } else if (topic.viewModel.showUncheckedFollowUpTopics) {
            return topic.UncheckedFollowUpTopics;
        } else if (topic.viewModel.showChoiceFollowUp) {
            var choiceIndex = topic.viewModel.choiceIndex;
            if (choiceIndex > -1) {
                return topic.Choices[choiceIndex].FollowUpTopics;
            }
            return [];
        } else {
            // if there aren't any follow-ups, return an empty list
            return [];
        }
    }

    getQuestionsRemainingForTopic(topic: Topic, parentCategory: Category) {
        var index = parentCategory.viewModel.questionsRemaining.indexOf(topic);

        if (topic.viewModel == null) {
            topic.viewModel = new TopicViewModel();
            topic.viewModel.hasPreviousResponse = false;
            topic.viewModel.previousResponseDisplay = '';
            topic.viewModel.showPreviousResponse = false;
            topic.viewModel.isValid = true;
        }

        if (!topic.viewModel.isValid) {
            // Make sure it doesn't already exist
            if (index < 0) {
                parentCategory.viewModel.questionsRemaining.push(topic);
            }
        } else if (index >= 0) {
            // If it's in the list, but now it's complete, remove it from the parent
            parentCategory.viewModel.questionsRemaining.splice(index, 1);
        }

        // Check follow-ups
        var followUpTopics = this.getTopicFollowUps(topic);
        for (var i = 0; i < followUpTopics.length; i++) {
            this.getQuestionsRemainingForTopic(followUpTopics[i], parentCategory);
        }
    }

    checkAllAdditionalFollowUpConditions() {
        try {
            for (let category of this.survey.Categories) {
                for (let topic of category.Topics) {
                    this.checkTopicAdditionalFollowUpConditionsRecurse(topic);
                }
            }
        } catch (e) {}
    }

    checkTopicAdditionalFollowUpConditionsRecurse(topic: Topic) {
        try {
            this.checkAdditionalFollowUpConditions(topic);
            var followUpTopics = this.getAllTopicFollowUps(topic);
            for (var i = 0; i < followUpTopics.length; i++) {
                this.checkTopicAdditionalFollowUpConditionsRecurse(followUpTopics[i]);
            }
        } catch (e) {}
    }

    handleTimeout() {
        //console.log('timed out from parent');
        this.informService.releaseInform(this.siteName, this.informName).then((relres) => {
            // change survey to read only so they can't sneakily remove the modal blocking things and save
            this.isFormDirty = false;
            this.survey.IsReadOnly = true;
            //console.log('Survey released due to inactivity.');
        });
    }
    handleTimeoutEnd() {
        // Added this as a second function because we have the timeout component send a timeout
        // notice so we can release the inform, and then leave the logged out modal up until
        // the user clicks out of it and it'll then bring them back to the sites page.
        //console.log('Final function of the timeoutscript');
        this.router.navigate(['/']);
    }

    checkAdditionalFollowUpConditions(topic: Topic) {
        try {
            if (!topic) {
                return;
            }
            if (
                !topic.AdditionalFollowupConditions ||
                topic.AdditionalFollowupConditions.Conditions.length == 0
            ) {
                topic.viewModel.hasAdditionalFollowUp = false;
                return;
            }

            var followUpConditions = topic.AdditionalFollowupConditions;
            // The view will evaluate additional follow-ups if one or more exists
            topic.viewModel.hasAdditionalFollowUp = followUpConditions.Conditions.length > 0;
            // MatchAny=0, MatchAll=1
            var matchAll = followUpConditions.MatchType == 1;
            // If matchAll is true, initialize to true since we set showAdditionalFollowUp = conditionMatch && showAdditionalFollowUp
            // If matchAll is false, initialize to false since we set showAdditionalFollowUp = conditionMatch || showAdditionalFollowUp
            topic.viewModel.showAdditionalFollowUp =
                matchAll && topic.viewModel.hasAdditionalFollowUp;
            for (var i = 0; i < followUpConditions.Conditions.length; i++) {
                var condition = followUpConditions.Conditions[i];
                var conditionMatch = false;

                var matchingTopic =
                    this.topicDictionary[condition.CategoryName + ':' + condition.TopicId];
                if (!matchingTopic) {
                    continue;
                }

                // Evaluate the match value
                if (condition.MatchValue == 0) {
                    // match blank
                    conditionMatch =
                        (matchingTopic.response == '' || matchingTopic.response == undefined) ==
                        condition.ConditionEquals;
                } else if (condition.MatchValue == 1) {
                    // match not blank
                    conditionMatch =
                        (matchingTopic.response != undefined &&
                            matchingTopic.response.length > 0) == condition.ConditionEquals;
                } else if (condition.MatchValue == 2) {
                    // match checked
                    conditionMatch =
                        (matchingTopic.response != undefined &&
                            matchingTopic.response.toLowerCase() == 'checked') ==
                        condition.ConditionEquals;
                } else if (condition.MatchValue == 3) {
                    // match not checked
                    conditionMatch =
                        (matchingTopic.response != undefined &&
                            matchingTopic.response.toLowerCase() == 'unchecked') ==
                        condition.ConditionEquals;
                } else if (condition.MatchValue == 4) {
                    // match choice
                    for (var j = 0; j < condition.Values.length; j++) {
                        if (
                            condition.Values[j] != undefined &&
                            matchingTopic.response != undefined &&
                            condition.Values[j].toLowerCase() ==
                                matchingTopic.response.toLowerCase()
                        ) {
                            conditionMatch = true;
                            break;
                        }
                    }
                }

                if (matchAll) {
                    // Can't use shorthand notation &= (it's bitwise only)
                    topic.viewModel.showAdditionalFollowUp =
                        topic.viewModel.showAdditionalFollowUp && conditionMatch;
                    // If any of the condtions are false and the condition is MatchAll,
                    // we don't need to evaluate the other rules
                    if (!conditionMatch) {
                        break;
                    }
                } else {
                    // Can't use shorthand notation |= (it's bitwise only)
                    topic.viewModel.showAdditionalFollowUp =
                        topic.viewModel.showAdditionalFollowUp || conditionMatch;
                    // If any of the conditions are true and the condition MatchAny,
                    // we don't need to evaluate the other rules
                    if (conditionMatch) {
                        break;
                    }
                }
            }
        } catch (e) {}
    }

    getAllTopicFollowUps(topic: Topic) {
        // This returns a list of all follow-up topics including ones that are not visible
        var followUpTopics = [];
        try {
            if (topic.BlankResponseFollowUpTopics && topic.BlankResponseFollowUpTopics.length > 0) {
                followUpTopics = followUpTopics.concat(topic.BlankResponseFollowUpTopics);
            }
            if (
                topic.NotBlankResponseFollowUpTopics &&
                topic.NotBlankResponseFollowUpTopics.length > 0
            ) {
                followUpTopics = followUpTopics.concat(topic.NotBlankResponseFollowUpTopics);
            }
            if (topic.CheckedFollowUpTopics && topic.CheckedFollowUpTopics.length > 0) {
                followUpTopics = followUpTopics.concat(topic.CheckedFollowUpTopics);
            }
            if (topic.UncheckedFollowUpTopics && topic.UncheckedFollowUpTopics.length > 0) {
                followUpTopics = followUpTopics.concat(topic.UncheckedFollowUpTopics);
            }
            if (topic.viewModel.choiceIndex > -1) {
                followUpTopics = followUpTopics.concat(
                    topic.Choices[topic.viewModel.choiceIndex].FollowUpTopics
                );
            }
        } catch (e) {}

        return followUpTopics;
    }

    getQuestionsRemaining() {
        var questionsRemaining = [];
        try {
            for (let category of this.survey.Categories) {
                this.getQuestionsRemainingForCategory(category);

                questionsRemaining = questionsRemaining.concat(
                    category.viewModel.questionsRemaining
                );
            }
            this.questionsRemaining = questionsRemaining;

            // Calculate the required remaining based on how many remaining questions are mandatory
            var requiredRemaining = [];
            for (var i = 0; i < questionsRemaining.length; i++) {
                if (questionsRemaining[i].IsMandatory) {
                    requiredRemaining.push(this.questionsRemaining[i]);
                }
            }
            this.requiredRemaining = requiredRemaining;
            this.totalRequiredRemaining = requiredRemaining.length;
            // this.ref.detectChanges(); // removed because it was messing up flexgrids and somehow doubling the columns, and i don't know what it was needed for in the first place.
        } catch (e) {
            this.error(e);
        }
        return this.questionsRemaining.length;
    }

    categoryChanged() {
        /*
    try {
        this.category = this.survey.Categories[this.currentCategory ];
        this.displayedTopics = this.category.Topics;
    } catch (e) {
        this.error(e);
    }
    */
    }

    applyBulkEntry() {
        // If both the response and note are empty, notify the user that they need to be filled out
        if (
            (!this.bulkResponder.response || this.bulkResponder.response.length == 0) &&
            (!this.bulkResponder.note || this.bulkResponder.note.length == 0) &&
            (!this.bulkResponder.respondent || this.bulkResponder.respondent.length == 0)
        ) {
            // toastr.info('Enter a response, note, or respondent');
            return;
        }

        // Make sure they've selected at least one topic
        if (!this.filter.topics || this.filter.topics.length == 0) {
            // toastr.info('Select one or more topics');
            return;
        }

        if (this.bulkResponder.hasError) {
            // toastr.info('Please ensure that all of the selected topics have the same response type');
            return;
        }

        let topicsUpdated: number = 0;
        for (var i = 0; i < this.filter.topics.length; i++) {
            // Only apply to topics that are checked for bulk response (or the selected topic from the DropDown box)
            if (this.filter.topics[i].viewModel.bulkResponseChecked) {
                topicsUpdated = this.applyBulkEntryToTopic(this.filter.topics[i], topicsUpdated);
            }
        }

        if (topicsUpdated == 0) {
            // toastr.info('Unable to update topics using the provided response');
        } else if (topicsUpdated == 1) {
            // toastr.success('1 topic has been updated');
            // Mantis 2437: Clear selected questions after applying bulk entry
            this.bulkResponderSelectNone();
        } else {
            // toastr.success(topicsUpdated + ' topics have been updated');
            // Mantis 2437: Clear selected questions after applying bulk entry
            this.bulkResponderSelectNone();
        }
    }

    bulkResponderSelectNone() {
        for (var i = 0; i < this.survey.Categories.length; i++) {
            // Only select categories if there hasn't been a filter applied
            // or if the category has a child that's filtered
            if (!this.filter.isApplied || this.survey.Categories[i].viewModel.hasChildFiltered) {
                this.survey.Categories[i].viewModel.bulkResponseChecked = false;
                this.bulkResponderCategoryChecked(this.survey.Categories[i], false);
            }
        }

        // Update once at the end
        this.updateBulkResponderResponseType();
    }

    bulkResponderSelectAll() {
        for (var i = 0; i < this.survey.Categories.length; i++) {
            // Only select categories if there hasn't been a filter applied
            // or if the category has a child that's filtered
            if (!this.filter.isApplied || this.survey.Categories[i].viewModel.hasChildFiltered) {
                this.survey.Categories[i].viewModel.bulkResponseChecked = true;
                this.bulkResponderCategoryChecked(this.survey.Categories[i], true);
            }
        }

        // Update once at the end
        this.updateBulkResponderResponseType();
    }

    bulkResponderCategoryChecked(category: Category, updateResponseType: boolean) {
        if (!category) {
            return;
        }

        var isChecked = category.viewModel.bulkResponseChecked;
        for (var i = 0; i < category.Topics.length; i++) {
            this.bulkResponderChangeTopicChecked(category.Topics[i], isChecked, false);
            // If the category isn't expanded and we've checked it, auto expand it
            if (isChecked && !category.viewModel.isExpanded) {
                category.viewModel.isExpanded = true;
            }
        }

        // Only update the bulk responder response type if specified
        if (updateResponseType) {
            this.updateBulkResponderResponseType();
        }
    }

    updateBulkResponderResponseType() {
        try {
            this.bulkResponder.responseStyle = -1;
            this.bulkResponder.hasError = false;
            for (var i = 0; i < this.filter.topics.length; i++) {
                // We don't want to compare items that aren't checked
                if (!this.filter.topics[i].viewModel.bulkResponseChecked) {
                    continue;
                }

                if (this.bulkResponder.responseStyle == -1) {
                    this.bulkResponder.responseStyle = this.filter.topics[i].Style;
                } else if (
                    (this.bulkResponder.responseStyle == 2 && this.filter.topics[i].Style == 0) ||
                    (this.bulkResponder.responseStyle == 0 && this.filter.topics[i].Style == 2)
                ) {
                    // Treat the None and FreeForm as same style
                    continue;
                } else if (this.bulkResponder.responseStyle != this.filter.topics[i].Style) {
                    // Topic response style didn't match--set to -1 and break out
                    this.bulkResponder.responseStyle = -1;
                    this.bulkResponder.hasError = true;
                    break;
                }

                if (this.bulkResponder.responseStyle == 1) {
                    // If it's multiple choice, we need to verify that the choices are identical
                    if (this.bulkResponder.responseChoices.length == 0) {
                        // Add entries to new list
                        for (var j = 0; j < this.filter.topics[i].Choices.length; j++) {
                            this.bulkResponder.responseChoices.push(
                                this.filter.topics[i].Choices[j].Label
                            );
                        }
                    } else {
                        // Verify that the responses are identical
                        if (
                            this.bulkResponder.responseChoices.length !=
                            this.filter.topics[i].Choices.length
                        ) {
                            this.bulkResponder.responseStyle = -1;
                            this.bulkResponder.hasError = true;
                        } else {
                            for (var j = 0; j < this.filter.topics[i].Choices.length; j++) {
                                if (
                                    this.bulkResponder.responseChoices[j].toLowerCase() !=
                                    this.filter.topics[i].Choices[j].Label.toLowerCase()
                                ) {
                                    this.bulkResponder.responseStyle = -1;
                                    this.bulkResponder.hasError = true;
                                    break;
                                }
                            }
                        }
                    }
                }
            }

            // If the response style is invalid, remove any exists response choices
            if (this.bulkResponder.responseStyle == -1) {
                this.bulkResponder.response = '';
                this.bulkResponder.responseChoices = [];
            }
        } catch (e) {
            this.error(e);
        }
    }

    applyBulkEntryToTopic(topic: Topic, topicsUpdated: number) {
        var topicUpdated = false;
        // Update the note if one was entered
        if (this.bulkResponder.note && this.bulkResponder.note.length > 0) {
            topic.Note = this.bulkResponder.note;
            topicUpdated = true;
            this.topicNotesChanged(topic, false);
        }

        // Update the respondent if one was entered
        if (this.bulkResponder.respondent && this.bulkResponder.respondent.length > 0) {
            topic.Respondent = this.bulkResponder.respondent;
            topicUpdated = true;
            this.topicRespondentChanged(topic, false);
        }

        if (topic.Style == 1) {
            // Multiple Choice
            var updatedChoiceIndex = -1;
            for (var i = 0; i < topic.Choices.length; i++) {
                if (
                    topic.Choices[i].Label.toLowerCase() ==
                    this.bulkResponder.response.toLowerCase()
                ) {
                    topic.Response = topic.Choices[i].Label;
                    updatedChoiceIndex = i;
                    topicUpdated = true;
                    break;
                }
            }
            if (updatedChoiceIndex > -1) {
                for (var i = 0; i < topic.Choices.length; i++) {
                    topic.Choices[i].Selected = i == updatedChoiceIndex;
                }
            }
        } else if (
            (topic.Style == 2 || topic.Style == 0) &&
            this.bulkResponder.response &&
            this.bulkResponder.response.length > 0
        ) {
            // FreeForm -- Only allow populated responses
            topic.Response = this.bulkResponder.response;
            topicUpdated = true;
        } else if (topic.Style == 3) {
            // CheckList -- only match the words "checked" and "unchecked"
            if (this.bulkResponder.response.toLowerCase() == 'checked') {
                topic.Response = 'Checked';
                topicUpdated = true;
            } else if (this.bulkResponder.response.toLowerCase() == 'unchecked') {
                topic.Response = 'Unchecked';
                topicUpdated = true;
            }
        }

        if (topicUpdated) {
            this.topicsUpdated++;
            this.validateTopic(topic);
        }
        return topicsUpdated;
    }

    bulkResponderChangeTopicChecked(topic: Topic, isChecked: boolean, updateResponseType: boolean) {
        if (!topic) {
            return;
        }

        // Only update the topic if a filter hasn't been applied
        // or if the topic is filtered (when filter has been applied)
        if (!this.filter.isApplied || topic.viewModel.filtered) {
            topic.viewModel.bulkResponseChecked = isChecked;
            this.topicBulkResponseCheckChanged(topic, updateResponseType);
        }

        // Only check follow-ups if a filter hasn't been applied
        // or if the topic has a child that's filtered (when filter has been applied)
        if (!this.filter.isApplied || topic.viewModel.hasChildFiltered) {
            var followUpTopics = this.getTopicFollowUps(topic);
            for (var i = 0; i < followUpTopics.length; i++) {
                this.bulkResponderChangeTopicChecked(
                    followUpTopics[i],
                    isChecked,
                    updateResponseType
                );
            }
        }
    }

    topicBulkResponseCheckChanged(topic: Topic, updateResponseType: boolean) {
        let index = -1;
        if (this.filter.topics != null) {
            index = this.filter.topics.indexOf(topic);
        }
        if (topic.viewModel.bulkResponseChecked) {
            // Add it to the list (if it doesn't already exist)
            if (index < 0) {
                this.filter.topics.push(topic);
            }
        } else {
            // Remove it from the list (if it exists)
            if (index > -1) {
                this.filter.topics.splice(index, 1);
            }
        }

        if (updateResponseType === true) {
            this.updateBulkResponderResponseType();
        }
    }

    validateTopic(topic: Topic) {
        try {
            var requiresValidation = false;
            if (topic.Hidden) {
                // Currently we're marking hidden topics as valid since they can't be answered if they're hidden
                topic.viewModel.isValid = true;
            } else if (topic.Style == 1) {
                // MultipleChoice
                var choiceIndex = -1;
                for (var i = 0; i < topic.Choices.length; i++) {
                    if (topic.Choices[i].Label == topic.Response) {
                        choiceIndex = i;
                        topic.Choices[i].Selected = true;
                        break;
                    }
                }
                if (choiceIndex > -1) {
                    for (var i = 0; i < topic.Choices.length; i++) {
                        topic.Choices[i].Selected = i == choiceIndex;
                    }
                }

                // Any choice change should force a full validation
                requiresValidation = true;
                topic.viewModel.isValid = topic.Response != undefined && choiceIndex > -1;
            } else if (topic.Style == 2 || topic.Style == 0) {
                // FreeForm (or None)
                var previouslyValid = topic.viewModel.isValid;
                topic.viewModel.isValid = topic.Response != undefined && topic.Response.length > 0;
                // We only need to do a full form validation if the validation changed (not text changed)
                requiresValidation = previouslyValid != topic.viewModel.isValid;
            } else if (topic.Style == 3) {
                // CheckList (should be "Checked" or "Unchecked")
                topic.viewModel.isValid = topic.Response != undefined && topic.Response.length > 0;
                if (topic.viewModel.isValid && topic.Response.toLowerCase() === 'checked') {
                    topic.Checked = true;
                } else {
                    topic.Checked = false;
                }

                // Any checkbox change should force a full validation
                requiresValidation = true;
            } else if (topic.Style == 4) {
                // Now validating flexgrid sometimes.
                if (!this.survey.IsLocked && !this.survey.IsReadOnly) {
                    topic.viewModel.isValid = true;
                    topic.Table.Columns.forEach((col) => {
                        if (col.isValid == false) {
                            topic.viewModel.isValid = false;
                        }
                    });

                    requiresValidation = true; // I think we need this to get required remaining
                }
            }

            topic.viewModel.hasError = topic.IsMandatory && !topic.viewModel.isValid;

            // Re-check for any follow up topics and set the viewModel flag
            // This needs doing before we get the questions remaining (since they use view model flags)
            this.checkBlankResponseFollowUpTopics(topic);
            this.checkNotBlankResponseFollowUpTopics(topic);
            this.checkCheckedFollowUpTopics(topic);
            this.checkUncheckedFollowUpTopics(topic);
            this.checkChoiceIndex(topic);

            // Only validate if we're done initializing and the validation state changed
            if (requiresValidation) {
                this.getQuestionsRemaining();

                // We need to check all additional follow-ups since
                // this could be another topic's additional follow-up condition
                this.checkAllAdditionalFollowUpConditions();
            }
            /*
      this.scope.hasChanges = true;
      */
        } catch (e) {
            //console.log("validate topic error: " + e);
        }
    }

    checkBlankResponseFollowUpTopics(topic: Topic) {
        topic.viewModel.showBlankResponseFollowUpTopics =
            (topic.Style == 2 || topic.Style == 0) &&
            (topic.Response == undefined || topic.Response.length == 0) &&
            topic.BlankResponseFollowUpTopics != undefined &&
            topic.BlankResponseFollowUpTopics.hasOwnProperty('length') &&
            topic.BlankResponseFollowUpTopics.length > 0;
    }

    checkNotBlankResponseFollowUpTopics(topic: Topic) {
        topic.viewModel.showNotBlankResponseFollowUpTopics =
            (topic.Style == 2 || topic.Style == 0) &&
            topic.Response != undefined &&
            topic.Response.length > 0 &&
            topic.NotBlankResponseFollowUpTopics != undefined &&
            topic.NotBlankResponseFollowUpTopics.hasOwnProperty('length') &&
            topic.NotBlankResponseFollowUpTopics.length > 0;
    }

    checkCheckedFollowUpTopics(topic: Topic) {
        topic.viewModel.showCheckedFollowUpTopics =
            topic.Style == 3 &&
            topic.Response == 'Checked' &&
            topic.CheckedFollowUpTopics != undefined &&
            topic.CheckedFollowUpTopics.hasOwnProperty('length') &&
            topic.CheckedFollowUpTopics.length > 0;
    }

    checkUncheckedFollowUpTopics(topic: Topic) {
        topic.viewModel.showUncheckedFollowUpTopics =
            topic.Style == 3 &&
            topic.Response == 'Unchecked' &&
            topic.UncheckedFollowUpTopics != undefined &&
            topic.UncheckedFollowUpTopics.hasOwnProperty('length') &&
            topic.UncheckedFollowUpTopics.length > 0;
    }

    checkChoiceIndex(topic: Topic) {
        topic.viewModel.showChoiceFollowUp = false;
        topic.viewModel.choiceIndex = -1;
        if (topic.Style != 1) {
            return;
        }

        for (var i = 0; i < topic.Choices.length; i++) {
            if (topic.Choices[i].Label == topic.Response) {
                topic.viewModel.showChoiceFollowUp = true;
                topic.viewModel.choiceIndex = i;
                return;
            }
        }
    }

    topicNotesChanged(topic: Topic, validateTopic: boolean) {
        this.isFormDirty = true;
        topic.viewModel.hasNotes = topic.Note != undefined && topic.Note.length > 0;
        if (validateTopic) {
            this.validateTopic(topic);
        }
    }

    topicRespondentChanged(topic: Topic, validateTopic: boolean) {
        this.isFormDirty = true;
        topic.viewModel.hasRespondent =
            topic.Respondent != undefined && topic.Respondent.length > 0;
        if (validateTopic) {
            this.validateTopic(topic);
        }
    }

    download() {
        this.notificationService.toast.info('Retrieving', 'Retrieving Worksheet From Server');

        let assessmentName: string = '__current';
        if (this.selectedAssessment != undefined && this.selectedAssessment.Name != undefined) {
            assessmentName = this.selectedAssessment.Name;
        }

        if (this.isInArchived) {
            this.informService
                .downloadArchivedTechnicalReviewDocx(
                    this.site.Id,
                    this.selectedArchivedAssessment.AssessmentGuid,
                    this.informName,
                    this.populateResponse
                )
                .then((b64) => {
                    this.createDocx(b64);
                })
                .catch((err) => {
                    console.error(err);
                });
        } else {
            this.informService
                .downloadFormDocx(
                    this.siteId,
                    assessmentName,
                    this.informName,
                    this.populateResponse
                )
                .then((b64) => {
                    this.createDocx(b64);
                })
                .catch((err) => {
                    console.error(err);
                });
        }
        return false;
    }

    createDocx(b64: string) {
        let raw = window.atob(b64);

        let bytes = new Uint8Array(raw.length);
        for (let i = 0; i < raw.length; i++) {
            bytes[i] = raw.charCodeAt(i);
        }
        let blob = new Blob([bytes]);

        let _sExt: string = 'docx';
        if (
            this.survey.Name == 'Asset Inventory Worksheet' ||
            this.survey.Name == 'User Access Review Worksheet' ||
            this.survey.Name == 'Local Computer User Access Review Worksheet' ||
            this.survey.Name == 'External Port Use Worksheet' ||
            this.survey.Name == 'NIST Antivirus Verification Worksheet' ||
            this.survey.Name == 'NIST Asset Inventory Worksheet' ||
            this.survey.Name == 'NIST External Information System Worksheet' ||
            this.survey.Name == 'NIST User Access Review Worksheet' ||
            this.survey.Name == 'NIST Application Inventory Worksheet' ||
            this.survey.Name == 'Antivirus Verification Worksheet' ||
            this.survey.Name == 'Application Inventory Worksheet' ||
            this.survey.Name == 'Azure Enterprise Application Inventory Worksheet' ||
            this.survey.Name == 'External Information System Worksheet' ||
            this.survey.Name == 'File Scan System Selection Worksheet' ||
            this.survey.Name == 'File Share Identification Worksheet' ||
            this.survey.Name == 'Sensitive Data Assessment Worksheet' ||
            this.survey.Name == 'Datto Workplace Sensitive Data Assessment Worksheet'
        ) {
            _sExt = 'xlsx';
        }

        saveAs(blob, this.siteName + ' - ' + this.survey.Name + '.' + _sExt);
    }

    inviteModalLoading(val) {
        this.inviteButtonLoading = val;
    }

    copyAllPreviousTopicResponses(topic: Topic) {
        if (topic.PreviousAnswer) {
            topic.Response = topic.PreviousAnswer;
            topic.Note = topic.PreviousNote;
            topic.Respondent = topic.PreviousRespondent;
            this.validateTopic(topic);
        }
        for (let choice of topic.Choices) {
            for (let followUp of choice.FollowUpTopics) {
                this.copyAllPreviousTopicResponses(followUp);
            }
        }
    }

    copyAllPreviousResponses() {
        for (let category of this.survey.Categories) {
            for (let topic of category.Topics) {
                this.copyAllPreviousTopicResponses(topic);
            }
        }
        this.notificationService.toast.success('', 'Form updated');
        return false;
    }

    copyPreviousTable() {
        this.informService.AnnounceMergedTable(this.mergedTableData);
    }

    getLocalizedColumnHeader(col: any) {
        let rValue: string = col.Header;

        if (col.HeaderLocalized) {
            if (col.HeaderLocalized[this.culture] && col.HeaderLocalized[this.culture].length > 0) {
                rValue = col.HeaderLocalized[this.culture];
            }
        }

        return rValue;
    }

    getLocalizedColumnChoices(col: any) {
        let rValue: any[] = [];

        if (col.ChoicesLocalized && col.ChoicesLocalized.length > 0) {
            if (
                col.ChoicesLocalized[this.culture] &&
                col.ChoicesLocalized[this.culture].length > 0
            ) {
                var i: number;
                for (i = 0; i < col.Choices.length; i++) {
                    let nv: any;
                    if (col.ChoicesLocalized[this.culture].length >= i - 1) {
                        nv = {
                            choiceName: col.ChoicesLocalized[this.culture][i],
                            choiceValue: col.Choices[i],
                        };
                    } else {
                        nv = { choiceName: col.Choices[i], choiceValue: col.Choices[i] };
                    }
                    rValue.push(nv);
                }
            }
        } else {
            for (i = 0; i < col.Choices.length; i++) {
                let nv: any = { choiceName: col.Choices[i], choiceValue: col.Choices[i] };
                rValue.push(nv);
            }
        }

        return rValue;
    }

    loadTableColumns(gridColumns: ColumnProxy[], topicTable: InformTable) {
        let index = 0;
        for (let column of topicTable.Columns) {
            let cp: ColumnProxy = new ColumnProxy();
            cp.header = this.getLocalizedColumnHeader(column);
            cp.isReadOnly = false;
            if (column.Width) {
                cp.width = column.Width;
            }
            if (column.ColumnStyle == ColumnStyle.MultipleChoice) {
                cp.choices = [];
                for (let choice of this.getLocalizedColumnChoices(column)) {
                    cp.choices.push({
                        choiceName: choice.choiceName,
                        choiceValue: choice.choiceValue,
                    });
                }
                cp.choiceMap = new DataMap(cp.choices, 'choiceValue', 'choiceName');
                cp.showDropDown = true;
                cp.class = 'dropdownCell';
            } else if (column.ColumnStyle == ColumnStyle.ReadOnly) {
                cp.isReadOnly = true;

                let columnMap = [];
                for (let row of topicTable.Rows) {
                    var itc = row.Cells[index];
                    if (
                        itc.ValueLocalized &&
                        itc.ValueLocalized[this.culture] &&
                        itc.ValueLocalized[this.culture].length > 0
                    ) {
                        columnMap.push({
                            choiceName: itc.ValueLocalized[this.culture],
                            choiceValue: itc.Value,
                        });
                    }
                }
                if (columnMap.length > 0) {
                    cp.choiceMap = new DataMap(columnMap, 'choiceValue', 'choiceName');
                }
            } else if (column.ColumnStyle == ColumnStyle.CheckBox) {
                cp.isCheckBox = true;
            }

            if (this.survey.IsReadOnly) {
                cp.isReadOnly = true;
            }

            cp.colId = 'col' + index;
            gridColumns.push(cp);
            index++;
        }
    }

    loadTableView(flexGrid: WjFlexGrid, isReadOnly: boolean, gridColumns: ColumnProxy[]) {
        if (flexGrid) {
            if (!isReadOnly) {
                flexGrid.allowAddNew = true;
                flexGrid.allowDelete = true;
            } else {
                // this.flex.isReadOnly = true;
            }
            flexGrid.autoClipboard = true;
            flexGrid.selectionMode = SelectionMode.CellRange;
            flexGrid.autoGenerateColumns = false;
            // this.flex.showMarquee = true;
            flexGrid.showSelectedHeaders = HeadersVisibility.All;
            flexGrid.deferResizing = true;
            flexGrid.autoSizeMode = AutoSizeMode.Both;
            flexGrid.allowResizing = AllowResizing.Both;
            flexGrid.rows.minSize = 28;

            let index: number = 0;

            if (gridColumns) {
                for (let column of gridColumns) {
                    let columnBinding: string = 'col' + index;
                    let wjColumn = <WjFlexGridColumn>flexGrid.columns[index];

                    if (column.showDropDown) {
                        wjColumn.dataMap = new DataMap(
                            gridColumns[index].choices,
                            'choiceValue',
                            'choiceName'
                        );
                    }
                    if (column.isReadOnly) {
                        wjColumn.isReadOnly = true;
                        flexGrid.allowAddNew = false;
                        wjColumn.wordWrap = true;
                        wjColumn.isContentHtml = true;

                        if (column.choiceMap) {
                            wjColumn.dataMap = column.choiceMap;
                        }
                    }
                    wjColumn.cssClass =
                        column.isReadOnly || this.survey.IsReadOnly ? 'isRO' : 'notRO';

                    if (column.isCheckBox) {
                        wjColumn.width = 50;
                    }

                    // some columns may need to have a specified width because the header text is larger than the column input
                    if (column.width && column.width > 0) {
                        wjColumn.width = column.width;
                    }

                    wjColumn.wordWrap = true;

                    index++;
                }
            }
        }
    }

    getLocalizedSurveyName() {
        let rValue: string = '';
        if (this.survey) {
            rValue = this.survey.Name;

            if (this.survey.NameLocalized) {
                if (
                    this.survey.NameLocalized[this.culture] &&
                    this.survey.NameLocalized[this.culture].length > 0
                ) {
                    rValue = this.survey.NameLocalized[this.culture];
                }
            }
        }
        return rValue;
    }

    getLocalizedCellValue(cell: any) {
        let rValue: string = cell.Value;

        if (cell.ValueLocalized) {
            if (cell.ValueLocalized[this.culture] && cell.ValueLocalized[this.culture].length > 0) {
                rValue = cell.ValueLocalized[this.culture];
            }
        }

        return rValue;
    }

    loadTableData(topicTable: InformTable, modelData: any[], tableTagData: any[]) {
        //modelData = [];
        if (topicTable && topicTable.Columns && topicTable.Rows) {
            for (let itr of topicTable.Rows) {
                let index = 0;
                let s: any = {};

                for (let itc of itr.Cells) {
                    let pname: string = 'col' + index;
                    s[pname] = itc.Value;

                    s[pname] = {
                        choiceValue: itc.Value,
                        choiceName: this.getLocalizedCellValue(itc),
                    };
                    s[pname] = this.getLocalizedCellValue(itc);

                    index++;
                }
                modelData.push(s);

                if (itr.Tags) {
                    tableTagData.push(itr.Tags);
                }
            }
        }
    }

    resizeFlexRows(grid) {
        setTimeout(() => {
            //console.log('resizeflexrows : ' + grid);
            if (grid) {
                var rng = grid.viewRange; // only autosize viewable rows
                //this.logObject(rng);
                //console.log('rng.topRow: ' + rng.row);
                //console.log('rng.bottomRow: ' + rng.row2);
                var row = grid.columnHeaders.rows[0];
                row.wordWrap = true;
                grid.autoSizeRow(0, true); // autosize header
                //grid.autoSizeRows(); // autosize all rows

                grid.autoSizeRows(rng.topRow, rng.bottomRow);
                //for (let i = rng.topRow; i <= rng.bottomRow; i++) {
                //    grid.autoSizeRow(i);
                //}
            }
        }, 100);
    }

    logObject(o: any) {
        for (var propName in o) {
            console.log(propName, o[propName]);
        }
    }

    toggleHelp(help) {
        if (this.helpOpenID == help.url && this.helpOpen) {
            this.closeHelp();
            return;
        }

        if (typeof help.url != 'undefined' && help.url != '') {
            if (
                (!this.helpOpen && this.helpOpenID !== help.url) ||
                (this.helpOpenID !== help.url && this.helpOpen)
            ) {
                this.sideDrawer.nativeElement.classList.add('helpOpen');
                this.helpLoadingComplete = false;
                this.helpIframeElement.style.display = 'block';
                this.helpOpenID = help.url;
                // using location.replace to stop the changes from being added to history
                this.helpIframeElement.contentWindow.location.replace(help.folder + help.url);
                this.helpDisplayed = true;
                setTimeout(() => {
                    if (document.getElementById(this.helpOpenID)) {
                        document
                            .getElementById(this.helpOpenID)
                            .scrollIntoView({ behavior: 'smooth', block: 'start' });
                    }
                    this.helpLoadingComplete = true;
                    this.helpOpen = true;
                }, 500);
            }
        }
    }

    closeHelp() {
        this.sideDrawer.nativeElement.classList.remove('helpOpen');
        this.helpOpen = false;
        this.helpOpenID = '';
        this.helpIframeElement.style.display = 'none';
        this.helpLoadingComplete = true;
    }

    checkForItglueOAuth(params: any) {
        // if code is sent in query params then Kaseya is trying to OAuth into our portal

        if (params['oas']) {
            this.itglueOauthReturnTopic = params['oas'];
            this.notificationService.toast.success('IT Glue', 'Temporary Connection Successful');
        }
    }

    ngOnDestroy() {
        this.aSub?.unsubscribe();
        this.pSub?.unsubscribe();
        //    this.sSub.unsubscribe();
        this.tSub?.unsubscribe();
        //$('.ui.modal').modal('destroy').remove();

        try {
            if (!this.survey.IsReadOnly) {
                this.informService.releaseInform(this.siteName, this.informName).then((relres) => {
                    //console.log('Success Trying to release Inform lock');
                    //console.log(relres);
                });
            } else {
                //console.log('Didn\'t try to release, because it was readonly');
            }
        } catch {}
        document.body.classList.remove('informPage');

        this.deleteModuleLock();
    }

    changeLanguage(event) {
        if (this.siteType != 'UKGDPR') {
            this.culture = event;
            this.languageCtrl.setValue(this.culture);
            //this.ngOnInit();
            //this.survey;

            var tmpTables: any[] = [];

            tmpTables.push(...this.topicTables);
            this.topicTables = [];

            for (let t of tmpTables) {
                setTimeout(() => {
                    t.ngOnInit();
                    setTimeout(() => {
                        t.ngAfterViewInit();
                        setTimeout(() => {
                            this.resizeFlexRows(t.flex);
                        }, 100);
                    }, 100);
                }, 100);
            }
        }
    }

    deleteModuleLock() {
        this.pageClosed = true;
        if (!this.lockedUser && !this.survey.IsReadOnly && this.siteService.isComplianceManagerGRC(this.site)) {
            this.grcService.deleteModuleLock(this.siteId, 'worksheets', this.lockGUID);
        }
    }

    checkLock() {
        this.grcService.getModuleLock(this.siteId, 'worksheets').then((lockUser) => {
            if (lockUser == null && !this.pageClosed) {
                this.grcService
                    .insertModuleLock(this.siteId, 'worksheets', this.lockGUID)
                    .then(() => {
                        if (this.pageClosed)
                            this.grcService.deleteModuleLock(
                                this.siteId,
                                'worksheets',
                                this.lockGUID
                            );
                    });
            } else {
                this.lockedUser = lockUser;
            }
        });
    }

    setDefaultBreadCrumb() {
        this.grcService.setBreadcrumbs([
            { path: '...', text: 'Compliance Manager GRC' },
            { path: '..', text: 'Archived Assessment' },
            { path: '.', text: 'Technical Review Worksheets' },
        ]);
    }

    setBreadCrumb() {
        this.grcService.setBreadcrumbs([
            { path: '....', text: 'Compliance Manager GRC' },
            { path: '...', text: 'Archived Assessment' },
            { path: './technical-review', text: 'Technical Review Worksheets', link: true },
            { path: '.', text: this.survey.Name },
        ]);
    }
}
