import { Directive, Input, Output, EventEmitter } from '@angular/core';

@Directive({
    selector: '[xInit]',
})
export class InitDirective {
    @Input() isLast: boolean;

    @Output('xInit') initEvent: EventEmitter<any> = new EventEmitter();

    ngOnInit() {
        setTimeout(() => this.initEvent.emit(), 1);
    }
}
