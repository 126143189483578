import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'splitCamelCase',
})
export class SplitCamelCasePipe implements PipeTransform {
    transform(value: string): string {
        return value
            .split(/([A-Z][a-z]+)/)
            .filter((e) => e.trim())
            .join(' ');
        // // this doesn't work with safari because of look behind
        // return value.replace(
        //     /([^\p{L}\d]+|(?<=\p{L})(?=\d)|(?<=\d)(?=\p{L})|(?<=[\p{Ll}\d])(?=\p{Lu})|(?<=\p{Lu})(?=\p{Lu}\p{Ll})|(?<=[\p{L}\d])(?=\p{Lu}\p{Ll}))/gu,
        //     ' '
        // );
    }
}
