import { Component, OnInit, OnDestroy } from '@angular/core';

import { AuthService, Roles, SiteRoles } from 'app/core/auth';
import { MessageService } from 'app/core/message.service';
import { Site } from 'app/sites/shared/site.model';
import { SITE, PING_SITE } from 'app/sites/shared/constants';

@Component({
    templateUrl: './site-settings.component.html',
    styleUrls: ['./site-settings.component.css'],
})
export class SiteSettingsComponent implements OnInit, OnDestroy {
    constructor(private authService: AuthService, private messageService: MessageService) {}

    sidebarMenu: any = [];

    subs = [];

    ngOnInit() {
        this.subs.push(
            this.messageService
                .on('SCAN_WIZARD_PAGE_CHANGE')
                .subscribe((pageNumber: number | null) => {
                    if (this.sidebarMenu.length) {
                        if (pageNumber === null) {
                            this.sidebarMenu[0].expanded = false;
                        } else {
                            this.sidebarMenu[0].expanded = true;

                            this.sidebarMenu[0].activeOverride = false;

                            for (let i = 0; i < this.sidebarMenu[0].children.length; i++) {
                                let child = this.sidebarMenu[0].children[i];
                                child.activeOverride = false;

                                // child.icon = (i < pageNumber - 1) ? 'green check circle' : null;
                            }

                            if (pageNumber === 0) {
                                this.sidebarMenu[0].activeOverride = true;
                            } else {
                                this.sidebarMenu[0].children[pageNumber - 1].activeOverride = true;
                            }
                        }
                    }
                })
        );

        this.subs.push(
            this.messageService.on(SITE).subscribe((site: Site) => {
                if (!site) return;

                if (
                    site.Type == 'GDPR' ||
                    site.Type == 'HIPAA' ||
                    site.Type == 'CYBERINSURANCE' ||
                    site.Type == 'NIST' ||
                    site.Type == 'CMMC' /*&& site.HasLicenseViolation*/
                )
                    this.sidebarMenu = [
                        {
                            href: 'advanced',
                            icon: 'cubes',
                            label: 'Advanced Options',
                        },
                    ];
                else this.sidebarMenu = this.buildSidebarMenu(site);
            })
        );

        this.messageService.broadcast(PING_SITE);
    }

    ngOnDestroy() {
        for (let sub of this.subs) {
            sub.unsubscribe();
        }
    }

    buildSidebarMenu(site: Site) {
        let menu = [];

        let indoc = site.IsIndoc;
        let gdpr =
            site.Type == 'GDPR' ||
            site.Type == 'HIPAA' ||
            site.Type == 'CYBERINSURANCE' ||
            site.Type == 'NIST' ||
            site.Type == 'CMMC';
        let ch = site.Type == 'standard';

        let user = this.authService.getIdentity();
        let roleId = parseInt(user.roleId);
        let siteRoles = this.authService.getCurrentSiteRoles();

        let hasAdminAccess =
            [Roles.Admin, Roles.Master].indexOf(roleId) > -1 ||
            siteRoles.indexOf(SiteRoles.SiteAdmin) > -1;

        if (gdpr && !indoc) {
            menu.push(
                {
                    href: 'scanwizard',
                    icon: 'settings',
                    label: 'Scan Settings',
                    class: 'wizardItems',
                    children: [
                        { label: 'Scan Hosts' },
                        { label: 'Merge Options' },
                        { label: 'Scan Credentials' },
                        { label: 'Local Domains' },
                        { label: 'Additional Credentials' },
                        { label: 'IP Ranges' },
                        { label: 'SNMP Information' },
                        { label: 'External Vulnerability' },
                        { label: 'Confirm' },
                    ],
                },
                {
                    href: 'scan-schedules',
                    icon: 'calendar',
                    label: 'Scan Schedules',
                }
            );
        }

        //if ((gdpr || ch) && !indoc) {
        //  menu.push({
        //    href: 'appliance',
        //    icon: 'disk',
        //    label: 'Appliance'
        //  });
        //}

        //if (hasAdminAccess) {
        //  menu.push(
        //    {
        //      href: 'users',
        //      icon: 'user',
        //      label: 'Users'
        //    },
        //    {
        //      href: 'roles',
        //      icon: 'users',
        //      label: 'Roles'
        //    }
        //  );
        //}

        if (gdpr && !indoc) {
            menu.push(
                {
                    href: 'reports',
                    icon: 'file alternate',
                    label: 'Report Settings',
                    children: [
                        {
                            label: 'Text',
                            href: 'reports/text',
                        },

                        {
                            label: 'My Logo',
                            href: 'reports/my-logo',
                        },
                        {
                            label: 'Theme',
                            href: 'reports/theme',
                        },
                        {
                            label: 'Cover Images',
                            href: 'reports/cover-images',
                        },
                        /* {
               label: 'Company Information',
               href: 'reports/company-information',
             }*/
                    ],
                },
                {
                    href: 'invites',
                    icon: 'paper plane',
                    label: 'Manage Invites',
                }
            );
        }

        //if ((gdpr || ch) && !indoc) {
        //  menu.push({
        //    href: 'admin-alerts',
        //    icon: 'mail',
        //    label: 'Admin Alerts'
        //  });
        //}
        if (indoc && hasAdminAccess) {
            menu.push({
                href: 'client-view',
                icon: 'user circle',
                label: 'Client View',
            });
        }

        //if (hasAdminAccess)
        //  menu.push({
        //    href: 'advanced',
        //    icon: 'cubes',
        //    label: 'Advanced Options'
        //  });

        //if (ch && !indoc) {
        //  menu.push({
        //    href: 'addons',
        //    icon: 'cubes',
        //    label: 'Add-ons'
        //  });
        //}

        return menu;
    }
}
