import { Component, EventEmitter, Input, Output, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ScanDataService, ScanDataUser } from 'app/scans';
import { SemanticInputComponent } from 'app/semantic-legacy/components/input/input';

import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'sds-data-search-filter',
    templateUrl: './data-search-filter.component.html',
    styleUrls: ['./data-search-filter.component.css'],
})
export class DataSearchFilterComponent implements OnInit {
    constructor() {}

    @Input() placeholder: string = 'Filter...';
    @Input() data: any[];
    @Input() fields: string[];
    @Output() filteredData = new EventEmitter<any[]>();
    @Output() searchBarText = new EventEmitter<string>();
    @ViewChild(SemanticInputComponent, { static: true }) semanticInput: SemanticInputComponent;
    searchKey: string;

    searchKeyCtrl: UntypedFormControl = new UntypedFormControl();

    ngOnInit() {
        this.searchKeyCtrl.valueChanges.pipe(debounceTime(420)).subscribe((value) => {
            this.searchKey = value;
            this.onFilter();
        });

        this.onFilter();
    }

    onFilter() {
        if (this.data) {
            let filtered = this.data;

            if (this.searchKey) {
                let key = this.searchKey.toLowerCase().replace(/^\s+/g, '').replace(/\s+$/g, '');
                filtered = filtered.filter((item) => {
                    for (let field of this.fields) {
                        if (
                            this.getByString(item, field) &&
                            this.getByString(item, field).toLowerCase().indexOf(key) > -1
                        )
                            //allows for passing deeper levels in fields array
                            return true;
                    }
                });
            }

            this.filteredData.emit(filtered);
            this.searchBarText.emit(this.searchKey);
        }
    }

    getByString(o: any, s: string) {
        s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        s = s.replace(/^\./, ''); // strip a leading dot
        var a = s.split('.');
        for (var i = 0, n = a.length; i < n; ++i) {
            var k = a[i];
            if (k in o) {
                o = o[k];
            } else {
                return;
            }
        }
        return o;
    }

    clearSearch() {
        this.searchKeyCtrl.setValue('', { emitEvent: false }); // Don't emit valueChange event
        // Do this manually to bypass debounce
        this.searchKey = '';
        this.semanticInput.control.setValue('');
        this.onFilter();
    }
}
