import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'status',
})
export class StatusPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        switch (value) {
            case 'expired':
                return 'Expired';
            case 'notstarted':
                return 'Needs Attention';
            case 'incomplete':
                return 'Incomplete';
            case 'complete':
                return 'Completed';

            default:
                return '';
        }
    }
}
