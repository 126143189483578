import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { combineLatest, Subject, Subscription, takeUntil } from 'rxjs';
import { PING_SITE, SITE } from 'app/sites/shared/constants';
import { Site } from 'app/sites/shared/site.model';
import { MessageService } from 'app/core/message.service';
import { SiteParam, SiteSettingService } from 'app/sites/site/settings/site-settings.service';
import { UiService } from 'app/core/ui.service';
import { AuthService } from 'app/core/auth';
import { KvsService } from 'app/sites/shared/kvs.service';
import { NotificationService } from '../../../../../shared/itc/notification/notification.service';

@Component({
    selector: 'kvs-it-complete',
    templateUrl: './kvs-it-complete.component.html',
    styleUrls: ['./kvs-it-complete.component.scss'],
})
export class KvsItCompleteComponent implements OnInit {
    kvsNotProvisioned: boolean = false;
    loadingComplete: boolean;
    site: Site;
    msgSub: Subscription;
    ngUnsubscribe: Subject<KvsItCompleteComponent> = new Subject();
    rcExportSaving: boolean;
    apikeyverified: boolean;

    selectedOrganization: {} = {};
    customers: any[] = [];

    rcExportForm: FormGroup;
    rcApiVerifying: boolean;

    oldRcExportSettings: any;

    breadcrumbs = [
        { path: '../..', text: 'Vulnerability Scanner' },
        { path: '..', text: 'Settings' },
        { path: '.', text: 'IT Complete' },
    ];

    constructor(
        private authService: AuthService,
        private messageService: MessageService,
        private formBuilder: FormBuilder,
        private siteSettingService: SiteSettingService,
        private notificationService: NotificationService,
        private kvsService: KvsService,
        private uiService: UiService
    ) {}

    ngOnInit() {
        this.loadingComplete = false;
        this.apikeyverified = false;

        let products = this.authService.getProducts();
        if (!products.KVS) {
            this.loadingComplete = true;
            this.kvsNotProvisioned = true;
        }
        this.oldRcExportSettings = {};

        this.rcExportForm = this.formBuilder.group({
            enabled: [false],
            location: ['USA'],
            accountId: ['', Validators.required],
            apiKey: ['', Validators.required],
            selectedCustomer: [null], // save index of selected customer
        });

        this.msgSub = this.messageService.on(SITE).subscribe((site: Site) => {
            if (!site) return;

            this.processSite(site);
        });

        combineLatest([
            this.rcExportForm.controls.location.valueChanges,
            this.rcExportForm.controls.accountId.valueChanges,
            this.rcExportForm.controls.apiKey.valueChanges,
        ])
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((_) => {
                if (
                    this.apikeyverified &&
                    (this.rcExportForm.controls.location.dirty ||
                        this.rcExportForm.controls.accountId.dirty ||
                        this.rcExportForm.controls.apiKey.dirty)
                ) {
                    this.apikeyverified = false;
                    this.customers = [];
                    this.rcExportForm.controls.selectedCustomer.setValue(null);
                }
            });

        this.messageService.broadcast(PING_SITE);
    }

    verifyRcApiKey() {
        this.rcApiVerifying = true;
        this.apikeyverified = false;

        this.rcApiVerifying = true;

        let checkConnectionData = {
            siteId: this.site.Id,
            accountId: this.rcExportForm.controls.accountId.value,
            apiKey: this.rcExportForm.controls.apiKey.value,
            location: this.rcExportForm.controls.location.value,
        };

        let selectedCustomer = this.customers[0];
        this.kvsService
            .getRCOrganizations(checkConnectionData)
            .then((res) => {
                this.rcApiVerifying = false;
                this.customers = res;
                this.customers.sort((a, b) => (a.name > b.name ? 1 : -1));

                if (res.length > 0) {
                    this.apikeyverified = true;
                    this.notificationService.toast.success(
                        'Integration to RocketCyber',
                        'Connection to RocketCyber is successful.'
                    );
                    this.rcExportForm.controls.location.markAsPristine();
                    this.rcExportForm.controls.accountId.markAsPristine();
                    this.rcExportForm.controls.apiKey.markAsPristine();
                    if (!this.isObjectInArray(this.customers, selectedCustomer)) {
                        this.rcExportForm.controls.selectedCustomer.setValue(null);
                    }
                } else {
                    this.apikeyverified = false;
                    this.rcExportForm.controls.selectedCustomer.setValue(null);
                    this.notificationService.toast.error(
                        'Integration to RocketCyber',
                        'Unable to connect to RocketCyber. Please verify the API Key and Account ID and try again.'
                    );
                }
            })
            .catch((err) => {
                this.rcApiVerifying = false;
                this.notificationService.toast.error(
                    'Integration to RocketCyber',
                    'Unable to connect to RocketCyber. Please verify the API Key and Account ID and try again.'
                );
            });
    }

    isObjectInArray(arr: object[], objectToFind: object): boolean {
        return arr.some((obj) => JSON.stringify(obj) === JSON.stringify(objectToFind));
    }

    processSite(site: Site) {
        this.site = site;
        this.uiService.setTitle('Export to RocketCyber', site.Name);

        this.siteSettingService
            .getSensitiveSiteParam(this.site.Id, 'kvs_export')
            .then((siteParam) => {
                if (siteParam.Value) {
                    let rcSettings = JSON.parse(siteParam.Value);
                    if (!rcSettings.location) {
                        rcSettings.location = 'USA';
                    }
                    this.oldRcExportSettings = rcSettings;

                    if (!rcSettings.organization) {
                        this.selectedOrganization = { id: -1, name: 'Not Selected' };
                    }
                    this.rcExportForm.patchValue(rcSettings);
                    this.customers = [rcSettings.selectedCustomer];
                    this.rcExportForm.controls.selectedCustomer.setValue(0);
                }
            });

        this.loadingComplete = true;
    }

    ngOnDestroy() {
        this.msgSub.unsubscribe();
        this.ngUnsubscribe.next(void 0);
        this.ngUnsubscribe.complete();
    }

    saveRocketCyberExportPreferences() {
        this.rcExportSaving = true;

        let kvsSiteParams = [];
        let kvsSiteParam: SiteParam = new SiteParam();
        kvsSiteParam.SiteId = this.site.Id;
        kvsSiteParam.Name = 'kvs_export';
        let rcExportSettings: { selectedCustomer: any; verified: boolean; enabled: boolean };

        if (this.oldRcExportSettings && !this.rcExportForm.controls.enabled.value) {
            rcExportSettings = this.oldRcExportSettings;
            rcExportSettings.enabled = false;
            rcExportSettings.verified = false;
        } else {
            rcExportSettings = this.rcExportForm.getRawValue();
            rcExportSettings.verified = this.apikeyverified;
            rcExportSettings.selectedCustomer = this.customers[rcExportSettings.selectedCustomer];
        }
        kvsSiteParam.Value = JSON.stringify(rcExportSettings);
        kvsSiteParams.push(kvsSiteParam);

        this.siteSettingService
            .upsertSensitiveSiteParams(this.site.Id, kvsSiteParams)
            .then((s) => {
                this.processSite(this.site);
                this.notificationService.toast.success(
                    'Success',
                    'IT Complete setting was updated.'
                );
                this.rcExportSaving = false;
            })
            .catch((error) => {
                this.notificationService.toast.error(
                    'Error',
                    'There was a problem saving your preferences, please try again.'
                );
                this.rcExportSaving = false;
            });
    }
}
