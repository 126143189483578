import { Component, OnInit } from '@angular/core';
import { Setting, SettingService, SDSP_SETTINGS } from 'app/settings';
import { UiService } from 'app/core/ui.service';
//import { OrganizationsService } from 'app/organizations/organizations.service';
import { ActivatedRoute } from '@angular/router';
import { SiteService } from 'app/sites/shared/site.service';
import { FilesService } from 'app/sites/site/assessments/files/files.service';
import { NotificationService } from 'app/shared/itc/notification/notification.service';

@Component({
    selector: 'sds-indoc-agent-download',
    templateUrl: './indoc-agent-download.component.html',
    styleUrls: ['./indoc-agent-download.component.scss'],
})
export class IndocAgentDownloadComponent implements OnInit {
    private installKey: string;
    downloadEnabled: boolean = false;
    private fileURL: string = 'https://downloadrapidfiretools.com/download/ComputerScanner.exe';
    private fileName: string;
    isloading: boolean = false;

    constructor(
        private settingService: SettingService,
        private uiService: UiService,
        private siteService: SiteService,
        private activatedRoute: ActivatedRoute,
        private filesService: FilesService,
        private notificationService: NotificationService
    ) {
        this.activatedRoute.queryParams.subscribe((params) => {
            this.installKey = params['ik'];
            if (
                this.installKey !== '' &&
                this.installKey.length == 32 &&
                this.installKey != undefined
            ) {
                this.isloading = true;
                this.siteService.isIkValid(this.installKey).then((res) => {
                    if (res) {
                        this.downloadEnabled = true;
                        this.fileName = `ComputerScanner-${this.installKey}.exe`;
                    } else {
                        this.downloadEnabled = false;
                    }
                    this.isloading = false;
                });
            } else {
                this.downloadEnabled = false;
            }
        });
    }

    customBranding: boolean;
    logoImage: string;
    theme: string;

    ngOnInit() {
        this.uiService.setTitle('End-User-Inititated Scan Download');
        let localSettings = JSON.parse(localStorage.getItem(SDSP_SETTINGS) || '{}');
        this.settingService.getBranding().then((settings) => {
            let customBranding = settings.find((s: Setting) => s.Name == 'EnableCustomBranding');
            if (customBranding && customBranding.Value == 'true') {
                this.customBranding = true;
                localSettings['EnableCustomBranding'] = true;
            }

            let theme = settings.find((s: Setting) => s.Name == 'Theme');
            if (theme) {
                this.theme = theme.Value;
                localSettings['Theme'] = theme.Value;
            }

            if (this.customBranding) {
                let logo = settings.find((s: Setting) => s.Name == 'CompanyLogo');
                if (logo) {
                    this.logoImage = logo.Value;
                    localSettings['CompanyLogo'] = logo.Value;
                }
            }

            // Write back any new values to the local settings
            localStorage.setItem(SDSP_SETTINGS, JSON.stringify(localSettings));
        });
    }

    download() {
        if (this.installKey) {
            this.notificationService.toast.success('Downloading file', '');
            // this.filesService.downloadAndRename(this.fileURL, this.fileName);
            this.siteService.redirectToManualDataCollectorUrl(this.installKey).then((res) => {
                if (res) window.open(res);
            });
        }
    }
}
