<sds-loader [complete]="loadingComplete">
    <sds-breadcrumb-trail [crumbs]="breadcrumbs"></sds-breadcrumb-trail>

    <div class="ui grid dashboard" *ngIf="kvsNotProvisioned">
        <div class="nine wide column">
            <div class="ui grid">
                <div class="twelve wide column">
                    <itc-banner type="info" title="KVS Not Provisioned">
                        <ng-container *ngIf="kvsNotProvisioned">
                            This functionality is only associated with the KVS product.
                            <br />
                            Contact your Account Representative to learn more about this product.
                        </ng-container>
                    </itc-banner>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!kvsNotProvisioned">
        <header class="pageHeader">
            <h1>IT Complete</h1>
        </header>

        <itc-card class="integration">
            <card-title>
                RocketCyber Integration - Export Reports to RocketCyber
                <help-button
                    title="Enable RocketCyber Integration"
                    text="This integration allows you to view VulScan security issues from within your RocketCyber dashboard. In addition to your VulScan subscription, you will need a RocketCyber subscription to use this integration."
                    url="/vs/help/vulscan/rocketcyber.htm"></help-button>
            </card-title>
            <card-content>
                <form [formGroup]="rcExportForm" class="itc-form col fullWidth">
                    <itc-toggle [control]="rcExportForm.controls.enabled">
                        Enable RocketCyber Integration
                    </itc-toggle>

                    <div
                        class="required field"
                        [class.disabled]="!rcExportForm.controls.enabled.value">
                        <label>User Location</label>
                        <sm-select [control]="rcExportForm.controls.location">
                            <option value="USA">USA</option>
                            <option value="Europe">Europe</option>
                        </sm-select>
                    </div>
                    <div
                        class="required field"
                        [class.disabled]="!rcExportForm.controls.enabled.value">
                        <label>RocketCyber Account Id</label>
                        <input type="text" formControlName="accountId" />
                    </div>
                    <div
                        class="required field"
                        [class.disabled]="!rcExportForm.controls.enabled.value">
                        <label for="textboxApiKey">RocketCyber API Key</label>
                        <div class="ui input" style="width: 100%">
                            <input
                                type="text"
                                id="textboxApiKey"
                                formControlName="apiKey"
                                style="flex: 1" />
                            <itc-button
                                type="tertiary"
                                label="Check Connection"
                                (onclick)="verifyRcApiKey()"
                                [loading]="rcApiVerifying"
                                style="white-space: nowrap; margin-left: 8px"></itc-button>
                        </div>
                    </div>

                    <div
                        class="field"
                        [class.disabled]="!rcExportForm.controls.enabled.value"
                        style="margin-top: 0">
                        <label>RocketCyber Customer</label>
                        <sm-select
                            [disabled]="!rcExportForm.controls.enabled.value || !apikeyverified"
                            [control]="rcExportForm.controls.selectedCustomer"
                            [options]="{ standalone: true, direction: 'upward' }">
                            <option *ngFor="let customer of customers; index as i" [value]="i">
                                {{ customer.name }}
                            </option>
                        </sm-select>
                    </div>
                    <div
                        class="integrationActions"
                        tooltip="Check if all fields are entered and press Check Connection button"
                        [ttHidden]="
                            (apikeyverified && rcExportForm.controls.selectedCustomer.value) ||
                            (oldRcExportSettings.enabled && !rcExportForm.controls.enabled.value)
                        ">
                        <itc-button
                            type="primary"
                            icon="fa-floppy-disk"
                            [loading]="rcExportSaving"
                            (onclick)="saveRocketCyberExportPreferences()"
                            [disabled]="
                                !(
                                    (apikeyverified &&
                                        rcExportForm.controls.selectedCustomer.value) ||
                                    (oldRcExportSettings.enabled &&
                                        !rcExportForm.controls.enabled.value)
                                )
                            ">
                            Save
                        </itc-button>
                    </div>
                </form>
            </card-content>
        </itc-card>
    </div>
</sds-loader>
