<h4>Computer Info</h4>
<table class="ui table">
    <tbody>
        <tr>
            <td><b>Computer Name</b></td>
            <td>{{ diag?.ComputerDomain }}\{{ diag?.ComputerName }}</td>
        </tr>
        <tr>
            <td><b>IP Address</b></td>
            <td>{{ diag?.IPAddress }}</td>
        </tr>
    </tbody>
</table>

<h4>Applications</h4>
<p>
    The following lists all Installed Applications currently on the computer
    {{ diag?.ComputerName }}.
    <span *ngIf="!removed">Newly installed applications are highlighted in yellow.</span>
    <span *ngIf="removed">Recently removed applications are shown in red.</span>
</p>
<table class="ui table">
    <thead>
        <tr>
            <th>Application Name</th>
            <th>Version</th>
            <th>Installed</th>
        </tr>
    </thead>
    <tbody>
        <tr
            *ngFor="let item of diag?.Children"
            [class.highlight]="item.IsNew == 'true'"
            [class.highlightRed]="item.WasRemoved == 'true'">
            <td>{{ item.ApplicationName }}</td>
            <td>{{ item.ApplicationVersion || 'N/A' }}</td>
            <td>
                {{
                    item.WasRemoved == 'true'
                        ? 'REMOVED'
                        : item.InstallDate.length != 8
                        ? 'N/A'
                        : (fixDate(item.InstallDate) | date : 'shortDate')
                }}
            </td>
        </tr>
        <!--
        <tr ng-if="diag.Children.length > diagTableLimit" ng-click="increaseLimit()">
            <td colspan="3" style="text-align: center">
                <a href="#"><i class="plus icon"></i>{{diag.Children.length - diagTableLimit}} More...</a>
            </td>
        </tr>
        -->
    </tbody>
</table>
