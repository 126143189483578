<sm-modal title="Announcements" class="sm" #modal>
    <modal-content>
        <div *ngIf="announcements?.length">
            <div [innerHTML]="announcements[viewIndex]?.HtmlContent"></div>
        </div>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" class="ok" icon="fa-xmark">Close</itc-button>
        <itc-button
            type="secondary"
            icon="fa-arrow-left"
            (onclick)="prev()"
            [disabled]="viewIndex == 0">
            Previous
        </itc-button>
        <itc-button
            type="primary"
            icon="fa-arrow-right"
            (onclick)="next()"
            [disabled]="viewIndex == announcements?.length - 1">
            Next
        </itc-button>
    </modal-actions>
</sm-modal>
