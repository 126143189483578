<sds-breadcrumb-trail [crumbs]="breadcrumbs"></sds-breadcrumb-trail>

<header class="pageHeader">
    <h1>General</h1>
</header>

<sds-loader [complete]="loadingComplete">
    <itc-card class="integration">
        <card-title>
            Default Matching Rule
            <help-button
                title="Device Matching Rule"
                text="VulScan allows you to set rules for how to identify individual devices — increasing the accuracy of your vulnerability scans."
                url="/vs/help/vulscan/vs-config-matching-criteria.htm"></help-button>
        </card-title>
        <card-content>
            <div>
                <label>Condition</label>
                <sm-select
                    class="form-control input-sm"
                    [(model)]="settings.condition"
                    id="settingsCondition">
                    <option value="all">All selected attributes must match</option>
                    <option value="any">Any selected attribute must match</option>
                </sm-select>
                <!--<select class="form-control input-sm" [(ngModel)]="settings.condition" id="settingsCondition">
                                                          <option value="all">All selected attributes must match</option>
                                                          <option value="any">Any selected attribute must match</option>
                                                        </select>-->
            </div>
            <br />

            <div>
                <itc-notification-banner
                    id="attributes_banner"
                    allowMultiple="false"></itc-notification-banner>
                Select attributes (must select at least 1 attribute):
                <!-- <div style="margin-left: 1em; margin-top: 0.5em; margin-bottom: 1em;"> -->
                <div style="margin-left: 1em; margin-top: 0.5em; margin-bottom: 1em">
                    <itc-checkbox
                        name="mac"
                        [control]="attrMacCtrl"
                        label="MAC Address"
                        value="mac"></itc-checkbox>
                    <br />
                    <itc-checkbox
                        name="hostname"
                        [control]="attrHostnameCtrl"
                        label="Hostname"
                        value="hostname"></itc-checkbox>
                    <br />
                    <itc-checkbox
                        name="ip"
                        [control]="attrIpCtrl"
                        label="IP Address"
                        value="ip"></itc-checkbox>
                </div>
            </div>
            <br />

            <div class="integrationActions">
                <itc-button
                    type="primary"
                    icon="fa-floppy-disk"
                    (onclick)="saveChanges()"
                    [loading]="isSaving">
                    Save
                </itc-button>
            </div>
        </card-content>
    </itc-card>

    <itc-card class="import-config integration">
        <card-title>
            Discovery Agents Scan Data Import Configuration
            <help-button
                title="Discovery Agents Scan Data Import Configuration"
                text="Once you set up Discovery Agents, you can configure your VulScan site to import scan data from your deployed agents. Accomplish this by assigning the appropriate labels to pull the correct agent scan data into your site."
                url="/vs/help/vulscan/vulscan-org-discovery-agents.htm"></help-button>
        </card-title>
        <card-content>
            <p>
                Select labels for the Discovery Agents you wish to use for this site. Your site will
                import data from the Discovery Agents with the selected labels.
            </p>
            <sds-loader [complete]="orgDiscoveryLoadingComplete">
                <label>Labels</label>
                <sm-select
                    [control]="agentLabelCtrl"
                    class="fluid search multiple"
                    (onChange)="onSelectAgentConfigs($event)"
                    [options]="{ direction: 'upward' }"
                    #agentLabelsSelect>
                    <option [value]="'/%all%/'">All</option>
                    <option *ngFor="let agent of agentLabels" [value]="agent">{{ agent }}</option>
                </sm-select>

                <div class="integrationActions" type>
                    <itc-button
                        icon="fa-floppy-disk"
                        type="primary"
                        (onclick)="saveAgentConfig()"
                        [loading]="isAgentConfigSaving">
                        Save
                    </itc-button>
                </div>
            </sds-loader>
        </card-content>
    </itc-card>
</sds-loader>
