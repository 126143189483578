import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';

import { AuthService } from 'app/core/auth';
import { MessageService } from 'app/core/message.service';
import { SettingService } from '../shared/setting.service';
import { UiService } from 'app/core/ui.service';

import { Setting } from '../shared/setting.model';
import { ADMINALERTS, FETCH_SETTINGS, PING_SETTINGS, SETTINGS } from '../shared/constants';
import { NotificationService } from '../../shared/itc/notification/notification.service';

@Component({
    selector: 'sds-admin-alerts',
    templateUrl: './admin-alerts.component.html',
    styleUrls: ['./admin-alerts.component.css'],
})
export class AdminAlertsComponent implements OnInit, OnDestroy {
    constructor(
        private authService: AuthService,
        private messageService: MessageService,
        private settingService: SettingService,
        private notificationService: NotificationService,
        private uiService: UiService
    ) {}

    emails: string;
    prefix: string;
    scanFailedCtrl: FormControl<boolean> = new FormControl();
    scanCompleteCtrl: FormControl<boolean> = new FormControl();
    notifyFailedCtrl: FormControl<boolean> = new FormControl();
    reportsCompleteCtrl: FormControl<boolean> = new FormControl();
    scanMergedCtrl: FormControl<boolean> = new FormControl();

    oldSettings: any = {};

    loadingComplete: boolean;
    saving: boolean;

    sub: any;

    breadcrumbs = [
        { path: '..', text: 'Admin' },
        { path: '.', text: 'Admin Alerts' },
    ];

    ngOnInit() {
        this.uiService.setTitle('Admin Alerts');
        this.sub = this.messageService.on(SETTINGS).subscribe((sets) => {
            this.loadingComplete = false;

            this.oldSettings = Object.assign({}, sets);
            if (!this.oldSettings[ADMINALERTS.SUBJPREFIX]) {
                this.oldSettings[ADMINALERTS.SUBJPREFIX] = '%%SITE%% - ';
            }

            this.resetSettings();

            this.loadingComplete = true;
        });
        this.messageService.broadcast(PING_SETTINGS);
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    resetSettings() {
        this.emails = this.oldSettings[ADMINALERTS.EMAILTO];
        this.prefix = this.oldSettings[ADMINALERTS.SUBJPREFIX];

        this.scanFailedCtrl.setValue(this.oldSettings[ADMINALERTS.SENDSCANFAILED] != 'false');
        this.notifyFailedCtrl.setValue(this.oldSettings[ADMINALERTS.SENDNOTIFYERROR] == 'true');
        this.scanCompleteCtrl.setValue(this.oldSettings[ADMINALERTS.SENDSCANCOMPLETE] != 'false');
        this.reportsCompleteCtrl.setValue(
            this.oldSettings[ADMINALERTS.SENDREPORTSCOMPLETE] != 'false'
        );
        this.scanMergedCtrl.setValue(this.oldSettings[ADMINALERTS.SENDMERGECOMPLETE] != 'false');
    }

    saveSettings() {
        let accountId = this.authService.getIdentity().accountId;
        let newSettings: Setting[] = [];

        newSettings.push({
            AccountId: accountId,
            Name: ADMINALERTS.EMAILTO,
            Value: this.emails,
        });
        newSettings.push({
            AccountId: accountId,
            Name: ADMINALERTS.SUBJPREFIX,
            Value: this.prefix,
        });
        newSettings.push({
            AccountId: accountId,
            Name: ADMINALERTS.SENDSCANFAILED,
            Value: this.scanFailedCtrl.value?.toString(),
        });
        newSettings.push({
            AccountId: accountId,
            Name: ADMINALERTS.SENDNOTIFYERROR,
            Value: this.notifyFailedCtrl.value?.toString(),
        });
        newSettings.push({
            AccountId: accountId,
            Name: ADMINALERTS.SENDSCANCOMPLETE,
            Value: this.scanCompleteCtrl.value?.toString(),
        });
        newSettings.push({
            AccountId: accountId,
            Name: ADMINALERTS.SENDREPORTSCOMPLETE,
            Value: this.reportsCompleteCtrl.value?.toString(),
        });
        newSettings.push({
            AccountId: accountId,
            Name: ADMINALERTS.SENDMERGECOMPLETE,
            Value: this.scanMergedCtrl.value?.toString(),
        });

        this.saving = true;

        this.settingService
            .updateSettings(newSettings)
            .then(() => {
                this.saving = false;
                this.notificationService.toast.success('Success', 'Updated admin alert settings.');
                this.messageService.broadcast(FETCH_SETTINGS);
            })
            .catch((err) => {
                this.saving = false;
                this.notificationService.toast.error(
                    'Error',
                    'Something unexpected happened. Please try again.'
                );
            });
    }
}
