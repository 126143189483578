<div style="display: flex; width: calc(100% - 5px)">
    <div class="organizationsContainer" style="width: 100%">
        <header class="pageHeader">
            <h1>Organizations</h1>
            <itc-tag type="subtle" color="orange">
                {{ (filteredOrganizations$ | async)?.length || 0 }}
            </itc-tag>

            <div class="item searchInput">
                <sm-input placeholder="Search for site by name"
                          [control]="searchKey"
                          icon="icon-search"></sm-input>
            </div>

            <div>
                <div class="ui labeled input siteFilter">
                    <sm-select [control]="filterCtrl">
                        <option value="all" selected="selected">All Organizations</option>
                        <option value="auditguru">Compliance Manager Sites</option>
                        <option value="cspro">Compliance Services Pro Sites</option>
                        <option value="cyberhawk">Cyber Hawk Sites</option>
                        <option value="indoc" *ngIf="hasIndoc">Indoc Sites</option>
                        <option value="ndpro" *ngIf="products.NdPro">
                            Network Detective Pro Sites
                        </option>
                        <option value="kvs">VulScan Sites</option>
                    </sm-select>
                </div>
            </div>

            <div class="flex-right">
                <itc-button type="primary"
                            icon="fa-plus"
                            (onclick)="showAddOrganizationModal(addOrgForm)"
                            *ngIf="canAdd">
                    Add Organization
                </itc-button>
            </div>
        </header>

        <ng-container *ngIf="filteredOrganizations$ | async as filteredOrganizations; else loading">
            <sds-organization-grid *ngIf="viewMode === 'grid'"
                                   [organizations]="filteredOrganizations"
                                   [canModify]="canAdd"
                                   [viewModeUpdating]="viewModeUpdating"
                                   (onDelete)="organizationDeleted($event)"
                                   (viewLoaded)="viewUpdated()"
                                   (detailsClicked)="toggleDetails($event)"></sds-organization-grid>
        </ng-container>

        <ng-container *ngIf="orgListError$ | async as error">
            <itc-banner type="error">There was a problem loading your organizations.</itc-banner>
        </ng-container>
    </div>
</div>

<sm-modal title="Add Organization" class="xs" #addOrganizationModal>
    <modal-content>
        <form name="addOrganizationForm" #addOrgForm="ngForm" style="position: relative" novalidate>
            <itc-button type="ghost"
                        class="ITGluebutton"
                        [disabled]="!iTGConnection"
                        (onclick)="addITGlue()">
                Add from IT Glue
            </itc-button>

            <label [class.invalid]="
                    !orgName.valid && orgName.errors.regex && (orgName.touched || orgName.dirty)
                ">
                Organization Name*
            </label>
            <input [class.invalid]="
                    !orgName.valid && orgName.errors.regex && (orgName.touched || orgName.dirty)
                "
                   type="text"
                   name="organizationName"
                   [(ngModel)]="newOrganizationName"
                   orgNameValidator
                   maxlength="50"
                   #orgName="ngModel" notBlank />
            <label style="margin-top: -12px">
                Organization names must start with an alphanumeric character, may only include
                alphanumeric characters, dash, and space and must be less than 50 characters long.
            </label>
        </form>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" class="cancel">Cancel</itc-button>
        <itc-button type="primary" (onclick)="addOrganization()" [disabled]="!addOrgForm.valid">
            Confirm
        </itc-button>
    </modal-actions>
</sm-modal>

<sm-modal title="Add From IT Glue" class="sm" [overrideOverflow]="true" #addFromITGlueModal>
    <modal-content>
        <form class="ui form" name="addFromITGlueForm">
            <p style="margin-top: 0">
                Select your organizations from IT Glue to automatically create RapidFire Tools
                <b>organizations</b>
                .
            </p>
            <div class="required field">
                <i class="icon-itglue small float-left" style="width: 32px"></i>
                <label>IT Glue Organizations</label>

                <sds-loader [complete]="loadingITGComplete">
                    <sm-select #itgorgsselect
                               [control]="itGlueOrgControl"
                               placeholder="Select multiple..."
                               class="fluid search multiple itgorgsselect"
                               (onChange)="multiSelect($event)">
                        <option *ngFor="let org of itGlueOrgs">{{ org.name }}</option>
                    </sm-select>
                </sds-loader>
            </div>
        </form>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" (onclick)="closeITGlueModal()" class="cancel">Back</itc-button>
        <itc-button type="primary"
                    (onclick)="addITGlueOrganization()"
                    [disabled]="existingSelection.length == 0">
            Add
        </itc-button>
    </modal-actions>
</sm-modal>
<sm-modal class="md"
          [title]="
        'Enable Confidential Information Protection for ' + products?.NdPro
            ? 'Network Detective Pro'
            : 'InDoc'
    "
          #privacymodal>
    <modal-content>
        <div>
            <p>
                Before you can start storing confidential information using the
                {{ products?.NdPro ? 'Network Detective Pro' : 'InDoc' }} feature, you must
                establish a confidential information store. This is done by establishing a circle of
                trust. You should grant access to the minimum number of possible users. Access is
                granted and removed using the Admin Settings > Users page. To enable your
                confidential information store, please enter your password below.
            </p>
        </div>
        <form>
            <div class="field">
                Password:
                <input type="password"
                       id="textboxPassword1"
                       name="textboxPassword1"
                       placeholder="Enter Password"
                       [(ngModel)]="password1"
                       autocomplete="off" />
            </div>
        </form>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" class="cancel" (onclick)="closePrivacyModal(false)">
            Cancel
        </itc-button>
        <itc-button type="primary" (onclick)="closePrivacyModal(true)">OK</itc-button>
    </modal-actions>
</sm-modal>
<sm-modal class="md"
          [title]="
        'Confidential Information Protection Enabled for ' + products?.NdPro
            ? 'Network Detective Pro'
            : 'InDoc'
    "
          #privacycompletemodal>
    <modal-content>
        <div>
            <p>
                Congratulations! Your Confidential Information Store has been created and you have
                been granted rights to view and edit confidential information. You are being
                redirected to the Admin Settings > Users page where you can grant access to view
                confidential information to others. Simply click on the privacy icon to grant or
                revoke access.
            </p>
        </div>
    </modal-content>
    <modal-actions>
        <itc-button type="primary" (onclick)="privacyCompleteModal.hide()">OK</itc-button>
    </modal-actions>
</sm-modal>

<sm-modal class="md" title="Error Establishing Confidential Information Store" #privacyerrormodal>
    <modal-content>
        <div>
            <p>Error Establishing Confidential Information Store</p>
            <p>Details: {{ privacyError }}</p>
        </div>
    </modal-content>
    <modal-actions>
        <itc-button type="primary" (onclick)="privacyErrorModal.hide(); privacyModal.show()">
            OK
        </itc-button>
    </modal-actions>
</sm-modal>

<ng-template #loading>
    <itc-loader></itc-loader>
</ng-template>
