import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { isValidEmail, isValidMultiEmail } from 'app/shared/email.validator';

import { Subject, Observable } from 'rxjs';

const REGEX = {
    url: /[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
	email: /(([a-zA-Z0-9!#\$%&\'\*\+\-\/=?^_`\{\|\}~\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,})(\s*;\s*|\s*$))+/,
    emails: /(([a-zA-Z0-9!#\$%&\'\*\+\-\/=?^_`\{\|\}~\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,})(\s*;\s*|\s*$))+/,
};

declare var $: any;

@Injectable({ providedIn: 'root' })
export class FormService {
    constructor(private router: Router) {}

    // Form validation
    validate(name): boolean {
        var fields = this.clear(name);

        var allPassed = true;

        for (var i = 0; i < fields.length; i++) {
            var field = fields[i];

            var input = null;
            var value = null;
            if ($(field).find('p-dropdown').length !== 0) {
                input = $(field).find('p-dropdown')[0];
                if ($(input).find('.p-placeholder').length == 0) {
                    value = input.innerText;
                }
            } else {
                input = $(field).find('input, select')[0];
                value = $(input).val();
            }
            if (input) {
                $(input).change(function () {
                    var f = $(this).closest('.field');
                    $(f).removeClass('error');
                    $(f).find('label.error').removeClass('show');
                });

                if ($(field).hasClass('required') && !$(field).hasClass('skipfield')) {
                    if (!value) {
                        $(field).addClass('error');
                        allPassed = false;
                        continue;
                    }
                }

                var r = $(input).data('rules');
                if (r) {
                    var rules = r.split(',');

                    for (var j = 0; j < rules.length; j++) {
                        var rule = rules[j];

                        var passed = false;
                        var regex = void 0;
                        switch (rule) {
                            case 'regex':
                                regex = $(input).data('regex');
                                if (!regex) {
                                    console.error(
                                        'Regex match rule specified but "data-regex" attribute is missing.',
                                        input
                                    );
                                    passed = false;
                                } else {
                                    var exp = regex.match(/\/(.*)\/([gimuy]*)/); // have to split out the flags from the expression
                                    regex = new RegExp(exp[1], exp[2]);
                                }
                                break;
                            default:
                                regex = REGEX[rule];
                                break;
                        }

                        if (!regex) {
                            passed = false;
                        } else if (rule == 'email') {
                            passed = isValidEmail(value);
                        } else {
                            var match = value.match(regex);
                            if (match) passed = true;
                        }

                        if (!passed) {
                            this.error(field, rule);
                            allPassed = false;
                            break;
                        }
                    }
                }
            }
        }

        return allPassed;
    }

    clear(formName) {
        var form = $('form[name=' + formName + ']');
        var fields = $(form).find('.field');

        $(fields).removeClass('error');
        $(fields).find('label.error').removeClass('show');

        return fields;
    }

    error(field, rule) {
        $(field).addClass('error');
        if (rule) {
            var label = $(field).find('label.error[data-rule=' + rule + ']');
            if (label && label[0]) {
                $(label[0]).addClass('show');
            }
        }
    }
}
