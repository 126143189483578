import { Component, OnInit, ViewChild } from '@angular/core';
import { FormService } from 'app/core/form.service';
import { AuthService } from 'app/core/auth';
import { MessageService } from 'app/core/message.service';
import { UiService } from 'app/core/ui.service';
import { TableSorting } from 'app/shared/sortable-table/sortable-table.component';
import { ControlsService } from 'app/sites/shared/controls/controls.service';
import { StandardsService } from 'app/sites/shared/standards/standards.service';
import { ClonedControl, Control } from 'app/sites/shared/controls/control.model';
import { Standard } from 'app/sites/shared/standards/standard.model';
import { Requirement } from 'app/sites/shared/standards/requirement.model';
import { SiteService } from 'app/sites/shared/site.service';
import { CmControlEditorModalComponent } from 'app/sites/site/compliance-manager/control-editor-modal/cm-control-editor-modal.component';
import { NotificationService } from '../../../shared/itc/notification/notification.service';

@Component({
    selector: 'sds-controls-library',
    templateUrl: './controls-library.component.html',
    styleUrls: ['./controls-library.component.css'],
})
export class ControlsLibraryComponent implements OnInit {
    constructor(
        private authService: AuthService,
        private messageService: MessageService,
        private formService: FormService,
        private notificationService: NotificationService,
        private uiService: UiService,
        private controlsService: ControlsService,
        private standardsService: StandardsService,
        private siteService: SiteService
    ) {}

    @ViewChild(CmControlEditorModalComponent, { static: true })
    controlEditor: CmControlEditorModalComponent;

    controlSaved: any;
    controlEditorAdding: boolean = false;

    user: any;

    @ViewChild('deleteControl', { static: true }) deleteControl;
    // @ViewChild('cloneControl') cloneControl;

    loadingComplete: boolean = false;
    isDeleteinProgress = false;
    controls: Control[];
    filteredControls: any[] = [];
    sortedControls: any[] = [];
    pagedControls: any[] = [];

    systemControlIds: string[] = [];

    selectedControl: Control = new Control();

    clonedControlId: string;
    clonedControlName: string;

    hideSystemControls: boolean = false;

    languageMap: any = [
        { value: 'nl-NL', label: 'Dutch' },
        { value: 'en-US', label: 'English (US)' },
        { value: 'fr-FR', label: 'French' },
        { value: 'fr-CA', label: 'French (Canada)' },
        { value: 'de-DE', label: 'German' },
        { value: 'it-IT', label: 'Italian' },
        { value: 'pt-BR', label: 'Portuguese (Brasil)' },
        { value: 'es-ES', label: 'Spanish' },
    ];

    breadcrumbs = [
        { path: '../..', text: 'Admin' },
        { path: '..', text: 'Compliance Manager GRC' },
        { path: '.', text: 'Controls Library' },
    ];

    culture: string = 'en-US';
    standardDict = {};
    selectedRequirements: Requirement[] = [];

    standards: Standard[] = [];
    cloneControl = new ClonedControl();

    //site: Site;

    controlsFilterText: string = '';
    cloning: boolean;

    async ngOnInit() {
        this.uiService.setTitle('Controls Library');

        this.user = this.authService.getIdentity();

        await this.loadControls();

        this.loadStandards();

        this.controlSaved = this.controlEditor.controlSaved.subscribe(async (control) => {
            /*await this.loadControls();*/
            this.updateControlsTable(control);

            if (this.controlsFilterText.trim() !== '') {
                this.searchControls();
            } else {
                this.sortControls();
            }
        });
    }

    updateControlsTable(control: Control) {
        if (this.controlEditorAdding) {
            this.controls.push(control);
        } else {
            let oldControlIndex = this.controls.findIndex((ctrl) => ctrl.Id == control.Id);
            this.controls[oldControlIndex] = control;
        }
        this.filterControls();
    }

    loadStandards() {
        this.standardsService.getSystemStandards().then((resSystem) => {
            this.standards = [];
            this.standards.push(...resSystem);
            this.standardsService.getAccountStandards().then((resAccount) => {
                this.standards.push(...resAccount);
                this.controlEditor.standards = this.standards;
            });
        });
    }

    async loadControls() {
        this.controls = [];
        this.systemControlIds = [];

        await this.controlsService.getSystemControlsForIso(this.culture).then((resSystem) => {
            this.controls.push(...resSystem);
            resSystem.forEach((sc) => {
                this.systemControlIds.push(sc.ControlId);
            });
        });

        await this.controlsService.getAccountControlsForIso(this.culture).then((resAccount) => {
            this.controls.push(...resAccount);
        });

        this.filterControls();
        this.loadingComplete = true;
    }

    addControl() {
        this.controlEditorAdding = true;
        this.controlEditor.canSave = true;
        this.controlEditor.startControlEditor(null, this.controls);
    }

    startControlEditor(control: Control) {
        this.controlEditorAdding = false;
        this.controlsService.getEditControl(control.Id).then((ctl) => {
            this.controlEditor.startControlEditor(ctl, this.controls);
            if (ctl.IsSystem) this.controlEditor.isReadOnly = true;
            else this.controlEditor.isReadOnly = false;
        });
    }

    startDelete(control: Control) {
        this.selectedControl = control;
        this.deleteControl.show({ closable: false });
    }

    closeDelete() {
        this.deleteControl.hide();
    }

    async deleteSelectedControl() {
        this.isDeleteinProgress = true;
        await this.controlsService.deleteControl(this.selectedControl.Id);
        await this.loadControls();
        this.notificationService.toast.success('Success', 'Control Successfully Deleted');
        this.isDeleteinProgress = false;
        this.deleteControl.hide();
    }

    startClone(control: Control) {
        this.cloneControl.OrigId = control.Id;
        this.cloneControl.OrigControlId = control.ControlId;
        this.cloneControl.OrigShortDesc = control.ShortDesc.LocalizedText[this.culture];
        this.controlsService.getCloneControlName(this.cloneControl, this.controls);
        console.log('this.cloneControl', this.cloneControl);
        this.controlEditor.cloneControl(this.cloneControl, this.controls);
    }

    getSHortDiscription(ctl?: Control) {
        var returnString = '';
        if (
            ctl &&
            (ctl.ShortDesc.LocalizedText[this.culture] == '' ||
                !ctl.ShortDesc.LocalizedText[this.culture])
        ) {
            this.languageMap.forEach((rec) => {
                if (
                    returnString == '' &&
                    ctl.ShortDesc.LocalizedText[rec.value] &&
                    ctl.ShortDesc.LocalizedText[rec.value] != ''
                ) {
                    returnString = ctl.ShortDesc.LocalizedText[rec.value];
                }
            });
        } else if (ctl) returnString = ctl.ShortDesc.LocalizedText[this.culture];
        return returnString;
    }

    async controlCloned(control: Control) {
        await this.loadControls();
        this.notificationService.toast.success('Success', 'Control Successfully Cloned');
    }

    hideSystemRecordsChanged(event?: any) {
        this.hideSystemControls = event;
        this.sortControls();
    }

    // startClone(control: Control) {
    //   this.selectedControl = control;
    //   this.clonedControlId = this.selectedControl.ControlId;
    //   this.clonedControlName = this.selectedControl.ShortDesc.LocalizedText[this.culture];
    //   if (this.pagedControls.findIndex((e) => {
    //     return e.ShortDesc.LocalizedText[this.culture] == this.clonedControlName || e.ControlId == this.clonedControlId
    //   }) > -1) {
    //     var temp = this.clonedControlName
    //     var tempId = this.clonedControlId
    //     let count = 0;
    //     while (true) {
    //       this.clonedControlName = temp + ((count == 0) ? ' (copy)' : ' (copy -' + count + ')')
    //       this.clonedControlId = tempId + ((count == 0) ? ' (copy)' : ' (copy -' + count + ')')
    //       let i = this.pagedControls.findIndex((e) => {
    //         return e.ShortDesc.LocalizedText[this.culture] == this.clonedControlName || e.ControlId == this.clonedControlId
    //       })
    //       if (i == -1) break;
    //       else count++
    //     }
    //   }
    //   this.cloneControl.show({ closable: false });
    // }

    // cloneSelectedControl() {
    //   if (this.pagedControls.findIndex((e) => {
    //     return e.ShortDesc.LocalizedText[this.culture] == this.clonedControlName || e.ControlId == this.clonedControlId
    //   }) > -1) {
    //     var temp = this.clonedControlName
    //     var tempId = this.clonedControlId
    //     let count = 0;
    //     while (true) {
    //       this.clonedControlName = temp + ((count == 0) ? ' (copy)' : ' (copy -' + count + ')')
    //       this.clonedControlId = tempId + ((count == 0) ? ' (copy)' : ' (copy -' + count + ')')
    //       let i = this.pagedControls.findIndex((e) => {
    //         return e.ShortDesc.LocalizedText[this.culture] == this.clonedControlName || e.ControlId == this.clonedControlId
    //       })
    //       if (i == -1) break;
    //       else count++
    //     }
    //   }
    //   this.cloning = true;
    //   this.controlsService.cloneControl(this.selectedControl.Id, this.clonedControlId, this.clonedControlName, this.culture).then(res => {
    //     this.loadControls();
    //     this.notificationService.toast.success('Success', 'Control Successfully Cloned');
    //     this.cloneControl.hide();
    //     this.cloning = false;
    //   })
    // }

    // closeClone() {
    //   this.cloneControl.hide();
    // }

    searchControls() {
        let val: string = this.controlsFilterText.toLowerCase();

        let displayedControls = [];

        this.controls.forEach((gc) => {
            if (gc.ShortDesc.LocalizedText[this.culture].toLocaleLowerCase().includes(val)) {
                if (!displayedControls.find((fc) => fc.Id == gc.Id)) {
                    displayedControls.push(gc);
                }
            } else if (gc.ControlId.toLowerCase().includes(val)) {
                if (!displayedControls.find((fc) => fc.Id == gc.Id)) {
                    displayedControls.push(gc);
                }
                //} else if (gc.LongDesc.LocalizedText[this.culture].toLocaleLowerCase().includes(val)) {
                //  if (!displayedControls.find(fc => fc.Id == gc.Id)) {
                //    displayedControls.push(gc);
                //  }
                //} else if (gc.Guidance.LocalizedText[this.culture].toLocaleLowerCase().includes(val)) {
                //  if (!displayedControls.find(fc => fc.Id == gc.Id)) {
                //    displayedControls.push(gc);
                //  }
            }
            //else {
            //  gc.Requirements.forEach(fr => {
            //    if (fr.RequirementId.toLowerCase().includes(val)) {
            //      if (!displayedControls.find(fc => fc.Id == gc.Id)) {
            //        displayedControls.push(gc);
            //      }
            //    } else if (fr.ShortDesc.LocalizedText[this.culture].toLocaleLowerCase().includes(val)) {
            //      if (!displayedControls.find(fc => fc.Id == gc.Id)) {
            //        displayedControls.push(gc);
            //      }
            //    } else if (fr.LongDesc.LocalizedText[this.culture].toLocaleLowerCase().includes(val)) {
            //      if (!displayedControls.find(fc => fc.Id == gc.Id)) {
            //        displayedControls.push(gc);
            //      }
            //    } else if (fr.Guidance.LocalizedText[this.culture].toLocaleLowerCase().includes(val)) {
            //      if (!displayedControls.find(fc => fc.Id == gc.Id)) {
            //        displayedControls.push(gc);
            //      }
            //    }
            //  })
            //}
        });

        this.filterControls(displayedControls);
    }

    filterControls(filteredData?: any) {
        if (filteredData) this.filteredControls = filteredData;
        else this.filteredControls = this.controls;

        this.sortControls();
    }

    checkFilter() {
        let filtered: any[] = [];
        for (let lic of this.filteredControls) {
            filtered.push(lic);
        }
        return filtered;
    }

    sortControls(sorting?: TableSorting) {
        if (!sorting)
            this.sortedControls = this.filteredControls.sort((a, b) =>
                this.fixControlId(a.ControlId)
                    .toLowerCase()
                    .localeCompare(this.fixControlId(b.ControlId).toLowerCase())
            );
        else {
            switch (sorting.sortColumn) {
                case 'ControlId':
                    this.sortedControls = this.filteredControls.sort((a, b) =>
                        this.fixControlId(a.ControlId)
                            .toLowerCase()
                            .localeCompare(this.fixControlId(b.ControlId).toLowerCase())
                    );
                    break;
                case 'ShortDesc':
                    this.sortedControls = this.filteredControls.sort((a, b) =>
                        a.ShortDesc.LocalizedText[this.culture]
                            .toLowerCase()
                            .localeCompare(b.ShortDesc.LocalizedText[this.culture].toLowerCase())
                    );
                    break;
                default:
                    this.sortedControls = this.filteredControls.sort((a, b) =>
                        this.fixControlId(a.ControlId)
                            .toLowerCase()
                            .localeCompare(this.fixControlId(b.ControlId).toLowerCase())
                    );
                    break;
            }

            if (sorting.sortDirection == 'desc')
                //descending
                this.sortedControls.reverse();
        }

        if (this.hideSystemControls) {
            this.sortedControls = this.sortedControls.filter((r) => !r.IsSystem);
        }

        this.sortedControls = [...this.sortedControls];
    }

    fixControlId(id: string) {
        let els: string[] = id.split('.');
        for (var i = 0; i < els.length; i++) {
            els[i] = els[i].padStart(3, '0');
        }
        return els.join('.');
    }
}
