import { Component, Input, OnInit } from '@angular/core';
import { SiteDashboard } from '../../shared/site-dashboard.model';
import { FormControl } from '@angular/forms';
import { SiteDashboardService } from 'app/sites/shared/site-dashboard.service';

@Component({
    selector: 'sds-vulscan',
    templateUrl: './vulscan.component.html',
    styleUrls: ['./vulscan.component.scss'],
})
export class VulscanComponent {
    @Input() set sites(value: any) {
        this.allSites = value;
        this.filteredSites = value;
        this.onSortSites();
    }

    searchKeyControl = new FormControl<string>('');
    allSites: SiteDashboard[] = [];
    filteredSites: SiteDashboard[] = [];
    sortedSites: SiteDashboard[] = [];
    sortColumn: string = '';
    sortDirection: string = '';

    constructor(public siteDashboardService: SiteDashboardService) {}

    searchSites() {
        if (this.searchKeyControl.value?.trim().toLowerCase()) {
            let key: string = this.searchKeyControl.value.toLowerCase();
            this.filteredSites = this.allSites.filter(
                (site) =>
                    site.Name.toLowerCase().indexOf(key) > -1 ||
                    site.Organization.toLowerCase().indexOf(key) > -1
            );
        } else {
            this.filteredSites = this.allSites;
        }
    }

    onSortSites(ev: any = { sortColumn: 'Name', sortDirection: 'asc' }) {
        this.sortColumn = ev.sortColumn;
        this.sortDirection = ev.sortDirection;
        this.sortSites();
    }

    resetSearchKey() {
        if (this.searchKeyControl) {
            this.searchKeyControl.setValue('');
        }
    }

    sortSites() {
        this.sortedSites = this.filteredSites.sort((a, b) => {
            let cola = a[this.sortColumn].toLocaleLowerCase();
            let colb = b[this.sortColumn].toLocaleLowerCase();

            if (this.sortDirection === 'desc') {
                return cola < colb ? 1 : cola === colb ? 0 : -1;
            } else {
                return cola > colb ? 1 : cola === colb ? 0 : -1;
            }
        });

        this.filteredSites = [...this.sortedSites];
    }
}
