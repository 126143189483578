import { Component, isDevMode, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { ALL_SITES, NavService } from 'app/core/nav/nav.service';
import { AuthService } from 'app/core/auth';
import { ErrorService } from 'app/core/error/error.service';
import { AlertService } from './shared/alert.service';
import { Alert } from './shared/alert.model';
import { ALERT_STATUSES } from './shared/constants';
import { Site } from 'app/sites/shared/site.model';
import { SiteService } from 'app/sites/shared/site.service';
import { UiService } from 'app/core/ui.service';

@Component({
    templateUrl: './alerts.component.html',
    styleUrls: ['./alerts.component.css'],
})
export class AlertsComponent implements OnInit {
    constructor(
        private alertService: AlertService,
        private authService: AuthService,
        private errorService: ErrorService,
        private navService: NavService,
        private siteService: SiteService,
        private uiService: UiService,
        private router: Router
    ) {}

    allAlerts: Alert[];

    alerts: Alert[];

    statusFiltered: Alert[];
    timeFiltered: Alert[];
    filteredAlerts: Alert[];

    sortedAlerts: Alert[];

    sites: Site[];

    sizes = [15, 25, 50, 100];
    pageSize: number;
    pageNumber: number;
    pagedAlerts: Alert[];
    sortMethod: string = 'Created';
    sortDirection: string = 'desc';

    loadingComplete: boolean;

    initPage: boolean;

    ngOnInit() {
        if (!environment.production) console.log('alerts ngOnInit');

        this.navService.setSite(ALL_SITES);

        this.alertService
            .getAlerts()
            .then((alerts) => this.process(alerts, false))
            .catch(() => {
                this.loadingComplete = true;
                this.errorService.error();
            });
    }

    process(alerts: Alert[], isFilter: boolean) {
        for (let alert of alerts) {
            /*try {
          alert.SiteName = this.sites.find((s) => s.Id == alert.SiteId).Name;
      } catch (Exception) {
          alert.SiteName = "";
      }*/
            if (alert.AlertData != '') alert.DataObject = JSON.parse(alert.AlertData);
        }

        // Filter out TASK type
        this.alerts = alerts.filter(
            (a) =>
                [
                    ALERT_STATUSES.TASK.value,
                    ALERT_STATUSES.TASK_PENDING.value,
                    ALERT_STATUSES.TASK_COMPLETE.value,
                ].indexOf(a.Status) < 0
        );

        if (!environment.production) console.log(this.alerts);

        this.loadingComplete = true;
    }

    error(err: any) {
        console.error(err);
    }

    sortAlerts() {
        if (this.sortMethod === 'Created' || this.sortMethod === undefined) {
            this.sortedAlerts = this.filteredAlerts.sort((a, b) => {
                if (this.sortDirection === 'asc') {
                    return new Date(a.Created).getTime() - new Date(b.Created).getTime();
                } else {
                    return new Date(b.Created).getTime() - new Date(a.Created).getTime();
                }
            });
        } else {
            this.sortedAlerts = this.filteredAlerts.sort((a, b) => this.sortByColumn(a, b));
        }
    }

    onDetails(event, guid) {
        this.router.navigate(['alerts', guid]);
    }

    processSort(sortBy: string) {
        this.sortDirection = sortBy.charAt(0) === '-' ? 'desc' : 'asc';
        this.sortMethod = sortBy.replace('-', '');
        if (isDevMode()) {
            console.log('sortBy: ' + this.sortMethod + ', Direction: ' + this.sortDirection);
        }
        this.sortAlerts();
    }

    cmp(a, b) {
        if (a > b) {
            return this.sortDirection === 'asc' ? 1 : -1;
        }
        if (a < b) {
            return this.sortDirection === 'asc' ? -1 : 1;
        }
        return 0;
    }
    cmpTime(a, b) {
        if (this.sortDirection === 'asc') {
            return new Date(a).getTime() - new Date(b).getTime();
        } else {
            return new Date(b).getTime() - new Date(a).getTime();
        }
    }

    sortByColumn(a, b) {
        a[this.sortMethod] = a[this.sortMethod] === undefined ? '' : a[this.sortMethod];
        b[this.sortMethod] = b[this.sortMethod] === undefined ? '' : b[this.sortMethod];
        return (
            this.cmp(a[this.sortMethod], b[this.sortMethod]) || this.cmpTime(a.Created, b.Created)
        );
        // return (this.sortDirection === "asc") ? a[this.sortMethod].localeCompare(b[this.sortMethod]) : b[this.sortMethod].localeCompare(a[this.sortMethod]);
        // if (a[this.sortMethod].toLowerCase() < b[this.sortMethod].toLowerCase()) {
        //   return (this.sortDirection === 'asc') ? -1 : 1;
        // } else if (a[this.sortMethod].toLowerCase() > b[this.sortMethod].toLowerCase()) {
        //   return (this.sortDirection === 'asc') ? 1 : -1;
        // } else {
        //   return 0;
        // }
    }
}
