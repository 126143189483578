<sds-breadcrumb-trail [crumbs]="breadcrumbs"></sds-breadcrumb-trail>

<header class="pageHeader">
    <h1>Scan Credentials</h1>
    <help-button
        title="Scan Credentials"
        text="You can enter scan credentials for your VulScan sites and then assign these credentials to be used during the scan task. Scan credentials are not required to perform an internal scan, but adding them can help detect a wider range of security issues."
        url="/vs/help/vulscan/vs-scan-credentials.htm"></help-button>
</header>

<sds-loader [complete]="loadingComplete">
    <div class="item searchInput">
        <itc-input
            placeholder="Quick Filter..."
            [control]="searchKey"
            (modelChange)="filterCredentials()"
            debounce="420"
            [working]="filteringCredentials"
            class="quickFilter"></itc-input>
    </div>
    <itc-button
        type="primary"
        icon="fa-plus"
        style="float: right; margin-top: -42px"
        (onclick)="showAddCredentialModal()"
        *ngIf="!readonly">
        Create New Credential
    </itc-button>

    <div class="overflowTable">
        <table
            sortable-table
            class="itc-table sortable-table-titles-scroll"
            (sorted)="onCredentialsSorted($event)"
            sortColumn="Name"
            sortDirection="asc"
            style="margin-top: 0">
            <thead>
                <tr>
                    <th sortable-column="Name">Name</th>
                    <th sortable-column="Type">Type</th>
                    <th sortable-column="Comment">Comment</th>
                    <th *ngIf="!readonly"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let credential of filteredCredentials">
                    <td>{{ credential.Name }}</td>
                    <td>{{ credential.Type }}</td>
                    <td>{{ credential.Comment }}</td>
                    <td class="collapsing" *ngIf="!readonly">
                        <itc-button
                            type="icon"
                            icon="fa-pencil"
                            (onclick)="showCredentialEditModal(credential)"
                            tooltip="Edit"
                            ttposition="top"></itc-button>
                        <itc-button
                            type="icon"
                            icon="fa-trash-can"
                            (onclick)="confirmCredentialDelete(credential)"
                            tooltip="Delete"
                            ttposition="top"
                            [tooltip]="
                                credential.isRunning
                                    ? 'You cannot delete a task while it\'s running.'
                                    : 'Delete'
                            "
                            [loading]="
                                credential == credentialToDelete && attemptingDelete
                            "></itc-button>
                    </td>
                </tr>
                <tr *ngIf="!credentials || credentials.length == 0">
                    <td colspan="4"><em>No entries.</em></td>
                </tr>
            </tbody>
        </table>
    </div>
</sds-loader>

<sm-modal
    title="{{ editMode ? 'Edit Existing Credential' : 'Add New Credential' }}"
    class="sm"
    #addCredentialModal>
    <modal-content>
        <form class="itc-form col" [formGroup]="addCredentialForm" novalidate>
            <p
                class="required field"
                [ngClass]="{
                    formError:
                        addCredentialForm.get('Name').dirty && addCredentialForm.get('Name').invalid
                }">
                <label for="Name">Name:</label>
                <input type="text" formControlName="Name" id="Name" maxlength="128" />
                <span class="errorText">Name is required and must be 128 characters or fewer.</span>
            </p>

            <p class="field">
                <label for="Comment">Comment:</label>
                <input type="text" formControlName="Comment" id="Comment" maxlength="256" />
            </p>

            <p
                class="required field"
                [ngClass]="{
                    formError:
                        addCredentialForm.get('Type').dirty && addCredentialForm.get('Type').invalid
                }">
                <label for="Type">Type:</label>
                <sm-select
                    class="form-control"
                    [control]="addCredentialForm.get('Type')"
                    id="Type"
                    [disabled]="editMode ? true : false">
                    <option *ngFor="let credType of credTypes" [value]="credType">
                        {{ credType }}
                    </option>
                </sm-select>
            </p>

            <div formGroupName="Value">
                <!-- Username + Password Type-->
                <div
                    *ngIf="addCredentialForm.get('Type').value === credTypes[0]"
                    formGroupName="UPGroup">
                    <p
                        class="required field"
                        [ngClass]="{
                            formError: UPGroup.AllowInsecure.dirty && UPGroup.AllowInsecure.invalid
                        }"
                        style="margin-bottom: 8px">
                        <label>Allow Insecure Use</label>
                        <br />
                        <itc-radio
                            [control]="UPGroup.AllowInsecure"
                            value="true"
                            label="Yes"></itc-radio>
                        <itc-radio
                            [control]="UPGroup.AllowInsecure"
                            value="false"
                            label="No"></itc-radio>
                        <span class="errorText">Allow Insecure Use choice is required.</span>
                    </p>

                    <div
                        class="inline required fields"
                        [ngClass]="{
                            formError:
                                (UPGroup.Password.invalid && UPGroup.Password.dirty) ||
                                (UPGroup.Username.dirty && UPGroup.Username.invalid)
                        }">
                        <p class="field">
                            <label for="Username">Username:</label>
                            <input
                                type="text"
                                formControlName="Username"
                                name="username"
                                id="Username"
                                autocomplete="new-password" />
                        </p>

                        <p class="field">
                            <label for="Password">Password:</label>
                            <input
                                [type]="showingPassword ? 'text' : 'password'"
                                formControlName="Password"
                                name="Password"
                                id="Password"
                                autocomplete="new-password"
                                [placeholder]="
                                    editMode &&
                                    addCredentialForm.get('Type').pristine &&
                                    UPGroup.Username.pristine &&
                                    UPGroup.Password.pristine
                                        ? '&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;'
                                        : ''
                                " />
                        </p>

                        <div class="errorText">
                            <span class="errorText" style="flex: 1">
                                Username and Password are required.
                            </span>
                            <itc-checkbox
                                label="Show Password"
                                name="showPassword"
                                [(ngModel)]="showingPassword"
                                [ngModelOptions]="{ standalone: true }"></itc-checkbox>
                        </div>
                    </div>
                </div>

                <!-- SNMP Type -->
                <div
                    *ngIf="addCredentialForm.get('Type').value == credTypes[1]"
                    formGroupName="SNMPGroup">
                    <p
                        class="required field"
                        [ngClass]="{
                            formError:
                                SNMPGroup.AllowInsecure.dirty && SNMPGroup.AllowInsecure.invalid
                        }"
                        style="margin-bottom: 8px">
                        <label>Allow Insecure Use</label>
                        <br />
                        <itc-radio
                            [control]="SNMPGroup.AllowInsecure"
                            value="true"
                            label="Yes"></itc-radio>
                        <itc-radio
                            [control]="SNMPGroup.AllowInsecure"
                            value="false"
                            label="No"></itc-radio>
                        <span class="errorText">Allow Insecure Use choice is required.</span>
                    </p>

                    <p
                        class="required"
                        [ngClass]="{
                            formError:
                                SNMPGroup.CommunityString.touched &&
                                SNMPGroup.CommunityString.invalid
                        }">
                        <label>Community String</label>
                        <input type="text" formControlName="CommunityString" />
                        <span class="errorText">Community string is required</span>
                    </p>
                </div>

                <!-- SNMP3 Type -->
                <div
                    *ngIf="addCredentialForm.get('Type').value == credTypes[2]"
                    formGroupName="SNMP3Group">
                    <p
                        class="required field"
                        [ngClass]="{
                            formError:
                                SNMP3Group.AllowInsecure.dirty && SNMP3Group.AllowInsecure.invalid
                        }"
                        style="margin-bottom: 8px">
                        <label for="allowInsecure">Allow Insecure Use</label>
                        <br />
                        <itc-radio
                            [control]="SNMP3Group.AllowInsecure"
                            value="true"
                            label="Yes"></itc-radio>
                        <itc-radio
                            [control]="SNMP3Group.AllowInsecure"
                            value="false"
                            label="No"></itc-radio>
                        <span class="errorText">Allow Insecure Use choice is required.</span>
                    </p>

                    <p
                        class="field"
                        [ngClass]="{
                            formError:
                                SNMP3Group.AuthAlgorithm.dirty && SNMP3Group.AuthAlgorithm.invalid
                        }"
                        style="margin-bottom: 8px">
                        <label for="allowInsecure">Auth Algorithm</label>
                        <br />
                        <itc-radio
                            name="authRadio"
                            [control]="SNMP3Group.AuthAlgorithm"
                            value="MD5"
                            label="MD5"></itc-radio>
                        <itc-radio
                            name="authRadio"
                            [control]="SNMP3Group.AuthAlgorithm"
                            value="SHA1"
                            label="SHA1"></itc-radio>
                        <span class="errorText">Auth Alogirthm is required</span>
                    </p>

                    <div
                        class="inline required fields"
                        [ngClass]="{
                            formError:
                                (SNMP3Group.Username.dirty && SNMP3Group.Username.invalid) ||
                                (SNMP3Group.Password.dirty && SNMP3Group.Password.invalid)
                        }">
                        <p class="field">
                            <label for="Username">Username</label>
                            <input
                                type="text"
                                formControlName="Username"
                                name="username"
                                id="Username"
                                autocomplete="new-password" />
                        </p>

                        <p class="field">
                            <label for="Password">Password</label>
                            <input
                                [type]="showingPassword ? 'text' : 'password'"
                                formControlName="Password"
                                name="Password"
                                id="Password"
                                autocomplete="new-password"
                                [placeholder]="
                                    editMode &&
                                    addCredentialForm.get('Type').pristine &&
                                    SNMP3Group.Username.pristine &&
                                    SNMP3Group.Password.pristine
                                        ? '&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;'
                                        : ''
                                " />
                        </p>

                        <div class="errorText">
                            <span class="errorText" style="flex: 1">
                                Username and Password are required
                            </span>
                            <itc-checkbox
                                label="Show Password"
                                name="showPassword"
                                [(ngModel)]="showingPassword"
                                [ngModelOptions]="{ standalone: true }"></itc-checkbox>
                        </div>
                    </div>

                    <p
                        class="required field"
                        [ngClass]="{
                            formError:
                                SNMP3Group.PrivacyAlgorithm.dirty &&
                                SNMP3Group.PrivacyAlgorithm.invalid
                        }"
                        style="margin-bottom: 8px">
                        <label for="allowInsecure">Privacy Algorithm</label>
                        <br />
                        <itc-radio
                            name="privacyRadio"
                            [control]="SNMP3Group.PrivacyAlgorithm"
                            value="AES"
                            label="AES"></itc-radio>
                        <itc-radio
                            name="privacyRadio"
                            [control]="SNMP3Group.PrivacyAlgorithm"
                            value="DES"
                            label="DES"></itc-radio>
                        <itc-radio
                            name="privacyRadio"
                            [control]="SNMP3Group.PrivacyAlgorithm"
                            value="None"
                            label="None"></itc-radio>
                        <span class="errorText">Privacy algorithm is required</span>
                    </p>

                    <p
                        class="required field"
                        *ngIf="SNMP3Group.PrivacyAlgorithm.value != 'None'"
                        [ngClass]="{
                            formError:
                                SNMP3Group.PrivatePassword.dirty &&
                                addCredentialForm.get('Value').get('SNMP3Group').errors
                                    ?.privatePasswordRequired
                        }">
                        <label class="required">Privacy Password</label>
                        <input
                            type="{{ showingPrivatePassword ? 'text' : 'password' }}"
                            autocomplete="new-password"
                            formControlName="PrivatePassword"
                            autocomplete="new-password"
                            style="margin-bottom: 0"
                            [placeholder]="
                                editMode &&
                                addCredentialForm.get('Type').pristine &&
                                SNMP3Group.PrivatePassword.pristine
                                    ? '&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;'
                                    : ''
                            " />
                        <span class="inlineErrorAndField">
                            <span class="errorText" style="flex: 1">
                                Privacy algorithm is required
                            </span>
                            <itc-checkbox
                                label="Show Privacy Password"
                                name="showPrivatePassword"
                                [(ngModel)]="showingPrivatePassword"
                                [ngModelOptions]="{ standalone: true }"></itc-checkbox>
                        </span>
                    </p>
                </div>
            </div>
        </form>
    </modal-content>
    <modal-actions>
        <itc-button label="Cancel" type="secondary" (onclick)="warnAndCancel()"></itc-button>
        <itc-button
            [label]="editMode ? 'Save' : 'Add'"
            type="primary"
            (onclick)="saveNewCredential()"
            [loading]="saving"
            [disabled]="
                !addCredentialForm.dirty || (addCredentialForm.dirty && addCredentialForm.invalid)
            "></itc-button>
    </modal-actions>
</sm-modal>

<sm-modal title="Confirm Delete" class="sm" #confirmDeleteCredentialModal>
    <modal-content>
        <div *ngIf="associatedScanTasks?.length > 0">
            <div class="content">
                The selected credential
                <b>{{ credentialToDelete?.Name }}</b>
                will be deleted from the following Scan Tasks:
            </div>
            <li
                style="margin-left: 10px; margin-top: 5px"
                *ngFor="let scanTask of associatedScanTasks">
                {{ scanTask.Label }}
            </li>
            <div style="margin-top: 25px">Do you wish to proceed?</div>
        </div>
        <div class="ui warning icon message" *ngIf="associatedScanTasks?.length == 0">
            <i class="warning sign icon"></i>
            <div class="content">
                Are you sure you want to delete this Scan Credential
                <b>{{ credentialToDelete?.Name }}?</b>
            </div>
        </div>
    </modal-content>
    <modal-actions>
        <itc-button
            label="Cancel"
            type="secondary"
            (onclick)="confirmDeleteCredentialModal.hide(); deleting = false"></itc-button>
        <itc-button
            label="Delete"
            type="danger"
            (onclick)="deleteCredential()"
            [loading]="deleting"></itc-button>
    </modal-actions>
</sm-modal>

<sm-modal title="Confirm Cancel" class="sm" #confirmCancelModal>
    <modal-content>
        <div class="ui warning icon message">
            <i class="warning sign icon"></i>
            <div class="content">
                <div class="header">
                    Credential data has changed. Are you sure you want to cancel?
                </div>
            </div>
        </div>
    </modal-content>
    <modal-actions>
        <itc-button
            label="No"
            type="secondary"
            (onclick)="confirmCancelModal.hide(); addCredentialModal.show()"></itc-button>
        <itc-button
            label="Yes"
            type="danger"
            (onclick)="confirmCancelModal.hide(); deleting = false; saving = false"></itc-button>
    </modal-actions>
</sm-modal>

<sm-modal title="Confirm Edit" class="sm" #confirmEditModal>
    <modal-content>
        <div class="content">
            The selected credential
            <b>{{ addCredentialForm.get('Name').value }}</b>
            will be updated in the following Scan Tasks:
        </div>
        <li style="margin-left: 10px; margin-top: 5px" *ngFor="let scanTask of associatedScanTasks">
            {{ scanTask.Label }}
        </li>
        <div style="margin-top: 25px">Do you wish to proceed?</div>
    </modal-content>
    <modal-actions>
        <itc-button
            label="Cancel"
            type="secondary"
            (onclick)="confirmEditModal.hide()"></itc-button>
        <itc-button
            label="Ok"
            type="primary"
            (onclick)="confirmEdit()"
            [loading]="saving"></itc-button>
    </modal-actions>
</sm-modal>
