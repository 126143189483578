import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    standalone: true,
    name: 'timeAgo',
})
export class TimeAgoPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        var now = new Date();
        var then = new Date(value);

        var dif = Math.abs((now.getTime() - then.getTime()) / 1000);

        if (dif / 60 < 1) {
            return Math.floor(dif) + ' sec ago';
        } else {
            dif = dif / 60;
            if (dif / 60 < 1) {
                return Math.floor(dif) + ' min ago';
            } else {
                dif = dif / 60;
                if (dif / 24 < 1) {
                    return Math.floor(dif) + ' hrs ago';
                } else {
                    dif = dif / 24;
                    return Math.floor(dif) + ' days ago';
                }
            }
        }
    }
}
