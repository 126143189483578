<div class="ui card">
    <div class="content">
        <div class="header">
            RELATED ITEMS
            <!--<a href="#" class="settings"><i class="setting icon"></i></a>-->
            <a (click)="addDialog()" class="viewMoreLink">ADD</a>
        </div>
    </div>
    <div class="content">
        <div *ngIf="!_items?.length">No items yet. Add some.</div>
        <!--ngIF this to show when null or 0 items-->
        <ul *ngIf="_items?.length" class="striped">
            <!--ngIf this to show when >0 items and ngFor this over items-->
            <li *ngFor="let item of _items" style="display: flex; align-items: center">
                <div
                    class="header"
                    style="
                        display: inline-block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        margin-right: 20px;
                        vertical-align: middle;
                    ">
                    <a
                        routerLink="../../../{{ item.Type.toLowerCase() }}s/{{
                            item.RelatedUID
                        }}/detail"
                        [title]="returnDisplayName(item)">
                        <i class="icon {{ item.Icon.toLowerCase() }}"></i>
                        <!--change icon by type-->
                        {{ returnDisplayName(item) }}
                    </a>
                </div>
                <div
                    style="
                        font-size: 10px;
                        display: inline-block;
                        flex: 1;
                        text-align: right;
                        white-space: nowrap;
                    ">
                    {{ item.Type.toUpperCase() }}S
                </div>
                <div style="margin-left: 20px" (click)="confirmDelete(item.RelatedUID)">
                    <a><i class="trash icon"></i></a>
                </div>
            </li>
        </ul>
    </div>
</div>

<sm-modal class="md" title="Add Related Item" #addItemModal>
    <modal-content>
        <div>
            <div class="ui secondary menu filterPaginate">
                <form name="relatedItemsSearchForm" (submit)="onSearch()">
                    <div class="ui right action input">
                        <sm-input placeholder="Search..." [control]="searchControl"></sm-input>
                        <a
                            class="ui icon button darker"
                            [class.loading]="searching"
                            (click)="onSearch()"
                            style="height: 32px">
                            <!-- <i class="search icon" [style.opacity]="searching ? 0 : 1" style="font-size:16px;"></i> -->
                            Search
                        </a>
                    </div>
                </form>
            </div>

            <form name="addRelatedItemsForm">
                <table sortable-table class="itc-table" id="itemsTable" (sorted)="onSorted($event)">
                    <thead>
                        <tr>
                            <th></th>
                            <th sortable-column="Name">Name</th>
                            <th sortable-column="DisplayName">DisplayName</th>
                            <th sortable-column="Type">Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of pagedSearchItems; let i = index">
                            <td><itc-checkbox [control]="item.cb" label=" "></itc-checkbox></td>
                            <td (click)="item.cb.setValue(!item.cb.value)">{{ item.Name }}</td>
                            <td (click)="item.cb.setValue(!item.cb.value)">
                                {{ item.DisplayName }}
                            </td>
                            <td (click)="item.cb.setValue(!item.cb.value)">{{ item.Type }}</td>
                        </tr>
                    </tbody>
                </table>

                <itc-paginator
                    [data]="filteredSearchItems"
                    (onPage)="pagedSearchItems = $event"></itc-paginator>

                <div *ngIf="pagedSearchItems?.length == 0">
                    <br />
                    <itc-banner
                        type="info"
                        title="Enter search criteria to display users or computers">
                        No user or computer items were found matching the given criteria.
                    </itc-banner>
                </div>
            </form>
        </div>
    </modal-content>
    <modal-actions>
        <itc-button
            type="secondary"
            class="cancel"
            (onclick)="addItemModal.hide(); clearModalItems()"
            [disabled]="addingItems">
            Cancel
        </itc-button>
        <itc-button
            type="primary"
            (onclick)="addItems()"
            [loading]="addingItems"
            [disabled]="addingItems">
            Add
        </itc-button>
    </modal-actions>
</sm-modal>
