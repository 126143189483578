<div
    class="download_page"
    [class.light]="theme == 'light'"
    [class.customBranded]="customBranding"
    style="height: 100vh; overflow: auto">
    <span class="topdesign"></span>
    <span class="leftdesign"></span>
    <!--
    <a class="kaseya_logo ir" href="https://www.kaseya.com/" *ngIf="!customBranding">Kaseya</a>
    <a class="itc_launcher ir" href="https://www.kaseya.com/" *ngIf="!customBranding">ITC</a>
    -->

    <div class="download_container">
        <div class="download_header">
            <div class="company_logo ir" *ngIf="!(customBranding && logoImage)">
                RapidFire Tools
            </div>
            <div *ngIf="customBranding && logoImage">
                <img [src]="logoImage" width="240" />
            </div>
        </div>
        <div class="download_item download">
            <div class="iconContainer">
                <img src="/assets/img/downloads/download-icon-windows.svg" />
            </div>
            <p class="name">End-User Initiated Computer Scanner (Windows)</p>
            <p class="shortText">
                The End-User Initiated Computer Scanner is a Windows application that is run on
                individual computers (workstations or servers) to collect information for each
                system.
            </p>
            <div class="downloadSection">
                <br />
                <br />
                <itc-button
                    type="primary"
                    [loading]="isloading"
                    [disabled]="!downloadEnabled"
                    (onclick)="download()">
                    Download
                </itc-button>
                <br />
                <b *ngIf="!downloadEnabled">
                    Contact your IT administrator to regenerate this download.
                </b>
            </div>
        </div>
        <div class="download_item download" style="border-right: 0">
            <!--             <div class="iconContainer">
                <img src="/assets/img/downloads/download-icon-mac.svg">
            </div>
            <p class="name">Computer Scan macOS Data Collector</p>
            <p class="shortText">The Mac OS Computer Data Collector is a Mac application (OS X Snow Leopard or later) that is run on individual computers (workstations or servers) to collect information for that system.</p>
            <a href="ahttps://download.rapidfiretools.com/download/ComputerScanMacCollector.zip">Download</a> -->
        </div>
        <div class="download_item download">
            <!-- <div class="iconContainer">
                <img src="/assets/img/downloads/download-icon-linux.svg">
            </div>
            <p class="name">Computer Scan Linux Data Collector</p>
            <p class="shortText">The Linux Computer Data Collector is a Linux application (works on most modern Linux versions) that is run on individual computers (workstations or servers) to collect information for that system.</p>
            <a href="https://download.rapidfiretools.com/download/ComputerScanLinuxCollector.tar.gz">Download</a> -->
        </div>
    </div>
</div>
