import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ItcProgressTrackerComponent } from './progress-tracker.component';
import { ItcProgressTrackerStepComponent } from './progress-tracker-step.component';
import { ItcButtonComponent } from '../button/button.component';

@NgModule({
    imports: [CommonModule, ItcButtonComponent, FontAwesomeModule],
    declarations: [ItcProgressTrackerComponent, ItcProgressTrackerStepComponent],
    exports: [ItcProgressTrackerComponent, ItcProgressTrackerStepComponent],
    providers: [],
})
export class ItcProgressTrackerModule {}
