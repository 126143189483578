import { Notes } from '../../sites/shared/notes/notes.model';

export class ScanDataDatabase {
    public Id: number;
    public Domain: string;
    public Server: string;
    public Instance: string;
    public Version: string;
    public Databases: number;
    public Jobs: number;

    public Notes: Notes;
    public Procedures: Notes[];
    public RelatedItems: string[];
    public SmartTags: any[];
}
