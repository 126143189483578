import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'siteDecode',
})
export class SiteDecodePipe implements PipeTransform {
    transform(value: string): string {
        return (value ? value : '').replace(/%26/g, '&');
    }
}
