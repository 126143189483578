import { Component, OnInit } from '@angular/core';
import { VendorsInfo } from './vendors.model';
import { CM_COLORS } from '../../shared/cm-summary.constants';
import { PrintService } from 'app/sites/shared/print.service';

@Component({
    selector: 'sds-printable-vendors',
    templateUrl: './printable-vendors.component.html',
    styleUrls: ['./printable-vendors.component.scss'],
})
export class PrintableVendorsComponent implements OnInit {
    CM_COLORS = CM_COLORS;
    vendorsInfo: VendorsInfo;

    responseColors = {
        'Yes, Fully': CM_COLORS.COVERED,
        'Yes, Partially': CM_COLORS.PARTIAL,
        'Unsure': CM_COLORS.UNSURE,
        'No': CM_COLORS.NOTCOVERED,
        'Addressed by Controls': CM_COLORS.COVERED,
        'Addressed': CM_COLORS.COVERED,
        'Not Addressed': CM_COLORS.NOTCOVERED,
        '': CM_COLORS.NOTASSESSED,
        'Yes, With Issues': CM_COLORS.PARTIAL,
        'Selected': CM_COLORS.COVERED,
        'Not Selected': CM_COLORS.NOTCOVERED,
        'Not Assessed': CM_COLORS.NOTASSESSED,
    };

    constructor(private printService: PrintService) {}

    ngOnInit() {
        this.vendorsInfo = this.printService.VendorsData;

        Promise.resolve(this.vendorsInfo).then(() => {
            setTimeout(() => {
                this.printService.onDataReady();
            }, 500);
        });
    }
}
