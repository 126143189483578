import { Component, Input, Output, EventEmitter, HostListener, ViewChild } from '@angular/core';

import { Observable, interval, merge, Subject } from 'rxjs';
import { debounce, takeUntil } from 'rxjs/operators';

declare var $: any;

@Component({
    selector: 'ngxtimeout',
    templateUrl: './ngx-timeout.component.html',
    styleUrls: ['./ngx-timeout.component.css'],
})
export class NgxTimeoutComponent {
    @ViewChild('warningModal', { static: true }) warningModal: any;
    @ViewChild('timedOutModal', { static: true }) timedOutModal: any;
    showingModal: boolean = false;
    timedOut: boolean = false;
    ngUnsubscribe: Subject<any> = new Subject();

    private mousemove = new EventEmitter();
    private mousedown = new EventEmitter();
    private keypress = new EventEmitter();
    private observables;
    private timeoutId: any;
    private warningId: any;

    /**
     * Inactivity timeout limit (defaults 20 minutes)
     */
    @Input() ngxInactivityTimeout: number = 20;

    /**
     * How soon to warn before timeout (defaults 5 minutes)
     */
    @Input() ngxInactivityWarning: number = 5;

    /**
     * Inactivity callback after timeout
     */
    @Output() ngxTimeoutCallback = new EventEmitter();

    /**
     * A final function to run after user closes modal and before the component goes away
     */
    @Output() ngxTimeoutEnd = new EventEmitter();

    /**
     * Attach a mouse move listener
     */
    @HostListener('document:mousemove', ['$event'])
    onMousemove(event) {
        this.mousemove.emit(event);
    }

    /**
     * Atach a mouse down listener
     */
    @HostListener('document:mousedown', ['$event'])
    onMousedown(event) {
        this.mousedown.emit(event);
    }

    /**
     * Attach a key press listener
     */
    @HostListener('document:keypress', ['$event'])
    onKeypress(event) {
        this.keypress.emit(event);
    }

    constructor() {
        /*
         * Merge to flattens multiple Observables together
         * by blending their values into one Observable
         */
        this.observables = merge(this.mousemove, this.mousedown, this.keypress);

        this.observables
            .pipe(
                /*
                 * Debounce to emits a value from the source Observable
                 * only after a particular time span
                 */
                debounce(() => interval(1000)),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(() => {
                /*
                 * Subscribe to handle emitted values
                 */
                this.reset();
                this.start();
            });
    }

    ngOnInit() {
        this.start();
    }

    /**
     * Start inactivity timer
     */
    public start(): void {
        /**
         * Inactivity callback if timeout (in minutes) is exceeded
         */
        if (!this.timedOut) {
            // console.log('Inactivity: Timer Started');
            // if warning is set properly and less than timeout add warning timer
            if (
                this.ngxInactivityWarning < this.ngxInactivityTimeout &&
                this.ngxInactivityWarning !== 0
            ) {
                let warningTime = this.ngxInactivityTimeout - this.ngxInactivityWarning;
                this.warningId = setTimeout(() => this.showWarning(), warningTime * 60000);
            }
            // add timeout timer.
            if (!this.timedOut) {
                this.timeoutId = setTimeout(
                    () => this.showTimeout(),
                    this.ngxInactivityTimeout * 60000
                );
            }
        }
    }
    /**
     * Reset inactivity timer
     */
    public reset(): void {
        if (!this.timedOut) {
            // console.log('Inactivity: timer reset');
            clearTimeout(this.timeoutId);
            clearTimeout(this.warningId);
        }
    }

    showWarning(): void {
        console.log('Inactivity: warning shown');
        this.warningModal.show();
        this.showingModal = true;
    }

    showTimeout(): void {
        this.ngxTimeoutCallback.emit(true);
        this.warningModal.hide();
        this.timedOutModal.show({ closable: false });
        console.log('Inactivity: Timeout shown');
        this.timedOut = true;
    }

    timeoutEnd(): void {
        this.warningModal.hide();
        this.timedOutModal.hide();
        this.ngxTimeoutEnd.emit(true);
    }

    ngOnDestroy() {
        this.timedOut = true;
        clearTimeout(this.timeoutId);
        clearTimeout(this.warningId);
        this.warningModal.hide();
        this.timedOutModal.hide();
        this.ngUnsubscribe.next(void 0);
        this.ngUnsubscribe.complete();
        // console.log('Killed timeouts on destroy');
    }
}
