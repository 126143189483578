import { Component, OnInit } from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { AuthService } from 'app/core/auth';

import { NotificationService } from '../shared/itc/notification/notification.service';
import { Subject, map, switchMap, takeUntil } from 'rxjs';
import { notBlank } from '../shared/not-blank.validator';

@Component({
   selector: 'pwd-reset-form',
   templateUrl: './pwd-reset.component.html',
   styleUrls: ['./pwd-reset.component.scss'],
})
export class PwdResetComponent implements OnInit {
    constructor(
        private route: ActivatedRoute,
        private authService: AuthService,
        private notificationService: NotificationService,
        private fb: FormBuilder,
    ) {}

    hashLoading: boolean = true;

    loading: boolean = false;
   
    passwordForm = this.fb.group({
        newPass: ['', notBlank],
    });
    
    hash: string;
    isHashInvalid: boolean = false;
    resetSuccessful:boolean = false;

    ngUnsubscribe$ = new Subject<boolean>();

    pwdvalue$ = new Subject<KeyboardEvent>();

    passwordCheckMessage: string;

    ngOnInit() {
        this.hashLoading = true;

        this.isHashInvalid = false;
        this.resetSuccessful = false;
        

        this.route.queryParamMap
            .pipe(map(qp => qp.get('id')))
            .pipe(switchMap(id => {
                this.hash = id;
                return this.authService.isResetPwdGuidValid(id)
            }))
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                {
                    next: ok => {
                        this.isHashInvalid = !ok;
                        this.hashLoading = false;
                    },
                    error: err => {
                        this.isHashInvalid = true;
                        this.hashLoading = false;
                        console.log(err);
                    }
                }
        )

       
    }

   

    submit() {

        if (this.passwordCheckMessage) {
            return;
        }

        this.loading = true;

        this.authService
            .resetPassword(this.hash, this.passwordForm.get('newPass').value)
            .then(result => {
                this.loading = false;
                if (result) {
                    this.isHashInvalid = false;
                    this.resetSuccessful = true;
                } else {
                    this.isHashInvalid = true;
                    this.resetSuccessful = false;
                }
            })
            .catch((err) => {
                this.loading = false;
                this.notificationService.toast.error("Error", "error occurred resetting password");
                console.log(err);
            })
    }

    
    ngOnDestroy() {
        this.ngUnsubscribe$.next(true);
        this.ngUnsubscribe$.complete();
    }
}
