import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { FormService } from 'app/core/form.service';
import { MessageService } from 'app/core/message.service';
import { UiService } from 'app/core/ui.service';
import { Site } from 'app/sites/shared/site.model';
import {
    FmitUser,
    SiteUser,
    SiteUsersService,
    UserGroup,
} from 'app/sites/shared/site-users.service';
import { PING_SITE, SITE } from 'app/sites/shared/constants';
import { Subscription, lastValueFrom } from 'rxjs';
import { TableSorting } from 'app/shared/sortable-table/sortable-table.component';
import { environment } from 'environments/environment';
import { debounceTime } from 'rxjs/operators';
import { AuthHttp, AuthService, Roles } from 'app/core/auth';
import { Router } from '@angular/router';
import { SiteEncodePipe } from 'app/shared/siteEncode.pipe';
import { SiteService } from 'app/sites/shared/site.service';
import { NotificationService } from '../../../../shared/itc/notification/notification.service';

declare var $;

/*class User {
  public email: string;
  public firstName: string;
  public lastName: string;
  public enabled: boolean;
  public roles: any;
}*/

@Component({
    selector: 'sds-user-management',
    templateUrl: './user-management.component.html',
    styleUrls: ['./user-management.component.css'],
})
export class UserManagementComponent implements OnInit, OnDestroy {
    constructor(
        private messageService: MessageService,
        private siteUsersService: SiteUsersService,
        private notificationService: NotificationService,
        private uiService: UiService,
        private http: AuthHttp,
        private router: Router,
        private siteEncodePipe: SiteEncodePipe,
        private siteService: SiteService,
        private authService: AuthService
    ) {
        this.apiUrl = environment.apiUrl;
    }

    @ViewChild('userAddModal', { static: true }) userAddModal: any;
    @ViewChild('userDeleteModal', { static: true }) userDeleteModal: any;
    @ViewChild('userInviteModal', { static: true }) userInviteModal: any;
    @ViewChild('noRolInviteModal', { static: true }) noRolInviteModal: any;

    isSiteRestricted: boolean;
    siteUsers: SiteUser[];
    fmitUsers: FmitUser[];
    sortedUsers: SiteUser[];
    newUserType: string = 'existing';
    existingSelection = [];
    selectionError: boolean;
    modalStatus: string = 'tabStatus';
    groups: UserGroup[] = [];

    addUserWaiting: boolean = false;
    deleteUserWaiting: boolean = false;

    filter: string = '_all';
    apiUrl: string;
    msgSub: Subscription;
    site: Site;

    isNdPro: boolean;
    isCyberHawk: boolean;
    isVulScan: boolean;

    selectedUser: SiteUser;

    loadingComplete: boolean;

    filters: any = [{ value: 'a', label: 'A Filter' }];

    breadcrumbs = [
        { path: '..', text: 'Home' },
        { path: '.', text: 'Users' },
    ];

    ngOnInit() {
        this.msgSub = this.messageService.on(SITE).subscribe((site: Site) => {
            if (!site) return;

            this.loadingComplete = false;
            this.site = site;
            this.uiService.setTitle('Users', site.Name);
            let user = this.authService.getIdentity();
            let roleId = parseInt(user.roleId, 10);
            this.isSiteRestricted = [Roles.SiteRestricted].indexOf(roleId) > -1;
            this.getSiteUsers();
            this.getSiteGroups();

            this.isNdPro = this.siteService.isNDProWeb(site);
            this.isCyberHawk = this.siteService.isCyberHawk(site);
            this.isVulScan = this.siteService.isKVS(site);
        });

        this.messageService.broadcast(PING_SITE);
    }

    ngOnDestroy() {
        this.msgSub.unsubscribe();
    }

    getSiteUsers() {
        this.siteUsersService
            .getSiteUsers(this.site.Id)
            .then((users) => {
                this.siteUsers = users;
                this.sortUsers();

                this.siteUsersService.getExistingUsersForSite(this.site.Id).then((exUsers) => {
                    this.fmitUsers = exUsers
                        .filter((u) => {
                            return this.siteUsers.findIndex((v) => v.Username == u.Username) < 0;
                        })
                        .sort((a, b) =>
                            a.Username.toLowerCase().localeCompare(b.Username.toLowerCase())
                        );

                    this.loadingComplete = true;
                });
            })
            .catch((err) => {
                this.loadingComplete = true;
                console.error(err);
            });
    }
    getSiteGroups() {
        this.siteUsersService
            .getSiteUserGroups(this.site.Id)
            .then((groups) => {
                this.groups = groups;
            })
            .catch((err) => {
                console.error(err);
            });
    }
    getGroupsByUserId(userId: number) {
        let rVal: UserGroup[] = [];
        if (this.groups != null) {
            for (let i = 0; i < this.groups.length; i++) {
                if (this.groups[i].Users.includes(userId)) {
                    rVal.push(this.groups[i]);
                }
            }
        }
        return rVal;
    }

    showUserDeleteModal(user: SiteUser) {
        this.selectedUser = user;
        this.userDeleteModal.show();
    }

    showUserInviteModal(user: SiteUser) {
        this.selectedUser = user;
        this.userInviteModal.show();
    }

    showNoRolInviteModal() {
        this.noRolInviteModal.show();
    }

    addUser(user: SiteUser) {
        this.siteUsers.push(user);
        this.sortUsers();
    }

    addExisting(users: SiteUser[]) {
        users.forEach((u) => {
            this.fmitUsers.splice(
                this.fmitUsers.findIndex((v) => v.Username == u.Username),
                1
            );
        });
        this.fmitUsers = [...this.fmitUsers];
        this.siteUsers = [...this.siteUsers, ...users];
        this.sortUsers();
    }

    removeUser(user: SiteUser) {
        this.deleteUserWaiting = true;
        this.siteUsersService
            .deleteSiteUserRole(user)
            .then((res) => {
                this.deleteUserWaiting = false;
                this.siteUsers.splice(
                    this.siteUsers.findIndex((u) => u.UserId == user.UserId),
                    1
                );
                this.notificationService.toast.success(
                    'Success',
                    'User was successfully removed from the site.'
                );

                this.userDeleteModal.hide();

                this.getSiteUsers();
            })
            .catch((err) => {
                console.error(err);
                this.deleteUserWaiting = false;
                this.notificationService.toast.error('Error', 'Something went wrong.');
            });
    }

    sendInvitation(siteUser: SiteUser) {
        this.siteUsersService.getMyUser().then((s) => {
            let user = s;
            if (siteUser.Role && user) {
                this.notificationService.toast.info(
                    'Processing',
                    'Processing Requested Invitations'
                );

                let invitation: Invitation;

                if (this.isCyberHawk) {
                    invitation = this.getCyberHawkInvitation(siteUser, user);
                }
                if (this.isVulScan) {
                    invitation = this.getKVSInvitation(siteUser, user);
                } else if (this.isNdPro) {
                    invitation = this.getNDProInvitation(siteUser, user);
                } else {
                    invitation = this.getCMGRCInvitation(siteUser, user);
                }

                this.sendInformInvitation(invitation)
                    .then((s) => {
                        this.notificationService.toast.success('Success', 'Invitations Sent');
                    })
                    .catch((r) => {
                        this.notificationService.toast.error(
                            'Error',
                            'Error occured during processing.  Invitations not sent.'
                        );
                    });
            } else this.showNoRolInviteModal();

            this.userInviteModal.hide();
        });
    }

    getNDProInvitation(siteUser: SiteUser, user: SiteUser): Invitation {
        let urlTree = this.router.createUrlTree([
            'site',
            this.siteEncodePipe.transform(this.site.Name),
            'network-detective-pro',
        ]);
        let urlSite = 'https://' + window.location.host + urlTree.toString();

        let invitation = new Invitation();
        invitation.Recipients = [];
        invitation.Recipients.push(siteUser.Username);
        invitation.Subject = 'Assistance Requested';
        invitation.SiteId = this.site.Id;
        invitation.Body = `You have been invited as a user to access the Network Detective Pro Site ${this.site.Name} using the Kaseya-RapidFire Tools Your IT Portal.\n
     <b>First time users of the Your IT Portal should note the following:</b>\n
     If you have never logged into the Your IT Portal previously, you have been assigned a temporary password by the Site Administrator referenced below.\n
     If you are a first time user of the Your IT Portal, please visit this page <a href="https://www.youritportal.com/login#reset-password">https://www.youritportal.com/login#reset-password</a> to create a new password for your user account.\n
     Once you have created your new password, access the Network Detective Pro site by logging into the Your IT Portal <a href="${urlSite}">${urlSite}</a>.\n
     This request was made by ${user.FirstName} (${user.Username}).`;

        return invitation;
    }

    getCMGRCInvitation(siteUser: SiteUser, user: SiteUser): Invitation {
        let urlTree = this.router.createUrlTree([
            'site',
            this.siteEncodePipe.transform(this.site.Name),
            'compliance-manager-grc',
            'my-work',
        ]);
        let urlSite = 'https://' + window.location.host + urlTree.toString();

        let invitation = new Invitation();
        invitation.Recipients = [];
        invitation.Recipients.push(siteUser.Username);
        invitation.Subject = 'Assessment Assistance Requested';
        invitation.SiteId = this.site.Id;
        invitation.Body = `You have been invited as a user to access the Compliance Manager GRC Site ${this.site.Name} using the Kaseya-RapidFire Tools Your IT Portal.\n
     <b>First time users of the Your IT Portal should note the following:</b>\n
     If you have never logged into the Your IT Portal previously, you have been assigned a temporary password by the Site Administrator referenced below.\n
     If you are a first time user of the Your IT Portal, please visit this page <a href="https://www.youritportal.com/login#reset-password">https://www.youritportal.com/login#reset-password</a> to create a new password for your user account.\n
     Once you have created your new password, access the Compliance Manager GRC site by logging into the Your IT Portal <a href="${urlSite}">${urlSite}</a>.\n
     This request was made by ${user.FirstName} (${user.Username}).`;

        return invitation;
    }

    getCyberHawkInvitation(siteUser: SiteUser, user: SiteUser): Invitation {
        let urlTree = this.router.createUrlTree([
            'site',
            this.siteEncodePipe.transform(this.site.Name),
            'cyber-hawk',
        ]);
        let urlSite = 'https://' + window.location.host + urlTree.toString();

        let invitation = new Invitation();
        invitation.Recipients = [];
        invitation.Recipients.push(siteUser.Username);
        invitation.Subject = 'Assistance Requested';
        invitation.SiteId = this.site.Id;
        invitation.Body = `You have been invited as a user to access the Cyber Hawk Site ${this.site.Name} using the Kaseya-RapidFire Tools Your IT Portal.\n
     <b>First-time users of the Your IT Portal should note the following:</b>\n
     If you have never logged into the Your IT Portal previously, you have been assigned a temporary password by the Site Administrator referenced below.\n
     If you are a first-time user of the Your IT Portal, please visit this page <a href="https://www.youritportal.com/login#reset-password">https://www.youritportal.com/login#reset-password</a> to create a new password for your user account.\n
     Once you have created your new password, access the Cyber Hawk site by logging into the Your IT Portal <a href="${urlSite}">${urlSite}</a>.\n
     This request was made by ${user.FirstName} (${user.Username}).`;

        return invitation;
    }

    getKVSInvitation(siteUser: SiteUser, user: SiteUser): Invitation {
        let urlTree = this.router.createUrlTree([
            'site',
            this.siteEncodePipe.transform(this.site.Name),
            'vulnerability-scanner',
        ]);
        let urlSite = 'https://' + window.location.host + urlTree.toString();

        let invitation = new Invitation();
        invitation.Recipients = [];
        invitation.Recipients.push(siteUser.Username);
        invitation.Subject = 'Assistance Requested';
        invitation.SiteId = this.site.Id;
        invitation.Body = `You have been invited as a user to access the VulScan Site ${this.site.Name} using the Kaseya-RapidFire Tools Your IT Portal.\n
     <b>First-time users of the Your IT Portal should note the following:</b>\n
     If you have never logged into the Your IT Portal previously, you have been assigned a temporary password by the Site Administrator referenced below.\n
     If you are a first-time user of the Your IT Portal, please visit this page <a href="https://www.youritportal.com/login#reset-password">https://www.youritportal.com/login#reset-password</a> to create a new password for your user account.\n
     Once you have created your new password,  access the VulScan site by logging into the Your IT Portal <a href="${urlSite}">${urlSite}</a>.\n
     This request was made by ${user.FirstName} (${user.Username}).`;

        return invitation;
    }

    sortUsers(sorting?: TableSorting) {
        if (!sorting || sorting == undefined || sorting.sortColumn == undefined) {
            this.sortedUsers = this.siteUsers.sort((a, b) =>
                a.Username.toLowerCase().localeCompare(b.Username.toLowerCase())
            );
        } else {
            let sortedUsers = this.siteUsers.sort((a, b) => {
                if (sorting.sortColumn == 'roleText') {
                    return this.getUserRoleName(a.Role)
                        .toLowerCase()
                        .localeCompare(this.getUserRoleName(b.Role).toLowerCase());
                } else if (sorting.sortColumn == 'groupsText') {
                    return (
                        this.getGroupsByUserId(a.UserId).length -
                        this.getGroupsByUserId(b.UserId).length
                    );
                } else {
                    return a[sorting.sortColumn]
                        .toLowerCase()
                        .localeCompare(b[sorting.sortColumn].toLowerCase());
                }
            });

            if (sorting.sortDirection == 'desc') {
                this.sortedUsers = sortedUsers.reverse();
            } else {
                this.sortedUsers = sortedUsers;
            }
        }
    }

    getUserRoleName(roleValue: string) {
        if (!roleValue) return 'Unassigned';
        let roles = roleValue.split(',');
        let rs = [];
        for (let role of roles) {
            switch (role) {
                case 'SDS_SITEADMIN':
                    rs.push('Site Admin');
                    break;
                case 'SDS_TECH':
                    rs.push('Technician');
                    break;
                case 'SDS_AUDITOR':
                    rs.push('Internal Auditor');
                    break;
                case 'AG_SME':
                    rs.push('Subject Matter Expert');
                    break;
                case 'IND_CLIENT':
                    rs.push('Client');
                    break;
                case 'GRC_REPORT_VIEWER':
                    rs.push('Reports Viewer');
                    break;
                case 'EMP_PORTAL_ADMIN':
                    rs.push('Employee Portal Admin');
                    break;
            }
        }
        return rs.join(', ');
    }

    sendInformInvitation(req: Invitation): Promise<any> {
        const url: string = this.apiUrl + 'Inform/SendInvitation';
        return lastValueFrom(this.http.post(url, req, { observe: 'response' })).then(
            (res) => res.status == 200
        );
    }
}

export class Invitation {
    public Recipients: string[];
    public Subject: string;
    public Body: string;
    public Formname: string;
    public SiteId: number;
}
