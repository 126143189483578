import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrintLayoutComponent } from './print-layout.component';
import { AppRoutingModule } from '../app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { PrintService } from 'app/sites/shared/print.service';

@NgModule({
    imports: [CommonModule, AppRoutingModule, BrowserModule],
    providers: [PrintService],
    declarations: [PrintLayoutComponent],
})
export class PrintModule {}
