import { Component, EventEmitter, Input, Output, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ScanDataService, ScanDataUser } from 'app/scans';
import { Subscription } from 'rxjs';
import { FilterOption } from '../data-filter.model';

@Component({
    selector: 'sds-data-filter',
    templateUrl: './data-filter.component.html',
})
export class DataFilterComponent implements OnInit {
    constructor() {}

    @Input()
    set data(value: ScanDataUser[]) {
        if (value) {
            this._data = value;
            this.filter();
        }
    }

    @Input() filterOptions: FilterOption[];
    @Output() filteredData = new EventEmitter<any[]>();
    private _data: ScanDataUser[];
    private subscr: Subscription;

    itemsCtrl: UntypedFormControl = new UntypedFormControl();
    @ViewChild('itemSelect', { static: true }) itemSelect: any;

    ngOnInit() {
        this.subscr = this.itemsCtrl.valueChanges.subscribe(() => this.filter());
    }

    ngAfterViewInit() {
        let z = this.filterOptions[0].id;
        this.itemsCtrl.setValue(z);
        this.itemSelect.setSelected(z);
    }

    ngOnDestroy() {
        this.subscr.unsubscribe();
    }

    filter() {
        if (this._data) {
            let _filteredData: ScanDataUser[] = [];

            if (this.itemsCtrl.value === '_active') {
                _filteredData = this._data.filter((item) => {
                    return item.hasOwnProperty('Active') && item.Active === true;
                });
            } else if (this.itemsCtrl.value === '_inactive') {
                _filteredData = this._data.filter((item) => {
                    return item.hasOwnProperty('Active') && item.Active === false;
                });
            } else if (this.itemsCtrl.value === '_enabled') {
                _filteredData = this._data.filter((item) => {
                    return item.hasOwnProperty('Enabled') && item.Enabled === true;
                });
            } else if (this.itemsCtrl.value === '_notEnabled') {
                _filteredData = this._data.filter((item) => {
                    return item.hasOwnProperty('Enabled') && item.Enabled === false;
                });
            } else _filteredData = this._data;

            console.log(_filteredData);
            this.filteredData.emit(_filteredData);
        }
    }
}
