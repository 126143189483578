import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SemanticModalComponent, SMModalTagsDirective } from './components/modal/modal';

import { SemanticButtonComponent } from './components/button/button';

import { SemanticSelectComponent } from './components/select/select';

import {
    SemanticAccordionComponent,
    SemanticAccordionItemComponent,
} from './components/accordion/accordion';

import { SemanticItemComponent } from './components/item/item';

import {
    SemanticInputComponent,
    SemanticTextareaComponent,
    SemanticCheckboxComponent,
} from './components/input/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SemanticDropdownComponent } from './components/dropdown/dropdown';
import { SemanticLoaderComponent } from './components/loader/loader';
// import { BrowserModule } from '@angular/platform-browser';
// import { SemanticTabsComponent, SemanticTabComponent } from "./components/tab/tab";

export * from './components/button/button';
export * from './components/modal/modal';
export * from './components/input/input';
export * from './components/dropdown/dropdown';
export * from './components/select/select';
export * from './components/loader/loader';
export * from './components/accordion/accordion';
export * from './components/item/item';
// export * from "./components/tab/tab";

export let SEMANTIC_COMPONENTS: Array<any> = [
    SemanticInputComponent,
    SemanticTextareaComponent,
    SemanticCheckboxComponent,
    SemanticDropdownComponent,
    SemanticSelectComponent,
    SemanticLoaderComponent,
    SemanticModalComponent,
    SemanticAccordionComponent,
    SemanticAccordionItemComponent,
    SemanticButtonComponent,
    SemanticItemComponent,
];

export let SEMANTIC_DIRECTIVES: Array<any> = [
    SMModalTagsDirective, // directives with no functionality, simply declare tags
];

@NgModule({
    declarations: [SEMANTIC_DIRECTIVES, SEMANTIC_COMPONENTS],
    exports: [SEMANTIC_COMPONENTS, SEMANTIC_DIRECTIVES],
    imports: [CommonModule, FormsModule, ReactiveFormsModule],
})
export class SemanticLegacyModule {}
