<h2>Rapid Baseline Printable Report</h2>

<div class="donutsContainer">
    <div class="cmPrintCard" *ngFor="let standard of rapidBaselineInfo.ActiveStandards">
        <cm-summary-donut
            style="display: inline-flex; vertical-align: top; cursor: pointer"
            [standard]="
                standard.Name + (standard.Name != standard.Variant ? ' ' + standard.Variant : '')
            "
            [centerText]="
                rapidBaselineInfo.Results[standard.Id].ChartData[0].count /
                    rapidBaselineInfo.Results[standard.Id].Standard.Requirements.length | percent
            "
            [isRapidBaseline]="true"
            [chartData]="rapidBaselineInfo.Results[standard.Id].ChartData"></cm-summary-donut>
    </div>
</div>

<div class="responseBox" *ngFor="let res of rapidBaselineInfo.SortedResponseList">
    <div class="responseHeader">
        <div class="responseTitle">
            <h4>{{ res.Control.ShortDesc.LocalizedText[culture] }}</h4>
            <h5>{{ res.Control.LongDesc.LocalizedText[culture] }}</h5>
        </div>
        <div class="answerButton">
            <button
                class="ui button darkText"
                [class.unanswered]="!res.Response"
                type="button"
                [ngStyle]="{
                    'background-color':
                        res.Response == 'Yes, Fully'
                            ? CM_COLORS.COVERED
                            : res.Response == 'Yes, Partially'
                            ? CM_COLORS.PARTIAL
                            : res.Response == 'Unsure'
                            ? CM_COLORS.UNSURE
                            : res.Response == 'No'
                            ? CM_COLORS.NOTCOVERED
                            : 'white'
                }">
                {{ res.Response ? res.Response : 'Unanswered' }}
            </button>
        </div>
    </div>
    <h4 *ngIf="rapidBaselineInfo.ActiveStandards.length > 0">Active Standards</h4>
    <div class="activeStandard" *ngFor="let standard of rapidBaselineInfo.ActiveStandards">
        <h5 *ngIf="getControlRequirementText(standard, res.Control.Requirements) !== ''">
            {{ standard.Name + (standard.Name != standard.Variant ? ' ' + standard.Variant : '') }}
        </h5>
        <p
            *ngIf="getControlRequirementText(standard, res.Control.Requirements) !== ''"
            class="standardText"
            [innerHTML]="getControlRequirementText(standard, res.Control.Requirements)"></p>
    </div>
    <div class="responseBody">
        <b>Comment:</b>
        {{ res.Comment != '' ? res.Comment : 'No comment documented.' }}
    </div>
    <div class="responseBody">
        <b>Assigned to:</b>
        <span *ngFor="let a of res.Assignments; last as isLast">
            {{ getAssigneeName(a, false) + (!isLast ? ', ' : '') }}
        </span>
    </div>

    <div>
        <b>Assigned by:</b>
        {{ res.Assignments.length > 0 ? res.Assignments[0]?.AssignedByDisplayName || '' : '' }}
    </div>
</div>
