import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

import { environment } from 'environments/environment';
import { AuthHttp } from 'app/core/auth';
import { ErrorService } from 'app/core/error/error.service';
import { MessageService } from 'app/core/message.service';
import { Site, SiteTypeIcon } from './site.model';
import { AuthService } from '../../core/auth/auth.service';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { ItcFileUploaderService } from 'app/shared/itc';
import { SiteSettingService } from '../site/settings/site-settings.service';
import { ScanDataService } from 'app/scans';
import { AssessmentService } from './assessment.service';
import { DateService } from 'app/shared/date.service';
import { filter, distinctUntilChanged } from 'rxjs';

declare var ago: any;

export const CMTypes: string[] = ['GDPR', 'HIPAA', 'CYBERINSURANCE', 'NIST', 'CMMC', 'UKGDPR'];

@Injectable({ providedIn: 'root' })
export class SiteService {
    private apiUrl: string;

    constructor(
        private http: AuthHttp,
        private messageService: MessageService,
        private authService: AuthService,
        private fileUploader: ItcFileUploaderService,
        private siteSettingService: SiteSettingService,
        private scandataService: ScanDataService,
        private assessmentService: AssessmentService,
        private dateService: DateService,
        private router: Router
    ) {
        this.apiUrl = environment.apiUrl;
    }

    private currentSite: Site;
    private currentSite$: BehaviorSubject<Site> = new BehaviorSubject<Site>(null);
    public hasCMSites: boolean;

    app_getCurrentSite(): Promise<Site> {
        return new Promise<Site>((resolve, reject) => {
            if (this.currentSite) {
                resolve(this.currentSite);
                return;
            }

            let sub = this.messageService.on('APP_SET_CURRENT_SITE').subscribe((site: Site) => {
                resolve(site);
                sub.unsubscribe();
            });
        });
    }

    app_setCurrentSite(site: Site) {
        this.currentSite = site;
        this.messageService.broadcast('APP_SET_CURRENT_SITE', site);
    }

    setSiteObs(site: Site) {
        this.currentSite$.next(site);
    }
    getSiteObs() {
        return this.currentSite$.asObservable().pipe(filter(Boolean), distinctUntilChanged());
    }
    resetSiteObs() {
        this.currentSite$.next(null);
    }

    // I had to make different versions because getCurrentSite doesn't reject if we don't have a currentsite
    // so i couldn't use it in the route guards
    getCurrentSite() {
        return this.currentSite;
    }
    setCurrentSite(site: Site) {
        this.currentSite = site;
    }
    isComplianceManager(site: Site) {
        return CMTypes.indexOf(site.Type) > -1;
    }
    isComplianceManagerGRC(site: Site) {
        return site.Type == 'CM2';
    }
    isCSPro(site: Site) {
        return site.Type == 'CM2' && site.IsCSPro;
    }
    isNDPro(site: Site) {
        return site.Type == 'NDPRO';
    }
    isNDProWeb(site: Site) {
        let products = this.authService.getProducts();
        return site.Type === 'NDPRO' && products.NdProWeb;
    }
    isCyberHawk(site: Site) {
        return site.Type == 'standard';
    }
    isIndoc(site: Site) {
        return site.IsIndoc;
    }
    isKVS(site: Site) {
        return site.Type == 'KVS';
    }

    getSiteTypeIcons(site: Site): SiteTypeIcon[] {
        let siteTypes: SiteTypeIcon[] = [];
        let products = this.authService.getProducts();
        if (site.Type == 'standard') {
            siteTypes.push({
                title: 'Cyber Hawk',
                link: this.getProductSiteUrl(site, 'cyber-hawk'),
                class: 'cyberhawk',
            });
        }
        if (site.Type == 'GDPR') {
            siteTypes.push({
                title: 'Compliance Manager - GDPR',
                link: this.getProductSiteUrl(site, 'compliance-manager'),
                class: 'compliance-manager gdpr',
            });
        }
        if (site.Type == 'HIPAA') {
            siteTypes.push({
                title: 'Compliance Manager - HIPAA',
                link: this.getProductSiteUrl(site, 'compliance-manager'),
                class: 'compliance-manager hipaa',
            });
        }
        if (site.Type == 'CYBERINSURANCE') {
            siteTypes.push({
                title: 'Compliance Manager - Cyber Insurance',
                link: this.getProductSiteUrl(site, 'compliance-manager'),
                class: 'compliance-manager cyberinsurance',
            });
        }
        if (site.Type == 'NIST') {
            siteTypes.push({
                title: 'Compliance Manager - NIST',
                link: this.getProductSiteUrl(site, 'compliance-manager'),
                class: 'compliance-manager nist',
            });
        }
        if (site.Type == 'CMMC') {
            siteTypes.push({
                title: 'Compliance Manager - CMMC',
                link: this.getProductSiteUrl(site, 'compliance-manager'),
                class: 'compliance-manager cmmc',
            });
        }
        if (this.isNDPro(site) || this.isIndoc(site)) {
            if (this.isNDPro(site)) {
                siteTypes.push({
                    title: 'Network Detective Pro',
                    link: this.getProductSiteUrl(site, ''),
                    class: 'icon-ndpro',
                });
            } else {
                siteTypes.push({
                    title: 'Indoc',
                    link: this.getProductSiteUrl(site, 'indoc'),
                    class: 'indoc',
                });
            }
        }
        if (site.Type == 'KVS') {
            siteTypes.push({
                title: 'Vulscan',
                link: this.getProductSiteUrl(site, 'vulnerability-scanner'),
                class: 'kvs',
            });
        }
        if (site.Type == 'CM2' && !site.IsCSPro) {
            siteTypes.push({
                title: 'Compliance Manager GRC',
                link: this.getProductSiteUrl(site, 'compliance-manager2'),
                class: 'compliance-manager compliance-manager2',
            });
        }
        if (site.Type == 'CM2' && site.IsCSPro) {
            siteTypes.push({
                title: 'Compliance Services Pro Sites',
                link: this.getProductSiteUrl(site, 'compliance-manager2'),
                class: 'compliance-manager2-cspro',
            });
        }
        if (site.Type == 'UKGDPR') {
            siteTypes.push({
                title: 'Compliance Manager - UK GDPR',
                link: this.getProductSiteUrl(site, 'compliance-manager'),
                class: 'compliance-manager ukgdpr',
            });
        }

        return siteTypes;
    }

    getProductSiteUrl(site: Site, product: string): string[] {
        // return product site url, used on site-card
        let href = ['/site', site.Name.replace(/&/g, '%26')];
        if (this.isComplianceManager(site)) {
            if (site.UserRoles == 'AG_SME') {
                href.push('assessments', 'form');
            } else {
                href.push(product);
            }
        } else if (this.authService.isClient()) {
            href.push('client');
        } else {
            href.push(product);
        }
        return href;
    }

    getSites(): Promise<Site[]> {
        const url: string = this.apiUrl + 'Sites';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as Site[]);
        //.catch(this.E.error);
    }

    getSitesInOrganization(organization: string): Promise<Site[]> {
        const url: string = this.apiUrl + `OrganizationSites/${window.btoa(organization)}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as Site[]);
        //.catch(this.E.error);
    }

    getSiteKVSSitesInOrganization(siteId: number): Promise<Site[]> {
        if (!siteId) {
            return Promise.resolve(new Array<Site>());
        }
        const url: string = this.apiUrl + `SiteOrganizationKVSSites/${siteId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as Site[]);
        //.catch(this.E.error);
    }

    getSitesWithData(): Promise<Site[]> {
        const url: string = this.apiUrl + 'Sites/WithData';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as Site[]);
        //.catch(this.E.error);
    }

    getSite(id: number): Promise<Site> {
        const url: string = this.apiUrl + 'Sites/' + id;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as Site);
        //.catch(this.E.error);
    }

    // used to get a pared down version of site object
    getSiteByNameLimitedData(name: string): Promise<Site> {
        const url: string = this.apiUrl + `SiteByName`;
        if (name.indexOf('%') >= 0) {
            name = decodeURIComponent(name);
        }
        return this.http
            .post(url, {SiteName: name})
            .toPromise()
            .then((res) => res as Site);
        //.catch(this.E.error);
    }

    getSiteByName(name: string): Promise<Site> {
        const url: string = this.apiUrl + 'Sites/?name=' + encodeURIComponent(name);
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as Site);
        //.catch(this.E.error);
    }

    postSite(site: Site): Promise<any> {
        const url: string = this.apiUrl + 'Sites';
        return this.http
            .post(url, site)
            .toPromise()
            .then((res) => res);
    }

    provisionSite(
        site: Site,
        license: any,
        useExisting: boolean,
        organizationName: string,
        organizationId: number,
        provisionApp: boolean = true
    ) {
        const url: string = this.apiUrl + 'Sites/Provision';
        return this.http
            .post(url, {
                site: site,
                license: license,
                useExisting: useExisting,
                organizationName: organizationName,
                organizationId: organizationId,
                provisionApp: provisionApp,
            })
            .toPromise()
            .then((res) => res);
    }

    provisionKVSApplianceOnSite(
        site: Site,
        license: any,
        kvsType: string,
        kvsAppId?: string
    ): Promise<any> {
        const url: string = this.apiUrl + `Sites/ProvisionKVSApplianceOnSite`;
        return this.http
            .post(url, {
                site: site,
                license: license,
                kvsType: kvsType,
                kvsAppId: kvsAppId,
            })
            .toPromise()
            .then((res) => res);
    }

    provisionRDCApplianceOnSite(site: Site): Promise<any> {
        const url: string = this.apiUrl + `Sites/ProvisionRDCApplianceOnSite`;
        return this.http
            .post(url, { site: site })
            .toPromise()
            .then((res) => res);
    }

    deleteKVSAppliance(applianceId: string, siteId: number): Promise<any> {
        const url: string = this.apiUrl + `Appliances/${applianceId}/Delete`;
        return this.http
            .post(url, { SiteId: siteId })
            .toPromise()
            .then((res) => res);
    }

    deleteSite(site: Site): Promise<any> {
        const url: string = this.apiUrl + `Sites/${site.Id}`;
        return this.http
            .delete(url)
            .toPromise()
            .then((res) => res);
    }

    createGdprAssessment(siteId: number): Promise<any> {
        const url: string = this.apiUrl + 'Sites/' + siteId + '/CreateGdprAssessment';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    createGdprTodo5(siteId: number): Promise<any> {
        const url: string = this.apiUrl + 'Sites/' + siteId + '/CreateGdprTodo5';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    setCmmcLevel(siteId: number, level: string): Promise<any> {
        const url: string = this.apiUrl + 'Sites/' + siteId + '/SetCmmcLevel/' + level;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    answerTodoYN(siteId: number, alert: any, accepted: boolean) {
        const url: string = this.apiUrl + 'Sites/' + siteId + '/AnswerTodoYN';
        return this.http
            .post(url, { alert: alert, accepted: accepted })
            .toPromise()
            .then((res) => res);
    }

    rescanGdprCdfs(siteId: number): Promise<any> {
        const url: string = this.apiUrl + 'Sites/' + siteId + '/RescanGdprCdfs';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    rescanGdprNetwork(siteId: number): Promise<any> {
        const url: string = this.apiUrl + 'Sites/' + siteId + '/RescanGdprNetwork';

        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    rescanGdprPush(siteId: number): Promise<any> {
        const url: string = this.apiUrl + 'Sites/' + siteId + '/RescanGdprPush';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    rescanGdprIvul(siteId: number): Promise<any> {
        const url: string = this.apiUrl + 'Sites/' + siteId + '/RescanGdprIvul';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    doPrescan(siteId: number): Promise<any> {
        const url: string = this.apiUrl + 'Sites/' + siteId + '/DoPrescan';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    doAzurescan(siteId: number): Promise<any> {
        const url: string = this.apiUrl + 'Sites/' + siteId + '/DoAzurescan';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    uploadCdfAlerts(formdata: FormData): Promise<any> {
        let headers = new HttpHeaders();
        headers.append('Accept', 'application/json');

        //headers.append('Content-Type', 'multipart/form-data');
        let options = { headers: headers };

        const url: string = this.apiUrl + 'Sites/UploadCdf';
        return this.http
            .post(url, formdata, options)
            .toPromise()
            .then((res) => res);
    }

    uploadCdf(formdata: FormData): Observable<any> {
        return this.fileUploader.uploadFile('Sites/UploadCdf', formdata);
    }

    uploadAttachment(formdata: FormData): Observable<any> {
        return this.fileUploader.uploadFile('Sites/UploadAttachment', formdata);
    }

    downloadAttachment(siteId: number, attachmentId: string): Promise<any> {
        const url: string = this.apiUrl + 'Sites/' + siteId + '/DownloadAttachment/' + attachmentId;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    downloadAttachmentsZip(siteId: number): Promise<any> {
        const url: string = this.apiUrl + 'Sites/' + siteId + '/DownloadAttachmentsZip';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    restartCurrentAssessment(siteId: number): Promise<any> {
        const url: string = this.apiUrl + 'Sites/' + siteId + '/RestartCurrentAssessment';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    restartInternalScans(siteId: number): Promise<any> {
        const url: string = this.apiUrl + 'Sites/' + siteId + '/RestartInternalScans';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    restartPersonalDataScan(siteId: number): Promise<any> {
        const url: string = this.apiUrl + 'Sites/' + siteId + '/RestartPersonalDataScan';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    restartPersonalDataWorksheet(siteId: number): Promise<any> {
        const url: string = this.apiUrl + 'Sites/' + siteId + '/RestartPersonalDataWorksheet';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    restartExternalScan(siteId: number): Promise<any> {
        const url: string = this.apiUrl + 'Sites/' + siteId + '/RestartExternalScan';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    skipUpdate(siteId: number): Promise<any> {
        const url: string = this.apiUrl + 'Sites/' + siteId + '/SkipUpdate';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    startCyberhawkLevel1Scan(siteId: number): Promise<any> {
        const url: string = this.apiUrl + 'Sites/' + siteId + '/StartCyberhawkLevel1Scan';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    startCyberhawkLevel2Scan(siteId: number): Promise<any> {
        const url: string = this.apiUrl + 'Sites/' + siteId + '/StartCyberhawkLevel2Scan';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    cancelCyberhawkLevel1Scan(siteId: number): Promise<any> {
        const url: string = this.apiUrl + 'Sites/' + siteId + '/CancelCyberhawkLevel1Scan';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    cancelCyberhawkLevel2Scan(siteId: number): Promise<any> {
        const url: string = this.apiUrl + 'Sites/' + siteId + '/CancelCyberhawkLevel2Scan';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getCyberhawkScanStatuses(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/ScanStatus`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getCurrencySymbol(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/CurrencySymbol`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getAccountInfo(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/AccountInfo`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getSiteContactInfo(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/Contact`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    hasRftServer(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/HasRftServer`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getRollbackToTodoImpact(siteId: number, alerttype: string): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/RollBackToTodoImpact/${alerttype}/`;

        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    rollbackToTodo(siteId: number, alerttype: string): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/RollBackToTodo/${alerttype}/`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getRiskSnapshotReportSets(siteId: number): Promise<any[]> {
        let url: string = this.apiUrl + `Sites/${siteId}/RiskSnapshot/ReportSets`;

        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any[]);
    }

    getRiskSnapshotReportList(siteId: number, reportSetName: string): Promise<any[]> {
        let url: string =
            this.apiUrl + `Sites/${siteId}/RiskSnapshot/Reports/${reportSetName}/List`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any[]);
    }

    downloadRiskSnapshotReport(
        siteId: number,
        reportSetName: string,
        reportName: string
    ): Promise<any> {
        let url: string =
            this.apiUrl +
            `Sites/${siteId}/RiskSnapshot/Reports/${reportSetName}/Download?reportName=${reportName}`;
        return this.http
            .get(url, { observe: 'response' })
            .toPromise()
            .then((res) => (res.status == 200 ? res.body : null));
    }

    downloadRiskSnapshotReportSetZip(siteId: number, reportSetName: string): Promise<any> {
        let url: string =
            this.apiUrl + `Sites/${siteId}/RiskSnapshot/Reports/${reportSetName}/DownloadZip`;
        return this.http
            .get(url, { observe: 'response' })
            .toPromise()
            .then((res) => (res.status == 200 ? res.body : null));
    }

    deleteRiskSnapshotPublishedReport(
        siteId: number,
        reportSetName: string,
        reportName: string
    ): Promise<any> {
        let url: string =
            this.apiUrl +
            `Sites/${siteId}/RiskSnapshot/Reports/${reportSetName}/Report?reportName=${reportName}`;
        return this.http
            .delete(url)
            .toPromise()
            .then((res) => res);
    }

    deleteRiskSnapshotPublishedReportSet(siteId: number, reportSetName: string): Promise<any> {
        let url: string = this.apiUrl + `Sites/${siteId}/RiskSnapshot/Reports/${reportSetName}`;
        return this.http
            .delete(url)
            .toPromise()
            .then((res) => res);
    }

    setRiskSnapshotUseCompensatingControl(
        siteId: number,
        useCompensatingControl: boolean
    ): Promise<any> {
        let url: string = this.apiUrl + 'Sites/RiskSnapshot/UseCompensatingControl';
        return this.http
            .post(url, {
                siteId: siteId,
                useCompensatingControl: useCompensatingControl,
            })
            .toPromise()
            .then((res) => res);
    }

    setRiskSnapshotUseConnector(siteId: number, useConnector: boolean): Promise<any> {
        let url: string = this.apiUrl + 'Sites/RiskSnapshot/UseConnector';
        return this.http
            .post(url, { siteId: siteId, useConnector: useConnector })
            .toPromise()
            .then((res) => res);
    }

    getRiskSnapshotUseCompensatingControl(siteId: number): Promise<any> {
        let url: string = this.apiUrl + `Sites/${siteId}/RiskSnapshot/UseCompensatingControl`;

        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any);
    }

    getRiskSnapshotUseConnector(siteId: number): Promise<any> {
        let url: string = this.apiUrl + `Sites/${siteId}/RiskSnapshot/UseConnector`;

        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any);
    }

    startRiskSnapshotScans(siteId: number): Promise<any> {
        let url: string = this.apiUrl + `Sites/${siteId}/RiskSnapshot/StartScan`;

        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any);
    }

    hasRunningAuditGuruScan(siteId: number): Promise<boolean> {
        let url: string = this.apiUrl + `Sites/${siteId}/HasRunningAuditGuruScan`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as boolean);
    }

    checkEmployeeSubdomain(siteId: number, subdomain: string): Promise<boolean> {
        const url: string =
            this.apiUrl + `Sites/${siteId}/checkEmployeeSubdomain?name=` + subdomain;
        return this.http
            .get(url, { responseType: 'text' })
            .toPromise()
            .then((resText) => resText == 'true');
        //.catch(this.E.error);
    }
    getEmployeePortalSettings(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/EmployeePortalSettings`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
        //.catch(this.E.error);
    }

    getVendorPortalSettings(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/VendorPortalSettings`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
        //.catch(this.E.error);
    }

    getVendorTrackerInfo(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/VendorTracker`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
        //.catch(this.E.error);
    }

    setEmployeePortalSettings(siteId: number, settings: any): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/EmployeePortalSettings`;
        return this.http
            .post(url, settings)
            .toPromise()
            .then((res) => res);
        //.catch(this.E.error);
    }

    setVendorPortalSettings(siteId: number, settings: any): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/VendorPortalSettings`;
        return this.http
            .post(url, settings)
            .toPromise()
            .then((res) => res);
        //.catch(this.E.error);
    }

    getVendorAssessment(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/VendorManagement/Assesments`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
        //.catch(this.E.error);
    }

    updateVendorAssessment(siteId: number, settings: any): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/VendorManagement/Assesment`;
        return this.http
            .put(url, settings)
            .toPromise()
            .then((res) => res);
        //.catch(this.E.error);
    }

    deleteVendorAssessment(siteId: number, settings: any): Promise<any> {
        const url: string =
            this.apiUrl +
            `Sites/${siteId}/VendorManagement/Assesment/${settings.Id}/${settings.Name}`;
        return this.http
            .delete(url)
            .toPromise()
            .then((res) => res);
        //.catch(this.E.error);
    }

    createVendorAssessment(data: any): Promise<any> {
        const url: string = this.apiUrl + `Sites/VendorManagement/Assesment`;
        return this.http
            .post(url, data)
            .toPromise()
            .then((res) => res);
        //.catch(this.E.error);
    }

    getEmployeeTrackerTrainings(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/EmployeeTracker/Trainings`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getEmployeeTrackerPolicies(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/EmployeeTracker/Policies`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    updateEmployeePortalGroup(siteId: number, group: any): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/EmployeePortalSettings/Groups`;
        return this.http
            .put(url, group)
            .toPromise()
            .then((res) => res);
    }

    importEmployeePortalGroups(siteId: number, groups: any[]): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/EmployeePortalSettings/GroupsImport`;
        return this.http
            .post(url, groups)
            .toPromise()
            .then((res) => res);
    }
    addEmployeePortalGroup(siteId: number, group: any): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/EmployeePortalSettings/Groups`;
        return this.http
            .post(url, group)
            .toPromise()
            .then((res) => res);
    }
    deleteEmployeePortalGroup(siteId: number, groupId: number): Promise<any> {
        const url: string =
            this.apiUrl + `Sites/${siteId}/EmployeePortalSettings/Groups/${groupId}`;
        return this.http
            .delete(url)
            .toPromise()
            .then((res) => res);
    }

    importEmployeePortalEmployees(siteId: number, employees: any[]): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/EmployeePortalSettings/EmployeesImport`;
        return this.http
            .post(url, employees)
            .toPromise()
            .then((res) => res);
    }
    updateEmployeePortalEmployee(siteId: number, employee: any): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/EmployeePortalSettings/Employees`;
        return this.http
            .put(url, employee)
            .toPromise()
            .then((res) => res);
    }

    addEmployeePortalEmployee(siteId: number, employee: any): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/EmployeePortalSettings/Employees`;
        return this.http
            .post(url, employee)
            .toPromise()
            .then((res) => res);
    }
    deleteEmployeePortalEmployee(siteId: number, employeeId: number): Promise<any> {
        const url: string =
            this.apiUrl + `Sites/${siteId}/EmployeePortalSettings/Employees/${employeeId}`;
        return this.http
            .delete(url)
            .toPromise()
            .then((res) => res);
    }

    getEmployeePortalTraining(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/EmployeePortalSettings/Trainings`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    updateEmployeePortalTraining(siteId: number, training: any): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/EmployeePortalSettings/Trainings`;
        return this.http
            .put(url, training)
            .toPromise()
            .then((res) => res);
    }

    addEmployeePortalTraining(siteId: number, training: any): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/EmployeePortalSettings/Trainings`;
        return this.http
            .post(url, training)
            .toPromise()
            .then((res) => res);
    }
    deleteEmployeePortalTraining(siteId: number, trainingId: number): Promise<any> {
        const url: string =
            this.apiUrl + `Sites/${siteId}/EmployeePortalSettings/Trainings/${trainingId}`;
        return this.http
            .delete(url)
            .toPromise()
            .then((res) => res);
    }

    updateEmployeePortalPolicy(siteId: number, policy: any): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/EmployeePortalSettings/Policies`;
        return this.http
            .put(url, policy)
            .toPromise()
            .then((res) => res);
    }

    addEmployeePortalPolicy(siteId: number, policy: any): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/EmployeePortalSettings/Policies`;
        return this.http
            .post(url, policy)
            .toPromise()
            .then((res) => res);
    }
    deleteEmployeePortalPolicy(siteId: number, policyId: number): Promise<any> {
        const url: string =
            this.apiUrl + `Sites/${siteId}/EmployeePortalSettings/Policies/${policyId}`;
        return this.http
            .delete(url)
            .toPromise()
            .then((res) => res);
    }

    getSiteConnectors(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/GetConnectors`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    updateConnectorLabel(siteId: number, connectorId: string, label: string): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/EditConnector/${connectorId}`;

        return this.http
            .post(url, { label: label })
            .toPromise()
            .then((res) => res);
    }

    deleteConnector(siteId: number, connectorId: string): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/DeleteConnector/${connectorId}`;
        return this.http
            .delete(url)
            .toPromise()
            .then((res) => res);
    }

    rotateSiteKey(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/RotateSiteKey`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    setSiteKeyEnabled(siteId: number, enabled: boolean): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/SiteKeyEnabled`;
        let postbody: any = { enabled: enabled };
        return this.http
            .post(url, postbody)
            .toPromise()
            .then((res) => res);
    }

    getManualDataCollectorUrl(siteId: number) {
        const url: string = this.apiUrl + `SiteManualDataCollectorUrl/${siteId}`;
        return lastValueFrom(this.http.get(url))
            .then((res) => res)
            .catch((err) => {});
    }

    deleteManualDataCollectorUrl(siteId: number) {
        const url: string = this.apiUrl + `SiteManualDataCollectorUrl/${siteId}`;
        return lastValueFrom(this.http.delete(url))
            .then((res) => res)
            .catch((err) => {});
    }

    redirectToManualDataCollectorUrl(collectorGuid: string): Promise<any> {
        const url: string = this.apiUrl + `SiteRedirectToManualDataCollectorUrl/${collectorGuid}`;
        return lastValueFrom(this.http.get(url))
            .then((res) => res)
            .catch((err) => {
                if (err.status == 302) {
                    window.location.href = err.error;
                }
            });
    }

    isIkValid(collectorGuid: string): Promise<any> {
        const url: string = this.apiUrl + `IsIkValid/${collectorGuid}`;
        return lastValueFrom(this.http.get(url))
            .then((res) => res)
            .catch((err) => {});
    }

    isCaraIkValid(collectorGuid: string): Promise<any> {
        const url: string = this.apiUrl + `IsCaraIkValid/${collectorGuid}`;
        return lastValueFrom(this.http.get(url))
            .then((res) => res)
            .catch((err) => {});
    }
    generateManualDataCollectorUrl(siteId: number) {
        const url: string = this.apiUrl + `Sites/${siteId}/SiteGenerateManualDataCollectorUrl`;
        let postbody: any = { siteId: siteId };
        return this.http
            .post(url, postbody)
            .toPromise()
            .then((res) => res);
    }

    getManualDataCollectorFiles(siteId: number) {
        const url: string = this.apiUrl + `SiteManualDataCollector/Files/${siteId}`;
        return lastValueFrom(this.http.get(url))
            .then((res) => res)
            .catch((err) => {});
    }

    deleteManualDataCollectorFile(siteId: number, filename: string) {
        let encFilename = btoa(filename);
        console.log('encfilename: ' + encFilename);
        const url: string = this.apiUrl + `SiteManualDataCollector/Files/${siteId}/${encFilename}`;
        return lastValueFrom(this.http.delete(url))
            .then((res) => res)
            .catch((err) => {});
    }

    /* Used for site details component */
    friendlyTypes: any = {
        RPS: 'Reporter',
        CH: 'Cyber Hawk',
        AG: 'Compliance Manager',
        KVS: 'Vulnerability Scanner',
        NDPro: 'Network Detective Pro',
    };
    siteTypeIcons: any[];

    getSiteDetails(site: any) {
        console.log('getting site details');
        if (site.apps.length > 0 || site.siteLoading) {
            return;
            //CARA - Cyberattack Risk Assessment
        }
        site.siteLoading = true;
        this.siteSettingService
            .getApplianceInfoForReal(site.site.Id)
            .then((appliances) => {
                console.log('appliances', appliances);
                site.apps = [];
                if (!appliances || !appliances.length) {
                    // appliance not installed
                    let notInstalled = true;
                    site.apps.push({
                        Id: '<not installed>',
                    });
                    site.siteLoading = false;
                } else {
                    for (let i = 0; i < appliances.length; i++) {
                        let info = appliances[i]; // TODO update this to work with more than one appliance

                        let updateStatus = 'unknown';
                        let updateAvailable = false;
                        if (info.UpdateStatus == 1) {
                            updateStatus = 'up-to-date';
                            updateAvailable = false;
                        } else if (info.UpdateStatus == 0) {
                            updateStatus = 'update available';
                            updateAvailable = true;
                        }

                        let types = '';
                        for (let j = 0; j < info.Types.length; j++) {
                            if (types) {
                                types += ', ';
                            }
                            types += this.friendlyTypes[info.Types[j]];
                        }
                        this.siteSettingService
                            .getApplianceScanStatuses(site.site.Id, info.Id)
                            .then((scans) => {
                                for (let sc of scans) {
                                    let startDate = new Date(sc.start);
                                    sc.start = this.dateService.relativeTime(startDate);
                                }
                                let appLastCheckin = new Date(info.LastCheckin);
                                let app = {
                                    Id: info.Id,
                                    Type: types,
                                    LastCheckin:
                                        appLastCheckin.getFullYear() < 2000
                                            ? 'NEVER'
                                            : info.LastCheckin,
                                    UpdateStatus: updateStatus,
                                    UpdateAvailable: updateAvailable,
                                    IsVirtual: info.IsVirtual,
                                    IsServer: info.IsServer,
                                    Scans: scans,
                                    Status: info.IsOnline ? 'Online' : 'Offline',
                                };
                                site.apps.push(app);
                                if (app.Type.indexOf('Cyber Hawk') > -1) {
                                    this.siteSettingService.getAddons(site.site.Id).then((s) => {
                                        site.addons.abds = s.abdsEnabled ? true : false;
                                        // site.addons = "Add-on: ABDS: " + (s.abdsEnabled ? "Enabled" : "Not Enabled");
                                    });
                                }
                                if (app.Type.indexOf('Reporter') > -1) {
                                    if (types.indexOf('Cyber') >= 0) {
                                        this.scandataService
                                            .getCyberhawkManagementPlans(site.site.Id)
                                            .then((plans) => {
                                                this.setManagementPlans(site, plans);
                                            });
                                    } else {
                                        this.scandataService
                                            .getIndocManagementPlans(site.site.Id)
                                            .then((plans) => {
                                                this.setManagementPlans(site, plans);
                                            });
                                    }
                                }
                                if (
                                    app.Type.indexOf('Audit Guru') > -1 ||
                                    app.Type.indexOf('Compliance Manager') > -1
                                ) {
                                    this.assessmentService
                                        .getAssessment(site.site.Id, '__current')
                                        .then((assessment) => {
                                            site.assessment = assessment;
                                        })
                                        .catch(() => {});
                                }
                            });
                    }
                    setTimeout(() => (site.siteLoading = false), 1500);
                }
            })
            .catch((err) => {});
    }

    setManagementPlans(site: any, plans: any) {
        if (plans.error !== 'outdated') {
            for (let key in plans) {
                // remove null or objects with no length
                if (plans[key] === null || plans[key].length <= 0) {
                    delete plans[key];
                } else {
                    plans[key] = plans[key].length;
                }
            }
            site.managementPlans = plans;
        }
    }

    generateCyberattackDataCollectorUrl(siteId: number) {
        const url: string = this.apiUrl + `Sites/${siteId}/GenerateCaraDataCollectorUrl`;
        let postbody: any = {};
        return this.http
            .post(url, postbody)
            .toPromise()
            .then((res) => res);
    }

    getCyberattackDataCollectorFiles(orgId: number) {
        const url: string = this.apiUrl + `CaraDataCollector/Files/${orgId}`;
        return lastValueFrom(this.http.get(url))
            .then((res) => res)
            .catch((err) => {});
    }

    deleteCyberattackDataCollectorFile(siteId: number, filename: string) {
        let encFilename = btoa(filename);
        console.log('encfilename: ' + encFilename);
        const url: string = this.apiUrl + `CaraDataCollector/Files/${siteId}/${encFilename}`;
        return lastValueFrom(this.http.delete(url))
            .then((res) => res)
            .catch((err) => {});
    }

    getCyberattackCollectorUrl(siteId: number) {
        const url: string = this.apiUrl + `CaraDataCollectorUrl/${siteId}`;
        return lastValueFrom(this.http.get(url))
            .then((res) => res)
            .catch((err) => {});
    }

    deleteCyberattackCollectorUrl(siteId: number) {
        const url: string = this.apiUrl + `CaraDataCollectorUrl/${siteId}`;
        return lastValueFrom(this.http.delete(url))
            .then((res) => res)
            .catch((err) => {});
    }

    redirectToCyberattackCollectorUrl(collectorGuid: string): Promise<any> {
        const url: string = this.apiUrl + `RedirectToCaraDataCollectorUrl/${collectorGuid}`;
        return lastValueFrom(this.http.get(url))
            .then((res) => res)
            .catch((err) => {
                if (err.status == 302) {
                    window.location.href = err.error;
                }
            });
    }

    goToSite(site) {
        this.app_setCurrentSite(site);
        this.setSiteObs(site);
        const baseRoute = ['site', site.Name];
        if (this.isComplianceManagerGRC(site)) {
            if (site.UserRoles?.includes('AG_SME')) {
                this.router.navigate([...baseRoute, 'compliance-manager-grc', 'my-work']);
            } else if (
                site.UserRoles?.includes('GRC_REPORT_VIEWER') &&
                !site.UserRoles?.includes('SDS_')
            ) {
                this.router.navigate([
                    ...baseRoute,
                    'compliance-manager-grc',
                    'results-evidence',
                    'reports',
                ]);
            } else if (site.UserRoles == 'EMP_PORTAL_ADMIN') {
                this.router.navigate([
                    ...baseRoute,
                    'compliance-manager-grc',
                    'employee-portal',
                    'setup',
                ]);
            } else {
                // if CMGRC redirect to standards and controls
                console.log('cmgrc', [...baseRoute, 'compliance-manager-grc', 'dashboard']);
                this.router.navigate([...baseRoute, 'compliance-manager-grc', 'dashboard']);
            }
        } else if (this.isComplianceManager(site)) {
            if (site.UserRoles == 'AG_SME') {
                this.router.navigate([...baseRoute, 'compliance-manager', 'assessments', 'forms']);
            } else {
                this.router.navigate([...baseRoute, 'home']);
            }
        } else if (this.isKVS(site)) {
            if (site.UserRoles == 'IND_CLIENT') {
                this.router.navigate([...baseRoute, 'vulnerability-scanner', 'dashboard']);
            } else {
                this.router.navigate([...baseRoute, 'home']);
            }
        } else if (site.UserRoles == 'IND_CLIENT') {
            if (this.isNDPro(site)) {
                this.router.navigate([
                    ...baseRoute,
                    'network-detective-pro',
                    'settings',
                    'client-view',
                ]);
            } else {
                this.router.navigate([...baseRoute, 'client', 'reports']);
            }
        } else if (this.isNDProWeb(site)) {
            this.router.navigate([...baseRoute, 'network-detective-pro', 'dashboard']);
        } else if (this.isNDPro(site)) {
            this.router.navigate([...baseRoute, 'network-detective-pro', 'dashboard']);
        } else {
            this.router.navigate([...baseRoute, 'home']);
        }
    }

    sendInvitationEmployeeTraining(employeeId: number, siteId: number) {
        const url: string = this.apiUrl + `sendInvitationEmployeeTraining/${siteId}`;

        return this.http
            .post(url, employeeId)
            .toPromise()
            .then((res) => res);
    }

    getEvidenceList(siteId: number) {
        const url: string = this.apiUrl + `existingevidences/${siteId}`;
        return lastValueFrom(this.http.get(url))
            .then((res) => res)
            .catch((err) => {});
    }

    getEvidenceTypes(siteId: number) {
        const url: string = this.apiUrl + `getevidencetypes/${siteId}`;
        return lastValueFrom(this.http.get(url))
            .then((res) => res)
            .catch((err) => {});
    }

    getSiteTypeTitle(site: Site): string {
        if (this.isComplianceManagerGRC(site)) {
            return 'Compliance Manager GRC';
        } else if (this.isComplianceManager(site)) {
            return 'Compliance Manager';
        } else if (this.isKVS(site)) {
            return 'Vulnerability Scanner';
        } else if (this.isNDPro(site)) {
            return 'Network Detective Pro';
        } else if (this.isCyberHawk(site)) {
            return 'Cyber Hawk';
        } else {
            return 'Reporter';
        }
    }

    getOrganizationSitesInSameOrganizationByType(organizationId: number, siteId: number, siteType: string) {
        const url: string = this.apiUrl + `OrganizationSitesInSameOrganizationByType/${organizationId}/${siteId}/${siteType}`;
        return lastValueFrom(this.http.get(url))
            .then((res) => res)
            .catch((err) => { });
    }
}
