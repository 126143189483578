import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PreferencesService } from '../preferences.service';
import { FormControl } from '@angular/forms';
import { NotificationService } from '../../../shared/itc/notification/notification.service';

@Component({
    selector: 'sds-mfa-disable',
    templateUrl: './mfa-disable.component.html',
    styleUrls: ['./mfa-disable.component.css'],
})
export class MfaDisableComponent implements OnInit {
    deletingTwoFactorKey: boolean;
    totpCtrl: FormControl = new FormControl();

    @Input() isTwoFactorEnabled: boolean;
    @Output() onIsTwoFactorEnabledChange = new EventEmitter<boolean>();

    constructor(
        private prefService: PreferencesService,
        private notificationService: NotificationService
    ) {}

    ngOnInit() {}

    deleteTwoFactorSetup() {
        this.totpCtrl.setErrors(null);

        if (!this.totpCtrl.value) {
            this.totpCtrl.setErrors({ empty: 'value is empty' });
            return;
        }

        this.deletingTwoFactorKey = true;
        this.prefService.disableTwoFactor(this.totpCtrl.value).then((res) => {
            this.deletingTwoFactorKey = false;
            if (res.disabled) {
                this.isTwoFactorEnabled = false;
                this.onIsTwoFactorEnabledChange.emit(this.isTwoFactorEnabled);
                this.notificationService.toast.success(
                    'Success',
                    'Two-Factor Authentication was disabled for your user account.'
                );
            } else {
                this.notificationService.toast.error(
                    'Invalid Login Code',
                    'Please check the authentication code in Google Authenticator and try again. The code may have expired, and a new code issued.'
                );
            }
        });
    }
}
