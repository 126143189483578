import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

export interface ConfirmData {
    heading: string;
    message: string;
    proceedTo: string;
}

@Injectable({ providedIn: 'root' })
export class ConfirmService {
    constructor(private router: Router) {}

    private confirmData: ConfirmData;

    confirm(data: ConfirmData) {
        this.confirmData = data;
        this.router.navigate(['confirm']);
    }

    getConfirmData(): ConfirmData {
        return this.confirmData;
    }
}
