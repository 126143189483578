<sds-breadcrumb-trail [crumbs]="breadcrumbs"></sds-breadcrumb-trail>

<header class="pageHeader"><h1>Roles</h1></header>

<sds-loader [complete]="loadingComplete">
    <div id="roleContainer">
        <div>
            <table class="ui table">
                <thead>
                    <tr>
                        <th class="roleTitle">
                            <p>Site Admin</p>
                            <itc-button
                                type="primary"
                                class="right floated"
                                (onclick)="showModal('SDS_SITEADMIN')"
                                icon="fa-plus">
                                Add User
                            </itc-button>
                            <!-- <sds-button color="primary" class="right floated" (onclick)="showModal('SDS_SITEADMIN')" label="Add User" icon="icon-add"></sds-button> -->
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="roles['SDS_SITEADMIN'].length == 0">
                        <td>No Users</td>
                    </tr>
                    <tr *ngFor="let user of roles['SDS_SITEADMIN']">
                        <td>
                            {{ user.Username }}
                            <itc-button
                                type="icon"
                                icon="fa-trash-can"
                                class="right floated"
                                (onclick)="removeUserFromRole(user, 'SDS_SITEADMIN')"
                                tooltip="Delete"></itc-button>
                            <!-- <a class="right floated icon" (click)="removeUserFromRole(user, 'SDS_SITEADMIN')" style="cursor: pointer" title="Delete"><i class="icon-delete"></i></a> -->
                            <div
                                class="right floated"
                                *ngIf="orgLevelDiscoveryAgents && isSuperUser">
                                <itc-toggle
                                    [on]="user.OrgRole.indexOf('DISCOVERY_AGENT') > -1"
                                    (onChanged)="
                                        toggleUserInOrgRole(user, 'DISCOVERY_AGENT', $event)
                                    "
                                    tooltip="{{
                                        (user.OrgRole.indexOf('DISCOVERY_AGENT') > -1
                                            ? 'Dis'
                                            : 'En') +
                                            'able Discovery Agent Access' +
                                            (overrideDA
                                                ? '. All users currently have access to Discovery Agents through a global setting in the Users page.'
                                                : '')
                                    }}"></itc-toggle>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="column">
            <table class="ui table">
                <thead>
                    <tr>
                        <th class="roleTitle">
                            <p>Technician</p>
                            <itc-button
                                type="primary"
                                icon="fa-plus"
                                class="right floated"
                                (onclick)="showModal('SDS_TECH')">
                                Add User
                            </itc-button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="roles['SDS_TECH'].length == 0">
                        <td>No Users</td>
                    </tr>
                    <tr *ngFor="let user of roles['SDS_TECH']">
                        <td>
                            {{ user.Username }}
                            <itc-button
                                type="icon"
                                icon="fa-trash-can"
                                class="right floated"
                                (onclick)="removeUserFromRole(user, 'SDS_TECH')"
                                tooltip="Delete"></itc-button>
                            <div
                                class="right floated"
                                *ngIf="orgLevelDiscoveryAgents && isSuperUser">
                                <itc-toggle
                                    [on]="user.OrgRole.indexOf('DISCOVERY_AGENT') > -1"
                                    (onChanged)="
                                        toggleUserInOrgRole(user, 'DISCOVERY_AGENT', $event)
                                    "
                                    tooltip="{{
                                        (user.OrgRole.indexOf('DISCOVERY_AGENT') > -1
                                            ? 'Dis'
                                            : 'En') +
                                            'able Discovery Agent Access' +
                                            (overrideDA
                                                ? '. All “Site Admin” and “Technician” role users currently have access to Discovery Agents through a global setting in the Users page.'
                                                : '')
                                    }}"></itc-toggle>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="column">
            <table class="ui table" *ngIf="site && isAG">
                <thead>
                    <tr>
                        <th class="roleTitle">
                            <p>Internal Auditor</p>
                            <itc-button
                                type="primary"
                                icon="fa-plus"
                                class="right floated"
                                (onclick)="showModal('SDS_AUDITOR')">
                                Add User
                            </itc-button>
                            <!-- <a class="ui right floated green icon labeled button" href="javascript:" (click)="showModal('SDS_AUDITOR')"><i class="plus icon"></i>Add User</a> -->
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="roles['SDS_AUDITOR'].length == 0">
                        <td>No Users</td>
                    </tr>
                    <tr *ngFor="let user of roles['SDS_AUDITOR']">
                        <td>
                            {{ user.Username }}
                            <itc-button
                                type="icon"
                                icon="fa-trash-can"
                                class="right floated"
                                (onclick)="removeUserFromRole(user, 'SDS_AUDITOR')"
                                tooltip="Delete"></itc-button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="column">
            <table class="ui table" *ngIf="site && isAG">
                <thead>
                    <tr>
                        <th class="roleTitle">
                            <p>Subject Matter Expert</p>
                            <itc-button
                                type="primary"
                                icon="fa-plus"
                                class="right floated"
                                (onclick)="showModal('AG_SME')">
                                Add User
                            </itc-button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="roles['AG_SME'].length == 0">
                        <td>No Users</td>
                    </tr>
                    <tr *ngFor="let user of roles['AG_SME']">
                        <td>
                            {{ user.Username }}
                            <itc-button
                                type="icon"
                                icon="fa-trash-can"
                                class="right floated"
                                (onclick)="removeUserFromRole(user, 'AG_SME')"
                                tooltip="Delete"></itc-button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="column">
            <table class="ui table" *ngIf="site && isGrc">
                <thead>
                    <tr>
                        <th class="roleTitle">
                            <p>Reports Viewer</p>
                            <itc-button
                                type="primary"
                                icon="fa-plus"
                                class="right floated"
                                (onclick)="showModal('GRC_REPORT_VIEWER')">
                                Add User
                            </itc-button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="roles['GRC_REPORT_VIEWER'].length == 0">
                        <td>No Users</td>
                    </tr>
                    <tr *ngFor="let user of roles['GRC_REPORT_VIEWER']">
                        <td>
                            {{ user.Username }}
                            <itc-button
                                type="icon"
                                icon="fa-trash-can"
                                class="right floated"
                                (onclick)="removeUserFromRole(user, 'GRC_REPORT_VIEWER')"
                                tooltip="Delete"></itc-button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="column">
            <table class="ui table" *ngIf="site && isGrc">
                <thead>
                    <tr>
                        <th class="roleTitle">
                            <p>Employee Portal Admin</p>
                            <itc-button
                                type="primary"
                                icon="fa-plus"
                                class="right floated"
                                (onclick)="showModal('EMP_PORTAL_ADMIN')">
                                Add User
                            </itc-button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="roles['EMP_PORTAL_ADMIN'].length == 0">
                        <td>No Users</td>
                    </tr>
                    <tr *ngFor="let user of roles['EMP_PORTAL_ADMIN']">
                        <td>
                            {{ user.Username }}
                            <itc-button
                                type="icon"
                                icon="fa-trash-can"
                                class="right floated"
                                (onclick)="removeUserFromRole(user, 'EMP_PORTAL_ADMIN')"
                                tooltip="Delete"></itc-button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div id="otherRoleContainer">
        <div class="column">
            <table class="ui table" *ngIf="site && (isIndoc || isKvs || isNdp)">
                <thead>
                    <tr>
                        <th class="roleTitle">
                            <p>Client View</p>
                            <itc-button
                                type="primary"
                                icon="fa-plus"
                                class="right floated"
                                (onclick)="showModal('IND_CLIENT')">
                                Add User
                            </itc-button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="roles['IND_CLIENT'].length == 0">
                        <td>No Users</td>
                    </tr>
                    <tr *ngFor="let user of roles['IND_CLIENT']">
                        <td>
                            {{ user.Username }}
                            <itc-button
                                type="icon"
                                icon="fa-trash-can"
                                class="right floated"
                                (onclick)="removeUserFromRole(user, 'IND_CLIENT')"
                                tooltip="Delete"></itc-button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</sds-loader>

<sm-modal
    title="Add User(s) as '{{
        site?.Type === 'CM2' && roleNames[selectedRole] === 'Client View'
            ? 'Reports Viewer'
            : roleNames[selectedRole]
    }}'"
    class="sm addUserToRoleModal"
    #modal>
    <modal-content>
        <p>Select users to add</p>
        <form name="addUsersToRoleForm">
            <div>
                <div style="display: inline-flex; flex-direction: column; width: 100%">
                    <itc-checkbox
                        style="display: inline-block; margin-bottom: 8px"
                        *ngFor="let user of availableUsers; let i = index"
                        [control]="fcs[i]"
                        [name]="'fcs' + i"
                        (onChanged)="validateForm()"
                        [label]="user.Username"
                        [tooltip]="getLabelTitle(user, selectedRole)"
                        ttPosition="bottom-right"></itc-checkbox>
                </div>
            </div>
        </form>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" class="cancel">Cancel</itc-button>
        <itc-button
            type="primary"
            [disabled]="!validForm"
            (onclick)="addSelectedUsersToSelectedRole()">
            Add
        </itc-button>
    </modal-actions>
</sm-modal>
