<h3 class="ui header">{{ confirmData?.heading }}</h3>

<p>{{ confirmData?.message }}</p>

<a
    class="ui labeled icon button darker"
    *ngIf="confirmData?.proceedTo"
    [routerLink]="confirmData?.proceedTo">
    <i class="right arrow icon"></i>
    Continue
</a>
