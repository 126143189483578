import { Component, OnInit, Input, Output, EventEmitter, AfterViewChecked } from '@angular/core';

@Component({
    selector: 'sds-site-grid',
    templateUrl: './site-grid.component.html',
    styleUrls: ['./site-grid.component.css'],
})
export class SiteGridComponent implements OnInit {
    @Input() sortedSitesChunked: any;
    @Input() openDetails: any;
    @Input() viewModeUpdating: boolean;
    @Output() viewLoaded = new EventEmitter<any>(true);
    @Output() detailsClicked = new EventEmitter<any>();

    loadingComplete: boolean = true;

    constructor() {}

    ngOnInit() {}

    ngAfterViewChecked() {
        if (this.viewModeUpdating) {
            this.viewLoaded.emit(true);
        }
    }

    toggleDetails(t) {
        this.detailsClicked.emit(t);
    }
}
