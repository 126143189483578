<sds-breadcrumb-trail [crumbs]="breadcrumbs"></sds-breadcrumb-trail>

<header class="pageHeader">
    <h1>{{ title }}</h1>
</header>

<sds-loader [complete]="loadingComplete">
    <itc-card style="min-width: 1000px">
        <card-title>Compliance Manager Uploads</card-title>
        <card-content>
            Upload ID: {{ connector != null ? connector.Id : 'N/A' }}
            <itc-button
                type="icon"
                icon="fa-clipboard-list-check"
                tooltip="Copy to clipboard"
                ttPosition="right"
                style="vertical-align: middle"
                *ngIf="connector != null"
                (onclick)="copy(connector.Id)"></itc-button>
        </card-content>
    </itc-card>

    <itc-card style="min-width: 1000px">
        <card-title>Compliance Manager Server</card-title>
        <card-content>
            <div class="left floated">
                <div>
                    Server ID: {{ mainApp != null ? mainApp.Id : 'N/A' }}
                    <itc-button
                        type="icon"
                        icon="fa-clipboard-list-check"
                        tooltip="Copy to clipboard"
                        ttPosition="right"
                        style="vertical-align: middle"
                        *ngIf="mainApp != null"
                        (onclick)="copy(mainApp.Id)"></itc-button>
                </div>
                <br />
                <div>
                    Status:
                    <span
                        *ngIf="mainApp != null && mainApp?.IsCheckedIn"
                        [ngStyle]="{ color: mainApp?.Status == 'Online' ? 'green' : 'red' }">
                        {{ mainApp?.IsCheckedIn ? mainApp.Status : 'N/A' }}
                    </span>
                    <span style="color: red" *ngIf="mainApp == null || !mainApp?.IsCheckedIn">
                        No Server installed. Ready to install.
                    </span>
                </div>
            </div>
            <div class="right floated" *ngIf="mainApp != null">
                <sds-manage-appliance
                    [app]="mainApp"
                    [siteId]="site.Id"
                    [disabled]="
                        mainApp?.Status != 'Online' || !mainApp?.IsCheckedIn
                    "></sds-manage-appliance>
            </div>
        </card-content>
    </itc-card>

    <ng-container *ngIf="showRemoteDataCollectors">
        <header class="sectionHeader" style="display: block; height: 30px; margin-bottom: 10px">
            <h4 class="left floated">Remote Data Collectors</h4>
            <div class="right floated">
                <itc-button type="primary" icon="fa-plus" (onclick)="showRDCProvisionModal()">
                    Provision
                </itc-button>
                <itc-button
                    type="primary"
                    icon="fa-trash-can"
                    (onclick)="startMultiDelete()"
                    *ngIf="isSiteAdmin"
                    [disabled]="!checkAnySelected()">
                    Delete Selected
                </itc-button>
            </div>
        </header>

        <div>
            <table
                class="itc-table sortable"
                sortable-table
                sortColumn="id"
                sortDirection="asc"
                (sorted)="sortRDCs($event)">
                <thead>
                    <tr>
                        <th><itc-checkbox (onChanged)="checkAllRDC($event)"></itc-checkbox></th>
                        <th sortable-column="id">RDC ID</th>
                        <th sortable-column="description">Description</th>
                        <th sortable-column="status">Status</th>
                        <th></th>
                        <th class="collapsing"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let app of pagedRDCs">
                        <td><itc-checkbox [(ngModel)]="app.Selected"></itc-checkbox></td>
                        <td>{{ app.Id }}</td>
                        <td>{{ app.Description }}</td>
                        <td>{{ app.Status }}</td>
                        <td>
                            <sds-manage-appliance
                                [app]="app"
                                [showAppInfo]="true"
                                [siteId]="site.Id"
                                displayType="link"></sds-manage-appliance>
                        </td>
                        <td width="100px">
                            <itc-button
                                type="icon"
                                icon="fa-pencil"
                                tooltip="Edit"
                                ttPosition="top-left"
                                (onclick)="showEditDescModal(app)"></itc-button>
                            <itc-button
                                type="icon"
                                icon="fa-trash-can"
                                tooltip="Delete"
                                ttPosition="top-left"
                                (onclick)="showDeleteModal(app)"
                                *ngIf="isSiteAdmin"></itc-button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <itc-paginator [data]="rdcs" (onPage)="pagedRDCs = $event"></itc-paginator>
        </div>
    </ng-container>

    <sm-modal class="sm" title="Data Collector Description" #editDataCollectorDescriptionModal>
        <modal-content>
            <div>Add description of data collector.</div>
            <input type="text" [formControl]="dcDescCtrl" maxlength="100" />
        </modal-content>
        <modal-actions>
            <itc-button type="secondary" (onclick)="editDataCollectorDescriptionModal.hide()">
                Cancel
            </itc-button>
            <itc-button type="primary" (onclick)="saveDcDesc()">Save</itc-button>
        </modal-actions>
    </sm-modal>

    <sm-modal class="xs" title="Provison New RDC" #provisionConfirmModal>
        <modal-content>
            <div>Do you wish to provision a new RDC?</div>
        </modal-content>
        <modal-actions>
            <itc-button
                type="secondary"
                (onclick)="provisionConfirmModal.hide()"
                [disabled]="isProvisioning">
                Cancel
            </itc-button>
            <itc-button type="primary" (onclick)="provisionRDC()" [loading]="isProvisioning">
                OK
            </itc-button>
        </modal-actions>
    </sm-modal>

    <sm-modal class="xs" title="Confirm Delete" #deleteRdcModal>
        <modal-content>
            <div>
                Removing a Remote Data Collector will cancel all currently scheduled and running
                tasks. Are you sure you wish to remove
                {{
                    appsToDelete.length == 1
                        ? 'this RDC'
                        : 'these ' + appsToDelete.length + ' RDCs'
                }}?
            </div>
        </modal-content>
        <modal-actions>
            <itc-button type="secondary" (onclick)="deleteRdcModal.hide()" [disabled]="isDeleting">
                Cancel
            </itc-button>
            <itc-button type="primary" (onclick)="deleteAppliance()" [loading]="isDeleting">
                OK
            </itc-button>
        </modal-actions>
    </sm-modal>
</sds-loader>
