export class AlertDiagnostics {
    diags: DiagNode[];
}

export class DiagNode {
    Name: string;
    Value: any;
    //Parent: DiagNode;
    Children: DiagNode[];
}
