<sds-loader [complete]="loadingComplete">
    <div class="siteListContainer viewList">
        <div class="siteContainer viewModeHeader sortable">
            <div
                class="sortHeader sitename"
                [ngClass]="{ ascending: sortBy == 'Name', descending: sortBy == '-Name' }"
                [class.sorted]="sortBy.replace('-', '') == 'Name'"
                (click)="toggleSort('-Name')">
                Site Name
            </div>
            <div
                class="sortHeader todosBadge"
                [ngClass]="{ ascending: sortBy == 'OpenTodos', descending: sortBy == '-OpenTodos' }"
                [class.sorted]="sortBy.replace('-', '') == 'OpenTodos'"
                (click)="toggleSort('-OpenTodos')">
                To Do
            </div>
            <div
                class="sortHeader siteContainerButtons"
                [ngClass]="{ ascending: sortBy == 'Type', descending: sortBy == '-Type' }"
                [class.sorted]="sortBy.replace('-', '') == 'Type'"
                (click)="toggleSort('-Type')">
                Site Type
            </div>
            <div class="siteinfo">Details</div>
        </div>
        <sds-site-card
            *ngFor="let site of sortedSites"
            [site]="site"
            cardViewMode="list"
            [openDetails]="openDetails"
            (detailsClicked)="toggleDetails($event)"></sds-site-card>
    </div>
</sds-loader>
