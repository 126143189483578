<form class="ui form" name="setupTwoFactorForm">
    <h3>Setup Two-Factor Authentication</h3>

    <itc-banner type="info" *ngIf="!twoFactorKeyCtrl.value">
        Two-factor authentication (2FA) enhances security by combining something you know (your
        password) with something you have (your phone). This portal supports any OTP-compatible
        authenticator app, such as Microsoft Authenticator or Google Authenticator, which you can
        install on your phone or tablet. Follow these steps to set up 2FA:
        <ol>
            <li>Open any OTP-compatible authenticator app.</li>
            <li>Press the "Generate Secret Key" button.</li>
            <li>Scan the QR code or manually enter the key.</li>
            <li>Verify that 2FA is set up correctly.</li>
        </ol>

        Press "Generate Secret Key" after installing the OTP authenticator app.
    </itc-banner>

    <br *ngIf="!twoFactorKeyCtrl.value" />

    <itc-button
        label="Generate Secret Key"
        type="primary"
        icon="fa-key"
        (click)="generateSecretKey()"
        [loading]="generatingKey"
        *ngIf="!twoFactorKeyCtrl.value && !isRegenerate"></itc-button>
    <itc-button
        label="Regenerate Secret Key"
        type="primary"
        icon="fa-key"
        (click)="regenerateSecretKey()"
        [loading]="generatingKey"
        *ngIf="!twoFactorKeyCtrl.value && isRegenerate"></itc-button>

    <div *ngIf="twoFactorKeyCtrl.value && !isTwoFactorEnabled">
        <img
            src="https://api.qrserver.com/v1/create-qr-code/?size=250x250&data={{
                twoFactorQRCode
            }}"
            style="margin: 10px 0" />
        <sm-input
            [control]="twoFactorKeyCtrl"
            [readonly]="true"
            label="Secret Key (Text)"></sm-input>

        <br />

        <itc-banner type="info" *ngIf="twoFactorKeyCtrl.value">
            Use any OTP-compatible authenticator app to enter the current login code. The code
            updates periodically. If it changes, re-enter the new code and click Verify. Two-factor
            authentication will only be enabled after successful verification.
        </itc-banner>

        <br />

        <sm-input
            [control]="totpCtrl"
            label="Login Code"
            (keydown.enter)="enableTwoFactor()"></sm-input>

        <br />

        <itc-button
            label="Enable Two-Factor"
            type="primary"
            icon="fa-check"
            (click)="enableTwoFactor()"
            [loading]="enablingTwoFactor"></itc-button>
    </div>
</form>

<div *ngIf="isTwoFactorEnabled">
    <br />
    <itc-banner type="success">
        Two-Factor Authentication (2FA) has been enabled. You will be asked to provide a code every
        time you login, and to disable 2FA in the future.
    </itc-banner>
</div>
