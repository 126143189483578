<table
    sortable-table
    sortColumn="Created"
    sortDirection="desc"
    class="itc-table"
    (sorted)="onSorted($event)">
    <thead>
        <tr>
            <th sortable-column="Status" style="min-width: 100px">Status</th>

            <th sortable-column="Created">Date</th>

            <th *ngIf="!forSite" sortable-column="SiteName" style="min-width: 200px">Site</th>

            <th sortable-column="Message">Message</th>
        </tr>
    </thead>
    <tr *ngFor="let alert of alerts" (click)="detail(alert.Guid)">
        <td class="collapsing">
            <sds-alert-status-badge [alert]="alert"></sds-alert-status-badge>
        </td>
        <td class="collapsing">{{ alert.Created | date : 'short' }}</td>
        <td *ngIf="!forSite">{{ alert.SiteName }}</td>
        <td>
            <a routerLink="{{ alert.Guid }}">
                <b style="color: black">{{ alert.Message }}</b>
                <br />
                <span style="font-style: italic; color: #555">
                    {{ alert.DataObject?.Primary?.ObjectName }}
                </span>
            </a>
        </td>
    </tr>
</table>
