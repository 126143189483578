import { ReportTheme, HexColor, DEFAULT_CHART_QUALITY, DEFAULT_CHART_TRANSPARENCY } from './shared';

export class ModernOceanic implements ReportTheme {
    ThemeName = 'Modern Oceanic';
    // ReportModernizationThemeOriginalName = 'Modern Oceanic';

    DefaultFontName = 'Arial';
    DefaultFontSize = 20;
    DefaultTextColor = HexColor(0x80, 0x82, 0x84);

    Header1Size = 50;
    Header2Size = 36;
    Header3Size = 30;
    Header4Size = 26;

    HasBottomBorder = true;
    LineBottomBorder = true;

    DefaultTableFontSize = 16;
    TableHeaderBackgroundColor = HexColor(0x59, 0x59, 0x59);
    TableHeaderTextColor = HexColor(0xff, 0xff, 0xff);
    TableRowColor = HexColor(0xff, 0xff, 0xff);
    TableAtlRowColor = HexColor(0xe7, 0xe7, 0xe8);

    TableRowTextColor = HexColor(0x22, 0x1e, 0x1f);

    CriticalRiskColor = HexColor(0x91, 0x24, 0x3e);
    HighRiskColor = HexColor(0xdd, 0x4b, 0x50);
    MediumRiskColor = HexColor(0xf1, 0x8c, 0x43);
    LowRiskColor = HexColor(0xf8, 0xc8, 0x51);

    StopColor = HexColor(0xdc, 0x1c, 0x24);
    GoColor = HexColor(0x10, 0xcc, 0x41);
    DisabledColor = HexColor(153, 153, 153);

    GraphTitleColor = HexColor(0x59, 0x59, 0x59);
    GraphSubTitleColor = HexColor(0x59, 0x59, 0x59);
    XColor = HexColor(0x59, 0x59, 0x59);
    YColor = HexColor(0x59, 0x59, 0x59);
    GraphOutlineColor = HexColor(0x59, 0x59, 0x59);

    UseNewRiskMeter = true;

    ChartColorsPaletteIntList = [
        HexColor(189, 50, 49),
        HexColor(55, 157, 239),
        HexColor(221, 99, 47),
        HexColor(71, 141, 44),
        HexColor(217, 217, 18),

        HexColor(155, 107, 66),
        HexColor(208, 62, 115),
        HexColor(40, 33, 105),
        HexColor(60, 133, 133),
        HexColor(99, 57, 133),

        HexColor(155, 65, 3),
        HexColor(36, 82, 67),
        HexColor(132, 133, 188),
        HexColor(236, 118, 122),
        HexColor(83, 23, 65),

        HexColor(139, 198, 62),
        HexColor(33, 57, 140),
        HexColor(245, 179, 51),
        HexColor(55, 15, 42),
        HexColor(83, 110, 133),
    ];

    ChartTopFivePaletteIntList = [
        HexColor(55, 157, 239),
        HexColor(221, 99, 47),
        HexColor(71, 141, 44),
        HexColor(217, 217, 18),
        HexColor(155, 107, 66),
        HexColor(208, 62, 115),
    ];

    ChartTwoColorPaletteIntList = [HexColor(189, 50, 49), HexColor(55, 157, 239)];

    ChartQuality = DEFAULT_CHART_QUALITY;
    ChartTransparencyPercent = DEFAULT_CHART_TRANSPARENCY;

    ReportModernizationEnabled = true;
    UseNewRiskMeter2020 = true;
    ReportModernizationTableType = 'bottom border gray';

    ReportModernizationPrimaryColor = HexColor(0x51, 0xb5, 0xbf, true);
    ReportModernizationSecondaryColor = HexColor(0xc2, 0xd9, 0x4f, true);
    ReportModernizationSecondaryColorLighter = HexColor(0xa8, 0xb7, 0x90, true);
    ReportModernizationTertiaryColor = HexColor(0xbc, 0xbe, 0xc0, true);
    ReportModernizationHighlightColor = HexColor(0x00, 0x77, 0x93, true);
}
