import { Component, OnInit, ViewChild } from '@angular/core';
import { UiService } from 'app/core/ui.service';
import { CanComponentDeactivate } from 'app/deactivate-guard.service';
import { SemanticModalComponent } from 'app/semantic-legacy/components/modal/modal';
import { ItcFileUploaderComponent } from 'app/shared/itc';
import { FileUpload, FileUploaderSummary } from 'app/shared/itc/file-uploader/file-uploader.model';
import { NotificationService } from 'app/shared/itc/notification/notification.service';
import { KvsService } from 'app/sites/shared/kvs.service';
import { Site } from 'app/sites/shared/site.model';
import { SiteService } from 'app/sites/shared/site.service';

@Component({
    selector: 'kvs-proxy-settings',
    templateUrl: './kvs-proxy-settings.component.html',
    styleUrls: ['./kvs-proxy-settings.component.scss'],
})
export class KvsProxySettingsComponent implements OnInit, CanComponentDeactivate {
    breadcrumbs = [
        { path: '../..', text: 'VulScan' },
        { path: '..', text: 'Settings' },
        { path: '.', text: 'VulScan Proxy Settings' },
    ];

    loadingComplete: boolean;
    site: Site;
    currentFile: FileUpload[];
    connectionTestResult: boolean;
    isDirty: boolean;
    configReadyToTest: boolean;
    isSaved: boolean;
    checkingConnection: boolean;
    isUploading: boolean;
    isConfigUploaded: boolean;
    associatedScanTasks: any;
    fileToDelete: string;

    @ViewChild('confirmDeleteProxySettingsModal')
    confirmDeleteProxySettingsModal: SemanticModalComponent;
    @ViewChild('fileUploaderComp') fileUploaderComp: ItcFileUploaderComponent;

    constructor(
        private uiService: UiService,
        private kvsService: KvsService,
        private siteService: SiteService,
        private notificationService: NotificationService
    ) {}

    @ViewChild(ItcFileUploaderComponent) fileUploader: ItcFileUploaderComponent;

    ngOnInit(): void {
        this.loadingComplete = false;
        this.isDirty = false;
        this.isSaved = true;
        this.checkingConnection = false;
        this.isConfigUploaded = false;
        this.uiService.setTitle('VulScan Proxy Settings');
        this.site = this.siteService.getCurrentSite();
        this.getCurrentFile();
        this.loadingComplete = true;
    }
    getCurrentFile() {
        this.kvsService.getCurrentProxyConfig(this.site.Id).then((res) => {
            if (res.filename != '') {
                this.currentFile = [
                    {
                        name: res.filename,
                        canDelete: true,
                    },
                ] as FileUpload[];
                this.isConfigUploaded = true;
                this.fileToDelete = res.filename;
            }
        });
    }

    checkConnection() {
        this.checkingConnection = true;
        this.notificationService.toast.info('Info', 'OpenVPN Connection check started.');
        this.kvsService
            .checkProxyConfiguration(this.site.Id)
            .then((res) => {
                this.connectionTestResult = res.result;
                if (this.connectionTestResult) {
                    this.isDirty = true;
                    this.isSaved = false;
                    this.notificationService.toast.success(
                        'Success',
                        'OpenVPN Connection has been successfully established.'
                    );
                    this.checkingConnection = false;
                } else {
                    this.notificationService.toast.error(
                        'OpenVPN connection failed',
                        'Please verify the openVPN configuration file and try again.'
                    );
                    this.checkingConnection = false;
                }
            })
            .catch((e) => {
                this.connectionTestResult = false;
                this.isDirty = false;
                this.notificationService.toast.error(
                    'OpenVPN connection failed',
                    'Please verify the openVPN configuration file and try again.'
                );
                this.checkingConnection = false;
            });
    }

    uploadDone(upload: FileUploaderSummary) {
        if (upload.errors.length === undefined || upload.errors.length == 0) {
            this.configReadyToTest = true;
            this.isDirty = false;
            this.isSaved = false;
            this.isConfigUploaded = true;
        }
    }

    upload(file: FileUpload) {
        this.currentFile = [] as FileUpload[];
        console.log('uploading file', file);
        let formData: FormData = new FormData();
        formData.append('configFile', file.file, file.file.name);
        this.fileUploader.processUploadProgress(
            file,
            this.kvsService.uploadProxyConfiguration(this.site.Id, formData)
        );
    }

    confirmDeleteProxy() {
        this.kvsService
            .getKvsRivsScanTasks(this.site.Id)
            .then((res) => {
                this.associatedScanTasks = res;
                this.confirmDeleteProxySettingsModal.show({ closable: false });
            })
            .catch((err) => {
                this.notificationService.toast.error('Error', "Can't Load Tasks used by RIVS");
            });
    }

    removeFile() {
        this.kvsService
            .removeProxyConfiguration(this.site.Id)
            .then((res) => {
                if (res.result) {
                    this.configReadyToTest = false;
                    this.isDirty = false;
                    this.isSaved = true;
                    this.isConfigUploaded = false;
                    this.currentFile = [] as FileUpload[];
                    this.notificationService.toast.success(
                        'Success',
                        'VulScan Proxy setting was removed.'
                    );
                } else {
                    this.notificationService.toast.error(
                        'Error',
                        'There was a problem removing your preferences, please try again.'
                    );
                }
                this.confirmDeleteProxySettingsModal.hide();
            })
            .catch(() => {
                this.notificationService.toast.error(
                    'Error',
                    'There was a problem removing your preferences, please try again.'
                );
            });
    }

    saveProxySettings() {
        this.kvsService
            .saveProxyConfiguration(this.site.Id)
            .then((res) => {
                if (res.result) {
                    this.fileUploaderComp.clearUploads();
                    this.getCurrentFile();
                    this.isSaved = res.result;
                    this.isDirty = !res.result;
                    this.notificationService.toast.success(
                        'Success',
                        'VulScan Proxy setting was updated.'
                    );
                } else {
                    this.isSaved = false;
                    this.isDirty = true;
                    this.notificationService.toast.error(
                        'Error',
                        'There was a problem saving your preferences, please try again.'
                    );
                }
            })
            .catch(() => {
                this.notificationService.toast.error(
                    'Error',
                    'There was a problem saving your preferences, please try again.'
                );
            });
    }

    canDeactivate() {
        if (this.isSaved) return true;
        else {
            if (
                confirm(
                    'The current site proxy settings has unsaved changes. Do you wish to leave without saving changes?'
                )
            )
                return true;
            else return false;
        }
    }
}
