import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function passwordValidator(passwordName: string, confirmPasswordName: string): ValidatorFn {
    return (form: AbstractControl): ValidationErrors | null => {
        const pw = form.get(passwordName);
        const confpw = form.get(confirmPasswordName);
        const newPass = pw.value?.trim();

        /* Password rules */
        const minLength = 8;
        const upperCaseRegExp = new RegExp('^(?=.*?[A-Z])');
        const lowerCaseRegExp = new RegExp('^(?=.*?[a-z])');
        const numberRegExp = new RegExp('^(?=.*?[0-9])');
        const symbolRegExp = new RegExp('^(?=.*?[!"#$%&\'()*+,-.\\/:;<=>?@[\\\\\\]\\^_`{|}~])');

        // return null if either field isn't filled in, or if there are other errors
        if (
            !pw ||
            !confpw ||
            (confpw.errors && !confpw.errors.hasError) ||
            (!pw.dirty && !confpw.dirty)
        ) {
            return null;
        }

        const hasUpperCase = upperCaseRegExp.test(newPass);
        const hasLowerCase = lowerCaseRegExp.test(newPass);
        const hasNumeric = numberRegExp.test(newPass);
        const hasSymbol = symbolRegExp.test(newPass);

        if (pw.value !== confpw.value) {
            // must match
            return { hasError: 'Passwords must match.' };
        } else if (newPass.length < minLength) {
            return {
                hasError: 'Password must be at least 8 characters long.',
            };
        } else if (!hasUpperCase || !hasLowerCase || !hasNumeric || !hasSymbol) {
            return {
                hasError:
                    'Password must contain at least 1 upper case letter, 1 lowercase letter, 1 number and 1 symbol.',
            };
        }

        return null;
    };
}
