<div *ngIf="file" class="file-upload-container" [class.upload-error]="file.state === 'error'">
    <div class="file-info">
        <fa-icon [icon]="file.icon || 'file-lines'"></fa-icon>
        <span  truncateText class="file-name">{{ file.name }}</span>
        <span class="file-size">{{ file.size }}</span>
        <span class="progress" *ngIf="file.state !== 'complete' && file.state !== 'error'">
            {{ file.progress }}%
        </span>
        <itc-button
            *ngIf="file.canDelete"
            type="icon"
            icon="fa-trash"
            (onclick)="removeFile.emit(file)"></itc-button>
    </div>
    <itc-progress-bar
        *ngIf="file.state !== 'complete'"
        class="errorMessage"
        [progress]="file.progress"
        [error]="
            file.state === 'error' ? 'There was a problem uploading your file. Try again' : null
        "></itc-progress-bar>
</div>
