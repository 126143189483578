<sds-breadcrumb-trail [crumbs]="breadcrumbs"></sds-breadcrumb-trail>

<header class="pageHeader">
    <h1>Client View</h1>
</header>

<form class="ui form settingsPage smallerContainer">
    <div class="sectionHeader">
        <h3>Manage Published Reports</h3>
    </div>

    <div class="eight wide" style="flex: 1">
        <div class="ui secondary menu">
            <div class="item">
                <div class="ui left labeled input">
                    <div
                        class="ui label darker"
                        style="border-top-right-radius: 0; border-bottom-right-radius: 0">
                        Report Set
                    </div>
                    <sm-select [control]="reportSetCtrl" #reportSetSelect>
                        <option
                            *ngFor="let set of reportSets"
                            value="{{ set }}"
                            selected="{{ selectedReportSet == set ? 'selected' : '' }}">
                            {{ set }}
                        </option>
                    </sm-select>
                </div>
            </div>

            <div class="item">
                <itc-button
                    type="secondary"
                    icon="fa-download"
                    [disabled]="!selectedReportSet"
                    [loading]="downloadingReportSetZip"
                    (onclick)="downloadReportSetZip()">
                    Download .zip
                </itc-button>
            </div>
            <div *ngIf="!isClient" class="item">
                <itc-button
                    type="danger"
                    icon="fa-trash-can"
                    [disabled]="!selectedReportSet"
                    [loading]="deletingReportSet"
                    (onclick)="showDeleteReportSetConfirm()">
                    Delete Report Set
                </itc-button>
            </div>
        </div>

        <itc-banner type="info" *ngIf="loadingComplete && !reportSets?.length">
            No Reports Found. Published reports can be generated via Reporter when you select
            Publish to Client Portal in the Schedule Reports Wizard.
        </itc-banner>

        <h5 class="ui top attached header" *ngIf="listLoading" style="background: #f9fafb">
            &nbsp;
        </h5>
        <div
            class="ui attached segment loading"
            *ngIf="listLoading"
            style="min-height: 250px"></div>

        <table
            class="itc-table"
            *ngIf="reportList?.length && !listLoading"
            sortable-table
            sortColumn="filename"
            sortDirection="asc"
            (sorted)="onSort($event)">
            <thead>
                <tr>
                    <th sortable-column="filename">Filename</th>
                    <th sortable-column="lastmodifiedformatted">
                        Last Modified
                        <span class="UTCHeaderRef">UTC</span>
                    </th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let report of reportList">
                    <td>{{ report.filename }}</td>
                    <td>{{ report.lastmodifiedformatted | date : timeDateFormat : 'UTC' }}</td>
                    <td class="collapsing">
                        <itc-button
                            type="icon"
                            icon="fa-download"
                            [class.disabled]="report.deleting"
                            [class.loading]="report.loading"
                            (click)="downloadReport(report)"
                            tooltip="Download"></itc-button>
                        <itc-button
                            *ngIf="!isClient"
                            type="icon"
                            icon="fa-trash-can"
                            [class.disabled]="report.loading"
                            [class.loading]="report.deleting"
                            (click)="showDeleteReportConfirm(report)"
                            style="margin-left: 6px"
                            tooltip="Delete"></itc-button>
                    </td>
                </tr>
            </tbody>
        </table>

        <itc-banner type="info" *ngIf="selectedReportSet && !reportList?.length && !listLoading">
            No reports found in the selected report set.
        </itc-banner>
    </div>

    <!--<br />
  <br />

  <div class="ui right floated">
    <sds-button class="darker" icon="undo" [disabled]="savingSettings" (onclick)="undoModal.show()">
      Undo
    </sds-button>
    <sds-button color="primary" icon="save" [disabled]="savingSettings" [loading]="savingSettings" (onclick)="saveSettings()">
      Save
    </sds-button>
  </div>-->
</form>

<sm-modal title="Confirm" class="xs" #undoModal>
    <modal-content>
        <itc-banner type="warning" title="Are you sure?">
            Any changes you have made to the page will be lost.
        </itc-banner>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" class="cancel">No</itc-button>
        <itc-button type="primary" (onclick)="undoChanges()">Yes</itc-button>
    </modal-actions>
</sm-modal>

<sm-modal title="Delete Report" class="xs" #confirmModal>
    <modal-content>
        {{ deleteMessage }}
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" class="cancel" (onclick)="cancelDeleteReport()">No</itc-button>
        <itc-button
            type="danger"
            [loading]="removing || deletingReportSet"
            (onclick)="reportToDelete ? deleteReport(reportToDelete) : deleteReportSet()">
            Yes
        </itc-button>
    </modal-actions>
</sm-modal>
