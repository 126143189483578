import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';

import { Alert, AlertService } from 'app/alerts';
import { Audit } from '../../audit/shared/audit.model';
import { AuditService } from '../../audit/shared/audit.service';
import { MessageService } from 'app/core/message.service';
import { Notes } from '../../../shared/notes/notes.model';
import { NotesService } from '../../../shared/notes/notes.service';
import { ScanDataService } from 'app/scans/shared/scan-data.service';
import { Site } from 'app/sites/shared/site.model';
import { SiteService } from 'app/sites/shared/site.service';
import { SettingService } from 'app/settings/shared/setting.service';
import { UiService } from 'app/core/ui.service';

import { SITE, PING_SITE } from 'app/sites/shared/constants';
import { AuthService } from 'app/core/auth';

// import { SIDEBAR_LOADER } from 'app/shared/sidebar/layout/sidebar-layout.component';

const TODO_LOADER: string = 'TODO_LOADER';
declare var $: any;

@Component({
    templateUrl: './indoc-dashboard.component.html',
    styleUrls: ['./indoc-dashboard.component.css'],
})
export class IndocDashboardComponent implements OnInit, OnDestroy {
    products: any = {};

    constructor(
        private alertService: AlertService,
        private auditService: AuditService,
        private messageService: MessageService,
        private notesService: NotesService,
        private scandataService: ScanDataService,
        private settingService: SettingService,
        private datePipe: DatePipe,
        private uiService: UiService,
        private authService: AuthService
    ) {}

    @ViewChild('riskInfoModal', { static: true }) riskInfoModal: any;

    site: Site;
    todos: Alert[];
    auditLog: Audit[];
    notes: Notes = new Notes({
        Id: '',
        SiteId: -1,
        ItemId: '{"Site":""}',
        Body: '',
        PrivateBody: '',
        Title: '',
        Category: 'Notes',
    });
    todoLoading: boolean;
    managementPlansLoading: boolean;

    loadingComplete: boolean;
    subs: any[] = [];

    riskScore: number = 0;
    riskModalInfo: any;

    showRiskScore: boolean = false;
    showManagementPlan: boolean = true;
    showNotes: boolean = true;
    showAssetSummary: boolean = true;
    showTodos: boolean = true;
    showAudits: boolean = true;

    summaryInfo: any;
    managementPlans: any[];
    indocOnly: boolean;

    outdatedScan: boolean;
    emptyScan: boolean;
    scanDate: string;
    publishDate: string;
    timeZoneOffset: string;
    timezones: any;
    refPublishDate: Date;
    refScanDate: Date;
    scanDateFormatted: string;
    publishDateFormatted: string;
    info: any;

    breadcrumbs = [{ path: '.', text: 'Dashboard' }];

    ngOnInit() {
        this.products = this.authService.getProducts();

        if (this.products.NdPro)
            this.breadcrumbs.unshift({ path: '..', text: 'Network Detective Pro' });
        else this.breadcrumbs.unshift({ path: '..', text: 'InDoc' });

        this.subs.push(
            this.messageService.on(SITE).subscribe((site) => {
                this.onSite(site);
            })
        );
        this.messageService.broadcast(PING_SITE);

        this.todoLoading = true;
        this.managementPlansLoading = true;
    }

    ngOnDestroy() {
        for (let sub of this.subs) sub.unsubscribe();
    }

    onSite(site: Site) {
        this.site = site;
        this.uiService.setTitle('Dashboard', site.Name);
        if (this.site) {
            let initSite = this.site;

            this.scandataService.getDomainInfo(this.site.Id).then((info) => {
                if (Object.keys(info).length == 0) {
                    this.emptyScan = true;
                    this.summaryInfo = {};
                    return;
                } else if (info.error == 'outdated') {
                    this.outdatedScan = true;
                    return;
                }
                this.info = info;
                this.scanDate = info.ScanDate;
                this.publishDate = info.PublishedDate;

                this.settingService.getTimeZones().then((timezones) => {
                    this.timezones = timezones; //get the timezone data from tzinfo.ts
                    console.log(this.timezones);
                    this.getSettings(this.info);
                });

                //this.settingService.getSettings()
                //  .then(settings => this.process(settings)).catch(error => console.log(error));
                //this.summaryInfo = (typeof info.Domain !== 'undefined') ? info.Domain.Summary : {};
            });

            //this.scandataService.getManagementPlans(this.site.Id)
            //  .then((plans) => {
            //    if (plans.error == 'outdated') {
            //      this.outdatedScan = true;
            //      return;
            //    }

            //    for (let key in plans) {
            //      // remove null or objects with no length
            //      if (plans[key] === null || (plans[key].length <= 0)) {
            //        delete plans[key];
            //      } else {
            //        plans[key] = plans[key].sort(this.sortRiskScores);
            //      }
            //    }
            //    this.managementPlans = plans;
            //    this.managementPlansLoading = false;
            //    console.log("managementplans");
            //    console.log(this.managementPlans);
            //  });

            this.indocOnly = this.site.IsIndoc && !this.site.Type;

            //if (!this.indocOnly) {
            //  this.todoLoading = true;
            //  this.alertService.getSiteTodos(this.site.Id, 5, false)
            //    .then((todos) => {
            //      if (initSite.Id == this.site.Id) {
            //        this.todos = todos;
            //        this.todoLoading = false;
            //      }
            //    });
            //}

            //this.auditService.getAlertHistoryBySite(this.site.Id, 5)
            //  .then((audits) => {
            //    if (this.site && initSite.Id == this.site.Id) {
            //      this.auditLog = audits;
            //      console.log(this.auditLog);
            //    }
            //  });

            this.notes = new Notes({
                Id: '',
                SiteId: this.site.Id,
                ItemId: '{"Site":""}',
                Body: '',
                PrivateBody: '',
                Title: '',
                Category: 'Notes',
            });

            console.log('getting notes');
            this.notesService.getNotes(this.site.Id, 'Notes', 'Site', '').then((notes) => {
                if (this.site && initSite.Id == this.site.Id && notes != null) {
                    this.notes = notes[0];
                }
            });

            // this.riskScore = (this.site.Id - (this.site.Id % 5)) % 100;

            this.loadingComplete = true;
        }
    }

    getSettings(info: any) {
        this.settingService
            .getSettings()
            .then((settings) => this.process(settings))
            .catch((error) => console.log(error));
        this.summaryInfo = typeof info.Domain !== 'undefined' ? info.Domain.Summary : {};
    }

    saveNotes(notes: Notes) {
        if (!notes.Id) {
            this.notesService.postNotes(notes).then((res) => {
                notes.Id = res.headers.get('X-Note-Id');
                this.notes = notes;
            });
        } else {
            this.notesService.putNotes(notes).then((res) => {
                this.notes = notes;
            });
        }
    }

    showRiskModal(obj) {
        this.riskModalInfo = obj;
        this.riskModalInfo.SeverityDisplay = this.calculateDisplaySeverity(
            this.riskModalInfo.Severity
        );
        this.riskModalInfo.RiskDisplay = this.calculateDisplaySeverity(
            this.riskModalInfo.RiskScore
        );
        this.riskModalInfo.ProbabilityDisplay = this.calculateDisplaySeverity(
            this.riskModalInfo.Probability
        );
        this.riskInfoModal.show();
    }

    calculateDisplaySeverity(num) {
        if (num >= 80) {
            return 'high';
        } else if (num >= 50) {
            return 'medium';
        } else {
            return 'low';
        }
    }

    sortRiskScores(a, b) {
        if (+a.RiskScore < +b.RiskScore) {
            return 1;
        }
        if (+a.RiskScore > +b.RiskScore) {
            return -1;
        }
        return 0;
    }

    parseRiskManagementTitle(title) {
        title = title.replace(/(.*)Manage*?mentPlan/, '$1'); // there was a typo in the key that was coming back, AB is gonna fix it but this regex is here till then, and we can just remove the word if we want.
        title = title.replace(/([A-Z])/g, ' $1');
        return title.trim();
    }

    isEmpty(obj) {
        for (var item in obj) {
            return false;
        }
        return true;
    }

    process(settings: any) {
        if (!settings || !Object.keys(settings).length) return;

        let newOffset: any;

        //get the timezone for the settings
        let timeZone = '';
        let supportsDaylightSavingTime = false;
        for (let s of settings) {
            if (s.Name == 'TZINFO') {
                timeZone = s.Value;
                break;
            }
        }

        //get the timezone offset for the specified timezone
        for (let tz of this.timezones) {
            if (tz.Id == timeZone) {
                this.timeZoneOffset = tz.BaseUtcOffset.slice(0, -3); // timezone offset format is originally '-00:00:00' or '00:00:00'. this removes the last ':00'
                this.timeZoneOffset[0] == '-'
                    ? this.timeZoneOffset
                    : (this.timeZoneOffset = '+' + this.timeZoneOffset); //the GMT offset requires a - or +, so this add the + for positive offsets
                supportsDaylightSavingTime = tz.SupportsDaylightSavingTime;
                break;
            }
        }

        // format the dates based on what's specified in Admin Settings, General, Date Format
        for (let s of settings) {
            if (s.Name == 'DateFormat') {
                let utcScanDate = this.dateProcess(this.scanDate);
                let utcPublishDate = this.dateProcess(this.publishDate);
                this.scanDateFormatted = utcScanDate
                    ? this.datePipe.transform(
                          utcScanDate,
                          (s.Value != null ? s.Value : 'MM/dd/yyyy') + ' hh:mm:ss a',
                          this.timeZoneOffset
                      )
                    : 'N/A';
                this.publishDateFormatted = utcPublishDate
                    ? this.datePipe.transform(
                          utcPublishDate,
                          (s.Value != null ? s.Value : 'MM/dd/yyyy') + ' hh:mm:ss a',
                          this.timeZoneOffset
                      )
                    : 'N/A';
                this.refPublishDate = new Date(this.publishDate); //this is to have a Date() object to work with for Daylight Saving Time
                this.refScanDate = new Date(this.scanDate);

                //check to see if the published date in the selected timezone is in daylight savings time now, and if so, save it with the adjusted offset.
                //do scan and published separately in case they were done on different days with one before and one after Daylight Saving.
                if (
                    supportsDaylightSavingTime &&
                    this.isDaylightSavingTime(timeZone, this.refScanDate)
                ) {
                    newOffset = this.offsetProcess(this.timeZoneOffset, timeZone);
                    this.scanDateFormatted = utcScanDate
                        ? this.datePipe.transform(
                              utcScanDate,
                              (s.Value != null ? s.Value : 'MM/dd/yyyy') + ' hh:mm:ss a',
                              newOffset
                          )
                        : 'N/A';
                }
                if (
                    supportsDaylightSavingTime &&
                    this.isDaylightSavingTime(timeZone, this.refPublishDate)
                ) {
                    newOffset = this.offsetProcess(this.timeZoneOffset, timeZone);
                    this.publishDateFormatted = utcPublishDate
                        ? this.datePipe.transform(
                              utcPublishDate,
                              (s.Value != null ? s.Value : 'MM/dd/yyyy') + ' hh:mm:ss a',
                              newOffset
                          )
                        : 'N/A';
                }
                console.log(this.publishDate);
                break;
            }
        }
    }

    //this is used to force the published date to be in UTC before being displayed as local time.
    //For some reason, without doing this, it will show the the UTC time but say it is the local time
    dateProcess(publishDate: string) {
        let dateAndTime: string[] = publishDate.split('T');
        let date: any[] = dateAndTime[0].split('-');
        let time: any[] = dateAndTime[1].split(':');
        return new Date(
            Date.UTC(date[0], date[1] - 1, date[2], time[0], time[1], time[2])
        ).toUTCString();
    }

    //this adjusts the offset for Daylight Saving Time. All DST is -1 hour from the absolute value of the offset with the exception of Lord How Daylight Time
    offsetProcess(offset: string, timeZone: string) {
        if (timeZone == 'Lord Howe Standard Time') {
            return '+11:00';
        } else {
            //let posOrNeg = offset[0] == '-' ? offset[0] : '+';
            let temp = parseInt(offset.slice(0, 3)) + 1; //EST is UTC-5, add 1 for EDT (UTC-4)
            let end = offset.slice(3); //save the minutes format ':00'
            let daylightOffset = temp + end;
            daylightOffset = daylightOffset.toString();

            return daylightOffset[0] == '-' ? daylightOffset : '+' + daylightOffset; //add '+' to a positive offset
        }
    }

    //this is used to determin if the selected timezone is currently in Daylight Saving Time or not.
    isDaylightSavingTime(timeZone: any, referenceDate: Date) {
        //first acquire the rules that govern daylight saving time. start/end week either corresponds to the week number or the ordinal number of the day of the week (i.e. 2nd Sunday)
        let startMonth: number, startWeek: number, startDayofWeek: number;
        let endMonth: number, endWeek: number, endDayofWeek: number;
        for (let tz of this.timezones) {
            if (tz.Id == timeZone) {
                let adjustmentRules = tz.AdjustmentRules;
                for (let rule of adjustmentRules) {
                    // 9999 is used to grad the rules for the most recent years
                    if (rule.DateEnd.startsWith('9999')) {
                        //save the Month, Week, and DayOfWeek that Daylight Savings begins
                        startMonth = rule.DaylightTransitionStart.Month;
                        startWeek = rule.DaylightTransitionStart.Week;
                        startDayofWeek = rule.DaylightTransitionStart.DayOfWeek;
                        //save the Month, Week, and DayOfWeek that Daylight Savings ends
                        endMonth = rule.DaylightTransitionEnd.Month;
                        endWeek = rule.DaylightTransitionEnd.Week;
                        endDayofWeek = rule.DaylightTransitionEnd.DayOfWeek;
                    }
                }
            }
        }
        //grab the processed published date that was saved as refPublishDate and see if it falls during daylight savings or not
        //if the date is between the months it starts and ends, then no further processing is necessary
        if (startMonth < referenceDate.getMonth() + 1 && referenceDate.getMonth() + 1 < endMonth) {
            return true;
        }
        //if the published date is the same month that Daylight Saving starts, get the start date and compare with the published date
        else if (referenceDate.getMonth() + 1 == startMonth) {
            let DSTStartDate: Date;
            let count = 0;
            let year = new Date().getFullYear();
            let dayTracker: Date;
            for (let day = 1; day <= 31; day++) {
                let testDate = new Date(year, startMonth - 1, day);
                if (startMonth != testDate.getMonth()) {
                    //for months without 31 days, the date will roll over to the next month, so terminate the loop  if the month changes.
                    break;
                }
                if (testDate.getDay() == startDayofWeek) {
                    count++;
                    dayTracker = testDate; //keep track of each date that day is on.
                }
                //this checks for the ordinal number of the day of the week, i.e. 2nd Sunday.
                //the week corresponds to the ordinal number of the day of the week. 5th week would just be the last of that day.
                if (count == startWeek) {
                    //loop will terminate if the day is hit. It will finish if the specified is the 5th day and there is no 5th that year. The last was stored already.
                    break;
                }
            }
            DSTStartDate = dayTracker;
            if (referenceDate >= DSTStartDate) {
                return true;
            } else {
                return false;
            }
        }
        //if the published date is the same month that Daylight Saving ends, get the end date and compare with the published date
        else if (referenceDate.getMonth() + 1 == endMonth) {
            let DSTEndDate: Date;
            let count = 0;
            let year = new Date().getFullYear();
            let dayTracker: Date;
            for (let day = 1; day <= 31; day++) {
                let testDate = new Date(year, endMonth - 1, day);
                if (startMonth != testDate.getMonth()) {
                    //for months without 31 days, the date will roll over to the next month, so terminate the loop if the month changes.
                    break;
                }
                if (testDate.getDay() == startDayofWeek) {
                    count++;
                    dayTracker = testDate;
                }
                if (count == endWeek) {
                    break;
                }
            }
            DSTEndDate = dayTracker;
            if (referenceDate >= DSTEndDate) {
                return false;
            } else {
                return true;
            }
        }
    }
}
