<itc-banner *ngIf="saveErrors" type="error">
    Error: Your answer has not been saved. Please check your network connection and try again.
</itc-banner>
<div
    class="wizardContainer"
    [ngClass]="{
        wizardArchivedContainer:isInArchivedAssessment,
        hotKeysVisible: showHotkeys,
        wizardview: wizardView == 'wizard',
        listview: wizardView == 'list',
        isCSPro: site?.IsCSPro,
    }">
    <div class="wizardContentContainer">
        <div class="wizardProgressContainer" *ngIf="_allQuestions?.length">
            <p>You have {{ remainingQuestions }}/{{ _allQuestions?.length }} questions remaining</p>
            <div class="wizardProgress">
                <div
                    class="wizardProgressComplete"
                    [style.width]="completedQuestions / _allQuestions?.length | percent"></div>
            </div>
            <span style="font-size: 14px">
                {{ completedQuestions / _allQuestions?.length | percent }} completed
            </span>
            <div class="listViewModeToggle" [class.disabled]="IsSaving">
                <a *ngIf="wizardView == 'wizard'" routerLink="" (click)="toggleView('list')">
                    View All Questions as List
                </a>
                <a *ngIf="wizardView == 'list'" routerLink="" (click)="toggleView('wizard')">
                    Back to Wizard
                </a>
            </div>
        </div>
        <div id="wizardView" *ngIf="wizardView == 'wizard'">
            <div class="questionNumberContainer">
                <ng-container *ngIf="!options.sortByStandards">
                    <p class="questionNumber">
                        Question {{ activeQuestionIndex }} of {{ _allQuestions?.length }}
                    </p>
                </ng-container>
                <ng-container *ngIf="options.sortByStandards">
                    <p class="questionNumber">
                        {{ selectedStandard === 'all' ? '' : selectedStandard + ' ' }}Question
                        {{ activeQuestionIndex }} of {{ displayedQuestions?.length }}
                    </p>
                    <sm-select
                        [control]="standardSelectCtrl"
                        class="standardSelectBox"
                        (onChange)="chooseStandard($event)"
                        *ngIf="options.sortByStandards">
                        <option value="all">All Standards</option>
                        <option *ngFor="let std of questionStandards">{{ std }}</option>
                    </sm-select>
                </ng-container>
            </div>

            <div class="wizardContent">
                <div class="wizardText">
                    <div class="preWhiteSpace" [innerHTML]="questionDescriptionText"></div>
                </div>

                <div class="wizardGuidance" *ngIf="site?.IsCSPro">
                    <a
                        class="csprolink"
                        *ngIf="csProGuidance && options.showCSGuidance"
                        (click)="jumpToCSPro()">
                        Jump to
                        <span class="csProIcon"></span>
                        Guidance
                    </a>
                    <p>
                        <strong style="display: block">General Guidance</strong>
                        <span class="preWhiteSpace" [innerHTML]="questionGuidance"></span>
                    </p>
                    <p *ngIf="csProGuidance && options.showCSGuidance" id="csProGuidance">
                        <strong
                            style="display: flex; align-items: center"
                            *ngIf="options.showCSGuidance">
                            <span class="csProIcon" title="CS Pro"></span>
                            Guidance
                        </strong>
                        <span [innerHTML]="csProGuidance | nl2br"></span>
                    </p>
                </div>

                <div class="wizardResourcesContainer">
                    <div class="wizardResources">
                        <div class="resourcesSectionHeader">
                            <p>Resources</p>
                            <itc-button
                                type="icon"
                                size="small"
                                [icon]="allResourcesExpanded ? 'fa-compress' : 'fa-expand'"
                                (onclick)="toggleAllResources()">
                                {{
                                    allResourcesExpanded
                                        ? '&nbsp;&nbsp;Collapse All'
                                        : '&nbsp;&nbsp;Expand All'
                                }}
                            </itc-button>
                        </div>

                        <section
                            *ngIf="options.showEvidence"
                            [class.open]="resourceCategories?.evidenceOpen">
                            <header (click)="toggleResource('evidenceOpen')">
                                <fa-icon
                                    [icon]="
                                        resourceCategories?.evidenceOpen
                                            ? 'chevron-up'
                                            : 'chevron-down'
                                    "
                                    [fixedWidth]="true"></fa-icon>
                                Evidence of Compliance
                                <span class="resourceCount">
                                    {{
                                        activeQuestion?.Evidence
                                            ? activeQuestion.Evidence.length
                                            : 0
                                    }}
                                </span>
                            </header>
                            <div [hidden]="!resourceCategories?.evidenceOpen">
                                <itc-button
                                    type="secondary"
                                    icon="fa-plus"
                                    size="small"
                                    [disabled]="isReadOnly"
                                    (onclick)="showImportComplianceModal()">
                                    <span>Add Evidence</span>
                                </itc-button>
                                <table
                                    class="itc-table"
                                    *ngIf="
                                        activeQuestion?.Evidence && activeQuestion?.Evidence?.length
                                    "
                                    style="margin-bottom: 0px">
                                    <tr *ngFor="let evidence of activeQuestion?.Evidence">
                                        <td class="left floated" style="width: 65%">
                                            <div
                                                style="
                                                    display: flex;
                                                    align-items: center;
                                                    height: 24px;
                                                ">
                                                <div
                                                    class="column"
                                                    style="height: 24px; width: 24px">
                                                    <img
                                                        [src]="evidence.ProductIconUrl"
                                                        width="24"
                                                        height="24" />
                                                </div>
                                                <div
                                                    class="column"
                                                    truncateText
                                                    truncateWidth="100% - 10px"
                                                    [title]="evidence.Name"
                                                    style="padding-left: 5px">
                                                    {{ evidence.Name }}
                                                </div>
                                            </div>
                                        </td>
                                        <td class="right floated">
                                            <span style="margin-right: 6px">
                                                <itc-button
                                                    type="icon"
                                                    icon="fa-chart-pie"
                                                    tooltip="View Evidence"
                                                    (onclick)="showReport(evidence)"></itc-button>
                                            </span>
                                            <span style="margin-right: 6px">
                                                <itc-button
                                                    type="icon"
                                                    icon="fa-arrow-down-to-line"
                                                    tooltip="Download"
                                                    [loading]="evidence.downloading"
                                                    (onclick)="
                                                        downloadEvidence(evidence)
                                                    "></itc-button>
                                            </span>
                                            <span>
                                                <itc-button
                                                    type="icon"
                                                    icon="fa-trash"
                                                    tooltip="Delete"
                                                    (onclick)="startDelete(evidence)"></itc-button>
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </section>

                        <section
                            *ngIf="options.showRelatedIssues"
                            [class.open]="resourceCategories.relatedIssuesOpen">
                            <header (click)="toggleResource('relatedIssuesOpen')">
                                <fa-icon
                                    [icon]="
                                        resourceCategories.relatedIssuesOpen
                                            ? 'chevron-up'
                                            : 'chevron-down'
                                    "
                                    [fixedWidth]="true"></fa-icon>
                                Related Technical Issues
                                <span class="resourceCount">
                                    {{ activeQuestion?.RelatedIssues?.length }}
                                </span>
                            </header>
                            <div [hidden]="!resourceCategories.relatedIssuesOpen">
                                <sds-loader
                                    [complete]="!techIssuesLoading"
                                    style="position: relative">
                                    <span
                                        style="color: #aaa"
                                        *ngIf="
                                            !activeQuestion?.RelatedIssues ||
                                            !activeQuestion?.RelatedIssues?.length
                                        ">
                                        No technical issues.
                                    </span>
                                    <table
                                        class="itc-table riskIssues"
                                        *ngIf="
                                            activeQuestion?.RelatedIssues &&
                                            activeQuestion?.RelatedIssues?.length
                                        ">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Issue</th>
                                                <th>Risk Score</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                *ngFor="let item of activeQuestion?.RelatedIssues"
                                                (click)="showRiskModal(item)"
                                                [class]="
                                                    'risk_' + calculateDisplaySeverity(item.Score)
                                                "
                                                style="cursor: pointer">
                                                <td></td>
                                                <td>{{ item.Title }}</td>
                                                <td>{{ item.Score }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </sds-loader>
                            </div>
                        </section>

                        <section
                            *ngIf="options.showRelatedControls"
                            [class.open]="resourceCategories.relatedControlsOpen">
                            <header (click)="toggleResource('relatedControlsOpen')">
                                <fa-icon
                                    [icon]="
                                        resourceCategories.relatedControlsOpen
                                            ? 'chevron-up'
                                            : 'chevron-down'
                                    "></fa-icon>
                                Related Controls
                                <span class="resourceCount">
                                    {{ activeQuestion?.RelatedControls?.length }}
                                </span>
                            </header>
                            <div [hidden]="!resourceCategories.relatedControlsOpen">
                                <cm-wizard-related-controls
                                    [activeQuestion]="activeQuestion"
                                    [culture]="culture"
                                    (showReadOnly)="
                                        showReadOnlyModal($event)
                                    "></cm-wizard-related-controls>
                            </div>
                        </section>

                        <section
                            *ngIf="options.showCommentsTab"
                            [class.open]="resourceCategories.commentsOpen">
                            <header (click)="toggleResource('commentsOpen')">
                                <fa-icon
                                    [icon]="
                                        resourceCategories.commentsOpen
                                            ? 'chevron-up'
                                            : 'chevron-down'
                                    "
                                    [fixedWidth]="true"></fa-icon>
                                Comments
                            </header>
                            <div [hidden]="!resourceCategories.commentsOpen">
                                <textarea
                                    *ngIf="activeQuestion"
                                    class="wizardCommentTextarea sidebarWizardCommentTextarea"
                                    [(ngModel)]="activeQuestion.Comment"
                                    placeholder="Enter your comments"
                                    [disabled]="isReadOnly"
                                    autoResize></textarea>
                            </div>
                        </section>

                        <section
                            *ngIf="options.showFilesTab"
                            [class.open]="resourceCategories.filesOpen"
                            class="resourceFiles">
                            <header (click)="toggleResource('filesOpen')">
                                <fa-icon
                                    [icon]="
                                        resourceCategories.filesOpen ? 'chevron-up' : 'chevron-down'
                                    "
                                    [fixedWidth]="true"></fa-icon>
                                Files
                                <span class="resourceCount">
                                    {{ activeQuestion?.Attachments?.length }}
                                </span>
                            </header>
                            <div
                                style="position: relative; height: 100%"
                                [hidden]="!resourceCategories.filesOpen">
                                <dropdown-button
                                    buttonType="secondary"
                                    buttonLabel="Add Files"
                                    buttonIcon="fa-plus"
                                    buttonSize="small"
                                    panelTop="true"
                                    [disabled]="isReadOnly"
                                    [menuOptions]="dropdownMenuOptions">
                                    <dropdown-header>
                                        <itc-file-uploader
                                            type="dropdown"
                                            buttonText="Upload Local File"
                                            returnType="upload"
                                            [allowMultiple]="true"
                                            [extraInfo]="aqId"
                                            [maxConcurrentUploads]="1"
                                            (uploadFile)="uploadAttachment($event)"
                                            (uploadStart)="addPendingUploadFiles($event)"
                                            style="width: 100%; height: 100%"></itc-file-uploader>
                                    </dropdown-header>
                                </dropdown-button>

                                <div
                                    class="fileListContainer"
                                    *ngIf="
                                        activeQuestion?.Attachments?.length > 0 ||
                                        activeQuestion?.UploadingAttachments?.length > 0
                                    ">
                                    <ul id="attachments">
                                        <li *ngFor="let attachment of activeQuestion.Attachments">
                                            <div
                                                *ngIf="attachment?.Id.startsWith('https')"
                                                class="attachmentFileName">
                                                <span
                                                    style="
                                                        font-size: 14px;
                                                        display: flex;
                                                        flex-direction: column;
                                                    ">
                                                    <a
                                                        [href]="attachment?.Id"
                                                        target="_blank"
                                                        style="
                                                            cursor: pointer;
                                                            display: inline-block;
                                                        "
                                                        [title]="attachment?.Id">
                                                        <i
                                                            class="itgIcon icon-itglue"
                                                            style="
                                                                font-size: 14px;
                                                                color: #666;
                                                            "></i>
                                                        {{ attachment?.Name }}
                                                    </a>
                                                </span>
                                                <span>
                                                    Uploaded on
                                                    {{ attachment?.LastModified | date : 'short' }}
                                                    by
                                                    {{ attachment?.UploadedBy }}
                                                </span>
                                            </div>

                                            <div *ngIf="!attachment?.Id.startsWith('https')">
                                                <a
                                                    (click)="downloadAttachment(attachment)"
                                                    class="attachmentFileName"
                                                    style="
                                                        cursor: pointer;
                                                        max-width: 75%;
                                                        overflow: hidden;
                                                        text-overflow: ellipsis;
                                                        white-space: nowrap;
                                                    "
                                                    [attr.title]="attachment?.Name || null"
                                                    [class.disabled]="exportLoading">
                                                    {{ attachment?.Name }}
                                                </a>
                                                <span>
                                                    Uploaded on
                                                    {{ attachment?.LastModified | date : 'short' }}
                                                    by
                                                    {{ attachment?.UploadedBy }}
                                                </span>
                                            </div>
                                            <div>
                                                <itc-button
                                                    type="icon"
                                                    icon="fa-trash-can"
                                                    (onclick)="startRemove(attachment)"
                                                    tooltip="Delete"
                                                    ttPostion="top"
                                                    [disabled]="isReadOnly"></itc-button>
                                            </div>
                                        </li>
                                        <li
                                            class="file-upload-container"
                                            *ngFor="
                                                let upload of activeQuestion.UploadingAttachments
                                            "
                                            [class.upload-error]="upload.state === 'error'"
                                            [hidden]="upload.state === 'complete'">
                                            <div class="file-info">
                                                <span class="file-name">{{ upload.name }}</span>
                                                <span class="file-size">{{ upload.size }}</span>
                                                <span
                                                    class="progress"
                                                    *ngIf="upload.state !== 'complete'"
                                                    style="float: right">
                                                    {{ upload.progress }}%
                                                </span>
                                            </div>
                                            <itc-progress-bar
                                                *ngIf="upload.state !== 'complete'"
                                                [progress]="upload.progress"
                                                [error]="
                                                    upload.state === 'error'
                                                        ? 'There was a problem uploading your file.'
                                                        : null
                                                "></itc-progress-bar>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </section>

                        <section
                            *ngIf="options.showRelatedRequirements"
                            [class.open]="resourceCategories.relatedRequirementsOpen">
                            <header (click)="toggleResource('relatedRequirementsOpen')">
                                <fa-icon
                                    [icon]="
                                        resourceCategories.relatedRequirementsOpen
                                            ? 'chevron-up'
                                            : 'chevron-down'
                                    "
                                    [fixedWidth]="true"></fa-icon>
                                Related Requirements
                                <span class="resourceCount">
                                    {{ activeQuestion?.Control.Requirements?.length }}
                                </span>
                            </header>
                            <div [hidden]="!resourceCategories.relatedRequirementsOpen">
                                <cm-wizard-related-requirements
                                    [activeQuestion]="activeQuestion"
                                    [culture]="culture"
                                    (showReadOnly)="
                                        showReadOnlyModal($event)
                                    "></cm-wizard-related-requirements>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>

        <div id="listView" *ngIf="wizardView == 'list'">
            <div class="listSearchHeader">
                <itc-input
                    [debounce]="400"
                    [control]="listViewSearch"
                    placeholder="Filter..."></itc-input>
                <sm-select
                    [control]="standardSelectCtrl"
                    class="standardSelectBox"
                    (onChange)="chooseStandard($event)"
                    *ngIf="options.sortByStandards"
                    style="margin-left: 16px">
                    <option value="all">All Standards</option>
                    <option *ngFor="let std of questionStandards">{{ std }}</option>
                </sm-select>
                <p class="listQuestionCount">
                    {{
                        listQuestions.value.length < _allQuestions.length && !hideResponded
                            ? listQuestions.value.length + ' of '
                            : hideResponded
                            ? unansweredCount + ' of '
                            : ''
                    }}{{ _allQuestions.length }} questions
                </p>
                <itc-checkbox
                    name="hideRepsonses"
                    id="hideResponses"
                    [(ngModel)]="hideResponded"
                    (onchange)="setResponsesHidden($event)"
                    label="Hide questions with responses"></itc-checkbox>
            </div>
            <!-- listen... you hate it, I hate it, but we need a fake header when we have virtual scrolling on a table.-->
            <div class="fakeCDKHeader">
                <table
                    class="itc-table"
                    matSort
                    [matSortActive]="currentSort.active"
                    [matSortDirection]="currentSort.direction"
                    (matSortChange)="sortData($event)"
                    [matSortDisableClear]="true">
                    <thead>
                        <tr class="itc-header">
                            <th *ngIf="options.listViewColumns.includes('check')" data-col="check">
                                <itc-dropdown-button type="bulkCheckbox" [displayButton]="true" *ngIf="!isInArchivedAssessment">
                                    <button-content>
                                        <itc-checkbox
                                            [label]="false"
                                            [checked]="
                                                allResponsesChecked == true ||
                                                (selectedResponses?.length ==
                                                    listQuestions?.value?.length &&
                                                    listQuestions?.value?.length !== 0)
                                                    ? true
                                                    : selectedResponses.length
                                                    ? 'mixed'
                                                    : false
                                            "
                                            (onChanged)="checkAllResponses($event)"
                                            [tooltip]="
                                                selectedResponses.length
                                                    ? 'Uncheck All'
                                                    : 'Check All'
                                            "></itc-checkbox>
                                    </button-content>
                                    <dropdown-content>
                                        <ul>
                                            <li class="noHover">
                                                {{ selectedResponses?.length }} Selected
                                            </li>
                                            <li class="dropdownHeader" style="margin-top: 0">
                                                Set Responses
                                            </li>
                                            <li
                                                *ngFor="let a of answers"
                                                [class.disabled]="
                                                    !selectedResponses?.length || a.disableCondition
                                                "
                                                [hidden]="
                                                    a.disableSpecialCase === 'AddressedByControls'
                                                "
                                                [tooltip]="
                                                    a.disableCondition && a.disableTooltip
                                                        ? a.disableTooltip
                                                        : null
                                                "
                                                ttPosition="right">
                                                <a (click)="showBulkUpdateResponses(a.title)">
                                                    {{ a.title }}
                                                </a>
                                            </li>
                                            <li class="dropdownHeader">Comments</li>
                                            <li [class.disabled]="!selectedResponses?.length">
                                                <a (click)="showBulkUpdateComments($event)">
                                                    Change Comment
                                                </a>
                                            </li>
                                            <li
                                                class="dropdownHeader"
                                                *ngIf="options.allowAssignments">
                                                Assignments
                                            </li>
                                            <li
                                                [class.disabled]="!selectedResponses?.length"
                                                *ngIf="options.allowAssignments">
                                                <a (click)="showBulkUpdateAssignments($event)">
                                                    Assign Question
                                                </a>
                                            </li>
                                        </ul>
                                    </dropdown-content>
                                </itc-dropdown-button>
                            </th>
                            <th
                                *ngIf="options.listViewColumns.includes('control')"
                                mat-sort-header="control"
                                data-col="control">
                                Control
                            </th>
                            <th
                                *ngIf="options.listViewColumns.includes('requirement')"
                                mat-sort-header="requirement"
                                data-col="requirement">
                                Requirement
                            </th>
                            <th
                                *ngIf="options.listViewColumns.includes('response')"
                                mat-sort-header="response"
                                data-col="response">
                                Response
                            </th>
                            <th
                                *ngIf="options.listViewColumns.includes('comment')"
                                mat-sort-header="comment"
                                data-col="comment">
                                Comment
                            </th>
                            <th
                                *ngIf="options.listViewColumns.includes('files')"
                                mat-sort-header="files"
                                data-col="files">
                                Files
                            </th>
                            <th
                                *ngIf="options.listViewColumns.includes('assignments')"
                                mat-sort-header="assignments"
                                data-col="assigments">
                                Assigned to
                            </th>
                        </tr>
                    </thead>
                </table>
            </div>
            <cdk-virtual-scroll-viewport [style.height]="gridHeight + 'px'">
                <table
                    cdk-table
                    [dataSource]="listViewDataSource"
                    [fixedLayout]="true"
                    class="itc-table"
                    matSort
                    [matSortActive]="currentSort.active"
                    [matSortDirection]="currentSort.direction"
                    (matSortChange)="sortData($event)"
                    [matSortDisableClear]="true">
                    <ng-container cdkColumnDef="check">
                        <th cdk-header-cell *cdkHeaderCellDef></th>
                        <td cdk-cell *cdkCellDef="let response" (click)="checkResponse(response)">
                            <itc-checkbox
                                *ngIf="!isInArchivedAssessment"
                                [label]="false"
                                [checked]="response.Checked"></itc-checkbox>
                        </td>
                    </ng-container>

                    <ng-container cdkColumnDef="control">
                        <th cdk-header-cell *cdkHeaderCellDef></th>
                        <td cdk-cell *cdkCellDef="let response" (click)="checkResponse(response)">
                            <a class="clickableText" (click)="goToQuestion(response.Control.Id)">
                                {{ response.Control.ControlId }}
                                {{ response.Control.ShortDesc.LocalizedText[culture] }}
                            </a>
                        </td>
                    </ng-container>

                    <ng-container cdkColumnDef="requirement">
                        <th cdk-header-cell *cdkHeaderCellDef></th>
                        <td cdk-cell *cdkCellDef="let response" (click)="checkResponse(response)">
                            <a
                                class="clickableText"
                                (click)="goToQuestion(response.Requirement.Id)">
                                {{ response.StandardName }} -
                                {{ response.Requirement.RequirementId }} -
                                {{ response.Requirement.ShortDesc.LocalizedText[culture] }}
                            </a>
                        </td>
                    </ng-container>

                    <ng-container cdkColumnDef="response">
                        <th cdk-header-cell *cdkHeaderCellDef></th>
                        <td cdk-cell *cdkCellDef="let response">
                            <div class="responseDropdown">
                                <a
                                    *ngIf="options?.assessment !== 'vendorPortal'"
                                    class="responseButton"
                                    style="background-color: #fff; border-color: #333"
                                    [ngStyle]="{
                                        backgroundColor: response.Response
                                            ? responseColors[response.Response].colorMuted
                                            : null,
                                        borderColor: response.Response
                                            ? responseColors[response.Response].border
                                            : null
                                    }">
                                    {{ response.Response || 'Unanswered' }}
                                </a>
                                <a
                                    *ngIf="options?.assessment === 'vendorPortal'"
                                    class="responseButton"
                                    style="background-color: #fff; border-color: #333"
                                    [ngStyle]="{
                                        backgroundColor: response.ResponseTitle
                                            ? responseColors[response.ResponseTitle].colorMuted
                                            : null,
                                        borderColor: response.ResponseTitle
                                            ? responseColors[response.ResponseTitle].border
                                            : null
                                    }">
                                    {{ response.ResponseTitle || 'Unanswered' }}
                                </a>

                                <!-- <a class="responseButton" style="background-color: #fff; border-color: #333;" [ngStyle]="{ backgroundColor: (response.Response) ? responseColors[response.Response].colorMuted : null, borderColor: (response.Response) ? responseColors[response.Response].border : null }">{{ response.Response || 'Unanswered' }}</a> -->
                                <!-- <div id="dropdownContent" #responseDropdown *ngIf="response.Checked">
                                    <ul>
                                        <li *ngFor="let a of answers">
                                            <a>
                                            <i [style.backgroundColor]=a.colorMuted style="width: 16px; height: 16px; display: inline-block; border-radius: 50%;vertical-align:middle;margin-right: 8px; "></i> {{ a.title }}
                                            </a>
                                        </li>
                                    </ul>
                                </div> -->
                            </div>
                        </td>
                    </ng-container>

                    <ng-container cdkColumnDef="comment">
                        <th cdk-header-cell *cdkHeaderCellDef></th>
                        <td
                            cdk-cell
                            *cdkCellDef="let response"
                            (click)="checkResponse(response)"
                            vertical-align="middle">
                            <span class="truncated" [title]="response.Comment">
                                {{
                                    response.fuseJsHighlighted
                                        ? response.fuseJsHighlighted.Comment
                                        : response.Comment
                                }}
                            </span>
                            <itc-button
                                type="icon"
                                icon="fa-pencil"
                                tooltip="Update Comment"
                                [ttPosition]="
                                    options.assessment === 'vendorPortal' ? 'top-left' : 'top'
                                "
                                class="editPencil"
                                (onclick)="showUpdateComment($event, response)"></itc-button>
                        </td>
                    </ng-container>

                    <ng-container cdkColumnDef="files">
                        <th cdk-header-cell *cdkHeaderCellDef></th>
                        <td cdk-cell *cdkCellDef="let response" (click)="checkResponse(response)">
                            <span>
                                {{
                                    response.Attachments?.length
                                        ? response.Attachments?.length + ' Files'
                                        : ''
                                }}
                            </span>
                            <itc-button
                                type="icon"
                                icon="fa-paperclip-vertical"
                                (onclick)="showUpdateFiles($event, response)"
                                tooltip="Update Files"
                                ttPosition="top-left"
                                class="editFiles"></itc-button>
                        </td>
                    </ng-container>

                    <ng-container cdkColumnDef="assignments">
                        <th cdk-header-cell *cdkHeaderCellDef></th>
                        <td cdk-cell *cdkCellDef="let response" (click)="checkResponse(response)">
                            <span class="truncated" [title]="response.AssignedList">
                                {{ response.AssignedList }}
                            </span>
                            <itc-button
                                type="icon"
                                icon="fa-user-plus"
                                tooltip="Update Assignments"
                                ttPosition="top-left"
                                class="editAssignments"
                                (onclick)="showUpdateAssignments($event, response)"></itc-button>
                        </td>
                    </ng-container>

                    <ng-template
                        let-row
                        cdkRowDef
                        cdkVirtualFor
                        [cdkRowDefColumns]="options.listViewColumns"
                        [cdkVirtualForOf]="listQuestions">
                        <tr
                            cdk-row
                            [class.rowSelected]="row.Checked"
                            [hidden]="hideResponded && row.Response !== ''"></tr>
                    </ng-template>
                </table>
            </cdk-virtual-scroll-viewport>
        </div>

        <div class="wizardFooter">
            <div class="wizard-answers {{ options?.assessment }}" *ngIf="answers && activeQuestion">
                Select Response
                <ul>
                    <li *ngFor="let a of answers; let i = index">
                        <input
                            type="radio"
                            name="response"
                            [value]="a.dbval ? a.dbval : a.title"
                            [attr.id]="'resp' + a.value"
                            [(ngModel)]="activeQuestion.Response"
                            [disabled]="
                                isReadOnly ||
                                (a.disableCondition &&
                                    activeQuestion &&
                                    !activeQuestion[a.disableCondition]) ||
                                (a.disableSpecialCase == 'AddressedByControls' &&
                                    !(
                                        activeQuestion.RelatedControls.length > 0 &&
                                        activeQuestion.AllowABC
                                    ))
                                    ? true
                                    : null
                            " />
                        <label
                            [attr.for]="'resp' + a.value"
                            (click)="responseChanged()"
                            [tooltip]="
                                a.disableTooltip
                                    ? a.disableTooltip
                                    : a.disableSpecialCase == 'AddressedByControls' &&
                                      activeQuestion.RelatedControls.length == 0
                                    ? 'This response requires an associated Control'
                                    : !activeQuestion.AllowABC
                                    ? 'This response requires all associated controls be addressed in the Controls Assessment'
                                    : ''
                            "
                            [ttHidden]="
                                (!a.disableTooltip && !a.disableSpecialCase) ||
                                (a.disableCondition &&
                                    activeQuestion &&
                                    activeQuestion[a.disableCondition]) ||
                                (a.disableSpecialCase == 'AddressedByControls' &&
                                    activeQuestion.RelatedControls.length != 0 &&
                                    activeQuestion.AllowABC)
                            ">
                            <span class="itcRadio"></span>
                            <span style="flex: 1">{{ a.title }}</span>
                            <span
                                *ngIf="
                                    options?.type === 'control' &&
                                    activeQuestion.RBAResponse === a.title
                                ">
                                &nbsp;(baseline)
                            </span>
                            <i
                                *ngIf="
                                    !a.disableSpecialCase ||
                                    a.disableSpecialCase !== 'AddressedByControls'
                                "
                                class="answerSquare icon"
                                [style.backgroundColor]="a.color"></i>
                            <i
                                *ngIf="
                                    a.disableSpecialCase &&
                                    a.disableSpecialCase === 'AddressedByControls'
                                "
                                class="answerSquare icon"
                                [style.backgroundColor]="
                                    activeQuestion.RelatedControls.length > 0 &&
                                    activeQuestion.AllowABC
                                        ? CM_COLORS.COVERED_BRIGHT
                                        : CM_COLORS.NOTASSESSED_BRIGHT
                                "></i>
                        </label>
                        <span class="hotkeyLabel" *ngIf="showHotkeys">
                            Press &quot;{{ i + 1 }}&quot; to select
                        </span>
                    </li>
                </ul>
            </div>
            <div class="wizard-assignments" *ngIf="options.allowAssignments">
                <div>
                    <label for="assignToDropUp">Assigned To:</label>
                    <div [tooltip]="activeQuestion?.AssignedList || null">
                        <div
                            class="ui selection dropdown upward"
                            [class.disabled]="assigningInProgress ||isInArchivedAssessment "
                            id="assignToDropUp">
                            <i class="dropdown icon"></i>
                            <div class="text">
                                <!-- <i class="tag icon" style="color: #a0a0a0;"></i> -->
                                Select Users
                            </div>
                            <div class="menu transition hidden" style="display: block; width: auto">
                                <a
                                    class="item"
                                    style="white-space: nowrap"
                                    *ngFor="let user of siteUsers">
                                    <itc-checkbox
                                        [label]="user.DisplayName"
                                        [control]="user.Assigned"
                                        (onChanged)="updateWizardAssessments()"></itc-checkbox>
                                </a>
                                <a
                                    class="divider"
                                    style="display: block"
                                    *ngIf="siteGroups.length"></a>
                                <a
                                    class="item"
                                    style="white-space: nowrap"
                                    *ngFor="let group of siteGroups; let i = index"
                                    [class.csProGroup]="group.Type === 'CSPro'">
                                    <itc-checkbox
                                        [label]="group.Name"
                                        [control]="group.Assigned"
                                        (onChanged)="updateWizardAssessments()"></itc-checkbox>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <label for="wizardAssignBy">Assigned By:</label>
                    <input
                        type="text"
                        [value]="activeQuestion?.Assignments[0]?.AssignedByDisplayName || ''"
                        id="wizardAssignBy"
                        readonly />
                </div>
            </div>
            <div class="itc-divider"></div>
            <div class="wizard-buttons" [class.showHotkeys]="showHotkeys">
                <itc-toggle
                    *ngIf='!isInArchivedAssessment'
                    [(ngModel)]="showHotkeys"
                    (ngModelChange)="updatePrefs()"
                    label="Show Hotkeys"
                    style="display: flex; margin-right: 8px"></itc-toggle>
                <itc-checkbox
                    *ngIf='!isInArchivedAssessment'
                    [(ngModel)]="autoAdvance"
                    (ngModelChange)="updatePrefs()"
                    label="Automatically advance after response"
                    style="display: flex; margin-left: 8px"></itc-checkbox>
                <div class="actionButtons">
                    <div class="myWorkButton" *ngIf="isMyWork">
                        <itc-button
                            type="secondary"
                            class="wizardButton noBreak"
                            (onclick)="toggleView('mywork')">
                            Return to My Work
                        </itc-button>
                        <span class="hotkeyLabel" *ngIf="showHotkeys"></span>
                    </div>
                    <div class="importRBAButton" *ngIf="options.showImportButton">
                        <itc-button
                            *ngIf='!isInArchivedAssessment'
                            type="secondary"
                            class="wizardButton noBreak"
                            (onclick)="toggleView('import')">
                            Import Rapid Baseline Responses
                        </itc-button>
                        <span class="hotkeyLabel" *ngIf="showHotkeys"></span>
                    </div>
                    <div class="resultsButton" *ngIf="options.showResultsButton && !isMyWork">
                        <itc-button
                            type="secondary"
                            class="wizardButton noBreak"
                            (onclick)="toggleView('results')">
                            View Results
                        </itc-button>
                        <span class="hotkeyLabel" *ngIf="showHotkeys"></span>
                    </div>
                    <div class="prevButton">
                        <itc-button
                            type="secondary"
                            class="wizardButton"
                            (onclick)="prevPage()"
                            id="prevResponse"
                            [disabled]="activeQuestionIndex <= 1 || IsSaving"
                            #prevButton>
                            Back
                        </itc-button>
                        <span class="hotkeyLabel" *ngIf="showHotkeys">
                            Press &quot;&lt;&quot; to go back
                        </span>
                    </div>
                    <div class="nextButton">
                        <itc-button
                            type="primary"
                            class="wizardButton"
                            (onclick)="nextPage()"
                            id="nextResponse"
                            [loading]="IsSaving"
                            #nextButton>
                            Next
                        </itc-button>
                        <span class="hotkeyLabel" *ngIf="showHotkeys">
                            Press &quot;&gt;&quot; to go fwd
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modals -->
<sm-modal title="IT Glue Documents" class="lg" #itglueModal>
    <!-- doing this redirect here instead of another modal because I was getting a bug where on occasion the second modal wouldn't show
    so instead I'm putting the redirect content in the same modal which I think makes sense anyway -->
    <modal-content>
        <sds-loader [complete]="itglueModalLoadingComplete">
            <div *ngIf="itgModalContent == 'redirect'">
                <p>
                    You are about to be redirected to the IT Glue portal. Once you log in, you will
                    return to this page.
                </p>
                <p>
                    Before proceeding, you should save your changes to this worksheet. Unsaved
                    changes will be lost.
                </p>
                <p>Would you like to save your worksheet changes now?</p>
            </div>
            <div *ngIf="itgModalContent == 'interstitial'">
                <p (click)="itgModalNoWarning()">
                    You are about to be redirected to the IT Glue portal. Once you log in, you will
                    return to this page.
                </p>
            </div>
            <div *ngIf="itgModalContent == 'orgs'">
                <div style="display: flex; align-items: center">
                    <span style="margin-right: 15px">Organization:</span>
                    <sm-select
                        [control]="itglueOrgCtrl"
                        class="search selection"
                        style="margin-right: 20px; width: 350px"
                        name="itglueOrgSelect">
                        <option *ngFor="let org of itglueOrgs" value="{{ org.id }}">
                            {{ org.attributes.name }}
                        </option>
                    </sm-select>
                </div>
                <div
                    style="
                        display: flex;
                        align-items: center;
                        margin-top: 20px;
                        margin-bottom: 20px;
                    ">
                    <span style="white-space: nowrap; margin-right: 15px">Search Term:</span>
                    <div class="ui action input rightButton">
                        <input
                            type="text"
                            class="form-control input-sm response-font-size"
                            placeholder="Enter Search Term"
                            spellcheck="false"
                            [(ngModel)]="itglueSearchTerm"
                            autofocus
                            name="itglueOrgTextInput"
                            (keyup)="processSearchKey($event)" />
                        <itc-button
                            type="primary"
                            [loading]="isSearchingITGlue"
                            (onclick)="searchITGlueDocuments()">
                            Search
                        </itc-button>
                    </div>
                </div>
                <span *ngIf="itglueFiles.length" style="font-size: smaller">
                    Select one or more attachments to attach to the selected topic.
                </span>
                <table class="itc-table" sortable-table style="width: 100%">
                    <thead>
                        <tr>
                            <th></th>
                            <th>File Name</th>
                            <th>URL</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let file of pagedItGlueFiles; let i = index">
                            <td class="collapsing">
                                <itc-checkbox
                                    class="bulk-responder-checkbox"
                                    [(ngModel)]="itglueFileSelection[i + pageNumber * 10]"
                                    (onChanged)="updateITGcount()"></itc-checkbox>
                            </td>
                            <td class="collapsing">{{ file.name }}</td>
                            <td class="collapsing">
                                <a href="{{ file.url }}" target="_blank">{{ file.url }}</a>
                            </td>
                        </tr>
                        <tr *ngIf="itglueFiles.length == 0">
                            <td colspan="3">
                                <div class="ui info icon message">
                                    <i class="info circle icon"></i>
                                    <div class="content">
                                        <div class="header">
                                            Enter search criteria to search IT Glue
                                        </div>
                                        <p>No files were found matching the given criteria.</p>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <itc-paginator
                    [data]="itglueFiles"
                    (onPage)="pagedItGlueFiles = $event"></itc-paginator>
            </div>
        </sds-loader>
    </modal-content>
    <modal-actions>
        <ng-container *ngIf="itgModalContent == 'redirect'">
            <itc-button type="secondary" class="close cancel">Cancel</itc-button>
            <itc-button type="danger" (onclick)="redirectWarningAction(false)">
                Do Not Save
            </itc-button>
            <itc-button type="primary" class="approve" (onclick)="redirectWarningAction(true)">
                Save
            </itc-button>
        </ng-container>

        <ng-container *ngIf="itgModalContent == 'interstitial'">
            <div class="itgWarnCheckbox">
                <itc-checkbox label="Do not warn again" [control]="itgWarnCheckbox"></itc-checkbox>
            </div>
            <itc-button type="secondary" class="close cancel">Cancel</itc-button>
            <itc-button type="primary" class="approve" (onclick)="redirectWarningAction(false)">
                Ok
            </itc-button>
        </ng-container>

        <ng-container *ngIf="itgModalContent == 'orgs'">
            <itc-button
                type="secondary"
                icon="fa-xmark"
                class="cancel"
                (onclick)="resetITGlueDocuments()">
                Cancel
            </itc-button>
            <itc-button
                type="primary"
                icon="fa-plus"
                [class]="'approve'"
                [disabled]="!itglueFileSelectionCount"
                [title]="
                    !itglueFileSelectionCount ? 'Please select at least one document to link' : ''
                "
                (onclick)="addItglueFiles()">
                Add {{ itglueFileSelectionCount }} files
            </itc-button>
        </ng-container>
    </modal-actions>
</sm-modal>

<sm-modal title="Remove File from Response" class="xs" #deleteModal>
    <modal-content>
        <p style="word-break: break-all">
            Remove
            <strong>{{ selectedDeleteAttachment?.Name }}</strong>
            from this response?
        </p>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" class="close" (onclick)="deleteModal.hide()">
            Cancel
        </itc-button>
        <itc-button type="danger" icon="fa-trash-can" (onclick)="removeAttachmentFromResponse()">
            Remove
        </itc-button>
    </modal-actions>
</sm-modal>

<sm-modal class="xs" overrideOverflow="true" #deleteModalModified>
    <modal-title>Delete File from Evidence of Compliance</modal-title>
    <modal-content>
        <p>
            Are you sure you want to delete
            <strong>{{ evidenceNameToDelete }}</strong>
            from this response?
        </p>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" class="close" (onclick)="deleteModalModified.hide()">
            Cancel
        </itc-button>
        <itc-button type="danger" (onclick)="deleteEvidence(evidenceToDelete)">Delete</itc-button>
    </modal-actions>
</sm-modal>

<sm-modal
    title="Add Evidence of Compliance"
    class="sm"
    #importEvidenceCompliance
    overrideOverflow="activeQuestion?.Evidence.length==0 ? true">
    <modal-content>
        <table
            sortable-table
            class="itc-table sortable-table-titles-scroll"
            sortColumn="name"
            sortDirection="asc"
            (sorted)="sortEvidence($event)"
            style="margin-top: 0">
            <thead>
                <tr>
                    <th>
                        <itc-checkbox
                            *ngIf="allEvidenceList.length > 0"
                            (onchange)="allEvidencesSelected($event)"
                            [(ngModel)]="allSelected"></itc-checkbox>
                    </th>
                    <th sortable-column="name">IT Complete Module</th>
                    <th sortable-column="evidence">Evidence Type</th>
                </tr>
            </thead>
            <tbody *ngIf="true">
                <tr *ngFor="let evidence of allEvidenceList">
                    <td>
                        <itc-checkbox
                            (onchange)="evidenceSelected($event, evidence)"
                            [checked]="evidence.selected == 'true' ? true : false"></itc-checkbox>
                    </td>

                    <td>
                        <div
                            class="horizonal-div"
                            style="display: flex; align-items: center; height: 24px">
                            <div class="column" style="height: 24px; width: 24px">
                                <img [src]="evidence.ProductIconUrl" width="24" height="24" />
                            </div>
                            <div class="column" style="padding-left: 5px">
                                {{ evidence.ProductName }}
                            </div>
                        </div>
                    </td>

                    <td>
                        {{ evidence.Name }}
                    </td>
                </tr>
            </tbody>
        </table>
        <div>
            <empty-state
                *ngIf="allEvidenceList.length == 0"
                [style]="{ paddingTop: '0px', textAlign: 'center' }"
                text="There is no Evidence of Compliance. Import new evidence in Data Collection > IT Complete Modules page."
                icon="results"
                title="No Evidence Found"></empty-state>
        </div>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" class="Cancel" (onclick)="importEvidenceCompliance.hide()">
            Cancel
        </itc-button>
        <itc-button
            type="primary"
            [disabled]="allSelected != 'mixed' && !allSelected"
            (onclick)="addEvidenceOfCompliance()">
            Add
        </itc-button>
    </modal-actions>
</sm-modal>

<sm-modal title="Existing Attachments" class="lg" #attachmentsModal>
    <modal-content>
        <sds-loader [complete]="attachmentModalLoadingComplete">
            <p>Select one or more attachments to attach to the selected topic.</p>
            <div style="margin: 10px 0px">
                <div class="item searchInput" style="display: inline-block; width: 50%">
                    <sds-data-search-filter
                        placeholder="Filter By File Name.."
                        [data]="files"
                        (filteredData)="filterFiles($event)"
                        [fields]="['filename']"></sds-data-search-filter>
                </div>
            </div>
            <table
                class="itc-table scrollable sortable"
                sortable-table
                sortColumn="filename"
                sortDirection="asc"
                (sorted)="sortFiles($event)">
                <thead>
                    <tr>
                        <th class="collapsing"></th>
                        <th sortable-column="filename">File Name</th>
                        <th sortable-column="lastmodified">Upload Date</th>
                        <th sortable-column="uploadedby">Uploaded By</th>
                    </tr>
                </thead>
                <tr
                    *ngFor="let file of filteredFiles"
                    (click)="toggleAttachmentFile(file)"
                    id="f{{ file.fileid }}"
                    class="existingFileRow">
                    <td>
                        <itc-checkbox [checked]="isSelected(file)"></itc-checkbox>
                    </td>
                    <td>
                        {{ file.filename }}
                    </td>
                    <td>
                        {{ file.lastmodified | date : 'medium' }}
                    </td>
                    <td>
                        {{ file.uploadedby }}
                    </td>
                </tr>
            </table>
        </sds-loader>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" class="cancel">Cancel</itc-button>
        <itc-button
            type="primary"
            icon="fa-plus"
            class="approve"
            [disabled]="!selectedFiles?.length"
            [title]="!selectedFiles?.length ? 'Please select at least one document to attach' : ''"
            (onclick)="addSelectedAttachments()">
            Add {{ selectedFiles?.length }} files
        </itc-button>
    </modal-actions>
</sm-modal>

<sm-modal title="Bulk Actions" class="lg" #bulkActionsModal>
    <modal-content>
        Update Comments:
        <textarea
            *ngIf="activeQuestion"
            class="wizardCommentTextarea modalWizardCommentTextarea"
            [(ngModel)]="activeQuestion.Comment"
            placeholder="Enter your comments"
            [disabled]="isReadOnly"
            autoResize></textarea>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" class="cancel">Cancel</itc-button>
        <itc-button type="primary" class="approve">Save</itc-button>
    </modal-actions>
</sm-modal>

<sm-modal title="Wizard" class="xs" #wizardConfirmModal>
    <modal-content>
        <p *ngIf="!remainingQuestions">
            {{
                options?.assessmentCompleteText
                    ? options?.assessmentCompleteText
                    : 'You have responded to all questions. Do you wish to View Results?'
            }}
        </p>
        <p *ngIf="remainingQuestions">
            You have {{ remainingQuestions }} questions without responses. Do you wish to jump to
            the next question without a response?
        </p>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" class="cancel">No</itc-button>
        <itc-button type="primary" class="approve" (onclick)="processWizardEndConfirmation()">
            Yes
        </itc-button>
    </modal-actions>
</sm-modal>

<sm-modal
    title="Comments"
    class="itc-modal xs wizardCommentModal"
    [closable]="true"
    #updateCommentsModal>
    <modal-content>
        <textarea
            [formControl]="commentCtrl"
            placeholder="Add Comment"
            class="wizardCommentTextarea modalWizardCommentTextarea"
            [readonly]="isReadOnly"
            autoResize
            >{{ listViewActiveQuestion?.Comment }}</textarea
        >
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" size="large" class="cancel">Cancel</itc-button>
        <itc-button
            type="primary"
            size="large"
            class="approve"
            (onclick)="isBulkUpdate ? bulkUpdateComments() : updateComment()"
            [disabled]="isReadOnly">
            Apply
        </itc-button>
    </modal-actions>
</sm-modal>

<sm-modal
    title="Assignments"
    class="itc-modal sm wizardAssignmentModal"
    [closable]="true"
    #updateAssignmentsModal>
    <modal-content>
        <itc-banner type="info" title="Assignments in progress" *ngIf="assigningInProgress">
            Please wait for previous assignments to complete before assigning more assessment survey
            questions.
        </itc-banner>
        <ng-container *ngIf="isBulkUpdate">
            Update {{ selectedResponses.length }} assignments:
        </ng-container>
        <div class="assignmentPeople">
            <ul>
                <li *ngFor="let user of siteUsers">
                    <itc-checkbox
                        [control]="user.Assigned"
                        [label]="user.DisplayName"
                        [disabled]="isReadOnly"></itc-checkbox>
                </li>
                <li>
                    <hr />
                </li>
                <li *ngFor="let group of siteGroups">
                    <itc-checkbox [control]="group.Assigned" [label]="group.Name"></itc-checkbox>
                </li>
            </ul>
        </div>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" size="large" class="cancel">Cancel</itc-button>
        <itc-button
            type="primary"
            size="large"
            class="approve"
            (onclick)="isBulkUpdate ? bulkUpdateAssignments() : updateAssignments()"
            [disabled]="isReadOnly || assigningInProgress">
            Apply
        </itc-button>
    </modal-actions>
</sm-modal>

<sm-modal
    title="Confirm Bulk Change"
    class="itc-modal xs wizardResponseConfirmationModal"
    [closable]="true"
    #updateResponsesModal>
    <modal-content>
        Set {{ selectedResponses.length }} selected responses to: {{ bulkUpdateResponse }}
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" size="large" class="cancel">Cancel</itc-button>
        <itc-button
            type="primary"
            size="large"
            class="approve"
            (onclick)="isBulkUpdate ? bulkUpdateResponses() : updateResponse()"
            [disabled]="isReadOnly">
            Apply
        </itc-button>
    </modal-actions>
</sm-modal>

<sm-modal
    title="Files"
    class="itc-modal xs wizardFileAddModal"
    [closable]="true"
    [overrideOverflow]="
        !listViewActiveQuestion?.Attachments?.length &&
        !listViewActiveQuestion?.UploadingAttachments?.length
    "
    #updateFilesModal>
    <modal-content>
        <div style="position: relative; height: 100%; border: 0" class="wizardResourcesContainer">
            <dropdown-button
                buttonType="tertiary"
                buttonLabel="Add Files"
                buttonIcon="fa-plus"
                panelTop="true"
                [disabled]="isReadOnly"
                [menuOptions]="dropdownMenuOptions">
                <dropdown-header>
                    <itc-file-uploader
                        type="dropdown"
                        buttonText="Upload Local File"
                        returnType="upload"
                        [allowMultiple]="true"
                        [extraInfo]="lvAqId"
                        (uploadFile)="uploadAttachment($event)"
                        (uploadStart)="addPendingUploadFiles($event)"
                        style="width: 100%; height: 100%"></itc-file-uploader>
                </dropdown-header>
            </dropdown-button>

            <div
                class="wizardResources"
                class="fileListContainerModal"
                *ngIf="
                    listViewActiveQuestion?.Attachments?.length > 0 ||
                    listViewActiveQuestion?.UploadingAttachments?.length > 0
                "
                style="margin-top: 12px">
                <ul id="attachments">
                    <li *ngFor="let attachment of listViewActiveQuestion.Attachments">
                        <div *ngIf="attachment?.Id.startsWith('https')" class="attachmentFileName">
                            <span style="font-size: 14px; display: flex; flex-direction: column">
                                <a
                                    [href]="attachment?.Id"
                                    target="_blank"
                                    style="cursor: pointer; display: inline-block"
                                    [title]="attachment?.Id">
                                    <i
                                        class="itgIcon icon-itglue"
                                        style="font-size: 14px; color: #666"></i>
                                    {{ attachment?.Name }}
                                </a>
                            </span>
                            <span>
                                Uploaded on
                                {{ attachment?.LastModified | date : 'short' }} by
                                {{ attachment?.UploadedBy }}
                            </span>
                        </div>

                        <div *ngIf="!attachment?.Id.startsWith('https')">
                            <a
                                (click)="downloadAttachment(attachment)"
                                class="attachmentFileName"
                                style="
                                    cursor: pointer;
                                    display: inline-block;
                                    max-width: 75%;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                "
                                [attr.title]="attachment?.Name || null"
                                [class.disabled]="exportLoading">
                                {{ attachment?.Name }}
                            </a>
                            <br />
                            <span>
                                Uploaded on
                                {{ attachment?.LastModified | date : 'short' }} by
                                {{ attachment?.UploadedBy }}
                            </span>
                        </div>
                        <div>
                            <itc-button
                                type="icon"
                                icon="fa-trash-can"
                                (onclick)="startRemove(attachment)"
                                tooltip="Delete"
                                ttPostion="top"
                                [disabled]="isReadOnly"></itc-button>
                        </div>
                    </li>
                    <li
                        class="file-upload-container"
                        *ngFor="let upload of listViewActiveQuestion?.UploadingAttachments"
                        [class.upload-error]="upload.state === 'error'"
                        [hidden]="upload.state === 'complete'">
                        <div class="file-info">
                            <span class="file-name">{{ upload.name }}</span>
                            <span class="file-size">{{ upload.size }}</span>
                            <span
                                class="progress"
                                *ngIf="upload.state !== 'complete'"
                                style="float: right">
                                {{ upload.progress }}%
                            </span>
                        </div>
                        <itc-progress-bar
                            *ngIf="upload.state !== 'complete'"
                            [progress]="upload.progress"
                            [error]="
                                upload.state === 'error'
                                    ? 'There was a problem uploading your file.'
                                    : null
                            "></itc-progress-bar>
                    </li>
                </ul>
            </div>
        </div>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" size="large" class="cancel">Close</itc-button>
        <!-- <itc-button type="primary" size="large"  class="approve">Apply</itc-button> -->
    </modal-actions>
</sm-modal>

<!-- If we ever edit the text in this modal, make sure to edit it in cm-read-only-wizard.component as well. -->
<sm-modal title="Access External Content" class="xs" #externalContent>
    <modal-content>
        <p>
            The reference URL you have selected will direct you to access content that is published
            on an external website. Please confirm your access to this content
        </p>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" class="close" (onclick)="externalContent.hide()">
            Cancel
        </itc-button>
        <itc-button type="primary" (onclick)="navigateTo()">OK</itc-button>
    </modal-actions>
</sm-modal>

<cm-read-only-wizard></cm-read-only-wizard>

<cm-eoc-report-modal #eocReportModal></cm-eoc-report-modal>
