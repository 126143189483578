import { Component, OnInit } from '@angular/core';
import { PrintService } from 'app/sites/shared/print.service';
import { AllVendorsInfo } from './allvendors.model';

@Component({
    selector: 'sds-printable-all-vendors',
    templateUrl: './printable-all-vendors.component.html',
    styleUrls: ['./printable-all-vendors.component.scss'],
})
export class PrintableAllVendorsComponent implements OnInit {
    allVendorsInfo: AllVendorsInfo;
    constructor(private printService: PrintService) {}

    ngOnInit() {
        this.allVendorsInfo = this.printService.AllVendorsData;

        Promise.resolve(this.allVendorsInfo).then(() => {
            setTimeout(() => {
                this.printService.onDataReady();
            }, 500);
        });
    }
}
