<div class="wfi-container">
    <div class="smaller-container">
        <!-- #region Log in with KaseyaOne-->
        <h3>Single Sign-On</h3>
        <form>
            <itc-toggle
                [control]="enableKaseyaOneCtrl"
                label="Enable Log in with KaseyaOne"
                labelPosition="after"
                style="margin-bottom: 0"></itc-toggle>
            <div
                class="integration-desc integration-indent supporting-text"
                style="text-align: left">
                This option will allow your users to enable Log In with KaseyaOne for themselves.
                <a href="{{ rft_url }}/portal/rft-portal/log-in-kaseya-one.htm" target="_blank">
                    Learn More
                </a>
            </div>
        </form>
    </div>
    <!-- #endregion -->
    <hr />
    <sds-loader [complete]="autoUserCreationLoaded && kaseyaOneRequiredLoaded">
        <!-- #region Require K1 SSO-->

        <kaseya-one-required [kaseyaOneEnabled]="enableKaseyaOneCtrl.value"
                             (onLoaded)="kaseyaOneRequiredLoaded = $event" />
        <!-- #endregion -->
        <!-- #region Automatic User Creation-->

        <div class="smaller-container" style="min-height: 200px">
            <h3>Automatic User Provisioning</h3>
            <form>
                <div class="supporting-text" style="text-align: left">
                    Once enabled, your users in KaseyaOne who are granted access to any RapidFire
                    Tools module will automatically have a user created.
                    <a href="{{ rft_url }}/portal/rft-portal/automatic-user-creation.htm"
                       target="_blank">
                        Learn More
                    </a>
                </div>
                <itc-toggle [control]="enableKaseyaOneAutomaticUserCreationCtrl"
                            label="Enable Automatic User Provisioning"
                            labelPosition="after"
                            [disabled]="!enableKaseyaOneCtrl.value"></itc-toggle>
                <div class="integration-field">
                    <div class="integration-label">
                        Choose Default Global Access Level
                        <sup>*</sup>
                    </div>
                    <div class="integration-desc">
                        <itc-radio name="global_access"
                                   [control]="defaultAccessLevelCtrl"
                                   value="site_restricted"
                                   (onChanged)="setGlobalAccessLevel()"
                                   [disabled]="!enableKaseyaOneAutomaticUserCreationCtrl.value"
                                   style="margin-right: 24px">
                            Site Restricted
                        </itc-radio>
                        <itc-radio name="global_access"
                                   [control]="defaultAccessLevelCtrl"
                                   value="admin"
                                   (onChanged)="setGlobalAccessLevel()"
                                   [disabled]="!enableKaseyaOneAutomaticUserCreationCtrl.value">
                            Admin
                        </itc-radio>
                    </div>
                </div>
                <div *ngIf="defaultAccessLevelCtrl.value == 'admin'"
                     class="integration-desc integration-indent">
                    <itc-banner type="info">
                        Selecting Admin will create users with access to all Sites and Global
                        Settings
                    </itc-banner>
                </div>
                <div class="integration-field"
                     [class.disabled]="defaultAccessLevelCtrl.value == 'admin'">
                    <div class="integration-label">
                        Choose Default Site(s)
                        <sup>*</sup>
                    </div>
                    <div class="integration-desc">
                        <sm-select class="fluid multiple"
                                   [options]="groupSelectOptions"
                                   multiple
                                   (modelChange)="onDefaultSiteChange($event)"
                                   [disabled]="!enableKaseyaOneAutomaticUserCreationCtrl.value"
                                   style="display: block; width: 100%"
                                   #defaultSites>
                            <option *ngFor="let site of sites" [value]="site.Id">
                                {{ site.Name }}
                            </option>
                        </sm-select>
                    </div>
                </div>
                <div class="integration-field"
                     [class.disabled]="defaultAccessLevelCtrl.value == 'admin'">
                    <div class="integration-label">
                        Choose Default Site Role
                        <sup>*</sup>
                    </div>
                    <div class="integration-desc ui labeled input" style="width: 100%">
                        <sm-select class="fluid"
                                   [control]="defaultSiteRoles"
                                   [disabled]="!enableKaseyaOneAutomaticUserCreationCtrl.value"
                                   (modelChange)="setDefaultRole($event)"
                                   [options]="{ direction: 'upward' }"
                                   style="display: block; width: 100%">
                            <option *ngFor="let role of defaultAccessRoles" [value]="role.value">
                                {{ role.label }}
                            </option>
                        </sm-select>
                    </div>
                </div>
            </form>
        </div>
        <!-- #endregion -->
        <automatic-user-deprovisioning [kaseyaOneEnabled]="enableKaseyaOneCtrl.value" (onLoaded)="kaseyaOneRequiredLoaded = $event"></automatic-user-deprovisioning>
        <!-- #region Access Group-->
        <div class="smaller-container"
             style="min-height: 200px; width: 1152px !important; margin-top: 24px">
            <div class="ui grid" style="width: 100%; padding-bottom: 0px; margin-bottom: 0px">
                <div class="twelve wide column" style="padding-bottom: 0px; margin-bottom: 0px">
                    <h3>Access Group</h3>
                </div>
                <div class="four wide column" style="padding-bottom: 0px; margin-bottom: 0px">
                    <label class="supporting-text" style="pointer-events: none; cursor: default">
                        Last Synced {{ k1AccessGroupSync | accountDateTime }}
                    </label>
                    <a (click)="syncAccessGroups()" [routerLink]="[]" class="sync-text">
                        <i *ngIf="!k1AccessGroupSyncing"
                           class="sync icon"
                           style="margin-left: 10px"></i>
                        <i *ngIf="k1AccessGroupSyncing"
                           class="sync loading icon"
                           style="margin-left: 10px"></i>
                        Sync
                    </a>
                </div>
            </div>
            <form>
                <span style="
                        font-family: Roboto;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 20px;
                        letter-spacing: 0px;
                        text-align: left;
                        display: block;
                    ">
                    Select the role you would like to assign each KaseyaOne Access Group. Changes to
                    access group settings will change these settings for users.
                    <a href="{{ rft_url }}/portal/rft-portal/rbac.htm" target="_blank">
                        Learn More
                    </a>
                </span>
                <div>
                    <itc-toggle [control]="enableKaseyaOneAccessGroupCtrl"
                                label="Enable Access Group"
                                labelPosition="after"
                                [disabled]="!enableKaseyaOneCtrl.value"></itc-toggle>
                    <itc-button label="Add Access Group"
                                type="primary"
                                icon="fa-plus"
                                class="right floated"
                                style="padding-top: 10px"
                                [disabled]="
                            !enableKaseyaOneAccessGroupCtrl.value || !hasAvailableAccessGroup
                        "
                                (onclick)="addAccessGroup()"></itc-button>
                </div>
                <br />
                <div>
                    <table class="itc-table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>KaseyaOne Access Group</th>
                                <th>Global Access Level</th>
                                <th>Site(s)</th>
                                <th>Site Role</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let accessGroupRow of accessGroupRows; let i = index">
                                <td>{{ accessGroupRow.Order }}</td>
                                <td>
                                    <p-dropdown [disabled]="!enableKaseyaOneAccessGroupCtrl.value"
                                                (onChange)="setAccessGroupInRow($event, accessGroupRow)"
                                                [(ngModel)]="accessGroupRow.AccessGroupId"
                                                [ngModelOptions]="{ standalone: true }"
                                                [options]="accessGroupRow.AccessGroupOptions"
                                                optionValue="Id"
                                                optionLabel="Name"
                                                [placeholder]="accessGroupRow.PlaceHolder"
                                                [style]="{ width: '242px' }"></p-dropdown>
                                </td>
                                <td>
                                    <p-dropdown [disabled]="!enableKaseyaOneAccessGroupCtrl.value"
                                                (onChange)="setGlobalRoleInRow($event, accessGroupRow)"
                                                [(ngModel)]="accessGroupRow.GlobalRoleId"
                                                [ngModelOptions]="{ standalone: true }"
                                                [options]="rftGlobalRoles"
                                                optionValue="Id"
                                                optionLabel="Name"
                                                [style]="{ width: '242px' }"></p-dropdown>
                                </td>
                                <td>
                                    <p-multiSelect [disabled]="
                                            !enableKaseyaOneAccessGroupCtrl.value ||
                                            accessGroupRow.GlobalRoleId == 1
                                        "
                                                   (onChange)="setSitesInRow($event, accessGroupRow, i)"
                                                   [(ngModel)]="accessGroupRow.SiteIds"
                                                   [options]="sites"
                                                   optionValue="Id"
                                                   optionLabel="Name"
                                                   [ngModelOptions]="{ standalone: true }"
                                                   [style]="{ width: '226px' }">
                                        <ng-template let-value pTemplate="selectedItems">
                                            <ngContainer *ngIf="accessGroupRow.SiteIds?.length <= 1">
                                                <itc-tag type="chip"
                                                         [closable]="true"
                                                         color="default"
                                                         *ngFor="let option of accessGroupRow.SiteIds"
                                                         (closed)="
                                                        closeSiteSelection(accessGroupRow, i)
                                                    ">
                                                    {{ getSite(option).Name }}
                                                </itc-tag>
                                            </ngContainer>
                                            <itc-tag type="chip"
                                                     [closable]="true"
                                                     *ngIf="accessGroupRow.SiteIds?.length > 1"
                                                     (closed)="closeSiteSelection(accessGroupRow, i)">
                                                {{ accessGroupRow.SiteIds.length }}
                                                Selected
                                            </itc-tag>
                                        </ng-template>
                                        <ng-template pTemplate="item" let-value>
                                            <itc-checkbox [stopBubbling]="true"
                                                          [checked]="
                                                    isChecked(value.Id, accessGroupRow.SiteIds)
                                                "></itc-checkbox>
                                            {{ value.Name }}
                                        </ng-template>
                                    </p-multiSelect>
                                </td>
                                <td>
                                    <p-dropdown [disabled]="
                                            !enableKaseyaOneAccessGroupCtrl.value ||
                                            accessGroupRow.GlobalRoleId == 1
                                        "
                                                (onChange)="setSiteRoleInRow($event, accessGroupRow)"
                                                [options]="accessGroupRow.AvailableSiteRoles"
                                                [(ngModel)]="accessGroupRow.SiteRole"
                                                [ngModelOptions]="{ standalone: true }"
                                                optionValue="value"
                                                optionLabel="label"
                                                [style]="{ width: '242px' }"></p-dropdown>
                                </td>
                                <td>
                                    <itc-button type="icon"
                                                icon="fa-trash-can"
                                                tooltip="Delete"
                                                (onclick)="startDelete(accessGroupRow)"></itc-button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <empty-state icon="inbox"
                                 *ngIf="!accessGroupRows?.length"
                                 title="No Access Groups"></empty-state>
                </div>
            </form>
        </div>
    </sds-loader>
    <!-- #endregion -->
</div>
