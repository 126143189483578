<header class="pageHeader">
    <h1>{{ title }}</h1>
    <help-button
        title="Issue Overrides"
        text="Disable detected issues and prevent them from appearing in your assessment reports, or modify issue risk scores."
        url="/networkdetective/help/networkdetective/issue-overrides.htm"></help-button>
</header>

<sds-loader [complete]="loadingComplete">
    <ng-content></ng-content>
    <div class="useGlobal" *ngIf="siteId != -1">
        <itc-toggle
            [stopBubbling]="true"
            [(ngModel)]="useGlobal"
            (ngModelChange)="changeUseGlobal()"></itc-toggle>
        <span>Use Global Issue Overrides</span>
    </div>
    <div class="filterContainer">
        <input
            pInputText
            type="text"
            (input)="issuesTable.filterGlobal($event.target.value, 'contains')"
            placeholder="Search..." />
        <div>
            <strong>{{ displayedCount }} of {{ issues.length }}</strong>
            Results
        </div>
    </div>
    <p-table
        [value]="issues"
        dataKey="id"
        (sortFunction)="customSort($event)"
        [customSort]="true"
        sortField="Category"
        [sortOrder]="1"
        tableStyleClass="itc-table"
        [globalFilterFields]="['Category', 'Name']"
        [tableStyle]="{ width: '100%' }"
        (onFilter)="filterContent($event)"
        #issuesTable>
        <ng-template pTemplate="header">
            <tr>
                <th class="bulk-actions">
                    <itc-bulk-actions
                        [menuOptions]="bulkActions"
                        [selectedCount]="selectedCount"
                        [totalCount]="issues.length"
                        (checkboxClicked)="checkAllAssessments($event)"
                        [disabled]="useGlobal"></itc-bulk-actions>
                </th>
                <th pResizableColumn pSortableColumn="Category" style="width: 200px">
                    Category
                    <p-sortIcon field="Category"></p-sortIcon>
                </th>
                <th pResizableColumn pSortableColumn="Name">
                    Name
                    <p-sortIcon field="Name"></p-sortIcon>
                </th>
                <th
                    pResizableColumn
                    pSortableColumn="Severity"
                    class="compactCell"
                    style="width: 110px">
                    Severity
                    <p-sortIcon field="Severity"></p-sortIcon>
                </th>
                <th
                    pResizableColumn
                    pSortableColumn="Probability"
                    class="compactCell"
                    style="width: 110px">
                    Probability
                    <p-sortIcon field="Probability"></p-sortIcon>
                </th>
                <th
                    pResizableColumn
                    pSortableColumn="Score"
                    class="compactCell"
                    style="width: 85px">
                    Score
                    <p-sortIcon field="Score"></p-sortIcon>
                </th>
                <th pResizableColumn pSortableColumn="Enabled">
                    Enabled
                    <p-sortIcon field="Enabled"></p-sortIcon>
                </th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="sorticon" let-value>
            <i
                [ngClass]="{
                    'pi-sort-alt': value === 0,
                    'pi-sort-amount-up-alt': value === 1,
                    'pi-sort-amount-down': value === -1
                }"></i>
        </ng-template>
        <ng-template pTemplate="body" let-issue let-editing="editing">
            <tr [class.selected]="issue.Checked">
                <td (click)="rowClicked(issue, $event)">
                    <itc-checkbox
                        [(ngModel)]="issue.Checked"
                        [stopBubbling]="true"
                        (onchange)="toggleSelected(issue, $event)"
                        [disabled]="useGlobal"></itc-checkbox>
                </td>
                <td
                    class="issueName"
                    (click)="rowClicked(issue)"
                    [ngStyle]="{
                        color: useGlobal ? 'var(--text-secondary)' : 'var(--text-primary)'
                    }">
                    {{ issue.Category }}
                </td>
                <td
                    (click)="rowClicked(issue)"
                    [ngStyle]="{
                        color: useGlobal ? 'var(--text-secondary)' : 'var(--text-primary)'
                    }">
                    {{ issue.Name }}
                </td>
                <td
                    [pEditableColumn]="issue.Severity"
                    pEditableColumnField="Severity"
                    class="compactCell"
                    [ngStyle]="{
                        color:
                            useGlobal || disableSevProd[issue.Category]
                                ? 'var(--text-secondary)'
                                : 'var(--text-primary)'
                    }"
                    tooltip="Severity is not currently used in any reports for this module."
                    ttPosition="bottom"
                    [ttHidden]="!disableSevProd[issue.Category]">
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <p-dropdown
                                [options]="severityOptions"
                                [(ngModel)]="issue.Severity"
                                (ngModelChange)="saveOverride(issue)"
                                [autofocus]="true"
                                [style]="{ width: '100%' }"
                                [disabled]="useGlobal || disableSevProd[issue.Category]"
                                #severity></p-dropdown>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ issue.Severity }}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td
                    [pEditableColumn]="issue.Probability"
                    pEditableColumnField="Probability"
                    class="compactCell"
                    [ngStyle]="{
                        color:
                            useGlobal || disableSevProd[issue.Category]
                                ? 'var(--text-secondary)'
                                : 'var(--text-primary)'
                    }"
                    tooltip="Probability is not currently used in any reports for this module."
                    ttPosition="bottom"
                    [ttHidden]="!disableSevProd[issue.Category]">
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <p-dropdown
                                [options]="probabilityOptions"
                                [(ngModel)]="issue.Probability"
                                [autofocus]="true"
                                [style]="{ width: '100%' }"
                                (ngModelChange)="saveOverride(issue)"
                                [disabled]="useGlobal || disableSevProd[issue.Category]"
                                #probability></p-dropdown>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ issue.Probability }}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td
                    [pEditableColumn]="issue.Score"
                    pEditableColumnField="Score"
                    class="inputCell compactCell"
                    [ngStyle]="{
                        color: useGlobal ? 'var(--text-secondary)' : 'var(--text-primary)'
                    }">
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input
                                type="number"
                                pInputText
                                min="0"
                                max="100"
                                [(ngModel)]="issue.Score"
                                [disabled]="useGlobal"
                                (ngModelChange)="saveOverride(issue)" />
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ issue.Score }}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td>
                    <itc-toggle
                        [(ngModel)]="issue.Enabled"
                        [stopBubbling]="true"
                        (ngModelChange)="saveOverride(issue)"
                        [disabled]="useGlobal"></itc-toggle>
                </td>
                <td>
                    <itc-button
                        style="display: inline"
                        *ngIf="!useGlobal && overriddenIssues[issue.Id]"
                        tooltip="Reset"
                        type="icon"
                        icon="fa-arrow-left-rotate"
                        (onclick)="startReset(issue)"></itc-button>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <sm-modal title="Disable Issues" class="xs" #disableModal>
        <modal-content>Are you sure you want to disable {{ selectedCount }} issues?</modal-content>
        <modal-actions>
            <itc-button type="secondary" class="cancel" [disabled]="pendingSave">Cancel</itc-button>
            <itc-button type="primary" (onclick)="toggleMultiple(false)" [loading]="pendingSave">
                Disable
            </itc-button>
        </modal-actions>
    </sm-modal>
    <sm-modal title="Enable Issues" class="xs" #enableModal>
        <modal-content>Are you sure you want to enable {{ selectedCount }} issues?</modal-content>
        <modal-actions>
            <itc-button type="secondary" class="cancel" [disabled]="pendingSave">Cancel</itc-button>
            <itc-button type="primary" (onclick)="toggleMultiple(true)" [loading]="pendingSave">
                Enable
            </itc-button>
        </modal-actions>
    </sm-modal>
    <sm-modal title="Reset Issues" class="xs" #resetModal>
        <modal-content>
            Are you sure you want to reset Severity, Probability, and Score values to default for
            {{ selectedCount }} issues?
        </modal-content>
        <modal-actions>
            <itc-button type="secondary" class="cancel" [disabled]="pendingSave">Cancel</itc-button>
            <itc-button type="danger" (onclick)="resetMultiple()" [loading]="pendingSave">
                Reset
            </itc-button>
        </modal-actions>
    </sm-modal>
    <sm-modal title="Reset Issue" class="xs" #resetSingleModal>
        <modal-content>
            Are you sure you want to reset Severity, Probability, and Score values to default for
            <strong>{{ selectedIssue?.Name }}</strong>
            ?
        </modal-content>
        <modal-actions>
            <itc-button type="secondary" class="cancel" [disabled]="pendingSave">Cancel</itc-button>
            <itc-button type="danger" (onclick)="resetSingle()" [loading]="pendingSave">
                Reset
            </itc-button>
        </modal-actions>
    </sm-modal>
    <sm-modal title="Use Global Issue Overrides" class="xs" #globalModal>
        <modal-content>
            Are you sure you want to use global Issue Overrides? This will
            <strong>overwrite all saved fields.</strong>
        </modal-content>
        <modal-actions>
            <itc-button
                type="secondary"
                class="cancel"
                (onclick)="useGlobal = false"
                [disabled]="pendingSave">
                Cancel
            </itc-button>
            <itc-button type="danger" (onclick)="resetMultiple(true)" [loading]="pendingSave">
                Confirm
            </itc-button>
        </modal-actions>
    </sm-modal>
</sds-loader>
