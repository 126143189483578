import { Component, Input } from '@angular/core';
import { SemanticLegacyModule } from 'app/semantic-legacy/semantic-legacy.module';

@Component({
    selector: 'sds-loader, itc-loader',
    standalone: true,
    imports: [SemanticLegacyModule],
    template: `
        <sm-loader class="inverted" [complete]="complete"></sm-loader>
        <span
            [hidden]="!complete && !hideVisibility"
            [style.visibility]="hideVisibility && !complete ? 'hidden' : ''">
            <ng-content></ng-content>
        </span>
    `,
    styles: [':host ::ng-deep .ui.dimmer { position: inherit !important; }'],
})
export class ItcLoaderComponent {
    @Input() complete: boolean;
    /* added this var to use css hide visibility instead of display none
  otherwise wijmo charts won't work */
    @Input() hideVisibility: boolean;

    constructor() {}
}
