import { Injectable } from '@angular/core';

import { lastValueFrom, Subject } from 'rxjs';

import { environment } from 'environments/environment';
import { AuthHttp } from 'app/core/auth';
import { InformInvitation } from './informinvitation.model';
import { InformMetadata } from './informmeta.model';
import { InformRequest } from './informrequest.model';
import { Survey } from './survey.model';

@Injectable()
export class InformOldService {
    private apiUrl: string;

    // observable sources
    private mergedTableData = new Subject<any[]>();

    // observable streams
    mergedTable$ = this.mergedTableData.asObservable();

    constructor(private http: AuthHttp) {
        this.apiUrl = environment.apiUrl;
    }

    AnnounceMergedTable(data: any[]) {
        this.mergedTableData.next(data);
    }

    getInformByName(req: InformRequest): Promise<Survey> {
        const url: string = this.apiUrl + 'Inform/Get';
        return this.http
            .post(url, req)
            .toPromise()
            .then((res) => res as Survey);
    }

    saveInformByName(req: InformRequest): Promise<any> {
        const url: string = this.apiUrl + 'Inform/Set';
        return this.http
            .post(url, req, { observe: 'response' })
            .toPromise()
            .then((res) => res.status == 200);
    }

    sendInformInvitation(req: InformInvitation): Promise<any> {
        const url: string = this.apiUrl + 'Inform/SendInvitation';
        return this.http
            .post(url, req, { observe: 'response' })
            .toPromise()
            .then((res) => res.status == 200);
    }

    getInformsMetadata(siteId: number, assessmentName: string): Promise<InformMetadata[]> {
        const url: string = this.apiUrl + `Informs/${siteId}/${assessmentName}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as InformMetadata[]);
    }

    downloadFormZip(siteId: number, assessmentName: string): Promise<any> {
        const url: string =
            this.apiUrl + `Sites/${siteId}/Forms/DownloadZip?assessmentName=${assessmentName}`;

        return this.http
            .get(url, { observe: 'response' })
            .toPromise()
            .then((res) => {
                return res.status == 200 ? res.body : null;
            });
    }

    downloadFormDocx(
        siteId: number,
        assessmentName: string,
        formName: string,
        populate: boolean
    ): Promise<any> {
        const url: string =
            this.apiUrl +
            `Inform/Docx/${siteId}/${assessmentName}/${formName}?populate=${populate}`;
        return this.http
            .get(url, { observe: 'response' })
            .toPromise()
            .then((res) => {
                return res.status == 200 ? res.body : null;
            });
    }

    releaseInform(siteName: string, formName: string): Promise<any> {
        const url: string =
            this.apiUrl + `Inform/Release?siteName=${siteName}&formName=${formName}`;

        return this.http
            .get(url, { observe: 'response' })
            .toPromise()
            .then((res) => res);
    }

    renewInformLock(siteName: string, formName: string): Promise<any> {
        const url: string = this.apiUrl + `Inform/Renew?siteName=${siteName}&formName=${formName}`;

        return this.http
            .get(url, { observe: 'response' })
            .toPromise()
            .then((res) => res);
    }

    getArchivedInformByName(req: InformRequest) {
        const url: string = this.apiUrl + `GetArchivedInformByName`;
        return lastValueFrom(this.http.post(url, req))
            .then((res) => res as Survey)
            .catch((err) => {});
    }

    downloadArchivedTechnicalReviewDocx(
        siteId: number,
        assessmentGuid: string,
        formName: string,
        populate: boolean
    ): Promise<any> {
        const url: string =
            this.apiUrl +
            `DownloadArchivedTechnicalReviewDocx/${siteId}/${assessmentGuid}/${formName}?populate=${populate}`;
        return lastValueFrom(this.http.get(url))
            .then((res) => {
                return res;
            })
            .catch((err) => {});
    }
}
