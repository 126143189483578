import {
    Component,
    OnInit,
    Input,
    EventEmitter,
    Output,
    ViewChild,
    ElementRef,
} from '@angular/core';

import { Router } from '@angular/router';
import { Organization } from '../organization.model';
import { OrganizationsService } from '../organizations.service';
import { NotificationService } from '../../shared/itc/notification/notification.service';
import { SiteDashboardService } from 'app/sites/shared/site-dashboard.service';
const CURRENT_ASSESSMENT: string = '__current';
declare var ago: any;
declare var jQuery: any;

@Component({
    selector: 'sds-organization-card',
    templateUrl: './organization-card.component.html',
    styleUrls: ['./organization-card.component.scss'],
})
export class OrganizationCardComponent implements OnInit {
    @Input() organization: any;
    @Input() canModify: boolean;
    @Input() cardViewMode: string;

    @ViewChild('renameOrganizationModal', { static: true }) renameOrganizationModal: any;
    @ViewChild('deleteOrganizationModal', { static: true }) deleteOrganizationModal: any;
    @ViewChild('dropdownMenu') dropdownMenu: ElementRef;

    @Output() onDelete: EventEmitter<string> = new EventEmitter<string>();
    renameModalStep: number;
    newOrganizationName: string;
    oldOrganizationName: string;
    isNewOrganizationNameValid: boolean;
    actionOpen = false;

    constructor(
        private router: Router,
        private notificationService: NotificationService,
        private organizationsService: OrganizationsService,
        public siteDashboardService: SiteDashboardService
    ) {}

    ngOnInit() {}

    getButtonHref(organization: Organization): string {
        let href: string = `/organization/${organization.Name}/`;

        return href;
    }

    gotoOrganization(organization: Organization) {
        ///////////Note to devs, please PLEASE do not use navigatebyurl when including the site name as sites with & aren't properly sanitized. Only use navigate.
        if (organization.Id !== -1) {
            // fake org is -1 don't go anywhere on click in that case
            this.router.navigate(['organizations', organization.Name]);
        }
    }

    showRenameOrganizationModal(e) {
        e.preventDefault();
        e.stopPropagation();
        this.renameModalStep = 1;
        this.newOrganizationName = this.organization.Name;
        this.oldOrganizationName = this.organization.Name;
        this.renameOrganizationModal.show({ closable: false });
    }

    deleteOrganization(e) {
        e.preventDefault();
        e.stopPropagation();

        this.deleteOrganizationModal.show();
    }

    deleteModalOk() {
        this.organizationsService.deleteOrganizationObs(this.organization.Id).then((res) => {
            this.deleteOrganizationModal.hide();
            let deleteMessage = `Organization ${this.organization.Name} deleted.`;
            if (this.organization.Sites.length > 0) {
                deleteMessage += ' The sites are available under "All Sites".';
            }
            this.notificationService.toast.success('Success', deleteMessage);
            this.onDelete.emit(this.organization.Name);
        });
    }

    renameModalAdvance() {
        if (this.renameModalStep == 1) {
            this.renameModalStep = 2;
        } else if (this.renameModalStep == 2) {
            this.renameOrganization();
            this.renameOrganizationModal.hide();
        }
    }

    renameModalGoback() {
        if (this.renameModalStep == 2) {
            this.renameModalStep = 1;
        } else if (this.renameModalStep == 1) {
            this.renameOrganizationModal.hide();
        }
    }

    renameOrganization() {
        this.oldOrganizationName = this.organization.Name;
        this.organization.Name = this.newOrganizationName;

        this.organizationsService.updateOrganizationObs(this.organization).catch((err) => {
            this.organization.Name = this.oldOrganizationName;
            this.notificationService.toast.error('Unable to rename organization', err.error);
        });
    }

    // setOrganizationName(name: string) {
    //     if (!this.formService.validate('renameOrganizationForm')) {
    //         this.isNewOrganizationNameValid = false;
    //         return;
    //     }

    //     if (this.organization.Name === name) {
    //         this.isNewOrganizationNameValid = false;
    //         return;

    //     }

    //     this.newOrganizationName = name;
    //     this.isNewOrganizationNameValid = true;
    // }

    toggleActionOpen(evt) {
        evt.stopPropagation();
        this.actionOpen = !this.actionOpen;
        setTimeout(() => {
            let rect = this.dropdownMenu.nativeElement.getBoundingClientRect();
            if (
                rect.x + rect.width >= window.innerWidth &&
                !this.dropdownMenu.nativeElement.classList.contains('offscreen')
            ) {
                this.dropdownMenu.nativeElement.classList.add('offscreen');
                // } else {
                //     this.dropdownMenu.nativeElement.classList.remove('offscreen');
            }
        }, 1);
    }
}
