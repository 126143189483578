<itc-button
    label="Restart Assessment"
    type="danger"
    icon="fa-arrows-rotate"
    (onclick)="restartAssessment()"
    [hidden]="loadingLicense"
    [disabled]="restarted"
    #restartButton>
    Restart Assessment
</itc-button>

<sm-modal class="sm" [overrideOverflow]="true" #selectRestartTypeModal>
    <modal-title>Select Restart Type</modal-title>
    <modal-content>
        Select which part of the Assessment you would like to restart.
        <br />
        <sm-select class="form-control input-sm" [(model)]="restartType">
            <option value="Entire Assessment">Entire Assessment</option>
            <option value="Internal Scans">Internal Scans</option>
            <option value="External Vulnerability Scan">External Vulnerability Scan</option>
            <option
                value="{{ moduleValidationWorksheet }}"
                *ngIf="
                    moduleValidationWorksheet != 'Regenerate Personal Data Validation Worksheet'
                ">
                {{ moduleValidationWorksheet }}
            </option>
        </sm-select>
    </modal-content>
    <modal-actions>
        <itc-button
            label="Cancel"
            type="secondary"
            (onclick)="closeSelectRestartTypeModalAction(false)"></itc-button>
        <itc-button
            label="OK"
            type="primary"
            (onclick)="closeSelectRestartTypeModalAction(true)"></itc-button>
    </modal-actions>
</sm-modal>

<sm-modal class="sm" #confirmRestartModal>
    <modal-title>Confirm</modal-title>
    <modal-content>
        <div [innerHTML]="warningMessage"></div>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" (onclick)="closeConfirmModalAction(false)">Cancel</itc-button>
        <itc-button type="primary" (onclick)="closeConfirmModalAction(true)">OK</itc-button>
    </modal-actions>
</sm-modal>
