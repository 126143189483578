<header *ngIf="!isOnlySME">
    <p>Select Assessment</p>
    <form name="assessmentSelect">
        <sm-select
            class="assessmentSelect"
            [control]="selectAssessmentCtrl"
            [placeholder]="
                loadingAssessments
                    ? ''
                    : assessments.length
                    ? 'Select Assessment'
                    : 'No Assessments'
            "
            [disabled]="!assessments.length"
            [loading]="loadingAssessments"
            style="top: 1.5rem"
            #assessmentSelect>
            <option selected="selected" value="__current" *ngIf="currentAssessment">
                Current Assessment
            </option>
            <ng-container *ngFor="let assmt of assessments">
                <option
                    *ngIf="!(currentAssessment && currentAssessment.Name == assmt)"
                    [value]="assmt">
                    {{ assmt }}
                </option>
            </ng-container>
        </sm-select>
        <!--
        <a class="ui labeled icon button" (click)="downloadZip()">
            <i class="download button icon"></i>
            .ZIP
        </a>
          -->
    </form>
</header>

<router-outlet></router-outlet>
