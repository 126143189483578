import { CommonModule } from '@angular/common';
import {
    Component,
    Input,
    forwardRef,
    EventEmitter,
    Output,
    OnInit,
    ViewChild,
    ElementRef,
    NgModule,
} from '@angular/core';
import {
    ControlValueAccessor,
    UntypedFormControl,
    NG_VALUE_ACCESSOR,
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
    standalone: true,
    imports: [CommonModule, FormsModule, ReactiveFormsModule],
    selector: 'itc-checkbox',
    template: `
        <label
            class="kaseya-ui-form-label kaseya-ui-checkbox {{ className }}"
            [ngClass]="{
                'checkbox--disabled': disabled,
                'checkbox--value-entered': value,
                'checkbox--nested': nested,
                'checkbox--value-mixed': isMixed
            }">
            <div class="checkbox__input-container qa-input-container">
                <span
                    class="checkbox__field-label"
                    *ngIf="label !== false && label && labelPosition === 'before'"
                    style="margin-right: 8px;">
                    <span>{{ label }}</span>
                </span>
                <span
                    class="checkbox__field-label"
                    *ngIf="label !== false && label === '' && labelPosition === 'before'"
                    style="margin-right: 8px;">
                    <span><ng-content></ng-content></span>
                </span>
                <input
                    type="checkbox"
                    [attr.name]="name ? name : ''"
                    [attr.value]="value ? value : ''"
                    [attr.checked]="isChecked ? isChecked : null"
                    (change)="cbChanged($event)"
                    (blur)="onBlur($event)"
                    [attr.disabled]="disabled ? disabled : null"
                    #checkbox />
                <span
                    class="checkbox__field-label"
                    *ngIf="label !== false && label && labelPosition === 'after'"
                    style="margin-left: 8px;">
                    <span>{{ label }}</span>
                </span>
                <span
                    class="checkbox__field-label"
                    *ngIf="label !== false && label === '' && labelPosition === 'after'"
                    style="margin-left: 8px;">
                    <span><ng-content></ng-content></span>
                </span>
            </div>
        </label>
    `,
    styleUrls: ['./itc-checkbox.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ItcCheckboxComponent),
            multi: true,
        },
    ],
})
export class ItcCheckboxComponent implements OnInit, ControlValueAccessor {
    @Input() control: UntypedFormControl;
    @Input() label: string | boolean = '';

    /**
     * Position for label. \
     * String: before | after
     */
    @Input() labelPosition = 'after';

    /**
     * Setting to disable checkbox \
     * Boolean: true | false
     */
    @Input() disabled = false;
    @Input() name: string;
    @Input() className: string;

    /**
     * Class list to add to container
     */
    @Input() class: string;

    /**
     * Whether to nest the checkbox under a parent item \
     * Boolean : true | false
     */
    @Input() nested = false;
    @Input() value;
    @Input() set checked(val: boolean | string) {
        // update checkbox element because just changed checked doesn't work properly.
        if (typeof val !== 'string') {
            this.isMixed = false;
            this.checkbox.nativeElement.checked = val;
            this.isChecked = val;
        } else {
            this.isMixed = true;
            this.writeValue(val);
        }
    }
    @Input() stopBubbling = false;

    @Output() onChanged = new EventEmitter();
    @Output() onchange = new EventEmitter();

    @ViewChild('checkbox', { static: true }) checkbox: ElementRef;

    isChecked = false;
    isMixed = false;
    controlSub: Subscription;

    ngOnInit() {
        if (this.control) {
            // handle form controls disable status
            this.control.registerOnDisabledChange((ds) => {
                this.setDisabledState(ds);
            });
            this.controlSub = this.control.valueChanges.subscribe((val) => {
                this.writeValue(val);
            });
            this.setDisabledState(this.control.disabled);
            this.writeValue(this.control.value);
        }
    }

    ngOnDestroy() {
        if (this.controlSub) {
            this.controlSub.unsubscribe();
        }
    }

    /* ngModal Access boilerplate */
    onChange = (_) => {};
    onBlur = (_) => {};
    writeValue(obj: any): void {
        // ngModel or FormControl value changed
        if (obj == 'mixed') {
            // Sometimes the checkbox checked gets stuck, even if it doesn't have the attribute set
            // so force it using the element instead of relying on isChecked
            this.checkbox.nativeElement.checked = false;
            this.isChecked = false;
            this.isMixed = true;
        } else {
            // Sometimes the checkbox checked gets stuck, even if it doesn't have the attribute set
            // so force it using the element instead of relying on isChecked
            this.checkbox.nativeElement.checked = obj;
            this.isChecked = obj;
            this.isMixed = false;
        }
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onBlur = fn;
    }
    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    cbChanged(cb) {
        if (this.stopBubbling) {
            console.log('stop propogation');
            cb.stopPropagation();
        }
        this.isChecked = cb && cb.target && cb.target.checked;

        if (this.isMixed) {
            // by default go to false after clicking on a mixed checkbox
            this.checkbox.nativeElement.checked = false;
            this.isChecked = false;
            this.isMixed = false;
        }

        if (this.control) {
            this.control.setValue(this.isChecked);
            this.control.markAsDirty();
            this.control.markAsTouched();
        } else {
            this.onChange(this.isChecked);
        }
        this.onChanged.emit(this.isChecked);
        this.onchange.emit(this.isChecked);
    }
}
