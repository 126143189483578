import { Component, OnInit, OnDestroy, isDevMode } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Location } from '@angular/common';
import { K1organizationService } from './shared/k1organization.service';
import { environment } from 'environments/environment';

import { AuthService, Roles } from 'app/core/auth';
import { ErrorService } from 'app/core/error/error.service';
import { MessageService } from 'app/core/message.service';
import { NavService, ALL_SITES } from 'app/core/nav/nav.service';
import { Setting } from './shared/setting.model';
import { SettingService } from './shared/setting.service';

import { Site } from 'app/sites/shared/site.model';
import { SiteService } from 'app/sites/shared/site.service';

import { SETTINGS, PING_SETTINGS, FETCH_SETTINGS, SDSP_SETTINGS } from './shared/constants';
import { SidebarMenuItem } from 'app/shared/sidebar/menu/sidebar-menu-item.model';

@Component({
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.css'],
})
export class SettingsComponent implements OnInit, OnDestroy {
    constructor(
        private authService: AuthService,
        private errorService: ErrorService,
        private messageService: MessageService,
        private navService: NavService,
        private router: Router,
        private settingService: SettingService,
        private location: Location,
        private siteService: SiteService,
        private k1service: K1organizationService
    ) {}

    settings: any = {};
    dumb() {}
    sidebarMenu: SidebarMenuItem[];

    subs: any[] = [];
    hasK1Credential: boolean = false;
    ngOnInit() {
        this.navService.setSite(ALL_SITES);
        this.navService.setHeaderText('');
        this.k1service.checkK1Id().then((hasK1Credential) => {
            this.hasK1Credential = hasK1Credential;
            this.buildSidebarMenu();
        });
        this.subs.push(
            this.messageService.on(PING_SETTINGS).subscribe(() => this.onPingSettings())
        );
        this.subs.push(
            this.messageService.on(FETCH_SETTINGS).subscribe(() => this.fetchSettings())
        );
        this.siteService.setCurrentSite(null);

        this.buildSidebarMenu();
    }

    ngOnDestroy() {
        this.messageService.clear(SETTINGS);
        for (let sub of this.subs) {
            sub.unsubscribe();
        }
    }

    buildSidebarMenu() {
        // this.sidebarMenu = [];
        let user = this.authService.getIdentity();
        let roleId = parseInt(user.roleId, 10);
        let hasAdminAccess = [Roles.Admin].indexOf(roleId) > -1;
        let hasMasterAccess = [Roles.Master].indexOf(roleId) > -1;
        let products = this.authService.getProducts();
        console.log('products', products);

        let menuItems: SidebarMenuItem[] = [
            {
                href: 'general',
                icon: 'gear',
                label: 'General',
            },
            {
                href: 'branding',
                icon: 'paintbrush-fine',
                label: 'Branding',
            },
            {
                href: 'connections',
                icon: 'icon-integrate',
                label: 'Connections',
            },
            {
                href: 'admin-alerts',
                icon: 'bell',
                label: 'Admin Alerts',
            },
            {
                href: 'users',
                icon: 'user',
                label: 'Users',
            },
        ] as any[];

        if ((hasAdminAccess || hasMasterAccess) && products.CM2) {
            menuItems.push({
                label: 'Compliance Manager GRC',
                url: 'compliance-manager-grc',
                icon: 'icon-audit-guru',
                children: [
                    {
                        label: 'Controls Library',
                        href: 'compliance-manager-grc/controls-library',
                        icon: 'icon-ballot-check',
                    },
                    {
                        label: 'Control Templates',
                        href: 'compliance-manager-grc/control-templates',
                        icon: 'icon-manage-plans',
                    },
                    {
                        label: 'Standards Library',
                        href: 'compliance-manager-grc/standards-library',
                        icon: 'icon-ballot-check',
                    },
                ],
            });
        }

        if ((hasAdminAccess || hasMasterAccess) && products.NdProWeb) {
            let ndProSettings: SidebarMenuItem = {
                label: 'Network Detective Pro',
                url: 'network-detective-pro',
                icon: 'icon-ndpro',
                children: [
                    {
                        label: 'Report Settings',
                        href: 'network-detective-pro/report-preferences',
                    },
                    { label: 'Issue Overrides', href: 'network-detective-pro/issue-overrides' },
                    { label: 'User Report Sets', href: 'network-detective-pro/user-report-sets' },
                    { label: 'Augment Reporting', href: 'network-detective-pro/augment-reporting' },
                    { label: 'InForm Templates', href: 'network-detective-pro/inform-templates' },
                ],
            };
            if (isDevMode()) {
                ndProSettings.children.push({
                    label: 'Audit Items',
                    href: 'network-detective-pro/audit-items',
                    badgeText: 'New',
                });
                ndProSettings.children.push({
                    label: 'Audit Categories',
                    href: 'network-detective-pro/audit-categories',
                    badgeText: 'New',
                });
                ndProSettings.children.push({
                    label: 'Audit Templates',
                    href: 'network-detective-pro/audit-templates',
                    badgeText: 'New',
                });
            }
            menuItems.push(ndProSettings);
        }

        /* KVS */
        if (products.KVS) {
            menuItems.push({
                label: 'VulScan',
                url: 'vulnerability-scanner',
                icon: 'icon-vulnerability-scanner',
                children: [
                    {
                        label: 'Scan Profiles',
                        href: 'vulnerability-scanner/scan-profiles',
                        icon: 'icon-manage-plans',
                    },
                    {
                        label: 'Exclusion Rules',
                        href: 'vulnerability-scanner/exclusion-rules',
                        icon: 'icon-ballot-check',
                    },
                ],
            });
        }

        /* end KVS */

        //if (this.authService.) if account has Cyber Hawk

        menuItems.push({
            label: 'Service Plans',
            url: 'service-plans',
            icon: 'square-list',
            children: [
                {
                    label: 'Manage Plans',
                    href: 'service-plans/manage-plans',
                    icon: 'icon-manage-plans',
                },
                {
                    label: 'Manage Catalogs',
                    href: 'service-plans/manage-catalogs',
                    icon: 'icon-manage-catalogs',
                },
            ],
        });

        menuItems.push({
            href: 'email-groups',
            icon: 'mail-bulk',
            label: 'Email Groups',
        });

        // endif

        if (hasAdminAccess || hasMasterAccess) {
            menuItems.push({
                href: 'data-collectors',
                icon: 'hard-drive',
                label: 'Data Collectors',
            });
        }

        if (hasAdminAccess || hasMasterAccess) {
            let obj = {
                icon: 'icon-itc-mark',
                url: 'itcomplete',
                label: 'IT Complete',
                children: [
                    {
                        label: 'Settings',
                        href: 'itcomplete/it-settings',
                    },
                ],
            };
            if (this.hasK1Credential)
                obj.children.push({
                    label: 'Organization Mapping',
                    href: 'itcomplete/organization-mapping',
                });

            menuItems.push(obj);
        }

        //if (user.accountId === 'PERF100405') {
        //    menuItems.push({
        //        href: 'k1',
        //        icon: 'icon-settings',
        //        label: 'Kaseya One'
        //    });
        //}
        if (hasAdminAccess || hasMasterAccess) {
            menuItems.push({
                href: 'license-usage',
                icon: 'file-certificate',
                label: 'License Usage',
            });
        }

        if ((products.NdPro || products.CM2 || products.KVS) && hasMasterAccess) {
            menuItems.push({
                href: 'api-keys',
                icon: 'key',
                label: 'API Keys',
            });
        }

        this.sidebarMenu = menuItems;
    }

    // SETTINGS

    fetchSettings() {
        this.settingService
            .getSettings()
            .then((settings) => this.onSettings(settings))
            .catch((err) => {
                this.errorService.error(err);
            });
    }

    onSettings(settings: Setting[]) {
        for (let setting of settings) {
            this.settings[setting.Name] = setting.Value;
        }

        localStorage.setItem(SDSP_SETTINGS, JSON.stringify(this.settings));
        this.messageService.broadcast(SETTINGS, this.settings);

        if (!environment.production) console.log('settings', this.settings);
    }

    onPingSettings() {
        // Always re-fetch from localStorage to account for changes by user
        let sets = JSON.parse(localStorage.getItem(SDSP_SETTINGS));
        this.settings = sets;
        this.messageService.broadcast(SETTINGS, this.settings);
    }
}
