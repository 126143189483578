<sds-loader [complete]="loadingComplete">
    <sds-breadcrumb-trail [crumbs]="breadcrumbs"></sds-breadcrumb-trail>

    <header class="pageHeader">
        <h1>
            <span class="todoHeader">To Do</span>
        </h1>
        <itc-tag type="subtle" color="orange" *ngIf="todos">
            {{ filteredTodos?.length }}
        </itc-tag>

        <div class="item searchInput">
            <sds-alert-search-filter
                [alerts]="todos"
                (onFiltered)="filtered1 = $event"></sds-alert-search-filter>
        </div>

        <div class="item">
            <sds-todo-filters
                [todos]="filtered1"
                (onFiltered)="filteredTodos = $event; sortTodos()"></sds-todo-filters>
        </div>

        <div class="item">
            <itc-checkbox label="Hide Related" [control]="hideRelatedCtrl"></itc-checkbox>
        </div>
    </header>

    <div *ngIf="filteredTodos?.length == 0">
        <div class="ui info icon message">
            <i class="info circle icon"></i>
            <div class="content">
                <div class="header">No To-Do Items Found</div>
                <p>No to-do items were found matching the given criteria.</p>
            </div>
        </div>
    </div>

    <div *ngIf="filteredTodos?.length > 0">
        <sds-todo-table
            [todos]="pagedTodos"
            [forSite]="true"
            (sortBy)="processSort($event)"
            [invertDefault]="invertDefault"></sds-todo-table>
        <itc-paginator
            [pageNumber]="pageNumber"
            [pageSize]="pageSize"
            [data]="filteredTodos"
            (onPageWithInfo)="savePaginationState($event)"></itc-paginator>
    </div>
</sds-loader>
