<sds-loader [complete]="loadingComplete">
    <sds-breadcrumb-trail [crumbs]="breadcrumbs"></sds-breadcrumb-trail>

    <header class="pageHeader">
        <h1>Report Settings</h1>
        <help-button
            title="Report Settings"
            text="Customize your report text settings, logos, color themes, and other details."
            url="/cm-grc/help/cm-grc-docs/cm-grc-report-preferences.htm"></help-button>
    </header>

    <div class="smallerContainer">
        <itc-tabs [containerStyle]="false" style="width: 100%">
            <itc-tab tabTitle="Text">
                <form>
                    <div class="html ui attached segment">
                        <div class="ui top attached label">Text</div>
                        <div class="content textPageContent">
                            <table
                                style="width: 100%; margin-top: 0; border: 0; margin-bottom: 0"
                                class="basic borderless table">
                                <tr>
                                    <td width="175">
                                        <label for="rp-for">Report Prepared For:</label>
                                    </td>
                                    <td>
                                        <div class="fluid field">
                                            <input type="text" name="rp-for" [(ngModel)]="tFor" />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td><label for="rp-by">Report Prepared By:</label></td>
                                    <td>
                                        <div class="fluid field">
                                            <input type="text" name="rp-by" [(ngModel)]="tBy" />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td><label for="rp-footer">Footer:</label></td>
                                    <td>
                                        <div class="fluid field">
                                            <input
                                                type="text"
                                                name="rp-footer"
                                                [(ngModel)]="tFooter" />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label for="rp-disclaimer">Cover Page Disclaimer:</label>
                                    </td>
                                    <td>
                                        <div class="fluid field">
                                            <textarea
                                                name="rp-disclaimer"
                                                rows="8"
                                                style="width: 100%; resize: vertical"
                                                [(ngModel)]="tDisclaimer"></textarea>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td><label for="rp-language">Target Language(s):</label></td>
                                    <td>
                                        <div
                                            class="field"
                                            [class.error]="tLanguageError"
                                            style="/*width: 196px;*/">
                                            <sm-select
                                                placeholder=""
                                                *ngIf="!isEnglishOnly"
                                                class="fluid search multiple"
                                                (onChange)="setLanguages($event)"
                                                #tLanguageSelect>
                                                <option
                                                    *ngFor="let selectedLang of selectedLanguages"
                                                    selected="selected"
                                                    value="{{ selectedLang.code }}">
                                                    {{ selectedLang.name }}
                                                </option>
                                                <option
                                                    *ngFor="let lang of allowedLanguages"
                                                    value="{{ lang.code }}">
                                                    {{ lang.name }}
                                                </option>
                                            </sm-select>
                                            <span
                                                *ngIf="isEnglishOnly"
                                                class="ui label transition visible"
                                                data-value="en-US"
                                                style="display: inline-block !important">
                                                English (US)
                                            </span>
                                            <label *ngIf="tLanguageError">
                                                You must select at least one target language.
                                            </label>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td><label for="rp-paper">Paper Sizes:</label></td>
                                    <td>
                                        <div class="field" style="width: 196px">
                                            <sm-select
                                                class="rp-paper"
                                                [control]="tPaperCtrl"
                                                #tPaperSelect>
                                                <option value="A4">A4</option>
                                                <option value="L_8-5_11">Letter (8.5"x11")</option>
                                            </sm-select>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td><label for="rp-currency">Currency Symbol:</label></td>
                                    <td>
                                        <div class="field" style="width: 196px">
                                            <sm-select
                                                class="rp-currency"
                                                [control]="tCurrencyCtrl"
                                                #tCurrencySelect>
                                                <option value="€">€</option>
                                                <option value="GBP">£</option>
                                                <option value="USD">$</option>
                                                <option value="CHF">CHF</option>
                                                <option value="MYR">RM</option>
                                                <option value="y">¥</option>
                                                <option value="INR">र</option>
                                            </sm-select>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding-bottom: 18px">
                                        <label for="rp-conversion">Conversion Factor:</label>
                                    </td>
                                    <td style="padding-bottom: 18px">
                                        <div class="">
                                            <wj-input-number
                                                [(value)]="tConversionFactor"
                                                [format]="'F6'"
                                                [step]="1"
                                                style="
                                                    width: 196px;
                                                    margin-right: 5px;
                                                "></wj-input-number>
                                            x USD
                                        </div>
                                    </td>
                                </tr>
                            </table>
                            <div
                                class="right floated"
                                style="margin-top: 35px; margin-right: -20px">
                                <itc-button
                                    type="primary"
                                    icon="fa-floppy-disk"
                                    [loading]="isSaving"
                                    [disabled]="tLanguageError"
                                    (onclick)="saveTextPreferences()">
                                    Save
                                </itc-button>
                            </div>
                        </div>
                    </div>
                </form>
            </itc-tab>

            <itc-tab tabTitle="My Logo">
                <form>
                    <div class="html ui attached segment">
                        <div class="ui top attached label">Logos</div>
                        <div class="ui grid">
                            <div class="twelve wide column">
                                <div class="ui grid">
                                    <div class="four wide column coverLogoText">
                                        Cover Logo Image:
                                        <br />
                                        (600x150)
                                    </div>
                                    <div class="twelve wide column">
                                        <itc-file-uploader
                                            type="draganddrop"
                                            [maxFileSize]="2"
                                            accept="image/jpg, image/jpeg, image/png"
                                            allowedFileTypes="JPG, PNG"
                                            (uploadDone)="processCoverImage($event)"
                                            style="width: 600px">
                                            <div class="imgPreview">
                                                <fa-icon
                                                    icon="x"
                                                    (click)="removeCoverImage()"
                                                    [hidden]="!lCoverImage"></fa-icon>
                                                <img
                                                    width="600"
                                                    height="150"
                                                    [src]="
                                                        lCoverImage
                                                            ? lCoverImageFormat + lCoverImage
                                                            : 'https://dummyimage.com/600x150/000/fff'
                                                    "
                                                    class="coverLogoImage" />
                                            </div>
                                        </itc-file-uploader>
                                    </div>
                                    <div class="four wide column coverLogoText">
                                        Header Logo Image:
                                        <br />
                                        (300 x 75 or 600 x 150)
                                    </div>
                                    <div class="twelve wide column">
                                        <itc-file-uploader
                                            type="draganddrop"
                                            [maxFileSize]="2"
                                            accept="image/jpg, image/jpeg, image/png"
                                            allowedFileTypes="JPG, PNG"
                                            (uploadDone)="processHeaderImage($event)"
                                            style="width: 600px">
                                            <div class="imgPreview">
                                                <fa-icon
                                                    icon="x"
                                                    (click)="removeHeaderImage()"
                                                    [hidden]="!lHeaderImage"></fa-icon>
                                                <img
                                                    width="600"
                                                    height="150"
                                                    [src]="
                                                        lHeaderImage
                                                            ? lHeaderImageFormat + lHeaderImage
                                                            : 'https://dummyimage.com/600x150/000/fff'
                                                    "
                                                    class="coverLogoImage" />
                                            </div>
                                        </itc-file-uploader>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="right floated buttons" style="margin-top: 15px; margin-right: -5px">
                        <itc-button
                            type="primary"
                            icon="fa-floppy-disk"
                            [loading]="isSaving"
                            (onclick)="saveLogoPreferences()">
                            Save
                        </itc-button>
                    </div>
                </form>
            </itc-tab>

            <itc-tab tabTitle="Theme">
                <form class="ui form">
                    <div class="html ui attached segment">
                        <div class="ui top attached label">
                            Cover Page Style (double-click to view)
                        </div>
                        <div class="content themePageContent">
                            <div
                                class="themePageContainer"
                                *ngFor="let page of coverPageList"
                                [class.active]="thCoverPage == page.id"
                                (dblclick)="previewCoverPage(page)"
                                (click)="chooseCoverPage(page)">
                                <img
                                    *ngIf="page?.id?.length"
                                    class="themePage"
                                    [src]="'/assets/img/themes/' + page.id + '.png'" />
                                <p class="themePageTitle">{{ page.title }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="ui attached segment">
                        <div class="field">
                            <label style="display: flex">
                                Cover Accent Color
                                <span *ngIf="thAccentColorIgnored" style="font-weight: normal">
                                    (Accent color set by document style)
                                </span>
                            </label>
                            <div class="ui labeled input" style="max-width: 150px">
                                <div class="ui label" [style.backgroundColor]="thAccentColor"></div>
                                <input
                                    id="colorPicker"
                                    [disabled]="thAccentColorIgnored"
                                    [colorPicker]="thAccentColor"
                                    (colorPickerChange)="onColorChange($event)"
                                    [value]="thAccentColor"
                                    [cpPosition]="'top'"
                                    [cpAlphaChannel]="'disabled'"
                                    [cpFallbackColor]="'#000'" />
                            </div>
                        </div>
                    </div>

                    <br />

                    <div class="html ui attached segment" *ngIf="showColorScheme">
                        <div class="ui top attached label">Module Color Scheme</div>
                        <div class="content">
                            <itc-radio id="csChoice" [checked]="!thUseCustomCS">
                                Use Pre-Defined Color Pallette
                            </itc-radio>
                            <ul class="colorSchemes">
                                <li *ngFor="let scheme of thColorSchemes; let schemeIndex = index">
                                    <input
                                        type="radio"
                                        name="thColorScheme"
                                        value="{{ scheme.name }}"
                                        id="sch{{ schemeIndex }}"
                                        (change)="onPreDefinedCSChange(scheme, $event)"
                                        [checked]="
                                            thPreDefinedChoice == (scheme.name | lowercase)
                                        " />
                                    <label class="colorScheme" for="sch{{ schemeIndex }}">
                                        <div class="colorBlock">
                                            <span
                                                class="schemeColor"
                                                [style.backgroundColor]="scheme.c1"></span>
                                            <span
                                                class="schemeColor"
                                                [style.backgroundColor]="scheme.c2"></span>
                                            <span
                                                class="schemeColor"
                                                [style.backgroundColor]="scheme.c3"></span>
                                        </div>
                                        <p class="schemeName">{{ scheme.name }}</p>
                                    </label>
                                </li>
                            </ul>

                            <itc-radio
                                id="csChoice2"
                                [checked]="thUseCustomCS"
                                label="Use Custom Defined Colors (click on module color to select)"></itc-radio>
                            <br />
                            <br />
                            <div class="customScheme">
                                <div>
                                    <span
                                        [colorPicker]="thCustomCS[0]"
                                        (colorPickerChange)="onCustomCSChange('custom1', $event)"
                                        [style.backgroundColor]="thCustomCS[0]"
                                        [cpAlphaChannel]="'disabled'"
                                        [cpFallbackColor]="'#0044CE'"
                                        [cpPosition]="'right'"
                                        (cpToggleChange)="onCustomCSOpen($event)"></span>
                                    Network Administrator
                                </div>

                                <div>
                                    <span
                                        [colorPicker]="thCustomCS[1]"
                                        (colorPickerChange)="onCustomCSChange('custom2', $event)"
                                        [style.backgroundColor]="thCustomCS[1]"
                                        [cpAlphaChannel]="'disabled'"
                                        [cpFallbackColor]="'#BF4435'"
                                        [cpPosition]="'right'"
                                        (cpToggleChange)="onCustomCSOpen($event)"></span>
                                    Security
                                </div>

                                <div>
                                    <span
                                        [colorPicker]="thCustomCS[2]"
                                        (colorPickerChange)="onCustomCSChange('custom3', $event)"
                                        [style.backgroundColor]="thCustomCS[2]"
                                        [cpAlphaChannel]="'disabled'"
                                        [cpFallbackColor]="'#72DDFC'"
                                        [cpPosition]="'right'"
                                        (cpToggleChange)="onCustomCSOpen($event)"></span>
                                    Exchange / SQL Server / HIPAA
                                </div>
                            </div>
                        </div>
                    </div>

                    <br />

                    <div class="html ui attached segment">
                        <div class="ui top attached label">Document Style</div>
                        <div class="content">
                            <sm-select
                                [control]="themeCtrl"
                                style="display: inline-block; margin-right: 10px"
                                (onChange)="themeDropdownChanged()"
                                #themeSelect>
                                <option *ngFor="let theme of themeList" [value]="theme.ThemeName">
                                    {{ theme.ThemeName }}
                                </option>
                            </sm-select>

                            <itc-button type="ghost" icon="fa-pencil" (onclick)="showStyleDialog()">
                                Modify Style
                            </itc-button>
                        </div>
                    </div>
                    <div class="right floated buttons" style="margin-top: 15px; margin-right: -5px">
                        <itc-button
                            type="primary"
                            icon="fa-floppy-disk"
                            [loading]="isSaving"
                            (onclick)="saveThemePreferences()">
                            Save
                        </itc-button>
                    </div>
                </form>
            </itc-tab>

            <itc-tab tabTitle="Cover Image">
                <form>
                    <div class="html ui attached segment">
                        <div class="ui top attached label">Cover Image</div>
                        <div class="content">
                            <div class="cover-image-option-container">
                                <button (click)="moveLeft()" class="cover-scroll-left">&lt;</button>

                                <drag-scroll #nav>
                                    <div
                                        drag-scroll-item
                                        class="cover-image-option"
                                        *ngFor="let url of coverImageList"
                                        [class.active]="selectedImage == url">
                                        <img
                                            [src]="url"
                                            (click)="clickItem(url)"
                                            width="200"
                                            height="150" />
                                    </div>
                                </drag-scroll>
                            </div>
                            <button (click)="moveRight()" class="cover-scroll-right">&gt;</button>
                        </div>

                        <itc-file-uploader
                            type="draganddrop"
                            [maxFileSize]="1"
                            accept="image/jpg, image/jpeg, image/png"
                            allowedFileTypes="JPG, PNG"
                            (uploadDone)="customCoverImage($event)"
                            style="width: 400px">
                            <div class="imgPreview" [hidden]="!ciCoverImage">
                                <b>Preview:</b>
                                <br />
                                <img
                                    width="400"
                                    height="300"
                                    src="data:image;base64,{{ this.ciCoverImage }}" />
                            </div>
                        </itc-file-uploader>
                    </div>
                    <div class="right floated buttons" style="margin-top: 15px; margin-right: -5px">
                        <itc-button
                            type="primary"
                            icon="fa-floppy-disk"
                            [loading]="isSaving"
                            (onclick)="saveCoverImagePreferences()">
                            Save
                        </itc-button>
                    </div>
                </form>
            </itc-tab>
        </itc-tabs>
    </div>
</sds-loader>

<sm-modal title="Preview Theme Style - {{ previewCover.title }}" class="xs" #previewModal>
    <modal-content>
        <img
            *ngIf="previewCover.id?.length"
            [src]="'/assets/img/themes/' + previewCover.id + '.png'"
            style="max-width: 100%; box-shadow: 0px 3px 8px rgb(0 0 0 / 24%)" />
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" class="cancel" (onclick)="previewModal.hide()">
            Cancel
        </itc-button>
    </modal-actions>
</sm-modal>

<sds-report-style-dialog (onUpdate)="setCustomTheme($event)" #styleDialog></sds-report-style-dialog>
