import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    forwardRef,
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
    selector: 'color-picker',
    template: `
        <div class="colorPicker">
            <input
                [name]="name"
                type="text"
                [attr.id]="inputId ? inputId : null"
                [(ngModel)]="color"
                [colorPicker]="color"
                (colorPickerChange)="changeColor($event)"
                [cpPosition]="position"
                [cpAlphaChannel]="alphaChannel"
                [cpFallbackColor]="fallbackColor"
                [disabled]="disabled"
                [required]="required"
                (change)="inputChange($event)"
                #colorPicker />
            <div class="colorChip" [style.background]="color" (click)="colorPicker.click()"></div>
        </div>
    `,
    styles: [
        `
            .colorPicker {
                display: inline-flex;
                align-items: center;
                flex-direction: row-reverse;
                &:hover {
                    .colorChip {
                        border-color: var(--gray-40);
                    }
                }
                .colorChip {
                    display: block;
                    width: 32px;
                    height: 32px;
                    border: 1px solid var(--border-secondary);
                    border-right: none;
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                }
                input {
                    width: 92px;
                    border-left: none;
                    border-radius: 0 4px 4px 0;
                    margin-bottom: 0;
                    &:focus {
                        border-left: none !important;
                        & ~ .colorChip {
                            border-color: var(--rft-orange);
                        }
                    }
                }
            }
            :host ::ng-deep {
                .color-picker {
                    transform: translateX(-30px);
                }
            }
        `,
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RFTColorPickerComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RFTColorPickerComponent {
    @Input() alphaChannel = 'disabled';
    @Input() color: string;
    @Input() control: FormControl;
    @Input() disabled = false;
    @Input() fallbackColor = '#000000';
    @Input() name: string = null;
    @Input() position = 'bottom';
    @Input() required = false;
    @Input() inputId = null;
    @Output() colorPickerChange = new EventEmitter<string>();

    controlSub: Subscription;
    constructor(private cdr: ChangeDetectorRef) {}

    ngOnInit() {
        if (this.control) {
            // handle form controls disable status
            this.control.registerOnDisabledChange((ds) => {
                this.setDisabledState(ds);
            });
            this.controlSub = this.control.valueChanges.subscribe((val) => {
                this.writeValue(val);
            });
            this.setDisabledState(this.control.disabled);
            this.writeValue(this.control.value);
        }
    }

    /* ngModal Access boilerplate */
    onChange = (color: string) => {};
    onBlur = () => {};
    writeValue(color: string): void {
        this.color = color;
        this.onChange(color);
        this.colorPickerChange.emit(color);
        this.cdr.markForCheck();
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onBlur = fn;
    }
    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    changeColor(color: string) {
        if (this.control) {
            this.control.setValue(color);
            this.control.markAsDirty();
            this.control.markAsTouched();
        } else {
            this.color = color;
            this.colorPickerChange.emit(color);
        }
    }

    /**
     * User entered an invalid hex value, so fall back to default
     */
    inputChange(evt) {
        if (
            !this.color ||
            this.color.indexOf('#') === -1 ||
            (this.color.length !== 4 && this.color.length < 7) ||
            this.color.length > 7
        ) {
            this.color = this.fallbackColor;
        }
    }
}
