import { Notes } from '../../sites/shared/notes/notes.model';

export class ScanDataSecurityGroup {
    public Id: number;
    public GroupName: string;
    public Path: string;
    public TotalMember: string;
    public EnabledMembers: any;
    public DisabledMembers: any;
    public FilteredMembers: any;
    public Description: string;
    public TotalEnabled?: number;
    public TotalDisabled?: number;

    public Notes: Notes;
    public Procedures: Notes[];
    public RelatedItems: string[];
    public SmartTags: any[];
}
