import {
    Component,
    ContentChild,
    Directive,
    ElementRef,
    HostListener,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import { ItcButtonComponent } from '../button/button.component';

@Component({
    selector: 'itc-dropdown-button',
    template: `
        <div
            class="itc dropdown button {{ classList }}"
            [class.bulkChangeCheckbox]="type === 'bulkCheckbox'"
            [class.loading]="loading"
            (click)="buttonClicked($event)"
            [class.loading]="loading">
            <span class="button-content"><ng-content select="button-content"></ng-content></span>
            <fa-icon *ngIf="type === 'bulkCheckbox'" [icon]="['fas', 'caret-down']"></fa-icon>
            <div class="dropdownContent" [hidden]="!this.dropdownOpen" #dropdownContent>
                <ng-content select="dropdown-content"></ng-content>
            </div>
        </div>
    `,
    styleUrls: ['./itc-dropdown-button.component.scss'],
})
export class ItcDropdownButtonComponent implements OnInit {
    @Input() classList: string;
    @Input() type: string;
    @Input() set loading(val: boolean) {
        this._loading = val;
        if (this.button) {
            this.button.loading = val;
        }
    }
    _loading = false;
    _dropdownOpen = false;
    @ViewChild('dropdownContent') dropdownContent: ElementRef;
    @ContentChild(ItcButtonComponent, { static: true }) button: ItcButtonComponent;

    // listen for click outside of dropdown.
    @HostListener('document:click', ['$event.target'])
    public onPageClick(targetElement) {
        const clickedInside = this.elementRef.nativeElement.contains(targetElement);

        if (this.dropdownOpen == true) {
            if (!clickedInside) {
                this.dropdownOpen = false;
            }
        }
    }

    set dropdownOpen(value) {
        this._dropdownOpen = value;
        if (value) {
            setTimeout(() => {
                this.resizeDropdownContent();
            });
        }
    }
    get dropdownOpen() {
        return this._dropdownOpen;
    }

    constructor(private elementRef: ElementRef) {}

    ngOnInit() {}

    buttonClicked(event) {
        event.stopPropagation();
        if (!this._loading) {
            if (
                !(event.taret instanceof HTMLInputElement) ||
                event.target === event.currentTarget
            ) {
                this.dropdownOpen = !this.dropdownOpen;
            }
        }
    }

    /**
     * Resize dropdown content to make it scroll
     *
     * dd = Dropdown, pc = PageContent
     * If dropdown bottom > pagecontent bottom, set height
     * of dropdown so it scrolls and doesn't go off page.
     */
    resizeDropdownContent() {
        let ddEl = this.dropdownContent.nativeElement;
        let ddRect = ddEl.getBoundingClientRect();
        let pcBottom = document
            .querySelector('.sidebarContent-inner')
            .getBoundingClientRect().bottom;
        ddEl.scrollTop = 0;
        if (ddRect.bottom >= pcBottom) {
            let maxHeight = ddRect.height - (ddRect.bottom - pcBottom) - 20; // the 20 is just a little extra space
            ddEl.style.display = 'block';
            ddEl.style.maxHeight = maxHeight + 'px';
            ddEl.style.overflow = 'auto';
        }
    }
}

@Directive({ selector: 'button-content, dropdown-content' })
export class ItcDropdownButtonTagsDirective {
    // No behavior
    // The only purpose is to "declare" the tag in Angular2
}
