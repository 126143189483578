import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Entry } from './snmp-entries.component';
@Injectable({
    providedIn: 'root',
})
export class SNMPEntriesService {
    isValid = new BehaviorSubject<boolean>(false);

    setValid(valid: boolean): void {
        this.isValid.next(valid);
    }
    getValid(): Observable<boolean> {
        return this.isValid.asObservable();
    }

    sortEntries(entries: Entry[] | string[]) {
        entries.sort((a, b) => {
            let aEntry;
            let bEntry;
            if (typeof a === 'string') {
                aEntry = a;
                bEntry = b;
            } else {
                aEntry = a.entry;
                bEntry = b.entry;
            }
            return aEntry.localeCompare(bEntry, undefined, {
                numeric: true,
                sensitivity: 'base',
            });
        });
    }
}
