import { Component, OnInit } from '@angular/core';
import { PrintService } from '../../../../shared/print.service';
import { EmployeeTrackerInfo } from './employee-tracker.model';
import { CM_COLORS } from '../../shared/cm-summary.constants';

@Component({
    selector: 'sds-printable-employee-tracker-policy',
    templateUrl: './printable-employee-tracker-policy.component.html',
    styleUrls: ['./printable-employee-tracker-policy.component.scss'],
})
export class PrintableEmployeeTrackerPolicyComponent implements OnInit {
    policyInfo: EmployeeTrackerInfo;
    tableStatusBackground: any;

    constructor(private printService: PrintService) {}

    ngOnInit() {
        this.tableStatusBackground = {
            COMPLETED_COLOR: CM_COLORS.COVERED,
            PENDING_COLOR: CM_COLORS.PARTIAL,
            EXPIRED_COLOR: CM_COLORS.NOTCOVERED,
            COMPLETED_COLOR_LEGEND: CM_COLORS.COVERED,
            PENDING_COLOR_LEGEND: CM_COLORS.PARTIAL,
            EXPIRED_COLOR_LEGEND: CM_COLORS.NOTCOVERED,
            COMPLETED_STATUS_TABLE_BACKGROUND: CM_COLORS.COVERED,
            PENDING_STATUS_TABLE_BACKGROUND: CM_COLORS.PARTIAL,
            EXPIRED_STATUS_TABLE_BACKGROUND: CM_COLORS.NOTCOVERED,
            COMPLETED_STATUS_TABLE_COLOR: CM_COLORS.FOREGROUND,
            PENDING_STATUS_TABLE_COLOR: CM_COLORS.FOREGROUND,
            EXPIRED_STATUS_TABLE_COLOR: CM_COLORS.FOREGROUND,
        };

        this.policyInfo = this.printService.EmployeeTrackerData;

        Promise.resolve(this.policyInfo).then(() => {
            setTimeout(() => {
                this.printService.onDataReady();
            }, 500);
        });
    }
}
