import { Component, Input } from '@angular/core';
import { RFT_URL } from 'app/core/constants';

@Component({
    selector: 'help-button',
    template: `
        <div
            class="help-button"
            [style.cursor]="url || command ? 'pointer' : 'default'"
            [tooltip]="text"
            [ttLink]="url ? { href: this.rft_url + this.url, text: 'Learn More', newTab: true } : null"
            [ttTitle]="title"
            ttPosition="bottom"
            (ttLinkClick)="openHelpWindow()"
            (click)="openHelpWindow()">
            <fa-icon [icon]="icon" [fixedWidth]="true"></fa-icon>
        </div>
    `,
    styles: [
        'div.help-button { display: inline-block; padding: 4px; margin-left: 4px; cursor: pointer; }',
        'fa-icon { display: flex; width: 16px; height: 16px; }',
    ],
})
export class HelpButtonComponent {
    @Input() title: string;
    @Input() text: string;
    @Input() noLink = false;
    @Input() url: string;
    @Input() linkText: string = 'Learn More';
    @Input() command: () => void;
    @Input() icon = 'circle-question';

    rft_url:string = RFT_URL;

    constructor() {}

    openHelpWindow() {
        if (this.noLink) {
            return;
        }
        if (this.command) {
            this.command();
            return;
        }
        window.open(this.rft_url + this.url);
    }
}
