<div
    class="download_page"
    [class.light]="theme == 'light'"
    [class.customBranded]="customBranding"
    style="height: 100vh; overflow: auto">
    <span class="topdesign"></span>
    <span class="leftdesign"></span>
    <!--
    <a class="kaseya_logo ir" href="https://www.kaseya.com/" *ngIf="!customBranding">Kaseya</a>
    <a class="itc_launcher ir" href="https://www.kaseya.com/" *ngIf="!customBranding">ITC</a>
    -->

    <div class="download_container">
        <div class="download_header">
            <div class="company_logo ir" *ngIf="!(customBranding && logoImage)">
                RapidFire Tools
            </div>
            <div *ngIf="customBranding && logoImage">
                <img [src]="logoImage" width="240" />
            </div>
        </div>
        <div class="download_item download">
            <div class="iconContainer">
                <img src="/assets/img/downloads/download-icon-windows.svg" />
            </div>
            <p class="name">Windows Computer Security Scan</p>
            <p class="shortText">
                The Computer Data Collector is a Windows application that is run on individual
                computers (workstations or servers) to collect information for each system.
            </p>
            <itc-button (onclick)="downloadFile('ComputerScanDataCollector.exe')">
                Download
            </itc-button>
        </div>
        <div class="download_item download">
            <div class="iconContainer">
                <img src="/assets/img/downloads/download-icon-mac.svg" />
            </div>
            <p class="name">Mac OS Computer Security Scan</p>
            <p class="shortText">
                The MacOS ARM64 Computer Data Collector is a Mac application that is run on
                individual computers (workstations or servers) to collect information for that
                system. Use this to collect computer information from macOS systems to be merged
                into the network data collection. Download this version for ARM64 architecture.
            </p>
            <itc-button (onclick)="downloadFile('computerscanner-osx-arm64.tar.gz')">
                Download
            </itc-button>
        </div>
        <div class="download_item download">
            <div class="iconContainer">
                <img src="/assets/img/downloads/download-icon-linux.svg" />
            </div>
            <p class="name">Linux Computer Security Scan</p>
            <p class="shortText">
                The Linux Computer Data Collector is a Linux application (works on most modern Linux
                versions) that is run on individual computers (workstations or servers) to collect
                information for that system.
            </p>
            <itc-button (onclick)="downloadFile('computerscanner-linux-x64.tar.gz')">
                Download
            </itc-button>
        </div>
    </div>
</div>
