import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthHttp } from 'app/core/auth/auth.http';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class ResourcesService {
    private apiUrl: string;
    private cysuranceFile: any;

    constructor(private http: AuthHttp, private httpClient: HttpClient) {
        this.apiUrl = environment.apiUrl;
        this.httpClient.get('assets/data/cysurance.json').subscribe((json) => {
            this.cysuranceFile = json;
        });
    }

    getPriceSheet(): Promise<any> {
        const url: string = this.apiUrl + 'Resources/PriceSheet';

        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    // I believe 'name' should include the file extension here
    getBrochure(name: string): Promise<any> {
        const url: string = this.apiUrl + `Resources/Brochure?name=${name}`;

        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getCysuranceJSON(): Promise<any> {
        return this.cysuranceFile as any;
    }
}
