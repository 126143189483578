import {
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    Injectable,
    Input,
    OnChanges,
    OnInit,
    Injector,
    SimpleChanges,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';

import { Alert } from 'app/alerts/shared/alert.model';
import { AlertDiagnostics } from './alert-diagnostics.model';
import { ALERT_DIAGS } from 'app/alerts/shared/constants';

import { environment } from 'environments/environment';
import { SemanticModalComponent } from 'app/semantic-legacy/components/modal/modal';

@Component({
    selector: 'sds-alert-diag-modal',
    templateUrl: './alert-diag-modal.component.html',
    styleUrls: ['./alert-diag-modal.component.css'],
})
export class AlertDiagModalComponent implements OnChanges, OnInit {
    constructor(private resolver: ComponentFactoryResolver) {}

    @Input() alert: Alert;

    @Input() set data(val: any) {
        if (val && val.Diagnostics) {
            this.diags = this.populate({ Children: val.Diagnostics });

            // If this is throwing errors make sure ALERT_DIAGS has the view for this.alert.AlertType
            (this.diagComponentRef.instance as any).diagData = this.diags;

            if (this.modal.instance) this.modal.instance.refresh();

            if (!environment.production) console.log(this.diags);
        }
    }

    @ViewChild('modal', { static: true }) modal: ComponentRef<SemanticModalComponent>;
    @ViewChild('diagView', { read: ViewContainerRef, static: true }) diagView: ViewContainerRef;

    diagComponentRef: ComponentRef<any>;
    diags: any;

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.alert && changes.alert.currentValue) {
            this.alert = changes.alert.currentValue;
            this.setup();
        }
    }

    isSetup = false;

    setup() {
        if (this.isSetup || !this.alert) {
            return;
        }

        let componentType = ALERT_DIAGS[this.alert.AlertType];

        if (!componentType) return;

        let factory = this.resolver.resolveComponentFactory(componentType);
        // let injector = ReflectiveInjector.fromResolvedProviders([], this.diagView.injector);
        let injector = Injector.create({ providers: [], parent: this.diagView.injector });

        let component = factory.create(injector);
        this.diagView.insert(component.hostView);
        this.diagComponentRef = component;

        this.isSetup = true;
    }

    // Convoluted recursive function to populate diagnostic details
    // Input should look like: { 'Children': <alertItem.Diagnostics> }
    populate(node) {
        var diag = {};

        if (node.Children && node.Children.length > 0) {
            node.Children.forEach((child) => {
                // if node has children
                if (child.Children && child.Children.length > 0) {
                    if (diag[child.Name] === void 0) diag[child.Name] = [];

                    // recursive funtimes with gross and confusing non-tail recursion :D
                    var r = this.populate(child);

                    // if return value is array, assign it
                    if (Array.isArray(r)) diag[child.Name] = r;
                    // otherwise push to an array
                    else diag[child.Name].push(r);
                }
                // just a value node
                else {
                    diag[child.Name] = child.Value;
                }
            });
        }
        // if name was empty, it's an array;
        if (diag['']) diag = diag[''];

        return diag;
    }

    showModal() {
        let modal = this.modal as any;
        modal.show();
        window.setTimeout(function () {
            modal.refresh();
        }, 100);
    }
}
