export class Notes {
    public Id: string;
    public AccountId: string;
    public Body: string;
    public PrivateBody: string;
    public PrivateCode: string;
    public ItemId: string;
    public SiteId: number;
    public Title: string;
    public Category: string;
    public HasPrivate?: boolean;
    public CreatedDate: string;

    public constructor(init?: Partial<Notes>) {
        Object.assign(this, init);
    }
}
