import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';
import { AuthHttp } from 'app/core/auth';
import { Requirement } from './requirement.model';
import { Standard } from './standard.model';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class StandardsService {
    private apiUrl: string;

    constructor(private http: AuthHttp) {
        this.apiUrl = environment.apiUrl;
    }

    getSystemStandards(siteId: number = null): Promise<Standard[]> {
        const url: string = this.apiUrl + `SystemStandards` + (siteId ? `?siteId=${siteId}` : '');
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getSystemStandardsForIso(iso: string, siteId: number = null): Promise<Standard[]> {
        const url: string =
            this.apiUrl + `SystemStandardsForIso/${iso}` + (siteId ? `?siteId=${siteId}` : '');
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getAccountStandards(siteId: number = null): Promise<Standard[]> {
        const url: string = this.apiUrl + `AccountStandards` + (siteId ? `?siteId=${siteId}` : '');
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getAccountStandardsForIso(iso: string, siteId: number = null): Promise<Standard[]> {
        const url: string =
            this.apiUrl + `AccountStandardsForIso/${iso}` + (siteId ? `?siteId=${siteId}` : '');
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getSiteStandards(siteId: number): Promise<Standard[]> {
        const url: string = this.apiUrl + `SiteStandards/${siteId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getSiteStandardsForIso(siteId: number, iso: string): Promise<Standard[]> {
        const url: string = this.apiUrl + `SiteStandardsForIso/${siteId}/${iso}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }
    getSelectedStandards(siteId: number): Promise<Standard[]> {
        const url: string = this.apiUrl + `SelectedStandards/${siteId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }
    setSelectedStandards(siteId: number, standardIds: number[]): Promise<any> {
        const url: string = this.apiUrl + `SelectStandards/${siteId}`;
        return this.http
            .post(url, { standardIds: standardIds })
            .toPromise()
            .then((res) => res);
    }

    updateStandard(standard: Standard): Promise<Standard> {
        const url: string = this.apiUrl + `Standard`;
        return this.http
            .put(url, standard)
            .toPromise()
            .then((res) => res);
    }

    addStandard(standard: Standard): Promise<Standard> {
        const url: string = this.apiUrl + `Standard`;
        return this.http
            .post(url, standard)
            .toPromise()
            .then((res) => res);
    }

    deleteStandard(standardId: number): Promise<any> {
        const url: string = this.apiUrl + `Standard/${standardId}`;
        return this.http
            .delete(url)
            .toPromise()
            .then((res) => res);
    }

    cloneStandard(standardId: number, newStandardName: string): Promise<any> {
        const url: string = this.apiUrl + `Standard/Clone/${standardId}`;
        let postBody = { newStandardName: newStandardName };
        return this.http
            .post(url, postBody)
            .toPromise()
            .then((res) => res);
    }

    addAccountStandard(standardId: number): Promise<any> {
        const url: string = this.apiUrl + `AccountStandard`;
        return this.http
            .post(url, { standardId: standardId })
            .toPromise()
            .then((res) => res);
    }

    deleteAccountStandard(standardId: number): Promise<any> {
        const url: string = this.apiUrl + `AccountStandard/${standardId}`;
        return this.http
            .delete(url)
            .toPromise()
            .then((res) => res);
    }

    addSiteStandard(standardId: number, siteId: number): Promise<any> {
        const url: string = this.apiUrl + `SiteStandard`;
        return this.http
            .post(url, { standardId: standardId, siteId: siteId })
            .toPromise()
            .then((res) => res);
    }

    deleteSiteStandard(standardId: number, siteId: number): Promise<any> {
        const url: string = this.apiUrl + `SiteStandard/${siteId}/${standardId}`;
        return this.http
            .delete(url)
            .toPromise()
            .then((res) => res);
    }

    getStandardDisplayName(std) {
        return std.Name.trim() + (std.Name != std.Variant ? ' - ' + std.Variant.trim() : '');
    }

    // updates guidance and policy for this requirement and site
    updateRequirementTextOverrides(siteId: number, requirement: Requirement) {
        const url: string = this.apiUrl + `UpdateRequirementTextOverrides/${siteId}`;
        return this.http
            .post(url, requirement)
            .toPromise()
            .then((res) => res);
    }

    // reverts guidance and policy for this requirement and site
    revertRequirementTextOverrides(siteId: number, requirementId: number) {
        const url: string =
            this.apiUrl + `RevertRequirementTextOverrides/${siteId}/${requirementId}`;
        return this.http
            .delete(url)
            .toPromise()
            .then((res) => res);
    }

    importStandardsAndControls(fromSiteId: number, toSiteId: number, iso: string) {
        const url: string =
            this.apiUrl + `ImportStandardsAndControls/${fromSiteId}/${toSiteId}/${iso}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    setAcknowledgeStandardLicense(siteId: number, standard: string) {
        const url: string =
            this.apiUrl + `SetAcknowledgeStandardLicense/${siteId}/${standard}`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    getAcknowledgeStandardLicense(siteId: number, standard: string) {
        const url: string =
            this.apiUrl + `GetAcknowledgeStandardLicense/${siteId}/${standard}`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    getArchivedStandards(siteId: number, archiveGui: string): Promise<any> {
        const url: string = this.apiUrl + `GetArchivedStandards/${siteId}/${archiveGui}`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }
}
