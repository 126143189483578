import {
    ChangeDetectorRef,
    Component,
    OnInit,
    AfterViewInit,
    OnDestroy,
    isDevMode,
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

import { VpAuthService } from '../vp-auth.service';

import { VendportalService } from '../vendportal.service';
import { Vendor, SiteParams } from '../data';
import { UiService } from '../../core/ui.service';

declare var window: any;

@Component({
    selector: 'portal-layout',
    templateUrl: './portal-layout.component.html',
    styleUrls: ['./portal-layout.component.scss'],
})
export class PortalLayoutComponent implements OnInit, OnDestroy {
    constructor(
        private authService: VpAuthService,
        private service: VendportalService,
        private router: Router,
        private _cdr: ChangeDetectorRef,
        private uiService: UiService
    ) {}

    isAuth: boolean;
    version: string = 'LOCAL';
    isDev: boolean = false;
    siteParams: SiteParams;

    userData: Vendor;

    currentLang: string;
    ngOnInit() {
        this.isAuth = this.authService.isAuth();

        this.service.getMe().subscribe(
            (ud) => (this.userData = ud),
            (err) => console.log(err)
        );

        this.service.getSiteParams().subscribe(
            (sp) => (this.siteParams = sp),
            (err) => console.log(err)
        );
        this.uiService
            .getVersionUpdated()
            .then((version) => {
                this.version = version.replace(/Dev-|Beta-/, '');
                console.log('Version: ', version);
            })
            .catch((err) => {});
    }

    ngAfterViewInit() {}

    ngOnDestroy() {}

    logout() {
        this.authService.logout();
    }
}
