import {
    Component,
    Input,
    forwardRef,
    EventEmitter,
    Output,
    OnInit,
    ViewChild,
    ElementRef,
} from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
    selector: 'itc-toggle',
    template: `
        <div class="kaseya-ui-toggle__container {{ className }}">
            <span
                class="toggle__label shifted"
                (click)="toggleToggle()"
                *ngIf="label !== false && labelPosition === 'before'">
                {{ label ? label : on ? labelOn : labelOff }}
                <ng-container *ngTemplateOutlet="labelContent"></ng-container>
            </span>
            <div
                class="kaseya-ui-toggle"
                [ngClass]="{
                    'toggle--small': small,
                    'toggle--on': on,
                    'toggle-disabled': disabled
                }"
                (click)="toggleToggle($event)">
                <div class="toggle__content">
                    <div class="toggle__knob">
                        <!-- <i class="kaseya-ui-icon toggle__icon fas fa-fw fa-pro-check"></i> -->
                        <i class="kaseya-ui-icon toggle__icon icon check"></i>
                    </div>
                </div>
            </div>
            <span
                class="toggle__label"
                (click)="toggleToggle()"
                *ngIf="label !== false && labelPosition !== 'before'">
                {{ label ? label : on ? labelOn : labelOff }}
                <ng-container *ngTemplateOutlet="labelContent"></ng-container>
            </span>
        </div>

        <ng-template #labelContent>
            <ng-content></ng-content>
        </ng-template>
    `,
    styleUrls: ['./itc-toggle.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ItcToggleComponent),
            multi: true,
        },
    ],
})
export class ItcToggleComponent implements OnInit, ControlValueAccessor {
    @Input() control: UntypedFormControl;
    @Input() className: string;
    @Input() disabled = false;
    @Input() on = false;
    @Input() small = false;
    @Input() labelOff: string;
    @Input() labelOn: string;
    @Input() label: string | boolean;
    @Input() labelPosition = 'before';
    @Input() stopBubbling = false;
    @Output() onChanged = new EventEmitter<boolean>();

    controlSub: Subscription;

    ngOnInit() {
        if (this.control) {
            this.controlSub = this.control.valueChanges.subscribe((val) => {
                this.writeValue(val);
            });
            this.writeValue(this.control.value);
        }
    }

    ngOnDestroy() {
        if (this.controlSub) {
            this.controlSub.unsubscribe();
        }
    }

    /* ngModal Access boilerplate */
    onChange = (_) => {};
    onBlur = (_) => {};
    writeValue(obj: any): void {
        this.on = obj;
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onBlur = fn;
    }
    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    toggleToggle(evt) {
        if (this.stopBubbling) {
            evt.stopPropagation();
        }
        if (this.disabled) {
            return false;
        }
        this.on = !this.on;
        if (this.control) {
            // if using a formControl set it's value
            this.control.setValue(this.on);
            this.control.markAsDirty();
        } else {
            // this will update the ngModel
            this.onChange(this.on);
        }
        this.onChanged.emit(this.on);
    }
}
