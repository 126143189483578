import { CM_COLORS } from '../shared/cm-summary.constants';
import { Answer, QuestionDescription, WizardOptions } from '../shared/cm-wizard/cm-wizard.model';

export let ControlsWizardAnswers: Answer[] = [
    {
        title: 'Yes, Fully',
        color: CM_COLORS.COVERED_BRIGHT,
        colorMuted: CM_COLORS.COVERED,
        value: 1,
    },
    {
        title: 'Yes, with Technical Issues',
        color: CM_COLORS.PARTIAL_BRIGHT,
        colorMuted: CM_COLORS.PARTIAL,
        value: 2,
    },
    {
        title: 'Yes, Partially',
        color: CM_COLORS.PARTIAL_BRIGHT,
        colorMuted: CM_COLORS.PARTIAL,
        value: 3,
    },
    {
        title: 'No',
        color: CM_COLORS.NOTCOVERED_BRIGHT,
        colorMuted: CM_COLORS.NOTCOVERED,
        value: 4,
    },
];

export let ControlsWizardDescription: QuestionDescription[] = [
    {
        title: 'Does your organization handle:',
        content: 'Control.ShortDesc',
        localized: true,
    },
    {
        title: 'Description',
        content: 'Control.LongDesc',
        localized: true,
        ifBlank: '[There is currently no description for this control.]',
    },
    {
        title: 'Guidance',
        content: 'Control.Guidance',
        localized: true,
        ifBlank: '[There is currently no guidance for this control.]',
    },
    {
        title: 'Procedure(s)',
        content: 'Control.Procedures',
        localized: true,
        type: 'list',
    },
    {
        title: 'Assessment Protocol',
        // this has to be in one line because I have a pipe that breaks newlines...yeah...it's annoying in this case, but needed for control descriptions
        content: `<ul class="wizardList"><li>Assess if the organization has implemented the operational procedure(s) for this control.</li><li>Evaluate documentation to verify that a periodic assessment of the organization's implementation of the control procedure(s) meets the organization's requirements.</li><li>Be prepared to present current documentation that will be demanded for an audit, incident investigation, or lawsuit.</li><li>Add comments or link documents to this control's response to substantiate the selected response below.</li></ul>`,
        localized: false,
        type: 'text',
        prevList: 'Procedure(s)',
        uglyHackText2: `<ul class="wizardList"><li>Assess if the organization has implemented the control in a manner consistent with the guidance.</li><li>Evaluate documentation to verify that a periodic assessment of the organization's implementation of the control meets the organization's requirements.</li><li>Be prepared to present current documentation that will be demanded for an audit, incident investigation, or lawsuit.</li><li>Add comments or link documents to this control's response to substantiate the selected response below.</li></ul>`,
    },
];

export let ControlsWizardOptions: WizardOptions = {
    assessment: 'ca',
    type: 'control',
    showImportButton: true,
    showRelatedIssues: true,
    listViewColumns: ['check', 'control', 'response', 'comment', 'files', 'assignments'],
    firstSort: { active: 'control', direction: 'asc' },
    showRelatedRequirements: true,
    showEvidence: true,
};
