import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ScanDataService } from './shared/scan-data.service';
import { ScanDataHelper } from 'app/scans/shared/scan-data-helper.service';

@NgModule({
    imports: [CommonModule],
    declarations: [],
    providers: [ScanDataService, ScanDataHelper],
})
export class ScansModule {}
