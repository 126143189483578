<sds-loader [complete]="loadingComplete">
    <sds-breadcrumb-trail [crumbs]="breadcrumbs"></sds-breadcrumb-trail>
    <header class="pageHeader">
        <h1>Control Templates</h1>
        <help-button
            title="Control Templates"
            text="Assign a custom set of controls to a template. Then assign the template to your assessment site to quickly apply that set of controls."
            url="/cm-grc/help/cm-grc-docs/grc-controls-template.htm"></help-button>
    </header>

    <div>
        <input
            style="width: 500px; margin-bottom: 0; margin-right: 16px"
            type="text"
            placeholder="Search"
            [(ngModel)]="templateSearch"
            (ngModelChange)="templateSearchChanged()"
            debounce="420" />

        <itc-checkbox
            id="hideSystemTemplates"
            class="bulk-responder-checkbox"
            (onChanged)="hideSystemTemplatesChanged($event)">
            Hide System Control Templates:
        </itc-checkbox>

        <itc-button
            type="primary"
            icon="fa-plus"
            style="float: right; margin-right: 10px"
            (onclick)="showTemplateEditModal(null, 0)"
            [disabled]="!canModifyTemplates(null)">
            Add New Control Template
        </itc-button>
    </div>

    <p style="color: gray; font-size: 0.8em; margin-top: 16px; margin-bottom: 8px">
        <fa-icon icon="lock-keyhole"></fa-icon>
        Lock indicates System Standard (read-only)
    </p>

    <div style="width: 100%">
        <div style="width: 100%; padding: 10px 0">
            <div>
                <table
                    sortable-table
                    class="itc-table"
                    style="margin-top: 0"
                    (sorted)="sortControlsTemplate($event)">
                    <thead>
                        <tr>
                            <th></th>
                            <th sortable-column="Id">ID</th>
                            <th sortable-column="Name">Control Template Name</th>
                            <th sortable-column="f1">Number of Controls</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let template of templatePage; let i = index">
                            <td style="text-align: right; width: 20px">
                                <itc-button
                                    *ngIf="template.IsSystem"
                                    type="icon"
                                    icon="fa-lock-keyhole"
                                    (onclick)="showTemplateEditModal(template, 2)"
                                    tooltip="View"></itc-button>
                            </td>
                            <td>
                                <a
                                    style="cursor: pointer"
                                    (click)="showTemplateEditModal(template, 2)">
                                    {{ template.Id }}
                                </a>
                            </td>
                            <td
                                [style.color]="
                                    template.IsSystem ? 'rgb(192,192,192)' : 'rgb(72, 72, 72)'
                                ">
                                {{
                                    template.IsSystem ? template.Name + ' (System)' : template.Name
                                }}
                            </td>
                            <td
                                [style.color]="
                                    template.IsSystem ? 'rgb(192,192,192)' : 'rgb(72, 72, 72)'
                                ">
                                {{ template.ControlIds.length }}
                            </td>
                            <td
                                *ngIf="!template.IsSystem"
                                style="text-align: right; padding-left: 20px">
                                <itc-button
                                    tooltip="Edit"
                                    type="icon"
                                    icon="fa-pencil"
                                    (onclick)="showTemplateEditModal(template, 2)"
                                    [disabled]="!canModifyTemplates(template)"></itc-button>
                                <itc-button
                                    tooltip="Delete"
                                    type="icon"
                                    icon="fa-trash-can"
                                    (onclick)="confirmDeleteTemplate(template)"
                                    [disabled]="!canModifyTemplates(template)"></itc-button>
                                <itc-button
                                    tooltip="Clone"
                                    ttPosition="top-left"
                                    type="icon"
                                    icon="fa-clone"
                                    (onclick)="showTemplateCloneModal(template)"
                                    [disabled]="!canModifyTemplates(template)"></itc-button>
                            </td>
                            <td
                                *ngIf="template.IsSystem"
                                style="text-align: right; padding-left: 20px">
                                <itc-button
                                    tooltip="Clone"
                                    ttPosition="top-left"
                                    type="icon"
                                    icon="fa-clone"
                                    (onclick)="showTemplateCloneModal(template)"
                                    [disabled]="!canModifyTemplates(template)"></itc-button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <itc-paginator
                    [data]="templatesFiltered"
                    (onPage)="templatePage = $event"></itc-paginator>
            </div>
        </div>
    </div>
</sds-loader>

<sm-modal [title]="templateEditTitle" class="md" #templateEditModal>
    <modal-content>
        <div class="ui container">
            <div
                *ngIf="viewSettings.isFirstPopUp && !viewSettings.showAreYouSureDialogue"
                style="margin-top: 10px; height: 400px">
                <div class="ui placeholder" style="margin-bottom: 20px">
                    <div class="paragraph">
                        <div class="line">
                            Control templates application and selection of multiple controls during
                            the standards and control selection process. A control template must be
                            given a unique name and must include 1 or more controls.
                        </div>
                    </div>
                </div>
                <div class="ui grid" style="display: block">
                    <form class="ui form">
                        <div class="sixteen wide column row-style" *ngIf="!viewSettings.isEditMode">
                            <div class="required field">
                                <label for="templateName">
                                    Control Template Name:
                                    <span
                                        *ngIf="templateNameExists(templateEditName?.trim())"
                                        style="color: var(--text-critical)">
                                        Control Template name must be unique.
                                    </span>
                                </label>
                                <input
                                    style="margin-top: 5.75px"
                                    type="text"
                                    id="templateName"
                                    [ngModelOptions]="{ standalone: true }"
                                    [(ngModel)]="templateEditName" />
                            </div>
                        </div>
                    </form>
                    <div class="sixteen wide column" *ngIf="viewSettings.isEditMode">
                        <div *ngIf="!viewSettings.editControlName">
                            <label for="templateName" style="font-size: x-large">
                                {{ templateEditName }}
                            </label>
                            <itc-button
                                type="ghost"
                                icon="fa-pencil"
                                (onclick)="
                                    viewSettings.editControlName = true;
                                    tempControlName = templateEditName
                                "
                                [hidden]="isViewMode"></itc-button>
                        </div>
                        <div *ngIf="viewSettings.editControlName">
                            <form class="ui form">
                                <div class="required field">
                                    <label for="templateName">
                                        Control Template Name:
                                        <span
                                            *ngIf="templateNameExists(templateEditName?.trim())"
                                            style="color: var(--text-critical">
                                            Control Template name must be unique.
                                        </span>
                                    </label>
                                </div>
                            </form>
                            <div class="ui grid row">
                                <div class="fourteen wide column">
                                    <input
                                        style="margin-top: 5.75px"
                                        type="text"
                                        id="templateName"
                                        [ngModelOptions]="{ standalone: true }"
                                        [(ngModel)]="templateEditName" />
                                </div>
                                <div
                                    class="two wide column"
                                    style="
                                        justify-content: space-between;
                                        display: flex;
                                        flex-direction: row;
                                        padding-top: 33px;
                                    ">
                                    <span>
                                        <itc-button
                                            type="icon"
                                            icon="fa-xmark"
                                            style="color: grey"
                                            (click)="noChangeTitle()"></itc-button>
                                    </span>
                                    <span>
                                        <itc-button
                                            type="icon"
                                            icon="fa-check"
                                            (click)="yesChangeTitle()"></itc-button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" style="padding-bottom: 5.75px">
                        <div
                            class="eight wide column"
                            style="display: flex; flex-direction: column-reverse">
                            <label>
                                Controls List
                                <span
                                    tooltip="This is a list of controls that you have applied to this template."
                                    ttPosition="right">
                                    <i class="info circle icon" style="color: #0e6eb8"></i>
                                </span>
                            </label>
                        </div>
                        <div class="eight wide column">
                            <itc-button
                                icon="fa-plus"
                                type="tertiary"
                                (onclick)="controlsAddShow()"
                                style="float: right"
                                [disabled]="controlsAvailable.length == 0"
                                [hidden]="isViewMode">
                                Add Controls
                            </itc-button>
                        </div>
                    </div>

                    <div class="sixteen wide column" style="padding-top: 0px">
                        <div>
                            <table
                                sortable-table
                                class="itc-table"
                                (sorted)="onControlSorted($event)">
                                <thead>
                                    <tr>
                                        <th sortable-column="ControlId">Control ID</th>
                                        <th sortable-column="Description">Description</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody style="margin-top: 0; padding-top: 0">
                                    <tr *ngFor="let r of addControlFilteredList; let i = index">
                                        <td>{{ r.control.ControlId }}</td>
                                        <td>{{ r.control.ShortDesc.LocalizedText[culture] }}</td>
                                        <td>
                                            <itc-button
                                                type="icon"
                                                icon="fa-trash-can"
                                                (onclick)="controlSelectedRemove(r)"
                                                [hidden]="isViewMode"
                                                ttposition="top"
                                                tooltip="Delete"></itc-button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <itc-paginator
                            [data]="controlsSelected"
                            (onPage)="addControlFilteredList = $event"
                            pageSize="10"></itc-paginator>
                    </div>
                </div>
            </div>
            <div
                *ngIf="!viewSettings.isFirstPopUp && !viewSettings.showAreYouSureDialogue"
                style="height: 400px">
                <div class="ui placeholder" style="margin-bottom: 30px">
                    <div class="paragraph">
                        <div class="line">
                            Check all controls to include in the control template. Use the search
                            filter to limit the list of controls.
                        </div>
                    </div>
                </div>

                <div class="ui grid">
                    <div class="row" style="padding-top: 0px">
                        <div
                            class="eight wide column"
                            style="display: flex; flex-direction: column-reverse">
                            <label>Controls List</label>
                        </div>
                        <div class="eight wide column">
                            <div class="searchOption">
                                <input
                                    style="margin-bottom: 0px"
                                    type="text"
                                    [(ngModel)]="controlsAddSearch"
                                    placeholder="Search"
                                    (ngModelChange)="controlsAddSearchChanged()"
                                    debounce="420" />
                            </div>
                        </div>
                    </div>
                    <div class="sixteen wide column" style="padding-top: 0px">
                        <table sortable-table class="itc-table" (sorted)="onControlSorted($event)">
                            <thead>
                                <tr>
                                    <th>
                                        <itc-checkbox
                                            [checked]="allControlsAvailableChecked"
                                            (onChanged)="controlsCheckedAll()"></itc-checkbox>
                                    </th>
                                    <th sortable-column="ControlId">Control ID</th>
                                    <th sortable-column="Description">Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let r of searchControlFilteredList; let i = index">
                                    <td>
                                        <itc-checkbox
                                            [checked]="r.select"
                                            (onChanged)="controlChecked(i)"></itc-checkbox>
                                    </td>
                                    <td>{{ r.control.ControlId }}</td>
                                    <td title="{{ getControlLongDescriptionTooltip(r.control) }}">
                                        {{ r.control.ShortDesc.LocalizedText[culture] }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <itc-paginator
                            [data]="controlsAvailable"
                            (onPage)="searchControlFilteredList = $event"></itc-paginator>
                    </div>
                </div>
            </div>
            <div *ngIf="viewSettings.showAreYouSureDialogue">
                <div class="ui grid">
                    <div class="sixteen wide column">
                        <h2>Confirmation</h2>
                        Data has not been saved. Are you sure you want to cancel?
                    </div>
                </div>
            </div>
        </div>
    </modal-content>
    <modal-actions>
        <div *ngIf="viewSettings.isEditMode">
            <span *ngIf="viewSettings.isFirstPopUp && !viewSettings.showAreYouSureDialogue">
                <itc-button
                    size="regular"
                    icon="fa-xmark"
                    type="secondary"
                    (onclick)="closeTemplateModal()"
                    [disabled]="false">
                    Cancel
                </itc-button>
                <itc-button
                    size="regular"
                    icon="fa-check"
                    type="primary"
                    (onclick)="templateSave()"
                    [disabled]="
                        viewSettings.editControlName ||
                        controlsSelected.length == 0 ||
                        templateEditName.trim() == '' ||
                        !checkIfDataEditedInFirstPopUp() ||
                        templateNameExists(templateEditName?.trim())
                    "
                    [hidden]="isViewMode">
                    Save
                </itc-button>
            </span>
        </div>
        <div *ngIf="!viewSettings.isEditMode">
            <span *ngIf="viewSettings.isFirstPopUp && !viewSettings.showAreYouSureDialogue">
                <itc-button
                    size="regular"
                    icon="fa-xmark"
                    type="secondary"
                    (onclick)="closeTemplateModal()"
                    [disabled]="false">
                    Cancel
                </itc-button>
                <itc-button
                    size="regular"
                    icon="fa-check"
                    type="primary"
                    (onclick)="templateSave()"
                    [disabled]="isSaveDisabled() || templateEditName.trim() == ''">
                    Save
                </itc-button>
            </span>
        </div>
        <span *ngIf="!viewSettings.isFirstPopUp && !viewSettings.showAreYouSureDialogue">
            <itc-button
                size="regular"
                icon="fa-arrow-left"
                type="secondary"
                (onclick)="controlsAddCancel()"
                [disabled]="false">
                Back
            </itc-button>
            <itc-button
                size="regular"
                icon="fa-check"
                type="primary"
                [disabled]="isAddDisabled()"
                (onclick)="controlsAddSave()">
                Add
            </itc-button>
        </span>
        <div *ngIf="viewSettings.showAreYouSureDialogue">
            <itc-button size="regular" type="secondary" (onclick)="clickedNo()">No</itc-button>
            <itc-button size="regular" type="danger" (onclick)="clickedYes()">Yes</itc-button>
        </div>
    </modal-actions>
</sm-modal>

<sm-modal title="Confirm Delete Template" class="sm" #confirmDeleteTemplateModal>
    <modal-content>
        <div class="ui warning icon message">
            <i class="warning sign icon"></i>
            <div class="content">
                <div class="header">
                    <span *ngIf="templateActive != null">
                        Are you sure you want to delete this Template - {{ templateActive.Name }}?
                    </span>
                </div>
            </div>
        </div>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" (onclick)="confirmDeleteTemplateModal.hide()">
            Cancel
        </itc-button>
        <itc-button type="danger" (onclick)="templateDelete()">Delete</itc-button>
    </modal-actions>
</sm-modal>

<sm-modal
    title="Clone Control Template {{ templateActive?.Name }}"
    class="sm"
    #cloneControlTemplateModal>
    <modal-content>
        <p>
            Create a clone of the current control template with a new name. You can modify the
            derived control template without affecting the original control.
        </p>

        <div class="sixteen wide column row-style">
            <div class="required field">
                <label>
                    Control Template Name:
                    <span
                        *ngIf="controlTemplateNames.includes(clonedControlTemplateName?.trim())"
                        style="color: #b30000">
                        Control Template name must be unique.
                    </span>
                </label>
                <input
                    style="margin-top: 2px"
                    type="text"
                    name="ControlName"
                    [(ngModel)]="clonedControlTemplateName"
                    placeholder="Control Name" />
            </div>
        </div>
    </modal-content>
    <modal-actions>
        <itc-button class="close" type="secondary" (onclick)="closeClone()">Close</itc-button>
        <itc-button
            icon="fa-clone"
            type="primary"
            (onclick)="cloneSelectedControlTemplate()"
            [disabled]="
                controlTemplateNames.includes(clonedControlTemplateName?.trim()) ||
                clonedControlTemplateName?.trim() == ''
            "
            [loading]="cloning">
            Clone
        </itc-button>
    </modal-actions>
</sm-modal>
