import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { Alert, ALERT_STATUSES } from 'app/alerts';
import { UiService } from 'app/core/ui.service';

const UI_KEY: string = 'todo_mine_filter';

@Component({
    selector: 'sds-todo-filters',
    templateUrl: './todo-filters.component.html',
    styleUrls: ['./todo-filters.component.css'],
})
export class TodoFiltersComponent implements OnInit {
    constructor(private uiService: UiService) {}

    @Input()
    set todos(value: Alert[]) {
        if (value) {
            this._todos = value;
            this.filter();
        }
    }

    @Output() onFiltered = new EventEmitter<Alert[]>();

    private _todos: Alert[];
    private someFilter: number = 1;

    itemsCtrl = new UntypedFormControl();

    ngOnInit() {
        this.itemsCtrl.setValue(this.someFilter);
        let mine = this.uiService.getControlValue(UI_KEY);
        if (mine) {
            this.someFilter = parseInt(mine);
            this.itemsCtrl.setValue(mine);
        }
    }

    filter() {
        if (this._todos) {
            this.onFiltered.emit(
                this._todos.filter((todo) => {
                    if (this.someFilter == 2 && todo.Status == ALERT_STATUSES.TASK_COMPLETE.value)
                        return false;
                    else return true;

                    //return this.someFilter == 2 ? todo.isMine : true;
                })
            );
        }
    }

    onChange() {
        this.someFilter = this.itemsCtrl.value;

        this.uiService.setControlValue(UI_KEY, this.someFilter);
        this.filter();
    }
}
