import { Component, OnInit, ViewChild } from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { KvsService } from 'app/sites/shared/kvs.service';
import { UiService } from 'app/core/ui.service';
import { MessageService } from 'app/core/message.service';
import { Subscription } from 'rxjs';
import { Site } from 'app/sites/shared/site.model';
import { PING_SITE, SITE } from 'app/sites/shared/constants';
import { NotificationService } from '../../../../../shared/itc/notification/notification.service';

@Component({
    selector: 'kvs-custom-scan-profile',
    templateUrl: './kvs-custom-scan-profile.component.html',
    styleUrls: ['./kvs-custom-scan-profile.component.css'],
})
export class KvsCustomScanProfileComponent implements OnInit {
    breadcrumbs = [
        { path: '../..', text: 'Vulscan' },
        { path: '..', text: 'Settings' },
        { path: '.', text: 'Custom Scan Profiles' },
    ];

    searchKey: UntypedFormControl = new UntypedFormControl();
    loadingComplete: boolean;
    readonly: boolean;
    msgSub: Subscription;
    site: Site;
    profiles: any[];
    filteringProfiles = false;
    filteredProfiles: any[];
    credentialToDelete: any;
    deleting: boolean;
    associatedScanTasks: any[];
    attemptingDelete: boolean;
    profileToDelete: any;
    profileToUpdate: any;
    existingProfiles: any;
    UidCustomProfile: any;
    profileSelected: any;
    confirmDelete: boolean;
    formulario: UntypedFormGroup;

    @ViewChild('confirmDeleteProfileModal') confirmDeleteProfileModal: any;
    @ViewChild('confirmCancelModal') confirmCancelModal: any;
    @ViewChild('confirmUpdateProfileModal') confirmUpdateProfileModal: any;
    @ViewChild('addProfileModal') addProfileModal: any;

    constructor(
        private kvsService: KvsService,
        private notificationService: NotificationService,
        private msgService: MessageService,
        private uiService: UiService,
        public builder: UntypedFormBuilder
    ) {}

    ngOnInit() {
        this.uiService.setTitle('Custom Scan Profiles');
        this.loadingComplete = false;
        this.readonly = false;
        this.confirmDelete = false;
        this.attemptingDelete = false;
        this.deleting = false;
        this.msgSub = this.msgService.on(SITE).subscribe((site: Site) => {
            if (!site) return;
            this.site = site;
            this.getProfile();
        });

        this.msgService.broadcast(PING_SITE);
    }

    dropDown = this.builder.group({
        profilesName: ['lowimpact', [Validators.required]],
    });

    getProfile() {
        if (this.site) {
            this.loadingComplete = false;
            this.kvsService
                .getCustomProfiles(this.site.Id)
                .then((res) => {
                    this.profiles = res;
                    this.filteredProfiles = res;
                    this.searchKey.setValue('');
                    this.onProfilesSorted({ sortColumn: 'Name', sortDirection: 'asc' });
                    this.loadingComplete = true;
                })
                .catch((err) => {
                    this.searchKey.setValue('');
                    this.loadingComplete = true;
                    this.notificationService.toast.error('Error', "Custom Scan Profile Can't Load");
                });
        }
    }

    showAddProfileModal() {
        this.addProfileModal.showModal(false, {});
    }

    profileSaved(profileName: string) {
        if (profileName.length > 0) {
            this.getProfile();
        }
    }

    onProfilesSorted(ev?: any) {
        if (ev && ev.sortColumn != undefined) {
            this.filteredProfiles = this.filteredProfiles.sort((a, b) =>
                this.sortByColumn(a, b, ev.sortColumn.replace('-', ''), ev.sortDirection)
            );
        }
    }

    sortByColumn(a, b, sortMethod, sortDirection) {
        if (sortMethod === 'Id') {
            return this.cmp(a[sortMethod], b[sortMethod], sortDirection);
        }
        a[sortMethod] = a[sortMethod] === undefined ? '' : a[sortMethod];
        b[sortMethod] = b[sortMethod] === undefined ? '' : b[sortMethod];
        return this.cmp(a[sortMethod].toUpperCase(), b[sortMethod].toUpperCase(), sortDirection);
    }

    cmp(a, b, sortDirection) {
        if (a > b) {
            return sortDirection == 'asc' ? 1 : -1;
        }
        if (a < b) {
            return sortDirection == 'asc' ? -1 : 1;
        }
        return 0;
    }

    filterProfiles() {
        let filtered = [];
        if (this.searchKey) {
            this.filteringProfiles = true;
            let key = this.searchKey.value.toLowerCase();
            filtered = this.profiles.filter(
                (profile) =>
                    profile.Name.toLowerCase().indexOf(key) >= 0 ||
                    profile.Comment.toLowerCase().indexOf(key) >= 0
            );
        } else {
            filtered = this.profiles;
        }

        this.filteredProfiles = filtered;
        setTimeout(() => {
            this.filteringProfiles = false;
        }, 250);
    }

    deleteProfile() {
        if (this.profileToDelete) {
            this.deleting = true;
            this.profileSelected = this.dropDown.get('profilesName').value;
            this.kvsService
                .deleteCustomProfile({ profile: this.profileToDelete })
                .then((res) => {
                    if (this.associatedScanTasks.length != 0) {
                        this.updateTasks();
                    } else {
                        this.confirmDeleteProfileModal.hide();
                        this.confirmCancelModal.hide();
                        this.getProfile();
                        this.deleting = false;
                        this.notificationService.toast.success(
                            'Success',
                            'Custom Scan Profile Deleted'
                        );
                    }
                })
                .catch((err) => {
                    this.confirmDeleteProfileModal.hide();
                    this.confirmCancelModal.hide();
                    this.deleting = false;
                    this.notificationService.toast.error('Error', `${err.error.Message}`);
                });
        } else {
            this.deleting = false;
        }
    }

    confirmProfileDelete(profile: any) {
        this.profileToDelete = profile;
        this.existingProfiles = this.profiles.filter((p) => p.Uid !== profile.Uid);
        this.attemptingDelete = true;
        this.kvsService
            .getKvsCustomProfileScanTasks(this.site.Id, this.profileToDelete.Uid)
            .then((res) => {
                this.associatedScanTasks = res;
                this.attemptingDelete = false;
                if (this.associatedScanTasks.length == 0) {
                    this.confirmCancelModal.show();
                    if (this.confirmDelete) {
                        this.deleteProfile();
                    }
                } else {
                    this.confirmDeleteProfileModal.show({ closable: false });
                }
            })
            .catch((err) => {
                this.attemptingDelete = false;
                this.notificationService.toast.error(
                    'Error',
                    "Custom Scan Profile Can't Load Tasks"
                );
            });
    }

    updateTasks() {
        let profile = null;
        if (
            this.profileSelected === 'lowimpact' ||
            this.profileSelected === 'standard' ||
            this.profileSelected === 'comprehensive'
        )
            profile = this.profileSelected;
        else profile = this.existingProfiles.find((res) => res.Uid === this.profileSelected);

        this.kvsService
            .updateTask(this.associatedScanTasks, profile, this.site.Id)
            .then((res) => {
                this.confirmDeleteProfileModal.hide();
                this.confirmCancelModal.hide();
                this.getProfile();
                this.deleting = false;
                this.notificationService.toast.success('Success', 'Custom Scan Profile Deleted');
            })
            .catch((err) => {});
    }

    showProfileEditModal(profile: any) {
        this.addProfileModal.showModal(true, profile);
    }

    confirmDeleteModal() {
        this.deleteProfile();
    }

    ngOnDestroy() {
        this.msgSub.unsubscribe();
    }
}
