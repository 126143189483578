<sds-loader [complete]="loadingComplete">
    <sds-breadcrumb-trail
        [crumbs]="breadcrumbs"
        *ngIf="dataExplorerType === 'indoc'"></sds-breadcrumb-trail>
    <header class="pageHeader">
        <h1>Computers</h1>

        <div>
            <span class="ui purple circular label">{{ numActive }}</span>
            Active&nbsp;&nbsp;
            <span class="ui olive circular label">{{ numEnabled }}</span>
            Enabled&nbsp;&nbsp;
            <span class="ui orange circular label">{{ computers.length }}</span>
            Total&nbsp;&nbsp;
        </div>

        <div class="item searchInput">
            <sds-data-search-filter
                [data]="computers"
                (filteredData)="onFiltered($event)"
                [fields]="['Name', 'Ip']"></sds-data-search-filter>
        </div>

        <div class="item">
            <sds-data-filter
                [data]="computers"
                [filterOptions]="filterOptions"
                (filteredData)="filteredComputers = $event; sortComputers()"></sds-data-filter>
        </div>
    </header>

    <itc-banner *ngIf="mergeInProgress" type="info">
        <strong>Database update is in progress.</strong>
        {{ computers?.length ? 'New scan' : 'Scan' }} data has been uploaded successfully. Check in
        a few minutes after database update is complete.
    </itc-banner>

    <div class="fullWidthContent">
        <table
            sortable-table
            class="itc-table sortable"
            id="computersTable"
            (sorted)="onSorted($event)">
            <thead>
                <tr>
                    <th sortable-column="Domain">Domain</th>
                    <th sortable-column="Name" sort-direction="asc">Name</th>
                    <th sortable-column="Ip">IP Address</th>
                    <th sortable-column="Os">OS</th>
                    <th sortable-column="LastLogin">Last Login</th>
                    <th sortable-column="Active">Active</th>
                    <th sortable-column="Enabled">Enabled</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let comp of pagedComputers" [routerLink]="comp.Id + '/detail'">
                    <td
                        class="{{
                            (!comp.Enabled ? 'font-grey' : '') ||
                                (comp.Active && comp.Enabled ? 'font-bold' : '')
                        }}">
                        {{ comp.Domain }}
                    </td>
                    <td
                        class="{{
                            (!comp.Enabled ? 'font-grey' : '') ||
                                (comp.Active && comp.Enabled ? 'font-bold' : '')
                        }}">
                        {{ comp.Name }}
                    </td>
                    <td
                        class="{{
                            (!comp.Enabled ? 'font-grey' : '') ||
                                (comp.Active && comp.Enabled ? 'font-bold' : '')
                        }} breakAll">
                        {{ comp.Ip }}
                    </td>
                    <td
                        class="{{
                            (!comp.Enabled ? 'font-grey' : '') ||
                                (comp.Active && comp.Enabled ? 'font-bold' : '')
                        }}">
                        {{ comp.Os }}
                    </td>
                    <td class="{{
                            (!comp.Enabled ? 'font-grey' : '') ||
                                (comp.Active && comp.Enabled ? 'font-bold' : '')
                        }}">
                        {{ comp.LastLoginFormatted == 'Never' ? 'Never' : (comp.LastLoginFormatted+'+00:00' | accountDateTime) }}
                    </td>
                    <td
                        class="{{
                            (!comp.Enabled ? 'font-grey' : '') ||
                                (comp.Active && comp.Enabled ? 'font-bold' : '')
                        }}">
                        {{ comp.Active ? 'Yes' : 'No' }}
                    </td>
                    <td
                        class="{{
                            (!comp.Enabled ? 'font-grey' : '') ||
                                (comp.Active && comp.Enabled ? 'font-bold' : '')
                        }}">
                        {{ comp.Enabled ? 'Yes' : 'No' }}
                    </td>
                </tr>
            </tbody>
        </table>
        <itc-paginator
            [data]="filteredComputers"
            (onPage)="pagedComputers = $event"
            [hidden]="!filteredComputers.length"></itc-paginator>

        <empty-state
            icon="results"
            title="No Computer Items Found"
            [text]="emptyStateText"
            *ngIf="!filteredComputers.length"></empty-state>
    </div>
</sds-loader>
