import { Notes } from '../../sites/shared/notes/notes.model';
import { ScanDataComputer } from './scan-data-computer.model';

export class ScanDataApplication {
    public Id: number;
    public Name: string;
    public Domain: string;
    public Version: string;
    public Computers: string;
    public ComputersList?: ScanDataComputer[];

    public Notes: Notes;
    public Procedures: Notes[];
    public RelatedItems: string[];
    public SmartTags: any[];
}
