/* Important note! To use this if it's wrapped in an <sds-loader> you must
   use the [hideVisibility]="true" attrinute or the chart will not render
   ex. <sds-loader [complete]="loadingComplete" [hideVisibility]="true">
*/

import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { WjFlexPie } from '@grapecity/wijmo.angular2.chart';
import { HitTestInfo } from '@grapecity/wijmo.chart';

@Component({
    selector: 'itc-legacy-donut-chart',
    templateUrl: './legacy-donut-chart.component.html',
    styleUrls: ['./legacy-donut-chart.component.scss'],
})
export class LegacyDonutChartComponent implements OnInit {
    @ViewChild('chart') chart: WjFlexPie;

    @Input() set data(value: any[]) {
        if (value && value.length) {
            this.chartData = [];
            this.chartColors = [];
            value.forEach((data) => {
                this.chartData.push({
                    bindingName: data.bindingName,
                    count: data.count,
                    hideFromLegend: data.hideFromLegend,
                });
                this.chartColors.push(this.forceRGBColor(data.color));
            });

            if (!value.reduce((t, i) => parseInt(i.count, 10) + t, 0)) {
                // everything is empty, add a placeholder item to at least show where the donut hsould be.
                this.emptyDonut = true;
                this.chartData.push({ bindingName: 'EmptyDonut', count: 1 });
                this.chartColors.push('rgba(218,218,218,1)');
            }
        }
    }
    @Input() tooltipFunc: (hti: HitTestInfo, chart: WjFlexPie) => string;
    @Input() centerTextLarge = '';
    @Input() legendSeparator = [false, false, false, false];
    @Input() centerTextSmall = '';
    @Input() overlapTextSmall = '';
    @Input() innerRadius = 0.7;
    @Input() showLegend = false;
    @Input() legendType = 'ovals'; // ovals || bars
    @Input() legendPosition = 'right'; // right || bottom
    @Input() diameter: number;
    @Input() palette: string[];
    //Changing the input to a number so we can easily add 100 inside due to wijmo bug
    @Input() centerTextOffset = 0;

    totalItems = 0;
    emptyDonut = false;
    loadingComplete = false;

    chartColors: string[] = [];
    chartData: Array<{ bindingName: string; count: number; hideFromLegend?: boolean }>;

    constructor() {}

    ngOnInit() {
        //Wijmo bug patch
        this.diameter = this.diameter + 20;
    }

    /* because we have an old version of wijmo we don't have the innertext property
    so this is to add the text in the middle of the donut gauge */
    chartRendered(sender, e) {
        if (this.centerTextLarge || this.centerTextSmall) {
            let el = e.engine.element.querySelector('g:last-of-type'),
                rect = el.getBoundingClientRect(),
                ptx = rect.left + rect.width / 2,
                pty = rect.top + rect.height / 2;

            if (this.centerTextLarge) {
                let str1 = this.centerTextLarge.toString();
                let str1Size = e.engine.measureString(str1, 'largeText');
                let str1pt = sender.pageToControl(ptx, pty);
                str1pt.x -= str1Size.width / 2;
                str1pt.y += str1Size.height / 2;
                if (this.centerTextSmall) {
                    str1pt.y -= str1Size.height / 3;
                } // has both lines so move position;
                str1pt.y -= this.centerTextOffset;
                e.engine.drawString(str1, str1pt, 'largeText');
            }

            if (this.centerTextSmall) {
                let str2 = this.centerTextSmall;
                let str2Size = e.engine.measureString(str2, 'smallText');
                let str2pt = sender.pageToControl(ptx, pty);
                str2pt.x -= str2Size.width / 2;
                str2pt.y += str2Size.height / 2;
                if (this.centerTextLarge) {
                    str2pt.y += str2Size.height / 2 + 2;
                } // has both lines so move position
                str2pt.y -= this.centerTextOffset;
                e.engine.drawString(str2, str2pt, 'smallText');
            }
        }

        this.loadingComplete = true;
    }

    // generate custom tooltip
    tooltipContent = (hti: HitTestInfo) => {
        if (this.emptyDonut) {
            return '';
        }

        let item = hti.item;

        if (typeof this.tooltipFunc === 'function') {
            return this.tooltipFunc(hti, this.chart);
        } else {
            return `
            <div class='itc-chart-tooltip' style='border-color:${
                this.chart.palette[hti.pointIndex]
            };'>
                ${item.bindingName}<span style='padding-left: 8px; font-weight: bold'>${
                item.count
            }</span>
            </div>
            `;
        }
    };

    // if you don't pass in rgba by default the wijmo donut chart will use that color as a border and make the fill
    // something like 70% opacity, so it'll show up more faint than it should, just force rgba when hex color code
    forceRGBColor(color, opacity = 1) {
        color = color.trim(); // for some reason it's passing in a space sometimes
        let rgbColor: any, colorArray: any;
        let hexRegExp = new RegExp(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/);

        if (hexRegExp.test(color)) {
            // valid hex
            color = color.match(hexRegExp)[1]; // remove #
            if (color.length == 3) {
                color
                    .split('')
                    .map((h) => h + h)
                    .join('');
            } // make 3 digit hex 6
            colorArray = color.match(/.{1,2}/g);
            rgbColor = {
                red: parseInt(colorArray[0], 16),
                green: parseInt(colorArray[1], 16),
                blue: parseInt(colorArray[2], 16),
            };
        } else if (color.indexOf('rgb') == 0) {
            // rgb/rgba
            colorArray = color.match(
                /rgba?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d(?:\.\d?))\))?/
            );
            rgbColor = { red: colorArray[1], green: colorArray[2], blue: colorArray[3] };
        } else {
            console.warn('Donut color needs to be valid hex/rgb/rgba');
            return color; // just return color so it doesn't break, but warn the dev
        }
        return `rgba(${rgbColor.red}, ${rgbColor.green}, ${rgbColor.blue}, ${opacity})`;
    }
}
