import { Injectable } from '@angular/core';

import { AuthHttp } from 'app/core/auth';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class LicenseService {
    private apiUrl: string;

    constructor(private http: AuthHttp) {
        this.apiUrl = environment.apiUrl;
    }

    getProvisioning(): Promise<any> {
        const url: string = this.apiUrl + `Provisioning`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getAvailableLicenses(siteLicenseType: string): Promise<any> {
        const url: string =
            this.apiUrl +
            (siteLicenseType
                ? `AvailableLicenses?siteLicenseType=${siteLicenseType}`
                : 'AvailableLicenses');
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getAvailableLicensesForSite(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/AvailableLicenses`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getSubscriptionPrice(model: string): Promise<any> {
        const url: string = this.apiUrl + `SubscriptionPrice?model=${model}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getSubscriptionOptions(
        licenseType: 'AG' | 'CH' | 'KVS' | 'CM2' | 'NDPRO' | '',
        currentLevel?: string,
        agppVersion?: number,
        limit250?: boolean
    ): any[] {
        let opts = [];
        if (!licenseType) return opts;

        if (agppVersion == 1) {
            opts = [
                { name: 'GDPR 50 (up to 50 computers)', level: 'gdpr50', model: 'GDPR 50' },
                { name: 'GDPR 100 (up to 100 computers)', level: 'gdpr100', model: 'GDPR 100' },
                { name: 'GDPR 250 (up to 250 computers)', level: 'gdpr250', model: 'GDPR 250' },
                { name: 'GDPR 500 (up to 500 computers)', level: 'gdpr500', model: 'GDPR 500' },
                { name: 'GDPR 1000 (up to 1000 computers)', level: 'gdpr1000', model: 'GDPR 1000' },
                //{ name: 'GDPR 2000 (up to 2000 computers)', level: 'gdpr2000', model: 'GDPR 2000' }
            ];
        } else if (agppVersion == 2) {
            if (licenseType == 'AG') {
                opts = [
                    { name: 'Up to 100 computers', level: 'ag100', model: 'GDPR 100' },
                    { name: 'Up to 250 computers', level: 'ag250', model: 'GDPR 250' },
                    { name: 'Up to 500 computers', level: 'ag500', model: 'GDPR 500' },
                    { name: 'Up to 1000 computers', level: 'ag1000', model: 'GDPR 1000' },
                    { name: 'Up to 2000 computers', level: 'ag2000', model: 'GDPR 2000' },
                ];
            } else if (licenseType == 'CH')
                opts = [
                    { name: 'Up to 100 computers', level: 'ch100', model: 'CH 100' },
                    { name: 'Up to 250 computers', level: 'ch250', model: 'CH 250' },
                    { name: 'Up to 500 computers', level: 'ch500', model: 'CH 500' },
                    { name: 'Up to 1000 computers', level: 'ch1000', model: 'CH 1000' },
                    { name: 'Up to 2000 computers', level: 'ch2000', model: 'CH 2000' },
                ];

            if (limit250) opts = opts.slice(0, 1);
        } else {
            if (licenseType == 'AG') {
                opts = [
                    { name: 'Up to 250 computers', level: 'ag250', model: 'GDPR 250' },
                    { name: 'Up to 500 computers', level: 'ag500', model: 'GDPR 500' },
                    { name: 'Up to 1000 computers', level: 'ag1000', model: 'GDPR 1000' },
                    { name: 'Up to 2000 computers', level: 'ag2000', model: 'GDPR 2000' },
                ];
            } else if (licenseType == 'CH')
                opts = [
                    { name: 'Up to 250 computers', level: 'ch250', model: 'CH 250' },
                    { name: 'Up to 500 computers', level: 'ch500', model: 'CH 500' },
                    { name: 'Up to 1000 computers', level: 'ch1000', model: 'CH 1000' },
                    { name: 'Up to 2000 computers', level: 'ch2000', model: 'CH 2000' },
                ];

            if (limit250) opts = opts.slice(0, 1);
        }

        if (currentLevel && !limit250) {
            let index = opts.findIndex((o) => o.level == currentLevel);
            opts.splice(0, index + 1);
        }

        return opts;
    }

    getLicenseCaps(): Promise<any> {
        const url: string = this.apiUrl + `Sites/LicenseCaps`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getLicenses(): Promise<any> {
        const url: string = this.apiUrl + `Sites/License`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getSiteLicense(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/License`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    upgradeSiteLicense(siteId: number, license: any, useExisting: boolean): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/License`;
        return this.http
            .post(url, { license: license }, { observe: 'response' })
            .toPromise()
            .then((res) => res);
    }

    getSiteABDSLicense(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/ABDSLicense`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getKVSLicenseValidation(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `ValidateKVSLicense/${siteId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getHostedEVSLicense(accountId: string): Promise<any> {
        const url: string = this.apiUrl + `GetHostedEVSLicense/${accountId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }
}
