import { Component, ElementRef, OnInit, Input, HostListener } from '@angular/core';

import { SortableTableComponent } from './sortable-table.component';

@Component({
    selector: '[sortable-column]',
    template: '<ng-content></ng-content>',
})
export class SortableColumnComponent implements OnInit {
    constructor(private elementRef: ElementRef) {}

    @Input('sortable-column') columnName: string;

    private sortDirection: 'asc' | 'desc' = 'asc';
    private activeColumn: boolean;
    private parentTable: SortableTableComponent;

    ngOnInit() {
        this.elementRef.nativeElement.classList.add('sortable-column');
    }

    @HostListener('click')
    sort() {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';

        if (this.parentTable)
            this.parentTable.updateSort({
                sortColumn: this.columnName,
                sortDirection: this.sortDirection,
            });
    }

    registerParent(parentTable: SortableTableComponent) {
        if (!parentTable)
            throw new Error('Error: registerParent called with null or undefined value!');
        else {
            this.parentTable = parentTable;
            if (this.parentTable.sortColumn == this.columnName) {
                this.sortDirection = this.parentTable.sortDirection == 'asc' ? 'asc' : 'desc';
                this.activeColumn = true;
                if (this.activeColumn)
                    this.elementRef.nativeElement.classList.add('sortBy', this.sortDirection);
            }
        }
    }

    setActive(active: boolean, sortDirection: 'asc' | 'desc') {
        this.activeColumn = active;
        this.sortDirection = sortDirection;

        // Update <th> classes
        this.elementRef.nativeElement.classList.remove('asc', 'desc', 'sortBy');

        if (this.activeColumn) {
            this.elementRef.nativeElement.classList.add('sortBy', this.sortDirection);
        }
    }
}
