import { Directive, Input, HostListener } from '@angular/core';
import { ItcInlineEditComponent } from './inline-edit.component';

@Directive({
    selector: '[editableOnEnter]',
})
export class EditableOnEnterDirective {
    constructor(private inlineedit: ItcInlineEditComponent) {}

    @HostListener('keyup.enter')
    onEnter() {
        this.inlineedit.toViewMode();
    }
}
