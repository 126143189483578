<sds-breadcrumb-trail [crumbs]="breadcrumbs"></sds-breadcrumb-trail>

<header class="pageHeader">
    <h1>Data Collectors</h1>
    <itc-tag type="subtle" color="orange">
        {{ count }}
    </itc-tag>

    <div class="item searchInput">
        <settings-search-filter
            [data]="filtered1"
            (filteredData)="filteredCollectors = $event; sortCollectors()"
            [fields]="['Id', 'SiteSearchString', 'Description']"></settings-search-filter>
    </div>

    <div class="ui labeled input">
        <sm-select [control]="filterCtrl" (modelChange)="filterCollectors()">
            <option value="all">All Data Collectors</option>
            <option *ngIf="accountwideTypes['Cyber Hawk']" value="Cyber Hawk">Cyber Hawk</option>
            <option *ngIf="accountwideTypes['Reporter']" value="Reporter">Reporter</option>
            <option *ngIf="accountwideTypes['Compliance Manager']" value="Compliance Manager">
                Compliance Manager
            </option>
            <option value="Remote Data Collector">Remote Data Collector</option>
            <option *ngIf="accountwideTypes['Vulnerability Scanner']" value="Vulnerability Scanner">
                VulScan
            </option>
            <option *ngIf="accountwideTypes['Discovery Agent']" value="Discovery Agent">
                Discovery Agent
            </option>
        </sm-select>
    </div>
    <div class="flex-right">
        <itc-button
            icon="fa-download"
            type="primary"
            [loading]="exporting"
            (onclick)="exportTable('Data Collectors')">
            Download
        </itc-button>
    </div>
</header>

<sds-loader [complete]="loadingComplete">
    <div class="overflowTable">
        <table
            class="itc-table sortable"
            *ngIf="(pagedCollectors && pagedCollectors.length) || !filteredCollectors.length"
            sortable-table
            sortColumn="dcId"
            sortDirection="asc"
            (sorted)="sortCollectors($event)">
            <thead>
                <tr>
                    <th sortable-column="status" class="status"></th>
                    <th sortable-column="dcId">Data Collector ID</th>
                    <th sortable-column="desc">Description</th>
                    <th sortable-column="type" style="min-width: 85px">Type</th>
                    <th sortable-column="dcType">Data Collector Type</th>
                    <th sortable-column="organization" style="min-width: 120px">Organization</th>
                    <th sortable-column="activated" style="min-width: 110px">Activated</th>
                    <th sortable-column="runningTasks" class="skip-export">Running Tasks</th>
                    <th sortable-column="queuedTasks" class="skip-export">Queued Tasks</th>
                    <th sortable-column="updateStatus">Update Status</th>
                    <th sortable-column="checkin">Last Check-in</th>
                    <th sortable-column="manage" style="min-width: 105px" class="skip-export">
                        Manage
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let dc of pagedCollectors">
                    <td class="status {{ dc?.Status ? dc.Status : 'N/A' }}">
                        <a>
                            <i
                                class="icon circle {{
                                    dc?.Status == 'Online'
                                        ? 'green'
                                        : dc?.Status == 'Offline'
                                        ? 'red'
                                        : 'grey'
                                }}"
                                data-title="{{
                                    dc?.Status == 'Online'
                                        ? 'Online'
                                        : dc?.Status == 'Offline'
                                        ? 'Offline'
                                        : 'Status Unknown'
                                }}"></i>
                        </a>
                    </td>
                    <td>{{ dc.Id }}</td>
                    <td>{{ dc.Description }}</td>
                    <td>{{ dc.Type }}</td>
                    <td>{{ dc.DCType }}</td>
                    <td>{{ dc.Organization }}</td>
                    <td>{{ dc.Activated }}</td>
                    <td class="skip-export">
                        {{ dc.RunningTasks == -1 ? 'N/A' : dc.RunningTasks }}
                    </td>
                    <td class="skip-export">{{ dc.QueuedTasks == -1 ? 'N/A' : dc.QueuedTasks }}</td>
                    <td>{{ dc.UpdateStatus }}</td>
                    <td>{{ dc.HasCheckedIn ? dc.TimezoneAdjustedTime : 'NEVER' }}</td>
                    <td class="skip-export">
                        <itc-button
                            type="icon"
                            icon="fa-pencil"
                            [tooltip]="dc.Status == 'Offline' ? 'Agent is offline' : 'Manage'"
                            [disabled]="dc.Status == 'Offline'"
                            (onclick)="showSelectSite(dc)"></itc-button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <itc-paginator
        [data]="filteredCollectors"
        (onPage)="pagedCollectors = $event"
        [hidden]="!filteredCollectors.length"></itc-paginator>
    <empty-state
        icon="results"
        title="No data collectors found"
        [text]="
            dataCollectors.length && !filteredCollectors.length
                ? 'No data collectors were found matching the given criteria.'
                : null
        "
        *ngIf="!filteredCollectors.length"></empty-state>

    <!-- Full Table for Downloading -->
    <div hidden>
        <table
            #dataCollectorsTable
            class="itc-table sortable"
            *ngIf="(sortedCollectors && sortedCollectors.length) || !filteredCollectors.length"
            sortable-table
            sortColumn="dcId"
            sortDirection="asc"
            (sorted)="sortCollectors($event)">
            <thead>
                <tr>
                    <th sortable-column="status" class="status"></th>
                    <th sortable-column="dcId">Data Collector ID</th>
                    <th sortable-column="type" style="min-width: 85px">Type</th>
                    <th sortable-column="dcType">Data Collector Type</th>
                    <th sortable-column="organization" style="min-width: 120px">Organization</th>
                    <th sortable-column="activated" style="min-width: 110px">Activated</th>
                    <th sortable-column="runningTasks" class="skip-export">Running Tasks</th>
                    <th sortable-column="queuedTasks" class="skip-export">Queued Tasks</th>
                    <th sortable-column="updateStatus">Update Status</th>
                    <th sortable-column="checkin">Last Check-in</th>
                    <th sortable-column="manage" style="min-width: 105px" class="skip-export">
                        Manage
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let dc of sortedCollectors">
                    <td class="status {{ dc?.Status ? dc.Status : 'N/A' }}">
                        <a>
                            <i
                                class="icon circle {{
                                    dc?.Status == 'Online'
                                        ? 'green'
                                        : dc?.Status == 'Offline'
                                        ? 'red'
                                        : 'grey'
                                }}"
                                data-title="{{
                                    dc?.Status == 'Online'
                                        ? 'Online'
                                        : dc?.Status == 'Offline'
                                        ? 'Offline'
                                        : 'Status Unknown'
                                }}"></i>
                        </a>
                    </td>
                    <td>{{ dc.Id }}</td>
                    <td>{{ dc.Type }}</td>
                    <td>{{ dc.DCType }}</td>
                    <td>{{ dc.Organization }}</td>
                    <td>{{ dc.Activated }}</td>
                    <td class="skip-export">
                        {{ dc.RunningTasks == -1 ? 'N/A' : dc.RunningTasks }}
                    </td>
                    <td class="skip-export">{{ dc.QueuedTasks == -1 ? 'N/A' : dc.QueuedTasks }}</td>
                    <td>{{ dc.UpdateStatus }}</td>
                    <td>{{ dc.HasCheckedIn ? dc.TimezoneAdjustedTime : 'Never' }}</td>
                    <td class="skip-export">
                        <itc-button
                            *ngIf="dc.IsAvailable"
                            type="icon"
                            icon="fa-pencil"
                            tooltip="Manage"
                            (onclick)="showSelectSite(dc)"></itc-button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <!-- End of Full Table for Downloading -->
</sds-loader>

<sm-modal title="Select Site" class="xs" [overrideOverflow]="true" #dcManager>
    <modal-content>
        <p>Drop down only includes Sites available in the Portal.</p>
        <form class="ui form">
            <div class="ui labeled input">
                <sm-select [control]="siteCtrl" (onChange)="siteSelected()" #siteSelect>
                    <option value="Select Site">Select Site</option>
                    <option *ngFor="let option of dcSites" value="{{ option.Name }}">
                        {{ option.Name }}
                    </option>
                </sm-select>
            </div>
        </form>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" class="close" (onclick)="closeDcManager()">Close</itc-button>
        <itc-button
            type="primary"
            [disabled]="siteCtrl.value == 'Select Site'"
            (onclick)="toDataCollector()">
            Manage Data Collector
        </itc-button>
    </modal-actions>
</sm-modal>
