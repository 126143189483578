import { Topic } from './topic.model';

export class Filter {
    keyWords: string;
    topics: Topic[];
    selectedTopic: string;
    isApplied: boolean = false;

    constructor() {
        this.topics = [];
    }
}
