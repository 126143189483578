import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MessageService {
    constructor() {}

    private subjects: any = {};

    on(key: string): Observable<any> {
        return this.getSubject(key).asObservable();
    }

    broadcast(key: string, data?: any) {
        let sub: Subject<any> = this.getSubject(key);
        sub.next(data);
    }

    clear(key: string) {
        if (this.subjects[key]) delete this.subjects[key];
    }

    private getSubject(key: string): Subject<any> {
        if (!this.subjects[key]) this.subjects[key] = new Subject<any>();
        return this.subjects[key] as Subject<any>;
    }
}
