import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { AssessmentService } from 'app/sites/shared/assessment.service';
import { AuthService } from 'app/core/auth/auth.service';
import { MessageService } from 'app/core/message.service';
import { Site } from 'app/sites/shared/site.model';
import { SITE, PING_SITE } from 'app/sites/shared/constants';
import { UiService } from 'app/core/ui.service';
import { NavService } from 'app/core/nav/nav.service';

export const ASSESSMENT: string = 'ASSESSMENT';
export const PING_ASSESSMENT: string = 'PING_ASSESSMENT';

const CURRENT_ASSESSMENT: string = '__current';

@Component({
    selector: 'sds-site-assessments',
    templateUrl: './site-assessments.component.html',
    styleUrls: ['./site-assessments.component.css'],
})
export class SiteAssessmentsComponent implements OnInit, OnDestroy {
    constructor(
        private assessmentService: AssessmentService,
        private authService: AuthService,
        private messageService: MessageService,
        private navService: NavService,
        private uiService: UiService
    ) {}

    @ViewChild('assessmentSelect') assessmentSelect: any;
    selectAssessmentCtrl: UntypedFormControl = new UntypedFormControl();
    assessments: string[] = [];
    loadingAssessments: boolean;

    currentAssessment: any;
    selectedAssessment: any;

    assessmentEmpty = false;

    // sidebarMenu: any[] = [
    //   /*{
    //     href: 'dashboard',
    //     icon: 'dashboard',
    //     label: 'Dashboard'
    //   },*/
    // ];

    site: Site;

    loadingComplete: boolean;
    msub: any;
    ssub: any;

    isOnlySME = false;
    UI_KEY: string;

    ngOnInit() {
        this.loadingAssessments = true;

        // On selectAssessmentCtrl value change
        this.selectAssessmentCtrl.valueChanges.subscribe((value) => {
            if (!this.site) return;

            if (value !== void 0) {
                //&& (value != this.uiService.getControlValue(this.UI_KEY))) {
                this.uiService.setControlValue(this.UI_KEY, value);

                if (value !== '') {
                    // We need to get the latest assessment either way, but we can ignore this call if value == current
                    if (!this.currentAssessment && value != CURRENT_ASSESSMENT) {
                        this.assessmentService
                            .getAssessment(this.site.Id, CURRENT_ASSESSMENT)
                            .then((assessment) => {
                                this.currentAssessment = assessment;
                            })
                            .catch(() => {});
                    }

                    // Get the selected assessment
                    this.assessmentService
                        .getAssessment(this.site.Id, value)
                        .then((assessment) => {
                            if (value == CURRENT_ASSESSMENT) this.currentAssessment = assessment;

                            this.selectedAssessment = assessment;
                            this.messageService.broadcast(ASSESSMENT, this.selectedAssessment);
                        })
                        .catch(() => {});
                }
            } else {
                this.messageService.broadcast(ASSESSMENT, null);
            }
        });

        // On SITE
        this.ssub = this.messageService.on(SITE).subscribe((site: Site) => {
            if (!site || site == this.site) return;

            this.site = site;
            this.UI_KEY = 'ASSESSMENT_SELECT_' + this.site.Id;

            this.loadingAssessments = true;

            this.isOnlySME = this.authService.isOnlySME();

            if (this.isOnlySME) {
                // this.sidebarMenu = [
                //   {
                //     href: 'forms',
                //     icon: 'checkmark box',
                //     label: 'Forms'
                //   }
                // ];

                // this.selectAssessmentCtrl.setValue(CURRENT_ASSESSMENT);
                // this.assessmentSelect.setSelected(CURRENT_ASSESSMENT);
                // setTimeout(() => {
                //   this.assessmentSelect.setSelected(CURRENT_ASSESSMENT);
                // }, 500);

                this.assessmentService
                    .getAssessment(this.site.Id, CURRENT_ASSESSMENT)
                    .then((assessment) => {
                        this.currentAssessment = assessment;
                        this.selectedAssessment = assessment;
                        this.messageService.broadcast(ASSESSMENT, this.selectedAssessment);
                    })
                    .catch(() => {});

                this.loadingAssessments = false;
            } else {
                this.assessmentService
                    .getAssessmentNames(this.site.Id)
                    .then((assessments) => {
                        console.log(assessments);
                        if (assessments.length) {
                            // this.sidebarMenu = [
                            //   {
                            //     href: 'dashboard',
                            //     icon: 'dashboard',
                            //     label: 'Dashboard'
                            //   },
                            //   {
                            //     href: 'forms',
                            //     icon: 'checkmark box',
                            //     label: 'Forms'
                            //   },
                            //   {
                            //     href: 'files',
                            //     icon: 'folder open',
                            //     label: 'Files'
                            //   },
                            //   {
                            //     href: 'reports',
                            //     icon: 'file alternate outline',
                            //     label: 'Reports'
                            //   },
                            //   {
                            //     href: 'issues',
                            //     icon: 'file alternate outline',
                            //     label: 'Issues'
                            //   }
                            // ];

                            this.assessments = assessments.sort((a, b) => {
                                return b.localeCompare(a);
                            });

                            let value =
                                this.uiService.getControlValue(this.UI_KEY) || CURRENT_ASSESSMENT;

                            this.selectAssessmentCtrl.setValue(value);
                            this.assessmentSelect.setSelected(value);
                            setTimeout(() => {
                                this.assessmentSelect.setSelected(value);
                            }, 500);
                            this.assessmentEmpty = false;
                            console.log('assessment not empty');
                        } else {
                            this.assessments = [];
                            this.selectAssessmentCtrl.setValue('');
                            delete this.currentAssessment;
                            this.assessmentEmpty = true;

                            this.messageService.broadcast(ASSESSMENT, { isEmpty: true });
                        }

                        this.loadingAssessments = false;
                    })
                    .catch((res) => {});
            }
        });

        this.msub = this.messageService.on(PING_ASSESSMENT).subscribe(() => {
            if (this.assessmentEmpty) {
                this.messageService.broadcast(ASSESSMENT, { isEmpty: true });
            } else {
                this.messageService.broadcast(ASSESSMENT, this.selectedAssessment);
            }
        });

        this.messageService.broadcast(PING_SITE);
    }

    ngOnDestroy() {
        this.msub.unsubscribe();
        this.ssub.unsubscribe();
    }

    downloadZip() {}
}
