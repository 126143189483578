import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SiteService } from 'app/sites/shared/site.service';
import { Site } from 'app/sites/shared/site.model';
import { PING_SITE, SITE } from 'app/sites/shared/constants';
import { Subscription } from 'rxjs';

import { MessageService } from 'app/core/message.service';
import { UiService } from 'app/core/ui.service';

import { ASSESSMENT, PING_ASSESSMENT } from '../site-assessments.component';
import { NotificationService } from '../../../../shared/itc/notification/notification.service';

@Component({
    selector: 'sds-assessment-dashboard',
    templateUrl: './assessment-dashboard.component.html',
    styleUrls: ['./assessment-dashboard.component.css'],
})
export class AssessmentDashboardComponent implements OnInit {
    isAssessmentStarted: boolean = false;
    isAssessmentStartable: boolean = false;
    site: Site;
    msgSub: Subscription;
    restartType: string;
    warningMessage: string;
    selectedAssessment: any;
    loadingComplete: boolean;
    isLoaded: boolean;
    processingRestart: boolean;
    aSub: any;
    bSub: any;
    cSub: any;
    scansRunning: boolean;
    canStart: boolean = true;

    breadcrumbs = [
        { path: '../..', text: 'Compliance Manager' },
        { path: '..', text: 'Assessments' },
        { path: '.', text: 'Dashboard' },
    ];

    constructor(
        private messageService: MessageService,
        private siteService: SiteService,
        private notificationService: NotificationService,
        private router: Router,
        private uiService: UiService
    ) {}

    ngOnInit() {
        this.loadingComplete = false;

        this.aSub = this.messageService.on(ASSESSMENT).subscribe((assessment) => {
            if (!assessment) return;

            this.isLoaded = true;
            this.loadingComplete = true;

            if (assessment.isEmpty) return;

            this.selectedAssessment = assessment;

            this.isAssessmentStarted = false;
            this.isAssessmentStartable = false;

            if (this.selectedAssessment && this.selectedAssessment.State == 'Started') {
                this.isAssessmentStarted = true;
            }

            if (
                this.selectedAssessment &&
                this.selectedAssessment.State == 'Archived' &&
                assessment.IsLatest
            ) {
                this.isAssessmentStartable = true;
            }

            if (this.isAssessmentStartable === true) {
                this.siteService.hasRunningAuditGuruScan(this.site.Id).then((res) => {
                    if (res) {
                        this.scansRunning = res;
                    }
                });
            }
        });

        this.msgSub = this.messageService.on(SITE).subscribe((site: Site) => {
            if (!site || site == this.site) return;
            //PREVENT ROLE PRESENCE
            if (site.UserRoles != null && site.UserRoles == 'AG_SME') {
                this.router.navigate([
                    'site',
                    site.Name.replace(/&/g, '%26'),
                    'compliance-manager',
                    'assessments',
                    'forms',
                ]);
            }
            if (site.UserRoles != null && site.UserRoles == 'IND_CLIENT') {
                this.router.navigateByUrl(`/sites`);
            }
            this.site = site;
            this.uiService.setTitle('Dashboard', site.Name);
            this.messageService.broadcast(PING_ASSESSMENT);
        });

        this.bSub = this.messageService.on('ASSESSMENT_RESTARTED').subscribe(() => {
            console.log('assessment_restarted');
            this.isLoaded = true;
            this.loadingComplete = true;
        });

        this.cSub = this.messageService.on('ASSESSMENT_RESTARTING').subscribe(() => {
            console.log('assessment_restarting');
            this.isLoaded = false;
            this.loadingComplete = false;
        });

        this.messageService.broadcast(PING_SITE);
    }

    ngOnDestroy() {
        this.msgSub.unsubscribe();
        this.aSub.unsubscribe();
        this.bSub.unsubscribe();
        this.cSub.unsubscribe();
    }

    startAssessment() {
        if (this.canStart) {
            this.canStart = false;
            this.siteService.createGdprTodo5(this.site.Id).then((s) => {
                this.notificationService.toast.success('Status', 'Assessment Started');
                let nextUrl: string = '/site/' + this.site.Name + '/compliance-manager/todo';
                this.router.navigateByUrl(nextUrl);
            });
        }
    }
}
