import { Injectable } from '@angular/core';

import { AuthHttp } from 'app/core/auth/auth.http';

import { environment } from 'environments/environment';

const PREFS: string = 'SDSP_USER_PREFERENCES';

@Injectable({ providedIn: 'root' })
export class PreferencesService {
    private apiUrl: string;

    constructor(private http: AuthHttp) {
        this.apiUrl = environment.apiUrl;
    }

    getPreferences(): Map<string, any> {
        let preferences = new Map<string, any>();

        let ps = localStorage.getItem(PREFS);
        if (ps) {
            let prefs = JSON.parse(ps);
            for (let key in prefs) {
                preferences.set(key, prefs[key]);
            }
        }

        return preferences;
    }

    getPreference(key: string): any {
        let prefs = this.getPreferences();
        return prefs.get(key);
    }

    savePreferences(preferences: Map<string, any>): Promise<any> {
        return new Promise((resolve, _) => {
            let prefs = {};
            let iter = preferences.keys(),
                p = '';
            do {
                p = iter.next().value;
                prefs[p] = preferences.get(p);
            } while (p);

            localStorage.setItem(PREFS, JSON.stringify(prefs));

            resolve(null);
        });
    }

    changePassword(oldPass: string, newPass: string): Promise<any> {
        const url: string = this.apiUrl + 'User/ChangePassword';

        return this.http
            .post(url, { oldPassword: oldPass, newPassword: newPass })
            .toPromise()
            .then((res) => res);
    }

    generateTwoFactorSecret(): Promise<string> {
        const url: string = this.apiUrl + `GenerateTOTPSecret`;
        return this.http
            .get(url)
            .toPromise()
            .then((secret: string) => {
                return secret;
            });
    }

    regenerateTwoFactorSecret(): Promise<string> {
        const url: string = this.apiUrl + `RegenerateTOTPSecret`;
        return this.http
            .get(url)
            .toPromise()
            .then((secret: string) => {
                return secret;
            });
    }

    enableTwoFactor(totp: string): Promise<any> {
        const url: string = this.apiUrl + `Enable2FA`;
        return this.http
            .post(url, { totp: totp })
            .toPromise()
            .then((res) => {
                // Set preference for TwoFactorLogin
                var prefs = this.getPreferences();
                prefs.set('AUTH_2FA', true);
                this.savePreferences(prefs).then(() => {});
                return res;
            })
            .catch((err) => {
                return err;
            });
    }

    disableTwoFactor(totp: string): Promise<any> {
        const url: string = this.apiUrl + `Disable2FA`;
        return this.http
            .post(url, { totp: totp })
            .toPromise()
            .then((res) => {
                // Unset preference for TwoFactorLogin
                var prefs = this.getPreferences();
                prefs.set('AUTH_2FA', false);
                this.savePreferences(prefs).then(() => {});
                return res;
            })
            .catch((err) => {
                return err;
            });
    }

    checkTOTP(): Promise<any> {
        const url: string = this.apiUrl + `User/IsTwoFactorEnabled`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res == 'true');
    }
}
