import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FormService } from 'app/core/form.service';
import { AuthService } from 'app/core/auth';
import { MessageService } from 'app/core/message.service';
import { TableSorting } from 'app/shared/sortable-table/sortable-table.component';
import { ControlsService } from 'app/sites/shared/controls/controls.service';
import { StandardsService } from 'app/sites/shared/standards/standards.service';
import { ClonedControl, Control } from 'app/sites/shared/controls/control.model';
import { Standard } from 'app/sites/shared/standards/standard.model';
import { Requirement } from 'app/sites/shared/standards/requirement.model';
import { I18N } from 'app/sites/shared/standards/i18n.model';
import { Site } from 'app/sites/shared/site.model';
import isEqual from 'lodash-es/isEqual';
import { GrcService } from 'app/sites/shared/grc.service';
import { Setting } from 'app/settings/shared/setting.model';
import { SettingService } from 'app/settings/shared/setting.service';
import { NotificationService } from '../../../../shared/itc/notification/notification.service';

declare var $: any;

@Component({
    selector: 'cm-control-editor-modal',
    templateUrl: './cm-control-editor-modal.component.html',
    styleUrls: ['./cm-control-editor-modal.component.scss'],
})
export class CmControlEditorModalComponent {
    constructor(
        private authService: AuthService,
        private messageService: MessageService,
        private formService: FormService,
        private notificationService: NotificationService,
        private controlsService: ControlsService,
        private standardsService: StandardsService,
        private cdr: ChangeDetectorRef,
        private grcService: GrcService,
        private settingService: SettingService
    ) {}

    siteLevel: number = 0;
    canSave: boolean = true;

    @Input() site: Site;

    @Input() standards: Standard[] = [];

    overlayMode: boolean = false;

    @Output() controlSaved: EventEmitter<Control> = new EventEmitter<Control>();
    @Output() controlCloned: EventEmitter<Control> = new EventEmitter<Control>();
    @Output() controlRefresh: EventEmitter<Control> = new EventEmitter<Control>();

    @ViewChild('addControl', { static: true }) addControl;
    @ViewChild('cloneControlModal', { static: true }) cloneControlModal;
    ctrlIdCtrl: UntypedFormControl = new UntypedFormControl();
    ctrlSDescriptionCtrl: UntypedFormControl = new UntypedFormControl();
    ctrlLDescriptionCtrl: UntypedFormControl = new UntypedFormControl();
    ctrlGuidanceCtrl: UntypedFormControl = new UntypedFormControl();
    procOptCtrl: UntypedFormControl = new UntypedFormControl();
    requirementControls: UntypedFormControl[] = [];
    searchRequirementsCtrl: UntypedFormControl = new UntypedFormControl();

    isReadOnly = false;

    stage: number = 1;
    loadingComplete: boolean = false;
    saving: boolean = false;

    filtered1: any[] = [];

    selectedControl = new Control();

    siteId: number = -1;

    languageCtrl: UntypedFormControl = new UntypedFormControl();
    languageMap: any = [
        { value: 'nl-NL', label: 'Dutch' },
        { value: 'en-US', label: 'English (US)' },
        { value: 'fr-FR', label: 'French' },
        { value: 'fr-CA', label: 'French (Canada)' },
        { value: 'de-DE', label: 'German' },
        { value: 'it-IT', label: 'Italian' },
        { value: 'pt-BR', label: 'Portuguese (Brasil)' },
        { value: 'es-ES', label: 'Spanish' },
    ];

    culture: string = 'en-US';
    standardDict = {};
    reqAll: StdDicEntry[] = [];
    selectedRequirements: Requirement[] = [];
    filteredRequirements: any[] = [];
    sortedRequirements: any[] = [];
    pagedRequirements: any[] = [];

    requirementsOnStart: any[] = [];

    addedFilteredRequirements: any[] = [];
    addedRequirements: any[] = [];
    modalDialogueTitle: string = '';

    addMode: boolean = false;
    procEditState: boolean[];
    procEditText: UntypedFormControl[];
    quickSelectCtrl: UntypedFormControl = new UntypedFormControl();
    defaultProcedure: I18N[] = [];
    oldProcedure: I18N[] = [];
    private isInit: boolean = false;

    user: any;

    reqFilterText: string = '';

    newCloneControl: ClonedControl;
    clonedControlIdField = new UntypedFormControl();
    clonedControlNameField = new UntypedFormControl();
    isCloning = false;
    clonedControlIdValid = true;
    clonedControlNameValid = true;
    allControls: Control[];
    controlIdExists = false;

    OnInit() {
        for (let i = 0; i < this.standards.length; i++) {
            if (this.standards[i].Requirements) {
                for (let j = 0; j < this.standards[i].Requirements.length; j++) {
                    this.standardDict[this.standards[i].Requirements[j].Id] = {
                        standard: this.standards[i],
                        requirement: this.standards[i].Requirements[j],
                    };
                }
            }
            this.reqAll = Object.values(this.standardDict);

            this.isInit = true;
        }
    }

    controlIsSystem(control: Control) {
        return false;
        // return control.AccountId == "SYSTEM";
    }

    startControlEditor(
        control: Control,
        allControls: Control[],
        readOnlyMode = false,
        overlayMode = false
    ) {
        console.log('this control', control);
        this.ctrlIdCtrl = new UntypedFormControl();
        this.ctrlSDescriptionCtrl = new UntypedFormControl();
        this.ctrlLDescriptionCtrl = new UntypedFormControl();
        this.ctrlGuidanceCtrl = new UntypedFormControl();
        this.procOptCtrl = new UntypedFormControl();
        this.requirementControls = [];
        this.searchRequirementsCtrl = new UntypedFormControl();
        this.allControls = allControls;
        this.overlayMode = overlayMode;
        this.controlIdExists = false;
        this.procEditState = [];
        this.procEditText = [];
        this.addMode = false;
        if (!this.isInit) {
            this.OnInit();
        }

        // added readonly because on standards&controls we want readonly on account controls too
        this.isReadOnly = readOnlyMode;
        // force readonly on IsSystem cards
        if (control && control.IsSystem && !overlayMode) {
            this.isReadOnly = true;
        }

        this.saving = false;
        if (control == null) {
            this.selectedControl = new Control();
        } else {
            control.Requirements = control.Requirements.filter((c) => {
                if (this.standardDict[c.Id]) return c;
            });

            this.selectedControl = JSON.parse(JSON.stringify(control));
            this.oldProcedure = JSON.parse(JSON.stringify(this.selectedControl.Procedures.slice()));
            this.procEditState = [];
            this.procEditText = [];
            this.selectedControl.Procedures.forEach((p) => {
                this.procEditState.push(false);
                let fc = new UntypedFormControl();
                fc.setValue(p.LocalizedText[this.culture]);
                this.procEditText.push(fc);
            });
            if (this.site) {
                this.controlsService.getDefaultProcedures(this.site.Id, control.Id).then((res) => {
                    this.defaultProcedure = res;
                });
            }
        }

        if (!this.selectedControl.ShortDesc.LocalizedText[this.culture])
            this.selectedControl.ShortDesc.LocalizedText[this.culture] = '';
        if (!this.selectedControl.LongDesc.LocalizedText[this.culture])
            this.selectedControl.LongDesc.LocalizedText[this.culture] = '';
        if (!this.selectedControl.Guidance.LocalizedText[this.culture])
            this.selectedControl.Guidance.LocalizedText[this.culture] = '';
        if (!this.selectedControl.Policy.LocalizedText[this.culture])
            this.selectedControl.Policy.LocalizedText[this.culture] = '';

        this.ctrlIdCtrl.setValue(this.selectedControl.ControlId);
        this.ctrlSDescriptionCtrl.setValue(
            this.selectedControl.ShortDesc.LocalizedText[this.culture]
        );
        this.ctrlLDescriptionCtrl.setValue(
            this.selectedControl.LongDesc.LocalizedText[this.culture]
        );
        this.ctrlGuidanceCtrl.setValue(this.selectedControl.Guidance.LocalizedText[this.culture]);
        this.languageCtrl.setValue(this.culture);
        this.procOptCtrl.setValue('');
        this.requirementsOnStart = JSON.parse(JSON.stringify(this.selectedControl.Requirements));
        this.stage = 1;

        if (this.selectedControl.Id === -1) {
            this.modalDialogueTitle = 'Add Control';
        } else if (this.selectedControl.IsSystem && !this.overlayMode) {
            this.modalDialogueTitle = 'View Control';
        } else {
            this.modalDialogueTitle = 'Edit Control';
        }

        console.log('selectedControl site: ' + this.selectedControl.SiteId);

        this.addControl.show({ closable: false });

        console.log('selectedcontrol', this.selectedControl);
    }

    closeControlEditor() {
        this.addControl.hide();
    }

    modalCancel() {
        if (this.saving) return;
        if (this.stage == 1) {
            if (this.selectedControl.Id == -1) {
                this.modalDialogueTitle = 'Add Control';
                if (
                    this.ctrlIdCtrl.value != '' ||
                    this.ctrlSDescriptionCtrl.value != '' ||
                    this.ctrlLDescriptionCtrl.value != '' ||
                    this.ctrlGuidanceCtrl.value != '' ||
                    this.selectedControl.Procedures.length > 0 ||
                    this.selectedControl.Requirements.length > 0
                ) {
                    this.stage = 3;
                } else {
                    this.closeControlEditor();
                    setTimeout(() => {
                        this.isReadOnly = false;
                    }, 500);
                }
                //this.closeControlEditor();
            } else {
                this.modalDialogueTitle = 'Edit Control';
                if (
                    this.ctrlIdCtrl.value != this.selectedControl.ControlId ||
                    this.ctrlSDescriptionCtrl.value !=
                        this.selectedControl.ShortDesc.LocalizedText[this.culture] ||
                    this.ctrlLDescriptionCtrl.value !=
                        this.selectedControl.LongDesc.LocalizedText[this.culture] ||
                    this.ctrlGuidanceCtrl.value !=
                        this.selectedControl.Guidance.LocalizedText[this.culture] ||
                    JSON.stringify(this.selectedControl.Procedures) !=
                        JSON.stringify(this.oldProcedure) ||
                    !isEqual(
                        this.requirementsOnStart.sort((a, b) => (a.Id > b.Id ? 1 : -1)),
                        this.selectedControl.Requirements.sort((a, b) => (a.Id > b.Id ? 1 : -1))
                    )
                ) {
                    this.stage = 3;
                } else this.closeControlEditor();
            }
        } else {
            if (this.selectedControl.Id == -1) this.modalDialogueTitle = 'Add Control';
            else this.modalDialogueTitle = 'Edit Control';
            this.stage--;
        }
        this.formService.clear('controlsForm');
    }

    clickedNo() {
        if (this.saving) return;
        if (this.stage == 3) {
            if (this.modalDialogueTitle == 'Add Requirements') this.stage = 2;
            else this.stage = 1;
        } else if (this.stage == 4) {
            this.stage = 1;
        }
    }

    clickedYes() {
        if (this.stage == 3) {
            if (this.modalDialogueTitle == 'Add Requirements') this.stage = 1;
            else this.closeControlEditor();
        } else if (this.stage == 4) {
            this.saving = true;
            this.controlsService
                .revertControlTextOverrides(this.site.Id, this.selectedControl.Id)
                .then((res) => {
                    this.controlSaved.emit(res);
                    this.closeControlEditor();
                    this.saving = false;
                });
        }
    }

    saveSelectedControl() {
        this.saving = true;
        console.log('this.ctrlIdCtrl.value: ' + this.ctrlIdCtrl.value);

        const valid = this.formService.validate('controlsForm');
        console.log('valid: ' + valid);
        if (valid) {
            this.selectedControl.ControlId = this.ctrlIdCtrl.value;
            this.selectedControl.ShortDesc.LocalizedText[this.culture] =
                this.ctrlSDescriptionCtrl.value;
            this.selectedControl.LongDesc.LocalizedText[this.culture] =
                this.ctrlLDescriptionCtrl.value;
            if (this.overlayMode) {
                if (
                    this.selectedControl.Guidance.LocalizedText[this.culture] ==
                    this.ctrlGuidanceCtrl.value
                )
                    this.selectedControl.Guidance = null;
                else
                    this.selectedControl.Guidance.LocalizedText[this.culture] =
                        this.ctrlGuidanceCtrl.value;

                if (
                    JSON.stringify(this.selectedControl.Procedures) ==
                    JSON.stringify(this.oldProcedure)
                ) {
                    this.selectedControl.Procedures = null;
                } else {
                    this.setCustomPnPCooperFlag();
                }

                this.controlsService
                    .updateControlTextOverrides(this.site.Id, this.selectedControl)
                    .then((res) => {
                        this.selectedControl = res;
                        this.controlSaved.emit(this.selectedControl);
                        this.closeControlEditor();
                    });
            } else {
                this.selectedControl.Guidance.LocalizedText[this.culture] =
                    this.ctrlGuidanceCtrl.value;
                if (this.selectedControl.Id === -1) {
                    this.controlsService.addControl(this.selectedControl).then((control) => {
                        this.selectedControl.Id = control.Id;
                        if (this.site == null) {
                            this.controlsService
                                .addAccountControl(this.selectedControl.Id)
                                .then(() => {
                                    this.controlSaved.emit(this.selectedControl);
                                    this.closeControlEditor();
                                });
                        } else {
                            this.selectedControl.SiteId = this.site.Id;
                            this.controlsService
                                .addSiteControl(
                                    this.selectedControl.Id,
                                    this.selectedControl.SiteId
                                )
                                .then(() => {
                                    this.controlSaved.emit(this.selectedControl);
                                    this.closeControlEditor();
                                });
                        }
                    });
                } else {
                    console.log('control siteid: ' + this.selectedControl.SiteId);
                    if (this.site == null || this.selectedControl.SiteId > 0) {
                        this.controlsService.updateControl(this.selectedControl).then((res) => {
                            this.controlSaved.emit(res);
                            this.closeControlEditor();
                            this.controlRefresh.emit();
                        });
                    } else {
                        this.selectedControl.Id = -1;
                        this.selectedControl.ShortDesc.Id = -1;
                        this.selectedControl.LongDesc.Id = -1;
                        this.selectedControl.Guidance.Id = -1;
                        this.selectedControl.Policy.Id = -1;
                        this.selectedControl.Procedures.forEach((p) => (p.Id = -1));
                        this.selectedControl.Requirements.forEach((r) => (r.Id = -1));

                        this.controlsService.addControl(this.selectedControl).then((res) => {
                            this.controlSaved.emit(res);
                            this.controlsService.addSiteControl(res.Id, this.site.Id).then(() => {
                                this.closeControlEditor();
                            });
                        });
                    }
                }
            }
        } else {
            this.notificationService.toast.error('Error', 'Please fill required fields.');
            this.saving = false;
        }

        this.culture = 'en-US';
    }

    changeLanguage(event) {
        //update previous culture on change so it's saved between language changes
        this.selectedControl.ShortDesc.LocalizedText[this.culture] =
            this.ctrlSDescriptionCtrl.value;
        this.selectedControl.LongDesc.LocalizedText[this.culture] = this.ctrlLDescriptionCtrl.value;
        this.selectedControl.Guidance.LocalizedText[this.culture] = this.ctrlGuidanceCtrl.value;
        this.selectedControl.Policy.LocalizedText[this.culture] = '';
        console.log(this.culture);

        this.culture = event;
        this.languageCtrl.setValue(this.culture);

        if (!this.selectedControl.ShortDesc.LocalizedText[this.culture])
            this.selectedControl.ShortDesc.LocalizedText[this.culture] = '';
        if (!this.selectedControl.LongDesc.LocalizedText[this.culture])
            this.selectedControl.LongDesc.LocalizedText[this.culture] = '';
        if (!this.selectedControl.Guidance.LocalizedText[this.culture])
            this.selectedControl.Guidance.LocalizedText[this.culture] = '';
        if (!this.selectedControl.Policy.LocalizedText[this.culture])
            this.selectedControl.Policy.LocalizedText[this.culture] = '';
        this.ctrlSDescriptionCtrl.setValue(
            this.selectedControl.ShortDesc.LocalizedText[this.culture]
        );
        this.ctrlLDescriptionCtrl.setValue(
            this.selectedControl.LongDesc.LocalizedText[this.culture]
        );
        this.ctrlGuidanceCtrl.setValue(this.selectedControl.Guidance.LocalizedText[this.culture]);
    }

    addOption() {
        if (this.procOptCtrl.value === null || this.procOptCtrl.value.match(/^ *$/) !== null) {
            this.notificationService.toast.error('Error', 'Blank options cannot be added.');
        } else {
            let optObj = {
                Id: -1,
                LocalizedText: {},
            };
            optObj.LocalizedText['en-US'] = this.procOptCtrl.value;
            this.selectedControl.Procedures.push(optObj);
            this.procEditState.push(false);
            let fc = new UntypedFormControl();
            fc.setValue(this.procOptCtrl.value);
            this.procEditText.push(fc);
            this.procOptCtrl.setValue('');
            this.addMode = false;
        }
    }

    deleteOption(opt: I18N, index: number) {
        if (opt.Id == -1) {
            this.selectedControl.Procedures.splice(index, 1);
            this.procEditState.splice(index, 1);
            this.procEditText.splice(index, 1);
        } else {
            for (let i = 0; i < this.selectedControl.Procedures.length; i++) {
                if (this.selectedControl.Procedures[i].Id == opt.Id) {
                    this.selectedControl.Procedures.splice(i, 1);
                    this.procEditState.splice(index, 1);
                    this.procEditText.splice(index, 1);
                    break;
                }
            }
        }
    }
    editOption(index: number) {
        this.procEditText[index].setValue(
            this.selectedControl.Procedures[index].LocalizedText[this.culture]
        );
        this.procEditState[index] = true;
    }
    saveOption(index: number) {
        if (
            this.procEditText[index].value === null ||
            this.procEditText[index].value.match(/^ *$/) !== null
        ) {
            this.notificationService.toast.error('Error', 'Blank options cannot be added.');
        } else {
            this.selectedControl.Procedures[index].LocalizedText[this.culture] =
                this.procEditText[index].value;
            this.procEditState[index] = false;
        }
    }

    goRequirements() {
        if (this.selectedControl.Id == -1) this.modalDialogueTitle = 'Add Requirement';
        else this.modalDialogueTitle = 'Add Requirement';
        this.filterRequirements();
        this.selectedRequirements = [];
        document.querySelector('.ui.modal.active.visible .content').scrollTop = 0;
        this.stage = 2;
    }

    deleteRequirement(requirement: Requirement, index: number) {
        if (
            confirm(
                'Delete ' +
                    requirement.RequirementId +
                    ' mapping? This action will only complete upon saving the control.'
            )
        ) {
            this.selectedControl.Requirements = this.selectedControl.Requirements.filter(
                (req) => req.Id !== requirement.Id
            );

            this.addedRequirements = this.addedRequirements.filter(
                (req) => req.Id !== requirement.Id
            );
        }
    }

    toggleSelectedRequirement(requirementId: number) {
        var selectedIndex = this.selectedRequirements.findIndex((x) => x.Id == requirementId);
        console.log(this.requirementControls, requirementId);
        if (this.requirementControls[requirementId].value) {
            this.selectedRequirements.push(this.standardDict[requirementId].requirement);
        } else {
            if (selectedIndex >= 0) {
                this.selectedRequirements.splice(selectedIndex, 1);
            }
        }
    }

    saveSelectedRequirements() {
        this.selectedControl.Requirements = this.selectedControl.Requirements.concat(
            this.selectedRequirements
        );
        if (this.selectedControl.Id == -1) this.modalDialogueTitle = 'Add Control';
        else this.modalDialogueTitle = 'Edit Control';
        this.stage = 1;
        this.notificationService.toast.success(
            'Success ',
            this.selectedRequirements.length.toString() + ' requirements successfully added'
        );
    }

    filterRequirements(filteredData?: any) {
        if (filteredData) this.filteredRequirements = filteredData;
        else this.filteredRequirements = Object.values(this.standardDict);
        this.filteredRequirements = this.checkFilter2();
        this.filtered1 = this.filteredRequirements;
        this.sortRequirements();
    }

    checkFilter2() {
        let filtered: any[] = [];
        for (let req of this.filteredRequirements) {
            if (
                this.selectedControl.Requirements.findIndex((x) => x.Id == req.requirement.Id) == -1
            ) {
                filtered.push(req);
                let tmpFc: UntypedFormControl = new UntypedFormControl();
                this.requirementControls[req.requirement.Id] = tmpFc;
            }
        }
        return filtered;
    }

    searchRequirements() {
        let val: string = this.reqFilterText.toLowerCase();
        console.log(val);
        let start = Date.now();
        let newList: StdDicEntry[] = [];
        this.reqAll.forEach((fr) => {
            if (fr.requirement.RequirementId.toLowerCase().includes(val)) {
                newList.push(fr);
            } else if (fr.standard.Name.toLowerCase().includes(val)) {
                newList.push(fr);
            } else if (fr.standard.Variant.toLowerCase().includes(val)) {
                newList.push(fr);
            } else {
                let sdval = fr.requirement.ShortDesc.LocalizedText[this.culture];
                if (sdval) {
                    if (sdval.toLowerCase().includes(val)) {
                        newList.push(fr);
                    }
                }
            }
        });

        this.filterRequirements(newList);
        console.log('lapsed time ' + (Date.now() - start));
    }

    sortRequirements(sorting?: TableSorting) {
        if (!sorting) {
            this.sortedRequirements = this.filteredRequirements.sort(
                (a, b) =>
                    a.standard.Name.toLowerCase().localeCompare(
                        b.standard.Name.toLowerCase().toLowerCase()
                    ) ||
                    a.standard.Variant.toLowerCase().localeCompare(
                        b.standard.Variant.toLowerCase()
                    ) ||
                    a.requirement.RequirementId.toLowerCase().localeCompare(
                        b.requirement.RequirementId.toLowerCase()
                    )
            );
        } else if (sorting.sortColumn == 'Complex') {
            this.sortedRequirements = this.filteredRequirements.sort(
                (a, b) =>
                    a.standard.Name.toLowerCase().localeCompare(
                        b.standard.Name.toLowerCase().toLowerCase()
                    ) ||
                    a.standard.Variant.toLowerCase().localeCompare(
                        b.standard.Variant.toLowerCase()
                    ) ||
                    a.requirement.RequirementId.toLowerCase().localeCompare(
                        b.requirement.RequirementId.toLowerCase()
                    )
            );
        } else {
            /*   if (!sorting)
      this.sortedRequirements = this.filteredRequirements.sort((a, b) => (a.requirement.RequirementId.toLowerCase().localeCompare(b.requirement.RequirementId.toLowerCase()))); */
            switch (sorting.sortColumn) {
                case 'Name':
                    this.sortedRequirements = this.filteredRequirements.sort((a, b) =>
                        a.standard.Name.toLowerCase().localeCompare(b.standard.Name.toLowerCase())
                    );
                    break;
                case 'Variant':
                    this.sortedRequirements = this.filteredRequirements.sort((a, b) =>
                        a.standard.Name.toLowerCase().localeCompare(b.standard.Name.toLowerCase())
                    );
                    break;
                case 'RequirementId':
                    this.sortedRequirements = this.filteredRequirements.sort((a, b) =>
                        a.requirement.RequirementId.toLowerCase().localeCompare(
                            b.requirement.RequirementId.toLowerCase()
                        )
                    );
                    break;
                case 'ShortDesc':
                    this.sortedRequirements = this.filteredRequirements.sort((a, b) =>
                        a.requirement.ShortDesc.LocalizedText[this.culture]
                            .toLowerCase()
                            .localeCompare(
                                b.requirement.ShortDesc.LocalizedText[this.culture].toLowerCase()
                            )
                    );
                    break;
                default:
                    this.sortedRequirements = this.filteredRequirements.sort((a, b) =>
                        a.requirement.RequirementId.toLowerCase().localeCompare(
                            b.requirement.RequirementId.toLowerCase()
                        )
                    );
                    break;
            }

            if (sorting.sortDirection == 'desc')
                //descending
                this.sortedRequirements.reverse();
        }

        this.filteredRequirements = [...this.sortedRequirements];
    }

    sortControlRequirements(sorting?: TableSorting) {
        if (sorting.sortColumn == 'Complex' && this.selectedControl.Requirements.length > 0) {
            this.selectedControl.Requirements = this.selectedControl.Requirements.sort(
                (a, b) =>
                    this.standardDict[a.Id].standard.Name.toLowerCase().localeCompare(
                        this.standardDict[b.Id].standard.Name.toLowerCase()
                    ) ||
                    this.standardDict[a.Id].standard.Variant.toLowerCase().localeCompare(
                        this.standardDict[b.Id].standard.Variant.toLowerCase()
                    ) ||
                    a.RequirementId.toLowerCase().localeCompare(b.RequirementId.toLowerCase())
            );
        } else {
            switch (sorting.sortColumn) {
                case 'Name':
                    this.selectedControl.Requirements = this.selectedControl.Requirements.sort(
                        (a, b) =>
                            this.standardDict[a.Id].standard.Name.toLowerCase().localeCompare(
                                this.standardDict[b.Id].standard.Name.toLowerCase()
                            )
                    );
                    break;
                case 'Variant':
                    this.selectedControl.Requirements = this.selectedControl.Requirements.sort(
                        (a, b) =>
                            this.standardDict[a.Id].standard.Variant.toLowerCase().localeCompare(
                                this.standardDict[b.Id].standard.Variant.toLowerCase()
                            )
                    );
                    break;
                case 'RequirementId':
                    this.selectedControl.Requirements = this.selectedControl.Requirements.sort(
                        (a, b) =>
                            a.RequirementId.toLowerCase().localeCompare(
                                b.RequirementId.toLowerCase()
                            )
                    );
                    break;
                case 'ShortDesc':
                    this.selectedControl.Requirements = this.selectedControl.Requirements.sort(
                        (a, b) =>
                            a.ShortDesc.LocalizedText[this.culture]
                                .toLowerCase()
                                .localeCompare(
                                    b.ShortDesc.LocalizedText[this.culture].toLowerCase()
                                )
                    );
                    break;
                default:
                    this.selectedControl.Requirements = this.selectedControl.Requirements.sort(
                        (a, b) =>
                            a.RequirementId.toLowerCase().localeCompare(
                                b.RequirementId.toLowerCase()
                            )
                    );
                    break;
            }

            if (sorting.sortDirection == 'desc') this.selectedControl.Requirements.reverse();
            //descending
        }

        this.selectedControl.Requirements = [...this.selectedControl.Requirements];
    }

    cloneControl(
        clonedControl: ClonedControl,
        allControls: Control[],
        isSite?: boolean,
        siteID?: number
    ) {
        this.newCloneControl = clonedControl;
        this.allControls = allControls;
        this.clonedControlIdValid = true;
        this.clonedControlNameValid = true;
        this.siteId = isSite ? siteID : -1;
        this.clonedControlIdField.setValue(this.newCloneControl.ControlId);
        this.clonedControlNameField.setValue(this.newCloneControl.ShortDesc);
        this.validateClonedControlId();
        this.validateClonedControlName();
        this.cloneControlModal.show({ closable: false });
    }

    validateClonedControlId() {
        let val = this.clonedControlIdField.value.toLowerCase().trim();
        this.clonedControlIdValid = !this.allControls.some((c) => c.ControlId.toLowerCase() == val);
    }

    validateClonedControlName() {
        let val = this.clonedControlNameField.value.toLowerCase().trim();
        this.clonedControlNameValid = !this.allControls.some(
            (c) => c.ShortDesc.LocalizedText[this.culture].toLowerCase() == val
        );
    }

    validateControlId() {
        let val = this.ctrlIdCtrl.value.toLowerCase().trim();
        this.controlIdExists = this.allControls
            .filter((c) => c.Id !== this.selectedControl.Id)
            .some((c) => c.ControlId.toLowerCase().trim() === val);
        this.canSave = !this.controlIdExists;
    }

    async cloneSelectedControl() {
        this.isCloning = true;
        const clonedControl = await this.controlsService.cloneControl(
            this.newCloneControl.OrigId,
            this.clonedControlIdField.value,
            this.clonedControlNameField.value,
            this.culture,
            this.siteId
        );
        this.controlCloned.emit(clonedControl);
        this.cloneControlModal.hide();
        this.isCloning = false;
    }

    quickSelectProcedure(event) {
        this.procOptCtrl.setValue(event);
        this.quickSelectCtrl.setValue(null);
    }

    setCustomPnPCooperFlag() {
        let sets: Setting[] = [];
        sets.push({
            AccountId: this.site.AccountId,
            Name: 'COOPER_CUSTOM_PNP',
            Value: 'TRUE',
        });

        this.settingService
            .updateSettings(sets)
            .then(() => {})
            .catch(() => {});
    }

    // startClone(control: Control) {
    //   this.selectedControl = control;
    //   this.clonedControlId.setValue(this.selectedControl.ControlId);
    //   this.clonedControlName.setValue(this.selectedControl.ShortDesc.LocalizedText[this.culture]);
    //   this.clonedControlIdValid = true;
    //   this.clonedControlNameValid = true;
    //   if (this.controls.findIndex((e) => {
    //     return e.ShortDesc.LocalizedText[this.culture] == this.clonedControlName.value || e.ControlId == this.clonedControlId.value
    //   }) > -1) {
    //     var temp = this.clonedControlName.value;
    //     var tempId = this.clonedControlId.value;
    //     let count = 0;
    //     while (true) {
    //       this.clonedControlName.setValue(temp + ((count == 0) ? ' (copy)' : ' (copy -' + count + ')'));
    //       this.clonedControlId.setValue(tempId + ((count == 0) ? ' (copy)' : ' (copy -' + count + ')'));
    //       let i = this.controls.findIndex((e) => {
    //         return e.ShortDesc.LocalizedText[this.culture] == this.clonedControlName.value || e.ControlId == this.clonedControlId.value
    //       })
    //       if (i == -1) { break; }
    //       count++;
    //     }
    //   }
    //   this.cloneControl.show({ closable: false });
    // }
}

export class StdDicEntry {
    standard: Standard;
    requirement: Requirement;
}
