<!-- wrapping in span to allow title even when button is disabled -->
<span [attr.title]="title || ''">
    <a
        *ngIf="link != undefined"
        href="javascript:"
        class="ui button {{ class }} {{ color || '' }}"
        [class.labeled]="(presetContent || label) && icon"
        [class.icon]="icon || internalIcon"
        [class.loading]="loading"
        [class.disabled]="disabled"
        (click)="click($event)"
        [routerLink]="link"
        [queryParams]="params">
        <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
    </a>

    <a
        *ngIf="link == undefined"
        href="javascript:"
        class="ui button {{ class }} {{ color || '' }}"
        [class.labeled]="(presetContent || label) && icon"
        [class.icon]="icon || internalIcon"
        [class.loading]="loading"
        [class.disabled]="disabled"
        (click)="click($event)">
        <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
    </a>
</span>

<ng-template #buttonContent>
    <i *ngIf="icon && !loading" class="{{ icon }} icon {{ color ? '' : 'button' }}"></i>
    <!--<span #innerContent>
    <ng-content *ngIf="!presetContent"></ng-content>
    <span *ngIf="presetContent">{{presetContent}}</span>
  </span>-->
    <span *ngIf="presetContent || label">{{ presetContent || label }}</span>
</ng-template>
