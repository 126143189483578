import { Component, OnInit } from '@angular/core';
import { PrintService } from 'app/sites/shared/print.service';
import { AllPoliciesInfo } from './allpolicies.model';

@Component({
    selector: 'sds-printable-all-policies',
    templateUrl: './printable-all-policies.component.html',
    styleUrls: ['./printable-all-policies.component.scss'],
})
export class PrintableAllPoliciesComponent implements OnInit {
    allPoliciesInfo: AllPoliciesInfo;
    constructor(private printService: PrintService) {}

    ngOnInit() {
        this.allPoliciesInfo = this.printService.AllPoliciesData;

        Promise.resolve(this.allPoliciesInfo).then(() => {
            setTimeout(() => {
                this.printService.onDataReady();
            }, 500);
        });
    }
}
