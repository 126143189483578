import { Component, ElementRef, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { MessageService } from 'app/core/message.service';
import { Site } from 'app/sites/shared/site.model';
import { SITE, PING_SITE } from 'app/sites/shared/constants';
import { SiteSettingService, ReportPreference } from '../site-settings.service';
import { SettingService } from 'app/settings';
import { ToastService } from 'app/core/toast';
import { UiService } from 'app/core/ui.service';
import { AuthService } from 'app/core/auth/auth.service';
import { SiteService } from '../../../shared/site.service';
import {
    ReportTheme,
    CustomTheme,
    DefaultTheme,
    Helvetica,
    HelveticaEarth,
    ModernBlaze,
    ModernBold,
    ModernGraphite,
    ModernOceanic,
    ModernOptimism,
    ModernRoyal,
    ModernSerenity,
    TimesNewRoman,
    OptimaBlue,
    TahomaBlack,
} from './themes';
import { Setting } from 'app/settings/shared/setting.model';
import { environment } from 'environments/environment';
import { DragScrollComponent } from 'ngx-drag-scroll';
import { HttpClient } from '@angular/common/http';

const THEMES: any = [
    { id: 'basic', name: 'Basic' },
    { id: 'basic2', name: 'Basic2' },
    { id: 'classic', name: 'Classic' },
    { id: 'classic2', name: 'Classic2' },
    { id: 'contemporary', name: 'Contemporary' },
    { id: 'modern', name: 'Modern' },
    { id: 'modern2', name: 'Modern2' },
    { id: 'professional', name: 'Professional' },
];

@Component({
    selector: 'sds-report-preferences',
    templateUrl: './report-preferences-old.component.html',
    styleUrls: ['./report-preferences-old.component.css'],
})
export class ReportPreferencesOldComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('nav', { read: DragScrollComponent, static: true }) ds: DragScrollComponent;
    @ViewChild('previewModal', { static: true }) previewModal: any;

    coverImageList = [
        '/assets/img/cover_images/automotive.jpg',
        '/assets/img/cover_images/coverimg1.jpg',
        '/assets/img/cover_images/hipaa_cover_2.jpg',
        '/assets/img/cover_images/hipaa_cover_3.jpg',
        '/assets/img/cover_images/hipaa_cover_4.jpg',
        '/assets/img/cover_images/hipaa_cover_5.jpg',
        '/assets/img/cover_images/hipaa_cover_7.jpg',
        '/assets/img/cover_images/hipaa_cover_8.jpg',
        '/assets/img/cover_images/hospitality.jpg',
        '/assets/img/cover_images/legal.jpg',
        '/assets/img/cover_images/manufacturing.jpg',
        '/assets/img/cover_images/medical.jpg',
        '/assets/img/cover_images/mod_exchange1.jpg',
        '/assets/img/cover_images/mod_exchange2.jpg',
        '/assets/img/cover_images/mod_exchange3.jpg',
        '/assets/img/cover_images/mod_network1.jpg',
        '/assets/img/cover_images/mod_network2.jpg',
        '/assets/img/cover_images/mod_network3.jpg',
        '/assets/img/cover_images/mod_network4.jpg',
        '/assets/img/cover_images/mod_security1.jpg',
        '/assets/img/cover_images/mod_security2.jpg',
        '/assets/img/cover_images/mod_security3.jpg',
        '/assets/img/cover_images/retail.jpg',
    ];
    coverPageList = [
        { id: 'basic', title: 'Basic' },
        { id: 'basic2', title: 'Basic2' },
        { id: 'classic', title: 'Classic' },
        { id: 'classic2', title: 'Classic2' },
        { id: 'contemporary', title: 'Contemporary' },
        { id: 'modern', title: 'Modern' },
        { id: 'modern2', title: 'Modern2' },
        { id: 'professional', title: 'Professional' },
        { id: 'tech2', title: 'Tech2' },
    ];

    languages = {
        'nl-NL': 'Dutch',
        'en-US': 'English (US)',
        'fr-FR': 'French',
        //'fr-CA': 'French (Canada)',
        'de-DE': 'German',
        'it-IT': 'Italian',
        //'pt-BR': 'Portuguese (Brasil)',
        'es-ES': 'Spanish',
    };

    isEnglishOnly = false;

    thColorSchemes = [
        { name: 'Standard', c1: '#0044CE', c2: '#BF4435', c3: '#72DDFC' },
        { name: 'Standard2', c1: '#003399', c2: '#800000', c3: '#008000' },
        { name: 'Blue', c1: '#0000FF', c2: '#A6CAF0', c3: '#6666CC' },
        { name: 'Red', c1: '#FF0000', c2: '#FFCCCC', c3: '#FF7C80' },
        { name: 'Green', c1: '#336633', c2: '#66FF66', c3: '#33CC66' },
        { name: 'Neutral', c1: '#CCCC66', c2: '#CCCC99', c3: '#FFFFCC' },
        { name: 'Violet', c1: '#333366', c2: '#666699', c3: '#9999FF' },
        { name: 'Purple', c1: '#660066', c2: '#663399', c3: '#9933CC' },
        { name: 'Fruity', c1: '#99CCCC', c2: '#FF6699', c3: '#FFFFCC' },
        { name: 'Sunny', c1: '#FF3366', c2: '#FF6633', c3: '#FFCC33' },
        { name: 'Camo', c1: '#666633', c2: '#339900', c3: '#999900' },
        { name: 'Triad', c1: '#3333CC', c2: '#008000', c3: '#663300' },
        { name: 'Triad2', c1: '#003366', c2: '#CC0000', c3: '#CCCC33' },
        { name: 'Triad3', c1: '#6600CC', c2: '#CC9933', c3: '#006633' },
        { name: 'Triad4', c1: '#3333CC', c2: '#CC3300', c3: '#99FF33' },
    ];
    thCustomCS = ['#0044CE', '#BF4435', '#72DDFC'];
    thUseCustomCS: boolean = false;
    thPreDefinedChoice = 'standard';
    thCSName: string = '';
    thDocStyle: any = null;

    themeList = [
        new DefaultTheme(),
        new HelveticaEarth(),
        new Helvetica(),
        new ModernBlaze(),
        new ModernBold(),
        new ModernGraphite(),
        new ModernOceanic(),
        new ModernOptimism(),
        new ModernRoyal(),
        new ModernSerenity(),
        new OptimaBlue(),
        new TahomaBlack(),
        new TimesNewRoman(),
    ];
    themeCtrl: UntypedFormControl = new UntypedFormControl();
    @ViewChild('themeSelect', { static: true }) themeSelect: any;

    thCoverPage: string;
    thTheme: ReportTheme;

    previewCover = { url: '', title: '', id: '' };

    breadcrumbs = [
        { path: '../../..', text: 'Compliance Manager' },
        { path: '../..', text: 'Settings' },
        { path: '.', text: 'Report Settings' },
    ];

    showColorScheme: boolean = false;
    lCoverImageFormat: string;
    lHeaderImageFormat: string;

    constructor(
        private messageService: MessageService,
        private settingService: SettingService,
        private siteSettingService: SiteSettingService,
        private toaster: ToastService,
        private route: ActivatedRoute,
        private uiService: UiService,
        private authService: AuthService,
        private siteService: SiteService,
        private http: HttpClient
    ) {
        this.tConversionFactor = 1.5;
    }

    isSaving: boolean;

    site: Site;

    activePanel: string;

    tFor: string;
    tBy: string;
    tFooter: string;
    tDisclaimer: string;
    tLanguageCtrl: UntypedFormControl = new UntypedFormControl();
    tPaperCtrl: UntypedFormControl = new UntypedFormControl();
    tCurrencyCtrl: UntypedFormControl = new UntypedFormControl();
    tConversionFactor: number;
    tLanguageError: boolean;

    allowedLanguages: any[] = [];
    selectedLanguages: any[] = [];

    @ViewChild('tLanguageSelect') tLanguageSelect: any;
    @ViewChild('tPaperSelect', { static: true }) tPaperSelect: any;
    @ViewChild('tCurrencySelect', { static: true }) tCurrencySelect: any;

    lCoverImage: string; //b64
    lHeaderImage: string; //b64

    ciCoverImage: string; //b64
    ciCustom: boolean;
    @ViewChild('ciUpload', { static: true }) ciUpload: ElementRef;
    @ViewChild('ciPreview', { static: true }) ciPreview: ElementRef;
    selectedImage: string;

    @ViewChild('styleDialog', { static: true }) styleDialog: any;

    thAccentColor: string;

    coName: string;
    coAddress: string;
    coCity: string;
    coState: string;
    coPhone: string;
    coEmail: string;
    coURL: string;
    coFounded: string;

    loadingComplete: boolean;
    sub: any;

    defaultTHAccentColor: string = '#000000';
    defaultTHCoverPage: string = 'tech2';
    defaultTHStyle: string = 'Modern Graphite';

    oldthAccentColor: string; // used to track the last client set accent color so we can go back to that if they change from a modern report
    thAccentColorIgnored: boolean = false; // modern reports  ignore accent color, so do stuff with that knowledge

    ngOnInit() {
        this.sub = this.messageService.on(SITE).subscribe((site: Site) => {
            if (site) {
                this.site = site;
                this.uiService.setTitle('Report Settings', site.Name);
                this.loadingComplete = false;

                if (this.siteService.isComplianceManagerGRC(this.site)) {
                    // update breadcrumb text for GRC
                    this.breadcrumbs[0].text = 'Compliance Manager GRC';
                    this.isEnglishOnly = true;
                }
                if (this.siteService.isKVS(this.site)) {
                    this.breadcrumbs[0].text = 'VulScan';
                    this.isEnglishOnly = true;
                }

                this.siteSettingService
                    .getReportPreferences(this.site.Id)
                    .then((prefs) => {
                        // TEXT
                        console.log('prefs', prefs);
                        let tFor = prefs.find((p) => p.Name == 'T_FOR');
                        let tBy = prefs.find((p) => p.Name == 'T_BY');
                        let tFooter = prefs.find((p) => p.Name == 'T_FOOTER');
                        let tDisclaimer = prefs.find((p) => p.Name == 'T_DISCLAIMER');
                        let tLanguage = prefs.find((p) => p.Name == 'T_LANGUAGE');
                        let tPaperSize = prefs.find((p) => p.Name == 'T_PAPERSIZE');
                        let tCurrency = prefs.find((p) => p.Name == 'T_CURRENCY');
                        let tConvFactor = prefs.find((p) => p.Name == 'T_CONV_FACTOR');

                        this.tFor = tFor ? tFor.Value : site.Name;
                        this.tBy = tBy ? tBy.Value : null;

                        if (!this.tBy)
                            this.settingService.getSettings().then((sets) => {
                                for (let set of sets)
                                    if (set.Name == 'CompanyName') this.tBy = set.Value;
                            });

                        this.tFooter = tFooter ? tFooter.Value : 'PROPRIETARY & CONFIDENTIAL';
                        this.tDisclaimer = tDisclaimer
                            ? tDisclaimer.Value
                            : 'CONFIDENTIALITY NOTE: The information contained in this report document is for the exclusive use of the client specified above and may contain confidential, privileged and non-disclosable information. If the recipient of this report is not the client or addressee, such recipient is strictly prohibited from reading, photocopying, distributing or otherwise using this report or its contents in any way.';
                        if (this.site.Type == 'HIPAA' || this.site.Type == 'CYBERINSURANCE') {
                            this.allowedLanguages = [];
                            this.allowedLanguages.push({
                                code: 'en-US',
                                name: this.languages['en-US'],
                            });
                            this.tLanguageCtrl.setValue(tLanguage?.Value ? tLanguage.Value : 'en-US');
                            this.setLanguages(this.tLanguageCtrl.value.split(','));
                        } else {
                            this.siteSettingService
                                .getAllowedLanguages(this.site.Id)
                                .then((langs) => {
                                    this.allowedLanguages = [];
                                    for (let lang of langs) {
                                        if (this.languages[lang])
                                            this.allowedLanguages.push({
                                                code: lang,
                                                name: this.languages[lang],
                                            });
                                    }

                                    // TEMPORARY: REMOVE THIS BEFORE GOING TO PROD
                                    //if (this.allowedLanguages.findIndex(l => l.code == 'fr-FR') < 0)
                                    //  this.allowedLanguages.push({ code: 'fr-FR', name: 'French' });

                                    window.setTimeout(() => {
                                        this.tLanguageCtrl.setValue(
                                           tLanguage?.Value ? tLanguage.Value : 'en-US'
                                        );
                                        this.setLanguages(this.tLanguageCtrl.value.split(','));
                                        //this.tLanguageSelect.setSelected(tLanguage ? tLanguage.Value : 'en-US');

                                        this.tLanguageCtrl.valueChanges.subscribe((value) => {
                                            console.log(value);
                                        });
                                    }, 10);
                                });
                        }

                        let defaultPaperSize =
                            this.site.Type == 'GDPR' || this.site.Type == 'UKGDPR'
                                ? 'A4'
                                : 'L_8-5_11';
                        this.tPaperCtrl.setValue(tPaperSize?.Value ? tPaperSize.Value : defaultPaperSize);
                        this.tPaperSelect.setSelected(
                            tPaperSize?.Value ? tPaperSize.Value : defaultPaperSize
                        );

                        if (this.site.Type == 'GDPR') {
                            this.tCurrencyCtrl.setValue(tCurrency?.Value ? tCurrency.Value : '€');
                            this.tCurrencySelect.setSelected(tCurrency?.Value ? tCurrency.Value : '€');
                            this.tConversionFactor = tConvFactor?.Value
                                ? Number.parseFloat(tConvFactor.Value)
                                : 1.5;
                        } else if (this.site.Type == 'UKGDPR') {
                            this.tCurrencyCtrl.setValue(tCurrency?.Value ? tCurrency.Value : 'GBP');
                            this.tCurrencySelect.setSelected(tCurrency?.Value ? tCurrency.Value : 'GBP');
                            this.tConversionFactor = tConvFactor?.Value
                                ? Number.parseFloat(tConvFactor.Value)
                                : 1.5;
                        } else {
                            this.tCurrencyCtrl.setValue(tCurrency?.Value ? tCurrency.Value : 'USD');
                            this.tCurrencySelect.setSelected(tCurrency?.Value ? tCurrency.Value : 'USD');
                            this.tConversionFactor = tConvFactor?.Value
                                ? Number.parseFloat(tConvFactor.Value)
                                : 1;
                        }

                        // LOGO

                        let lCoverImage = prefs.find((p) => p.Name == 'L_COVER_IMAGE');
                        // this is to remove the data string for items that were saved during testing
                        this.lCoverImage = lCoverImage?.Image64
                            ? lCoverImage.Image64.replace(/data:.*;base64,/, '')
                            : null;

                        this.lCoverImageFormat = lCoverImage?.Value
                            ? lCoverImage.Value
                            : 'data:image;base64,';

                        let lHeaderImage = prefs.find((p) => p.Name == 'L_HEADER_IMAGE');
                        // this is to remove the data string for items that were saved during testing
                        this.lHeaderImage = lHeaderImage
                            ? lHeaderImage.Image64.replace(/data:.*;base64,/, '')
                            : null;

                        this.lHeaderImageFormat = lHeaderImage?.Value
                            ? lHeaderImage.Value
                            : 'data:image;base64,';

                        // THEME

                        let thCover = prefs.find((p) => p.Name == 'TH_COVER');
                        let thTheme = prefs.find((p) => p.Name == 'TH_THEME');
                        let thAccent = prefs.find((p) => p.Name == 'TH_ACCENT');
                        let thColorScheme = prefs.find((p) => p.Name == 'TH_COLORSCHEME');
                        let thDocStyle = prefs.find((p) => p.Name == 'TH_DOCSTYLE');

                        this.thCoverPage = thCover?.Value ? thCover.Value : this.defaultTHCoverPage;
                        this.thAccentColor = thAccent?.Value ? thAccent.Value : this.defaultTHAccentColor;
                        this.oldthAccentColor = this.thAccentColor;
                        this.thTheme = thTheme?.Value
                            ? JSON.parse(thTheme.Value)
                            : this.themeList.find((t) => t.ThemeName == this.defaultTHStyle); //this.themeList[0];

                        if (this.thTheme !== null) {
                            // check theme name to see if it's a normal one already included
                            let startingTheme = this.themeList.find(
                                (t) => t.ThemeName == this.thTheme.ThemeName
                            );
                            if (typeof startingTheme === 'undefined') {
                                // this is not a normally named theme, so make it a custom one.
                                let customTheme: ReportTheme = Object.assign(
                                    new CustomTheme(),
                                    this.thTheme
                                );
                                this.themeList.push(customTheme);
                            }
                            this.themeCtrl.setValue(this.thTheme.ThemeName);
                            this.themeSelect.setSelected(this.thTheme.ThemeName);
                            if (this.thTheme.ReportModernizationEnabled) {
                                // if theme is modern, accent color is ignored anyway, so disable.
                                this.oldthAccentColor = this.thAccentColor;
                                this.thAccentColorIgnored = true;
                                this.thAccentColor = this.thTheme.ReportModernizationPrimaryColor;
                            } else {
                                this.thAccentColorIgnored = false;
                            }
                        }

                        if (thColorScheme?.Value) {
                            let cs = JSON.parse(thColorScheme.Value);
                            this.thCustomCS = cs.colors;
                            this.thUseCustomCS = cs.name == '';
                            this.thCSName = cs.name;
                            this.thPreDefinedChoice = cs.name.toLowerCase();
                        }

                        /*if (thDocStyle && thDocStyle.Value) {
              let style = JSON.parse(thDocStyle.Value);
              console.log(style);
            }*/

                        // COVER IMAGE

                        let ciCoverImage = prefs.find((p) => p.Name == 'CI_COVER_IMAGE');
                        this.ciCoverImage = ciCoverImage ? ciCoverImage.Image64 : null;
                        if (ciCoverImage?.Value) {
                            this.selectedImage = ciCoverImage.Value;
                        }

                        /*
            let coName = prefs.find(p => p.Name == 'CO_NAME');
            let coAddress = prefs.find(p => p.Name == 'CO_ADDRESS');
            let coCity = prefs.find(p => p.Name == 'CO_CITY');
            let coState = prefs.find(p => p.Name == 'CO_STATE');
            let coPhone = prefs.find(p => p.Name == 'CO_PHONE');
            let coEmail = prefs.find(p => p.Name == 'CO_EMAIL');
            let coURL = prefs.find(p => p.Name == 'CO_URL');
            let coFounded = prefs.find(p => p.Name == 'CO_FOUNDED');

            this.coName = coName ? coName.Value : null;
            this.coAddress = coAddress ? coAddress.Value : null;
            this.coCity = coCity ? coCity.Value : null;
            this.coState = coState ? coState.Value : null;
            this.coPhone = coPhone ? coPhone.Value : null;
            this.coEmail = coEmail ? coEmail.Value : null;
            this.coURL = coURL ? coURL.Value : null;
            this.coFounded = coFounded ? coFounded.Value : null;
            */
                        this.loadingComplete = true;
                    })
                    .catch((err) => {
                        this.loadingComplete = true;
                        console.error(err);
                    });
            }
        });
        this.messageService.broadcast(PING_SITE);
        //PG 1/26/22 make active panel active on load
        this.activePanel = 'text';
        this.route.params.subscribe((params) => {
            this.activePanel = params['panel'];

            this.isSaving = false;
        });
    }

    ngAfterViewInit() {
        this.messageService.broadcast('SIDEBAR_SET_EXPANDED', 'reports');
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    setLanguages(langs: string[]) {
        this.tLanguageError = false;
        if (langs.length < 1) {
            this.tLanguageError = true;
            return;
        }

        for (let i = 0; i < this.selectedLanguages.length; i++) {
            let selected = this.selectedLanguages[i];
            if (langs.indexOf(selected.code) < 0) {
                this.selectedLanguages.splice(i, 1);
                this.allowedLanguages.push(selected);
            }
        }

        this.allowedLanguages.sort((a, b) =>
            a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        );

        for (let lang of langs) {
            let idx = this.allowedLanguages.findIndex((l) => l.code == lang);
            if (idx >= 0) {
                let language = this.allowedLanguages.splice(idx, 1)[0];
                this.selectedLanguages.push(language);
            }
        }

        this.tLanguageCtrl.setValue(langs.join(','));
    }

    processCoverImage(resp) {
        let index = resp.file.result.indexOf(',');
        this.lCoverImageFormat = resp.file.result.substring(0, index + 1);
        this.lCoverImage = resp.file.result.replace(/data:.*;base64,/, '');
    }

    removeCoverImage() {
        this.lCoverImageFormat = '';
        this.lCoverImage = '';
    }

    processHeaderImage(resp) {
        let index = resp.file.result.indexOf(',');
        this.lHeaderImageFormat = resp.file.result.substring(0, index + 1);
        this.lHeaderImage = resp.file.result.replace(/data:.*;base64,/, '');
    }

    removeHeaderImage() {
        this.lHeaderImageFormat = '';
        this.lHeaderImage = '';
    }

    previewCoverPage(page) {
        this.previewCover = page;
        this.previewModal.show();
        setTimeout(() => {
            this.previewModal.refresh();
        }, 25);
    }

    chooseCoverPage(page) {
        // if tech2 is selected and the accent color is black, default to orange color
        if (page.id == 'tech2' && this.thAccentColor == '#000000') {
            this.thAccentColor = '#e37f46';
            this.oldthAccentColor = '#e37f46';
        }
        this.thCoverPage = page.id;
    }

    showStyleDialog() {
        let origTheme = this.themeList.find((t) => t.ThemeName == this.themeCtrl.value);

        let theme = Object.assign(new CustomTheme(), origTheme);
        if (theme.ThemeName.indexOf('customized') == -1) {
            // if theme name is not already customized, add customized
            theme.ThemeName = origTheme.ThemeName + ' (customized)';
        }
        this.styleDialog.showModal(theme);
    }

    setCustomTheme(theme) {
        let oldCustom = this.themeList.find((t) => t.ThemeName.indexOf('customized') > -1);

        if (oldCustom) {
            // we can only allow one custom right now,
            // so remove the old one
            this.themeList.splice(this.themeList.indexOf(oldCustom), 1);
        }
        // create new custom theme, push it and selet it
        let customTheme: ReportTheme = Object.assign(new CustomTheme(), theme);
        this.themeList.push(customTheme);

        this.thTheme = theme;
        this.themeCtrl.setValue(this.thTheme.ThemeName);
        this.themeSelect.setSelected(this.thTheme.ThemeName);
    }

    onColorChange(ev) {
        this.thAccentColor = ev;
        // user set their own color, so save it to bring back if we need to in the future.
        this.oldthAccentColor = ev;
    }

    moveLeft() {
        this.ds.moveLeft();
    }

    moveRight() {
        this.ds.moveRight();
    }

    clickItem(url) {
        this.selectedImage = url;
        this.http.get(url, { responseType: 'arraybuffer' }).subscribe((data) => {
            let fileb64 = '';
            let bytes = new Uint8Array(data);
            bytes.forEach((b) => {
                fileb64 += String.fromCharCode(b);
            });
            this.ciCoverImage = window.btoa(fileb64);
        });
    }

    // note this is on the Cover Images tab, whereas prepareCoverImage() above refers to the My Logo tab
    customCoverImage(resp) {
        // get just b64... I dunno.. it's just how it was done
        this.ciCoverImage = resp.file.result.replace(/.*;base64,/, '');
        // selected image is used to highlight the selected image in carousel
        this.selectedImage = '';
    }

    saveTextPreferences() {
        if (this.tLanguageCtrl.value == '') this.tLanguageError = true;

        if (this.tLanguageError) return;

        let prefs: ReportPreference[] = [
            { Name: 'T_FOR', Value: this.tFor },
            { Name: 'T_BY', Value: this.tBy },
            { Name: 'T_FOOTER', Value: this.tFooter },
            { Name: 'T_DISCLAIMER', Value: this.tDisclaimer },
            { Name: 'T_LANGUAGE', Value: this.tLanguageCtrl.value },
            { Name: 'T_PAPERSIZE', Value: this.tPaperCtrl.value },
            { Name: 'T_CURRENCY', Value: this.tCurrencyCtrl.value },
            { Name: 'T_CONV_FACTOR', Value: this.tConversionFactor },
        ];

        for (let pref of prefs) {
            pref.SiteId = this.site.Id;
            pref.AccountId = '';
        }

        this.isSaving = true;
        this.siteSettingService.setReportPreferences(this.site.Id, prefs).then((res) => {
            this.isSaving = false;
            this.toaster.success('Success', 'Text settings were updated.');
        });
    }

    saveLogoPreferences() {
        let prefs: ReportPreference[] = [
            { Name: 'L_COVER_IMAGE', Value: this.lCoverImageFormat, Image64: this.lCoverImage },
            { Name: 'L_HEADER_IMAGE', Value: this.lHeaderImageFormat, Image64: this.lHeaderImage },
        ];

        for (let pref of prefs) {
            pref.SiteId = this.site.Id;
            pref.AccountId = '';
        }

        this.isSaving = true;
        this.siteSettingService.setReportPreferences(this.site.Id, prefs).then((res) => {
            this.isSaving = false;
            this.toaster.success('Success', 'Logo settings were updated.');
        });

        this.setCooperFlag();
    }

    saveThemePreferences() {
        let theme = this.thTheme
            ? this.thTheme
            : this.themeList.find((t) => t.ThemeName == this.themeCtrl.value) || this.themeList[0];

        if (this.thUseCustomCS && this.thCSName == '') this.thCSName = 'Standard';

        let cs = {
            name: this.thCSName,
            colors: this.thCustomCS,
        };

        let prefs: ReportPreference[] = [
            { Name: 'TH_COVER', Value: this.thCoverPage },
            { Name: 'TH_ACCENT', Value: this.thAccentColor },
            { Name: 'TH_THEME', Value: JSON.stringify(theme) },
            { Name: 'TH_COLORSCHEME', Value: JSON.stringify(cs) },
            {
                Name: 'TH_DOCSTYLE',
                Value: this.thDocStyle ? JSON.stringify(this.thDocStyle) : null,
            },
        ];

        for (let pref of prefs) {
            pref.SiteId = this.site.Id;
            pref.AccountId = '';
        }

        this.isSaving = true;
        this.siteSettingService.setReportPreferences(this.site.Id, prefs).then((res) => {
            this.isSaving = false;
            this.toaster.success('Success', 'Theme settings were updated.');
        });
    }

    saveCoverImagePreferences() {
        let prefs: ReportPreference[] = [
            {
                Name: 'CI_COVER_IMAGE',
                Value: this.ciCustom ? '' : this.selectedImage,
                Image64: this.ciCoverImage,
                SiteId: this.site.Id,
                AccountId: '',
            },
        ];

        this.isSaving = true;
        this.siteSettingService.setReportPreferences(this.site.Id, prefs).then((res) => {
            this.isSaving = false;
            this.toaster.success('Success', 'Cover image selection was updated.');
        });
    }

    onPreDefinedCSChange(scheme, ev) {
        this.thUseCustomCS = false;
        this.thCSName = scheme.name;
        this.thCustomCS[0] = scheme.c1;
        this.thCustomCS[1] = scheme.c2;
        this.thCustomCS[2] = scheme.c3;
    }
    onCustomCSChange(which, ev) {
        this.thUseCustomCS = true;
        this.thCSName = '';
        switch (which) {
            case 'custom1':
                this.thCustomCS[0] = ev;
                break;
            case 'custom2':
                this.thCustomCS[1] = ev;
                break;
            case 'custom3':
                this.thCustomCS[2] = ev;
                break;
        }
    }
    onCustomCSOpen(ev) {
        if (ev) {
            this.thUseCustomCS = true;
        }
    }

    themeDropdownChanged() {
        this.thTheme = this.themeList.find((t) => t.ThemeName == this.themeCtrl.value);
        if (this.thTheme.ReportModernizationEnabled) {
            this.thAccentColorIgnored = true;
            this.thAccentColor = this.thTheme.ReportModernizationPrimaryColor;
        } else {
            this.thAccentColorIgnored = false;
            this.thAccentColor = this.oldthAccentColor;
        }
    }

    setCooperFlag() {
        let sets: Setting[] = [];

        sets.push({
            AccountId: this.site.AccountId,
            Name: 'COOPER_LOGO',
            Value: 'TRUE',
        });

        this.settingService
            .updateSettings(sets)
            .then(() => {})
            .catch(() => {});
    }

    /*
  saveCompanyPreferences() {
    let prefs: ReportPreference[] = [
      { Name: 'CO_NAME', Value: this.coName },
      { Name: 'CO_ADDRESS', Value: this.coAddress },
      { Name: 'CO_CITY', Value: this.coCity },
      { Name: 'CO_STATE', Value: this.coState },
      { Name: 'CO_PHONE', Value: this.coPhone },
      { Name: 'CO_EMAIL', Value: this.coEmail },
      { Name: 'CO_URL', Value: this.coURL },
      { Name: 'CO_FOUNDED', Value: this.coFounded },
    ];

    for (let pref of prefs) {
      pref.SiteId = this.site.Id;
      pref.AccountId = '';
    }

    this.isSaving = true;
    this.siteSettingService.setReportPreferences(this.site.Id, prefs)
      .then((res) => {
        this.isSaving = false;
        this.toaster.success('Success', 'Company Information settings were updated.')
      });
  }
  */
}
