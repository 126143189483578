<div id="vuln">
    <div
        class="ui big label"
        [ngClass]="{ red: diag.Severity == 'High', yellow: diag.Severity == 'Medium' }"
        style="height: 38px">
        <i class="warning sign icon"></i>
        {{ diag.Severity }}
    </div>

    <div class="ui big label darker">
        CVSS:
        <div class="detail">{{ diag.CVSS }}</div>
    </div>

    <div class="ui big label darker">
        OID:
        <div class="detail">{{ diag.OID }}</div>
    </div>

    <div class="ui big label darker" *ngIf="isValidPort(diag.Port)">
        Port:
        <div class="detail">
            {{ diag.Port + (diag.Protocol ? ' / ' + diag.Protocol : '') }}
        </div>
    </div>

    <div class="ui celled list">
        <div class="item">
            <div class="header">NVT Name</div>
            <p>{{ diag['NVT Name'] }}</p>
        </div>
        <div class="item">
            <div class="header">Summary</div>
            <p>{{ cleanNewlines(diag.Summary) }}</p>
        </div>
        <div class="item">
            <div class="header">Affected Devices</div>
            <p>{{ cleanNewlines(diag['Affect Nodes']) }}</p>
        </div>

        <div class="item" *ngIf="diag['Impact']">
            <div class="header">Impact</div>
            <p>{{ cleanNewlines(diag.Impact) }}</p>
        </div>
        <div class="item" *ngIf="diag['Solution']">
            <div class="header">Solution</div>
            <p [innerHTML]="cleanNewlines(diag.Solution) | linkify"></p>
        </div>
        <div class="item" *ngIf="diag.CVES && diag.CVES != 'NOCVE'">
            <div class="header">CVEs</div>
            <p>{{ cleanNewlines(diag.CVES) }}</p>
        </div>
        <div class="item" *ngIf="diag.BIDS">
            <div class="header">BIDs</div>
            <p>{{ cleanNewlines(diag.BIDS) }}</p>
        </div>
        <div class="item" *ngIf="diag['Vulnerability Insight']">
            <div class="header">Vulnerability Insight</div>
            <p [innerHTML]="diag['Vulnerability Insight'] | linkify"></p>
        </div>
        <div class="item" *ngIf="diag['Vulnerability Detection Result']">
            <div class="header">Vulnerability Detection Result</div>
            <p [innerHTML]="diag['Vulnerability Detection Result'] | linkify"></p>
        </div>
        <div class="item" *ngIf="diag['Vulnerability Detection Method']">
            <div class="header">Vulnerability Detection Method</div>
            <p [innerHTML]="diag['Vulnerability Detection Method'] | linkify"></p>
        </div>
        <div class="item" *ngIf="diag['Product Detection Result']">
            <div class="header">Product Detection Result</div>
            <p [innerHTML]="diag['Product Detection Result'] | linkify"></p>
        </div>
        <div class="item" *ngIf="diag['References']">
            <div class="header">References</div>
            <p [innerHTML]="diag['References'] | linkify"></p>
        </div>
    </div>
</div>
