import { Injectable } from '@angular/core';

import { AuthHttp } from 'app/core/auth';
import { ErrorService } from 'app/core/error/error.service';
import { Alert } from './alert.model';

import { environment } from 'environments/environment';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AlertService {
    private apiUrl: string;

    constructor(private http: AuthHttp) {
        this.apiUrl = environment.apiUrl;
    }

    getAlerts(): Promise<Alert[]> {
        const url: string = this.apiUrl + 'Alerts';
        return lastValueFrom(this.http.get(url)).then((data) => data as Alert[]);
        //.catch(this.E.error);
    }

    getAlert(id: string): Promise<Alert> {
        const url: string = this.apiUrl + 'Alerts/' + id;
        return this.http
            .get(url)
            .toPromise()
            .then((data) => data as Alert);
        //.catch(this.E.error);
    }

    getSiteAlerts(siteId: number): Promise<Alert[]> {
        const url: string = this.apiUrl + 'Alerts?siteId=' + siteId;
        return this.http
            .get(url)
            .toPromise()
            .then((data) => data as Alert[]);
        //.catch(this.E.error);
    }

    getSiteIgnoredAlerts(siteId: number): Promise<any[]> {
        const url: string = this.apiUrl + 'Alerts/Ignored?siteId=' + siteId;
        return this.http
            .get(url)
            .toPromise()
            .then((data) => data as any[]);
        //.catch(this.E.error);
    }

    getSimilarAlerts(alert: Alert): Promise<Alert[]> {
        const url: string = this.apiUrl + `Alerts/${alert.Guid}/Similar`;
        return this.http
            .get(url)
            .toPromise()
            .then((data) => data as Alert[]);
        //.catch(this.E.error);
    }

    getTodos(limit?: number): Promise<Alert[]> {
        if (!limit) limit = 0;
        const url: string = this.apiUrl + `Alerts/Todo?limit=${limit}`;
        return this.http
            .get(url)
            .toPromise()
            .then((data) => data as Alert[]);
        //.catch(this.E.error);
    }

    getSiteTodos(siteId: number, limit?: number, updates?: boolean): Promise<Alert[]> {
        if (!limit) limit = 0;
        const url: string =
            this.apiUrl +
            `Alerts/Todo?siteId=${siteId}&limit=${limit}` +
            (updates !== void 0 ? `&updates=${updates}` : '');
        return this.http
            .get(url)
            .toPromise()
            .then((data) => data as Alert[]);
        //.catch(this.E.error);
    }

    getSuggestedTags(alertType: string): Promise<any> {
        const url: string = this.apiUrl + 'Alerts/SuggestedTags/' + alertType;
        return this.http
            .get(url)
            .toPromise()
            .then((data) => data);
        //.catch(this.E.error)
    }

    deleteIgnoredAlertItems(ids: string[]): Promise<any[]> {
        const url: string = this.apiUrl + 'Alerts/Ignored/Delete';
        return this.http
            .post(url, ids, { observe: 'response' })
            .toPromise()
            .then((res) => res);
        //.catch(this.E.error);
    }

    alertAction(alert: Alert, actions: any, data: any): Promise<any> {
        const url: string = this.apiUrl + 'Alerts/Action';
        return this.http
            .post(
                url,
                { alert: alert, actions: actions, actionItem: data },
                { observe: 'response' }
            )
            .toPromise()
            .then((res) => res);
        //.catch(this.E.error);
    }

    endUserAction(alert: Alert, action: string, email: string): Promise<any> {
        const url: string = this.apiUrl + 'Alerts/EndUserAction';
        return this.http
            .post(url, { alert: alert, action: action, email: email }, { observe: 'response' })
            .toPromise()
            .then((res) => res);
        //.catch(this.E.error);
    }

    decryptParams(p: string): Promise<any> {
        const url: string = this.apiUrl + 'Params?p=' + p;
        return this.http
            .get(url)
            .toPromise()
            .then((data) => data);
        //.catch(this.E.error);
    }
}
