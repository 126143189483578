import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { AuthService } from 'app/core/auth';
import { Setting } from '../shared/setting.model';
import { SettingService } from '../shared/setting.service';
import { MessageService } from 'app/core/message.service';
import { UiService } from 'app/core/ui.service';

import { PING_SETTINGS, FETCH_SETTINGS, SETTINGS } from '../shared/constants';
import { TZINFOS } from './tzinfos';
import { NotificationService } from '../../shared/itc/notification/notification.service';
import { DateService } from 'app/shared/date.service';

@Component({
    selector: 'sds-general-settings',
    templateUrl: './general-settings.component.html',
    styleUrls: ['./general-settings.component.css'],
})
export class GeneralSettingsComponent implements OnInit, OnDestroy {
    constructor(
        private authService: AuthService,
        private messageService: MessageService,
        private settingService: SettingService,
        private notificationService: NotificationService,
        private uiService: UiService,
        private dateService: DateService
    ) {}

    settings: any = {};
    originalSettings: any = {};
    saving: boolean;
    loadingComplete: boolean;
    date: Date = new Date();

    timeZones: any[] = TZINFOS;
    timeZoneCtrl: UntypedFormControl = new UntypedFormControl();

    dateFmtCtrl: UntypedFormControl = new UntypedFormControl();
    dateFormats: any[] = ['dd-MMM-yyyy', 'MM/dd/yyyy', 'yyyy/MM/dd', 'dd/MM/yyyy'];

    subs: any[] = [];

    breadcrumbs = [
        { path: '..', text: 'Admin' },
        { path: '.', text: 'General' },
    ];

    ngOnInit() {
        this.uiService.setTitle('General Settings');
        this.subs.push(
            this.messageService.on(SETTINGS).subscribe((settings) => this.process(settings))
        );
        // this.messageService.broadcast(PING_SETTINGS);
        this.messageService.broadcast(FETCH_SETTINGS);
    }

    //oldTZ: string;
    //ngAfterViewChecked() {
    //  if (this.timeZoneSelect.getSelected() != this.oldTZ) {
    //    this.oldTZ = this.timeZoneCtrl.value;
    //    this.timeZoneSelect.setSelected(this.timeZoneCtrl.value);
    //  }
    //}

    ngOnDestroy() {
        for (let sub of this.subs) sub.unsubscribe();
    }

    //error(err: any): Promise<any> {
    //  return Promise.reject(err);
    //}

    process(settings: any) {
        if (!settings || !Object.keys(settings).length) {
            return;
            //let localSettings = localStorage.getItem(SDSP_SETTINGS);
            //settings = localSettings ? JSON.parse(localSettings) : DEFAULTS;
        }

        this.settings = settings;
        console.log(settings);

        let localTimeZone = this.dateService.getLocalTimeZone();

        for (let s of Object.keys(settings)) {
            if (s == 'TZINFO') {
                setTimeout(() => {
                    this.timeZoneCtrl.setValue(settings[s] == null ? localTimeZone : settings[s]);
                }, 100);
            } else if (s == 'DateFormat') {
                setTimeout(() => {
                    this.dateFmtCtrl.setValue(settings[s] == null ? 'MM/dd/yyyy' : settings[s]);
                }, 100);
            }
        }
        this.loadingComplete = true;
    }

    saveSettings() {
        this.saving = true;

        let tz = this.timeZoneCtrl.value,
            dfmt = this.dateFmtCtrl.value;
        if (!tz || !dfmt) {
            this.saving = false;
            return;
        }

        let accountId = this.authService.getIdentity().accountId;

        let sets: Setting[] = [];
        sets.push({
            AccountId: accountId,
            Name: 'TZINFO',
            Value: tz,
        });
        sets.push({
            AccountId: accountId,
            Name: 'DateFormat',
            Value: dfmt,
        });

        this.settingService
            .updateSettings(sets)
            .then(() => {
                this.saving = false;
                // save accountTimeInfo, i need to do it through uiService as we don't really have a good data store, and we need it if
                // user is in incognito mode since we can't get localstorage
                const accountTimeInfo = { DateFormat: dfmt, TZINFO: tz };
                this.uiService.setControlValue('accountTimeInfo', JSON.stringify(accountTimeInfo));

                this.notificationService.toast.success('Success', 'General Settings were updated.');
            })
            .catch(() => {
                this.saving = false;

                this.notificationService.toast.error(
                    'Error',
                    'There was an error saving the settings.'
                );
            });
    }

    resetSettings() {
        this.notificationService.toast.success('Success', 'General Settings were reset.');
    }
}
