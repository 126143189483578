import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidationErrors, AbstractControl } from '@angular/forms';

@Directive({
    selector: '[validPort]',
    providers: [{ provide: NG_VALIDATORS, useExisting: PortValidator, multi: true }],
})
export class PortValidator implements Validator {
    constructor() {}

    validate(control: AbstractControl): ValidationErrors | null {
        return validationTest(control.value);
    }
}

export function validPort(control: AbstractControl): { [key: string]: any } | null {
    return validationTest(control.value);
}

export function validationTest(val): ValidationErrors | null {
    const portRegex =
        /^([1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/i;
    return portRegex.test(val) ? null : { invalidPort: true };
}
