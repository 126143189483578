import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

import { VpAuthService } from './vp-auth.service';
import { JwtHelper } from '../core/auth/auth.jwt';

@Injectable()
export class VpGuard  {
    constructor(private authService: VpAuthService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let rval: boolean = false;

        if (this.authService.isAuth() && !JwtHelper.isTokenExpired(this.authService.getToken()))
            rval = true;

        if (rval == false) {
            this.authService.saveTargetUrl(state.url);

            this.router.navigateByUrl('/vendor/login');
        }
        console.log('AuthGuard result: ', rval);
        return rval;
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }
}
