import { Component, Input, OnInit } from '@angular/core';
import { AlertDiagnostics } from '../../alert-diagnostics.model';
import { Site } from 'app/sites/shared/site.model';
import { SITE, PING_SITE } from 'app/sites/shared/constants';
import { AuthService, Roles } from 'app/core/auth';
import { Subscription } from 'rxjs';

@Component({
    templateUrl: './ABDSScan.component.html',
})
export class ABDSScanComponent implements OnInit {
    constructor(private authService: AuthService) {}

    diag: any = {};
    licensed: boolean;

    entries: any[];
    sub: Subscription;
    site: Site;
    ueSiteName: string;
    isMasterUser: boolean;

    @Input()
    set diagData(value) {
        if (!value) return;

        this.diag = value;
        if (this.diag.ABDS_Licensed) this.licensed = true;

        this.ueSiteName = encodeURI(this.diag.SiteName);

        this.entries = [];
        for (let key of Object.keys(this.diag)) {
            if (key == 'ABDS_Licensed') continue;

            let details = this.diag[key][0],
                keys = [];

            for (let pname of Object.keys(details)) {
                if (
                    ['category', 'classification', 'type', 'subtype'].indexOf(pname) < 0 &&
                    details[pname]
                )
                    keys.push(pname);
            }

            this.entries.push({
                keys: keys,
                details: details,
            });
        }
    }

    ngOnInit() {
        let user = this.authService.getIdentity();
        let roleId = parseInt(user.roleId);
        this.isMasterUser = roleId == Roles.Master;
    }
}
