import { CM_COLORS } from '../shared/cm-summary.constants';
import { Answer, QuestionDescription, WizardOptions } from '../shared/cm-wizard/cm-wizard.model';

export let RequirementsWizardAnswers: Answer[] = [
    {
        title: 'Addressed by Controls',
        color: CM_COLORS.COVERED_BRIGHT,
        colorMuted: CM_COLORS.COVERED,
        value: 1,
        disableSpecialCase: 'AddressedByControls',
    },
    {
        title: 'Addressed',
        color: CM_COLORS.COVERED_BRIGHT,
        colorMuted: CM_COLORS.COVERED,
        value: 2,
    },
    {
        title: 'Not Addressed',
        color: CM_COLORS.NOTCOVERED_BRIGHT,
        colorMuted: CM_COLORS.NOTCOVERED,
        value: 3,
    },
];

export let RequirementsWizardDescription: QuestionDescription[] = [
    {
        title: 'Does your organization meet the requirement for:',
        content: 'Requirement.ShortDesc',
        localized: true,
    },
    {
        title: 'Description',
        content: 'Requirement.LongDesc',
        localized: true,
        ifBlank: '[There is currently no description for this requirement.]',
    },
    {
        title: 'Guidance',
        content: 'Requirement.Guidance',
        localized: true,
        ifBlank: '[There is currently no guidance for this requirement.]',
    },
    {
        title: 'References',
        content: 'Requirement.ReferenceUrls',
        localized: false,
        ifBlank: '',
        type: 'list',
    },
];

export let RequirementsWizardOptions: WizardOptions = {
    assessment: 'ra',
    type: 'requirement',
    sortByStandards: true,
    showControlId: false,
    showRelatedControls: true,
    listViewColumns: ['check', 'requirement', 'response', 'comment', 'files', 'assignments'],
    firstSort: { active: 'requirement', direction: 'asc' },
    showCSGuidance: false,
};
