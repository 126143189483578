import { inject } from '@angular/core';
import { Router, UrlTree, CanActivateFn, CanMatchFn } from '@angular/router';
import { AuthService } from '../auth.service';

export const HasNDProWebGuard: CanActivateFn | CanMatchFn = (): boolean | UrlTree => {
    let authService = inject(AuthService);
    let router = inject(Router);
    let products = authService.getProducts();
    if (products.NdProWeb === true) {
        return true;
    } else {
        return router.createUrlTree(['/error', '403', 'RFTE120']);
    }
};
