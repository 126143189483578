import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';
import { AuthHttp } from 'app/core/auth/auth.http';
import { ErrorService } from 'app/core/error/error.service';
import { Tags } from '.';

@Injectable({ providedIn: 'root' })
export class TagsService {
    private apiUrl: string;

    constructor(private http: AuthHttp, private E: ErrorService) {
        this.apiUrl = environment.apiUrl;
    }

    getSmartTags(siteId: number, tagType: string, search: string): Promise<Tags[]> {
        const url: string =
            this.apiUrl + 'SmartTags?siteId=' + siteId + '&type=' + tagType + '&search=' + search;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as Tags[]);
        //.catch(this.E.error);
    }

    getAllSmartTags(siteId: number): Promise<Tags[]> {
        const url: string = this.apiUrl + `Sites/${siteId}/SmartTags`;

        console.log('URL:  ' + url);

        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as Tags[])
            .catch(this.E.error);
    }

    putSmartTagAssignments(
        siteId: number,
        tagAssignments: { tagType: string; appliesToType: string; appliesToIds: string[] }[]
    ): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/SmartTags`;
        return this.http
            .put(url, tagAssignments, { observe: 'response' })
            .toPromise()
            .then((res) => res)
            .catch((err) => console.error(err));
    }

    exportSmartTags(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/SmartTags/Export`;
        return this.http
            .get(url, { observe: 'response' })
            .toPromise()
            .then((res) => (res.status == 200 ? res.body : null));
    }

    importSmartTags(siteId: number, tagFile: any) {
        const url: string = this.apiUrl + `Sites/${siteId}/SmartTags/Import`;
        let formData = new FormData();
        formData.append('file[]', tagFile);
        return this.http
            .post(url, formData, { observe: 'response' })
            .toPromise()
            .then((res) => (res.status == 200 ? res.body : null));
    }
}
