import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MessageService } from 'app/core/message.service';
import { Site } from 'app/sites/shared/site.model';
import { PatchOp, ScanDataService } from 'app/scans/shared/scan-data.service';
import { RelatedItems } from 'app/sites/shared/site-related-items.model';
import { ScanDataHelper } from 'app/scans/shared/scan-data-helper.service';
import { Router } from '@angular/router';
import { NdpService } from '../../../sites/shared/ndp.service';

@Component({
    selector: 'sds-related-items-card',
    templateUrl: './related-items-card.component.html',
    styleUrls: ['./related-items-card.component.css'],
})
export class RelatedItemsCardComponent implements OnInit {
    constructor(
        private messageService: MessageService,
        private scanDataService: ScanDataService,
        private scanDataHelper: ScanDataHelper,
        private ndpService: NdpService,
        private router: Router
    ) {}

    private _parentId: string;
    get parentId() {
        return this._parentId;
    }
    @Input() set parentId(value: string) {
        if (!value) return;
        this._parentId = value;
    }
    private _parentType: string;
    get parentType() {
        return this._parentType;
    }
    @Input() set parentType(value: string) {
        if (!value) return;
        this._parentType = value;
    }
    private _site: Site;
    get site() {
        return this._site;
    }
    @Input() set site(value: Site) {
        if (!value || !value.Id) return;
        this._site = value;
        this.getNDPAssessmentId();
    }

    searchItems: any[] = [];
    sortedSearchItems: any[] = [];
    filteredSearchItems: any[] = [];
    pagedSearchItems: any[] = [];

    initPage: boolean;
    sortColumn: string = '';
    sortDirection: string = '';

    _items: any[] = [];
    sub: any;
    fields: string[] = ['Name'];
    searchKey: string;
    searchControl = new UntypedFormControl();
    searching: boolean;

    assessmentId: number = -1;
    dataExplorerType = 'Indoc';

    @Input() set items(is: any[]) {
        if (!is) return;
        this._items = [];
        for (let item of is) {
            var relatedObj = JSON.parse(window.atob(item.RelatedUID));
            let type = '',
                icon = '',
                name = '',
                domain = '';

            if (relatedObj.User) {
                type = 'User';
                icon = 'User';
                name = relatedObj.User.Username;
                domain = relatedObj.User.Domain;
            } else if (relatedObj.Computer) {
                type = 'Computer';
                icon = 'Computer';
                name = relatedObj.Computer.Name;
                domain = relatedObj.Computer.Domain;
            } else if (relatedObj.Database) {
                type = 'Database';
                icon = 'Database';
                name = relatedObj.Database.Server;
                domain = relatedObj.Database.Domain;
            } else if (relatedObj.Application) {
                type = 'Application';
                icon = 'Browser';
                name = relatedObj.Application.Name;
                domain = relatedObj.Application.Domain;
            } else if (relatedObj.NonADDevice) {
                type = 'NonAd-Device';
                icon = 'tablet alternate';
                name = relatedObj.NonADDevice.ComputerName;
                domain = relatedObj.NonADDevice.IpAddress;
            } else if (relatedObj.SecurityGroup) {
                type = 'Security-Group';
                icon = 'users';
                name = relatedObj.SecurityGroup.GroupName;
                domain = relatedObj.SecurityGroup.Path;
            } else if (relatedObj.DNSEntry) {
                type = 'DN';
                icon = 'sitemap';
                name = relatedObj.DNSEntry.Hostname;
                domain = relatedObj.DNSEntry.IpAddress;
            }

            let relatedItems: RelatedItems = {
                Site: item.Site,
                Type: type,
                Icon: icon,
                Name: name,
                Domain: domain,
                RelatedUID: item.RelatedUID,
            };

            this._items.push(relatedItems);
            this._items = this.sortItems(this._items);
        }
    }

    @ViewChild('addItemModal', { static: true }) addModal: any;
    selectedIds: string[] = [];
    addingItems: boolean;

    ngOnInit() {
        this.site = new Site();
        this.dataExplorerType = this.ndpService.getDataExplorerType();
    }

    async getNDPAssessmentId() {
        this.assessmentId = await this.ndpService.getAssessmentIdForDataExplorer(this.site, this.dataExplorerType);
    }

    sortItems(items: any[]): any[] {
        return items.sort((a, b) => {
            let cola = a['Name'],
                colb = b['Name'];

            if (typeof cola === 'string') cola = cola.toLocaleLowerCase();
            if (typeof colb === 'string') colb = colb.toLocaleLowerCase();

            return cola > colb ? 1 : cola === colb ? 0 : -1;
        });
    }

    confirmDelete(relID) {
        if (confirm('Are you sure you wish to delete this item?')) {
            this.deleteItem(relID);
        } else {
            return false;
        }
    }

    sortSearchItems() {
        this.sortedSearchItems = this.filteredSearchItems.sort((a, b) => {
            let cola = a[this.sortColumn],
                colb = b[this.sortColumn];

            if (typeof cola === 'string') cola = cola.toLocaleLowerCase();
            if (typeof colb === 'string') colb = colb.toLocaleLowerCase();

            if (this.sortDirection === 'desc') {
                return cola < colb ? 1 : cola === colb ? 0 : -1;
            } else {
                return cola > colb ? 1 : cola === colb ? 0 : -1;
            }
        });
        this.filteredSearchItems = [...this.sortedSearchItems];
    }

    onSorted(ev?: any) {
        if (ev) {
            this.sortColumn = ev.sortColumn;
            this.sortDirection = ev.sortDirection;
            this.sortSearchItems();
        }
    }

    addDialog() {
        (this.addModal as any).show({ closable: false });
        (this.addModal as any).refresh();
    }

    deleteItem(relatedUID: string) {
        let items = [];

        // remove this item from the list we're passing to the patch operation
        for (let item of this._items) {
            if (item.RelatedUID == relatedUID) continue;
            else items.push(item.RelatedUID);
        }

        let pop: PatchOp = {
            op: 'replace',
            path: '/RelatedItems',
            value: items.join(','), // join to a comma-separated list of id's
        };

        this.addingItems = true;
        this.scanDataService
            .patchItem(this.site.Id, this.parentType, this.parentId, [pop], this.assessmentId)
            .then((res) => {
                this.addingItems = false;
            })
            .catch((err) => {
                this.addingItems = false;
            });

        // remove the deleted item from the related items array
        for (var i = 0; i < this._items.length; i++) {
            if (this._items[i].RelatedUID == relatedUID) {
                this._items.splice(i, 1);
                break;
            }
        }

        this.searchControl.setValue('');
        this.filteredSearchItems = [];
        this.clearModalItems();
    }

    addItems() {
        let res = this.filteredSearchItems.filter((obj) => {
            return obj.cb.value;
        });
        res.forEach((item) => {
            this.selectedIds.push(window.btoa(item.RelatedUID));
        });

        let items = [];
        for (let it of this._items) items.push(it.RelatedUID);

        let pop: PatchOp = {
            op: 'replace',
            path: '/RelatedItems',
            value: items.concat(this.selectedIds).join(','), // join to a comma-separated list of id's
        };

        this.addingItems = true;
        this.scanDataService
            .patchItem(this.site.Id, this.parentType, this.parentId, [pop], this.assessmentId)
            .then((res) => {
                this.addingItems = false;
            })
            .catch((err) => {
                this.addingItems = false;
            });

        for (let item of this.selectedIds) {
            var relatedObj = JSON.parse(window.atob(item));
            var type = '',
                icon = '',
                name = '',
                domain = '';

            if (relatedObj.User) {
                type = 'User';
                icon = 'User';
                name = relatedObj.User.Username;
                domain = relatedObj.User.Domain;
            } else if (relatedObj.Computer) {
                type = 'Computer';
                icon = 'Computer';
                name = relatedObj.Computer.Name;
                domain = relatedObj.Computer.Domain;
            } else if (relatedObj.Database) {
                type = 'Database';
                icon = 'Database';
                name = relatedObj.Database.Server;
                domain = relatedObj.Database.Domain;
            } else if (relatedObj.Application) {
                type = 'Application';
                icon = 'Browser';
                name = relatedObj.Application.Name;
                domain = relatedObj.Application.Domain;
            }

            let relatedItems: RelatedItems = {
                Site: this.site.Name,
                Type: type,
                Icon: icon,
                Name: name,
                Domain: domain,
                RelatedUID: item,
            };

            this._items.push(relatedItems);
        }

        this._items = this.sortItems(this._items);
        this.selectedIds = [];
        this.addModal.hide();
        this.clearModalItems();
    }

    async onSearch() {
        this.searchKey = this.searchControl.value;
        if (!this.searchKey || this.searchKey == '') {
            this.filteredSearchItems = [];
            return;
        }

        this.sortColumn = 'Item';
        this.sortDirection = 'asc';
        this.initPage = true;

        this.searching = true;
        //ensure assessmentId is loaded in case search is done too early
        this.assessmentId = await this.ndpService.getAssessmentIdForDataExplorer(this.site, this.dataExplorerType);
        this.scanDataService
            .searchScanDataItems(
                this.site.Id,
                this.searchKey.toLocaleLowerCase(),
                this.assessmentId
            )
            .then((items: any[]) => {
                this.searching = false;

                let filtered = [];

                for (let item of items) {
                    let relatedObj = JSON.parse(window.atob(item.Id));
                    let type = '',
                        icon = '',
                        name = '',
                        displayName = '',
                        relatedUID = '';

                    if (relatedObj.User) {
                        type = 'User';
                        icon = 'User';
                        name = relatedObj.User.Domain + '\\' + relatedObj.User.Username;
                        displayName = item.DisplayName;
                        relatedUID = this.scanDataHelper.getUserUID(item);
                    } else if (relatedObj.Computer) {
                        type = 'Computer';
                        icon = 'Computer';
                        name = relatedObj.Computer.Domain + '\\' + relatedObj.Computer.Name;
                        displayName = relatedObj.Computer.Name;
                        relatedUID = this.scanDataHelper.getComputerUID(item);
                    } else if (relatedObj.Database) {
                        type = 'Database';
                        icon = 'Database';
                        name = relatedObj.Database.Domain + '\\' + relatedObj.Database.Server;
                        displayName = relatedObj.Database.Server;
                        relatedUID = this.scanDataHelper.getDatabaseUID(item);
                    } else if (relatedObj.Application) {
                        type = 'Application';
                        icon = 'Browser';
                        name = relatedObj.Application.Domain + '\\' + relatedObj.Application.Name;
                        displayName = relatedObj.Application.Name;
                        relatedUID = this.scanDataHelper.getApplicationUID(item);
                    }

                    let searchItems = {
                        Name: name,
                        DisplayName: displayName,
                        Type: type,
                        RelatedUID: relatedUID,
                        cb: new UntypedFormControl(),
                    };

                    // don't add currently selected item
                    if (
                        window.atob(this.parentId) != searchItems.RelatedUID &&
                        !this._items.some(
                            (i) => searchItems.RelatedUID == window.atob(i.RelatedUID)
                        )
                    )
                        filtered.push(searchItems);
                }

                this.filteredSearchItems = filtered;
                this.sortSearchItems();
            });
    }

    clearModalItems() {
        this.filteredSearchItems = [];
        this.sortSearchItems();
        this.searchControl.setValue('');
    }

    returnDisplayName(item) {
        // using this function to not add the "/" when we don't have item.Name
        let name = item.Domain;
        if (item.Name) {
            name += '/' + item.Name;
        }
        return name;
    }
}
