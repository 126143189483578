import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';

import { Notes } from '../../notes.model';
import { NotesService } from '../../notes.service';
import { NotificationService } from '../../../../../shared/itc/notification/notification.service';

declare var CKEDITOR: any;

@Component({
    selector: 'sds-sop-card',
    templateUrl: './sop-card.component.html',
    styleUrls: ['./sop-card.component.css'],
})
export class SopCardComponent implements OnInit, AfterViewInit {
    constructor(
        private notesService: NotesService,
        private notificationService: NotificationService
    ) {}

    @ViewChild('addModal') addModal: any;
    @ViewChild('viewModal') viewModal: any;
    @ViewChild('ckview') ckview: any;
    @ViewChild('ckadd') ckadd: any;

    showAll: boolean = false;
    editMode: boolean = false;
    invalidTitle: boolean = false;
    invalidBody: boolean = false;

    @Input() SOPs: Notes[];
    @Input() siteId: number;
    @Input() itemJSON: string;

    @Output() onUpdate = new EventEmitter<Notes[]>();

    selectedSOP: Notes = new Notes({
        Id: '',
        SiteId: -1,
        ItemId: '',
        Body: '',
        PrivateBody: '',
        Title: '',
        Category: 'SOP',
    });
    tempTitle: string;

    ngOnInit() {
        for (var name in CKEDITOR.instances) {
            CKEDITOR.instances[name].destroy(true);
        }
    }

    ngAfterViewInit() {}

    addDialog() {
        this.selectedSOP = new Notes({
            Id: '',
            SiteId: this.siteId,
            ItemId: this.itemJSON,
            Body: '',
            PrivateBody: '',
            Title: '',
            Category: 'SOP',
        });

        (this.addModal as any).show({ closable: false });
        if (CKEDITOR.instances.ckadd == null) {
            CKEDITOR.replace(this.ckadd.nativeElement);
        }
        console.log(CKEDITOR.instances);
        CKEDITOR.instances.ckadd.setData('');
        (this.addModal as any).refresh();
    }

    editDialog(sop: Notes) {
        this.selectedSOP = sop;

        (this.viewModal as any).show({ closable: false });
        if (CKEDITOR.instances.ckview == null) {
            CKEDITOR.replace(this.ckview.nativeElement);
        }
        CKEDITOR.instances.ckview.setData('');

        this.refreshModal();
    }

    refreshModal() {
        (this.viewModal as any).refresh();
    }

    editSOP(toggle: boolean) {
        CKEDITOR.instances.ckview.setData(this.selectedSOP.Body);
        this.tempTitle = this.selectedSOP.Title;
        this.editMode = toggle;
        this.refreshModal();
    }

    addSOP() {
        this.invalidTitle = false;
        this.invalidBody = false;
        let toastMSG = '';
        this.selectedSOP.Body = CKEDITOR.instances.ckadd.getData();
        if (this.selectedSOP.Title == '') {
            toastMSG = 'Procedure Title Required';
            this.invalidTitle = true;
        }
        if (this.selectedSOP.Body == '') {
            toastMSG = 'Procedure Body Required';
            this.invalidBody = true;
        }
        if (this.invalidTitle || this.invalidBody) {
            if (this.invalidTitle && this.invalidBody) {
                toastMSG = 'Procedure Title and Body Required';
            }
            this.notificationService.toast.error('Error', toastMSG);
            return;
        }
        //this.notesService.postNotes(this.selectedSOP).then((res) => {
        //    this.selectedSOP.Id = res.headers.get('X-Note-Id');
        //    this.SOPs.push(this.selectedSOP);
        //})
        //.catch((err) => console.error(err));
        this.SOPs.push(this.selectedSOP);
        this.onUpdate.emit(this.SOPs);
        (this.addModal as any).hide();
    }

    closeSOP() {
        this.invalidTitle = false;
        this.invalidBody = false;
        (this.addModal as any).hide();
    }

    saveSOP() {
        this.selectedSOP.Body = CKEDITOR.instances.ckview.getData();
        this.selectedSOP.Title = this.tempTitle;

        //this.notesService.putNotes(this.selectedSOP);
        this.onUpdate.emit(this.SOPs);

        this.editMode = false;
        this.refreshModal();
    }

    deleteSOP(i: number) {
        //this.notesService.deleteNotes(this.SOPs[i]);
        this.SOPs.splice(i, 1);
        this.onUpdate.emit(this.SOPs);
    }
}
