import { Injectable, inject } from '@angular/core';
import { NotificationService } from './itc/notification/notification.service';

export interface UtilityOptions {
    showNotification: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class UtilityService {
    notificationService = inject(NotificationService);

    constructor() {}

    async copyToClipboard(content, options?: UtilityOptions) {
        try {
            await navigator.clipboard.writeText(content);
            if (options?.showNotification === true) {
                console.log('Content copied to clipboard');
                this.notificationService.toast.info('', 'Content copied to clipboard');
            }
        } catch (err) {
            console.error('Failed to copy: ', err);
            if (options?.showNotification === true) {
                this.notificationService.toast.info('', 'Failed to copy to clipboard');
            }
        }
    }
}
