<div class="compliance-dashboard">
    <div class="ui cards" *ngIf="assessments && assessments.length > 0">
        <div class="rowtitle">Home</div>
        <div
            class="ui card trainingbox clickable"
            (click)="goAssessment(t)"
            *ngFor="let t of assessments">
            <div class="content">
                <div class="header">
                    {{ t.Name }}
                    <div style="float: right">&gt;</div>
                </div>

                <!--p><span >Assigned date</span>: <b>{{t.EffectiveDate | date:'shortDate'}}</b></p -->
                <p *ngIf="t.ExpirationDate">
                    <span>Expiration date</span>
                    :
                    <b>{{ t.ExpirationDate | date : 'shortDate' }}</b>
                </p>
                <p *ngIf="t.Status == 'complete' && t.CompletionDate">
                    <span>Completion date</span>
                    :
                    <b>{{ t.CompletionDate | date : 'shortDate' }}</b>
                </p>
            </div>
            <div class="ui bottom attached">
                <div [class]="'statusbadge ' + t.Status">{{ t.Status | status }}</div>
            </div>
        </div>
    </div>
</div>
