import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, firstValueFrom, lastValueFrom, Observable, of } from 'rxjs';

import { AnnouncementService } from 'app/core/announcements/announcement.service';

import { JwtHelper } from './auth.jwt';
import { environment } from 'environments/environment';
import { SiteRoleLabel } from './site-role-label.model';
const TOKEN_NAME: string = 'sdsportal_authtoken';

export interface RFTProduct {
    ProductShortName: string[];
    ProductName: string;
    ProductDescription: string;
    ProductCodes: string[];
    ProductChildren?: RFTProduct[];
    ProductDisabled?: boolean;
    ProductEndDate?: Date;
    DisabledTooltip?: string;
    DisabledtooltipTitle?: string;
}
export const rftProducts: RFTProduct[] = [
    {
        ProductShortName: ['CH', 'CHProduct'],
        ProductName: 'Cyber Hawk',
        ProductDescription: 'Cyber Hawk',
        ProductCodes: ['FTR_C28'],
        ProductChildren: [
            {
                ProductShortName: ['CH2019'],
                ProductName: 'CH - CH2019',
                ProductDescription: 'CH - CH2019',
                ProductCodes: ['FTR_C40'],
                ProductDisabled: false,
            },
            {
                ProductShortName: ['KSM'],
                ProductName: 'CH - KSM',
                ProductDescription: 'CH - KSM',
                ProductCodes: ['FTR_C41'],
                ProductDisabled: false,
            },
            {
                ProductShortName: ['USM'],
                ProductName: 'CH - USM',
                ProductDescription: 'CH - USM',
                ProductCodes: ['FTR_C42'],
                ProductDisabled: false,
            },
        ],
        ProductDisabled: false,
    },
    {
        ProductShortName: ['CMProduct'],
        ProductName: 'Compliance Manager',
        ProductDescription: 'Compliance Manager',
        ProductCodes: ['FTR_C33'],
        ProductChildren: [
            {
                ProductShortName: ['AGHIPAA'],
                ProductName: 'Compliance Manager 1 - HIPAA',
                ProductDescription: 'Compliance Manager 1 - HIPAA',
                ProductCodes: ['FTR_C35'],
                ProductDisabled: false,
            },
            {
                ProductShortName: ['AGGDPR'],
                ProductName: 'Compliance Manager 1 - GDPR',
                ProductDescription: 'Compliance Manager 1 - GDPR',
                ProductCodes: ['FTR_C36'],
                ProductDisabled: false,
            },
            {
                ProductShortName: ['AG2019'],
                ProductName: 'Compliance Manager 1 - AG2019',
                ProductDescription: 'Compliance Manager 1 - AG2019',
                ProductCodes: ['FTR_C50'],
                ProductDisabled: false,
            },
            {
                ProductShortName: ['UCM'],
                ProductName: 'Compliance Manager 1 - UCM',
                ProductDescription: 'Compliance Manager 1 - UCM',
                ProductCodes: ['FTR_C51'],
                ProductDisabled: false,
            },
            {
                ProductShortName: ['AGCYBERINSURANCE'],
                ProductName: 'Compliance Manager 1 - AGCYBERINSURANCE',
                ProductDescription: 'Compliance Manager 1 - AGCYBERINSURANCE',
                ProductCodes: ['FTR_C52'],
                ProductDisabled: false,
            },
            {
                ProductShortName: ['AGNIST'],
                ProductName: 'Compliance Manager 1 - AGNIST',
                ProductDescription: 'Compliance Manager 1 - AGNIST',
                ProductCodes: ['FTR_C54'],
                ProductDisabled: false,
            },
            {
                ProductShortName: ['AGCMMC'],
                ProductName: 'Compliance Manager 1 - AGCMMC',
                ProductDescription: 'Compliance Manager 1 - AGCMMC',
                ProductCodes: ['FTR_C56'],
                ProductDisabled: false,
            },
            {
                ProductShortName: ['AGUKGDPR'],
                ProductName: 'Compliance Manager 1 - AGUKGDPR',
                ProductDescription: 'Compliance Manager 1 - AGUKGDPR',
                ProductCodes: ['FTR_C44'],
                ProductDisabled: false,
            },
            {
                ProductShortName: ['CM2019SEP'],
                ProductName: 'Compliance Manager 1 - CM2019SEP',
                ProductDescription: 'Compliance Manager 1 - CM2019SEP',
                ProductCodes: ['FTR_C53'],
                ProductDisabled: false,
            },
            {
                ProductShortName: ['KCM'],
                ProductName: 'Compliance Manager 1 - KCM',
                ProductDescription: 'Compliance Manager 1 - KCM',
                ProductCodes: ['FTR_C34'],
                ProductDisabled: false,
            },
        ],
        ProductDisabled: false,
    },
    {
        ProductShortName: ['CMGRCProduct', 'CM2'],
        ProductName: 'Compliance Manager GRC',
        ProductDescription: 'Compliance Manager GRC',
        ProductCodes: ['FTR_C501'],
        ProductDisabled: false,
    },
    {
        ProductShortName: ['VulScanProduct', 'KVS'],
        ProductName: 'VulScan',
        ProductDescription: 'VulScan',
        ProductCodes: ['FTR_C111'],
        ProductDisabled: false,
    },
    {
        ProductShortName: ['NdPro'],
        ProductName: 'Network Detective Pro',
        ProductDescription: 'Network Detective Pro',
        ProductCodes: ['FTR_C57'],
        ProductChildren: [
            {
                ProductShortName: ['NdProWeb'],
                ProductName: 'Network Detective Pro - Web',
                ProductDescription: 'Network Detective Pro - Web',
                ProductCodes: ['FTR_C571'],
                ProductDisabled: false,
            },
        ],
        ProductDisabled: false,
    },
];

export const enum Roles {
    Admin = 1,
    Restricted = 2,
    Master = 3,
    //SdsSiteAdmin = 30,
    //SdsTech = 31,
    //SdsAuditor = 32,
    SiteRestricted = 33,
}

export const GlobalRoles = [
    {
        label: 'Admin',
        value: 1,
    },
    {
        label: 'Restricted',
        value: 2,
    },
    {
        label: 'Site Restricted',
        value: 33,
    },
];

//export const SiteRoles: any = {
//  Auditor: 'SDS_AUDITOR',
//  SiteAdmin: 'SDS_SITEADMIN',
//  Tech: 'SDS_TECH',
//  SME: 'AG_SME',
//  Client: 'IND_CLIENT'
//}
export const enum SiteRoles {
    Auditor = 'SDS_AUDITOR',
    SiteAdmin = 'SDS_SITEADMIN',
    Tech = 'SDS_TECH',
    SME = 'AG_SME',
    Client = 'IND_CLIENT',
    EmployeePortalAdmin = 'EMP_PORTAL_ADMIN',
    GrcReportViewer = 'GRC_REPORT_VIEWER',
}
export const enum OrgRoles {
    DiscoveryAgent = 'DISCOVERY_AGENT',
}

export const LowestCommonDenominatorSiteRoles: SiteRoleLabel[] = [
    { value: SiteRoles.SiteAdmin, label: 'Site Admin' },
    { value: SiteRoles.Tech, label: 'Technician' },
];

export const SiteTypeAccessGroupRoles: any = {
    CM2: [
        { value: SiteRoles.Auditor, label: 'Internal Auditor' },
        { value: SiteRoles.SME, label: 'Subject Matter Expert' },
        { value: SiteRoles.GrcReportViewer, label: 'Reports Viewer' },
        { value: SiteRoles.EmployeePortalAdmin, label: 'Employee Portal Admin' },
    ],
    NDPRO: [{ value: SiteRoles.Client, label: 'Client View' }],
    KVS: [{ value: SiteRoles.Client, label: 'Client View' }],
    GDPR: [
        { value: SiteRoles.Auditor, label: 'Internal Auditor' },
        { value: SiteRoles.SME, label: 'Subject Matter Expert' },
    ],
    HIPAA: [
        { value: SiteRoles.Auditor, label: 'Internal Auditor' },
        { value: SiteRoles.SME, label: 'Subject Matter Expert' },
    ],
    CYBERINSURANCE: [
        { value: SiteRoles.Auditor, label: 'Internal Auditor' },
        { value: SiteRoles.SME, label: 'Subject Matter Expert' },
    ],
    NIST: [
        { value: SiteRoles.Auditor, label: 'Internal Auditor' },
        { value: SiteRoles.SME, label: 'Subject Matter Expert' },
    ],
    CMMC: [
        { value: SiteRoles.Auditor, label: 'Internal Auditor' },
        { value: SiteRoles.SME, label: 'Subject Matter Expert' },
    ],
    UKGDPR: [
        { value: SiteRoles.Auditor, label: 'Internal Auditor' },
        { value: SiteRoles.SME, label: 'Subject Matter Expert' },
    ],
};

@Injectable({ providedIn: 'root' })
export class AuthService {
    private apiUrl: string;

    constructor(
        private http: HttpClient,
        protected router: Router,
        private announcementService: AnnouncementService
    ) {
        this.apiUrl = environment.apiUrl;
    }

    private savedUrl: string;

    private currentSiteRoles: string[];

    public twoFactorRequired: boolean;
    public twoFactorEnabled: boolean;
    public k1Required: boolean;
    public isAccountDisabled: boolean;
    public disabledMessage: string;

    private expiredProducts$ = new BehaviorSubject<RFTProduct[]>(null);

    isAuth(): boolean {
        let token = this.getToken();
        return token != null && !JwtHelper.isTokenExpired(token);
    }

    getToken(): string {
        return localStorage.getItem(TOKEN_NAME);
    }

    setToken(t: string) {
        localStorage.setItem(TOKEN_NAME, t);
        let id = JwtHelper.decodeToken(t);
        this.announcementService.setAnnouncementFtrs(id.ftrs);
    }

    clearToken() {
        localStorage.removeItem(TOKEN_NAME);
    }

    async logoutK1() {
        const url: string = this.apiUrl + 'getk1ssov2logouturl';
        let logoutUrl: string = undefined;
        await firstValueFrom(this.http.get(url)).then((res) => {
            logoutUrl = res as string;
        });

        this.clearToken();
        window.location.href = logoutUrl;
    }

    logout() {
        this.clearToken();
        this.router.navigateByUrl('/login');
    }

    logoutWithoutRedirect() {
        this.clearToken();
    }

    getIdentity(): any {
        return this.isAuth() ? JwtHelper.decodeToken(this.getToken()) : null;
    }

    userIsRole(role: Roles): boolean {
        let user = this.getIdentity();
        if (!user) return false;

        return user.roleId == role;
    }

    // used for guards, and when we want to check multiple roles
    // instead of calling userIsRole multiple times and getting identity each time
    userIsRoles(roles: Roles[]) {
        let user = this.getIdentity();
        let isRole = false;
        if (!user) {
            return false;
        }
        roles.forEach((role) => {
            if (user.roleId == role) {
                isRole = true;
            }
        });
        return isRole;
    }

    userIsKaseya(): boolean {
        let user = this.getIdentity();
        if (!user) return false;
        return user.iskaseya == 'true';
    }

    userIsInternal(): boolean {
        let user = this.getIdentity();
        if (!user) {
            return false;
        }
        return user.accountId == 'PERF100405' || user.accountId.substring(0, 3) == '@D3';
    }

    userIsSSO(): boolean {
        let user = this.getIdentity();
        if (!user) return false;
        return user.issinglesignon == 'true';
    }

    userIsK1(): boolean {
        let user = this.getIdentity();
        if (!user) return false;
        console.log('user.isk1: ' + user.isk1);
        return user.isk1 == 'True';
    }

    userIsMfaRequired(): boolean {
        let user = this.getIdentity();
        if (!user) return false;

        return user.mfaRequired == 'true';
    }

    userIsMfaEnabled(): boolean {
        let user = this.getIdentity();
        if (!user) return false;

        return user.mfaEnabled == 'true';
    }

    getSavedUrl(): string {
        let url = this.savedUrl;
        delete this.savedUrl;
        return url;
    }

    saveTargetUrl(url: string) {
        this.savedUrl = url;
    }

    itcLogin(navurl: string): Promise<any> {
        const url: string = this.apiUrl + 'k1ssov2launchandreturntourl';

        return lastValueFrom(this.http.post(url, { NavUrl: navurl }))
            .then((res) => res)
            .catch((err) => {
                if (err.status == 302) {
                    window.location.href = err.error;
                }
            });
    }

    isResetPwdGuidValid(guid: string): Observable<boolean> {
        const url: string = this.apiUrl + 'CheckResetPasswordGuid';

        return this.http.post<boolean>(url, { Guid: guid });
    }

    resetPassword(guid: string, newPwd: string): Promise<boolean> {
        const url: string = this.apiUrl + 'ChangePassword';

        return lastValueFrom(this.http.post<boolean>(url, { Guid: guid, NewPassword: newPwd }));
    }

    clearCurrentSiteRoles() {
        this.currentSiteRoles = null;
    }

    getCurrentSiteRoles(): string[] {
        return this.currentSiteRoles || [];
    }

    setCurrentSiteRoles(roles: string) {
        if (roles) this.currentSiteRoles = roles.split(',');
    }

    isOnlySME(): boolean | null {
        if (!this.currentSiteRoles || !(this.currentSiteRoles instanceof Array)) return null;

        return this.currentSiteRoles.length == 1 && this.currentSiteRoles[0] == SiteRoles.SME;
    }

    isClient(): boolean | null {
        if (!this.currentSiteRoles || !(this.currentSiteRoles instanceof Array)) return null;

        return this.currentSiteRoles.length == 1 && this.currentSiteRoles[0] == SiteRoles.Client;
    }

    getProducts(productCodes?: string): any {
        let products: any = {};
        let haystack;

        if (productCodes !== undefined) {
            if (productCodes === '') return products;
            haystack = productCodes;
        } else {
            haystack = this.getIdentity().ftrs;
        }

        if (!haystack) return products;
        rftProducts.forEach((product) => {
            product.ProductCodes.forEach((code) => {
                if (haystack.indexOf(code) > -1) {
                    product.ProductShortName.forEach((sn) => {
                        products[sn] = true;
                    });
                }
                if (product.ProductChildren) {
                    product.ProductChildren.forEach((child) => {
                        if (haystack.indexOf(child.ProductCodes[0]) > -1) {
                            child.ProductShortName.forEach((csn) => {
                                products[csn] = true;
                            });
                            product.ProductShortName.forEach((sn) => {
                                products[sn] = true;
                            });
                        }
                    });
                }
            });
        });

        /* Other products */
        if (haystack.indexOf('FTR_BP') > -1) {
            products.BP = true;
        }
        if (haystack.indexOf('FTR_C58') > -1) {
            products.LegacySites = true;
        }
        if (haystack.indexOf('FTR_C112') > -1) {
            products.HEVS = true;
        }

        return products;
    }
}
