import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'show-hide',
    template: `
        <div class="show-hide">
            <ng-content *ngIf="state === 'open'"></ng-content>
            <button (click)="toggleState()">
                {{ state === 'open' ? closeText : openText }}
                <fa-icon
                    [icon]="state === 'open' ? 'chevron-up' : 'chevron-down'"
                    *ngIf="showArrow"></fa-icon>
            </button>
        </div>
    `,
    styles: [
        `
            .show-hide {
                position: relative;
                button {
                    border: 0;
                    margin: 0;
                    padding: 0;
                    background: none;
                    color: var(--text-link-primary);
                    font-weight: 500;
                    cursor: pointer;
                    fa-icon {
                        margin-left: 4px;
                    }
                }
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShowHideComponent {
    @Input() openText = 'Show more';
    @Input() closeText = 'Show less';
    @Input() showArrow = true;
    @Input() state: 'open' | 'closed' = 'closed';

    toggleState() {
        if (this.state === 'open') {
            this.state = 'closed';
        } else {
            this.state = 'open';
        }
    }
}
