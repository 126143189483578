<h4>Computer Info</h4>
<table class="ui table">
    <tbody>
        <tr>
            <td><b>Computer Name</b></td>
            <td>{{ diag?.Domain }}\{{ diag?.Computer }}</td>
        </tr>
        <tr>
            <td><b>IP Address</b></td>
            <td>{{ diag ? diag['IP Address'] : '' }}</td>
        </tr>
    </tbody>
</table>

<h4>Missing Updates</h4>
<p>{{ diag ? diag['Missing Updates'] : '' }}</p>
