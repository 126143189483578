<sds-breadcrumb-trail [crumbs]="breadcrumbs"></sds-breadcrumb-trail>

<header class="pageHeader cm1HasSelectAssessment">
    <h1>Files</h1>
</header>

<sds-loader [complete]="loadingComplete">
    <itc-banner type="info" *ngIf="files && !files.length">
        No Files found for the current assessment.
    </itc-banner>

    <itc-banner type="info" title="No Assessments Found" *ngIf="noAssessment">
        There are not currently any assessments for this site. You can start the assessment from the
        To Do list, after installing the Compliance Manager Server.
    </itc-banner>

    <table sortable-table class="itc-table" *ngIf="files?.length" (sorted)="onSorted($event)">
        <thead>
            <tr>
                <th sortable-column="fileid">Doc ID</th>
                <th sortable-column="filename">Original Filename</th>
                <th sortable-column="lastmodified">Upload Date</th>
                <th sortable-column="uploadedby">Uploaded By</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let file of files">
                <td>{{ file.fileid }}</td>
                <td>{{ file.filename }}</td>
                <td>{{ file.lastmodified | date : 'medium' }}</td>
                <td>{{ file.uploadedby }}</td>
                <td>
                    <itc-button
                        type="icon"
                        icon="fa-trash-can"
                        tooltip="Remove"
                        ttposition="top"
                        (onclick)="removeAttachment(file.fileid)"></itc-button>
                </td>
            </tr>
        </tbody>
    </table>
</sds-loader>

<sm-modal title="Attachment Can Not Be Removed" class="lg" #attachmentUsedModal>
    <modal-content>
        <p>Document used in previous assessments cannot be removed.</p>
    </modal-content>
    <modal-actions>
        <itc-button label="Close" icon="fa-xmark" type="secondary" class="cancel"></itc-button>
    </modal-actions>
</sm-modal>
<sm-modal title="Confirm Attachment Removal" class="lg" #attachmentNotUsedModal>
    <modal-content>
        <p>
            Removing a document will permanently remove it from the repository. This action cannot
            be undone. Do you wish to proceed anyways?
        </p>
    </modal-content>
    <modal-actions>
        <itc-button
            label="Delete File"
            type="danger"
            icon="fa-trash-can"
            class="approve"
            (onclick)="removeAttachmentPermanently()"></itc-button>
        <itc-button label="Cancel" icon="fa-xmark" type="secondary" class="cancel"></itc-button>
    </modal-actions>
</sm-modal>

<sm-modal title="Checking For File Usage" class="lg notClosable" #checkingAttachmentModal>
    <modal-content>
        <p>
            You cannot delete a file if it is part of a previous assessment. Please wait while we
            check to make sure you can delete the file. This may take some time.
        </p>
        <p style="position: relative">
            <sds-loader [complete]="checkingLoadingComplete"></sds-loader>
        </p>
    </modal-content>
</sm-modal>
