import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';
import { AuthHttp } from 'app/core/auth';

import { Control, ClonedControl } from './control.model';
import { ControlTemplate } from './controltemplate.model';
import { ControlsRequirement } from 'app/settings';
import { Requirement } from '../standards/requirement.model';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ControlsService {
    private apiUrl: string;

    constructor(private http: AuthHttp) {
        this.apiUrl = environment.apiUrl;
    }

    getAccountControls(): Promise<Control[]> {
        const url: string = this.apiUrl + `AccountControls`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getSystemControls(): Promise<Control[]> {
        const url: string = this.apiUrl + `SystemControls`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getEditControl(controlId): Promise<Control> {
        const url: string = this.apiUrl + `AccountControl/${controlId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getAccountControlsForIso(iso: string, siteId: number = null): Promise<Control[]> {
        const url: string =
            this.apiUrl + `AccountControlsForIso/${iso}` + (siteId ? `?siteId=${siteId}` : '');
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getSystemControlsForIso(iso: string, siteId: number = null): Promise<Control[]> {
        const url: string =
            this.apiUrl + `SystemControlsForIso/${iso}` + (siteId ? `?siteId=${siteId}` : '');
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getSiteControls(siteId: number): Promise<Control[]> {
        const url: string = this.apiUrl + `SiteControls/${siteId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getSiteControlsForIso(siteId: number, iso: string): Promise<Control[]> {
        const url: string = this.apiUrl + `SiteControlsForIso/${siteId}/${iso}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getSelectedControls(siteId: number): Promise<Control[]> {
        const url: string = this.apiUrl + `SelectedControls/${siteId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }
    setSelectedControls(siteId: number, controlIds: number[]): Promise<any> {
        const url: string = this.apiUrl + `SelectControls/${siteId}`;
        return this.http
            .post(url, { controlIds: controlIds })
            .toPromise()
            .then((res) => res);
    }
    getSelectedControlsForIso(siteId: number, iso: string): Promise<Control[]> {
        const url: string = this.apiUrl + `SelectedControlsForIso/${siteId}/${iso}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    updateControl(control: Control): Promise<Control> {
        const url: string = this.apiUrl + `Control`;
        return this.http
            .put(url, control)
            .toPromise()
            .then((res) => res);
    }

    addControl(control: Control): Promise<Control> {
        const url: string = this.apiUrl + `Control`;
        return this.http
            .post(url, control)
            .toPromise()
            .then((res) => res);
    }

    deleteControl(controlId: number, siteId: number = -1): Promise<any> {
        const url: string = this.apiUrl + `Control/${controlId}`;
        let body = {
            siteId: siteId,
        };
        return this.http
            .post(url, body)
            .toPromise()
            .then((res) => res);
    }

    cloneControl(
        controlId: number,
        newControlId: string,
        newControlName: string,
        newControlCulture: string,
        siteId?: number
    ): Promise<any> {
        const url: string = this.apiUrl + `Control/Clone/${controlId}`;
        let postBody = {
            newControlId: newControlId,
            newControlName: newControlName,
            newControlCulture: newControlCulture,
            siteId: siteId ? siteId : -1,
        };
        return this.http
            .post(url, postBody)
            .toPromise()
            .then((res) => res);
    }

    addAccountControl(controlId: number): Promise<any> {
        const url: string = this.apiUrl + `AccountControl`;
        return this.http
            .post(url, { controlId: controlId })
            .toPromise()
            .then((res) => res);
    }

    deleteAccountControl(controlId: number): Promise<any> {
        const url: string = this.apiUrl + `AccountControl/${controlId}`;
        return this.http
            .delete(url)
            .toPromise()
            .then((res) => res);
    }

    addSiteControl(controlId: number, siteId: number): Promise<any> {
        const url: string = this.apiUrl + `SiteControl`;
        return this.http
            .post(url, { controlId: controlId, siteId: siteId })
            .toPromise()
            .then((res) => res);
    }

    deleteSiteControl(controlId: number, siteId: number): Promise<any> {
        const url: string = this.apiUrl + `SiteControl/${siteId}/${controlId}`;
        return this.http
            .delete(url)
            .toPromise()
            .then((res) => res);
    }

    addControlTemplate(controlTemplate: ControlTemplate): Promise<ControlTemplate> {
        const url: string = this.apiUrl + `ControlTemplate`;
        return this.http
            .post(url, controlTemplate)
            .toPromise()
            .then((res) => res);
    }

    updateControlTemplate(controlTemplate: ControlTemplate): Promise<ControlTemplate> {
        const url: string = this.apiUrl + `ControlTemplate`;
        return this.http
            .put(url, controlTemplate)
            .toPromise()
            .then((res) => res);
    }

    getControlTemplates(): Promise<ControlTemplate[]> {
        const url: string = this.apiUrl + `ControlTemplates`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getSystemControlTemplates(): Promise<ControlTemplate[]> {
        const url: string = this.apiUrl + `SystemControlTemplates`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    deleteControlTemplate(controlTemplateId: number): Promise<any> {
        const url: string = this.apiUrl + `ControlTemplate/${controlTemplateId}`;
        return this.http
            .delete(url)
            .toPromise()
            .then((res) => res);
    }

    cloneControlTemplate(controlTemplateId: number, newControlTemplateName: string): Promise<any> {
        const url: string = this.apiUrl + `ControlTemplate/Clone/${controlTemplateId}`;
        let postBody = { newControlTemplateName: newControlTemplateName };
        return this.http
            .post(url, postBody)
            .toPromise()
            .then((res) => res);
    }

    exportRBA(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `ExportRBA/${siteId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    exportControlsAssessment(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `ExportControlsAssessment/${siteId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }
    exportRequirementsAssessment(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `ExportRequirementsAssessment/${siteId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    exportPOAM(siteId: number, assessmentName: string = '__current'): Promise<any> {
        const url: string = this.apiUrl + `ExportPOAM/${siteId}?assessmentName=${assessmentName}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    ExportPolicyProcedure(
        siteId: number,
        isCommonControl: boolean,
        standardId: number
    ): Promise<any> {
        const url: string = this.apiUrl + `ExportPolicyProcedure/${siteId}`;
        let postBody = isCommonControl
            ? { isCommonControl: true }
            : { isCommonControl: false, standardId: standardId };
        return this.http
            .post(url, postBody)
            .toPromise()
            .then((res) => res);
    }

    getCloneControlName(cloneControl: ClonedControl, allControls: Control[]) {
        let origControlId = cloneControl.OrigControlId,
            origShortDesc = cloneControl.OrigShortDesc,
            culture = cloneControl.Culture,
            controlId = cloneControl.OrigControlId,
            shortDesc = cloneControl.OrigShortDesc;
        let controlIdMaxLenght = 45;
        if (
            allControls.findIndex(
                (e) =>
                    e.ShortDesc.LocalizedText[culture] == origShortDesc ||
                    e.ControlId == origControlId
            ) > -1
        ) {
            while (true) {
                shortDesc = shortDesc + ' (copy)';
                controlId = controlId + ' (copy)';

                let i = allControls.findIndex(
                    (e) =>
                        e.ShortDesc.LocalizedText[culture] == shortDesc || e.ControlId == controlId
                );
                if (i == -1) {
                    if (controlId?.length > controlIdMaxLenght) {
                        controlId = controlId.substring(0, 37) + ' (copy)';
                    }

                    break;
                }
            }
        }
        cloneControl.ControlId = controlId;
        cloneControl.ShortDesc = shortDesc;
    }

    exportVendorAssessment(siteId: number, vendorId: number): Promise<any> {
        const url: string = this.apiUrl + `ExportVendorAssessment/${siteId}/${vendorId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    // updates guidance and procedures for this control and site
    updateControlTextOverrides(siteId: number, control: Control) {
        const url: string = this.apiUrl + `UpdateControlTextOverrides/${siteId}`;
        return this.http
            .post(url, control)
            .toPromise()
            .then((res) => res);
    }

    // reverts guidance and procedures for this control and site
    revertControlTextOverrides(siteId: number, controlId: number) {
        const url: string = this.apiUrl + `RevertControlTextOverrides/${siteId}/${controlId}`;
        return this.http
            .delete(url)
            .toPromise()
            .then((res) => res);
    }
    getDefaultProcedures(siteId: number, controlId: number): Promise<any> {
        const url: string = this.apiUrl + `GetDefaultProcedures/${siteId}/${controlId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getControlsByRequirement(requirementId: number) {
        const url: string = this.apiUrl + `GetControlsByRequirement/${requirementId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    upsertControlRequirement(controlReq: ControlsRequirement) {
        const url: string = this.apiUrl + `UpsertControlRequirement`;
        return this.http
            .put(url, {
                requirement: controlReq.requirement,
                controls: controlReq.addedControls,
                deleteControls: controlReq.deleteControls,
            })
            .toPromise()
            .then((res) => res);
    }

    deleteControlsRequirement(requirement: Requirement, controls: Control[]) {
        const url: string = this.apiUrl + `DeleteRequirementControl`;
        return this.http
            .put(url, {
                requirement: requirement,
                controls: controls,
            })
            .toPromise()
            .then((res) => res);
    }

    getArchivedControls(siteId: number, archiveGui: string): Promise<any> {
        const url: string = this.apiUrl + `GetArchivedControls/${siteId}/${archiveGui}`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    exportArchivedPOAM(siteId: number, assessmentGuid: string): Promise<any> {
        const url: string = this.apiUrl + `ExportArchivedPOAM/${siteId}/${assessmentGuid}`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }
}
