<ng-container>
    <span style="color: #aaa" *ngIf="!activeQuestion?.Control.Requirements?.length">
        No related requirements.
    </span>
    <ng-container *ngIf="activeQuestion?.Control.Requirements.length">
        <div class="overflowSidebarTable">
            <table class="itc-table sidebarTable relatedRequirementsTable">
                <thead>
                    <tr>
                        <th style="white-space: nowrap; width: 180px">Response</th>
                        <th style="white-space: nowrap; width: 130px">Requirement ID</th>
                        <th style="white-space: nowrap; width: 200px">Description</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let cr of activeQuestion?.Control.Requirements"
                        style="cursor: pointer"
                        (click)="onShowReadOnly(cr)">
                        <td>
                            <itc-button
                                type="color"
                                [color]="responseColor[cr.Response] || '#fff'"
                                class="RBAButton darkText"
                                style="white-space: nowrap"
                                [class.unanswered]="!cr.Response">
                                {{ cr.Response ? cr.Response : 'Unanswered' }}
                            </itc-button>
                        </td>
                        <td>
                            <div truncateText truncateWidth="98px" [title]="cr.RequirementId">
                                {{ cr.RequirementId }}
                            </div>
                        </td>
                        <td>
                            <span
                                truncateText
                                truncateWidth="220px"
                                [truncateLines]="2"
                                [title]="cr.ShortDesc.LocalizedText[culture]">
                                {{ cr.ShortDesc.LocalizedText[culture] }}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-container>
</ng-container>
