<div class="input-container">
    <sm-input
        name="searchInput"
        debounce="200"
        [control]="searchKeyControl"
        (input)="searchSites()"
        class="searchInput"
        placeholder="Search keywords..."></sm-input>

    <div class="checkBox">
        <itc-checkbox
            [disabled]="isCheckboxRestricted"
            [(ngModel)]="checkStatus"
            (onchange)="filterSites()"
            name="checkboxOneTimeExclusions"></itc-checkbox>
        Only My Sites
    </div>
    <div class="filterSitesCheck">
        <strong>{{ filteredSites?.length }} of {{ allSites?.length }}</strong>
        &nbsp;Results
    </div>
</div>

<div>
    <table
        class="itc-table scrollable sortable top-aligned"
        sortable-table
        sortColumn="Name"
        sortDirection="asc"
        (sorted)="onSortSites($event)">
        <thead>
            <tr>
                <th sortable-column="Name">Site</th>
                <th sortable-column="Organization">Organization</th>
                <th sortable-column="Type">Assessment Type</th>
                <th>Assessment Status</th>
                <th class="scan">Last 3 Scans</th>
                <th class="reports">Reports</th>
                <th sortable-column="OpenTodos">To Do</th>
                <th sortable-column="ApplianceOnline">Appliance</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let site of filteredSites" [class.expiredSite]="site.IsLicenseInactive">
                <td
                    class="truncateWidth"
                    [tooltip]="site.Tooltip ? site.Tooltip : null"
                    [ttTitle]="site.Tooltip ? 'Subscription Expired' : null"
                    ttPosition="bottom-right">
                    <a
                        class="linkColor breakWord"
                        [routerLink]="null"
                        (click)="siteDashboardService.goToSite(site)">
                        {{ site.Name }}
                    </a>
                </td>
                <td
                    class="truncateColorText"
                    [tooltip]="site.Tooltip ? site.Tooltip : null"
                    [ttTitle]="site.Tooltip ? 'Subscription Expired' : null"
                    ttPosition="bottom-right">
                    <a
                        class="breakWord link-org"
                        [routerLink]="null"
                        (click)="siteDashboardService.goToOrganization(site)">
                        {{ site.Organization ? site.Organization : "Unassigned"}}
                    </a>
                </td>
                <td
                    [tooltip]="site.Tooltip ? site.Tooltip : null"
                    [ttTitle]="site.Tooltip ? 'Subscription Expired' : null"
                    ttPosition="bottom-right">
                    <a
                        style="color: black"
                        [routerLink]="null"
                        (click)="siteDashboardService.goToSiteDashboard(site)">
                        {{ getAssessmentType(site) }}
                    </a>
                </td>
                <td
                    [tooltip]="site.Tooltip ? site.Tooltip : null"
                    [ttTitle]="site.Tooltip ? 'Subscription Expired' : null"
                    ttPosition="bottom-right">
                    <a
                        class="linkColor"
                        [ngStyle]="{
                            color: site.AssessmentStatus === 'Not Started' ? 'black' : '#166EFA',
                        }"
                        [routerLink]="null"
                        (click)="siteDashboardService.goToSiteDashboard(site)">
                        {{
                            site.AssessmentStatus === 'Not Started'
                                ? site.AssessmentStatus
                                : (site.AssessmentStatus | accountDate)
                        }}
                    </a>
                </td>
                <td
                    style="display: flex; justify-content: flex-end; padding-bottom: inherit"
                    [tooltip]="site.Tooltip ? site.Tooltip : null"
                    [ttTitle]="site.Tooltip ? 'Subscription Expired' : null"
                    ttPosition="bottom-right">
                    <scan-status-tooltips
                        *ngIf="site.Scan3Id"
                        status="{{ getStatusScan(site, 3) }}"
                        url="{{ getScanUrl(site, 3) }}"
                        text="{{ getToolTip(site, 3) }}"
                        [status]="getStatusScanClass(site, 3)"
                        title="{{ getTitle(site, 3) }}"></scan-status-tooltips>
                    <scan-status-tooltips
                        *ngIf="site.Scan2Id"
                        status="{{ getStatusScan(site, 2) }}"
                        url="{{ getScanUrl(site, 2) }}"
                        text="{{ getToolTip(site, 2) }}"
                        [status]="getStatusScanClass(site, 2)"
                        title="{{ getTitle(site, 2) }}"></scan-status-tooltips>
                    <scan-status-tooltips
                        *ngIf="site.Scan1Id"
                        status="{{ getStatusScan(site, 1) }}"
                        url="{{ getScanUrl(site, 1) }}"
                        text="{{ getToolTip(site, 1) }}"
                        [status]="getStatusScanClass(site, 1)"
                        title="{{ getTitle(site, 1) }}"></scan-status-tooltips>
                </td>
                <td
                    class="paddingLeft"
                    [tooltip]="site.Tooltip ? site.Tooltip : null"
                    [ttTitle]="site.Tooltip ? 'Subscription Expired' : null"
                    ttPosition="bottom-right">
                    <a
                        class="linkColor"
                        [ngStyle]="{ color: site.ReportLastGenerated ? '#166EFA' : 'black' }"
                        [routerLink]="null"
                        (click)="siteDashboardService.goToSiteDashboard(site)">
                        {{
                            site.ReportLastGenerated
                                ? (site.ReportLastGenerated | accountDate)
                                : 'N/A'
                        }}
                    </a>
                </td>
                <td
                    [ngStyle]="{
                        'padding-bottom': 'inherit',
                        'padding-top': site.OpenTodos > 0 ? '6px' : '',
                    }"
                    [tooltip]="site.Tooltip ? site.Tooltip : null"
                    [ttTitle]="site.Tooltip ? 'Subscription Expired' : null"
                    ttPosition="bottom-right">
                    <span
                        [tooltip]="site.IsLicenseInactive ? null : 'Open ToDos'"
                        (click)="siteDashboardService.goToTodo(site)"
                        *ngIf="site.OpenTodos > 0"
                        class="ui label circular circleCount todoStyle">
                        {{ site.OpenTodos }}
                    </span>

                    <fa-icon
                        [tooltip]="site.IsLicenseInactive ? null : 'Open ToDos'"
                        class="iconOpenTodo"
                        (click)="siteDashboardService.goToTodo(site)"
                        *ngIf="site.OpenTodos === 0"
                        [icon]="['fas', 'circle-check']"></fa-icon>
                </td>
                <td
                    style="padding-bottom: inherit"
                    [tooltip]="site.Tooltip ? site.Tooltip : null"
                    [ttTitle]="site.Tooltip ? 'Subscription Expired' : null"
                    ttPosition="bottom-right">
                    <span
                        style="cursor: pointer"
                        (click)="siteDashboardService.goToSite(site)"
                        class="ui label circular circleCount"
                        [ngStyle]="getDynamicStyles(site.ApplianceOnline)">
                        {{ site.ApplianceOnline ? 'Online' : 'Offline' }}
                    </span>
                </td>
            </tr>
        </tbody>
    </table>

    <empty-state
        icon="results"
        title="No Sites Found"
        text="No sites were found matching the given criteria."
        *ngIf="!filteredSites?.length"></empty-state>
</div>
