import { Component, EventEmitter, Input, Output, SimpleChange } from '@angular/core';
import { ItcPaginatorInfo } from './itc-paginator-info.interface';

@Component({
    selector: 'itc-paginator',
    templateUrl: './itc-paginator.component.html',
    styleUrls: ['./itc-paginator.component.scss'],
})
export class ItcPaginatorComponent {
    @Input() set pageNumber(value: number) {
        if (value) {
            this.currentPage = value;
        }
    }
    @Input() set pageSize(value: number) {
        if (value) {
            this._pageSize = value;
        }
    }
    get pageSize() {
        return this._pageSize;
    }
    @Input() set data(value: any) {
        if (value) {
            this._allItems = value;
            this._itemCount = value.length;
            this.processPages();
        }
    }
    get itemCount() {
        return this._itemCount;
    }
    @Input() set allItems(value: any[]) {
        if (value) {
            this._allItems = value;
            this._itemCount = value.length;
            this.processPages();
        }
    }
    get allItems() {
        return this._allItems;
    }
    @Input() set totalItemCount(totalCount: number) {
        // you can use this instead of passing in the array
        // like when using an observable
        if (totalCount) {
            this._itemCount = totalCount;
            this.processPages();
        }
    }
    @Input() hidden = false;

    _pageSize = 25;
    _pageSizeOptions = [10, 25, 50, 100];
    _itemCount = 1;
    _allItems: any[] = [];
    lastPage = 1;
    currentPage: number | null = null;
    pageList: number[] = [];

    // legacy
    @Input() sizes: any;

    @Output() onPage: EventEmitter<any> = new EventEmitter<any>();
    @Output() pagedItems: EventEmitter<any> = new EventEmitter<any>();
    @Output() onPageWithInfo: EventEmitter<ItcPaginatorInfo> = new EventEmitter<ItcPaginatorInfo>();

    processPages(): void {
        this.currentPage = this.currentPage || 1;

        this.lastPage = Math.ceil(this.itemCount / this._pageSize) || 1;
        this.currentPage = this.currentPage > this.lastPage ? this.lastPage : this.currentPage;
        this.updatePageList();
        this.emitPage();
    }

    setPage(pageNum: number | 'next' | 'prev'): void {
        if (pageNum === 'prev') {
            if (this.currentPage > 0) this.currentPage--;
        } else if (pageNum === 'next') {
            if (this.currentPage < this.lastPage) this.currentPage++;
        } else {
            this.currentPage = pageNum;
        }

        this.updatePageList();
        this.emitPage();
    }

    updatePageSize(): void {
        this.processPages();
        this.emitPage();
    }

    updatePageList(): void {
        let pageList = [1, 2, 3, 4, 5, -1, this.lastPage];
        if (this.lastPage <= 6) {
            pageList = Array.from(Array(this.lastPage + 1).keys()).slice(1);
        }

        if (this.currentPage > 5) {
            if (this.currentPage > this.lastPage - 5) {
                pageList = [
                    1,
                    -1,
                    this.lastPage - 4,
                    this.lastPage - 3,
                    this.lastPage - 2,
                    this.lastPage - 1,
                    this.lastPage,
                ];
            } else {
                pageList = [
                    1,
                    -1,
                    this.currentPage - 1,
                    this.currentPage,
                    this.currentPage + 1,
                    -1,
                    this.lastPage,
                ];
            }
        }
        this.pageList = pageList;
    }

    pageContent(): any[] {
        return this.allItems.slice(
            (this.currentPage - 1) * this.pageSize,
            (this.currentPage - 1) * this.pageSize + this.pageSize
        );
    }

    resetPageNumber(): void {
        this.currentPage = 1;
    }

    emitPage(): void {
        setTimeout(() => {
            this.pagedItems.emit(this.pageContent());
            this.onPage.emit(this.pageContent());
            this.onPageWithInfo.emit({
                items: this.pageContent(),
                pageIndex: this.currentPage - 1,
                pageCount: this.itemCount / this.pageSize,
                pageSize: this.pageSize,
            });
        });
    }
}
