export class I18N {
    Id: number;
    LocalizedText: any; //  ex: {'en': 'Tiger', 'es':'Tigre', 'nl':'tijger'}
    Selected?: boolean; // used for procedures in particular

    constructor() {
        this.Id = -1;
        this.LocalizedText = {};
    }
}
