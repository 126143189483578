import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'sds-ndp-global-user-report-sets',
    templateUrl: './ndp-global-user-report-sets.component.html',
    styleUrls: ['./ndp-global-user-report-sets.component.scss'],
})
export class NdpGlobalUserReportSetsComponent implements OnInit {
    loadingComplete: boolean = true;
    title = 'User Report Sets';
    breadcrumbs = [
        { path: '../..', text: 'Admin' },
        { path: '..', text: 'Network Detective Pro' },
        { path: '.', text: this.title },
    ];
    constructor() {}

    ngOnInit(): void {}
}
