<div *ngIf="!cwrLookupDone">
    <div class="ui right action left icon input" [class.disabled]="!connection">
        <i class="search icon"></i>
        <input type="text"
                style="width: calc(100% - 26px);margin-bottom:0px;"
                [id]="searchTxtId"
                placeholder="Company Name"
                [(ngModel)]="cwrCompanySearchTxt" />
        <itc-button (onclick)="findCWRCompanies()"
                    [loading]="loadingCWR"
                    [disabled]="!connection">
            Lookup
        </itc-button>
    </div>
</div>
<div *ngIf="cwrLookupDone" style="display:flex">
    <itc-button (onclick)="goBackToLookup()"
                tooltip="Return to Lookup"
                ttPosition="top"
                type="secondary icon"
                icon="fa-arrow-left"></itc-button>
    <p-dropdown [options]="connectWiseCompanies"
                optionLabel="name"
                optionValue="identifier"
                [autoDisplayFirst]="false"
                #companyDropdown
                (onChange)="companySelected(companyDropdown.value)">
    </p-dropdown>
</div>

