import { inject, Pipe, type PipeTransform } from '@angular/core';
import { NdpService } from 'app/sites/shared/ndp.service';
import { AudITReportSummary } from '../audIT.model';

@Pipe({
    name: 'audIT',
    standalone: true,
})
export class AudITPipe implements PipeTransform {
    private ndpService = inject(NdpService);

    transform(
        value: AudITReportSummary | string | number,
        pipe: string,
        ...args: unknown[]
    ): unknown {
        if (pipe === 'monthlyExpense' && typeof value !== 'string' && typeof value !== 'number') {
            let multiplier = 100 / Math.max(value.Score, 1);
            let start = (value.Details.Expenses.ITMonthlyExpenseStart * multiplier);
            let end = (value.Details.Expenses.ITMonthlyExpenseEnd * multiplier);
            return (
                this.ndpService.currencyFormatter.format(start) +
                ' - ' +
                this.ndpService.currencyFormatter.format(end)
            );
        }
        if (pipe === 'currency' && typeof value === 'number') {
            return this.ndpService.currencyFormatter.format(value);
        }
        if (pipe === 'currencyRound' && typeof value === 'number') {
            return this.ndpService.currencyFormatterNoCents.format(value);
        }
    }
}
