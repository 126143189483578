import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';
import { AuthHttp } from 'app/core/auth';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class ReportsService {
    private apiUrl: string;

    constructor(private http: AuthHttp) {
        this.apiUrl = environment.apiUrl;
    }

    getReportsList(siteId: number, assessmentName: string): Promise<any> {
        const url: string =
            this.apiUrl + `Sites/${siteId}/Reports?assessmentName=${assessmentName}`;
        console.log('getting reports in service');
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getArchivedGrcReportsList(
        siteId: number,
        assessmentName: string,
        assessmentGuid: string
    ): Promise<any> {
        const url: string =
            this.apiUrl +
            `Sites/${siteId}/ArchivedReports?assessmentName=${assessmentName}&assessmentGuid=${assessmentGuid}`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    downloadReport(
        siteId: number,
        reportName: string,
        assessmentName: string,
        locale: string
    ): Promise<any> {
        const url: string =
            this.apiUrl +
            `Sites/${siteId}/Reports/Download?reportName=${encodeURIComponent(
                reportName
            )}&assessmentName=${assessmentName}&locale=${locale}`;

        return this.http
            .get(url, { observe: 'response' })
            .toPromise()
            .then((res) => (res.status == 200 ? res.body : null));
    }

    downloadArchivedAssessmentReport(
        siteId: number,
        reportName: string,
        locale: string,
        assessmentGuid: string
    ): Promise<any> {
        const url: string =
            this.apiUrl +
            `Sites/${siteId}/Reports/DownloadArchivedAssessment?reportName=${encodeURIComponent(
                reportName
            )}&locale=${locale}&assessmentGuid=${assessmentGuid}`;

        return lastValueFrom(this.http.get(url, { observe: 'response' })).then((res) =>
            res.status == 200 ? res.body : null
        );
    }

    downloadReportsZip(siteId: number, assessmentName: string, locale: string): Promise<any> {
        const url: string =
            this.apiUrl +
            `Sites/${siteId}/Reports/DownloadZip?assessmentName=${assessmentName}&locale=${locale}`;

        return this.http
            .get(url, { observe: 'response' })
            .toPromise()
            .then((res) => (res.status == 200 ? res.body : null));
    }

    downloadArchivedAssessmentReportsZip(
        siteId: number,
        locale: string,
        assessmentGuid: string
    ): Promise<any> {
        const url: string =
            this.apiUrl +
            `Sites/${siteId}/Reports/DownloadArchivedAssessmentReportsZip?locale=${locale}&assessmentGuid=${assessmentGuid}`;

        return lastValueFrom(this.http.get(url, { observe: 'response' })).then((res) =>
            res.status == 200 ? res.body : null
        );
    }

    reuploadDattoWorkplaceReport(siteId: number, reportName: string, locale: string) {
        const url = this.apiUrl + `Sites/${siteId}/ReuploadReportToDatto?reportName`;
        const body = {
            reportName: reportName,
            locale: locale,
        };

        return lastValueFrom(this.http.post(url, body, { observe: 'response' })).then((res) => res);
    }
}
