import { Directive } from '@angular/core';
import {
    AbstractControl,
    NG_VALIDATORS,
    ValidationErrors,
    Validator,
    ValidatorFn,
} from '@angular/forms';

@Directive({
    selector: '[validEmail]',
    providers: [{ provide: NG_VALIDATORS, useExisting: validEmailValidator, multi: true }],
})
export class validEmailValidator implements Validator {
    constructor() {}

    validate(control: AbstractControl): ValidationErrors | null {
        return validateEmail(control.value);
    }
}

export function EmailValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        return validateEmail(control.value);
    };
}

export function validEmail(control: AbstractControl): { [key: string]: any } | null {
    return validateEmail(control.value);
}

export function isValidEmail(val): boolean {
    return validateEmail(val) == null;
}

function validateEmail(val): ValidationErrors | null {
    if (val === null || val === undefined || val === '') {
        return null;
    }

    const regex =
        /^([a-zA-Z0-9!#\$%&\'\*\+\-\/=?^_`\{\|\}~\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,})$/;
    let match = regex.exec(val);

    if (match) {
        return null; //Valid email
    }

    return { invalidEmail: true };
}

export function isValidMultiEmail(val): boolean {
    return validateMultiEmail(val) == null;
}

function validateMultiEmail(val): ValidationErrors | null {
    if (val === null || val === undefined || val === '') {
        return null;
    }

    const regex =
        /^(([a-zA-Z0-9!#\$%&\'\*\+\-\/=?^_`\{\|\}~\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,}(,\s?)?))+$/;
    let match = regex.exec(val);

    if (match) {
        return null; //Valid email
    }

    return { invalidEmail: true };
}
