<sds-modal-debug
    *ngIf="false"
    [modals]="[
        'addSiteModal',
        'moveSiteModal',
        'privacyModal',
        'privacyCompleteModal',
        'privacyErrorModal',
        'smbErrorModal'
    ]"
    [scope]="this"></sds-modal-debug>

<sds-loader [complete]="loadingComplete">
    <div>
        <div>
            <sds-loader [complete]="sitesLoadingComplete">
                <div class="sitesContainer">
                    <header class="pageHeader">
                        <h1>Sites</h1>
                        <div class="flex-right">
                            <itc-button
                                type="primary"
                                icon="fa-hard-drive"
                                [routerLink]="['discovery-agents']"
                                *ngIf="
                                    organizationName !== '__all' &&
                                    (canAdd || hasAgentAccess) &&
                                    selectedOrg?.orgLevelDiscoveryAgents
                                ">
                                Discovery Agents
                            </itc-button>
                            <itc-button
                                type="secondary"
                                [disabled]="!sitesDashboard.length"
                                (onclick)="showMoveSiteModal()"
                                *ngIf="canAdd && organizations?.length">
                                Move Site
                            </itc-button>
                            <itc-button
                                type="primary"
                                icon="fa-plus"
                                (onclick)="showAddSiteModal()"
                                *ngIf="canAdd">
                                Add Site
                            </itc-button>
                        </div>
                    </header>

                    <!--
                    <sds-loader *ngIf="!showAllSiteDashboard" [complete]="!viewModeUpdating">
                        <sds-site-list
                            *ngIf="viewMode == 'list'"
                            sortBy="-name"
                            [sortedSites]="sortedSites"
                            [viewModeUpdating]="viewModeUpdating"
                            (viewLoaded)="viewUpdated()"
                            (detailsClicked)="toggleDetails($event)"
                            (sortSites)="toggleSort($event)"></sds-site-list>

                        <sds-site-grid
                            *ngIf="viewMode == 'grid'"
                            [sortedSitesChunked]="sortedSitesChunked"
                            [viewModeUpdating]="viewModeUpdating"
                            (viewLoaded)="viewUpdated()"
                            (detailsClicked)="toggleDetails($event)"></sds-site-grid>
                    </sds-loader>
-->

                    <itc-tabs (tabChanged)="tabChanged($event)" *ngIf="showAllSiteDashboard">
                        <itc-tab tabTitle="All" [tabDetails]="sitesDashboard?.length.toString()">
                            <sds-all-site [sites]="sitesDashboard" #allSitesComp></sds-all-site>
                        </itc-tab>
                        <itc-tab
                            tabTitle="Compliance Manager GRC"
                            [tabDetails]="complianceManagerGrcSites.length.toString()"
                            [hidden]="complianceManagerGrcSites?.length === 0">
                            <sds-compliance-manager-grc
                                [sites]="complianceManagerGrcSites"
                                [sitesUserIds]="sitesUserIds"
                                #cmGrcComp></sds-compliance-manager-grc>
                        </itc-tab>
                        <itc-tab
                            tabTitle="Compliance Manager Legacy"
                            [hidden]="complianceManagerLegacySites?.length === 0"
                            [tabDetails]="complianceManagerLegacySites?.length.toString()">
                            <sds-compliance-manager-legacy
                                [sites]="complianceManagerLegacySites"
                                [sitesUserIds]="sitesUserIds"></sds-compliance-manager-legacy>
                        </itc-tab>
                        <itc-tab
                            tabTitle="Cyber Hawk"
                            [tabDetails]="cyberHawkSites?.length.toString()"
                            [hidden]="cyberHawkSites?.length === 0">
                            <sds-cyber-hawk #chSites [sites]="cyberHawkSites"></sds-cyber-hawk>
                        </itc-tab>
                        <itc-tab
                            tabTitle="Indoc"
                            [hidden]="indocSites?.length === 0"
                            [tabDetails]="indocSites?.length.toString()">
                            <sds-indoc #indocComp [sites]="indocSites"></sds-indoc>
                        </itc-tab>
                        <itc-tab
                            tabTitle="Network Detective Pro"
                            [hidden]="networkDetectiveProSites?.length === 0"
                            [tabDetails]="networkDetectiveProSites?.length.toString()">
                            <sds-network-detective-pro
                                #ndProComp
                                [sites]="networkDetectiveProSites"></sds-network-detective-pro>
                        </itc-tab>
                        <itc-tab
                            tabTitle="VulScan"
                            [hidden]="vulscanSites?.length === 0"
                            [tabDetails]="vulscanSites?.length.toString()">
                            <sds-vulscan #vsComp [sites]="vulscanSites"></sds-vulscan>
                        </itc-tab>
                    </itc-tabs>
                </div>
            </sds-loader>
        </div>
    </div>
</sds-loader>

<sds-add-site-modal
    [agppVersion]="agppVersion"
    [isSMB]="isSMB"
    [isCM]="isCM"
    [smbErrorMessages]="smbErrorMessages"
    [organizations]="organizations"
    [selectedOrg]="organizationName"
    (onAdd)="siteAdded($event)"
    #addSiteModal></sds-add-site-modal>
<sds-move-site-modal
    [organizations]="organizations"
    [selectedOrg]="organizationName"
    (onMove)="siteMoved($event)"
    #moveSiteModal></sds-move-site-modal>


<sm-modal class="md" title="License Exceeded" #smberrormodal>
    <modal-content>
        <div *ngIf="!isSiteRestricted && !isRestricted">
            <p>The maximum number of computers allowed has been exceeded for:</p>
            <span *ngFor="let smbErrorMessage of smbErrorMessages">
                - {{ smbErrorMessage }}
                <br />
            </span>
            <br />
            <p>Please contact your Account Manager to upgrade your license.</p>
            Alerting and report generation has been temporarily suspended.
            <p></p>
        </div>
        <div *ngIf="isSiteRestricted || isRestricted">
            <p>A license violation has been detected for the account.</p>
            <p>Please contact your account's administrator.</p>
            <p></p>
        </div>
    </modal-content>
    <modal-actions>
        <itc-button type="primary" (onclick)="smbErrorModal.hide()">Continue</itc-button>
    </modal-actions>
</sm-modal>
