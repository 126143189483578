import { Pipe, PipeTransform, inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import { UiService } from '../core/ui.service';
import { DateService } from './date.service';

@Pipe({ name: 'accountDateTime' })
export class accountDateTimePipe implements PipeTransform {
    constructor() {}
    private uiService = inject(UiService);
    private dateService = inject(DateService);

    transform(date: Date | string, args?: string[]): string {
        let origDate = date;
        let ignoreDst = args?.indexOf('ignoreDst') > -1;
        let ignoreFormat = args?.indexOf('ignoreFormat') > -1;
        let addTime = args?.indexOf('addTime') > -1;
        let useUtc = args?.indexOf('UTC') > -1;
        if (typeof date === 'string') {
            date = new Date(date);
        }
        if (date && isNaN(date.getTime())) {
            console.warn('Invalid date for datepipe:', origDate);
            return origDate instanceof Date ? origDate.toISOString() : origDate;
        }
        // get saved settings, this gets update when they change settings and set when they log in
        let portalSettings = { DateFormat: 'MM/dd/yyy', TZINFO: this.dateService.getLocalTimeZone() }
        try {
            let tempSettings = JSON.parse(this.uiService.getControlValue('accountTimeInfo'));
            if (tempSettings.DateFormat) {
                portalSettings.DateFormat = tempSettings.DateFormat;
            }
            if (tempSettings.TZINFO) {
                portalSettings.TZINFO = tempSettings.TZINFO;
            }
        } catch (e) {
        }

        // default to 'short' in case we have a problem getting the portal settings for some reason.
        let userFormat = 'MM/dd/yyyy';
        // default to blank for formatted offset so we just use the users timezone.
        let formattedOffset = '';

        if (useUtc) {
            userFormat = portalSettings.DateFormat + ', h:mm a';
            return new DatePipe('en-us').transform(
                date,
                ignoreFormat ? 'MM/dd/yyyy' + (addTime ? ', h:mm a' : '') : userFormat,
                'UTC'
            );
        }

        if (portalSettings && portalSettings.DateFormat && portalSettings.TZINFO) {
            userFormat = portalSettings.DateFormat + ', h:mm a';
            const userTZ = portalSettings.TZINFO;
            formattedOffset = this.dateService.getFormattedOffset(date, userTZ, ignoreDst);
        } else {
            console.log("No portal settings to get settings. Falling back to 'default'.");
        }

        if (typeof date !== 'string' || (typeof date === 'string' && !isNaN(Date.parse(date)))) {
            return new DatePipe('en-us').transform(
                date,
                ignoreFormat ? 'MM/dd/yyyy' + (addTime ? ', h:mm a' : '') : userFormat,
                formattedOffset
            );
        } else {
            return '';
        }
    }
}
