import { I18N } from './i18n.model';
import { ReferenceUrl } from './referenceurl.model';

export class Requirement {
    Id: number;
    Order: number;
    RequirementId: string;
    ShortDesc: I18N;
    LongDesc: I18N;
    Guidance: I18N;
    CsproGuidance: I18N;
    ReferenceUrls: ReferenceUrl[];
    Selected: boolean;
    Response?: string;
    Policy: I18N;
    HasOverrides: boolean;

    constructor() {
        this.Id = -1;
        this.RequirementId = '';
        this.Order = 0;
        this.ShortDesc = new I18N();
        this.LongDesc = new I18N();
        this.Guidance = new I18N();
        this.CsproGuidance = undefined;
        this.ReferenceUrls = [];
        this.Selected = false;
        this.Policy = new I18N();
        this.HasOverrides = false;
    }
}
