export class Notification {
    id: string;
    type: NotificationType;
    title: string;
    method: NotificationMethod;
    message: string;
    autoClose: boolean;
    fade: boolean;
    timer?: number;
    linkTo: string | string[];
    linkToLabel: string;
    forceReload: boolean;

    constructor(init?: Partial<Notification>) {
        Object.assign(this, init);
    }
}

export enum NotificationType {
    Error,
    Info,
    Success,
    Warning,
    Download,
}

export enum NotificationMethod {
    Banner,
    Toast,
}
