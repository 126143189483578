import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'kvs-site-vulnerability-scanner',
    templateUrl: './site-kvs.component.html',
    styleUrls: ['./site-kvs.component.css'],
})
export class SiteVulnerabilityScannerComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
