import { Component, Input, OnInit } from '@angular/core';

@Component({
    templateUrl: './CriticalPatchesNotAppliedTimely.component.html',
})
export class CriticalPatchesNotAppliedTimelyComponent implements OnInit {
    constructor() {}

    @Input()
    set diagData(value) {
        if (!value) return;

        this.diag = value;
    }

    diag: any;

    ngOnInit() {}
}
