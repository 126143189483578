import {
    Component,
    Input,
    ChangeDetectionStrategy,
    ViewChild,
    ElementRef,
    Directive,
    Output,
    EventEmitter,
    OnDestroy,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { BehaviorSubject, debounceTime, fromEvent, merge, Observable, Subscription } from 'rxjs';

declare var jQuery: any;

/**
 * Component, implementation of Semantic UI modal components.
 *
 * This component is triggered by SMModalDirective.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'sm-modal',
    template: `
        <div class="ui modal {{ size$ | async }}" id="{{ id }}" #modal>
            <i *ngIf="closable" class="close icon"></i>
            <div [ngClass]="{ icon: icon }" class="ui header">
                <i *ngIf="icon" class="icon {{ icon }}"></i>
                <p class="modalTitle">
                    {{ title }}
                    <ng-content select="modal-title"></ng-content>
                </p>
            </div>
            <div
                class="content"
                [class.scrolling]="forceScroll"
                [style.overflow]="overrideOverflow ? 'unset' : null"
                [style.height]="modalHeight ? modalHeight : null"
                #ModalContent>
                <ng-content select="modal-content"></ng-content>
            </div>
            <div class="modalScrollingShadow"></div>
            <div class="actions">
                <ng-content select="modal-actions"></ng-content>
            </div>
        </div>
    `,
})
export class SemanticModalComponent implements OnDestroy {
    size$: BehaviorSubject<string> = new BehaviorSubject('md');

    @Input() set class(val: string) {
        if (val) {
            this.size$?.next(val);
        }
    }
    @Input() id: string;
    @Input() title: string;
    @Input() icon: string;
    @Input() scrolling: boolean = false; // not being used for anything anymore, but there for legacy
    @Input() closable = false;
    @Input() forceScroll = false;
    @Input() overrideOverflow = false;
    @Input() modalHeight: string;
    @ViewChild('modal', { static: true }) modal: ElementRef;
    @ViewChildren('ModalContent', { read: ElementRef })
    modalContent: QueryList<ElementRef>;
    @Output() onModalShow: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onModalHide: EventEmitter<boolean> = new EventEmitter<boolean>();

    eventSub: Subscription;

    ngAfterViewInit() {
        // these are all used to udpate the shadow below the scrolling content
        const scrollEvents$: Observable<Event> = fromEvent(
            this.modalContent.first.nativeElement,
            'scroll'
        );
        const resizeEvents$: Observable<Event> = fromEvent(window, 'resize');
        const sizeChanges$: Observable<string> = this.size$;
        const allEvents$: Observable<Event | string> = merge(
            scrollEvents$,
            resizeEvents$,
            sizeChanges$
        );

        this.eventSub = allEvents$
            .pipe(
                // I wanted to add more debounce but then the functionality stinks, so add at least a little
                debounceTime(10)
            )
            .subscribe(() => {
                this.processContentScroll();
            });
    }

    show(data?: {}) {
        if (data) (data as any).duration = 200;
        jQuery(this.modal.nativeElement)
            .modal(data || { duration: 200 })
            .modal('toggle');
        setTimeout(() => {
            this.processContentScroll();
        }, 1);

        this.onModalShow.emit(true);
    }

    hide() {
        jQuery(this.modal.nativeElement).modal('hide');

        this.onModalHide.emit(true);
    }

    refresh() {
        window.setTimeout(() => this.doRefresh(), 0);
    }

    private doRefresh() {
        jQuery(this.modal.nativeElement).modal('refresh');
    }

    processContentScroll() {
        if (this.modalContent) {
            let contentHeight = this.modalContent.first.nativeElement.clientHeight;
            let contentScrollHeight = this.modalContent.first.nativeElement.scrollHeight;
            let contentScrollTop = Math.ceil(this.modalContent.first.nativeElement.scrollTop);
            if (contentScrollTop < contentScrollHeight - contentHeight) {
                /* we're at the bottom, remove the shadow */
                this.modalContent.first.nativeElement.classList.add('shadowed');
                this.modalContent.first.nativeElement.classList.add('scrolling');
            } else {
                this.modalContent.first.nativeElement.classList.remove('shadowed');
            }
        }
    }

    ngOnDestroy(): void {
        this.hide();
        const parent = this.modal.nativeElement.parentElement;
        parent.removeChild(this.modal.nativeElement);
        this.eventSub.unsubscribe();
    }
}

@Directive({ selector: 'modal-title, modal-content, modal-actions' })
export class SMModalTagsDirective {
    // No behavior
    // The only purpose is to "declare" the tag in Angular2
}
