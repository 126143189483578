<div class="box">
    <div
        class="boxheader"
        [ngClass]="{
            no_data: item.status === 1,
            no_issues: item.status === 2,
            low_risk: item.status === 3,
            medium_risk: item.status === 4,
            high_risk: item.status === 5
        }">
        <div class="title">{{ item.title }}</div>
        <span class="infoIcon" class="icon-vulnerability-scanner"></span>
    </div>
    <div class="boxbody">
        <img style="width: 48px; height: 48px" [src]="item.icon" />
        <span class="issuesLabel">{{ item.numIssues }} Issues</span>
    </div>
</div>
