import { Pipe, PipeTransform } from '@angular/core';
/*
 * Display just the specific keys from an object
 */
@Pipe({ name: 'listReduce' })
export class ListReducePipe implements PipeTransform {
    transform(value: any[], key: string, separator: ', '): string {
        let result = [];
        if (value) {
            value.forEach((item) => {
                if (key) {
                    if (item && item[key]) {
                        result.push(item[key]);
                    }
                } else {
                    if (item) {
                        result.push(item);
                    }
                }
            });
        }
        return result.join(separator);
    }
}
