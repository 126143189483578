import { Component, ElementRef, OnInit, AfterViewInit, ViewChild, Input } from '@angular/core';
import { trigger, state, query, style, animate, transition } from '@angular/animations';

import { AuthService } from '../../../core/auth';
import { ItcProduct } from './itcproduct.model';

@Component({
    selector: 'itc-app-launcher',
    templateUrl: './app-launcher.component.html',
    styleUrls: ['./app-launcher.component.scss'],
    animations: [
        trigger('launcherIconState', [
            state('closedcolor', style({ backgroundColor: '#fff' })),
            state('closedlight', style({ backgroundColor: 'var(--rft-orange)' })),
            state('open', style({ backgroundColor: '#656565' })),
            transition('closedcolor => open', animate(200)),
            transition('open => closedcolor', animate(200)),
            transition('closedlight => open', animate(200)),
            transition('open => closedlight', animate(200)),
        ]),
        trigger('launcherState', [
            state('closedcolor', style({ transform: 'scaleX(0)', opacity: 0 })),
            state('closedlight', style({ transform: 'scaleX(0)', opacity: 0 })),
            state('open', style({ transform: 'scaleX(1)', opacity: 1 })),
            transition('closedcolor => open', animate(100)),
            transition('open => closedclosedcolor', animate(100)),
            transition('closedlight => open', animate(100)),
        ]),
    ],
})
export class ItcAppLauncherComponent implements OnInit, AfterViewInit {
    @ViewChild('appLauncher', { static: true }) appLauncher: ElementRef;
    @ViewChild('appLauncherContent', { static: true }) appLauncherContent: ElementRef;
    @Input() set products(products: any) {
        if (products && products.length) {
            products.forEach((p) => {
                if (p.isPinned) {
                    this.pinnedProducts.push(p);
                } else {
                    this.otherProducts.push(p);
                }
            });
            console.log('pinned', this.pinnedProducts);
            console.log('other', this.otherProducts);
        }
    }
    @Input() set theme(val: string) {
        // if user doesn't have a theme set, default to color, because that's what we default to anyway in the layout.
        if (val && val !== undefined) {
            this._theme = val;
        } else {
            this._theme = 'color';
        }
        this.state = 'closed' + val;
    }
    get theme() {
        return this._theme;
    }
    _theme = 'color';
    state: string;
    pinnedProducts = [];
    otherProducts = [];

    constructor(private authService: AuthService) {}

    ngOnInit() {
        this.state = 'closed' + this.theme;
        console.log('User is K1:', this.authService.userIsK1());
    }

    ngAfterViewInit() {}

    toggleLauncher() {
        if (this.state == 'closed' + this.theme) {
            this.state = 'open';
        } else {
            this.appLauncherContent.nativeElement.scrollTop = 0;
            this.state = 'closed' + this.theme;
        }
    }

    goToProduct(product) {
        window.open(product.landingPageUrl);
    }

    closeLauncher() {
        this.state = 'closed' + this.theme;
    }
}
