import { Injectable } from '@angular/core';
import { Alert } from 'app/alerts';
import { AuthHttp } from 'app/core/auth';
import { Audit } from './audit.model';
import { ErrorService } from 'app/core/error/error.service';
import { lastValueFrom } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class AuditService {
    private apiUrl: string;

    constructor(private http: AuthHttp, private E: ErrorService) {
        this.apiUrl = environment.apiUrl;
    }

    getAlertHistory(): Promise<Audit[]> {
        const url: string = this.apiUrl + 'AlertHistory';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as Audit[]);
        //.catch(this.E.error);
    }

    getAlertHistoryBySite(siteId: number, limit?: number): Promise<Audit[]> {
        const url: string =
            this.apiUrl + 'AlertHistory/' + siteId + (limit ? '?limit=' + limit : '');
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as Audit[]);
        //.catch(this.E.error);
    }
    
    async getHistoryForAlert(alert: Alert): Promise<Audit[]> {
        const url: string = this.apiUrl + 'AlertHistory/' + alert.SiteId + '/' + alert.Guid;
        return lastValueFrom(this.http.get(url)).then((data) => data as Audit[]);
    }
}
