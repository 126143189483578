import { Component, OnInit, OnDestroy, ViewChildren, QueryList, isDevMode, ViewChild, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SemanticModalComponent } from 'app/semantic-legacy/components/modal/modal';
import { VendportalService } from '../vendportal.service';
import { AssignedAssessment, Vendor, VendorAssessmentAcceptance } from '../data';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
/* CMGRC Wizard Imports  */
import {
    Answer,
    QuestionDescription,
    WizardOptions,
} from '../../sites/site/compliance-manager/shared/cm-wizard/cm-wizard.model';
import { CmWizardComponent } from '../../sites/site/compliance-manager/shared/cm-wizard/cm-wizard.component';
import { VPWizardAnswers, VPWizardDescription, VPWizardOptions } from './assessment.wizard';

@Component({
    selector: 'vendor-assessment',
    templateUrl: './assessment.component.html',
    styleUrls: ['./assessment.component.scss'],
})
export class AssessmentComponent implements OnInit, OnDestroy {
    loadingComplete: boolean = false;
    state: number = 0;
    culture: string = 'en-US';
    assessment: AssignedAssessment;
    vendor: Vendor;
    ngUnsubscribe = new Subject();

    title = 'Rapid Baseline Assessment';

    /* CMGRC Wizard Stuff */
    @ViewChildren(CmWizardComponent) grcWizard: QueryList<CmWizardComponent>;
    wizardDescription: QuestionDescription[] = VPWizardDescription;
    wizardAnswers: Answer[] = VPWizardAnswers;
    wizardOptions: WizardOptions = VPWizardOptions;

    disableLock =
        false &&
        isDevMode(); /* DO NOT LET THIS GO TO STAGING+ SET TO TRUE... ONLY FOR DEBUGGING, DO NOT REMOVE ISDEVMODE() EITHER WAY */

    lockedUser: any = null;
    isReadOnly: boolean = false;
    @ViewChild('warningModal') warningModal: SemanticModalComponent;
    @ViewChild('timedOutModal') timedOutModal: SemanticModalComponent;
    warningInterval: any;
    timeoutInterval: any;
    pageClosed: boolean = false;
    lockGUID: string = null;

    breadcrumbs = [
        { path: '..', text: 'Compliance Manager GRC' },
        { path: '.', text: this.title },
    ];

    constructor(
        private service: VendportalService,
        private route: ActivatedRoute,
        private router: Router,
    ) {}

    ngOnDestroy() {
        this.pageClosed = true;
        if (!this.disableLock) {
            if (!this.lockedUser && this.vendor?.SiteId) {
                this.service.deleteVendorAssessmentLock(this.vendor, this.lockGUID);
            }
        }
        
        clearInterval(this.warningInterval);
        clearInterval(this.timeoutInterval);
        this.ngUnsubscribe.next(void 0);
        this.ngUnsubscribe.complete();
    }

    @HostListener('window:beforeunload', ['$event'])
    unloadDeleteLock($event) {
        this.pageClosed = true;
        if (!this.lockedUser && this.vendor?.SiteId) {
            this.service.deleteVendorAssessmentLock(this.vendor, this.lockGUID);
        }
    }

    ngOnInit() {
        this.loadingComplete = false;
        this.lockGUID = crypto.randomUUID();
        this.service.getMe().toPromise().then((vendor) => this.vendor = vendor);
        
        this.route.paramMap
            .pipe(
                switchMap((p) => this.service.getAssignedAssessment(+p.get('id'))),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe((assess) => {
                this.assessment = assess;
                // this.controlsTotal = this.assessment.Controls.length;
                this.assessment.Controls.sort((a, b) =>
                    a.Control.ControlId.toLowerCase().localeCompare(
                        b.Control.ControlId.toLowerCase(),
                        undefined,
                        { numeric: true, sensitivity: 'base', ignorePunctuation: true }
                    )
                );
                console.log('vendor', this.vendor);
                this.initResponses();
                this.startAssessment();
                this.loadingComplete = true;
            });
    }

    initResponses() {
        if (this.assessment) {
            console.log('this assessment', this.assessment);
            this.assessment.Controls.forEach((c) => {
                if (!c.Response) {
                    c.Response = {
                        AnswerType: '',
                        AnswerDT: new Date(),
                        VendorId: undefined,
                        AssessmentId: c.ComesFromAssessmentId,
                        ControlId: c.Control.Id,
                        Notes: '',
                    };
                }
            });
        }
    }

    saveResponses(res): Promise<any> {
        console.log('saving response(s)', res);

        let answers: VendorAssessmentAcceptance[];

        if (!Array.isArray(res)) {
            res.Response.AssessmentId = res.ComesFromAssessmentId; //sets the right assessment where this responce comes
            answers = [res.Response];
        } else {
            answers = (res as any[]).map((r) => {
                let ret = r.Response as VendorAssessmentAcceptance;
                ret.AssessmentId = r.ComesFromAssessmentId; //sets the right assessment where this responce comes
                return ret;
            });
        }

        return this.service
            .save(answers)
            .toPromise()
            .then((res) => {

                this.grcWizard.first.saveHasCompleted();
                
                if (!this.disableLock) {
                    //Refresh lock
                    this.service
                        .updateVendorAssessmentLock(this.vendor, this.lockGUID)
                        .catch(() => {
                            this.isReadOnly = true;
                        });
                    clearInterval(this.warningInterval);
                    this.warningInterval = setInterval(this.warnLock, 900000);
                    clearInterval(this.timeoutInterval);
                }
            })
            .catch((ret) => {

                this.grcWizard.first.saveCompletedWithErrors();
                
                console.log(ret);
            });
    }

    startAssessment() {
        if (!this.disableLock) {
            this.service.getVendorAssessmentLock(this.vendor).then((lockUser) => {
                console.log('lockUser', lockUser);
                console.log('pageClosed', this.pageClosed);
                if (lockUser == null && !this.pageClosed) {
                    this.service
                        .insertVendorAssessmentLock(this.vendor, this.lockGUID)
                        .then(() => {
                            if (this.pageClosed)
                                this.service.deleteVendorAssessmentLock(
                                    this.vendor,
                                    this.lockGUID
                                );
                        })
                        .catch(() => {
                            this.isReadOnly = true;
                        });
                    this.warningInterval = setInterval(this.warnLock, 900000);
                } else if (lockUser != null && lockUser.GUID != this.lockGUID) {
                    this.lockedUser = lockUser;
                    this.isReadOnly = true;
                }
            });
        }

        this.state = 1;
    }

    warnLock() {
        this.warningModal.show({ closable: false });
        clearInterval(this.warningInterval);
        clearInterval(this.timeoutInterval);
        this.timeoutInterval = setInterval(this.lockTimeout, 300000);
    }

    lockTimeout() {
        this.service.deleteVendorAssessmentLock(this.vendor, this.lockGUID);
        this.isReadOnly = true;
        this.warningModal.hide();
        clearInterval(this.timeoutInterval);
        this.timedOutModal.show({ closable: false });
    }

    async processResults() {
        this.router.navigateByUrl('/vendor/home').then((ok) => {});
    }
}
