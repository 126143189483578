import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Site } from 'app/sites';
import { environment } from 'environments/environment';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { AuthService, rftProducts, RFTProduct } from '.';
import { DateService } from 'app/shared/date.service';
import { CMTypes } from 'app/sites/shared/site.service';

export interface AccountProductDisabled {
    AccountId: string;
    ProductId: string;
    DisableDate: Date;
    GraceDays: string;
}

interface RFTProductObj {
    [key: string]: RFTProduct;
}

@Injectable({
    providedIn: 'root',
})
export class EndDateService {
    private apiUrl: string;
    private expiredProducts$ = new BehaviorSubject<RFTProductObj>(null);

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private dateService: DateService
    ) {
        this.apiUrl = environment.apiUrl;
    }

    getAllExpiredProducts(): Promise<AccountProductDisabled[]> {
        const url: string = this.apiUrl + 'GetLicensesStatus';
        return lastValueFrom(this.http.get<AccountProductDisabled[]>(url)).then((res) => res);
    }

    processExpired(expired: AccountProductDisabled[]): void {
        let expiredCodes = expired.map((p) => 'FTR_' + p.ProductId).join(',');
        let expiredProducts = this.authService.getProducts(expiredCodes);

        // special case to treat all CM1 and CMGRC products as the same expiration date.
        if (expiredProducts['CMProduct'] || expiredProducts['CMGRCProduct']) {
            expiredProducts['CMGRCProduct'] = true;
            expiredProducts['CMProduct'] = true;
        }

        let expiredProductModules: RFTProductObj = {};
        Object.keys(expiredProducts).forEach((key) => {
            rftProducts.forEach((product) => {
                if (product.ProductShortName.indexOf(key) > -1) {
                    let endDate;

                    endDate = expired.find((exp) => {
                        let id = 'FTR_' + exp.ProductId;
                        let p = product.ProductCodes.includes(id);
                        let c = product.ProductChildren?.find((child) =>
                            child.ProductCodes.includes(id)
                        );
                        return p || c;
                    })?.DisableDate;

                    if (endDate) {
                        product.ProductEndDate = endDate;
                    }

                    product.DisabledTooltip = this.getExpiredTooltip(
                        null,
                        product.ProductName,
                        product.ProductEndDate
                    );
                    product.DisabledtooltipTitle = 'Subscription Ended';
                    product.ProductDisabled = true;
                    expiredProductModules[product.ProductName] = product;
                }
            });
        });
        // special case to make sure CM and CMGRC have the same expiration date.
        if (expiredProductModules['Compliance Manager']) {
            let cm = expiredProductModules['Compliance Manager'];
            let cmGRC = expiredProductModules['Compliance Manager GRC'];
            let cmEndDate = cm.ProductEndDate || cmGRC.ProductEndDate || null;
            if (cmEndDate) {
                cm.ProductEndDate = cmEndDate;
                cmGRC.ProductEndDate = cmEndDate;
                cm.DisabledTooltip = this.getExpiredTooltip(null, 'Compliance Manager', cmEndDate);
                cmGRC.DisabledTooltip = this.getExpiredTooltip(
                    null,
                    'Compliance Manager GRC',
                    cmEndDate
                );
            }
        }
        this.expiredProducts$.next(expiredProductModules);
    }

    getExpiredProducts(refresh: boolean = false): Observable<RFTProductObj> {
        if (this.expiredProducts$.getValue() === null || refresh) {
            this.getAllExpiredProducts().then((expired) => this.processExpired(expired));
        }
        return this.expiredProducts$.asObservable();
    }

    getExpiredTooltip(site?: Site, product?: string, expires?: Date): string {
        let module;
        if (product) {
            module = product;
        } else {
            module = this.getSiteTypeName(site);
        }
        let expireDate = this.dateService.formatDate(expires);
        return `Your ${module} subscription ended on <strong>${expireDate}</strong>. To renew your subscription, please contact your Account Manager.`;
    }

    // I had to copy this here because I run into circular dependency issues using siteService
    getSiteTypeName(site: Site): string {
        if (CMTypes.includes(site.Type)) return 'Compliance Manager';

        switch (site.Type) {
            case 'CM2':
                return 'Compliance Manager GRC';
            case 'NDPRO':
                return 'Network Detective Pro';
            case 'KVS':
                return 'VulScan';
            case 'standard':
                return 'Cyber Hawk';
            default:
                return '';
        }
    }

    resetService(): void {
        this.expiredProducts$.next(null);
    }
}
