import {
    Component,
    ContentChild,
    HostListener,
    ElementRef,
    EventEmitter,
    Output,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { ViewModeDirective } from './view-mode.directive';
import { EditModeDirective } from './edit-mode.directive';
import { NgControl } from '@angular/forms';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { switchMap, takeUntil, filter, take, switchMapTo } from 'rxjs/operators';

@Component({
    selector: 'itc-inline-edit',
    template: `
        <ng-container *ngTemplateOutlet="currentView"></ng-container>
    `,
    styleUrls: ['./inline-edit.component.css'],
})
export class ItcInlineEditComponent implements OnInit, OnDestroy {
    @ContentChild(ViewModeDirective) viewModeTpl: ViewModeDirective;
    @ContentChild(EditModeDirective) editModeTpl: EditModeDirective;
    @Output() update = new EventEmitter();

    editMode = new Subject();
    editMode$ = this.editMode.asObservable();

    mode: 'view' | 'edit' = 'view';

    buttonSubscription: Subscription;
    clickOutsideSubscription: Subscription;
    constructor(private host: ElementRef) {}

    ngOnInit() {
        this.viewModeHandler();
        this.editModeHandler();
        this.host.nativeElement.parentNode.classList.add('inline-edit-wrapper');
        this.updateParentClass();
    }

    toViewMode() {
        this.update.next(null);
        this.mode = 'view';
        this.updateParentClass();
    }

    private get element() {
        return this.host.nativeElement;
    }

    private viewModeHandler() {
        this.buttonSubscription = fromEvent(this.element, 'click')
            .pipe()
            .subscribe(() => {
                this.mode = 'edit';
                this.editMode.next(true);
                this.updateParentClass();
            });
    }

    private editModeHandler() {
        const clickOutside$ = fromEvent(document, 'click').pipe(
            filter(({ target }) => this.element.contains(target) === false),
            take(1)
        );

        this.clickOutsideSubscription = this.editMode$
            .pipe(switchMapTo(clickOutside$))
            .subscribe((event) => this.toViewMode());
    }

    get currentView() {
        return this.mode === 'view' ? this.viewModeTpl.tpl : this.editModeTpl.tpl;
    }

    updateParentClass() {
        this.host.nativeElement.parentNode.classList.remove('edit-mode', 'view-mode');
        this.host.nativeElement.parentNode.classList.add(this.mode + '-mode');
    }

    ngOnDestroy() {
        if (this.buttonSubscription) this.buttonSubscription.unsubscribe();
        if (this.clickOutsideSubscription) this.clickOutsideSubscription.unsubscribe();
    }
}
