<span
    [attr.title]="title || ''"
    *ngIf="link != undefined && !type.includes('file')"
    [ngStyle]="{ cursor: disabled ? 'not-allowed' : null }">
    <a
        href="javascript:"
        [routerLink]="routerLink ? routerLink : null"
        class="itc button {{ size }} {{ type || '' }} {{ class }}"
        (mouseover)="hover = true"
        (mouseout)="hover = false"
        [ngStyle]="{
            backgroundColor: type == 'color' && hover == true ? hoverColor : color,
            borderColor: type == 'color' && hover == true ? hoverColor : color
        }"
        [class.has-icon]="icon && iconPlacement == 'before' && !type.includes('icon')"
        [class.has-icon-after]="icon && iconPlacement == 'after' && !type.includes('icon')"
        [class.loading]="loading"
        [class.disabled]="disabled"
        (click)="click($event)"
        [class.loading-spinner]="type == 'icon' && loading"
        [routerLink]="link"
        [queryParams]="params">
        <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
    </a>
</span>

<span
    [attr.title]="title || ''"
    *ngIf="link == undefined && !type.includes('file')"
    [ngStyle]="{ cursor: disabled ? 'not-allowed' : null }">
    <a
        href="javascript:"
        [routerLink]="routerLink ? routerLink : null"
        class="itc button {{ size }} {{ type || '' }} {{ class }}"
        (mouseover)="hover = true"
        (mouseout)="hover = false"
        [ngStyle]="{
            backgroundColor: type == 'color' && hover == true ? hoverColor : color,
            borderColor: type == 'color' && hover == true ? hoverColor : color
        }"
        [class.has-icon]="icon && iconPlacement == 'before' && !type.includes('icon')"
        [class.has-icon-after]="icon && iconPlacement == 'after' && !type.includes('icon')"
        [class.loading]="loading"
        [class.disabled]="disabled"
        (click)="click($event)"
        [class.loading-spinner]="(type == 'icon' || type == 'text') && loading">
        <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
    </a>
</span>

<ng-container *ngIf="type.includes('file')">
    <input
        type="file"
        name="file"
        id="{{ fileId }}"
        class="fileInput"
        (change)="changeCallback($event)"
        accept="{{ fileAccept }}" />
    <label
        for="{{ fileId }}"
        class="itc button {{ size }} {{ type || '' }}"
        [class.has-icon]="icon && iconPlacement == 'before'"
        [class.has-icon-after]="icon && iconPlacement == 'after'"
        [class.loading]="loading"
        [class.disabled]="disabled"
        [attr.title]="title || ''">
        <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
    </label>
</ng-container>

<ng-template #buttonContent>
    <div class="loader" *ngIf="loading && type !== 'icon' && type !== 'text'">
        <span></span>
        <span></span>
        <span></span>
    </div>
    <span class="button-content" [style.visibility]="loading ? 'hidden' : ''">
        <i *ngIf="icon && !faIcon && iconPlacement == 'before'" class="{{ icon }} icon "></i>
        <fa-icon
            *ngIf="faIcon && iconPlacement == 'before'"
            [icon]="['far', faIcon]"
            [ngStyle]="{ marginRight: type.includes('icon') ? 0 : '8px' }"></fa-icon>
        <span *ngIf="label">{{ label }}</span>
        <span *ngIf="!label"><ng-content></ng-content></span>
        <i *ngIf="icon && !faIcon && iconPlacement == 'after'" class="{{ icon }} icon "></i>
        <fa-icon
            *ngIf="faIcon && iconPlacement == 'after' && !isDropdownButton"
            [icon]="['far', faIcon]"
            [ngStyle]="{ marginLeft: type.includes('icon') ? 0 : '8px' }"></fa-icon>
        <fa-icon
            *ngIf="isDropdownButton && displayChevronDownIcon"
            icon="chevron-down"
            style="margin-left: 8px; margin-right: 0"></fa-icon>
    </span>
</ng-template>
