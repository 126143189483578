import {
    Component,
    Input,
    ChangeDetectionStrategy,
    Output,
    ViewChild,
    ViewContainerRef,
    EventEmitter,
    OnInit,
    ElementRef,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { Subject, timer } from 'rxjs';
import { debounce } from 'rxjs/operators';

/**
 * Implementation of Input element
 *
 * @link http://semantic-ui.com/elements/input.html
 *
 * @example
 * <sm-input icon="dollar" type="number" [(model)]="model" class="right fluid" placeholder="Enter a sum..."></sm-input>
 */
@Component({
    selector: 'sm-input',
    template: `
        <div class="field" [ngClass]="{ error: !control.valid && control.dirty && isInsideForm }">
            <label *ngIf="label && isInsideForm">{{ label }}</label>
            <div
                class="ui input {{ class }}"
                [ngClass]="{ icon: icon, error: !control.valid && control.dirty && !isInsideForm }">
                <label *ngIf="label && !isInsideForm" class="ui label">{{ label }}</label>
                <input
                    [type]="type"
                    [formControl]="control"
                    (input)="onModelChange(input.value)"
                    #input
                    placeholder="{{ placeholder }}"
                    [readonly]="readonly" />
                <i *ngIf="icon" class="{{ icon }} icon"></i>
            </div>
        </div>
    `,
})
export class SemanticInputComponent implements OnInit {
    @Input() label: string;
    @Input() class: string;
    @Input() icon: string;
    @Input() type: string = 'text';
    @Input() placeholder: string;
    @Input() model: {};
    @Input() control: UntypedFormControl = new UntypedFormControl();
    @Input() debounce: number;
    @Input() readonly: boolean;
    @Output() modelChange: EventEmitter<string | number> = new EventEmitter<string | number>();

    debouncer: Subject<string | number> = new Subject<string | number>();

    public isInsideForm: boolean = false;

    constructor(public viewRef: ViewContainerRef) {}

    ngOnInit() {
        this.debouncer
            .pipe(debounce(() => timer(this.debounce)))
            .subscribe((val) => this.modelChange.emit(val));

        // if input field is inside form
        if (this.inForm(this.viewRef.element.nativeElement, 'form')) {
            this.isInsideForm = true;
        }
    }

    inForm(el: Node, classname: string): boolean {
        if (el.parentNode) {
            if (el.parentNode.nodeName.toLowerCase() === classname.toLowerCase()) {
                return !!el.parentNode;
            } else {
                return this.inForm(el.parentNode, classname);
            }
        } else {
            return false;
        }
    }

    onModelChange(val: string | number) {
        this.debouncer.next(val);
    }
}

/**
 * Implementation of Checkbox element
 *
 * @link http://semantic-ui.com/modules/checkbox.html
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'sm-checkbox',
    template: `
        <div
            class="field"
            [ngClass]="{ error: (!control.value && control?.validator) || manualError }">
            <div class="ui {{ classType }} checkbox" [class.disabled]="disabled">
                <input
                    type="checkbox"
                    [attr.value]="value"
                    [attr.title]="title"
                    [attr.type]="inputType"
                    tabindex="0"
                    [attr.name]="name"
                    [formControl]="control"
                    ngDefaultControl
                    [attr.disabled]="disabled ? '' : null"
                    #inputElement />
                <label *ngIf="label" (click)="toggleValue()" [attr.title]="title">
                    <i class="{{ icon }} icon" *ngIf="icon && classType === 'checkbox'"></i>
                    {{ label }}
                </label>
            </div>
        </div>
    `,
})
export class SemanticCheckboxComponent {
    @Input() control: UntypedFormControl = new UntypedFormControl();
    @Input() label: string;
    @Input() disabled: boolean;
    @Input() value: string | number;
    @Input() name: string;
    @Input() icon: string;
    @Input() manualError: boolean;
    @Input() title: string;

    @ViewChild('inputElement', { static: true }) inputElement: ElementRef;

    @Input('type')
    set type(data: string) {
        if (data && data !== 'checkbox') {
            this.classType = data;
            if (data === 'radio') {
                this.inputType = data;
            }
        }
    }

    public inputType: string = 'checkbox';
    public classType = 'checkbox';

    toggleValue() {
        if (this.inputType != 'radio' && this.disabled != true) {
            this.control.setValue(!this.control.value);
            this.setIndeterminate(false);
        }
    }

    setIndeterminate(value: boolean) {
        if (this.inputType != 'radio') {
            this.inputElement.nativeElement.indeterminate = value;

            if (value) this.control.setValue(true, { emitEvent: false }); // expectation when clicking indeterminate checkbox is that it goes to false
        }
    }
}

/**
 * Implementation of Textarea element
 *
 * @link http://semantic-ui.com/collections/form.html#text-area
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'sm-textarea',
    template: `
        <div class="field" [ngClass]="{ error: !control.valid && control.dirty }">
            <label *ngIf="label">{{ label }}</label>
            <textarea rows="{{ rows }}" [formControl]="control"></textarea>
        </div>
    `,
})
export class SemanticTextareaComponent {
    @Input() control: UntypedFormControl = new UntypedFormControl();
    @Input() label: string;
    @Input() rows: string;
}
