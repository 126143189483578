import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FilesService } from 'app/sites/site/assessments/files/files.service';
import { Site } from 'app/sites/shared/site.model';

@Component({
    selector: 'sds-connector-files',
    templateUrl: './connector-files.component.html',
})
export class ConnectorFilesComponent implements OnInit {
    constructor(private filesService: FilesService) {}

    _site: Site;

    @Input()
    set site(value: Site) {
        if (value) {
            this._site = value;

            this.getConnectorFilesList('both').then((res) => {});
        }
    }

    @Input()
    set reload(value: any) {
        if (value) {
            this.getConnectorFilesList('both').then((res) => {});
        }
    }

    _selectedConnectorFiles: any[];
    @Output() selectedConnectorFiles = new EventEmitter<any[]>();

    connectorFiles: any[];
    connectorFilesSelectAll: UntypedFormControl = new UntypedFormControl();
    connectorFileControls: UntypedFormControl[] = [];
    pageSize: number = 10;
    pageNumber: number = 0;
    pagedConnectorFiles: any[];
    connectorTableLoadingComplete: boolean;

    ngOnInit() {
        this.connectorFileControls = [];
    }

    getConnectorFilesList(fileType: string): Promise<any> {
        this.connectorTableLoadingComplete = false;
        this.connectorFileControls = [];
        this._selectedConnectorFiles = [];
        this.connectorFiles = [];
        return new Promise((resolve, reject) => {
            this.filesService
                .getConnectorFilesMetadata(this._site.Id, fileType)
                .then((metas) => {
                    this.connectorFiles = metas;
                    for (let file of this.connectorFiles) {
                        let tmpFc: UntypedFormControl = new UntypedFormControl();
                        this.connectorFileControls.push(tmpFc);
                    }

                    this.pageConnectorFiles({ pageNumber: 0, pageSize: 10 });
                    this.connectorTableLoadingComplete = true;
                    resolve(metas);
                })
                .catch((err) => {
                    console.error(err);
                    this.connectorTableLoadingComplete = true;
                    reject(err);
                });
        });
    }

    toggleConnectorFile(index: any) {
        var pagedIndex = this.pageNumber * this.pageSize + index;

        if (this.connectorFileControls[pagedIndex].value) {
            this._selectedConnectorFiles.push(this.connectorFiles[pagedIndex]);
        } else {
            var selectedIndex = this._selectedConnectorFiles.indexOf(
                this.connectorFiles[pagedIndex]
            );
            if (selectedIndex >= 0) {
                this._selectedConnectorFiles.splice(selectedIndex, 1);
            }
        }

        this.selectedConnectorFiles.emit(this._selectedConnectorFiles);
    }

    toggleAllConnectorFileSelections() {
        for (let connectorFileControl of this.connectorFileControls) {
            connectorFileControl.setValue(this.connectorFilesSelectAll.value);
        }
        for (let i = 0; i < this.connectorFileControls.length; i++) {
            this.toggleConnectorFile(i);
        }
    }

    pageConnectorFiles(ev?: any) {
        if (ev) {
            this.pageSize = ev.pageSize;
            this.pageNumber = ev.pageNumber;
        }

        this.pagedConnectorFiles = this.connectorFiles.slice(
            this.pageNumber * this.pageSize,
            this.pageNumber * this.pageSize + this.pageSize
        );
    }
}
