import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

/**
 * This class now serves only as a simple wrapper around the new HttpClient interface.
 * This way there is much less code to change.
 */
@Injectable()
export class AuthHttp {
    constructor(private http: HttpClient) {}

    get(url: string, options?: any): Observable<any> {
        return this.http.get(url, options);
    }

    post(url: string, body: any, options?: any): Observable<any> {
        return this.http.post(url, body, options);
    }

    put(url: string, body: any, options?: any): Observable<any> {
        return this.http.put(url, body, options);
    }

    patch(url: string, body: any, options?: any): Observable<any> {
        return this.http.patch(url, body, options);
    }

    delete(url: string, options?: any): Observable<any> {
        return this.http.delete(url, options);
    }
}
