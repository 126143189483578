import { Component, Input, OnInit } from '@angular/core';

@Component({
    templateUrl: './ApplicationInstalledLockedDown.component.html',
})
export class ApplicationInstalledLockedDownComponent implements OnInit {
    constructor() {}

    @Input()
    set diagData(value) {
        if (!value) return;

        this.diag = value;

        let newItems: any[] = [],
            oldItems: any[] = [],
            removedItems: any[] = [];
        for (let item of this.diag.Children) {
            if (item.IsNew == 'true') newItems.push(item);
            else if (item.WasRemoved == 'true') removedItems.push(item);
            else oldItems.push(item);
        }

        this.removed = removedItems.length > 0;

        newItems = newItems.sort((a, b) =>
            a.ApplicationName.toLowerCase().localeCompare(b.ApplicationName.toLowerCase())
        );
        oldItems = oldItems.sort((a, b) =>
            a.ApplicationName.toLowerCase().localeCompare(b.ApplicationName.toLowerCase())
        );
        removedItems = removedItems.sort((a, b) =>
            a.ApplicationName.toLowerCase().localeCompare(b.ApplicationName.toLowerCase())
        );

        this.diag.Children = this.removed
            ? removedItems.concat(oldItems)
            : newItems.concat(oldItems);
        //this.diag.Children = removedItems.concat(newItems.concat(this.diag.Children));
    }

    diag: any;
    removed: boolean;

    ngOnInit() {}

    fixDate(date) {
        var y = parseInt(date.substring(0, 4));
        var m = parseInt(date.substring(4, 6)) - 1;
        var d = parseInt(date.substring(6, 8));

        return new Date(y, m, d);
    }
}
