import {
    ChangeDetectionStrategy, Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
    selector: 'itc-bulk-actions',
    templateUrl: './bulk-actions.component.html',
    styleUrls: ['./bulk-actions.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItcBulkActionsComponent {
    @Input() classList: string;
    @Input() loading: boolean = false;
    _selectedCount = 0;
    _totalCount = 0;
    _selectedNaming = 'Row';
    @Input() disabled: boolean = false;
    @Input() menuOptions: MenuItem[];
    @Input() set selectedCount(val: number) {
        this._selectedCount = val;
        this.updateLabel();
    }
    get selectedCount() {
        return this._selectedCount;
    }
    @Input() set totalCount(val: number) {
        this._totalCount = val;
        this.updateLabel();
    }
    get totalCount() {
        return this._totalCount;
    }
    @Input() set selectedNaming(name: string) {
        this._selectedNaming = name;
        this.updateLabel();
    }
    get selectedNaming() {
        return this._selectedNaming;
    }
    @Output() checkboxClicked = new EventEmitter();
    @ViewChild('dropdownMenu', { static: true }) dropdownMenu: any;

    ngOnInit() {
        this.updateLabel();
    }

    updateLabel() {
        if (this.menuOptions && this.menuOptions.length > 0) {
            this.menuOptions[0].label = this.selectedCount + ' Selected';
            this.menuOptions = [...this.menuOptions];
        }
    }

    toggleDropdown(event) {
        // don't open on click of checkbox
        if (event.target.tagName !== 'INPUT') {
            this.dropdownMenu.toggle(event);
        }
    }
}
