import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'msal-oauth',
    template: `<p>Redirecting you...</p>`,
})
export class MsalOauthComponent implements OnInit {

    constructor(private router: Router) { }

    ngOnInit(): void {
        let host = window.location.host;
        let hash = window.location.hash;
        let query = '';
        if (hash.length > 0) {
            query = decodeURIComponent(hash.substring(1));
        }
        let vars = new URLSearchParams(query);
        let foundUrl = false;
        if (host != 'localhost:4200') {
            let domain = (host.match(/([^.]+)\.\w{2,3}(?:\.\w{2})?$/) || [])[0];
            if (vars.has("state")) {
                let stateVar = vars.get("state").split('|');
                if (stateVar.length > 1 && stateVar[1]) {
                    foundUrl = true;
                    document.location.href = `${window.location.protocol}//${stateVar[1]}.${domain}#${query}`;
                }
            }
        }
        if (!foundUrl) {
            this.router.navigateByUrl('/login');
        }
  }

}
