import {
    ChangeDetectorRef,
    Component,
    OnInit,
    AfterViewInit,
    OnDestroy,
    isDevMode,
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

import { AuthService } from '../auth';
import { NavService } from 'app/core/nav/nav.service';
import { Setting, SettingService, DEFAULTS, SDSP_SETTINGS } from 'app/settings';

import { UiService } from 'app/core/ui.service';

declare var window: any;

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.css'],
})
export class LayoutComponent implements OnInit, OnDestroy {
    constructor(
        private authService: AuthService,
        private navService: NavService,
        private settingService: SettingService,
        private router: Router,
        private uiService: UiService,
        private _cdr: ChangeDetectorRef
    ) {}

    isAuth: boolean;
    version: string = 'LOCAL';
    isDev: boolean = false;
    backgroundColor: string;

    theme: string = 'none'; // will be nothing/light/dark
    themeChangeSub: Subscription;

    ngOnInit() {
        this.isDev = isDevMode();
        this.isAuth = this.authService.isAuth();

        // apply existing local settings or default values
        let sets = this.getLocalSettings();
        if (sets.BannerColor) this.backgroundColor = sets.BannerColor;
        if (sets.Theme) this.theme = sets.Theme;

        if (this.authService.getIdentity()) {
            this.settingService.getSettings().then((settings) => this.onSettings(settings));
        } else {
            this.settingService.getBranding().then((settings) => this.onSettings(settings));
        }

        this.uiService
            .getVersion()
            .then((version) => {
                this.version = version.replace(/Dev-|Beta-/, '');
                console.log('Version: ', version);
            })
            .catch((err) => {});

        /* Look for NavigationEnd hook and scroll to top of content, this is to fix
       A problem with some pages not scrolling to the top (ex. /sites) */
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            let pageContentNode = document.querySelector('.page_content');
            if (pageContentNode) pageContentNode.scrollTop = 0;
        });

        this.themeChangeSub = this.settingService
            .getThemeChangeObservable()
            .subscribe((theme: string) => {
                this.theme = theme;
            });
    }

    ngAfterViewInit() {}

    ngOnDestroy() {}

    getLocalSettings(): any {
        let sets: any = JSON.parse(localStorage.getItem(SDSP_SETTINGS));
        if (sets) return sets;
        else return DEFAULTS;
    }

    onSettings(settings: Setting[]) {
        for (let setting of settings) {
            //if (setting.Name == 'BannerColor')
            //  this.backgroundColor = setting.Value;
            if (setting.Name == 'Theme') this.theme = setting.Value;
        }
    }
}
