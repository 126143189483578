import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Connection, ConnectionService } from 'app/connections';
import { Site } from 'app/sites';
import { KvsTicket } from 'app/sites/shared/kvs.service';
import { SiteService } from 'app/sites/shared/site.service';

@Component({
    selector: 'psa-tickets',
    templateUrl: './psa-tickets.component.html',
    styleUrls: ['./psa-tickets.component.scss'],
})
export class PsaTicketsComponent implements OnInit {
    friendlyTypes: any = {
        KBMS: 'Kaseya BMS',
        CW: 'ConnectWise',
        CWREST: 'ConnectWise',
        ATWS: 'Autotask',
        ATREST: 'Autotask',
        TP: 'TigerPaw',
    };

    site: Site;
    baseUrlForTicket: string = '';
    connection: Connection;
    loading: boolean = true;

    private _loadingUrls: boolean = true;
    set loadingUrls(value: boolean) {
        this._loadingUrls = value;
        this.isLoading();
    }
    get loadingUrls() {
        return this._loadingUrls;
    }

    private _ticketList: KvsTicket[];
    @Input() set ticketList(value: KvsTicket[]) {
        this._ticketList = value;
        this.onTicketListChange(this._ticketList);
    }
    get ticketList() {
        return this._ticketList;
    }

    private _loadingTickets: boolean = true;
    @Input() set loadingTickets(value: boolean) {
        this._loadingTickets = value;
        this.isLoading();
    }
    get loadingTickets() {
        return this._loadingTickets;
    }

    @Input() siteId: number;

    constructor(private connService: ConnectionService, private siteService: SiteService) {}

    ngOnInit() {
        //On Init, get the site and the connection. Then set the base url for navigating to PSA Ticket details.
        this.siteService.app_getCurrentSite().then((site) => {
            this.onSite(site);
        });
    }

    onTicketListChange(ticketList: KvsTicket[]) {
        if (!this.loadingTickets && !ticketList.length) {
            this.loadingUrls = false;
            return;
        }

        this.ticketList.sort((a, b) => {
            return a.OpenDate > b.OpenDate ? -1 : a.OpenDate < b.OpenDate ? 1 : 0;
        });

        this.setTicketUrl(ticketList, this.baseUrlForTicket);
    }

    /** Used to change the main loading variable so that the template only needs to worry about one. */
    isLoading() {
        this.loading = this.loadingTickets || this.loadingUrls;
        console.log('IsLoading: ', this.loading);
    }

    onSite(site: Site) {
        this.site = site;
        if (this.site) {
            //Get the connection and set the base url used for navigating to tickets in the connected PSA
            this.connService.getConnectionBySite(this.site.Id).then((conn) => {
                this.onConnection(conn);
            });
        }
    }

    /** Get the base url for navigating to PSA ticket details. */
    async onConnection(conn: Connection) {
        this.connection = conn;
        this.baseUrlForTicket = await this.setBaseUrl(conn);
    }

    async setBaseUrl(conn: Connection): Promise<string> {
        if (!conn) {
            return '';
        }

        if (conn.Type == 'KBMS') {
            return conn.URL.trim();
        }

        //For CWREST, we need to get the version that is added to the url. This will be done when passing in the ticketId to navigate.
        if (conn.Type == 'CWREST') {
            return '';
        }

        if (conn.Type == 'ATREST') {
            return conn.URL.trim();
        }

        //ATWS needs the zone number.
        this.connService.getBaseUrlForPsaTicket(conn, this.site.Id, -1).then((url) => {
            return url;
        });

        // return empty string as further there are checks for empty
        return '';
    }

    /** Set the BaseUrl property for each ticket. */
    setTicketUrl(tickets: KvsTicket[], baseUrl: string) {
        if (!tickets.length) {
            this.loadingUrls = false;
            return;
        }

        if (baseUrl && baseUrl.length) {
            tickets.forEach((ticket) => {
                ticket.BaseUrl = this.getTicketUrl(
                    this.connection,
                    baseUrl,
                    ticket.IntegrationTicketId
                );
            });

            this.loadingUrls = false;
            return;
        }

        //If there is currently no baseUrl available, get it from the integrations and then set the BaseUrl properties of the tickets.
        this.connService
            .getBaseUrlForPsaTicket(this.connection, this.site.Id, tickets[0].IntegrationTicketId)
            .then((url) => {
                this.baseUrlForTicket = url;
                baseUrl = url;

                tickets.forEach((ticket) => {
                    ticket.BaseUrl = this.getTicketUrl(
                        this.connection,
                        baseUrl,
                        ticket.IntegrationTicketId
                    );
                });

                this.loadingUrls = false;
            });
    }

    /** Get the rest of the URL needed for navigating the ticket details for a PSA. */
    getTicketUrl(conn: Connection, baseUrl: string, ticketId: number): string {
        let rValue = '';

        if (baseUrl.endsWith('/')) {
            baseUrl = baseUrl.slice(0, -1);
        }

        switch (conn?.Type) {
            case 'KBMS':
                rValue = `${baseUrl}/react/servicedesk/tickets/${ticketId}`;
                break;
            case 'ATWS':
            case 'ATREST':
                rValue = `${baseUrl}/Mvc/ServiceDesk/TicketDetail.mvc?workspace=False&mode=0&ticketId=${ticketId}`;
                break;
            case 'CWREST':
                rValue = `${baseUrl}/services/system_io/Service/fv_sr100_request.rails?service_recid=${ticketId}&companyName=${conn.CompanyId}`;
                break;
            default:
                rValue = conn.URL;
                break;
        }

        return rValue;
    }
}
