import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'cm-summary-donut',
    templateUrl: './cm-summary-donut.component.html',
    styleUrls: ['./cm-summary-donut.component.scss'],
})
export class CmSummaryDonutComponent implements OnInit {
    @Input() standard: string;
    @Input() set chartData(value: chartData[]) {
        this._chartData = value;
    }
    get chartData() {
        return this._chartData;
    }
    @Input() centerText: string;
    @Input() isRapidBaseline: boolean;
    @Input() cardId: string;
    @Input() hasOverrides = false;
    @Output() cardClicked = new EventEmitter<string>();
    @Output() chartClicked = new EventEmitter<string>();
    _chartData: chartData[];
    constructor() {}

    ngOnInit() {}
    // processBGColor(color) {
    //     let bgColor;
    //     if (color.substr(0,1) == '#') {
    //         // hex
    //         let colorArray = color.substr(1).match(/.{1,2}/g);
    //         let aRgb = [ parseInt(colorArray[0], 16), parseInt(colorArray[1], 16),  parseInt(colorArray[2], 16) ];
    //         bgColor = `rgba(${aRgb[0]}, ${aRgb[1]}, ${aRgb[2]}, 0.5)`;
    //     } else if (color.indexOf('rgb') == 0) {
    //         let colorArray = color.match(/rgba?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d(?:\.\d?))\))?/);
    //         bgColor = `rgba(${colorArray[1]},${colorArray[2]},${colorArray[3]},0.5)`;
    //     } else {
    //         console.log("color needs to be in hex/rgb/rgba");
    //     }

    //     return bgColor;
    // }

    emitCardClick() {
        this.cardClicked.emit(this.cardId);
    }

    emitChartClick() {
        this.chartClicked.emit(this.cardId);
    }

    // // generate custom tooltip
    // tooltipContent(hti) {
    //     if (hti.name == 'placeholder') { return ''; }
    //     return `
    //         <b>${hti.name}</b><br>
    //         ${hti.value}
    //     `;

    // }
}

interface chartData {
    bindingName: string;
    count: number;
    color: string;
    legendColor: string;
}
