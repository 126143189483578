import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, NavigationStart } from '@angular/router';

import { ANIMATE_OUT_DURATION } from '../banner/banner.config';
import { Notification, NotificationMethod } from './notification.model';
import { NotificationService } from './notification.service';

@Component({
    selector: 'itc-notification-banner',
    templateUrl: './notification-banner.component.html',
    styleUrls: ['./notification-banner.component.scss'],
})
export class ItcNotificationBannerComponent implements OnInit {
    @Input() id = 'default-notification';
    @Input() allowMultiple = false;

    banners: Notification[] = [];
    bannerSubscription: Subscription;
    routeSubscription: Subscription;
    state: string;

    constructor(private router: Router, private notificationService: NotificationService) {}

    ngOnInit() {
        this.bannerSubscription = this.notificationService
            .getObservable()
            .subscribe((notification) => {
                // make sure the ID matches
                if (notification.id == this.id) {
                    // if we get a blank message, we clear all banners, might need to change this
                    if (!notification.message) {
                        this.banners = [];
                        return;
                    }
                    if (notification.method == NotificationMethod.Banner) {
                        if (this.allowMultiple) {
                            this.banners.push(notification);
                        } else {
                            this.banners = [notification];
                        }
                        this.state = 'visible';
                    }
                }
            });

        this.routeSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.notificationService.clear(this.id);
            }
        });
    }

    ngOnDestroy() {
        // unsubscribe
        this.bannerSubscription.unsubscribe();
        this.routeSubscription.unsubscribe();
    }

    bannerClosed(val) {
        // banner was closed, remove that notification
        setTimeout(() => {
            this.banners = this.banners.filter((x) => x !== val);
        }, ANIMATE_OUT_DURATION);
    }
}
