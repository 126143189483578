<div class="ui error message licenseViolation" *ngIf="false">
    <!--site?.HasLicenseViolation-->
    <b>
        <i class="warning sign icon"></i>
        License Violation:
    </b>
    Your subscription is for up to
    <b>{{ site.LicensedNumber }}</b>
    computers. The network being scanned returned
    <b>{{ site.DeployedNumber }}</b>
    . You must upgrade to continue with the assessment.
</div>

<sds-sidebar-layout [menuItems]="menuTree" [menuExtras]="menuExtras">
    <!--*ngIf="!site?.HasLicenseViolation"-->
    <router-outlet></router-outlet>
</sds-sidebar-layout>
