<sm-modal
    title="{{ riskModalInfo?.RiskDisplay }} Risk &nbsp;&nbsp; | &nbsp;&nbsp; {{
        riskModalInfo?.Title
    }}"
    class="{{ riskModalInfo?.RiskDisplay }}Risk md"
    #riskModal>
    <modal-content>
        <div class="riskModalContentContainer" *ngIf="riskModalInfo">
            <div>
                <p class="riskSectionTitle">Risk Score</p>
                {{ riskModalInfo?.Score }}
            </div>

            <div style="flex: 1; text-align: left">
                <p class="riskSectionTitle">Recommendations</p>
                {{ riskModalInfo?.Recommendation }}
                <ul class="riskModalIssueData">
                    <li
                        *ngFor="let issue of riskModalInfo?.IssueData"
                        [class.mitigated]="issue.IsMitigated">
                        {{ issue.InfoString }}
                    </li>
                </ul>
            </div>

            <div>
                <p class="riskSectionTitle">Severity</p>
                <img
                    src="/assets/img/risk_{{ riskModalInfo?.SeverityDisplay }}.svg"
                    style="width: 50px"
                    alt="{{ riskModalInfo?.Severity }}"
                    title="{{ riskModalInfo?.Severity }}" />
            </div>

            <div>
                <p class="riskSectionTitle">Probability</p>
                <img
                    src="/assets/img/risk_{{ riskModalInfo?.ProbabilityDisplay }}.svg"
                    style="width: 50px"
                    alt="{{ riskModalInfo?.Probability }}"
                    title="{{ riskModalInfo?.Probability }}" />
            </div>
        </div>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" class="close" (onclick)="riskInfoClosed()">Close</itc-button>
    </modal-actions>
</sm-modal>
