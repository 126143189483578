import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SemanticModalComponent } from 'app/semantic-legacy/semantic-legacy.module';
import { FormControl, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { KvsService } from 'app/sites/shared/kvs.service';
import { merge, Subject, takeUntil } from 'rxjs';
import { SiteSettingService } from 'app/sites/site/settings/site-settings.service';
import { SettingService } from 'app/settings/shared/setting.service';
import { TableSorting } from 'app/shared/sortable-table/sortable-table.component';
import { NotificationService } from 'app/shared/itc/notification/notification.service';
import { Organization } from 'app/organizations/organization.model';
import { OrganizationsService } from 'app/organizations/organizations.service';
import { Agent, SelectedAgent } from './discovery-agent.model';
import { AuthService } from 'app/core/auth';
import * as saveAs from 'file-saver';
import { MenuItem } from 'primeng/api';
import { DiscoveryAgentAdvancedOptions } from './discovery-agent-advanced-options.model';
import { DiscoveryAgentService } from './discovery-agent.service';

const weekday: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const weekdayDropDown: string[] = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
];

@Component({
    selector: 'sds-discovery-agent',
    templateUrl: './discovery-agent.component.html',
    styleUrls: ['./discovery-agent.component.scss'],
})
export class DiscoveryAgentComponent implements OnInit {
    @ViewChild('confirmRegenerateInstallKeyModal', { static: true })
    confirmRegenerateInstallKeyModal: SemanticModalComponent;
    @ViewChild('confirmRemoveAgentModal', { static: true })
    confirmRemoveAgentModal: SemanticModalComponent;
    @ViewChild('confirmBulkRemoveAgentModal', { static: true })
    confirmBulkRemoveAgentModal: SemanticModalComponent;
    @ViewChild('confirmAgentRunNowModal', { static: true })
    confirmAgentRunNowModal: SemanticModalComponent;
    @ViewChild('confirmAgentScheduleModal', { static: true })
    confirmAgentScheduleModal: SemanticModalComponent;
    @ViewChild('manageAgentModal', { static: true }) manageAgentModal: SemanticModalComponent;
    @ViewChild('confirmAgentUpdateModal', { static: true })
    confirmAgentUpdateModal: SemanticModalComponent;
    @ViewChild('editDiscoveryAgentCommentBulkModal', { static: true })
    editDiscoveryAgentCommentBulkModal: SemanticModalComponent;
    @ViewChild('editDiscoveryAgentLabelBulkModal', { static: true })
    editDiscoveryAgentLabelBulkModal: SemanticModalComponent;
    @ViewChild('confirmCancelScansModal', { static: true })
    confirmCancelScansModal: SemanticModalComponent;
    @ViewChild('deepFileScanSettingModal', { static: true })
    deepFileScanSettingModal: SemanticModalComponent;
    @ViewChild('deepfilesScanDayGuard', { static: true })
    deepfilesScanDayGuard: SemanticModalComponent;
    @ViewChild('advancedOptionsModal', { static: true }) advancedOptionsModal: SemanticModalComponent;

    defaultValueFormGroup = {
        driveEncryptCheckBox: false,
        hipaaCheckBox: false,
        pciCheckBox: false,
        perDataCheckBox: false,
        perIdentInforCheckBox: false,
        scanningPdfCheckBox: false,
        scanningZipCheckBox: false,
        pdfTimeOutValue: 5,
    };
    finishedLoadingAgents: boolean = false;
    savingAgentSchedule: boolean = false;
    isDeleting: boolean = false;
    isCMProduct: boolean = false;
    isVulScanProduct: boolean = false;
    isCMGRCProduct: boolean = false;
    isNDProProduct: boolean = false;

    allAgentsChecked: boolean = false;
    isDeepRunScanNow: boolean = false;
    is3ppScan: boolean = false;
    runNowModalText: string = '';

    agentApps: Agent[] = [];
    selectedAgents: Agent[] = [];

    manageType: string = '';
    manageTarget: string;
    manageRunning: boolean = false;
    autoUpdateSetting: boolean = false;

    modalTitle: string;
    modalContent: string;
    selectedDay: string;
    installKey: string = '';

    timeDateFormat: string;

    robotIDs: string[] = [];
    filteredAgents: Agent[] = [];
    sortedAgents: Agent[] = [];
    pagedAgents: Agent[] = [];
    agtPageNumber: number = 0;
    selectedAgent: SelectedAgent = { Id: '', Label: '', CanRemove: false };
    quickFilter: string;
    sortColumn: string;
    sortDirection: string;
    indexSelectedDay: number;

    weekDaysDropDown: string[] = [];
    offlineAgents: string = '';
    agentsToRemove: string = '';

    ngUnsubscribe$: Subject<DiscoveryAgentComponent> = new Subject();
    friendlyTypes: any = {
        AGT: 'Discovery Agent',
    };

    weekDaysCtrls: FormControl[] = [
        new FormControl<boolean>(false),
        new FormControl<boolean>(false),
        new FormControl<boolean>(false),
        new FormControl<boolean>(false),
        new FormControl<boolean>(false),
        new FormControl<boolean>(false),
        new FormControl<boolean>(false),
    ];
    prevAgentSchedule: string = '';

    commentCtrl: FormControl<string> = new FormControl('', Validators.maxLength(100));
    bulkCommentCtrl: FormControl<string> = new FormControl('', Validators.maxLength(100));
    labelCtrl: FormControl<string> = new FormControl('', Validators.maxLength(100));
    bulkLabelCtrl: FormControl<string> = new FormControl('', Validators.maxLength(100));
    dayScheduledCtrl: UntypedFormControl = new UntypedFormControl();
    formGru;

    weekdays: string[] = weekday;
    weekdayDropDown: string[] = weekday;
    _organization: Organization;

    sensitiveDataStatus: boolean = true;
    settingScanForm: FormGroup;
    bulkActions: MenuItem[];

    isDailyScan: boolean;
    isDeepFileScan: boolean;

    advancedOptions: DiscoveryAgentAdvancedOptions = {
        urlTesting: false, testEntertainment: false, testPornography: false,
        testShareware: false, testSocialMedia: false, testWarez: false, testWebMail: false,
        portTesting: false, wifiData: false, policies: false, screenSaverSettings: false,
        usbDetection: false, internetAccessibility: false, loginDetails: false
    };

    modalAdvancedOptions: DiscoveryAgentAdvancedOptions = {
        urlTesting: false, testEntertainment: false, testPornography: false,
        testShareware: false, testSocialMedia: false, testWarez: false, testWebMail: false,
        portTesting: false, wifiData: false, policies: false, screenSaverSettings: false,
        usbDetection: false, internetAccessibility: false, loginDetails: false
    };
    isAdvancedOptionsDirty: boolean = false;

    @Input() set organization(org: Organization) {
        if (org) {
            this._organization = org;
            this.getInstallKey();
            this.getDiscoveryAgents();
            this.loadDiscoveryAgentDefaultSettings();
        }
    }
    get organization() {
        return this._organization;
    }

    constructor(
        private kvsService: KvsService,
        private notificationService: NotificationService,
        private siteSettingService: SiteSettingService,
        private settingService: SettingService,
        private organizationsService: OrganizationsService,
        private router: Router,
        private authService: AuthService,
        private discoveryAgentService: DiscoveryAgentService
    ) { }

    async ngOnInit() {
        this.settingService.getSettings().then((settings) => this.process(settings));
        this.checkProducts();
        this.createSettingScanForm();

        merge(
            this.settingScanForm.get('driveEncryptCheckBox').valueChanges,
            this.settingScanForm.get('hipaaCheckBox').valueChanges,
            this.settingScanForm.get('pciCheckBox').valueChanges,
            this.settingScanForm.get('perDataCheckBox').valueChanges,
            this.settingScanForm.get('perIdentInforCheckBox').valueChanges
        )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => {
                this.validateDeepFileScanSetting();
            });

        this.createBulkActions();
    }

    async loadDiscoveryAgentDefaultSettings() {
        let discoveryAgentOrganizationSettings = await this.discoveryAgentService.getOrgDiscoveryAgentDefaultSettings(this.organization.Id);
        if (discoveryAgentOrganizationSettings?.advancedOptions) {
            this.advancedOptions = structuredClone(discoveryAgentOrganizationSettings.advancedOptions);
        }

    }

    loadDeepFileScanSetting(showModal: boolean = false) {
        this.organizationsService
            .getDeepFileScanSettings(this._organization.Name, this.robotIDs)
            .then((response) => {
                if (response.includes('-driveencryption'))
                    this.settingScanForm.get('driveEncryptCheckBox').setValue(true);
                if (response.includes('-hipaadeep'))
                    this.settingScanForm.get('hipaaCheckBox').setValue(true);
                if (response.includes('-pcideep'))
                    this.settingScanForm.get('pciCheckBox').setValue(true);
                if (response.includes('-gdprdeep'))
                    this.settingScanForm.get('perDataCheckBox').setValue(true);
                if (response.includes('-pii'))
                    this.settingScanForm.get('perIdentInforCheckBox').setValue(true);

                if (response.includes('-zipChecked'))
                    this.settingScanForm.get('scanningZipCheckBox').setValue(true);

                if (response.includes('-pdftimeout')) {
                    this.settingScanForm.get('scanningPdfCheckBox').setValue(true);
                    const regex = new RegExp(`-pdftimeout\\|\\s*(\\d+)`, 'i');
                    const match = response.match(regex);
                    if (match) this.settingScanForm.get('pdfTimeOutValue').setValue(match[1]);
                }

                if (!response) {
                    this.settingScanForm.patchValue(this.defaultValueFormGroup);
                }

                if (showModal) {
                    this.deepFileScanSettingModal.show();
                }
            })
            .catch((err) => {
                this.notificationService.toast.error('Error', err);
            });
    }

    createSettingScanForm() {
        this.settingScanForm = new FormGroup({
            driveEncryptCheckBox: new FormControl(false),
            hipaaCheckBox: new FormControl(false),
            pciCheckBox: new FormControl(false),
            perDataCheckBox: new FormControl(false),
            perIdentInforCheckBox: new FormControl(false),
            scanningPdfCheckBox: new FormControl(false),
            scanningZipCheckBox: new FormControl(false),
            pdfTimeOutValue: new FormControl({ value: 5, disabled: false }, [
                Validators.min(1),
                Validators.max(60),
                Validators.required,
            ]),
        });
    }

    EnableRunScanChange() {
        if (this.isDailyScan == true) {
            this.confirmDayListChange(false);
            this.dayScheduledCtrl.setValue(this.weekDaysDropDown[0]);
        } else {
            this.is3ppScan = this.isDailyScan;
            this.isDeepFileScan = this.isDailyScan;
        }
    }
    checkProducts() {
        let products = this.authService.getProducts();
        if (products.CMProduct) {
            this.isCMProduct = true;
        }
        if (products.CMGRCProduct) {
            this.isCMGRCProduct = true;
        }
        if (products.NdProWeb) {
            this.isNDProProduct = true;
        }
        if (products.VulScanProduct) {
            this.isVulScanProduct = true;
        }
    }

    validateDeepFileScanSetting() {
        if (
            !this.settingScanForm.get('driveEncryptCheckBox').value &&
            !this.settingScanForm.get('hipaaCheckBox').value &&
            !this.settingScanForm.get('pciCheckBox').value &&
            !this.settingScanForm.get('perDataCheckBox').value &&
            !this.settingScanForm.get('perIdentInforCheckBox').value
        ) {
            this.sensitiveDataStatus = true;
            this.settingScanForm.get('pdfTimeOutValue').setValue(5);
            this.settingScanForm.get('scanningZipCheckBox').setValue(false);
            this.settingScanForm.get('scanningPdfCheckBox').setValue(false);
        } else {
            this.sensitiveDataStatus = false;
        }
    }

    getInstallKey() {
        this.organizationsService
            .getInstallKey(this.organization.Id)
            .then((installKey) => (this.installKey = installKey.InstallKey));
    }

    /**
     * Load, Filter, and Sort the Discovery Agents.
     */
    getDiscoveryAgents() {
        this.finishedLoadingAgents = false;
        this.loadDiscoveryAgents()
            .then(() => {
                this.filteredAgents = this.agentApps;
                this.robotIDs = this.filteredAgents?.map((agent) => agent.Id);
                this.loadDeepFileScanSetting();
                this.getDeepFileScanSettingsStatus();
                this.get3ppScanSetting();
                this.onSortAgents();
                this.adjustScanScheduleCtrls();
                this.finishedLoadingAgents = true;
            })
            .catch((err) => {
                this.finishedLoadingAgents = true;
            });
    }

    getDeepFileScanSettingsStatus() {
        this.organizationsService
            .GetDeepFileScanSettingsStatus(this._organization.Name, this.robotIDs)
            .then((res) => {
                if (res) {
                    this.isDeepFileScan = true;
                    this.dayScheduledCtrl.setValue(res);
                } else {
                    this.isDeepFileScan = false;
                    this.dayScheduledCtrl.setValue('');
                }
            })
            .catch((err) => console.log(err));
    }

    get3ppScanSetting() {
        this.organizationsService
            .Get3ppScanSetting(this._organization.Id, this.robotIDs)
            .then((res) => {
                this.is3ppScan = res;
            })
            .catch((err) => console.log(err));
    }

    checkCheckBoxvalue(index: any) {
        this.indexSelectedDay = weekdayDropDown.findIndex((p) => p === this.dayScheduledCtrl.value);
        if (index === this.indexSelectedDay) {
            this.selectedDay = weekdayDropDown[this.indexSelectedDay];
            this.deepfilesScanDayGuard.show({ closable: false });
        } else {
            this.confirmDayListChange(false);
        }
    }

    adjustScanScheduleCtrls() {
        this.weekDaysDropDown = [];
        for (let agent of this.agentApps) {
            if (agent.ScanSchedule.length > 0) {
                this.isDailyScan = true;
                let _sDays: string[] = agent.ScanSchedule.split(' ');
                let _cronDays: number[] = [];
                _sDays.forEach((day) => {
                    _cronDays.push(weekday.indexOf(day));
                });

                this.weekDaysCtrls.forEach((dayCtrl) => {
                    dayCtrl.setValue(false);
                });

                _cronDays.forEach((cron) => {
                    this.weekDaysCtrls[cron].setValue(true);
                    this.weekDaysDropDown.push(weekdayDropDown[cron]);
                });

                if (this.weekDaysDropDown.length) {
                    this.dayScheduledCtrl.setValue(this.weekDaysDropDown[0]);
                }
                this.prevAgentSchedule = _cronDays.join('');

                break;
            } else {
                this.isDailyScan = false;
                this.weekDaysCtrls.forEach((dayCtrl) => {
                    dayCtrl.disable();
                });
            }
        }
    }

    cancelDayListChange() {
        this.weekDaysCtrls[this.indexSelectedDay].setValue(true);
        this.deepfilesScanDayGuard.hide();
    }

    confirmDayListChange(close: boolean = true) {
        this.weekDaysDropDown = [];
        for (let i = 0; i < this.weekDaysCtrls.length; i++) {
            if (this.weekDaysCtrls[i].value) {
                this.weekDaysDropDown.push(weekdayDropDown[i]);
            }
        }

        if (!this.weekDaysDropDown.length) {
            this.isDeepFileScan = false;
            this.is3ppScan = false;
        }

        if (close) {
            this.isDeepFileScan = false;
            this.dayScheduledCtrl.setValue('');
            this.deepfilesScanDayGuard.hide();
        }
    }
    /**
     * Function to handle getting Discovery Agents separately from other appliances.
     * Discovery Agents have extra data needed for the Discovery Agents table.
     * The agents that are scheduled for deprovisioning are excluded from the results.
     * @returns Promise that resolves true when it is finished.
     */
    loadDiscoveryAgents(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.organizationsService
                .getOrgDiscoveryAgentInfo(this.organization.Name)
                .then((res) => {
                    this.agentApps = [];
                    let agents: any[] = res;
                    if (!(agents?.length > 0)) {
                        this.weekDaysCtrls.forEach((dayCtrl) => {
                            dayCtrl.disable();
                        });
                    }

                    agents.forEach((agent) => {
                        let updateStatus = 'unknown';
                        let updateAvailable = false;
                        if (agent.UpdateStatus == 1) {
                            updateStatus = 'up-to-date';
                            updateAvailable = false;
                        } else if (agent.UpdateStatus == 0) {
                            updateStatus = 'update available';
                            updateAvailable = true;
                        }

                        let lastCheckin = new Date(agent.LastCheckin);
                        let noCheckin = lastCheckin.getFullYear() < 2000;

                        let lastScan = new Date(agent.LastScan);
                        let hasScans = lastScan.getFullYear() > 2000;

                        let _splitDays: number[] = agent.ScanSchedule.split(',');
                        let sortedCron = _splitDays.sort((a, b) => (a > b ? 1 : a < b ? -1 : 0));
                        let stringDays: string[] = [];
                        sortedCron.forEach((dayNum) => {
                            stringDays.push(weekday[dayNum]);
                        });

                        this.agentApps.push({
                            Id: agent.Id,
                            Description: agent.Description ? agent.Description : '',
                            Computer: agent.Computer,
                            ConnectorId: agent.ConnectorId,
                            LastCheckin: agent.LastCheckin,
                            UpdateStatus: updateStatus,
                            UpdateAvailable: updateAvailable,
                            IsCheckedIn: !noCheckin,
                            Status: agent.IsOnline ? 'Online' : 'Offline',
                            LastScan: agent.LastScan,
                            HasScans: hasScans,
                            ScanSchedule: stringDays.join(' '),
                            Type: this.friendlyTypes['AGT'],
                            Label: agent.Label ? agent.Label : '',
                            IPAddresses: agent.IPAddresses,
                            Checked: false,
                        });
                    });

                    resolve(true);
                })
                .catch((err) => {
                    if (err.status == 403) this.router.navigate(['/error', '403', 'RFTE105']);
                    this.weekDaysCtrls.forEach((dayCtrl) => {
                        dayCtrl.disable();
                    });
                    resolve(true);
                });
        });
    }

    onGenerateInstallKeyButton() {
        if (!this.installKey || this.installKey == '') {
            this.generateInstallKey();
        } else {
            this.confirmRegenerateInstallKeyModal.show();
        }
    }

    generateInstallKey() {
        this.organizationsService
            .rotateInstallKey(this.organization.Id)
            .then((installKey) => (this.installKey = installKey));
    }

    copyToClipboard(item: string) {
        if (item == null) item = '';
        navigator.clipboard.writeText(item);
        this.notificationService.toast.success('Success', 'Copied to Clipboard');
    }

    advancedOptionsEqual(o1: DiscoveryAgentAdvancedOptions, o2: DiscoveryAgentAdvancedOptions): boolean {
        return (JSON.stringify(o1) === JSON.stringify(o2));
    }

    async showAdvancedOptionsModal() {
        this.modalAdvancedOptions = structuredClone(this.advancedOptions);
        this.urlSubOptionChanged();
        (this.advancedOptionsModal as any).show({ closable: false });
    }

    closeAdvancedOptionsModalAction() {
        this.advancedOptionsModal.hide();
        this.isAdvancedOptionsDirty = !this.advancedOptionsEqual(this.modalAdvancedOptions,this.advancedOptions);
        this.advancedOptions = structuredClone(this.modalAdvancedOptions);
    }

    cancelAdvancedOptionsModalAction() {
        this.advancedOptionsModal.hide();
    }

    urlOptionChanged() {
        this.modalAdvancedOptions.testEntertainment = this.modalAdvancedOptions.urlTesting;
        this.modalAdvancedOptions.testPornography = this.modalAdvancedOptions.urlTesting;
        this.modalAdvancedOptions.testShareware = this.modalAdvancedOptions.urlTesting;
        this.modalAdvancedOptions.testSocialMedia = this.modalAdvancedOptions.urlTesting;
        this.modalAdvancedOptions.testWarez = this.modalAdvancedOptions.urlTesting;
        this.modalAdvancedOptions.testWebMail = this.modalAdvancedOptions.urlTesting;
    }

    urlSubOptionChanged() {

        let anyUrlSubOptionChecked: boolean = (this.modalAdvancedOptions.testEntertainment || this.modalAdvancedOptions.testPornography || this.modalAdvancedOptions.testShareware
            || this.modalAdvancedOptions.testSocialMedia || this.modalAdvancedOptions.testWarez || this.modalAdvancedOptions.testWebMail);

        this.modalAdvancedOptions.urlTesting = anyUrlSubOptionChecked;

    }

    showConfirmAgentScheduleModal() {

        this.modalContent = 'Update all agents to the selected scan schedule?';
        this.confirmAgentScheduleModal.show();
    }

    /**
     * Sorting function for the Discovery Agents table.
     * @param sorting
     */
    onSortAgents(sorting?: TableSorting) {
        if (!sorting)
            this.sortedAgents = this.filteredAgents.sort((a, b) =>
                a.Computer.toLowerCase().localeCompare(b.Computer.toLowerCase())
            );
        else {
            switch (sorting.sortColumn) {
                case 'status':
                    this.sortedAgents = this.filteredAgents.sort((a, b) =>
                        a.Status.toLowerCase().localeCompare(b.Status.toLowerCase())
                    );
                    break;
                case 'computer':
                    this.sortedAgents = this.filteredAgents.sort((a, b) =>
                        a.Computer.toLowerCase().localeCompare(b.Computer.toLowerCase())
                    );
                    break;
                case 'last-scan':
                    this.sortedAgents = this.filteredAgents.sort((a, b) =>
                        a.LastScan > b.LastScan ? 1 : a.LastScan < b.LastScan ? -1 : 0
                    );
                    break;
                case 'agentId':
                    this.sortedAgents = this.filteredAgents.sort((a, b) =>
                        a.Id.toLowerCase().localeCompare(b.Id.toLowerCase())
                    );
                    break;
                case 'description': //description
                    let tempSort: any;
                    if (sorting.sortDirection == 'desc')
                        tempSort = this.filteredAgents.sort((a, b) =>
                            a.Description == '' ? -1 : 1
                        );
                    //sort empty vs not empty first
                    else
                        tempSort = this.filteredAgents.sort((a, b) =>
                            a.Description == '' ? 1 : -1
                        );
                    tempSort.sort((a, b) =>
                        a.Description == ''
                            ? 0
                            : a.Description.toLowerCase().localeCompare(b.Description.toLowerCase())
                    ); //sort ignoring empty
                    this.sortedAgents = tempSort;
                    break;
                case 'label':
                    let tempSortLabel: any;
                    if (sorting.sortDirection == 'desc')
                        tempSortLabel = this.filteredAgents.sort((a, b) =>
                            a.Label == '' ? -1 : 1
                        );
                    //sort empty vs not empty first
                    else
                        tempSortLabel = this.filteredAgents.sort((a, b) =>
                            a.Label == '' ? 1 : -1
                        );
                    tempSortLabel.sort((a, b) =>
                        a.Label == ''
                            ? 0
                            : a.Label.toLowerCase().localeCompare(b.Label.toLowerCase())
                    ); //sort ignoring empty
                    this.sortedAgents = tempSortLabel;
                    break;
                case 'updateStatus':
                    this.sortedAgents = this.filteredAgents.sort((a, b) =>
                        a.UpdateStatus.toLowerCase().localeCompare(b.UpdateStatus.toLowerCase())
                    );
                    break;
                case 'last-checkin':
                    this.sortedAgents = this.filteredAgents.sort((a, b) =>
                        a.LastCheckin > b.LastCheckin ? 1 : a.LastCheckin < b.LastCheckin ? -1 : 0
                    );
                    break;
                default:
                    this.sortedAgents = this.filteredAgents.sort((a, b) =>
                        a.Computer.toLowerCase().localeCompare(b.Computer.toLowerCase())
                    );
                    break;
            }

            if (sorting.sortDirection == 'desc')
                //descending
                this.sortedAgents.reverse();
        }

        this.filteredAgents = [...this.sortedAgents];
    }

    /**
     * Quickfilter function for the Discovery Agents table.
     */
    doQuickFilter() {
        if (this.quickFilter) {
            this.filteredAgents = this.agentApps.filter(
                (app) =>
                    app.Id.toLowerCase().includes(this.quickFilter.toLowerCase()) ||
                    app.Computer.toLowerCase().includes(this.quickFilter.toLowerCase()) ||
                    app.Description.toLowerCase().includes(this.quickFilter.toLowerCase()) ||
                    app.Label.toLowerCase().includes(this.quickFilter.toLowerCase())
            );
        } else {
            this.filteredAgents = this.agentApps;
        }
        this.onSortAgents();
    }

    /**
     * Set selectedAgent to the agent provided.
     * @param agent
     */
    getSelectedAgent(agent: any) {
        this.selectedAgent = { Id: agent.Id, Label: agent.Label, CanRemove: agent.Status == 'Online' };
    }

    /**
     * Reset selectedAgent to the default values { Id: "", CanRemove: false }
     */
    resetSelectedAgent() {
        this.selectedAgent = { Id: '', Label: '', CanRemove: false };
    }

    showRemoveAgentModal(agent: any) {
        this.getSelectedAgent(agent);
        this.confirmRemoveAgentModal.show();
    }

    hideRemoveAgentModal() {
        this.confirmRemoveAgentModal.hide();
        this.resetSelectedAgent();
    }

    showManageAgentModal(agent: any) {
        this.getSelectedAgent(agent);
        this.commentCtrl.setValue(agent.Description);
        this.labelCtrl.setValue(agent.Label);
        this.manageAgentModal.show();
    }

    showBulkAgentRemovalModal() {
        this.offlineAgents = '';
        this.agentsToRemove = '';
        this.selectedAgents.forEach((agent) => {
            if (agent.Status != 'Online') {
                this.offlineAgents += agent.Id + '\n';
            } else {
                this.agentsToRemove += agent.Id + '\n';
            }
        });

        this.confirmBulkRemoveAgentModal.show();
    }

    clearSelectedAgents() {
        this.selectedAgents = [];
        this.allAgentsChecked = false;
    }

    /**
     * Schedule a desprovision task to the Discovery Agent.
     * The list of agents is then updated on the front end.
     * @param agent
     */
    removeAgent(agent: any) {
        let agentToRemove: any = { Id: agent.Id, CanRemove: agent.CanRemove };
        if (agentToRemove.CanRemove) {
            this.notificationService.toast.info(
                'Info',
                `Scheduling Discovery Agent ${agentToRemove.Id} for removal.`
            );

            this.kvsService
                .deprovisionAgentForOrg(agent.Id)
                .then((res) => {
                    if (res) {
                        this.notificationService.toast.success(
                            'Success',
                            `Discovery Agent ${agentToRemove.Id} was scheduled for removal.`
                        );
                        let temp: any[] = [];
                        temp.push(
                            ...this.agentApps.filter(
                                (currentAgent) => currentAgent.Id != agentToRemove.Id
                            )
                        );

                        this.agentApps = [];
                        this.agentApps.push(...temp);
                        this.doQuickFilter();
                    } else {
                        this.notificationService.toast.error(
                            'Error',
                            `Discovery Agent ${agentToRemove.Id} was not scheduled for removal.`
                        );
                    }
                })
                .catch((err) => {
                    this.notificationService.toast.error('Error', err);
                });
        }
        this.confirmRemoveAgentModal.hide();
        this.resetSelectedAgent();
        this.getDiscoveryAgents();
    }

    removeBulkAgent() {
        this.selectedAgents.forEach((agent) => {
            if (agent.Status == 'Online') {
                this.notificationService.toast.info(
                    'Info',
                    `Scheduling Discovery Agent ${agent.Id} for removal.`
                );

                this.kvsService
                    .deprovisionAgentForOrg(agent.Id)
                    .then((res) => {
                        if (res) {
                            this.notificationService.toast.success(
                                'Success',
                                `Discovery Agent ${agent.Id} was scheduled for removal.`
                            );
                            let temp: any[] = [];
                            temp.push(
                                ...this.agentApps.filter(
                                    (currentAgent) => currentAgent.Id != agent.Id
                                )
                            );

                            this.agentApps = [];
                            this.agentApps.push(...temp);
                            this.doQuickFilter();
                        } else {
                            this.notificationService.toast.error(
                                'Error',
                                `Discovery Agent ${agent.Id} was not scheduled for removal.`
                            );
                        }
                    })
                    .catch((err) => {
                        this.notificationService.toast.error('Error', err);
                    });
            }
        });

        this.clearSelectedAgents();
        this.confirmBulkRemoveAgentModal.hide();
        this.getDiscoveryAgents();
    }

    showAgentRunNowModal() {
        this.confirmAgentRunNowModal.show();
    }

    hideAgentRunNowModal() {
        this.confirmAgentRunNowModal.hide();
        this.resetSelectedAgent();
    }

    agentBulkUpdate() {
        let error: boolean = false;
        this.selectedAgents.forEach((agent) => {
            this.discoveryAgentService.sendUpdateToDiscoveryAgentById(this.organization.Id,agent.Id).catch((err) => {
                this.notificationService.toast.error(
                    'Error',
                    'There was a problem sending the update request to the data collector.'
                );
                error = true;
            });
        });

        if (!error) {
            this.notificationService.toast.success(
                'Sent',
                'A request was sent to update the data collector. The data collector will update itself after 15 minutes and should be available for use after the update is complete.'
            );
        }

        this.clearSelectedAgents();
        this.confirmAgentUpdateModal.hide();
        this.getDiscoveryAgents();
    }

    saveBulkComment() {
        let error: boolean = false;
        if (this.bulkCommentCtrl.value == null) this.bulkCommentCtrl.setValue('');
        this.selectedAgents.forEach((agent) => {
            this.siteSettingService
                .setApplianceDescription(agent.Id, this.bulkCommentCtrl.value)
                .catch((err) => {
                    this.notificationService.toast.error(
                        'Error',
                        'There was a problem sending the comment update request to the data collector.'
                    );
                    error = true;
                });
        });

        if (!error) {
            this.notificationService.toast.success(
                'Sent',
                'A request was sent to update the comment for the data collector.'
            );
        }

        this.clearSelectedAgents();
        this.editDiscoveryAgentCommentBulkModal.hide();
        this.getDiscoveryAgents();
    }

    runDeepFileScanNow(isDeepScan: boolean) {
        this.isDeepRunScanNow = isDeepScan;
        this.runNowModalText = isDeepScan
            ? 'Run Discovery Agent Deep Files Scan Now?'
            : 'Run Discovery Agent Scan Now?';
        this.confirmAgentRunNowModal.show();
    }

    saveBulkLabel() {
        if (this.bulkLabelCtrl.value == null) this.bulkLabelCtrl.setValue('');

        const updatedSelectedAgents = this.selectedAgents
            .filter((x) => x.Label != this.bulkLabelCtrl.value)
            .map((x) => { return { Id: x.Id, PreviousLabel: x.Label } });
        console.log(updatedSelectedAgents);
        if (!updatedSelectedAgents?.length) {
            this.notificationService.toast.success(
                'Sent',
                'A request was sent to update the label for the data collector.'
            );
            this.clearSelectedAgents();
            this.editDiscoveryAgentLabelBulkModal.hide();
            this.getDiscoveryAgents();
        }

        this.siteSettingService
            .setMultipleApplianceLabels(
                updatedSelectedAgents,
                this.bulkLabelCtrl.value,
                this.organization.Id
            )
            .then((_) => {
                this.notificationService.toast.success(
                    'Sent',
                    'A request was sent to update the label for the data collector.'
                );
                this.clearSelectedAgents();
                this.editDiscoveryAgentLabelBulkModal.hide();
                this.getDiscoveryAgents();
            })
            .catch((err) => {
                this.notificationService.toast.error(
                    'Error',
                    'There was a problem sending the label update request to the data collector.'
                );
                this.clearSelectedAgents();
                this.editDiscoveryAgentLabelBulkModal.hide();
                this.getDiscoveryAgents();
            });
    }

    cancelScans() {
        this.notificationService.toast.info('Info', 'Canceling scan');
        this.selectedAgents.forEach((agent) => {
            this.kvsService
                .cancelAllScans(agent.Id)
                .then((res) => {
                    this.notificationService.toast.success(
                        'Success',
                        'Scans successfully cancelled'
                    );
                })
                .catch(() => {
                    this.notificationService.toast.error('', 'Error canceling scans');
                });
        });

        this.clearSelectedAgents();
        this.confirmCancelScansModal.hide();
        this.getDiscoveryAgents();
    }

    process(settings: any) {
        for (let s of settings) {
            if (s.Name == 'DateFormat') {
                this.timeDateFormat = s.Value + ' hh:mm:ss a';
            }
        }
    }

    /**
     * Queue a new scan for selected Discovery Agents.
     */
    agentRunNow() {
        this.confirmAgentRunNowModal.hide();
        let deepScanSettings = this.isDeepRunScanNow ? this.getScanSetting() : '';
        this.notificationService.toast.info('Info', 'Queueing scan');
        this.selectedAgents.forEach((agent) => {
            this.kvsService
                .agentRunNowOrg(this.organization.Id, agent.Id, deepScanSettings, this.is3ppScan)
                .then((res) => {
                    this.notificationService.toast.success('Success', 'Scan successfully queued');
                })
                .catch(() => {
                    this.notificationService.toast.error('', 'Error queuing scan');
                });
        });
    }

    updateDeepFileScanAgentFlags() {
        this.savingAgentSchedule = true;
        let deepScanSettings = this.getScanSetting();
        this.organizationsService
            .UpdateAgentFlags(this.organization.Id, this.robotIDs, deepScanSettings)
            .then((res) => {
                this.savingAgentSchedule = false;
                this.deepFileScanSettingModal.hide();
                this.notificationService.toast.success(
                    'Success',
                    'Deep File Scan settings were saved.'
                );
            })
            .catch((_) => {
                this.savingAgentSchedule = false;
                this.notificationService.toast.error('Error', 'An unspecified error occurred.');
            });
    }

    onApplySchedule() {
        this.savingAgentSchedule = true;
        let week: string = '';
        if (this.isDailyScan) {
            for (let i = 0; i < this.weekDaysCtrls.length; i++) {
                if (this.weekDaysCtrls[i].value == true) week += i;
            }
        }

        let deepScanSettings = this.getScanSetting();
        let weeklyScanDay = this.isDeepFileScan ? this.dayScheduledCtrl.value : '';

        this.kvsService
            .scheduleAgentForOrg(
                this._organization.Id,
                week,
                deepScanSettings,
                weeklyScanDay,
                this.isDeepFileScan,
                this.is3ppScan,
                this.advancedOptions
            )
            .then((_) => { })
            .then((_) => {
                this.savingAgentSchedule = false;
                this.isAdvancedOptionsDirty = false;
                this.prevAgentSchedule = week;
                this.notificationService.toast.success(
                    'Success',
                    'Scan Schedule settings were saved.'
                );
                this.getDiscoveryAgents();
                this.confirmAgentScheduleModal.hide();
            })
            .catch((_) => {
                this.savingAgentSchedule = false;
                this.notificationService.toast.error('Error', 'An unspecified error occurred.');
            });
    }

    getScanSetting(): string {
        let settings = [];
        if (this.settingScanForm.get('driveEncryptCheckBox').value) {
            settings.push('-driveencryption');
        }
        if (this.settingScanForm.get('hipaaCheckBox').value) {
            settings.push('-hipaadeep');
        }
        if (this.settingScanForm.get('pciCheckBox').value) {
            settings.push('-pcideep');
        }
        if (this.settingScanForm.get('perDataCheckBox').value) {
            settings.push('-gdprdeep');
        }
        if (this.settingScanForm.get('perIdentInforCheckBox').value) {
            settings.push('-pii');
        }
        if (this.settingScanForm.get('scanningZipCheckBox').value) {
            settings.push('-zipChecked');
        } else if (!this.sensitiveDataStatus) {
            settings.push('-skipzips');
        }

        if (this.settingScanForm.get('scanningPdfCheckBox').value) {
            settings.push('-pdftimeout|' + this.settingScanForm.get('pdfTimeOutValue').value);
        } else if (!this.sensitiveDataStatus) settings.push('-skippdfs');

        return settings.join('|');
    }

    /**
     *   Action when you select a single row
     */
    checkAgent(agent: any): void {
        if (this.allAgentsChecked) {
            this.allAgentsChecked = false;
        }
        agent.Checked = !agent.Checked;
        this.updateSelected();
    }

    /**
     * Toggle all responses in list view table
     *
     * @param status {boolean}
     */
    checkAllAgents(status: boolean): void {
        this.pagedAgents.forEach((r) => {
            r.Checked = status;
        });
        this.allAgentsChecked = status;
        this.updateSelected();
    }

    /**
     * Update the selected rows to selectedResponses variable
     */
    updateSelected(): void {
        this.selectedAgents = this.pagedAgents.filter((a) => a.Checked);
        this.createBulkActions();
    }

    showOverview() {
        this.manageType = '';
        this.updateModalSize();
    }

    updateModalSize() {
        let modalClass = 'small';
        this.manageAgentModal.modal.nativeElement.classList.remove('large', 'tiny', 'small');
        this.manageAgentModal.modal.nativeElement.classList.add(modalClass);
    }

    runManage(ddValue: string) {
        this.manageTarget = this.selectedAgent.Id;
        this.manageType = ddValue;
        this.updateModalSize();
        if (ddValue == 'Update') {
            this.modalTitle = 'Warning - ' + this.selectedAgent.Id;
            this.modalContent =
                'Issuing a command to update the data collector will cancel all running scans. Do you wish to update now?';
        } else if (ddValue == 'Set Auto-Update') {

            this.manageRunning = true;
            this.modalTitle = 'Requesting Auto-Update Status...';
            this.discoveryAgentService
                .getAutoUpdateForApplianceById(this.organization.Id,this.selectedAgent.Id)
                .then((res) => {
                    this.autoUpdateSetting = res;
                    this.modalTitle = (res ? 'Dis' : 'En') + 'able Auto-update - ' + this.selectedAgent.Id;
                    this.modalContent = 'Do you wish to ' + (res ? 'dis' : 'en') + 'able auto-update?';
                    this.manageRunning = false;
                });
        } else if (ddValue == 'Download Logs') {
            this.manageRunning = true;
            this.modalTitle = 'Downloading Logs...';
            this.notificationService.toast.download(
                'Downloading Logs',
                'Requesting log files from ' + this.selectedAgent.Id
            );
            this.discoveryAgentService
                .getLogsForApplianceById(this.organization.Id ,this.manageTarget)
                .then((b64) => {
                    let raw = window.atob(b64);
                    let bytes = new Uint8Array(raw.length);
                    for (let i = 0; i < raw.length; i++) {
                        bytes[i] = raw.charCodeAt(i);
                    }
                    let blob = new Blob([bytes]);

                    saveAs(blob, this.manageTarget + ' - Logs.zip');
                    this.manageType = '';
                    this.manageRunning = false;
                })
                .catch((err) => {
                    this.notificationService.toast.error(
                        'Error',
                        'Log download for ' + this.selectedAgent.Id + ' timed out.'
                    );
                    this.manageType = '';
                    this.manageRunning = false;
                });
        } else if (ddValue == 'Download Audit') {
            this.manageRunning = true;
            this.modalTitle = 'Requesting Audit...';
            this.notificationService.toast.download(
                'Downloading Audit',
                'Requesting audit file from ' + this.selectedAgent.Id
            );
            this.discoveryAgentService
                .getAuditForApplianceById(this.organization.Id, this.manageTarget)
                .then((b64) => {
                    let raw = window.atob(b64);
                    let bytes = new Uint8Array(raw.length);
                    for (let i = 0; i < raw.length; i++) {
                        bytes[i] = raw.charCodeAt(i);
                    }
                    let blob = new Blob([bytes]);

                    saveAs(blob, this.manageTarget + ' - Audit.zip');
                    this.manageType = '';
                    this.manageRunning = false;
                })
                .catch((err) => {
                    this.notificationService.toast.error(
                        'Error',
                        'Audit download for ' + this.selectedAgent.Id + ' timed out.'
                    );
                    this.manageType = '';
                    this.manageRunning = false;
                });
        } else if (ddValue == 'Update Comment') {
            this.modalTitle = 'Update Comment - ' + this.selectedAgent.Id;
        } else if (ddValue == 'Update Label') {
            this.modalTitle = 'Update Label - ' + this.selectedAgent.Id;
        }
    }

    doManage() {
        this.manageAgentModal.hide();
        if (this.manageType == 'Update') {
            this.manageRunning = true;
            this.modalTitle = 'Sending Command to Appliance...';
            this.discoveryAgentService.sendUpdateToDiscoveryAgentById(this.organization.Id, this.manageTarget)
                .then((res) => {
                    this.notificationService.toast.success(
                        'Sent',
                        'A request was sent to update the data collector. The data collector will update itself after 15 minutes and should be available for use after the update is complete.'
                    );
                    this.manageType = '';
                    this.manageRunning = false;
                })
                .catch((err) => {
                    this.notificationService.toast.error(
                        'Error',
                        'There was a problem sending the update request to the data collector.'
                    );
                    this.manageType = '';
                    this.manageRunning = false;
                });
        } else if (this.manageType == 'Set Auto-Update') {
            this.manageRunning = true;
            this.modalTitle = 'Sending Command to Appliance...';
            this.discoveryAgentService
                .sendAutoUpdateToApplianceById(this.organization.Id,this.manageTarget, !this.autoUpdateSetting)
                .then((res) => {
                    this.notificationService.toast.success(
                        'Sent',
                        'The request for the auto-update setting has been sent to the data collector.'
                    );
                    this.manageType = '';
                    this.manageRunning = false;
                })
                .catch((err) => {
                    this.notificationService.toast.error(
                        'Error',
                        'There was a problem sending the auto-update request to the data collector.'
                    );
                    this.manageType = '';
                    this.manageRunning = false;
                });
        } else if (this.manageType == 'Update Comment') {
            this.manageRunning = true;
            if (this.commentCtrl.value == null) this.commentCtrl.setValue('');
            this.modalTitle = 'Sending Command to Appliance...';
            this.siteSettingService
                .setApplianceDescription(this.manageTarget, this.commentCtrl.value)
                .then((res) => {
                    this.notificationService.toast.success(
                        'Sent',
                        'The request for the comment update has been sent to the data collector.'
                    );
                    this.manageType = '';
                    this.manageRunning = false;
                })
                .catch((err) => {
                    this.notificationService.toast.error(
                        'Error',
                        'There was a problem sending the comment update request to the data collector.'
                    );
                    this.manageType = '';
                    this.manageRunning = false;
                });
        } else if (this.manageType == 'Update Label') {
            this.manageRunning = true;
            if (this.labelCtrl.value == null) this.labelCtrl.setValue('');
            this.modalTitle = 'Sending Command to Appliance...';
            this.siteSettingService
                .setApplianceLabel(this.manageTarget, this.selectedAgent.Label, this.labelCtrl.value, this.organization.Id)
                .then((res) => {
                    this.notificationService.toast.success(
                        'Sent',
                        'The request for the label update has been sent to the data collector.'
                    );
                    this.manageType = '';
                    this.manageRunning = false;
                })
                .catch((err) => {
                    this.notificationService.toast.error(
                        'Error',
                        'There was a problem sending the label update request to the data collector.'
                    );
                    this.manageType = '';
                    this.manageRunning = false;
                });
        }

        this.resetSelectedAgent();
        this.getDiscoveryAgents();
    }

    createBulkActions() {
        this.bulkActions = [];
        this.bulkActions = [
            {
                label: this.selectedAgents.length + ' Selected',
                items: [
                    {
                        label: 'Run Scan Now',
                        disabled: !this.selectedAgents.length,
                        command: () => this.runDeepFileScanNow(false),
                    },
                    {
                        label: 'Update Now',
                        disabled: !this.selectedAgents.length,
                        command: () => this.confirmAgentUpdateModal.show(),
                    },
                    {
                        label: 'Cancel Scans',
                        disabled: !this.selectedAgents.length,
                        command: () => this.confirmCancelScansModal.show(),
                    },
                    {
                        label: 'Remove Agents',
                        disabled: !this.selectedAgents.length,
                        command: () => this.showBulkAgentRemovalModal(),
                    },
                    {
                        label: 'Update Comment',
                        disabled: !this.selectedAgents.length,
                        command: () => this.editDiscoveryAgentCommentBulkModal.show(),
                    },
                    {
                        label: 'Update Label',
                        disabled: !this.selectedAgents.length,
                        command: () => this.editDiscoveryAgentLabelBulkModal.show(),
                    },
                    {
                        label: 'Run Deep File Scan Now',
                        disabled: !this.selectedAgents.length || this.sensitiveDataStatus,
                        command: () => this.runDeepFileScanNow(true),
                    },
                ],
            },
        ];
    }
}
