import { Pipe, PipeTransform, inject } from '@angular/core';
import { accountDateTimePipe } from './accountDateTime.pipe';

@Pipe({ name: 'getMaxDate' })
export class getMaxDatePipe implements PipeTransform {
    constructor() {}
    accountDatePipe = inject(accountDateTimePipe);

    transform(scans: any[]) {
        if (scans == undefined || scans.length == 0) return '';
        let d = new Date(
            Math.max.apply(
                null,
                scans.map(function (e) {
                    return new Date(e.lastmodified);
                })
            )
        );

        return this.accountDatePipe.transform(d.toISOString());
    }
}
