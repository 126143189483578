<h4>Computer Info</h4>
<table class="ui table">
    <tbody>
        <tr>
            <td><b>Computer Name</b></td>
            <td>{{ diag?.ComputerDomain }}\{{ diag?.ComputerName }}</td>
        </tr>
        <tr>
            <td><b>IP Address</b></td>
            <td>{{ diag?.IPAddress }}</td>
        </tr>
    </tbody>
</table>

<h4>History</h4>
<p>The following table shows connections to unauthorized wireless networks:</p>
<table class="ui table">
    <thead>
        <tr>
            <th>Timestamp</th>
            <th>SSID</th>
            <th>Security</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let item of fixListDates(diag?.Children)">
            <td>{{ item.Timestamp | date : 'medium' }}</td>
            <td>{{ item.SSID }}</td>
            <td>{{ item.Security }}</td>
        </tr>
        <!--<tr ng-if="diag.Children.length > diagTableLimit" ng-click="increaseLimit()">
            <td colspan="3" style="text-align: center">
                <a href="#"><i class="plus icon"></i>{{diag.Children.length - diagTableLimit}} More...</a>
            </td>
        </tr>-->
    </tbody>
</table>
