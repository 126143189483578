/* 
    Code from: 
    https://netbasal.com/diy-keyboard-shortcuts-in-your-angular-application-4704734547a2
*/
import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { EventManager } from '@angular/platform-browser';
import { Observable } from 'rxjs';

type Options = {
    element: any;
    keys: string;
    preventDefault: boolean;
};

@Injectable()
export class Hotkeys {
    defaults: Partial<Options> = {
        element: this.document,
        preventDefault: false,
    };

    constructor(private eventManager: EventManager, @Inject(DOCUMENT) private document: Document) {}

    addShortcut(options: Partial<Options>) {
        const merged = { ...this.defaults, ...options };
        const event = `keydown.${merged.keys}`;

        return new Observable((observer) => {
            const handler = (e) => {
                if (merged.preventDefault == true) {
                    e.preventDefault();
                }
                observer.next(e);
            };

            const dispose = this.eventManager.addEventListener(merged.element, event, handler);

            return () => {
                dispose();
            };
        });
    }
}
