<sds-breadcrumb-trail [crumbs]="breadcrumbs"></sds-breadcrumb-trail>

<div>
    <header class="pageHeader">
        <h1>Scan Profiles</h1>
    </header>
    <table
        id="sortedTable"
        class="itc-table"
        style="margin-top: 0"
        sortable-table
        sortColumn="scanProfile"
        sortDirection="asc"
        (sorted)="sortProfiles($event)">
        <thead>
            <tr>
                <th sortable-column="scanProfile">Scan Profile</th>
                <th sortable-column="description">Description</th>
                <th sortable-column="notes">Notes</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let scanProfile of scanProfiles">
                <td>{{ scanProfile.scanProfile }}</td>
                <td>{{ scanProfile.description }}</td>
                <td>{{ scanProfile.notes }}</td>
            </tr>
        </tbody>
    </table>
</div>
