import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
    standalone: true,
    imports: [CommonModule],
    selector: 'itc-progress-bar',
    template: `
        <div class="progress-container">
            <div class="progress-bar" [ngClass]="status" [style.width]="progress + '%'"></div>
        </div>
        <div class="progress-error" *ngIf="error">{{ error }}</div>
    `,
    styles: [
        '.progress-container { height: 6px; width: 100%; background-color: #e5e5e5; border-radius: 3px; position: relative; }',
        '.progress-bar { height: 6px; background-color: var(--rft-orange); transition: 0.1s; border-radius: 3px; }',
        '.progress-error { margin-top: 8px; color: #D22F2D; }',
        '.success {background-color: #14c266}',
        '.warning {background-color: #e5b000}',
        '.danger {background-color: #D22F2D}',
    ],
})
export class ItcProgressBarComponent {
    @Input() progress: number;
    @Input() status: string | null;
    @Input() error: string | null;

    constructor() {}

    ngOnChanges() {
        //cap at 100
        this.progress > 100 ? (this.progress = 100) : this.progress;
    }
}
