import { Component, Input, OnInit } from '@angular/core';
import { SiteDashboard } from '../../shared/site-dashboard.model';
import { FormControl } from '@angular/forms';
import { SiteDashboardService } from 'app/sites/shared/site-dashboard.service';

@Component({
    selector: 'sds-cyber-hawk',
    templateUrl: './cyber-hawk.component.html',
    styleUrls: ['./cyber-hawk.component.scss'],
})
export class CyberHawkComponent {
    @Input() set sites(value: any) {
        this.allSites = value;
        this.filteredSites = value;
        this.onSortSites();
    }

    allSites: SiteDashboard[] = [];
    filteredSites: SiteDashboard[] = [];
    sortedSites: SiteDashboard[] = [];
    sortColumn: string = '';
    sortDirection: string = '';
    searchKeyControl = new FormControl<string>('');

    constructor(public siteDashboardService: SiteDashboardService) {}

    resetSearchKey() {
        this.searchKeyControl.setValue('');
    }

    searchSites() {
        if (this.searchKeyControl.value?.trim().toLowerCase()) {
            let key: string = this.searchKeyControl.value.toLowerCase();
            this.filteredSites = this.allSites.filter(
                (site) =>
                    site.Name.toLowerCase().indexOf(key) > -1 ||
                    site.Organization.toLowerCase().indexOf(key) > -1
            );
        } else {
            this.filteredSites = this.allSites;
        }
    }

    onSortSites(ev: any = { sortColumn: 'Name', sortDirection: 'asc' }) {
        this.sortColumn = ev.sortColumn;
        this.sortDirection = ev.sortDirection;
        this.sortSites();
    }

    sortSites() {
        this.sortedSites = this.filteredSites.sort((a, b) => {
            let cola = a[this.sortColumn],
                colb = b[this.sortColumn];

            if (typeof cola === 'string') {
                cola = cola.toLocaleLowerCase();
            }
            if (typeof colb === 'string') {
                colb = colb.toLocaleLowerCase();
            }

            if (this.sortDirection === 'desc') {
                return cola < colb ? 1 : cola === colb ? 0 : -1;
            } else {
                return cola > colb ? 1 : cola === colb ? 0 : -1;
            }
        });

        this.filteredSites = [...this.sortedSites];
    }

    getDynamicStyles(isOnline: boolean) {
        return {
            'background-color': isOnline ? '#9ee7a1' : '#FF9E9E',
            'color': '#001d30',
            'width': '60px',
            'display': 'flex',
            'padding': '2px 10px',
            'font-size': '12px',
            'font-weight': '500px',
            'justify-content': 'center',
            'align-items': 'center',
            'gap': '8px',
        };
    }
}
