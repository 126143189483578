import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidationErrors, AbstractControl } from '@angular/forms';

@Directive({
    selector: '[validDomain]',
    providers: [{ provide: NG_VALIDATORS, useExisting: ValidDomainValidator, multi: true }],
})
export class ValidDomainValidator implements Validator {
    constructor() {}

    validate(control: AbstractControl): ValidationErrors | null {
        return domainValidation(control.value);
    }
}

export function validDomain(control: AbstractControl): { [key: string]: any } | null {
    return domainValidation(control.value);
}

export function domainValidation(val): ValidationErrors | null {
    // crazy regex from https://stackoverflow.com/questions/10306690/domain-name-validation-with-regex
    const domainRegex =
        /^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9\-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})$/i;
    return domainRegex.test(val) ? null : { invalidDomain: true };
}
