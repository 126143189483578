<div class="input-container">
    <sm-input
        name="searchInput"
        debounce="200"
        [control]="searchKeyControl"
        (input)="filterSites()"
        class="searchInput"
        placeholder="Search keywords..."></sm-input>

    <div class="dropDownTypes">
        <p-dropdown
            class="viewOptions"
            name="cmType"
            placeholder="All"
            [(ngModel)]="selectedType"
            (onChange)="filterSites()"
            [options]="['All', 'Compliance Services Pro']"></p-dropdown>
    </div>

    <div class="checkBox">
        <itc-checkbox
            [disabled]="isCheckboxRestricted"
            [(ngModel)]="checkStatus"
            (onchange)="saveAndFilterSites(checkStatus, ONLY_MY_SITES)"
            name="checkboxOneTimeExclusions"></itc-checkbox>
        Only My Sites
    </div>

    <div class="label">
        <strong>{{ filteredSites?.length }} of {{ allSites?.length }}</strong>
        Results
    </div>
</div>

<div class="scanStatus">
    <div>
        <p-dropdown
            class="viewOptions"
            [style]="{
                'border-radius': '20px',
                'text-align': 'center',
                'padding-left': '8px',
                'height': '24px',
                'align-items': 'center',
                'color': 'var(--text-primary)',
                'font-weight': '500',
                'background-color': selectedScanDate ? 'var(--primitive-orange-10)' : null,
                'border-color': selectedScanDate ? 'var(--rft-orange)' : null,
            }"
            [options]="scanStatusOptions"
            [(ngModel)]="selectedScanDate"
            (onChange)="saveAndFilterSites(selectedScanDate, SCAN_STATUS)"
            placeholder="Scan Status">
            <ng-template pTemplate="selectedItem">
                <div *ngIf="selectedScanDate">
                    <span>Scan Status:&nbsp;</span>
                    <span>{{ selectedScanDate }}</span>
                </div>
            </ng-template>
            <ng-template let-technicalIssue pTemplate="item">
                <div class="flex align-items-center gap-2">
                    <span>{{ technicalIssue }}</span>
                </div>
            </ng-template>
        </p-dropdown>
    </div>

    <div>
        <p-dropdown
            class="viewOptions"
            panelStyleClass="disableBottomItem"
            [style]="{
                'border-radius': '20px',
                'text-align': 'center',
                'padding-left': '8px',
                'height': '24px',
                'align-items': 'center',
                'color': 'var(--text-primary)',
                'font-weight': '500',
                'background-color': selectedTechnicalIssue ? 'var(--primitive-orange-10)' : null,
                'border-color': selectedTechnicalIssue ? 'var(--rft-orange)' : null,
            }"
            [options]="technicalIssuesOption"
            [(ngModel)]="selectedTechnicalIssue"
            (onChange)="saveAndFilterSites(selectedTechnicalIssue, TECHNICAL_ISSUE)">
            <ng-template pTemplate="selectedItem">
                <span *ngIf="selectedTechnicalIssue">
                    Technical Issues: {{ selectedTechnicalIssue }}
                </span>
                <span *ngIf="!selectedTechnicalIssue">Technical Issues</span>
            </ng-template>
        </p-dropdown>
    </div>
</div>

<sds-loader [complete]="loadingComplete">
    <div>
        <table
            class="itc-table scrollable sortable top-aligned"
            sortable-table
            sortColumn="Name"
            sortDirection="asc"
            (sorted)="onSortSites($event)">
            <thead>
                <tr>
                    <th sortable-column="Name">Site</th>
                    <th sortable-column="Organization">Organization</th>
                    <th>Selected Standards</th>
                    <th class="lastScan">Last 5 Scans</th>
                    <th class="textAlign">Technical Review</th>
                    <th>Assessment Status</th>
                    <th>Reports</th>
                    <th sortable-column="ApplianceOnline">Appliance</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let site of displaySites" [class.expiredSite]="site.IsLicenseInactive">
                    <td
                        style="max-width: 200px"
                        [tooltip]="site.Tooltip ? site.Tooltip : null"
                        [ttTitle]="site.Tooltip ? 'Subscription Expired' : null"
                        ttPosition="bottom-right">
                        <a
                            class="linkColor breakWord"
                            [routerLink]="null"
                            (click)="siteDashboardService.goToSite(site)">
                            {{ site.Name }}
                        </a>
                        <i
                            *ngIf="site.IsCSPro"
                            style="margin-top: -8px"
                            class="compliance-manager2-cspro site_type"></i>
                    </td>
                    <td
                        class="truncateTextStyle"
                        [tooltip]="site.Tooltip ? site.Tooltip : null"
                        [ttTitle]="site.Tooltip ? 'Subscription Expired' : null"
                        ttPosition="bottom-right">
                        <a
                            class="breakWord link-org"
                            [routerLink]="null"
                            (click)="siteDashboardService.goToOrganization(site)">
                            {{ site.Organization ? site.Organization : "Unassigned"}}
                        </a>
                    </td>
                    <td
                        style="max-width: 200px"
                        [tooltip]="site.Tooltip ? site.Tooltip : null"
                        [ttTitle]="site.Tooltip ? 'Subscription Expired' : null"
                        ttPosition="bottom-right">
                        <div *ngIf="getSelectedStadard(site).length">
                            <span>{{ getSelectedStadard(site)[0] }}</span>
                            <span
                                [tooltip]="site.IsLicenseInactive ? null : getStandardToolTip(site)"
                                ttPosition="bottom"
                                class="standardItemNumber"
                                *ngIf="getSelectedStadard(site).length > 1">
                                +{{ getSelectedStadard(site).length - 1 }}
                            </span>
                        </div>
                    </td>
                    <td
                        *ngIf="!isSiteRestricted"
                        style="display: flex; justify-content: flex-end"
                        [tooltip]="site.Tooltip ? site.Tooltip : null"
                        [ttTitle]="site.Tooltip ? 'Subscription Expired' : null"
                        ttPosition="bottom-right">
                        <scan-status-tooltips
                            *ngIf="site.Scan5Id"
                            status="{{ getStatusScan(site, 5) }}"
                            url="{{ getScanUrl(site, 5) }}"
                            text="{{ getToolTip(site, 5) }}"
                            [status]="getStatusScanClass(site, 5)"
                            title="{{ getTitle(site, 5) }}"></scan-status-tooltips>
                        <scan-status-tooltips
                            *ngIf="site.Scan4Id"
                            status="{{ getStatusScan(site, 4) }}"
                            url="{{ getScanUrl(site, 4) }}"
                            text="{{ getToolTip(site, 4) }}"
                            [status]="getStatusScanClass(site, 4)"
                            title="{{ getTitle(site, 4) }}"></scan-status-tooltips>
                        <scan-status-tooltips
                            *ngIf="site.Scan3Id"
                            status="{{ getStatusScan(site, 3) }}"
                            url="{{ getScanUrl(site, 3) }}"
                            text="{{ getToolTip(site, 3) }}"
                            [status]="getStatusScanClass(site, 3)"
                            title="{{ getTitle(site, 3) }}"></scan-status-tooltips>
                        <scan-status-tooltips
                            *ngIf="site.Scan2Id"
                            status="{{ getStatusScan(site, 2) }}"
                            url="{{ getScanUrl(site, 2) }}"
                            text="{{ getToolTip(site, 2) }}"
                            [status]="getStatusScanClass(site, 2)"
                            title="{{ getTitle(site, 2) }}"></scan-status-tooltips>
                        <scan-status-tooltips
                            *ngIf="site.Scan1Id"
                            status="{{ getStatusScan(site, 1) }}"
                            url="{{ getScanUrl(site, 1) }}"
                            text="{{ getToolTip(site, 1) }}"
                            [status]="getStatusScanClass(site, 1)"
                            title="{{ getTitle(site, 1) }}"></scan-status-tooltips>
                    </td>
                    <td *ngIf="isSiteRestricted"></td>
                    <td
                        *ngIf="!isSiteRestricted"
                        style="padding-left: 50px"
                        [tooltip]="site.Tooltip ? site.Tooltip : null"
                        [ttTitle]="site.Tooltip ? 'Subscription Expired' : null"
                        ttPosition="bottom-right">
                        <div class="techStatusItem">
                            <i
                                style="margin-left: auto"
                                class="{{ getTechnicalReviewStatusIcon(site) }}"></i>
                            <a
                                class="linkColor"
                                [routerLink]="null"
                                (click)="siteDashboardService.goToTechnicalReview(site)">
                                {{ site.TechnicalReviewWorksheetCompleteCount }}/{{
                                    site.TechnicalReviewWorksheetTotalCount
                                }}
                            </a>
                        </div>
                    </td>
                    <td *ngIf="isSiteRestricted"></td>
                    <td
                        *ngIf="!isSiteRestricted"
                        [tooltip]="site.Tooltip ? site.Tooltip : null"
                        [ttTitle]="site.Tooltip ? 'Subscription Expired' : null"
                        ttPosition="bottom">
                        <div class="assStatusItem">
                            <span class="labelAssessment">Rapid Baseline</span>
                            <i class="{{ getRapidBaseLineStatusIcon(site) }}"></i>
                            <a
                                class="linkColor"
                                [routerLink]="null"
                                (click)="siteDashboardService.goToRapidBaseline(site)">
                                {{ site.RapidBaselineCompleteCount }}/{{
                                    site.RapidBaselineTotalCount
                                }}
                            </a>
                        </div>

                        <div class="assStatusItem">
                            <span class="labelAssessment">Controls</span>
                            <i class="{{ getControlsStatusIcon(site) }}"></i>
                            <a
                                class="linkColor"
                                [routerLink]="null"
                                (click)="siteDashboardService.goToControlsAssessment(site)">
                                {{ site.ControlAssessmentCompleteCount }}/{{
                                    site.ControlAssessmentTotalCount
                                }}
                            </a>
                        </div>
                        <div class="assStatusItem">
                            <span class="labelAssessment">Requirements</span>
                            <i style="margin-left: 0px" class="{{ getRequirementsIcon(site) }}"></i>
                            <a
                                class="linkColor"
                                [routerLink]="null"
                                (click)="siteDashboardService.goToRequirementsAssessment(site)">
                                {{ site.RequirementAssessmentCompleteCount }}/{{
                                    site.RequirementAssessmentTotalCount
                                }}
                            </a>
                        </div>
                    </td>
                    <td *ngIf="isSiteRestricted"></td>
                    <td
                        *ngIf="!isSiteRestricted"
                        [tooltip]="site.Tooltip ? site.Tooltip : null"
                        [ttTitle]="site.Tooltip ? 'Subscription Expired' : null"
                        ttPosition="bottom-right">
                        <div class="reportColumn">
                            <div>
                                Technical Review:
                                <a
                                    [ngClass]="{
                                        linkColor: site.TechnicalReviewReportLastGenerated,
                                        noLinkColor: !site.TechnicalReviewReportLastGenerated,
                                    }"
                                    [routerLink]="null"
                                    (click)="siteDashboardService.goToReports(site)">
                                    {{
                                        site.TechnicalReviewReportLastGenerated
                                            ? (site.TechnicalReviewReportLastGenerated
                                              | accountDate)
                                            : 'N/A'
                                    }}
                                </a>
                            </div>

                            <div>
                                Compliance:

                                <a
                                    [ngClass]="{
                                        linkColor: site.ComplianceReportLastGenerated,
                                        noLinkColor: !site.ComplianceReportLastGenerated,
                                    }"
                                    [routerLink]="null"
                                    (click)="siteDashboardService.goToReports(site)">
                                    {{
                                        site.ComplianceReportLastGenerated
                                            ? (site.ComplianceReportLastGenerated | accountDate)
                                            : 'N/A'
                                    }}
                                </a>
                            </div>
                        </div>
                    </td>
                    <td *ngIf="isSiteRestricted"></td>
                    <td
                        *ngIf="!isSiteRestricted"
                        [tooltip]="site.Tooltip ? site.Tooltip : null"
                        [ttTitle]="site.Tooltip ? 'Subscription Expired' : null"
                        ttPosition="bottom-right">
                        <span
                            style="cursor: pointer"
                            (click)="siteDashboardService.goToSite(site)"
                            class="ui label circular circleCount"
                            [ngStyle]="getDynamicStyles(site)">
                            {{ getApplianceText(site) }}
                        </span>
                    </td>
                    <td *ngIf="isSiteRestricted"></td>
                </tr>
            </tbody>
        </table>

        <empty-state
            icon="results"
            title="No Sites Found"
            text="No sites were found matching the given criteria."
            *ngIf="!displaySites?.length"></empty-state>
    </div>
</sds-loader>
