import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { Organization } from '../organization.model';
import { ActivatedRoute, Params } from '@angular/router';
import { FormControl } from '@angular/forms';
import { ALL_SITES, NavService } from '../../core/nav/nav.service';
import { UiService } from '../../core/ui.service';
import { OrganizationsService } from '../organizations.service';
import { Subscription, Observable, Subject, map, takeUntil, BehaviorSubject, of } from 'rxjs';
import { AuthService, Roles } from 'app/core/auth';
import { SiteService } from 'app/sites';

@Component({
    templateUrl: './organization-sidebar.component.html',
    styleUrls: ['./organization-sidebar.component.scss'],
})
export class OrganizationSidebarComponent implements OnInit, OnDestroy {
    @ViewChild('collapseButton', { static: true }) collapseButton: ElementRef;

    loadingComplete: boolean = true;
    selectedOrgName$: BehaviorSubject<string>;
    filteringOrgs = false;
    searchKey = new FormControl<string>('');
    unfilteredOrganizations: Organization[];
    all_sites = ALL_SITES; // doing this to use it in the template
    isSidebarCollapsed = false;
    routeParams: Subscription;
    headerText$: Observable<string> = new Observable();
    organizations$: Observable<Organization[]>;
    filteredOrganizations$: Observable<Organization[]>;
    ngUnsubscribe$: Subject<any> = new Subject();
    isSuperUser = false;
    hasAgentAccess: any = {};

    constructor(
        private route: ActivatedRoute,
        private uiService: UiService,
        private organizationsService: OrganizationsService,
        private navService: NavService,
        private authService: AuthService,
        private siteService: SiteService
    ) {}

    ngOnInit() {
        this.isSuperUser =
            this.authService.userIsRole(Roles.Admin) || this.authService.userIsRole(Roles.Master);

        this.organizationsService.getAllOrganizations();
        this.selectedOrgName$ = this.organizationsService.selectedOrgName$;
        // takeUntil doesn't seem to work with subscribing to params
        this.routeParams = this.route.firstChild.params.subscribe((params: Params) => {
            if (params.organization) {
                this.organizationsService.setSelectedOrganization(params.organization);
            } else {
                this.organizationsService.setSelectedOrganization('all-orgs');
            }
        });
        this.organizationsService
            .getOrganizationsObs()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((orgs) => {
                if (orgs) {
                    orgs.forEach((o) => {
                        o.HasCSPro = o.Sites.some((s) => s.IsCSPro);
                        o.orgLevelDiscoveryAgents = o.Sites.some(
                            (s) =>
                                this.siteService.isComplianceManagerGRC(s) ||
                                this.siteService.isKVS(s) ||
                                this.siteService.isNDPro(s) ||
                                this.siteService.isIndoc(s)
                        );
                        if (this.isSuperUser) {
                            //try to short-circuit
                            this.hasAgentAccess[o.Name] = true;
                        } else {
                            this.organizationsService
                                .hasOrgDiscoveryAgentAccess(o.Name)
                                .then((res) => {
                                    this.hasAgentAccess[o.Name] = res;
                                    console.log(this.hasAgentAccess);
                                });
                        }
                    });
                    this.organizations$ = of(orgs);
                    this.filteredOrganizations$ = this.organizations$;
                }
            });

        this.headerText$ = this.navService.getHeaderTextObservable();
    }

    ngAfterViewInit() {
        let collapsed = this.uiService.getControlValue('SIDEBAR_COLLAPSED');
        this.isSidebarCollapsed = collapsed == 'true';
    }

    ngOnDestroy() {
        this.routeParams.unsubscribe();
        this.ngUnsubscribe$.next(void 0);
        this.ngUnsubscribe$.complete();
        this.organizationsService.resetObs();
    }

    /**
     * Run to set parentItem as active when a childItem is
     *
     * @param isActive
     */
    onRouterLinkActive(isActive) {
        let activeItem = document.querySelector('.activeMenuItem') as HTMLElement;
        let activeParent = activeItem?.parentNode.parentNode.previousSibling as HTMLElement;
        document.querySelector('.activeMenuItemParent')?.classList.remove('activeMenuItemParent');
        if (isActive) {
            activeParent?.classList.add('activeMenuItemParent');
        } else {
            activeParent?.classList.remove('activeMenuItemParent');
        }
    }

    filterOrganizations() {
        this.filteredOrganizations$ = this.organizations$?.pipe(
            map((org) => {
                return org
                    .filter(
                        (o) => o.Name.toLowerCase().indexOf(this.searchKey.value.toLowerCase()) > -1
                    )
                    .sort((a, b) =>
                        a.Name.toLowerCase().localeCompare(b.Name.toLowerCase(), undefined, {
                            numeric: true,
                            sensitivity: 'base',
                            ignorePunctuation: true,
                        })
                    );
            })
        );
    }

    toggleSidebar() {
        this.isSidebarCollapsed = !this.isSidebarCollapsed;
        this.uiService.setControlValue('SIDEBAR_COLLAPSED', this.isSidebarCollapsed);
        this.collapseButton.nativeElement.classList.add('forceShow');
        setTimeout(() => {
            this.collapseButton.nativeElement.classList.remove('forceShow');
        }, 750);
    }
}
