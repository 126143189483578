import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, Directive } from '@angular/core';

@Component({
    selector: 'itc-card',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './itc-card.component.html',
    styleUrls: ['./itc-card.component.scss'],
})
export class ItcCardComponent {
    @Input() class: string;
    @Input() hasTopBorder = false;
    @Input() hasShadow = true;
    @Output() onCardClicked = new EventEmitter();

    emitClick(evt): void {
        this.onCardClicked.emit(evt);
    }
}

@Directive({ standalone: true, selector: 'card-title, card-content, card-actions' })
export class ItcCardTagsDirective {
    // No behavior
    // The only purpose is to "declare" the tag in Angular
}
