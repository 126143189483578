import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'itc-spinner',
    template: `
        <i
            class="kaseya-ui-spinner {{ className }}"
            [ngStyle]="{
                'width': size + 'px',
                'height': size + 'px',
                '--spinner-color': color || null
            }"></i>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItcSpinnerComponent {
    /* generally don't pass in a number here, use one of the two sizes supplied small/large */
    @Input() set size(val: 'small' | 'large' | number) {
        if (val) {
            if (typeof val === 'number') {
                this._size = val;
                return;
            }
            if (val === 'small' || val === 'large') {
                this._size = val === 'small' ? 16 : 32;
                return;
            }
        }
    }
    get size() {
        return this._size;
    }
    /* Default color is in style.scss, you probably shouldn't use this */
    @Input() color: string;
    @Input() className = '';
    _size = 16;
}
