import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    forwardRef,
    inject,
} from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    ReactiveFormsModule,
    FormsModule,
    FormControl,
    Validators,
} from '@angular/forms';
import { FileUploaderSummary } from 'app/shared/itc/file-uploader/file-uploader.model';
import { ItcButtonComponent } from 'app/shared/itc/button/button.component';
import { ItcCheckboxComponent, ItcFileUploaderComponent } from 'app/shared/itc';
import { CommonModule } from '@angular/common';
import { notBlank } from 'app/shared/not-blank.validator';
import { uniq as _uniq, uniqBy as _uniqBy, remove as _remove } from 'lodash-es';
import { NotificationService } from 'app/shared/itc/notification/notification.service';
import { SNMPEntriesService } from './snmp-entries.service';
export interface Entry {
    isChecked: boolean;
    entry: string;
}
@Component({
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        ItcCheckboxComponent,
        ItcButtonComponent,
        ItcFileUploaderComponent,
    ],
    selector: 'rft-snmp-entries',
    template: `
        <form class="itc form">
            <div class="input-container">
                <label for="entryInput">
                    Community String
                    <span style="color: var(--text-critical)">*</span>
                </label>
                <div class="input-container__input">
                    <div class="required field">
                        <input
                            type="text"
                            id="entryInput"
                            [formControl]="entriesCtrl"
                            (keypress)="checkKeys($event)"
                            #ipControl />
                        <span
                            class="error-message"
                            [hidden]="!entriesCtrl.value || entriesCtrl.valid">
                            {{ getErrorMessage() }}
                        </span>
                    </div>
                    <div>
                        <itc-button
                            type="primary"
                            [disabled]="entriesCtrl.invalid"
                            (onclick)="addEntry()"
                            class="addButton">
                            Add
                        </itc-button>
                        <itc-file-uploader
                            type="button"
                            accept=".txt"
                            allowedFileTypes="TXT"
                            returnType="BinaryString"
                            [allowToasts]="false"
                            buttonText="Import"
                            buttonType="secondary"
                            buttonIcon=""
                            [disabled]="importing"
                            (uploadDone)="importEntries($event)"></itc-file-uploader>
                    </div>
                </div>
                <div class="select-container">
                    <div class="entryContainer">
                        <div
                            *ngFor="let entry of entriesObj; let i = index"
                            [class.selected]="entry.isChecked"
                            (click)="updateCheckboxes(entry)">
                            {{ entry.entry }}
                        </div>
                    </div>
                    <div class="select-container__buttons">
                        <itc-button
                            label="Remove Selected"
                            type="secondary"
                            size="small"
                            [disabled]="!selectedEntries"
                            (onclick)="removeEntry(false)"></itc-button>
                        <itc-button
                            *ngIf="defaultValue"
                            label="Reset to Auto-Detected"
                            type="secondary"
                            size="small"
                            (onclick)="resetEntry()"></itc-button>
                        <itc-button
                            label="Clear All"
                            type="secondary"
                            size="small"
                            [disabled]="!entries.length"
                            (onclick)="removeEntry(true)"></itc-button>
                        <div class="selectedEntries">
                            Count:
                            <strong>{{ entriesCount }}</strong>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    `,
    styles: [
        `
            form {
                display: flex;
                margin-top: 8px;
                margin-bottom: 0px;
            }
            .input-container {
                width: 100%;
            }
            .input-container__input {
                display: flex;
                gap: 16px;
                justify-content: space-between;
                & > div:nth-of-type(1) {
                    flex: 2;
                }
                .addButton {
                    margin-right: 12px;
                }
            }
            .entryContainer {
                border: 1px;
                border-style: solid;
                border-color: #cccccc;
                border-radius: 4px;
                min-height: 200px;
                overflow-y: auto;
                padding-top: 7px;
                height: 226px;
                &:hover {
                    border-color: #999;
                }
                div {
                    padding: 4px 8px;
                    line-height: 20px;
                    cursor: pointer;
                    &.selected {
                        background-color: rgba(var(--rft-orange-rgb), 0.2);
                    }
                }
            }
            .select-container {
                .select-container__buttons {
                    display: flex;
                    align-items: center;
                    padding-top: 8px;
                }
                itc-file-uploader {
                    padding-left: 16px;
                }
            }
            .selectedEntries {
                flex: 1;
                text-align: right;
                color: var(--text-secondary);
            }
            .error-message {
                display: block;
                margin-top: -10px;
                color: var(--text-critical);
                font-weight: 400;
                line-height: 20px;
                font-size: 14px;
                margin-bottom: 16px;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => SNMPEntriesComponent),
        },
    ],
})
export class SNMPEntriesComponent implements ControlValueAccessor {
    notificationService = inject(NotificationService);
    entriesService = inject(SNMPEntriesService);
    entries: string[] = []; // this will be the reference to the control, do not overwrite it, splice and push
    entriesObj: Entry[] = []; // this is used to handle the checkboxes
    selectedEntries: number = 0; //
    entriesCount = 0;
    importing = false;
    entriesCtrl = new FormControl('', [notBlank, Validators.maxLength(32)]);
    @Input() defaultValue: string[];
    @Output() entriesChange = new EventEmitter<number | void>();
    /* Logic/code to be able to use ngmodel or formcontrol in this component*/
    set value(value: string[]) {
        if (value !== this.entries) {
            this.entries = value;
            this.entriesObj = value.map((ip) => {
                return {
                    isChecked: false,
                    entry: String(ip),
                };
            });
            this.onChange(value);
            this.onTouch();
        }
    }
    get value(): string[] {
        return this.entries;
    }
    onChange = (value: any) => {};
    onTouch = () => {};
    onBlur() {
        this.onTouch();
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }
    writeValue(value: any): void {
        if (value && value !== this.entries) {
            this.entries = value;
            this.entriesObj = value.map((ip) => {
                return {
                    isChecked: false,
                    entry: String(ip),
                };
            });
            this.countEntries();
        }
    }
    getErrorMessage(): string {
        if (this.entriesCtrl.hasError('required')) {
            return 'SNMP community strings can not be empty.';
        }
        if (this.entriesCtrl.hasError('maxlength')) {
            return 'SNMP community strings must be 32 characters or less.';
        }
        return null;
    }
    addEntry(): void {
        let newEntry = this.entriesCtrl.value;
        if (this.entriesCtrl.valid && !this.entries.includes(newEntry)) {
            this.entries.push(newEntry);
            // if (this.ipRanges.findIndex((ip) => ip.ip === newIP) === -1) {
            this.entriesObj.push({
                isChecked: false,
                entry: newEntry,
            });
            this.entriesCtrl.setValue('');
        }
        this.entriesCtrl.markAsPristine();
        this.entriesService.sortEntries(this.entries);
        this.entriesService.sortEntries(this.entriesObj);
        this.countEntries();
    }
    importEntries(file: FileUploaderSummary): void {
        let result = file.file['result'];
        this.importing = true;
        this.importEntriesTxt(result);
    }
    importEntriesTxt(fileContent: string): void {
        let importEntries = fileContent.replace(/\r?\n/g, ',').split(',');
        let newEntriesObj: Entry[] = [];
        let newEntries: string[] = [];
        let prevCount = this.entries.length;
        importEntries.forEach((entry) => {
            newEntries.push(entry);
            newEntriesObj.push({
                isChecked: false,
                entry: entry,
            });
        });
        let tempentriesObj = _uniqBy([...this.entriesObj, ...newEntriesObj], 'entry') as Entry[];
        let tempentries = _uniq([...this.entries, ...newEntries]);
        // clear ips and update to keep the reference
        this.entries.splice(0, this.entries.length);
        this.entries.push(...tempentries);
        this.entriesObj = tempentriesObj;
        this.entriesService.sortEntries(this.entries);
        this.entriesService.sortEntries(this.entriesObj);
        this.countEntries();
        this.notificationService.toast.success(
            'Successful Import',
            `${this.entriesCount - prevCount} entries were new and have been imported.`
        );
        this.importing = false;
    }
    resetEntries(): void {
        this.entries.splice(0, this.entries.length);
        this.entriesObj = [];
        this.defaultValue.forEach((r) => {
            let addEntry: Entry = {
                isChecked: false,
                entry: r,
            };
            this.entriesObj.push(addEntry);
            this.entries.push(r);
        });
        this.countEntries();
    }
    removeEntry(removeAll: boolean): void {
        if (removeAll) {
            this.entries.splice(0, this.entries.length);
            this.entriesObj = [];
        } else {
            let removedObj = _remove(this.entriesObj, (entry) => entry.isChecked);
            removedObj.forEach((entry) => {
                this.entries.splice(this.entries.indexOf(entry.entry), 1);
            });
        }
        this.updateSelectedEntries();
    }
    updateCheckboxes(entry: Entry): void {
        entry.isChecked = !entry.isChecked;
        this.updateSelectedEntries();
    }
    updateSelectedEntries(): void {
        this.selectedEntries = this.entriesObj.filter((entry) => entry.isChecked).length;
        this.countEntries();
    }
    countEntries(): void {
        this.entriesCount = this.entries.length;
        this.entriesService.setValid(this.entriesCount > 0);
        this.entriesChange.emit(this.entriesCount);
    }
    checkKeys(evt: KeyboardEvent): boolean {
        // submit on enter
        if (evt.key === 'Enter') {
            this.addEntry();
            evt.preventDefault();
            return true;
        }
    }
}
