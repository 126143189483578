import * as VIEWS from '../diag/views';

export const TACs = {
    Change: {
        icon: 'info circle',
        message: 'info',
    },
    Threat: {
        icon: 'warning circle',
        message: 'error',
    },
    Anomaly: {
        icon: 'warning sign',
        message: 'warning',
    },
    TASK: {
        icon: 'checkmark box',
        message: '',
    },
};

const V_NEW: string = 'New';
const V_TODO: string = 'To Do';
const V_END_USER: string = 'Pending End-User';
const V_COMPLETED: string = 'Completed';
const V_TICKET: string = 'Ticket';
const V_TASK: string = 'NewGdprTask';
const V_TASK_PENDING: string = 'PendingGdprTask';
const V_TASK_COMPLETE: string = 'CompleteGdprTask';
const V_TASK_NOACTION: string = 'NewNoActionTask';

//const V_CANCELED: string = 'Canceled';

export const ALERT_STATUSES = {
    NEW: {
        value: V_NEW,
        label: 'New',
        color: 'red',
        message:
            'This alert has not been processed. You may convert it into a To Do item, or send it straight to your ticketing system.',
    },
    TODO: {
        value: V_TODO,
        label: 'To Do',
        color: 'orange',
        message: 'You may take action(s) to complete this To Do item.',
    },
    END_USER: {
        value: V_END_USER,
        label: 'End User',
        color: 'purple',
        message: 'This alert is pending End User action.',
    },
    COMPLETED: {
        value: V_COMPLETED,
        label: 'Complete',
        color: 'green',
        message:
            'This alert has been processed completely. You may return it to the To Do list if you need to.',
    },
    TICKET: {
        value: V_TICKET,
        label: 'Ticket',
        color: 'blue',
        message:
            'This alert was sent to the ticketing system. You may also create a To Do item from it.',
    },
    TASK: {
        value: V_TASK,
        label: 'Task',
        color: 'blue',
        message: 'This is a task that requires an action to be taken. See below for details.',
    },
    TASK_PENDING: {
        value: V_TASK_PENDING,
        label: 'Task',
        color: 'blue',
        message: 'This is a task that requires an action to be taken. See below for details.',
    },
    TASK_COMPLETE: {
        value: V_TASK_COMPLETE,
        label: 'Complete',
        color: 'green',
        message: 'This issue/task has been marked complete.',
    },
    TASK_NOACTION: {
        value: V_TASK_NOACTION,
        label: 'Task',
        color: 'blue',
        message:
            'This is a task that requires no action to be taken, and will automatically complete on its own.',
    },
    //CANCELED: { value: V_CANCELED, label: 'Canceled', color: 'grey', message: 'This alert was canceled.' }
};

export function AlertStatusLookup(key: string): any {
    switch (key) {
        case V_NEW:
            return ALERT_STATUSES.NEW;
        case V_TODO:
            return ALERT_STATUSES.TODO;
        case V_END_USER:
            return ALERT_STATUSES.END_USER;
        case V_COMPLETED:
            return ALERT_STATUSES.COMPLETED;
        case V_TICKET:
            return ALERT_STATUSES.TICKET;
        case V_TASK:
            return ALERT_STATUSES.TASK;
        case V_TASK_PENDING:
            return ALERT_STATUSES.TASK_PENDING;
        case V_TASK_COMPLETE:
            return ALERT_STATUSES.TASK_COMPLETE;
        case V_TASK_NOACTION:
            return ALERT_STATUSES.TASK_NOACTION;
        //case V_CANCELED:
        //return ALERT_STATUSES.CANCELED
    }
}

const V_INVESTIGATE: string = 'investigate';
const V_IGNORE: string = 'ignore';
const V_IGNORE_ALWAYS: string = 'ignore_always';
const V_IGNORE_FALSEP: string = 'ignore_falsep';
const V_IGNORE_ONCE: string = 'ignore_once';
const V_TASK_ACTION: string = 'task';

export const ALERT_ACTIONS = {
    INVESTIGATE: {
        value: V_INVESTIGATE,
        label: 'Investigate',
        color: 'orange',
        message: 'Please investigate and remediate if required.',
    },
    IGNORE: {
        value: V_IGNORE,
        label: 'Ignore',
        color: 'grey',
        message: 'Please ignore – I am aware of it.',
    },
    IGNORE_ALWAYS: {
        value: V_IGNORE_ALWAYS,
        label: 'Ignore Always',
        color: 'grey',
        message: 'I do not wish to receive this notification anymore.',
    },
    IGNORE_FALSEP: {
        value: V_IGNORE_FALSEP,
        type: 'Ignore',
        label: 'False Positive',
        color: 'grey',
        message: 'False Positive. Please tag appropriately.',
    },
    IGNORE_ONCE: {
        value: V_IGNORE_ONCE,
        type: 'Ignore',
        label: 'Ignore Once',
        color: 'grey',
        message: "Just ignore this one time. I'm aware of it.",
    },
    TASK: {
        value: V_TASK_ACTION,
        label: 'Task',
        color: 'blue',
        message: 'This is a task that requires an action to be taken. See below for details.',
    },
};

export function AlertActionLookup(key: string): any {
    switch (key) {
        case V_INVESTIGATE:
            return ALERT_ACTIONS.INVESTIGATE;
        case V_IGNORE:
            return ALERT_ACTIONS.IGNORE;
        case V_IGNORE_ALWAYS:
            return ALERT_ACTIONS.IGNORE_ALWAYS;
        case V_IGNORE_FALSEP:
            return ALERT_ACTIONS.IGNORE_FALSEP;
        case V_IGNORE_ONCE:
            return ALERT_ACTIONS.IGNORE_ONCE;
        case V_TASK_ACTION:
            return ALERT_ACTIONS.TASK;
    }
}

export function AlertActionLookup2(key: string): any {
    switch (key) {
        case V_INVESTIGATE:
            return ALERT_ACTIONS.INVESTIGATE;
        case V_IGNORE:
        case V_IGNORE_ALWAYS:
        case V_IGNORE_FALSEP:
        case V_IGNORE_ONCE:
            return ALERT_ACTIONS.IGNORE;
        case V_TASK_ACTION:
            return ALERT_ACTIONS.TASK;
    }
}

export const ALERT_OBJECT_TYPES = {
    AdministrativeRightsGranted: { p: 'domain', s: 'user' },
    AutoScreenLockDisabledOnSensitiveComputer: { p: 'domain', s: 'computer' },
    AutoScreenLockDisabledForSensitiveUser: { p: 'domain', s: 'user' },
    ApplicationInstalledOnLockedDownSystem: { p: 'computer', s: 'app' },
    CriticalPatchesNotAppliedTimely: { p: 'computer' },
    CriticalPatchesNotAppliedTimelyOnDMZComputer: { p: 'computer' },
    DefaultGPOModified: { p: 'gpo', s: 'setting' },
    DnsChangeOnLockedDownNetwork: { p: 'iprange', s: 'hostname' },
    EventLogCleared: { p: 'user', s: 'info' },
    GPOModified: { p: 'gpo', s: 'setting' },
    ABDSScan: { p: 'computer', s: 'malwaretype' },
    InternetRestrictionNotEnforced: { p: 'computer' },
    LocalAdminUserAddedBriefing: { p: 'computer', s: 'user' },
    NewDeviceOnRestrictedNetwork: { p: 'iprange', s: 'computer' },
    NewInternalVulnHighBriefing: { p: 'oid', s: 'computer' },
    NewInternalVulnMediumBriefing: { p: 'oid', s: 'computer' },
    NewProfile: { p: 'computer', s: 'user' },
    NewProfileOnBusinessOwnerComputer: { p: 'computer', s: 'user' },
    NewUser: { p: 'domain', s: 'user' },
    PotentialCardholderDataOnNonCDESystem: { p: 'computer', s: 'file' },
    PotentialEPHIOnUnauthorizedSystem: { p: 'computer', s: 'file' },
    PotentialPIIOnNonSensitiveComputer: { p: 'computer', s: 'file' },
    RemovableDriveAddedToLockedDownSystem: { p: 'computer', s: 'disk' },
    SuspiciousUserLogonsBySingleDesktopUser: { p: 'user', s: 'computer' },
    UnauthorizedAccessToAccountingComputer: { p: 'computer', s: 'user' },
    UnauthorizedAccessToCDE: { p: 'computer', s: 'user' },
    UnauthorizedAccessToEPHI: { p: 'computer', s: 'user' },
    UnauthorizedAccessToITRestrictedComputer: { p: 'computer', s: 'user' },
    UnauthorizedPrinterOnNetwork: { p: 'printer' },
    UnauthorizedWirelessConnection: { p: 'ssid', s: 'computer' },
    UnitrendsBackupAllHyperVServers: { p: 'computer' },
    UnitrendsBackupAllVMwareServers: { p: 'computer' },
    UnitrendsBackupAllWindowsServers: { p: 'computer' },
    UnitrendsBackupFailed: { p: 'computer', s: 'datetime' },
    UnitrendsRestoreTestFailed: { p: 'computer', s: 'datetime' },
    UnusualLogonTimeByUserBriefing: { p: 'user', s: 'computer' },
    UnusualLogonToComputerByUserBriefing: { p: 'computer', s: 'user' },
    UserRemovedFromActiveDirectory: { p: 'domain', s: 'user' },
};

export const TAG_OBJECT_TYPES = [
    'ssid',
    'iprange',
    'computer',
    'user',
    'printer',
    'networkprinter',
];

export const OBJECT_TYPE_INFO = {
    app: { name: 'Application', icon: 'browser' },
    computer: { name: 'Computer', icon: 'computer' },
    datetime: { name: 'Datetime', icon: 'calendar' },
    disk: { name: 'Drive', icon: 'disk outline' },
    domain: { name: 'Domain', icon: 'sitemap' },
    file: { name: 'File', icon: 'file outline' },
    gpo: { name: 'GPO', icon: 'file alternate outline' },
    hostname: { name: 'Host', icon: 'computer' },
    info: { name: '', icon: 'info circle' },
    iprange: { name: 'IP Range', icon: 'sitemap' },
    malwaretype: { name: 'Malware Type', icon: 'exclamation triangle' },
    networkprinter: { name: 'Network Printer', icon: 'print' },
    oid: { name: 'OID', icon: 'warning sign' },
    printer: { name: 'Printer', icon: 'print' },
    setting: { name: 'Setting', icon: 'setting' },
    ssid: { name: 'SSID', icon: 'wifi' },
    user: { name: 'User', icon: 'user' },
};

export const ALERT_DIAGS = {
    ABDSScan: VIEWS.ABDSScanComponent,
    ApplicationInstalledOnLockedDownSystem: VIEWS.ApplicationInstalledLockedDownComponent,
    CriticalPatchesNotAppliedTimely: VIEWS.CriticalPatchesNotAppliedTimelyComponent,
    EventLogCleared: VIEWS.EventLogClearedComponent,
    NewInternalVulnHighBriefing: VIEWS.NewInternalVulnComponent,
    NewInternalVulnMediumBriefing: VIEWS.NewInternalVulnComponent,
    NewProfile: VIEWS.NewProfileComponent,
    NewProfileOnBusinessOwnerComputer: VIEWS.NewProfileComponent,
    UnauthorizedWirelessConnection: VIEWS.UnauthorizedWirelessConnectionComponent,
    CriticalPatchesNotAppliedTimelyOnDMZComputer: VIEWS.CriticalPatchesNotAppliedTimelyComponent,
};
