import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/core/auth';

@Component({
    selector: 'kvs-global-false-positive-settings',
    templateUrl: './kvs-false-positive-settings.component.html',
    styleUrls: ['./kvs-false-positive-settings.component.css'],
})
export class GlobalFalsePositiveSettingsComponent implements OnInit {
    kvsNotProvisioned: boolean = false;

    loadingComplete: boolean;
    breadcrumbs = [
        { path: '../..', text: 'Admin' },
        { path: '..', text: 'VulScan' },
        { path: '', text: 'Exclusion Rules' },
    ];
    constructor(private authService: AuthService) {}

    ngOnInit() {
        this.loadingComplete = false;

        let products = this.authService.getProducts();
        if (!products.KVS) {
            this.loadingComplete = true;
            this.kvsNotProvisioned = true;
        }

        this.loadingComplete = true;
    }
}
