import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Organization } from 'app/organizations/organization.model';
import { OrganizationsService } from 'app/organizations/organizations.service';
import { SiteService } from '../site.service';
import { Site } from '../site.model';
import { ALL_SITES } from '../../../core/nav/nav.service';
import { SiteDashboardService } from 'app/sites/shared/site-dashboard.service';

@Component({
    selector: 'sds-move-site-modal',
    templateUrl: './move-site.component.html',
    styleUrls: ['./move-site.component.scss'],
})
export class MoveSiteComponent implements OnInit {
    constructor(
        private siteService: SiteService,
        private organizationsService: OrganizationsService,
        private siteDashboardService: SiteDashboardService
    ) {}

    @ViewChild('modal', { static: true }) modal: any;
    @Input() showOneSite: string;
    @Output() onMove: EventEmitter<string> = new EventEmitter<string>();
    @Input() organizations: Organization[];
    @Input() set selectedOrg(val: string) {
        // pass in selected org to default to it
        if (val && val !== ALL_SITES) {
            // we set the source Organization name as long as it's not All.
            // and save selected org to use
            this.sourceOrganizationName = val;
            this._selectedOrg = val;
        } else {
            // if val is not set or is all we just default to no default folder
            this._selectedOrg = '';
            this.sourceOrganizationName = undefined;
        }
    }
    get selectedOrg(): string {
        return this._selectedOrg;
    }
    _selectedOrg: string;

    sourceOrganizationName: string;
    prevSourceOrganizationName: string; // used to prevent sm-select from firing onchange when you clcik the back button
    destinationOrganizationName: string;
    sourceOrganizationSites: Site[];
    selectedSites: boolean[];
    selectedSiteValues: Site[];
    filteredOrganizations: Organization[];
    nameOrganization: string[] = [];
    nameFilteredOrganization: string[] = [];
    movingSite: boolean = false;
    step: number = 1;
    nextLoading: boolean = false;
    canProceed: boolean = false;
    hasViolation: boolean;
    loadingSitesComplete = true;

    ngOnInit() {
        this.initModal();

        this.canProceed = true;
    }

    initModal() {
        this.sourceOrganizationSites = [];
        this.selectedSites = [];
        this.prevSourceOrganizationName = undefined;
        this.sourceOrganizationName = this.selectedOrg;
        this.destinationOrganizationName = undefined;
    }

    showModal() {
        this.initModal();
        this.changeStep(1);
        if (this.organizations) {
            // remove orgs with no sites since there's nothing to move from them
            this.filteredOrganizations = this.organizations.filter((o) => o.Sites.length > 0);
        }

        // if we're already in an org when we click move site, set it as the source by default, assuming they want to move things from there.
        if (this.selectedOrg) {
            this.filteredOrganizations.forEach((org) => {
                if (org.Name === this.selectedOrg) {
                    this.setSourceOrganizationId(this.selectedOrg);
                }
            });
        }

        if (this.selectedOrg || this.organizations) {
            this.nameFilteredOrganization = this.filteredOrganizations.map((org) => org.Name);
            this.nameOrganization = this.organizations.map((org) => org.Name);
            this.nameFilteredOrganization.unshift('Unassigned Sites');
        }

        this.modal.show({ closable: false });
        setTimeout(() => {
            this.modal.refresh();
        }, 10);
    }

    changeStep(step: number) {
        this.step = step;
        this.modal.refresh();
    }

    advance() {
        if (!this.canProceed) return;

        if (this.nextLoading) return;

        if (this.step == -1) {
            this.modal.hide();
            this.initModal();
        }

        if (this.step == 2) {
            if (this.destinationOrganizationName === 'Unassigned Sites')
                this.destinationOrganizationName = '__unassigned';
            this.movingSite = true;
            this.organizationsService
                .moveSitesToOrganization(
                    this.destinationOrganizationName,
                    this.selectedSiteValues.map((element) => element.Id)
                )
                .then((res) => {
                    this.modal.hide();
                    this.organizationsService.getLatest();
                    this.onMove.emit(this.destinationOrganizationName);
                    this.movingSite = false;

                    this.selectedSiteValues.forEach(site => this.siteDashboardService.setDashboardItem(site.Id, 'OrganizationName'));

                    return;
                });
        } else {
            this.changeStep(this.step + 1);
        }
    }

    goBack() {
        if (this.step == -1) {
            this.changeStep(1);
            return;
        }

        if (this.step == 1) {
            this.changeStep(-1); // yo dawg I heard you like your cancel buttons to have cancel buttons
            return;
        }

        this.changeStep(this.step - 1);

        this.canProceed = true;
    }

    validate() {
        this.hasViolation = false;
        if (
            this.sourceOrganizationName === this.destinationOrganizationName ||
            !this.selectedSites.includes(true) ||
            typeof this.destinationOrganizationName == 'undefined'
        ) {
            this.hasViolation = true;
        }
    }

    setSourceOrganizationId(organizationEvent: any) {
        let organizationName =
            typeof organizationEvent === 'object' ? organizationEvent.value : organizationEvent;

        if (organizationName === 'Unassigned Sites') {
            organizationName = '__unassigned';
        }
        if (this.prevSourceOrganizationName != organizationName) {
            // ignoring if model changes but value hasn't.
            //PG Before validating we need to clear selected keys else it fails the first time
            this.selectedSites = [];
            this.validate();
            this.prevSourceOrganizationName = organizationName;
            if (
                organizationName === '__unassigned' &&
                this.destinationOrganizationName === '__unassigned'
            ) {
                this.destinationOrganizationName = this.organizations[0].Name;
            }

            this.loadingSitesComplete = false;
            this.siteService.getSitesInOrganization(organizationName).then((res) => {
                this.sourceOrganizationSites = [];
                this.selectedSites = Array(res.length);
                this.selectedSites.fill(false);

                this.sourceOrganizationSites.push(...res);

                if (this.showOneSite) {
                    this.sourceOrganizationSites = this.sourceOrganizationSites.filter(
                        (p) => p.Name === this.showOneSite
                    );
                }
                this.loadingSitesComplete = true;
            });
        }
    }

    setDestinationOrganizationId(organizationName: string) {
        this.validate();
    }

    onSiteSelected(evt: any) {
        console.log('evt: ' + evt);

        this.selectedSiteValues = [];
        for (var i = 0; i < this.selectedSites.length; i++) {
            if (this.selectedSites[i]) {
                this.selectedSiteValues.push(this.sourceOrganizationSites[i]);
            }
        }
        this.validate();
    }

    getSourceOrganizationName() {
        if (this.sourceOrganizationName === '__unassigned') {
            return 'Unassigned Sites';
        }

        return this.sourceOrganizationName;
    }
}
