import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ToastService } from '../toast.service';

@Component({
    selector: 'app-toast-area',
    templateUrl: './toast-area.component.html',
    styleUrls: ['./toast-area.component.css'],
})
export class ToastAreaComponent implements OnInit {
    constructor(private toastService: ToastService) {}

    @ViewChild('toastArea', { read: ViewContainerRef, static: true })
    private toastArea: ViewContainerRef;

    ngOnInit() {
        this.toastService
            .getObservable()
            .subscribe((toast) => this.toastService.makeToast(this.toastArea, toast));
    }
}
