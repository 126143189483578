import { Component, isDevMode, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthService, Roles, SiteRoles } from 'app/core/auth';
import { MessageService } from 'app/core/message.service';
import { NavService } from 'app/core/nav/nav.service';
import { Site } from '../shared/site.model';

import { SiteService } from '../shared/site.service';
import { UniviewService } from '../shared/uniview.service';
import { DattoService } from '../shared/datto.service';

import { SidebarMenuExtras, SidebarMenuItem } from 'app/shared/sidebar/menu/sidebar-menu-item.model';
import { PING_SITE, SITE } from '../shared/constants';
import { UiService } from '../../core/ui.service';
import { Subject, takeUntil } from 'rxjs';
import { ScanDataService } from '../../scans/shared/scan-data.service';
import { K1organizationService } from '../../settings/shared/k1organization.service';

const UI_KEY_PAGENO: string = 'site_todo_page_number';
const UI_KEY_PAGESIZE: string = 'site_todo_page_size';
@Component({
    templateUrl: './site.component.html',
    styleUrls: ['./site.component.css'],
})
export class SiteComponent implements OnInit, OnDestroy {
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private messageService: MessageService,
        private navService: NavService,
        private siteService: SiteService,
        private univiewService: UniviewService,
        private dattoService: DattoService,
        private uiService: UiService,
        private scandataService: ScanDataService,
        private k1OrganizationService: K1organizationService
    ) {}

    site: Site;

    menuTree: SidebarMenuItem[] = [];
    menuExtras: SidebarMenuExtras;

    hideAssessmentChildren: boolean = false;

    indocMenuItems: SidebarMenuItem[];
    ndpDataExplorerItems: SidebarMenuItem[] = [];

    ngUnsubscribe$: Subject<void> = new Subject();

    ngOnInit() {
        this.navService.setHeaderText(' '); // not empty string, so it will force the bar to show right away

        this.route.params.subscribe((params: Params) => {
            let name: string = params['name'];
            //this.navService.setSiteName(name);
            name = name.replace(/%26/g, '&');

            this.siteService.getSiteByName(name).then((site) => this.onSite(site));
        });

        this.messageService
            .on(PING_SITE)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => this.onPingSite());

        this.messageService
            .on('LICENSE_UPDATED')
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((model) => {
                let lnum = parseInt(model.split(' ')[1], 10);
                this.site.LicensedNumber = lnum;
                this.site.HasLicenseViolation =
                    false /*(this.site.LicensedNumber < this.site.DeployedNumber)*/;
            });

        this.messageService
            .on('ASSESSMENT_RESTARTED')
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => {
                this.site.HasLicenseViolation = false;
                this.site.DeployedNumber = 0;
                this.navService.setSite(this.site);
                this.messageService.broadcast(SITE, this.site);
            });

        this.messageService
            .on('UNIVIEW_SAVED')
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => {
                this.buildSidebarMenu();
            });

        this.messageService
            .on('DATTO_SAVED')
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => {
                this.buildSidebarMenu();
            });

        this.messageService
            .on('DATTO_WORKPLACE_SAVED')
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(() => {
                this.buildSidebarMenu();
            });
    }

    ngOnDestroy() {
        this.siteService.app_setCurrentSite(null);
        this.siteService.resetSiteObs();
        this.uiService.setControlValue(UI_KEY_PAGENO, '');
        this.uiService.setControlValue(UI_KEY_PAGESIZE, '');

        this.ngUnsubscribe$.next(void 0);
        this.ngUnsubscribe$.complete();
    }

    onSite(site: Site) {
        this.site = site;
        this.siteService.app_setCurrentSite(this.site);
        this.siteService.setSiteObs(this.site);

        this.navService.setHeaderText(this.site.Name);
        if (this.site.IsCSPro) {
            this.navService.setHeaderFlair('<i class="cs-pro-logo"></i>');
        }

        let roleId: number = parseInt(this.authService.getIdentity().roleId, 10);

        if (site.UserRoles) {
            this.authService.setCurrentSiteRoles(site.UserRoles);
            this.messageService.broadcast(
                'NAV_SET_LIMITED_CRUMBS',
                this.authService.isOnlySME() || this.authService.isClient()
            );
        } else {
            this.authService.clearCurrentSiteRoles();

            // If no site roles, check global access role
            if (roleId != Roles.Master && roleId != Roles.Admin && roleId != Roles.Restricted) {
                // User does not have access to this site
                this.router.navigateByUrl('/sites');
                return;
            }
            // Global admin privileges cascade down to sites
            else if (roleId == Roles.Admin || roleId == Roles.Master) {
                this.authService.setCurrentSiteRoles(SiteRoles.SiteAdmin);
            }
        }

        //if (this.site.HasLicenseViolation) {
        //  this.router.navigate(['/site', this.site.Name, 'settings', 'advanced']);
        //}

        let siteRoles = this.authService.getCurrentSiteRoles();

        let isTech = siteRoles && siteRoles.indexOf(SiteRoles.Tech) > -1;
        let isAdmin = siteRoles && siteRoles.indexOf(SiteRoles.SiteAdmin) > -1;

        // Build sidebar menu
        this.buildSidebarMenu();

        // This is not really the ideal way to do these redirects
        if (this.route.children.length == 0) {
            if (
                this.site.IsIndoc &&
                ([Roles.Admin, Roles.Master].indexOf(roleId) > -1 || isAdmin || isTech)
            )
                this.router.navigate(['site', this.site.Name.replace(/&/g, '%26'), 'indoc']);
            else if (this.authService.isClient())
                this.router.navigate([
                    'site',
                    this.site.Name.replace(/&/g, '%26'),
                    'client',
                    'reports',
                ]);
            else this.router.navigate(['site', this.site.Name.replace(/&/g, '%26'), 'todo']);
        }

        this.navService.setSite(site);

        this.messageService.broadcast(SITE, this.site);
    }

    onPingSite() {
        this.messageService.broadcast(SITE, this.site);
    }

    buildSidebarMenu() {
        let user = this.authService.getIdentity();
        let products = this.authService.getProducts();
        let indoc = this.siteService.isIndoc(this.site);
        let ndpro = this.siteService.isNDPro(this.site);
        let cm = this.siteService.isComplianceManager(this.site);
        let ch = this.siteService.isCyberHawk(this.site);
        let kvs = this.siteService.isKVS(this.site);
        let cmgrc = this.siteService.isComplianceManagerGRC(this.site);
        let siteRoles = this.authService.getCurrentSiteRoles();

        let isTech = siteRoles && siteRoles.indexOf(SiteRoles.Tech) > -1;
        let isAdmin = siteRoles && siteRoles.indexOf(SiteRoles.SiteAdmin) > -1;
        let isClientView = siteRoles && siteRoles.indexOf(SiteRoles.Client) > -1;
        let isSME = siteRoles && siteRoles.indexOf(SiteRoles.SME) > -1;
        let isAuditor = siteRoles && siteRoles.indexOf(SiteRoles.Auditor) > -1;
        let isEmpPortalAdmin = siteRoles && siteRoles.indexOf(SiteRoles.EmployeePortalAdmin) > -1;
        let isPrivileged = this.authService.userIsRoles([Roles.Master, Roles.Admin]);
        let uniview = false;
        let datto = false;
        let dattoWorkplace = false;
        let evidenceCompliance = false;
        let isGrcReportViewer = siteRoles && siteRoles.indexOf(SiteRoles.GrcReportViewer) > -1;
        /* NDpro Roles */
        let ndpMaster = this.authService.userIsRole(Roles.Master);
        let ndpAdmin = this.authService.userIsRole(Roles.Admin);
        let ndpSiteRestricted = this.authService.userIsRoles([
            Roles.Restricted,
            Roles.SiteRestricted,
        ]);

        let menuTree: SidebarMenuItem[] = [];

        Promise.allSettled([
            this.univiewService.isUniviewEnabled(this.site.Id),
            this.dattoService.isDattoEnabled(this.site.Id),
            this.dattoService.isDattoWorkplaceEnabled(this.site.Id),
            this.k1OrganizationService.isK1OrganizationMapped(this.site.Id),
        ])
            .then((res) => {
                uniview = res[0].status === 'fulfilled' ? res[0].value : false;
                datto = res[1].status === 'fulfilled' ? res[1].value : false;
                dattoWorkplace = res[2].status === 'fulfilled' ? res[2].value : false;
                evidenceCompliance = res[3].status === 'fulfilled' ? res[3].value : false;
            })
            .catch((error) => {
                uniview = false;
                datto = false;
                dattoWorkplace = false;
                evidenceCompliance = false;
            })
            .finally(() => {
                // Home
                if (isTech || isAdmin || isAuditor) {
                    let homeNode = {
                        label: 'Home',
                        url: 'home',
                        icon: 'house-blank',
                        children: [],
                    };

                    if (!cmgrc && !ndpro) {
                        // per ticket RFTDEV-5947 - hide home > dashboard for CMGRC site
                        homeNode.children.push({
                            label: 'Dashboard',
                            href: 'home/dashboard',
                            icon: 'icon-dashboard',
                        });
                    }

                    if ((ch || cm || indoc || kvs || cmgrc || ndpro) && !isAuditor) {
                        homeNode.children.push({
                            label: 'Data Collectors',
                            href: 'home/data-collectors',
                            icon: 'icon-appliance',
                        });
                    }

                    if (isAdmin) {
                        homeNode.children.push({
                            label: 'Users',
                            href: 'home/users',
                            icon: 'icon-user',
                        });
                        homeNode.children.push({
                            label: 'Roles',
                            href: 'home/roles',
                            icon: 'icon-users',
                        });
                    }

                    if (cmgrc && (isAdmin || isAuditor || isTech))
                        homeNode.children.push({
                            label: 'Groups',
                            href: 'home/groups',
                            icon: 'icon-users',
                        });

                    if (((ch || cm) && !isAuditor) || cmgrc) {
                        homeNode.children.push({
                            label: 'Admin Alerts',
                            href: 'home/admin-alerts',
                            icon: 'icon-notifications',
                        });
                    }

                    if (isAdmin)
                        homeNode.children.push({
                            label: 'Advanced Options',
                            href: 'home/advanced',
                            icon: 'icon-advanced-options',
                        });

                    if (
                        !isAuditor &&
                        ch &&
                        (user.distributorId == 'FMIT' || this.site.HasFusebill)
                    ) {
                        homeNode.children.push({
                            label: 'Add-ons',
                            href: 'home/addons',
                            icon: 'icon-addons',
                        });
                    }

                    menuTree.push(homeNode);
                }

                // InDoc
                if (indoc && !ndpro) {
                    let indocNode = {
                        label: products.NdPro ? 'Network Detective' : 'InDoc',
                        url: 'indoc',
                        icon: 'icon-indoc',
                        children: [],
                    };

                    let settingsNode = {
                        label: 'Settings',
                        url: 'indoc/settings',
                        icon: 'icon-settings',
                        children: [
                            {
                                label: 'Client View',
                                href: 'indoc/settings/client-view',
                                icon: 'user circle',
                            },
                        ],
                    };

                    if (products.NdPro) {
                        indocNode.children.push({
                            label: 'Dashboard',
                            href: 'indoc/dashboard',
                            icon: 'icon-dashboard',
                        });

                        if (!(indoc && isClientView)) {
                            indocNode.children.push({
                                label: 'Data Collection',
                                url: 'indoc/data-collection',

                                children: [
                                    {
                                        label: 'Cyberattack Risk Scan',
                                        href: 'indoc/data-collection/cybersecurity-assessment-scan',
                                    },

                                    {
                                        label: 'End-User Initiated Scan',
                                        href: 'indoc/data-collection/end-user-initiated-scan',
                                    },
                                    {
                                        label: 'Scan Data',
                                        href: 'indoc/data-collection/scandata',
                                    },
                                ],
                            });
                        }
                    } else {
                        indocNode.children.push({
                            label: 'Dashboard',
                            href: 'indoc/iddashboard',
                            icon: 'icon-dashboard',
                        });
                    }

                    let dataExplorerNode = {
                        label: 'Data Explorer',
                        url: 'indoc/data-explorer',
                        children: [
                            {
                                label: 'Users',
                                href: 'indoc/data-explorer/users',
                                icon: 'icon-users',
                            },
                            {
                                label: 'Computers',
                                href: 'indoc/data-explorer/computers',
                                icon: 'icon-computer',
                            },
                            {
                                label: 'Databases',
                                href: 'indoc/data-explorer/databases',
                                icon: 'icon-db',
                            },
                            {
                                label: 'Applications',
                                href: 'indoc/data-explorer/applications',
                                icon: 'icon-application',
                            },
                            {
                                label: 'Printers',
                                href: 'indoc/data-explorer/printers',
                                icon: 'icon-print',
                            },
                            {
                                label: 'Non-AD Devices',
                                href: 'indoc/data-explorer/nonad-devices',
                                icon: 'icon-mobility',
                            },
                            {
                                label: 'Security Groups',
                                href: 'indoc/data-explorer/security-groups',
                                icon: 'icon-people',
                            },
                            {
                                label: 'DNS',
                                href: 'indoc/data-explorer/dns',
                                icon: 'icon-indoc2',
                            },
                        ],
                    };
                    this.addDataExplorerCounts(dataExplorerNode.children);

                    this.indocMenuItems = dataExplorerNode.children;

                    if (products.NdPro) {
                        // // disabled NDPro Online Portal 2022-10-27 per RFTDEV-9549
                        // indocNode.children.push({
                        //   label: 'Interactive Reports',
                        //   icon: 'icon-audit',
                        //   href: 'indoc/interactive-reports',
                        // /*   children: [
                        //     {
                        //       label: 'Predefined Reports',
                        //       href: 'indoc/reports',
                        //       icon: 'icon-audit',
                        //     },
                        //     {
                        //       label: 'Datasets',
                        //       href: 'indoc/datasets',
                        //       icon: 'icon-db',
                        //     },
                        //   ], */
                        // });
                    }
                    if (!(isClientView && indoc)) {
                        indocNode.children.push(dataExplorerNode);
                    }

                    if (isAdmin || isTech || (indoc && isClientView)) {
                        indocNode.children.push(settingsNode);
                    }

                    menuTree.push(indocNode);
                }

                if (ndpro) {
                    let ndProNode: SidebarMenuItem = {
                        label: 'Network Detective Pro',
                        url: 'network-detective-pro',
                        icon: 'icon-ndpro',
                        children: [],
                    };

                    if (isAdmin || isTech) {
                        if (!products.NdProWeb) {
                            ndProNode.children.push({
                                label: 'Dashboard',
                                href: 'network-detective-pro/dashboard',
                            });
                        }

                        if (!products.NdProWeb) {
                            ndProNode.children.push({
                                label: 'Data Collection',
                                url: 'network-detective-pro/data-collection',
                                children: [
                                    {
                                        label: 'Cyberattack Risk Assessment',
                                        href: 'network-detective-pro/data-collection/cybersecurity-assessment-scan',
                                    },
                                    {
                                        label: 'End-user Initiated Scan',
                                        href: 'network-detective-pro/data-collection/end-user-initiated-scan',
                                    },
                                    {
                                        label: 'Scan Data',
                                        href: 'network-detective-pro/data-collection/scandata',
                                    },
                                ],
                            });

                            let ndDataExplorerNode = {
                                label: 'Data Explorer',
                                url: 'network-detective-pro/data-explorer',
                                children: [
                                    {
                                        label: 'Users',
                                        href: 'network-detective-pro/data-explorer/users',
                                        badgeContent: '0',
                                    },
                                    {
                                        label: 'Computers',
                                        href: 'network-detective-pro/data-explorer/computers',
                                        badgeContent: '0',
                                    },
                                    {
                                        label: 'Databases',
                                        href: 'network-detective-pro/data-explorer/databases',
                                        badgeContent: '0',
                                    },
                                    {
                                        label: 'Applications',
                                        href: 'network-detective-pro/data-explorer/applications',
                                        badgeContent: '0',
                                    },
                                    {
                                        label: 'Printers',
                                        href: 'network-detective-pro/data-explorer/printers',
                                        badgeContent: '0',
                                    },
                                    {
                                        label: 'Non-AD Devices',
                                        href: 'network-detective-pro/data-explorer/nonad-devices',
                                        badgeContent: '0',
                                    },
                                    {
                                        label: 'Security Groups',
                                        href: 'network-detective-pro/data-explorer/security-groups',
                                        badgeContent: '0',
                                    },
                                    {
                                        label: 'DNS',
                                        href: 'network-detective-pro/data-explorer/dns',
                                        badgeContent: '0',
                                    },
                                ],
                            };

                            this.addDataExplorerCounts(ndDataExplorerNode.children);

                            this.ndpDataExplorerItems = ndDataExplorerNode.children;
                            ndProNode.children.push(ndDataExplorerNode);
                        }

                        if (products.NdProWeb) {
                            ndProNode.children.push({
                                label: 'Dashboard',
                                href: 'network-detective-pro/dashboard',
                            });

                            if (isDevMode()) {
                                ndProNode.children.push({
                                    label: 'audIT',
                                    href: 'network-detective-pro/audit',
                                    badgeText: 'New',
                                });
                            }

                            ndProNode.children.push({
                                label: 'Assessments',
                                href: 'network-detective-pro/assessments',
                            });

                            ndProNode.children.push({
                                label: 'Automation',
                                href: 'network-detective-pro/automation',
                            });

                            ndProNode.children.push({
                                label: 'Data Explorer',
                                href: 'network-detective-pro/data-explorer',
                            });

                            ndProNode.children.push({
                                label: 'Reports',
                                href: 'network-detective-pro/reports',
                            });
                        }

                        let ndpSettingsNode;
                        if (products.NdProWeb) {
                            ndpSettingsNode = {
                                label: 'Settings',
                                url: 'network-detective-pro/settings',
                                children: [
                                    {
                                        label: 'Report Settings',
                                        href: 'network-detective-pro/settings/report-preferences',
                                    },
                                    {
                                        label: 'Issue Overrides',
                                        href: 'network-detective-pro/settings/issue-overrides',
                                    },
                                    {
                                        label: 'User Report Sets',
                                        href: 'network-detective-pro/settings/user-report-sets',
                                    },
                                    {
                                        label: 'Automation Settings',
                                        href: 'network-detective-pro/settings/automation-settings',
                                    },
                                    {
                                        label: 'Client View',
                                        href: 'network-detective-pro/settings/client-view',
                                    },
                                ],
                            };
                        } else {
                            ndpSettingsNode = {
                                label: 'Settings',
                                url: 'network-detective-pro/settings',
                                icon: 'icon-settings',
                                children: [
                                    {
                                        label: 'Client View',
                                        href: 'network-detective-pro/settings/client-view',
                                        icon: 'user circle',
                                    },
                                ],
                            };
                        }
                        ndProNode.children.push(ndpSettingsNode);
                    }

                    if (isClientView) {
                        let settingsNode = {
                            label: 'Settings',
                            url: 'network-detective-pro/settings',
                            icon: 'icon-settings',
                            children: [
                                {
                                    label: 'Client View',
                                    href: 'network-detective-pro/settings/client-view',
                                    icon: 'user circle',
                                },
                            ],
                        };
                        ndProNode.children.push(settingsNode);
                    }

                    menuTree.push(ndProNode);
                }

                if (cmgrc) {
                    let cmGRCNode = {
                        label: 'Compliance Manager GRC',
                        url: 'compliance-manager-grc',
                        icon: 'icon-audit-guru',
                        children: [],
                    };

                    // // Hidden per Win RFTDEV-5948
                    // agNode.children.push(
                    //   { label: 'Dashboard', href: 'compliance-manager-grc/dashboard', icon: 'icon-dashboard' }
                    // );

                    // // Hidden per Win RFTDEV-5949
                    // agNode.children.push(
                    //   { label: 'To Do', href: 'compliance-manager-grc/todo', icon: 'icon-audit' }
                    // );

                    if (isTech || isAdmin || isAuditor) {
                        cmGRCNode.children.push({
                            label: 'Dashboard',
                            href: 'compliance-manager-grc/dashboard',
                            icon: 'icon-dashboard',
                        });

                        let cmGrcComplianceMonitorNode = {
                            label: 'Compliance Monitor',
                            url: 'compliance-manager-grc/compliance-monitor',
                            icon: 'icon-list',
                            children: [],
                        };

                        cmGrcComplianceMonitorNode.children.push({
                            label: 'Status',
                            href: 'compliance-manager-grc/compliance-monitor/status',
                            icon: 'icon-audit',
                        });

                        cmGrcComplianceMonitorNode.children.push({
                            label: 'Devices',
                            href: 'compliance-manager-grc/compliance-monitor/devices',
                            icon: 'icon-audit',
                        });

                        cmGrcComplianceMonitorNode.children.push({
                            label: 'Scan Settings',
                            href: 'compliance-manager-grc/compliance-monitor/scan-settings',
                            icon: 'icon-audit',
                        });

                        cmGrcComplianceMonitorNode.children.push({
                            label: 'Benchmarks',
                            href: 'compliance-manager-grc/compliance-monitor/benchmarks',
                        });

                        if(isDevMode()) {
                            cmGRCNode.children.push(cmGrcComplianceMonitorNode);
                        }

                        cmGRCNode.children.push({
                            label: 'My Work',
                            href: 'compliance-manager-grc/my-work',
                        });

                        cmGRCNode.children.push({
                            label: 'Standards & Controls',
                            href: 'compliance-manager-grc/standards-controls',
                            icon: 'icon-audit',
                        });

                        cmGRCNode.children.push({
                            label: 'Policies & Procedures',
                            href: 'compliance-manager-grc/policies-procedures',
                            icon: 'icon-audit',
                        });

                        cmGRCNode.children.push({
                            label: 'Rapid Baseline Assessment',
                            href: 'compliance-manager-grc/rapid-baseline',
                            icon: 'icon-assessment',
                        });

                        if (isTech || isAdmin) {
                            let cmGrcDataCollectionNode = {
                                label: 'Data Collection',
                                url: 'compliance-manager-grc/data-collection',
                                icon: 'icon-list',
                                children: [],
                            };
                            cmGrcDataCollectionNode.children.push(
                                {
                                    label: 'LAN Scan',
                                    href: 'compliance-manager-grc/data-collection/lan',
                                    icon: 'icon-audit',
                                },
                                {
                                    label: 'Discovery Agent Scans',
                                    href: 'compliance-manager-grc/data-collection/discovery-agents',
                                    icon: 'icon-audit',
                                },
                                {
                                    label: 'Cloud Scan',
                                    href: 'compliance-manager-grc/data-collection/cloud',
                                    icon: 'icon-audit',
                                },
                                {
                                    label: 'Computer Scan',
                                    href: 'compliance-manager-grc/data-collection/computer',
                                    icon: 'icon-audit',
                                },
                                {
                                    label: 'External VulScan',
                                    href: 'compliance-manager-grc/data-collection/external',
                                    icon: 'icon-audit',
                                },
                                {
                                    label: 'Internal VulScan',
                                    href: 'compliance-manager-grc/data-collection/internal',
                                    icon: 'icon-audit',
                                }
                            );

                            if (uniview) {
                                cmGrcDataCollectionNode.children.push({
                                    label: 'Unitrends',
                                    href: 'compliance-manager-grc/data-collection/uniview',
                                    icon: 'icon-audit',
                                });
                            }

                            if (datto) {
                                cmGrcDataCollectionNode.children.push({
                                    label: 'Datto Continuity',
                                    href: 'compliance-manager-grc/data-collection/datto',
                                    icon: 'icon-audit',
                                });
                            }

                            if (dattoWorkplace) {
                                cmGrcDataCollectionNode.children.push({
                                    label: 'Datto Workplace',
                                    href: 'compliance-manager-grc/data-collection/workplace',
                                    icon: 'icon-audit',
                                });
                            }

                            if (evidenceCompliance) {
                                cmGrcDataCollectionNode.children.push({
                                    label: 'IT Complete Modules',
                                    href: 'compliance-manager-grc/data-collection/it-complete',
                                    icon: 'icon-audit',
                                });
                            }

                            cmGrcDataCollectionNode.children.push({
                                label: 'Scan Data',
                                href: 'compliance-manager-grc/data-collection/scans',
                                icon: 'icon-audit',
                            });

                            cmGRCNode.children.push(cmGrcDataCollectionNode);
                        }

                        cmGRCNode.children.push({
                            label: 'Technical Review',
                            href: 'compliance-manager-grc/technical-review',
                            icon: 'icon-audit',
                        });

                        cmGRCNode.children.push({
                            label: 'Controls Assessment',
                            href: 'compliance-manager-grc/controls-assessment',
                            icon: 'icon-audit',
                        });

                        cmGRCNode.children.push({
                            label: 'Requirements Assessment',
                            href: 'compliance-manager-grc/requirements-assessment',
                            icon: 'icon-audit',
                        });

                        cmGRCNode.children.push({
                            label: 'Plan of Action & Milestone',
                            href: 'compliance-manager-grc/plan-action',
                            icon: 'icon-audit',
                        });
                    }
                    // SME only get My Work and Worksheets
                    if (isSME) {
                        cmGRCNode.children.push({
                            label: 'My Work',
                            href: 'compliance-manager-grc/my-work',
                        });
                    }

                    let RANDEChildren = [];

                    if (isAdmin || isAuditor || isTech || isGrcReportViewer) {
                        RANDEChildren.push({
                            label: 'Reports',
                            href: 'compliance-manager-grc/results-evidence/reports',
                            icon: 'icon-audit',
                        });
                    }

                    if (isAdmin || isAuditor || isTech) {
                        RANDEChildren.push({
                            label: 'Files / Exhibits',
                            href: 'compliance-manager-grc/results-evidence/files-exhibits',
                            icon: 'icon-audit',
                        });
                    }

                    if (isSME || isAdmin || isAuditor || isTech) {
                        RANDEChildren.push({
                            label: 'Worksheets',
                            href: 'compliance-manager-grc/results-evidence/worksheets',
                            icon: 'icon-audit',
                        });
                    }

                    if (
                        !isEmpPortalAdmin ||
                        isTech ||
                        isAdmin ||
                        isClientView ||
                        isSME ||
                        isAuditor ||
                        isGrcReportViewer
                    ) {
                        cmGRCNode.children.push({
                            label: 'Results & Evidence',
                            url: 'compliance-manager-grc/results-evidence',
                            icon: 'icon-list',
                            children: RANDEChildren,
                        });
                    }

                    if (isAdmin || isAuditor || isTech) {
                        cmGRCNode.children.push({
                            label: 'Archived Assessments',
                            href: 'compliance-manager-grc/archived-assessments',
                        });
                    }

                    if (isAdmin) {
                        cmGRCNode.children.push({
                            label: 'Settings',
                            url: 'compliance-manager-grc/settings',
                            icon: 'icon-list',
                            children: [
                                {
                                    label: 'Report Settings',
                                    href: 'compliance-manager-grc/settings/report-preferences/text',
                                    icon: 'icon-check-box-checked',
                                },
                                {
                                    label: 'Manage Invites',
                                    href: 'compliance-manager-grc/settings/manage-invites',
                                    icon: 'icon-check-box-checked',
                                },
                                {
                                    label: 'IT Complete',
                                    href: 'compliance-manager-grc/settings/it-complete',
                                    icon: 'icon-list',
                                },
                                {
                                    label: 'Schedules',
                                    href: 'compliance-manager-grc/settings/schedules',
                                    icon: 'icon-schedule',
                                },
                            ],
                        });

                        //agNode.children.push(
                        //    { label: 'Audit History', href: 'compliance-manager-grc/audit-history', icon: 'icon-list' }
                        //);
                    }

                    if (isAdmin || isAuditor) {
                        cmGRCNode.children.push({
                            label: 'Employee Portal',
                            url: 'compliance-manager-grc/employee-portal',
                            icon: 'icon-list',
                            children: [
                                {
                                    label: 'Setup',
                                    href: 'compliance-manager-grc/employee-portal/setup',
                                    icon: 'icon-check-box-checked',
                                },
                                {
                                    label: 'Employee Tracker',
                                    href: 'compliance-manager-grc/employee-portal/employee-tracker',
                                    icon: 'icon-check-box-checked',
                                },
                            ],
                        });

                        cmGRCNode.children.push({
                            label: 'Vendor Management',
                            url: 'compliance-manager-grc/vendor-management',
                            icon: 'icon-list',
                            children: [
                                {
                                    label: 'Setup',
                                    href: 'compliance-manager-grc/vendor-management/setup',
                                    icon: 'icon-check-box-checked',
                                },
                                {
                                    label: 'Vendor Tracker',
                                    href: 'compliance-manager-grc/vendor-management/vendor-tracker',
                                    icon: 'icon-check-box-checked',
                                },
                            ],
                        });
                    } else if (isEmpPortalAdmin) {
                        cmGRCNode.children.push({
                            label: 'Employee Portal',
                            url: 'compliance-manager-grc/employee-portal',
                            icon: 'icon-list',
                            children: [
                                {
                                    label: 'Setup',
                                    href: 'compliance-manager-grc/employee-portal/setup',
                                    icon: 'icon-check-box-checked',
                                },
                                {
                                    label: 'Employee Tracker',
                                    href: 'compliance-manager-grc/employee-portal/employee-tracker',
                                    icon: 'icon-check-box-checked',
                                },
                            ],
                        });
                    }

                    menuTree.push(cmGRCNode);
                }
                // Compliance Manager
                if (cm) {
                    let cmNode = {
                        label: 'Compliance Manager',
                        url: 'compliance-manager',
                        icon: 'icon-audit-guru',
                        children: [],
                    };

                    if (isTech || isAdmin || isAuditor) {
                        cmNode.children.push({
                            label: 'To Do',
                            href: 'compliance-manager/todo',
                            icon: 'icon-list',
                        });
                    }
                    if (!isSME) {
                        cmNode.children.push({
                            label: 'Assessments',
                            url: 'compliance-manager/assessments',
                            icon: 'icon-assessment',
                            children: [
                                {
                                    label: 'Dashboard',
                                    href: 'compliance-manager/assessments/dashboard',
                                    icon: 'icon-dashboard',
                                },
                                {
                                    label: 'Forms',
                                    href: 'compliance-manager/assessments/forms',
                                    icon: 'icon-check-box-checked',
                                },
                                {
                                    label: 'Files',
                                    href: 'compliance-manager/assessments/files',
                                    icon: 'icon-folder',
                                },
                                {
                                    label: 'Reports',
                                    href: 'compliance-manager/assessments/reports',
                                    icon: 'icon-audit',
                                },
                                {
                                    label: 'Issues',
                                    href: 'compliance-manager/assessments/issues',
                                    icon: 'icon-audit',
                                },
                            ],
                        });
                    } else {
                        cmNode.children.push({
                            label: 'Assessments',
                            url: 'compliance-manager/assessments',
                            icon: 'icon-assessment',
                            children: [
                                {
                                    label: 'Forms',
                                    href: 'compliance-manager/assessments/forms',
                                    icon: 'icon-check-box-checked',
                                },
                            ],
                        });
                    }

                    if (!(isSME || isClientView)) {
                        cmNode.children.push({
                            label: 'Risk Updates',
                            href: 'compliance-manager/risksnapshot',
                            icon: 'icon-list',
                        });
                    }

                    if (isAdmin) {
                        cmNode.children.push({
                            label: 'Settings',
                            url: 'compliance-manager/settings',
                            icon: 'icon-settings',
                            children: [
                                {
                                    label: 'Scan Settings',
                                    href: 'compliance-manager/settings/scanwizard',
                                    icon: 'icon-gears',
                                },
                                {
                                    label: 'Scan Schedules',
                                    href: 'compliance-manager/settings/scan-schedules',
                                    icon: 'icon-schedule',
                                },
                                {
                                    label: 'Report Settings',
                                    href: 'compliance-manager/settings/reports',
                                    icon: 'icon-reports-alt',
                                    children: [
                                        {
                                            label: 'Text',
                                            href: 'compliance-manager/settings/reports/text',
                                        },
                                        {
                                            label: 'My Logo',
                                            href: 'compliance-manager/settings/reports/my-logo',
                                        },
                                        {
                                            label: 'Theme',
                                            href: 'compliance-manager/settings/reports/theme',
                                        },
                                        {
                                            label: 'Cover Images',
                                            href: 'compliance-manager/settings/reports/cover-images',
                                        },
                                    ],
                                },
                                {
                                    label: 'Manage Invites',
                                    href: 'compliance-manager/settings/invites',
                                    icon: 'icon-manageinvites',
                                },
                                {
                                    label: 'IT Complete',
                                    href: 'compliance-manager/settings/itcomplete',
                                    icon: 'icon-itc-mark',
                                },
                            ],
                        });
                    } else if (isTech) {
                        cmNode.children.push({
                            label: 'Settings',
                            url: 'compliance-manager/settings',
                            icon: 'icon-settings',
                            children: [
                                {
                                    label: 'Scan Settings',
                                    href: 'compliance-manager/settings/scanwizard',
                                    icon: 'icon-gears',
                                },
                                {
                                    label: 'Scan Schedules',
                                    href: 'compliance-manager/settings/scan-schedules',
                                    icon: 'icon-schedule',
                                },
                            ],
                        });
                    }
                    menuTree.push(cmNode);
                }

                // Cyber Hawk
                if (ch) {
                    let chNode = {
                        label: 'Cyber Hawk',
                        url: 'cyber-hawk',
                        icon: 'icon-cyberhawk',
                        children: [],
                    };

                    if (isTech || isAdmin)
                        chNode.children.push(
                            {
                                label: 'To Do',
                                href: 'cyber-hawk/todo',
                                icon: 'icon-list',
                            },
                            {
                                label: 'Alerts',
                                href: 'cyber-hawk/alerts',
                                icon: 'icon-notifications',
                            },
                            {
                                label: 'Smart Tags',
                                href: 'cyber-hawk/smart-tags',
                                icon: 'icon-tag',
                            }
                        );

                    if (isAdmin || isTech)
                        chNode.children.push({
                            label: 'Settings',
                            url: 'cyber-hawk/settings',
                            icon: 'icon-settings',
                            children: [
                                {
                                    label: 'Scan Settings',
                                    href: 'cyber-hawk/settings/scanwizard',
                                    icon: 'icon-gears',
                                },
                                {
                                    label: 'Scan & Notification Schedules',
                                    href: 'cyber-hawk/settings/scan-schedules',
                                    icon: 'icon-schedule',
                                },
                                {
                                    label: 'Policy Configuration',
                                    href: 'cyber-hawk/settings/policies',
                                    icon: 'icon-policy',
                                },
                                {
                                    label: 'Email Groups',
                                    href: 'cyber-hawk/settings/email-groups',
                                    icon: 'icon-email-groups',
                                },
                                {
                                    label: 'Summary Emails',
                                    href: 'cyber-hawk/settings/summary-emails',
                                    icon: 'icon-mail',
                                },
                                {
                                    label: 'Email Configuration',
                                    href: 'cyber-hawk/settings/email-configuration',
                                    icon: 'icon-mail-settings',
                                },
                                {
                                    label: 'Weekly Notice',
                                    href: 'cyber-hawk/settings/weekly-notice',
                                    icon: 'icon-event',
                                },
                            ],
                        });
                    if (!isClientView) menuTree.push(chNode);
                }

                if (kvs) {
                    let kvsNode = {
                        label: 'VulScan',
                        url: 'vulnerability-scanner',
                        icon: 'icon-vulnerability-scanner',
                        children: [],
                    };
                    kvsNode.children.push({
                        label: 'Dashboard',
                        href: 'vulnerability-scanner/dashboard',
                        icon: 'icon-dashboard',
                    });

                    kvsNode.children.push({
                        label: 'Scan Results',
                        href: 'vulnerability-scanner/scan-results',
                        icon: 'icon-file-search',
                    });

                    let kvsReportsChildren = [
                        {
                            label: 'Weekly Trend Report',
                            href: 'vulnerability-scanner/reports/weekly',
                            icon: 'icon-reports',
                        },
                        {
                            label: 'Monthly Trend Report',
                            href: 'vulnerability-scanner/reports/monthly',
                            icon: 'icon-reports',
                        },
                        {
                            label: 'Open Ports Report',
                            href: 'vulnerability-scanner/reports/open-ports',
                            icon: 'icon-reports',
                        },
                    ];

                    kvsNode.children.push({
                        label: 'Reports',
                        url: 'vulnerability-scanner/reports',
                        icon: 'icon-reports',
                        children: kvsReportsChildren,
                    });

                    if (isAdmin || isTech) {
                        let kvsSettingsChildren = [
                            {
                                label: 'General',
                                href: 'vulnerability-scanner/settings/general',
                                icon: 'icon-settings',
                            },
                            {
                                label: 'Report Settings',
                                href: 'vulnerability-scanner/settings/report-preferences/text',
                                icon: 'icon-check-box-checked',
                            },
                            {
                                label: 'Scan Credentials',
                                href: 'vulnerability-scanner/settings/scan-credentials',
                                icon: 'icon-account-circle',
                            },
                            {
                                label: 'Custom Scan Profiles',
                                href: 'vulnerability-scanner/settings/custom-scan-profiles',
                                icon: 'icon-account-circle',
                            },
                            {
                                label: 'Scan and Notification Tasks',
                                href: 'vulnerability-scanner/settings/scan-notification-task',
                                icon: 'icon-tasks-alt',
                            },
                            {
                                label: 'Exclusion Rules',
                                href: 'vulnerability-scanner/settings/exclusion-rules',
                                icon: 'icon-ballot-check',
                            },
                            {
                                label: 'VulScan Proxy Settings',
                                href: 'vulnerability-scanner/settings/proxy-settings',
                                icon: 'icon-settings',
                            },
                        ];
                        let roleId: number = parseInt(this.authService.getIdentity().roleId, 10);
                        if ([Roles.Admin, Roles.Master].indexOf(roleId) > -1) {
                            kvsSettingsChildren.push({
                                label: 'IT Complete',
                                href: 'vulnerability-scanner/settings/it-complete',
                                icon: 'icon-list',
                            });
                        }
                        kvsNode.children.push({
                            label: 'Settings',
                            url: 'vulnerability-scanner/settings',
                            icon: 'icon-settings',
                            children: kvsSettingsChildren,
                        });
                    }

                    menuTree.push(kvsNode);
                }

                if (isPrivileged || isAdmin) {
                    menuTree.push({
                        label: 'Audit Log',
                        href: 'audit-log',
                        icon: 'clipboard-list-check',
                    });
                }

                //console.log(menuTree);
                this.menuTree = menuTree;
            });
    }

    addDataExplorerCounts(dataExplorerNode: any[]) {
        if (!dataExplorerNode) {
            return;
        }

        this.scandataService
            .getDomainInfo(this.site.Id)
            .then((info) => {
                if (info.Domain) {
                    dataExplorerNode.find((item) => item.label === 'Users').badgeContent =
                        (Number(info.Domain.Details.Users) || 0) +
                        (Number(info.Domain.Details.LocalAccounts) || 0);
                    dataExplorerNode.find((item) => item.label === 'Computers').badgeContent =
                        info.Domain.Details.Computers || 0;
                    dataExplorerNode.find((item) => item.label === 'Databases').badgeContent =
                        info.Domain.Details.Databases || 0;
                    dataExplorerNode.find((item) => item.label === 'Applications').badgeContent =
                        info.Domain.Details.ApplicationsTotal || 0;
                    dataExplorerNode.find((item) => item.label === 'Printers').badgeContent =
                        info.Domain.Details.PrintersTotal || 0;
                    dataExplorerNode.find((item) => item.label === 'Non-AD Devices').badgeContent =
                        info.Domain.Details.NonADTotal || 0;
                    dataExplorerNode.find((item) => item.label === 'Security Groups').badgeContent =
                        info.Domain.Details.SecurityGroupsTotal || 0;
                    dataExplorerNode.find((item) => item.label === 'DNS').badgeContent =
                        info.Domain.Details.DNSTotal || 0;
                }
            })
            .catch((err) => console.error(err));
    }
}
