<sm-modal [class]="state === 'changePassword' ? 'xs' : 'sm'" title="User Settings" #modal>
    <modal-content>
        <form class="ui form settingsPage" [hidden]="state !== 'default'">
            <h3>Security</h3>
            <hr />
            <itc-button
                type="primary"
                icon="fa-key"
                label="Change My Password"
                (onclick)="state = 'changePassword'"></itc-button>
            <br />
            <br />

            <itc-checkbox
                label="Enable Two-Factor Authentication"
                icon="fa-key"
                [control]="twoFactorCtrl"
                *ngIf="!isMfaRequired"></itc-checkbox>
            <itc-button
                type="primary"
                icon="fa-key"
                label="Reset Two-Factor Authentication"
                (onclick)="resetMfa()"
                *ngIf="isMfaRequired"></itc-button>
            <br />
            <br />
        </form>

        <form
            class="ui form"
            name="changePasswordForm"
            [formGroup]="passwordForm"
            *ngIf="state === 'changePassword'">
            <h3>Change My Password</h3>

            <div class="required field">
                <label>Current Password</label>
                <input type="password" name="currentPass" formControlName="currentPass" />
            </div>

            <password-form-fields
                formControlName="newPass"
                (onError)="passwordError = $event"></password-form-fields>

            <itc-banner type="error" *ngIf="passwordError">
                {{ passwordError }}
            </itc-banner>
        </form>

        <sds-mfa-enable
            [(preferences)]="preferences"
            [(isTwoFactorEnabled)]="isTwoFactorEnabled"
            [isRegenerate]="isMfaRequired"
            [hidden]="state !== 'setupTwoFactor'"
            #mfaEnable></sds-mfa-enable>

        <sds-mfa-disable
            [isTwoFactorEnabled]="isTwoFactorEnabled"
            (onIsTwoFactorEnabledChange)="onIsTwoFactorEnabledSet($event)"
            [hidden]="state !== 'deleteTwoFactor'"></sds-mfa-disable>
    </modal-content>
    <modal-actions>
        <itc-button
            type="secondary"
            class="cancel"
            (onclick)="state === 'changePassword' ? hideChangePassword() : null">
            Close
        </itc-button>

        <itc-button
            *ngIf="state === 'changePassword'"
            type="secondary"
            [label]="state === 'changePassword' ? 'Back' : 'Close'"
            [icon]="state === 'changePassword' ? 'fa-arrow-left' : 'close'"
            (onclick)="
                state === 'changePassword' ? hideChangePassword() : hideModal()
            "></itc-button>
        <itc-button
            *ngIf="state === 'changePassword'"
            type="primary"
            [label]="state === 'changePassword' ? 'Confirm' : 'Save'"
            [icon]="state === 'changePassword' ? 'fa-check' : 'save'"
            (onclick)="state === 'changePassword' ? doChangePassword() : savePrefs()"
            [disabled]="state === 'changePassword' && !passwordForm.valid"
            [loading]="state === 'changePassword' && changingPassword"></itc-button>

        <itc-button
            *ngIf="
                state === 'deleteTwoFactor' || (state === 'setupTwoFactor' && !isTwoFactorEnabled)
            "
            type="secondary"
            label="Back"
            icon="fa-arrow-left"
            (onclick)="isTwoFactorEnabled ? cancelTwoFactorSetup() : confirmCancel()"></itc-button>
        <itc-button
            *ngIf="state === 'setupTwoFactor' && isTwoFactorEnabled && !deletingTwoFactorKey"
            type="primary"
            label="OK"
            icon="fa-check"
            (onclick)="finishTwoFactorSetup()"></itc-button>
    </modal-actions>
</sm-modal>
