<sm-modal title="{{modalTitle}}" class="standardsModal md" #showMarkdownModal>
    <modal-content>
        <ng-container >
            <div [innerHTML]="thePrettyPrint"></div>
        </ng-container>
    </modal-content>
    <modal-actions>
        <div>
            <itc-button class="button" type="secondary" (onclick)="showMarkdownModal.hide()">
                Cancel
            </itc-button>
            <itc-button
                class="button"
                type="primary"
                (onclick)="copyToClipboard()">
                Copy to Clipboard
            </itc-button>
        </div>
    </modal-actions>
</sm-modal>
