import { Component, OnDestroy, OnInit } from '@angular/core';
import { NdpService } from 'app/sites/shared/ndp.service';
import { SiteService } from 'app/sites/shared/site.service';
import { Site } from 'app/sites/shared/site.model';
import { SiteRoles } from 'app/sites/shared/constants';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    templateUrl: './ndpro-wrapper.component.html',
    styleUrls: ['./ndpro-wrapper.component.scss'],
})
export class NdpWrapperComponent implements OnInit, OnDestroy {
    constructor(
        private ndpService: NdpService,
        private siteService: SiteService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        let site: Site = this.siteService.getCurrentSite();
        this.ndpService.setCurrencyFormatter(site);
        if (site.UserRoles === SiteRoles.Client) {
            this.router.navigate(['settings', 'client-view'], { relativeTo: this.route });
        }
    }

    ngOnDestroy(): void {
        this.ndpService.resetNDPState();
    }
}
