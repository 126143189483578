<sds-loader [complete]="loadingComplete">
    <sds-breadcrumb-trail
        [crumbs]="breadcrumbs"
        *ngIf="dataExplorerType === 'indoc'"></sds-breadcrumb-trail>

    <header class="pageHeader">
        <h1>Users</h1>

        <div>
            <span class="ui purple circular label">{{ numActive }}</span>
            Active&nbsp;&nbsp;
            <span class="ui olive circular label">{{ numEnabled }}</span>
            Enabled&nbsp;&nbsp;
            <span class="ui orange circular label">{{ users.length }}</span>
            Total&nbsp;&nbsp;
        </div>

        <div class="item searchInput">
            <sds-data-search-filter
                [data]="users"
                (filteredData)="onFiltered($event)"
                [fields]="['Username', 'DisplayName','Domain']"></sds-data-search-filter>
        </div>

        <div class="item">
            <sds-data-filter
                [data]="users"
                [filterOptions]="filterOptions"
                (filteredData)="filteredUsers = $event; sortUsers()"></sds-data-filter>
        </div>
    </header>

    <itc-banner *ngIf="mergeInProgress" type="info">
        <strong>Database update is in progress.</strong>
        {{ users?.length ? 'New scan' : 'Scan' }} data has been uploaded successfully. Check in a
        few minutes after database update is complete.
    </itc-banner>

    <table sortable-table class="itc-table sortable" id="usersTable" (sorted)="onSorted($event)">
        <thead>
            <tr>
                <th sortable-column="Domain">Domain</th>
                <th sortable-column="Username" sort-direction="asc">Username</th>
                <th sortable-column="DisplayName">Display Name</th>
                <th sortable-column="LastLogin">Last Login</th>
                <th sortable-column="Active">Active</th>
                <th sortable-column="Enabled">Enabled</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let user of pagedUsers" [routerLink]="user.Type != 'Local Account' ? (user.Id + '/detail') : undefined" [style.cursor]="user.Type != 'Local Account' ? 'pointer' : 'not-allowed'"
                 tooltip="Detailed information is not available for {{user.Type}}s." ttPosition="top-right" [ttHidden]="user.Type != 'Local Account'">
                <td
                    class="{{
                        (!user.Enabled ? 'font-grey' : '') ||
                            (user.Active && user.Enabled ? 'font-bold' : '')
                    }}">
                    {{ user.Domain }}
                </td>
                <td
                    class="{{
                        (!user.Enabled ? 'font-grey' : '') ||
                            (user.Active && user.Enabled ? 'font-bold' : '')
                    }}">
                    {{ user.Username }}
                </td>
                <td
                    class="{{
                        (!user.Enabled ? 'font-grey' : '') ||
                            (user.Active && user.Enabled ? 'font-bold' : '')
                    }}">
                    {{ user.DisplayName }}
                </td>
                <td class="{{
                        (!user.Enabled ? 'font-grey' : '') ||
                            (user.Active && user.Enabled ? 'font-bold' : '')
                    }}">
                    {{ user.LastLoginFormatted == 'Never' ? 'Never' : (user.LastLoginFormatted+'+00:00' | accountDateTime) }}
                </td>
                <td
                    class="{{
                        (!user.Enabled ? 'font-grey' : '') ||
                            (user.Active && user.Enabled ? 'font-bold' : '')
                    }}">
                    {{ user.Active ? 'Yes' : 'No' }}
                </td>
                <td
                    class="{{
                        (!user.Enabled ? 'font-grey' : '') ||
                            (user.Active && user.Enabled ? 'font-bold' : '')
                    }}">
                    {{ user.Enabled ? 'Yes' : 'No' }}
                </td>
            </tr>
        </tbody>
    </table>
    <itc-paginator
        [data]="filteredUsers"
        (onPage)="pagedUsers = $event"
        [hidden]="!filteredUsers.length"></itc-paginator>

    <empty-state
        icon="results"
        title="No User Items Found"
        [text]="emptyStateText"
        *ngIf="!filteredUsers.length"></empty-state>
</sds-loader>
