import { Component, OnInit } from '@angular/core';
import { AuthService } from '../core/auth/auth.service';
import { environment } from '../../environments/environment';
import { SiteUsersService } from '../sites/shared/site-users.service';
import { EndDateService } from 'app/core/auth';
import { OrganizationsService } from 'app/organizations/organizations.service';

@Component({
    selector: 'sds-logout',
    templateUrl: './logout.component.html',
})
export class LogoutComponent implements OnInit {
    constructor(
        private authService: AuthService,
        private siteUsersService: SiteUsersService,
        private endDateService: EndDateService,
        private organizationsService: OrganizationsService
    ) {}

    ngOnInit() {
        this.siteUsersService.getMyUser().then((res) => {
            this.logout(res.IsK1);
        });
    }

    logout(isK1: boolean) {
        this.endDateService.resetService();
        this.organizationsService.resetObs();
        // clear localstorage I'm using for route test, this can be removed when we finish route guards
        localStorage.removeItem('currentTestSite');
        if (isK1) {
            this.authService.logoutK1();
        } else {
            this.authService.logout();
        }
    }
}
