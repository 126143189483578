import { Component, Input, OnInit } from '@angular/core';

import { AlertDiagnostics } from '../../alert-diagnostics.model';

@Component({
    templateUrl: './NewProfile.component.html',
})
export class NewProfileComponent implements OnInit {
    constructor() {}

    diag: any = {};

    @Input()
    set diagData(value) {
        if (!value) return;

        this.diag = value;
    }

    ngOnInit() {}

    months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    fixDate(date) {
        if (date.match(/\d{14}.\d{6}[+-]\d{3,4}/)) {
            let match = date.match(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2}).\d{6}([+-]\d{3,4})/);
            let yyyy = match[1];
            let MM = match[2];
            let dd = match[3];
            let hh = match[4];
            let mm = match[5];
            let ss = match[6];
            let tz = match[7];
            date = `${this.months[parseInt(MM) - 1]} ${dd}, ${yyyy} ${hh}:${mm}:${ss} ${tz}`;
            console.log(Date);
        } else if (
            !date.match(
                /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/
            )
        ) {
            var match = date.match(
                /[a-zA-Z]+, ([0-9]{4})-([0-9]{2})-([0-9]{2}) ([0-9]{2}:[0-9]{2}:[0-9]{2} [A-Z]{3})/
            );
            if (match) {
                var yyyy = match[1];
                var MM = match[2];
                var dd = match[3];
                var t = match[4];
                date = this.months[parseInt(MM) - 1] + ' ' + dd + ', ' + yyyy + ' ' + t;
                console.log(date);
            }
        }

        return new Date(date).toISOString();
    }
}
