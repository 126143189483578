import { Component, OnInit, Input, inject } from '@angular/core';

import { Site, SiteTypeIcon } from '../../sites/shared/site.model';
import { SiteService } from '../../sites/shared/site.service';
import { DateService } from 'app/shared/date.service';
import { EndDateService } from '../../core/auth/end-date.service';

@Component({
    selector: 'sds-organization-site-card',
    template: `
        <div
            class="siteContainer"
            [class.unavailable]="site.IsLicenseInactive"
            [tooltip]="tooltipText"
            [ttTitle]="tooltipTitle"
            ttPosition="bottom">
            <span class="siteName" [title]="site.Name">{{ site.Name }}</span>
            <span class="siteType">
                <i
                    *ngFor="let siteType of siteTypeIcons"
                    class="site_type {{ siteType.class }}"
                    [title]="siteType.title"></i>
            </span>
        </div>
    `,
    styles: [
        `
            :host {
                display: flex;
                width: 100%;
            }
            /* li {
            display: flex;
            padding: 5px 10px;
            cursor: pointer;
          } */
            .siteContainer {
                width: 100%;
                display: flex;
                .siteName {
                    flex: 1;
                    text-overflow: ellipsis;
                    flex: 1;
                    /* width: 300px; */
                    overflow: hidden;
                    white-space: nowrap;
                    padding-right: 10px;
                    font-size: 11px;
                }
                .siteType a {
                    font-size: 16px !important;
                    width: 16px !important;
                    color: #ababab;
                }
                .siteType a:last-of-type {
                    margin-right: 0;
                }
                a.site_type.kvs {
                    width: 20px !important;
                    height: 14px !important;
                    background-size: 20px 14px !important;
                }

                &.unavailable {
                    cursor: not-allowed;
                    .siteName,
                    .siteType {
                        color: var(--text-disabled-old);
                    }
                }
            }
        `,
    ],
})
export class OrganizationSiteCardComponent {
    private siteService = inject(SiteService);
    private endDateService = inject(EndDateService);

    @Input() set site(site: Site) {
        this._site = site;
        this.siteTypeIcons = this.siteService.getSiteTypeIcons(site);

        if (site.IsLicenseInactive) {
            this.tooltipTitle = 'Subscription Ended';
            this.tooltipText = this.endDateService.getExpiredTooltip(
                site,
                null,
                this.site.LicenseEndDate
            );
        }
    }
    get site() {
        return this._site;
    }

    _site: Site;
    siteTypeIcons: SiteTypeIcon[];
    tooltipTitle: string;
    tooltipText: string;

    constructor() {}
}
