<div class="file-uploader-container">
    <ng-container *ngIf="type === 'button'">
        <ng-content *ngTemplateOutlet="ngContent"></ng-content>
        <itc-button
            [type]="buttonType"
            [icon]="buttonIcon"
            (onclick)="_handleClick()"
            title="Upload file"
            [size]="size ? size : null"
            [disabled]="disabled"
            (dragenter)="_handleDragEnter($event)"
            (dragleave)="_handleDragLeave()"
            (dragover)="_handleDragOver($event)"
            (drop)="_handleDrop($event)">
            {{ buttonText }}
        </itc-button>
    </ng-container>

    <ng-container *ngIf="type === 'dropdown'">
        <a
            (click)="_handleClick()"
            title="Upload file"
            class="dropdownButton"
            (dragenter)="_handleDragEnter($event)"
            (dragleave)="_handleDragLeave()"
            (dragover)="_handleDragOver($event)"
            (drop)="_handleDrop($event)">
            {{ buttonText }}
        </a>
    </ng-container>

    <ng-container *ngIf="type === 'draganddrop'">
        <div
            class="file-uploader {{ className }}"
            (dragenter)="_handleDragEnter($event)"
            (dragleave)="_handleDragLeave()"
            (dragover)="_handleDragOver($event)"
            (drop)="_handleDrop($event)">
            <ng-content *ngTemplateOutlet="ngContent"></ng-content>

            <div
                class="kaseya-ui-file-uploader {{ className }}"
                [class.file-uploader--focused]="state.focused"
                (click)="_handleClick()">
                <div class="dropMessage" [innerHTML]="dropMessage"></div>
                <div class="file-uploader__max-file-size" *ngIf="allowedFileTypes || showMaxSize">
                    <ng-container *ngIf="allowedFileTypes">
                        Allowed file types: {{ allowedFileTypes }}.
                    </ng-container>
                    <ng-container *ngIf="showMaxSize">
                        Max file size: {{ maxFileSize | fileSize }}
                    </ng-container>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="type === 'photo'">
        <div class="file-uploader photoUploader {{ className }}" (drop)="_handleDrop($event)">
            <div
                class="dropzone"
                (dragenter)="_handleDragEnter($event)"
                (dragleave)="_handleDragLeave()"
                (dragover)="_handleDragOver($event)"
                [class.file-uploader--focused]="state.focused"
                [class.file-uploader--filled]="image"
                [ngStyle]="dropzoneStyles"
                *ngIf="!disabled">
                <div
                    class="preview"
                    [style.background-image]="'url(\'' + image + '\')'"
                    [hidden]="!image"
                    [ngStyle]="dropzoneStyles"></div>
                <div class="dropzone-icon" (click)="_handleClick()">
                    <fa-icon icon="camera"></fa-icon>
                    <itc-spinner
                        *ngIf="isUploading"
                        style="
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        "></itc-spinner>
                </div>
            </div>
            <div
                class="preview dropzone"
                [style.background-image]="'url(\'' + image + '\')'"
                [ngStyle]="dropzoneStyles"
                *ngIf="disabled">
                <div class="dropzone-icon">
                    <fa-icon icon="camera"></fa-icon>
                    <itc-spinner
                        *ngIf="isUploading"
                        style="
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        "></itc-spinner>
                </div>
            </div>

            <div class="kaseya-ui-file-uploader__photo {{ className }}">
                <p class="uploadTitle">
                    {{ uploadTitle }}
                    <ng-container *ngIf="image && !disabled">
                        <span class="removeLink" (click)="removeFile.emit()">Remove</span>
                    </ng-container>
                </p>
                <p class="uploadDirections" *ngIf="!disabled">
                    Drag and drop or
                    <a [routerLink]="null" style="cursor: pointer" (click)="_handleClick()">
                        Select a file
                    </a>
                </p>
                <p class="uploadText">{{ uploadText }}</p>
                <p class="uploadError">{{ uploadError }}</p>
            </div>
        </div>
    </ng-container>

    <input
        [attr.accept]="accept || null"
        type="file"
        style="display: none"
        (change)="_handleInputChange($event)"
        [multiple]="allowMultiple"
        #uploadInput />

    <ng-container *ngIf="showProgress && currentFiles && currentFiles.length">
        <itc-file-list-item
            *ngFor="let file of currentFiles"
            [file]="file"
            (removeFile)="removeFile.emit($event)"></itc-file-list-item>
    </ng-container>
    <ng-container *ngIf="showProgress">
        <itc-file-list-item *ngFor="let file of uploads" [file]="file"></itc-file-list-item>
    </ng-container>
</div>

<ng-template #ngContent><ng-content></ng-content></ng-template>
