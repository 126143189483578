import { Component, Input, OnInit } from '@angular/core';

import { Alert, AlertActionLookup2, AlertStatusLookup, ALERT_STATUSES } from 'app/alerts';

@Component({
    selector: 'sds-todo-type-badge',
    templateUrl: './todo-type-badge.component.html',
    styleUrls: ['./todo-type-badge.component.css'],
})
export class TodoTypeBadgeComponent implements OnInit {
    constructor() {}

    action: any;

    @Input()
    set alert(a: Alert) {
        if (a) {
            if (a.Status == ALERT_STATUSES.TODO.value)
                this.action = AlertActionLookup2(a.ActionType);
            else this.action = AlertStatusLookup(a.Status);
        }
    }

    ngOnInit() {}

    /*getActionColor(alert: Alert) {
    if (alert) {
      let act = alert.ActionType;
      if (act.indexOf('ignore') > -1)
        act = 'ignore';

      let action = AlertActionLookup(act);
      return action.color;
    }
  }

  getActionText(alert: Alert) {
    if (alert) {
      let act = alert.ActionType;
      if (act.indexOf('ignore') > -1)
        act = 'ignore';

      let action = AlertActionLookup(act);
      return action.label;
    }
  }*/
}
