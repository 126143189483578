import { Component, OnInit, OnDestroy } from '@angular/core';
import { LauncherService } from './launcher.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'app/core/auth';
import { Subscription } from 'rxjs';
@Component({
    templateUrl: './launcher.component.html',
    styleUrls: ['./launcher.component.css'],
})
export class LauncherComponent implements OnInit, OnDestroy {
    pSub: Subscription;

    constructor(
        private route: ActivatedRoute,
        private launcherService: LauncherService,
        private authService: AuthService
    ) {}

    ngOnInit() {
        // log out locally if they're already logged in and click nav menu button
        this.authService.logoutWithoutRedirect();
        console.log('launcher');

        this.pSub = this.route.queryParams.subscribe((params) => {
            if (params['login_hint']) {
                console.log('launched with hint: ' + params['login_hint']);
                this.launcherService.launchWithLoginHint(params['login_hint']);
            } else {
                this.launcherService.launch();
            }
        });
    }

    ngOnDestroy() {
        this.pSub.unsubscribe();
    }
}
