<h2>All Employees Policy Acceptance Status Printable Report</h2>

<h5>All Employees Policy Acceptance - Status</h5>
<div
    class="testHeaderParent"
    style="border-bottom: 2px solid #d5d5d5; padding-bottom: 12px; width: 100%">
    <div *ngFor="let statusData of allPoliciesInfo.StatusData; let i = index">
        <cm-unit-donut
            [status]="statusData.Status"
            [label]="statusData.Label"
            [numComplete]="statusData.numComplete"
            [percentComplete]="statusData.percentComplete"></cm-unit-donut>
    </div>
</div>

<h5>All Employees Policy Acceptance - Results Summary</h5>
<div calss="panel1Head" style="display: table; padding-left: 5px">
    <ng-container *ngFor="let policyAcceptance of allPoliciesInfo.AllPoliciesData; let i = index">
        <div
            class="ui card"
            style="
                border-radius: 12px;
                cursor: pointer;
                padding: 24px;
                margin: 15px 15px 15px 0px;
                display: inline-block;
            ">
            <sds-tracker-donut-component
                [cardMeta]="policyAcceptance.PolicyName"
                [grpNames]="policyAcceptance.Groups"
                [chartData]="policyAcceptance.ChartData"
                [centerText]="policyAcceptance.PercentageComplete"
                [cardId]="policyAcceptance.PolicyId"
                [centerTextSmall]="'Completed'"
                [legendPosition]="'right'"
                (cardClicked)="
                    policyCardClicked(policyAcceptance.PolicyId)
                "></sds-tracker-donut-component>
        </div>
    </ng-container>
</div>
