import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { RiskModalInfo } from './risk-modal.model';

@Component({
    selector: 'sds-risk-modal',
    styleUrls: ['./risk-modal.component.scss'],
    templateUrl: './risk-modal.component.html',
})
export class RiskModalComponent implements OnInit {
    constructor() {}

    riskModalInfo: RiskModalInfo;
    @Output() onRiskInfoModalClosed: EventEmitter<boolean> = new EventEmitter();
    @Output() onViewResults: EventEmitter<boolean> = new EventEmitter();

    @ViewChild('riskModal', { static: true }) riskModal: any;

    ngOnInit() {}

    showRiskModal(obj) {
        this.riskModalInfo = obj;
        this.riskModalInfo.SeverityDisplay = this.calculateDisplaySeverity(
            this.riskModalInfo.Severity
        );
        this.riskModalInfo.RiskDisplay = this.calculateDisplaySeverity(this.riskModalInfo.Score);
        this.riskModalInfo.ProbabilityDisplay = this.calculateDisplaySeverity(
            this.riskModalInfo.Probability
        );
        this.riskModal.show();
    }

    calculateDisplaySeverity(num) {
        if (num > 70) {
            return 'high';
        } else if (num > 50) {
            return 'medium';
        } else {
            return 'low';
        }
    }

    riskInfoClosed() {
        this.riskModal.hide();
        this.onRiskInfoModalClosed.emit(true);
    }
}
