export class Tags {
    public SiteId: number;
    public AccountId: string;
    public Name: string;
    public AppliesTo: string;
    public AppliesToType: string;
    public Type: number;
    public WhatDesc: string;
    public WhyDesc: string;

    public constructor(init?: Partial<Tags>) {
        Object.assign(this, init);
    }
}
