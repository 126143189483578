import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { VendportalService } from '../vendportal.service';
import { AssignedAssessment } from '../data';

@Component({
    selector: 'sds-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
    constructor(private service: VendportalService, private router: Router) {}

    assessments: AssignedAssessment[];

    ngOnInit() {
        this.service.getAssignedAssessments().subscribe(
            (ret) => (this.assessments = ret),
            (err) => console.log(err)
        );
    }

    goAssessment(a: AssignedAssessment) {
        this.router.navigateByUrl('/vendor/assessment/' + a.Id).then((ok) => console.log(ok));
    }
}
