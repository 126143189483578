import { AlertButton } from './alertbutton.model';

export class Alert {
    public Guid: string;
    public Created: string;
    public AlertType: string;
    public TAC: string;
    public Message: string;
    public Recommendation: string;
    public AlertData: string;
    public SiteId: number;
    public SiteName: string;

    public AccountId: string;
    public Status: string;
    public Notes: string;
    public ActionType: string;
    public ApplianceId?: string;

    public CreatedBy?: string;
    public DataObject?: any;

    public isMine?: boolean;

    public PublicSop?: string;
    public PrivateSop?: string;
    public SopTitle?: string;
    public CompletionType?: string;

    public Buttons?: AlertButton[];
}
