import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NavService } from 'app/core/nav/nav.service';
import { Breadcrumb } from 'app/shared/breadcrumbs/breadcrumb.model';
import { Organization } from '../organization.model';
import { OrganizationsService } from '../organizations.service';
import { MessageService } from 'app/core/message.service';
import { UiService } from 'app/core/ui.service';
import { SITE, PING_SITE } from 'app/sites/shared/constants';
import { Site } from 'app/sites/shared/site.model';
import { Subject, Subscription, takeUntil, map, mergeMap, combineLatest, of } from 'rxjs';

@Component({
    templateUrl: './org-discovery-agents.component.html',
    styles: [],
})
export class OrgDiscoveryAgentsComponent implements OnInit, OnDestroy {
    organization: Organization;
    title: string;
    breadcrumbs: Breadcrumb[];
    routeSub: Subscription;
    ngUnsubscribe$: Subject<OrgDiscoveryAgentsComponent> = new Subject();

    constructor(
        private route: ActivatedRoute,
        private navService: NavService,
        private organizationsService: OrganizationsService,
        private router: Router,
        private messageService: MessageService,
        private uiService: UiService
    ) {}

    ngOnInit() {
        this.title = 'Discovery Agents';
        this.uiService.setTitle(this.title);
        if (this.router.url.indexOf('/site/') > -1) {
            this.messageService
                .on(SITE)
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe((site: Site) => {
                    if (!site) return;

                    this.breadcrumbs = [
                        { path: '../../..', text: 'Compliance Manager GRC' },
                        { path: '../..', text: 'Data Collection' },
                        { path: '..', text: 'Discovery Agent Scans', link: true },
                        { path: '.', text: 'Manage Agents' },
                    ];
                    this.organizationsService.getOrganizations().then((orgs) => {
                        this.organization = orgs?.find((o) => o.Name === site.Organization);
                    });
                });
            this.messageService.broadcast(PING_SITE);
        } else {
            this.organizationsService
                .getOrganizationsObs()
                .pipe(
                    mergeMap((orgs: Organization[]) => {
                        return combineLatest([of(orgs), this.route.params]);
                    }),
                    map(([orgs, params]: [Organization[], Params]) => {
                        return orgs?.find((o) => o.Name === decodeURIComponent(params.organization));
                    }),
                    takeUntil(this.ngUnsubscribe$)
                )
                .subscribe((org) => {
                    this.organization = org;
                    this.navService.setHeaderText(this.organization?.Name);
                    this.breadcrumbs = [
                        { path: '..', text: this.organization?.Name, link: true },
                        { path: '.', text: 'Discovery Agents' },
                    ];
                });
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next(void 0);
        this.ngUnsubscribe$.complete();
    }
}
