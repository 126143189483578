import { Component, OnInit, ViewChild } from '@angular/core';
import {
    AbstractControl,
    UntypedFormBuilder,
    UntypedFormControl,
    ValidationErrors,
    Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { FormService } from 'app/core/form.service';
import { AuthService } from 'app/core/auth';
import { MessageService } from 'app/core/message.service';
import { UiService } from 'app/core/ui.service';
import { TableSorting } from 'app/shared/sortable-table/sortable-table.component';
import { StandardsService } from 'app/sites/shared/standards/standards.service';
import { Standard } from 'app/sites/shared/standards/standard.model';
import { Requirement } from 'app/sites/shared/standards/requirement.model';
import { I18N } from 'app/sites/shared/standards/i18n.model';
import { ReferenceUrl } from 'app/sites/shared/standards/referenceurl.model';
import { SiteService } from 'app/sites/shared/site.service';
import { NotificationService } from '../../../shared/itc/notification/notification.service';
import { ControlsService } from 'app/sites/shared/controls/controls.service';
import { Control } from 'app/sites/shared/controls/control.model';

@Component({
    selector: 'standards-library',
    templateUrl: './standards-library.component.html',
    styleUrls: ['./standards-library.component.css'],
})
export class StandardsLibraryComponent implements OnInit {
    constructor(
        private authService: AuthService,
        private messageService: MessageService,
        private formService: FormService,
        private notificationService: NotificationService,
        private uiService: UiService,
        private standardsService: StandardsService,
        private siteService: SiteService,
        private fb: UntypedFormBuilder,
        private router: Router,
        private controlService: ControlsService
    ) {}

    @ViewChild('addStandard', { static: true }) addStandard;
    @ViewChild('deleteStandard', { static: true }) deleteStandard;
    @ViewChild('cloneStandard', { static: true }) cloneStandard;

    // removed, changing to react forms, haven't changed req or refurl yet
    // stndNameCtrl: FormControl = new FormControl();
    // stndVarNameCtrl: FormControl = new FormControl();
    // stndRevisonCtrl: FormControl = new FormControl();
    // stndPurposeCtrl: FormControl = new FormControl();
    // stndScopeCtrl: FormControl = new FormControl();

    stndCrosswalkCtrl: UntypedFormControl = new UntypedFormControl();

    clonedStandardNameCtrl = new UntypedFormControl('', [
        Validators.required,
        this.uniqueStandardName.bind(this),
    ]);

    reqIdCtrl: UntypedFormControl = new UntypedFormControl();
    reqSDescriptionCtrl: UntypedFormControl = new UntypedFormControl();
    reqLDescriptionCtrl: UntypedFormControl = new UntypedFormControl();
    reqGuidanceCtrl: UntypedFormControl = new UntypedFormControl();

    refLabelCtrl: UntypedFormControl = new UntypedFormControl();
    refUrlCtrl: UntypedFormControl = new UntypedFormControl();

    standardsRequirementEditRef: Standard[] = [];
    stage: number = 1;
    loadingComplete: boolean = false;
    saving: boolean = false;
    standards: Standard[] = [];
    filteredStandards: Standard[] = [];
    sortedStandards: Standard[] = [];
    pagedStandards: Standard[] = [];
    controls: any[] = [];
    filteredControls: any[] = [];
    pagedControls: any[] = [];
    sortedControls: any[] = [];
    waitControls: boolean = false;
    pageSize: number = 20;
    sizes: number[] = [10, 20, 50, 100];
    pageNumber: number = 0;
    initPage: boolean;
    hideSystemStandards: false;
    showsSelectControls: boolean = false;
    previousControlId: number = -1;
    reqpageSize: number = 20;
    reqpageNumber: number = 0;
    reqinitPage: boolean;
    selectedControl: any;

    addStandardsControl = {
        pageSize: 10,
        pageNumber: 0,
        lastPage: 0,
    };
    isEditMode: boolean = false;
    isViewOnlyMode = false;
    modalTitle: string = '';
    oldStandard: Standard;
    validTitlesForModal: string[] = [
        'Add Standard',
        'Add Requirement',
        'Edit Standard',
        'Edit Requirement',
        'View Standard',
    ];
    deletedRequirement: Requirement[] = [];
    controlRequirements: ControlsRequirement[] = [];
    filteredStandardControl: Requirement[] = [];
    filteredStandardControlPageSize: number;
    filteredStandardControlPageIndex: number;
    reqEditIndex: number = -1; //since new requirements are pushed with id = -1, that can't be relied upon for editing new reqs before saving standards, we use this too
    selectedStandard = new Standard();
    selectedRequirement: Requirement;
    selectedControls: Control[] = [];
    // clonedStandardName: string;
    // systemStandardNames: string[] = [];

    standardsFilterText: string = '';
    searchControlValue: string = '';
    checkAllControl: boolean = false;
    showSelected: boolean = false;

    breadcrumbs = [
        { path: '../..', text: 'Admin' },
        { path: '..', text: 'Compliance Manager GRC' },
        { path: '.', text: 'Standards Library' },
    ];

    languageCtrl: UntypedFormControl = new UntypedFormControl();
    languageMap: any = [
        { value: 'nl-NL', label: 'Dutch' },
        { value: 'en-US', label: 'English (US)' },
        { value: 'fr-FR', label: 'French' },
        { value: 'fr-CA', label: 'French (Canada)' },
        { value: 'de-DE', label: 'German' },
        { value: 'it-IT', label: 'Italian' },
        { value: 'pt-BR', label: 'Portuguese (Brasil)' },
        { value: 'es-ES', label: 'Spanish' },
    ];
    culture: string = 'en-US';
    cloning: boolean;

    standardsForm = this.fb.group(
        {
            standardLanguage: ['en-US'],
            standardName: ['', Validators.required],
            standardVariant: ['', Validators.required],
            standardRevision: ['', Validators.required],
            standardPurpose: [''],
            standardScope: [''],
        },
        {
            validator: [this.uniqueStandard.bind(this)],
        }
    );

    ngOnInit() {
        this.uiService.setTitle('Standards Library');
        this.stage = 1;

        this.loadControls();
        this.loadStandards();
    }

    loadStandards() {
        this.standardsService.getSystemStandards().then((resSystem) => {
            this.standards = [];
            this.standards.push(...resSystem);

            this.standardsService.getAccountStandards().then((resAccount) => {
                this.standards.push(...resAccount);
                this.filterStandards();
                this.loadingComplete = true;
            });
        });
    }

    async loadControls() {
        const existingControlIds = [];
        const siteControlIds = [];
        this.controls = [];

        // Get System Controls
        const resSystem = await this.controlService.getSystemControlsForIso(this.culture);
        resSystem.forEach((sc) => {
            existingControlIds.push(sc.Id);
        });
        this.controls.push(...resSystem);

        // Get account or global controls.
        const resAccount = await this.controlService.getAccountControlsForIso(this.culture);
        resAccount.forEach((ac) => {
            if (!siteControlIds.includes(ac.ControlId) && !existingControlIds.includes(ac.Id)) {
                this.controls.push(ac);
                existingControlIds.push(ac.Id);
            }
        });

        this.controls.forEach((control) => {
            control.checked = false;
            control.Requirements = [];
        });

        this.filterControls();
    }

    filterControls(search: boolean = false) {
        let val: string = this.searchControlValue.toLowerCase().trim();
        this.filteredControls = [];

        if (search) {
            this.controls.forEach((control) => {
                if (
                    control.ControlId.toLowerCase().includes(val) ||
                    control.ShortDesc.LocalizedText[this.culture].toLowerCase().includes(val)
                ) {
                    this.filteredControls.push(control);
                }
            });
        } else {
            this.filteredControls = this.controls;
        }
        this.sortControls();
    }

    startDelete(standard: Standard) {
        this.selectedStandard = JSON.parse(JSON.stringify(standard));
        this.deleteStandard.show();
    }

    startStandardEditor(standard: Standard, isSystem: boolean) {
        // check to make sure user is still logged in to fi Mike Semmells problem.
        if (!this.authService.isAuth()) {
            this.router.navigateByUrl('/login');
            return;
        }
        this.culture = 'en-US';
        this.saving = false;
        this.selectedControls = [];
        if (standard == null) {
            this.selectedStandard = new Standard();

            // this.stage = 1;
            this.isEditMode = false;
            this.isViewOnlyMode = false;
            this.modalTitle = this.validTitlesForModal[0];
            // this.addStandard.show({ closable: false });
            // this.stndNameCtrl.setValue('');
            // this.stndVarNameCtrl.setValue('');
            // this.stndRevisonCtrl.setValue('');
        } else {
            this.waitControls = true;
            this.oldStandard = standard;
            this.selectedStandard = JSON.parse(JSON.stringify(standard));
            this.isEditMode = true;
            this.isViewOnlyMode = isSystem;
            // this.stndNameCtrl.setValue(this.selectedStandard.Name);
            // this.stndVarNameCtrl.setValue(this.selectedStandard.Variant);
            // this.stndRevisonCtrl.setValue(this.selectedStandard.Revision);
            // this.stage = 1;
            if (!this.selectedStandard.IsSystem) {
                this.getControlsByRequirement();
                this.addStandardControlsSortTable();
            }
            this.modalTitle = this.isViewOnlyMode
                ? this.validTitlesForModal[4]
                : this.validTitlesForModal[2];
            // this.addStandard.show({ closable: false });
        }

        this.stage = 1;

        if (!this.selectedStandard.Purpose) {
            this.selectedStandard.Purpose = new I18N();
        }

        if (!this.selectedStandard.Scope) {
            this.selectedStandard.Scope = new I18N();
        }

        let purposeCtrlText =
            this.selectedStandard.Purpose &&
            this.selectedStandard.Purpose.LocalizedText[this.culture]
                ? this.selectedStandard.Purpose.LocalizedText[this.culture]
                : '';
        let scopeCtrlText =
            this.selectedStandard.Scope && this.selectedStandard.Scope.LocalizedText[this.culture]
                ? this.selectedStandard.Scope.LocalizedText[this.culture]
                : '';

        this.standardsForm.setValue({
            standardLanguage: this.culture,
            standardName: this.selectedStandard.Name,
            standardVariant: this.selectedStandard.Variant,
            standardRevision: this.selectedStandard.Revision,
            standardPurpose: purposeCtrlText,
            standardScope: scopeCtrlText,
        });
        // this.stndNameCtrl.setValue(this.selectedStandard.Name);
        // this.stndVarNameCtrl.setValue(this.selectedStandard.Variant);
        // this.stndRevisonCtrl.setValue(this.selectedStandard.Revision);
        // let purposeCtrlText = (this.selectedStandard.Purpose.LocalizedText[this.culture]) ? this.selectedStandard.Purpose.LocalizedText[this.culture] : '';
        // this.stndPurposeCtrl.setValue(purposeCtrlText);
        // let scopeCtrlText = (this.selectedStandard.Scope.LocalizedText[this.culture]) ? this.selectedStandard.Scope.LocalizedText[this.culture] : '';
        //   this.stndScopeCtrl.setValue(scopeCtrlText);
        // this.languageCtrl.setValue(this.culture);
        // console.log("this.seelctedStandard", this.selectedStandard);
        this.addStandard.show({ closable: false });
    }

    getControlsByRequirement() {
        const allApiCall = this.selectedStandard.Requirements.map((requirement) => {
            return this.getControlByRequirement(requirement);
        });

        Promise.all(allApiCall).then((values) => {
            this.waitControls = false;
        });
    }

    getControlByRequirement(requirement: Requirement) {
        return new Promise((resolve, reject) => {
            this.controlService
                .getControlsByRequirement(requirement.Id)
                .then((control) => {
                    if (control?.length > 0) {
                        this.controlRequirements.push({
                            requirement: requirement,
                            addedControls: JSON.parse(JSON.stringify(control)),
                            previousStateControls: JSON.parse(JSON.stringify(control)),
                            deleteControls: [],
                        });
                    }
                    resolve(control);
                })
                .then((err) => {
                    reject(err);
                });
        });
    }

    closeStandardEditor() {
        this.addStandard.hide();
        this.deleteStandard.hide();
        this.standardsForm.markAsPristine();
        this.deletedRequirement = [];
        this.selectedControls = [];
        this.controlRequirements = [];
        this.standardsFilterText = '';
        this.resetCheckControls();
    }

    modalCancel() {
        if (this.stage == 1) {
            if (this.standardsForm.dirty) {
                this.stage = 3;
                return;
            } else {
                this.closeStandardEditor();
            }
            // if (!this.isEditMode) {
            //   if (this.stndNameCtrl.value != '' ||
            //     this.stndVarNameCtrl.value != '' ||
            //     this.stndRevisonCtrl.value != '' ||
            //     this.selectedStandard.Requirements.length > 0) this.stage = 3
            //   else this.closeStandardEditor();
            // } else {
            //   if (this.oldStandard.Requirements.length != this.selectedStandard.Requirements.length) {
            //     this.stage = 3;
            //     return;
            //   }
            //   if (this.stndNameCtrl.dirty ||
            //     this.stndVarNameCtrl.dirty ||
            //     this.stndRevisonCtrl.dirty ||
            //     JSON.stringify(this.oldStandard.Requirements) != JSON.stringify(this.selectedStandard.Requirements)) {
            //     this.stage = 3;
            //   } else this.closeStandardEditor();
            // }
        } else {
            this.addStandardsControlPageChange();
            if (this.isEditMode) {
                this.modalTitle = this.validTitlesForModal[2];
            } else {
                this.modalTitle = this.validTitlesForModal[0];
            }
            this.culture = 'en-US';
            this.stage--;
            this.selectedControls = [];
            this.resetCheckControls();
        }
    }

    clickedNo() {
        this.stage = 1;
    }

    clickedYes() {
        this.closeStandardEditor();
    }

    addStandardControlsSortTable(sorting?: TableSorting) {
        if (!sorting) {
            this.selectedStandard.Requirements = this.selectedStandard.Requirements.sort((a, b) => {
                if (a.Order > b.Order) return 1;
                else return -1;
            });
        } else {
            switch (sorting.sortColumn) {
                case 'Order': {
                    this.selectedStandard.Requirements = this.selectedStandard.Requirements.sort(
                        (a, b) => {
                            if (a.Order > b.Order) return 1;
                            else return -1;
                        }
                    );
                    break;
                }
                case 'Requirement': {
                    this.selectedStandard.Requirements = this.selectedStandard.Requirements.sort(
                        (a, b) =>
                            a.RequirementId.toLowerCase().localeCompare(
                                b.RequirementId.toLowerCase()
                            )
                    );
                    break;
                }
                case 'Description': {
                    this.selectedStandard.Requirements = this.selectedStandard.Requirements.sort(
                        (a, b) =>
                            a.ShortDesc.LocalizedText[this.culture]
                                .toLowerCase()
                                .localeCompare(
                                    b.ShortDesc.LocalizedText[this.culture].toLowerCase()
                                )
                    );
                    break;
                }
            }

            if (sorting.sortDirection == 'desc') {
                this.selectedStandard.Requirements = this.selectedStandard.Requirements.reverse();
            }
        }

        this.addStandardsControlPageChange(this.addStandardsControl);
    }

    addStandardsControlPageChange(ev?: any) {
        if (ev) {
            this.addStandardsControl.pageNumber = ev.pageNumber;
            this.addStandardsControl.lastPage = ev.lastPage;
            this.addStandardsControl.pageSize = ev.pageSize;
            var numberOfRecordsDone = ev.pageNumber * ev.pageSize;

            this.filteredStandardControl = this.selectedStandard.Requirements.slice(
                numberOfRecordsDone,
                numberOfRecordsDone + ev.pageSize
            );
        } else {
            this.filteredStandardControl = this.selectedStandard.Requirements;
        }
    }

    sortControls(sorting?: TableSorting) {
        if (!sorting) {
            this.sortedControls = this.filteredControls.sort((a, b) =>
                this.fixControlId(a.ControlId)
                    .toLowerCase()
                    .localeCompare(this.fixControlId(b.ControlId).toLowerCase())
            );
        } else {
            switch (sorting.sortColumn) {
                case 'ControlId':
                    this.sortedControls = this.filteredControls.sort((a, b) =>
                        this.fixControlId(a.ControlId)
                            .toLowerCase()
                            .localeCompare(this.fixControlId(b.ControlId).toLowerCase())
                    );
                    break;
                case 'Description':
                    this.sortedControls = this.filteredControls.sort((a, b) =>
                        a.ShortDesc.LocalizedText[this.culture]
                            .toLowerCase()
                            .localeCompare(b.ShortDesc.LocalizedText[this.culture].toLowerCase())
                    );
                    break;
                default:
                    this.sortedControls = this.filteredControls.sort((a, b) =>
                        this.fixControlId(a.ControlId)
                            .toLowerCase()
                            .localeCompare(this.fixControlId(b.ControlId).toLowerCase())
                    );
                    break;
            }

            if (sorting.sortDirection == 'desc') {
                //descending
                this.sortedControls.reverse();
            }

            //check if selected controls is available
            if (this.showsSelectControls) {
                this.selectOnlyCheckedControls();
            }
        }

        this.pageControls();
    }

    controlChecked(control: any, event: boolean) {
        if (event) {
            if (!this.selectedControls.some((p) => p.Id === control.Id)) {
                this.selectedControls.push(control);
            }
        } else {
            this.selectedControls = this.selectedControls.filter((p) => p.Id !== control.Id);
        }
        control.checked = event;
        this.checkAllControls();
    }

    controlsCheckedAll(event: boolean) {
        this.checkAllControl = event;
        if (event) {
            this.pagedControls.forEach((control) => {
                if (!this.selectedControls.some((p) => p.Id === control.Id)) {
                    this.selectedControls.push(control);
                }
            });
        } else {
            this.pagedControls.forEach((control) => {
                this.selectedControls = this.selectedControls.filter((p) => p.Id !== control.Id);
            });
        }
        this.pagedControls.forEach((p) => (p.checked = event));
    }

    controlUncheckAll() {
        this.controls.forEach((p) => (p.checked = false));
    }

    fixControlId(id: string) {
        let els: string[] = id.split('.');
        for (var i = 0; i < els.length; i++) {
            els[i] = els[i].padStart(3, '0');
        }
        return els.join('.');
    }

    pageControls(ev?: any) {
        if (ev) {
            if (ev.pageNumber != this.reqpageNumber) {
                this.reqpageNumber = ev.pageNumber;
            }

            if (ev.pageSize != this.reqpageSize && !this.reqinitPage) {
                this.reqpageSize = ev.pageSize;
                this.reqpageNumber = 0;
            }

            this.reqinitPage = false;
        }
        this.pagedControls = this.sortedControls.slice(
            this.reqpageNumber * this.reqpageSize,
            this.reqpageNumber * this.reqpageSize + this.reqpageSize
        );

        this.checkAllControls();
    }

    checkAllControls() {
        this.pagedControls.every((p) => p.checked)
            ? (this.checkAllControl = true)
            : (this.checkAllControl = false);
    }

    saveSelectedStandard() {
        this.saving = true;
        this.selectedStandard.Name = this.standardsForm.get('standardName').value;
        this.selectedStandard.Variant = this.standardsForm.get('standardVariant').value;
        this.selectedStandard.Revision = this.standardsForm.get('standardRevision').value;
        this.selectedStandard.Purpose.LocalizedText[this.culture] =
            this.standardsForm.get('standardPurpose').value;
        this.selectedStandard.Scope.LocalizedText[this.culture] =
            this.standardsForm.get('standardScope').value;

        let elementExists = [];
        // elementExists = this.filteredStandards.filter(r => r.Name.toLowerCase().trim() == this.selectedStandard.Name.toLowerCase().trim()
        //   && r.Variant.toLowerCase().trim() === this.selectedStandard.Variant.toLowerCase().trim() &&
        //   r.Revision.toLowerCase().trim() === this.selectedStandard.Revision.toLowerCase().trim())
        // if (!this.isEditMode ? this.standardVariantExists() : elementExists.length > 1 ) {
        //   this.notificationService.toast.error('Error', 'A standard already exists with the same name, please choose a differnt name.')
        //   this.saving = false;
        //   return
        // }

        let valid = this.formService.validate('standardsForm');
        if (valid) {
            if (this.selectedStandard.Id == -1) {
                this.standardsService.addStandard(this.selectedStandard).then((res) => {
                    this.upsertControl(res.Requirements);
                    this.standardsService.addAccountStandard(res.Id).then(() => {
                        this.standards.push(res);
                        this.filterStandards();
                        this.closeStandardEditor();
                        this.notificationService.toast.success(
                            'Success',
                            'Standard Successfully Created'
                        );
                    });
                });
            } else {
                this.standardsService.updateStandard(this.selectedStandard).then((res) => {
                    this.removeRequirementContols();
                    this.upsertControl(res.Requirements);

                    for (let i = 0; i < this.standards.length; i++) {
                        if (this.standards[i].Id == res.Id) {
                            this.standards[i] = res;
                        }
                    }
                    this.filterStandards();
                    this.closeStandardEditor();
                    this.notificationService.toast.success(
                        'Success',
                        'Standard Successfully Updated'
                    );
                });
            }
        } else {
            this.notificationService.toast.error('Error', 'Please fill required fields.');
            this.saving = false;
        }
    }
    removeRequirementContols() {
        this.deletedRequirement.forEach((requirement) => {
            let controlReq = this.controlRequirements.find(
                (p) => p.requirement.Id === requirement.Id
            );
            if (controlReq) {
                this.controlService.deleteControlsRequirement(
                    requirement,
                    controlReq.previousStateControls
                );
            }
        });
        this.deletedRequirement = [];
    }

    upsertControl(requirements: Requirement[]) {
        this.controlRequirements.forEach((element) => {
            let requirement = requirements.find(
                (p) => p.RequirementId === element.requirement.RequirementId
            );
            if (requirement) {
                element.requirement = requirement;
                if (this.isEditMode) {
                    this.updateListsControls(element);
                }
                this.controlService.upsertControlRequirement(element);
            }
        });

        this.controlRequirements = [];
    }

    updateListsControls(controlRequirement: ControlsRequirement) {
        controlRequirement.deleteControls = controlRequirement.previousStateControls.filter(
            (p) => !controlRequirement.addedControls.some((item) => item.Id === p.Id)
        );
    }

    deleteSelectedStandard() {
        this.standardsService
            .deleteStandard(this.selectedStandard.Id)
            .then(() => {
                for (let i = 0; i < this.standards.length; i++) {
                    if (this.standards[i].Id == this.selectedStandard.Id) {
                        this.standards.splice(i, 1);
                        this.filterStandards();
                    }
                }
                this.closeStandardEditor();
            })
            .catch((err) => {
                this.notificationService.toast.error(
                    'Error',
                    'An error occured while attempting to delete.'
                );
            });
    }

    startClone(standard: Standard) {
        this.selectedStandard = standard;

        let tempCloneName = this.selectedStandard.Name;

        let count = 0;
        while (true) {
            tempCloneName += ' (copy)';
            let i = this.pagedStandards.findIndex((e) => {
                return e.Name == tempCloneName;
            });
            if (i == -1) break;
            else count++;
        }
        this.clonedStandardNameCtrl.setValue(tempCloneName);

        this.cloneStandard.show({ closable: false });
    }

    cloneSelectedStandard() {
        // if (this.pagedStandards.findIndex((e) => {
        //   return e.Name == this.clonedStandardName
        // }) > -1) {
        //   var temp = this.clonedStandardName
        //   let count = 0;
        //   while (true) {
        //     this.clonedStandardName = temp + ((count == 0) ? ' (copy)' : ' (copy -' + count + ')')
        //     let i = this.pagedStandards.findIndex((e) => {
        //       return e.Name == this.clonedStandardName
        //     })
        //     if (i == -1) break;
        //     else count++
        //   }
        // }
        this.cloning = true;
        this.standardsService
            .cloneStandard(this.selectedStandard.Id, this.clonedStandardNameCtrl.value)
            .then((res) => {
                this.loadStandards();
                this.notificationService.toast.success('Success', 'Standard Successfully Cloned');
                this.cloneStandard.hide();
                this.cloning = false;
            });
    }

    closeStandard() {
        this.cloneStandard.hide();
    }

    setRequirement(requirement: Requirement, index: number) {
        if (!this.authService.isAuth()) {
            this.router.navigateByUrl('/login');
            return;
        }

        this.scrollModalUp();
        this.controlUncheckAll();

        if (requirement == null) {
            this.reqEditIndex = -1;
            this.selectedRequirement = new Requirement();
            this.selectedRequirement.Order =
                this.selectedStandard.Requirements.length == 0
                    ? 1
                    : this.selectedStandard.Requirements[
                          this.selectedStandard.Requirements.length - 1
                      ].Order + 1;
            this.modalTitle = this.validTitlesForModal[1];
        } else {
            this.reqEditIndex = index;
            this.selectedRequirement = JSON.parse(JSON.stringify(requirement));
            this.modalTitle = this.validTitlesForModal[3];
        }
        this.setControls();
        if (!this.selectedRequirement.ShortDesc.LocalizedText[this.culture]) {
            this.selectedRequirement.ShortDesc.LocalizedText[this.culture] = '';
        }
        if (!this.selectedRequirement.LongDesc.LocalizedText[this.culture]) {
            this.selectedRequirement.LongDesc.LocalizedText[this.culture] = '';
        }
        if (!this.selectedRequirement.Guidance.LocalizedText[this.culture]) {
            this.selectedRequirement.Guidance.LocalizedText[this.culture] = '';
        }

        this.reqIdCtrl.setValue(this.selectedRequirement.RequirementId);
        this.reqSDescriptionCtrl.setValue(
            this.selectedRequirement.ShortDesc.LocalizedText[this.culture]
        );
        this.reqLDescriptionCtrl.setValue(
            this.selectedRequirement.LongDesc.LocalizedText[this.culture]
        );
        this.reqGuidanceCtrl.setValue(
            this.selectedRequirement.Guidance.LocalizedText[this.culture]
        );
        this.languageCtrl.setValue(this.culture);
        this.refLabelCtrl.setValue('');
        this.refUrlCtrl.setValue('');
        this.stage = 2;
        this.reqpageNumber = 0;
    }

    setControls() {
        this.selectedControls = [];

        if (this.controlRequirements.length > 0) {
            let requirement = this.controlRequirements.find(
                (p) => p.requirement.RequirementId === this.selectedRequirement.RequirementId
            );

            if (requirement) {
                this.selectedControls = JSON.parse(JSON.stringify(requirement.addedControls));
            }
        }
        this.filterControls();
        this.resetCheckControls(true);
    }

    resetCheckControls(showModal: boolean = false) {
        this.sortedControls.forEach((element) => {
            let exist = this.selectedControls.find((x) => x.Id === element.Id);
            if (exist) {
                element.checked = true;
            } else {
                element.checked = false;
            }
        });

        if (showModal && this.isEditMode) {
            this.stage = 2;
        }
    }

    saveSelectedRequirement() {
        this.selectedRequirement.RequirementId = this.reqIdCtrl.value;
        this.selectedRequirement.ShortDesc.LocalizedText[this.culture] =
            this.reqSDescriptionCtrl.value;
        this.selectedRequirement.LongDesc.LocalizedText[this.culture] =
            this.reqLDescriptionCtrl.value;
        this.selectedRequirement.Guidance.LocalizedText[this.culture] = this.reqGuidanceCtrl.value;
        let valid = this.formService.validate('requirementsForm');
        if (valid) {
            let findReqId = this.selectedStandard.Requirements.findIndex((e) => {
                return e.RequirementId == this.selectedRequirement.RequirementId;
            });

            if (this.reqEditIndex == -1 && findReqId > -1) {
                this.notificationService.toast.error(
                    'Error',
                    'A requirement with the same ID already exists. Please modify the Requirement ID'
                );
                this.saving = false;
                return;
            }
            if (this.reqEditIndex == -1) {
                //insert the controls realated to the requirement
                this.saveSelectedControls();
                this.selectedStandard.Requirements.push(
                    JSON.parse(JSON.stringify(this.selectedRequirement))
                );
                if (this.isEditMode) {
                    this.modalTitle = this.validTitlesForModal[2];
                } else {
                    this.modalTitle = this.validTitlesForModal[0];
                }
                this.stage = 1;
            } else {
                let reqEditUnpagedIndex =
                    this.reqEditIndex +
                    this.filteredStandardControlPageIndex * this.filteredStandardControlPageSize;
                if (findReqId != reqEditUnpagedIndex && findReqId > -1) {
                    this.notificationService.toast.error(
                        'Error',
                        'A requirement with the same ID already exists. Please modify the Requirement ID'
                    );
                    this.saving = false;
                    return;
                }
                this.saveSelectedControls();
                this.selectedStandard.Requirements[reqEditUnpagedIndex] = JSON.parse(
                    JSON.stringify(this.selectedRequirement)
                );
                if (this.isEditMode) {
                    this.modalTitle = this.validTitlesForModal[2];
                } else {
                    this.modalTitle = this.validTitlesForModal[0];
                }
                this.stage = 1;
            }
        } else {
            this.notificationService.toast.error('Error', 'Please fill required fields.');
            this.saving = false;
        }
        this.culture = 'en-US';
        this.standardsForm.markAsDirty();
    }

    saveSelectedControls() {
        let requirement = this.controlRequirements.find(
            (p) => p.requirement.RequirementId === this.selectedRequirement.RequirementId
        );

        if (requirement) {
            requirement.addedControls = [];
            requirement.addedControls = this.selectedControls;
        } else {
            this.controlRequirements.push({
                requirement: this.selectedRequirement,
                addedControls: this.selectedControls,
                previousStateControls: [],
                deleteControls: [],
            });
        }
    }

    deleteRequirement(requirement: Requirement, index: number) {
        let deleteIndex = index;
        if (
            confirm(
                'Delete ' +
                    requirement.RequirementId +
                    '? This action will only complete upon saving the standard.'
            )
        ) {
            this.selectedStandard.Requirements.splice(deleteIndex, 1);
            this.addStandardsControlPageChange();
            if (requirement.Id !== -1) {
                this.deletedRequirement.push(requirement);
            }
            this.standardsForm.markAsDirty();
        }
    }

    changeLanguage(event, type: string) {
        if (type == 'standard') {
            // save current items for culture
            this.selectedStandard.Purpose.LocalizedText[this.culture] =
                this.standardsForm.get('standardPurpose').value;
            this.selectedStandard.Scope.LocalizedText[this.culture] =
                this.standardsForm.get('standardScope').value;

            // update culture and set value
            this.culture = event;
            this.standardsForm.get('standardLanguage').setValue(this.culture);

            // make sure we have the localizedText
            if (!this.selectedStandard.Purpose.LocalizedText[this.culture]) {
                this.selectedStandard.Purpose.LocalizedText[this.culture] = '';
            }
            if (!this.selectedStandard.Scope.LocalizedText[this.culture]) {
                this.selectedStandard.Scope.LocalizedText[this.culture] = '';
            }

            // update formcontrols
            this.standardsForm
                .get('standardPurpose')
                .setValue(this.selectedStandard.Purpose.LocalizedText[this.culture]);
            this.standardsForm
                .get('standardScope')
                .setValue(this.selectedStandard.Scope.LocalizedText[this.culture]);
        } else if (type == 'requirement') {
            this.selectedRequirement.ShortDesc.LocalizedText[this.culture] =
                this.reqSDescriptionCtrl.value;
            this.selectedRequirement.LongDesc.LocalizedText[this.culture] =
                this.reqLDescriptionCtrl.value;
            this.selectedRequirement.Guidance.LocalizedText[this.culture] =
                this.reqGuidanceCtrl.value;

            this.culture = event;
            this.languageCtrl.setValue(this.culture);

            if (!this.selectedRequirement.ShortDesc.LocalizedText[this.culture]) {
                this.selectedRequirement.ShortDesc.LocalizedText[this.culture] = '';
            }
            if (!this.selectedRequirement.LongDesc.LocalizedText[this.culture]) {
                this.selectedRequirement.LongDesc.LocalizedText[this.culture] = '';
            }
            if (!this.selectedRequirement.Guidance.LocalizedText[this.culture]) {
                this.selectedRequirement.Guidance.LocalizedText[this.culture] = '';
            }
            this.reqSDescriptionCtrl.setValue(
                this.selectedRequirement.ShortDesc.LocalizedText[this.culture]
            );
            this.reqLDescriptionCtrl.setValue(
                this.selectedRequirement.LongDesc.LocalizedText[this.culture]
            );
            this.reqGuidanceCtrl.setValue(
                this.selectedRequirement.Guidance.LocalizedText[this.culture]
            );
        }
    }

    addRefUrl() {
        if (
            this.refLabelCtrl.value == '' ||
            this.refLabelCtrl.value == null ||
            this.refUrlCtrl.value == '' ||
            this.refUrlCtrl.value == null
        ) {
            this.notificationService.toast.error('Error', 'Please fill in the Label and URL.');
            return;
        }
        let urlObj = {
            Id: -1,
            Label: this.refLabelCtrl.value,
            Url: this.refUrlCtrl.value,
        };
        this.selectedRequirement.ReferenceUrls.push(urlObj);
        this.refLabelCtrl.setValue('');
        this.refUrlCtrl.setValue('');
    }

    deleteRefUrl(url: ReferenceUrl, index: number) {
        if (url.Id == -1) {
            this.selectedRequirement.ReferenceUrls.splice(index, 1);
        } else {
            for (let i = 0; i < this.selectedRequirement.ReferenceUrls.length; i++) {
                if (this.selectedRequirement.ReferenceUrls[i].Id == url.Id) {
                    this.selectedRequirement.ReferenceUrls.splice(i, 1);
                }
            }
        }
    }

    searchStandards() {
        let val: string = this.standardsFilterText.toLowerCase();

        let selectedStandards = [];

        this.standards.forEach((gs) => {
            if (gs.Name.toLowerCase().includes(val)) {
                if (!selectedStandards.find((fs) => fs.Id == gs.Id)) {
                    selectedStandards.push(gs);
                }
            } else if (gs.Variant.toLowerCase().includes(val)) {
                if (!selectedStandards.find((fs) => fs.Id == gs.Id)) {
                    selectedStandards.push(gs);
                }
            } else if (gs.Revision.toLowerCase().includes(val)) {
                if (!selectedStandards.find((fs) => fs.Id == gs.Id)) {
                    selectedStandards.push(gs);
                }
            }
        });
        this.filterStandards(selectedStandards);
    }

    showSelectedControls(event: any) {
        if (event) {
            this.selectOnlyCheckedControls();
            this.pageControls();
        } else {
            this.filterControls();
        }
        this.showsSelectControls = event;
    }
    selectOnlyCheckedControls() {
        this.sortedControls = this.filteredControls.filter((control) => control.checked);
    }

    filterStandards(filteredData?: any) {
        if (filteredData) {
            this.filteredStandards = filteredData;
        } else {
            this.filteredStandards = this.standards;
        }
        //if (this.filterCtrl.value != 'all') {
        //  this.filteredStandards = this.checkFilter();
        //}
        //this.filtered1 = this.filteredLicenses;
        this.sortStandards();
    }

    checkFilter() {
        let filtered: any[] = [];
        for (let lic of this.filteredStandards) {
            filtered.push(lic);
        }
        return filtered;
    }

    sortStandards(sorting?: TableSorting) {
        if (!sorting) {
            this.sortedStandards = this.filteredStandards.sort((a, b) =>
                a.Name.toLowerCase().localeCompare(b.Name.toLowerCase())
            );
        } else {
            switch (sorting.sortColumn) {
                case 'Name':
                    this.sortedStandards = this.filteredStandards.sort((a, b) =>
                        a.Name.toLowerCase().localeCompare(b.Name.toLowerCase())
                    );
                    break;
                case 'Variant':
                    this.sortedStandards = this.filteredStandards.sort((a, b) =>
                        a.Variant.toLowerCase().localeCompare(b.Variant.toLowerCase())
                    );
                    break;
                case 'Requirements':
                    this.sortedStandards = this.filteredStandards.sort((a, b) =>
                        a.Requirements.length > b.Requirements.length
                            ? 1
                            : a.Requirements.length < b.Requirements.length
                            ? -1
                            : 0
                    );
                    break;
                default:
                    this.sortedStandards = this.filteredStandards.sort((a, b) =>
                        a.Name.toLowerCase().localeCompare(b.Name.toLowerCase())
                    );
                    break;
            }

            if (sorting.sortDirection == 'desc') {
                //descending
                this.sortedStandards.reverse();
            }
        }
        if (this.hideSystemStandards) {
            this.sortedStandards = this.sortedStandards.filter((s) => !s.IsSystem);
        }
        this.sortedStandards = [...this.sortedStandards];
    }

    hideSystemRecordsChanged(event?: any) {
        this.hideSystemStandards = event;
        this.sortStandards();
    }

    addCrosswalk(): void {
        if (
            this.stndCrosswalkCtrl.value === null ||
            this.stndCrosswalkCtrl.value.match(/^ *$/) !== null
        ) {
            this.notificationService.toast.error('Error', 'Blank crosswalks cannot be added.');
        } else {
            let cwObj = {
                Id: -1,
                StandardId: this.selectedStandard.Id,
                CrosswalkDesc: '',
                DisplayOrder: this.selectedStandard.Crosswalks.length,
            };
            cwObj.CrosswalkDesc = this.stndCrosswalkCtrl.value;
            this.selectedStandard.Crosswalks.push(cwObj);
            this.stndCrosswalkCtrl.setValue('');
            this.standardsForm.markAsDirty();
        }
    }

    deleteCrosswalk(cw: I18N): void {
        let index = this.selectedStandard.Crosswalks.findIndex((sscw) => sscw.Id == cw.Id);
        if (index > -1) {
            this.selectedStandard.Crosswalks.splice(index, 1);
        }
        this.standardsForm.markAsDirty();
    }

    scrollModalUp() {
        document.querySelector('.ui.modals.visible.active .content.scrolling').scrollTop = 0;
    }

    uniqueStandard(control: AbstractControl): ValidationErrors | null {
        let name = control.get('standardName').value.toLowerCase().trim(),
            variant = control.get('standardVariant').value.toLowerCase().trim(),
            revision = control.get('standardRevision').value.toLowerCase().trim();

        if (this.standards) {
            let stdExists = this.standards.filter(
                (r) =>
                    r.Name.toLowerCase().trim() === name &&
                    r.Variant.toLowerCase().trim() === variant &&
                    r.Revision.toLowerCase().trim() === revision
            );
            if (
                stdExists.length &&
                stdExists.findIndex((r) => r.Id == this.selectedStandard.Id) == -1
            ) {
                return { notUnique: true };
            }
        }
        return null;
    }

    uniqueStandardName(control: AbstractControl): ValidationErrors | null {
        let name = control.value.toLowerCase().trim();

        if (this.standards) {
            let stdExists = this.standards.filter((r) => r.Name.toLowerCase().trim() === name);
            if (stdExists.length) {
                return { notUniqueName: true };
            }
        }
        return null;
    }

    sortReferenceURL(sorting?: TableSorting) {
        switch (sorting.sortColumn) {
            case 'Label':
                this.selectedRequirement.ReferenceUrls.sort((a, b) =>
                    a.Label.toLowerCase().localeCompare(b.Label.toLowerCase())
                );
                break;
            case 'Url':
                this.selectedRequirement.ReferenceUrls.sort((a, b) =>
                    a.Url.toLowerCase().localeCompare(b.Url.toLowerCase())
                );
                break;
            default:
                this.selectedRequirement.ReferenceUrls.sort((a, b) =>
                    a.Label.toLowerCase().localeCompare(b.Label.toLowerCase())
                );
                break;
        }

        if (sorting.sortDirection == 'desc') {
            //descending
            this.selectedRequirement.ReferenceUrls.reverse();
        }
    }
}

export class ControlsRequirement {
    requirement: Requirement;
    addedControls: Control[];
    previousStateControls: Control[];
    deleteControls: Control[];

    constructor() {
        this.requirement = new Requirement();
        this.addedControls = [];
        this.previousStateControls = [];
        this.deleteControls = [];
    }
}
