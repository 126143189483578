import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, OnInit, Output, ViewChild } from '@angular/core';
import { RouterLink, RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BehaviorSubject, Subject } from 'rxjs';

@Component({
    standalone: true,
    imports: [CommonModule, RouterModule, FontAwesomeModule, RouterModule],
    selector: 'itc-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
})
export class ItcButtonComponent implements OnInit {
    constructor() { }

    forceDisable: boolean = false;
    icon$: BehaviorSubject<string> = new BehaviorSubject(this.icon);

    // if using in a modal and using 'cancel' or 'approve' you need to enter it like [class]="'approve'"
    @Input() class: string;
    @Input() type: string = 'primary';
    @Input() set color(val: string) {
        if (this.type == 'color') {
            if (this.colorIsHex(val)) {
                val = this.hexToRGBA(val);
            }
            this._color = val;
            this.hoverColor = this.rgb_linear_shade(-0.12, val);
        }
    }
    get color() {
        return this._color;
    }
    @Input() disabled: boolean;
    @Input() size: string;
    @Input() set icon(val: string) {
        this.icon$.next(val);
    }
    get icon() {
        return this.icon$?.value;
    }
    @Input() iconPlacement = 'before';
    @Input() loading: boolean;
    @Input() label: string;
    @Input() link: string;
    @Input() params: string;
    @Input() title: string;
    @Input() disableAfterClick: boolean;
    @Input() loadingAfterClick: boolean;
    @Input() fileId = 'fileInput' + Math.ceil(Date.now() + Math.random() * 100 + 1);
    @Input() fileAccept: string;
    @Input() routerLink: RouterLink;
    @Input() isDropdownButton = false;
    @Input() displayChevronDownIcon = true;
    @Input() target;

    @ViewChild('innerContent') innerContent: any;

    _color: string;
    hoverColor: string;
    hover = false;
    hasLabel: boolean;
    faIcon = null;

    @Output() onclick: EventEmitter<any> = new EventEmitter<any>();
    @Output() onchange: EventEmitter<any> = new EventEmitter<any>();

    ngOnInit() {
        //if (this.icon?.toLocaleLowerCase().substring(0,3) === 'fa-') {
        //  this.faIcon = this.icon.toLocaleLowerCase().slice(3);
        //}
        this.icon$.subscribe((value: string) => {
            if (value?.toLocaleLowerCase().substring(0, 3) === 'fa-') {
                this.faIcon = value.toLocaleLowerCase().slice(3);
            }
        });
    }

    click(ev) {
        if (this.disabled || this.loading || this.forceDisable) {
            return;
        }
        this.forceDisable = true; // set forceDisable to avoid accidental double clicks
        this.onclick.emit(ev);
        setTimeout(() => (this.forceDisable = false), 500);
        if (this.disableAfterClick) {
            this.disabled = true;
        }
        if (this.loadingAfterClick) {
            this.loading = true;
        }
    }

    changeCallback($event) {
        this.onchange.emit($event);
    }

    // darken function for hover from https://stackoverflow.com/a/13542669
    // small tweak for typescript (D var)
    rgb_linear_shade(p: number, c: string) {
        var i = parseInt,
            r = Math.round,
            [a, b, c, d] = c.split(','),
            D = p < 0,
            t = P ? 0 : 255 * p,
            P = D ? 1 + p : 1 - p;
        return (
            'rgb' +
            (d ? 'a(' : '(') +
            r(i(a[3] == 'a' ? a.slice(5) : a.slice(4)) * P + t) +
            ',' +
            r(i(b) * P + t) +
            ',' +
            r(i(c) * P + t) +
            (d ? ',' + d : ')')
        );
    }

    /* helper function to handle RGB colors from https://stackoverflow.com/a/53936623*/
    colorIsHex(hex: string) {
        return /^#([A-Fa-f0-9]{3,4}){1,2}$/.test(hex);
    }
    getChunksFromString(st, chunkSize) {
        return st.match(new RegExp(`.{${chunkSize}}`, 'g'));
    }
    convertHexUnitTo256 = (hexStr) => parseInt(hexStr.repeat(2 / hexStr.length), 16);
    getAlphafloat(a, alpha) {
        if (typeof a !== 'undefined') {
            return a / 255;
        }
        if (typeof alpha != 'number' || alpha < 0 || alpha > 1) {
            return 1;
        }
        return alpha;
    }

    hexToRGBA(hex: string, alpha?: number) {
        const chunkSize = Math.floor((hex.length - 1) / 3);
        const hexArr = this.getChunksFromString(hex.slice(1), chunkSize);
        const [r, g, b, a] = hexArr.map(this.convertHexUnitTo256);
        return `rgba(${r}, ${g}, ${b}, ${this.getAlphafloat(a, alpha)})`;
    }
}
