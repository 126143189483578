import { Component, Input, OnInit } from '@angular/core';
import { MetricBoxItem } from './metric-box-item.model';
@Component({
    selector: 'sds-metric-box',
    templateUrl: './metric-box.component.html',
    styleUrls: ['./metric-box.component.scss'],
})
export class MetricBoxComponent implements OnInit {
    
    @Input() item: MetricBoxItem;
    constructor() {}

    ngOnInit() {}
}
