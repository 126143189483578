<sm-modal class="sm EOCReportsModal" #evidenceReportModal>
    <modal-title>{{ evidence?.Name }}</modal-title>
    <modal-content>
        <div>
            {{ evidence?.Description }}
        </div>
        <div class="row" style="height: 180px; width: 100%; padding-top: 20px">
            <div class="column" style="width: 180px; float: left; padding-left: 20px">
                <itc-legacy-donut-chart
                    class="reportChart"
                    [data]="currentCoverage"
                    [centerTextLarge]="evidence?.CurrentCoverage + '/' + evidence?.TotalCoverage"
                    [centerTextSmall]="coveragePercentText"
                    [diameter]="160"></itc-legacy-donut-chart>
            </div>
            <div class="column metric">
                <table class="itc-table">
                    <th>Metrics</th>
                    <th></th>
                    <tbody>
                    <tr *ngFor="let metric of evidence?.Metrics">
                        <td>
                            {{ metric.Name }} &nbsp;
                            <fa-icon
                                icon="circle-question"
                                tooltip="{{ metric.Description }}"></fa-icon>
                        </td>
                        <td>
                            <b>{{ metric.Value }}</b>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </modal-content>
    <modal-actions>
        <div class="row">
            <div class="column" style="float: left">
                <small>
                    <b>Last Updated:</b>
                    {{ evidence?.LastImported ? (evidence.LastImported | accountDate) : ''}}
                </small>
            </div>
            <div class="column">
                <itc-button type="secondary" (onclick)="evidenceReportModal.hide()">Close</itc-button>
            </div>
        </div>
    </modal-actions>
</sm-modal>