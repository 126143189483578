import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';
import { AuthHttp } from 'app/core/auth';
import { ErrorService } from 'app/core/error/error.service';
import { CheckScanHostReq } from './scan-wizard/checkscanhostreq.model';
import { CheckUnitrendsReq } from './scan-wizard/checkunitrendsreq.model';
import { ApplianceInfoRequest } from './scan-wizard/applianceinforequest.model';
import { ApplianceInfoResponse } from './scan-wizard/applianceinforesponse.model';
import { ScanSettingsRequest, ScanSettingsRequestWithPasswordChanged } from './scan-wizard/scansettingsrequest.model';
import { HttpHeaders } from '@angular/common/http';
import { DateTime } from '@grapecity/wijmo';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SiteSettingService {
    private apiUrl: string;

    constructor(private http: AuthHttp, private E: ErrorService) {
        this.apiUrl = environment.apiUrl;
    }

    checkScanHosts(req: CheckScanHostReq): Promise<any> {
        const url: string = this.apiUrl + 'SiteSettings/CheckScanHosts';
        return this.http
            .post(url, req)
            .toPromise()
            .then((res) => res);
        //.catch(this.E.error);
    }

    checkUnitrends(req: CheckUnitrendsReq[]): Promise<any> {
        const url: string = this.apiUrl + 'SiteSettings/CheckUnitrends';
        return this.http
            .post(url, req)
            .toPromise()
            .then((res) => res);
        //.catch(this.E.error);
    }

    getApplianceInfo(air: ApplianceInfoRequest): Promise<ApplianceInfoResponse> {
        const url: string = this.apiUrl + 'SiteSettings/WizardApplianceInfo';
        return this.http
            .post(url, air)
            .toPromise()
            .then((res) => res as ApplianceInfoResponse);
    }

    scheduleGdprScan(scheduleRequest: ScanSettingsRequest): Promise<any> {
        const url: string = this.apiUrl + 'SiteSettings/SetScanSettings';
        return this.http
            .post(url, scheduleRequest)
            .toPromise()
            .then((res) => res as any);
    }

    setScanWizardSettings(siteId: number, scheduleRequest: ScanSettingsRequestWithPasswordChanged): Promise<any> {
        const url = `${this.apiUrl}SiteSettings/SetScanWizardSettings/${siteId}`;
        return lastValueFrom(this.http.post(url, scheduleRequest));
    }

    validateExternalVulnerabilityScanSettings(
        scheduleRequest: ScanSettingsRequest
    ): Promise<string> {
        const url: string = this.apiUrl + 'SiteSettings/ValidateExternalVulnerabilityScanSettings';
        return this.http
            .post(url, scheduleRequest)
            .toPromise()
            .then((res) => res as string);
    }

    getGdprScan(siteId: number): Promise<ScanSettingsRequest> {
        const url: string = this.apiUrl + `SiteSettings/GetScanSettings/${siteId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as ScanSettingsRequest);
    }

    getScanWizardSettings(siteId: number): Promise<ScanSettingsRequest> {
        const url = `${this.apiUrl}SiteSettings/GetScanWizardSettings/${siteId}`;
        return lastValueFrom(this.http.get(url)).then((res) => res as ScanSettingsRequest);
    }

    getReportPreferences(siteId: number): Promise<ReportPreference[]> {
        const url: string = this.apiUrl + `Sites/${siteId}/ReportPreferences`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as ReportPreference[]);
    }

    getGlobalReportPreferences(siteId: number): Promise<ReportPreference[]> {
        const url: string = this.apiUrl + `Sites/${siteId}/GlobalReportPreferences`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as ReportPreference[]);
    }

    setReportPreferences(siteId: number, prefs: ReportPreference[]): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/ReportPreferences`;
        return this.http
            .put(url, prefs)
            .toPromise()
            .then((res) => res);
    }

    getAllowedLanguages(siteId: number): Promise<string[]> {
        const url: string = this.apiUrl + `Sites/${siteId}/ReportPreferences/Languages`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as string[]);
    }

    getAllSiteParams(siteId: number): Promise<SiteParam[]> {
        const url: string = this.apiUrl + `Sites/${siteId}/SiteParams`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as SiteParam[]);
    }

    getSiteParam(siteId: number, pname: string): Promise<SiteParam> {
        const url: string = this.apiUrl + `Sites/${siteId}/SiteParams/${pname}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as SiteParam);
    }

    getSensitiveSiteParam(siteId: number, pname: string): Promise<SiteParam> {
        const url: string = this.apiUrl + `Sites/${siteId}/SensitiveSiteParams/${pname}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as SiteParam);
    }

    upsertSiteParams(siteId: number, siteParams: SiteParam[]): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/SiteParams`;
        return this.http
            .post(url, siteParams)
            .toPromise()
            .then((res) => res);
    }

    upsertSensitiveSiteParams(siteId: number, siteParams: SiteParam[]): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/SensitiveSiteParams`;
        return this.http
            .post(url, siteParams)
            .toPromise()
            .then((res) => res);
    }

    upsertSiteParamsLarge(siteId: number, siteParams: SiteParam[]): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/SiteParamsLarge`;
        return this.http
            .post(url, siteParams)
            .toPromise()
            .then((res) => res);
    }

    getSiteParamsLarge(siteId: number, paramName: string): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/SiteParamsLarge/${paramName}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getAdminAlertSettings(siteId: number): Promise<SiteParam[]> {
        const url: string = this.apiUrl + `Sites/${siteId}/SiteParams/AdminAlerts`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as SiteParam[]);
    }

    deleteAdminAlertSettings(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/SiteParams/AdminAlerts`;
        return this.http
            .delete(url, { observe: 'response' })
            .toPromise()
            .then((res) => res);
    }

    getAssessmentIssuesSettings(siteId: number): Promise<SiteParam[]> {
        const url: string = this.apiUrl + `Sites/${siteId}/SiteParams/AssessmentIssues`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as SiteParam[]);
    }

    getAssessmentIssuesSettingsLargeParams(siteId: number, pname: string): Promise<SiteParam> {
        const url: string =
            this.apiUrl + `Sites/${siteId}/SiteParams/AssessmentIssuesLargeParams/${pname}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as SiteParam);
    }

    getScanScheduleSettings(siteId: number): Promise<SiteParam[]> {
        const url: string = this.apiUrl + `Sites/${siteId}/SiteParams/ScanSchedules`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as SiteParam[]);
    }

    getApplianceInfoForReal(siteId?: number): Promise<any[]> {
        siteId = !siteId ? -1 : siteId;
        const url: string = this.apiUrl + `Sites/${siteId}/Appliance`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any[]);
    }

    GetDiscoveryAgentInfo(siteId: number): Promise<any[]> {
        const url: string = this.apiUrl + `Sites/${siteId}/DiscoveryAgent`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    GetOrgDiscoveryAgentInfo(siteId: number): Promise<any[]> {
        const url: string = this.apiUrl + `Sites/${siteId}/OrgDiscoveryAgent`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    //getIsApplianceUpToDate(siteId: number, applianceIds: string[]): Promise<any> {
    //  const url: string = this.apiUrl + `Sites/${siteId}/Appliance/IsUpToDate`;
    //  return this.http.post(url, applianceIds)
    //    .toPromise()
    //    .then(res => res as any[]);
    //}

    sendUpdateToAppliance(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/Appliance/Update`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any);
    }

    sendUpdateToApplianceById(applianceId: string): Promise<any> {
        const url: string = this.apiUrl + `Appliances/${applianceId}/Update`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any);
    }

    getAutoUpdateForApplianceById(applianceId: string): Promise<any> {
        const url: string = this.apiUrl + `Appliances/${applianceId}/AutoUpdate`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any);
    }
    sendAutoUpdateToApplianceById(applianceId: string, enabled: boolean): Promise<any> {
        const url: string = this.apiUrl + `Appliances/${applianceId}/AutoUpdate`;
        return this.http
            .post(url, { Enabled: enabled })
            .toPromise()
            .then((res) => res as any);
    }

    getHealthCheckForApplianceById(applianceId: string): Promise<any> {
        const url: string = this.apiUrl + `Appliances/${applianceId}/HealthCheck`;
        return lastValueFrom(this.http.get(url)).then((res) => res as any);
    }

    getHealthCheckForApplianceBySiteAndId(siteId: number, applianceId: string): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/Appliances/${applianceId}/HealthCheck`;
        return lastValueFrom(this.http.get(url)).then((res) => res as any);
    }

    getLogsForApplianceById(applianceId: string): Promise<any> {
        const url: string = this.apiUrl + `Appliances/${applianceId}/DownloadLogs`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any);
    }
    getAuditForApplianceById(applianceId: string): Promise<any> {
        const url: string = this.apiUrl + `Appliances/${applianceId}/DownloadAudit`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any);
    }
    getReportsForApplianceById(applianceId: string): Promise<any> {
        const url: string = this.apiUrl + `Appliances/${applianceId}/ListReports`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any);
    }

    getReportById(applianceId: string, report: any): Promise<any> {
        const url: string = this.apiUrl + `Appliances/${applianceId}/DownloadReport`;
        return this.http
            .post(url, report)
            .toPromise()
            .then((res) => res as any);
    }
    deleteReportById(applianceId: string, report: any): Promise<any> {
        const url: string = this.apiUrl + `Appliances/${applianceId}/DeleteReport`;
        return this.http
            .post(url, report)
            .toPromise()
            .then((res) => res as any);
    }
    getScansForApplianceById(applianceId: string, siteId: number): Promise<any> {
        const url: string = this.apiUrl + `Appliances/${applianceId}/ListScans/${siteId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any);
    }

    getHEVSScansBySiteId(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `Appliances/HostedEVS/ListScans/${siteId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any);
    }
    getCurrentAssessmentScansForApplianceById(applianceId: string, siteId: number): Promise<any> {
        const url: string =
            this.apiUrl + `Appliances/${applianceId}/ListCurrentAssessmentScans/${siteId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any);
    }

    getCurrentVulnScansForSite(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `Appliances/ListCurrentVulnScans/${siteId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any);
    }

    getScansForAppliance(applianceId: string, scans: any[]): Promise<any> {
        const url: string = this.apiUrl + `Appliances/${applianceId}/DownloadScans`;
        return this.http
            .post(url, scans)
            .toPromise()
            .then((res) => res as any);
    }

    getScansForHEVS(scans: any[]): Promise<any> {
        const url: string = this.apiUrl + `Appliances/DownloadHEVScans`;
        return this.http
            .post(url, scans)
            .toPromise()
            .then((res) => res as any);
    }
    deleteScans(applianceId: string, scans: any[]): Promise<any> {
        const url: string = this.apiUrl + `Appliances/${applianceId}/DeleteScans`;
        return this.http
            .post(url, scans)
            .toPromise()
            .then((res) => res as any);
    }

    deleteHEVSScans(scans: any[]): Promise<any> {
        const url: string = this.apiUrl + `Appliances/DeleteHEVSScans`;
        return this.http
            .post(url, scans)
            .toPromise()
            .then((res) => res as any);
    }
    cancelScans(applianceId: string, scans: any[]): Promise<any> {
        const url: string = this.apiUrl + `Appliances/${applianceId}/CancelScans`;
        return this.http
            .post(url, scans)
            .toPromise()
            .then((res) => res as any);
    }
    cancelHEVSScans(scans: any[]): Promise<any> {
        const url: string = this.apiUrl + `Appliances/CancelHEVSScans`;
        return this.http
            .post(url, scans)
            .toPromise()
            .then((res) => res as any);
    }
    unqueueScans(scans: any[]): Promise<any> {
        const url: string = this.apiUrl + `Appliances/UnqueueScans`;
        return this.http
            .post(url, scans)
            .toPromise()
            .then((res) => res as any);
    }

    unqueueHEVSScans(scans: any[]): Promise<any> {
        const url: string = this.apiUrl + `Appliances/UnqueueHEVSScans`;
        return this.http
            .post(url, scans)
            .toPromise()
            .then((res) => res as any);
    }

    getApplianceScanStatuses(siteId: number, applianceId: string): Promise<any[]> {
        const url: string = this.apiUrl + `Sites/${siteId}/Appliance/ScanStatus`;
        return this.http
            .post(url, { robotId: applianceId })
            .toPromise()
            .then((res) => res as any);
    }

    getApplianceScanStatusesForNullSites(applianceId: string): Promise<any[]> {
        const url: string = this.apiUrl + `Appliance/${applianceId}/ScanStatus`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any);
    }

    getAddons(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/Settings/Addons`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any);
    }

    getAvailableAbdsSubscriptions(siteId: number): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/Settings/AbdsSubscriptions`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any);
    }

    addAbdsSubscription(siteId: number, licenseFrequency: string) {
        const url: string = this.apiUrl + `Sites/${siteId}/Settings/AddAbdsSubscription`;

        return this.http
            .post(url, { freq: licenseFrequency })
            .toPromise()
            .then((res) => res);
    }

    bindAbdsSubscription(siteId: number, licenseFrequency: string) {
        const url: string = this.apiUrl + `Sites/${siteId}/Settings/BindAbdsSubscription`;

        return this.http
            .post(url, { freq: licenseFrequency })
            .toPromise()
            .then((res) => res);
    }

    setAbdsAutorenew(siteId: number, autoRenew: boolean) {
        const url: string = this.apiUrl + `Sites/${siteId}/Settings/SetAbdsAutorenew`;

        return this.http
            .post(url, { autorenew: autoRenew })
            .toPromise()
            .then((res) => res);
    }

    logCysuranceAction(siteId: number, action: string) {
        const url: string = this.apiUrl + `Sites/${siteId}/Settings/LogCysurance`;

        return this.http
            .post(url, { action: action })
            .toPromise()
            .then((res) => res);
    }

    getIpCount(ipRanges: string[], excludedIpRanges: string[]) {
        const url: string = this.apiUrl + 'SiteSettings/GetIpCount';
        return this.http
            .post(url, { ips: ipRanges, excludedIps: excludedIpRanges })
            .toPromise()
            .then((res) => res as any);
    }

    getKBMSQuantities(siteId: number) {
        const url: string = this.apiUrl + `Sites/${siteId}/GetKBMSBillingQuantities`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any);
    }

    getVsaConnector(siteId: number) {
        const url: string = this.apiUrl + `Sites/${siteId}/GetVsaConnector`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any);
    }

    setVsaConnector(siteId: number, isEnabled: boolean) {
        const url: string = this.apiUrl + `Sites/${siteId}/SetVsaConnector`;
        return this.http
            .post(url, { Enabled: isEnabled })
            .toPromise()
            .then((res) => res as any);
    }

    setApplianceDescription(appId: string, desc: string): Promise<any> {
        const url: string = this.apiUrl + `SiteSettings/${appId}/Appliance/SetDescription`;
        return this.http
            .post(url, { Desc: desc })
            .toPromise()
            .then((res) => res as any);
    }

    setApplianceLabel(appId: string, previousLabel: string, label: string, organizationId: number): Promise<any> {
        const url: string = this.apiUrl + `SiteSettings/${appId}/Appliance/${organizationId}/SetLabel`;
        return this.http
            .post(url, { PreviousLabel: previousLabel, Label: label })
            .toPromise()
            .then((res) => res as any);
    }

    setMultipleApplianceLabels(apps: any[], label: string, organizationId: number): Promise<any> {
        const url = this.apiUrl + `SiteSettings/Appliances/${organizationId}/SetLabels`;
        return this.http
            .post(url, { Apps: apps, Label: label})
            .toPromise()
            .then((res) => res as any);
    }
}

export class ReportPreference {
    public Name: string;
    public Value: string;
    public AccountId?: string;
    public SiteId?: number;
    public Image64?: string;
}

export class SiteParam {
    public SiteId: number;
    public Name: string;
    public Value: string;
}
