import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
    name: 'roleName',
    standalone: true,
})
export class RoleNamePipe implements PipeTransform {
    transform(value: unknown, ...args: unknown[]): unknown {
        let roleName: string = 'Unassigned';
        switch (value) {
            case 'SDS_SITEADMIN':
                roleName = 'Site Admin';
                break;
            case 'SDS_TECH':
                roleName = 'Technician';
                break;
            case 'SDS_AUDITOR':
                roleName = 'Internal Auditor';
                break;
            case 'AG_SME':
                roleName = 'Subject Matter Expert';
                break;
            case 'IND_CLIENT':
                roleName = 'Client';
                break;
            case 'GRC_REPORT_VIEWER':
                roleName = 'Reports Viewer';
                break;
            case 'EMP_PORTAL_ADMIN':
                roleName = 'Employee Portal Admin';
                break;
        }

        return roleName;
    }
}
