import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { Audit } from '../audit.model';
import { environment } from 'environments/environment';
import { UntypedFormControl } from '@angular/forms';
import { DateService } from 'app/shared/date.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'sds-audit-search-filter',
    templateUrl: './audit-search-filter.component.html',
    styleUrls: ['./audit-search-filter.component.css'],
})
export class AuditSearchFilterComponent implements OnInit {
    toDate: Date;
    fromDate: Date;
    dateFormat: string;
    ngUnsubscribe$: Subject<void> = new Subject<void>();

    constructor(private dateService: DateService) {
        // values for InputDate, Calendar
        const today = new Date();
        this.toDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 13, 30);
        this.fromDate = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate() - 7,
            13,
            30
        );
    }

    @Input()
    set audits(value: Audit[]) {
        if (value) {
            this._audits = value;
            this.filter();
        }
    }

    private _audits: Audit[];
    searchKey: string;

    showAdminControl: UntypedFormControl = new UntypedFormControl();
    showAdmin: boolean;

    @Output() onFiltered = new EventEmitter<Audit[]>();

    ngOnInit() {
        this.dateService
            .getDateFormat()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((dateFormat) => {
                this.dateFormat = dateFormat;
            });

        this.showAdminControl.valueChanges.subscribe((value) => {
            this.handleShowAdminChecked(value);
        });

        this.showAdminControl.setValue(true);
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next(void 0);
        this.ngUnsubscribe$.complete();
    }

    handleOutputEvents(outputEvent) {
        if (!environment.production) {
            console.log('outputEvent: ' + outputEvent);
        }
    }

    handleShowAdminChecked(checkedValue) {
        if (!environment.production) {
            console.log('checked: ' + checkedValue);
        }

        try {
            this.showAdmin = checkedValue;
            this.filter();
        } catch (Exception) {
            if (!environment.production) {
                console.log('showAdminChecked exception' + Error);
            }
        }
    }

    handleKeyFrom(keyEvent) {
        if (!environment.production) {
            console.log('keypress: ' + keyEvent.target.value);
        }

        try {
            let d = new Date(keyEvent.target.value);

            // date is valid if we get this far
            //this.dateFrom.momentObj = moment(d);

            this.filter();
        } catch (Exception) {}
    }

    handleKeyTo(keyEvent) {
        if (!environment.production) {
            console.log('keypress: ' + keyEvent.target.value);
        }

        try {
            let d = new Date(keyEvent.target.value);
            d.setDate(d.getDate() + 1);
            // date is valid if we get this far
            //this.dateTo.momentObj = moment(d);

            this.filter();
        } catch (Exception) {}
    }

    filter() {
        if (!environment.production) {
            console.log('audit-search-filter.filter');
        }

        let filtered = this._audits;

        // setting this way because fromDate and toDate are including a nonzero time part
        let minDate = new Date(
            this.fromDate.getFullYear(),
            this.fromDate.getMonth(),
            this.fromDate.getDate()
        );
        let maxDate = new Date(
            this.toDate.getFullYear(),
            this.toDate.getMonth(),
            this.toDate.getDate() + 1
        ); // +1 so we get everything through that day

        if (filtered) {
            filtered = filtered.filter((audit) => {
                let includeAudit: boolean =
                    new Date(audit.ModifiedDate).valueOf() >= minDate.valueOf() &&
                    new Date(audit.ModifiedDate).valueOf() < maxDate.valueOf();

                if (!this.showAdmin && audit.ModifiedBy == 'ADMIN') {
                    includeAudit = false;
                }

                return includeAudit;
            });
        }

        if (this.searchKey) {
            let key = this.searchKey.toLowerCase().replace(/^\s+/g, '').replace(/\s+$/g, '');
            filtered = this.searchAudits(filtered, key);
        }

        this.onFiltered.emit(filtered);
    }

    searchAudits(filtered: Audit[], key: string) {
        let search: string = key.trim().toLowerCase();

        let filteredAudits: Audit[] = [];
        filtered.forEach((a) => {
            let hit = this.searchAudit(a, search);
            if (hit) filteredAudits.push(a);
        });

        return filteredAudits;
    }

    searchAudit(audit: Audit, search: string) {
        let hit: boolean = false;

        if (audit.AlertObject.trim().toLowerCase().includes(search)) {
            hit = true;
        } else if (audit.AlertType.trim().toLowerCase().includes(search)) {
            hit = true;
        } else if (audit.Message.trim().toLowerCase().includes(search)) {
            hit = true;
        } else if (audit.ModificationType.trim().toLowerCase().includes(search)) {
            hit = true;
        } else if (audit.ModifiedBy.trim().toLowerCase().includes(search)) {
            hit = true;
        } else if (audit.ChildAudits && audit.ChildAudits.length > 0) {
            audit.ChildAudits.every((ca) => {
                if (this.searchAudit(ca, search)) {
                    hit = true;
                }
                return hit;
            });
        }

        return hit;
    }
}
