import { Injector, Pipe, PipeTransform, Type } from '@angular/core';

@Pipe({
    name: 'dynamicPipe',
})
export class DynamicPipe implements PipeTransform {
    public constructor(private injector: Injector) {}

    transform(value: any, pipeToken: Type<any>, pipeArgs: any[]): any {
        if (!pipeToken) {
            return value;
        } else {
            let pipe = this.injector.get(pipeToken);
            return pipeArgs ? pipe.transform(value, ...pipeArgs) : pipe.transform(value);
        }
    }
}
