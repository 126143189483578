<sm-modal title="{{ isReadOnly ? 'View Control' : modalDialogueTitle }}" class="md" #addControl>
    <modal-content>
        <div *ngIf="stage == 1">
            <p>
                Describe a new control or modify an existing one. Although not required, controls
                can be specified in multiple languages. Controls should be linked to the
                requirements they help meet.
            </p>
            <div>
                <form class="ui form" name="controlsForm">
                    <div class="ui container">
                        <div class="sixteen wide column row-style">
                            <div class="itc-field">
                                <div>
                                    <label>Language:</label>
                                </div>
                                <sm-select
                                    [control]="languageCtrl"
                                    (onChange)="changeLanguage($event)"
                                    tabindex="-1"
                                    [disabled]="overlayMode">
                                    <option
                                        *ngFor="let language of languageMap"
                                        [value]="language.value">
                                        {{ language.label }}
                                    </option>
                                </sm-select>
                            </div>
                        </div>

                        <div class="sixteen wide column required row-style">
                            <div class="required field itc-field">
                                <label>
                                    Control ID:
                                    <span
                                        *ngIf="controlIdExists"
                                        style="color: var(--border-critical)">
                                        Control ID must be different from existing controls
                                    </span>
                                </label>
                                <itc-input
                                    [disabled]="languageCtrl && languageCtrl.value != 'en-US'"
                                    style="margin-top: 2px"
                                    type="text"
                                    name="ControlID"
                                    debounce="250"
                                    maxLength="45"
                                    [control]="ctrlIdCtrl"
                                    (modelChange)="validateControlId()"
                                    placeholder="Control ID"
                                    [readonly]="isReadOnly || overlayMode"></itc-input>
                            </div>
                        </div>

                        <div class="sixteen wide column row-style required field">
                            <div class="itc-field">
                                <label>Control Name:</label>
                                <input
                                    type="text"
                                    name="csDescript"
                                    maxLength="5000"
                                    [formControl]="ctrlSDescriptionCtrl"
                                    placeholder="Add a short description here"
                                    maxlength="100"
                                    [readonly]="isReadOnly || overlayMode" />
                            </div>
                        </div>

                        <div class="sixteen wide column row-style">
                            <div class="itc-field">
                                <label>Description:</label>
                                <textarea
                                    name="clDescript"
                                    style="height: 100%"
                                    maxLength="5000"
                                    [formControl]="ctrlLDescriptionCtrl"
                                    placeholder="Add a long description here"
                                    rows="4"
                                    [readonly]="isReadOnly || overlayMode"></textarea>
                            </div>
                        </div>

                        <div class="sixteen wide column row-style">
                            <div class="itc-field">
                                <label>Guidance:</label>
                                <textarea
                                    name="cGuidance"
                                    style="height: 100%"
                                    maxLength="5000"
                                    [formControl]="ctrlGuidanceCtrl"
                                    placeholder="Guidance"
                                    rows="4"
                                    [readonly]="isReadOnly"></textarea>
                            </div>
                        </div>

                        <div class="ui row">
                            <div class="sixteen wide column row-style procedureOptionsContainer">
                                <label>Procedure Options:</label>
                                <table class="itc-table" style="margin-top: 0; margin-bottom: 16px">
                                    <tbody>
                                        <tr
                                            *ngFor="
                                                let opt of selectedControl.Procedures;
                                                let i = index
                                            ">
                                            <ng-container *ngIf="!procEditState[i]">
                                                <td
                                                    *ngIf="!isReadOnly"
                                                    style="max-width: 40px; padding-left: 10px">
                                                    <itc-button
                                                        type="icon"
                                                        icon="fa-pencil"
                                                        (onclick)="editOption(i)"
                                                        tooltip="Edit"
                                                        ttPosition="top"></itc-button>
                                                </td>
                                                <td
                                                    *ngIf="!isReadOnly"
                                                    style="max-width: 40px; padding-left: 10px">
                                                    <itc-button
                                                        type="icon"
                                                        icon="fa-trash-can"
                                                        (onclick)="deleteOption(opt, i)"
                                                        tooltip="Delete"
                                                        ttPosition="top"></itc-button>
                                                </td>
                                                <td style="padding: 8px 14px">
                                                    {{ opt.LocalizedText['en-US'] }}
                                                </td>
                                            </ng-container>
                                            <ng-container *ngIf="procEditState[i]">
                                                <td colspan="3">
                                                    <b>Edit Procedure</b>
                                                    <div style="padding-left: 40px">
                                                        <textarea
                                                            name="cProcedure"
                                                            style="height: 100%"
                                                            maxLength="5000"
                                                            [formControl]="procEditText[i]"
                                                            placeholder="{{
                                                                opt.LocalizedText['en-US']
                                                            }}"
                                                            rows="4"
                                                            [readonly]="isReadOnly"></textarea>
                                                        <div style="float: right">
                                                            <itc-button
                                                                type="secondary"
                                                                (onclick)="
                                                                    procEditState[i] = false
                                                                ">
                                                                Cancel
                                                            </itc-button>
                                                            <itc-button
                                                                type="primary"
                                                                (onclick)="saveOption(i)">
                                                                Save
                                                            </itc-button>
                                                        </div>
                                                    </div>
                                                </td>
                                            </ng-container>
                                        </tr>
                                        <tr *ngIf="!isReadOnly">
                                            <ng-container *ngIf="!addMode">
                                                <td style="max-width: 20px; padding-left: 10px">
                                                    <itc-button
                                                        type="icon"
                                                        icon="fa-plus"
                                                        (onclick)="addMode = true"
                                                        tooltip="Add"
                                                        ttPosition="top"></itc-button>
                                                </td>
                                                <td></td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        name="Procedure Option"
                                                        placeholder="Add Procedure Option"
                                                        readonly />
                                                </td>
                                            </ng-container>
                                            <ng-container *ngIf="addMode">
                                                <td colspan="3">
                                                    <b>Add Procedure</b>
                                                    <div style="padding-left: 40px">
                                                        <div
                                                            *ngIf="defaultProcedure?.length"
                                                            [control]="quickSelectCtrl"
                                                            style="
                                                                display: flex;
                                                                padding-bottom: 6px;
                                                            ">
                                                            <span
                                                                style="
                                                                    margin-top: 6px;
                                                                    margin-right: 10px;
                                                                    width: 100px;
                                                                ">
                                                                Quick Select:
                                                            </span>
                                                            <sm-select
                                                                style="width: 540px"
                                                                (onChange)="
                                                                    quickSelectProcedure($event)
                                                                "
                                                                class="truncatedSelectOptions"
                                                                name="truncatedSelectOptions">
                                                                <option
                                                                    *ngFor="
                                                                        let opt of defaultProcedure
                                                                    "
                                                                    [value]="
                                                                        opt.LocalizedText[culture]
                                                                    ">
                                                                    {{ opt.LocalizedText[culture] }}
                                                                </option>
                                                            </sm-select>
                                                        </div>
                                                        <textarea
                                                            name="cProcedure"
                                                            style="height: 100%"
                                                            maxLength="5000"
                                                            [formControl]="procOptCtrl"
                                                            placeholder="Procedure"
                                                            rows="4"
                                                            [readonly]="isReadOnly"></textarea>
                                                        <div style="float: right">
                                                            <itc-button
                                                                type="secondary"
                                                                (onclick)="
                                                                    procOptCtrl.setValue('');
                                                                    quickSelectCtrl.setValue('');
                                                                    addMode = false
                                                                ">
                                                                Cancel
                                                            </itc-button>
                                                            <itc-button
                                                                type="primary"
                                                                (onclick)="addOption()">
                                                                Add
                                                            </itc-button>
                                                        </div>
                                                    </div>
                                                </td>
                                            </ng-container>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </form>

                <div
                    class="ui container section-mystyle"
                    style="
                        justify-content: space-between;
                        flex-wrap: wrap;
                        margin-bottom: 8px;
                        padding-top: 0px;
                        padding-bottom: 0;
                        display: flex;
                    ">
                    <div
                        class="eight wide column"
                        style="display: flex; flex-direction: column-reverse">
                        <label style="display: flex; margin-bottom: 0">Requirements List</label>
                    </div>
                    <div class="eight wide column">
                        <itc-button
                            *ngIf="!isReadOnly && !overlayMode"
                            icon="fa-plus"
                            type="tertiary"
                            (onclick)="goRequirements()"
                            style="display: flex; flex-direction: row-reverse">
                            Add Requirement
                        </itc-button>
                    </div>
                </div>
                <div class="sixteen wide column" style="padding-top: 0px !important; margin-top: 0">
                    <div class="ui container">
                        <div style="min-height: 80px; overflow-x: auto; overflow-y: auto">
                            <table
                                class="itc-table sortable"
                                sortable-table
                                sortColumn="Complex"
                                sortDirection="asc"
                                (sorted)="sortControlRequirements($event)"
                                style="margin-top: 0 !important">
                                <thead>
                                    <tr>
                                        <th
                                            sortable-column="Name"
                                            style="text-align: left; min-width: 120px">
                                            Standard
                                        </th>
                                        <th
                                            sortable-column="Variant"
                                            style="text-align: left; min-width: 120px">
                                            Variant
                                        </th>
                                        <th
                                            sortable-column="RequirementId"
                                            style="text-align: left; min-width: 150px">
                                            ID
                                        </th>
                                        <th
                                            sortable-column="ShortDesc"
                                            style="
                                                text-align: left;
                                                min-width: 220px;
                                                max-width: 220px;
                                            ">
                                            Description
                                        </th>
                                        <th
                                            style="min-width: 70px"
                                            *ngIf="!isReadOnly && !overlayMode"></th>
                                    </tr>
                                </thead>

                                <tbody
                                    class="flex-table basic table"
                                    style="margin-top: 0 !important">
                                    <tr *ngFor="let req of addedRequirements">
                                        <td>{{ standardDict[req.Id].standard.Name }}</td>
                                        <td>{{ standardDict[req.Id].standard.Variant }}</td>
                                        <td>
                                            {{
                                                req.RequirementId.length > 40
                                                    ? (req.RequirementId | slice : 0 : 40) + '..'
                                                    : req.RequirementId
                                            }}
                                        </td>
                                        <td style="overflow: hidden">
                                            {{
                                                req.ShortDesc.LocalizedText[culture]
                                                    ? req.ShortDesc.LocalizedText[culture].length >
                                                      40
                                                        ? (req.ShortDesc.LocalizedText[culture]
                                                              | slice : 0 : 40) + '..'
                                                        : req.ShortDesc.LocalizedText[culture]
                                                    : req.ShortDesc.LocalizedText['en-US'].length >
                                                      40
                                                    ? (req.ShortDesc.LocalizedText['en-US']
                                                          | slice : 0 : 40) + '..'
                                                    : req.ShortDesc.LocalizedText['en-US']
                                            }}
                                        </td>
                                        <td *ngIf="!isReadOnly && !overlayMode">
                                            <itc-button
                                                type="icon"
                                                icon="fa-trash-can"
                                                (onclick)="deleteRequirement(req)"
                                                ttposition="top"
                                                tooltip="Delete"></itc-button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <itc-paginator
                                [pageSize]="10"
                                [data]="selectedControl.Requirements"
                                (onPage)="addedRequirements = $event"></itc-paginator>
                        </div>
                    </div>
                </div>
                <div class="sixteen wide column"></div>
            </div>
        </div>
        <div *ngIf="stage == 2">
            <p>
                Check all requirements the control helps to meet. Use the search filter to limit the
                list of requirements.
            </p>
            <div
                style="
                    justify-content: space-between;
                    flex-wrap: wrap;
                    margin-bottom: 8px;
                    padding-top: 0px;
                    padding-bottom: 0px;
                    display: flex;
                ">
                <div class="eight wide column" style="display: flex; align-items: flex-end">
                    <label style="margin-bottom: 0">Requirements List</label>
                </div>
                <div class="eight wide column">
                    <div class="item searchInput addRequirementSearch">
                        <sm-input
                            placeholder="Enter Search Value"
                            [(model)]="reqFilterText"
                            (modelChange)="searchRequirements()"
                            debounce="420"></sm-input>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="sixteen wide column" style="padding-top: 0px !important">
                    <div style="height: 410px; overflow-x: auto; overflow-y: auto">
                        <table
                            class="itc-table"
                            sortable-table
                            sortColumn="Complex"
                            sortDirection="asc"
                            (sorted)="sortRequirements($event)">
                            <thead>
                                <tr>
                                    <th style="min-width: 50px"></th>
                                    <th
                                        sortable-column="Name"
                                        style="text-align: left; min-width: 120px">
                                        Standard
                                    </th>
                                    <th
                                        sortable-column="Variant"
                                        style="text-align: left; min-width: 120px">
                                        Variant
                                    </th>
                                    <th
                                        sortable-column="RequirementId"
                                        style="text-align: left; min-width: 150px">
                                        ID
                                    </th>
                                    <th sortable-column="ShortDesc" style="text-align: left">
                                        Description
                                    </th>
                                    <!--<th style="min-width:155px"></th>-->
                                </tr>
                            </thead>
                            <tbody
                                class="flex-table basic sortable"
                                style="margin-top: 0 !important">
                                <tr *ngFor="let row of pagedRequirements">
                                    <td style="max-width: 50px">
                                        <itc-checkbox
                                            [control]="requirementControls[row.requirement.Id]"
                                            (onChanged)="
                                                toggleSelectedRequirement(row.requirement.Id)
                                            "
                                            name="{{ 'reqcheck' + row.requirement.Id }}"
                                            ngDefaultControl></itc-checkbox>
                                    </td>
                                    <td style="min-width: 120px">{{ row.standard.Name }}</td>
                                    <td style="min-width: 120px">{{ row.standard.Variant }}</td>
                                    <td style="min-width: 150px">
                                        {{
                                            row.requirement.RequirementId.length > 30
                                                ? (row.requirement.RequirementId | slice : 0 : 30) +
                                                  '..'
                                                : row.requirement.RequirementId
                                        }}
                                    </td>
                                    <td>
                                        {{
                                            row.requirement.ShortDesc.LocalizedText[culture]
                                                ? row.requirement.ShortDesc.LocalizedText[culture]
                                                      .length > 35
                                                    ? (row.requirement.ShortDesc.LocalizedText[
                                                          culture
                                                      ] | slice : 0 : 35) + '..'
                                                    : row.requirement.ShortDesc.LocalizedText[
                                                          culture
                                                      ]
                                                : row.requirement.ShortDesc.LocalizedText['en-US']
                                                      .length > 35
                                                ? (row.requirement.ShortDesc.LocalizedText['en-US']
                                                      | slice : 0 : 35) + '..'
                                                : row.requirement.ShortDesc.LocalizedText['en-US']
                                        }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <itc-paginator
                        [data]="filteredRequirements"
                        (onPage)="pagedRequirements = $event"></itc-paginator>
                </div>
            </div>
        </div>
        <div *ngIf="stage == 3">
            <div class="ui grid">
                <div class="sixteen wide column">
                    <h2>Confirmation</h2>
                    Data has not been saved. Are you sure you want to cancel?
                </div>
            </div>
        </div>
        <div *ngIf="stage == 4">
            <div class="ui grid">
                <div class="sixteen wide column">
                    <h2>Revert to Default</h2>
                    This will discard all overrides made to this control. Do you wish to continue?
                </div>
            </div>
        </div>
    </modal-content>
    <modal-actions>
        <div *ngIf="stage == 1 || stage == 2" style="padding-bottom: 10px">
            <itc-button
                type="danger"
                (onclick)="stage = 4"
                [disabled]="!selectedControl.HasOverrides"
                *ngIf="stage == 1 && overlayMode && !isReadOnly"
                [loading]="saving && selectedControl.HasOverrides"
                style="float: left">
                Revert to Default
            </itc-button>
            <itc-button
                *ngIf="stage == 1"
                size="regular"
                type="secondary"
                (onclick)="modalCancel()">
                {{ isReadOnly ? 'Close' : 'Cancel' }}
            </itc-button>
            <itc-button
                *ngIf="stage == 2"
                size="regular"
                icon="fa-arrow-left"
                type="secondary"
                (onclick)="modalCancel()">
                Back
            </itc-button>
            <itc-button
                icon="fa-check"
                size="regular"
                type="primary"
                [disabled]="!canSave"
                (onclick)="saveSelectedControl()"
                *ngIf="stage == 1 && !isReadOnly"
                [loading]="saving">
                Save
            </itc-button>
            <itc-button
                icon="fa-check"
                size="regular"
                type="primary"
                [disabled]="selectedRequirements.length == 0"
                (onclick)="saveSelectedRequirements()"
                *ngIf="stage != 1">
                Add
            </itc-button>
        </div>
        <div *ngIf="stage == 3 || stage == 4">
            <itc-button type="secondary" (onclick)="clickedNo()">No</itc-button>
            <itc-button type="danger" (onclick)="clickedYes()" [loading]="saving">Yes</itc-button>
        </div>
    </modal-actions>
</sm-modal>

<sm-modal title="Clone Control {{ selectedControl.ControlId }}" class="sm" #cloneControlModal>
    <modal-content>
        <p>
            Create a clone of the current control with a new name. You can modify the derived
            control without affecting the original control.
        </p>

        <div class="sixteen wide column row-style">
            <div class="required field">
                <label>
                    Control ID:
                    <span *ngIf="!clonedControlIdValid" style="color: #b30000">
                        Control ID must be different from existing controls
                    </span>
                </label>
                <itc-input
                    style="margin-top: 2px"
                    [control]="clonedControlIdField"
                    (modelChange)="validateClonedControlId()"
                    debounce="250"
                    maxLength="45"
                    placeholder="Control ID"></itc-input>
            </div>
        </div>

        <div class="sixteen wide column row-style">
            <div class="required field">
                <label>
                    Control Name:
                    <span *ngIf="!clonedControlNameValid" style="color: #b30000">
                        Control Name must be different from existing controls
                    </span>
                </label>
                <itc-input
                    style="margin-top: 2px"
                    [control]="clonedControlNameField"
                    (modelChange)="validateClonedControlName()"
                    debounce="250"
                    placeholder="Control Name"></itc-input>
            </div>
        </div>
    </modal-content>
    <modal-actions>
        <itc-button class="close" type="secondary" (onclick)="cloneControlModal.hide()">
            Close
        </itc-button>
        <itc-button
            icon="fa-clone"
            type="primary"
            (onclick)="cloneSelectedControl()"
            [disabled]="!clonedControlIdValid || !clonedControlNameValid"
            [loading]="isCloning">
            Clone
        </itc-button>
    </modal-actions>
</sm-modal>
