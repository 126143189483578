<div>
    <span
        style="color: #aaa"
        *ngIf="!activeQuestion?.RelatedControls || !activeQuestion?.RelatedControls?.length">
        No related controls.
    </span>
    <ng-container
        *ngIf="activeQuestion?.RelatedControls && activeQuestion?.RelatedControls?.length">
        <div class="overflowSidebarTable">
            <table class="itc-table riskIssues sidebarTable">
                <thead>
                    <tr>
                        <th style="white-space: nowrap; width: 180px">Response</th>
                        <th style="white-space: nowrap; width: 100px">Control ID</th>
                        <th style="white-space: nowrap; width: 220px">Control Name</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let rc of activeQuestion?.RelatedControls"
                        style="cursor: pointer"
                        (click)="onShowReadOnly(rc)">
                        <td>
                            <itc-button
                                type="color"
                                [color]="responseColor[rc.Response] || CM_COLORS.NOTCOVERED"
                                class="CAButton darkText">
                                {{
                                    rc.Response == 'Yes, with Technical Issues'
                                        ? 'Yes, with Issues'
                                        : rc.Response == ''
                                        ? 'Unanswered'
                                        : rc.Response
                                }}
                            </itc-button>
                        </td>
                        <td>
                            <span truncateText truncateWidth="98px" [title]]="rc.Control.ControlId">
                                {{ rc.Control.ControlId }}
                            </span>
                        </td>
                        <td>
                            <span
                                truncateText
                                truncateWidth="220px"
                                [truncateLines]="2"
                                [title]="rc.Control.ShortDesc.LocalizedText[culture]">
                                {{ rc.Control.ShortDesc.LocalizedText[culture] }}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- <table style="border-collapse: collapse">
            <thead>
                <tr>
                    <th style="white-space: nowrap; width: 25%">Control ID</th>
                    <th style="white-space: nowrap">Control Name</th>
                    <th style="white-space: nowrap; width: 30%">Response</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let rc of activeQuestion?.RelatedControls">
                    <td>{{ rc.Control.ControlId }}</td>
                    <td>{{ rc.Control.ShortDesc.LocalizedText[culture] }}</td>
                    <td>
                        <itc-button
                            type="color"
                            [color]="responseColor[rc.Response] || CM_COLORS.NOTCOVERED"
                            (onclick)="onShowReadOnly(rc)"
                            class="CAButton darkText">
                            {{
                                rc.Response == 'Yes, with Technical Issues'
                                    ? 'Yes, with Issues'
                                    : rc.Response == ''
                                    ? 'Unanswered'
                                    : rc.Response
                            }}
                        </itc-button>
                    </td>
                </tr>
            </tbody>
        </table> -->
    </ng-container>
</div>
