import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'environments/environment';
import { VpAuthService } from './vp-auth.service';
import { ErrorService } from 'app/core/error/error.service';

import { Vendor, AssignedAssessment, SiteParams, VendorAssessmentAcceptance } from './data';
import { Control } from 'app/sites/shared/controls/control.model';

@Injectable()
export class VendportalService {
    private apiUrl: string;

    constructor(
        private http: HttpClient,
        private authService: VpAuthService,
        private E: ErrorService
    ) {
        this.apiUrl = environment.apiUrl;
    }

    login(inviteId: string): Promise<any> {
        const url: string = this.apiUrl + 'vendportal/login';
        this.authService.clearToken();

        return this.http
            .post<any>(url, { InviteId: inviteId }, { observe: 'response' })
            .toPromise()
            .then((res) => {
                if (res.body && res.body.token) this.authService.setToken(res.body.token);
            });
        //.catch(this.E.error);
    }

    getMe(): Observable<Vendor> {
        const url: string = this.apiUrl + 'vendportal/me';

        return this.http.get<Vendor>(url);
    }
    getAssignedAssessments(): Observable<AssignedAssessment[]> {
        const url: string = this.apiUrl + 'vendportal/assessments';

        return this.http.get<AssignedAssessment[]>(url);
    }
    getAssignedAssessment(assessId: number): Observable<AssignedAssessment> {
        const url: string = this.apiUrl + 'vendportal/assessment/' + assessId;

        return this.http.get<AssignedAssessment>(url);
    }
    getControl(controlId: number): Observable<Control> {
        const url: string = this.apiUrl + 'vendportal/control/' + controlId;

        return this.http.get<Control>(url);
    }

    getSiteParams(): Observable<SiteParams> {
        const url: string = this.apiUrl + 'vendportal/siteparams';

        return this.http.get<SiteParams>(url);
    }

    save(responses: VendorAssessmentAcceptance[]): Observable<VendorAssessmentAcceptance[]> {
        const url: string = this.apiUrl + 'vendportal/acceptances';

        return this.http.post<VendorAssessmentAcceptance[]>(url, responses);
    }

    uploadAttachment(formdata: FormData): Promise<string[]> {
        let headers = new HttpHeaders();
        headers.append('Accept', 'application/json');

        //headers.append('Content-Type', 'multipart/form-data');
        let options = { headers: headers };

        const url: string = this.apiUrl + 'vendportal/attachments';
        return this.http.post<string[]>(url, formdata, options).toPromise();
    }

    downloadAttachment(assessmentId: number, controlId: number, fileName: string): Promise<any> {
        const url: string = this.apiUrl + 'vendportal/attachment';

        let par = new HttpParams()
            .append('assessId', '' + assessmentId)
            .append('controlId', '' + controlId)
            .append('fileName', fileName);

        return this.http.get(url, { params: par }).toPromise();
    }

    deleteAttachment(assessmentId: number, controlId: number, filename: string): Promise<string[]> {
        const url: string = this.apiUrl + 'vendportal/attachment';

        let par = new HttpParams()
            .append('assessId', '' + assessmentId)
            .append('controlId', '' + controlId)
            .append('fileName', filename);

        return this.http.delete<string[]>(url, { params: par }).toPromise();
    }

    private getVendorAssessmentModuleName(vendor: Vendor) {
        return "VendorAssessment_" + vendor.Id;
    }

    getVendorAssessmentLock(vendor: Vendor): Promise<any> {
        return this.getModuleLock(vendor.SiteId, this.getVendorAssessmentModuleName(vendor));
    }

    getModuleLock(siteId: number, moduleName: string): Promise<any> {
        const url: string = this.apiUrl + `vendportal/GetModuleLock/${siteId}/${moduleName}`;

        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    updateVendorAssessmentLock(vendor: Vendor, guid: string = '') {
        return this.updateModuleLock(vendor.SiteId, this.getVendorAssessmentModuleName(vendor),guid);
    }

    updateModuleLock(siteId: number, moduleName: string, guid: string = '') {
        const url: string = this.apiUrl + `vendportal/UpdateModuleLock/${siteId}/${moduleName}?guid=${guid}`;
        let req = {};
        return this.http
            .post(url, req)
            .toPromise()
            .then((res) => res);
    }

    insertVendorAssessmentLock(vendor: Vendor, guid: string = '') {
        return this.insertModuleLock(vendor.SiteId, this.getVendorAssessmentModuleName(vendor),guid);
    }

    insertModuleLock(siteId: number, moduleName: string, guid: string = '') {
        const url: string = this.apiUrl + `vendportal/InsertModuleLock/${siteId}/${moduleName}?guid=${guid}`;
        let req = {};
        return this.http
            .post(url, req)
            .toPromise()
            .then((res) => res);
    }

    deleteVendorAssessmentLock(vendor: Vendor, guid: string = '') {
        return this.deleteModuleLock(vendor.SiteId, this.getVendorAssessmentModuleName(vendor), guid);
    }

    deleteModuleLock(siteId: number, moduleName: string, guid: string = '') {
        const url: string = this.apiUrl + `vendportal/deleteModuleLock/${siteId}/${moduleName}?guid=${guid}`;

        return this.http
            .delete(url)
            .toPromise()
            .then((res) => res);
    }
}
