import { Directive } from '@angular/core';
import { AbstractControl, ValidationErrors, NG_VALIDATORS, Validator } from '@angular/forms';
import { validateIPAddressControl } from '../ipaddress.validator';
import { validDomain } from '../domain.validator';
import { hostnameRegex, hostnameRegexWithPossiblePort, domainNameRegex } from '../regex.constants';

@Directive({
    selector: '[validInternalScanTarget]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: ValidInternalScanTargetValidator, multi: true },
    ],
})
export class ValidInternalScanTargetValidator implements Validator {
    constructor() {}

    validate(control: AbstractControl<any, any>): ValidationErrors | null {
        return internalScanTargetValidation(control.value);
    }
}

export function validInternalScanTarget(control: AbstractControl): { [key: string]: any } | null {
    return internalScanTargetValidation(control.value);
}

@Directive({
    selector: '[validExternalScanTarget]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: ValidExternalScanTargetValidator, multi: true },
    ],
})
export class ValidExternalScanTargetValidator implements Validator {
    constructor() {}

    validate(control: AbstractControl<any, any>): ValidationErrors | null {
        return externalScanTargetValidation(control.value);
    }
}

export function validExternalScanTarget(control: AbstractControl): { [key: string]: any } | null {
    return externalScanTargetValidation(control.value);
}

function scanTargetValidation(val, regex): ValidationErrors | null {
    if (val === null || val === undefined || val === '') {
        return null;
    }

    return validateIPAddressControl(val) === null || regex.test(val)
        ? null
        : { invalidScanTarget: true };
}

export function internalScanTargetValidation(val): ValidationErrors | null {
        return scanTargetValidation(val, hostnameRegex);
}

export function externalScanTargetValidation(val): ValidationErrors | null {
    return scanTargetValidation(val, domainNameRegex);
}

export function validHostnameOrIP(control: AbstractControl): { [key: string]: any } | null {
    return scanTargetValidation(control.value, hostnameRegexWithPossiblePort);
}
