<h2>Employee Tracker Training Printable Report</h2>

<div
    style="
        margin-top: 12px;
        padding-top: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    ">
    <div class="donutsContainer">
        <sds-tracker-donut-component
            [cardMeta]="trainingInfo.DonutData.name"
            [grpNames]="trainingInfo.DonutData.groupNames"
            [chartData]="trainingInfo.DonutData.value"
            [centerText]="trainingInfo.DonutData.completePercent"
            [legendPosition]="'bottom'"
            [legendSeparator]="[true, false, true, false]"
            [diameter]="150"
            [centerTextSmall]="'Completed'"></sds-tracker-donut-component>
    </div>
</div>

<table class="itc-table" style="margin-bottom: 0 !important">
    <thead>
        <tr>
            <th style="margin-left: 5px; text-align: left">Name</th>
            <th style="text-align: left">Email</th>
            <th style="text-align: left">Status</th>
            <th style="text-align: left; padding-left: 50px">View Date</th>
        </tr>
    </thead>

    <tbody class="flex-table basic" style="margin-top: 0 !important">
        <tr *ngFor="let row of trainingInfo.EmployeeData">
            <td style="margin-left: 5px">{{ row.FirstName + ' ' + row.LastName }}</td>
            <td>{{ row.Email }}</td>
            <td style="padding: 12px; width: 150px">
                <div
                    class="statusBackGround"
                    *ngIf="row.Status == 'complete'"
                    [style.background-color]="
                        tableStatusBackground.COMPLETED_STATUS_TABLE_BACKGROUND
                    "
                    [style.color]="tableStatusBackground.COMPLETED_STATUS_TABLE_COLOR">
                    Completed
                </div>
                <div
                    class="statusBackGround"
                    *ngIf="row.Status == 'incomplete'"
                    [style.background-color]="tableStatusBackground.PENDING_STATUS_TABLE_BACKGROUND"
                    [style.color]="tableStatusBackground.PENDING_STATUS_TABLE_COLOR">
                    Incomplete
                </div>
                <div
                    class="statusBackGround"
                    *ngIf="row.Status == ''"
                    [style.background-color]="tableStatusBackground.PENDING_STATUS_TABLE_BACKGROUND"
                    [style.color]="tableStatusBackground.PENDING_STATUS_TABLE_COLOR">
                    Needs Attention
                </div>
                <div
                    class="statusBackGround"
                    *ngIf="row.Status == 'expired'"
                    [style.background-color]="tableStatusBackground.EXPIRED_STATUS_TABLE_BACKGROUND"
                    [style.color]="tableStatusBackground.EXPIRED_STATUS_TABLE_COLOR">
                    Expired
                </div>
            </td>
            <td style="padding-left: 50px">
                {{
                    !row.AnswerDate ? 'N/A' : (row.AnswerDate | accountDateTime | date : 'longDate')
                }}
            </td>
        </tr>
    </tbody>
</table>
