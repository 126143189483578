import { Component, OnInit, Input, Output, EventEmitter, AfterViewChecked } from '@angular/core';

@Component({
    selector: 'sds-site-list',
    templateUrl: './site-list.component.html',
    styleUrls: ['./site-list.component.css'],
})
export class SiteListComponent implements OnInit {
    @Input() sortBy: any;
    @Input() sortedSites: any;
    @Input() openDetails: any;
    @Input() viewModeUpdating: boolean;
    @Output() viewLoaded = new EventEmitter<any>(true);
    @Output() detailsClicked = new EventEmitter<any>();
    @Output() sortSites = new EventEmitter<any>();

    loadingComplete: boolean = true;

    constructor() {}

    ngOnInit() {}

    ngAfterViewChecked() {
        if (this.viewModeUpdating) {
            this.viewLoaded.emit(true);
        }
    }

    toggleDetails(t) {
        this.detailsClicked.emit(t);
    }

    toggleSort(s) {
        this.sortSites.emit(s);
    }
}
