import { Pipe, type PipeTransform } from '@angular/core';
import { get as _get } from 'lodash-es';

@Pipe({
    name: 'localized',
    standalone: true,
})
export class LocalizedPipe implements PipeTransform {
    transform(value: unknown, ...args: string[]): unknown {
        let rValue: string = _get(value, args[0]) || '';
        let lValue: string = _get(value, args[1]);
        let culture: string = args[2] || 'en-US';
        if (lValue) {
            if (lValue[culture] && lValue[culture].length) {
                rValue = lValue[culture];
            }
        }
        return rValue;
    }
}
