import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AuthHttp } from '../../core/auth';
import {
    Evidence,
    EvidenceTypeForSite,
} from '../site/compliance-manager/data-collection/evidence-compliance/evidence-compliance.model';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EvidenceComplianceService {
    private readonly apiUrl: string;

    constructor(private http: AuthHttp) {
        this.apiUrl = environment.apiUrl;
    }

    async getEvidenceTypes(siteId: number): Promise<EvidenceTypeForSite> {
        const url = this.apiUrl + `getevidencetypes/${siteId}`;
        return lastValueFrom(this.http.get(url));
    }

	async upsertEvidences(siteId: number, evidences: Evidence[]) {
		const url = this.apiUrl + `evidences/${siteId}`;
		return lastValueFrom(this.http.post(url, evidences));
	}

	async deleteEvidences(siteId: number, evidences: Evidence[]) {
		const url = this.apiUrl + `delete-evidences/${siteId}`;
        return lastValueFrom(this.http.post(url, evidences));
	}

    async downloadEvidence(siteId: number, evidence: Evidence) {
        const url = this.apiUrl + `download-evidence/${siteId}`;
        return lastValueFrom(this.http.post(url, evidence));
    }
}
