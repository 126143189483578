import { Component, Input, OnInit } from '@angular/core';

import { Breadcrumb } from './breadcrumb.model';
import { Site } from 'app/sites/shared/site.model';
import { SITE, PING_SITE } from 'app/sites/shared/constants';
import { MessageService } from 'app/core/message.service';
import { AuthService } from '../../core/auth/auth.service';
import { SiteService } from 'app/sites/shared/site.service';

@Component({
    selector: 'sds-breadcrumb-trail',
    templateUrl: './breadcrumb-trail.component.html',
    styleUrls: ['./breadcrumb-trail.component.css'],
})
export class BreadcrumbTrailComponent implements OnInit {
    @Input() crumbs: Breadcrumb[];
    limitedAccess = false;
    sSub: any;
    siteInfo: Breadcrumb[] = [];

    constructor(
        private authService: AuthService,
        private messageService: MessageService,
        private siteService: SiteService
    ) {}

    ngOnInit() {
        this.limitedAccess = this.authService.isOnlySME();

        this.getSiteInfo();

        if (this.limitedAccess == null) {
            this.sSub = this.messageService.on(SITE).subscribe((site: Site) => {
                this.onSite(site);
            });
        }
    }

    onSite(site) {
        // we can't check if onlysme until we have the site set, so listen for message
        // this really only happens if they direct link or refresh on the page.
        this.limitedAccess = this.authService.isOnlySME();
    }

    getSiteInfo() {
        if (this.siteInfo.length == 0) {
            let site = this.siteService.getCurrentSite();

            if (site?.Name) {
                this.siteInfo.unshift({ path: '..', text: site.Name });

                if (site.Organization) {
                    this.siteInfo.unshift({
                        path: '/organizations/' + site.Organization,
                        text: site.Organization,
                        link: true,
                    });
                }
            }
            else {
                this.sSub = this.messageService.on(SITE).subscribe((site: Site) => {
                    this.getSiteInfo();
                });
            }
        }
    }

    ngOnDestroy() {
        if (this.sSub) {
            this.sSub.unsubscribe();
        }
    }
}
