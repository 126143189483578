import { Component, OnDestroy, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';

import { MessageService } from 'app/core/message.service';
import { ScanDataService, ScanDataUser } from 'app/scans';
import { UiService } from 'app/core/ui.service';

import { Site } from 'app/sites/shared/site.model';
import { PING_SITE, SITE } from 'app/sites/shared/constants';
// import { SIDEBAR_LOADER } from 'app/shared/sidebar/layout/sidebar-layout.component';
import { FilterOption } from 'app/sites/shared/data-filter.model';
import { SiteService } from 'app/sites/shared/site.service';
import { NdpService } from 'app/sites/shared/ndp.service';
import { Router } from '@angular/router';
import { AssessmentService } from '../../../shared/assessment.service';
import { SiteSettingService } from '../../settings/site-settings.service';

@Component({
    selector: 'sds-site-data-users',
    templateUrl: './site-data-users.component.html',
    styleUrls: ['./site-data-users.component.css'],
})
export class SiteDataUsersComponent implements OnInit, OnDestroy {
    constructor(
        private messageService: MessageService,
        private scanDataService: ScanDataService,
        private datePipe: DatePipe,
        private uiService: UiService,
        private siteService: SiteService,
        private ndpService: NdpService,
        private assessmentService: AssessmentService,
        private router: Router
    ) {}

    users: ScanDataUser[] = [];
    filtered1: ScanDataUser[] = [];
    filteredUsers: ScanDataUser[] = [];
    sortedUsers: ScanDataUser[] = [];
    pagedUsers: ScanDataUser[] = [];

    numActive: number = 0;
    numEnabled: number = 0;
    sortColumn: string = '';
    sortDirection: string = '';
    filterOptions: FilterOption[] = [
        { id: '_all', name: 'All' },
        { id: '_active', name: 'Active' },
        { id: '_inactive', name: 'Inactive' },
        { id: '_enabled', name: 'Enabled' },
        { id: '_notEnabled', name: 'Not Enabled' },
    ];

    loadingComplete = false;
    mergeInProgress: boolean = false;
    emptyStateText: string = 'There is no scan data in the current assessment.';
    sub: any;
    dataExplorerType = 'Indoc';

    breadcrumbs = [{ text: 'Network Detective' }, { text: 'Data Explorer' }, { text: 'Users' }];

    ngOnInit() {
        this.dataExplorerType = this.ndpService.getDataExplorerType();
        this.sub = this.messageService.on(SITE).subscribe(async (site: Site) => {
            if (site) {
                this.uiService.setTitle('Users', site.Name);
                const assessmentId = await this.ndpService.getAssessmentIdForDataExplorer(
                    site,
                    this.dataExplorerType
                );

                if (assessmentId) {
                    this.assessmentService
                        .getProgressStatusBySiteParams(site.Id, assessmentId, [
                            'NEEDS_NDP_MERGE',
                            'UPDATE_NDP_PORTALDATA',
                        ])
                        .then((res) => {
                            this.mergeInProgress = res;
                        });
                }
                this.getUsers(site, assessmentId);
            }
        });

        this.messageService.broadcast(PING_SITE);

        this.ndpService.createDataExplorerBreadcrumbs('Users');
    }

    getUsers(site: Site, assessmentId: number) {
        this.scanDataService
            .getUsers(site.Id, false, assessmentId)
            .then((users: ScanDataUser[]) => {
                this.users = users;

                this.numActive = 0;
                this.numEnabled = 0;

                for (let user of users) {
                    if (user.Active) this.numActive++;
                    if (user.Enabled) this.numEnabled++;
                }

                this.sortColumn = 'Username';
                this.sortDirection = 'asc';
                this.filterUsers();

                this.emptyStateText = this.scanDataService.getEmptyStateText(
                    this.users,
                    this.mergeInProgress,
                    'user'
                );
                this.loadingComplete = true;
            });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    filterUsers(filteredData?: any) {
        if (filteredData) this.filteredUsers = filteredData;
        else this.filteredUsers = this.users;

        this.filtered1 = this.filteredUsers;
        this.sortUsers();
    }

    sortUsers() {
        if (this.sortColumn != 'LastLoginFormatted') {
            this.sortedUsers = this.filteredUsers.sort((a, b) => {
                let cola = a[this.sortColumn],
                    colb = b[this.sortColumn];

                if (typeof cola === 'string') cola = cola.toLocaleLowerCase();
                if (typeof colb === 'string') colb = colb.toLocaleLowerCase();

                if (this.sortDirection === 'desc') {
                    return cola < colb ? 1 : cola === colb ? 0 : -1;
                } else {
                    return cola > colb ? 1 : cola === colb ? 0 : -1;
                }
            });
        }
        //this is to properly sort the dates. To deal with the problem of "Never" not wanting to sort properly in ascending order, first sort to descending then ascending
        if (this.sortColumn == 'LastLoginFormatted') {
            //sort in descending order
            if (this.sortDirection === 'desc') {
                this.sortedUsers = this.filteredUsers.sort((a, b) => {
                    let cola = a[this.sortColumn],
                        colb = b[this.sortColumn];

                    if (cola == 'Never') {
                        return cola === colb ? 0 : 1;
                    } else {
                        (cola = new Date(a[this.sortColumn])),
                            (colb = new Date(b[this.sortColumn]));
                        return cola < colb ? 1 : cola === colb ? 0 : -1;
                    }
                });
            }
            //sort in ascending order
            else {
                this.sortedUsers = this.filteredUsers.sort((a, b) => {
                    //to sort in ascending order, first sort in descending order to gather the logins that are "Never"
                    let cola = a[this.sortColumn],
                        colb = b[this.sortColumn];

                    if (cola == 'Never') {
                        return cola === colb ? 0 : 1;
                    } else {
                        (cola = new Date(a[this.sortColumn])),
                            (colb = new Date(b[this.sortColumn]));
                        return cola < colb ? 1 : cola === colb ? 0 : -1;
                    }
                });

                this.sortedUsers = this.filteredUsers.sort((a, b) => {
                    //then sort in ascending order
                    let cola = a[this.sortColumn],
                        colb = b[this.sortColumn];

                    if (cola == 'Never') {
                        return cola === colb ? 0 : -1;
                    } else {
                        (cola = new Date(a[this.sortColumn])),
                            (colb = new Date(b[this.sortColumn]));
                        return cola > colb ? 1 : cola === colb ? 0 : -1;
                    }
                });
            }
        }
        this.filteredUsers = [...this.sortedUsers];
    }

    onFiltered($event) {
        if ($event) this.filteredUsers = $event;

        console.log(this.filteredUsers);
        this.filterUsers(this.filteredUsers);
    }

    onSorted(ev?: any) {
        if (ev) {
            this.sortColumn = ev.sortColumn;
            this.sortDirection = ev.sortDirection;
            this.sortUsers();
        }
    }

    getDateText(dateString: string) {
        let date = new Date(dateString);
        if (date.getTime() < new Date(1601, 1, 1).getTime()) return 'Never';
        else return this.datePipe.transform(date.toISOString(), 'medium');
    }
}
