<div class="employeeDonutContainer" (click)="emitCardClick()">
    <p class="cardType">{{ cardHeader }}</p>
    <p class="cardStandard" [title]="cardMeta">{{ cardMeta }}</p>
    <!--<div class="tooltip" *ngIf="trimedTitle != ''"> <p class="cardStandard">{{trimedTitle}}</p>
    <span class="tooltiptext">{{cardMeta}}</span>
  </div>-->
    <p class="normalText" *ngIf="grpSlicedStringName == ''">{{ grpNameString }}</p>
    <div class="tooltip" *ngIf="grpSlicedStringName != ''">
        <p class="normalText">{{ grpSlicedStringName }}</p>
        <span class="tooltiptext">{{ grpNameString }}</span>
    </div>
    <p class="normalText">{{ _totalCount }} {{ measure }}</p>
    <div class="donutContainer" (click)="emitChartClick()">
        <itc-legacy-donut-chart
            [data]="chartData"
            [legendSeparator]="legendSeparator"
            [centerTextLarge]="centerText"
            [centerTextSmall]="centerTextSmall"
            [diameter]="diameter"
            [showLegend]="true"
            [legendPosition]="legendPosition ? legendPosition : 'right'"></itc-legacy-donut-chart>
    </div>
</div>
