<sm-modal title="{{ modalTitle }}" class="xs exc" #showExcModal>
    <modal-content>
        <ng-container>
            <div class="exc-p8">
                <div>
                    {{ command }}
                    <span class="exc-Title" *ngIf="objectTitle">{{ objectTitle }}</span>
                </div>
                <div>{{ explanation }}</div>
            </div>
            <div class="exc-p8p8">
                <label class="exc-Label">
                    {{ label }}&nbsp;
                    <span class="exc-asterisc">*</span>
                </label>
                <textarea
                    name="noteInput"
                    [formControl]="noteInputControl"
                    class="exc-noteInput"
                    maxlength="255"
                    pInputTextarea></textarea>
            </div>
        </ng-container>
    </modal-content>
    <modal-actions>
        <div class="exc-countField" *ngIf="countInfo">
            <b>{{ countInfo }}</b>
            &nbsp; selected
        </div>
        <div>
            <itc-button class="button" type="secondary" (onclick)="doCancel()">
                Cancel
            </itc-button>
            <itc-button
                class="button"
                type="primary"
                (onclick)="doSave()"
                [disabled]="!(noteInputControl.value || _allowSaveOnVoid)">
                Save
            </itc-button>
        </div>
    </modal-actions>
</sm-modal>
