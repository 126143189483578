<p-multiSelect
    #dropdown
    [options]="_options"
    [formControl]="control"
    inputId="control"
    (onChange)="_onSelectedItem($event)"
    (onClick)="dropdownSelectClicked()"
    optionLabel="Label"
    optionValue="Value"
    optionDisabled="Disabled"
    [filter]="false"
    [showHeader]="false"
    [showToggleAll]="false"
    [appendTo]="'body'"
    [dropdownIcon]="'fa6-chevron-down'"
    [placeholder]="placeholder"
    [defaultLabel]="defaultLabel"
    [disabled]="disabled"
    class="wideDropdown"
    [class.typableDropdown]="_typable"
    [class.showEmpty]="_typable">
    <ng-template let-value pTemplate="selectedItems">
        <ng-container *ngIf="control.value.length === 0 && !_typable && placeholder">
            <itc-tag type="chip" [closable]="false" color="default" class="placeholderTag"> {{ placeholder }} </itc-tag>
        </ng-container>
        <ng-container *ngIf="control.value.length && control.value.length <= _maxItems">
            <itc-tag type="chip"
                     [closable]="true"
                     color="default"
                     *ngFor="let option of control.value"
                     (closed)="_clearItem(option)">
                {{ optionLabelDict[option] }}
            </itc-tag>
        </ng-container>
        <itc-tag
            type="chip"
            [closable]="true"
            color="default"
            *ngIf="control.value.length && control.value.length > _maxItems"
            (closed)="_clearItem()">
            {{ control.value.length }} Selected
        </itc-tag>
        <span class="dropdownAddText" *ngIf="_typable">
            <input
                type="text"
                [formControl]="innerControl"
                (click)="dropdown.show();$event.stopPropagation()"
                (change)="_addOption()"
                [disabled]="disabled || control.disabled" />
        </span>
    </ng-template>
    <ng-template pTemplate="item" let-value>
        <itc-checkbox
            [stopBubbling]="true"
            [checked]="control.value.indexOf(value.Value) > -1"></itc-checkbox>
        {{ value.Label }}
    </ng-template>
</p-multiSelect>
