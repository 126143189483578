<div class="ui big breadcrumb" *ngIf="crumbs?.length">
    <ng-template ngFor let-crumb [ngForOf]="siteInfo">
        <a class="ui section"
           [routerLink]="crumb.path"
           *ngIf="crumb.text && !limitedAccess && crumb.link">
            {{ crumb.text }}
        </a>
        <span class="ui section" *ngIf="limitedAccess || !crumb.link">{{ crumb.text }}</span>
        /
    </ng-template>
    <a class="ui section" *ngIf="!limitedAccess && crumbs[0].link" [routerLink]="crumbs[0].path">
        {{ crumbs[0].text }}
    </a>
    <span class="ui section" *ngIf="limitedAccess || !crumbs[0].link">{{ crumbs[0].text }}</span>

    <ng-template ngFor let-crumb [ngForOf]="crumbs.slice(1)">
        /
        <a class="ui section"
           [routerLink]="crumb.path"
           *ngIf="crumb.text && !limitedAccess && crumb.link">
            {{ crumb.text }}
        </a>
        <span class="ui section" *ngIf="limitedAccess || !crumb.link">{{ crumb.text }}</span>
    </ng-template>
</div>
