import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';
import { AuthHttp } from 'app/core/auth';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AssessmentService {
    private apiUrl: string;

    constructor(private http: AuthHttp) {
        this.apiUrl = environment.apiUrl;
    }

    getAssessmentNames(siteId: number): Promise<string[]> {
        const url: string = this.apiUrl + `Sites/${siteId}/Assessments`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getAssessment(siteId: number, name: string): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/Assessments/${name}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getAssessmentRisks(siteId: number, name: string): Promise<any> {
        const url: string = this.apiUrl + `Sites/${siteId}/Assessments/${name}/Risks`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getSiteAssessmentParam(
        siteId: number,
        assessmentId: number,
        pname: string
    ): Promise<SiteAssessmentParam> {
        const url: string =
            this.apiUrl + `Sites/${siteId}/Assessments/${assessmentId}/AssessmentParams/${pname}`;
        return lastValueFrom(this.http.get(url)).then((res) => res as SiteAssessmentParam);
    }

    async getProgressStatusBySiteParams(siteId: number, assessmentId: number, pNames: string[]) {
        for (const pName of pNames) {
            const siteParam = await this.getSiteAssessmentParam(siteId, assessmentId, pName);
            if (siteParam && siteParam.PValue === 'TRUE') {
                return true;
            }
        }
        return false;
    }

    getArchivedAssesments(siteId: number, archiveGui: string): Promise<any> {
        const url: string = this.apiUrl + `GetArchivedAssessments/${siteId}/${archiveGui}`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    upsertSiteAssessmentParams(
        siteId: number,
        assessmentId: number,
        siteParams: SiteAssessmentParam[]
    ): Promise<any> {
        const url: string =
            this.apiUrl + `Sites/${siteId}/Assessments/${assessmentId}/AssessmentParams`;
        return lastValueFrom(this.http.post(url, siteParams)).then((res) => res);
    }
}

export class SiteAssessmentParam {
    public SiteId: number;
    public AssessmentId: number;
    public PName: string;
    public PValue: string;
}
