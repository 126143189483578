import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ToastComponent } from './toast.component';
import { ToastService } from './toast.service';
import { ToastAreaComponent } from './toast-area/toast-area.component';

@NgModule({
    imports: [CommonModule],
    declarations: [ToastComponent, ToastAreaComponent],
    providers: [ToastService],
    exports: [ToastComponent, ToastAreaComponent],
})
export class ToastModule {}
