import { Injectable } from '@angular/core';
import { AuthService } from '../core/auth/auth.service';

const VP_TOKEN_NAME: string = 'vendportal_authtoken';
@Injectable()
export class VpAuthService extends AuthService {
    getToken(): string {
        return localStorage.getItem(VP_TOKEN_NAME);
    }

    setToken(t: string) {
        localStorage.setItem(VP_TOKEN_NAME, t);

        this.twoFactorRequired = false;
    }

    clearToken() {
        localStorage.removeItem(VP_TOKEN_NAME);
    }

    logout() {
        this.clearToken();

        this.router.navigateByUrl('/vendor/login');
    }
}
