import { Directive } from '@angular/core';
import { MultiSelect } from 'primeng/multiselect';

@Directive({
    selector: 'p-multiSelect',
})
export class PrimengMultiselectDirective {
    constructor(private multiselect: MultiSelect) {
        this.multiselect.filter = false;
        this.multiselect.showHeader = false;
        this.multiselect.showToggleAll = false;
        this.multiselect.appendTo = 'body';
        this.multiselect.dropdownIcon = 'fa6-chevron-down';
    }
}
