import { ChangeDetectorRef, Component, OnDestroy, OnInit, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Alert } from 'app/alerts';
import { AlertService } from 'app/alerts/shared/alert.service';
import { ALERT_STATUSES } from 'app/alerts/shared/constants';
import { AuthService } from 'app/core/auth';
import { ErrorService } from 'app/core/error/error.service';
import { MessageService } from 'app/core/message.service';
import { UiService } from 'app/core/ui.service';
import { NotificationService } from 'app/shared/itc/notification/notification.service';
import { Site } from 'app/sites/shared/site.model';
import { SITE, PING_SITE } from 'app/sites/shared/constants';
import { environment } from 'environments/environment';
import { MenuItem } from 'primeng/api';

@Component({
    templateUrl: './site-alerts.component.html',
    styleUrls: ['./site-alerts.component.css'],
})
export class SiteAlertsComponent implements OnInit, OnDestroy {
    constructor(
        private alertService: AlertService,
        private authService: AuthService,
        private errorService: ErrorService,
        private messageService: MessageService,
        private uiService: UiService,
        private router: Router,
        private _cdr: ChangeDetectorRef,
        private notificationService: NotificationService
    ) {}

    alerts: Alert[];

    statusFilter: string;
    searchKey: string;
    statusFiltered: Alert[];
    timeFiltered: Alert[];
    filteredAlerts: Alert[];
    allAlertsChecked: boolean = false;
    loadingComplete: boolean;
    sortedAlerts: Alert[];
    site: Site;

    sizes = [15, 25, 50, 100];
    pageSize: number = 25;
    pageNumber: number = 0;
    pagedAlerts: Alert[];
    sortMethod: string = 'Created';
    sortDirection: string = 'desc';
    deleting: boolean = false;
    totalAlerts: number;
    sub: Subscription;

    IAloadingComplete: boolean;
    ignoredAlerts: any[];
    selectedIA: string[] = [];
    bulkActions: MenuItem[];
    selectedAlerts: string[] = [];

    breadcrumbs = [
        { path: '..', text: 'Cyber Hawk' },
        { path: '.', text: 'Alerts' },
    ];

    ngOnInit() {
        this.sub = this.messageService.on(SITE).subscribe((site: Site) => {
            if (!site || (this.site && site.Id == this.site.Id)) return;

            this.site = site;
            this.uiService.setTitle('Alerts', site.Name);
            this.alertService
                .getSiteAlerts(this.site.Id)
                .then((alerts) => this.process(alerts))
                .catch(() => {
                    this.loadingComplete = true;
                    this.errorService.error();
                });
        });
        this.messageService.broadcast(PING_SITE);
    }

    createBulkActions() {
        this.bulkActions = [];
        this.bulkActions = [
            {
                label: this.selectedAlerts.length + ' Selected',
                items: [
                    {
                        label: 'Delete',
                        disabled: !this.selectedAlerts.length,
                        command: () => this.deleteNotification(),
                    },
                ],
            },
        ];
    }

    checkAlert(ignoredAlert: any) {
        let id = this.createId(ignoredAlert);
        let index = this.selectedAlerts.findIndex((p) => p === id);
        if (index > -1) {
            this.selectedAlerts.splice(index, 1);
        } else {
            this.selectedAlerts.push(id);
        }
        this.createBulkActions();
    }

    checkAllAlerts(status: boolean) {
        this.ignoredAlerts.forEach((alert) => {
            alert.Checked = status;
        });
        this.allAlertsChecked = status;
        this.selectedAlerts = status ? this.ignoredAlerts.map((p) => this.createId(p)) : [];
        this.createBulkActions();
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    process(alerts: Alert[]) {
        for (let alert of alerts) {
            //alert.SiteName = this.site.Name;
            if (alert.AlertData != '') alert.DataObject = JSON.parse(alert.AlertData);
        }
        // Filter out TASK type
        this.alerts = alerts.filter(
            (a) =>
                a.Status != ALERT_STATUSES.TASK.value &&
                a.Status != ALERT_STATUSES.TASK_COMPLETE.value &&
                a.Status != ALERT_STATUSES.TASK_PENDING.value
        );
        this._cdr.detectChanges(); //do this to avoid the ExpressionChangedAfterItHasBeenCheckedError
        if (!environment.production) console.log(this.alerts);

        this.loadingComplete = true;
    }

    fetchIgnored() {
        this.IAloadingComplete = false;
        this.alertService
            .getSiteIgnoredAlerts(this.site.Id)
            .then((alerts) => {
                this.ignoredAlerts = alerts;
                this.selectedIA = [];
                this.IAloadingComplete = true;
                this.selectedAlerts = [];
                this.totalAlerts = alerts?.length;
                this.createBulkActions();
            })
            .catch(() => {});
    }
    deleteIgnoredItems() {
        this.deleting = true;
        this.alertService
            .deleteIgnoredAlertItems(this.selectedIA)
            .then((res) => {
                this.deleting = false;
                this.notificationService.toast.success('Success', 'Notifications were deleted');
            })
            .catch(() => {
                this.deleting = false;
                this.notificationService.toast.error('Error', 'There were some errors');
            });
    }

    error(err: any) {}

    filterAlerts() {
        this.filteredAlerts = this.alerts.filter((alert) => {
            return (
                (this.statusFilter
                    ? alert.Status.toLowerCase() == this.statusFilter.toLowerCase()
                    : true) &&
                (this.searchKey
                    ? alert.Message.toLowerCase().indexOf(this.searchKey.toLowerCase()) > -1
                    : true)
            );
        });

        this.filteredAlerts = this.filteredAlerts.sort((a, b) => {
            return new Date(b.Created).getTime() - new Date(a.Created).getTime();
        });

        this.pageAlerts();
    }

    sortAlerts() {
        if (this.sortMethod === 'Created' || this.sortMethod === undefined) {
            this.sortedAlerts = this.filteredAlerts.sort((a, b) => {
                if (this.sortDirection === 'asc') {
                    return new Date(a.Created).getTime() - new Date(b.Created).getTime();
                } else {
                    return new Date(b.Created).getTime() - new Date(a.Created).getTime();
                }
            });
        } else {
            this.sortedAlerts = this.filteredAlerts.sort((a, b) => this.sortByColumn(a, b));
        }
        this.pageAlerts();
    }

    pageAlerts(ev?: any) {
        if (ev) {
            this.pageSize = ev.pageSize;
            this.pageNumber = ev.pageNumber;
        }

        this.pagedAlerts = this.filteredAlerts.slice(
            this.pageNumber * this.pageSize,
            this.pageNumber * this.pageSize + this.pageSize
        );
    }

    onDetails(event, guid) {
        this.router.navigate(['alert', guid]);
    }

    processSort(sortBy: string) {
        this.sortDirection = sortBy.charAt(0) === '-' ? 'desc' : 'asc';
        this.sortMethod = sortBy.replace('-', '');
        if (isDevMode()) {
            console.log('sortBy: ' + this.sortMethod + ', Direction: ' + this.sortDirection);
        }
        this.sortAlerts();
    }

    cmp(a, b) {
        if (a > b) {
            return this.sortDirection === 'asc' ? 1 : -1;
        }
        if (a < b) {
            return this.sortDirection === 'asc' ? -1 : 1;
        }
        return 0;
    }
    cmpTime(a, b) {
        if (this.sortDirection === 'asc') {
            return new Date(a).getTime() - new Date(b).getTime();
        } else {
            return new Date(b).getTime() - new Date(a).getTime();
        }
    }

    sortByColumn(a, b) {
        a[this.sortMethod] = a[this.sortMethod] === undefined ? '' : a[this.sortMethod];
        b[this.sortMethod] = b[this.sortMethod] === undefined ? '' : b[this.sortMethod];
        return (
            this.cmp(a[this.sortMethod], b[this.sortMethod]) || this.cmpTime(a.Created, b.Created)
        );
    }

    removedIgnoredAlert(ignoredAlert: any) {
        this.selectedIA.push(this.createId(ignoredAlert));
        this.ignoredAlerts = this.ignoredAlerts.filter(
            (item) => this.createId(item) !== this.createId(ignoredAlert)
        );

        this.selectedAlerts = this.selectedAlerts.filter(
            (item) => item !== this.createId(ignoredAlert)
        );

        this.createBulkActions();
    }

    deleteNotification(): void {
        this.ignoredAlerts = this.ignoredAlerts.filter(
            (item) =>
                !this.selectedAlerts.some((removeItem) => {
                    return removeItem === this.createId(item);
                })
        );

        this.selectedAlerts = this.selectedAlerts.filter(
            (item) => !this.selectedIA.some((removeitem) => item === removeitem)
        );

        this.selectedIA.push(...this.selectedAlerts);
        this.selectedAlerts = [];
        this.createBulkActions();
    }

    createId(ignoredAlert: any) {
        return (
            ignoredAlert.alertId + '-' + ignoredAlert.groupId + (ignoredAlert.isNotif ? 'n' : '')
        );
    }
}
