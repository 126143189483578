import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { environment } from 'environments/environment';
import { AuthHttp } from 'app/core/auth';
import { SiteUser, SiteUsersService, SmeUser } from 'app/sites/shared/site-users.service';

import { differenceWith, findIndex, isEqual, pull } from 'lodash-es';
import { NotificationService } from '../itc/notification/notification.service';

declare var $: any;

@Component({
    selector: 'sds-invite-sme',
    templateUrl: './invite-sme.component.html',
    styleUrls: ['./invite-sme.component.css'],
})
export class InviteSmeComponent implements OnInit {
    constructor(
        private siteUsersService: SiteUsersService,
        private notificationService: NotificationService,
        private http: AuthHttp
    ) {
        this.apiUrl = environment.apiUrl;
    }

    private apiUrl: string;

    siteId: number;
    siteName: string;
    surveyName: string;
    formFileName: string;
    selectionError: boolean = false;
    siteSMEs: SiteUser[];
    addableSMEs: SiteUser[];
    selectedSMEs: SiteUser[];
    newSMEs: string[];
    existingSelection: string[];
    emailSMEs: boolean = false;
    inviteButtonLoading: boolean = false;
    emailInviteBody: string;
    emailInviteSubject: string;
    emailInviteTo: string[];
    siteType: string;
    addingSME: boolean = false;
    @ViewChild('addSMEModal', { static: true }) addSMEModal: ElementRef;
    @ViewChild('inviteModal', { static: true }) inviteModal: ElementRef;

    @Output() loadedCallback = new EventEmitter();
    // @Output() savedCallback = new EventEmitter();
    // @Output() emailedCallback = new EventEmitter();
    // @Output() removedCallback = new EventEmitter();
    @Output() invitesUpdated = new EventEmitter();

    ngOnInit() {
        $('.coupled.modal').modal({ allowMultiple: false, closeable: false });
    }

    addSME() {
        if (!this.newSMEs.length) {
            this.selectionError = true;
            return false;
        }
        this.addingSME = true;
        this.notificationService.toast.info('Processing', 'Adding Subject Matter Expert');

        // u is username
        for (let u of this.newSMEs) {
            // add user invitations to database

            let su = new SmeUser();
            su.Username = u;
            su.SiteId = this.siteId;
            su.Formname = this.surveyName;

            this.siteUsersService.addSiteSme(this.siteId, su).then((s) => {
                // set data to selectedSmes
                this.selectedSMEs.push(
                    this.siteSMEs[findIndex(this.siteSMEs, (sme) => sme.Username === u)]
                );
                this.addableSMEs = this.addableSMEs.filter((user) => user.Username !== u);
                this.invitesUpdated.emit({ users: this.selectedSMEs, formName: this.surveyName });
                this.addingSME = false;
                this.notificationService.toast.success('Success', 'Subject Matter Expert Added');
            });
        }
        $('.inviteUsers').dropdown('clear');
    }

    revokeSME(user: SiteUser) {
        this.notificationService.toast.info('Processing', 'Revoking invitation');
        this.siteUsersService
            .revokeSiteFormSmeInvitation(user, this.siteId, this.surveyName)
            .then((s) => {
                pull(this.selectedSMEs, user);
                this.addableSMEs.push(user);
                this.invitesUpdated.emit({ users: this.selectedSMEs, formName: this.surveyName });
                this.notificationService.toast.success('Success', 'Invitation Revoked');
            })
            .catch((res) => {
                $('.revokeSME.loaded').removeClass('loaded');
                this.notificationService.toast.error('Error', 'An unspecified error occurred.');
            });
    }

    addedUserEmail(ev: any) {
        this.selectionError = false;
        this.newSMEs = ev;
    }

    inviteOthers(
        siteId: number,
        surveyName: string,
        allSMEs: any = null,
        selSMEs: any = null,
        formFileName: string = null,
        siteName: string = null,
        siteType: string = null
    ) {
        this.siteId = siteId;
        this.surveyName = surveyName;
        this.formFileName = formFileName;
        this.siteName = siteName;
        this.siteSMEs = []; // all smes linked to site
        this.selectedSMEs = []; // smes invited to this form
        this.addableSMEs = []; // smes that are addable (siteSmes - selectedSmes)
        this.newSMEs = []; // new ones to add.
        this.siteType = siteType;
        if (allSMEs != null && selSMEs != null) {
            this.siteSMEs = [...allSMEs];
            this.selectedSMEs = selSMEs;
            // this.addableSMEs = differenceWith(allSMEs, selSMEs, isEqual);
            // can't use because objects returned missing values, so do it handle based on UserId
            this.addableSMEs = this.siteSMEs.filter(function (a) {
                return !selSMEs.some(function (b) {
                    return a.UserId === b.UserId;
                });
            });
            this.loadedCallback.emit();
            // attach event to go back to invite modal after closing addSMEModal
            $(this.inviteModal.nativeElement).modal('show');
        } else {
            this.siteUsersService
                .getSiteSmes(siteId)
                .then((s) => {
                    this.siteSMEs = [...s];

                    this.siteUsersService
                        .getSiteFormSmeInvitations(siteId, surveyName)
                        .then((s1) => {
                            this.selectedSMEs = [...s1];
                            this.addableSMEs = differenceWith(
                                this.siteSMEs,
                                this.selectedSMEs,
                                isEqual
                            );

                            this.loadedCallback.emit();
                            // attach event to go back to invite modal after closing addSMEModal
                            $(this.inviteModal.nativeElement).modal('show');
                        })
                        .catch((res) => {
                            this.notificationService.toast.error(
                                'Error',
                                'An unspecified error occurred.'
                            );
                        });
                })
                .catch((res) => {
                    this.notificationService.toast.error('Error', 'An unspecified error occurred.');
                });
        }
    }

    openAddModal() {
        let myUser: SiteUser;
        this.siteUsersService.getMyUser().then((s) => {
            myUser = s;
            ///site/{sitename}/audit-guru/assessments/forms/Q01NQyBBc3NldCBNYW5hZ2VtZW50IFdvcmtzaGVldC5pZGY0
            let url = '';
            if (this.siteType !== 'CM2') {
                url = `Please use the link below to assist in completing the ${this.surveyName}\n
      https://${window.location.hostname}/site/${encodeURI(
                    this.siteName
                )}/compliance-manager/assessments/forms/${encodeURIComponent(this.formFileName)} \n
      This request was made by ${myUser.FirstName} ${myUser.LastName} (${myUser.Username}).`;
            } else {
                url = `Please use the link below to assist in completing the ${this.surveyName}\n
      https://${window.location.hostname}/site/${encodeURI(
                    this.siteName
                )}/compliance-manager-grc/results-evidence/worksheets/${encodeURIComponent(
                    this.formFileName
                )} \n
      This request was made by ${myUser.FirstName} ${myUser.LastName} (${myUser.Username}).`;
            }

            this.emailInviteSubject = 'Assistance Requested';
            this.emailInviteTo = this.existingSelection;
            this.emailInviteBody = url;
            this.newSMEs = [];
            $('.inviteUsers').dropdown('clear');
            $(this.addSMEModal.nativeElement).modal('show');
        });
    }

    closeAddModal() {
        this.newSMEs = [];
        $('.inviteUsers').dropdown('clear');
        $(this.inviteModal.nativeElement).modal('hide all');
    }

    processInvitations() {
        this.notificationService.toast.info('Processing', 'Processing Requested Invitations');
        let ii: Invitation = new Invitation();
        ii.Body = this.emailInviteBody;
        ii.Subject = this.emailInviteSubject;
        ii.Recipients = [];
        ii.Recipients.push(...this.newSMEs);
        ii.SiteId = this.siteId;
        ii.Formname = this.surveyName;

        this.sendInformInvitation(ii)
            .then((s) => {
                this.addSME();
                this.notificationService.toast.success('Success', 'Invitations Sent');
                // $('#emailModal').modal('hide');
            })
            .catch((r) => {
                this.notificationService.toast.error(
                    'Error',
                    'Error occured during processing.  Invitations not sent.'
                );
            });
    }

    sendInformInvitation(req: Invitation): Promise<any> {
        const url: string = this.apiUrl + 'Inform/SendInvitation';
        return this.http
            .post(url, req, { observe: 'response' })
            .toPromise()
            .then((res) => res.status == 200);
    }

    ngOnDestory() {
        $(this.addSMEModal.nativeElement).modal('destroy').remove();
        $(this.inviteModal.nativeElement).modal('destroy').remove();
    }
}

export class Invitation {
    public Recipients: string[];
    public Subject: string;
    public Body: string;
    public Formname: string;
    public SiteId: number;
}
