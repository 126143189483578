import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'siteEncode',
})
export class SiteEncodePipe implements PipeTransform {
    transform(value: string): string {
        return (value ? value : '').replace(/&/g, '%26');
    }
}
