import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'sds-kvs-overview',
    templateUrl: './kvs-overview.component.html',
    styleUrls: ['./kvs-overview.component.css'],
})
export class KvsOverviewComponent implements OnInit {
    breadcrumbs = [
        { path: '../..', text: 'VulScan' },
        { path: '..', text: 'Settings' },
        { path: '.', text: 'Overview' },
    ];

    constructor() {}

    ngOnInit() {}
}
