import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { NdpService } from '../../sites/shared/ndp.service';
import { SiteParam, SiteSettingService } from 'app/sites/site/settings/site-settings.service';
import { SemanticModalComponent } from '../../semantic-legacy/components/modal/modal';
import {
    Issue,
    NdpIssueOverride,
    NDP_USE_GLOBAL_ISSUE_OVERRIDES_PNAME,
    HMLRanking,
} from '../../sites/site/network-detective-pro/ndpro.model';
import { MenuItem } from 'primeng/api';
import { filter, Subscription } from 'rxjs';
import { issueCategoryNames } from '../ndp-issues.constants';

@Component({
    selector: 'ndp-issue-overrides-portable',
    templateUrl: './ndp-issue-overrides-portable.component.html',
    styleUrls: ['./ndp-issue-overrides-portable.component.scss'],
})
export class NdpIssueOverridesPortableComponent {
    const 
    loadingComplete: boolean = false;
    pendingSave: boolean = false;
    constructor(private ndpService: NdpService, private siteSettingService: SiteSettingService) {}
    useGlobal: boolean = false;
    _siteId: number = -1;
    get siteId() {
        return this._siteId;
    }
    @Input() set siteId(value) {
        this._siteId = value;
        if (this._siteId != null) {
            this.initIssues();
        }
    }
    @Input() title: string = 'Issue Overrides';
    issues: Issue[] = []; //current issue data to display
    issueDefaults: Issue[] = []; //points to either issueGlobalDefaults or issueSiteDefaults based on where we are
    issueGlobalDefaults: Issue[] = []; //purely raw defaults for issues; what global resets to
    issueSiteDefaults: Issue[] = []; //raw default sev/prob/score, but global enable/disable; what site resets to
    issueGlobalPreview: Issue[] = []; //the global state (including sev/prob/score) for readonly view when enabling useGlobal
    bulkActions: MenuItem[];
    saveQueue: any = {};
    displayedCount = 0;
    selectedCount = 0;
    severityOptions = ['High', 'Medium', 'Low'];
    probabilityOptions = ['High', 'Medium', 'Low'];
    disableSevProd = {
        'BDR Assessment': true,
        'CARA Assessment': true,
        'SQL Assessment': true,
    };
    selectedIssue: Issue;
    overriddenIssues: any = {};
    @ViewChild('disableModal') disableModal: SemanticModalComponent;
    @ViewChild('enableModal') enableModal: SemanticModalComponent;
    @ViewChild('resetModal') resetModal: SemanticModalComponent;
    @ViewChild('resetSingleModal') resetSingleModal: SemanticModalComponent;
    @ViewChild('globalModal') globalModal: SemanticModalComponent;
    ngOnInit() {}

    initIssues() {
        this.ndpService.getIssues(this.siteId).then((issues) => {
            console.log('issues from backend', [...issues]);
            issues.forEach((issue) => {
                issue.Checked = false;
                issue.Enabled = true;
                issue.Category = issueCategoryNames[issue.Category];
            });
            this.issues = issues;
            this.issueGlobalDefaults = JSON.parse(JSON.stringify(this.issues));
            this.displayedCount = this.issues.length;
            this.createBulkActions();
            this.ndpService.getGlobalIssueOverrides(this.siteId).then((issueOverrides) => {
                if (this.siteId != -1) {
                    this.issueGlobalPreview = this.applyOverridesToIssues(
                        issueOverrides,
                        this.issueGlobalDefaults
                    );
                    this.issueSiteDefaults = this.applyOverridesToIssues(
                        issueOverrides,
                        this.issueGlobalDefaults,
                        true
                    );
                    this.overriddenIssues = {};
                    this.initSiteOverrides();
                } else {
                    this.overriddenIssues = {};
                    this.issueDefaults = JSON.parse(JSON.stringify(this.issueGlobalDefaults));
                    this.issues = this.applyOverridesToIssues(
                        issueOverrides,
                        this.issueGlobalDefaults
                    );
                    this.loadingComplete = true;
                }
            });
        });
    }

    async initSiteOverrides() {
        this.useGlobal = true;
        await this.siteSettingService
            .getSiteParam(this.siteId, NDP_USE_GLOBAL_ISSUE_OVERRIDES_PNAME)
            .then((spr) => {
                if (spr.Value == 'FALSE') {
                    this.useGlobal = false;
                }
            });
        this.ndpService.getIssueOverrides(this.siteId).then((siteIssueOverrides) => {
            if (siteIssueOverrides.length > 0) {
                this.useGlobal = false;
            }
            if (!this.useGlobal) {
                this.overriddenIssues = {};
                this.issueDefaults = JSON.parse(JSON.stringify(this.issueSiteDefaults));
                this.issues = this.applyOverridesToIssues(
                    siteIssueOverrides,
                    this.issueSiteDefaults
                );
            } else {
                this.issues = this.issueGlobalPreview;
            }
            this.loadingComplete = true;
        });
    }

    applyOverridesToIssues(
        issueOverrides: NdpIssueOverride[],
        _issues: Issue[],
        onlyEnabled: boolean = false
    ) {
        let issues = JSON.parse(JSON.stringify(_issues));
        issueOverrides.forEach((override) => {
            let idx = issues.findIndex((i) => i.Id == override.Id);
            if (idx > -1) {
                if (
                    (override.Enabled === true || override.Enabled === false) &&
                    issues[idx].Enabled != override.Enabled
                ) {
                    this.overriddenIssues[override.Id] = true;
                    issues[idx].Enabled = override.Enabled;
                }
                if (!onlyEnabled) {
                    if (
                        override.Probability != null &&
                        issues[idx].Probability != override.Probability
                    ) {
                        this.overriddenIssues[override.Id] = true;
                        issues[idx].Probability = override.Probability as HMLRanking;
                    }
                    if (override.Score != null && issues[idx].Score != override.Score) {
                        this.overriddenIssues[override.Id] = true;
                        issues[idx].Score = override.Score;
                    }
                    if (override.Severity != null && issues[idx].Severity != override.Severity) {
                        this.overriddenIssues[override.Id] = true;
                        issues[idx].Severity = override.Severity as HMLRanking;
                    }
                }
            }
        });
        return issues;
    }
    saveOverride(issue: Issue) {
        if (issue.Score < 0 || issue.Score > 100) return;
        let override: NdpIssueOverride = {
            Id: issue.Id,
            Severity: issue.Severity,
            Enabled: issue.Enabled,
            Probability: issue.Probability,
            Score: issue.Score,
        };
        if (this.pendingSave) {
            this.saveQueue[issue.Id] = override;
        } else {
            this.pendingSave = true;
            this.ndpService.saveIssueOverride(this.siteId, override).finally(() => {
                this.overriddenIssues[override.Id] = true;
                this.pendingSave = false;
                this.processSaveQueue();
            });
        }
    }
    processSaveQueue() {
        if (!this.pendingSave) {
            this.pendingSave = true;
            let overrides: NdpIssueOverride[] = Object.values(this.saveQueue);
            if (overrides.length > 0) {
                this.saveQueue = {};
                this.ndpService.saveIssueOverrides(this.siteId, overrides).finally(() => {
                    overrides.forEach((override) => {
                        this.overriddenIssues[override.Id] = true;
                    });
                    this.pendingSave = false;
                });
            } else {
                this.pendingSave = false;
            }
        }
    }

    changeUseGlobal() {
        if (this.useGlobal) {
            this.globalModal.show({ closable: false });
        } else {
            let newSettings: SiteParam[] = [];

            newSettings.push({
                SiteId: this.siteId,
                Name: NDP_USE_GLOBAL_ISSUE_OVERRIDES_PNAME,
                Value: 'FALSE',
            });
            this.siteSettingService.upsertSiteParams(this.siteId, newSettings);

            this.issueDefaults = JSON.parse(JSON.stringify(this.issueSiteDefaults));
            this.issues = JSON.parse(JSON.stringify(this.issueSiteDefaults));

            let overrides: NdpIssueOverride[] = [];
            this.issues.forEach((issue) => {
                overrides.push({
                    Id: issue.Id,
                    Severity: null,
                    Enabled: issue.Enabled,
                    Probability: null,
                    Score: null,
                });
            });
            this.ndpService.saveIssueOverrides(this.siteId, overrides);
            this.overriddenIssues = {};
        }
    }

    /** toggle single checkbox */
    toggleSelected(issue, state) {
        issue.Checked = state;
        this.getSelectedCount();
    }
    /** toggle select all checkbox */
    checkAllAssessments(state) {
        this.issues.forEach((issue) => (issue.Checked = state));
        this.getSelectedCount();
    }
    /** get the count of items that are selected */
    getSelectedCount() {
        this.selectedCount = this.issues.filter((i) => i.Checked).length;
        this.createBulkActions();
    }
    /** toggle multiple items from bulk action */
    toggleMultiple(state) {
        this.pendingSave = true;
        let overrides: NdpIssueOverride[] = [];
        this.issues.forEach((issue) => {
            if (issue.Checked) {
                issue.Enabled = state;
                overrides.push({
                    Id: issue.Id,
                    Enabled: state,
                });
            }
        });
        this.ndpService.saveIssueOverrides(this.siteId, overrides).finally(() => {
            overrides.forEach((override) => {
                this.overriddenIssues[override.Id] = true;
            });
            this.disableModal.hide();
            this.enableModal.hide();
            this.pendingSave = false;
        });
    }
    /** reset multiple items from bulk action */
    resetMultiple(resetAll: boolean = false) {
        this.pendingSave = true;
        if (resetAll) {
            let newSettings: SiteParam[] = [];

            newSettings.push({
                SiteId: this.siteId,
                Name: NDP_USE_GLOBAL_ISSUE_OVERRIDES_PNAME,
                Value: 'TRUE',
            });
            this.siteSettingService.upsertSiteParams(this.siteId, newSettings);
            this.issueDefaults = JSON.parse(JSON.stringify(this.issueGlobalPreview));
        }
        let overrides: NdpIssueOverride[] = [];
        this.issues
            .filter((i) => i.Checked || resetAll)
            .forEach((issue) => {
                let idx = this.issueDefaults.findIndex((i) => i.Id == issue.Id);
                issue.Enabled = this.issueDefaults[idx].Enabled;
                issue.Score = this.issueDefaults[idx].Score;
                issue.Probability = this.issueDefaults[idx].Probability;
                issue.Severity = this.issueDefaults[idx].Severity;
                overrides.push({
                    Id: issue.Id,
                    Severity: null,
                    Enabled: this.siteId == -1 || resetAll ? null : issue.Enabled,
                    Probability: null,
                    Score: null,
                });
            });
        this.ndpService.saveIssueOverrides(this.siteId, overrides).finally(() => {
            overrides.forEach((override) => {
                this.overriddenIssues[override.Id] = false;
            });
            this.resetModal.hide();
            this.globalModal.hide();
            this.pendingSave = false;
        });
    }
    resetSingle() {
        this.pendingSave = true;
        let override: NdpIssueOverride = new NdpIssueOverride();
        let idx = this.issueDefaults.findIndex((i) => i.Id == this.selectedIssue.Id);
        this.selectedIssue.Enabled = this.issueDefaults[idx].Enabled;
        this.selectedIssue.Score = this.issueDefaults[idx].Score;
        this.selectedIssue.Probability = this.issueDefaults[idx].Probability;
        this.selectedIssue.Severity = this.issueDefaults[idx].Severity;
        override = {
            Id: this.selectedIssue.Id,
            Severity: null,
            Enabled: this.siteId == -1 ? null : this.selectedIssue.Enabled,
            Probability: null,
            Score: null,
        };
        this.ndpService.saveIssueOverride(this.siteId, override).finally(() => {
            this.overriddenIssues[override.Id] = false;
            this.resetSingleModal.hide();
            this.pendingSave = false;
        });
    }
    startReset(issue: Issue) {
        this.selectedIssue = issue;
        this.resetSingleModal.show({ closable: false });
    }
    customSort(sortEvt) {
        sortEvt.data.sort((data1, data2) => {
            let value1;
            let value2;
            if (sortEvt.field === 'Probability' || sortEvt.field === 'Severity') {
                value1 =
                    data1[sortEvt.field] === 'High'
                        ? 'a'
                        : data1[sortEvt.field] === 'Medium'
                        ? 'b'
                        : 'c';
                value2 =
                    data2[sortEvt.field] === 'High'
                        ? 'a'
                        : data2[sortEvt.field] === 'Medium'
                        ? 'b'
                        : 'c';
            } else if (sortEvt.field === 'Score') {
                value1 = +data1[sortEvt.field];
                value2 = +data2[sortEvt.field];
            } else {
                value1 = data1[sortEvt.field];
                value2 = data2[sortEvt.field];
            }
            let result = null;
            if (value1 == null && value2 != null) {
                result = -1;
            } else if (value1 != null && value2 == null) {
                result = 1;
            } else if (value1 == null && value2 == null) {
                result = 0;
            } else if (typeof value1 === 'string' && typeof value2 === 'string') {
                result = value1.localeCompare(value2);
            } else {
                result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
            }
            if (result == 0 && sortEvt.field == 'Category') {
                value1 = data1['Name'];
                value2 = data2['Name'];
                result = value1.localeCompare(value2);
            }
            return sortEvt.order * result;
        });
    }
    rowClicked(issue, evt = null) {
        if (evt) {
            evt.stopPropagation();
        }
        if (this.useGlobal) {
            return;
        }
        issue.Checked = !issue.Checked;
        this.getSelectedCount();
    }
    // not using right now.
    // showDropdown(dd) {
    //     setTimeout(() => {
    //         dd.show();
    //     }, 1);
    // }
    filterContent(evt) {
        this.displayedCount = evt.filteredValue.length;
    }
    createBulkActions() {
        this.bulkActions = [
            {
                label: this.selectedCount + ' Selected',
                items: [
                    {
                        label: 'Enable Issues',
                        disabled: !this.selectedCount,
                        command: () => {
                            this.enableModal.show({ closable: false });
                        },
                    },
                    {
                        label: 'Disable Issues',
                        disabled: !this.selectedCount,
                        command: () => {
                            this.disableModal.show({ closable: false });
                        },
                    },
                    {
                        label: 'Reset Issues',
                        styleClass: 'danger',
                        disabled: !this.selectedCount,
                        command: () => this.resetModal.show({ closable: false }),
                    },
                ],
            },
        ];
    }
}
