export const ipRangeRegex = new RegExp(/^((?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(-(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))?)$/);
export const ipRegex = new RegExp(/^((?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/);

export const hostnameRegexWithPossiblePort = new RegExp(/^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])(:\d+)?$/);
export const hostnameRegex = new RegExp(/^(?=[a-z0-9])^((?!\.)([a-z0-9-]*))(\.((?!\.)([a-z0-9-]*)))*[a-z0-9]{2,}$/i);
export const domainNameRegex = new RegExp(/^(?=[a-z0-9])^((?!\.)([a-z0-9-]{2,}))(\.((?!\.)([a-z0-9-]*)))+[a-z0-9]{2,}$/i);

export const domainRegex = new RegExp(/^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9\-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})$/i);

// crazy regex from https://stackoverflow.com/questions/10306690/domain-name-validation-with-regex
export const fqdnRegex = new RegExp(/^(?!:\/\/)([a-zA-Z0-9]+\.)?[a-zA-Z0-9][a-zA-Z0-9-]+\.[a-zA-Z]{2,6}?$/i);
