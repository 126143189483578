import { Directive, HostBinding } from '@angular/core';
import {
    AbstractControl,
    ValidatorFn,
    ValidationErrors,
    NG_VALIDATORS,
    Validator,
} from '@angular/forms';

@Directive({
    selector: '[validIPAddress]',
    providers: [{ provide: NG_VALIDATORS, useExisting: validIpAddressValidator, multi: true }],
})

//We can use this validator with Template forms
export class validIpAddressValidator implements Validator {
    constructor() {}

    validate(control: AbstractControl): ValidationErrors | null {
        return validateIPAddressControl(control.value);
    }
}
export function validIpOrRange(control: AbstractControl): { [key: string]: any } | null {
    return validateIPAddressControl(control.value);
}

function isValidRangeIP(firstIP: string, secondIP: string): boolean {
    if (firstIP == secondIP) {
        return false;
    } else {
        // Convert the IPs to unsigned int
        const firstIPNumber = dot2num(firstIP);
        const secondIPNumber = dot2num(secondIP);
        return firstIPNumber < secondIPNumber;
    }
}

function dot2num(dot) {
    var d = dot.split('.');
    return ((+d[0] * 256 + +d[1]) * 256 + +d[2]) * 256 + +d[3];
}

export function validateIPAddressControl(val): ValidationErrors | null {
    // only validate if there's a value
    if (val === null || val === undefined || val === '') {
        return null;
    }
    // replaces the required validator to require a value and the value can't be just spaces
    const ip = val;
    const regex =
        /^((?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(-(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))?)$/;
    const match = regex.exec(ip);

    if (!match) {
        // If the input doesn't match the IP range format, check if it's a single IP address
        const singleIPRegex =
            /^((?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
        const singleIPMatch = singleIPRegex.exec(ip);

        if (!singleIPMatch) {
            // The input is neither a valid single IP address nor an IP address range
            return { invalidIP: true };
        }
    }

    // If it's a single IP address, or an IP address range, perform validation accordingly
    if (match) {
        const rangeIPs = match[0].split('-');
        if (rangeIPs.length === 1) {
            // Single IP address validation using the regex
            return null; // Valid single IP address
        } else if (rangeIPs.length === 2) {
            // IP range validation using the isValidRangeIP function
            const firstIP = rangeIPs[0];
            const secondIP = rangeIPs[1];
            if (isValidRangeIP(firstIP, secondIP)) {
                return null; // Valid IP address range
            } else {
                return { invalidIPRange: true };
            }
        }
    }

    return { invalidIP: true };
}
//We can use this ValidatorFn with Reactive forms
export function IpAddressValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const ip = control.value;
        const regex =
            /^((?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(-(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))?)$/;
        const match = regex.exec(ip);

        if (!match) {
            // If the input doesn't match the IP range format, check if it's a single IP address
            const singleIPRegex =
                /^((?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
            const singleIPMatch = singleIPRegex.exec(ip);

            if (!singleIPMatch) {
                // The input is neither a valid single IP address nor an IP address range
                return { invalidIP: true };
            }
        }

        // If it's a single IP address, or an IP address range, perform validation accordingly
        if (match) {
            const rangeIPs = match[0].split('-');
            if (rangeIPs.length === 1) {
                // Single IP address validation using the regex
                return null; // Valid single IP address
            } else if (rangeIPs.length === 2) {
                // IP range validation using the isValidRangeIP function
                const firstIP = rangeIPs[0];
                const secondIP = rangeIPs[1];
                if (isValidRangeIP(firstIP, secondIP)) {
                    return null; // Valid IP address range
                } else {
                    return { invalidIPRange: true };
                }
            }
        }

        return { invalidIP: true };
    };
}
