export class Evidence {
	ProductName: string;
	ProductIconUrl: string;
	Name: string;
	Description: string;
	CurrentCoverage: number;
	TotalCoverage: number;
	LastImported: Date;
	LastUpdated: Date;
	Status: 'Outdated' | 'Updated'
	Metrics: EvidenceMetric[];
	isChecked?: boolean
	isDeleting?: boolean;
    isReloading?: boolean;
    EvidenceTypeId: string;
    EvidenceId: string;

    constructor({ ProductName, ProductIconUrl, Name, Description, CurrentCoverage, TotalCoverage, LastImported, LastUpdated, Metrics, EvidenceTypeId, EvidenceId }) {
		this.ProductName = ProductName;
		this.ProductIconUrl = ProductIconUrl;
		this.Name = Name;
		this.Description = Description;
		this.CurrentCoverage = CurrentCoverage;
		this.TotalCoverage = TotalCoverage;
		this.LastImported = LastImported;
		this.LastUpdated = LastUpdated;
		this.Status = (!LastUpdated || LastImported < LastUpdated) ? "Outdated" : "Updated"
        this.Metrics = Metrics;
        this.EvidenceTypeId = EvidenceTypeId;
        this.EvidenceId = EvidenceId;
	}

		getMetricsTotalCount(): number {
		return this.Metrics.reduce((pv, cv) => pv + cv.Value, 0);
	}
}

class EvidenceMetric {
	Name: string;
	Description: string;
	Value: number;
}

export interface EvidenceTypeForSite {
	existingEvidences: Evidence[]
	newEvidences: Evidence[]
}
