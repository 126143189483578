<h2>Controls Assessment Printable Report</h2>

<div class="donutsContainer">
    <cm-summary-donut
        style="display: inline-flex; vertical-align: top; cursor: pointer"
        [standard]="''"
        [centerText]="
            controlsAssessmentInfo.Results['ALL'].ChartData[0].count /
                controlsAssessmentInfo.Results['ALL'].Responses.length | percent
        "
        [isRapidBaseline]="false"
        [chartData]="controlsAssessmentInfo.Results['ALL'].ChartData"></cm-summary-donut>

    <div class="cmStandardTable">
        <div class="resultsDivTable" [ngStyle]="{ 'max-height': getDynamicHeight() }">
            <div
                class="resultBox darkText"
                [class.noAnswer]="mud.Response == ''"
                [ngStyle]="{
                    'background-color':
                        mud.Response == 'Yes, Fully'
                            ? CM_COLORS.COVERED
                            : mud.Response == 'Yes, Partially'
                            ? CM_COLORS.PARTIAL
                            : mud.Response == 'Yes, With Issues'
                            ? CM_COLORS.PARTIAL
                            : mud.Response == 'Yes, with Technical Issues'
                            ? CM_COLORS.PARTIAL
                            : mud.Response == 'No'
                            ? CM_COLORS.NOTCOVERED
                            : mud.Response == ''
                            ? CM_COLORS.NOTASSESSED
                            : 'white',
                    'border': mud ? '1px solid #fff' : 'none',
                    'cursor': mud ? (mud.Response == '' ? 'not-allowed' : 'pointer') : 'auto'
                }"
                *ngFor="let mud of controlsAssessmentInfo.Results['ALL'].tableArr">
                {{ mud.Control.ControlId }}
            </div>
        </div>
    </div>
</div>

<div class="responseBox" *ngFor="let res of controlsAssessmentInfo.SortedResponseList">
    <div class="responseHeader">
        <div class="responseTitle">
            <h4>{{ res.Control.ShortDesc.LocalizedText[culture] }}</h4>
            <h5>{{ res.Control.LongDesc.LocalizedText[culture] }}</h5>
        </div>
        <div class="answerButton">
            <button
                class="ui button darkText"
                [class.unanswered]="!res.Response"
                type="button"
                [ngStyle]="{
                    'background-color':
                        res.Response == 'Yes, Fully'
                            ? CM_COLORS.COVERED
                            : res.Response == 'Yes, Partially'
                            ? CM_COLORS.PARTIAL
                            : res.Response == 'Yes, With Issues'
                            ? CM_COLORS.PARTIAL
                            : res.Response == 'Yes, with Technical Issues'
                            ? CM_COLORS.PARTIAL
                            : res.Response == 'Unsure'
                            ? CM_COLORS.UNSURE
                            : res.Response == 'No'
                            ? CM_COLORS.NOTCOVERED
                            : 'white'
                }">
                {{
                    res.Response == 'Yes, with Technical Issues'
                        ? 'Yes, with Issues'
                        : res.Response
                        ? res.Response
                        : 'Unanswered'
                }}
            </button>
        </div>
    </div>
    <h4 *ngIf="controlsAssessmentInfo.ActiveStandards.length > 0">Active Standards</h4>
    <div class="activeStandard" *ngFor="let standard of controlsAssessmentInfo.ActiveStandards">
        <h5 *ngIf="getControlRequirementText(standard, res.Control.Requirements) !== ''">
            {{ standard.Name + (standard.Name != standard.Variant ? ' ' + standard.Variant : '') }}
        </h5>
        <p
            *ngIf="getControlRequirementText(standard, res.Control.Requirements) !== ''"
            class="standardText"
            [innerHTML]="getControlRequirementText(standard, res.Control.Requirements)"></p>
    </div>
    <div class="responseBody">
        <b>Comment:</b>
        {{ res.Comment != '' ? res.Comment : 'No comment documented.' }}
    </div>
    <div class="responseBody">
        <b>Assigned to:</b>
        <span *ngFor="let a of res.Assignments; last as isLast">
            {{ getAssigneeName(a, false) + (!isLast ? ', ' : '') }}
        </span>
    </div>

    <div>
        <b>Assigned by:</b>
        {{ res.Assignments.length > 0 ? res.Assignments[0]?.AssignedByDisplayName || '' : '' }}
    </div>
</div>
