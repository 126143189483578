import { inject } from '@angular/core';
import {
    Router,
    RouterStateSnapshot,
    ActivatedRouteSnapshot,
    CanActivateFn,
    CanMatchFn,
    UrlTree,
} from '@angular/router';

import { AuthService } from '../auth.service';

export const GlobalProductGuard: CanActivateFn | CanMatchFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
): boolean | UrlTree => {
    let authService = inject(AuthService);
    let router = inject(Router);
    let productParam = route.data.product;
    let hasAccess = false;

    let products = authService.getProducts();

    console.log('Global Product Guard: ', productParam);

    if (productParam) {
        if (productParam === 'Compliance Manager') {
            hasAccess = products.CMProduct;
        } else if (productParam === 'Compliance Manager GRC') {
            hasAccess = products.CMGRCProduct;
        } else if (productParam === 'Cyber Hawk') {
            hasAccess = products.CHProduct;
        } else if (productParam === 'VulScan' || productParam === 'Vulnerability Scanner') {
            hasAccess = products.VulScanProduct;
        } else if (productParam === 'Network Detective Pro') {
            hasAccess = products.NdPro;
        } else if (productParam === 'Network Detective Pro Web') {
            hasAccess = products.NdProWeb;
        }
    }
    console.log('Global Product Guard results: ', hasAccess);

    if (!hasAccess) {
        router.navigate(['/error', '403']);
    }
    return hasAccess;
};
