import { Component, OnInit } from '@angular/core';
import { ScanProfile } from 'app/settings/shared/scan-profile.model';
import { TableSorting } from 'app/shared/sortable-table/sortable-table.component';
import { UiService } from 'app/core/ui.service';

@Component({
    selector: 'global-scan-profiles',
    templateUrl: './kvs-scan-profiles.component.html',
    styleUrls: ['./kvs-scan-profiles.component.scss'],
})
export class GlobalScanProfilesComponent implements OnInit {
    breadcrumbs = [
        { path: '../..', text: 'Admin' },
        { path: '..', text: 'VulScan' },
        { path: '', text: 'Scan Profiles' },
    ];

    scanProfiles: ScanProfile[] = [];
    sortedProfiles: ScanProfile[] = [];

    constructor(private uiService: UiService) {}

    ngOnInit() {
        this.uiService.setTitle('Scan Profile');
        this.loadProfiles();
    }

    loadProfiles() {
        this.scanProfiles.push({
            scanProfile: 'Low Impact Scan',
            description: 'Standard TCP ports and Top 1000 UDP',
            notes: 'Does not include brute force login attempts',
        });
        this.scanProfiles.push({
            scanProfile: 'Standard Scan',
            description: 'Standard TCP ports and Top 1000 UDP',
            notes: '',
        });
        this.scanProfiles.push({
            scanProfile: 'Comprehensive Scan',
            description: 'All TCP (1-65535) and Top 1000 UDP',
            notes: 'Comprehensive scans may take a significant amount of time and incur increased load on network',
        });
    }

    sortProfiles(sorting: TableSorting) {
        this.sortedProfiles = this.scanProfiles.sort((a, b) => {
            if (sorting.sortDirection == 'asc')
                return a[sorting.sortColumn]
                    .toLowerCase()
                    .localeCompare(b[sorting.sortColumn].toLowerCase());
            else
                return b[sorting.sortColumn]
                    .toLowerCase()
                    .localeCompare(a[sorting.sortColumn].toLowerCase());
        });
    }
}
