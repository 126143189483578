import { ReportTheme, HexColor, DEFAULT_CHART_QUALITY, DEFAULT_CHART_TRANSPARENCY } from './shared';

export class TahomaBlack implements ReportTheme {
    ThemeName = 'Tahoma Black';
    // ReportModernizationThemeOriginalName = 'Tahoma Black';

    DefaultFontName = 'Tahoma';
    DefaultFontSize = 20;
    DefaultTextColor = HexColor(0x00, 0x00, 0x00);

    Header1Size = 32;
    Header2Size = 28;
    Header3Size = 24;
    Header4Size = 22;

    HasBottomBorder = false;
    LineBottomBorder = false;

    // Theme Table Setting
    DefaultTableFontSize = 22;
    TableHeaderBackgroundColor = HexColor(0xbe, 0xc0, 0xbf);
    TableHeaderTextColor = HexColor(0x00, 0x00, 0x00);

    TableRowColor = HexColor(255, 255, 255);
    TableAtlRowColor = HexColor(217, 217, 217);
    TableRowTextColor = HexColor(0x00, 0x00, 0x00);

    // Theme Risk Colors
    CriticalRiskColor = HexColor(0x91, 0x24, 0x3e);
    HighRiskColor = HexColor(0xdd, 0x4b, 0x50);
    MediumRiskColor = HexColor(0xf1, 0x8c, 0x43);
    LowRiskColor = HexColor(0xf8, 0xc8, 0x51);

    GoColor = HexColor(0x2d, 0xa3, 0x59);
    StopColor = HexColor(0xff, 0x2d, 0x21);
    DisabledColor = HexColor(0xc0, 0xc0, 0xc0);

    GraphTitleColor = HexColor(0xbe, 0xc0, 0xbf);
    GraphSubTitleColor = HexColor(0xbe, 0xc0, 0xbf);
    XColor = HexColor(0x00, 0x00, 0x00);
    YColor = HexColor(0x00, 0x00, 0x00);
    GraphOutlineColor = HexColor(0xbe, 0xc0, 0xbf);

    UseNewRiskMeter = true;

    ChartColorsPaletteIntList = [
        HexColor(189, 50, 49),
        HexColor(55, 157, 239),
        HexColor(221, 99, 47),
        HexColor(71, 141, 44),
        HexColor(217, 217, 18),

        HexColor(155, 107, 66),
        HexColor(208, 62, 115),
        HexColor(40, 33, 105),
        HexColor(60, 133, 133),
        HexColor(99, 57, 133),

        HexColor(155, 65, 3),
        HexColor(36, 82, 67),
        HexColor(132, 133, 188),
        HexColor(236, 118, 122),
        HexColor(83, 23, 65),

        HexColor(138, 198, 62),
        HexColor(34, 57, 139),
        HexColor(245, 178, 51),
        HexColor(54, 14, 41),
        HexColor(82, 109, 132),
    ];

    ChartTopFivePaletteIntList = [
        HexColor(82, 109, 132),
        HexColor(54, 14, 41),
        HexColor(245, 178, 51),
        HexColor(34, 57, 139),
        HexColor(138, 198, 62),
        HexColor(236, 117, 121),
    ];

    ChartTwoColorPaletteIntList = [HexColor(82, 109, 132), HexColor(54, 14, 41)];

    ChartQuality = DEFAULT_CHART_QUALITY;
    ChartTransparencyPercent = DEFAULT_CHART_TRANSPARENCY;
}
