<sds-breadcrumb-trail [crumbs]="breadcrumbs"></sds-breadcrumb-trail>

<header class="pageHeader">
    <h1>Alerts</h1>
    <itc-tag type="subtle" color="orange" *ngIf="alerts">{{ filteredAlerts?.length }}</itc-tag>

    <div class="item searchInput">
        <sds-alert-search-filter
            [alerts]="timeFiltered"
            (onFiltered)="statusFiltered = $event"></sds-alert-search-filter>
    </div>

    <div class="item">
        <sds-alert-status-filter
            [alerts]="alerts"
            (onFiltered)="timeFiltered = $event"></sds-alert-status-filter>
    </div>

    <div class="item">
        <sds-alert-time-filter
            [alerts]="statusFiltered"
            (onFiltered)="filteredAlerts = $event; sortAlerts()"></sds-alert-time-filter>
    </div>

    <div class="flex-right">
        <itc-button
            label="View Ignored Alerts"
            type="primary"
            (onclick)="fetchIgnored(); ignoredAlertsModal.show({ closable: false })"
            icon="fa-eye-slash"></itc-button>
    </div>
</header>

<sds-loader [complete]="loadingComplete">
    <div class="fullWidthContent" *ngIf="filteredAlerts?.length == 0">
        <itc-banner type="info" title="No Alerts Found">
            No alerts were found matching the given criteria.
        </itc-banner>
    </div>

    <div class="fullWidthContent" *ngIf="filteredAlerts?.length > 0">
        <sds-alerts-table
            [alerts]="pagedAlerts"
            [forSite]="true"
            (sortBy)="processSort($event)"></sds-alerts-table>

        <itc-paginator [data]="filteredAlerts" (onPage)="pagedAlerts = $event"></itc-paginator>
    </div>
</sds-loader>

<sm-modal class="lg" title="Ignored Alerts" #ignoredAlertsModal style="max-height: 85%">
    <modal-content>
        <sds-loader [complete]="IAloadingComplete">
            <table class="itc-table bulkTable">
                <thead>
                    <tr>
                        <th>
                            <itc-bulk-actions
                                *ngIf="!(totalAlerts === selectedIA?.length)"
                                [menuOptions]="bulkActions"
                                [selectedCount]="selectedAlerts?.length"
                                [totalCount]="ignoredAlerts?.length"
                                (checkboxClicked)="checkAllAlerts($event)"></itc-bulk-actions>
                        </th>
                        <th>Alert Type</th>
                        <th>Alert Details</th>
                        <th>Ignored Recipient</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let ignoredAlert of ignoredAlerts"
                        [class.negative]="selectedIA.indexOf(createId(ignoredAlerts)) > -1">
                        <td>
                            <itc-checkbox
                                (click)="checkAlert(ignoredAlert)"
                                [checked]="ignoredAlert.Checked"></itc-checkbox>
                        </td>
                        <td>{{ ignoredAlert.alertType }}</td>
                        <td>{{ ignoredAlert.alertDetails }}</td>
                        <td>
                            <span *ngFor="let recipient of ignoredAlert.recipients">
                                {{ recipient }}
                                <br />
                            </span>
                        </td>
                        <td style="text-align: center">
                            <itc-button
                                type="icon"
                                icon="fa-trash-can"
                                tooltip="Delete"
                                (onclick)="removedIgnoredAlert(ignoredAlert)"></itc-button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <empty-state
                *ngIf="!ignoredAlerts?.length"
                icon="inbox"
                title="No Ignored Alerts"></empty-state>
        </sds-loader>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" class="cancel" label="Close"></itc-button>
        <itc-button
            type="primary"
            class="approve"
            label="Save"
            [disabled]="!selectedIA.length"
            [loading]="deleting"
            (onclick)="deleteIgnoredItems()"></itc-button>
    </modal-actions>
</sm-modal>
