import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
    HttpErrorResponse,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { VpAuthService } from './vp-auth.service';
import { environment } from 'environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(public auth: VpAuthService, public router: Router) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // skip interceptor for non vendor portal requests
        try {
            if (!request.url.startsWith(environment.apiUrl + 'vendportal'))
                return next.handle(request);

            let token = this.auth.getToken();

            if (token) {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${this.auth.getToken()}`,
                    },
                });
            }

            return next.handle(request);
        } catch {}
    }
}
