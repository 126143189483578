import { Survey } from './survey.model';

export class InformRequest {
    InformName: string;
    SiteName: string;
    TodoGuid: string;
    AssessmentName: string;
    AssessmentGuid: string;
    InformSurvey: Survey;
    UpdateGdprModel: boolean;
    IsSaveAndReturn: boolean;
}
