import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

type PillSize = 'small' | 'large';
type PillType = 'solid' | 'subtle' | 'priority';
type PillStatus =
    | 'success'
    | 'low-severity'
    | 'med-severity'
    | 'high-severity'
    | 'critical'
    | 'new'
    | 'active'
    | 'inactive'
    | 'open'
    | 'closed'
    | 'pending';
type PillColorSolid =
    | 'dark-green'
    | 'teal'
    | 'light-blue'
    | 'dark-blue'
    | 'blue-purple'
    | 'violet'
    | 'pink'
    | 'magenta'
    | 'salmon-pink'
    | 'charcoal-gray'
    | 'default';
type PillColorSubtle =
    | 'green'
    | 'ocean-blue'
    | 'red'
    | 'yellow'
    | 'orange'
    | 'bittersweet'
    | 'violet'
    | 'sky-blue'
    | 'purple'
    | 'black'
    | 'turquoise'
    | 'lavender';

@Component({
    standalone: true,
    imports: [CommonModule, FontAwesomeModule],
    selector: 'itc-tag',
    template: `
        <p
            class="itc tag {{ size }} {{ type }} {{ statusName }} {{ color }}"
            [ngClass]="{ pill: pill, hasIcon: icon, closable: closable }"
            (click)="onClicked($event)">
            <fa-icon [icon]="icon" *ngIf="icon" [fixedWidth]="true"></fa-icon>
            <span class="tag-text"><ng-content></ng-content></span>
            <fa-icon
                icon="xmark"
                class="close"
                *ngIf="closable"
                (click)="onClose($event)"
                [fixedWidth]="true"></fa-icon>
        </p>
    `,
    styleUrls: ['./itc-tag.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItcTagComponent {
    @Input() pill = true;
    @Input() size: PillSize = 'small';
    @Input() icon: string;
    @Input() closable = false;
    @Input() type: PillType | 'chip' = 'solid';
    @Input() color: PillColorSolid | PillColorSubtle = 'default';
    @Input() status: PillStatus;
    // all this because my code highlighting doesn't like me using 'status' in the template
    get statusName() {
        return this.status;
    }

    @Output() clicked = new EventEmitter<boolean>();
    @Output() closed = new EventEmitter<boolean>();

    onClicked(evt: Event) {
        evt.stopPropagation();
        this.clicked.emit(true);
    }
    onClose(evt: Event) {
        evt.stopPropagation();
        this.closed.emit(true);
    }
}
