import { Component, OnInit, ViewChild } from '@angular/core';
import { Site } from 'app/sites/shared/site.model';
import { MessageService } from 'app/core/message.service';
import { ASSESSMENT, PING_ASSESSMENT } from '../site-assessments.component';
import { AssessmentService } from 'app/sites/shared/assessment.service';
import { AuthService } from 'app/core/auth';
import { PING_SITE, SITE } from 'app/sites/shared/constants';
import { UiService } from 'app/core/ui.service';
import { TableSorting } from 'app/shared/sortable-table/sortable-table.component';
import { Connection, ConnectionService } from 'app/connections';
import { SiteParam, SiteSettingService } from 'app/sites/site/settings/site-settings.service';
import { Router } from '@angular/router';
import { CanComponentDeactivate } from 'app/deactivate-guard.service';
import { NotificationService } from '../../../../shared/itc/notification/notification.service';

@Component({
    selector: 'sds-risks-lapses',
    templateUrl: './risks-lapses.component.html',
    styleUrls: ['./risks-lapses.component.css'],
})
export class RisksLapsesComponent implements OnInit, CanComponentDeactivate {
    constructor(
        private connService: ConnectionService,
        private messageService: MessageService,
        private uiService: UiService,
        private assessmentService: AssessmentService,
        private notificationService: NotificationService,
        private siteSettingService: SiteSettingService,
        private authService: AuthService,
        private router: Router
    ) {}

    site: Site;
    selectedAssessment: any;
    loadingComplete: boolean;
    exporting: boolean = false;

    noRisksReturned: boolean = true;

    aSub: any;
    sSub: any;
    issuesJson: any;
    retrievedSettings: any;
    testGetSettings: boolean = true;
    testSaveSettings: boolean = true;
    riskList: any[];
    riskRows: any[];

    risksToExport: any[];
    previouslyExported: any[] = [];
    rowsChecked: number;

    riskModalInfo: any;
    @ViewChild('riskInfoModal', { static: true }) riskInfoModal: any;

    selectedAll: boolean = false;

    confirm: any = {};
    alert: any = {};

    conn: Connection = new Connection();
    ticketError: string;
    checkingTicket: boolean = false;
    ticket: any = {};

    canFinish: boolean = false;
    integrationData: any = {};
    checkingLogin: boolean = false;
    step: number = 1;
    isEdit: boolean = false;
    ticketNumber: string;

    firstSave: boolean = false;
    disableExportButton: boolean = false;

    breadcrumbs = [
        { path: '../..', text: 'Compliance Manager' },
        { path: '..', text: 'Assessments' },
        { path: '.', text: 'Issues' },
    ];

    noAssessment = false;

    ngOnInit() {
        //if (isDevMode) {
        this.loadingComplete = false;
        this.noRisksReturned = true;
        this.sSub = this.messageService.on(SITE).subscribe((site: Site) => {
            if (site != this.site) {
                this.site = site;
                this.uiService.setTitle('Issues', site.Name);
                this.messageService.broadcast(PING_ASSESSMENT);
            }
        });

        this.aSub = this.messageService.on(ASSESSMENT).subscribe((assessment) => {
            if (assessment) {
                this.loadingComplete = false;
                this.selectedAll = false;

                if (assessment != this.selectedAssessment) {
                    this.selectedAssessment = assessment;

                    if (this.site && this.selectedAssessment && this.selectedAssessment.isEmpty) {
                        this.noAssessment = true;
                        this.loadingComplete = true;
                    } else if (
                        this.site &&
                        this.selectedAssessment &&
                        !this.selectedAssessment.isEmpty
                    ) {
                        this.noAssessment = false;
                        ///Get the Issues from the Assessment everytime instead of just once in case it ever changes
                        this.assessmentService
                            .getAssessmentRisks(this.site.Id, this.selectedAssessment.Name)
                            .then((returnedRisks) => {
                                console.log(returnedRisks);
                                ///If there are no risks to display, skip all other processes
                                if (returnedRisks == null || returnedRisks.length == 0) {
                                    this.noRisksReturned = true;
                                    this.riskRows = [];
                                    this.loadingComplete = true;
                                    this.messageService.broadcast(PING_SITE);
                                    console.log(this.site);
                                    console.log(this.selectedAssessment);
                                    return;
                                }
                                this.noRisksReturned = false;
                                this.riskList = returnedRisks;
                                this.buildRiskRowsFromAssessment(returnedRisks);
                            })

                            ///Get the Issues settings from site_params_large
                            .then(() => {
                                this.siteSettingService
                                    .getAssessmentIssuesSettingsLargeParams(
                                        this.site.Id,
                                        this.selectedAssessment.Name
                                    )
                                    .then((settings) => {
                                        if (settings) {
                                            this.retrievedSettings = settings;
                                            this.updateRiskRowsFromSettings(settings); ///comment to reset site_params
                                            //this.saveToSiteParams(); ///uncomment to reset site_params
                                            this.sortByTitleScoreDescending();
                                            this.loadingComplete = true;
                                        } else {
                                            this.sortByTitleScoreDescending();
                                            this.loadingComplete = true;
                                        }
                                        //if (this.testGetSettings) {
                                        //this.getAssessmentDataFromDb();
                                        //}
                                        //else
                                        //this.getAssessment();
                                    });
                            });
                    } else this.loadingComplete = true;
                }
            }
        });

        this.messageService.broadcast(PING_SITE);

        console.log(this.site);
        console.log(this.selectedAssessment);
        //}
        //else { this.loadingComplete = true; }
    }

    ///Old code for deciding to get issues from the assessment or the site_params_large
    /*
  getAssessmentDataFromDb() {
    if (this.retrievedSettings) {
      try {
        this.noRisksReturned = true;
        let assessmentData: any;
        let latestDate: Date = new Date("1/1/2000");
        //for (let set of this.retrievedSettings) {
        //  if (set.Name == "AssessmentIssues_" + this.selectedAssessment.Name) {
        //    assessmentData = JSON.parse(set.Value);
        //  }
        //}
        assessmentData = JSON.parse(this.retrievedSettings.Value);

        if (assessmentData) {

          console.log(this.selectedAssessment.Name);
          if (assessmentData.AssessmentName == this.selectedAssessment.Name) {
            console.log("Assessment Found in JSON");
            console.log(assessmentData);
            this.riskRows = assessmentData.AssessmentIssues;
            var obj = {};
            for (var i = 0, len = this.riskRows.length; i < len; i++) {
              obj[this.riskRows[i].Title] = this.riskRows[i];
            }
            this.riskRows = new Array();
            for (var key in obj)
              this.riskRows.push(obj[key]);
            this.noRisksReturned = false;
            this.loadingComplete = true;
          }
        }

        if (this.noRisksReturned || !assessmentData)
          this.getAssessment();
      }
      catch (err) {
        this.getAssessment();
      }
    }
    else
      this.getAssessment();

    this.sortByTitleScoreDescending();
  }
  */
    /*
  getAssessment() {
    this.loadingComplete = false;

    console.log(this.selectedAssessment.Name);
    if (this.selectedAssessment && this.site) {
      this.assessmentService.getAssessmentRisks(this.site.Id, this.selectedAssessment.Name)
        .then((returnedRisks) => {
          console.log(returnedRisks);
          if (returnedRisks == null || returnedRisks.length == 0) {
            this.noRisksReturned = true;
            this.loadingComplete = true;
            return;
          }
          this.noRisksReturned = false;
          this.riskList = returnedRisks;
        }).then(() => {
          this.riskRows = [];
          let id: number = 0;
          if (this.riskList) {
            this.riskList.forEach((val) => {
              var risk = {
                Id: id,
                IsChecked: false,
                RiskScore: val.RiskScore,
                Risk: val.Risk,
                Issue: val.Issue,
                IssueData: val.IssueData,
                Title: val.Title,
                Severity: val.Severity,
                Probability: val.Probability,
                Recommendation: val.Recommendation,
                BmsTicket: "Not Submitted",
                IsArchived: false
              };
              this.riskRows.push(risk);
              id++;
            });
            var obj = {};
            for (var i = 0, len = this.riskRows.length; i < len; i++) {
              obj[this.riskRows[i].Title] = this.riskRows[i];
            }
            this.riskRows = new Array();
            for (var key in obj)
              this.riskRows.push(obj[key]);
            //save data to site params
            this.firstSave = true;
            this.saveToSiteParams();
            console.log(this.riskRows);
          }
        });

      this.loadingComplete = true;
      console.log(this.selectedAssessment);
    }
  }
  */
    ///end old code

    //get the assessment data and add to riskRows[]
    buildRiskRowsFromAssessment(returnedRisks: any) {
        this.riskRows = [];
        let id: number = 0;
        if (returnedRisks) {
            returnedRisks.forEach((val) => {
                var risk = {
                    Id: id,
                    IsChecked: false,
                    RiskScore: val.RiskScore,
                    Risk: val.Risk,
                    Issue: val.Issue,
                    IssueData: val.IssueData,
                    Title: val.Title,
                    Severity: val.Severity,
                    Probability: val.Probability,
                    Recommendation: val.Recommendation,
                    BmsTicket: 'Not Submitted',
                    IsArchived: false,
                };
                this.riskRows.push(risk);
                id++;
            });
            var obj = {};
            for (var i = 0, len = this.riskRows.length; i < len; i++) {
                obj[this.riskRows[i].Title] = this.riskRows[i];
            }
            this.riskRows = new Array();
            for (var key in obj) this.riskRows.push(obj[key]);
        }
    }

    //get the site_params_large data and compare it to riskRows and set the correct IsArchived and BmsTicket for each
    updateRiskRowsFromSettings(settings: any) {
        let issueSettings: any[];
        if (settings) {
            try {
                this.noRisksReturned = true;
                let assessmentData: any;
                let latestDate: Date = new Date('1/1/2000');

                assessmentData = JSON.parse(settings.Value);

                if (assessmentData) {
                    console.log(this.selectedAssessment.Name);

                    if (assessmentData.AssessmentName == this.selectedAssessment.Name) {
                        console.log('Assessment Found in JSON');
                        console.log(assessmentData);
                        issueSettings = assessmentData.AssessmentIssues;
                        var obj = {};
                        for (var i = 0, len = issueSettings.length; i < len; i++) {
                            obj[issueSettings[i].Title] = issueSettings[i];
                        }
                        issueSettings = new Array();
                        for (var key in obj) issueSettings.push(obj[key]);
                        this.noRisksReturned = false;
                    }
                }
                //get check the data for exported issues and set each risk in riskRows with the correct IsArchived and BmsTicket
                issueSettings.forEach((setting) => {
                    if (setting.IsArchived) {
                        for (let risk of this.riskRows) {
                            if (risk.Title == setting.Title) {
                                risk.IsArchived = setting.IsArchived;
                                risk.BmsTicket = setting.BmsTicket;
                            }
                        }
                    }
                });
            } catch (err) {
                console.log(err);
            }
        }
    }

    updateRowCount() {
        let filteredRows = this.riskRows.filter((risk) => risk.IsChecked && !risk.IsArchived);
        this.rowsChecked = filteredRows.length;
        // turn off select all checkbox
        this.selectedAll = false;
    }

    exportToBMS() {
        //if (this.authService.userIsRole(Roles.SiteRestricted)) {
        //  this.notificationService.toast.warning('Access Denied', 'Your assigned permissions do not allow access to this page. Please have your site admin complete this setup.');
        //}
        //else {
        this.disableExportButton = true;
        let noConnections = false;
        this.connService
            .getConnectionBySite(this.site.Id)
            .then((connection) => {
                if (!connection) {
                    noConnections = true;
                    alert(
                        'Connection to Kaseya BMS required to export issues. Please use "Go to Connections" button to set up a new connection.'
                    );
                    this.disableExportButton = false;
                    return;
                }
            })
            .then(() => {
                if (!noConnections) {
                    this.risksToExport = [];
                    this.riskRows.forEach((risk) => {
                        if (risk.IsChecked && !risk.IsArchived) {
                            this.risksToExport.push(risk);
                        }
                    });
                    //check if previously exported issues are included and remove for exports
                    this.siteSettingService
                        .getAssessmentIssuesSettingsLargeParams(
                            this.site.Id,
                            this.selectedAssessment.Name
                        )
                        .then((settings) => {
                            let assessmentData: any;
                            //for (let set of settings) {
                            //  if (set.Name == "AssessmentIssues_" + this.selectedAssessment.Name) {
                            //    assessmentData = JSON.parse(set.Value);
                            //  }
                            //}
                            if (settings) {
                                assessmentData = JSON.parse(settings.Value);

                                //get all the previously exported issues
                                let data: any[] = assessmentData.AssessmentIssues;
                                for (let d of data) {
                                    if (d.IsArchived) {
                                        this.previouslyExported.push(d);
                                    }
                                }
                                ///Compare only the exported issues in the site params and replace risks in riskRows if the risk was already exported
                                //let siteParamsRisks = assessmentData.AssessmentIssues;
                                for (let exportedRisk of this.previouslyExported) {
                                    for (let risk of this.riskRows) {
                                        ///since risk.Id could change after loading from the Assessment, compare Title instead when comparing between riskRows and site_params
                                        if (risk.Title == exportedRisk.Title && !risk.IsArchived) {
                                            risk.IsArchived = exportedRisk.IsArchived;
                                            risk.BmsTicket = exportedRisk.BmsTicket;
                                        }
                                    }
                                }
                            }
                        })
                        .then(() => {
                            let previouslyExportedCount: number = this.checkPreviouslyExported(
                                this.previouslyExported,
                                this.risksToExport
                            );
                            if (this.risksToExport.length) {
                                if (
                                    confirm(
                                        previouslyExportedCount > 0
                                            ? `Tickets will be created in Kaseya BMS for this issue. ${previouslyExportedCount} issues were previously exported. The table will update. ${this.risksToExport.length} issues will be exported. Do you want to continue?`
                                            : 'Tickets will be created in Kaseya BMS for this issue. Do you want to continue?'
                                    )
                                ) {
                                    this.exporting = true;
                                    this.notificationService.toast.warning(
                                        'Exporting Issues',
                                        'Exporting Issues to BMS.'
                                    );
                                    this.generateTicket();
                                    console.log(this.risksToExport);
                                }
                            } else
                                alert(
                                    'The selected issues have already been exported. The table will update.'
                                );
                        });
                }
            });
        this.disableExportButton = false;
        //}
    }

    checkPreviouslyExported(dbEported: any[], currentExports: any[]) {
        //check if any issue selected for export was already exported, remove the issue if it was, and return how many were removed
        let temp: any[] = [];
        let prevExportcount: number = 0;
        let include: boolean;
        if (dbEported != null) {
            for (let current of currentExports) {
                include = true;
                for (let item of dbEported) {
                    ///since risk.Id could change after loading from the Assessment, compare Title instead when comparing between riskRows and site_params
                    if (current.Title == item.Title) {
                        prevExportcount++;
                        include = false;
                        break;
                    }
                }
                if (include) temp.push(current);
            }
            this.risksToExport = temp;
        }
        return prevExportcount;
    }

    sortByTitleScoreDescending() {
        if (this.riskRows) {
            let sortedByTitle = this.riskRows.sort((a, b) =>
                a.Title.toLowerCase().localeCompare(b.Title.toLowerCase())
            );
            sortedByTitle = sortedByTitle.reverse();
            let sortedRisks = sortedByTitle.sort((a, b) =>
                Number(a.RiskScore) > Number(b.RiskScore) ? -1 : 1
            );
            this.riskRows = sortedRisks;
        }
    }

    onSorted(sorting?: TableSorting) {
        if (!sorting.sortColumn) {
            this.sortByTitleScoreDescending();
        } else {
            if (this.riskRows) {
                let sortedRisks: any;
                if (sorting.sortColumn == 'bmsTicket') {
                    let sortedRows = this.riskRows.sort((a, b) => this.sortBmsTicket(a, b, 0));
                    sortedRisks = sortedRows.sort((a, b) => this.sortBmsTicket(a, b, 1));
                } else {
                    let sortedByTitle = this.riskRows.sort((a, b) =>
                        a.Title.toLowerCase().localeCompare(b.Title.toLowerCase())
                    );

                    sortedRisks = sortedByTitle.sort((a, b) => {
                        if (sorting.sortColumn == 'score') {
                            return Number(a.RiskScore) < Number(b.RiskScore) ? -1 : 1;
                        } else if (sorting.sortColumn == 'risk')
                            return a.Title.toLowerCase().localeCompare(b.Title.toLowerCase());
                        //else if (sorting.sortColumn == 'bmsTicket')
                        //  return a.BmsTicket.toLowerCase().localeCompare(b.BmsTicket.toLowerCase());
                    });
                }

                if (sorting.sortDirection == 'desc') this.riskRows = sortedRisks.reverse();
                else this.riskRows = sortedRisks;
            }
        }
    }

    parseBmsTicket(ticketNumber: string, returnDate: boolean) {
        var split = ticketNumber.split('-');
        if (split.length == 2) {
            let prefix: number = Number(split[0]);
            let dateNum: string = split[1];
            let month: number = Number(dateNum.substring(0, 2));
            let day: number = Number(dateNum.substring(2, 4));
            let year: number = Number(dateNum.substring(4));
            let date: Date = new Date(year, month, day);

            if (returnDate) {
                return date;
            } else {
                return prefix;
            }
        } else {
            return -1;
        }
    }

    sortBmsTicket(a: any, b: any, sortCount: number) {
        let sortDate: boolean = sortCount == 0 ? false : true;
        return this.parseBmsTicket(a.BmsTicket, sortDate) <
            this.parseBmsTicket(b.BmsTicket, sortDate)
            ? -1
            : 1;
    }

    showRiskInfoModal(risk) {
        this.riskModalInfo = risk;
        this.riskModalInfo.SeverityDisplay = this.calculateDisplaySeverity(
            this.riskModalInfo.Severity
        );
        this.riskModalInfo.RiskDisplay = this.calculateDisplaySeverity(
            this.riskModalInfo.RiskScore
        );
        this.riskModalInfo.ProbabilityDisplay = this.calculateDisplaySeverity(
            this.riskModalInfo.Probability
        );
        this.riskInfoModal.show();
    }

    calculateDisplaySeverity(num) {
        if (num > 70) {
            return 'high';
        } else if (num > 50) {
            return 'medium';
        } else {
            return 'low';
        }
    }

    ngOnDestroy() {
        this.aSub.unsubscribe();
        this.sSub.unsubscribe();
    }

    selectAll() {
        this.riskRows.forEach((risk) => {
            risk.IsChecked = this.selectedAll;
        });
        // set rowschecked so we can enable or disable the export button
        this.rowsChecked = this.selectedAll ? 1 : 0;
    }

    // generate ticket code is based on testTicket() from add-connection.component.ts

    generateTicket() {
        //delete this.ticketError;
        //this.checkingTicket = true;

        //this.conn.TicketMetadata = JSON.stringify(this.ticket);
        //this.risksToExport.forEach((exportedRisk) => {
        //  this.connService.createTicket(this.site.Id, this.selectedAssessment.Name, exportedRisk)
        //  .then((res) => {
        //    this.riskRows.forEach((risk) => {
        //      if (risk.Id == exportedRisk.Id) {
        //        if (!res.includes("Error")) {
        //          risk.BmsTicket = res;
        //          risk.IsArchived = true;
        //          risk.IsChecked = false;
        //        }
        //        else {
        //          risk.BmsTicket = res;
        //          risk.IsArchived = false;
        //          risk.IsChecked = false;
        //        }
        //      }
        //    });
        //    //update database with new data
        //    this.saveToSiteParams();
        //  })
        //  .catch((err) => {
        //    console.log(err)
        //    });
        //});
        this.connService
            .createTicket(this.site.Id, this.selectedAssessment.Name, this.risksToExport)
            .then((res) => {
                this.notificationService.toast.warning(
                    'Saving Exports',
                    'Saving export changes to the issues table.'
                );
                let rValue: any[] = res;
                rValue.forEach((issueExport) => {
                    this.riskRows.forEach((risk) => {
                        if (risk.Id == issueExport.Id) {
                            if (issueExport.BmsTicket == '') {
                                risk.BmsTicket = 'Error';
                                risk.IsChecked = true;
                                risk.IsArchived = false;
                            } else {
                                risk.BmsTicket = issueExport.BmsTicket;
                                risk.IsChecked = false;
                                risk.IsArchived = true;
                            }
                            //risk.BmsTicket = issueExport.BmsTicket;
                            //risk.IsChecked = false;
                            //if (issueExport.BmsTicket.includes("Error")) {
                            //  risk.IsArchived = false;
                            //}
                            //else
                            //  risk.IsArchived = true;
                        }
                    });
                });
            })
            .then(() => {
                //update database with new data
                this.saveToSiteParams();
                //this.exporting = false;
                //this.notificationService.toast.success("Exports Saved", "Export changes saved.")
            })
            .catch((err) => {
                console.log(err);
            });
    }

    //onTicketError(res: any) {
    //  this.checkingTicket = false;

    //  this.ticketError = res.replace(/^"/, "").replace(/"$/, "");

    //  this.notificationService.toast.error('Error', 'There was a problem checking your ticket details. Please review them and try again.');
    //}

    saveToSiteParams() {
        var assessmentIssues = {
            AssessmentName: this.selectedAssessment.Name,
            AssessmentIssues: this.riskRows,
        };

        this.issuesJson = JSON.stringify(assessmentIssues);
        let issuesParam: SiteParam[] = [];
        issuesParam.push({
            SiteId: this.site.Id,
            Name: 'AssessmentIssues_' + this.selectedAssessment.Name,
            Value: this.issuesJson,
        });
        //console.log(this.issuesJson);
        this.siteSettingService.upsertSiteParamsLarge(this.site.Id, issuesParam).then(() => {
            if (this.firstSave) {
                this.firstSave = false;
            } else {
                this.exporting = false;
                this.notificationService.toast.success('Exports Saved', 'Export changes saved.');
                this.updateRowCount();
            }
        });
    }

    goToConnections() {
        //if (this.authService.userIsRole(Roles.SiteRestricted)) {
        //  this.notificationService.toast.warning('Access Denied', 'Your assigned permissions do not allow access to this page. Please have your site admin complete this setup.');
        //}
        //else {
        this.router.navigateByUrl('/settings/connections');
        //}
    }

    doBeforeUnload() {
        if (this.exporting) return false;
        else return true;
    }

    canDeactivate() {
        if (this.doBeforeUnload()) {
            return true;
        } else {
            if (
                confirm(
                    'Leaving or refreashing could cause issues to not be marked as exported. Do you wish to continue?'
                )
            )
                return true;
            else return false;
        }
    }

    get isSelectAllCheckBoxAvailable() {
        const index = this.riskRows.findIndex((record) => !record.IsArchived);
        return index > -1;
    }
}
