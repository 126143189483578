import { Component, OnInit, Input, Output, EventEmitter, AfterViewChecked } from '@angular/core';
import { OrganizationCardComponent } from '../organization-card/organization-card.component';
import { Router } from '@angular/router';
@Component({
    selector: 'sds-organization-grid',
    templateUrl: './organization-grid.component.html',
    styleUrls: ['./organization-grid.component.css'],
})
export class OrganizationGridComponent implements OnInit {
    @Input() organizations: any;
    @Input() viewModeUpdating: boolean;
    @Input() canModify: boolean;
    @Output('onDelete') onDelete: EventEmitter<string> = new EventEmitter<string>();

    loadingComplete: boolean = true;

    constructor(private router: Router) {}

    ngOnInit() {}

    organizationDeleted(organizationName: string) {
        console.log('organization deleted');
        this.onDelete.emit(organizationName);
        //this.router.navigate(['organizations']);
    }
}
