import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Site } from 'app/sites/shared/site.model';
import { PING_SITE, SITE } from 'app/sites/shared/constants';
import { KvsService } from 'app/sites/shared/kvs.service';
import { MessageService } from 'app/core/message.service';
import { Subscription } from 'rxjs';
import { UiService } from 'app/core/ui.service';
import { UntypedFormControl } from '@angular/forms';
import { NotificationService } from '../../../../../shared/itc/notification/notification.service';
import { HEVSLicense } from '../../kvs.model';
import { SemanticModalComponent } from '../../../../../semantic-legacy/components/modal/modal';
import { IpRangeService } from '../../../../shared/ip-range/ip-range.service';

@Component({
    selector: 'sds-kvs-scan-notification-task',
    templateUrl: './kvs-scan-notification-task.component.html',
    styleUrls: ['./kvs-scan-notification-task.component.scss'],
})
export class KvsScanNotificationTaskComponent implements OnInit {
    @ViewChild('hostedExternalRunNowWarning', { static: true }) hostedExternalRunNowWarning: any;

    breadcrumbs = [
        { path: '../../..', text: 'VulScan' },
        { path: '../..', text: 'Settings' },
        { path: '..', text: 'Scan And Notification Tasks' },
    ];

    loadingComplete: boolean;
    scanTasksLoadingComplete: boolean;
    notificationTasksLoadingComplete: boolean;
    isDeleting: boolean = false;
    readonly: boolean;
    scanTasks: any[];
    filteredScanTasks: any[];
    filteringScans = false;
    notificationTasks: any[];
    filteredNotificationTasks: any[];
    filteringNotifications = false;
    site: Site;
    msgSub: Subscription;
    editMode: boolean;
    scanTaskToDelete: any;
    notificationTaskToDelete: any;
    taskDisplay = 'scan'; // scan or notification
    searchKey = new UntypedFormControl();
    modalContent: string;
    notificationTaskToRun: any;
    ipScansRequested = 0;
    friendlyTypes: any = {
        IVS: 'Internal Scan',
        EVS: 'External Scan',
        RIVS: 'Internal Scan',
        HEVS: 'External Scan',
    };
    buyMoreLicenseButtonLoading = false;

    @ViewChild('addScanTaskModal', { static: true }) addScanTaskModal: any;
    @ViewChild('addNotificationTaskModal', { static: true }) addNotificationTaskModal: any;
    @ViewChild('confirmDeleteScanTaskModal', { static: true }) confirmDeleteScanTaskModal: any;
    @ViewChild('confirmDeleteNotificationTaskModal', { static: true })
    confirmDeleteNotificationTaskModal: any;
    @ViewChild('notificationRunNowModal', { static: true }) notificationRunNowModal: any;
    @ViewChild('addProfileModal', { static: true }) addProfileModal: any;
    licence: HEVSLicense;
    @ViewChild('buyMoreLicenseConfirmationModal') buyMoreLicenseConfirmationModal: SemanticModalComponent;

    constructor(
        private kvsService: KvsService,
        private notificationService: NotificationService,
        private messageService: MessageService,
        private uiService: UiService,
        private activatedRoute: ActivatedRoute,
        private ipRangeService: IpRangeService,
    ) {}

    ngOnInit() {
        this.activatedRoute.data.subscribe((data) => {
            this.taskDisplay = data.viewType;
        });

        this.uiService.setTitle('Scan And Notification Tasks');
        this.readonly = false;
        this.loadingComplete = false;
        this.msgSub = this.messageService.on(SITE).subscribe((site: Site) => {
            if (!site) return;

            this.site = site;
            if (this.taskDisplay == 'scan') {
                this.getScanTasks();
            } else if (this.taskDisplay == 'notification') {
                this.getNotificationTasks();
            }

            this.kvsService.getHEVSLicense(site.Id).then((res) => {
                if (res) {
                    this.licence = res;
                }
                this.loadingComplete = true;
            });
        });

        this.messageService.broadcast(PING_SITE);
    }

    getScanTasks() {
        if (this.site) {
            this.scanTasksLoadingComplete = false;
            this.kvsService.getKvsScanTasks(this.site.Id).then((res) => {
                res.forEach((scan) => {
                    // console.log("Scan status",scan.Status.toLowerCase());
                    scan.isRunning =
                        scan.Status.toLowerCase().indexOf('pending') == -1 &&
                        scan.Status.toLowerCase().indexOf('done') == -1 &&
                        scan.Status.toLowerCase().indexOf('queued') == -1 &&
                        scan.Status.toLowerCase().indexOf('disabled') == -1;
                    // scan.isRunning = true;
                });

                res.forEach((scan) => {
                    if (scan.ApplianceId.startsWith('HEVS')) {
                        scan.Status = this.splitByDelimiterAndRejoinWithHtmlLineBreak(
                            '|',
                            scan.Status
                        );
                    }
                });

                this.scanTasks = res;
                this.filteredScanTasks = res;
                this.searchKey.setValue('');
                console.log('this.scantasks', this.scanTasks);
                this.scanTasksLoadingComplete = true;
            });
        }
    }

    splitByDelimiterAndRejoinWithHtmlLineBreak(delimiter: string, status: string): string {
        let rValue: string = '';
        let sStatuses = status.split(delimiter);
        let statusList: string[] = [];

        let sortedStatusList = this.sortStatusList(sStatuses);

        statusList = this.truncateStatusList(sortedStatusList);

        rValue = statusList.join('<br />');

        return rValue;
    }

    sortStatusList(splitStatuses: string[]): string[] {
        let rValue: string[] = [];

        rValue = splitStatuses.sort((a, b) => {
            if (a.toLowerCase().indexOf('running') != -1) {
                return -1;
            }

            if (a.toLowerCase().indexOf('queued') && b.toLowerCase().indexOf('canceled')) {
                return -1;
            }

            if (a.toLowerCase().indexOf('canceled') && b.toLowerCase().indexOf('queued')) {
                return 1;
            }

            if (a.toLowerCase().indexOf('done') != -1) {
                return 1;
            }
        });

        return rValue;
    }

    truncateStatusList(statusList: string[]): string[] {
        let rValue: string[] = [];

        if (statusList.length > 8) {
            rValue = statusList.slice(0, 7);
        } else {
            rValue.push(...statusList);
        }

        return rValue;
    }

    showAddScanTaskModal() {
        this.addScanTaskModal.showModal(false, {});
    }

    showScanTaskEditModal(task: any) {
        this.addScanTaskModal.showModal(true, task);
    }

    scanTaskSaved(evt: any) {
        this.getScanTasks();
    }

    getNotificationTasks() {
        if (this.site) {
            this.notificationTasksLoadingComplete = false;
            this.kvsService.getKvsNotificationTasks(this.site.Id).then((res) => {
                this.notificationTasks = res;
                this.filteredNotificationTasks = res;
                this.searchKey.setValue('');
                console.log('notification tasks', res);
                this.notificationTasksLoadingComplete = true;
            });
        }
    }

    notificationTaskSaved(evt: any) {
        this.getNotificationTasks();
    }

    showAddNotificationTaskModal() {
        this.addNotificationTaskModal.showModal(false, {});
    }

    showNotificationTaskEditModal(task: any) {
        this.addNotificationTaskModal.showModal(true, task);
    }

    runNow(scanTask: any) {
        if (scanTask.ApplianceId.startsWith('HEVS')) {
            this.ipScansRequested = this.ipRangeService.countIPs(scanTask.SelectedIpRanges, true);

            if (this.ipScansRequested > this.licence.Remaining) {
                this.hostedExternalRunNowWarning.show({ closable: true });
                return;
            }
        }

        scanTask.Status = 'Queued';
        this.notificationService.toast.info('Info', 'Queueing scan');
        this.kvsService
            .kvsRunNow(scanTask.Id)
            .then((res) => {
                this.notificationService.toast.success('Success', 'Scan successfully queued');
            })
            .catch((error) => (scanTask.Status = 'Pending'));
    }

    runNowNotification() {
        if (this.notificationTaskToRun) {
            this.notificationService.toast.info('Info', 'Queueing Notification');
            this.kvsService.kvsRunNowNotification(this.notificationTaskToRun.Id).then((res) => {
                this.notificationService.toast.success(
                    'Success',
                    'Notification successfully queued'
                );
            });
            this.notificationRunNowModal.hide();
        }
    }

    showNotificationRunNowModal(notificationTask) {
        this.notificationTaskToRun = notificationTask;
        this.modalContent = `Run ${notificationTask.Label} Notification Task Now?`;
        this.notificationRunNowModal.show();
    }

    logObject(o: any) {
        for (var propName in o) {
            console.log(propName, o[propName]);
        }
    }

    onNotificationTasksSorted(ev?: any) {
        if (ev && ev.sortColumn != undefined) {
            this.filteredNotificationTasks = this.filteredNotificationTasks.sort((a, b) =>
                this.sortByColumn(a, b, ev.sortColumn.replace('-', ''), ev.sortDirection)
            );
        }
    }

    onScanTasksSorted(ev?: any) {
        if (ev && ev.sortColumn != undefined) {
            this.filteredScanTasks = this.filteredScanTasks.sort((a, b) =>
                this.sortByColumn(a, b, ev.sortColumn.replace('-', ''), ev.sortDirection)
            );
        }
    }

    sortByColumn(a, b, sortMethod, sortDirection) {
        if (sortMethod === 'Next Run') {
            return this.cmpTime(a.NextRun, b.NextRun, sortDirection);
        }

        if (sortMethod === 'Id') {
            return this.cmp(a[sortMethod], b[sortMethod], sortDirection);
        }

        a[sortMethod] = a[sortMethod] === undefined ? '' : a[sortMethod];
        b[sortMethod] = b[sortMethod] === undefined ? '' : b[sortMethod];
        return this.cmp(a[sortMethod].toUpperCase(), b[sortMethod].toUpperCase(), sortDirection);
    }

    cmp(a, b, sortDirection) {
        if (a > b) {
            return sortDirection === 'asc' ? 1 : -1;
        }
        if (a < b) {
            return sortDirection === 'asc' ? -1 : 1;
        }
        return 0;
    }
    cmpTime(a, b, sortDirection) {
        if (sortDirection === 'asc') {
            return new Date(a).getTime() - new Date(b).getTime();
        } else {
            return new Date(b).getTime() - new Date(a).getTime();
        }
    }

    confirmScanTaskDelete(scanTask: any) {
        this.scanTaskToDelete = scanTask;
        this.confirmDeleteScanTaskModal.show();
    }

    deleteScanTask() {
        if (this.scanTaskToDelete) {
            this.isDeleting = true;
            this.kvsService.deleteScanTask(this.scanTaskToDelete).then((res) => {
                this.isDeleting = false;
                this.confirmDeleteScanTaskModal.hide();
                this.getScanTasks();
                this.notificationService.toast.success('Success', 'Scan Task Deleted');
            });
        }
    }

    confirmNotificationTaskDelete(notificationTask: any) {
        this.notificationTaskToDelete = notificationTask;
        this.confirmDeleteNotificationTaskModal.show();
    }

    deleteNotificationTask() {
        if (this.notificationTaskToDelete) {
            this.kvsService.deleteNotificationTask(this.notificationTaskToDelete).then((res) => {
                this.confirmDeleteNotificationTaskModal.hide();
                this.getNotificationTasks();
                this.notificationService.toast.success('Success', 'Notification Task Deleted');
            });
        }
    }

    filterScanTasks() {
        let filtered = [];
        if (this.searchKey) {
            this.filteringScans = true;
            let key = this.searchKey.value.toLowerCase();
            filtered = this.scanTasks.filter(
                (task) =>
                    task.ApplianceId.toLowerCase().indexOf(key) > -1 ||
                    (task.ApplianceDescription &&
                        task.ApplianceDescription.toLowerCase().indexOf(key) > -1) ||
                    task.Label.toLowerCase().indexOf(key) > -1
            );
        } else {
            filtered = this.scanTasks;
        }

        this.filteredScanTasks = filtered;
        /* just adding this so there's at least a little something when the filtering happens fast */
        setTimeout(() => {
            this.filteringScans = false;
        }, 250);
    }

    filterNotificationTasks() {
        let filtered = [];
        this.filteringNotifications = true;
        if (this.searchKey) {
            let key = this.searchKey.value.toLowerCase();
            filtered = this.notificationTasks.filter(
                (task) =>
                    task.Description.toLowerCase().indexOf(key) > -1 ||
                    task.Label.toLowerCase().indexOf(key) > -1
            );
        } else {
            filtered = this.notificationTasks;
        }
        this.filteredNotificationTasks = filtered;
        /* just adding this so there's at least a little something when the filtering happens fast */
        setTimeout(() => {
            this.filteringNotifications = false;
        }, 250);
    }

    showAddProfileModal() {
        this.addProfileModal.showModal(false, {});
    }

    returnToScanTaskModal(screen: string = '', event?: any) {
        this.addScanTaskModal.returnToModal(screen, event);
    }

    ngOnDestroy() {
        this.msgSub.unsubscribe();
    }

    getIpCount(ipRange: string): number {
        let rValue: number = 0;

        if (!ipRange.includes('-')) {
            rValue++;
            return rValue;
        }

        let splitRange = ipRange.split('-');
        if (splitRange.length != 2) {
            return;
        }

        let start = this.ipv4ToNum(splitRange[0]);
        let end = this.ipv4ToNum(splitRange[1]);

        rValue = end - start + 1;

        return rValue;
    }
    ipv4ToNum(ip: string): number {
        return ip.split('.').reduce((acc, cur) => (acc << 8) + parseInt(cur, 10), 0);
    }

    public async onBuyMoreLicenseButtonClicked() {
        this.buyMoreLicenseButtonLoading = true;
        await this.kvsService.buyMoreHevsLicense();
        this.buyMoreLicenseConfirmationModal.show();
        this.buyMoreLicenseButtonLoading = false;
    }
}
