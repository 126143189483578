<div *ngIf="readonly" style="margin-bottom: 1em">
    <div class="ui top attached message" *ngIf="!hideHeader">
        <div class="header">Alert Object(s)</div>
    </div>
    <div class="ui attached segment">
        <b>
            <i class="icon" [ngClass]="icons.p"></i>
            {{ alertData?.Primary.ObjectName }}
        </b>
        <a
            (click)="showDiagModal(alertData?.Primary)"
            *ngIf="
                alertData?.Primary.Diagnostics.length > 0 && alert?.AlertType != 'EventLogCleared'
            "
            style="float: right; cursor: pointer; margin-top: 4px">
            view details
        </a>

        <div
            class="ui relaxed list"
            style="margin-left: 1.5rem"
            *ngIf="alertData?.Secondary.length > 0">
            <div
                *ngFor="let object of alertData?.Secondary; let i = index"
                [class.error]="listError"
                style="margin-top: 0.5em">
                <i class="icon" [ngClass]="icons.s"></i>
                {{ object.ObjectName }}
                <a
                    (click)="showDiagModal(object)"
                    *ngIf="object.Diagnostics.length > 0 && alert?.AlertType != 'EventLogCleared'"
                    style="float: right; cursor: pointer">
                    view details
                </a>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!readonly" style="margin-bottom: 1em">
    <div class="" [class.negative]="hasError" *ngIf="!alert?.fullyAnswered">
        <h3 style="margin-bottom: 10px">
            Select the items to act on below:
            <!--&nbsp;&nbsp;&nbsp;
            <span style="font-size: 0.8em">
                <a href="#" (click)="setAll(true)">Select All</a> |
                <a href="#" (click)="setAll(false)">Clear All</a>
            </span>-->
        </h3>
    </div>

    <div class="ui top attached message" *ngIf="alert?.fullyAnswered">
        <div class="header">This alert has already been responded to completely.</div>
    </div>

    <div class="ui attached segment alertActionActs">
        <h4 class="ui header">
            <span class="field" [class.error]="listError && !alertData?.Primary.ActionType">
                <span style="float: right" *ngIf="alertData?.Primary.ActionType">
                    <i
                        class="icon"
                        style="margin: 0"
                        [class]="actionIcons[alertData?.Primary.ActionType]"></i>
                </span>

                <itc-checkbox [control]="pf">
                    <i class="{{ icons.p }} icon"></i>
                    {{ alertData?.Primary.ObjectName }}
                </itc-checkbox>

                <a
                    class="diags link"
                    (click)="showDiagModal(alertData?.Primary)"
                    *ngIf="
                        alertData?.Primary.Diagnostics.length > 0 &&
                        alert?.AlertType != 'EventLogCleared'
                    ">
                    view details
                </a>
            </span>
        </h4>

        <div
            class="ui relaxed list"
            style="margin-left: 1.5rem"
            *ngIf="alertData?.Secondary.length > 0">
            <div
                class="field"
                *ngFor="let object of alertData?.Secondary; let i = index"
                [class.error]="listError && !object.ActionType">
                <span style="float: right" *ngIf="object.ActionType">
                    <i class="icon" [class]="actionIcons[object.ActionType]" style="margin: 0"></i>
                </span>

                <itc-checkbox [control]="sf[i]" (onChanged)="valueChangedCheckkbox(i)">
                    <i class="{{ icons.s }} icon"></i>
                    {{ object.ObjectName }}
                </itc-checkbox>

                <a
                    class="diags link"
                    (click)="showDiagModal(object)"
                    *ngIf="object.Diagnostics.length > 0 && alert?.AlertType != 'EventLogCleared'">
                    view details
                </a>
            </div>
        </div>
    </div>

    <div class="ui bottom attached negative message" *ngIf="hasError">
        You must select at least one item!
    </div>
</div>

<sds-alert-diag-modal #diagContainer [alert]="alert" [data]="diagObject"></sds-alert-diag-modal>
