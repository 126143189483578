import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { FileSizePipe } from 'app/shared/fileSize.pipe';
import { FileUpload } from '../file-uploader.model';
import { ItcFileUploaderService } from '../file-uploader.service';
import { CommonModule } from '@angular/common';
import { ItcProgressBarComponent } from '../../progress-bar.component';
import { ItcButtonComponent } from '../../button/button.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
    standalone: true,
    imports: [CommonModule, ItcProgressBarComponent, ItcButtonComponent, FontAwesomeModule],
    selector: 'itc-file-list-item',
    templateUrl: './file-list-item.component.html',
    styleUrls: ['./file-list-item.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileListItemComponent {
    @Input() set file(value: FileUpload) {
        if (value) {
            this.processFileInfo(value);
        }
    }
    get file() {
        return this._file;
    }
    @Input() canDelete = false;

    @Output() removeFile = new EventEmitter<FileUpload>();
    _file: FileUpload;

    fileSizePipe = inject(FileSizePipe);
    fileUploaderService = inject(ItcFileUploaderService);

    processFileInfo(file: FileUpload) {
        // process file in case we only get a name
        if (file.observable) {
            this._file = file;
        } else {
            let fileObj = {
                name: file.name,
                size: file.size ? this.fileSizePipe.transform(file.size) : null,
                icon: this.fileUploaderService.getFileIcon(file),
                type: file.type || '',
                state: file.state || 'complete',
                observable: file.observable || null,
                progress: file.progress || 100,
                canDelete: file.canDelete || false,
            };
            this._file = fileObj;
        }
    }
}
