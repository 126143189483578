import {
    ChangeDetectorRef,
    Component,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { PING_SITE, SITE } from 'app/sites/shared/constants';
import { Site } from 'app/sites/shared/site.model';
import { MessageService } from 'app/core/message.service';
import { SiteParam, SiteSettingService } from '../site-settings.service';
import { UiService } from 'app/core/ui.service';
import { ConnectionService } from 'app/connections/connection.service';
import { Connection } from 'app/connections/connection.model';
import { FilesService } from 'app/sites/site/assessments/files/files.service';

import * as saveAs from 'file-saver';
import { NotificationService } from '../../../../shared/itc/notification/notification.service';

@Component({
    selector: 'sds-site-itcomplete',
    templateUrl: './itcomplete.component.html',
    styleUrls: ['./itcomplete.component.css'],
})
export class SiteItCompleteComponent implements OnInit, OnDestroy {
    loadingComplete: boolean;
    aSub: Subscription;
    itgSaving: boolean;
    itgForm: UntypedFormGroup;
    itgEnabled: boolean;
    itgWASEnabled: boolean;
    itgSettingNames = {
        enabled: 'ITGLUE_ENABLED',
        url: 'ITGLUE_URL',
    };
    itgDomains = ['.itglue.com', '.au.itglue.com', '.eu.itglue.com'];
    // itgOriginalValues: any = {};

    /* VSA Connector */
    vsaConnectorForm: UntypedFormGroup;
    vsaConnectorEnabled: boolean;
    vsaConnectorId: string;
    vsaConnectorSaving: boolean;

    /* BMS */
    bmsUseAPIAsTyping: boolean = false; // use API as typing instead of getting all in checkConnection
    bmsSaving: boolean = false;
    bmsForm: UntypedFormGroup;
    bmsEnabled: boolean;
    bmsSubmitted: boolean = false;
    bmsWASEnabled: boolean;
    bmsHasConnection: boolean = false;
    bmsSiteConnection: Connection;
    bmsEndpoints: number | string = 'N/A';
    bmsEmployees: number | string = 'N/A';
    bmsUsers: number | string = 'N/A';
    bmsLastSync: string = 'N/A';
    bmsLastSyncString: string = 'N/A';
    bmsSyncing: boolean = false;
    bmsError: string;
    bmsAllContractsBySite: any[];
    bmsAllContractsBySiteFiltered: any[];
    bmsContractSearching: boolean = false;
    bmsAllServicesByContract: any[];
    bmsAllServicesByContractFiltered: any[];
    bmsServiceSearching: boolean = false;
    bmsCheckingConnection: boolean = false;
    bmsContractFocus: boolean = false;
    bmsServiceFocus: boolean = false;
    bmsSettingNames = {
        enabled: 'BMS_ENABLED',
        contract: 'BMS_CONTRACT',
        contractObj: 'BMS_CONTRACTOBJ',
        service: 'BMS_SERVICE',
        serviceObj: 'BMS_SERVICEOBJ',
        billBy: 'BMS_BILLBY',
        min: 'BMS_MIN',
        max: 'BMS_MAX',
        lastSync: 'BMS_LASTSYNC',
        lastSyncUnits: 'BMS_LASTSYNC_UNITS',
    };
    bmsOriginalValues: any = {};
    ngUnsubscribe: Subject<SiteItCompleteComponent> = new Subject();
    site: Site;
    doReload: number;

    breadcrumbs = [
        { path: '../..', text: 'Compliance Manager' },
        { path: '..', text: 'Settings' },
        { path: '.', text: 'IT Complete' },
    ];

    @ViewChild('connectorModal', { static: true }) connectorModal: any;
    selectedConnectorFiles: any[];
    connectorModalLoadingComplete: boolean;
    loadingZip: boolean;
    deletingFiles: boolean;

    // handle if the user tries to leave the page via refresh or typing a different url
    @HostListener('window:beforeunload', ['$event'])
    checkForUnsavedChanges($event) {
        if (this.isFormDirty()) {
            $event.returnValue = true;
        }
    }

    constructor(
        private messageService: MessageService,
        private siteSettingService: SiteSettingService,
        private notificationService: NotificationService,
        private uiService: UiService,
        private connectionService: ConnectionService,
        private formBuilder: UntypedFormBuilder,
        private filesService: FilesService,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        /* not using reactive form for itg because the stupid sm-select doesn't have a control and only emits */
        this.itgEnabled = false;
        this.itgWASEnabled = false;
        this.vsaConnectorEnabled = false;
        this.loadingZip = false;
        this.doReload = 0;
        this.itgForm = this.formBuilder.group({
            enabled: false,
            url: ['', Validators.required],
            domain: [this.itgDomains[0], Validators.required],
            urlFull: '',
        });
        this.itgForm
            .get('enabled')
            .valueChanges.pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((value) => {
                this.itgEnabled = value;
            });

        this.vsaConnectorForm = this.formBuilder.group({
            enabled: false,
            url: ['', Validators.required],
            domain: [this.itgDomains[0], Validators.required],
            urlFull: '',
        });

        this.vsaConnectorForm
            .get('enabled')
            .valueChanges.pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((value) => {
                this.onVsaConnectorFormChange(value);
            });

        this.bmsEnabled = false;
        this.bmsWASEnabled = false;
        this.bmsForm = this.formBuilder.group(
            {
                enabled: false,
                contract: ['', Validators.required],
                contractObj: ['', Validators.required],
                service: [{ value: '', disabled: true }, Validators.required],
                serviceObj: ['', Validators.required],
                billBy: ['endpoints', Validators.required],
                min: ['1', [Validators.required, ValidateMin, Validators.pattern(/^\d+$/)]],
                max: [
                    { value: '-1', disabled: true },
                    [Validators.required, ValidateMax, Validators.pattern(/^-?\d+$/)],
                ],
                maxUnlimited: true,
            },
            { validator: validateMinMax }
        );
        this.bmsForm
            .get('enabled')
            .valueChanges.pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((value) => {
                // bms integration toggled
                this.bmsEnabled = value;
                if (value) {
                    this.bmsCheckConnection();
                    // reset whether the form was submitted or not to hide validation
                    this.bmsSubmitted = false;
                }
            });
        this.bmsForm
            .get('contract')
            .valueChanges.pipe(
                debounceTime(400),
                // can't use distinctuntilchanged because it's not being updated
                // distinctUntilChanged(),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe((value) => {
                this.bmsSearch('contract', value);
            });
        this.bmsForm
            .get('service')
            .valueChanges.pipe(
                debounceTime(400),
                // can't use distinctuntilchanged because it's not being updated
                // distinctUntilChanged(),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe((value) => {
                this.bmsSearch('service', value);
            });
        this.bmsForm
            .get('maxUnlimited')
            .valueChanges.pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((value) => {
                // when maxunlimited checkbox clicked enable/disable max input
                if (value) {
                    this.bmsForm.get('max').disable();
                } else {
                    this.bmsForm.get('max').enable();
                }
            });

        this.aSub = this.messageService.on(SITE).subscribe((site: Site) => {
            this.processSite(site);
        });

        this.messageService.broadcast(PING_SITE);
    }

    processSite(site: Site) {
        if (site) {
            this.site = site;

            this.uiService.setTitle('IT Complete', site.Name);

            this.siteSettingService.getAllSiteParams(site.Id).then((sp) => {
                let siteParams = {};
                sp.forEach((s) => {
                    siteParams[s.Name] = s.Value;
                });

                /* ITGlue Settings */
                let spItgEnabled = this.isTrueSet(siteParams[this.itgSettingNames['enabled']]);
                this.itgEnabled = spItgEnabled;
                if (spItgEnabled && spItgEnabled != this.itgForm.get('enabled').value) {
                    this.itgForm.get('enabled').setValue(spItgEnabled);
                    this.itgWASEnabled = spItgEnabled;
                }
                let spItgUrl = siteParams[this.itgSettingNames['url']];
                if (spItgUrl && spItgUrl.length > 0) {
                    let itgDomain = '';
                    this.itgForm.get('urlFull').setValue(spItgUrl, { emitEvent: false });
                    this.itgDomains.forEach((domain) => {
                        if (this.itgForm.get('urlFull').value.indexOf(domain) > -1) {
                            this.itgForm.get('domain').setValue(domain, { emitEvent: false });
                            itgDomain = domain;
                        }
                    });
                    this.itgForm
                        .get('url')
                        .setValue(spItgUrl.replace(itgDomain, ''), { emitEvent: false }); // just subdomain, i went this rouute in case dots are valid in itgluesubdomains
                }
                // // save original values for reset
                // this.itgOriginalValues = this.itgForm.value;

                /* Bms Settings */
                let spBmsEnabled = this.isTrueSet(siteParams[this.bmsSettingNames['enabled']]);
                // this.bmsOriginalValues['enabled'] = spBmsEnabled;
                if (spBmsEnabled && spBmsEnabled != this.bmsForm.get('enabled').value) {
                    this.bmsForm.get('enabled').setValue(spBmsEnabled);
                    this.bmsWASEnabled = spBmsEnabled;
                }
                if (siteParams[this.bmsSettingNames['contract']]) {
                    // this.bmsOriginalValues['contract'] = siteParams[this.bmsSettingNames['contract']];
                    this.bmsForm
                        .get('contract')
                        .setValue(siteParams[this.bmsSettingNames['contract']], {
                            emitEvent: false,
                        });
                }
                if (siteParams[this.bmsSettingNames['contractObj']]) {
                    // this.bmsOriginalValues['contractObj'] = siteParams[this.bmsSettingNames['contractObj']];
                    this.bmsForm
                        .get('contractObj')
                        .setValue(JSON.parse(siteParams[this.bmsSettingNames['contractObj']]), {
                            emitEvent: false,
                        });
                    // if there's a contract obj, it's set so enable the service field
                    this.bmsForm.get('service').enable({ emitEvent: false });
                }
                if (siteParams[this.bmsSettingNames['service']]) {
                    // this.bmsOriginalValues['service'] = siteParams[this.bmsSettingNames['service']];
                    this.bmsForm
                        .get('service')
                        .setValue(siteParams[this.bmsSettingNames['service']], {
                            emitEvent: false,
                        });
                }
                if (siteParams[this.bmsSettingNames['serviceObj']]) {
                    // this.bmsOriginalValues['serviceObj'] = siteParams[this.bmsSettingNames['serviceObj']];
                    this.bmsForm
                        .get('serviceObj')
                        .setValue(JSON.parse(siteParams[this.bmsSettingNames['serviceObj']]), {
                            emitEvent: false,
                        });
                }
                if (siteParams[this.bmsSettingNames['billBy']]) {
                    // this.bmsOriginalValues['billBy'] = siteParams[this.bmsSettingNames['billBy']];
                    this.bmsForm.get('billBy').setValue(siteParams[this.bmsSettingNames['billBy']]);
                }
                if (siteParams[this.bmsSettingNames['min']]) {
                    // this.bmsOriginalValues['min'] = siteParams[this.bmsSettingNames['min']];
                    this.bmsForm
                        .get('min')
                        .setValue(siteParams[this.bmsSettingNames['min']], { eventEmit: false });
                }
                if (siteParams[this.bmsSettingNames['max']]) {
                    // this.bmsOriginalValues['max'] = siteParams[this.bmsSettingNames['max']];
                    this.bmsForm
                        .get('max')
                        .setValue(siteParams[this.bmsSettingNames['max']], { emitEvent: false });
                    // this.bmsOriginalValues['maxUnlimited'] = (siteParams[this.bmsSettingNames['max']] == "-1");
                    this.bmsForm
                        .get('maxUnlimited')
                        .setValue(siteParams[this.bmsSettingNames['max']] == '-1');
                }
                this.bmsUpdateSync(
                    siteParams[this.bmsSettingNames['lastSync']],
                    siteParams[this.bmsSettingNames['lastSyncUnits']]
                );

                // save original values for reset
                this.bmsOriginalValues = this.bmsForm.value;
            });

            this.getVsaConnector(true);

            this.loadingComplete = true;
        }
    }

    ngOnDestroy() {
        this.aSub.unsubscribe();
        this.ngUnsubscribe.next(void 0);
        this.ngUnsubscribe.complete();
    }

    canDeactivate() {
        return (
            !this.isFormDirty() ||
            confirm('You have unsaved changes. Do you want to leave without saving?')
        );
    }

    isFormDirty() {
        // check to see if we should mark form as dirty
        return this.bmsForm.dirty || this.itgForm.dirty || this.vsaConnectorForm.dirty;
    }

    isTrueSet(v: any) {
        return v === 'true' || v === 'True';
    }

    onVsaConnectorFormChange(v) {
        this.vsaConnectorEnabled = v;
        this.getVsaConnector(false);
    }

    getVsaConnector(fromInit: boolean) {
        this.siteSettingService.getVsaConnector(this.site.Id).then((res) => {
            this.vsaConnectorId = res.Id;
            if (fromInit) {
                this.vsaConnectorEnabled = this.isTrueSet(res.Enabled);
                this.vsaConnectorForm.get('enabled').setValue(this.vsaConnectorEnabled);
            }
        });
    }

    copyVsaConnectorToClipboard() {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = this.vsaConnectorId;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);

        this.notificationService.toast.success('Success', 'VSA Connector ID Copied to Clipboard');
    }

    async saveVsaConnector() {
        await this.siteSettingService.setVsaConnector(this.site.Id, this.vsaConnectorEnabled);
        this.notificationService.toast.success('Success', 'VSA Connector Setting Saved');
        this.vsaConnectorForm.markAsPristine();
    }

    manageConnectorScans() {
        this.selectedConnectorFiles = [];
        this.connectorModal.show();
        this.connectorModalLoadingComplete = true;
    }

    // this has to be used because our version of sm-select doesn't allow a control (also why itg isn't reactive form)
    itgUpdateDomain(v) {
        // only run if it's changed
        if (this.itgForm.get('domain').value != v) {
            this.itgForm.get('domain').setValue(v);
            // I'm not sure why this call doesn't mark as dirty, so do it manually
            this.itgForm.get('domain').markAsDirty();
        }
    }

    saveItGluePreferences() {
        this.itgSaving = true;
        let _spaSiteParams: SiteParam[] = [];
        let _spSiteParamEnabled: SiteParam = new SiteParam();
        _spSiteParamEnabled.SiteId = this.site.Id;
        _spSiteParamEnabled.Name = this.itgSettingNames['enabled'];
        _spSiteParamEnabled.Value = this.itgEnabled.toString();
        _spaSiteParams.push(_spSiteParamEnabled);

        let itgUrl = this.itgForm.get('url').value;
        let itgDomain = this.itgForm.get('domain').value;

        if (itgUrl && itgUrl.length > 0) {
            // strip out the domain if they entered it anyway.
            this.itgDomains.forEach((domain) => {
                if (domain != '.itglue.com') {
                    this.itgForm.get('url').setValue(itgUrl.replace(domain, ''));
                }
            });
            // only do this last, otherwise we'll screw up .whatever.itglue.com since that's first
            this.itgForm.get('url').setValue(itgUrl.replace('.itglue.com', ''));

            let _spSiteParamUrl: SiteParam = new SiteParam();
            _spSiteParamUrl.SiteId = this.site.Id;
            _spSiteParamUrl.Name = this.itgSettingNames['url'];
            _spSiteParamUrl.Value = itgUrl + itgDomain;
            _spaSiteParams.push(_spSiteParamUrl);
        }

        this.siteSettingService
            .upsertSiteParams(this.site.Id, _spaSiteParams)
            .then((s) => {
                // toggle wasenabled to know when you disable the save button when disabled and no changes
                this.itgWASEnabled = !this.itgEnabled ? false : true;
                // this.itgOriginalValues = this.itgForm.value;
                this.itgForm.markAsPristine();
                this.notificationService.toast.success(
                    'Success',
                    'IT Complete setting was updated.'
                );
                this.itgSaving = false;
            })
            .catch((error) => {
                this.notificationService.toast.error(
                    'Error',
                    'There was a problem saving your preferences, please try again.'
                );
                this.itgSaving = false;
            });
    }

    // just disabled for now because sm-dropdown is garbage and it'd need to be changed for domain
    // itgResetForm() {
    //     this.itgForm.reset(this.itgOriginalValues, { emitEvent: false });
    //     this.itgEnabled = this.itgForm.get('enabled').value;
    //     this.itgUpdateDomain(this.itgForm.get('domain').value);
    // }

    /* BMS */
    bmsCheckConnection() {
        if (this.site) {
            // console.log("Checking Connection");
            this.bmsCheckingConnection = true;
            this.connectionService.getConnectionBySite(this.site.Id).then((conn) => {
                this.bmsSiteConnection = conn;
                this.bmsHasConnection =
                    this.bmsSiteConnection !== null ? this.bmsSiteConnection.Type == 'KBMS' : false;
                // console.log("Connection Valid: ", this.bmsHasConnection);
                // console.log("Connection: ", this.bmsSiteConnection);
                this.siteSettingService.getKBMSQuantities(this.site.Id).then((q) => {
                    this.bmsEndpoints = q.COMPUTER_COUNT;
                    // if no assessment data, service will return -1, so keep at N/A if we don't get values
                    if (q.CURRENTUSER_COUNT >= 0) {
                        this.bmsEmployees = q.CURRENTUSER_COUNT;
                    }
                    if (q.TOTALUSER_COUNT >= 0) {
                        this.bmsUsers = q.TOTALUSER_COUNT;
                    }
                    // console.log("Got quantities: ", q);
                });
                if (this.bmsEnabled && !this.bmsUseAPIAsTyping) {
                    // only get services if it's enabled
                    // console.log("Enabled and not api");
                    this.bmsGetAllContracts();
                    this.bmsGetAllServices();
                }
                this.bmsCheckingConnection = false;
            });
        }
    }

    bmsGetAllContracts() {
        this.bmsContractSearching = true;
        // console.log("Getting All Contracts for: ",this.site.Id);
        this.connectionService.getKBMSContracts(this.site.Id, '').then((c) => {
            this.bmsAllContractsBySite = c;
            this.bmsAllContractsBySite.sort(propComparator('ContractName'));
            // console.log("bmsContracts: ", this.bmsAllContractsBySite);

            // check to make sure contract is still in list if it's set.
            if (this.bmsForm.get('contractObj').value !== '') {
                let contractobjId = this.bmsForm.get('contractObj').value.Id;
                let contractValid = false;
                this.bmsAllContractsBySite.forEach((c) => {
                    if (c.Id == contractobjId) {
                        contractValid = true;
                    }
                });
                if (!contractValid) {
                    // throw toast
                    this.notificationService.toast.error(
                        'Error',
                        'Your previously selected contract is no longer available. Please choose a different contract.'
                    );
                    // mark form as submitted to show validation error
                    this.bmsSubmitted = true;
                    // clear contract, contractobj, service and service obj
                    this.bmsForm.get('contract').setValue('', { emitEvent: false });
                    this.bmsForm.get('contractObj').setValue('', { emitEvent: false });
                    this.bmsForm.get('service').disable({ emitEvent: false });
                    this.bmsForm.get('service').setValue('', { emitEvent: false });
                    this.bmsForm.get('serviceObj').setValue('', { emitEvent: false });
                }
            }

            this.bmsAllContractsBySiteFiltered = this.bmsForm.get('contract').value
                ? []
                : [...this.bmsAllContractsBySite];
            this.bmsContractSearching = false;
            // console.log("bmsContractsFiltered: ", this.bmsAllContractsBySiteFiltered);
        });
    }

    bmsGetAllServices() {
        if (this.bmsForm.get('contractObj').value) {
            // console.log("Getting All Services for: ", this.bmsForm.get('contractObj').value.Id);
            this.bmsServiceSearching = true;
            this.connectionService
                .getKBMSContractRecurringServices(
                    this.site.Id,
                    '',
                    this.bmsForm.get('contractObj').value.Id
                )
                .then((s) => {
                    this.bmsAllServicesByContract = s;
                    this.bmsAllServicesByContract.sort(propComparator('ServiceName'));
                    // console.log("bmsServices: ", this.bmsAllServicesByContract);
                    // console.log("serviceObj", this.bmsForm.get('serviceObj').value);

                    // check to make sure service is still in list if it's set.
                    // console.log("servobj", this.bmsForm.get('serviceObj').value);
                    if (this.bmsForm.get('serviceObj').value !== '') {
                        let servobjId = this.bmsForm.get('serviceObj').value.Id;
                        let serviceValid = false;
                        this.bmsAllServicesByContract.forEach((c) => {
                            if (c.Id == servobjId) {
                                serviceValid = true;
                            }
                        });
                        if (!serviceValid) {
                            // throw toast
                            this.notificationService.toast.error(
                                'Error',
                                'Your previously selected service is no longer available. Please choose a different service.'
                            );
                            // mark form as submitted to show validation error
                            this.bmsSubmitted = true;
                            // clear service and service obj
                            this.bmsForm.get('service').setValue('', { emitEvent: false });
                            this.bmsForm.get('serviceObj').setValue('', { emitEvent: false });
                        }
                    }

                    this.bmsAllServicesByContractFiltered = this.bmsForm.get('service').value
                        ? []
                        : [...this.bmsAllServicesByContract];
                    this.bmsServiceSearching = false;
                    // console.log("bmsServicesFiltered: ", this.bmsAllServicesByContractFiltered);
                });
        }
    }

    bmsSearch(type, value) {
        if (type == 'contract') {
            this.bmsContractSearching = true;

            // if contract is changed, unset contractobj and service/serviceobj because they're tied to contract
            this.bmsForm.get('contractObj').setValue('', { emitEvent: false });
            this.bmsForm.get('service').setValue('', { emitEvent: false });
            this.bmsForm.get('service').disable({ emitEvent: false });
            this.bmsForm.get('serviceObj').setValue('', { emitEvent: false });

            if (this.bmsUseAPIAsTyping) {
                if (value != '') {
                    this.connectionService
                        .getKBMSContracts(this.site.Id, value)
                        .then((contracts) => {
                            this.bmsAllContractsBySiteFiltered = contracts;
                            this.bmsContractSearching = false;
                        })
                        .catch((error) => {
                            this.bmsContractSearching = false;
                        });
                } else {
                    this.bmsContractSearching = false;
                }
            } else {
                this.bmsFilter(type, value);
                this.bmsContractSearching = false;
            }
        } else if (type == 'service') {
            this.bmsServiceSearching = true;
            // clear serviceObj since we'll key off of that to know if they have a valid service
            this.bmsForm.get('serviceObj').setValue('', { emitEvent: false });
            this.bmsFilter(type, value);
            this.bmsServiceSearching = false;

            // commenting out but leaving here in case we want to use the api as typing form on services but I don't think there'd be enough to be worth the calls
            // if (this.bmsUseAPIAsTyping) {
            //     if (value != "") {
            //         this.connectionService.getKBMSContractRecurringServices(this.site.Id, value, this.bmsForm.get('contractObj').value.Id).then((services) => {
            //             this.bmsAllServicesByContractFiltered = services;
            //             this.bmsServiceSearching = false;
            //         }).catch( error => { this.bmsServiceSearching = false; });
            //     } else {
            //         this.bmsServiceSearching = false;
            //     }
            // } else {
            // this.bmsFilter(type, value);
            // this.bmsServiceSearching = false;
            // }
        }
    }

    bmsFilter(type, val) {
        if (type == 'contract') {
            // console.log("Filter contracts: ", val);
            this.bmsAllContractsBySiteFiltered = this.bmsAllContractsBySite.filter((c) => {
                return c.ContractName.toUpperCase().indexOf(val.toUpperCase()) > -1;
            });
            // if contract typed == the only returned contract, set it
            if (this.bmsAllContractsBySiteFiltered.length == 1) {
                if (val === this.bmsAllContractsBySiteFiltered[0].ContractName) {
                    this.bmsSetContractService('contract', this.bmsAllContractsBySiteFiltered[0]);
                }
            }
        } else if (type == 'service') {
            // console.log("Filter services: ", val);
            this.bmsAllServicesByContractFiltered = this.bmsAllServicesByContract.filter((s) => {
                return s.ServiceName.toLowerCase().indexOf(val.toLowerCase()) > -1;
            });
            // if service typed == the only returned service, set it
            if (this.bmsAllServicesByContractFiltered.length == 1) {
                if (val === this.bmsAllServicesByContractFiltered[0].ServiceName) {
                    this.bmsSetContractService('service', this.bmsAllServicesByContractFiltered[0]);
                }
            }
        }
    }

    bmsSetContractService(type, obj) {
        if (type == 'contract') {
            this.bmsForm.get('contract').setValue(obj.ContractName, { emitEvent: false });
            this.bmsForm.get('contractObj').setValue(obj, { emitEvent: false });
            this.bmsForm.get('service').enable({ emitEvent: false });
            this.bmsAllContractsBySiteFiltered = [];
            this.bmsGetAllServices();
        } else if (type == 'service') {
            this.bmsForm.get('service').setValue(obj.ServiceName, { emitEvent: false });
            this.bmsForm.get('serviceObj').setValue(obj, { emitEvent: false });
            this.bmsAllServicesByContractFiltered = [];
        }
    }

    saveBmsPreferences() {
        this.bmsSubmitted = true;
        this.bmsSaving = true;

        let _spaSiteParams: SiteParam[] = [];
        let _spSiteParamEnabled: SiteParam = new SiteParam();
        _spSiteParamEnabled.SiteId = this.site.Id;
        _spSiteParamEnabled.Name = this.bmsSettingNames['enabled'];
        _spSiteParamEnabled.Value = this.bmsEnabled.toString();
        _spaSiteParams.push(_spSiteParamEnabled);
        // this.bmsOriginalValues['enabled'] = this.bmsEnabled;

        // if bms isn't enabled, don't worry about the extra variables, so we don't have to
        // deal with validation on fields that are hidden
        if (this.bmsEnabled) {
            // validate
            let errToast = '';
            if (this.bmsForm.get('contract').status == 'INVALID') {
                errToast =
                    'Contract is a required field. Please select Contract or disable integration';
            } else if (this.bmsForm.get('contractObj').status == 'INVALID') {
                errToast =
                    'Selected contract is not valid. Please choose a valid contract or disable integration';
            } else if (this.bmsForm.get('service').status == 'INVALID') {
                errToast =
                    'Service is a required field. Please select Service or disable integration';
            } else if (this.bmsForm.get('serviceObj').status == 'INVALID') {
                errToast =
                    'Selected Service is not valid. Please choose a valid service or disable integration';
            } else if (this.bmsForm.get('billBy').status == 'INVALID') {
                errToast =
                    'Bill By is a required field. Please choose an option or disable integration';
            } else if (this.bmsForm.get('min').status == 'INVALID') {
                errToast = 'Minimum quantity must be a numeric value of 1 or greater';
            } else if (this.bmsForm.get('max').status == 'INVALID') {
                errToast =
                    'Maximum quantity must be -1 for unlimited or a numeric value greater than 1.';
            } else if (this.bmsForm.status == 'INVALID' && this.bmsForm.errors['minMaxFailed']) {
                errToast = 'Minimum quantity must be less than maximum quantity';
            }
            if (errToast !== '') {
                this.notificationService.toast.error('Error', errToast);
                this.bmsSaving = false;
                return;
            }

            // save full form
            // contract
            let _spSiteParamContract: SiteParam = new SiteParam();
            _spSiteParamContract.SiteId = this.site.Id;
            _spSiteParamContract.Name = this.bmsSettingNames['contract'];
            _spSiteParamContract.Value = this.bmsForm.get('contract').value;
            _spaSiteParams.push(_spSiteParamContract);
            // this.bmsOriginalValues['contract'] = this.bmsForm.get('contract').value;

            // contractObj
            let _spSiteParamContractObj: SiteParam = new SiteParam();
            _spSiteParamContractObj.SiteId = this.site.Id;
            _spSiteParamContractObj.Name = this.bmsSettingNames['contractObj'];
            _spSiteParamContractObj.Value = JSON.stringify(this.bmsForm.get('contractObj').value);
            _spaSiteParams.push(_spSiteParamContractObj);
            // this.bmsOriginalValues['contractObj'] = this.bmsForm.get('contractObj').value;

            // service
            let _spSiteParamService: SiteParam = new SiteParam();
            _spSiteParamService.SiteId = this.site.Id;
            _spSiteParamService.Name = this.bmsSettingNames['service'];
            _spSiteParamService.Value = this.bmsForm.get('service').value;
            _spaSiteParams.push(_spSiteParamService);
            // this.bmsOriginalValues['service'] = this.bmsForm.get('service').value;

            // serviceObj
            let _spSiteParamServiceObj: SiteParam = new SiteParam();
            _spSiteParamServiceObj.SiteId = this.site.Id;
            _spSiteParamServiceObj.Name = this.bmsSettingNames['serviceObj'];
            _spSiteParamServiceObj.Value = JSON.stringify(this.bmsForm.get('serviceObj').value);
            _spaSiteParams.push(_spSiteParamServiceObj);
            // this.bmsOriginalValues['serviceObj'] = this.bmsForm.get('serviceObj').value;

            // billby
            let _spSiteParamBillBy: SiteParam = new SiteParam();
            _spSiteParamBillBy.SiteId = this.site.Id;
            _spSiteParamBillBy.Name = this.bmsSettingNames['billBy'];
            _spSiteParamBillBy.Value = this.bmsForm.get('billBy').value;
            _spaSiteParams.push(_spSiteParamBillBy);
            // this.bmsOriginalValues['billBy'] = this.bmsForm.get('billBy').value;

            // min
            let _spSiteParamMin: SiteParam = new SiteParam();
            _spSiteParamMin.SiteId = this.site.Id;
            _spSiteParamMin.Name = this.bmsSettingNames['min'];
            _spSiteParamMin.Value = this.bmsForm.get('min').value;
            _spaSiteParams.push(_spSiteParamMin);
            // this.bmsOriginalValues['min'] = this.bmsForm.get('min').value;

            // contract
            let _spSiteParamMax: SiteParam = new SiteParam();
            _spSiteParamMax.SiteId = this.site.Id;
            _spSiteParamMax.Name = this.bmsSettingNames['max'];
            _spSiteParamMax.Value = this.bmsForm.get('maxUnlimited').value
                ? -1
                : this.bmsForm.get('max').value;
            _spaSiteParams.push(_spSiteParamMax);
            // this.bmsOriginalValues['max'] = this.bmsForm.get('max').value;
            // this.bmsOriginalValues['maxUnlimited'] = (this.bmsForm.get('maxUnlimited').value ? -1 : this.bmsForm.get('max').value);
        }

        this.siteSettingService
            .upsertSiteParams(this.site.Id, _spaSiteParams)
            .then((s) => {
                // toggle wasenabled to know when you disable the save button when disabled and no changes
                this.bmsWASEnabled = !this.bmsEnabled ? false : true;
                this.notificationService.toast.success('Success', 'BMS settings updated.');
                this.bmsOriginalValues = this.bmsForm.value;
                // console.log("setting bmsOriginValues on save",this.bmsOriginalValues);
                this.bmsForm.markAsPristine();
                this.bmsSubmitted = false;
                this.bmsSaving = false;
            })
            .catch((error) => {
                this.notificationService.toast.error(
                    'Error',
                    'There was a problem saving your preferences, please try again.'
                );
                this.bmsSubmitted = false;
                this.bmsSaving = false;
            });
    }

    bmsSync() {
        if (this.site) {
            this.bmsSyncing = true;
            this.connectionService
                .SyncKBMSUnits(this.site.Id)
                .then((ls) => {
                    ls = JSON.parse(ls);
                    this.notificationService.toast.success(
                        'Success',
                        'Sync with BMS server successful'
                    );
                    this.bmsLastSyncString = ls.SYNC_TIME + ' (Quantity: ' + ls.SYNC_UNITS + ')';
                    // this.bmsLastSyncQuantity = ls.SYNC_UNITS;
                    this.bmsSyncing = false;
                })
                .catch((error) => {
                    this.bmsSyncing = false;
                    this.notificationService.toast.warning('Warning', error.error);
                    // refresh site params to get updated sync data
                    this.siteSettingService.getAllSiteParams(this.site.Id).then((sp) => {
                        let siteParams = {};
                        sp.forEach((s) => {
                            siteParams[s.Name] = s.Value;
                        });
                        this.bmsUpdateSync(
                            siteParams[this.bmsSettingNames['lastSync']],
                            siteParams[this.bmsSettingNames['lastSyncUnits']]
                        );
                    });
                });
        }
    }

    bmsUpdateSync(time, units) {
        if (time) {
            this.bmsLastSyncString = time;
            if (units) {
                this.bmsLastSyncString += ' (Quantity: ' + units + ')';
            }
        }
    }

    bmsResetForm() {
        this.bmsForm.reset(this.bmsOriginalValues, { emitEvent: false });
        if (this.bmsForm.get('contractObj').value) {
            this.bmsForm.get('service').enable({ emitEvent: false });
        }
        if (this.bmsForm.get('maxUnlimited').value) {
            this.bmsForm.get('max').setValue(-1, { emitEvent: false });
            this.bmsForm.get('max').disable();
        } else {
            this.bmsForm.get('max').enable();
        }
        this.bmsEnabled = this.bmsForm.get('enabled').value;
        this.bmsGetAllServices();
    }

    downloadSelectedConnectorFiles() {
        this.loadingZip = true;
        this.filesService
            .getConnectorFilesZip(this.site.Id, this.selectedConnectorFiles)
            .then((b64) => {
                this.loadingZip = false;
                let raw = window.atob(b64);
                let bytes = new Uint8Array(raw.length);
                for (let i = 0; i < raw.length; i++) {
                    bytes[i] = raw.charCodeAt(i);
                }
                let blob = new Blob([bytes]);

                saveAs(blob, 'ConnectorFiles.zip');
            })
            .catch((err) => {
                this.loadingZip = false;
                console.error(err);
            });
    }

    deleteSelectedConnectorFiles() {
        if (
            confirm(
                'Files will be removed permanently and will no longer be available for import. Proceed?'
            )
        ) {
            this.deletingFiles = true;
            this.filesService
                .deleteConnectorFiles(this.site.Id, this.selectedConnectorFiles)
                .then((res) => {
                    this.doReload++;
                    this.cdr.detectChanges();
                    this.deletingFiles = false;
                })
                .catch((err) => {
                    this.deletingFiles = false;
                    console.error(err);
                });
        }
    }
}

function ValidateMin(control: AbstractControl): { [key: string]: any } | null {
    if (parseFloat(control.value) == parseInt(control.value, 10) && !isNaN(control.value)) {
        if (parseInt(control.value, 10) <= 0) {
            return { validCount: true };
        }
    } else {
        return { invalidNumber: true };
    }
    return null;
}
function ValidateMax(control: AbstractControl): { [key: string]: any } | null {
    if (
        control.value &&
        (Number.isNaN(parseInt(control.value, 10)) ||
            parseInt(control.value, 10) < -1 ||
            parseInt(control.value, 10) == 0)
    ) {
        return { validCount: true };
    }
    return null;
}

function validateMinMax(control: AbstractControl): { [key: string]: any } | null {
    var r = [];
    const min = parseInt(control.get('min').value, 10);
    const max = parseInt(control.get('max').value, 10);
    const maxUnlimited = control.get('maxUnlimited').value;
    return max >= 0 && !maxUnlimited && min > max ? { minMaxFailed: true } : null;
}

const propComparator = (propName) => (a, b) =>
    a[propName].toUpperCase() == b[propName].toUpperCase
        ? 0
        : a[propName].toUpperCase() < b[propName].toUpperCase()
        ? -1
        : 1;
