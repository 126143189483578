import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';

const DEFAULTS: any = environment.production
    ? {
          apiUrl: '/api/',
          envSuffix: '',
          eu: environment.eu,
          au: environment.au,
      }
    : {
          apiUrl: 'https://alerts.alert-central-beta.com/api/',
          envSuffix: '-local',
      };

@Injectable()
export class AppConfig {
    private config: Object = null;

    constructor(private http: HttpClient) {}
    // This isn't really used anymore, but it's so much more complicated to actually remove it
    // we'll do that later.

    initialize() {
        return new Promise((resolve, reject) => {
            // this.config = {
            //     apiUrl: environment.apiUrl,
            //     envSuffix: environment.envSuffix
            // };
            // this.http.get('/assets/config.json', { observe: 'response' })
            //     .toPromise()
            //     .then((res) => {
            //         console.log('app.config promise: ' + res);
            //         this.config = res.body;
            //         resolve(true);
            //     }).catch((res) => {
            //         console.log("defaulting");
            //         console.log('res: ' + res);
            //         this.config = DEFAULTS;
            //         resolve(true);
            //     });

            resolve(true);
        });
    }

    logObject(o: any) {
        for (var propName in o) {
            console.log(propName, o[propName]);
        }
    }

    get(key: string): any {
        //this.logObject(this.config);

        return this.config[key];
    }
}

export const enum AppConfigKeys {
    ApiUrl = 'apiUrl',
    EU = 'eu',
    AU = 'au',
    EnvSuffix = 'envSuffix',
    Beta = 'beta',
}
