import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'deviceIdentifier',
})
export class DeviceIdentifierPipe implements PipeTransform {
    transform(ip: string, hostname: string, macAddress: string): string {
        let rValue = '';

        if (hostname) {
            if (macAddress) {
                rValue = `${hostname} (${ip} / ${macAddress})`;
            } else {
                rValue = `${hostname} (${ip})`;
            }
        } else {
            if (macAddress) {
                rValue = `${ip} (${macAddress})`;
            } else {
                rValue = ip;
            }
        }
        return rValue;
    }
}
