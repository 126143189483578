import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Site } from '../../shared/site.model';
import { AuthService } from '../../../core/auth/auth.service';
import { Router, RoutesRecognized } from '@angular/router';
import { SiteSettingService } from '../settings/site-settings.service';
import { ScanDataService } from '../../../scans/shared/scan-data.service';
import { AssessmentService } from '../../shared/assessment.service';
import { SiteService } from '../../shared/site.service';

const CURRENT_ASSESSMENT: string = '__current';
declare var ago: any;
declare var jQuery: any;

@Component({
    selector: 'sds-site-card',
    templateUrl: './site-card.component.html',
    styleUrls: ['./site-card.component.scss'],
})
export class SiteCardComponent implements OnInit {
    @Input() site: any;
    @Input() cardViewMode: string;
    @Input() openDetails: string;
    @Output() detailsClicked = new EventEmitter<any>();

    friendlyTypes: any = {
        RPS: 'Reporter',
        CH: 'Cyber Hawk',
        AG: 'Compliance Manager',
        KVS: 'Vulnerability Scanner',
        NDPro: 'Network Detective Pro',
    };
    siteTypeIcons: any[];

    constructor(
        private assessmentService: AssessmentService,
        private authService: AuthService,
        private router: Router,
        private scandataService: ScanDataService,
        private siteSettingService: SiteSettingService,
        private siteService: SiteService
    ) {}

    ngOnInit() {
        this.siteTypeIcons = this.siteService.getSiteTypeIcons(this.site.site);
    }

    gotoSite(site: Site) {
        this.siteService.getSite(site.Id).then((siteWithRoles) => {
            this.siteService.goToSite(siteWithRoles);
        });
    }

    setManagementPlans(site: any, plans: any) {
        if (plans.error == 'outdated') {
        } else {
            for (let key in plans) {
                // remove null or objects with no length
                if (plans[key] === null || plans[key].length <= 0) {
                    delete plans[key];
                } else {
                    plans[key] = plans[key].length;
                }
            }
            site.managementPlans = plans;
        }
    }

    getSiteDetails(site: any) {
        console.log('getting site details');
        if (site.apps.length > 0 || site.siteLoading) return;

        site.siteLoading = true;
        this.siteSettingService
            .getApplianceInfoForReal(site.site.Id)
            .then((appliances) => {
                console.log('appliances', appliances);
                site.apps = [];
                if (!appliances || !appliances.length) {
                    // appliance not installed
                    let notInstalled = true;
                    site.apps.push({
                        Id: '<not installed>',
                    });
                    site.siteLoading = false;
                } else {
                    for (let i = 0; i < appliances.length; i++) {
                        let info = appliances[i]; // TODO update this to work with more than one appliance

                        let updateStatus = 'unknown';
                        let updateAvailable = false;
                        if (info.UpdateStatus == 1) {
                            updateStatus = 'up-to-date';
                            updateAvailable = false;
                        } else if (info.UpdateStatus == 0) {
                            updateStatus = 'update available';
                            updateAvailable = true;
                        }

                        // let lastCheckin = new Date(info.LastCheckin);
                        // let lastCheckinAgo = ago(lastCheckin.getTime(), true) + ' ago';
                        // if (lastCheckin.getFullYear() < 2000) {
                        // this.noCheckin = true;
                        // }
                        let types = '';
                        for (let j = 0; j < info.Types.length; j++) {
                            if (types) types += ', ';
                            types += this.friendlyTypes[info.Types[j]];
                        }
                        this.siteSettingService
                            .getApplianceScanStatuses(site.site.Id, info.Id)
                            .then((scans) => {
                                for (let sc of scans) {
                                    let startDate = new Date(sc.start);
                                    sc.start = ago(
                                        this.convertUTCDateToLocalDate(startDate).getTime(),
                                        true
                                    );
                                }
                                let appLastCheckin = new Date(info.LastCheckin);
                                let app = {
                                    Id: info.Id,
                                    Type: types,
                                    LastCheckin:
                                        appLastCheckin.getFullYear() < 2000
                                            ? 'NEVER'
                                            : info.LastCheckin,
                                    UpdateStatus: updateStatus,
                                    UpdateAvailable: updateAvailable,
                                    IsVirtual: info.IsVirtual,
                                    IsServer: info.IsServer,
                                    Scans: scans,
                                    Status: info.IsOnline ? 'Online' : 'Offline',
                                };
                                site.apps.push(app);
                                if (app.Type.indexOf('Cyber Hawk') > -1) {
                                    this.siteSettingService.getAddons(site.site.Id).then((s) => {
                                        site.addons.abds = s.abdsEnabled ? true : false;
                                        // site.addons = "Add-on: ABDS: " + (s.abdsEnabled ? "Enabled" : "Not Enabled");
                                    });
                                }
                                if (app.Type.indexOf('Reporter') > -1) {
                                    if (types.indexOf('Cyber') >= 0) {
                                        this.scandataService
                                            .getCyberhawkManagementPlans(site.site.Id)
                                            .then((plans) => {
                                                this.setManagementPlans(site, plans);
                                            });
                                    } else {
                                        this.scandataService
                                            .getIndocManagementPlans(site.site.Id)
                                            .then((plans) => {
                                                this.setManagementPlans(site, plans);
                                            });
                                    }
                                }
                                if (
                                    app.Type.indexOf('Audit Guru') > -1 ||
                                    app.Type.indexOf('Compliance Manager') > -1
                                ) {
                                    this.assessmentService
                                        .getAssessment(site.site.Id, CURRENT_ASSESSMENT)
                                        .then((assessment) => {
                                            site.assessment = assessment;
                                            //this.isAssessmentStarted = false;
                                            //this.isAssessmentStartable = false;

                                            //if (this.selectedAssessment) {
                                            //  console.log("assessment name: " + this.selectedAssessment.Name);
                                            //  console.log("assessment state: " + this.selectedAssessment.State);
                                            //}
                                            //if (this.selectedAssessment && this.selectedAssessment.State == "Started") {
                                            //  this.isAssessmentStarted = true;
                                            //  console.log("set this.isAssessmentStarted: " + this.isAssessmentStarted);
                                            //}

                                            //if (this.selectedAssessment && this.selectedAssessment.State == "Archived" && assessment.IsLatest) {
                                            //  this.isAssessmentStartable = true;
                                            //}

                                            //if (this.isAssessmentStartable === true) {
                                            //  this.siteService.hasRunningAuditGuruScan(this.site.Id)
                                            //    .then((res) => {
                                            //      if (res) {
                                            //        this.scansRunning = res;
                                            //      }
                                            //    });
                                            //}

                                            //this.assessmentLoading = false;
                                        })
                                        .catch(() => {});
                                }
                            });
                    }
                    setTimeout(() => (site.siteLoading = false), 1500);
                }
            })
            .catch((err) => {});
    }
    convertUTCDateToLocalDate(date) {
        var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

        var offset = date.getTimezoneOffset() / 60;
        var hours = date.getHours();

        newDate.setHours(hours + offset);

        return newDate;
    }

    showDetails(e, site) {
        // if (site.site.Id == this.openDetails) {
        //   jQuery('[data-sid=' + site.site.Id + ']').find('.detailsContainer').slideToggle();
        // } else {
        //   if (this.openDetails !== '') {
        //     jQuery('[data-sid=' + this.openDetails + ']').find('.detailsContainer').slideUp();
        //   }
        //     this.openDetails = site.site.Id;
        //     jQuery('[data-sid=' + site.site.Id + ']').find('.detailsContainer').slideDown();
        //   // site.showDetails = (site.showDetails === undefined) ? site.showDetails = true : !site.showDetails;
        //   this.getSiteDetails(site);
        // }

        this.getSiteDetails(site);
        e.preventDefault();
        e.stopPropagation();
        this.detailsClicked.emit(site.site.Id);
    }
}
