import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'sds-tracker-donut-component',
    templateUrl: './tracker-donut-component.component.html',
    styleUrls: ['./tracker-donut-component.component.scss'],
})
export class TrackerDonutComponentComponent implements OnInit {
    @Input() standard: string;
    @Input() cardHeader: string;
    @Input() cardMeta: string;
    @Input() legendPosition: string;
    @Input() grpNames: string[] = [];
    @Input() diameter: number = 100;
    @Input() measure: string = 'Employees';
    @Input() set chartData(value: chartData[]) {
        console.log('chartData updated', value);
        this._totalCount = 0;
        this._chartData = value;
        value.forEach((a) => {
            this._totalCount += a.count;
        });
    }
    get chartData() {
        return this._chartData;
    }
    @Input() centerText: string;
    @Input() centerTextSmall: string;
    @Input() cardId: string;
    @Input() legendSeparator = [false, false, false, false];
    @Output() cardClicked = new EventEmitter<string>();
    @Output() chartClicked = new EventEmitter<string>();
    _chartData: chartData[];
    _totalCount: number = 0;

    grpSlicedStringName: string = '';
    grpNameString: string = '';
    constructor() {}
    ngOnInit() {
        //http://coursesweb.net/forum/calculate-number-characters-per-line-fit-into-div-t97.htm

        this.grpNameString = this.grpNames.join(', ');
        let grpCharsForLine = Math.ceil((235 * 1.37) / 14);
        if (this.grpNameString.length > grpCharsForLine)
            this.grpSlicedStringName = this.grpNameString.slice(0, grpCharsForLine) + '..';
        else this.grpSlicedStringName = '';
    }

    emitCardClick() {
        this.cardClicked.emit(this.cardId);
    }

    emitChartClick() {
        this.chartClicked.emit(this.cardId);
    }
}

interface chartData {
    bindingName: string;
    count: number;
    color: string;
    legendColor: string;
}
