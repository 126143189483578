import { Component, OnInit } from '@angular/core';
import { MessageService } from 'app/core/message.service';
import { ScanDataDatabase, ScanDataService } from 'app/scans';
import { UiService } from 'app/core/ui.service';
import { Site } from 'app/sites/shared/site.model';
import { PING_SITE, SITE } from 'app/sites/shared/constants';
import { FilterOption } from 'app/sites/shared/data-filter.model';
import { SiteService } from 'app/sites/shared/site.service';
import { NdpService } from 'app/sites/shared/ndp.service';
import { Router } from '@angular/router';
import { AssessmentService } from '../../../shared/assessment.service';

@Component({
    selector: 'sds-site-data-databases',
    templateUrl: './site-data-databases.component.html',
    styleUrls: ['./site-data-databases.component.css'],
})
export class SiteDataDatabasesComponent implements OnInit {
    constructor(
        private messageService: MessageService,
        private scanDataService: ScanDataService,
        private uiService: UiService,
        private siteService: SiteService,
        private ndpService: NdpService,
        private router: Router,
        private assessmentService: AssessmentService
    ) {}

    databases: ScanDataDatabase[] = [];
    filteredDbs: ScanDataDatabase[] = [];
    filtered1: ScanDataDatabase[] = [];
    sortedDbs: ScanDataDatabase[] = [];
    pagedDbs: ScanDataDatabase[] = [];

    filter: string = '_all';
    filters: any[] = [];
    sortColumn: string = '';
    sortDirection: string = '';
    filterOptions: FilterOption[] = [{ id: '_all', name: 'All' }];

    loadingComplete: boolean = false;
    mergeInProgress: boolean = false;
    emptyStateText: string = 'There is no scan data in the current assessment.';
    sub: any;
    dataExplorerType = 'Indoc';

    breadcrumbs = [{ text: 'Network Detective' }, { text: 'Data Explorer' }, { text: 'Databases' }];

    ngOnInit() {
        this.dataExplorerType = this.ndpService.getDataExplorerType();
        this.sub = this.messageService.on(SITE).subscribe(async (site: Site) => {
            if (site) {
                this.uiService.setTitle('Databases', site.Name);
                const assessmentId = await this.ndpService.getAssessmentIdForDataExplorer(
                    site,
                    this.dataExplorerType
                );

                if (assessmentId) {
                    this.assessmentService
                        .getProgressStatusBySiteParams(site.Id, assessmentId, [
                            'NEEDS_NDP_MERGE',
                            'UPDATE_NDP_PORTALDATA',
                        ])
                        .then((res) => {
                            this.mergeInProgress = res;
                        });
                }
                this.getDatabases(site, assessmentId);
            }
        });

        this.messageService.broadcast(PING_SITE);

        this.ndpService.createDataExplorerBreadcrumbs('Databases');
    }

    getDatabases(site: Site, assessmentId: number) {
        this.scanDataService
            .getDatabases(site.Id, assessmentId)
            .then((dbs: ScanDataDatabase[]) => {
                this.databases = dbs;

                console.log(this.databases);

                this.sortColumn = 'Server';
                this.sortDirection = 'asc';
                this.filterDbs();

                this.emptyStateText = this.scanDataService.getEmptyStateText(
                    this.databases,
                    this.mergeInProgress,
                    'database'
                );
                this.loadingComplete = true;

                this.uiService.setTitle('Databases', site.Name);
            })
            .catch((err) => console.error(err));
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    filterDbs(filteredData?: any) {
        if (filteredData) this.filteredDbs = filteredData;
        else this.filteredDbs = this.databases;

        this.filtered1 = this.filteredDbs;
        this.sortDbs();
    }

    // if any row contains string data, return true
    isStringColumn(rows, column) {
        return rows.some((row) => isNaN(parseInt(row[column])) == true);
    }

    sortDbs() {
        if (this.isStringColumn(this.filteredDbs, this.sortColumn)) {
            this.sortedDbs = this.filteredDbs.sort((a, b) => {
                let cola = a[this.sortColumn],
                    colb = b[this.sortColumn];

                if (typeof cola === 'string') cola = cola.toLocaleLowerCase();
                if (typeof colb === 'string') colb = colb.toLocaleLowerCase();

                if (this.sortDirection === 'desc') {
                    return cola < colb ? 1 : cola === colb ? 0 : -1;
                } else {
                    return cola > colb ? 1 : cola === colb ? 0 : -1;
                }
            });
        } else {
            this.sortedDbs = this.filteredDbs.sort((a, b) => {
                let cola = Number(a[this.sortColumn]),
                    colb = Number(b[this.sortColumn]);

                if (this.sortDirection === 'desc') {
                    return cola < colb ? 1 : cola === colb ? 0 : -1;
                } else {
                    return cola > colb ? 1 : cola === colb ? 0 : -1;
                }
            });
        }

        this.filteredDbs = [...this.sortedDbs];
    }

    onSorted(ev?: any) {
        if (ev) {
            this.sortColumn = ev.sortColumn;
            this.sortDirection = ev.sortDirection;
            this.sortDbs();
        }
    }

    onFiltered($event) {
        if ($event) this.filteredDbs = $event;

        console.log(this.filteredDbs);
        this.filterDbs(this.filteredDbs);
    }
}
