import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidationErrors, AbstractControl } from '@angular/forms';

@Directive({
    selector: '[validFilename]',
    providers: [{ provide: NG_VALIDATORS, useExisting: FilenameValidator, multi: true }],
})
export class FilenameValidator implements Validator {
    constructor() {}

    validate(control: AbstractControl): ValidationErrors | null {
        return validationTest(control.value);
    }
}

export function validFilename(control: AbstractControl): { [key: string]: any } | null {
    return validationTest(control.value);
}

export function validationTest(val): ValidationErrors | null {
    const filenameRegex = /^[^\t\r\n\\\/<>:"|?*]*[^\t\r\n\\\/<>:"|?*%.\s]$/i;
    return filenameRegex.test(val) ? null : { invalidFilename: true };
}
