import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    SecurityContext,
    inject,
    HostListener,
    ViewEncapsulation,
    ChangeDetectorRef,
} from '@angular/core';
import { ReactiveFormsModule, FormsModule, FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { SemanticModalComponent } from 'app/semantic-legacy/components/modal/modal';
import { SharedModule } from 'app/shared/shared.module';
import { CommonModule } from '@angular/common';
import { Subject } from 'rxjs';
import { Renderer, marked } from 'marked';
import { UtilityService } from 'app/shared/utility.service';

@Component({
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, FormsModule, SharedModule],
    selector: 'simple-markdown-modal',
    templateUrl: './simple-markdown-modal.component.html',
    styleUrls: ['./simple-markdown-modal.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None, // to allow the modal to be styled from the parent component
})

export class SimpleMarkdownModalComponent implements OnInit, OnDestroy {
    private fb = inject(FormBuilder);
    private utilityService = inject(UtilityService);
    private sanitizer = inject(DomSanitizer);
    private cdRef: ChangeDetectorRef = inject(ChangeDetectorRef);
    @Input() set title(t: string) {
        this.modalTitle = t;
    }

    @Input() ExposingMarkdown: string;
    @Input() RemoveMarkDownOnCopy: boolean = false;

    thePrettyPrint: string = "";

    @ViewChild('showMarkdownModal') showMarkdownModal: SemanticModalComponent;
    @ViewChild('scrollTable') scrollTable: ElementRef;

    modalForm = this.fb.group({
        searchKey: [''],
        controlsOptionSelected: ['showAll'],
        showSelected: [false],
    });

    modalTitle: string = "";
    url: string = '';

    private renderer: any = new Renderer();

    ngUnsubscribe$ = new Subject<void>();

    constructor(){
        this.addCodeHilight();
        this.setMarkedOptions({});
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    setMarkedOptions(options: any): void {
        options = Object.assign({
                gfm: true,
                tables: true,
                breaks: false,
                pedantic: false,
                sanitize: false,
                smartLists: true,
                smartypants: false,
            },
            options
        );
        options.renderer = this.renderer;

        marked.setOptions(options);
    }

    addCodeHilight(): void {
        this.renderer.codespan = function (code: any) {
            return '<code class="mdCodeSpan">' + code.text + '</code>';
        };
        this.renderer.code = function (code: any) {
            return '<code class="mdCode">' + code.text.replace(/\"/g, '') + '</code>';
        }
    }

    /** Show the modal with some default modal options */
    showModal(): void {
        this.thePrettyPrint = this.prettyPrint()?.toString() ?? '';
        this.cdRef.detectChanges();
        this.showMarkdownModal.show({
            onHide: () => {
                setTimeout(() => {
                    this.thePrettyPrint = '';
                }, 200);
            },
            autofocus: false,
            closable: true,
        });
    }

    async copyToClipboard(): Promise<void> {
        let toPrint = this.RemoveMarkDownOnCopy ? this.removeMarkdown(this.ExposingMarkdown): this.ExposingMarkdown;
        await this.copy(toPrint);
    }

    async copy(val: string) {
        await this.utilityService.copyToClipboard(val, { showNotification: true });
    }

    removeMarkdown(val: string): string {
        let reheaders = /#+ /g;
        let result = val.replace(reheaders, '\r\n');
        let re = /\*{2}|`{3}|(==).*|(--).*|_{2}|\*{1}(?=.+?)|(?<=.+?)\*{1}/g;
        return result.replace(re, '').trim();
    }

    prettyPrint(): string | null {
        if(this.ExposingMarkdown) {
            return this.sanitizer.sanitize(SecurityContext.HTML, marked.parse(this.ExposingMarkdown));
        }

        return '';
    }
}
