<div class="ui card">
    <div class="content">
        <div class="header">
            SMART TAGS
            <a
                (click)="toggleEditMode()"
                *ngIf="!editMode && (availableTags.length || _tags?.length)"
                class="right floated"
                style="margin-right: -7px"
                title="Toggle Edit Mode">
                <i class="pencil icon" [class.grey]="!editMode"></i>
            </a>
        </div>
    </div>
    <div class="content">
        <div *ngIf="availableTags.length || _tags?.length">
            <sm-select
                placeholder="Add smart tags"
                class="fluid search multiple"
                [class.ro]="!editMode"
                [disabled]="!editMode || updatingTags"
                (onChange)="selectChange($event)"
                #smartTagSelect>
                <option
                    *ngFor="let selectedTag of _tags"
                    selected="selected"
                    value="{{ selectedTag.TagType }}">
                    {{ selectedTag.Name }}
                </option>
                <option *ngFor="let tag of availableTags" value="{{ tag.TagType }}">
                    {{ tag.Name }}
                </option>
            </sm-select>
            <itc-button
                type="primary"
                style="margin-top: 16px; float: right"
                *ngIf="editMode"
                (onclick)="saveTags()"
                [loading]="updatingTags"
                #saveButton>
                Save
            </itc-button>
        </div>
        <div *ngIf="loadingComplete && !(availableTags.length || _tags?.length)">
            <itc-banner type="info">
                There are not currently any tags that can be applied to this item type.
            </itc-banner>
        </div>
    </div>
</div>
