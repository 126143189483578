<!--Start: Nav  -->
<div class="ui fixed borderless huge menu">
    <div class="ui container grid">
        <!--Start: Desktop Nav-->
        <div class="row">
            <div *ngIf="siteParams && siteParams.BannerUrl" class="item banner">
                <img [src]="siteParams.BannerUrl" />
            </div>
            <a [routerLink]="'/vendor/home'" class="header item">Vendor Portal</a>
            <div class="item">
                Welcome &nbsp;
                <span *ngIf="userData">{{ userData.Name }}</span>
            </div>

            <div class="right menu">
                <a class="item" (click)="logout()" translate>Logout</a>
            </div>
        </div>
        <!--End: Desktop Nav-->
    </div>
</div>
<!--End: Nav  -->
<div class="ui container bodycontainer">
    <router-outlet></router-outlet>
    <p class="version">v.{{ version }}</p>
</div>
