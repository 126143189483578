import { Component, ViewChild } from '@angular/core';
import { Evidence } from '../../data-collection/evidence-compliance/evidence-compliance.model';
import { SemanticModalComponent } from '../../../../../semantic-legacy/components/modal/modal';

@Component({
    selector: 'cm-eoc-report-modal',
    templateUrl: './cm-eoc-report-modal.component.html',
    styleUrls: ['./cm-eoc-report-modal.component.scss'],
})
export class CmEocReportModalComponent {
    @ViewChild('evidenceReportModal', { static: true }) evidenceReportModal: SemanticModalComponent;

    currentCoverage: any[];
    coveragePercentText: string;
    evidence: Evidence;

    showModal(evidence: any) {
        this.evidence = new Evidence(evidence);

        if (this.evidence?.TotalCoverage == 0) {
            this.evidence.TotalCoverage = 1;
        }

        this.currentCoverage = [{
            bindingName: 'Covered',
            count: this.evidence?.CurrentCoverage,
            color: '#14C266',
        }, {
            bindingName: 'Not Covered',
            count: this.evidence?.TotalCoverage - this.evidence?.CurrentCoverage,
            color: '#B3C4CB',
        }];

        this.coveragePercentText =
            (
                (this.evidence?.CurrentCoverage / this.evidence?.TotalCoverage) *
                100
            ).toFixed(0) + '% Coverage';

        this.evidenceReportModal.show({ closable: false });
    }
}
