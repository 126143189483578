import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Control } from '../../../../shared/controls/control.model';

import { Requirement } from '../../../../shared/standards/requirement.model';
import { CM_COLORS } from '../../shared/cm-summary.constants';

@Component({
    selector: 'cm-standard-requirement-display',
    templateUrl: './cm-standard-requirement-display.component.html',
    styleUrls: ['./cm-standard-requirement-display.component.scss'],
})
export class CmStandardRequirementDisplayComponent implements OnInit {
    // @Input() requirementList: any;
    @Input()
    get requirementList() {
        return this._requirementList;
    }
    set requirementList(value) {
        if (value) {
            console.log('display input updated');
            // set timeout to make sure we get the assessmentType
            setTimeout(() => {
                value.forEach((r) => {
                    // this is ugly, but wanted to make it work for standards and controls too.
                    if (this.assessmentType == 'standardsControls') {
                        r.Response = r.Selected ? 'Selected' : 'Not Selected';
                    }
                });
                this._requirementList = value;
            }, 100);
        }
    }
    @Input() assessmentType: string;
    @Output() cellClicked = new EventEmitter();
    @Input() controls: Control[];

    _requirementList: Requirement[];
    selectedRequirement: Requirement;

    CM_COLORS = CM_COLORS; // need this to be able to pass into template

    responseColors = {
        'Yes, Fully': CM_COLORS.COVERED,
        'Yes, Partially': CM_COLORS.PARTIAL,
        'Unsure': CM_COLORS.UNSURE,
        'No': CM_COLORS.NOTCOVERED,
        'Addressed by Controls': CM_COLORS.COVERED,
        'Addressed': CM_COLORS.COVERED,
        'Not Addressed': CM_COLORS.NOTCOVERED,
        '': CM_COLORS.NOTASSESSED,
        'Yes, With Issues': CM_COLORS.PARTIAL,
        'Yes, With Technical Issues': CM_COLORS.PARTIAL,
        'Yes, with Technical Issues': CM_COLORS.PARTIAL,
        'Selected': CM_COLORS.COVERED,
        'Not Selected': CM_COLORS.NOTCOVERED,
        'Not Assessed': CM_COLORS.NOTASSESSED,
    };

    constructor() {}

    ngOnInit() {}

    requirementCellClicked($event) {
        this.selectedRequirement = $event;
        this.cellClicked.emit($event);
    }

    requirementHasModifiedControls(requirement: Requirement) {
        return this.controls?.some((con) => {
            return (
                con.Requirements.some((controlReq) => controlReq.Id == requirement.Id) &&
                con.HasOverrides
            );
        });
    }
}
