<sds-loader [complete]="loadingComplete">
    <sds-breadcrumb-trail [crumbs]="breadcrumbs"></sds-breadcrumb-trail>

    <header class="pageHeader">
        <h1>Manage Invites</h1>
        <help-button
            title="Manage Invites"
            text="Manage which Subject Matter Experts you have invited to complete specific worksheets and surveys."
            url="/cm-grc/help/cm-grc-docs/cm-grc-manage-invites.htm"></help-button>
    </header>

    <form class="ui form settingsPage smallerContainer">
        <div *ngIf="invites">
            <div *ngIf="!invites.size">
                <itc-banner type="info">
                    There are currently no invites to edit forms on this site.
                </itc-banner>
            </div>

            <div *ngIf="invites.size">
                <table
                    class="itc-table"
                    sortable-table
                    sortColumn="form"
                    sortDirection="asc"
                    (sorted)="sortTable($event)">
                    <thead>
                        <tr>
                            <th sortable-column="form">Form</th>
                            <th sortable-column="smes">Subject Matter Experts</th>
                            <th class="collapsing"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let formName of forms">
                            <td>{{ formName }}</td>
                            <td>
                                <p *ngFor="let user of invites.get(formName)">
                                    {{ user.FirstName }} {{ user.LastName }} ({{ user.Username }})
                                    &nbsp;&nbsp;
                                </p>
                            </td>
                            <td class="collapsing">
                                <itc-button
                                    type="icon"
                                    icon="fa-pencil"
                                    (onclick)="
                                        inviteModalLoading(true);
                                        inviteSME.inviteOthers(
                                            site.Id,
                                            formName,
                                            allSMEs,
                                            invites.get(formName),
                                            formFileNames.get(formName),
                                            site.Name,
                                            this.siteType
                                        )
                                    "
                                    tooltip="Edit"></itc-button>
                            </td>

                            <!--
                            <td class="collapsing">
                            <sds-button class="basic" color="darker" icon="edit" [loading]="inviteButtonLoading" (onclick)="inviteModalLoading(true); inviteSME.inviteOthers(site.Id, formName, allSMEs, invites.get(formName));" (loadedCallback)="inviteModalLoading(false)">
                            </sds-button>
                        </td>
-->
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </form>
</sds-loader>

<sds-invite-sme
    (loadedCallback)="inviteModalLoading(false)"
    (invitesUpdated)="invitesUpdated($event)"
    #inviteSME></sds-invite-sme>
