import { Notes } from '../../sites/shared/notes/notes.model';

export class ScanDataNonADDevice {
    public Id: number;
    public IpAddress: string;
    public ComputerName: string;
    public Port: any;
    public Type: any;

    public Notes: Notes;
    public Procedures: Notes[];
    public RelatedItems: string[];
    public SmartTags: any[];
}
