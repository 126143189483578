<sds-loader [complete]="loadingComplete">
    <sds-breadcrumb-trail [crumbs]="breadcrumbs"></sds-breadcrumb-trail>

    <div class="ui grid dashboard" *ngIf="kvsNotProvisioned">
        <div class="nine wide column">
            <div class="ui grid">
                <div class="twelve wide column">
                    <itc-banner type="info" title="KVS Not Provisioned">
                        <ng-container *ngIf="chNotProvisioned">
                            This functionality is only associated with the KVS product.
                            <br />
                            Contact your Account Representative to learn more about this product.
                        </ng-container>
                        <ng-container *ngIf="chNoSites">
                            There are no KVS sites available to associate with this functionality.
                            <br />
                            Create a new KVS site to access this functionality.
                        </ng-container>
                    </itc-banner>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!kvsNotProvisioned">
        <header class="pageHeader">
            <h1>Exclusion Rules</h1>
        </header>

        <kvs-false-positives [siteId]="0" pageType="global"></kvs-false-positives>
    </div>
</sds-loader>
