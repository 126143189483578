import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { ClientViewService } from 'app/sites/shared/client/client-view.service';
import { MessageService } from 'app/core/message.service';

import { Site } from 'app/sites/shared/site.model';
import { PING_SITE, SITE } from 'app/sites/shared/constants';

import * as saveAs from 'file-saver';
import { NotificationService } from '../../../../shared/itc/notification/notification.service';

@Component({
    selector: 'sds-client-reports',
    templateUrl: './client-reports.component.html',
    styleUrls: ['./client-reports.component.css'],
})
export class ClientReportsComponent implements OnInit {
    constructor(
        private clientViewService: ClientViewService,
        private messageService: MessageService,
        private notificationService: NotificationService
    ) {}

    site: Site;

    reportSets: any[];
    reportList: any[];

    selectedReportSet: string;
    reportSetCtrl: UntypedFormControl = new UntypedFormControl();

    loadingComplete: boolean;
    reportSetsLoading: boolean;
    listLoading: boolean;

    loadingZip: boolean;

    sub: any;

    ngOnInit() {
        this.sub = this.messageService.on(SITE).subscribe((site: Site) => {
            this.loadingComplete = false;

            if (site) {
                this.site = site;

                this.clientViewService.getClientReportSets(this.site.Id).then((reportSets) => {
                    this.reportSets = reportSets;
                    this.loadingComplete = true;

                    this.selectedReportSet = this.reportSets[0];
                    this.getReportList();
                });
            }
        });

        this.reportSetCtrl.valueChanges.subscribe((value) => {
            this.selectedReportSet = value;
            this.getReportList();
        });

        this.messageService.broadcast(PING_SITE);
    }

    getReportList() {
        if (this.selectedReportSet) {
            this.listLoading = true;
            this.clientViewService
                .getClientReportList(
                    this.site.Id,
                    this.replaceSpecialCharacters(this.selectedReportSet)
                )
                .then((reports) => {
                    this.reportList = reports;
                    this.listLoading = false;
                });
        }
    }

    downloadReport(report: any) {
        report.loading = true;
        this.clientViewService
            .downloadClientReport(
                this.site.Id,
                this.replaceSpecialCharacters(this.selectedReportSet),
                report.filename
            )
            .then((b64) => {
                report.loading = false;

                let raw = window.atob(b64);
                let bytes = new Uint8Array(raw.length);
                for (let i = 0; i < raw.length; i++) {
                    bytes[i] = raw.charCodeAt(i);
                }
                let blob = new Blob([bytes]);

                saveAs(blob, report.filename);
            })
            .catch((err) => {
                report.loading = false;
                console.error(err);
            });
    }

    downloadReportZip() {
        this.loadingZip = true;
        this.clientViewService
            .downloadClientReportSetZip(
                this.site.Id,
                this.replaceSpecialCharacters(this.selectedReportSet)
            )
            .then((b64) => {
                this.loadingZip = false;

                let raw = window.atob(b64);
                let bytes = new Uint8Array(raw.length);
                for (let i = 0; i < raw.length; i++) {
                    bytes[i] = raw.charCodeAt(i);
                }
                let blob = new Blob([bytes]);

                saveAs(blob, this.site.Name + ' - Client Reports.zip');
            })
            .catch((err) => {
                this.loadingZip = false;

                console.error(err);
            });
    }

    replaceSpecialCharacters(value: any) {
        let rValue = value
            .replace('&', '-replaceAmpersand-')
            .replace('+', '-replacePlusSign-')
            .replace('%', '-replacePercentSign-')
            .replace('<', '-replaceLeftAngleBracket-')
            .replace('>', '-replaceRightAngleBracket-')
            .replace('[', '-replaceLeftSquareBracket-')
            .replace(']', '-replaceRightSquareBracket-')
            .replace('{', '-replaceLeftCurlyBracket-')
            .replace('}', '-replaceRightCurlyBracket-')
            .replace('(', '-replaceLeftParenthesis-')
            .replace(')', '-replaceRightParenthesis-');
        return rValue;
    }
}
