<sds-breadcrumb-trail [crumbs]="breadcrumbs"></sds-breadcrumb-trail>

<h2>Overview</h2>

<table class="ui very basic collapsing table">
    <tbody>
        <tr>
            <td style="width: 350px">General</td>
            <td>
                <a class="ui primary button" routerLink="../general">Visit</a>
            </td>
        </tr>
        <tr>
            <td style="width: 350px">Scan and Notifications Task</td>
            <td>
                <a class="ui primary button" routerLink="../scan-notification-task">Visit</a>
            </td>
        </tr>
        <tr>
            <td style="width: 350px">Exclusion Rules</td>
            <td>
                <a class="ui primary button" routerLink="../exclusion-rules">Visit</a>
            </td>
        </tr>
    </tbody>
</table>
