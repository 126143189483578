export class Toast {
    type: ToastType;
    title: string;
    message: string;
}

export enum ToastType {
    Error,
    Info,
    Success,
    Warning,
    Download,
}
