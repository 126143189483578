import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    inject,
} from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ChartData } from 'chart.js';
import { ChartModule } from 'primeng/chart';
import { GrcService } from 'app/sites/shared/grc.service';

@Component({
    selector: 'cmo-status-card',
    standalone: true,
    imports: [CommonModule, ChartModule, FontAwesomeModule],
    template: `
        <div class="itcCard" (click)="onclick.emit()">
            <div class="standard">
                <img class="standard-icon" [src]="standardIcon" />
                <div>
                    <p class="standard-text">{{ standard }}</p>
                    <p class="standard-variant">{{ variant }}</p>
                </div>
            </div>
            <div class="chart">
                <p-chart
                    width="192px"
                    height="96px"
                    [data]="chartData"
                    [options]="chartOptions"
                    type="doughnut"></p-chart>
                <p class="chart-status">{{ status }}%</p>
                <p class="chart-readiness">Compliance Readiness</p>
            </div>
            <div class="device-count">
                <fa-stack>
                    <fa-icon
                        [icon]="['fas', 'circle']"
                        style="color: var(--background-tertiary)"
                        stackItemSize="2x"
                        [fixedWidth]="true"></fa-icon>
                    <fa-icon icon="laptop-mobile" stackItemSize="1x" [fixedWidth]="true"></fa-icon>
                </fa-stack>
                <strong>{{ devices }}/{{ devicesTotal }}</strong>
                Devices
            </div>
        </div>
    `,
    styles: [
        `
            :host {
                display: block;
            }
            .itcCard {
                width: 276px;
                padding: 16px;
                border: 1px solid var(--border-primary);
                border-radius: 4px;
                box-shadow: var(--elevation-xs);

                &:hover {
                    cursor: pointer;
                    box-shadow: var(--elevation-sm);
                }
                .standard {
                    display: grid;
                    grid-template-columns: 40px 1fr;
                    gap: 8px;
                    p {
                        margin: 0;
                        height: 24px;
                        font-size: 16px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: 179px;
                    }
                    .standard-text {
                        font-weight: 500;
                    }
                    .standard-variant {
                        color: var(--text-secondary);
                    }
                }
                .chart {
                    position: relative;
                    text-align: center;
                    padding-top: 12px;

                    p-chart::ng-deep {
                        div {
                            margin: 0 auto;
                        }
                    }
                    .chart-status {
                        font-weight: 500;
                        font-size: 24px;
                        line-height: 28px;
                        margin: -35px 0 20px 0;
                    }
                    .chart-readiness {
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 20px;
                        color: var(----text-secondary);
                        text-align: center;
                        margin-bottom: 12px;
                    }
                }
                .device-count {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    fa-stack {
                        width: 32px;
                        height: 32px;
                        fa-icon {
                            display: inline-block;
                            &::ng-deep .svg-inline--fa.fa-stack-2x {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmoStatusCardComponent {
    grcService = inject(GrcService);

    @Input() standard: string;
    @Input() variant: string;
    @Input() status: number;
    @Input() devices: number;
    @Input() devicesTotal: number;
    @Output() onclick = new EventEmitter<void>();

    chartData: ChartData;
    chartOptions: any = {
        responsive: true,
        circumference: 180,
        cutout: '78%',
        rotation: -90,
        animation: false,
        interaction: {
            mode: null,
        },
        elements: {
            arc: {
                borderWidth: 0,
            },
        },
        layout: {
            padding: -30,
        },
    };
    standardIcon: string;

    ngOnInit() {
        let chartColor;
        if (this.status <= 20) {
            chartColor = '#F3523E';
        } else if (this.status <= 60) {
            chartColor = '#F18D13';
        } else if (this.status <= 80) {
            chartColor = '#FFCF33';
        } else if (this.status > 81) {
            chartColor = '#33CC39';
        }
        this.chartData = {
            datasets: [
                {
                    data: [this.status, 100 - this.status],
                    backgroundColor: [chartColor, '#D9D9D8'],
                },
            ],
        };
        this.standardIcon = this.grcService.getStandardIcon(this.standard);
    }
}
