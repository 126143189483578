import { CommonModule } from '@angular/common';
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { ChartConfiguration, ChartData, ChartOptions, DoughnutController, Plugin } from 'chart.js';
import { ChartModule } from 'primeng/chart';

@Component({
    selector: 'itc-donut-chart',
    standalone: true,
    imports: [CommonModule, ChartModule],
    templateUrl: './donut-chart.component.html',
    styleUrls: ['./donut-chart.component.scss'],
})
export class DonutChartComponent implements OnInit {
    @Input({ required: true }) data: ChartData<'doughnut'>;
    @Input({ required: true }) height: string;
    @Input({ required: true }) width: string;
    @Input() emptyChartColor = NEUTRAL_COLOR;
    @Input() showLegend = true;
    @Input() plugins: Plugin[] = [];
    @Input() option: ChartOptions<'doughnut'> = {};
    @Input() centerTexts: DonutChartCenterText[];
    @Input() showAnimation: true; // whether to show animation on charts
    @Input() arcBorders: true; // whether to show the border between donut segments
    @Output() totalVisibleNumber = new EventEmitter<number>();
    @ViewChild('legend_container') legendContainerRef: ElementRef;

    ngOnInit(): void {
        if (this.showLegend) {
            this.plugins.push(this.legendPlugin());
        }
        if (this.centerTexts?.length) {
            this.plugins.push(this.centerTextPlugin());
        }
        this.plugins.push(this.calculateTotalNumberPlugin());
        this.plugins.push(this.backgroundPlugin());
        this.option = {
            cutout: '70%',
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    enabled: false,
                },
            },
            animation: this.showAnimation ? false : true,
            hover: { mode: this.showAnimation ? 'nearest' : null },
            elements: { arc: { borderWidth: this.arcBorders ? 2 : 0 } },
            ...this.option,
        } as { [key: string]: any };
    }

    private legendPlugin(): Plugin {
        return {
            id: 'htmlLegend',
            afterUpdate: (chart, _args, options) => {
                const ul = this.getOrCreateLegendList(chart);

                // Remove old legend items
                while (ul.firstChild) {
                    ul.firstChild.remove();
                }

                // Reuse the built-in legendItems generator
                const items = chart.options.plugins.legend.labels.generateLabels(chart);
                const totalVisible = chart.data.datasets[0].data.reduce(
                    (total: number, value: number, index) => {
                        return chart.getDataVisibility(index) ? total + value : total;
                    },
                    0
                ) as number;

                items.forEach((item) => {
                    const li = document.createElement('li');
                    li.classList.add('legend-item');
                    if (item.hidden) {
                        li.classList.add('hidden');
                    } else {
                        li.classList.remove('hidden');
                    }
                    li.onclick = () => {
                        const type = (chart.config as ChartConfiguration).type;
                        if (type === 'pie' || type === 'doughnut') {
                            // Pie and doughnut charts only have a single dataset and visibility is per item
                            chart.toggleDataVisibility(item.index);
                        } else {
                            chart.setDatasetVisibility(
                                item.datasetIndex,
                                !chart.isDatasetVisible(item.datasetIndex)
                            );
                        }
                        chart.update();
                    };

                    const lt = document.createElement('div');
                    lt.classList.add('legend-title');

                    // Color box
                    const boxSpan = document.createElement('span');
                    boxSpan.classList.add('legend-box');
                    boxSpan.style.background = item.fillStyle.toString();
                    boxSpan.style.borderColor = item.strokeStyle.toString();
                    boxSpan.style.borderWidth = item.lineWidth + 'px';

                    // Text
                    const textContainer = document.createElement('p');
                    textContainer.classList.add('legend-text');

                    const text = document.createTextNode(item.text);
                    textContainer.appendChild(text);

                    // Value
                    const valueContainer = document.createElement('p');
                    valueContainer.classList.add('legend-value');
                    const value = chart.data.datasets[0].data[item.index] as number;
                    const percentage = item.hidden ? 0 : ((value / totalVisible) * 100).toFixed(1);
                    valueContainer.textContent = `${value} (${percentage}%)`;

                    lt.appendChild(boxSpan);
                    lt.appendChild(textContainer);
                    li.appendChild(lt);
                    li.appendChild(valueContainer);
                    ul.appendChild(li);
                });
            },
        };
    }

    private getOrCreateLegendList(_chart: unknown) {
        const legendContainer = this.legendContainerRef.nativeElement as HTMLElement;
        let listContainer = legendContainer.querySelector('ul');

        if (!listContainer) {
            listContainer = document.createElement('ul');
            listContainer.classList.add('legend-list');

            legendContainer.appendChild(listContainer);
        }

        return listContainer;
    }

    private calculateTotalNumberPlugin(): Plugin {
        return {
            id: 'totalNumber',
            afterDatasetUpdate: (chart) => {
                if ((chart.config as ChartConfiguration).type === 'doughnut') {
                    const totalVisible = chart.data.datasets[0].data.reduce(
                        (acc: number, curr: number, index) => {
                            return chart.getDataVisibility(index) ? acc + curr : acc;
                        },
                        0
                    ) as number;
                    this.totalVisibleNumber.emit(totalVisible);
                }
            },
        };
    }

    private centerTextPlugin(): Plugin {
        return {
            id: 'totalNumber',
            afterDraw: (chart) => {
                if ((chart.config as ChartConfiguration).type === 'doughnut') {
                    const { ctx, width, height } = chart;

                    ctx.save();
                    for (const centerText of this.centerTexts) {
                        ctx.font = centerText.font;
                        ctx.fillStyle = centerText.fillStyle?.length
                            ? centerText.fillStyle
                            : '#001D30';
                        ctx.textBaseline = 'middle';
                        const textWidth = ctx.measureText(centerText.value).width;
                        const textX = Math.round(
                            (width - textWidth) / 2 + (centerText.offsetX ?? 0)
                        );
                        const textY = height / 2 + (centerText.offsetY ?? 0);
                        ctx.fillText(centerText.value, textX, textY);
                    }
                    ctx.restore();
                }
            },
        };
    }

    private backgroundPlugin(): Plugin {
        return {
            id: 'background',
            beforeDraw: (chart) => {
                const { ctx, width, height } = chart;
                const { innerRadius } = chart.getDatasetMeta(chart.data.datasets.length - 1)
                    .controller as DoughnutController;
                const { outerRadius } = chart.getDatasetMeta(0).controller as DoughnutController;
                const radiusLength = outerRadius - innerRadius;

                const x = width / 2;
                const y = height / 2;

                ctx.beginPath();
                ctx.arc(x, y, outerRadius - radiusLength / 2, 0, 2 * Math.PI);
                ctx.lineWidth = radiusLength;
                ctx.strokeStyle = this.emptyChartColor;
                ctx.stroke();
            },
        };
    }
}

export interface DonutChartCenterText {
    offsetX?: number;
    offsetY?: number;
    font: string;
    fillStyle?: string;
    value: string;
}

export const SUCCESS_COLOR = '#33CC39';
export const FAILED_COLOR = '#F3523E';
export const NEUTRAL_COLOR = '#BFC5CB';
