import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'mapToIterable',
})
export class MapToIterablePipe implements PipeTransform {
    transform(value: any, wrapKey?: string, wrapValue?: string, sort?: string): any {
        if (!value) return [];

        let ret = Object.keys(value).map((key) => {
            if (wrapKey) {
                let ret: any = {};
                ret[wrapKey] = key;

                if (wrapValue) ret[wrapValue] = value[key];
                else ret['value'] = value[key];

                return ret;
            } else return value[key];
        });

        if (sort && wrapKey) {
            let val = wrapValue ? wrapValue : 'value';

            let dir = sort == 'asc' ? 1 : -1;

            ret = ret.sort((a, b) => ('' + a[val]).toLowerCase() < ('' + b[val]).toLowerCase() ? -dir : dir);
        } else if (sort) {
            let dir = sort == 'asc' ? 1 : -1;
            ret = ret.sort((a, b) => ('' + a).toLowerCase() < ('' + b).toLowerCase() ?  -dir : dir);
        }

        return ret;
    }
}
