import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
    selector: '[orgNameValidator]',
    providers: [{ provide: NG_VALIDATORS, useExisting: OrgNameValidatorDirective, multi: true }],
})
export class OrgNameValidatorDirective implements Validator {
    @Input() orgNameCurrent: string;
    @Input() existingNames: string[] = [];
    regex: RegExp = /^[a-zA-Z0-9]{1}[a-zA-Z0-9\-\s]*$/;

    validate(control: AbstractControl): ValidationErrors | null {
        let orgErrors = {};
        let invalid = false;

        if (this.orgNameCurrent && control.value == this.orgNameCurrent) {
            orgErrors['sameoldorg'] = true;
            invalid = true;
        }

        if (control.value?.charAt(0) === ' ') {
            orgErrors['cannotStartWithSpace'] = true;
            invalid = true;
        }

        if (!this.regex.test(control.value)) {
            orgErrors['regex'] = true;
            invalid = true;
        }

        if (control.value?.length > 50) {
            orgErrors['length'] = true;
            invalid = true;
        }

        if (this.existingNames.includes(control.value?.toLowerCase().trim())) {
            orgErrors['duplicate'] = true;
            invalid = true;
        }

        return invalid ? orgErrors : null;
    }
}
