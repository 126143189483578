<sds-loader [complete]="loadComplete"></sds-loader>
<div
    class="policyDonutContainer"
    [ngClass]="{
        success: status == 1,
        warn: status == 2,
        error: status == 3,
        unsure: status === 4,
        notassessed: status === 5
    }"
    [style.backgroundColor]="
        status == 1
            ? CM_COLORS.COVERED
            : status == 2
            ? CM_COLORS.PARTIAL
            : status == 3
            ? CM_COLORS.NOTCOVERED
            : status == 4
            ? CM_COLORS.NOTASSESSED
            : CM_COLORS.NOTASSESSED
    ">
    <div class="percentContainer">
        <h2>{{ secondaryNumComplete ? secondaryNumComplete + numComplete : numComplete }}</h2>
        <h5>
            {{ label }}
            <span *ngIf="secondaryLabel">({{ numComplete }})</span>
        </h5>
        <h5 *ngIf="secondaryLabel">{{ secondaryLabel }} ( {{ secondaryNumComplete }} )</h5>
    </div>
    <div class="donutContainer">
        <!-- <itc-legacy-donut-chart *ngIf="_chartData" [data]="_chartData"  diameter="50px"
      [showLegend]="false" legendType="ovals"></itc-legacy-donut-chart> -->

        <h2>{{ flooredPercentComplete }}</h2>
    </div>
</div>
