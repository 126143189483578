<p-menu #dropdownMenu [model]="menuOptions" [popup]="true" appendTo="body" styleClass="bulk-items-dropdown"></p-menu>
<div
    class="itc dropdown button {{ classList }}"
    [class.bulkChangeCheckbox]="true"
    [class.loading]="loading"
    (click)="toggleDropdown($event)">
    <itc-checkbox
        (onChanged)="checkboxClicked.emit($event)"
        [checked]="
            selectedCount === totalCount && totalCount > 0 ? true : selectedCount ? 'mixed' : false
        "
        [disabled]="disabled"
        [stopBubbling]="true"></itc-checkbox>
    <fa-icon [icon]="['fas', 'caret-down']"></fa-icon>
</div>
