import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorService } from 'app/core/error/error.service';
import { MessageService } from 'app/core/message.service';
import { UiService } from 'app/core/ui.service';
import { SiteService } from 'app/sites/shared/site.service';
import { Site } from 'app/sites/shared/site.model';
import { PING_SITE, SITE } from 'app/sites/shared/constants';
import { ResourcesService } from 'app/core/resources/resources.service';
import { AssessmentService } from 'app/sites/shared/assessment.service';
import { SiteSettingService } from 'app/sites/site/settings/site-settings.service';

import * as saveAs from 'file-saver';
import { TableSorting } from '../../../shared/sortable-table/sortable-table.component';
import { NotificationService } from '../../../shared/itc/notification/notification.service';

const CURRENT_ASSESSMENT: string = '__current';

@Component({
    templateUrl: './risk-snapshot.component.html',
    styleUrls: ['./risk-snapshot.component.css'],
})
export class RiskSnapshotComponent implements OnInit, OnDestroy {
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private errorService: ErrorService,
        private messageService: MessageService,
        private uiService: UiService,
        private siteService: SiteService,
        private notificationService: NotificationService,
        private resourceService: ResourcesService,
        private assessmentService: AssessmentService,
        private siteSettingService: SiteSettingService
    ) {}

    loadingComplete: boolean;
    site: Site;
    subs: any[] = [];
    reportSets: string[];
    reportList: any[];

    selectedReportSet: string;
    reportSetCtrl: UntypedFormControl = new UntypedFormControl();
    useCompensatingControls: boolean;
    useConnector: boolean;

    listLoading: boolean;
    downloadingReportSetZip: boolean;
    deletingReportSet: boolean;
    loadingZip: boolean;
    isAssessmentStarted: boolean;
    scansRunning: boolean;
    isVsaConnectorEnabled: boolean;

    breadcrumbs = [
        { path: '..', text: 'Compliance Manager' },
        { path: '.', text: 'Risk Updates' },
    ];

    @ViewChild('reportSetSelect', { static: true }) reportSetSelect: any;

    ngOnInit() {
        this.subs.push(
            this.messageService.on(SITE).subscribe((site: Site) => {
                this.onSite(site);
            })
        );

        this.reportSetCtrl.valueChanges.subscribe((value) => {
            if (!this.site || !value) return;

            this.selectedReportSet = value;

            this.listLoading = true;
            this.siteService
                .getRiskSnapshotReportList(this.site.Id, this.selectedReportSet)
                .then((reportList) => {
                    this.reportList = reportList;
                    this.listLoading = false;
                });
        });

        this.messageService.broadcast(PING_SITE);
    }

    onSite(site: Site) {
        if (!site) return;

        this.site = site;
        this.uiService.setTitle('Risk Update', site.Name);
        this.loadingComplete = false;
        this.siteService.getRiskSnapshotReportSets(site.Id).then((reportSets) => {
            this.reportSets = reportSets;

            if (reportSets.length) {
                this.reportSetCtrl.setValue(reportSets[0]);
            }

            this.loadingComplete = true;
        });

        this.siteService.getRiskSnapshotUseCompensatingControl(site.Id).then((res) => {
            this.useCompensatingControls = res;
        });

        this.getVsaConnectorEnabled(site.Id).then((res) => {
            if (this.isVsaConnectorEnabled) {
                this.siteService.getRiskSnapshotUseConnector(site.Id).then((res) => {
                    this.useConnector = res;
                });
            }
        });

        this.assessmentService
            .getAssessment(this.site.Id, CURRENT_ASSESSMENT)
            .then((assessment) => {
                this.isAssessmentStarted = assessment && assessment.State == 'Started';
            })
            .catch(() => {});

        this.checkScansRunning();
    }

    checkScansRunning() {
        this.scansRunning = false;
        this.siteService.hasRunningAuditGuruScan(this.site.Id).then((res) => {
            if (res) {
                this.scansRunning = res;
            }
        });
    }

    ngOnDestroy() {
        for (let sub of this.subs) {
            sub.unsubscribe();
        }
    }

    downloadReport(report: any) {
        report.loading = true;
        this.siteService
            .downloadRiskSnapshotReport(this.site.Id, this.selectedReportSet, report.filename)
            .then((b64) => {
                report.loading = false;

                let raw = window.atob(b64);
                let bytes = new Uint8Array(raw.length);
                for (let i = 0; i < raw.length; i++) {
                    bytes[i] = raw.charCodeAt(i);
                }
                let blob = new Blob([bytes]);

                saveAs(blob, report.filename);
            })
            .catch((err) => {
                report.loading = false;
                console.error(err);
            });
    }

    downloadReportSetZip() {
        this.downloadingReportSetZip = true;
        this.siteService
            .downloadRiskSnapshotReportSetZip(this.site.Id, this.selectedReportSet)
            .then((b64) => {
                this.downloadingReportSetZip = false;

                let raw = window.atob(b64);
                let bytes = new Uint8Array(raw.length);
                for (let i = 0; i < raw.length; i++) {
                    bytes[i] = raw.charCodeAt(i);
                }
                let blob = new Blob([bytes]);

                saveAs(blob, this.site.Name + ' - Risk Update Reports.zip');
            })
            .catch((err) => {
                this.downloadingReportSetZip = false;

                console.error(err);
            });
    }

    showDeleteReportConfirm(report: any) {
        if (confirm('Are you sure you want to delete this report?')) this.deleteReport(report);
    }

    showDeleteReportSetConfirm() {
        if (confirm('Are you sure you want to delete this report set?')) this.deleteReportSet();
    }

    deleteReport(report: any) {
        report.deleting = true;
        this.siteService
            .deleteRiskSnapshotPublishedReport(
                this.site.Id,
                this.selectedReportSet,
                report.filename
            )
            .then((res) => {
                let idx = this.reportList.findIndex((r) => r.filename == report.filename);
                this.reportList.splice(idx, 1);

                this.notificationService.toast.success(
                    'Success',
                    `Report '${report.filename}' deleted from report set.`
                );
            });
    }

    deleteReportSet() {
        this.deletingReportSet = true;
        this.siteService
            .deleteRiskSnapshotPublishedReportSet(this.site.Id, this.selectedReportSet)
            .then((res) => {
                this.deletingReportSet = false;
                let idx = this.reportSets.indexOf(this.selectedReportSet);
                this.reportSets.splice(idx, 1);
                if (this.reportSets.length) this.reportSetCtrl.setValue(this.reportSets[0]);
                else {
                    this.reportSetCtrl.setValue('');
                    this.reportSetSelect.reset();
                    this.selectedReportSet = '';
                    this.reportList = [];
                }

                this.notificationService.toast.success(
                    'Success',
                    `Report set '${this.selectedReportSet}' deleted.`
                );
            });
    }

    saveUseCompensatingControl() {
        this.siteService
            .setRiskSnapshotUseCompensatingControl(this.site.Id, this.useCompensatingControls)
            .then((res) => {
                this.siteService
                    .setRiskSnapshotUseConnector(this.site.Id, this.useConnector)
                    .then((res) => {
                        this.notificationService.toast.success(
                            'Success',
                            'Your preference was saved.'
                        );
                    });
            });
    }

    startRiskSnapshotScans() {
        this.loadingComplete = false;
        this.siteService
            .startRiskSnapshotScans(this.site.Id)
            .then((res) => {
                this.loadingComplete = true;
                this.notificationService.toast.success(
                    'Success',
                    'Risk Update Scans have been started.'
                );
                this.checkScansRunning();
            })
            .catch((err) => {
                this.loadingComplete = true;
                console.error(err);
            });
    }

    isTrueSet(v: any) {
        return v === 'true' || v === 'True';
    }

    getVsaConnectorEnabled(siteId: number): Promise<boolean> {
        this.isVsaConnectorEnabled = false;
        return new Promise<any>((resolve) => {
            this.siteSettingService.getVsaConnector(siteId).then((res) => {
                this.isVsaConnectorEnabled = this.isTrueSet(res.Enabled);

                resolve(this.isVsaConnectorEnabled);
            });
        });
    }

    onSort(sorting: TableSorting): void {
        this.reportList.sort((a, b) =>
            a[sorting.sortColumn].toLowerCase().localeCompare(b[sorting.sortColumn].toLowerCase())
        );

        if (sorting.sortDirection === 'desc') {
            this.reportList.reverse();
        }
    }
}
