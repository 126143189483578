import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SharedModule } from 'app/shared/shared.module';

import { TagsCardComponent } from './shared/tags-card/tags-card.component';

@NgModule({
    imports: [CommonModule, FormsModule, SharedModule],
    declarations: [TagsCardComponent],
    exports: [TagsCardComponent],
    providers: [],
})
export class TagsModule {}
