<sds-loader [complete]="loadingComplete">
    <sds-breadcrumb-trail [crumbs]="breadcrumbs"></sds-breadcrumb-trail>

    <header class="pageHeader">
        <h1>VulScan Proxy Settings</h1>
    </header>

    <itc-card class="integration">
        <card-title>
            Open VPN Configuration
            <help-button
                title="Open VPN Configuration"
                text="The Open VPN Configuration is used with the Remote Internal Vulnerability Scanner."
                url="/vs/help/remote-ivs/remote-ivs-setup.htm "></help-button>
        </card-title>

        <card-content>
            <div>
                <label class="subheading">Upload Open VPN Configuration File</label>
                <p>
                    Upload Open VPN Configuration File in the .ovpn format. This configuration file
                    contains the necessary settings and parameters required to establish a
                    connection to an Open VPN server.
                </p>
                <div>
                    <itc-file-uploader
                        type="button"
                        accept=".ovpn"
                        allowedFiletypes="OVPN"
                        returnType="Upload"
                        successToastText="File upload is complete."
                        [currentFiles]="currentFile"
                        [forceOnlyOne]="true"
                        (uploadFile)="upload($event)"
                        (uploadDone)="uploadDone($event)"
                        (removeFile)="confirmDeleteProxy()"
                        [disabled]="checkingConnection"
                        buttonType="secondary"
                        #fileUploaderComp></itc-file-uploader>
                </div>
            </div>

            <div style="margin-top: 20px">
                <label class="subheading">Check Connection</label>
                <p>
                    Verify the OpenVPN configuration file by checking the connection. Online Remote
                    Internal Vulnerability Scanner is required to establish the VPN tunnel.
                </p>
                <div>
                    <itc-button
                        type="secondary"
                        label="Check Connection"
                        [disabled]="!isConfigUploaded"
                        [loading]="checkingConnection"
                        (onclick)="checkConnection()"></itc-button>
                </div>
            </div>

            <div class="integrationActions">
                <itc-button
                    type="primary"
                    icon="fa-floppy-disk"
                    (onclick)="saveProxySettings()"
                    [disabled]="!isDirty">
                    Save
                </itc-button>
            </div>
        </card-content>
    </itc-card>

    <sm-modal title="Delete OpenVPN Configuration File" class="sm" #confirmDeleteProxySettingsModal>
        <modal-content>
            <div>
                Removing the configuration file
                <b>
                    will prevent all Remote Internal Vulnerability Scans (via VulScan Proxy) from
                    running.
                </b>
                Are you sure you want to delete {{ fileToDelete }}?
            </div>

            <div *ngIf="associatedScanTasks?.length > 0">
                <p style="margin-top: 16px">
                    This OpenVPN configuration file is currently being used in
                    <b>{{ associatedScanTasks?.length }}</b>
                    Scan Tasks:
                </p>
                <ul style="padding-left: 8px; margin-bottom: 0">
                    <li
                        style="margin-left: 10px; margin-top: 5px"
                        *ngFor="let scanTask of associatedScanTasks">
                        {{ scanTask.Label }}
                    </li>
                </ul>
            </div>
        </modal-content>
        <modal-actions>
            <itc-button
                label="Cancel"
                type="secondary"
                (onclick)="confirmDeleteProxySettingsModal.hide()"></itc-button>
            <itc-button label="Delete" type="danger" (onclick)="removeFile()"></itc-button>
        </modal-actions>
    </sm-modal>
</sds-loader>
