import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    SimpleChanges,
    forwardRef,
    inject,
} from '@angular/core';
import { PasswordStrengthMeterComponent } from './password-strength-meter/password-strength-meter.component';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    FormBuilder,
    AbstractControlOptions,
    ReactiveFormsModule,
    FormsModule,
} from '@angular/forms';
import { passwordValidator } from '../password.validator';
import { notBlank } from '../not-blank.validator';
import { MessageService } from 'app/core/message.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'password-form-fields',
    standalone: true,
    imports: [CommonModule, PasswordStrengthMeterComponent, ReactiveFormsModule, FormsModule],
    template: `
        <div [formGroup]="passwordForm">
            <div class="required field">
                <label>New Password</label>
                <input
                    type="password"
                    name="currentPass"
                    formControlName="newPass"
                    [attr.placeholder]="unchanged ? '(unchanged)' : null"
                    [style.margin-bottom]="unchanged ? '16px !important' : 0"
                    class="noMargin" />

                <div [hidden]="unchanged && !passwordForm.get('newPass').value">
                    <password-strength-meter
                        [password]="passwordForm.get('newPass').value"></password-strength-meter>
                    <div class="pw-requirements">
                        Password must:
                        <ul class="password-rules">
                            <li>Be 8 or more characters in length</li>
                            <li>
                                Include one of each of the following:
                                <ul>
                                    <li>An uppercase character</li>
                                    <li>A lowercase character</li>
                                    <li>A number</li>
                                    <li>A special character</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div class="required field">
                        <label>Confirm Password</label>
                        <input
                            type="password"
                            name="currentPass"
                            formControlName="confirmNewPass" />
                    </div>
                </div>
            </div>
        </div>
    `,
    styles: [
        `
            form[name='changePasswordForm'] h3 {
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
            }
            .pw-requirements {
                margin-bottom: 16px;
                font-size: 12px;
                line-height: 20px;
                color: var(--text-secondary);
                ul {
                    margin: 0;
                    padding-left: 16px;
                    & > li ul {
                        padding-left: 16px !important;
                        list-style-type: initial !important;
                    }
                }
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => PasswordFormComponent),
        },
    ],
})
export class PasswordFormComponent implements ControlValueAccessor {
    fb = inject(FormBuilder);
    cdr = inject(ChangeDetectorRef);
    messageService = inject(MessageService);

    @Input() unchanged: boolean;
    @Output() onError = new EventEmitter<string>();

    origUnchanged: boolean;
    passwordForm = this.fb.group(
        {
            newPass: ['', notBlank],
            confirmNewPass: '',
        },
        {
            validators: passwordValidator('newPass', 'confirmNewPass'),
        } as AbstractControlOptions
    );
    ngUnsubscribe$ = new Subject<void>();

    ngOnInit() {
        this.passwordForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
            // logic to show rules if password is unchanged to begin with and now has value
            if (this.passwordForm.get('newPass').value) {
                this.unchanged = false;
            } else {
                this.unchanged = this.origUnchanged;
                if (this.unchanged) {
                    this.passwordForm.get('newPass').markAsPristine();
                }
            }
            this.cdr.markForCheck();
            // write to value if it's valid or clear if not
            if (this.passwordForm.valid) {
                this.setInnerValue(this.passwordForm.get('newPass').value);
                this.onError.emit('');
            } else {
                this.setInnerValue('');
                if (this.unchanged) {
                    this.onError.emit('');
                } else {
                    this.onError.emit(this.passwordForm.getError('hasError'));
                }
            }
        });
        this.messageService
            .on('RESET_PASSWORD_FORM')
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((msg) => {
                this.passwordForm.reset();
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        this.origUnchanged = changes['unchanged'].currentValue;
    }

    set value(value: string) {
        if (value !== this.passwordForm.get('newPass').value) {
            this.passwordForm.get('newPass').setValue(value);
            this.onChange(value);
            this.onTouch();
        }
    }
    get value(): string {
        return this.passwordForm.get('newPass').value;
    }

    setInnerValue(value: string) {
        this.onChange(value);
        this.onTouch();
    }

    onChange = (value: any) => {};
    onTouch = () => {};
    onBlur() {
        this.onTouch();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    writeValue(value: any): void {
        if (value !== this.passwordForm.get('newPass').value) {
            this.passwordForm.get('newPass').setValue(value);
            this.cdr.markForCheck();
        }
    }
}
