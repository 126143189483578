<sds-loader [complete]="loadingComplete">
    <header class="pageHeader" style="margin-top: 8px">
        <h1>Reports</h1>
        <div class="flex-right">
            <div
                class="ui left labeled input reportSetDropdown"
                style="border-top-right-radius: 0; border-bottom-right-radius: 0">
                <div class="ui label darker" style="padding-top: 8px; padding-bottom: 8px">
                    Report Set
                </div>

                <sm-select
                    [control]="reportSetCtrl"
                    [disabled]="reportSetsLoading"
                    [loading]="reportSetsLoading"
                    [hidden]="loadingComplete && !reportSets?.length"
                    #reportSetSelect>
                    <option
                        *ngFor="let set of reportSets"
                        value="{{ set }}"
                        selected="{{ selectedReportSet == set ? 'selected' : '' }}">
                        {{ set }}
                    </option>
                </sm-select>
            </div>

            <itc-button
                label="Download .zip"
                type="primary"
                icon="fa-download"
                [loading]="loadingZip"
                (onclick)="downloadReportZip()"
                *ngIf="reportList?.length"></itc-button>
        </div>
    </header>

    <itc-banner type="info" *ngIf="loadingComplete && !reportSets?.length">
        No report sets are available to view at this time.
    </itc-banner>

    <itc-banner type="info" *ngIf="selectedReportSet && !listLoading && !reportList?.length">
        No reports were found in the selected report set.
    </itc-banner>

    <h5 class="ui top attached header" *ngIf="listLoading" style="background: #f9fafb">&nbsp;</h5>
    <div class="ui attached segment loading" *ngIf="listLoading" style="min-height: 250px"></div>

    <table class="itc-table" *ngIf="reportList?.length && !listLoading" style="margin-bottom: 50px">
        <thead>
            <tr>
                <th></th>
                <th>Filename</th>
                <th>Last Modified</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let report of reportList">
                <td class="collapsing">
                    <itc-button
                        type="primary icon"
                        icon="fa-download"
                        [class.loading]="report.loading"
                        (onclick)="downloadReport(report)"
                        tooltip="Download Report"></itc-button>
                </td>
                <td>{{ report.filename }}</td>
                <td>{{ report.lastmodified | date : 'medium' }}</td>
            </tr>
        </tbody>
    </table>
</sds-loader>
