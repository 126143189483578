<div class="login_page itc-kds">
    <a class="kaseya_logo ir" href="https://www.kaseya.com/">Kaseya</a>
    <div class="login_outer_container">
        <div class="login_container">
            <div class="logoContainer">
                <h1 class="title-logo">Vendor Portal</h1>
            </div>
            <itc-notification-banner></itc-notification-banner>
            <form (ngSubmit)="submit()">
                <label for="inviteid">Invite ID:</label>
                <div class="" [ngClass]="{ error: inviteidM.invalid && !inviteidM.pristine }">
                    <input
                        type="text"
                        name="inviteid"
                        id="inviteid"
                        [(ngModel)]="inviteid"
                        required
                        #inviteidM="ngModel"
                        tabindex="1" />
                </div>

                <button class="ui fluid button primary large" type="submit">Login</button>
            </form>
        </div>
    </div>
    <p class="login_copyright">
        <a href="https://support.rapidfiretools.com/hc/en-us" target="_blank">Help &amp; Support</a>
        | &copy; Kaseya
    </p>

    <div class="login_footer_copy" style="position: fixed; right: 15px; bottom: 0">
        <p class="footer_version" style="margin-left: 20px">v.{{ version }}</p>
    </div>
</div>
