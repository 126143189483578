import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
    forwardRef,
} from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    ReactiveFormsModule,
    FormsModule,
    FormControl,
    ControlContainer,
    AbstractControl,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MultiSelect, MultiSelectModule } from 'primeng/multiselect';
import { ItcCheckboxComponent, ItcTagComponent } from '../itc';
import { Subject, takeUntil } from 'rxjs';
import { notBlank } from '../not-blank.validator';
declare const $: any;

export interface Option {
    Label: string;
    Value: string;
    IsSelected?: boolean;
    Disabled?: boolean;
}

@Component({
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, FormsModule, MultiSelectModule, ItcCheckboxComponent, ItcTagComponent],
    selector: 'itc-multiselect',
    templateUrl: './multiselect.component.html',
    styleUrls: ['./multiselect.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => MultiselectComponent),
        },
    ],
})
export class MultiselectComponent implements ControlValueAccessor {
    @Input() formControlName: string;
    control: AbstractControl;
    ngUnsubscribe: Subject<any> = new Subject();
    @ViewChild("dropdown") dropdown: MultiSelect;

    _maxItems: number = 4;
    @Input() set maxItems(value: number) {
        this._maxItems = value;
    }
    _typable: boolean = false;
    @Input() set typable(value: boolean) {
        this._typable = value;
    }
    @Input() placeholder: string;
    @Input() defaultLabel: string;
    @Input() disabled: boolean = false;

    innerControl: FormControl<string> = new FormControl<string>('', notBlank);
    @Input() set typableFormControl(value: FormControl<string>) {
        this.innerControl = value;
    }
    
    _options: Option[] = [];
    optionLabelDict = {};
    @Input() set options(options: string[] | Option[]) {
        this._options = [];
        this.optionLabelDict = {};
        if (options && options.length > 0 && typeof options[0] === 'string') {
            (options as string[]).forEach((o) => {
                this._options.push({
                    Label: o,
                    Value: o,
                    IsSelected: false,
                });
                this.optionLabelDict[o] = o;
            });
        } else {
            (options as Option[]).forEach((o) => {
                this._options.push({
                    Label: o.Label,
                    Value: o.Value,
                    IsSelected: false,
                    Disabled: o.Disabled
                });
                this.optionLabelDict[o.Value] = o.Label;
            });
        }
        this.setOptionsSelected();
    }
    @Output('addOption') addOption: EventEmitter<string> = new EventEmitter<string>();
    additionalOption: string = '';
    _addOption() {
        if (this.innerControl.value && this.innerControl.valid) {
            let val = this.control.value;
            if (val.indexOf(this.innerControl.value) == -1) {
                val.push(this.innerControl.value);
                this.control.setValue(val);
            }
            if (this._options.findIndex(op => op.Value == this.innerControl.value) == -1) {
                this._options.push({
                    Label: this.innerControl.value,
                    Value: this.innerControl.value,
                    IsSelected: true
                });
                this.optionLabelDict[this.innerControl.value] = this.innerControl.value;
            }
        }
        this.innerControl.setValue('');
        this.addOption.emit(this.additionalOption);
    }

    @Output('clearItem') clearItem: EventEmitter<string | null> = new EventEmitter<string | null>();
    _clearItem(item: string | null) {
        if (item) {
            this._options.find(op => op.Value == item).IsSelected = false;
            let val = this.control.value;
            val.splice(val.indexOf(item), 1);
            this.control.setValue(val);
        } else {
            this._options.forEach(c => c.IsSelected = false);
            let val = [];
            this.control.setValue(val);
        }
        this.markAsTouched();
        this.clearItem.emit(item);
    }

    @Output('onSelectedItem') onSelectedItem: EventEmitter<any> = new EventEmitter<any>();
    _onSelectedItem(event: any) {
        if (typeof event.itemValue === 'string') {
            this._options.find(op => op.Value == event.itemValue).IsSelected = (this.control.value.indexOf(event.itemValue) > -1);
        } else {
            let foundInControl = (this.control.value.indexOf(event.itemValue.Value) > -1);
            this._options.find(op => op.Value == event.itemValue.Value).IsSelected = foundInControl;
            event.itemValue.IsSelected = foundInControl;
        }
        this.onSelectedItem.emit(event);
    }
    dropdownSelectClicked() {
    }

    innerValue: string[]; // inner variable that you want to call it

    set value(value: string[]) {
        if (value !== this.innerValue) {
            this.innerValue = value;
            this.onChange(value);
            this.onTouch();
        }
    }
    get value(): string[] {
        return this.innerValue;
    }

    setInnerValue(value: string[]) {
        this.innerValue = value;
        this.onChange(value);
        this.onTouch();
    }

    onChange = (value: any) => {};
    onTouch = () => {};
    onBlur() {
        this.onTouch();
    }
    touched = false;

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }
    markAsTouched() {
        if (!this.touched) {
            this.onTouch();
            this.touched = true;
        }
    }
    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }

    writeValue(value: any): void {
        if (value !== this.innerValue) {
            this.innerValue = value;
        }
    }

    constructor(private controlContainer: ControlContainer) {}

    ngOnInit() {
        if (this.controlContainer) {
            if (this.formControlName) {
                if (this._options?.length) {
                    this.setOptionsSelected();
                }
            } else {
                console.warn(
                    'Missing FormControlName directive from host element of the component'
                );
            }
        } else {
            console.warn("Can't find parent FormGroup directive");
        }
    }

    setOptionsSelected() {
        this.control = this.controlContainer.control.get(this.formControlName);
        let selectedItems = [];
        if (this.control.value && this.control.value.length > 0 && typeof this.control.value[0] === 'string') {
            (this.control.value as string[]).forEach((o) => {
                let index = this._options.findIndex(op => op.Value == o);
                if (index > -1) {
                    selectedItems.push(o);
                    this._options[index].IsSelected = true;
                }
            });
        } else {
            (this.control.value as Option[]).forEach((o) => {
                let index = this._options.findIndex(op => op.Value == o.Value);
                if (index > -1) {
                    selectedItems.push(o.Value);
                    this._options[index].IsSelected = true;
                }
            });
        }
        this.control.setValue(selectedItems);
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next(void 0);
        this.ngUnsubscribe.complete();
    }
}
