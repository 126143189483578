<sds-breadcrumb-trail [crumbs]="breadcrumbs"></sds-breadcrumb-trail>

<header class="pageHeader">
    <h1>Scan and Notification Tasks</h1>
    <help-button
        title="Scan and Notification Tasks"
        text="Scan tasks allow you perform vulnerability scans on the site network at regular intervals. Notification tasks allow you to send email reports of identified vulnerabilities to your technicians and/or customers."
        url="/vs/help/vulscan/create-scan-and-notification-tasks.htm"></help-button>
</header>

<div class="itc tabs container">
    <div class="tabs_header">
        <div class="tab" [routerLink]="['../', 'scan']" [class.tab-active]="taskDisplay == 'scan'">
            Scan Tasks
        </div>
        <div
            class="tab"
            [routerLink]="['../', 'notification']"
            [class.tab-active]="taskDisplay == 'notification'">
            Notification Tasks
        </div>
    </div>
    <div class="tabs_content">
        <div class="scanTasks" *ngIf="taskDisplay == 'scan'">
            <sds-loader [complete]="scanTasksLoadingComplete">
                <div class="item searchInput">
                    <itc-input
                        placeholder="Quick Filter..."
                        [control]="searchKey"
                        (modelChange)="filterScanTasks()"
                        debounce="420"
                        class="quickFilter"></itc-input>
                </div>
                <itc-button
                    type="primary"
                    icon="fa-plus"
                    style="float: right; margin-top: -42px"
                    (onclick)="showAddScanTaskModal()"
                    *ngIf="!readonly">
                    Create Scan Task
                </itc-button>
                <div class="overflowTable">
                    <table
                        sortable-table
                        class="itc-table sortable-table-titles-scroll"
                        (sorted)="onScanTasksSorted($event)"
                        style="margin-top: 0">
                        <thead>
                            <tr>
                                <th sortable-column="Id">Task ID</th>
                                <th sortable-column="ApplianceId">Appliance ID</th>
                                <th sortable-column="ApplianceDescription">
                                    Appliance Description
                                </th>
                                <th sortable-column="Label">Task Label</th>
                                <th sortable-column="Description">Description</th>
                                <th sortable-column="ScanType">Scan Type</th>
                                <th sortable-column="Status">Status</th>
                                <th sortable-column="Next Run">Next Run</th>
                                <th sortable-column="Repeats">Repeats</th>
                                <th></th>
                                <th *ngIf="!readonly"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let scanTask of filteredScanTasks">
                                <td>{{ scanTask.Id }}</td>
                                <td>
                                    {{
                                        scanTask.ApplianceId.startsWith('HEVS')
                                            ? 'Hosted EVS'
                                            : scanTask.ApplianceId
                                    }}
                                </td>
                                <td>{{ scanTask.ApplianceDescription }}</td>
                                <td>{{ scanTask.Label }}</td>
                                <td>{{ scanTask.Description }}</td>
                                <td>{{ friendlyTypes[scanTask.ScanType] }}</td>
                                <td
                                    *ngIf="scanTask.ApplianceId.startsWith('HEVS')"
                                    [innerHTML]="scanTask.Status"></td>
                                <td *ngIf="!scanTask.ApplianceId.startsWith('HEVS')">
                                    {{ scanTask.Status }}
                                </td>
                                <td>{{ scanTask | nextRun | accountDateTime }}</td>
                                <td>{{ scanTask.Repeats }}</td>
                                <td class="collapsing">
                                    <itc-button
                                        type="text"
                                        [disabled]="
                                            scanTask.isRunning || scanTask.Status == 'Queued'
                                        "
                                        (onclick)="runNow(scanTask)"
                                        [tooltip]="
                                            scanTask.isRunning
                                                ? 'This task is already running.'
                                                : ''
                                        ">
                                        Run Now
                                    </itc-button>
                                </td>
                                <td class="collapsing" *ngIf="!readonly">
                                    <itc-button
                                        type="icon"
                                        icon="fa-pencil"
                                        [disabled]="
                                            !scanTask.CanEdit ||
                                            scanTask.isRunning ||
                                            scanTask.Status == 'Queued'
                                        "
                                        (onclick)="showScanTaskEditModal(scanTask)"
                                        [tooltip]="
                                            scanTask.isRunning
                                                ? 'You cannot edit a task while it\'s running.'
                                                : scanTask.Status == 'Queued'
                                                ? 'You cannot edit a task if it\'s queued.'
                                                : 'Edit'
                                        "
                                        ttPosition="top-left"></itc-button>
                                    <itc-button
                                        type="icon"
                                        icon="fa-trash-can"
                                        [disabled]="!scanTask.CanDelete || scanTask.isRunning"
                                        (onclick)="confirmScanTaskDelete(scanTask)"
                                        [tooltip]="
                                            scanTask.isRunning
                                                ? 'You cannot delete a task while it\'s running.'
                                                : 'Delete'
                                        "
                                        ttPosition="top-left"></itc-button>

                                    <!-- <a href="javascript:void 0" *ngIf="scanTask.CanEdit" class="ui basic icon button" [class.disabled]="saving" (click)="showScanTaskEditModal(scanTask)" data-content="Edit" data-position="top center" title="Edit">
                      <i class="icon-edit"></i>
                  </a> -->
                                    <!-- <a href="javascript:void 0" *ngIf="scanTask.CanDelete" class="ui basic icon button" [class.disabled]="saving" (click)="confirmScanTaskDelete(scanTask)" data-content="Delete" data-position="top center" title="Delete">
                      <i class="icon-delete"></i>
                  </a> -->
                                </td>
                            </tr>
                            <tr *ngIf="scanTasks?.length == 0">
                                <td colspan="10">No Scan Tasks</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </sds-loader>
        </div>

        <div class="notificationTasks" *ngIf="taskDisplay == 'notification'">
            <sds-loader [complete]="notificationTasksLoadingComplete">
                <div class="item searchInput">
                    <itc-input
                        placeholder="Quick Filter..."
                        [control]="searchKey"
                        (modelChange)="filterNotificationTasks()"
                        debounce="420"
                        [working]="filteringNotifications"
                        class="quickFilter"></itc-input>
                </div>
                <itc-button
                    type="primary"
                    icon="fa-plus"
                    style="float: right; margin-top: -42px"
                    (onclick)="showAddNotificationTaskModal()"
                    *ngIf="!readonly">
                    Create Notification Task
                </itc-button>
                <div class="overflowTable">
                    <table
                        sortable-table
                        class="itc-table sortable-table-titles-scroll"
                        (sorted)="onNotificationTasksSorted($event)"
                        style="margin-top: 0">
                        <thead>
                            <tr>
                                <th sortable-column="Id">Task ID</th>
                                <!-- <th sortable-column="TaskType">Task Type</th> -->
                                <th sortable-column="Label">Task Label</th>
                                <th sortable-column="Description">Description</th>
                                <th sortable-column="Status">Status</th>
                                <th sortable-column="Next Run">Next Run</th>
                                <th sortable-column="Repeats">Repeats</th>
                                <th></th>
                                <th *ngIf="!readonly"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let notificationTask of filteredNotificationTasks">
                                <td>{{ notificationTask.Id }}</td>
                                <!-- <td>Notification</td> -->
                                <td>{{ notificationTask.Label }}</td>
                                <td>{{ notificationTask.Description }}</td>
                                <td>{{ notificationTask.Enabled ? 'Enabled' : 'Disabled' }}</td>
                                <td>{{ notificationTask | nextRun | accountDateTime }}</td>
                                <td>{{ notificationTask.Repeats }}</td>
                                <td class="collapsing">
                                    <itc-button
                                        type="text"
                                        (onclick)="showNotificationRunNowModal(notificationTask)">
                                        Run Now
                                    </itc-button>
                                </td>
                                <td class="collapsing" *ngIf="!readonly">
                                    <itc-button
                                        type="icon"
                                        icon="fa-pencil"
                                        [disabled]="!notificationTask.CanEdit"
                                        (onclick)="showNotificationTaskEditModal(notificationTask)"
                                        ttposition="top"
                                        tooltip="Edit"></itc-button>
                                    <itc-button
                                        type="icon"
                                        icon="fa-trash-can"
                                        [disabled]="!notificationTask.CanDelete"
                                        (onclick)="confirmNotificationTaskDelete(notificationTask)"
                                        ttposition="top"
                                        tooltip="Delete"></itc-button>

                                    <!-- <a href="javascript:void 0" *ngIf="notificationTask.CanEdit" class="ui basic icon button" [class.disabled]="saving" (click)="showNotificationTaskEditModal(notificationTask)" data-content="Edit" data-position="top center" title="Edit">
                      <i class="icon-edit"></i>
                  </a>
                  <a href="javascript:void 0" *ngIf="notificationTask.CanDelete" class="ui basic icon button" [class.disabled]="saving" (click)="confirmNotificationTaskDelete(notificationTask)" data-content="Delete" data-position="top center" title="Delete">
                      <i class="icon-delete"></i>
                  </a> -->
                                </td>
                            </tr>
                            <tr *ngIf="notificationTasks?.length == 0">
                                <td colspan="8">No Notification Tasks</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </sds-loader>
        </div>
    </div>

    <kvs-scan-wizard-modal
        [site]="site"
        (onSave)="scanTaskSaved($event)"
        (onCreateProfile)="showAddProfileModal()"
        #addScanTaskModal></kvs-scan-wizard-modal>
    <kvs-notification-wizard-modal
        [site]="site"
        (onSave)="notificationTaskSaved($event)"
        #addNotificationTaskModal></kvs-notification-wizard-modal>
    <kvs-custom-profile-wizard-modal
        [site]="site"
        (onSave)="returnToScanTaskModal('ScanProfilePage', $event)"
        (onClose)="returnToScanTaskModal('', false)"
        #addProfileModal></kvs-custom-profile-wizard-modal>

    <sm-modal title="Confirm Delete" class="sm" #confirmDeleteScanTaskModal>
        <modal-content>
            <itc-banner type="warning">Are you sure you want to delete this Scan Task?</itc-banner>

            <table>
                <tr>
                    <td><b>Id</b></td>
                    <td>{{ scanTaskToDelete?.Id }}</td>
                </tr>
                <tr>
                    <td><b>Appliance Id</b></td>
                    <td>{{ scanTaskToDelete?.ApplianceId }}</td>
                </tr>
                <tr>
                    <td><b>Label</b></td>
                    <td>{{ scanTaskToDelete?.Label }}</td>
                </tr>
            </table>
        </modal-content>
        <modal-actions>
            <itc-button
                label="Cancel"
                type="secondary"
                (onclick)="confirmDeleteScanTaskModal.hide()"
                [disabled]="isDeleting"></itc-button>
            <itc-button
                label="Delete"
                type="danger"
                (onclick)="deleteScanTask()"
                [loading]="isDeleting"></itc-button>
        </modal-actions>
    </sm-modal>

    <sm-modal title="Confirm Delete" class="sm" #confirmDeleteNotificationTaskModal>
        <modal-content>
            <itc-banner type="warning">
                Are you sure you want to delete this Notification Task?
            </itc-banner>

            <table>
                <tr>
                    <td><b>Id</b></td>
                    <td>{{ notificationTaskToDelete?.Id }}</td>
                </tr>
                <tr>
                    <td><b>Label</b></td>
                    <td>{{ notificationTaskToDelete?.Label }}</td>
                </tr>
            </table>
        </modal-content>
        <modal-actions>
            <itc-button
                label="Cancel"
                type="secondary"
                (onclick)="confirmDeleteNotificationTaskModal.hide()"></itc-button>
            <itc-button
                label="Delete"
                type="danger"
                (onclick)="deleteNotificationTask()"></itc-button>
        </modal-actions>
    </sm-modal>

    <sm-modal title="Notification Task Run Now Confirm" class="xs" #notificationRunNowModal>
        <modal-content>
            <div>
                {{ modalContent }}
            </div>
        </modal-content>
        <modal-actions>
            <itc-button type="secondary" (onclick)="notificationRunNowModal.hide()">
                Cancel
            </itc-button>
            <itc-button type="primary" (onclick)="runNowNotification()">Yes</itc-button>
        </modal-actions>
    </sm-modal>
</div>

<sm-modal title="License Limit Exceeded" class="sm hevsLicenseModal" #hostedExternalRunNowWarning>
    <modal-content>
        <itc-banner type="error">
            The number of scheduled External IP Scans in the Scan Task
            <strong>exceeds</strong>
            your license limit.
        </itc-banner>
        <span class="licenseCountLabel">Scheduled External IP Scans:</span>
        &nbsp;
        <span class="licenseCoutLabel">{{ this.ipScansRequested }}</span>
        <br />
        <span class="licenseCountLabel">Available External IP Scans:</span>
        &nbsp;
        <span class="licenseCountCalculation">{{ this.licence?.Remaining }}</span>
        <br />
        <br />
        <p>Reduce the number of IPs to be scanned or acquire additional licenses.</p>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" (onclick)="hostedExternalRunNowWarning.hide()">
            Close
        </itc-button>
        <itc-button type="primary" (onclick)="onBuyMoreLicenseButtonClicked()"
                    [loading]="buyMoreLicenseButtonLoading">
            Buy More Licenses
        </itc-button>
    </modal-actions>
</sm-modal>

<sm-modal class="xs" #buyMoreLicenseConfirmationModal>
    <modal-title>Hosted EVS license request submitted</modal-title>
    <modal-content>Your request for additional Hosted EVS licenses has been successfully submitted.
        Our team will reach out to you shortly.
    </modal-content>
    <modal-actions>
        <itc-button type="primary" (click)="buyMoreLicenseConfirmationModal.hide()">OK</itc-button>
    </modal-actions>
</sm-modal>
