<h2>Employee Tracker Policy Acceptance Printable Report</h2>

<div
    style="
        margin-top: 12px;
        padding-top: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    ">
    <div class="donutsContainer">
        <sds-tracker-donut-component
            [cardMeta]="policyInfo.DonutData.PolicyName"
            [grpNames]="policyInfo.DonutData.Groups"
            [chartData]="policyInfo.DonutData.ChartData"
            [centerText]="policyInfo.DonutData.PercentageComplete"
            [legendPosition]="'bottom'"
            [legendSeparator]="[true, false, true, false]"
            [diameter]="150"
            [centerTextSmall]="'Completed'"></sds-tracker-donut-component>
    </div>
</div>

<div>
    <table class="itc-table">
        <thead>
            <tr>
                <th style="margin-left: 5px; text-align: left">Name</th>
                <th style="text-align: left">Email</th>
                <th style="text-align: left">Status</th>
                <th style="text-align: left">Effective Date</th>
                <th style="text-align: left">Accepted/Rejected Date</th>
            </tr>
        </thead>

        <tbody class="flex-table basic" style="margin-top: 0 !important">
            <tr *ngFor="let row of policyInfo.EmployeeData; let i = index">
                <td style="margin-left: 5px">{{ row.FirstName + ' ' + row.LastName }}</td>
                <td>{{ row.Email }}</td>
                <td style="padding: 12px">
                    <div
                        class="statusBackGround"
                        *ngIf="row.Status == 'Accepted'"
                        [style.background-color]="
                            tableStatusBackground.COMPLETED_STATUS_TABLE_BACKGROUND
                        "
                        [style.color]="tableStatusBackground.COMPLETED_STATUS_TABLE_COLOR">
                        Accepted
                    </div>
                    <div
                        class="statusBackGround"
                        *ngIf="row.Status == 'Needs Attention'"
                        [style.background-color]="
                            tableStatusBackground.PENDING_STATUS_TABLE_BACKGROUND
                        "
                        [style.color]="tableStatusBackground.PENDING_STATUS_TABLE_COLOR">
                        Needs Attention
                    </div>
                    <div
                        class="statusBackGround"
                        *ngIf="row.Status == 'Rejected'"
                        [style.background-color]="
                            tableStatusBackground.EXPIRED_STATUS_TABLE_BACKGROUND
                        "
                        [style.color]="tableStatusBackground.EXPIRED_STATUS_TABLE_COLOR">
                        Rejected
                    </div>
                    <div
                        class="statusBackGround"
                        *ngIf="row.Status == 'Expired'"
                        [style.background-color]="
                            tableStatusBackground.EXPIRED_STATUS_TABLE_BACKGROUND
                        "
                        [style.color]="tableStatusBackground.EXPIRED_STATUS_TABLE_COLOR">
                        Expired
                    </div>
                </td>
                <td>{{ row.EffectiveDate | accountDateTime }}</td>
                <td>{{ row.AcceptedDate ? (row.AcceptedDate | accountDateTime) : 'N/A' }}</td>
            </tr>
        </tbody>
    </table>
</div>
