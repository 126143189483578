import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MessageService } from 'app/core/message.service';
import { NotificationService } from 'app/shared/itc/notification/notification.service';
import { Site } from 'app/sites/shared/site.model';
import { SITE, PING_SITE } from 'app/sites/shared/constants';
import { SiteParam, SiteSettingService } from 'app/sites/site/settings/site-settings.service';
import { SiteService } from 'app/sites/shared/site.service';
import { Subscription } from 'rxjs';
import { UiService } from 'app/core/ui.service';
import { OrganizationsService } from 'app/organizations/organizations.service';

const MATCHING_RULE_PNAME: string = 'vulscan-matchingrule';
const AGENT_SCAN_IMPORT_CONFIGURATION_PNAME: string = 'vulscan-agent-import-configs';

@Component({
    selector: 'sds-kvs-general-settings',
    templateUrl: './kvs-general-settings.component.html',
    styleUrls: ['./kvs-general-settings.component.css'],
})
export class KvsGeneralSettingsComponent implements OnInit {
    breadcrumbs = [
        { path: '../../..', text: 'VulScan' },
        { path: '../..', text: 'Settings' },
        { path: '..', text: 'General' },
    ];

    loadingComplete: boolean = false;
    orgDiscoveryLoadingComplete: boolean = false;
    isSaving: boolean = false;
    isAgentConfigSaving: boolean = false;
    readonly: boolean;
    site: Site;
    msgSub: Subscription;

    attrMacCtrl: UntypedFormControl = new UntypedFormControl(true);
    attrHostnameCtrl: UntypedFormControl = new UntypedFormControl(true);
    attrIpCtrl: UntypedFormControl = new UntypedFormControl(true);
    agentLabelCtrl: UntypedFormControl = new UntypedFormControl();
    @ViewChild('agentLabelsSelect') agentLabelsSelect: any;

    settings: any = {
        condition: 'all',
        attributes: {},
    };

    attributes: any = {
        mac: true,
        hostname: true,
        ip: true,
    };

    agentLabels: string[] = [];
    actualAgentLabels: Set<string> = new Set<string>();
    selectedAgentLabels: string[] = [];

    constructor(
        private messageService: MessageService,
        private notificationService: NotificationService,
        private siteService: SiteService,
        private siteSettingService: SiteSettingService,
        private uiService: UiService,
        private organizationService: OrganizationsService
    ) {}

    ngOnInit() {
        this.site = this.siteService.getCurrentSite();
        this.messageService.broadcast(PING_SITE);
        if (this.site) this.onSite(this.site);
    }

    onSite(site: Site) {
        this.uiService.setTitle('General Settings', site.Name);

        this.siteSettingService.getSiteParam(site.Id, MATCHING_RULE_PNAME).then((settings) => {
            this.settings = JSON.parse(settings.Value);

            this.attrMacCtrl.setValue(this.settings.attributes.mac);
            this.attrHostnameCtrl.setValue(this.settings.attributes.hostname);
            this.attrIpCtrl.setValue(this.settings.attributes.ip);

            this.loadingComplete = true;
        });

        this.organizationService
            .getOrgDiscoveryAgentInfo(site.Organization)
            .then((agents) => {
                this.actualAgentLabels = new Set<string>(
                    agents.filter((x) => x.Label?.length > 0).map((x) => x.Label)
                );
                this.agentLabels = [...this.actualAgentLabels];
            })
            .catch((_) => {
                this.agentLabels = [];
            })
            .then((_) => {
                this.siteSettingService
                    .getSiteParam(site.Id, AGENT_SCAN_IMPORT_CONFIGURATION_PNAME)
                    .then((configs) => {
                        if (!configs?.Value == null || configs?.Value == undefined || configs?.Value == "") {
                            configs.Value = "[\"/%all%/\"]";
                            this.siteSettingService.upsertSiteParams(site.Id, [configs]);
                        }
                        if (configs.Value) {
                            this.selectedAgentLabels = [
                                ...new Set<string>(JSON.parse(configs.Value)),
                            ];
                            this.agentLabels.push(
                                ...this.selectedAgentLabels.filter(
                                    (x) => !(x in this.actualAgentLabels)
                                )
                            );

                            setTimeout(() => {
                                this.agentLabelsSelect.runBehavior(
                                    'set selected',
                                    this.selectedAgentLabels
                                );
                                this.orgDiscoveryLoadingComplete = true;
                            });
                        }
                    });
            });
    }

    updateMatchingRule() {
        this.attributes.mac = this.attrMacCtrl.value;
        this.attributes.hostname = this.attrHostnameCtrl.value;
        this.attributes.ip = this.attrIpCtrl.value;
        this.settings.attributes = this.attributes;
    }

    saveChanges() {
        this.isSaving = true;
        this.updateMatchingRule();

        if (!this.validate()) {
            return;
        }

        let pValue = JSON.stringify(this.settings);
        let _siteParam: SiteParam[] = [];
        _siteParam.push({
            SiteId: this.site.Id,
            Name: MATCHING_RULE_PNAME,
            Value: pValue,
        });

        this.siteSettingService
            .upsertSiteParams(this.site.Id, _siteParam)
            .then((res) => {
                this.notificationService.toast.success(
                    'Saved',
                    'VulScan settings saved successfully.'
                );
                this.isSaving = false;
            })
            .catch(() => {
                this.notificationService.toast.error('Error', 'Vulscan settings not saved.');
                this.isSaving = false;
            });
    }

    onSelectAgentConfigs(ev: any) {
        if (ev?.length <= this.selectedAgentLabels.length) {
            this.selectedAgentLabels = ev;
            this.agentLabels = [...new Set([...ev, ...this.actualAgentLabels])];
            return;
        }

        if (ev.at(-1) == '/%all%/') {
            this.selectedAgentLabels.forEach((o) => {
                this.agentLabelsSelect.runBehavior('remove selected', o);
            });
            this.selectedAgentLabels = [ev.at(-1)];
            return;
        }

        if (this.selectedAgentLabels.includes('/%all%/')) {
            this.agentLabelsSelect.runBehavior('set exactly', [ev.at(-1)]);
            this.selectedAgentLabels = [ev.at(-1)];
            return;
        }

        this.agentLabels = [...new Set([...ev, ...this.actualAgentLabels])];
        this.selectedAgentLabels = ev;
    }

    saveAgentConfig() {
        this.isAgentConfigSaving = true;
        let _siteParam: SiteParam[] = [];
        _siteParam.push({
            SiteId: this.site.Id,
            Name: AGENT_SCAN_IMPORT_CONFIGURATION_PNAME,
            Value: JSON.stringify(this.selectedAgentLabels),
        });

        this.siteSettingService
            .upsertSiteParams(this.site.Id, _siteParam)
            .then((res) => {
                this.notificationService.toast.success(
                    'Saved',
                    'VulScan Discovery Agent Scan Data Import Configuration settings saved successfully.'
                );

                this.isAgentConfigSaving = false;
            })
            .catch((_) => {
                this.notificationService.toast.error(
                    'Error',
                    'VulScan Discovery Agent Scan Data Import Configuration not saved'
                );
                this.isAgentConfigSaving = false;
            });
    }

    validate() {
        this.notificationService.banner.clear('attributes_banner');
        if (!this.attributes.mac && !this.attributes.hostname && !this.attributes.ip) {
            this.notificationService.banner.error(
                '',
                'You must select at least one attribute.',
                'attributes_banner'
            );
            this.isSaving = false;
            return false;
        } else {
            return true;
        }
    }
}
