import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { AuthService, SiteRoles } from 'app/core/auth/auth.service';

import { NavService } from 'app/core/nav/nav.service';
import { PrintService } from './sites/shared/print.service';

import { PrimeNGConfig } from 'primeng/api';
import { LicenseManager } from 'primeng/api';

declare var window: any;
declare var $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
    constructor(
        private authService: AuthService,
        private navService: NavService,
        private router: Router,
        public printService: PrintService,
        private primengConfig: PrimeNGConfig
    ) {
        LicenseManager.verify(
            'eFr+bs3/XNwRXXH15nI6tGWAQFfho3pLD5mAcx93tWYB6S5mzY0TEuW2tx/lwfbfwmocfHIv2dpkkMhfOt3Qs38fNDRgCiszltQfWdnxfg==',
            '3147989354'
        );
    }

    ngOnInit() {
        this.primengConfig.ripple = false;
        this.primengConfig.zIndex = {
            modal: 1100,
            overlay: 1010,
            menu: 1010,
            tooltip: 1100,
        };
    }
}
