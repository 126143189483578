import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, inject, Input, OnInit, Output } from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    FormsModule,
    NG_VALUE_ACCESSOR,
    ReactiveFormsModule,
    ValidationErrors,
    ValidatorFn,
} from '@angular/forms';
import { FileUploaderSummary } from 'app/shared/itc/file-uploader/file-uploader.model';
import { ItcButtonComponent } from 'app/shared/itc/button/button.component';
import { ItcCheckboxComponent, ItcFileUploaderComponent, ItcTagComponent } from 'app/shared/itc';
import { CommonModule } from '@angular/common';
import { notBlank } from 'app/shared/not-blank.validator';
import { validateIPAddressControl, validIpOrRange } from 'app/shared/ipaddress.validator';
import { remove as _remove, uniq as _uniq, uniqBy as _uniqBy } from 'lodash-es';
import { NotificationService } from 'app/shared/itc/notification/notification.service';
import { IpRangeService } from './ip-range.service';

export interface IpRange {
    isChecked: boolean;
    ip: string;
}

@Component({
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        ItcCheckboxComponent,
        ItcButtonComponent,
        ItcFileUploaderComponent,
        ItcTagComponent,
    ],
    selector: 'rft-ip-range',
    template: `
        <form class="itc form">
            <div class="input-container">
                <div class="input-container__input">
                    <div class="required field">
                        <input
                            type="text"
                            name="ipRange"
                            [formControl]="ipRangeCtrl"
                            [placeholder]="placeHolderText"
                            (keypress)="checkKeys($event)"
                            #ipControl />
                        <span
                            class="error-message"
                            [hidden]="!ipRangeCtrl.value || ipRangeCtrl.valid">
                            {{
                                allowHostName
                                    ? 'Invalid IP Address, IP Address range, or Domain Name format.'
                                    : 'Please enter a unique and valide IP or IP range.'
                            }}
                        </span>
                    </div>
                    <div>
                        <itc-button
                            type="primary"
                            [disabled]="ipRangeCtrl.invalid"
                            (onclick)="addIpRangeOrHostName()"
                            [ngClass]="{ addButton: !hideImport }">
                            Add
                        </itc-button>
                        <itc-file-uploader
                            type="button"
                            accept=".txt"
                            allowedFileTypes="TXT"
                            returnType="BinaryString"
                            [allowToasts]="false"
                            buttonText="Import"
                            buttonType="secondary"
                            buttonIcon=""
                            [hidden]="hideImport"
                            [disabled]="importing"
                            (uploadDone)="importIps($event)"></itc-file-uploader>
                    </div>
                </div>
                <div class="select-container">
                    <div class="ipContainer">
                        <div
                            *ngFor="let ip of ipRangesObj"
                            [class.selected]="ip.isChecked"
                            (click)="updateCheckboxes(ip)">
                            {{ ip.ip }}
                        </div>
                    </div>
                    <div class="select-container__buttons">
                        <itc-button
                            label="Remove Selected"
                            type="secondary"
                            size="small"
                            [disabled]="!selectedIpRanges"
                            (onclick)="removeIpRange(false)"></itc-button>
                        <itc-button
                            *ngIf="defaultValue"
                            label="Reset to Auto-Detected"
                            type="secondary"
                            size="small"
                            (onclick)="resetIpRange()"></itc-button>
                        <itc-button
                            type="secondary"
                            size="small"
                            [hidden]="hideExcludeIp"
                            (onclick)="excludeIps.emit()">
                            {{ excludeIpButtonText }}
                            <itc-tag *ngIf="totalExcludeIp !== 0">{{ totalExcludeIp }}</itc-tag>
                        </itc-button>
                        <itc-button
                            label="Clear All"
                            type="secondary"
                            size="small"
                            [disabled]="!ipRanges.length"
                            (onclick)="removeIpRange(true)"></itc-button>
                        <div class="selectedIPs">
                            {{ ipAddressCountText }}
                            <strong>{{ ipCount }}</strong>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    `,
    styles: [
        `
            form {
                display: flex;
                margin-top: 8px;
                margin-bottom: 0;
            }
            .input-container {
                width: 100%;
            }
            .input-container__input {
                display: flex;
                gap: 16px;
                justify-content: space-between;

                & > div:nth-of-type(1) {
                    flex: 2;
                }

                .addButton {
                    margin-right: 12px;
                }
            }
            .ipContainer {
                border: 1px solid #cccccc;
                border-radius: 4px;
                min-height: 200px;
                overflow-y: scroll;
                padding-top: 7px;
                &:hover {
                    border-color: #999;
                }

                div {
                    padding: 4px 8px;
                    line-height: 20px;
                    cursor: pointer;
                    &.selected {
                        background-color: rgba(var(--rft-orange-rgb), 0.2);
                    }
                }
            }
            .select-container {
                height: 226px;
                display: flex;
                flex-direction: column;

                .select-container__buttons {
                    display: flex;
                    align-items: center;
                    padding-top: 8px;
                }
                itc-file-uploader {
                    padding-left: 16px;
                }
            }
            .selectedIPs {
                flex: 1;
                text-align: right;
                color: var(--text-secondary);
            }

            .error-message {
                display: block;
                color: var(--text-critical);
                font-weight: 400;
                line-height: 20px;
                font-size: 14px;
                margin-bottom: 16px;
            }

            ::ng-deep itc-tag > p {
                background-color: #e8f1ff !important;
                margin-right: 0 !important;
                margin-left: 4px;
                height: 16px;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => IpRangeComponent),
        },
    ],
})
export class IpRangeComponent implements ControlValueAccessor, OnInit {
    notificationService = inject(NotificationService);
    ipRangeService = inject(IpRangeService);

    ipRanges: string[] = []; // this will be the reference to the control, do not overwrite it, splice and push
    ipRangesObj: IpRange[] = []; // this is used to handle the checkboxes
    selectedIpRanges: number = 0; //
    ipCount = 0;
    importing = false;
    ipRangeCtrl: FormControl;

    @Input() totalExcludeIp: number = 0;
    @Input() excludeIpButtonText: string = 'Exclude IPs';
    @Input() ipAddressCountText: string = 'IP count:';
    @Input() placeHolderText: string = 'Add a single IP or IP range (192.168.0.0-192.168.0.255)';
    @Input() allowHostName: boolean = false;
    @Input() hideExcludeIp: boolean = false;
    @Input() hideImport: boolean = false;
    @Input() defaultValue: string[];
    @Input() validators: ValidatorFn[] = [validIpOrRange];
    @Output() ipRangesChange = new EventEmitter<number | void>();
    @Output() excludeIps = new EventEmitter<void>();

    /* Logic/code to be able to use ngmodel or formcontrol in this component*/
    set value(value: string[]) {
        if (value !== this.ipRanges) {
            this.ipRanges = value;
            this.ipRangesObj = value.map((ip) => {
                return {
                    isChecked: false,
                    ip: String(ip),
                };
            });
            this.onChange(value);
            this.onTouch();
        }
    }
    get value(): string[] {
        return this.ipRanges;
    }

    ngOnInit(): void {
        let validators = [notBlank, ...this.validators, this.NoIPDuplicates()];
        this.ipRangeCtrl = new FormControl('', validators);
    }

    onChange = (value: any) => {};
    onTouch = () => {};
    onBlur() {
        this.onTouch();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    writeValue(value: any): void {
        if (value && value !== this.ipRanges) {
            this.ipRanges = value;
            this.ipRangesObj = value.map((ip) => {
                return {
                    isChecked: false,
                    ip: String(ip),
                };
            });
            this.countIPs();
        }
    }

    addIpRangeOrHostName(): void {
        let newIP = this.ipRangeCtrl.value;
        if (newIP && validateIPAddressControl(newIP) === null) {
            newIP = newIP.replace(/[^0-9\.\-]/g, '');
            if (!this.ipRanges.includes(newIP)) {
                this.ipRanges.push(newIP);
                // if (this.ipRanges.findIndex((ip) => ip.ip === newIP) === -1) {
                this.ipRangesObj.push({
                    isChecked: false,
                    ip: newIP,
                });
                this.ipRangeCtrl.setValue('');
            }
            this.ipRangeCtrl.markAsPristine();
        } else {
            if (this.allowHostName) {
                this.ipRanges.push(newIP);
                this.ipRangesObj.push({
                    isChecked: false,
                    ip: newIP,
                });
                this.ipRangeCtrl.setValue('');
                this.ipRangeCtrl.markAsPristine();
            }
        }

        this.ipRangeService.sortIPs(this.ipRanges);
        this.ipRangeService.sortIPs(this.ipRangesObj);
        this.countIPs();
    }
    importIps(file: FileUploaderSummary): void {
        let result = file.file['result'];
        this.importing = true;
        this.importIpsTxt(result);
    }
    importIpsTxt(fileContent: string): void {
        let importIPs = fileContent.replace(/\r?\n/g, ',').split(',');
        let newEntries: IpRange[] = [];
        let newIPs: string[] = [];
        let prevCount = this.ipCount;
        let allImportCount = 0;
        importIPs.forEach((ip) => {
            if (ip) {
                if (ip.indexOf('-') > -1) {
                    let rangeArray = ip.split('-');
                    let importRange = this.ipRangeService.getIpsInRange(
                        rangeArray[0],
                        rangeArray[1]
                    );
                    allImportCount += importRange || 1;
                } else {
                    allImportCount++;
                }
                if (validateIPAddressControl(ip) == null) {
                    newEntries.push({
                        isChecked: false,
                        ip: ip,
                    });
                    newIPs.push(ip);
                }
            }
        });
        let tempIPRangesObj = _uniqBy([...this.ipRangesObj, ...newEntries], 'ip');
        let tempIPRanges = _uniq([...this.ipRanges, ...newIPs]);
        // clear ips and update to keep the reference
        this.ipRanges.splice(0, this.ipRanges.length);
        this.ipRanges.push(...tempIPRanges);
        this.ipRangesObj = tempIPRangesObj;
        this.ipRangeService.sortIPs(this.ipRanges);
        this.ipRangeService.sortIPs(this.ipRangesObj);
        this.countIPs();
        this.notificationService.toast.success(
            'Successful Import',
            `${
                this.ipCount - prevCount
            } of ${allImportCount} entries were new and valid and have been imported.`
        );
        this.importing = false;
    }

    resetIpRange(): void {
        this.ipRanges.splice(0, this.ipRanges.length);
        this.ipRangesObj = [];
        this.defaultValue.forEach((r) => {
            let addIP: IpRange = {
                isChecked: false,
                ip: r,
            };
            this.ipRangesObj.push(addIP);
            this.ipRanges.push(r);
        });
        this.countIPs();
    }

    removeIpRange(removeAll: boolean): void {
        if (removeAll) {
            this.ipRanges.splice(0, this.ipRanges.length);
            this.ipRangesObj = [];
        } else {
            let removedObj = _remove(this.ipRangesObj, (ip) => ip.isChecked);
            removedObj.forEach((ip) => {
                this.ipRanges.splice(this.ipRanges.indexOf(ip.ip), 1);
            });
        }
        this.updateSelectedIpRanges();
    }

    // this needs to be done in the parent component
    // isConfirmed(): boolean {
    //     return confirm(
    //         `You have requested to scan ${this.ipRanges.length} IP addresses.\nLarge ranges can take long periods of time to complete.\nDo you wish to continue?`
    //     );
    // }

    updateCheckboxes(ip: IpRange): void {
        ip.isChecked = !ip.isChecked;
        this.updateSelectedIpRanges();
    }

    updateSelectedIpRanges(): void {
        this.selectedIpRanges = this.ipRangesObj.filter((ip) => ip.isChecked).length;
        this.countIPs();
    }

    countIPs(): void {
        this.ipCount = this.ipRangeService.countIPs(
            this.ipRangesObj.map((ip) => ip.ip),
            this.allowHostName
        );
        this.ipRangeService.setValid(this.ipCount > 0);
        this.ipRangesChange.emit(this.ipCount);
    }

    checkKeys(evt: KeyboardEvent): boolean {
        var keyCode = evt.key;
        // submit on enter
        if (keyCode === 'Enter' && this.ipRangeCtrl.valid) {
            this.addIpRangeOrHostName();
            evt.preventDefault();
            return true;
        }

        if (!this.allowHostName) {
            // only allow digits, periods, and dashes
            if (keyCode.match(/[^0-9\.\-]/)) {
                evt.preventDefault();
                return false;
            }
        }
    }

    NoIPDuplicates(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const newIP = control.value;
            if (newIP && this.ipRanges.includes(newIP)) {
                return { duplicateIP: true };
            }
            return null;
        };
    }
}
