<table class="simple_table" style="max-width: 700px" *ngFor="let app of apps">
    <tr *ngIf="app.Type">
        <td class="collapsing" style="width: 185px">Data Collector Type:</td>
        <td>{{ app.Type }}</td>
    </tr>
    <tr>
        <td class="collapsing">Data Collector ID:</td>
        <td
            style="display: flex; align-items: center"
            [class.cellWithIcon]="
                allowDelete &&
                (app.Type == 'Internal Vulnerability Scanner' ||
                    app.Type == 'External Vulnerability Scanner' ||
                    app.Type == 'Remote Internal Vulnerability Scanner' ||
                    app.Type == 'Portable Vulnerability Scanner')
            ">
            <span style="flex: 1">{{ app.Id == 'HEVS' ? 'Hosted EVS' : app.Id }}</span>
            <itc-button
                type="icon"
                icon="fa-trash-can"
                *ngIf="
                    allowDelete &&
                    (app.Type == 'Internal Vulnerability Scanner' ||
                        app.Type == 'External Vulnerability Scanner' ||
                        app.Type == 'Remote Internal Vulnerability Scanner' ||
                        app.Type == 'Portable Vulnerability Scanner')
                "
                (onclick)="showDeleteModal(app)"
                tooltip="Remove Vulnerability Scanner from this Site."></itc-button>
        </td>
    </tr>
    <tr *ngIf="app.Type != 'Hosted External Vulnerability Scanner'">
        <td class="collapsing">Description:</td>
        <td style="display: flex; align-items: center" class="cellWithIcon">
            <span style="flex: 1">{{ app.Description }}</span>
            <itc-button
                type="icon"
                icon="fa-pencil"
                (onclick)="showEditDescModal(app)"
                tooltip="Edit"></itc-button>
        </td>
    </tr>
    <tr *ngIf="app.LastCheckin">
        <td class="collapsing">Last Check-in:</td>
        <td>
            {{
                app.IsCheckedIn == false
                    ? 'Never'
                    : (app.LastCheckin | accountDateTime)
            }}
        </td>
    </tr>
    <tr *ngIf="app.UpdateStatus">
        <td class="collapsing">Update Status:</td>
        <td>
            <i class="green check circle icon" *ngIf="app.UpdateStatus == 'up-to-date'"></i>
            <i
                class="yellow exclamation triangle icon"
                *ngIf="app.UpdateStatus == 'update available'"></i>
            {{ app.UpdateStatus }}
            <a
                href="javascript:"
                *ngIf="app.UpdateStatus == 'update available'"
                (click)="sendUpdate(app.Id)">
                (update now)
            </a>
        </td>
    </tr>
    <!-- <tr *ngIf="!notInstalled"> -->
    <tr *ngIf="showManageSection">
        <td class="collapsing">Manage Data Collector:</td>
        <td>
            <sds-manage-appliance
                [app]="app"
                [siteId]="siteId"
                [disabled]="app.Id.includes('not installed')"
                [isHostedEVS]="app.Type == 'Hosted External Vulnerability Scanner'"
                *ngIf="app.Status != 'Offline'"></sds-manage-appliance>
            <p *ngIf="app.Status == 'Offline'" style="display: inline-block; color: grey">
                (Data Collector Offline)
            </p>
        </td>
    </tr>
</table>

<sm-modal class="xs" title="Data Collector Description" #editDataCollectorDescriptionModal>
    <modal-content>
        <div>Add description of data collector.</div>
        <input type="text" [formControl]="dcDescCtrl" maxlength="100" style="margin-bottom: 0" />
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" (onclick)="editDataCollectorDescriptionModal.hide()">
            Cancel
        </itc-button>
        <itc-button type="primary" (onclick)="saveDcDesc()">Save</itc-button>
    </modal-actions>
</sm-modal>

<sm-modal class="xs" title="Confirm" #deleteKvsModal>
    <modal-content>
        <div>
            Removing an Appliance from a Site will cancel all currently scheduled and running tasks.
            Are you sure you wish to remove this Appliance from this site?
        </div>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" (onclick)="deleteKvsModal.hide()" [disabled]="isDeleting">
            No
        </itc-button>
        <itc-button type="danger" (onclick)="deleteKVS()" [loading]="isDeleting">Yes</itc-button>
    </modal-actions>
</sm-modal>

<sm-modal class="sm" title="Update Sent" #afterUpdateModal>
    <modal-content>
        The request to update has been queued. The data collector will update once it is online and
        available. Updates may be delayed due to communication issues or on-going scans. Typically,
        updates will happen within 24 hours.
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" (onclick)="afterUpdateModal.hide()">Close</itc-button>
    </modal-actions>
</sm-modal>
