import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Alert } from '../alert.model';
import { AlertStatusLookup } from '../constants';
import { NavService } from 'app/core/nav/nav.service';
import { TableSorting } from 'app/shared/sortable-table/sortable-table.component';

@Component({
    selector: 'sds-alerts-table',
    templateUrl: './alerts-table.component.html',
    styleUrls: ['./alerts-table.component.css'],
})
export class AlertsTableComponent implements OnInit {
    constructor(
        private navService: NavService,
        private route: ActivatedRoute,
        private router: Router
    ) {}

    @Input() alerts: Alert[];
    @Input() forSite: boolean = false;
    @Output() sortBy = new EventEmitter<string | number>();
    sortMethod: string | number;

    ngOnInit() {
        this.navService.saveReturnState('alerts');
        this.sortMethod = '-Created';
    }

    detail(guid: string) {
        this.router.navigate(['..', 'alerts', guid], { relativeTo: this.route });
    }

    onSorted(sorting: TableSorting) {
        let sortMethod = sorting.sortColumn;

        if (sorting.sortDirection == 'desc') sortMethod = '-' + sortMethod;

        this.sortMethod = sortMethod;
        this.sortBy.emit(this.sortMethod);
    }
}
