import { Component, Input } from '@angular/core';
import { ProgressStep } from './progress-tracker.model';

@Component({
    selector: 'progress-tracker-step',
    template: `
        <div
            class="progress-tracker__step"
            [ngClass]="{
                'progress-tracker__step-current': step.state === 'current',
                'progress-tracker__step-complete': step.state === 'complete',
                'progress-tracker__step-incomplete': step.state === 'incomplete',
                'progress-tracker__step-error': step.state === 'error'
            }">
            <div class="progress-tracker__step-icon">
                <fa-icon
                    [icon]="['fas', 'circle-' + (stepNumber + 1)]"
                    *ngIf="step.state === 'incomplete' || step.state === 'current'"></fa-icon>
                <fa-icon
                    [icon]="['fas', 'check-circle']"
                    *ngIf="step.state === 'complete'"></fa-icon>
                <fa-icon icon="exclamation-triangle" *ngIf="step.state === 'error'"></fa-icon>
            </div>
            <h3 class="progress-tracker__step-label">
                {{ step.label }}
            </h3>
        </div>
    `,
    styleUrls: [],
})
export class ItcProgressTrackerStepComponent {
    @Input() step: ProgressStep;
    @Input() stepNumber: number;
}
