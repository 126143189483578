<h2>All Employees Training Status and Results Printable Report</h2>

<h5>All Employees Training - Status</h5>
<div
    class="testHeaderParent"
    style="border-bottom: 2px solid #d5d5d5; padding-bottom: 12px; width: 100%">
    <div *ngFor="let statusData of allTrainingsInfo.StatusData; let i = index">
        <cm-unit-donut
            [status]="statusData.Status"
            [label]="statusData.Label"
            [numComplete]="statusData.numComplete"
            [percentComplete]="statusData.percentComplete"></cm-unit-donut>
    </div>
</div>

<h5>All Employees Training - Results Summary</h5>
<div calss="panel1Head" style="display: table; padding-left: 5px">
    <ng-container *ngFor="let ea of allTrainingsInfo.AllTrainingsData; let i = index">
        <div
            class="ui card"
            style="
                border-radius: 12px;
                cursor: pointer;
                padding: 24px;
                margin: 15px 15px 15px 0px;
                display: inline-block;
            ">
            <sds-tracker-donut-component
                [cardMeta]="ea.name"
                [grpNames]="ea.groupNames"
                [chartData]="ea.value"
                [centerText]="ea.completePercent"
                [cardId]="i"
                [centerTextSmall]="'Completed'"
                [legendPosition]="'right'"
                (cardClicked)="cardClicked($event)"></sds-tracker-donut-component>
        </div>
    </ng-container>
</div>
