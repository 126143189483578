import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

import { AuthService, Roles, SiteRoles } from 'app/core/auth';
import { Breadcrumb } from 'app/shared/breadcrumbs/breadcrumb.model';
import { BreadcrumbMappings } from './breadcrumb-mappings';
import { MessageService } from 'app/core/message.service';
//import { Site, SiteRoles, SiteService } from 'app/sites';
import { Site } from 'app/sites';

import { environment } from 'environments/environment';
import { RFT_URL } from '../constants';

export const ALL_SITES: string = '__all';

const SITE_DISABLED_STATES = [
    /\/(u\/)?(alert|todo)(\?(.*)|\/(.*))/,
    /assessments/,
    /confirm/,
    /error/,
    /settings/,
    /resources/,
    /indoc/,
];

@Injectable({ providedIn: 'root' })
export class NavService {
    constructor(
        private authService: AuthService,
        private messageService: MessageService,
        //private siteService: SiteService,
        private router: Router,
        private http: HttpClient
    ) {}
    rft_url = RFT_URL;
    private returnTo: string;
    private previousUrl: string;

    private headerTextSub: Subject<string> = new Subject<string>();
    private headerFlairSub: Subject<string> = new Subject<string>();

    getNavItems(site: Site): NavMenuItem[] {
        let user = this.authService.getIdentity();
        let roleId = parseInt(user.roleId, 10);

        let navItems: NavMenuItem[] = [];

        navItems.push({
            href: '/organizations',
            icon: 'custom sites',
            text: 'Organizations',
        });

        // navItems.push({
        //   href: '/sites',
        //   icon: 'custom sites',
        //   text: 'Sites'
        // });

        /* Removed in RFTDEV-20299 */
        // if ([Roles.Admin, Roles.Master, Roles.Restricted].indexOf(roleId) > -1) {
        //     navItems.push({
        //         href: '/todo',
        //         icon: '',
        //         text: 'To Do',
        //     });
        // }

        // if ([Roles.Admin, Roles.Master].indexOf(roleId) > -1) {
        //     navItems.push({
        //         href: '/settings',
        //         icon: 'setting',
        //         text: 'Admin',
        //     });
        // }

        return navItems;
    }

    //getSiteName(): Observable<string> {
    //  this.messageService.broadcast('NAV_GET_SITE');
    //  return this.messageService.on('NAV_SITE');

    //  /*this.messageService.on('NAV_SITE').subscribe((siteName: string) => {

    //  });
    //  let promise = this.messageService.on('NAV_SITE')
    //    .toPromise()
    //    .then((siteName: string) => {
    //      return siteName;
    //    });
    //  this.messageService.broadcast('NAV_GET_SITE');
    //  return promise;*/
    //}

    setSite(site: any) {
        this.messageService.broadcast('NAV_SET_SITE', site);
    }

    /*setSiteName(name: string) {
    this.messageService.broadcast('NAV_SET_SITE', name);
  }*/

    getSiteList(): Observable<Site[]> {
        this.messageService.broadcast('NAV_GET_SITE_LIST');
        return this.messageService.on('NAV_SITE_LIST');
    }

    getSiteEnabled() {
        for (let rgx of SITE_DISABLED_STATES) {
            let match = rgx.exec(this.router.url);
            if (match && match.length) return false;
        }
        return true;
    }

    /* Used to save state when redirecting users to login */
    saveReturnState(url: string) {
        this.returnTo = url;
    }

    loadReturnState(): string {
        let url = this.returnTo;
        return url;
    }

    /* Used to save a url to return to, mainly from an alert to inform or scanwizard */
    saveReturnUrl(url?: string) {
        this.previousUrl = typeof url !== 'undefined' ? url : this.router.url;
    }
    getReturnUrl(): string {
        return this.previousUrl;
    }
    clearReturnUrl() {
        this.previousUrl = '';
    }

    getHeaderTextObservable(): Observable<string> {
        return this.headerTextSub.asObservable();
    }

    setHeaderText(text: string) {
        // setTimeout to avoid ExpressionChangedAfterCheck errors
        setTimeout(() => {
            this.headerTextSub.next(text);
        }, 1);
    }

    getHeaderFlairObservable(): Observable<string> {
        return this.headerFlairSub.asObservable();
    }

    setHeaderFlair(flair: string) {
        this.headerFlairSub.next(flair);
    }

    getLatestWhatsNewDate(): Promise<any> {
        const url = `${this.rft_url}/wp-json/wp/v2/whatsNew?per_page=1&order=desc`;
        return this.http
            .get(url)
            .toPromise()
            .then((data) => data[0].date);
    }

    // getReleaseNotes(): Promise<any> {
    //   return new Promise((resolve, reject) => {
    //     this.http.get(`${this.rft_url}/data/portal-releasenotes.html`, { responseType: 'text' })
    //       .subscribe(
    //         (res) => { resolve(res) },
    //         (error) => { console.log('broked'); }
    //       );
    //   });
    // }
}

export class NavMenuItem {
    public href: string;
    public icon: string;
    public text: string;
}
