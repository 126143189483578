import { Component, OnDestroy, OnInit } from '@angular/core';
import * as saveAs from 'file-saver';

import { AuthService } from 'app/core/auth/auth.service';
import { InformMetadata } from 'app/sites/site/inform/informmeta.model';
import { InformOldService } from 'app/sites/site/inform/inform.service';
import { MessageService } from 'app/core/message.service';
import { UiService } from 'app/core/ui.service';

import { ASSESSMENT, PING_ASSESSMENT } from '../site-assessments.component';
import { Site } from 'app/sites/shared/site.model';
import { SITE, PING_SITE } from 'app/sites/shared/constants';
import { TableSorting } from 'app/shared/sortable-table/sortable-table.component';

@Component({
    selector: 'sds-forms-list',
    templateUrl: './forms-list.component.html',
    styleUrls: ['./forms-list.component.css'],
})
export class FormsListComponent implements OnInit, OnDestroy {
    constructor(
        private authService: AuthService,
        private informService: InformOldService,
        private messageService: MessageService,
        private uiService: UiService
    ) {}

    site: Site;
    selectedAssessment: any;

    forms: InformMetadata[];

    loadingComplete: boolean;
    aSub: any;
    sSub: any;

    isOnlySME: boolean;
    downloading: boolean;

    breadcrumbs = [
        { path: '../..', text: 'Compliance Manager' },
        { path: '..', text: 'Assessments' },
        { path: '.', text: 'Forms' },
    ];

    noAssessment = false;

    ngOnInit() {
        this.loadingComplete = false;

        this.aSub = this.messageService.on(ASSESSMENT).subscribe((assessment) => {
            this.selectedAssessment = assessment;

            if (this.site && this.selectedAssessment && !this.selectedAssessment.isEmpty) {
                this.noAssessment = false;
                this.getFormsList();
            } else if (
                this.site &&
                (this.selectedAssessment === null ||
                    (this.selectedAssessment && this.selectedAssessment.isEmpty))
            ) {
                // sme will return null if an assessment and no forms for them
                // isEmpty will be returned if there's no assessment
                this.noAssessment = true;
                this.loadingComplete = true;
            }
        });

        this.sSub = this.messageService.on(SITE).subscribe((site: Site) => {
            if (!site || site == this.site) {
                return;
            }

            this.site = site;
            this.uiService.setTitle('Forms', site.Name);
            this.messageService.broadcast(PING_ASSESSMENT);
            this.isOnlySME = this.authService.isOnlySME();
        });

        this.messageService.broadcast(PING_SITE);
    }

    getFormsList() {
        this.loadingComplete = false;
        this.informService
            .getInformsMetadata(this.site.Id, this.selectedAssessment.Name)
            .then((metas) => {
                this.forms = metas;

                for (let form of this.forms) {
                    form.Filename = form.Filename.replace('.idf4', '');
                    (form as any).link = window.btoa(form.Filename + '.idf4'); // the file extension must be included during encoding
                }

                this.loadingComplete = true;
            })
            .catch((err) => {
                this.loadingComplete = true;
                console.error(err);
            });
    }

    onSorted(sorting: TableSorting) {
        let sortedForms = this.forms.sort((a, b) => {
            if (sorting.sortColumn == 'LastModified')
                return a.LastModified < b.LastModified ? -1 : 1;
            else if (sorting.sortColumn)
                return a[sorting.sortColumn]
                    .toLowerCase()
                    .localeCompare(b[sorting.sortColumn].toLowerCase());
        });

        if (sorting.sortDirection == 'desc') this.forms = sortedForms.reverse();
        else this.forms = sortedForms;
    }

    downloadFormZip() {
        this.downloading = true;
        this.informService
            .downloadFormZip(this.site.Id, this.selectedAssessment.Name)
            .then((b64) => {
                this.downloading = false;

                let raw = window.atob(b64);
                let bytes = new Uint8Array(raw.length);
                for (let i = 0; i < raw.length; i++) {
                    bytes[i] = raw.charCodeAt(i);
                }
                let blob = new Blob([bytes]);

                saveAs(blob, this.site.Name + ' - Form Data.zip');
            })
            .catch((err) => {
                this.downloading = false;
                console.error(err);
            });
    }

    ngOnDestroy() {
        this.aSub.unsubscribe();
        this.sSub.unsubscribe();
    }
}
