import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CM_COLORS } from '../../cm-summary.constants';
import { RoEmit, RoWizardQuestion, WizardQuestion } from '../cm-wizard.model';
import { cloneDeep as _cloneDeep } from 'lodash-es';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'cm-wizard-related-controls',
    templateUrl: './cm-wizard-related-controls.component.html',
})
export class CmWizardRelatedcontrolsComponent {
    @Input() activeQuestion: WizardQuestion;
    @Input() culture: string;
    @Output() showReadOnly = new EventEmitter<RoEmit>();
    responseColor = { 'Yes, Fully': CM_COLORS.COVERED };
    CM_COLORS = CM_COLORS;
    roControl: RoWizardQuestion;

    constructor() {}

    onShowReadOnly(question) {
        console.log('emitting ', question);
        this.showReadOnly.emit({ q: question, t: 'control' });
    }
}
