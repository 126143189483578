import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class EulaService {
    private apiUrl: string;

    constructor(private http: HttpClient) {
        this.apiUrl = environment.apiUrl;
    }

    getEula(): Promise<any> {
        const url: string = this.apiUrl + 'Eula';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getEulaToAccept(): Promise<any> {
        const url: string = this.apiUrl + 'EulaToAccept';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    acceptEula(): Promise<any> {
        const url: string = this.apiUrl + 'AcceptEula';
        return this.http
            .post(url, {})
            .toPromise()
            .then((res) => res);
    }
}
