import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import * as saveAs from 'file-saver';

import { MessageService } from 'app/core/message.service';
import { ReportsService } from './reports.service';
import { Site } from 'app/sites/shared/site.model';
import { SITE, PING_SITE } from 'app/sites/shared/constants';
import { ASSESSMENT, PING_ASSESSMENT } from '../site-assessments.component';
import { TableSorting } from 'app/shared/sortable-table/sortable-table.component';
import { UiService } from 'app/core/ui.service';
import { environment } from 'environments/environment';

@Component({
    selector: 'sds-reports-list',
    templateUrl: './reports-list.component.html',
    styleUrls: ['./reports-list.component.css'],
})
export class ReportsListComponent implements OnInit, OnDestroy {
    constructor(
        private messageService: MessageService,
        private reportsService: ReportsService,
        private uiService: UiService
    ) {}

    @ViewChild('localeSelect') localeSelect: any;

    site: Site;
    reports: any;
    reportLocales: string[];
    reportLocaleCtrl: UntypedFormControl = new UntypedFormControl();
    selectedAssessment: any;
    selectedLocale: string;
    reportList: any[];

    loadingLocales: boolean;
    loadingZip: boolean;

    loadingComplete: boolean;
    sSub: any;
    aSub: any;

    languageMap: any = {
        'nl-NL': 'Dutch',
        'en-US': 'English (US)',
        'fr-FR': 'French',
        'fr-CA': 'French (Canada)',
        'de-DE': 'German',
        'it-IT': 'Italian',
        'pt-BR': 'Portuguese (Brasil)',
        'es-ES': 'Spanish',
    };

    breadcrumbs = [
        { path: '../..', text: 'Compliance Manager' },
        { path: '..', text: 'Assessments' },
        { path: '.', text: 'Reports' },
    ];

    noAssessment = false;

    ngOnInit() {
        this.aSub = this.messageService.on(ASSESSMENT).subscribe((assessment) => {
            if (assessment) {
                this.loadingComplete = false;

                if (assessment != this.selectedAssessment) {
                    this.selectedAssessment = assessment;

                    if (this.site && this.selectedAssessment && !this.selectedAssessment.isEmpty) {
                        this.getReportsList();
                        this.noAssessment = false;
                    } else if (
                        this.site &&
                        this.selectedAssessment &&
                        this.selectedAssessment.isEmpty
                    ) {
                        this.noAssessment = true;
                        this.loadingComplete = true;
                    }
                } else this.loadingComplete = true;
            }
        });

        this.sSub = this.messageService.on(SITE).subscribe((site: Site) => {
            if (site != this.site) {
                this.site = site;
                this.uiService.setTitle('Reports', site.Name);
                this.messageService.broadcast(PING_ASSESSMENT);
            }
        });

        this.reportLocaleCtrl.valueChanges.subscribe((locale) => {
            if (locale) {
                this.selectedLocale = locale;
                this.reportList = this.reports[locale];
            }
        });

        this.messageService.broadcast(PING_SITE);
    }

    getReportsList() {
        if (this.selectedAssessment && this.site) {
            if (!this.selectedAssessment.Name) {
                this.loadingComplete = true;
            } else {
                this.reportsService
                    .getReportsList(this.site.Id, this.selectedAssessment.Name)
                    .then((reports) => {
                        if (reports) {
                            this.reports = reports;
                            this.reportLocales = Object.keys(this.reports).sort(
                                (a: string, b: string) => {
                                    if (a == 'en-US') return -1;
                                    else if (b == 'en-US') return 1;
                                    else return 0;
                                }
                            );

                            this.selectedLocale = this.reportLocales[0];
                            this.reportLocaleCtrl.setValue(this.selectedLocale);
                            //this.localeSelect.setSelected(this.selectedLocale);
                        }

                        this.loadingComplete = true;
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }
        }
    }

    onSorted(sorting: TableSorting) {
        let sortedReports = this.reportList.sort((a, b) => {
            if (sorting.sortColumn == 'lastmodified')
                return a.lastmodified < b.lastmodified ? -1 : 1;
            else if (sorting.sortColumn)
                return a[sorting.sortColumn]
                    .toLowerCase()
                    .localeCompare(b[sorting.sortColumn].toLowerCase());
        });

        if (sorting.sortDirection == 'desc') this.reportList = sortedReports.reverse();
        else this.reportList = sortedReports;
    }

    ngOnDestroy() {
        this.aSub.unsubscribe();
        this.sSub.unsubscribe();
    }

    downloadReport(report: any) {
        report.loading = true;
        this.reportsService
            .downloadReport(
                this.site.Id,
                report.filename,
                this.selectedAssessment.Name,
                this.selectedLocale
            )
            .then((b64) => {
                report.loading = false;

                let raw = window.atob(b64);
                let bytes = new Uint8Array(raw.length);
                for (let i = 0; i < raw.length; i++) {
                    bytes[i] = raw.charCodeAt(i);
                }
                let blob = new Blob([bytes]);

                saveAs(blob, report.filename);
            })
            .catch((err) => {
                report.loading = false;
                console.error(err);
            });
    }

    downloadReportZip() {
        this.loadingZip = true;

        this.reportsService
            .downloadReportsZip(this.site.Id, this.selectedAssessment.Name, this.selectedLocale)
            .then((b64) => {
                this.loadingZip = false;

                let raw = window.atob(b64);
                let bytes = new Uint8Array(raw.length);
                for (let i = 0; i < raw.length; i++) {
                    bytes[i] = raw.charCodeAt(i);
                }
                let blob = new Blob([bytes]);

                saveAs(blob, this.site.Name + ' - Reports.zip');
            })
            .catch((err) => {
                this.loadingZip = false;

                console.error(err);
            });
    }

    getDownloadUrl(report: any) {
        return (
            environment.apiUrl +
            `Sites/${this.site.Id}/Reports/Download?reportName=${report.filename}`
        );
    }
}
