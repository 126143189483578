import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
    EventEmitter,
    Output,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Video } from '../video-tutorials.model';

@Component({
    selector: 'itc-video-player',
    templateUrl: './video-player.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoPlayerComponent implements OnInit {
    constructor(private dom: DomSanitizer) {}
    @Input() set video(val: Video) {
        if (val && Number.isInteger(val.id)) {
            this._video = val;
            this.videoSource = this.dom.bypassSecurityTrustResourceUrl(
                'https://player.vimeo.com/video/' + val.id
            );
        } else {
            console.log('is the video id a number?');
        }
    }
    get video(): Video {
        return this._video;
    }
    @Output() playerClosed: EventEmitter<boolean> = new EventEmitter();
    videoSource: SafeResourceUrl;
    _video: Video;

    ngOnInit(): void {}

    openInVimeo(vid): void {
        window.open('https://vimeo.com/' + vid);
    }
}
