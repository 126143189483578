import { APP_INITIALIZER } from '@angular/core';
import { Router, UrlSerializer } from '@angular/router';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgModule } from '@angular/core';

import { CoreModule } from './core/core.module';
import { SitesModule } from './sites/sites.module';
import { ToastModule } from './core/toast/toast.module';

import { SharedModule } from './shared/shared.module';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from 'app/core/auth';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { AppConfig } from './app.config';
import { AnnouncementService } from './core/announcements/announcement.service';
import { HomeComponent } from './home/home.component';
import { VendportalModule } from './vendportal/vendportal.module';
import { LauncherComponent } from './launcher/launcher.component';

import { DeactivateGuardService } from './deactivate-guard.service';
import { DownloadComponent } from './download/download.component';
import { CMDownloadComponent } from './cm-download/cm-download.component';
import { LogoutComponent } from './logout/logout.component';
import { RouteTestComponent } from './route-test/route-test.component';
import { PrintModule } from './print/printmodule.module';

import { PwdResetComponent } from './pwd-reset/pwd-reset.component';

//MS OAUTH imports
import {
    PublicClientApplication,
    InteractionType,
    IPublicClientApplication,
} from '@azure/msal-browser';
import {
    MSAL_GUARD_CONFIG,
    MSAL_INSTANCE,
    MSAL_INTERCEPTOR_CONFIG,
    MsalBroadcastService,
    MsalGuard,
    MsalGuardConfiguration,
    MsalInterceptor,
    MsalInterceptorConfiguration,
    MsalModule,
    MsalRedirectComponent,
    MsalService,
} from '@azure/msal-angular';
import { MsalOauthComponent } from './msal-oauth/msal-oauth.component';
import { CustomUrlSerializer } from './shared/customUrlSerializer';
import { PasswordFormComponent } from './shared/password-form/password-form.component';
let host = window.location.host;
if (host != 'localhost:4200') {
    var domain = (host.match(/([^.]+)\.\w{2,3}(?:\.\w{2})?$/) || [])[0];
    host = 'www.' + domain;
}
const msalScopes = [
    'User.Read',
    'User.Read.All',
    'User.ReadBasic.All',
    'Group.Read.All',
    'Directory.Read.All',
    'AuditLog.Read.All',
    'IdentityProvider.Read.All',
    'Reports.Read.All',
    'SecurityEvents.Read.All',
    'Sites.Read.All',
    'Notes.Read.All',
];
const redirectUri = window.location.protocol + '//' + host + '/oauth';
//MS OUATH imports end

const initConfig = (config: AppConfig, announcementService: AnnouncementService) => () => {
    return new Promise((resolve, _) => {
        config.initialize().then(() => {
            announcementService.checkLatestAnnouncement().then((needUpdate) => {
                if (needUpdate) {
                    announcementService.updateAnnouncements().then(() => {
                        resolve(true);
                    });
                } else resolve(true);
            });
            resolve(true);
        });
    });
};

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
        auth: {
            clientId: '9c14be53-1a73-409a-bd0c-5522a3f8cb85',
            authority: 'https://login.microsoftonline.com/common',
            redirectUri: redirectUri,
            postLogoutRedirectUri: '/',
        },
        cache: null,
    });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', msalScopes);

    return {
        interactionType: InteractionType.Popup,
        protectedResourceMap,
    };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Popup,
        authRequest: {
            scopes: msalScopes,
        },
    };
}

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        HomeComponent,
        DownloadComponent,
        CMDownloadComponent,
        LogoutComponent,
        RouteTestComponent,
        LauncherComponent,
        MsalOauthComponent,
        PwdResetComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        SitesModule,
        //ServiceWorkerModule.register('/ngsw-worker.js'),
        AppRoutingModule,
        CoreModule,
        ToastModule,
        SharedModule,
        PrintModule,
        VendportalModule,
        //MS OAUTH
        MsalModule,
        PasswordFormComponent,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        AppConfig,
        {
            provide: APP_INITIALIZER,
            useFactory: initConfig,
            deps: [AppConfig, AnnouncementService],
            multi: true,
        },
        DeactivateGuardService,
        //MS OAUTH
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true,
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory,
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory,
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory,
        },
        { provide: UrlSerializer, useClass: CustomUrlSerializer },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
    ],
    bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
