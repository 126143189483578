import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

type EmptyStateIcon =
    | 'code'
    | 'success'
    | 'connection'
    | 'inbox'
    | 'error'
    | 'payment'
    | 'documents'
    | 'location'
    | 'image'
    | 'items'
    | 'message'
    | 'results'
    | 'task'
    | 'shield'
    | 'sync'
    | 'no-data'
    | 'widget-access-denied'
    | 'widget-all-zero'
    | 'widget-data-error'
    | 'widget-generic-error'
    | 'widget-timed-out'
    | 'widget-too-many'
    | 'widget-unavailable';

@Component({
    selector: 'empty-state',
    standalone: true,
    imports: [CommonModule],
    template: `
        <div class="empty-state {{ class }}" [ngStyle]="style">
            <div
                class="empty-state-icon"
                style.backgroundImage="url(/assets/img/empty-state/{{ icon }}.svg"></div>
            <p class="empty-state-title">{{ title }}</p>
            <p class="empty-state-text" [innerHtml]="innerHtmlText" *ngIf="innerHtmlText">
                {{ text }}
            </p>
            <p class="empty-state-text" *ngIf="!innerHtmlText">
                {{ text }}
            </p>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyStateComponent {
    @Input() icon: EmptyStateIcon;
    @Input() title: string;
    @Input() text: string;
    @Input() class: string;
    @Input() style: { [key: string]: string };
    @Input() innerHtmlText: string;

    constructor() {}
}
