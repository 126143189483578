import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { SharedModule } from 'app/shared/shared.module';
import { ToastModule } from './toast/toast.module';

import { AuthGuard, AuthHttp } from './auth';

import { Hotkeys } from './hotkeys/hotkeys.service';

import { AnnouncementsComponent } from './announcements/announcements.component';
import { EulaComponent } from './eula/eula.component';
import { NavComponent } from './nav/nav.component';
import { LayoutComponent } from './layout/layout.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { ErrorComponent } from './error/error.component';
import { PreferencesComponent } from './preferences/preferences.component';
import { MfaEnableComponent } from './preferences/mfa-enable/mfa-enable.component';
import { MfaDisableComponent } from './preferences/mfa-disable/mfa-disable.component';
import { ResourcesComponent } from './resources/resources.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { VideoTutorialsComponent } from './video-tutorials/video-tutorials.component';
import { VideoCardComponent } from './video-tutorials/video-card/video-card.component';
import { VideoMenuComponent } from './video-tutorials/video-menu/video-menu.component';
import { VideoPlayerComponent } from './video-tutorials/video-player/video-player.component';
import { DeactivateGuardService } from 'app/deactivate-guard.service';
import { NgOptimizedImage } from '@angular/common';
import { PasswordStrengthMeterComponent } from 'app/shared/password-form/password-strength-meter/password-strength-meter.component';
import { PasswordFormComponent } from 'app/shared/password-form/password-form.component';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        RouterModule,
        SharedModule,
        ToastModule,
        NgOptimizedImage,
        PasswordStrengthMeterComponent,
        PasswordFormComponent,
    ],
    exports: [NavComponent, MfaEnableComponent, NgOptimizedImage],
    providers: [AuthGuard, AuthHttp, DeactivateGuardService, Hotkeys],
    declarations: [
        NavComponent,
        LayoutComponent,
        ConfirmComponent,
        ErrorComponent,
        PreferencesComponent,
        MfaEnableComponent,
        MfaDisableComponent,
        ResourcesComponent,
        AnnouncementsComponent,
        EulaComponent,
        ForbiddenComponent,
        VideoTutorialsComponent,
        VideoCardComponent,
        VideoMenuComponent,
        VideoPlayerComponent,
    ],
})
export class CoreModule {
    constructor() {
        console.log('Core Module');
    }
}
