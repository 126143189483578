import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

interface TabInstructions {
    action: 'set' | 'disable' | 'enable' | 'hide';
    tabIndex: number | string;
}

@Injectable()
export class ItcTabsService {
    tabAsObservable: Observable<TabInstructions>;
    private tabSubject: Subject<TabInstructions> = new Subject<TabInstructions>();

    constructor() {
        this.tabAsObservable = this.tabSubject.asObservable();
    }

    setTab(tabNo) {
        let tabInstructions: TabInstructions = {
            action: 'set',
            tabIndex: typeof tabNo === 'number' ? tabNo - 1 : tabNo,
        };
        this.tabSubject.next(tabInstructions);
    }
    disableTab(tabNo) {
        let tabInstructions: TabInstructions = { action: 'disable', tabIndex: tabNo - 1 };
        this.tabSubject.next(tabInstructions);
    }
    enableTab(tabNo) {
        let tabInstructions: TabInstructions = { action: 'enable', tabIndex: tabNo - 1 };
        this.tabSubject.next(tabInstructions);
    }
    hideTab(tabNo) {
        let tabInstructions: TabInstructions = { action: 'hide', tabIndex: tabNo - 1 };
        this.tabSubject.next(tabInstructions);
    }
}
