<div class="ui modal readOnlyWizard fs coupled" #readOnlyModal>
    <i class="close icon"></i>
    <div class="ui header">
        <p class="modalTitle">Response</p>
    </div>
    <div class="content scrolling">
        <div id="ModalwizardView">
            <div class="wizardContent" style="width: 100%">
                <div style="flex: 1 0 67% !important">
                    <div class="wizardText">
                        <div
                            class="preWhiteSpace"
                            [innerHTML]="roQuestion?.QuestionDescriptionText"></div>
                    </div>
                </div>

                <div
                    class="wizardResourcesContainer"
                    style="overflow: hidden; flex: 1 0 450px !important">
                    <div class="wizardResources" style="overflow: auto; height: 100%">
                        <div style="display: flex; justify-content: space-between">
                            <p>Resources</p>
                            <itc-button
                                type="tertiary"
                                size="small"
                                [icon]="allResourcesExpanded ? 'fa-compress' : 'fa-expand'"
                                (onclick)="toggleAllResources()">
                                {{ allResourcesExpanded ? 'Collapse All' : 'Expand All' }}
                            </itc-button>
                        </div>

                        <section [class.open]="relatedTechIssuesOpen" *ngIf="type === 'control'">
                            <header (click)="toggleResource('relatedTechIssuesOpen')">
                                <fa-icon
                                    [icon]="
                                        relatedTechIssuesOpen ? 'chevron-up' : 'chevron-down'
                                    "></fa-icon>
                                Related Technical Issues
                                <span class="resourceCount">
                                    {{ roQuestion?.RelatedIssues?.length }}
                                </span>
                            </header>
                            <div [hidden]="!relatedTechIssuesOpen">
                                <span
                                    style="color: #aaa"
                                    *ngIf="
                                        !roQuestion?.RelatedIssues ||
                                        !roQuestion?.RelatedIssues?.length
                                    ">
                                    No technical issues.
                                </span>
                                <ul
                                    class="riskIssues"
                                    *ngIf="
                                        roQuestion?.RelatedIssues &&
                                        roQuestion?.RelatedIssues?.length
                                    ">
                                    <li class="riskIssuesTitle">
                                        <div>Issue</div>
                                        <div>Risk Score</div>
                                    </li>
                                    <li
                                        *ngFor="let item of roQuestion?.RelatedIssues"
                                        (click)="showRiskModal(item)"
                                        [class]="'risk_' + calculateDisplaySeverity(item.Score)"
                                        style="cursor: pointer">
                                        <div [title]="item.Title">{{ item.Title }}</div>
                                        <div>{{ item.Score }}</div>
                                    </li>
                                </ul>
                            </div>
                        </section>

                        <section [class.open]="commentsOpen">
                            <header (click)="toggleResource('commentsOpen')">
                                <fa-icon
                                    [icon]="commentsOpen ? 'chevron-up' : 'chevron-down'"></fa-icon>
                                Comments
                            </header>
                            <div [hidden]="!commentsOpen">
                                <span style="color: #aaa" *ngIf="!roQuestion?.Comment">
                                    No comments.
                                </span>
                                <textarea 
                                          *ngIf="roQuestion?.Comment"
                                          [readonly]="true"
                                          class="wizardCommentTextArea"
                                          autoResize>{{ roQuestion?.Comment }}
                                </textarea>
                            </div>
                        </section>

                        <section [class.open]="filesOpen" class="resourceFiles">
                            <header (click)="toggleResource('filesOpen')">
                                <fa-icon
                                    [icon]="filesOpen ? 'chevron-up' : 'chevron-down'"></fa-icon>
                                Files
                                <span class="resourceCount">
                                    {{ roQuestion?.Attachments?.length }}
                                </span>
                            </header>
                            <div style="position: relative; height: 100%" [hidden]="!filesOpen">
                                <span style="color: #aaa" *ngIf="!roQuestion?.Attachments?.length">
                                    No files.
                                </span>
                                <div
                                    class="fileListContainer"
                                    *ngIf="roQuestion?.Attachments?.length > 0">
                                    <ul id="attachments">
                                        <li *ngFor="let attachment of roQuestion.Attachments">
                                            <div
                                                *ngIf="attachment?.Id.startsWith('https')"
                                                class="attachmentFileName">
                                                <span
                                                    style="
                                                        font-size: 14px;
                                                        display: flex;
                                                        flex-direction: column;
                                                    ">
                                                    <a
                                                        [href]="attachment?.Id"
                                                        target="_blank"
                                                        style="
                                                            cursor: pointer;
                                                            display: inline-block;
                                                        "
                                                        [title]="attachment?.Id">
                                                        <i
                                                            class="itgIcon icon-itglue"
                                                            style="
                                                                font-size: 14px;
                                                                color: #666;
                                                            "></i>
                                                        {{ attachment?.Name }}
                                                    </a>
                                                </span>
                                                <span>
                                                    Uploaded on
                                                    {{ attachment?.LastModified | date : 'short' }}
                                                    by
                                                    {{ attachment?.UploadedBy }}
                                                </span>
                                            </div>

                                            <div *ngIf="!attachment?.Id.startsWith('https')">
                                                <a
                                                    (click)="downloadAttachment(attachment)"
                                                    class="attachmentFileName"
                                                    style="
                                                        cursor: pointer;
                                                        max-width: 75%;
                                                        overflow: hidden;
                                                        text-overflow: ellipsis;
                                                        white-space: nowrap;
                                                    "
                                                    [attr.title]="attachment?.Name || null"
                                                    [class.disabled]="exportLoading">
                                                    {{ attachment?.Name }}
                                                </a>
                                                <span>
                                                    Uploaded on
                                                    {{ attachment?.LastModified | date : 'short' }}
                                                    by
                                                    {{ attachment?.UploadedBy }}
                                                </span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <!-- <div class="wizard-assignments" *ngIf="options?.allowAssignments"> -->
            <div
                class="wizard-assignments"
                style="flex-direction: row; justify-content: flex-start">
                <div>
                    <label for="wizardAssignTo">Assigned To:</label>
                    <div
                        class="ui selection dropdown upward"
                        id="ROassignToDropUp"
                        style="display: inline-block"
                        tabindex="-1">
                        <i class="dropdown icon"></i>
                        <div class="text">View assigned users</div>
                        <div class="menu transition hidden" style="display: block; width: auto">
                            <a
                                class="item"
                                style="white-space: nowrap"
                                *ngFor="let user of siteUsers">
                                <itc-checkbox
                                    [label]="user.DisplayName"
                                    [checked]="type === 'control' ? assignedUsers.includes(user.DisplayName) :  roQuestion.AssignedTo?.includes(user.DisplayName)"
                                    [disabled]="true"></itc-checkbox>
                            </a>
                            <a class="divider" style="display: block" *ngIf="siteGroups.length"></a>
                            <a
                                class="item"
                                style="white-space: nowrap"
                                *ngFor="let group of siteGroups; let i = index">
                                <itc-checkbox
                                    [label]="group.Name"
                                    [checked]="checkGroup(group)" 
                                    [disabled]="true"></itc-checkbox>
                            </a>
                        </div>
                    </div>
                </div>
                <div style="margin-left: 16px; display: inline-block">
                    <label for="wizardAssignBy">Assigned By:</label>
                    <input
                        type="text"
                        [value]="
                            type === 'control'
                                ? roQuestion?.Assignments[0]?.AssignedByDisplayName || ''
                                : roQuestion?.AssignedBy
                        "
                        id="wizardAssignBy"
                        style="margin-bottom: 0; width: 325px"
                        readonly />
                </div>
            </div>
            <!-- <div class="wizard-answers {{ options?.assessment }}" *ngIf="controlWizardAnswers && roQuestion" style="margin-bottom: 0;"> -->
            <div
                class="wizard-answers {{ options?.assessment }}"
                *ngIf="roQuestion"
                style="margin-bottom: 0">
                <ul style="margin-bottom: 0">
                    <li *ngFor="let a of answers; let i = index">
                        <input
                            type="radio"
                            name="roControlResponse"
                            [value]="a.dbval ? a.dbval : a.title"
                            [attr.disabled]="roQuestion?.Response != a.title ? true : null"
                            [checked]="roQuestion?.Response == a.title ? true : null" />
                        <label>
                            <span class="itcRadio"></span>
                            <span style="flex: 1">{{ a.title }}</span>
                            <span *ngIf="roQuestion?.RBAResponse === a.title">
                                &nbsp;(baseline)
                            </span>
                            <i
                                class="answerSquare icon"
                                [style.backgroundColor]="
                                    roQuestion?.Response == a.title ? a.color : a.colorMuted
                                "></i>
                        </label>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="actions">
        <itc-button type="secondary" class="cancel">Close</itc-button>
    </div>
</div>

<!-- Risk Info Modal -->
<div class="ui modal {{ riskModalInfo?.RiskDisplay }}Risk riskModal coupled" #riskModal>
    <i class="close icon"></i>
    <div class="ui header">
        <p class="modalTitle">
            {{ riskModalInfo?.RiskDisplay }} Risk &nbsp;&nbsp; | &nbsp;&nbsp;
            {{ riskModalInfo?.Title }}
        </p>
    </div>
    <div class="content scrolling">
        <div class="riskModalContentContainer" *ngIf="riskModalInfo">
            <div>
                <p class="riskSectionTitle">Risk Score</p>
                {{ riskModalInfo?.Score }}
            </div>

            <div style="flex: 1; text-align: left">
                <p class="riskSectionTitle">Recommendations</p>
                {{ riskModalInfo?.Recommendation }}
                <ul class="riskModalIssueData">
                    <li
                        *ngFor="let issue of riskModalInfo?.IssueData"
                        [class.mitigated]="issue.IsMitigated">
                        {{ issue.InfoString }}
                    </li>
                </ul>
            </div>

            <div>
                <p class="riskSectionTitle">Severity</p>
                <img
                    src="/assets/img/risk_{{ riskModalInfo?.SeverityDisplay }}.svg"
                    style="width: 50px"
                    alt="{{ riskModalInfo?.Severity }}"
                    title="{{ riskModalInfo?.Severity }}" />
            </div>

            <div>
                <p class="riskSectionTitle">Probability</p>
                <img
                    src="/assets/img/risk_{{ riskModalInfo?.ProbabilityDisplay }}.svg"
                    style="width: 50px"
                    alt="{{ riskModalInfo?.Probability }}"
                    title="{{ riskModalInfo?.Probability }}" />
            </div>
        </div>
    </div>
    <div class="actions">
        <itc-button type="secondary" class="cancel" [class.backToModal]="reOpenPrevModal">
            Close
        </itc-button>
    </div>
</div>

<div class="ui modal xs coupled" #externalContentModal>
    <i class="close icon"></i>
    <div class="ui header">
        <p class="modalTitle">Access External Content</p>
    </div>
    <div class="content">
        <p>
            The reference URL you have selected will direct you to access content that is published
            on an external website. Please confirm your access to this content
        </p>
    </div>
    <div class="actions">
        <itc-button type="secondary" class="cancel backToModal">Cancel</itc-button>
        <itc-button type="primary" class="cancel backToModal" (onclick)="navigateTo()">
            OK
        </itc-button>
    </div>
</div>
