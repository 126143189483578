import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Video } from '../video-tutorials.model';
import { RFT_URL } from 'app/core/constants';
@Component({
    selector: 'itc-video-card',
    template: `
        <div class="videoCardContainer">
            <img [ngSrc]="videoSource" width="400" height="226" />
            <div class="videoCardContent">
                <span
                    class="itc pill badge small"
                    [style.background-color]="productColors[video.product]">
                    {{ video.product }}
                </span>
                <h5 [title]="video.title">{{ video.title }}</h5>
                <p class="caption" [title]="video.caption">{{ video.caption }}</p>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoCardComponent {
    @Input() set video(val: Video) {
        // make sure id is a val just incase it gets screwed up.
        if (val && Number.isInteger(val.id)) {
            this.videoSource = this.imagePath + val.id + '.png';
            this._video = val;
        }
    }
    get video() {
        return this._video;
    }

    _video: Video;
    imagePath = `${RFT_URL}/data/resource-images/video-thumbnails/`;
    videoSource: string;
    // product colors used for pills.
    productColors = {
        'Compliance Manager GRC': '#0288d1',
        'Compliance Manager': '#09537c',
        'Cyber Hawk': '#cf2e2e',
        'VulScan': '#5dbba7',
        'Network Detective Pro': '#f48121',
    };
}
