import { Component, Input } from '@angular/core';

@Component({
    selector: 'task-checkbox',
    template: `
	    <li>
		    <div>
                <span [class.complete]='isComplete' class='step'>
                    <fa-icon *ngIf='isComplete; else notComplete' icon='check'></fa-icon>
                    <ng-template #notComplete>
                        <fa-icon [icon]="['fas', 'circle']"></fa-icon>
                    </ng-template>
                </span>
			    <ng-content></ng-content>
		    </div>
	    </li>
    `,
})
export class TaskCheckboxComponent {
    @Input() isComplete: boolean;
}
