<div class="ui card riskScoreItem">
    <div class="content">
        <div class="header">
            RISK SCORE
            <a href="#" class="settingsIcon"><i class="setting icon"></i></a>
        </div>
    </div>
    <div class="content">
        <wj-radial-gauge
            #flexgauge
            [(value)]="_rs"
            [isReadOnly]="true"
            [min]="0"
            [max]="100"
            [step]="1"
            [hasShadow]="true"
            [isAnimated]="false"
            [autoScale]="false"
            [startAngle]="90"
            [sweepAngle]="360"
            [thickness]="0.3"
            [style.fill]="GetGaugeColor(_rs)"
            (valueChanged)="gaugeChanged()"></wj-radial-gauge>
    </div>
</div>
