<div class="wfi-container smaller-container">
    <!-- #region BullPhish training Gloabal Setting -->
    <h3>Turn On BullPhish ID Training Content</h3>
    <itc-toggle
        [control]="bullPhishEnableCtrl"
        (onChanged)="bullPhishValueChanged()"
        label="Enable BullPhish ID Integration"
        labelPosition="after"
        [disabled]="disableAll"></itc-toggle>
    <div class="integration-desc integration-indent">
        <p>
            This setting globally enables/disables the BullPhish ID training content in Compliance
            Manager GRC. The integration must also be enabled per site. You must be an active
            BullPhish ID subscriber to enable this integration.
            <ng-container *ngIf="this.isProvisioned">
                (Note: BullPhish licensing limits apply.)
            </ng-container>
            <ng-container *ngIf="!this.isProvisioned">
                <a
                    [class.disabledLink]="!disableAll || disableEmail"
                    (click)="sendEmailBullPhishClicked()">
                    Click here to enable.
                </a>
                (Note: BullPhish licensing limits apply. There will be a 24-48 hr delay before your
                subscription is validated and the integration is provisioned.)
            </ng-container>
        </p>
        <itc-banner
            *ngIf="showBullPhishBanner"
            type="success"
            title="Request Sent"
            [closable]="true"
            (onClose)="onBannerBullPhishClose()"
            style="margin-bottom: 8px">
            A request to provision the BullPhish ID integration has been sent. Please allow 24 hours
            to complete provisioning. If you have any questions, please contact your Account
            Manager.
        </itc-banner>
        <p>
            If you do not have a BullPhish ID subscription,
            <a
                href="https://www.compliancemanagergrc.com/in-product/bpid-integration/"
                target="_blank">
                click here
            </a>
            to learn more.
        </p>
    </div>
</div>
