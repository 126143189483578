import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'primeNGDateFormat',
})
export class PrimeNGDateFormattingPipe implements PipeTransform {
    transform(value: unknown, ...args: unknown[]): unknown {
        /* PrimeNG uses a different date format just to be confusing, so convert .net to their version. */
        let pngDateFormat = '';
        switch (value) {
            case 'dd-MMM-yyyy':
                pngDateFormat = 'dd-M-yy';
                break;
            case 'yyyy/MM/dd':
                pngDateFormat = 'yy/mm/dd';
                break;
            case 'dd/MM/yyyy':
                pngDateFormat = 'dd/mm/yy';
                break;
            case 'MM/dd/yyyy':
            default:
                pngDateFormat = 'mm/dd/yy';
                break;
        }
        return pngDateFormat;
    }
}
