import {
    Directive,
    Input,
    ElementRef,
    HostListener,
    Renderer2,
    Output,
    EventEmitter,
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { assignIn as _assignIn } from 'lodash-es';

@Directive({
    selector: '[tooltip]',
    standalone: true,
})
export class ItcTooltipDirective {
    /** Text to display in tooltip */
    @Input('tooltip') tooltipText: string;
    /** Title to display in tooltip */
    @Input() ttTitle: string;
    /**
     * Tooltip Link
     *
     * @param TTLink {{ href: string, title: string }}
     */
    @Input() set ttLink(link: object | string) {
        this.isLinkInputted = !!link;
        if (typeof link === 'string') {
            this._ttLink.href = link;
        } else {
            if (Array.isArray(link)) {
                this._ttLinks = [];
                link.forEach((item) => {
                    let l = {
                        href: '',
                        text: 'Learn More',
                        newTab: true,
                        returnValue: null,
                    };
                    l = _assignIn(l, item);
                    this._ttLinks.push(l);
                });
            } else {
                this._ttLink = _assignIn(this._ttLink, link);
            }
        }
    }

    /** How to trigger the tooltip */
    @Input() ttTrigger: 'hover' | 'click' | 'focus' = 'hover';
    /** Whether to display tooltip or not */
    @Input() set ttHidden(val: boolean) {
        this._hidden = val;
        // this logic is for when the "ttHidden" variable may change while it's already displayed (app launcher)
        if (val && this.tooltip) {
            this.hide();
        }
    }
    get ttHidden() {
        return this._hidden;
    }
    /** Location of tooltip, relative to anchor */
    @Input() set ttPosition(
        value:
            | 'left'
            | 'top-left'
            | 'top'
            | 'top-right'
            | 'right'
            | 'bottom-right'
            | 'bottom'
            | 'bottom-left'
    ) {
        this._position = value.replace(' ', '-');
    }
    /** How far to offset the pointer from the host */
    @Input() set ttOffset(offset: number) {
        offset = typeof offset === 'string' ? parseInt(offset, 10) : offset;
        this._offset = offset;
    }
    /** How long to delay before showing tooltip*/
    @Input() set ttDelay(delay: number) {
        delay = typeof delay === 'string' ? parseInt(delay, 10) : delay;
        this._delay = delay;
    }

    @Input() ttLinksDelimiter: string = '&nbsp;&#9679;&nbsp;';

    @Output() ttLinkClick = new EventEmitter<void>();
    @Output() onLinkClick = new EventEmitter<void>();

    @Output() ttLinkItemClick = new EventEmitter<any>();
    @Output() onLinkItemClick = new EventEmitter<any>();

    _position = 'top';
    _offset = 4;
    // _delay = 25;
    _delay = 0;
    tooltip: HTMLElement;
    _hidden = false;
    _ttLink: ILink = {
        href: '',
        text: 'Learn More',
        newTab: true,
    };
    _ttLinks: ILinkExt[] = [];

    isLinkInputted = false;
    prevText: string;

    constructor(public el: ElementRef, private renderer: Renderer2, private router: Router) {
        /* This is needed to clear the tooltip when navigating, mainly found on org folders */
        router.events
            .pipe(filter((event) => event instanceof NavigationStart))
            .subscribe((event) => {
                if (this.tooltip) {
                    this.hide();
                }
            });
    }

    @HostListener('mouseenter') onMouseEnter() {
        if (this.ttTrigger !== 'hover') {
            return;
        }

        // add hover to element when it's hovered over
        if (!this.tooltip && !this._hidden) {
            this.show();
        }
    }

    @HostListener('mouseleave') onMouseLeave() {
        if (this.ttTrigger !== 'hover') {
            return;
        }

        if (this.tooltip) {
            this.hide();
        }
    }

    @HostListener('click') onClick() {
        if (this.ttTrigger !== 'click') {
            return;
        }

        if (this.tooltip) {
            this.hide();
        }

        if (!this.tooltip && !this._hidden) {
            this.show();
        }
    }

    @HostListener('focus') onFocus() {
        if (this.ttTrigger !== 'focus') {
            return;
        }

        if (!this.tooltip && !this._hidden) {
            this.show();
        }
    }

    @HostListener('blur') onBlur() {
        if (this.ttTrigger !== 'focus') {
            return;
        }

        if (this.tooltip) {
            this.hide();
        }
    }

    show() {
        if (!this.tooltipText || this.tooltipText === '' || this.tooltipText === null) {
            return;
        }

        this.create();
        this.setPosition();
        this.renderer.addClass(this.tooltip, 'kaseya-ui-tooltip--active');
        this.el.nativeElement.classList.add('kaseya-ui-tooltip--hover');
    }

    hide() {
        // don't hide if tooltip or element is being hovered over (and has a link)
        if (
            this.isLinkInputted &&
            (!this.tooltip ||
                this.el.nativeElement.matches(':hover') ||
                this.tooltip.matches(':hover'))
        ) {
            return;
        }
        if (this.tooltip) {
            this.el.nativeElement.classList.remove('kaseya-ui-tooltip--hover');
            this.renderer.removeClass(this.tooltip, 'kaseya-ui-tooltip--active');
            if (this.prevText && this.tooltipText !== this.prevText) {
                this.tooltipText = this.prevText;
            }
            window.setTimeout(() => {
                if (this.tooltip) {
                    this.renderer.removeChild(document.body, this.tooltip);
                    this.tooltip = null;
                }
            }, this._delay);
        }
    }

    create() {
        let tooltipContent;
        let tooltipTitle;
        let tooltipText;
        let tooltipLink;

        this.tooltip = this.renderer.createElement('div');

        tooltipContent = this.renderer.createElement('div');
        this.renderer.addClass(tooltipContent, 'kaseya-ui-tooltip__content');

        tooltipTitle = this.renderer.createElement('div');
        this.renderer.addClass(tooltipTitle, 'kaseya-ui-tooltip__title');
        this.renderer.appendChild(tooltipTitle, this.renderer.createText(this.ttTitle));

        tooltipText = this.renderer.createElement('div');
        this.renderer.addClass(tooltipText, 'kaseya-ui-tooltip__text');
        tooltipText.innerHTML = this.tooltipText;
        // this.renderer.appendChild(tooltipText, this.renderer.createText(this.tooltipText));

        if (this.isLinkInputted) {
            if (this._ttLinks.length === 0) {
                tooltipLink = this.renderer.createElement('span');
                this.renderer.addClass(tooltipLink, 'kaseya-ui-tooltip__link');
                this.renderer.appendChild(tooltipLink, this.renderer.createText(this._ttLink.text));
            } else {
                tooltipLink = this.renderer.createElement('div');
                this.renderer.setStyle(tooltipLink, 'display', 'flex');
                this.renderer.setStyle(tooltipLink, 'flex-direction', 'row');
                this.renderer.setStyle(tooltipLink, 'justify-content', 'flex-end');
                let notFirst = false;
                this._ttLinks.forEach((link) => {
                    if (notFirst) {
                        let divider = this.renderer.createElement('span');
                        this.renderer.addClass(divider, 'kaseya-ui-tooltip__link');
                        divider.innerHTML = this.ttLinksDelimiter;
                        this.renderer.appendChild(tooltipLink, divider);
                    } else {
                        notFirst = true;
                    }

                    let linkItem = this.renderer.createElement('span');
                    this.renderer.addClass(linkItem, 'kaseya-ui-tooltip__link');
                    this.renderer.appendChild(linkItem, this.renderer.createText(link.text));
                    this.renderer.listen(linkItem, 'click', () =>
                        this.onLinkItemButtonClicked(link.text)
                    );
                    this.renderer.appendChild(tooltipLink, linkItem);
                });
            }
        }

        if (this.ttTitle) {
            this.renderer.appendChild(tooltipContent, tooltipTitle);
        }
        this.renderer.appendChild(tooltipContent, tooltipText);
        if (this.isLinkInputted) {
            this.renderer.appendChild(tooltipContent, tooltipLink);
            if (this._ttLinks.length === 0) {
                this.renderer.listen(this.tooltip, 'click', () => this.onLinkButtonClicked());
            }
        }

        this.renderer.appendChild(document.body, this.tooltip);
        this.renderer.appendChild(this.tooltip, tooltipContent);

        this.renderer.addClass(this.tooltip, 'kaseya-ui-tooltip');
        this.renderer.addClass(this.tooltip, 'kaseya-ui-tooltip__' + this._position);
        this.renderer.setStyle(this.tooltip, '-webkit-transition', `opacity ${this._delay}ms`);
        this.renderer.setStyle(this.tooltip, '-moz-transition', `opacity ${this._delay}ms`);
        this.renderer.setStyle(this.tooltip, '-o-transition', `opacity ${this._delay}ms`);
        this.renderer.setStyle(this.tooltip, 'transition', `opacity ${this._delay}ms`);
        this.renderer.setStyle(this.tooltip, 'z-index', '1102'); // this is for tooltips in modals

        this.renderer.setStyle(tooltipContent, 'padding-bottom', this._offset + 'px');

        this.tooltip.addEventListener('mouseleave', () => {
            this.hide();
        });
    }
    onLinkItemButtonClicked(text: string): void {
        let link = this._ttLinks.find((l) => l.text === text);
        if (link && link?.href !== '') {
            this.openItemLink(link);
        }
        this.onLinkClick.emit();
        this.onLinkItemClick.emit(text);
    }

    openItemLink(link: ILinkExt) {
        this.hide();
        if (link.href[0] === '#') {
            this.ttLinkClick.emit();
            if (link.returnValue) {
                this.ttLinkItemClick.emit(link.returnValue);
            } else {
                this.ttLinkItemClick.emit(link.text);
            }
            return;
        }
        if (link.newTab) {
            window.open(link.href);
        } else {
            window.location.href = link.href;
        }
    }

    /** Open link in new window or tab */
    openLink() {
        this.hide();
        if (this._ttLink.href === '#') {
            this.ttLinkClick.emit();
            return;
        }
        if (this._ttLink.newTab) {
            window.open(this._ttLink.href);
        } else {
            window.location.href = this._ttLink.href;
        }
    }

    onLinkButtonClicked() {
        if (this._ttLink?.href !== '') {
            this.openLink();
        }
        this.onLinkClick.emit();
    }

    ngOnDestroy() {
        if (this.tooltip) {
            this.hide();
        }
    }

    setPosition() {
        const hostPos = this.el.nativeElement.getBoundingClientRect();
        const tooltipPos = this.tooltip.getBoundingClientRect();

        const scrollPos =
            window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;

        let top, left, paddingTop;

        if (this._position === 'top') {
            top = hostPos.top - tooltipPos.height - 4;
            left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
        }

        if (this._position === 'top-left') {
            top = hostPos.top - tooltipPos.height - 4;
            left = hostPos.left + hostPos.width - tooltipPos.width;
        }

        if (this._position === 'top-right') {
            top = hostPos.top - tooltipPos.height - 4;
            left = hostPos.left;
        }

        if (this._position === 'bottom') {
            top = hostPos.bottom - 1;
            left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
            paddingTop = 4;
        }

        if (this._position === 'bottom-left') {
            top = hostPos.bottom - 1;
            left = hostPos.left - tooltipPos.width + hostPos.width;
            paddingTop = 4;
        }

        if (this._position === 'bottom-right') {
            top = hostPos.bottom - 1;
            left = hostPos.left;
            paddingTop = 4;
        }

        if (this._position === 'left') {
            top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
            left = hostPos.left - tooltipPos.width - 4;
        }

        if (this._position === 'right') {
            top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
            left = hostPos.right + 4;
        }

        top = top >= 4 ? top : 4;
        left = left >= 4 ? left : 4;
        this.renderer.setStyle(this.tooltip, 'top', `${top + scrollPos + 1}px`);
        this.renderer.setStyle(this.tooltip, 'left', `${left}px`);
        if (paddingTop) {
            this.renderer.setStyle(this.tooltip, 'padding-top', `${paddingTop}px`);
        }
    }

    // used to programatically force a tooltip to show or change text
    forceShow(text: string = null, time: number = 1500) {
        // get current text before hiding
        let currentText = this.tooltipText;
        this.hide();
        // set timeout to give time for the tooltip to hide
        setTimeout(() => {
            if (text) {
                this.prevText = currentText;
                this.tooltipText = text;
            }
            this.show();
            if (time) {
                setTimeout(() => {
                    this.hide();
                }, time);
            }
        }, this._delay);
    }
}

interface ILink {
    href: string;
    text: string;
    newTab?: boolean;
}

interface ILinkExt extends ILink {
    returnValue?: any;
}
