import { Component, OnInit } from '@angular/core';
import { AuthService, SiteRoles } from 'app/core/auth';
import { SiteService } from 'app/sites/shared/site.service';
import { Site } from 'app/sites/shared/site.model';

@Component({
    template: `
        <sds-client-reports *ngIf="isClientView; else component"></sds-client-reports>
        <ng-template #component>
            <sds-client-view-settings></sds-client-view-settings>
        </ng-template>
    `,
})
export class ClientViewWrapRoutingComponent implements OnInit {
    isClientView: boolean = false;
    constructor(private authService: AuthService, private siteService: SiteService) {}

    ngOnInit(): void {
        this.siteService.app_getCurrentSite().then((site: Site) => {
            if (site) {
                this.checkRole(site);
            }
        });
    }

    checkRole(site: Site) {
        this.authService.setCurrentSiteRoles(site.UserRoles);
        let siteRoles = this.authService.getCurrentSiteRoles();
        this.isClientView = siteRoles && siteRoles.indexOf(SiteRoles.Client) > -1;
    }
}
