<div *ngIf="(connection && loading) || (connection && !loading && ticketList.length)">
    <em>
        <strong>Open Tickets ({{ friendlyTypes[connection?.Type] }} Connection):</strong>
    </em>
    <ng-container *ngTemplateOutlet="ticketContent"></ng-container>
</div>

<ng-template #ticketContent>
    <div class="loader" *ngIf="loading">
        <span></span>
        <span></span>
        <span></span>
    </div>
    <span class="ticket-content" [style.visibility]="loading ? 'hidden' : ''">
        <span *ngFor="let ticket of ticketList; let i = index">
            &nbsp;
            <a href="{{ ticket.BaseUrl }}" target="_blank" rel="external noopener noreferrer">
                #{{ ticket.TicketNumber }}
            </a>
            {{ i < ticketList.length - 1 ? ',' : '' }}
        </span>
    </span>
</ng-template>
