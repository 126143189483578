export const SITE: string = 'DATA_SITE';
export const PING_SITE: string = 'PING_SITE';
export const FILEUPLOADED = 'fileUploadDone'; // file upload done message key
export const FILEUPLOADFINISHED = 'fileUploadComplete';
export const FILEDELETED = 'fileDeleted';

export const SiteRoles: any = {
    Auditor: 'SDS_AUDITOR',
    SiteAdmin: 'SDS_SITEADMIN',
    Tech: 'SDS_TECH',
    Client: 'IND_CLIENT',
};
