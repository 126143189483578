import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { AuthHttp } from 'app/core/auth';
import { lastValueFrom } from 'rxjs';
import { Site } from './site.model';
import { SiteDashboard } from './site-dashboard.model';
import { SiteService } from './site.service';
import { Router } from '@angular/router';
import { SiteEncodePipe } from 'app/shared/siteEncode.pipe';

@Injectable({
    providedIn: 'root',
})
export class SiteDashboardService {
    private readonly apiUrl: string;

    constructor(
        private http: AuthHttp,
        private siteService: SiteService,
        private router: Router,
        private siteEncodePipe: SiteEncodePipe
    ) {
        this.apiUrl = environment.apiUrl;
    }

    getSitesByAccountId(): Promise<any> {
        const url: string = this.apiUrl + `getSitesByAccountId`;
        return lastValueFrom(this.http.get(url))
            .then((res) => res)
            .catch((err) => {});
    }

    getDashboardSiteInOrganization(organizationName: string): Promise<any> {
        const url: string =
            this.apiUrl + `GetDashboardSiteInOrganization/${window.btoa(organizationName)}`;
        return lastValueFrom(this.http.get(url))
            .then((res) => res)
            .catch((err) => {});
    }

    setDashboardItem(siteId: number, dashboardItem: string): Promise<any> {
        const url: string = this.apiUrl + `DashboardItem/${siteId}/${dashboardItem}`;
        return lastValueFrom(this.http.post(url, {}))
            .then((res) => res)
            .catch((err) => {});
    }

    saveGrcFilterState(value: string, name: string): Promise<any> {
        const url: string = this.apiUrl + `SaveGrcFilterState`;
        return lastValueFrom(this.http.post(url, { value: value, name: name }))
            .then((res) => res)
            .catch((err) => {});
    }

    getGrcFilterState(): Promise<any> {
        const url: string = this.apiUrl + `GetGrcFilterState`;
        return lastValueFrom(this.http.get(url))
            .then((res) => res)
            .catch((err) => {});
    }

    goToSite(site: Site | SiteDashboard) {
        if (site.IsLicenseInactive) return;

        this.siteService.getSite(site.Id).then((siteWithRoles) => {
            this.siteService.goToSite(siteWithRoles);
        });
    }

    goToSiteDashboard(site: Site) {
        if (site.IsLicenseInactive) return;

        this.router.navigate([
            '/',
            'site',
            this.siteEncodePipe.transform(site.Name),
            'home',
            'dashboard',
        ]);
    }

    goToTodo(site: Site) {
        if (site.IsLicenseInactive) return;

        if (this.siteService.isComplianceManager(site)) {
            this.router.navigate(['site', site.Name, 'compliance-manager', 'todo']);
        }

        if (this.siteService.isCyberHawk(site)) {
            this.router.navigate(['site', site.Name, 'cyber-hawk', 'todo']);
        }
    }

    goToOrganization(site: Site) {
        if (site.IsLicenseInactive || !site.Organization) return;

        this.router.navigate([
            '/',
            'organizations',
            this.siteEncodePipe.transform(site.Organization),
        ]);
    }

    goToAppliance(site: SiteDashboard) {
        if (site.IsLicenseInactive) return;

        this.router.navigate([
            '/',
            'site',
            this.siteEncodePipe.transform(site.Name),
            'home',
            'data-collectors',
        ]);
    }

    goToTechnicalReview(site: Site) {
        if (site.IsLicenseInactive) return;

        this.router.navigate([
            '/',
            'site',
            this.siteEncodePipe.transform(site.Name),
            'compliance-manager-grc',
            'technical-review',
        ]);
    }

    goToRapidBaseline(site: Site) {
        if (site.IsLicenseInactive) return;

        this.router.navigate([
            '/',
            'site',
            this.siteEncodePipe.transform(site.Name),
            'compliance-manager-grc',
            'rapid-baseline',
        ]);
    }

    goToControlsAssessment(site: Site) {
        if (site.IsLicenseInactive) return;

        this.router.navigate([
            '/',
            'site',
            this.siteEncodePipe.transform(site.Name),
            'compliance-manager-grc',
            'controls-assessment',
        ]);
    }

    goToRequirementsAssessment(site: Site) {
        if (site.IsLicenseInactive) return;

        this.router.navigate([
            '/',
            'site',
            this.siteEncodePipe.transform(site.Name),
            'compliance-manager-grc',
            'requirements-assessment',
        ]);
    }

    goToReports(site: Site) {
        if (site.IsLicenseInactive) return;

        this.router.navigate([
            '/',
            'site',
            this.siteEncodePipe.transform(site.Name),
            'compliance-manager-grc',
            'results-evidence',
            'reports',
        ]);
    }

    goToNDPAssessment(site: Site) {
        if (site.IsLicenseInactive) return;

        this.router.navigate([
            '/',
            'site',
            this.siteEncodePipe.transform(site.Name),
            'network-detective-pro',
            'dashboard',
            'assessment',
        ]);
    }
}
