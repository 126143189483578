import { Component, OnInit } from '@angular/core';

import { AuthService } from 'app/core/auth';
import { AppError, ErrorService } from './error.service';

@Component({
    selector: 'sds-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.css'],
})
export class ErrorComponent implements OnInit {
    constructor(private authService: AuthService, private errorService: ErrorService) {}

    error: AppError;

    ngOnInit() {
        this.error = this.errorService.getError();
    }

    isAuth(): boolean {
        return this.authService.isAuth();
    }
}
