import { Component, Input, OnInit, inject } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

import { ANIMATE_DURATION, TOAST_DURATION } from './toast.config';
import { Notification, NotificationType } from './notification.model';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Component({
    template: `
        <div
            class="kaseya-ui-toast {{ toastClass }}"
            [class.noAutoClose]="!toast.autoClose"
            [@toastState]="state"
            (mouseenter)="pauseTimer()"
            (mouseleave)="startTimer()">
            <div class="toast__icon-container">
                <fa-icon [icon]="toastIcon" [fixedWidth]="true" class="toast__icon"></fa-icon>
            </div>
            <div class="toast__text">
                <span class="toast__title">
                    <strong>{{ toast.title }}</strong>
                </span>
                <span class="toast__children" [innerHTML]="toast.message"></span>
                <span *ngIf="toast.linkToLabel" class="toast__linkTo" (click)="goToUrl()">
                    {{ toast.linkToLabel }}
                </span>
            </div>
            <ng-container *ngIf="showClose">
                <fa-icon icon="xmark" class="toast__close-button" (click)="removeToast()"></fa-icon>
            </ng-container>
            <div class="toast__progress-bar">
                <div
                    class="toast__progress-bar__fill"
                    [style.width.%]="(toast.timer / toastDuration) * 100"></div>
            </div>
        </div>
    `,
    styleUrls: ['./notification-toast.component.scss'],
    animations: [
        trigger('toastState', [
            state('visible', style({ opacity: 1 })),
            state('hidden', style({ opacity: 0 })),
            transition('void => visible', animate(ANIMATE_DURATION)),
            transition('visible => hidden', animate(ANIMATE_DURATION)),
        ]),
    ],
})
export class ItcNotificationToastComponent implements OnInit {
    @Input() id = 'default-notification';
    @Input() fade = true;
    router: Router = inject(Router);
    state: string;
    toast: Notification;
    NotificationType = NotificationType;
    type: string;
    toastIcon: string;
    toastClass: string;
    toastDuration = TOAST_DURATION;
    toastInterval: ReturnType<typeof setInterval>;
    toastState: Subject<string> = new Subject();
    showClose = true;

    constructor(toast: Notification) {
        this.toast = toast;
    }

    ngOnInit() {
        this.state = 'visible';
        switch (this.toast.type) {
            case NotificationType.Success:
                this.type = 'success';
                this.toastIcon = 'circle-check';
                this.toastClass = 'toast--success';
                break;
            case NotificationType.Error:
                this.type = 'error';
                this.toastIcon = 'exclamation-triangle';
                this.toastClass = 'toast--error';
                break;
            case NotificationType.Info:
                this.type = 'info';
                this.toastIcon = 'info-square';
                this.toastClass = 'toast--info';
                break;
            case NotificationType.Warning:
                this.type = 'warning';
                this.toastIcon = 'exclamation-circle';
                this.toastClass = 'toast--warning';
                break;
            case NotificationType.Download:
                this.type = 'download';
                this.toastIcon = 'circle-arrow-down';
                this.toastClass = 'toast--download';
                break;
        }
        this.startTimer();
    }

    hide() {
        this.state = 'hidden';
    }

    startTimer() {
        if (this.toast.autoClose) {
            this.toastInterval = setInterval(() => {
                this.toast.timer += 10;
                if (this.toast.timer >= TOAST_DURATION) {
                    this.removeToast();
                }
            }, 10);
        }
    }
    pauseTimer() {
        if (this.toast.autoClose) {
            this.clearInterval();
        }
    }
    clearInterval() {
        window.clearInterval(this.toastInterval);
    }

    removeToast() {
        this.clearInterval();
        this.toastState.next('complete');
    }
    goToUrl() {
        let url = '';
        if (Array.isArray(this.toast.linkTo)) {
            url = this.toast.linkTo?.join('/');
        } else url = this.toast.linkTo;

        this.router.navigateByUrl(url).then(() => {
            if (this.toast.forceReload) {
                window.location.reload();
            }
        });
    }
}
