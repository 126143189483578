import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LauncherService {
    private apiUrl: string;

    constructor(private http: HttpClient) {
        this.apiUrl = environment.apiUrl;
    }

    launchWithLoginHint(loginHint: string): Promise<any> {
        const url: string = this.apiUrl + `k1ssov2launch/${loginHint}`;
        return lastValueFrom(this.http.get(url))
            .then((res) => res)
            .catch((err) => {
                if (err.status == 302) {
                    window.location.href = err.error;
                }
            });
    }

    launch(): Promise<any> {
        const url: string = this.apiUrl + 'k1ssov2launch';
        return lastValueFrom(this.http.get(url))
            .then((res) => res)
            .catch((err) => {
                if (err.status == 302) {
                    window.location.href = err.error;
                }
            });
    }
}
