<div class="paginationionion" #pager>
    <button
        style="cursor: pointer"
        class="flipH"
        (click)="setPage(0)"
        [class.disabled]="pageNumber == 0 || !pages.length">
        <i class="icon-beginning-end beginning"></i>
    </button>
    <button
        style="cursor: pointer"
        class="flipH"
        (click)="setPage(pageNumber - 1)"
        [class.disabled]="pageNumber == 0 || !pages.length">
        <i class="icon-chevron"></i>
    </button>

    <div class="" [class.disabled]="!pages.length" style="padding-right: 3px">
        <input
            type="number"
            [ngModel]="pageNumber + 1"
            (ngModelChange)="setPage($event - 1)"
            style="
                width: 50px;
                border: 0;
                text-align: center;
                background-color: #fff;
                font-size: 12px;
                margin-bottom: 0;
            "
            #pageNumberInput />
        <div
            class="ui label darker"
            style="padding: 3px 10px; font-weight: 500; background-color: #f5f5f5">
            / {{ lastPage + 1 }}
        </div>
    </div>

    <button
        style="cursor: pointer"
        class=""
        (click)="setPage(pageNumber + 1)"
        [class.disabled]="pageNumber == lastPage || !pages.length">
        <i class="icon-chevron right"></i>
    </button>
    <button
        style="cursor: pointer"
        class=""
        (click)="setPage(lastPage)"
        [class.disabled]="pageNumber == lastPage || !pages.length">
        <i class="icon-beginning-end end"></i>
    </button>
    <p style="margin-bottom: 0; color: #000; font-size: 12px">view</p>
    <div class="ui input" style="font-size: 12px">
        <sm-select
            [(model)]="pageSize"
            class="compact pageSizeDropdown"
            style="font-size: 12px; padding: 2px 10px"
            (modelChange)="page()"
            [disabled]="!pages.length">
            <option *ngFor="let size of sizes">{{ size }}</option>
        </sm-select>
    </div>
</div>
