import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, map } from 'rxjs';
import {
    DisplayType,
    SelectStandardsModalState,
    SimpleStandard,
    StandardObject,
} from 'app/sites/site/compliance-manager/compliance-manager.model';
import { Standard } from 'app/sites/shared/standards/standard.model';

@Injectable({
    providedIn: 'root',
})
export class SelectStandardsService {
    private allStandards$ = new BehaviorSubject<StandardObject>(null);
    private searchKey$ = new BehaviorSubject<string>('');
    private selectedType$ = new BehaviorSubject<string>('showAll');
    private selectedOnly$ = new BehaviorSubject<boolean>(false);
    private forceRefresh$ = new BehaviorSubject<void>(null);
    private displayedStandards: StandardObject;
    public state: SelectStandardsModalState = {
        selectedCount: 0,
        allExpanded: true,
        allSelected: false,
        showSelected: false,
        noDisplayStandards: false,
    };

    setAllStandards(standards: SimpleStandard[]) {
        standards = JSON.parse(JSON.stringify(standards));
        standards.forEach((s) => {
            s.Expanded = true;
        });
        let allStandards: StandardObject = {
            systemStandards: standards.filter((r) => r.IsSystem && !r.IsDeprecated),
            nonSystemStandards: standards.filter((r) => !r.IsSystem && !r.IsDeprecated),
            deprecatedStandards: standards.filter((r) => r.IsDeprecated),
        };
        this.allStandards$.next(allStandards);
        this.setSelectedCount();
    }

    getAllStandards(): Observable<any> {
        return combineLatest([
            this.allStandards$.asObservable(),
            this.searchKey$,
            this.selectedType$,
            this.selectedOnly$,
            this.forceRefresh$,
        ]).pipe(
            map(([allStandards, searchKey]) => {
                // create filtered standards and search by search key
                let filteredStandards: StandardObject = {
                    systemStandards: [],
                    nonSystemStandards: [],
                    deprecatedStandards: [],
                };
                if (allStandards) {
                    Object.keys(allStandards).forEach((type) => {
                        allStandards[type].forEach((standard) => {
                            if (standard.StandardName.toLowerCase().includes(searchKey)) {
                                filteredStandards[type].push(standard);
                            } else if (
                                standard.Variants.some((v) => {
                                    return v.Variant.toLowerCase().includes(searchKey);
                                })
                            ) {
                                filteredStandards[type].push(standard);
                            }
                        });
                    });

                    this.setSelectedCount();
                    return filteredStandards;
                }
            }),
            map((filteredStandards) => {
                // filter by selected only
                if (this.selectedOnly$.value) {
                    Object.keys(filteredStandards).forEach((type) => {
                        filteredStandards[type] = filteredStandards[type].filter(
                            (r) => r.Selected || r.Selected == 'mixed'
                        );
                    });
                }
                return filteredStandards;
            }),
            map((filteredStandards) => {
                // return null if there's nothing to show
                if (
                    filteredStandards?.systemStandards.length == 0 &&
                    filteredStandards?.nonSystemStandards.length == 0 &&
                    filteredStandards?.deprecatedStandards.length == 0
                ) {
                    return null;
                }
                return filteredStandards;
            }),
            map((filteredStandards) => {
                // return selectedType standards only
                if (filteredStandards) {
                    switch (this.selectedType$.value) {
                        case 'showSysStd':
                            return filteredStandards.systemStandards.length
                                ? { systemStandards: filteredStandards.systemStandards }
                                : null;
                        case 'showUserStd':
                            return filteredStandards.nonSystemStandards.length
                                ? { nonSystemStandards: filteredStandards.nonSystemStandards }
                                : null;
                        case 'showDepStd':
                            return filteredStandards.deprecatedStandards.length
                                ? { deprecatedStandards: filteredStandards.deprecatedStandards }
                                : null;
                        case 'showAll':
                        default:
                            return filteredStandards;
                    }
                }
            }),
            map((filteredStandards) => {
                if (filteredStandards) {
                    this.setSelectedCount(filteredStandards, false);
                }
                this.displayedStandards = filteredStandards;
                if (!filteredStandards) {
                    this.state.allSelected = false;
                    this.state.noDisplayStandards = true;
                } else {
                    this.state.noDisplayStandards = false;
                }
                return filteredStandards;
            })
        );
    }

    setSelectedCount(
        standards: StandardObject = this.allStandards$.value,
        setCount: boolean = true
    ): void {
        if (standards) {
            let allSelected = true;
            let selectedCount = 0;
            Object.values(standards)?.forEach((s) => {
                s.forEach((r) => {
                    if (r.Selected === false || r.Selected === 'mixed') {
                        allSelected = false;
                    }
                    r.Variants.forEach((v) => {
                        if (v.Selected) {
                            selectedCount++;
                        }
                    });
                });
            });
            if (setCount) {
                this.state.selectedCount = selectedCount;
            }
            this.updateSelectAllCheckbox(standards);
        }
    }

    updateSelectAllCheckbox(standards: StandardObject = this.displayedStandards): void {
        if (standards) {
            let allSelected = true;
            let selectedCount = 0;
            Object.values(standards).forEach((s) => {
                s.forEach((r) => {
                    if (r.Selected === false || r.Selected === 'mixed') {
                        allSelected = false;
                    } else {
                        r.Variants.forEach((v) => {
                            if (v.Selected) {
                                selectedCount++;
                            }
                        });
                    }
                });
            });
            if (allSelected) {
                this.state.allSelected = true;
            } else if (selectedCount) {
                this.state.allSelected = 'mixed';
            } else {
                this.state.allSelected = false;
            }
        } else {
            this.state.allSelected = false;
            this.state.noDisplayStandards = true;
        }
    }

    setSearchKey(key: string): void {
        this.searchKey$.next(key.toLowerCase().trim());
    }

    setSelectedTypes(type: DisplayType): void {
        this.selectedType$.next(type);
    }

    toggleSelectAllStandards(): void {
        // toggles all displayed standards
        let allStandards = this.displayedStandards;
        if (allStandards) {
            this.state.allSelected = !this.state.allSelected;
            Object.values(allStandards).forEach((s) => {
                s.forEach((r) => {
                    r.Selected = this.state.allSelected;
                    r.Variants.forEach((v) => {
                        v.Selected = this.state.allSelected;
                    });
                });
            });
            this.forceRefresh$.next();
        }
    }

    toggleExpandAllStandards(): void {
        let allStandards = this.allStandards$.value;
        this.state.allExpanded = !this.state.allExpanded;
        Object.values(allStandards).forEach((s) => {
            s.forEach((r) => {
                r.Expanded = this.state.allExpanded;
            });
        });
    }

    toggleSelectedOnly(): void {
        this.state.showSelected = !this.selectedOnly$.value;
        this.selectedOnly$.next(this.state.showSelected);
    }

    toggleStandard(standard: SimpleStandard): void {
        standard.Selected = !standard.Selected;
        standard.Variants.forEach((v) => {
            v.Selected = standard.Selected;
        });
        this.setSelectedCount();
    }

    toggleVariant(standard: SimpleStandard, variant: Standard): void {
        variant.Selected = !variant.Selected;
        if (standard.Variants.every((v) => v.Selected)) {
            standard.Selected = true;
        } else if (standard.Variants.some((v) => v.Selected)) {
            standard.Selected = 'mixed';
        } else {
            standard.Selected = false;
        }
        this.setSelectedCount();
    }

    saveStandards(): StandardObject {
        return this.allStandards$.value;
    }

    resetModal(): void {
        this.searchKey$.next('');
        this.selectedOnly$.next(false);
        this.selectedType$.next('showAll');
        this.state.showSelected = false;
        this.state.selectedCount = 0;
        this.state.allExpanded = true;
    }
}
