import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CmControlEditorModalComponent } from './control-editor-modal/cm-control-editor-modal.component';
import { CmDataCollectorsComponent } from './data-collection/data-collectors/cm-data-collectors.component';
import {
    CmWizardComponent,
    CmWizardRelatedcontrolsComponent,
    CmWizardRelatedRequirementsComponent,
    CmReadOnlyWizardComponent,
} from './shared/cm-wizard/index';
import { CmEocReportModalComponent } from './shared/eoc-report-modal/cm-eoc-report-modal.component';
import { SelectStandardsModalComponent } from './shared/select-standards-modal/select-standards-modal.component';
import { SimpleMarkdownModalComponent } from './shared/simple-markdown-modal/simple-markdown-modal.component';
//import { CmoExceptionExclusionModalComponent } from './compliance-monitor/exclusion-exception-modal/cmo-exception-exclusion-modal.component.ts';
import { CmoExceptionExclusionModalComponent } from './compliance-monitor/exclusion-exception-modal/cmo-exception-exclusion-modal.component';

@NgModule({
    imports: [
        SharedModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SelectStandardsModalComponent,
        SimpleMarkdownModalComponent,
        CmoExceptionExclusionModalComponent
    ],
    declarations: [
        CmControlEditorModalComponent,
        CmWizardComponent,
        CmWizardRelatedcontrolsComponent,
        CmWizardRelatedRequirementsComponent,
        CmReadOnlyWizardComponent,
        CmDataCollectorsComponent,
        CmEocReportModalComponent,
    ],
    exports: [
        CmControlEditorModalComponent,
        CmWizardComponent,
        CmWizardRelatedcontrolsComponent,
        CmWizardRelatedRequirementsComponent,
        CmReadOnlyWizardComponent,
        CmDataCollectorsComponent,
        CmEocReportModalComponent,
        SelectStandardsModalComponent,
        SimpleMarkdownModalComponent,
        CmoExceptionExclusionModalComponent
    ],
})
export class ComplianceManagerSharedModule {
    constructor() {
        console.log('Compliance Manager Shared Module');
    }
}
