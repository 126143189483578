<h2>Vendor Asessment Results Printable Report</h2>

<div
    style="
        margin-top: 12px;
        padding-top: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    ">
    <div class="donutsContainer">
        <sds-tracker-donut-component
            [cardMeta]="vendorsInfo.DonutData.VendorName"
            [chartData]="vendorsInfo.DonutData.chartData"
            [centerText]="vendorsInfo.DonutData.CompletedPercentage"
            [legendPosition]="'bottom'"
            [measure]="'Controls'"
            [legendSeparator]="[true, false, false, false]"
            [diameter]="150"
            [centerTextSmall]="'Covered'"></sds-tracker-donut-component>
    </div>

    <div class="resultsDivTable">
        <div
            class="resultBox"
            *ngFor="let requirement of vendorsInfo.DetailData"
            [style.backgroundColor]="responseColors[requirement.Response]"
            [style.color]="'Black'">
            {{ requirement.RequirementId }}
        </div>
    </div>
</div>

<div class="responseBox" *ngFor="let res of vendorsInfo.TableData">
    <div class="responseHeader">
        <div class="responseTitle">
            <h4>{{ res.Description }}</h4>
            <h5>{{ res.LongDescription }}</h5>
        </div>
        <div class="answerButton">
            <button
                class="ui button"
                type="button"
                [style.color]="res.Response == 'not assessed' ? 'Black' : 'White'"
                [ngStyle]="{
                    'background-color':
                        res.Response == 'yes'
                            ? CM_COLORS.COVERED
                            : res.Response == 'partially'
                            ? CM_COLORS.PARTIAL
                            : res.Response == 'no'
                            ? CM_COLORS.NOTCOVERED
                            : res.Response == 'unsure'
                            ? CM_COLORS.UNSURE
                            : res.Response == 'not assessed'
                            ? CM_COLORS.NOTASSESSED
                            : 'white'
                }">
                {{
                    res.Response == 'yes'
                        ? 'Yes, Fully'
                        : res.Response == 'partially'
                        ? 'Yes, Partially'
                        : res.Response == 'no'
                        ? 'No'
                        : res.Response == 'unsure'
                        ? 'Unsure'
                        : res.Response == 'no'
                        ? 'No'
                        : res.Response == 'not assessed'
                        ? 'Not Assessed'
                        : 'Not Assessed'
                }}
            </button>
        </div>
    </div>
    <h5>Control Reference:</h5>
    <p>{{ res.ControlId }}</p>

    <h5>Comments:</h5>
    <p>{{ res.Notes ? res.Notes : 'No comments' }}</p>
</div>
