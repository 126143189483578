<div class="ui medium coupled modal addSMEModal" #addSMEModal>
    <div class="ui header">Email Invites</div>
    <div class="content" style="overflow: unset">
        <table style="width: 100%" class="simple table">
            <tr>
                <td class="emailInviteTitle" *ngIf="emailSMEs">To:</td>
                <td>
                    <sm-select
                        class="fluid search multiple inviteUsers"
                        [error]="selectionError"
                        (onChange)="addedUserEmail($event)">
                        <option *ngFor="let user of addableSMEs" [value]="user.Username">
                            {{ user.FirstName }} {{ user.LastName }} ({{ user.Username }}
                        </option>
                    </sm-select>
                    <div class="ui error message" *ngIf="selectionError">
                        <b>You must select at least one user to add.</b>
                    </div>
                </td>
            </tr>
            <tr *ngIf="emailSMEs">
                <td class="emailInviteTitle" *ngIf="emailSMEs">Subject:</td>
                <td>
                    <div class="field">
                        <input
                            type="text"
                            class="form-control text-response response-font-size"
                            [(ngModel)]="emailInviteSubject" />
                    </div>
                </td>
            </tr>
            <tr *ngIf="emailSMEs">
                <td class="emailInviteTitle" valign="top" *ngIf="emailSMEs">Body:</td>
                <td>
                    <textarea
                        class="form-control text-response response-font-size"
                        style="width: 100%"
                        rows="6"
                        [(ngModel)]="emailInviteBody"></textarea>
                </td>
            </tr>
            <tr>
                <td *ngIf="emailSMEs"></td>
                <td>
                    <div>
                        <itc-checkbox
                            [checked]="emailSMEs"
                            (onChanged)="emailSMEs = !emailSMEs"
                            id="emailCheckbox">
                            Email request to experts
                        </itc-checkbox>
                    </div>
                </td>
            </tr>
        </table>
    </div>
    <div class="actions">
        <itc-button type="secondary" class="cancel">Cancel</itc-button>
        <itc-button
            type="primary"
            icon="send"
            class="approve"
            [disabled]="!newSMEs?.length"
            (onclick)="processInvitations()"
            *ngIf="emailSMEs">
            Add & Send
        </itc-button>
        <itc-button
            type="primary"
            icon="fa-check"
            class="approve"
            [disabled]="!newSMEs?.length"
            (onclick)="addSME()"
            *ngIf="!emailSMEs">
            Add Expert
        </itc-button>
    </div>
</div>

<div class="ui xs coupled modal inviteModal" #inviteModal>
    <div class="ui header">Invite Other Subject Matter Experts</div>
    <div class="content">
        <p>
            <strong>
                Get help completing this form by inviting Subject Matter Experts to assist.
            </strong>
        </p>
        <p>
            Site Administrators can add additional Subject Matter Experts from the Site Settings
            menu.
        </p>

        <itc-button type="primary" icon="fa-plus" (onclick)="openAddModal()" [disabled]="selectedSMEs?.length == 0 && addableSMEs?.length == 0">
            Add New Experts
        </itc-button>

        <table class="ui table" style="margin-top: 20px; min-height: 40px">
            <thead>
                <tr>
                    <th colspan="2">Active Invites</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let user of selectedSMEs">
                    <td>{{ user.FirstName }} {{ user.LastName }} ({{ user.Username }})</td>
                    <td>
                        <itc-button
                            class="revokeSME"
                            style="float: right"
                            type="icon"
                            icon="fa-trash-can"
                            (onclick)="revokeSME(user)"
                            tooltip="Remove"
                            ttPosition="top"
                            [loadingAfterClick]="true"></itc-button>
                    </td>
                </tr>
                <tr *ngIf="!selectedSMEs || !selectedSMEs.length || addingSME">
                    <td colspan="2">
                        <div class="ui inline loader smeLoader" [class.active]="addingSME"></div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="actions">
        <itc-button type="secondary" class="cancel" (onclick)="closeAddModal()">Close</itc-button>
    </div>
</div>
