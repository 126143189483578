import {
    ColorScheme,
    CoverImage,
    SimpleDropdownObject,
    ThemeObject,
} from './report-preferences.model';
import {
    ReportTheme,
    CustomTheme,
    DefaultTheme,
    Helvetica,
    HelveticaEarth,
    ModernBlaze,
    ModernBold,
    ModernGraphite,
    ModernOceanic,
    ModernOptimism,
    ModernRoyal,
    ModernSerenity,
    TimesNewRoman,
    OptimaBlue,
    TahomaBlack,
} from '../../site/settings/reports/themes';

export const coverImageList: CoverImage[] = [
    { id: 'automotive', title: '', img: '/assets/img/cover_images/automotive.jpg' },
    { id: 'coverimg1', title: '', img: '/assets/img/cover_images/coverimg1.jpg' },
    { id: 'hipaa_cover_2', title: '', img: '/assets/img/cover_images/hipaa_cover_2.jpg' },
    { id: 'hipaa_cover_3', title: '', img: '/assets/img/cover_images/hipaa_cover_3.jpg' },
    { id: 'hipaa_cover_4', title: '', img: '/assets/img/cover_images/hipaa_cover_4.jpg' },
    { id: 'hipaa_cover_5', title: '', img: '/assets/img/cover_images/hipaa_cover_5.jpg' },
    { id: 'hipaa_cover_7', title: '', img: '/assets/img/cover_images/hipaa_cover_7.jpg' },
    { id: 'hipaa_cover_8', title: '', img: '/assets/img/cover_images/hipaa_cover_8.jpg' },
    { id: 'hospitality', title: '', img: '/assets/img/cover_images/hospitality.jpg' },
    { id: 'legal', title: '', img: '/assets/img/cover_images/legal.jpg' },
    { id: 'manufacturing', title: '', img: '/assets/img/cover_images/manufacturing.jpg' },
    { id: 'medical', title: '', img: '/assets/img/cover_images/medical.jpg' },
    { id: 'mod_cara', title: '', img: '/assets/img/cover_images/mod_cara.jpg' },
    { id: 'mod_exchange1', title: '', img: '/assets/img/cover_images/mod_exchange1.jpg' },
    { id: 'mod_exchange2', title: '', img: '/assets/img/cover_images/mod_exchange2.jpg' },
    { id: 'mod_exchange3', title: '', img: '/assets/img/cover_images/mod_exchange3.jpg' },
    { id: 'mod_network1', title: '', img: '/assets/img/cover_images/mod_network1.jpg' },
    { id: 'mod_network2', title: '', img: '/assets/img/cover_images/mod_network2.jpg' },
    { id: 'mod_network3', title: '', img: '/assets/img/cover_images/mod_network3.jpg' },
    { id: 'mod_network4', title: '', img: '/assets/img/cover_images/mod_network4.jpg' },
    { id: 'mod_security1', title: '', img: '/assets/img/cover_images/mod_security1.jpg' },
    { id: 'mod_security2', title: '', img: '/assets/img/cover_images/mod_security2.jpg' },
    { id: 'mod_security3', title: '', img: '/assets/img/cover_images/mod_security3.jpg' },
    { id: 'retail', title: '', img: '/assets/img/cover_images/retail.jpg' },
];

export const coverPageList: CoverImage[] = [
    { id: 'basic', title: 'Basic', img: '/assets/img/themes/basic.png' },
    { id: 'basic2', title: 'Basic2', img: '/assets/img/themes/basic2.png' },
    { id: 'classic', title: 'Classic', img: '/assets/img/themes/classic.png' },
    { id: 'classic2', title: 'Classic2', img: '/assets/img/themes/classic2.png' },
    { id: 'contemporary', title: 'Contemporary', img: '/assets/img/themes/contemporary.png' },
    { id: 'modern', title: 'Modern', img: '/assets/img/themes/modern.png' },
    { id: 'modern2', title: 'Modern2', img: '/assets/img/themes/modern2.png' },
    { id: 'professional', title: 'Professional', img: '/assets/img/themes/professional.png' },
    { id: 'tech2', title: 'Tech2', img: '/assets/img/themes/tech2.png' },
];

export const infographicList: CoverImage[] = [
    {
        id: 'infographicClassic',
        title: 'Classic',
        img: '/assets/img/infographics/infographicThumbnailClassic.png',
    },
    {
        id: 'infographicProfessional',
        title: 'Professional',
        img: '/assets/img/infographics/infographicThumbnailProfessional.png',
    },
];

export const thColorSchemes: ColorScheme[] = [
    { name: 'Standard', c1: '#0044CE', c2: '#BF4435', c3: '#72DDFC' },
    { name: 'Standard2', c1: '#003399', c2: '#800000', c3: '#008000' },
    { name: 'Blue', c1: '#0000FF', c2: '#A6CAF0', c3: '#6666CC' },
    { name: 'Red', c1: '#FF0000', c2: '#FFCCCC', c3: '#FF7C80' },
    { name: 'Green', c1: '#336633', c2: '#66FF66', c3: '#33CC66' },
    { name: 'Neutral', c1: '#CCCC66', c2: '#CCCC99', c3: '#FFFFCC' },
    { name: 'Violet', c1: '#333366', c2: '#666699', c3: '#9999FF' },
    { name: 'Purple', c1: '#660066', c2: '#663399', c3: '#9933CC' },
    { name: 'Fruity', c1: '#99CCCC', c2: '#FF6699', c3: '#FFFFCC' },
    { name: 'Sunny', c1: '#FF3366', c2: '#FF6633', c3: '#FFCC33' },
    { name: 'Camo', c1: '#666633', c2: '#339900', c3: '#999900' },
    { name: 'Triad', c1: '#3333CC', c2: '#008000', c3: '#663300' },
    { name: 'Triad2', c1: '#003366', c2: '#CC0000', c3: '#CCCC33' },
    { name: 'Triad3', c1: '#6600CC', c2: '#CC9933', c3: '#006633' },
    { name: 'Triad4', c1: '#3333CC', c2: '#CC3300', c3: '#99FF33' },
];

export const themeList: ThemeObject[] = [
    { name: 'Default Style', theme: new DefaultTheme() },
    { name: 'Helvetica Earth', theme: new HelveticaEarth() },
    { name: 'Helvetica', theme: new Helvetica() },
    { name: 'Modern Blaze', theme: new ModernBlaze() },
    { name: 'Modern Bold', theme: new ModernBold() },
    { name: 'Modern Graphite', theme: new ModernGraphite() },
    { name: 'Modern Oceanic', theme: new ModernOceanic() },
    { name: 'Modern Optimism', theme: new ModernOptimism() },
    { name: 'Modern Royal', theme: new ModernRoyal() },
    { name: 'Modern Serenity', theme: new ModernSerenity() },
    { name: 'Optima Blue', theme: new OptimaBlue() },
    { name: 'Tahoma Black', theme: new TahomaBlack() },
    { name: 'Times New Roman', theme: new TimesNewRoman() },
];

export const dateFormat: string[] = ['dd-MMM-yyyy', 'MM/dd/yyyy', 'yyyy/MM/dd', 'dd/MM/yyyy'];

export const paperSize: SimpleDropdownObject[] = [
    { code: 'A4', name: 'A4' },
    { code: 'L_8-5_11', name: 'Letter (8.5"x11")' },
];

export const languages: { [key: string]: string } = {
    'nl-NL': 'Dutch',
    'en-US': 'English (US)',
    'fr-FR': 'French',
    'fr-CA': 'French (Canada)',
    'de-DE': 'German',
    'it-IT': 'Italian',
    //'pt-BR': 'Portuguese (Brasil)',
    // 'es-ES': 'Spanish',
};

export const languagesArray: SimpleDropdownObject[] = [
    { code: 'nl-NL', name: 'Dutch' },
    { code: 'en-US', name: 'English (US)' },
    { code: 'fr-FR', name: 'French' },
    { code: 'fr-CA', name: 'French (Canada)' },
    { code: 'de-DE', name: 'German' },
    { code: 'it-IT', name: 'Italian' },
    // { code: 'pt-BR', name: 'Portuguese (Brasil)' },
    // { code: 'es-ES', name: 'Spanish' },
];

export const currency: SimpleDropdownObject[] = [
    { code: '€', name: '€' },
    { code: 'GBP', name: '£' },
    { code: 'USD', name: '$' },
    { code: 'CHF', name: 'CHF' },
    { code: 'MYR', name: 'RM' },
    { code: 'y', name: '¥' },
    { code: 'INR', name: 'र' },
    { code: 'ZAR', name: 'R' },
];

export const currencyFormatterCode: { [key: string]: string } = {
    '€': 'EUR',
    'GBP': 'GBP',
    'USD': 'USD',
    'CHF': 'CHF',
    'MYR': 'MYR',
    'y': 'JPY',
    'INR': 'INR',
    'ZAR': 'ZAR',
};

export const fontFamily: string[] = ['Arial', 'Helvetica', 'Optima', 'Tahoma', 'Times New Roman'];

export const customizedNameText = ' (customized)';
