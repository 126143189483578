<div class="ui card">
    <div class="content">
        <div class="header">
            PROCEDURES
            <!--<a href="#" class="settings"><i class="setting icon"></i></a>-->
            <a (click)="addDialog()" class="viewMoreLink">ADD</a>
        </div>
    </div>
    <div class="content">
        <div *ngIf="!SOPs || SOPs.length == 0">No Procedures yet. Add some.</div>
        <ul class="striped" *ngIf="SOPs && SOPs.length != 0">
            <li
                *ngFor="let SOP of SOPs ? (showAll ? SOPs : SOPs.slice(0, 4)) : []; let i = index"
                (click)="selectedIndex = i; editDialog(SOP)"
                style="cursor: pointer"
                tabindex="0">
                <div
                    style="
                        display: inline-block;
                        color: #4183c4;
                        margin: -10px;
                        margin-right: 20px;
                    ">
                    <i class="file outline icon" style="margin: 10px"></i>
                </div>
                {{ SOP.Title }}
                <div style="display: inline-block; float: right">
                    <a><i class="chevron right icon"></i></a>
                </div>
            </li>
        </ul>
        <a
            *ngIf="SOPs.length - 4 > 0"
            (click)="showAll = !showAll"
            style="float: right; padding-top: 10px">
            {{ showAll ? 'HIDE LAST ' + (SOPs.length - 4) : 'SHOW ' + (SOPs.length - 4) + ' MORE' }}
        </a>
    </div>
</div>

<sm-modal class="md" title="Add Procedure" #addModal>
    <modal-content>
        <div>
            <div class="ui form big">
                <input
                    type="text"
                    [(ngModel)]="selectedSOP.Title"
                    placeholder="Procedure Title"
                    style="margin-bottom: 10px"
                    class="required"
                    [class.invalidTitle]="invalidTitle" />
            </div>
            <div style="display: block" [class.invalidBody]="invalidBody">
                <textarea
                    id="ckadd"
                    name="ckadd"
                    style="max-width: 547px; max-height: 138px"
                    rows="20"
                    cols="2000"
                    #ckadd></textarea>
            </div>
        </div>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" class="cancel" (onclick)="closeSOP()">Cancel</itc-button>
        <itc-button type="primary" (onclick)="addSOP()">Add</itc-button>
    </modal-actions>
</sm-modal>

<sm-modal class="md" title="{{ editMode ? 'Edit Procedure' : 'Procedure Details' }}" #viewModal>
    <modal-content>
        <div>
            <h3 *ngIf="!editMode">{{ selectedSOP.Title }}</h3>
            <div class="ui form big">
                <input
                    *ngIf="editMode"
                    type="text"
                    [(ngModel)]="tempTitle"
                    placeholder="Procedure Title"
                    style="margin-bottom: 10px" />
            </div>
        </div>
        <div>
            <div
                *ngIf="!editMode"
                [innerHTML]="
                    (selectedSOP.Body
                        ? selectedSOP.Body
                        : 'No description yet; click Edit to add one'
                    ) | safeHtml
                "
                style="word-break: break-word; word-wrap: break-word"></div>
            <div [hidden]="!editMode">
                <div style="display: block">
                    <textarea
                        id="ckview"
                        name="ckview"
                        style="max-width: 547px; max-height: 138px"
                        rows="20"
                        cols="2000"
                        #ckview></textarea>
                </div>
            </div>
        </div>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" (onclick)="editMode ? editSOP(false) : viewModal.hide()">
            {{ editMode ? 'Cancel' : 'Close' }}
        </itc-button>
        <itc-button type="secondary" (onclick)="editSOP(true)">
            {{ editMode ? 'Reset' : 'Edit' }}
        </itc-button>
        <itc-button
            type="danger"
            class="cancel"
            *ngIf="!editMode"
            (onclick)="deleteSOP(selectedIndex)">
            Delete
        </itc-button>
        <itc-button type="primary" *ngIf="editMode" (onclick)="saveSOP()">Save</itc-button>
    </modal-actions>
</sm-modal>
