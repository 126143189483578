import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NotificationService } from '../notification/notification.service';
import { ProgressStep } from '../progress-tracker/progress-tracker.model';
import { ItcProgressTrackerService } from '../progress-tracker/progress-tracker.service';
import { IpRange } from 'app/sites/shared/ip-range/ip-range.component';
import { IpRangeService } from '../../../sites/shared/ip-range/ip-range.service';
import { Observable } from 'rxjs';

@Component({
    templateUrl: './itc-example.component.html',
    styleUrls: ['./itc-example.component.scss'],
})
export class ItcExampleComponent implements OnInit {
    // selectedView = 'button';
    selectedView = 'cmo-status-card';
    notificationBannerForm: UntypedFormGroup;
    notificationToastForm: UntypedFormGroup;
    checkboxModel = true;
    checkboxControl = new UntypedFormControl();
    checkboxModelDisabled = false;
    checkboxModelDisabledTrue = true;
    checkboxModelIndeterminate = 'mixed';
    toggleModel = true;
    toggleCtrl = new UntypedFormControl(false);
    radio1Model = 3;
    radio1Ctrl = new UntypedFormControl(4);
    toggleOnDisabledModel = true;
    date = '';
    dateObj = new Date();
    progressTrackerExample: ProgressStep[];
	private _centerTextDonutChartTotalNumber: number;
	get centerTextDonutChartTotalNumber(): number {
		return this._centerTextDonutChartTotalNumber;
	}

	set centerTextDonutChartTotalNumber(value: number) {
		this._centerTextDonutChartTotalNumber = value;
		this.cdr.detectChanges();
	}

	private _centerTextWithOffsetDonutChartTotalNumber: number;
	get centerTextWithOffsetDonutChartTotalNumber(): number {
		return this._centerTextWithOffsetDonutChartTotalNumber;
	}

	set centerTextWithOffsetDonutChartTotalNumber(value: number) {
		this._centerTextWithOffsetDonutChartTotalNumber = value;
		this.cdr.detectChanges();
	}

    iprangeFormControl = new FormControl<IpRange[]>([
        { isChecked: false, ip: '1.1.1.1' },
        { isChecked: false, ip: '2.2.2.0-2.2.2.12' },
    ]);
    iprangeModel = [
        { isChecked: false, ip: '1.1.1.1' },
        { isChecked: false, ip: '2.2.2.0-2.2.2.12' },
    ];
    isValid: Observable<boolean>;
    loaderComplete = false;

    constructor(
        private notificationService: NotificationService,
        public progressTrackerService: ItcProgressTrackerService,
        private ipRangeService: IpRangeService,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        this.notificationBannerForm = new UntypedFormGroup({
            bannerTitle: new UntypedFormControl('Banner Title'),
            bannerText: new UntypedFormControl('Banner Text'),
            bannerType: new UntypedFormControl('info'),
            bannerLocation: new UntypedFormControl('location1'),
        });
        this.notificationToastForm = new UntypedFormGroup({
            toastTitle: new UntypedFormControl('Toast Title'),
            toastText: new UntypedFormControl('Toast Text'),
            toastType: new UntypedFormControl('info'),
        });

        this.progressTrackerExample = [
            {
                label: 'Step 1 but it is too long because I wanna see what happens',
            },
            { label: 'Step 2' },
            { label: 'Step 3' },
            { label: 'Step 4' },
            { label: 'Step 5' },
            { label: 'Step 6' },
            { label: 'Step 7' },
            { label: 'Step 8' },
            { label: 'Step 9' },
        ];

        setTimeout(() => {
            this.isValid = this.ipRangeService.getValid();
        }, 0);
    }

    bannerOnClose(msg) {
        alert(msg);
    }

    showBannerNotification() {
        let bannerTitle = this.notificationBannerForm.get('bannerTitle').value,
            bannerText = this.notificationBannerForm.get('bannerText').value,
            bannerType = this.notificationBannerForm.get('bannerType').value,
            bannerLocation = this.notificationBannerForm.get('bannerLocation').value;
        if (bannerTitle == null && bannerText == null) {
            alert('banner title or text are required');
        } else {
            switch (bannerType) {
                case 'info':
                    this.notificationService.banner.info(bannerTitle, bannerText, bannerLocation);
                    break;
                case 'success':
                    this.notificationService.banner.success(
                        bannerTitle,
                        bannerText,
                        bannerLocation
                    );
                    break;
                case 'warning':
                    this.notificationService.banner.warning(
                        bannerTitle,
                        bannerText,
                        bannerLocation
                    );
                    break;
                case 'error':
                    this.notificationService.banner.error(bannerTitle, bannerText, bannerLocation);
                    break;
                case 'download':
                    this.notificationService.toast.download(
                        bannerTitle,
                        bannerText,
                        bannerLocation
                    );
                    break;
                default:
                    break;
            }
        }
    }

    showToastNotification() {
        let toastTitle = this.notificationToastForm.get('toastTitle').value,
            toastText = this.notificationToastForm.get('toastText').value,
            toastType = this.notificationToastForm.get('toastType').value;

        if (toastTitle == null && toastText == null) {
            alert('Toast Title OR Text are required');
        }
        switch (toastType) {
            case 'info':
                this.notificationService.toast.info(toastTitle, toastText);
                break;
            case 'success':
                this.notificationService.toast.success(toastTitle, toastText);
                break;
            case 'warning':
                this.notificationService.toast.warning(toastTitle, toastText);
                break;
            case 'error':
                this.notificationService.toast.error(toastTitle, toastText);
                break;
            case 'download':
                this.notificationService.toast.download(toastTitle, toastText);
                break;
        }
    }

    customTooltip(hti) {
        if (hti.name == 'placeholder') {
            return '';
        }
        return `
            <b>Custom Tooltip</b>: ${hti.name} - ${hti.value}
        `;
    }

    tabChangedYo(val) {
        console.log('val', val);
    }

    cardClicked() {
        console.log('Card clicked');
    }

    // rangeChange(val, calendar) {
    //     if (this.date[1] !== null) {
    //         setTimeout(() => {
    //             console.log('we got a range');
    //             let rangeItems = calendar.el.nativeElement.querySelectorAll('.p-highlight');
    //             rangeItems.forEach((item) => {
    //                 item.parentNode.classList.add('range-value');
    //             });
    //             rangeItems[0].parentNode.classList.add('range-value-start');
    //             rangeItems[rangeItems.length - 1].parentNode.classList.add('range-value-end');
    //         }, 0);
    //     } else {
    //         document
    //             .querySelectorAll('.range-value, .range-value-start, .renage-value-end')
    //             .forEach((item) => {
    //                 item.classList.remove('range-value', 'range-value-start', 'range-value-end');
    //             });
    //     }
    // }
}
