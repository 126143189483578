import { Component, OnInit } from '@angular/core';

import { AuthService, Roles, SiteRoles } from 'app/core/auth/auth.service';
import { SiteService } from 'app/sites/shared/site.service';
import { Site } from 'app/sites/shared/site.model';

@Component({
    selector: 'sds-route-test',
    templateUrl: './route-test.component.html',
    styleUrls: ['./route-test.component.css'],
})
export class RouteTestComponent implements OnInit {
    currentTestSite: any;
    site: Site;
    sites: string[];
    escapedSiteName: string = '';
    user: any;
    userRole: string;
    siteDropdown: string;
    sitesLoading: boolean = false;

    routes = [
        {
            route: '["/site", ":name", "home", "data-collectors"]',
            link: '/site/:name/home/data-collectors',
            onFail: '403',
            perms: [1, 1, 0, 1, 1, 0, 0],
        },
        {
            route: '["/site", ":name", "home", "users"]',
            link: '/site/:name/home/users',
            onFail: '403',
            perms: [1, 1, 0, 1, 0, 0, 0],
        },
        {
            route: '["/site", ":name", "home", "roles"]',
            link: '/site/:name/home/roles',
            onFail: '403',
            perms: [1, 1, 0, 1, 0, 0, 0],
        },
        {
            type: 'separator',
            message:
                '/site/:name/cyber-hawk/settings (redirects to /site/:name/cyber-hawk/settings/overview)',
        },
        {
            route: '["/site", ":name", "cyber-hawk", "settings", "overview"]',
            link: '/site/:name/cyber-hawk/settings/overview',
            message: '(Site must be CH)',
            onFail: '403',
            perms: [1, 1, 0, 1, 1, 0, 0],
        },
        {
            route: '["/site", ":name", "cyber-hawk", "settings", "scanwizard"]',
            link: '/site/:name/cyber-hawk/settings/scanwizard',
            message: '(Site must be CH)',
            onFail: '403',
            perms: [1, 1, 0, 1, 1, 0, 0],
        },
        {
            route: '["/site", ":name", "cyber-hawk", "settings", "scan-schedules"]',
            link: '/site/:name/cyber-hawk/settings/scan-schedules',
            message: '(Site must be CH)',
            onFail: '403',
            perms: [1, 1, 0, 1, 1, 0, 0],
        },
        {
            route: '["/site", ":name", "cyber-hawk", "settings", "policies"]',
            link: '/site/:name/cyber-hawk/settings/policies',
            message: '(Site must be CH)',
            onFail: '403',
            perms: [1, 1, 0, 1, 1, 0, 0],
        },
        {
            route: '["/site", ":name", "cyber-hawk", "settings", "email-groups"]',
            link: '/site/:name/cyber-hawk/settings/email-groups',
            message: '(Site must be CH)',
            onFail: '403',
            perms: [1, 1, 0, 1, 1, 0, 0],
        },
        {
            route: '["/site", ":name", "cyber-hawk", "settings", "summary-emails"]',
            link: '/site/:name/cyber-hawk/settings/summary-emails',
            message: '(Site must be CH)',
            onFail: '403',
            perms: [1, 1, 0, 1, 1, 0, 0],
        },
        {
            route: '["/site", ":name", "cyber-hawk", "settings", "email-configuration"]',
            link: '/site/:name/cyber-hawk/settings/email-configuration',
            message: '(Site must be CH)',
            onFail: '403',
            perms: [1, 1, 0, 1, 1, 0, 0],
        },
        {
            route: '["/site", ":name", "cyber-hawk", "settings", "weekly-notice"]',
            link: '/site/:name/cyber-hawk/settings/weekly-notice',
            message: '(Site must be CH)',
            onFail: '403',
            perms: [1, 1, 0, 1, 1, 0, 0],
        },
        {
            route: '["/site", ":name", "cyber-hawk", "settings", "login-alert-thresholds"]',
            link: '/site/:name/cyber-hawk/settings/login-alert-thresholds',
            message: '(Site must be CH)',
            onFail: '403',
            perms: [1, 1, 0, 1, 1, 0, 0],
        },
        {
            type: 'separator',
            message:
                '/site/:name/compliance-manager/settings (redirects to /site/:name/compliance-manager/settings/overview)',
        },
        {
            route: '["/site", ":name", "compliance-manager", "settings", "overview"]',
            link: '/site/:name/compliance-manager/settings/overview',
            message: '(Site must be CM)',
            onFail: '403',
            perms: [1, 1, 0, 1, 1, 0, 0],
        },
        {
            route: '["/site", ":name", "compliance-manager", "settings", "scanwizard"]',
            link: '/site/:name/compliance-manager/settings/scanwizard',
            message: '(Site must be CM)',
            onFail: '403',
            perms: [1, 1, 0, 1, 1, 0, 0],
        },
        {
            route: '["/site", ":name", "compliance-manager", "settings", "scan-schedules"]',
            link: '/site/:name/compliance-manager/settings/scan-schedules',
            message: '(Site must be CM)',
            onFail: '403',
            perms: [1, 1, 0, 1, 1, 0, 0],
        },
        {
            route: '["/site", ":name", "compliance-manager", "settings", "reports"]',
            link: '/site/:name/compliance-manager/settings/reports',
            message: '(Site must be CM)',
            onFail: '403',
            perms: [1, 1, 0, 1, 1, 0, 0],
        },
        {
            route: '["/site", ":name", "compliance-manager", "settings", "reports", "theme"]',
            link: '/site/:name/compliance-manager/settings/reports/:panel',
            message: '(Site must be CM)',
            onFail: '403',
            perms: [1, 1, 0, 1, 1, 0, 0],
        },
        {
            route: '["/site", ":name", "compliance-manager", "settings", "invites"]',
            link: '/site/:name/compliance-manager/settings/invites',
            message: '(Site must be CM)',
            onFail: '403',
            perms: [1, 1, 0, 1, 1, 0, 0],
        },
        {
            route: '["/site", ":name", "compliance-manager", "settings", "itcomplete"]',
            link: '/site/:name/compliance-manager/settings/itcomplete',
            message: '(Site must be CM)',
            onFail: '403',
            perms: [1, 1, 0, 1, 1, 0, 0],
        },
        {
            type: 'separator',
            message: '/settings (redirects to /settings/general)',
        },
        {
            route: '["/settings", "general"]',
            link: '/settings/general',
            onFail: 'Toast',
            perms: [1, 1, 0, 0, 0, 0, 0],
        },
        {
            route: '["/settings", "branding"]',
            link: '/settings/branding',
            onFail: 'Toast',
            perms: [1, 1, 0, 0, 0, 0, 0],
        },
        {
            route: '["/settings", "connections"]',
            link: '/settings/connections',
            onFail: 'Toast',
            perms: [1, 1, 0, 0, 0, 0, 0],
        },
        {
            route: '["/settings", "admin-alerts"]',
            link: '/settings/admin-alerts',
            onFail: 'Toast',
            perms: [1, 1, 0, 0, 0, 0, 0],
        },
        {
            route: '["/settings", "users"]',
            link: '/settings/users',
            onFail: 'Toast',
            perms: [1, 1, 0, 0, 0, 0, 0],
        },
        {
            route: '["/settings", "data-collectors"]',
            link: '/settings/data-collectors',
            onFail: 'Toast',
            perms: [1, 1, 0, 0, 0, 0, 0],
        },
        {
            route: '["/settings", "service-plans"]',
            link: '/settings/service-plans',
            onFail: 'Toast',
            perms: [1, 1, 0, 0, 0, 0, 0],
        },
        {
            type: 'noroute',
            message:
                "/settings/service-plans/:panel (Can't really link, needs to be clicked from /settings/service-plans)",
            onFail: 'Toast',
            perms: [1, 1, 0, 0, 0, 0, 0],
        },
        {
            type: 'noroute',
            message:
                "/settings/service-plans/manage-plans/:name (Can't really link, needs to be clicked from /settings/service-plans/manage-plans",
            onFail: 'Toast',
            perms: [1, 1, 0, 0, 0, 0, 0],
        },
        {
            route: '["/settings", "email-groups"]',
            link: '/settings/email-groups',
            onFail: 'Toast',
            perms: [1, 1, 0, 0, 0, 0, 0],
        },
        {
            route: '["/settings", "k1"]',
            link: '/settings/k1',
            onFail: 'Toast',
            perms: [1, 1, 0, 0, 0, 0, 0],
        },
        {
            route: '["/settings", "itcomplete"]',
            link: '/settings/itcomplete',
            onFail: 'Toast',
            perms: [1, 1, 0, 0, 0, 0, 0],
        },
    ];

    constructor(private siteService: SiteService, private authService: AuthService) {}

    ngOnInit() {
        console.log(this.routes);
        this.user = this.authService.getIdentity();
        this.userRole = this.processUserRole(this.user.roleId);

        if (localStorage.getItem('currentTestSite') != '') {
            this.currentTestSite = JSON.parse(localStorage.getItem('currentTestSite'));
            this.escapedSiteName = this.currentTestSite.Name.replace(/&/g, '%26');
            document.getElementById('routeTestContainer').className =
                this.currentTestSite.UserRoles.split(',').join(' ') + ' ' + this.userRole;
        } else {
            this.getSites();
        }
    }

    getSites() {
        this.siteService.getSites().then((res) => {
            this.sites = res.map((site) => site.Name);
            this.sites.sort(function (a, b) {
                return a.toLowerCase().localeCompare(b.toLowerCase());
            });

            if (this.currentTestSite && this.sites.indexOf(this.currentTestSite.Name) > -1) {
                this.siteDropdown = this.currentTestSite.Name;
            } else {
                this.chooseSite(this.sites[0]);
            }
        });
    }

    chooseSite(site) {
        this.escapedSiteName = site.replace(/&/g, '%26');
        this.siteDropdown = site;
        this.siteService.getSiteByName(site).then((site) => {
            this.currentTestSite = {
                Name: site.Name,
                Type: site.Type,
                UserRoles: site.UserRoles || 'Null',
            };
            this.processSiteRoles(this.currentTestSite);
            document.getElementById('routeTestContainer').className =
                this.currentTestSite.UserRoles.split(',').join(' ') + ' ' + this.userRole;
            localStorage.setItem('currentTestSite', JSON.stringify(this.currentTestSite));
            this.sitesLoading = false;
        });
    }

    processUserRole(roleId) {
        if (roleId == Roles.Admin) {
            return 'Admin';
        } else if (roleId == Roles.Restricted) {
            return 'Restricted';
        } else if (roleId == Roles.Master) {
            return 'Master';
        } else if (roleId == Roles.SiteRestricted) {
            return 'SiteRestricted';
        }
    }

    processSiteRoles(site) {
        site.UserRoles = site.UserRoles.replace('SDS_AUDITOR', 'Auditor');
        site.UserRoles = site.UserRoles.replace('SDS_SITEADMIN', 'SiteAdmin');
        site.UserRoles = site.UserRoles.replace('SDS_TECH', 'Tech');
        site.UserRoles = site.UserRoles.replace('AG_SME', 'SME');
        site.UserRoles = site.UserRoles.replace('IND_CLIENT', 'Client');
    }

    processRoute(route) {
        return JSON.parse(route.route.replace(':name', this.escapedSiteName));
    }

    resetSite() {
        this.sitesLoading = true;
        this.currentTestSite = '';
        this.escapedSiteName = '';
        this.getSites();
    }
}
