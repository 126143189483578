<div
    class="fluid outerContainer"
    id="page_outer_container"
    [class.light]="theme == 'light'"
    [class.dark]="theme == 'dark'">
    <app-nav></app-nav>
    <div class="page_content full height fluid" [ngClass]="{ shifted: !isAuth }">
        <div class="ui fluid container" style="display: flex; height: 100%">
            <div style="width: 100%">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
    <!--<app-toast-area></app-toast-area>-->
    <p class="version">{{ version }}</p>
</div>
<sds-announcements></sds-announcements>
