import { Component, OnInit } from '@angular/core';

import { NavService, ALL_SITES } from 'app/core/nav/nav.service';

@Component({
    selector: 'sds-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
    constructor(private navService: NavService) {}

    ngOnInit() {
        this.navService.setSite(ALL_SITES);
    }
}
