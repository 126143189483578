export class GlobalUser {
    public Id: number;
    public Username: string;
    public Password: string;
    public FirstName: string;
    public LastName: string;
    public HasPrivacy: boolean;
    public AccessRole?: number;
    public SiteRoles?: any;
    public TwoFactorEnabled: boolean;
    public IsManagedByAccessGroups: boolean;
}
