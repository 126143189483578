import { Component, OnDestroy, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MessageService } from 'app/core/message.service';
import { ScanDataComputer, ScanDataService } from 'app/scans';
import { UiService } from 'app/core/ui.service';
import { Site } from 'app/sites/shared/site.model';
import { PING_SITE, SITE } from 'app/sites/shared/constants';
import { FilterOption } from 'app/sites/shared/data-filter.model';
import { SiteService } from 'app/sites/shared/site.service';
import { NdpService } from 'app/sites/shared/ndp.service';
import { Router } from '@angular/router';
import { AssessmentService } from '../../../shared/assessment.service';

@Component({
    selector: 'sds-site-data-computers',
    templateUrl: './site-data-computers.component.html',
    styleUrls: ['./site-data-computers.component.css'],
})
export class SiteDataComputersComponent implements OnInit, OnDestroy {
    constructor(
        private messageService: MessageService,
        private scanDataService: ScanDataService,
        private uiService: UiService,
        private datePipe: DatePipe,
        private siteService: SiteService,
        private ndpService: NdpService,
        private router: Router,
        private assessmentService: AssessmentService
    ) {}

    computers: ScanDataComputer[] = [];
    filtered1: ScanDataComputer[] = [];
    filteredComputers: ScanDataComputer[] = [];
    sortedComputers: ScanDataComputer[] = [];
    pagedComputers: ScanDataComputer[] = [];

    numActive: number = 0;
    numEnabled: number = 0;
    sortColumn: string = '';
    sortDirection: string = '';
    filterOptions: FilterOption[] = [
        { id: '_all', name: 'All' },
        { id: '_active', name: 'Active' },
        { id: '_inactive', name: 'Inactive' },
        { id: '_enabled', name: 'Enabled' },
        { id: '_notEnabled', name: 'Not Enabled' },
    ];

    loadingComplete: boolean = false;
    mergeInProgress: boolean = false;
    emptyStateText: string = 'There is no scan data in the current assessment.';
    sub: any;
    dataExplorerType = 'Indoc';

    breadcrumbs = [{ text: 'Network Detective' }, { text: 'Data Explorer' }, { text: 'Computers' }];

    ngOnInit() {
        this.dataExplorerType = this.ndpService.getDataExplorerType();
        this.sub = this.messageService.on(SITE).subscribe(async (site: Site) => {
            if (site) {
                this.uiService.setTitle('Computers', site.Name);
                if (this.siteService.isNDPro(site)) {
                    this.breadcrumbs[0].text = 'Network Detective Pro';
                }
                const assessmentId = await this.ndpService.getAssessmentIdForDataExplorer(
                    site,
                    this.dataExplorerType
                );

                if (assessmentId) {
                    this.assessmentService
                        .getProgressStatusBySiteParams(site.Id, assessmentId, [
                            'NEEDS_NDP_MERGE',
                            'UPDATE_NDP_PORTALDATA',
                        ])
                        .then((res) => {
                            this.mergeInProgress = res;
                        });
                }
                this.getComputers(site, assessmentId);
            }
        });

        this.messageService.broadcast(PING_SITE);

        this.ndpService.createDataExplorerBreadcrumbs('Computers');
    }

    getComputers(site: Site, assessmentId: number) {
        this.scanDataService
            .getComputers(site.Id, false, assessmentId)
            .then((computers: ScanDataComputer[]) => {
                this.computers = computers;

                this.numActive = 0;
                this.numEnabled = 0;

                for (let computer of computers) {
                    if (computer.Active) this.numActive++;
                    if (computer.Enabled) this.numEnabled++;
                }

                console.log(this.computers);
                this.sortColumn = 'Name';
                this.sortDirection = 'asc';
                this.filterComputers();
                this.emptyStateText = this.scanDataService.getEmptyStateText(
                    this.computers,
                    this.mergeInProgress,
                    'computer'
                );
                this.loadingComplete = true;
            })
            .catch((err) => console.error(err));
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    filterComputers(filteredData?: any) {
        if (filteredData) this.filteredComputers = filteredData;
        else this.filteredComputers = this.computers;

        this.filtered1 = this.filteredComputers;
        this.sortComputers();
    }

    sortComputers() {
        if (this.sortColumn != 'LastLoginFormatted') {
            this.sortedComputers = this.filteredComputers.sort((a, b) => {
                let cola = a[this.sortColumn],
                    colb = b[this.sortColumn];

                if (typeof cola === 'string') cola = cola.toLocaleLowerCase();
                if (typeof colb === 'string') colb = colb.toLocaleLowerCase();

                if (this.sortDirection === 'desc') {
                    return cola < colb ? 1 : cola === colb ? 0 : -1;
                } else {
                    return cola > colb ? 1 : cola === colb ? 0 : -1;
                }
            });
        }
        //this is to properly sort the dates. To deal with the problem of "Never" not wanting to sort properly in ascending order, first sort to descending then ascending
        if (this.sortColumn == 'LastLoginFormatted') {
            //sort in descending order
            if (this.sortDirection === 'desc') {
                this.sortedComputers = this.filteredComputers.sort((a, b) => {
                    let cola = a[this.sortColumn],
                        colb = b[this.sortColumn];

                    if (cola == 'Never') {
                        return cola === colb ? 0 : 1;
                    } else {
                        (cola = new Date(a[this.sortColumn])),
                            (colb = new Date(b[this.sortColumn]));
                        return cola < colb ? 1 : cola === colb ? 0 : -1;
                    }
                });
            }
            //sort in ascending order
            else {
                this.sortedComputers = this.filteredComputers.sort((a, b) => {
                    //to sort in ascending order, first sort in descending order to gather the logins that are "Never"
                    let cola = a[this.sortColumn],
                        colb = b[this.sortColumn];

                    if (cola == 'Never') {
                        return cola === colb ? 0 : 1;
                    } else {
                        (cola = new Date(a[this.sortColumn])),
                            (colb = new Date(b[this.sortColumn]));
                        return cola < colb ? 1 : cola === colb ? 0 : -1;
                    }
                });

                this.sortedComputers = this.filteredComputers.sort((a, b) => {
                    //then sort in ascending order
                    let cola = a[this.sortColumn],
                        colb = b[this.sortColumn];

                    if (cola == 'Never') {
                        return cola === colb ? 0 : -1;
                    } else {
                        (cola = new Date(a[this.sortColumn])),
                            (colb = new Date(b[this.sortColumn]));
                        return cola > colb ? 1 : cola === colb ? 0 : -1;
                    }
                });
            }
        }

        this.filteredComputers = [...this.sortedComputers];
    }

    onFiltered($event) {
        if ($event) this.filteredComputers = $event;

        this.filterComputers(this.filteredComputers);
    }

    onSorted(ev?: any) {
        if (ev) {
            this.sortColumn = ev.sortColumn;
            this.sortDirection = ev.sortDirection;
            this.sortComputers();
        }
    }
}
