<div class="site_container" (click)="gotoSite(site.site)" [attr.data-sid]="site.site.Id">
    <!-- Grid View Blocks-->
    <div class="header" *ngIf="cardViewMode == 'grid'">
        <itc-button
            type="icon"
            icon="fa-chevron-down"
            (click)="showDetails($event, site)"></itc-button>

        <p class="siteNameContainer" title="{{ site.site.Name }}">{{ site.site.Name }}</p>
        <p *ngIf="site.site.Type != '' && site.site.Type != 'CM2'" class="siteIssuesContainer">
            <a
                class="siteIssues"
                [class.hasTodos]="site.site.OpenTodos"
                [routerLink]="
                    '/site/' +
                    site.site.Name +
                    (site.site.Type == 'standard' ? '/cyber-hawk/todo' : '/compliance-manager/todo')
                "
                title="Open ToDos">
                <span class="todoCount" style="display: none">
                    {{ site?.site?.OpenTodos }}
                </span>
                <fa-icon [icon]="['fas', 'circle-check']"></fa-icon>
            </a>
        </p>
    </div>
    <div class="content" *ngIf="cardViewMode == 'grid'">
        <!-- for grid view -->
        <div class="site_type_container">
            <i
                *ngFor="let siteType of siteTypeIcons"
                class="site_type {{ siteType.class }}"
                [title]="siteType.title"></i>
        </div>
        <div class="detailsContainer" style="position: relative" (click)="$event.stopPropagation()">
            <sds-site-details [site]="site"></sds-site-details>
        </div>
    </div>

    <!-- list view cards -->
    <div class="header" *ngIf="cardViewMode == 'list'">
        <p class="siteNameContainer" title="{{ site.site.Name }}">{{ site.site.Name }}</p>
        <p *ngIf="site.site.Type != '' && site.site.Type != 'CM2'" class="siteIssuesContainer">
            <a
                class="siteIssues"
                [class.hasTodos]="site.site.OpenTodos"
                [routerLink]="
                    '/site/' +
                    site.site.Name +
                    (site.site.Type == 'standard' ? '/cyber-hawk/todo' : '/compliance-manager/todo')
                "
                title="Open ToDos">
                <span class="todoCount" style="display: none">
                    {{ site?.site?.OpenTodos }}
                </span>
                <fa-icon [icon]="['fas', 'circle-check']"></fa-icon>
            </a>
        </p>
        <p *ngIf="site.site.Type == '' || site.site.Type == 'CM2'" class="siteIssuesContainer"></p>
        <div class="siteTypeContainer" *ngIf="cardViewMode == 'list'">
            <!-- for list view to make styling easier -->
            <div class="site_type_container">
                <i
                    *ngFor="let siteType of siteTypeIcons"
                    class="site_type {{ siteType.class }}"
                    [title]="siteType.title"
                    [innerHTML]="siteType.icon"></i>
            </div>
        </div>
        <itc-button
            type="icon"
            icon="fa-chevron-down"
            (click)="showDetails($event, site)"></itc-button>
    </div>
    <div class="content" *ngIf="cardViewMode == 'list'">
        <div class="detailsContainer" style="position: relative" (click)="$event.stopPropagation()">
            <sds-site-details [site]="site"></sds-site-details>
        </div>
    </div>
</div>
