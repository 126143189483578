<div class="cmStandardTable">
    <div
        *ngFor="let requirement of requirementList"
        [style.backgroundColor]="responseColors[requirement.Response]"
        [style.border]="
            selectedRequirement?.Id === requirement.Id
                ? '2px solid rgba(0,0,0,0.25)'
                : '2px solid ' + responseColors[requirement.Response] + ''
        "
        [style.cursor]="requirement.Response == '' ? 'not-allowed' : 'pointer'"
        (click)="requirementCellClicked(requirement)"
        [attr.title]="
            assessmentType == 'controlAssessment'
                ? requirement.Control.ControlId.length > 30
                    ? requirement.Control.ControlId
                    : null
                : requirement.RequirementId.length > 30
                ? requirement.RequirementId
                : null
        ">
        <ng-container *ngIf="assessmentType === 'controlAssessment'">
            {{ requirement.Control.ControlId }}
            <span *ngIf="requirement.HasOverrides">*</span>
        </ng-container>
        <ng-container *ngIf="assessmentType !== 'controlAssessment'">
            {{ requirement.RequirementId }}
            <span *ngIf="requirement.HasOverrides || requirementHasModifiedControls(requirement)">
                *
            </span>
        </ng-container>
    </div>
</div>
