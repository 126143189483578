import {
    ChangeDetectionStrategy,
    Component,
    Input,
    Output,
    ViewChild,
    EventEmitter,
} from '@angular/core';
import { ReactiveFormsModule, FormsModule, FormBuilder, FormControl } from '@angular/forms';
import { SemanticModalComponent } from 'app/semantic-legacy/components/modal/modal';
import { SharedModule } from 'app/shared/shared.module';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'cmo-exception-exclusion-modal',
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, FormsModule, SharedModule],
    templateUrl: './cmo-exception-exclusion-modal.component.html',
    styleUrls: ['./cmo-exception-exclusion-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmoExceptionExclusionModalComponent {
    @Input() set title(t: string) {
        this.modalTitle = t;
    }

    @Input() command: string;
    @Input() objectTitle: string;
    @Input() explanation: string;
    @Input() label: string;
    @Input() set note(n: string | null) {
        if (n) {
            this.noteInputControl.setValue(n);
        } else {
            this.noteInputControl.setValue('');
        }
    }
    @Input() set allowSaveOnVoid(a: boolean) {
        this._allowSaveOnVoid = a;
    }
    @Input() countInfo: string;

    @Output() save = new EventEmitter<string | null>();
    @Output() cancel = new EventEmitter<void>();

    modalTitle: string;
    _allowSaveOnVoid: boolean = false;
    noteInputControl = new FormControl<string | null>(null);

    @ViewChild('showExcModal') showExcModal: SemanticModalComponent;

    /** Show the modal with some default modal options */
    showModal(): void {
        this.showExcModal.show({
            onHide: () => {
                setTimeout(() => {
                    this.clear();
                }, 200);
            },
            autofocus: false,
            closable: true,
        });
    }

    doSave(): void {
        this.save.emit(this.noteInputControl.value);
        this.showExcModal.hide();
    }

    doCancel(): void {
        this.cancel.emit();
        this.showExcModal.hide();
    }

    clear(): void {
        this.noteInputControl.setValue('');
    }
}
