import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { RiskModalInfo } from 'app/sites/shared/riskModal/risk-modal.model';
import {
    Answer,
    RequirementResponse,
    RoWizardQuestion,
    WizardGroup,
    WizardSiteUser,
} from '../cm-wizard.model';
import { GrcService } from 'app/sites/shared/grc.service';
import { ErrorService } from 'app/core/error/error.service';
import { SiteService } from 'app/sites/shared/site.service';
import { NotificationService } from 'app/shared/itc/notification/notification.service';
import { Attachment } from 'app/sites/site/inform/attachment.model';
import * as saveAs from 'file-saver';
import { Site } from 'app/sites/shared/site.model';

declare var $: any;

@Component({
    selector: 'cm-read-only-wizard',
    templateUrl: './cm-read-only-wizard.component.html',
    styleUrls: ['./cm-read-only-wizard.component.scss'],
})
export class CmReadOnlyWizardComponent implements OnInit {
    roQuestion: RoWizardQuestion;
    public riskModalInfo: RiskModalInfo;

    siteUsers: WizardSiteUser[] = [];
    siteGroups: WizardGroup[] = [];
    answers: Answer[];
    @ViewChild('riskModal') riskModal: ElementRef;
    @ViewChild('readOnlyModal') readOnlyModal: ElementRef;
    @ViewChild('externalContentModal') externalContentModal: ElementRef;
    tabNumber = 1;
    url: string;
    requirementResponses: RequirementResponse[];
    site: Site;
    type: 'control' | 'requirement';
    reOpenPrevModal = true; // this is used to go back to the previous read only wizard modal
    commentsOpen = false;
    filesOpen = false;
    relatedTechIssuesOpen = false;
    allResourcesExpanded = false;
    assignedUsers: string[] = [];

    @HostListener('document:click', ['$event.target']) onClick(e) {
        if (e.classList.contains('ROlabelLink')) {
            this.url = e.innerText;
            $(this.externalContentModal.nativeElement).modal('show');
        }
    }

    constructor(
        private grcService: GrcService,
        private errorService: ErrorService,
        private siteService: SiteService,
        private notificationService: NotificationService
    ) {}

    ngOnInit() {
        this.siteService.app_getCurrentSite().then((site) => {
            this.onSite(site);
        });

        let resourcesOpen = localStorage.getItem('ROresourcesOpen');
        if (resourcesOpen) {
            let resourcesOpenObj = JSON.parse(resourcesOpen);
            this.relatedTechIssuesOpen = resourcesOpenObj.relatedTechIssuesOpen;
            this.commentsOpen = resourcesOpenObj.comments;
            this.filesOpen = resourcesOpenObj.files;
            this.allResourcesExpanded =
                this.relatedTechIssuesOpen && this.commentsOpen && this.filesOpen;
        }
    }

    async onSite(site) {
        this.site = site;
        this.requirementResponses = await this.grcService
            .getModuleState(site.Id, 'RequirementsAssessment')
            .catch((reason) => {
                this.errorService.logEvent(
                    'error',
                    'Requirements Assessment on site {0} failed to load for {USERNAME}: {1}',
                    [site.Name, reason]
                );
            });
    }

    showReadOnlyModal(res) {
        this.type = res.Type;
        if (res.Type === 'control') {
            this.roQuestion = res;
            this.assignedUsers = [];
            this.roQuestion.Assignments.forEach(Assignments => this.assignedUsers.push(Assignments.UserDisplayName));
        } else {
            if (this.requirementResponses?.length) {
                let requirementResponse = this.requirementResponses.find(
                    (resp) => resp.Requirement === res.Requirement.Id
                );
                if (requirementResponse) {
                    this.roQuestion = { ...res.Requirement, ...requirementResponse };
                } else {
                    this.roQuestion = res.Requirement;
                }
            } else {
                this.roQuestion = res.Requirement;
            }
        }
        // go back to readonlymodal view when user closes riskModal
        $(this.readOnlyModal.nativeElement).modal('attach events', '.backToModal');
        $(this.readOnlyModal.nativeElement).modal({ autofocus: false }).modal('show');

        $(this.readOnlyModal.nativeElement).find('#ROassignToDropUp').dropdown({
            action: 'nothing',
            selectedOnKeydown: false,
            direction: 'upward',
        });
    }

    showRiskModal(obj: RiskModalInfo, fromModal = true) {
        this.riskModalInfo = obj;
        this.riskModalInfo.SeverityDisplay = this.calculateDisplaySeverity(
            this.riskModalInfo.Severity
        );
        this.riskModalInfo.RiskDisplay = this.calculateDisplaySeverity(this.riskModalInfo.Score);
        this.riskModalInfo.ProbabilityDisplay = this.calculateDisplaySeverity(
            this.riskModalInfo.Probability
        );
        this.reOpenPrevModal = fromModal; // this is used to go back to the previous read only wizard modal
        if (fromModal) {
            this.reOpenPrevModal = true;
        }
        $(this.riskModal.nativeElement).modal({ autofocus: false }).modal('show');
    }

    calculateDisplaySeverity(num) {
        if (num > 70) {
            return 'high';
        } else if (num > 50) {
            return 'medium';
        } else {
            return 'low';
        }
    }

    navigateTo() {
        window.open(this.url, '_blank');
    }

    checkGroup(group) {
        if (this.type === 'control') {
            return this.roQuestion.Assignments.some((x) => group.Id === x.GroupId);
        } else {
            return group.Assigned?.value;
        }
    }

    downloadAttachment(attachment: Attachment) {
        this.notificationService.toast.info('File Download', 'Attachment download request sent...');
        this.siteService.downloadAttachment(this.site.Id, attachment.Id).then((res) => {
            var bin = window.atob(res);
            var ab = this.s2ab(bin);
            saveAs(new Blob([ab]), attachment.Name);
            this.notificationService.toast.success(
                'File Download',
                'Attachment download is complete.'
            );
        });
    }

    s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    }

    toggleResource(resource) {
        this[resource] = !this[resource];
        this.allResourcesExpanded =
            this.relatedTechIssuesOpen && this.commentsOpen && this.filesOpen;
        localStorage.setItem(
            'ROresourcesOpen',
            JSON.stringify({
                relatedTechIssuesOpen: this.relatedTechIssuesOpen,
                comments: this.commentsOpen,
                files: this.filesOpen,
            })
        );
    }

    toggleAllResources() {
        this.allResourcesExpanded = !this.allResourcesExpanded;
        this.relatedTechIssuesOpen = this.allResourcesExpanded;
        this.commentsOpen = this.allResourcesExpanded;
        this.filesOpen = this.allResourcesExpanded;
        localStorage.setItem(
            'ROresourcesOpen',
            JSON.stringify({
                relatedTechIssuesOpen: this.relatedTechIssuesOpen,
                comments: this.commentsOpen,
                files: this.filesOpen,
            })
        );
    }
}
