import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'print-layout',
    templateUrl: './print-layout.component.html',
    styleUrls: ['./print-layout.component.css'],
})
export class PrintLayoutComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
