<sds-breadcrumb-trail [crumbs]="breadcrumbs"></sds-breadcrumb-trail>

<header class="pageHeader cm1HasSelectAssessment">
    <h1>{{ site?.Type == 'CYBERINSURANCE' ? 'Cyber Insurance' : site?.Type }} Issues</h1>
    <itc-tag type="subtle" color="orange" *ngIf="riskRows">{{ riskRows?.length }}</itc-tag>
    <div class="flex-right" *ngIf="loadingComplete">
        <itc-button
            label="Go to Connections"
            type="primary"
            (onclick)="goToConnections()"></itc-button>
        <itc-button
            label="Export to BMS"
            type="primary"
            (onclick)="exportToBMS()"
            [loading]="exporting"
            [disabled]="noRisksReturned || exporting || !rowsChecked || disableExportButton"
            [tooltip]="!rowsChecked ? 'Select issues to export' : ''"></itc-button>
    </div>
</header>

<sds-loader [complete]="loadingComplete">
    <itc-banner type="info" *ngIf="loadingComplete && noRisksReturned && !noAssessment">
        No Issues identified for the current assessment.
    </itc-banner>

    <itc-banner type="info" title="No Assessments Found" *ngIf="noAssessment">
        There are not currently any assessments for this site. You can start the assessment from the
        To Do list, after installing the Compliance Manager Server.
    </itc-banner>

    <table class="itc-table" sortable-table (sorted)="onSorted($event)" *ngIf="!noRisksReturned">
        <thead>
            <tr>
                <th>
                    <itc-checkbox
                        [hidden]="!isSelectAllCheckBoxAvailable"
                        class="pointer"
                        [(ngModel)]="selectedAll"
                        (onChanged)="selectAll()"></itc-checkbox>
                </th>
                <th style="min-width: 90px" sortable-column="score">Risk Score</th>
                <th sortable-column="risk">Risk</th>
                <th>Risk Detail</th>
                <th style="min-width: 100px" sortable-column="bmsTicket">BMS Ticket #</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let risk of riskRows">
                <td>
                    <itc-checkbox
                        class="pointer"
                        (onChanged)="updateRowCount()"
                        [(ngModel)]="risk.IsChecked"
                        [value]="risk"
                        [hidden]="risk.IsArchived"></itc-checkbox>
                </td>
                <td>
                    <span class="riskscore {{ calculateDisplaySeverity(risk.RiskScore) }}">
                        {{ risk.RiskScore }}
                    </span>
                </td>
                <td>{{ risk.Title }}</td>
                <td class="pointer"><a (click)="showRiskInfoModal(risk)">View Details</a></td>
                <td>{{ risk.BmsTicket }}</td>
            </tr>
        </tbody>
    </table>
</sds-loader>
<sm-modal
    title="{{ riskModalInfo?.RiskDisplay }} Risk &nbsp;&nbsp; | &nbsp;&nbsp; {{
        riskModalInfo?.Title
    }}"
    class="{{ riskModalInfo?.RiskDisplay }}Risk md"
    #riskInfoModal>
    <modal-content>
        <div class="riskModalContentContainer" *ngIf="riskModalInfo">
            <div>
                <p class="riskSectionTitle">Risk Score</p>
                {{ riskModalInfo?.RiskScore }}
            </div>

            <div style="flex: 1; text-align: left">
                <p class="riskSectionTitle">Recommendations</p>
                {{ riskModalInfo?.Recommendation }}
                <ul class="riskModalIssueData">
                    <li
                        *ngFor="let issue of riskModalInfo?.IssueData"
                        [class.mitigated]="issue.IsMitigated">
                        {{ issue.InfoString }}
                    </li>
                </ul>
            </div>

            <div>
                <p class="riskSectionTitle">Severity</p>
                <img
                    src="/assets/img/risk_{{ riskModalInfo?.SeverityDisplay }}.svg"
                    style="width: 50px"
                    alt="{{ riskModalInfo?.Severity }}"
                    title="{{ riskModalInfo?.Severity }}" />
            </div>

            <div>
                <p class="riskSectionTitle">Probability</p>
                <img
                    src="/assets/img/risk_{{ riskModalInfo?.ProbabilityDisplay }}.svg"
                    style="width: 50px"
                    alt="{{ riskModalInfo?.Probability }}"
                    title="{{ riskModalInfo?.Probability }}" />
            </div>
        </div>
    </modal-content>
    <modal-actions>
        <itc-button class="cancel" type="secondary">Close</itc-button>
    </modal-actions>
</sm-modal>

<router-outlet (window:beforeunload)="doBeforeUnload()"></router-outlet>
