import { Injectable, ElementRef } from '@angular/core';

import { Subject, Observable } from 'rxjs';
import * as saveAs from 'file-saver';

declare var $: any;

@Injectable({ providedIn: 'root' })
export class ExportService {
    constructor() {}

    tableExport(table: ElementRef, filename: string, format: string) {
        console.log(table);
        var headings = [];
        var rows = [];
        var tableSizes = [];
        for (let t of table.nativeElement.children) {
            var size = 0;
            var tx = $(t);
            if (tx.is('thead')) {
                for (let b of tx.find('tr').children()) {
                    var bx = $(b);
                    if (!bx.hasClass('skip-export')) {
                        headings.push(bx.text());
                        size++;
                    }
                }
                tableSizes.push(size);
            }
            rows = [headings];
        }
        var xindex = 0;
        var init = true;
        for (let t of table.nativeElement.children) {
            var yindex = 1;
            var tx = $(t);
            if (tx.is('tbody')) {
                for (let b of tx.find('tr')) {
                    var bx = $(b);
                    console.log(bx);
                    if (init) {
                        let row = [];
                        for (let c of bx.children()) {
                            var cx = $(c);
                            row.push(cx.text());
                        }
                        rows.push(row);
                        yindex++;
                    } else {
                        let row = [];
                        if (yindex > rows.length) {
                            for (var x = 0; x < xindex; x++)
                                for (var i = 0; i < tableSizes[x]; i++) row.push('');
                            rows.push(row);
                        } else {
                            row = rows[yindex];
                        }
                        for (let c of bx.children()) {
                            var cx = $(c);
                            if (row == undefined) {
                                row = [];
                                for (var x = 0; x < xindex; x++)
                                    for (var i = 0; i < tableSizes[x]; i++) row.push('');
                            }
                            row.push(cx.text());
                        }
                    }
                    yindex++;
                }
            }
            while (yindex < rows.length) {
                let row = rows[yindex];
                for (var i = 0; i < tableSizes[xindex]; i++) row.push('');
                yindex++;
            }
            xindex++;
            if (rows.length > 1) init = false;
        }

        if (format == 'json') {
            var json = [];

            rows.shift(); // skip header row
            rows.forEach(function (row) {
                var obj = {};

                for (var i = 0; i < headings.length; i++) {
                    obj[headings[i]] = row[i];
                }

                json.push(obj);
            });

            this.downloadFileIEFriendly(
                filename + '.json',
                'application/json',
                JSON.stringify(json)
            );
            //this.downloadFile(filename + '.json', 'application/json', JSON.stringify(json))
        } else if (format == 'csv') {
            var csv = '';

            rows.forEach(function (row) {
                row.forEach(function (val) {
                    csv += '"' + val + '",';
                });
                csv += '\r\n';
            });

            this.downloadFileIEFriendly(
                filename + '.csv',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                csv
            );
            //this.downloadFile(filename + '.csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', csv);
        }
    }

    //Specific for the Data Collectors Table
    DCtableExport(table: ElementRef, filename: string, format: string) {
        console.log(table);
        var headings = [];
        var rows = [];
        var tableSizes = [];
        for (let t of table.nativeElement.children) {
            var size = 0;
            var tx = $(t);
            if (tx.is('thead')) {
                for (let b of tx.find('tr').children()) {
                    var bx = $(b);
                    if (!bx.hasClass('skip-export')) {
                        if (bx.hasClass('status')) {
                            headings.push('Status');
                            size++;
                        } else {
                            headings.push(bx.text());
                            size++;
                        }
                    }
                }
                tableSizes.push(size);
            }
            rows = [headings];
        }
        var xindex = 0;
        var init = true;
        for (let t of table.nativeElement.children) {
            var yindex = 1;
            var tx = $(t);
            if (tx.is('tbody')) {
                for (let b of tx.find('tr')) {
                    var bx = $(b);
                    console.log(bx);
                    if (init) {
                        let row = [];
                        for (let c of bx.children()) {
                            var cx = $(c);
                            if (!cx.hasClass('skip-export')) {
                                if (cx.hasClass('Online')) {
                                    row.push('Online');
                                } else if (cx.hasClass('Offline')) {
                                    row.push('Offline');
                                } else if (cx.hasClass('itemList')) {
                                    let text = '';
                                    for (let d of cx.find('li')) {
                                        if (text == '') {
                                        } else text += '\n';
                                        var dx = $(d);
                                        if (dx.is('li')) {
                                            console.log('Test');
                                            console.log(dx);
                                            text += `""${dx.text()}""`;
                                        }
                                    }
                                    row.push(text);
                                } else {
                                    row.push(cx.text());
                                }
                            }
                        }
                        rows.push(row);
                        yindex++;
                    } else {
                        let row = [];
                        if (yindex > rows.length) {
                            for (var x = 0; x < xindex; x++)
                                for (var i = 0; i < tableSizes[x]; i++) row.push('');
                            rows.push(row);
                        } else {
                            row = rows[yindex];
                        }
                        for (let c of bx.children()) {
                            var cx = $(c);
                            if (row == undefined) {
                                row = [];
                                for (var x = 0; x < xindex; x++)
                                    for (var i = 0; i < tableSizes[x]; i++) row.push('');
                            }
                            row.push(cx.text());
                        }
                    }
                    yindex++;
                }
            }
            while (yindex < rows.length) {
                let row = rows[yindex];
                for (var i = 0; i < tableSizes[xindex]; i++) row.push('');
                yindex++;
            }
            xindex++;
            if (rows.length > 1) init = false;
        }

        if (format == 'json') {
            var json = [];

            rows.shift(); // skip header row
            rows.forEach(function (row) {
                var obj = {};

                for (var i = 0; i < headings.length; i++) {
                    obj[headings[i]] = row[i];
                }

                json.push(obj);
            });

            this.downloadFileIEFriendly(
                filename + '.json',
                'application/json',
                JSON.stringify(json)
            );
            //this.downloadFile(filename + '.json', 'application/json', JSON.stringify(json))
        } else if (format == 'csv') {
            var csv = '';

            rows.forEach(function (row) {
                row.forEach(function (val) {
                    csv += '"' + val + '",';
                });
                csv += '\r\n';
            });

            this.downloadFileIEFriendly(
                filename + '.csv',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                csv
            );
            //this.downloadFile(filename + '.csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', csv);
        }
    }

    downloadFile(filename: string, contentType: string, data: string) {
        var uri = 'data:' + contentType + ',' + encodeURIComponent(data);

        var pom = document.createElement('a');
        pom.setAttribute('href', uri);
        pom.setAttribute('download', filename);

        if (document.createEvent) {
            var event = document.createEvent('MouseEvents');
            event.initEvent('click', true, true);
            pom.dispatchEvent(event);
        } else {
            pom.click();
        }
    }

    downloadFileIEFriendly(filename: string, contentType: string, data: string) {
        let bytes = new Uint8Array(data.length);
        for (let i = 0; i < data.length; i++) {
            bytes[i] = data.charCodeAt(i);
        }
        saveAs(new Blob([bytes], { type: contentType }), filename);
    }
}
