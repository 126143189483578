<h2>All Vendor Assessments Status and Results Printable Report</h2>

<h5>All Vendor Assessments - Status</h5>
<div
    class="testHeaderParent"
    style="border-bottom: 2px solid #d5d5d5; padding-bottom: 12px; width: 100%">
    <div *ngFor="let statusData of allVendorsInfo.StatusData; let i = index">
        <cm-unit-donut
            [status]="statusData.Status"
            [label]="statusData.Label"
            [numComplete]="statusData.numComplete"
            [percentComplete]="statusData.percentComplete"></cm-unit-donut>
    </div>
</div>

<h5>All Vendor Assessments - Result Summary</h5>
<div style="display: table; padding-left: 5px">
    <ng-container *ngFor="let ea of allVendorsInfo.AllVendorsData; let i = index">
        <div
            class="ui card"
            style="
                border-radius: 12px;
                cursor: pointer;
                padding: 24px;
                margin: 15px 15px 15px 0px;
                display: inline-block;
            ">
            <sds-tracker-donut-component
                [cardMeta]="ea.VendorName"
                [chartData]="ea.chartData"
                [measure]="'Controls'"
                [centerText]="ea.CompletedPercentage"
                [cardId]="i"
                [centerTextSmall]="'Covered'"
                [legendPosition]="'right'"></sds-tracker-donut-component>
        </div>
    </ng-container>
</div>
