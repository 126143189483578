import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'environments/environment';
import { AuthService } from 'app/core/auth';
import { ErrorService } from 'app/core/error/error.service';

@Injectable({ providedIn: 'root' })
export class LoginService {
    private apiUrl: string;

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private E: ErrorService
    ) {
        this.apiUrl = environment.apiUrl;
    }

    postLogin(req: LoginRequest): Promise<any> {
        const url: string = this.apiUrl + 'Login';
        this.authService.clearToken();

        return this.http
            .post(url, req, { observe: 'response' })
            .toPromise()
            .then((res) => this.loginResponse(res));
        //.catch(this.E.error);
    }

    loginResponse(res: HttpResponse<any>) {
        this.authService.twoFactorRequired = res.headers.get('X-TOTP-Required') != undefined;
        this.authService.twoFactorEnabled = res.headers.get('X-TOTP-Enabled') != undefined;
        this.authService.k1Required = res.headers.get('X-K1-Required') != undefined;
        this.authService.isAccountDisabled = res.headers.get('X-Account-Disabled') != undefined;
        if (this.authService.isAccountDisabled) {
            this.authService.disabledMessage = res.headers.get('X-Account-Disabled-Message');
        }
        if (res.body && res.body.token) {
            // set the token if 2af is required but not yet set
            if (this.authService.twoFactorRequired && !this.authService.twoFactorEnabled) {
                this.authService.setToken(res.body.token);
            }
            // set the token if 2af is not require
            if (!this.authService.twoFactorRequired) {
                this.authService.setToken(res.body.token);
            }
        }
    }

    resetPassword(username: string): Promise<any> {
        const url: string = this.apiUrl + 'ResetPassword';
        return this.http
            .post(url, { Username: username })
            .toPromise()
            .then((res) => res);
    }

    postKaseyaOAuthLogin(req: LoginRequest): Promise<any> {
        const url: string = this.apiUrl + 'KaseyaOAuthLogin';

        return this.http
            .post(url, req, { observe: 'response' })
            .toPromise()
            .then((res) => this.loginResponse(res));
        //.catch(this.E.error);
    }

    postK1S(req: LoginRequest): Promise<any> {
        const url: string = this.apiUrl + 'K1S';

        return this.http
            .post(url, req, { observe: 'response' })
            .toPromise()
            .then((res) => this.loginResponse(res));
    }

    postK1M(req: LoginRequest): Promise<any> {
        const url: string = this.apiUrl + 'K1M';

        return this.http
            .post(url, req, { observe: 'response' })
            .toPromise()
            .then((res) => res);
    }

    postKaseyaId(req: LoginRequest): Promise<any> {
        const url: string = this.apiUrl + 'KaseyaId';

        return this.http
            .post(url, req)
            .toPromise()
            .then((res) => res);
        //.catch(this.E.error);
    }

    postKaseyaNewPassword(req: LoginRequest): Promise<any> {
        const url: string = this.apiUrl + 'KaseyaNewPassword';

        return this.http
            .post(url, req)
            .toPromise()
            .then((res) => res);
        //.catch(this.E.error);
    }

    TOTPLogin(req: TOTPLoginRequest): Promise<any> {
        const url: string = this.apiUrl + 'TOTPLogin';

        return this.http
            .post(url, req, { observe: 'response' })
            .toPromise()
            .then((res) => this.loginResponse(res));
    }
}

export class LoginRequest {
    public username: string;
    public password: string;
    public oemid: string;
    public oemtoken: string;
    public remember: boolean;
}

export class TOTPLoginRequest {
    public username: string;
    public totp: string;
}
