import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { AuthHttp } from 'app/core/auth';
import { ErrorService } from 'app/core/error/error.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ItcFileUploaderService {
    private apiUrl: string;

    constructor(private http: AuthHttp, private E: ErrorService) {
        this.apiUrl = environment.apiUrl;
    }

    uploadFile(url: string, formData: FormData): Observable<any> {
        let headers = new HttpHeaders();
        headers.append('Accept', 'application/json');

        let options = {
            headers: headers,
            reportProgress: true,
            observe: 'events',
        };

        const apiURL = this.apiUrl + url;
        return this.http.post(apiURL, formData, options);
    }

    getFileIcon(file) {
        console.log('getting file icon', file);
        if (file.type?.match(/^image\//)) {
            return 'file-image';
        }
        if (file.type?.match(/^application\/.*?excel/)) {
            return 'file-excel';
        }
        if (file.type?.match(/^application\/.*?msword/)) {
            return 'file-word';
        }
        if (file.type?.match(/^text\/.xml/)) {
            return 'file-code';
        }
        if (
            file.name?.endsWith('.cdf') ||
            file.name?.endsWith('.ndf') ||
            file.name?.endsWith('.zip')
        ) {
            return 'file-zipper';
        }
        return 'file-lines';
    }
}
