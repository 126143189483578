import { ReportTheme, HexColor, DEFAULT_CHART_QUALITY, DEFAULT_CHART_TRANSPARENCY } from './shared';

export class Helvetica implements ReportTheme {
    ThemeName = 'Helvetica Medical Blue';
    // ReportModernizationThemeOriginalName = '';

    DefaultFontName = 'Helvetica';
    DefaultFontSize = 20;
    DefaultTextColor = HexColor(76, 76, 76);

    Header1Size = 32;
    Header2Size = 28;
    Header3Size = 24;
    Header4Size = 22;

    HasBottomBorder = true;
    LineBottomBorder = false;

    DefaultTableFontSize = 22;
    TableHeaderBackgroundColor = HexColor(0x1f, 0x59, 0x9a);
    TableHeaderTextColor = HexColor(0xff, 0xff, 0xff);
    TableRowColor = HexColor(0xff, 0xff, 0xff);
    TableAtlRowColor = HexColor(221, 230, 240);
    TableRowTextColor = HexColor(76, 76, 76);

    CriticalRiskColor = HexColor(0x91, 0x24, 0x3e);
    HighRiskColor = HexColor(0xdd, 0x4b, 0x50);
    MediumRiskColor = HexColor(0xf1, 0x8c, 0x43);
    LowRiskColor = HexColor(0xf8, 0xc8, 0x51);

    GoColor = HexColor(0x51, 0xcc, 0xa7);
    StopColor = HexColor(0xaa, 0x00, 0x00);
    DisabledColor = HexColor(0xbf, 0xbf, 0xbf);

    GraphTitleColor = HexColor(0x1f, 0x59, 0x9a);
    GraphSubTitleColor = HexColor(0x1f, 0x59, 0x9a);
    XColor = HexColor(76, 76, 76);
    YColor = HexColor(76, 76, 76);
    GraphOutlineColor = HexColor(0x1f, 0x59, 0x9a);

    UseNewRiskMeter = true;

    ChartColorsPaletteIntList = [
        HexColor(189, 50, 49),
        HexColor(55, 157, 239),
        HexColor(221, 99, 47),
        HexColor(71, 141, 44),
        HexColor(217, 217, 18),

        HexColor(155, 107, 66),
        HexColor(208, 62, 115),
        HexColor(40, 33, 105),
        HexColor(59, 133, 133),
        HexColor(98, 56, 133),

        HexColor(155, 65, 3),
        HexColor(36, 82, 67),
        HexColor(132, 133, 188),
        HexColor(236, 117, 121),
        HexColor(82, 22, 65),

        HexColor(138, 198, 62),
        HexColor(34, 57, 139),
        HexColor(245, 179, 51),
        HexColor(55, 15, 42),
        HexColor(82, 109, 132),
    ];

    ChartTopFivePaletteIntList = [
        HexColor(59, 133, 133),
        HexColor(138, 198, 62),
        HexColor(34, 57, 139),
        HexColor(236, 117, 121),
        HexColor(82, 109, 132),
        HexColor(245, 178, 51),
    ];

    ChartTwoColorPaletteIntList = [HexColor(59, 133, 133), HexColor(138, 198, 62)];

    ChartQuality = DEFAULT_CHART_QUALITY;
    ChartTransparencyPercent = DEFAULT_CHART_TRANSPARENCY;
}
