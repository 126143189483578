<sm-modal class="itc-modal lg videoTutorialsModal" [closable]="true" #videoModal>
    <modal-title>Video Tutorials</modal-title>
    <modal-content>
        <ng-container *ngIf="!nowPlaying">
            <div
                class="videoTutorialsContainer"
                *ngIf="VideoObj$ | async as vidObj; else loadingOrError">
                <ng-container *ngIf="!vidObj.error">
                    <div class="videoMenu">
                        <itc-video-menu
                            [categoryList]="vidObj.categories"
                            [selectedCategory]="selectedCategory"
                            (categoryClicked)="updateVideoCategory($event)"></itc-video-menu>
                    </div>
                    <div class="videoTutorials">
                        <div class="videoSearch">
                            <input
                                type="text"
                                [formControl]="searchCtrl"
                                placeholder="Search by keywords..." />
                            <strong>{{ displayedVideoCount }} of {{ totalVideoCount }}</strong>
                            &nbsp;Results
                        </div>
                        <div class="videoGrid" *ngIf="displayedVideos$ | async as videos">
                            <ng-container *ngIf="videos.length">
                                <itc-video-card
                                    *ngFor="let vid of videos; trackBy: trackByFn"
                                    [video]="vid"
                                    (click)="
                                        currentVideo = vid; nowPlaying = true
                                    "></itc-video-card>
                            </ng-container>

                            <div class="emptyState" *ngIf="!videos.length">
                                <img src="/assets/img/empty-state/magnifying-glass.svg" />
                                <p>No results found</p>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>

        <div class="videoPlayer" *ngIf="nowPlaying">
            <itc-video-player
                [video]="currentVideo"
                (playerClosed)="currentVideo = null; nowPlaying = false"></itc-video-player>
        </div>
    </modal-content>
</sm-modal>

<ng-template #loadingOrError>
    <div class="loadingState" *ngIf="!hasError">
        <div class="ui active centered loader" style="position: relative; border: 0"></div>
    </div>
    <div class="errorState" style="padding: 24px" *ngIf="hasError">
        <itc-banner type="error">There was a problem retrieving video list.</itc-banner>
    </div>
</ng-template>
