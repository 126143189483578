import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CWRESTCompany, Connection, ConnectionService } from '../../connections';
import { NotificationService } from '../itc/notification/notification.service';
import { Subject, catchError, of, switchMap, takeUntil } from 'rxjs';



@Component({
    selector: 'cw-companies-lookup',
    templateUrl: './cw-companies-lookup.component.html',
})
export class CWCompaniesLookupComponent implements OnInit, OnDestroy {
    
    constructor(
        private connectionService: ConnectionService,
        private notificationService: NotificationService,
    ) {}

    @Input() connection: Connection;
    @Input() searchTxtId: string = "cwrCompany";
    @Output() onSelectedCompanyId = new EventEmitter<string>();

    findCWRCompaniesSubj$ = new Subject<string>();
    ngUnsubscribe$ = new Subject<void>();
    cwrLookupDone: boolean;
    cwrCompanySearchTxt: string;
    loadingCWR: boolean;
    connectWiseCompanies: CWRESTCompany[];

    ngOnDestroy() {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    ngOnInit() {
        this.findCWRCompaniesSubj$
            .pipe(takeUntil(this.ngUnsubscribe$))
            .pipe(switchMap((searchTxt) => {

                if (searchTxt == null || !this.connection) {
                    return of(null);
                }

                this.loadingCWR = true;

                return this.connectionService
                    .findCWRESTCompaniesObservable(this.connection, this.cwrCompanySearchTxt, true)
                    .pipe(catchError((e) => {
                        this.notificationService.toast.error(
                            'Error',
                            'There was a problem retrieving your ConnectWise company list. Ensure the credentials used in the Connection are valid.'
                        );
                       
                        return of(null);
                    }))
            }))
            .subscribe({
                next: (companies) => {

                    if (companies == null) {
                        this.loadingCWR = false;
                        this.cwrLookupDone = false;
                        this.connectWiseCompanies = [];
                        return;
                    }

                    this.connectWiseCompanies = companies.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
                    this.cwrLookupDone = true;
                    this.loadingCWR = false;
                }
            });
    }

    public reset() {
        //cancels and resets company lookup
        this.findCWRCompaniesSubj$.next(null);
        this.cwrCompanySearchTxt = "";
        this.onSelectedCompanyId.emit("");
    }

    findCWRCompanies() {
        this.findCWRCompaniesSubj$.next(this.cwrCompanySearchTxt);
    }

    companySelected(companyId: string) {
        this.onSelectedCompanyId.emit(companyId);
    }

    goBackToLookup() {
        
        this.cwrLookupDone = false;
        this.cwrCompanySearchTxt='';

        this.onSelectedCompanyId.emit("");
    }
}
