import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';
import { AuthHttp } from 'app/core/auth';

@Injectable({ providedIn: 'root' })
export class UniviewService {
    private apiUrl: string;

    constructor(private http: AuthHttp) {
        this.apiUrl = environment.apiUrl;
    }

    getUniviewCompanies(clientid: string, clientsecret: string) {
        const url: string = this.apiUrl + `UniviewOrganizations`;

        return this.http
            .post(url, { clientid: clientid, clientsecret: clientsecret })
            .toPromise()
            .then((res) => res);
    }

    getUniviewSettings(siteId: number) {
        const url: string = this.apiUrl + `UniviewSettings/${siteId}`;

        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    isUniviewEnabled(siteId: number) {
        const url: string = this.apiUrl + `IsUniviewEnabled/${siteId}`;

        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    importUniviewAssets(siteId: number) {
        const url: string = this.apiUrl + `ImportUniviewAssets/${siteId}`;

        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }
}
