<sm-modal title="Select Standards" class="standardsModal md" #selectStandardsModal>
    <modal-content>
        <ng-container [ngSwitch]="modalContent">
            <ng-container *ngSwitchCase="'select'">
                <form [formGroup]="modalForm">
                    <div class="standards-modal-header">
                        <div>
                            <label>Search</label>
                            <itc-input
                                name="standardsFilterText"
                                placeholder="Search keywords..."
                                debounce="250"
                                [control]="this.modalForm.get('searchKey')"
                                class="standardsFilter"></itc-input>
                        </div>
                        <div *ngIf="allowFilterByType">
                            <label>Type</label>
                            <p-dropdown
                                class="viewOptions"
                                [options]="dropdownOptions"
                                formControlName="controlsOptionSelected"
                                [style]="{ width: '200px' }"></p-dropdown>
                        </div>
                        <div>
                            <label>&nbsp;</label>
                            <itc-checkbox formControlName="showSelected">
                                Only Show Selected
                            </itc-checkbox>
                        </div>
                    </div>
                </form>
                <div style="display: flex; height: 52px">
                    <div class="titleIcons">
                        <fa-icon
                            [icon]="
                                selectStandardsService.state.allExpanded ? 'compress' : 'expand'
                            "
                            class="expandIcon"
                            (click)="selectStandardsService.toggleExpandAllStandards()"></fa-icon>
                        <itc-checkbox
                            style="margin-bottom: 0px; margin-top: 1px"
                            [checked]="selectStandardsService.state.allSelected"
                            [disabled]="selectStandardsService.state.noDisplayStandards"
                            (onchange)="
                                selectStandardsService.toggleSelectAllStandards()
                            "></itc-checkbox>
                    </div>
                    <div class="tableTitle">
                        <b>Standard/Variant</b>
                    </div>
                </div>
                <div
                    class="scrollableTable"
                    *ngIf="standards$ | async as standards; else noResults"
                    #scrollTable>
                    <table class="itc-table">
                        <ng-container
                            *ngTemplateOutlet="
                                standardSection;
                                context: {
                                    $implicit: standards.systemStandards,
                                    name: 'SYSTEM STANDARDS',
                                    sectionCode: 'showSysStd'
                                }
                            "></ng-container>
                        <ng-container
                            *ngTemplateOutlet="
                                standardSection;
                                context: {
                                    $implicit: standards.nonSystemStandards,
                                    name: 'USER CREATED STANDARDS',
                                    sectionCode: 'showUserStd'
                                }
                            "></ng-container>
                        <ng-container
                            *ngTemplateOutlet="
                                standardSection;
                                context: {
                                    $implicit: standards.deprecatedStandards,
                                    name: 'DEPRECATED STANDARDS',
                                    sectionCode: 'showDepStd'
                                }
                            "></ng-container>
                    </table>
                </div>
            </ng-container>
            <ng-template #noResults>
                <empty-state icon="results" class="noResult" title="No Results Found"></empty-state>
            </ng-template>

            <div *ngSwitchCase="'acknowledge'">
                <h2 class="sectionTitle dashed">ISO 27002 Standard License Acknowledgement</h2>
                <br />
                In order to use the ISO 27002 Standard you will need to purchase the corresponding
                ISO 27001, ISO 27002 or equivalent license.
                <br />
                <br />
                Click the button below to acknowledge that you have purchased the correct license.
                <br />
                <br />
                For more information about purchasing ISO Standard publications and licenses, please
                visit:
                <a
                    class="labelLink"
                    [routerLink]="null"
                    (click)="viewExternalContent(externalLink.innerText)"
                    #externalLink>
                    https://www.iso.org/sectors/it-technologies
                </a>
            </div>
            <div *ngSwitchCase="'externalContent'">
                <h2 class="sectionTitle dashed">Access External Content</h2>
                <br />
                <p>
                    The reference URL you have selected will direct you to access content that is
                    published on an external website. Please confirm your access to this content
                </p>
            </div>
        </ng-container>
    </modal-content>
    <modal-actions>
        <div class="countField" *ngIf="modalContent === 'select'">
            <b>{{ selectStandardsService.state.selectedCount }}</b>
            &nbsp; selected
        </div>
        <ng-container [ngSwitch]="modalContent">
            <ng-container *ngSwitchCase="'select'">
                <itc-button class="button" type="secondary" (onclick)="selectStandardsModal.hide()">
                    Cancel
                </itc-button>
                <itc-button
                    class="button"
                    type="primary"
                    [loading]="acknowledging"
                    (onclick)="checkAcknowledgement()">
                    Save
                </itc-button>
            </ng-container>
            <ng-container *ngSwitchCase="'acknowledge'">
                <itc-button class="button" type="secondary" (onclick)="cancelAcknowledge()">
                    Cancel
                </itc-button>
                <itc-button class="button" type="primary" (onclick)="onAcknowledge()">
                    Acknowledge
                </itc-button>
            </ng-container>
            <ng-container *ngSwitchCase="'externalContent'">
                <itc-button class="button" type="secondary" (onclick)="cancelExternalContent()">
                    Cancel
                </itc-button>
                <itc-button class="button" type="primary" (onclick)="navigateTo()">OK</itc-button>
            </ng-container>
        </ng-container>
    </modal-actions>
</sm-modal>

<ng-template #standardSection let-section let-sectionName="name" let-sectionCode="sectionCode">
    <tbody *ngIf="section?.length > 0" class="standard-section">
        <tr class="stickyRow">
            <td colspan="2" class="stickyRowData">{{ sectionName }}</td>
        </tr>
        <ng-container *ngFor="let standard of section">
            <tr>
                <td class="checkboxes">
                    <fa-icon
                        [icon]="standard.Expanded ? 'chevron-up' : 'chevron-down'"
                        (click)="standard.Expanded = !standard.Expanded"
                        class="chevron"></fa-icon>
                    <itc-checkbox
                        [checked]="standard.Selected"
                        (onchange)="selectStandardsService.toggleStandard(standard)"></itc-checkbox>
                </td>
                <td class="standard-name" (click)="selectStandardsService.toggleStandard(standard)">
                    {{ standard.StandardName }}
                </td>
            </tr>
            <ng-container *ngFor="let variant of standard.Variants">
                <tr
                    *ngIf="
                        standard.Expanded && (!showSelected || (showSelected && variant.Selected))
                    ">
                    <td class="standard-variants">
                        <itc-checkbox
                            [checked]="variant.Selected"
                            [nested]="true"
                            (onchange)="
                                selectStandardsService.toggleVariant(standard, variant)
                            "></itc-checkbox>
                    </td>
                    <td
                        class="standard-variant-name"
                        (click)="selectStandardsService.toggleVariant(standard, variant)">
                        {{ variant.Variant }}
                    </td>
                </tr>
            </ng-container>
        </ng-container>
    </tbody>
</ng-template>
