<!-- Warning Modal -->
<sm-modal title="Timeout Warning" class="xs" #warningModal>
    <modal-content>
        <div class="ui warning icon message">
            <i class="warning sign icon"></i>
            <div class="content">
                Warning:
                <br />
                You have been inactive for
                {{ ngxInactivityTimeout - ngxInactivityWarning }} minutes. At
                {{ ngxInactivityTimeout }} minutes, this InForm worksheet will become available for
                other users to edit.
            </div>
        </div>
    </modal-content>
    <modal-actions>
        <div class="ui approve blue button">Ok</div>
    </modal-actions>
</sm-modal>

<!-- TimedOut Modal -->
<sm-modal title="Timed Out" class="xs" #timedOutModal>
    <modal-content>
        <div class="ui warning icon message">
            <i class="unlock icon"></i>
            <div class="content">
                You have been inactive for {{ ngxInactivityTimeout }} minutes. This InForm worksheet
                is now available for others to edit. Return to the Forms screen and reopen the
                worksheet to check it out again.
            </div>
        </div>
    </modal-content>
    <modal-actions>
        <div class="ui approve blue button" (click)="timeoutEnd()">Ok</div>
    </modal-actions>
</sm-modal>
