import { Directive } from '@angular/core';
import {
    NG_VALIDATORS,
    Validator,
    ValidationErrors,
    AbstractControl,
    ValidatorFn,
} from '@angular/forms';

@Directive({
    selector: '[notBlank]',
    standalone: true,
    providers: [{ provide: NG_VALIDATORS, useExisting: NotBlankValidator, multi: true }],
})
export class NotBlankValidator implements Validator {
    constructor() {}

    validate(control: AbstractControl): ValidationErrors | null {
        return validationTest(control.value);
    }
}

export function notBlank(control: AbstractControl): { [key: string]: any } | null {
    return validationTest(control.value);
}

function validationTest(val): ValidationErrors | null {
    // replaces the required validator to require a value and the value can't be just spaces
    return !val || (val && val.trim() === '') ? { required: true } : null;
}
