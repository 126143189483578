<div *ngIf="!licensed">
    <div style="display: inline">
        <span style="color: red; font-weight: bold">Further Details Not Available</span>
    </div>
    <br />
    <br />
    Advanced Breach Detection System (ABDS) is
    <span style="color: red; text-decoration: underline">not enabled</span>
    .
    <span *ngIf="isMasterUser">
        To receive advanced diagnostics, enable ABDS on this site by
        <a href="/site/{{ ueSiteName }}/home/addons">clicking here</a>
        .
    </span>
    <span *ngIf="!isMasterUser">
        To receive advanced diagnostics, contact the Site Admin to enable ABDS via the Cyber Hawk
        Add-ons tab.
    </span>
    Enabling ABDS will also allow daily scans (currently only weekly breach scans are performed).
    <br />
    Advanced Diagnostics include:
    <br />
    <ul>
        <li>Common Name</li>
        <li>File Location</li>
        <li>Type</li>
        <li>Command Line</li>
        <li>Username</li>
        <li>Registry Location</li>
        <li>Category</li>
        <li>Classification</li>
    </ul>
</div>

<div *ngIf="licensed">
    <table class="ui table">
        <thead>
            <tr>
                <th>Category</th>
                <th>Classification</th>
                <th>Type</th>
                <th>Sub-Type</th>
                <th>Details</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let entry of entries">
                <td>{{ entry.details.category | prettify }}</td>
                <td>{{ entry.details.classification | prettify }}</td>
                <td>{{ entry.details.type | prettify }}</td>
                <td>{{ entry.details.subtype | prettify }}</td>
                <td class="breakAll">
                    <span *ngFor="let key of entry.keys">
                        <b>{{ key | prettify }}</b>
                        : {{ entry.details[key] }}
                        <br />
                    </span>
                </td>
            </tr>
        </tbody>
    </table>
</div>
