import { Component, Input, OnInit } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { Site } from '../../shared/site.model';
import { SiteService } from '../../shared/site.service';
import { SiteDashboard } from '../../shared/site-dashboard.model';
import { SiteDashboardService } from '../../shared/site-dashboard.service';

@Component({
    selector: 'sds-all-site',
    templateUrl: './all-site.component.html',
    styleUrls: ['./all-site.component.scss'],
})
export class AllSiteComponent {
    sortedSites: SiteDashboard[] = [];
    sortColumn: string = '';
    sortDirection: string = '';
    searchKeyControl = new FormControl<string>('');
    filteredSites: SiteDashboard[] = [];
    allSites: SiteDashboard[] = [];
    selectedType: string = '';
    filterCtrl = new UntypedFormControl();
    filter: string = 'all';
    dropdownOptions: string[] = [
        'All Site Types',
        'Compliance Manager Sites',
        'Compliance Service Pro Sites',
        'Cyber Hawk Sites',
        'InDoc Sites',
        'Network Detective Pro Sites',
        'VulScan Sites',
        "Sites with Pending To-Do's",
        "Sites without Pending To-Do's",
    ];

    defaultSort = { sortColumn: 'Name', sortDirection: 'asc' };

    @Input() set sites(value: any) {
        this.allSites = value;
        this.filteredSites = value;
        this.onSortSites();
    }

    constructor(
        private siteService: SiteService,
        public siteDashboardService: SiteDashboardService
    ) {}

    getClassIcon(site: Site) {
        let className = '';

        if (this.siteService.isKVS(site)) {
            className = 'vulscanIcon';
        }
        if (
            this.siteService.isComplianceManager(site) ||
            this.siteService.isComplianceManagerGRC(site)
        ) {
            className = 'cmIcon';
        }
        if (this.siteService.isCyberHawk(site)) {
            className = 'chIcon';
        }
        if (this.siteService.isNDPro(site)) {
            className = 'ndpIcon';
        }
        return className;
    }

    resetSearchKey() {
        this.searchKeyControl.setValue('');
    }

    onSortSites(ev: any = { sortColumn: 'Name', sortDirection: 'asc' }) {
        this.sortColumn = ev.sortColumn;
        this.sortDirection = ev.sortDirection;
        this.sortSites();
    }

    sortSites() {
        this.sortedSites = this.filteredSites.sort((a, b) => {
            let cola = a[this.sortColumn],
                colb = b[this.sortColumn];

            if (this.sortColumn === 'Type') {
                if (this.siteService.isComplianceManager(a)) {
                    cola = 'compliance manager';
                }
                if (this.siteService.isComplianceManager(b)) {
                    colb = 'compliance manager';
                }
            }

            if (typeof cola === 'string') {
                cola = cola.toLocaleLowerCase();
            }
            if (typeof colb === 'string') {
                colb = colb.toLocaleLowerCase();
            }

            if (this.sortDirection === 'desc') {
                return cola < colb ? 1 : cola === colb ? 0 : -1;
            } else {
                return cola > colb ? 1 : cola === colb ? 0 : -1;
            }
        });

        this.filteredSites = [...this.sortedSites];
    }

    searchSites() {
        let searchKey = this.searchKeyControl.value
            ?.toLowerCase()
            .replace(/^\s+/g, '')
            .replace(/\s+$/g, '');

        if (searchKey) {
            let key: string = searchKey.toLowerCase();
            this.filteredSites = this.allSites.filter(
                (site) =>
                    site.Name.toLowerCase().indexOf(key) > -1 ||
                    site.Organization.toLowerCase().indexOf(key) > -1
            );
        } else {
            this.filteredSites = this.allSites;
        }

        this.filterByType();
    }

    checkToDoSiteType(site: Site): boolean {
        return this.siteService.isComplianceManager(site) || this.siteService.isCyberHawk(site);
    }

    IsNDPro(site: Site) {
        return this.siteService.isNDPro(site);
    }

    filterByType() {
        let siteToFilter: SiteDashboard[] = [];

        if (this.searchKeyControl.value) {
            siteToFilter = this.filteredSites;
        } else {
            siteToFilter = this.allSites;
        }

        if (this.selectedType === 'All Site Types') {
            this.filteredSites = siteToFilter;
        } else {
            if (this.selectedType === 'Compliance Manager Sites') {
                this.filteredSites = siteToFilter.filter(
                    (site) =>
                        this.siteService.isComplianceManager(site) ||
                        (this.siteService.isComplianceManagerGRC(site) && !site.IsCSPro)
                );
            }

            if (this.selectedType === 'Compliance Service Pro Sites') {
                this.filteredSites = siteToFilter.filter(
                    (site) => this.siteService.isComplianceManagerGRC(site) && site.IsCSPro
                );
            }
            if (this.selectedType === 'Cyber Hawk Sites') {
                this.filteredSites = siteToFilter.filter((site) =>
                    this.siteService.isCyberHawk(site)
                );
            }

            if (this.selectedType === 'Network Detective Pro Sites') {
                this.filteredSites = siteToFilter.filter((site) => this.siteService.isNDPro(site));
            }

            if (this.selectedType === 'VulScan Sites') {
                this.filteredSites = siteToFilter.filter((site) => this.siteService.isKVS(site));
            }

            if (this.selectedType === "Sites with Pending To-Do's") {
                this.filteredSites = siteToFilter.filter(
                    (site) => site?.OpenTodos > 0 && this.checkToDoSiteType(site)
                );
            }

            if (this.selectedType === "Sites without Pending To-Do's") {
                this.filteredSites = siteToFilter.filter((site) => site?.OpenTodos === 0);
            }

            if (this.selectedType === 'InDoc Sites') {
                this.filteredSites = siteToFilter.filter(
                    (site) => this.siteService.isIndoc(site) && !this.siteService.isNDPro(site)
                );
            }

            this.onSortSites();
        }
    }

    getToolTip(site: Site) {
        let toolTipName = '';

        if (
            this.siteService.isComplianceManager(site) &&
            !this.siteService.isComplianceManagerGRC(site)
        ) {
            toolTipName = `Compliance Manager - ${site.Type.toUpperCase()}`;
        }

        if (this.siteService.isComplianceManagerGRC(site)) {
            toolTipName = site.IsCSPro ? 'Compliance Services Pro Sites' : 'Compliance Manager GRC';
        }

        if (this.siteService.isKVS(site)) {
            toolTipName = 'Vulscan';
        }

        if (this.siteService.isCyberHawk(site)) {
            toolTipName = 'Cyber Hawk';
        }

        if (this.siteService.isNDPro(site)) {
            toolTipName = 'Network Detective Pro';
        }

        return toolTipName;
    }
}
