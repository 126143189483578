import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';
import { AuthHttp } from 'app/core/auth';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SiteUsersService {
    private readonly apiUrl: string;

    constructor(private http: AuthHttp) {
        this.apiUrl = environment.apiUrl;
    }

    getExistingUsers(): Promise<FmitUser[]> {
        let url: string = this.apiUrl + 'Users';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as FmitUser[]);
    }

    getExistingUsersForSite(siteId: number): Promise<FmitUser[]> {
        let url: string = this.apiUrl + `Users/${siteId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as FmitUser[]);
    }

    getMyUser(): Promise<SiteUser> {
        let url: string = this.apiUrl + `MyUser`;
        return this.http
            .get(url)
            .toPromise()
            .then((res: SiteUser) => {
                res.FullName = this.processUserFullName(res);
                return res;
            });
    }

    processUserFullName(user: SiteUser): string {
        if (user.FirstName && user.LastName) {
            return `${user.FirstName} ${user.LastName}`;
        } else if (user.FirstName && !user.LastName) {
            return user.FirstName;
        } else if (!user.FirstName && user.LastName) {
            return user.LastName;
        } else {
            return '';
        }
    }

    getSiteUsers(siteId: number): Promise<SiteUser[]> {
        let url: string = this.apiUrl + `Sites/${siteId}/Users`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as SiteUser[]);
    }

    getSiteUsersSafe(siteId: number): Promise<SiteUser[]> {
        let url: string = this.apiUrl + `Sites/${siteId}/UsersSafe`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as SiteUser[]);
    }

    postSiteUser(siteId: number, user: SiteUser): Promise<any> {
        let url: string = this.apiUrl + `Sites/${siteId}/Users`;
        return this.http
            .post(url, user)
            .toPromise()
            .then((res) => res);
    }

    addExistingUsersToSite(siteId: number, users: SiteUser[]): Promise<any> {
        let url: string = this.apiUrl + `Sites/${siteId}/ExistingUsers`;
        return this.http
            .post(url, users)
            .toPromise()
            .then((res) => res);
    }

    updateSiteUserRole(user: SiteUser): Promise<any> {
        let url: string = this.apiUrl + `Sites/${user.SiteId}/Users/${user.UserId}`;
        return this.http
            .put(url, {
                siteId: user.SiteId,
                userId: user.UserId,
                role: user.Role,
                userName: user.Username,
            })
            .toPromise()
            .then((res) => res);
    }

    updateOrgUserRole(user: SiteUser): Promise<any> {
        let url: string = this.apiUrl + `Organizations/${user.OrgId}/Users/${user.UserId}`;
        return this.http
            .put(url, {
                organizationId: user.OrgId,
                userId: user.UserId,
                role: user.OrgRole,
                userName: user.Username,
                siteId: user.SiteId,
            })
            .toPromise()
            .then((res) => res);
    }

    deleteSiteUserRole(user: SiteUser) {
        let url: string = this.apiUrl + `Sites/${user.SiteId}/Users/${user.UserId}`;
        return this.http
            .delete(url)
            .toPromise()
            .then((res) => res);
    }

    addSiteSme(siteId: number, sme: SmeUser): Promise<any> {
        let url: string = this.apiUrl + `Sites/${siteId}/Smes`;
        return this.http
            .post(url, sme)
            .toPromise()
            .then((res) => res);
    }

    getSiteSmes(siteId: number): Promise<SiteUser[]> {
        let url: string = this.apiUrl + `Sites/${siteId}/Smes`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as SiteUser[]);
    }

    getSiteFormSmeInvitations(siteId: number, formname: string): Promise<SiteUser[]> {
        let url: string = this.apiUrl + `Sites/${siteId}/FormSmeInvitations/${formname}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as SiteUser[]);
    }

    getAllFormSmeInvitations(siteId: number): Promise<any[]> {
        const url: string = this.apiUrl + `Sites/${siteId}/Invitations`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any[]);
    }

    revokeSiteFormSmeInvitation(user: SiteUser, siteId: number, formName: string) {
        let url: string = this.apiUrl + `Sites/${siteId}/Smes/${user.UserId}/${formName}`;
        return this.http
            .delete(url)
            .toPromise()
            .then((res) => res);
    }

    getSiteUserGroups(siteId: number): Promise<UserGroup[]> {
        const url: string = this.apiUrl + `Sites/${siteId}/UserGroups`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any[]);
    }

    getGlobalUserGroups(): Promise<UserGroup[]> {
        const url: string = this.apiUrl + `UserGroups`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any[]);
    }

    addUserGroup(userGroup: UserGroup): Promise<UserGroup> {
        const url: string = this.apiUrl + `UserGroups`;
        return this.http
            .post(url, userGroup)
            .toPromise()
            .then((res) => res);
    }

    updateUserGroup(userGroup: UserGroup): Promise<UserGroup> {
        const url: string = this.apiUrl + `UserGroups`;
        return this.http
            .put(url, userGroup)
            .toPromise()
            .then((res) => res);
    }

    deleteUserGroup(groupId: number): Promise<any> {
        const url: string = this.apiUrl + `UserGroups/${groupId}`;
        return this.http
            .delete(url)
            .toPromise()
            .then((res) => res);
    }

    getAssignmentsForWorkType(siteId: number, workType: string): Promise<Assignment[]> {
        const url: string = this.apiUrl + `Sites/${siteId}/AssignedWork/${workType}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any[]);
    }

    getAssignmentsForCurrentUser(siteId: number): Promise<Assignment[]> {
        const url: string = this.apiUrl + `Sites/${siteId}/AssignedWork`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as any[]);
    }
    //return is the list of assignments, with assigned by id set properly
    assignWork(siteId: number, assignments: Assignment[]): Promise<Assignment[]> {
        const url: string = this.apiUrl + `Sites/${siteId}/AssignedWork`;
        for (var i = 0; i < assignments.length; i++) {
            if (assignments[i].GroupId == null) {
                assignments[i].GroupId = -1;
            } else if (assignments[i].UserId == null) {
                assignments[i].UserId = -1;
            }
        }
        return this.http
            .post(url, assignments)
            .toPromise()
            .then((res) => res);
    }


    getSitesUser():Promise<number[]>{
        const url: string = this.apiUrl + `GetUserSites`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }
}

export class FmitUser {
    public Id: number;
    public AccountId: string;
    public Username: string;
}

export class SiteUser {
    public UserId: number;
    public AccountId: string;
    public SiteId: number;
    public OrgId: number;

    public Username: string;
    public Password: string;
    public FirstName: string;
    public LastName: string;
    public FullName?: string;
    public Role: string;
    public OrgRole: string;
    public IsK1: boolean;
    public Products: string;
}

export class SmeUser {
    public UserId: number;
    public SiteId: number;
    public Username: string;
    public Formname: string;
}

export class UserGroup {
    public Id: number;
    public SiteId: number;
    public Name: string;
    public AccountId: string;
    public Users: number[];
    public Type: string;
    public UserDisplayNames: string[];
}

export class Assignment {
    public UserId: number;
    public GroupId: number;
    public SiteId: number;
    public AssignedById: number;
    public WorkType: string;
    public WorkId: string;
    public UserDisplayName: string;
    public AssignedByDisplayName: string;
}
