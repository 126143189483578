import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { AuthHttp } from 'app/core/auth';
import { ErrorService } from 'app/core/error/error.service';
import { Notes } from './notes.model';

@Injectable({ providedIn: 'root' })
export class NotesService {
    private apiUrl: string;

    constructor(private http: AuthHttp, private E: ErrorService) {
        this.apiUrl = environment.apiUrl;
    }

    getNotes(
        siteId: number,
        category: string,
        itemType: string,
        itemValue: string
    ): Promise<Notes[]> {
        const url: string =
            this.apiUrl +
            'Notes/' +
            siteId +
            '?category=' +
            category +
            '&type=' +
            itemType +
            (itemValue ? '&value=' + itemValue : '');
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as Notes[]);
        //.catch(this.E.error);
    }

    getPrivateNotes(
        siteId: number,
        category: string,
        itemType: string,
        itemValue: string,
        pwd: string
    ): Promise<any> {
        //const url: string = this.apiUrl + 'PrivateNotes/' + siteId + '?pwd=' + encodeURIComponent(pwd) + '&category=' + category + '&type=' + itemType + (itemValue ? ('&value=' + itemValue) : '');
        //
        //return this.http.get(url)
        //    .toPromise()
        //    .then(res => res )

        const url: string = this.apiUrl + 'PrivateNotes';
        let privateNoteReq = {
            siteId: siteId,
            pwd: encodeURIComponent(pwd),
            category: category,
            itemType: itemType,
            itemValue: itemValue,
        };

        return this.http
            .post(url, privateNoteReq, { observe: 'response' })
            .toPromise()
            .then((res) => res);
    }

    getPrivacyStatus(): Promise<any> {
        const url: string = this.apiUrl + 'PrivacyStatus';
        return this.http
            .get(url, { observe: 'response' })
            .toPromise()
            .then((res) => res);
    }

    postNotes(note: Notes): Promise<any> {
        const url: string = this.apiUrl + 'Notes';

        return this.http
            .post(url, note, { observe: 'response' })
            .toPromise()
            .then((res) => res);
        //.catch(this.E.error);
    }

    putNotes(note: Notes): Promise<any> {
        const url: string = this.apiUrl + 'Notes/' + note.Id;
        return this.http
            .put(url, note)
            .toPromise()
            .then((res) => res);
        //.catch(this.E.error);
    }

    deleteNotes(note: Notes): Promise<any> {
        const url: string = this.apiUrl + 'Notes/' + note.Id;
        return this.http
            .delete(url)
            .toPromise()
            .then((res) => res.status == 200);
        //.catch(this.E.error);
    }

    postPrivacyPassword(formData: FormData) {
        let headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        let options = { headers: headers, observe: 'response' };
        const url: string = this.apiUrl + 'PrivacyPassword';
        return this.http
            .post(url, formData, options)
            .toPromise()
            .then((res) => res);
    }

    changePrivacyPassword(currentPasscode: string, newPasscode: string): Promise<any> {
        const url: string = this.apiUrl + 'ChangePrivacyPassword';

        return this.http
            .post(url, { oldPassword: currentPasscode, newPassword: newPasscode })
            .toPromise()
            .then((res) => res);
    }

    requestPrivacyAccess(siteId: number) {
        const url: string = this.apiUrl + 'RequestPrivacyAccess/' + siteId;

        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }
}
