import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ControlsAssessmentInfo } from '../site/compliance-manager/controls-assessment/printable/controlsasessment.model';
import { RapidBaselineInfo } from '../site/compliance-manager/rapid-baseline/printable/rapidbaseline.model';
import { RequirementsAssessmentInfo } from '../site/compliance-manager/requirements-assessment/printable/requirementsassessment.model';
import { VendorsInfo } from '../site/compliance-manager/vendor-management/printable-vendors/vendors.model';
import { AlertSOPInfo } from '../../alerts/shared/alert-sop-modal/printable-alert-sop-modal/alert-sop.model';
import { EmployeeTrackerInfo } from '../site/compliance-manager/employee-portal/printable/employee-tracker.model';
import { AllVendorsInfo } from '../site/compliance-manager/vendor-management/printable-all-vendors/allvendors.model';
import { AllTrainingsInfo } from '../site/compliance-manager/employee-portal/printable-all-training/alltrainings.model';
import { AllPoliciesInfo } from '../site/compliance-manager/employee-portal/printable-all-policies/allpolicies.model';

@Injectable()
export class PrintService {
    isPrinting = false;

    public _rapidBaselineData: RapidBaselineInfo;
    public _vendorsData: VendorsInfo;
    public _controlsAssessmentData: ControlsAssessmentInfo;
    public _requirementsAssessmentData: RequirementsAssessmentInfo;
    public _alertSOPData: AlertSOPInfo;
    public _employeeTrackerData: EmployeeTrackerInfo;
    public _allVendorsData: AllVendorsInfo;
    public _allTrainingsData: AllTrainingsInfo;
    public _allPoliciesData: AllPoliciesInfo;

    prevTitle: string;
    constructor(private router: Router, private route: ActivatedRoute) {}

    printDocument(documentName: string, fileName?: string) {
        if (fileName) {
            this.prevTitle = document.title;
            document.title = fileName;
        }

        this.isPrinting = true;

        setTimeout(() => {
            this.router.navigate([
                './',
                {
                    relativeTo: this.route,
                    outlets: {
                        print: ['print', documentName],
                    },
                },
            ]);
            // hide modals if they're showing, gotta do this because they put
            // a display:flex !important on the element that we can't overrride in just css'
            let modal = <HTMLElement>(
                document.querySelector('.ui.dimmer.modals.page.transition.visible.active')
            );
            let selector = <HTMLElement>document.getElementById('toastArea');
            if (selector.innerText) selector.style.display = 'none';

            if (modal) {
                modal.style.setProperty('display', 'none', 'important');
            }
        }, 100);
    }

    onDataReady() {
        window.print();
        this.isPrinting = false;
        if (this.prevTitle) document.title = this.prevTitle;
        // set modals back to flex how they started
        let modal = <HTMLElement>(
            document.querySelector('.ui.dimmer.modals.page.transition.visible.active')
        );
        let selector = <HTMLElement>document.getElementById('toastArea');
        if (selector.innerText) selector.style.display = 'block';

        if (modal) {
            modal.style.setProperty('display', 'flex', 'important');
        }

        this.router.navigate([{ outlets: { print: null } }]);
    }

    set RapidBaselineData(data: RapidBaselineInfo) {
        this._rapidBaselineData = data;
    }

    get RapidBaselineData(): RapidBaselineInfo {
        return this._rapidBaselineData;
    }

    set ControlsAssessmentData(data: ControlsAssessmentInfo) {
        this._controlsAssessmentData = data;
    }

    get ControlsAssessmentData(): ControlsAssessmentInfo {
        return this._controlsAssessmentData;
    }

    set RequirementsAssessmentData(data: RequirementsAssessmentInfo) {
        this._requirementsAssessmentData = data;
    }

    get RequirementsAssessmentData(): RequirementsAssessmentInfo {
        return this._requirementsAssessmentData;
    }

    set VendorsData(data: VendorsInfo) {
        this._vendorsData = data;
    }

    get VendorsData(): VendorsInfo {
        return this._vendorsData;
    }

    set AllVendorsData(data: AllVendorsInfo) {
        this._allVendorsData = data;
    }

    get AllVendorsData(): AllVendorsInfo {
        return this._allVendorsData;
    }

    set AlertSOPData(data: AlertSOPInfo) {
        console.log('setting alertsop', data);
        this._alertSOPData = data;
    }

    get AlertSOPData(): AlertSOPInfo {
        console.log('returning alertsop', this._alertSOPData);
        return this._alertSOPData;
    }

    set EmployeeTrackerData(data: EmployeeTrackerInfo) {
        this._employeeTrackerData = data;
    }

    get EmployeeTrackerData(): EmployeeTrackerInfo {
        return this._employeeTrackerData;
    }

    set AllTrainingsData(data: AllTrainingsInfo) {
        this._allTrainingsData = data;
    }

    get AllTrainingsData(): AllTrainingsInfo {
        return this._allTrainingsData;
    }

    set AllPoliciesData(data: AllPoliciesInfo) {
        this._allPoliciesData = data;
    }

    get AllPoliciesData(): AllPoliciesInfo {
        return this._allPoliciesData;
    }
}
