import { Component, Input, OnInit, isDevMode } from '@angular/core';

@Component({
    selector: 'sds-modal-debug',
    templateUrl: './modal-debug.component.html',
    styleUrls: ['./modal-debug.component.css'],
})
export class ModalDebugComponent implements OnInit {
    constructor() {}

    @Input() modals: string[];
    @Input() scope: any;
    isDevMode: boolean = isDevMode();

    ngOnInit() {}

    alog(stuff) {
        console.log(stuff);
    }

    showModal(modal) {
        try {
            this.scope[modal].showModal();
        } catch (e) {
            this.scope[modal].show();
        }
    }
}
