import { Component, Input, ChangeDetectionStrategy, EventEmitter, Output } from '@angular/core';
import { CM_COLORS } from '../../cm-summary.constants';
import { RoEmit, WizardQuestion } from '../cm-wizard.model';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'cm-wizard-related-requirements',
    templateUrl: './cm-wizard-related-requirements.component.html',
})
export class CmWizardRelatedRequirementsComponent {
    @Input() activeQuestion: WizardQuestion;
    @Input() culture: string;
    @Output() showReadOnly = new EventEmitter<RoEmit>();

    responseColor = {
        'Addressed by Controls': CM_COLORS.COVERED,
        'Addressed': CM_COLORS.COVERED,
        'Not Addressed': CM_COLORS.NOTCOVERED,
    };

    constructor() {}

    onShowReadOnly(question) {
        console.log('emitting ', question);
        this.showReadOnly.emit({ q: question, t: 'requirement' });
    }
}
