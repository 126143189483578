import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'sds-kvs-settings',
    templateUrl: './kvs-settings.component.html',
    styleUrls: ['./kvs-settings.component.css'],
})
export class KvsSettingsComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
