<div class="siteListContainer">
    <div class="column1">
        <sds-site-card
            *ngFor="let site of sortedSitesChunked?.col0"
            [site]="site"
            cardViewMode="grid"
            [openDetails]="openDetails"
            (detailsClicked)="toggleDetails($event)"></sds-site-card>
    </div>
    <div class="column2">
        <sds-site-card
            *ngFor="let site of sortedSitesChunked?.col1"
            [site]="site"
            cardViewMode="grid"
            [openDetails]="openDetails"
            (detailsClicked)="toggleDetails($event)"></sds-site-card>
    </div>
    <div class="column3">
        <sds-site-card
            *ngFor="let site of sortedSitesChunked?.col2"
            [site]="site"
            cardViewMode="grid"
            [openDetails]="openDetails"
            (detailsClicked)="toggleDetails($event)"></sds-site-card>
    </div>
    <div class="column4">
        <sds-site-card
            *ngFor="let site of sortedSitesChunked?.col3"
            [site]="site"
            cardViewMode="grid"
            [openDetails]="openDetails"
            (detailsClicked)="toggleDetails($event)"></sds-site-card>
    </div>
</div>
