<div class="app_launcher_container" #appLauncher>
    <!-- App Launcher Icon -->
    <div
        class="menuIcon"
        (click)="toggleLauncher()"
        tooltip="App Launcher"
        ttPosition="bottom-left"
        [ttHidden]="state == 'open'">
        <i class="itcAppLauncher" [@launcherIconState]="state"></i>
    </div>

    <div
        class="app_launcher_content_outside"
        (click)="closeLauncher()"
        *ngIf="state == 'open'"></div>

    <div class="app_launcher_content" [@launcherState]="state">
        <a href="https://one.kaseya.com" class="k1link" target="_blank">
            Kaseya One
            <fa-icon icon="square-arrow-up-right"></fa-icon>
        </a>

        <div class="app_launcher_product_container" #appLauncherContent>
            <ng-container *ngIf="pinnedProducts.length">
                <h2>My IT Complete</h2>
                <div class="myProducts" style="margin-bottom: 16px">
                    <div
                        class="myProduct"
                        *ngFor="let product of pinnedProducts"
                        (click)="goToProduct(product)">
                        <img [src]="product.productImageUrl" [alt]="product.name" />
                        <p class="product-name">{{ product.name }}</p>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="otherProducts.length">
                <h2>Discover More</h2>
                <div class="itmore">
                    <div
                        class="otherProduct"
                        *ngFor="let product of otherProducts"
                        (click)="goToProduct(product)">
                        <img [src]="product.productImageUrl" [alt]="product.name" />
                        <div class="product-info">
                            <p class="product-name">{{ product.name }}</p>
                            <p class="product-desc">{{ product.description }}</p>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
