import { InformOldComponent } from './inform.component';
import { TopicCircle, TopicDataType, TopicStyle, SwotType, TopicType } from './enums';
import { FollowupConditions } from './followupconditions.model';
import { Attachment } from './attachment.model';
import { Choice } from './choice.model';
import { Category } from './category.model';
import { Swot } from './swot.model';
import { InformTable } from './informtable.model';

export class Topic {
    AdditionalFollowupConditions: FollowupConditions;
    Attachments: Attachment[];
    BlankResponseFollowUpTopics: Topic[];
    BlankResponseIssueDescription: string;
    BlankResponseIssueRecommendation: string;
    BlankResponseIssueScore: number;
    BlankResponseSurveyPoints: number;
    CategoryId: string;
    Checked: boolean;
    CheckedFollowUpTopics: Topic[];
    CheckedIssueDescription: string;
    CheckedIssueRecommendation: string;
    CheckedIssueScore: number;
    CheckedSurveyPoints: number;
    Choices: Choice[];
    Circled: TopicCircle;
    DataType: TopicDataType;
    Enabled: boolean;
    FollowUpTopic: Topic;
    hasHelp: any;
    Hidden: boolean;
    Id: string;
    IncludeInReports: boolean;
    Instructions: string;
    InstructionsLocalized: any;
    IsFilteredOut: boolean = false;
    IsFollowUpTopic: boolean;
    IsIssue: boolean;
    IsIssueWhenBlank: boolean;
    IsIssueWhenChecked: boolean;
    IsIssueWhenNotBlank: boolean;
    IsIssueWhenNotChecked: boolean;
    IsMandatory: boolean;
    IssueDescription: string;
    IssueRecommendation: string;
    IssueScore: number;
    LastModifiedTime: Date;
    MaximumWeight: number;
    MergedTable: InformTable;
    Name: string;
    NameLocalized: any;
    NotBlankResponseFollowUpTopics: Topic[];
    NotBlankResponseIssueDescription: string;
    NotBlankResponseIssueRecommendation: string;
    NotBlankResponseIssueScore: number;
    NotBlankResponseSurveyPoints: number;
    Note: string;
    ParentTopic: Topic;
    PersonResponsible: string;
    Pictures: Attachment[];
    PreviousAnswer: string;
    PreviousAttachments: Attachment[];
    PreviousNote: string;
    PreviousRespondent: string;
    PreviousTable: InformTable;
    Respondent: string;
    Response: string;
    ResponseTime: Date;
    SectionNumber: string;
    SelectedChoice: Choice;
    Style: TopicStyle;
    Swot: SwotType;
    Swots: Swot[];
    Table: InformTable;
    Tags: string[];
    Type: TopicType;
    UncheckedFollowUpTopics: Topic[];
    UncheckedIssueDescription: string;
    UncheckedIssueRecommendation: string;
    UncheckedIssueScore: number;
    UncheckedSurveyPoints: number;
    viewModel: TopicViewModel;

    constructor() {}
}

export class TopicViewModel {
    parentTopic: Topic;
    topicNumber: number;
    htmlId: string;
    depth: number;
    topicCategory: Category;
    missingFollowUpName: boolean;
    filtered: boolean;
    bulkResponseChecked: boolean;
    previousResponseDisplay: string;
    hasPreviousResponse: boolean;
    showPreviousResponse: boolean;
    notesVisible: boolean;
    respondentVisible: boolean;
    swotsVisible: boolean;
    attachmentsVisible: boolean;
    hasNotes: boolean;
    hasRespondent: boolean;
    hasSwots: boolean;
    hasAttachments: boolean;
    isValid: boolean;
    hasError: boolean;
    selectBoxInitialized: boolean;
    showBlankResponseFollowUpTopics: boolean;
    showNotBlankResponseFollowUpTopics: boolean;
    showCheckedFollowUpTopics: boolean;
    showUncheckedFollowUpTopics: boolean;
    showChoiceFollowUp: boolean;
    choiceIndex: number;
    hasAdditionalFollowUp: boolean;
    showAdditionalFollowUp: boolean;
    hasChildFiltered: boolean;
}
