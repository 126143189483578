<h2>Requirements Assessment Printable Report</h2>

<div class="donutsContainer">
    <div class="cmPrintCard" *ngFor="let standard of requirementsAssessmentInfo.ActiveStandards">
        <cm-summary-donut
            style="display: inline-flex; vertical-align: top; cursor: pointer"
            [standard]="
                standard.Name + (standard.Name != standard.Variant ? ' ' + standard.Variant : '')
            "
            [centerText]="
                requirementsAssessmentInfo.Results[standard.Id].ChartData[0].count /
                    requirementsAssessmentInfo.Results[standard.Id].Standard.Requirements.length
                    | percent
            "
            [isRapidBaseline]="true"
            [chartData]="
                requirementsAssessmentInfo.Results[standard.Id].ChartData
            "></cm-summary-donut>

        <cm-standard-requirement-display
            *ngIf="selectedResult == '' + standard.Id"
            [requirementList]="results[standard.Id].Standard.Requirements"
            (cellClicked)="goToResponseByRequirement($event)"></cm-standard-requirement-display>
    </div>
</div>

<div class="responseBox" *ngFor="let res of requirementsAssessmentInfo.SortedResponseList">
    <div class="responseHeader">
        <div class="responseTitle">
            <h4>{{ res.Standard.Name }}</h4>
            <h5>{{ res.Requirement.RequirementId }} - {{ res.Standard.Variant }}</h5>
        </div>
        <div class="answerButton">
            <button
                class="ui button darkText"
                [class.unanswered]="!res.Response"
                type="button"
                [ngStyle]="{
                    'background-color':
                        res.Response == 'Addressed by Controls'
                            ? CM_COLORS.COVERED
                            : res.Response == 'Addressed'
                            ? CM_COLORS.COVERED
                            : res.Response == 'Not Addressed'
                            ? CM_COLORS.NOTCOVERED
                            : 'white'
                }">
                {{ res.Response ? res.Response : 'Not Answered' }}
            </button>
        </div>
    </div>
    <div class="responseBody">
        <span style="font-weight: 500">{{ res.Requirement.ShortDesc.LocalizedText[culture] }}</span>
        <div class="longDesc">
            {{ res.Requirement.LongDesc.LocalizedText[culture] }}
        </div>
    </div>
    <div class="responseBody">
        <b>Comment:</b>
        {{ res.Comment != '' ? res.Comment : 'No comment documented.' }}
    </div>
    <div class="responseBody">
        <b>Assigned to:</b>
        <span *ngFor="let a of res.Assignments; last as isLast">
            {{ getAssigneeName(a, false) + (!isLast ? ', ' : '') }}
        </span>
    </div>

    <div>
        <b>Assigned by:</b>
        {{ res.Assignments.length > 0 ? res.Assignments[0]?.AssignedByDisplayName || '' : '' }}
    </div>
</div>
