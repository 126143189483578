<div
    class="summaryDonutContainer"
    [class.rapidBaseline]="isRapidBaseline"
    (click)="emitCardClick()">
    <p class="cardType">
        {{ isRapidBaseline ? 'Assessment' : 'Standards Coverage' }}
        <span *ngIf="hasOverrides" class="itc badge small">Modified</span>
    </p>
    <p class="cardStandard" [title]="standard" *ngIf="standard">
        {{ standard }}
    </p>
    <div class="donutContainer" (click)="emitChartClick()">
        <itc-legacy-donut-chart
            [data]="chartData"
            [centerTextLarge]="centerText"
            [diameter]="100"
            [showLegend]="true"
            [legendPosition]="isRapidBaseline ? 'bottom' : 'right'"
            legendType="ovals"></itc-legacy-donut-chart>
    </div>
</div>
