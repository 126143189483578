<form class="ui form" name="deleteTwoFactorForm">
    <h3>Disable Two-Factor Authentication</h3>

    <itc-banner type="info">
        In order to disable two-factor authentication, please enter the current login code from
        Google Authenticator.
    </itc-banner>

    <br />

    <sm-input
        [control]="totpCtrl"
        label="Authenticator Code"
        (keydown.enter)="deleteTwoFactorSetup()"></sm-input>

    <br />

    <itc-button
        label="Disable"
        type="danger"
        (onclick)="deleteTwoFactorSetup()"
        [loading]="deletingTwoFactorKey"></itc-button>
</form>
