import {
    Component,
    ComponentRef,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { Alert } from 'app/alerts/shared/alert.model';
import { AlertDiagModalComponent } from 'app/alerts/diag/alert-diag-modal.component';
import { ALERT_STATUSES, ALERT_OBJECT_TYPES, OBJECT_TYPE_INFO } from 'app/alerts/shared/constants';

import { environment } from 'environments/environment';

@Component({
    selector: 'alert-data-view',
    templateUrl: './alert-data-view.component.html',
    styleUrls: ['./alert-data-view.component.css'],
})
export class AlertDataViewComponent implements OnChanges, OnInit {
    constructor() {}

    @Input() alert: Alert;
    @Input() readonly: boolean;
    @Input() hideHeader: boolean;
    @Input() hasError: boolean;

    @ViewChild('diagContainer', { static: true })
    diagContainer: ComponentRef<AlertDiagModalComponent>;

    alertData: any;
    icons = { p: '', s: '' };
    actionIcons = {
        investigate: 'search',
        ignore_always: 'ban',
        ignore_falsep: 'ban',
        ignore_once: 'ban',
    };

    pf: UntypedFormControl = new UntypedFormControl();
    sf: UntypedFormControl[] = [];

    diagObject: any;

    isChildUnChecked = false;

    statuses: any = ALERT_STATUSES;

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.alert) {
            var newValue = changes.alert.currentValue;
            if (newValue) {
                this.icons.p = OBJECT_TYPE_INFO[ALERT_OBJECT_TYPES[newValue.AlertType].p].icon;
                if (ALERT_OBJECT_TYPES[newValue.AlertType].s)
                    this.icons.s = OBJECT_TYPE_INFO[ALERT_OBJECT_TYPES[newValue.AlertType].s].icon;

                if (newValue.DataObject) this.alertData = newValue.DataObject;
                else this.alertData = JSON.parse(newValue.AlertData);

                this.setupControls();

                if (!environment.production) console.log(this.alertData);
            }
        }
    }
    valueChangedCheckkbox(i?: any) {
        if (i > -1 && this.sf[i].value == false) {
            this.isChildUnChecked = true;
            this.pf.setValue(false);
        }
    }
    setupControls() {
        this.pf = new UntypedFormControl();

        this.pf.valueChanges.subscribe((value) => {
            this.hasError = false;
            if (!this.isChildUnChecked) {
                for (let ctl of this.sf) {
                    ctl.setValue(value);
                }
            } else this.isChildUnChecked = false;
        });

        this.sf = [];
        for (let s of this.alertData.Secondary) {
            let fc = new UntypedFormControl();

            fc.valueChanges.subscribe((value) => {
                this.hasError = false;

                let c = 0;
                for (let ctl of this.sf) {
                    if (ctl.value == true) c++;
                    else break;
                }

                this.pf.setValue(c == this.sf.length, { emitEvent: false });
            });

            this.sf.push(fc);
        }
    }

    showDiagModal(obj: any) {
        this.diagObject = obj;
        (this.diagContainer as any).showModal();
    }
}
