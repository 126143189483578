import { Component, OnInit } from '@angular/core';

import { ConfirmData, ConfirmService } from './confirm.service';

@Component({
    selector: 'sds-confirm',
    templateUrl: './confirm.component.html',
    styleUrls: ['./confirm.component.css'],
})
export class ConfirmComponent implements OnInit {
    constructor(private confirmService: ConfirmService) {}

    confirmData: ConfirmData;

    ngOnInit() {
        this.confirmData = this.confirmService.getConfirmData();
    }
}
