import { Component, Input, OnInit } from '@angular/core';
import { Site } from '../../shared/site.model';
import { SiteDashboard } from '../../shared/site-dashboard.model';
import { FormControl } from '@angular/forms';
import { AuthService, Roles } from 'app/core/auth/auth.service';
import { accountDateTimePipe } from '../../../shared/accountDateTime.pipe';
import { SiteDashboardService } from 'app/sites/shared/site-dashboard.service';

@Component({
    selector: 'sds-compliance-manager-legacy',
    templateUrl: './compliance-manager-legacy.component.html',
    styleUrls: ['./compliance-manager-legacy.component.scss'],
})
export class ComplianceManagerLegacyComponent implements OnInit {
    @Input() set sites(value: any) {
        this.allSites = value;
        this.filteredSites = value;
        this.onSortSites();
    }
    constructor(
        private authService: AuthService,
        private dateWithFormat: accountDateTimePipe,
        public siteDashboardService: SiteDashboardService
    ) {}

    allSites: SiteDashboard[] = [];
    filteredSites: SiteDashboard[] = [];
    sortedSites: SiteDashboard[] = [];
    sortColumn: string = '';
    sortDirection: string = '';
    checkStatus: boolean = false;
    searchKeyControl = new FormControl<string>('');
    @Input() sitesUserIds: number[] = [];
    isCheckboxRestricted: boolean = false;

    ngOnInit(): void {
        this.checkIfUserIsSiteRestricted();
    }

    checkIfUserIsSiteRestricted() {
        if (
            this.authService.userIsRole(Roles.SiteRestricted) ||
            this.authService.userIsRole(Roles.Restricted)
        ) {
            this.isCheckboxRestricted = true;
            this.checkStatus = true;
        }
    }

    onSortSites(ev: any = { sortColumn: 'Name', sortDirection: 'asc' }) {
        this.sortColumn = ev.sortColumn;
        this.sortDirection = ev.sortDirection;
        this.sortSites();
    }

    filterSites() {
        if (this.sitesUserIds.length) {
            let listTemp = this.searchKeyControl.value ? this.filteredSites : this.allSites;
            this.filteredSites = this.checkStatus
                ? listTemp.filter((site) => this.sitesUserIds.find((Id) => Id === site.Id))
                : listTemp;
        }
    }

    sortSites() {
        this.sortedSites = this.filteredSites.sort((a, b) => {
            let cola = a[this.sortColumn],
                colb = b[this.sortColumn];

            if (typeof cola === 'string') {
                cola = cola.toLocaleLowerCase();
            }
            if (typeof colb === 'string') {
                colb = colb.toLocaleLowerCase();
            }

            if (this.sortDirection === 'desc') {
                return cola < colb ? 1 : cola === colb ? 0 : -1;
            } else {
                return cola > colb ? 1 : cola === colb ? 0 : -1;
            }
        });

        this.filteredSites = [...this.sortedSites];
    }

    getAssessmentType(site: Site) {
        let type = site.Type;
        if (site.Type === 'UKGDPR') {
            type = 'UK GDPR';
        }
        if (site.Type === 'NIST') {
            type = 'NIST CSF';
        }
        return type;
    }

    getDynamicStyles(isOnline: boolean) {
        return {
            'background-color': isOnline ? '#9ee7a1' : '#FF9E9E',
            'color': '#001d30',
            'width': '60px',
            'display': 'flex',
            'font-size': '12px',
            'font-weight': '500px',
            'padding': '2px 10px',
            'justify-content': 'center',
            'align-items': 'center',
            'gap': '8px',
        };
    }

    resetSearchKey() {
        this.searchKeyControl.setValue('');
    }
    searchSites() {
        if (this.searchKeyControl.value?.trim().toLowerCase()) {
            let key: string = this.searchKeyControl.value.toLowerCase();
            this.filteredSites = this.allSites.filter(
                (site) =>
                    site.Name.toLowerCase().indexOf(key) > -1 ||
                    site.Organization.toLowerCase().indexOf(key) > -1
            );
        } else {
            this.filteredSites = this.allSites;
        }
    }

    getScanUrl(site: SiteDashboard, taskNumber: number) {
        return `site/${site.Name}/home/dashboard`;
    }

    getStatusScan(site: SiteDashboard, scanNumber: number) {
        let status = 'failed';

        if (site.ScanInProgress && scanNumber === 1) {
            status = 'loading';
        }

        if (
            (scanNumber === 1 && !site.ScanInProgress && site.Scan1Success) ||
            (scanNumber === 2 && site.Scan2Success) ||
            (scanNumber === 3 && site.Scan3Success)
        ) {
            status = 'success';
        }

        return status;
    }

    getTitle(site: SiteDashboard, taskNumber: number) {
        if (site.IsLicenseInactive) return;

        let status = this.getStatusScan(site, taskNumber);
        let statusComplete = status ? status.charAt(0).toUpperCase() + status.slice(1) : '';
        return `Scan Status: ${statusComplete}`;
    }

    getStatusScanClass(site: SiteDashboard, scanNumber: number) {
        let status = 'failed';

        if (site.ScanInProgress && scanNumber === 1) {
            status = 'loading';
        }

        if (
            (scanNumber === 1 && !site.ScanInProgress && site.Scan1Success) ||
            (scanNumber === 2 && site.Scan2Success) ||
            (scanNumber === 3 && site.Scan3Success)
        ) {
            status = 'success';
        }

        return status;
    }

    getScanDate(site: SiteDashboard, taskNumber: number) {
        let scanType = '';
        if (taskNumber === 1) {
            scanType = site.Scan1StartDate?.toString();
        }
        if (taskNumber === 2) {
            scanType = site.Scan2StartDate?.toString();
        }
        if (taskNumber === 3) {
            scanType = site.Scan3StartDate?.toString();
        }

        return scanType;
    }

    getScanType(site: SiteDashboard, taskNumber: number) {
        let scanType = '';
        if (taskNumber === 1) {
            scanType = site.Scan1Type;
        }
        if (taskNumber === 2) {
            scanType = site.Scan2Type;
        }
        if (taskNumber === 3) {
            scanType = site.Scan3Type;
        }

        return scanType;
    }

    getToolTip(site: SiteDashboard, taskNumber: number) {
        if (site.IsLicenseInactive) return;
        let scanDate = this.getScanDate(site, taskNumber);
        let scanType = this.getScanType(site, taskNumber);
        let dateSection = '';

        if (this.getStatusScan(site, taskNumber) !== 'Running') {
            dateSection = `<span style=' width: 58px'>Scan Date: </span>
                 <span style='width: 114px; text-align: end'>${this.dateWithFormat.transform(
                     scanDate
                 )}</span>`;
        }

        return `<div style='display: flex; flex-direction: column; width: 212px'>
              <div style='display: flex; justify-content: space-between; align-items: flex-start; align-self: stretch; '>
                  <span style=' width: 58px'>Scan Type:</span>
                 <span  >${scanType}</span>
               </div>
                <div style='display: flex; justify-content: space-between; align-items: flex-start; align-self: stretch; '>
                  ${dateSection}
                </div>
             </div>`;
    }
}
