<div class="audit-search-container">
    <div class="item">
        <div class="audit-search-box" style="margin-left: 0">
            From: &nbsp;
            <p-calendar
                [(ngModel)]="fromDate"
                (onSelect)="filter()"
                [dateFormat]="dateFormat | primeNGDateFormat"
                appendTo="body"></p-calendar>
        </div>
        <div class="audit-search-box">
            To: &nbsp;
            <p-calendar
                [(ngModel)]="toDate"
                (onSelect)="filter()"
                [dateFormat]="dateFormat | primeNGDateFormat"
                appendTo="body"></p-calendar>
        </div>
    </div>
    <div class="item searchInput">
        <sm-input
            placeholder="Search..."
            [(model)]="searchKey"
            (modelChange)="filter()"
            debounce="420"
            icon="icon-search"></sm-input>
    </div>
    <div class="item">
        <itc-checkbox
            label="Show Admin Messages"
            [control]="showAdminControl"
            (checked)="handleShowAdminChecked($event)"></itc-checkbox>
    </div>
</div>
