import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

import { UiService } from '../../core/ui.service';
import { NavService } from '../nav/nav.service';

@Component({
    // selector: 'sds-403',
    templateUrl: './forbidden.component.html',
    styleUrls: ['./forbidden.component.css'],
})
export class ForbiddenComponent implements OnInit {
    errorCode: string;
    errorMessage: string = 'Your account does not have access to the requested page.';
    errorMessages: any;
    ngUnsubscribe$: Subject<any> = new Subject();

    constructor(
        private uiService: UiService,
        private route: ActivatedRoute,
        private navService: NavService
    ) {}

    ngOnInit() {
        this.errorMessages = {
            RFTE102:
                'You do not have the appropriate global access permissions to access this resource.',
            RFTE103: 'You do not have access to this products resources.',
            RFTE105:
                'You do not have the appropriate site specific permissions to access this resource.',
            RFTE110: 'Network Detective Pro is not enabled for this site.',
            RFTE111: 'Cyber Hawk is not enabled for this site.',
            RFTE112: 'Compliance Manager is not enabled for this site.',
            RFTE114: 'Compliance Manager GRC is not enabled for this site.',
            RFTE115: 'Vulnerability Scanner is not enabled for this site.',
            RFTE116: 'CSPro is not enabled for this site.',
            RFTE117: 'There are no CSPro enabled sites in this organization.',
            RFTE118: 'Selected organization does not have any of the required site types.',
            RFTE119: 'Network Detective Pro is not enabled for this account.',
            RFTE120: 'Your account is not provisioned for NDPro Web.',
        };

        this.navService.setHeaderText(''); // clear any header if there is one

        this.route.queryParams.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((qp: Params) => {
            if (qp['e']) {
                this.setError(qp['e']);
            }
        });

        this.route.params.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((params: Params) => {
            if (params['error']) {
                this.setError(params['error']);
            }
        });

        this.uiService.setTitle('Access Denied');
    }

    setError(e) {
        this.errorCode = e;
        this.errorMessage = this.errorMessages[e];
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next(void 0);
        this.ngUnsubscribe$.complete();
    }
}
