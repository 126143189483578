import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'nl2br',
})
export class nl2brPipe implements PipeTransform {
    transform(value: string): string {
        if (value) {
            return value.replace(/(\r\n|\n\r|\r|\n)/g, '<br>');
        }
    }
}
