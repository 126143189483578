<sds-breadcrumb-trail [crumbs]="breadcrumbs"></sds-breadcrumb-trail>

<header class="pageHeader">
    <h1>Admin Alerts</h1>
    <help-button
        title="Admin Alerts"
        text="Within the RapidFire Tools Portal, you can set and configure Admin Alerts to inform you of events such as a completed or failed scan or notification error."
        url="/portal/rft-portal/rft-portal-admin-alerts.htm"></help-button>
</header>

<form class="ui form settingsPage smallerContainer">
    <div style="margin-bottom: 20px">
        <label>
            <strong>Email Addresses</strong>
            (Separate multiple addresses with a comma (",")
        </label>
        <div>
            <label></label>
            <input type="text" [(ngModel)]="emails" name="emails" />
        </div>
    </div>

    <div>
        <label>
            <strong>Notices</strong>
            (Subject Prefix (added before notice type))
        </label>

        <div class="field">
            <input type="text" [(ngModel)]="prefix" name="prefix" />
        </div>

        <div>
            <itc-checkbox [control]="notifyFailedCtrl" label="Notification Error"></itc-checkbox>
        </div>
        <div>
            <itc-checkbox [control]="reportsCompleteCtrl" label="Reports Generated"></itc-checkbox>
        </div>
        <div>
            <itc-checkbox [control]="scanCompleteCtrl" label="Scan Complete"></itc-checkbox>
        </div>
        <div>
            <itc-checkbox [control]="scanFailedCtrl" label="Scan Failed"></itc-checkbox>
        </div>
        <div>
            <itc-checkbox [control]="scanMergedCtrl" label="Scan Merged"></itc-checkbox>
        </div>
    </div>

    <div class="ui right floated" style="margin-top: 20px">
        <itc-button type="secondary" [disabled]="saving" (onclick)="resetSettings()">
            Reset
        </itc-button>
        <itc-button type="primary" [loading]="saving" (onclick)="saveSettings()">Save</itc-button>
    </div>
</form>
