<sds-loader [complete]="loadingComplete">
    <sds-breadcrumb-trail [crumbs]="breadcrumbs"></sds-breadcrumb-trail>
    <header class="pageHeader">
        <h1>Standards Library</h1>
        <help-button
            title="Standards Library"
            text="Review out-of-box assessment standards, modify these standards, or create a new custom assessment standard."
            url="/cm-grc/help/cm-grc-docs/grc-standards-library.htm"></help-button>
    </header>
    <div>
        <input
            type="text"
            [(ngModel)]="standardsFilterText"
            style="width: 500px; margin-bottom: 0; margin-right: 16px"
            placeholder="Search to find matching standards"
            debounce="420"
            (input)="searchStandards()"
            #whatever />

        <itc-checkbox
            class="bulk-responder-checkbox"
            (onChanged)="hideSystemRecordsChanged($event)">
            Hide System Standards
        </itc-checkbox>
        <itc-button
            icon="fa-plus"
            type="primary"
            (onclick)="startStandardEditor(null, false)"
            style="float: right">
            New Standard
        </itc-button>
    </div>
    <p style="color: gray; font-size: 0.8em; margin-top: 16px; margin-bottom: 8px">
        <fa-icon icon="lock-keyhole"></fa-icon>
        Lock indicates System Standard (read-only)
    </p>

    <div>
        <table
            class="itc-table sortable"
            sortable-table
            sortColumn="Name"
            sortDirection="asc"
            (sorted)="sortStandards($event)">
            <thead>
                <tr>
                    <th style="width: 55px"></th>
                    <th sortable-column="Name">Standard Name</th>
                    <th sortable-column="Variant">Variant Name</th>
                    <th>Revision</th>
                    <th sortable-column="Requirements">Number of Requirements</th>
                    <th style="max-width: 60px"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let stnd of pagedStandards" [class.is-standard]="stnd.IsSystem">
                    <td style="text-align: right; width: 20px">
                        <itc-button
                            *ngIf="stnd.IsSystem"
                            type="icon"
                            icon="fa-lock-keyhole"
                            (onclick)="startStandardEditor(stnd, stnd.IsSystem)"
                            tooltip="View"></itc-button>
                    </td>
                    <td>
                        <a (click)="startStandardEditor(stnd, stnd.IsSystem)" class="clickableText">
                            {{ stnd.Name }}
                        </a>
                    </td>
                    <td>{{ stnd.Variant }}</td>
                    <td>{{ stnd.Revision }}</td>
                    <td>{{ stnd.Requirements.length }}</td>
                    <td *ngIf="!stnd.IsSystem" style="text-align: right">
                        <itc-button
                            tooltip="Edit"
                            type="icon"
                            icon="fa-pencil"
                            (onclick)="startStandardEditor(stnd, false)"></itc-button>
                        <itc-button
                            tooltip="Delete"
                            type="icon"
                            icon="fa-trash-can"
                            (onclick)="startDelete(stnd)"></itc-button>
                        <itc-button
                            type="icon"
                            icon="fa-clone"
                            (onclick)="startClone(stnd)"
                            tooltip="Clone"
                            ttPosition="top"></itc-button>
                    </td>
                    <td *ngIf="stnd.IsSystem" style="text-align: right">
                        <itc-button
                            type="icon"
                            icon="fa-clone"
                            (onclick)="startClone(stnd)"
                            tooltip="Clone"
                            ttPosition="top"></itc-button>
                    </td>
                </tr>
            </tbody>
        </table>
        <itc-paginator [data]="sortedStandards" (onPage)="pagedStandards = $event"></itc-paginator>
    </div>
</sds-loader>

<sm-modal title="{{ modalTitle }}" class="lg" #addStandard>
    <modal-content>
        <div *ngIf="stage == 1">
            <div class="ui container">
                <div class="ui grid" style="display: block">
                    <div class="ui placeholder" style="margin-bottom: 20px; margin-top: 15px">
                        <div class="paragraph">
                            <div class="line">
                                Define or modify a Standard to define a regulation or security
                                framework. Standards can have multiple variants to encapsulate
                                varying sets of requirements.
                            </div>
                        </div>
                    </div>
                    <form class="ui form" [formGroup]="standardsForm">
                        <div class="sixteen wide column">
                            <div class="field">
                                <label>Language:</label>
                                <sm-select
                                    [control]="standardsForm.get('standardLanguage')"
                                    (onChange)="changeLanguage($event, 'standard')"
                                    style="margin-top: 5px"
                                    tabindex="-1"
                                    [disabled]="isViewOnlyMode">
                                    <option
                                        *ngFor="let language of languageMap"
                                        [value]="language.value">
                                        {{ language.label }}
                                    </option>
                                </sm-select>
                            </div>
                        </div>
                        <div class="sixteen wide column" style="margin-top: 10px">
                            <div class="required field">
                                <label>
                                    Standard Name:
                                    <span
                                        *ngIf="standardsForm.errors?.notUnique"
                                        style="color: var(--text-critical)">
                                        A standard with the same name, variant, and revision already
                                        exists.
                                    </span>
                                </label>
                                <input
                                    type="text"
                                    name="Standard Name"
                                    formControlName="standardName"
                                    [class.inputError]="standardsForm.errors?.notUnique"
                                    placeholder="Standard Name"
                                    [readOnly]="isViewOnlyMode" />
                            </div>
                        </div>
                        <div class="sixteen wide column" style="margin-top: 10px">
                            <div class="required field">
                                <label>Variant Name:</label>
                                <input
                                    type="text"
                                    name="Standard Variant"
                                    formControlName="standardVariant"
                                    [class.inputError]="standardsForm.errors?.notUnique"
                                    placeholder="Variant Name"
                                    [readOnly]="isViewOnlyMode" />
                            </div>
                        </div>
                        <div class="sixteen wide column" style="margin-top: 10px">
                            <div class="required field">
                                <label>Revision:</label>
                                <input
                                    type="text"
                                    name="Standard Revision"
                                    formControlName="standardRevision"
                                    [class.inputError]="standardsForm.errors?.notUnique"
                                    placeholder="Revision"
                                    [readOnly]="isViewOnlyMode" />
                            </div>
                        </div>
                        <div class="sixteen wide column" style="margin-top: 10px">
                            <div class="field">
                                <label>
                                    Purpose:
                                    <i
                                        class="info circle icon"
                                        style="color: #0e6eb8; font-size: 12px"
                                        tooltip="Text will appear in the purpose section of Policy and Procedures documents."
                                        ttPosition="right"></i>
                                </label>
                                <input
                                    type="text"
                                    name="Standard Purpose"
                                    formControlName="standardPurpose"
                                    placeholder="Purpose"
                                    [readOnly]="isViewOnlyMode" />
                            </div>
                        </div>
                        <div class="sixteen wide column" style="margin-top: 10px">
                            <div class="field">
                                <label>
                                    Scope:
                                    <i
                                        class="info circle icon"
                                        style="color: #0e6eb8; font-size: 12px"
                                        tooltip="Text will appear in the scope section of Policy and Procedures documents."
                                        ttPosition="right"></i>
                                </label>
                                <input
                                    type="text"
                                    name="Standard Scope"
                                    formControlName="standardScope"
                                    placeholder="Scope"
                                    [readOnly]="isViewOnlyMode" />
                            </div>
                        </div>
                        <div class="sixteen wide column" style="margin-top: 10px">
                            <div class="field">
                                <label>Crosswalk:</label>
                                <table class="basic table" style="margin-top: 5px">
                                    <tbody>
                                        <tr
                                            *ngFor="
                                                let cw of selectedStandard.Crosswalks;
                                                let i = index
                                            ">
                                            <td
                                                *ngIf="!isViewOnlyMode"
                                                style="max-width: 20px; padding-left: 10px">
                                                <itc-button
                                                    type="icon"
                                                    icon="fa-trash-can"
                                                    (onclick)="deleteCrosswalk(cw)"
                                                    tooltip="Delete"
                                                    ttPosition="top"></itc-button>
                                            </td>
                                            <td style="padding: 8px 14px">
                                                {{ cw.CrosswalkDesc }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                *ngIf="!isViewOnlyMode"
                                                style="max-width: 20px; padding-left: 10px">
                                                <itc-button
                                                    type="icon"
                                                    icon="fa-plus"
                                                    (onclick)="addCrosswalk()"
                                                    tooltip="Add"
                                                    ttPosition="top"></itc-button>
                                            </td>
                                            <td *ngIf="!isViewOnlyMode">
                                                <input
                                                    type="text"
                                                    name="Crosswalk"
                                                    [formControl]="stndCrosswalkCtrl"
                                                    placeholder="Crosswalk" />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </form>
                    <div class="ui row" style="padding-bottom: 0px">
                        <div
                            class="eight wide column"
                            style="display: flex; flex-direction: column-reverse">
                            <label>Requirement List</label>
                        </div>
                        <div
                            class="eight wide column"
                            style="display: flex; flex-direction: row-reverse"
                            *ngIf="!isViewOnlyMode">
                            <itc-button
                                icon="fa-plus"
                                type="tertiary"
                                [tooltip]="!sortedControls.length ? 'Loading Controls...' : ''"
                                [loading]="!sortedControls.length"
                                (onclick)="setRequirement(null)">
                                Add Requirement
                            </itc-button>
                        </div>
                    </div>
                    <div
                        class="sixteen wide column"
                        style="padding-bottom: 0px; margin-top: 5px; padding-top: 0px"></div>
                    <div class="ui row" style="padding-top: 0px">
                        <div class="sixteen wide column">
                            <div style="height: 280px; margin-bottom: 20px; overflow-x: scroll">
                                <table
                                    sortable-table
                                    class="itc-table"
                                    (sorted)="addStandardControlsSortTable($event)"
                                    style="margin-bottom: 0 !important; margin-top: 0px !important">
                                    <thead>
                                        <tr>
                                            <th
                                                sortable-column="Order"
                                                style="
                                                    min-width: 120px;
                                                    max-width: 120px;
                                                    text-align: left;
                                                ">
                                                Order
                                            </th>
                                            <th
                                                sortable-column="Requirement"
                                                style="
                                                    min-width: 150px;
                                                    max-width: 150px;
                                                    text-align: left;
                                                ">
                                                Requirement
                                            </th>
                                            <th
                                                sortable-column="Description"
                                                style="min-width: 250px; max-width: 250px">
                                                Description
                                            </th>
                                            <th *ngIf="!isViewOnlyMode"></th>
                                        </tr>
                                    </thead>
                                    <tbody style="margin-top: 0 !important">
                                        <tr
                                            *ngFor="
                                                let req of filteredStandardControl;
                                                let index = index
                                            ">
                                            <td
                                                style="
                                                    min-width: 120px;
                                                    max-width: 120px;
                                                    text-align: left;
                                                ">
                                                {{ req.Order }}
                                            </td>
                                            <td
                                                style="
                                                    min-width: 150px;
                                                    max-width: 150px;
                                                    text-align: left;
                                                ">
                                                {{
                                                    req.RequirementId.length > 40
                                                        ? (req.RequirementId | slice : 0 : 40) +
                                                          '..'
                                                        : req.RequirementId
                                                }}
                                            </td>
                                            <td style="overflow: hidden">
                                                {{
                                                    req.ShortDesc.LocalizedText.hasOwnProperty(
                                                        culture
                                                    )
                                                        ? req.ShortDesc.LocalizedText[culture]
                                                              .length > 40
                                                            ? (req.ShortDesc.LocalizedText[culture]
                                                                  | slice : 0 : 40) + '..'
                                                            : req.ShortDesc.LocalizedText[culture]
                                                        : ''
                                                }}
                                            </td>
                                            <td style="display: flex" *ngIf="!isViewOnlyMode">
                                                <itc-button
                                                    icon="fa-pencil"
                                                    [disabled]="
                                                        waitControls || !sortedControls.length
                                                    "
                                                    type="icon"
                                                    [loading]="
                                                        waitControls || !sortedControls.length
                                                    "
                                                    (onclick)="
                                                        setRequirement(
                                                            req,
                                                            addStandardsControl.pageSize *
                                                                addStandardsControl.pageNumber +
                                                                index
                                                        )
                                                    "
                                                    ttposition="top"
                                                    [tooltip]="
                                                        waitControls || !sortedControls.length
                                                            ? 'Loading Controls...'
                                                            : 'Edit'
                                                    "></itc-button>
                                                <itc-button
                                                    icon="fa-trash-can"
                                                    type="icon"
                                                    (onclick)="
                                                        deleteRequirement(
                                                            req,
                                                            addStandardsControl.pageSize *
                                                                addStandardsControl.pageNumber +
                                                                index
                                                        )
                                                    "
                                                    [disabled]="
                                                        waitControls || !sortedControls.length
                                                    "
                                                    ttposition="top"
                                                    tooltip="Delete"></itc-button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <itc-paginator
                                [data]="selectedStandard.Requirements"
                                (onPageWithInfo)="
                                    filteredStandardControl = $event.items;
                                    filteredStandardControlPageSize = $event.pageSize;
                                    filteredStandardControlPageIndex = $event.pageIndex
                                "></itc-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="stage == 2">
            <div class="ui grid" style="display: block">
                <div class="ui placeholder" style="margin-bottom: 50px; margin-top: 15px">
                    <div class="paragraph">
                        <div class="line">
                            Define a requirement that must be met in order to comply with a given
                            standard. Each requirement must be given a unique Requirement ID,
                            typically from the regulatory standard or framework.
                        </div>
                    </div>
                </div>
                <form class="ui form" name="requirementsForm">
                    <div class="sixteen wide column">
                        <div class="field">
                            <label>Language:</label>
                            <sm-select
                                [control]="languageCtrl"
                                (onChange)="changeLanguage($event, 'requirement')">
                                <option
                                    *ngFor="let language of languageMap"
                                    [value]="language.value">
                                    {{ language.label }}
                                </option>
                            </sm-select>
                        </div>
                    </div>
                    <div class="sixteen wide column" style="margin-top: 10px">
                        <div class="required field">
                            <label>Requirement ID:</label>
                            <input
                                *ngIf="languageCtrl && languageCtrl.value != 'en-US'"
                                type="text"
                                disabled
                                name="Reqirement Id"
                                [formControl]="reqIdCtrl"
                                placeholder="Requirement ID" />
                            <input
                                *ngIf="languageCtrl && languageCtrl.value == 'en-US'"
                                type="text"
                                name="Reqirement Id"
                                [formControl]="reqIdCtrl"
                                placeholder="Requirement ID" />
                        </div>
                    </div>
                    <div class="sixteen wide column" style="margin-top: 10px">
                        <div class="required field">
                            <label>Requirement Name:</label>
                            <input
                                type="text"
                                name="sDescript"
                                [formControl]="reqSDescriptionCtrl"
                                placeholder="Add a short description here"
                                maxlength="100" />
                        </div>
                    </div>
                    <div class="sixteen wide column" style="margin-top: 10px">
                        <div class="field">
                            <label>Description:</label>
                            <textarea
                                name="lDescript"
                                [formControl]="reqLDescriptionCtrl"
                                placeholder="Add a long description here"
                                rows="8"></textarea>
                        </div>
                    </div>
                    <div class="sixteen wide column" style="margin-top: 10px">
                        <div class="field">
                            <label>Guidance:</label>
                            <textarea
                                name="Guidance"
                                [formControl]="reqGuidanceCtrl"
                                placeholder="Guidance"
                                rows="8"></textarea>
                        </div>
                    </div>
                    <div class="sixteen wide column" style="margin-top: 10px">
                        <div style="margin-bottom: 20px; padding-bottom: 15px">
                            <div>
                                <label>Reference URLs:</label>
                            </div>
                            <div>
                                <table
                                    class="itc-table sortable"
                                    sortable-table
                                    sortColumn="Label"
                                    sortDirection="asc"
                                    (sorted)="sortReferenceURL($event)"
                                    style="
                                        margin-bottom: 0 !important;
                                        margin-top: 0;
                                        overflow-x: auto;
                                    ">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th style="text-align: left" sortable-column="Label">
                                                Label
                                            </th>
                                            <th style="text-align: left" sortable-column="Url">
                                                URL
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="
                                                let url of selectedRequirement.ReferenceUrls;
                                                let i = index
                                            ">
                                            <td>
                                                <itc-button
                                                    type="icon"
                                                    icon="fa-trash-can"
                                                    (onclick)="deleteRefUrl(url, i)"
                                                    ttposition="top"
                                                    tooltip="Delete"></itc-button>
                                            </td>
                                            <td>
                                                {{
                                                    url.Label.length > 40
                                                        ? (url.Label | slice : 0 : 40) + '..'
                                                        : url.Label
                                                }}
                                            </td>
                                            <td style="word-break: break-all">
                                                {{
                                                    url.Url.length > 60
                                                        ? (url.Url | slice : 0 : 60) + '..'
                                                        : url.Url
                                                }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="max-width: 60px">
                                                <itc-button
                                                    type="icon"
                                                    icon="fa-plus"
                                                    (onclick)="addRefUrl()"
                                                    ttposition="top"
                                                    tooltip="Add"></itc-button>
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    name="Label"
                                                    [formControl]="refLabelCtrl"
                                                    placeholder="Label" />
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    name="URL"
                                                    [formControl]="refUrlCtrl"
                                                    placeholder="URL" />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="sixteen wide column" style="margin-top: 10px">
                        <div class="overflow:auto; margin-bottom: 20px; padding-bottom: 15px">
                            <div>
                                <label style="margin-bottom: 30px">
                                    Check all controls needed to implement the requirement. Use the
                                    search filter to limit the list of controls.
                                </label>
                                <div class="ui grid">
                                    <div class="row" style="padding-top: 0px">
                                        <div
                                            class="eight wide column"
                                            style="display: flex; flex-direction: column-reverse">
                                            <label>
                                                Controls List
                                                <span
                                                    tooltip="This is a list of controls that you have applied to this Standard's Requirement"
                                                    ttPosition="top-right">
                                                    <i
                                                        class="info circle icon"
                                                        style="color: #0e6eb8"></i>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="eight wide column">
                                        <div style="display: flex; align-items: center">
                                            <div class="searchOption">
                                                <itc-input
                                                    placeholder="Search..."
                                                    [(model)]="searchControlValue"
                                                    (modelChange)="filterControls(true)"
                                                    style="float: left; width: 200px"
                                                    icon="icon-search"></itc-input>
                                            </div>
                                            <div style="margin-left: 30px">
                                                <itc-checkbox
                                                    label="Display Selected Controls"
                                                    (onChanged)="
                                                        showSelectedControls($event)
                                                    "></itc-checkbox>
                                            </div>
                                        </div>
                                    </div>

                                    <table
                                        sortable-table
                                        class="itc-table"
                                        (sorted)="sortControls($event)"
                                        style="margin-bottom: 0 !important">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <itc-checkbox
                                                        [checked]="checkAllControl"
                                                        (onChanged)="
                                                            controlsCheckedAll($event)
                                                        "></itc-checkbox>
                                                </th>
                                                <th sortable-column="ControlId">Control ID</th>
                                                <th sortable-column="Description">Description</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let control of pagedControls">
                                                <td>
                                                    <itc-checkbox
                                                        [checked]="control.checked"
                                                        (onChanged)="
                                                            controlChecked(control, $event)
                                                        "></itc-checkbox>
                                                </td>
                                                <td>{{ control.ControlId }}</td>
                                                <td>
                                                    {{ control.ShortDesc.LocalizedText[culture] }}
                                                </td>
                                            </tr>
                                            <!-- <tr
                                                *ngIf="
                                                    assessmentTabProperties.addAssessmentProperties
                                                        .addContolModal.controlsAvailable.length ==
                                                    0
                                                ">
                                                <td colspan="3">No Controls Found</td>
                                            </tr> -->
                                        </tbody>
                                    </table>
                                    <itc-paginator
                                        [data]="sortedControls"
                                        (onPage)="pagedControls = $event"></itc-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div *ngIf="stage == 3">
            <div class="ui grid">
                <div class="sixteen wide column">
                    <h2>Confirmation</h2>
                    Data has not been saved. Are you sure you want to cancel?
                </div>
            </div>
        </div>
    </modal-content>
    <modal-actions>
        <div *ngIf="stage != 3">
            <itc-button
                *ngIf="stage == 1"
                size="regular"
                type="secondary"
                (onclick)="modalCancel()">
                Cancel
            </itc-button>
            <itc-button
                *ngIf="stage == 2"
                icon="fa-arrow-left"
                size="regular"
                type="secondary"
                (onclick)="modalCancel()">
                Back
            </itc-button>
            <itc-button
                icon="fa-check"
                size="regular"
                type="primary"
                [disabled]="!standardsForm.valid || !standardsForm.dirty"
                (onclick)="saveSelectedStandard()"
                *ngIf="stage == 1 && !isViewOnlyMode"
                [loading]="saving">
                {{ selectedStandard.Id == -1 ? 'Add' : 'Save' }}
            </itc-button>
            <itc-button
                icon="fa-check"
                size="regular"
                type="primary"
                (onclick)="saveSelectedRequirement()"
                *ngIf="stage != 1">
                {{ reqEditIndex == -1 ? 'Add' : 'Save' }}
            </itc-button>
        </div>
        <div *ngIf="stage == 3">
            <itc-button size="regular" type="secondary" (onclick)="clickedNo()">No</itc-button>
            <itc-button size="regular" type="danger" (onclick)="clickedYes()">Yes</itc-button>
        </div>
    </modal-actions>
</sm-modal>

<sm-modal title="Delete Standard {{ selectedStandard.Name }}" class="xs" #deleteStandard>
    <modal-content>
        <label>Delete {{ selectedStandard.Name }}? This action can't be undone.</label>
    </modal-content>
    <modal-actions>
        <itc-button size="regular" type="secondary" class="close" (onclick)="closeStandardEditor()">
            Cancel
        </itc-button>
        <itc-button
            size="regular"
            type="danger"
            icon="fa-trash-can"
            (onclick)="deleteSelectedStandard()">
            Delete
        </itc-button>
    </modal-actions>
</sm-modal>

<sm-modal title="Clone Standard {{ selectedStandard.Name }}" class="sm" #cloneStandard>
    <modal-content>
        <p>
            Create a clone of the current standard with a new name. You can modify the derived
            standard without affecting the original standard.
        </p>
        <form class="ui form">
            <div class="sixteen wide column row-style">
                <div class="required field">
                    <label>
                        Standard Name:
                        <span
                            *ngIf="clonedStandardNameCtrl?.errors?.notUniqueName"
                            style="color: var(--text-critical)">
                            Standard names must be unique.
                        </span>
                    </label>
                    <input
                        style="margin-top: 2px"
                        type="text"
                        name="ControlName"
                        [formControl]="clonedStandardNameCtrl"
                        placeholder="Standard Name" />
                </div>
            </div>
        </form>
    </modal-content>
    <modal-actions>
        <itc-button class="close" type="secondary" (onclick)="closeStandard()">Close</itc-button>
        <itc-button
            icon="fa-clone"
            type="primary"
            (onclick)="cloneSelectedStandard()"
            [disabled]="!clonedStandardNameCtrl.valid"
            [loading]="cloning">
            Clone
        </itc-button>
    </modal-actions>
</sm-modal>
