import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
    ProgressDirection,
    ProgressPagination,
    ProgressSize,
    ProgressStep,
} from './progress-tracker.model';
import { ItcProgressTrackerService } from './progress-tracker.service';
import { Observable, Subject } from 'rxjs';

@Component({
    selector: 'progress-tracker',
    template: `
        <div
            class="progress-tracker__container"
            [class.progress-tracker--compact]="size === 'compact'"
            *ngIf="pageination$ | async as pagination">
            <itc-button
                type="icon"
                icon="fa-chevron-left"
                (click)="progressTrackerService.updatePage('prev')"
                [hidden]="!pagination.hasPreviousPage"></itc-button>
            <progress-tracker-step
                *ngFor="
                    let step of steps$
                        | async
                        | slice
                            : pagination.currentPage * pagination.maximumPageSize
                            : (pagination.currentPage + 1) * pagination.maximumPageSize;
                    let i = index
                "
                [step]="step"
                [stepNumber]="
                    i + pagination.currentPage * pagination.maximumPageSize
                "></progress-tracker-step>
            <itc-button
                type="icon"
                icon="fa-chevron-right"
                (click)="progressTrackerService.updatePage('next')"
                [hidden]="!pagination.hasNextPage"></itc-button>
        </div>
    `,
    styleUrls: ['./progress-tracker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItcProgressTrackerComponent {
    constructor(public progressTrackerService: ItcProgressTrackerService) {}

    @Input() set steps(steps: ProgressStep[]) {
        this.progressTrackerService.setSteps(steps);
    }
    @Input() direction: ProgressDirection = 'horizontal'; // vertical not done yet as we haven't used it yet, I'll deal with it then
    @Input() size: ProgressSize = 'regular';
    @Input() set maxDisplayedSteps(max: number) {
        this._maxDisplayedSteps = max;
        this.progressTrackerService.setMaximumPageSize(max);
    }
    get maxDisplayedSteps(): number {
        return this._maxDisplayedSteps;
    }
    _maxDisplayedSteps = 4;
    steps$: Observable<ProgressStep[]> = this.progressTrackerService.getSteps();
    pageination$: Observable<ProgressPagination> = this.progressTrackerService.getPagination();
}
