<sm-modal title="Modify Style" class="sm" (onModalShow)="scrollToModalTop()" #modal>
    <modal-content>
        <form class="ui form" name="docStyleForm">
            <div class="field">
                <label for="styleName">Style Name</label>
                <input
                    type="text"
                    [disabled]="true"
                    [(ngModel)]="theme.ThemeName"
                    name="styleName" />
            </div>

            <div class="field">
                <label for="fontFamily">Font Family</label>
                <sm-select [control]="fontCtrl" name="fontFamily" #fontSelect>
                    <option *ngFor="let opt of fontFamilies">{{ opt }}</option>
                </sm-select>
            </div>

            <div class="field" style="margin-bottom: 16px">
                <label for="fontSize">Font Size</label>
                <wj-input-number
                    [(value)]="theme.DefaultFontSize"
                    [format]="'I'"
                    [step]="1"
                    style="width: 150px; margin-right: 5px; display: block"></wj-input-number>
            </div>

            <div class="ui attached segment">
                <div class="ui top attached label">Top Five Color Palette</div>
                <div class="content">
                    <ul id="topFiveCP">
                        <li *ngFor="let i of [0, 1, 2, 3, 4]">
                            <label [for]="'cp' + i">Color {{ i + 1 }}</label>
                            <span
                                [colorPicker]="palette[i]"
                                (colorPickerChange)="onColorChange(i, $event)"
                                [style.background-color]="palette[i]"
                                accesskey="'disabled'"
                                [cpPosition]="'bottom'"
                                (cpToggleChange)="fixCPLeft()"></span>
                        </li>
                    </ul>
                </div>
            </div>

            <br />

            <div class="ui attached segment">
                <div class="ui top attached label">Chart Style</div>
                <div class="content">
                    <div class="chartStyleOuterContainer imageRadio">
                        <div class="chartStyleContainer">
                            <input
                                type="radio"
                                name="ChartQuality"
                                [checked]="theme.ChartQuality == 'Standard2D'"
                                id="csH2D"
                                (change)="changeChartQuality('Standard2D')" />
                            <label for="csH2D">
                                <img src="/assets/img/pielegacy.png" />
                                Hi-Res 2D
                            </label>
                        </div>

                        <div class="chartStyleContainer">
                            <input
                                type="radio"
                                name="ChartQuality"
                                id="csL2D"
                                [checked]="theme.ChartQuality == 'Legacy'"
                                (change)="changeChartQuality('Legacy')" />
                            <label for="csL2D">
                                <img src="/assets/img/pie2d.png" />
                                Low-Res 2D
                            </label>
                        </div>

                        <div class="chartStyleContainer">
                            <input
                                type="radio"
                                name="ChartQuality"
                                id="csH3D"
                                [checked]="theme.ChartQuality == 'Transparent3D'"
                                (change)="changeChartQuality('Transparent3D')" />
                            <label for="csH3D">
                                <img src="/assets/img/pie3d.png" />
                                Hi-Res 3D (with transparency)
                            </label>
                        </div>

                        <div
                            class="chartTransparency"
                            style="display: block; width: 280px; border: 0; margin-top: 10px"
                            *ngIf="theme.ChartQuality == 'Transparent3D'">
                            Transparency
                            <wj-input-number
                                [(value)]="theme.ChartTransparencyPercent"
                                [step]="1"
                                [min]="0"
                                [max]="100"></wj-input-number>
                        </div>
                    </div>
                </div>
            </div>

            <br />

            <div class="ui attached segment">
                <div class="ui top attached label">Risk Meter</div>
                <div class="content">
                    <div class="riskTypeOuterContainer imageRadio">
                        <div class="riskTypeContainer">
                            <input
                                type="radio"
                                [checked]="
                                    !theme.UseNewRiskMeter2020 && theme.UseNewRiskMeter == true
                                "
                                id="rt1"
                                (change)="onChangeRiskMeter('new')" />
                            <label for="rt1">
                                <img src="/assets/img/risk1.png" />
                            </label>
                        </div>

                        <div class="riskTypeContainer">
                            <input
                                type="radio"
                                [checked]="
                                    !theme.UseNewRiskMeter2020 && theme.UseNewRiskMeter == false
                                "
                                id="rt2"
                                (change)="onChangeRiskMeter('old')" />
                            <label for="rt2">
                                <img src="/assets/img/risk2.png" />
                            </label>
                        </div>

                        <div class="riskTypeContainer" *ngIf="theme.ReportModernizationEnabled">
                            <input
                                type="radio"
                                [checked]="theme.UseNewRiskMeter2020 == true"
                                id="rt2020"
                                (change)="onChangeRiskMeter('modern')" />
                            <label for="rt2020">
                                <img src="/assets/img/risk2020.png" />
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <br />

            <div class="ui attached segment" *ngIf="theme.ReportModernizationEnabled">
                <div class="ui top attached label">Table Style</div>
                <div class="content">
                    <div class="modernTableStyleOuterContainer imageRadio">
                        <div class="modernTableStyleContainer">
                            <input
                                type="radio"
                                [checked]="
                                    theme.ReportModernizationTableType == 'bottom border gray'
                                "
                                id="tt1"
                                (change)="onChangeTableType('bottom border gray')" />
                            <label for="tt1">
                                <img
                                    src="/assets/img/modernTables/botrows/{{
                                        themeTableName
                                    }}.jpg" />
                            </label>
                        </div>
                        <div class="modernTableStyleContainer">
                            <input
                                type="radio"
                                [checked]="
                                    theme.ReportModernizationTableType ==
                                    'header color alternating rows'
                                "
                                id="tt2"
                                (change)="onChangeTableType('header color alternating rows')" />
                            <label for="tt2">
                                <img
                                    src="/assets/img/modernTables/altrows/{{
                                        themeTableName
                                    }}.jpg" />
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" class="cancel" (onclick)="modal.hide()">Cancel</itc-button>
        <itc-button class="primary" (onclick)="saveStyle()">Save</itc-button>
    </modal-actions>
</sm-modal>
