import { Component, DoCheck, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

class ButtonPreset {
    public class?: string;
    public color?: string;
    public icon?: string;
    public text?: string;
}

const Presets = {
    dismiss: { class: 'cancel', icon: 'times', text: 'Dismiss' },
    no: { color: 'red', icon: 'remove', text: 'No' },
    save: { color: 'primary', icon: 'save', text: 'Save' },
    yes: { color: 'green', icon: 'checkmark', text: 'Yes' },
};

@Component({
    selector: 'sds-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.css'],
})
export class ButtonComponent implements OnInit {
    constructor() {}

    forceDisable: boolean = false;

    @Input() class: string;
    @Input() color: string;
    @Input() disabled: boolean;
    @Input() icon: string;
    @Input() label: string;
    @Input() loading: boolean;
    @Input() link: string;
    @Input() params: string;
    @Input() title: string;
    @Input() disableAfterClick: boolean;
    @Input() loadingAfterClick: boolean;
    @Input() internalIcon: boolean = false;

    @ViewChild('innerContent') innerContent: any;

    hasLabel: boolean;

    presetContent: string;
    @Input() set preset(val: string) {
        if (Presets[val]) {
            let pre = Presets[val] as ButtonPreset;

            this.color = this.color || pre.color;
            this.icon = this.icon || pre.icon;
            this.class = this.class || pre.class;
            this.presetContent = pre.text;
        }
    }

    @Output('onclick') onclick: EventEmitter<any> = new EventEmitter<any>();

    ngOnInit() {
        //this.hasLabel = (this.innerContent && this.innerContent.nativeElement.innerText.trim() != '');
    }

    ngDoCheck() {
        //this.hasLabel = (this.innerContent && this.innerContent.nativeElement.innerText.trim() != '');
    }

    click(ev) {
        if (this.disabled || this.loading || this.forceDisable) {
            return;
        }
        this.forceDisable = true; // set forceDisable to avoid accidental double clicks
        this.onclick.emit(ev);
        setTimeout(() => (this.forceDisable = false), 500);
        if (this.disableAfterClick) {
            this.disabled = true;
        }
        if (this.loadingAfterClick) {
            this.loading = true;
        }
    }
}
