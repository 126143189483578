import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterObjects',
})
export class FilterObjectsPipe implements PipeTransform {
    transform(value: any[], compare: any[], condition: string): any[] {
        if (value && compare && condition?.indexOf('=') > -1) {
            let splitCondition = condition.split(/!?=/);
            let valueField = splitCondition[0];
            let compareField = splitCondition[1];
            let invertCondition = condition.indexOf('!=') > -1;
            return value.filter(v => { return compare.some(c => { return (compareField ? c[compareField] : c) === (valueField ? v[valueField] : v) }) != invertCondition });
        }
        return value;
    }
}
