<sds-loader [complete]="connectorTableLoadingComplete">
    <table class="itc-table">
        <thead>
            <tr>
                <th class="collapsing">
                    <itc-checkbox
                        label="&nbsp;"
                        [control]="connectorFilesSelectAll"
                        (change)="toggleAllConnectorFileSelections()"
                        name="seletAllConnectorFileCheckbox"
                        ngDefaultControl></itc-checkbox>
                </th>
                <th>Computer</th>
                <th>Upload Date</th>
                <th>Type</th>
            </tr>
        </thead>
        <tr
            *ngFor="let connectorFile of pagedConnectorFiles; let i = index"
            class="existingFileRow">
            <td>
                <itc-checkbox
                    label="&nbsp; "
                    [control]="connectorFileControls[i + pageNumber * pageSize]"
                    (change)="toggleConnectorFile(i)"
                    name="{{ 'filecheck' + i }}"
                    ngDefaultControl></itc-checkbox>
            </td>
            <td>
                {{ connectorFile.filename }}
            </td>
            <td>
                {{ connectorFile.lastmodified | date : 'medium' }}
            </td>
            <td>{{ connectorFile.filetype }}</td>
        </tr>
    </table>

    <itc-paginator [data]="connectorFiles" (onPage)="pagedConnectorFiles = $event"></itc-paginator>
</sds-loader>
