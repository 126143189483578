import { Requirement } from '../standards/requirement.model';
import { I18N } from '../standards/i18n.model';
import { EvidenceOfCompliance } from './evidence.model';

export class Control {
    Id: number;
    ControlId: string;
    ShortDesc: I18N;
    LongDesc: I18N;
    Guidance: I18N;
    Policy: I18N;
    CsProGuidance: I18N;
    Procedures: I18N[];
    Requirements: Requirement[];
    EvidenceOfComplianceOptions: EvidenceOfCompliance[];
    IsSystem: boolean;
    Selected: boolean;
    AccountId: string;
    SiteId: number;
    HasOverrides: boolean;

    constructor() {
        this.Id = -1;
        this.ControlId = '';
        this.ShortDesc = new I18N();
        this.LongDesc = new I18N();
        this.Guidance = new I18N();
        this.Policy = new I18N();
        this.CsProGuidance = undefined;
        this.Procedures = [];
        this.Requirements = [];
        this.EvidenceOfComplianceOptions = [];
        this.IsSystem = false;
        this.Selected = false;
        this.AccountId = '';
        this.SiteId = -1;
        this.HasOverrides = false;
    }
}

export class ClonedControl {
    Culture?: string;
    ControlId?: string;
    ShortDesc?: string;
    OrigId: number;
    OrigControlId: string;
    OrigShortDesc: string;
}
