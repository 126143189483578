import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'orderBy',
})
export class OrderByPipe implements PipeTransform {
    transform(value: any, propertyName: string): any {
        if (value != undefined) {
            return value.sort((a, b) => {
                return a[propertyName]
                    .toString()
                    .trim()
                    .toLowerCase()
                    .localeCompare(b[propertyName].toString().trim().toLowerCase());
            });
        }
    }
}
