<sds-breadcrumb-trail [crumbs]="breadcrumbs"></sds-breadcrumb-trail>
<header class="pageHeader cm1HasSelectAssessment">
    <h1>Reports</h1>
    <div class="flex-right" *ngIf="loadingComplete">
        <sm-select
            [control]="reportLocaleCtrl"
            [disabled]="!reportLocales?.length"
            [loading]="loadingLocales"
            #localeSelect>
            <option
                *ngFor="let locale of reportLocales"
                value="{{ locale }}"
                selected="{{ selectedLocale == locale ? 'selected' : '' }}">
                {{ languageMap[locale] }}
            </option>
        </sm-select>

        <itc-button
            label="Download .zip"
            type="primary"
            icon="fa-download"
            [loading]="loadingZip"
            (onclick)="downloadReportZip()"
            *ngIf="reportList?.length"></itc-button>
    </div>
</header>

<sds-loader [complete]="loadingComplete">
    <itc-banner type="info" *ngIf="reportList && !reportList.length">
        No reports found for the current assessment.
    </itc-banner>

    <itc-banner type="info" title="No Assessments Found" *ngIf="noAssessment">
        There are not currently any assessments for this site. You can start the assessment from the
        To Do list, after installing the Compliance Manager Server.
    </itc-banner>

    <table class="itc-table" *ngIf="reportList?.length" sortable-table (sorted)="onSorted($event)">
        <thead>
            <tr>
                <th></th>
                <th sortable-column="filename">Filename</th>
                <th sortable-column="lastmodified">Last Modified</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let report of reportList">
                <td class="collapsing">
                    <itc-button
                        type="icon"
                        icon="fa-download"
                        [class.loading]="report.loading"
                        (onclick)="downloadReport(report)"
                        tooltip="Download Report"></itc-button>
                </td>
                <td>{{ report.filename }}</td>
                <td>{{ report.lastmodified | date : 'medium' }}</td>
            </tr>
        </tbody>
    </table>
</sds-loader>
