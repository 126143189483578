export const SETTINGS: string = 'DATA_SETTINGS';
export const PING_SETTINGS: string = 'PING_SETTINGS';
export const FETCH_SETTINGS: string = 'FETCH_SETTINGS';

export const SDSP_SETTINGS: string = 'sds_portal_settings';
export const K1_DISABLE: string = 'K1_DISABLE';

export const ITG_NAVIGATE_FROM: string = 'ITG_NAVIGATE_FROM_QUESTION';

export const DEFAULTS: any = {
    CompanyName: '',
    BannerColor: '#F2711C',
};

export const ADMINALERTS: any = {
    EMAILTO: 'SDS_ADMINALERTS_EMAILTO',
    SUBJPREFIX: 'SDS_ADMINALERTS_SUBJPREFIX',
    SENDSCANFAILED: 'SDS_ADMINALERTS_SENDSCANFAILED',
    SENDNOTIFYERROR: 'SDS_ADMINALERTS_SENDNOTIFYERROR',
    SENDSCANCOMPLETE: 'SDS_ADMINALERTS_SENDSCANCOMPLETE',
    SENDREPORTSCOMPLETE: 'SDS_ADMINALERTS_SENDREPORTSCOMPLETE',
    SENDMERGECOMPLETE: 'SDS_ADMINALERTS_SENDMERGECOMPLETE',
};

export const SCANOPTIONS: any = {
    LIMITEDDEEPSCAN: 'limiteddeepscan',
    SKIPDEEPSCAN: 'skipdeepscan',
    SKIPWEBFILTERING: 'skipwebfiltering',
    SKIPMALICIOUSFILEDOWNLOAD: 'skipmaliciousfiledownload',
    SKIPEXTVULSCAN: 'skipextvulnscan',
    SKIPBROWSERPWDSCAN: 'skipbrowserpwdscan',
    PERFORMDARKWEBSCAN: 'performdarkwebscan',
    DARKWEBDOMAINS: 'darkwebdomains',
};
