<!--<div class="ui buttons">
    <div class="ui button" [class.black]="someFilter == 1" [class.darker]="someFilter != 1" (click)="toggleFilter(1)">All Items</div>    
    <div class="ui button" [class.black]="someFilter == 2" [class.darker]="someFilter != 2" (click)="toggleFilter(2)">My Items</div>
</div>-->
<div class="ui input">
    <sm-select [control]="itemsCtrl" (modelChange)="onChange()">
        <option value="1" default>All Items</option>
        <option value="2">Open Items</option>
        <i class="dropdown icon"></i>
    </sm-select>
</div>
