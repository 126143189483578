import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MessageService } from 'app/core/message.service';
import { environment } from 'environments/environment';

const LAST_ANNOUNCEMENT_ID: string = 'sdsportal_announcement_lastid';
const ANNOUNCEMENT_FTRS: string = 'sdsportal_announcement_ftrs';
const HIDE_AFTER_VIEW: string = 'sdsportal_announcement_hide';
const LAST_ANNOUNCEMENT_BATCH: string = 'sdsportal_announcement_lastbatch';

export const ANNOUNCEMENT: string = 'ANNOUNCEMENT';
export const PING_ANNOUNCEMENT: string = 'PING_ANNOUNCEMENT';

export class Announcement {
    public Id: string;
    public HtmlContent: string;
}

@Injectable({ providedIn: 'root' })
export class AnnouncementService {
    private queue: Announcement[];

    public newAnnouncements: number = 0;
    sub: any;

    constructor(private http: HttpClient, private msgService: MessageService) {
        this.queue = [];
    }

    checkLatestAnnouncement(): Promise<boolean> {
        const apiUrl = environment.apiUrl;

        let ftrs = localStorage.getItem(ANNOUNCEMENT_FTRS);

        console.log(ftrs);
        return new Promise((resolve, reject) => {
            this.http
                .get(apiUrl + `Announcements/Latest?products=${ftrs}`)
                .subscribe((id: string) => {
                    let latestViewed = localStorage.getItem(LAST_ANNOUNCEMENT_ID);

                    let needUpdate = false;
                    if (
                        (id || 'null') != 'null' &&
                        (!(latestViewed == 'null') || latestViewed != id)
                    ) {
                        needUpdate = true;
                    } else {
                        let lastBatch = localStorage.getItem(LAST_ANNOUNCEMENT_BATCH);
                        if (!lastBatch) this.queue = [];
                        else this.queue = JSON.parse(lastBatch);
                    }

                    resolve(needUpdate);
                });
        });
    }

    updateAnnouncements(): Promise<number> {
        const apiUrl = environment.apiUrl;

        let ftrs = localStorage.getItem(ANNOUNCEMENT_FTRS);
        let lastSeen = localStorage.getItem(LAST_ANNOUNCEMENT_ID);
        this.sub = this.msgService.on(PING_ANNOUNCEMENT).subscribe(() => this.onPingAnnouncement());
        if (!ftrs) return new Promise((resolve) => resolve(0));

        return this.http
            .get(
                apiUrl +
                    `Announcements?products=${ftrs}` +
                    (lastSeen && lastSeen != 'null' ? `&lastSeen=${lastSeen}` : '')
            )
            .toPromise()
            .then((announcements: Announcement[]) => {
                this.queue = announcements.sort(
                    (a, b) => new Date(a.Id).valueOf() - new Date(b.Id).valueOf()
                );
                localStorage.setItem(LAST_ANNOUNCEMENT_BATCH, JSON.stringify(this.queue));
                console.log(this.queue);
                this.newAnnouncements = this.queue.length;
                this.msgService.broadcast(ANNOUNCEMENT, this.queue);
                return this.newAnnouncements;
            })
            .catch((err) => {
                return 0;
            });
    }

    onPingAnnouncement() {
        if (!this.queue || this.queue.length == 0) {
            this.updateAnnouncements();
        } else {
            this.msgService.broadcast(ANNOUNCEMENT, this.queue);
        }
    }

    getAnnouncementQueue(): Announcement[] {
        return this.queue;
    }

    setAnnouncementFtrs(ftrs: string) {
        localStorage.setItem(ANNOUNCEMENT_FTRS, ftrs);
    }

    getModalHidePreference(): boolean {
        return localStorage.getItem(HIDE_AFTER_VIEW) == 'true';
    }

    setModalHidePreference(hideAfterView: boolean) {
        localStorage.setItem(HIDE_AFTER_VIEW, '' + hideAfterView);
    }

    setLastViewed(id: string) {
        localStorage.setItem(LAST_ANNOUNCEMENT_ID, id);
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }
}
