import { Injectable } from '@angular/core';
import { ScanDataComputer } from './scan-data-computer.model';
import { ScanDataUser } from './scan-data-user.model';
import { ScanDataApplication } from './scan-data-application.model';
import { ScanDataDatabase } from './scan-data-database.model';
import { ScanDataSecurityGroup } from './scan-data-security-group.model';
import { ScanDataNonADDevice } from './scan-data-nonad-device.model';
import { ScanDataPrinter } from './scan-data-printer.model';

@Injectable()
export class ScanDataHelper {
    public getUserUID(user: ScanDataUser): string {
        let userObj = {
            User: {
                Domain: user.Domain,
                Username: user.Username,
            },
        };

        return JSON.stringify(userObj);
    }

    public getComputerUID(computer: ScanDataComputer): string {
        let computerObj = {
            Computer: {
                Domain: computer.Domain,
                Name: computer.Name,
            },
        };

        return JSON.stringify(computerObj);
    }

    public getDatabaseUID(database: ScanDataDatabase): string {
        let databaseObj = {
            Database: {
                Domain: database.Domain,
                Server: database.Server,
            },
        };

        return JSON.stringify(databaseObj);
    }

    public getApplicationUID(application: ScanDataApplication): string {
        let applicationObj = {
            Application: {
                Domain: application.Domain,
                Name: application.Name,
                VersionNumber: application.Version
            },
        };

        return JSON.stringify(applicationObj);
    }

    public getSecurityGroupUID(securityGroup: ScanDataSecurityGroup): string {
        let securityGroupObj = {
            SecurityGroup: {
                GroupName: securityGroup.GroupName,
                Path: securityGroup.Path,
            },
        };

        return JSON.stringify(securityGroupObj);
    }
}
