import { Requirement } from './requirement.model';
import { I18N } from '../standards/i18n.model';

export class Standard {
    Id: number;
    Name: string;
    Variant: string;
    Revision: string;
    Updated: Date;
    Requirements: Requirement[];
    IsSystem: boolean;
    IsDeprecated?: boolean;
    Selected: boolean | 'mixed';
    Purpose: I18N;
    Scope: I18N;
    Crosswalks: Crosswalk[];
    HasOverrides: boolean;

    constructor() {
        this.Id = -1;
        this.Name = '';
        this.Variant = '';
        this.Revision = '';
        this.Updated = new Date();
        this.Requirements = [];
        this.IsSystem = false;
        this.Selected = false;
        this.Purpose = new I18N();
        this.Scope = new I18N();
        this.Crosswalks = [];
        this.HasOverrides = false;
    }
}

export class Crosswalk {
    Id: number;
    StandardId: number;
    CrosswalkDesc: string;
    DisplayOrder: number;
}
