import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { fromEvent, Observable, map, take, takeUntil, Subject, catchError, EMPTY } from 'rxjs';
import {
    FileUploaderSummary,
    FileUploaderState,
    FileUploaderReturnType,
    FileUploaderReturn,
    FileUploaderErrorStructure,
    FileUpload,
    UploadOptions,
} from './file-uploader.model';
import { ItcButtonComponent } from '../button/button.component';
import { NotificationService } from '../notification/notification.service';
import { HttpErrorResponse, HttpEvent, HttpEventType } from '@angular/common/http';
import { FileSizePipe } from 'app/shared/fileSize.pipe';
import { Attachment } from 'app/sites/site/inform/attachment.model';
import { MessageService } from 'app/core/message.service';
import { FILEUPLOADED, FILEUPLOADFINISHED } from 'app/sites/shared/constants';
import { ItcFileUploaderService } from './file-uploader.service';
import { CommonModule } from '@angular/common';
import { FileListItemComponent } from './file-list-item/file-list-item.component';

@Component({
    standalone: true,
    imports: [CommonModule, ItcButtonComponent, FileListItemComponent, FileSizePipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'itc-file-uploader',
    templateUrl: './file-uploader.component.html',
    styleUrls: ['./file-uploader.component.scss'],
})
export class ItcFileUploaderComponent implements OnInit {
    @Input() maxConcurrentUploads = 2;
    @Input() set maxFileSize(val: number) {
        if (val) {
            this._maxFileSize = val * 1000000;
        }
    }
    get maxFileSize() {
        return this._maxFileSize;
    }
    @Input() showMaxSize = true;
    @Input() showProgress: boolean;
    @Input() accept: string;
    @Input() allowedFileTypes: string;
    @Input() openOnClick = true;
    @Input() disabled = false;
    @Input() uploadErrorTitle = 'There was a problem uploading your file.';
    @Input() dropMessage = '<span>Browse</span> or drop file';
    @Input() className: string;
    @Input() allowMultiple = false;
    @Input() returnType: FileUploaderReturnType = 'DataUrl';
    @Input() type: 'button' | 'draganddrop' | 'dropdown' | 'photo' = 'button';
    @Input() uploadTitle: string;
    @Input() uploadText: string;
    @Input() style: undefined;
    @Input() dropzoneStyles: undefined;
    @Input() image: string;
    @Input() buttonText = 'Upload';
    @Input() buttonType = 'primary';
    @Input() buttonIcon = 'fa-upload';
    @Input() size = '';
    @Input() successToastTitle = 'File Upload';
    @Input() successToastText = 'File successfully uploaded.';
    @Input() errorToastTitle = 'Upload Error';
    @Input() errorToastText = 'There was a problem uploading your file';
    @Input() allowToasts = true;
    @Input() forceOnlyOne = false;
    @Input() keepUploadedList = true;
    @Input()
    get extraInfo(): string {
        return this._extraInfo;
    }
    set extraInfo(val: string) {
        if (val) {
            this._extraInfo = val;
        }
    }
    @Input() currentFiles: FileUpload[] = [];

    @Output() uploadFile = new EventEmitter<File | FileUpload>();
    @Output() uploadStart = new EventEmitter<FileUpload | FileUpload[]>();
    @Output() uploadDone = new EventEmitter<FileUploaderSummary>();
    @Output() uploaded = new EventEmitter<any>();
    @Output() uploadDragOver = new EventEmitter<DragEvent>();
    @Output() uploadDrop = new EventEmitter<DragEvent>();
    @Output() uploadFailed = new EventEmitter<FileUploaderErrorStructure>();
    @Output() fileUploaded = new EventEmitter<Attachment>();
    @Output() fileUploadStarted = new EventEmitter<FileUpload>();
    @Output() removeFile = new EventEmitter<FileUpload>();

    @ViewChild('uploadInput') input: ElementRef;

    state: FileUploaderState = { focused: 0 };
    errors: any = {};
    leftToProcess = 0;
    inProgress = 0;
    queue: FileUpload[] = [];
    _maxFileSize = 0;
    returnedFiles: FileUploaderReturn[] = [];
    activeUploads: FileUpload[];
    uploads: FileUpload[] = [];
    uploadRules = '';
    _extraInfo: string;
    uploadError: string;
    isUploading = false;

    constructor(
        private notificationService: NotificationService,
        private cdr: ChangeDetectorRef,
        private fileSizePipe: FileSizePipe,
        private messageService: MessageService,
        private fileUploaderService: ItcFileUploaderService
    ) {}

    ngOnInit(): void {
        // make dropmessage plural if we allowmultiple
        if (this.allowMultiple) {
            this.dropMessage = this.dropMessage.replace('file', 'files');
        }
        this.showProgress = this.returnType === 'Upload'; // only show progress on upload file types because that's all I can really read

        if (this.returnType === 'DataUrl') {
            this.allowToasts = false;
        }
    }

    // output events

    // dragged over, not sure why we'd need this, but eh it's in the kaseya-ui version
    onUploadDragOver(event): void {
        this.uploadDragOver.emit(event);
    }
    // file dropped, not sure why we'd need this, but eh it's in the kaseya-ui version
    onUploadDrop(event): void {
        this.uploadDrop.emit(event);
    }
    // upload failed, send back errors
    onUploadFailed(): void {
        this.uploadFailed.emit(this.errors);
    }

    _handleClick() {
        if (this.openOnClick && !this.disabled) {
            this.input.nativeElement.click();
        }
    }

    _handleInputChange(event: Event) {
        this._processFiles(event.target['files']);
    }

    _handleDragOver = (e) => {
        if (this.disabled) {
            return;
        }

        if (this._hasProcessibleFiles(e)) {
            e.preventDefault();

            this.onUploadDragOver(e);
        }
    };

    _handleDrop = (e) => {
        if (this.disabled) {
            return;
        }

        this.onUploadDrop(e);

        if (this._hasProcessibleFiles(e)) {
            e.preventDefault();

            this._processFiles(e.dataTransfer.files);
        }
    };

    _handleDragEnter = (e) => {
        if (this._hasProcessibleFiles(e)) {
            this.state.focused += 1;
            this.cdr.markForCheck();
            // this.setState({ focused: this.state.focused + 1 });
        }
    };

    _handleDragLeave = () => {
        if (this.state.focused) {
            this.state.focused -= 1;
            this.cdr.markForCheck();
            // this.setState({ focused: this.state.focused - 1 });
        }
    };

    _hasProcessibleFiles = (e) => {
        return Array.from(e.dataTransfer.types || []).reduce(
            (acc, type) => acc || type === 'Files',
            false
        );
    };

    _processFiles = (files: FileList) => {
        this.state.focused = 0;
        this.isUploading = true;
        this.errors = { size: [], kind: [], excess: [], other: [] };
        this.returnedFiles = [];
        this.leftToProcess += files.length;
        // this.inProgress = 0;

        if (this.allowToasts) {
            let toastNote = files.length > 1 ? 'Files uploading...' : 'File is uploading';
            this.notificationService.toast.info('File Upload', toastNote);
        }

        if (!this.allowMultiple && files.length > 1) {
            if (this.allowToasts) {
                this.notificationService.toast.error(
                    'File upload failed',
                    'Please upload only one file'
                );
            }
            this.errors.excess.push('Multiple files uploaded: files.length');
            this.onUploadFailed();
        } else {
            let uploadFiles: FileUpload[] = [];
            let thisUploadFile: FileUpload;
            Array.from(files).forEach((file) => {
                // create FileUpload for each file to store information
                thisUploadFile = {
                    extraInfo: this.extraInfo,
                    response: '',
                    name: file.name,
                    type: file.type || '',
                    size: this.fileSizePipe.transform(file.size),
                    icon: this.fileUploaderService.getFileIcon(file),
                    observable: null,
                    progress: 0,
                    state: 'pending',
                    file: file,
                };
                uploadFiles.push(thisUploadFile);
                this._processFile(thisUploadFile);
            });
            let returnFiles = this.allowMultiple ? uploadFiles : uploadFiles[0];
            this.uploadStart.emit(returnFiles);

            // clear input value in case they try to upload the same file somewhere else
            this.input.nativeElement.value = '';
        }
    };

    _checkMime = (file: File): boolean => {
        let acceptedTypes = this.accept.split(/, ?/);
        let fileExt = file.name.split('.').pop();
        let isAccepted = false;
        acceptedTypes.forEach((type) => {
            if (type.charAt(0) === '.') {
                // extension type, check that
                if ('.' + fileExt === type) {
                    isAccepted = true;
                }
            } else {
                // probably a mime type
                if (type.length > 1 && type.slice(-1) === '*') {
                    // wildcard mime type
                    if (file.type.startsWith(type.slice(0, -1))) {
                        isAccepted = true;
                    }
                } else {
                    // regular mime type
                    if (file.type === type) {
                        isAccepted = true;
                    }
                }
            }
        });
        return isAccepted;
    };

    /*
     * The most reliable way to determine if a given file is a folder, is trying to read it.  If there's an error
     * we can be pretty sure it was a folder and reject.
     */
    _processFile = (upload: FileUpload) => {
        if (this.maxFileSize && upload.file.size > this.maxFileSize) {
            this.notificationService.toast.error(
                'File upload failed',
                `File: ${upload.file.name} is over ${this.fileSizePipe.transform(
                    this.maxFileSize
                )} limit`
            );
            this.errors.size.push({
                file: upload.file,
                limit: this.fileSizePipe.transform(this.maxFileSize),
            });
            this.leftToProcess--;
            this.onUploadFailed();

            return;
        }

        if (this.accept && !this._checkMime(upload.file)) {
            this.notificationService.toast.error(
                'File upload failed',
                `File: ${upload.file.name} does not match allowed types`
            );
            this.errors.kind.push(upload.file);
            this.leftToProcess--;
            this.onUploadFailed();

            return;
        }

        const reader = new FileReader();

        //handle the item as a file
        reader.onload = () => {
            this.queue.push(upload);
            this._processComplete();
        };

        //the selected item is a folder -> error
        reader.onerror = () => {
            this.errors.kind.push({ kind: 'folder' });
            this._processComplete();
        };

        reader.readAsDataURL(upload.file);
    };

    _processComplete = () => {
        this.leftToProcess--;
        this._sendNext();
    };

    _hasErrors = () => {
        for (let k in this.errors) {
            if (this.errors[k].length > 0) {
                return true;
            }
        }
    };

    _handleFinish(): void {
        if (this.returnedFiles.length) {
            // only return the 1 item instead of array if there aren't multiple allowed anyway
            let filestoReturn = this.allowMultiple ? this.returnedFiles : this.returnedFiles[0];
            this.uploadDone.emit({ file: filestoReturn, errors: this.errors });
            if (this.forceOnlyOne) {
                this.currentFiles = [];
            }
            if (!this.keepUploadedList) {
                this.uploads = [];
            }
            this.messageService.broadcast(FILEUPLOADFINISHED, filestoReturn);
        } else {
            console.log('No files to return');
            let emptyReturn = { file: [], errors: this.errors };
            this.uploadDone.emit(emptyReturn);
            this.messageService.broadcast(FILEUPLOADFINISHED, emptyReturn);
        }
        this.input.nativeElement.value = '';
        if (this.allowToasts && !this._hasErrors()) {
            this.notificationService.toast.success(this.successToastTitle, this.successToastText);
        }
        this.isUploading = false;
    }

    _sendNext = async () => {
        if (this.queue.length === 0 && this.leftToProcess === 0 && this.inProgress === 0) {
            this._handleFinish();

            return;
        }

        if (this.inProgress >= this.maxConcurrentUploads || this.queue.length === 0) {
            return;
        }

        this.inProgress++;

        const upload = this.queue.pop();
        this._processUpload(upload)
            .pipe(take(1))
            .subscribe((resp) => {
                console.log('resp', resp);
                if (!resp.error) {
                    this.returnedFiles.push(resp);
                    this.uploaded.emit(resp);
                } else {
                    this.errors.others.push({ file: upload.file });
                }
                this.inProgress--;
                this._sendNext();
            });
    };

    _processUpload(upload: FileUpload): Observable<any> {
        let reader = new FileReader();

        switch (this.returnType.toLowerCase()) {
            case 'arraybuffer':
                reader.readAsArrayBuffer(upload.file);
                break;
            case 'binarystring':
                reader.readAsBinaryString(upload.file);
                break;
            case 'dataurl':
                reader.readAsDataURL(upload.file);
                break;
            case 'file':
                reader.readAsText(upload.file);
                break;
            case 'upload':
                this.uploadFile.emit(upload);
                break;
        }

        // return result, or error, and complete it
        return fromEvent(reader, 'load').pipe(
            map(() => {
                if (reader.result) {
                    if (this.returnType === 'File') {
                        return upload.file;
                    } else {
                        upload.result = reader.result;
                        return upload;
                    }
                } else {
                    return { error: 'Error processing file' };
                }
            }),
            take(1)
        );
    }

    processUploadProgress(
        file: File | FileUpload,
        upload: Observable<any>,
        options?: UploadOptions
    ) {
        let thisFile: FileUpload;
        let uploadComplete$: Subject<any> = new Subject();
        if (file['state'] === undefined) {
            thisFile = {
                extraInfo: thisFile.extraInfo,
                response: thisFile.response,
                name: file.name,
                type: file.type || '',
                size: this.fileSizePipe.transform(file.size),
                icon: this.fileUploaderService.getFileIcon(file as File),
                observable: upload,
                progress: 0,
                state: 'pending',
            };
        } else {
            thisFile = file as FileUpload;
            thisFile.observable = upload;
        }
        if (options?.page) {
            thisFile.page = options?.page;
        }
        if (options?.extraInfo) {
            thisFile.extraInfo = options?.extraInfo;
        }

        this.fileUploadStarted.emit(thisFile);

        if (this.forceOnlyOne) {
            this.uploads = [thisFile];
        } else {
            this.uploads.push(thisFile);
        }
        // this.notificationService.toast.info('File Upload', 'File is uploading...');

        upload
            .pipe(
                catchError((err: HttpErrorResponse): Observable<any> => {
                    thisFile.state = 'error';
                    console.log('err', err);
                    console.log('file', thisFile);
                    this.errors.other.push({ file: thisFile.file });
                    if (this.allowToasts) {
                        this.notificationService.toast.error(
                            this.errorToastTitle,
                            this.errorToastText
                        );
                    }
                    this.inProgress--;
                    this._sendNext();
                    this.cdr.markForCheck();
                    return EMPTY;
                }),
                takeUntil(uploadComplete$)
            )
            .subscribe((response: HttpEvent<any>) => {
                if (response) {
                    switch (response.type) {
                        case HttpEventType.Sent:
                            thisFile.state = 'uploading';
                            console.log('Request has been made!');
                            this.cdr.markForCheck();
                            break;
                        case HttpEventType.ResponseHeader:
                            console.log('Response header has been received!');
                            break;
                        case HttpEventType.UploadProgress:
                            thisFile.progress = Math.round(
                                (response.loaded / response.total) * 100
                            );
                            console.log(`Uploading! ${thisFile.progress}%`);
                            this.cdr.markForCheck();
                            break;
                        case HttpEventType.Response:
                            thisFile.response = response.body;
                            thisFile.state = 'complete';
                            this.returnedFiles.push(thisFile as FileUploaderReturn);
                            this.messageService.broadcast(FILEUPLOADED, thisFile);
                            this.fileUploaded.emit(response.body);
                            this.cdr.markForCheck();
                            this.inProgress--;
                            this._sendNext();
                            uploadComplete$.next(void 0);
                            uploadComplete$.complete();
                    }
                } else {
                    console.log('response empty', thisFile);
                }
            });
    }

    clearUploads() {
        this.uploads = [];
    }
}
