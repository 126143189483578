<sds-breadcrumb-trail [crumbs]="breadcrumbs"></sds-breadcrumb-trail>

<header class="pageHeader">
    <h1>Dashboard</h1>
</header>

<sds-loader [complete]="loadingComplete">
    <div
        class="ui grid dashboard"
        style="margin-left: 0; margin-right: 0"
        *ngIf="selectedAssessment">
        <div class="nine wide column">
            <div class="ui grid">
                <!--
            <div class="four wide column">
                <sds-risk-score-card [riskScore]="0"></sds-risk-score-card>
            </div>
                -->
                <div class="sixteen wide column" style="padding-left: 0">
                    <div class="ui card assessmentItem">
                        <div class="content" style="flex-basis: 30px; padding-left: 20px">
                            <div class="header">
                                <fa-icon icon="note"></fa-icon>
                                <span *ngIf="isAssessmentStarted">Current Assessment</span>
                                <span *ngIf="!isAssessmentStarted">
                                    {{ selectedAssessment?.Name }}
                                </span>
                                <a href="#" class="settingsIcon"><i class="setting icon"></i></a>
                            </div>
                        </div>
                        <div class="content" style="padding: 0">
                            <table class="assessmentStatus">
                                <tbody>
                                    <tr>
                                        <th style="padding: 4px 20px">Type</th>
                                        <th style="padding: 4px 20px">Status</th>
                                        <th style="padding: 4px 20px"></th>
                                    </tr>
                                    <tr>
                                        <td style="padding: 8px 20px !important">
                                            <div class="assessmentType">
                                                {{ selectedAssessment?.AssessmentType }}
                                            </div>
                                        </td>
                                        <td style="padding: 8px 20px !important">
                                            {{ selectedAssessment?.State }}
                                            {{ selectedAssessment?.StartDate }}
                                        </td>
                                        <td style="padding: 8px 20px !important">
                                            <sds-restart-site
                                                *ngIf="isAssessmentStarted"
                                                [siteId]="site.Id"
                                                [loadingLicense]="false"
                                                [restarted]="false"
                                                style="float: right"></sds-restart-site>
                                            <itc-button
                                                label="Start New"
                                                *ngIf="isAssessmentStartable"
                                                type="primary"
                                                (onclick)="startAssessment()"
                                                style="float: right"
                                                [disabled]="scansRunning"></itc-button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <itc-banner type="info" title="No Assessments Found" *ngIf="isLoaded && !selectedAssessment">
        There are not currently any assessments for this site. You can start the assessment from the
        To Do list, after installing the Compliance Manager Server.
    </itc-banner>
</sds-loader>
