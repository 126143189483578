<sds-loader [complete]="loadingComplete">
    <sds-breadcrumb-trail [crumbs]="breadcrumbs"></sds-breadcrumb-trail>

    <header class="pageHeader">
        <h1>IT Complete</h1>
    </header>

    <itc-card class="integration">
        <card-title>IT Glue Integration</card-title>
        <card-content>
            <form [formGroup]="itgForm" class="ui form settingsPage smallerContainer">
                <div>
                    <itc-toggle [control]="itgForm.get('enabled')">
                        <h4>Enable Link to IT Glue</h4>
                    </itc-toggle>

                    <div class="itgurl field" [class.disabled]="!itgEnabled">
                        <label for="glue-url">IT Glue URL:</label>
                        <div class="ui labeled input">
                            <div class="ui label">https://</div>
                            <input
                                type="text"
                                name="glue-url"
                                formControlName="url"
                                placeholder="subdomain"
                                style="border-top-right-radius: 0; border-bottom-right-radius: 0" />
                            <sm-dropdown
                                [title]="itgForm.get('domain').value"
                                class="label rightLabeledDropdown"
                                (onChange)="itgUpdateDomain($event)">
                                <sm-item *ngFor="let domain of itgDomains">
                                    {{ domain }}
                                </sm-item>
                            </sm-dropdown>
                        </div>
                    </div>
                    <div class="integrationActions">
                        <itc-button
                            icon="fa-floppy-disk"
                            type="primary"
                            [loading]="itgSaving"
                            (onclick)="saveItGluePreferences()"
                            [disabled]="
                                !itgForm.dirty ||
                                (itgEnabled &&
                                    (!itgForm.get('url').value ||
                                        itgForm.get('url').value == '')) ||
                                (!itgEnabled && !itgWASEnabled)
                            "
                            [tooltip]="
                                itgEnabled &&
                                (!itgForm.get('url').value || itgForm.get('url').value == '')
                                    ? 'IT Glue URL is required for save. Please update IT Glue URL field.'
                                    : ''
                            ">
                            Save
                        </itc-button>
                    </div>
                </div>
            </form>
        </card-content>
    </itc-card>

    <itc-card class="integration">
        <card-title>BMS Billing Integration</card-title>
        <card-content>
            <form [formGroup]="bmsForm" class="itc-form col" [class.formSubmitted]="bmsSubmitted">
                <itc-toggle [control]="bmsForm.get('enabled')" label=" ">
                    Enable BMS Contract Updates
                </itc-toggle>

                <a
                    [routerLink]="[]"
                    *ngIf="!bmsHasConnection"
                    (click)="bmsCheckConnection()"
                    style="float: right"
                    [style.display]="!bmsEnabled || bmsCheckingConnection ? 'none' : ''"
                    [class.disabledLink]="
                        !bmsEnabled && !bmsHasConnection && !bmsCheckingConnection
                    ">
                    Check Connection
                </a>

                <div class="bmsError" *ngIf="bmsEnabled && !bmsHasConnection">
                    <sds-loader [complete]="!bmsCheckingConnection">
                        <p>
                            BMS Connection not set up for this site.
                            <br />
                            <a [routerLink]="['/settings/connections']">Click here</a>
                            to set up a connection in Admins Settings > Connections.
                        </p>
                    </sds-loader>
                </div>

                <div class="field" [class.disabled]="!bmsEnabled || !bmsHasConnection">
                    <label for="bmsContract">Contract:</label>
                    <input
                        type="text"
                        id="bmsContract"
                        formControlName="contract"
                        [class.loading]="bmsContractSearching"
                        (blur)="bmsContractFocus = false"
                        (focus)="bmsContractFocus = true"
                        autocomplete="off" />
                    <div
                        *ngIf="bmsContractFocus"
                        class="autocorrectDropdown"
                        (focus)="bmsContractFocus = true">
                        <ul>
                            <!-- using mousedown instead of click so it fires before blur -->
                            <li
                                *ngFor="let contract of bmsAllContractsBySiteFiltered"
                                (mousedown)="bmsSetContractService('contract', contract)">
                                {{ contract.ContractName }}
                            </li>
                        </ul>
                    </div>

                    <label for="bmsService">Service:</label>
                    <input
                        type="text"
                        id="bmsService"
                        formControlName="service"
                        [class.loading]="bmsServiceSearching"
                        (blur)="bmsServiceFocus = false"
                        (focus)="bmsServiceFocus = true"
                        autocomplete="off" />

                    <div
                        *ngIf="bmsServiceFocus"
                        class="autocorrectDropdown"
                        (focus)="bmsServiceFocus = true">
                        <ul>
                            <!-- using mousedown instead of click so it fires before blur -->
                            <li
                                *ngFor="let service of bmsAllServicesByContractFiltered"
                                (mousedown)="bmsSetContractService('service', service)">
                                {{ service.ServiceName }}
                            </li>
                        </ul>
                    </div>

                    <label for="bmsBillBy">Bill By:</label>
                    <div class="grouped fields">
                        <div class="field">
                            <div>
                                <itc-radio
                                    id="billByEndpoints"
                                    [control]="bmsForm.controls.billBy"
                                    value="endpoints"
                                    label="Endpoints (Latest Assessment: {{
                                        bmsEndpoints
                                    }})"></itc-radio>
                            </div>
                        </div>
                        <div class="field">
                            <div>
                                <itc-radio
                                    id="billByEmployees"
                                    [control]="bmsForm.controls.billBy"
                                    value="employees"
                                    label="Current Employees (Latest Assessment: {{
                                        bmsEmployees
                                    }})"></itc-radio>
                            </div>
                        </div>
                        <div class="field">
                            <div>
                                <itc-radio
                                    id="billByUsers"
                                    [control]="bmsForm.controls.billBy"
                                    value="users"
                                    label="Users (Latest Assessment: {{ bmsUsers }})"></itc-radio>
                            </div>
                        </div>
                    </div>
                    <div class="inline fields compact minQty">
                        <label for="bmsMin">Minimum Quantity:</label>
                        <input
                            type="text"
                            id="bmsMin"
                            formControlName="min"
                            style="width: 100px; margin-left: 8px" />
                    </div>

                    <div class="inline fields compact maxQty">
                        <label for="bmsMax">Maximum Quantity:</label>
                        <input
                            type="text"
                            id="bmsMax"
                            formControlName="max"
                            style="width: 100px; margin-left: 8px" />
                        <itc-checkbox
                            id="maxUnlimited"
                            [control]="bmsForm.controls.maxUnlimited"
                            style="margin-left: 10px; margin-right: 5px"></itc-checkbox>
                        <label for="maxUnlimited" style="margin-top: 0 !important">Unlimited</label>
                    </div>

                    Last Sync: {{ bmsLastSyncString ? bmsLastSyncString : 'N/A' }}
                    <span [title]="bmsForm.dirty ? 'Please save your changes before syncing.' : ''">
                        <a
                            [routerLink]="[]"
                            [class.syncing]="bmsSyncing"
                            [class.disabledLink]="bmsForm.dirty"
                            (click)="bmsSync()"
                            style="display: inline-block; margin-left: 15px">
                            <ng-container *ngIf="!bmsSyncing">Sync Now</ng-container>
                            <ng-container *ngIf="bmsSyncing">
                                Syncing
                                <span></span>
                            </ng-container>
                        </a>
                    </span>
                </div>
                <div class="integrationActions">
                    <a
                        [routerLink]="[]"
                        [class.disabledLink]="!bmsForm.dirty"
                        (click)="bmsResetForm()"
                        style="margin-right: 20px">
                        Undo Changes
                    </a>
                    <itc-button
                        label="Save"
                        icon="fa-floppy-disk"
                        type="primary"
                        (onclick)="saveBmsPreferences()"
                        [loading]="bmsSaving"
                        [disabled]="!bmsForm.get('enabled').value && !bmsWASEnabled"
                        [title]=""></itc-button>
                </div>
            </form>
        </card-content>
    </itc-card>

    <itc-card class="integration">
        <card-title>VSA to Compliance Manager Connector Integration</card-title>
        <card-content>
            <form [formGroup]="vsaConnectorForm" class="itc-form col">
                <itc-toggle [control]="vsaConnectorForm.get('enabled')">
                    Enable VSA to Compliance Manager Connector Integration
                </itc-toggle>
                <div class="itgurl field" [class.disabled]="!vsaConnectorEnabled">
                    <label for="glue-url">Connector Id:</label>
                    <div class="ui labeled input">
                        <div class="ui label">{{ vsaConnectorId }}</div>
                        &nbsp;&nbsp;
                    </div>
                </div>
                <div style="margin-top: 16px; flex: 1">
                    <itc-button
                        label="Manage RMM Connector Scans"
                        [disabled]="!vsaConnectorEnabled"
                        type="primary"
                        [loading]="vsaConnectorSaving"
                        (onclick)="manageConnectorScans()"></itc-button>
                    <itc-button
                        label="Copy Connector ID"
                        [disabled]="!vsaConnectorEnabled"
                        type="primary"
                        [loading]="vsaConnectorSaving"
                        (onclick)="copyVsaConnectorToClipboard()"></itc-button>
                </div>
                <div class="integrationActions">
                    <itc-button
                        label="Save"
                        icon="fa-floppy-disk"
                        color="primary"
                        [loading]="vsaConnectorSaving"
                        [disabled]="!vsaConnectorForm.dirty"
                        (onclick)="saveVsaConnector()"></itc-button>
                </div>
            </form>
        </card-content>
    </itc-card>
</sds-loader>

<sm-modal title="Manage RMM Connector Files" class="lg" #connectorModal>
    <modal-content>
        <sds-loader [complete]="connectorModalLoadingComplete">
            <sds-connector-files
                [site]="site"
                [reload]="doReload"
                (selectedConnectorFiles)="selectedConnectorFiles = $event"></sds-connector-files>
        </sds-loader>
    </modal-content>
    <modal-actions>
        <itc-button label="Cancel" icon="fa-xmark" class="cancel" type="secondary"></itc-button>
        <itc-button
            label="Download Selected"
            [disabled]="!selectedConnectorFiles?.length"
            [loading]="loadingZip"
            type="primary"
            icon="fa-download"
            (onclick)="downloadSelectedConnectorFiles()"></itc-button>
        <itc-button
            label="Delete Selected"
            [disabled]="!selectedConnectorFiles?.length"
            [loading]="deletingFiles"
            icon="fa-trash-can"
            type="danger"
            (onclick)="deleteSelectedConnectorFiles()"></itc-button>
    </modal-actions>
</sm-modal>
