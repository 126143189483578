import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SharedModule } from 'app/shared/shared.module';

import { TodoTableComponent } from './todo-table/todo-table.component';
import { TodoFiltersComponent } from './todo-filters/todo-filters.component';
import { TodoTypeBadgeComponent } from './todo-type-badge/todo-type-badge.component';

@NgModule({
    imports: [CommonModule, RouterModule, SharedModule],
    declarations: [TodoTableComponent, TodoFiltersComponent, TodoTypeBadgeComponent],
    exports: [TodoTableComponent, TodoFiltersComponent, TodoTypeBadgeComponent],
})
export class TodoSharedModule {}
