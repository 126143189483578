import { Injectable, inject, isDevMode } from '@angular/core';
import { environment } from 'environments/environment';
import { AuthHttp, AuthService } from './auth';
import { lastValueFrom } from 'rxjs';
import { mapKeys as _mapKeys } from 'lodash-es';
import { ignoreAccounts } from './announcements/announcements.component';
import { UiService } from './ui.service';
import { SDSP_SETTINGS } from 'app/settings/shared/constants';
import { MessageService } from './message.service';

// we return with pascal case, walkme wants camelcase
export interface WalkMeUser {
    UserId?: string;
    userId?: string;
    EmailAddress?: string;
    emailAddress?: string;
    ResourceCreationDaysElapsed?: number;
    resourceCreationDaysElapsed?: number;
    DatabaseCreationDaysElapsed?: number;
    databaseCreationDaysElapsed?: number;
    FirstName?: string;
    firstName?: string;
    LastName?: string;
    lastName?: string;
    CompanyName?: string;
    companyName?: string;
    TenantId?: string;
    tenantId?: string;
    UtcDate?: string;
    utcDate?: string;
    UtcTime?: string;
    utcTime?: string;
    FeatureAccess?: string;
    featureAccess?: string;
    Role?: number;
    role?: number;
    Locale?: string;
    locale?: string;
    ApplicationVersionNumber?: string;
    applicationVersionNumber?: string;
    IsK1User?: boolean;
    isK1User?: boolean;
    SsoEnabled?: boolean;
    ssoEnabled?: boolean;
    Theme?: boolean;
}
type Environment = 'dev' | 'prod';

@Injectable({
    providedIn: 'root',
})
export class WalkMeService {
    uiService = inject(UiService);
    user = inject(AuthService).getIdentity();
    private messageService = inject(MessageService);

    private readonly apiUrl: string;
    isDev = isDevMode();
    isBeta = window.location.hostname.split('.')[1]?.indexOf('-beta') > -1;

    constructor(private http: AuthHttp) {
        this.apiUrl = environment.apiUrl;
    }

    canAddSnippet(): boolean {
        if (this.user.isDemo !== 'true' || ignoreAccounts.includes(this.user.accountId)) {
            return false;
        }
        return true;
    }
    addSnippet(): boolean {
        if (!this.isSetup() && this.canAddSnippet()) {
            let src: string;
            let env: Environment = 'dev';

            if (this.isBeta) {
                // use QA snippet
                src =
                    'https://rapidfiretools.walkme.kaseya.com/qa/walkme_df1d106d0155484ba785cdd0fcb9d3e3_https.js';
                env = 'dev';
            } else if (this.isDev) {
                // use Test snippet
                src =
                    'https://cdn.walkme.com/users/df1d106d0155484ba785cdd0fcb9d3e3/test/walkme_df1d106d0155484ba785cdd0fcb9d3e3_https.js';
                env = 'dev';
            } else {
                // use Prod snippet
                src =
                    'https://rapidfiretools.walkme.kaseya.com/prod/walkme_df1d106d0155484ba785cdd0fcb9d3e3_https.js';
                env = 'prod';
            }
            var walkme = document.createElement('script');
            walkme.type = 'text/javascript';
            walkme.async = true;
            walkme.src = src;
            var s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(walkme, s);
            (window as any)._walkmeConfig = { smartLoad: true };
            (window as any).walkme_event = (eventData: any) => {
                if (eventData.Type === 'wmLauncherClicked') {
                    this.messageService.broadcast('NAV_MENU_CLOSE');
                }
            };
            this.getWalkmeUserVariables(env);
            return true;
        } else {
            return false;
        }
    }

    isSetup(): boolean {
        return typeof (window as any)._walkMe?.getEnvId() === 'number';
    }

    getWalkmeUserVariables(env: Environment): void {
        let url: string = this.apiUrl + 'WalkMe' + '/' + env;
        lastValueFrom(this.http.get(url)).then((res) => {
            this.setWalkmeVariables(res);
        });
    }

    async setWalkmeVariables(userObject: WalkMeUser) {
        userObject.Locale = navigator.languages[0];
        await this.uiService.getVersionUpdated().then((version) => {
            userObject.ApplicationVersionNumber = version.replace('\n', '');
        });
        let sets: any = JSON.parse(localStorage.getItem(SDSP_SETTINGS));
        if (sets) {
            userObject.Theme = sets.Theme === 'light' ? sets.Theme : 'default';
        }
        userObject = _mapKeys(
            userObject,
            (value, key) => key.charAt(0).toLowerCase() + key.slice(1)
        );
        (window as any).walkMeData = userObject;
    }

    openWalkMeDrawer(view: string): void {
        if ((window as any).WalkMePlayerAPI?.isMenuOpen() == false) {
            console.log('opening walk me drawer', 'view', view);
            (window as any).WalkMePlayerAPI.toggleMenu();
        }
    }
}
