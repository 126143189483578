import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

import { AuthService } from './auth.service';
import { JwtHelper } from './auth.jwt';

@Injectable()
export class AuthGuard  {
    constructor(private authService: AuthService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let rval: boolean = false;

        if (this.authService.isAuth() && !JwtHelper.isTokenExpired(this.authService.getToken()))
            rval = true;

        if (rval == false) {
            this.authService.saveTargetUrl(state.url);

            let itcCookie: any = this.getCookie('rft-itc-success');
            if (itcCookie) {
                this.authService.itcLogin(state.url).then((res) => {});
            } else {
                this.router.navigateByUrl('/login');
            }
        }

        if (this.authService.userIsMfaRequired() && !this.authService.userIsMfaEnabled()) {
            this.router.navigateByUrl('/login');
        }

        return rval;
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }

    getCookie(name) {
        var value = '; ' + document.cookie;
        var parts = value.split('; ' + name + '=');
        if (parts.length == 2) return parts.pop().split(';').shift();
    }
}
