import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';
import { AuthHttp } from 'app/core/auth';

@Injectable({ providedIn: 'root' })
export class DattoService {
    private apiUrl: string;

    constructor(private http: AuthHttp) {
        this.apiUrl = environment.apiUrl;
    }

    getDattoCompanies(publickey: string, secretkey: string) {
        const url: string = this.apiUrl + `DattoCompanies`;

        return this.http
            .post(url, { publickey: publickey, secretkey: secretkey })
            .toPromise()
            .then((res) => res);
    }

    getDattoSettings(siteId: number) {
        const url: string = this.apiUrl + `DattoSettings/${siteId}`;

        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    isDattoEnabled(siteId: number) {
        const url: string = this.apiUrl + `IsDattoEnabled/${siteId}`;

        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    importDattoAssets(siteId: number, attachFiles: boolean, backupPercentage: number) {
        const url: string = this.apiUrl + `ImportDattoAssets/${siteId}`;

        return this.http
            .post(url, { attachFiles: attachFiles, backupPercentage: backupPercentage })
            .toPromise()
            .then((res) => res);
    }

    isDattoWorkplaceEnabled(siteId: number) {
        const url: string = this.apiUrl + `IsDattoWorkplaceEnabled/${siteId}`;

        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getDattoWorkplaceSettings(siteId: number) {
        const url: string = this.apiUrl + `DattoWorkplaceSettings/${siteId}`;

        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    verifyDattoWorkplaceCredentials(
        clientid: string,
        secretkey: string,
        endpoint: string,
        siteId: number
    ) {
        const url: string = this.apiUrl + `VerifyDattoWorkplaceCredentials/${siteId}`;

        return this.http
            .post(url, { clientId: clientid, secretKey: secretkey, url: endpoint })
            .toPromise()
            .then((res) => res);
    }

    verifyDattoWorkplaceCredentialsCompFileStor(
        siteId: number,
        clientid: string,
        secretkey: string,
        endpoint: string
    ) {
        const url: string = this.apiUrl + `verifyDattoWorkplaceCredentialsCompFileStor/${siteId}`;

        return this.http
            .post(url, { clientId: clientid, secretKey: secretkey, url: endpoint })
            .toPromise()
            .then((res) => res);
    }

    verifyDattoWorkplaceFilesCompFileStor(siteId: number) {
        const url: string = this.apiUrl + `verifyDattoWorkplaceFilesCompFileStor/${siteId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    removeDattoCompFileStorAssociation(siteId: number) {
        const url: string = this.apiUrl + `removeDattoWorkplaceCompFileStorAssociation/${siteId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    importDattoWorkplaceScans(siteId: number) {
        const url: string = this.apiUrl + `ImportDattoWorkplaceScans/${siteId}`;

        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }
}
