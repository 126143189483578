import { Component, Input, OnInit } from '@angular/core';

const ICONS: Object = {
    error: '',
    info: 'info circle',
    success: 'checkmark',
    warning: 'warning sign',
    positive: 'checkmark',
};

@Component({
    selector: 'sds-message',
    templateUrl: './message.component.html',
    styleUrls: ['./message.component.css'],
})
export class MessageComponent implements OnInit {
    @Input('class') class;

    private _icon: string;
    @Input('icon') set icon(val: string) {
        this._icon = val;
    }
    get icon(): string {
        return this._icon;
    }

    private _type: string;
    @Input('type') set type(val: string) {
        this._type = val;
        if (!this._icon) this._icon = ICONS[this._type];
    }
    get type(): string {
        return this._type;
    }

    constructor() {}

    ngOnInit() {}
}
