<sds-loader [complete]="loadingComplete">
    <sds-breadcrumb-trail [crumbs]="breadcrumbs"></sds-breadcrumb-trail>

    <header class="pageHeader">
        <h1>Controls Library</h1>
        <help-button
            title="Controls Library"
            text="View each of the controls for the 'out-of-box' compliance standards, or create custom controls for your specific purpose."
            url="/cm-grc/help/cm-grc-docs/grc-controls-library.htm"></help-button>
    </header>
    <div>
        <input
            type="text"
            [(ngModel)]="controlsFilterText"
            style="width: 500px; margin-bottom: 0; margin-right: 16px"
            placeholder="Search to find matching controls"
            (keyup)="searchControls()" />
        <itc-checkbox
            class="bulk-responder-checkbox"
            (onChanged)="hideSystemRecordsChanged($event)">
            Hide System Controls
        </itc-checkbox>
        <itc-button icon="fa-plus" type="primary" (onclick)="addControl()" style="float: right">
            New Control
        </itc-button>
    </div>

    <p style="color: gray; font-size: 0.8em; margin-top: 16px; margin-bottom: 8px">
        <fa-icon icon="lock-keyhole"></fa-icon>
        Lock indicates System Standard (read-only)
    </p>

    <div>
        <table
            class="itc-table sortable controltable"
            sortable-table
            sortColumn="ControlId"
            sortDirection="asc"
            (sorted)="sortControls($event)">
            <thead>
                <tr>
                    <th></th>
                    <th sortable-column="ControlId" style="width: 373px">Control ID</th>
                    <th sortable-column="ShortDesc">Control Name</th>
                    <th style="width: 125px"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let ctrl of pagedControls" [class.is-system]="ctrl.IsSystem">
                    <td style="text-align: right; width: 20px">
                        <itc-button
                            *ngIf="ctrl.IsSystem"
                            type="icon"
                            icon="fa-lock-keyhole"
                            (onclick)="startControlEditor(ctrl)"
                            tooltip="View"></itc-button>
                    </td>
                    <td style="width: 373px" height="41">
                        <a class="clickableText" (click)="startControlEditor(ctrl)">
                            {{ ctrl.ControlId }}
                        </a>
                    </td>
                    <td>{{ getSHortDiscription(ctrl) }}</td>

                    <td *ngIf="!ctrl.IsSystem" style="text-align: right; padding-left: 20px">
                        <itc-button
                            type="icon"
                            class="gridicon"
                            icon="fa-pencil"
                            (onclick)="startControlEditor(ctrl)"
                            tooltip="Edit"></itc-button>
                        <itc-button
                            type="icon"
                            class="gridicon"
                            icon="fa-trash-can"
                            (onclick)="startDelete(ctrl)"
                            tooltip="Delete"></itc-button>
                        <itc-button
                            type="icon"
                            icon="fa-clone"
                            (onclick)="startClone(ctrl)"
                            tooltip="Clone"></itc-button>
                    </td>
                    <td *ngIf="ctrl.IsSystem" style="text-align: right; padding-left: 20px">
                        <itc-button
                            type="icon"
                            icon="fa-clone"
                            (onclick)="startClone(ctrl)"
                            tooltip="Clone"></itc-button>
                    </td>
                </tr>
            </tbody>
        </table>
        <itc-paginator [data]="sortedControls" (onPage)="pagedControls = $event"></itc-paginator>
    </div>
</sds-loader>

<sm-modal title="Delete Control {{ selectedControl.ControlId }}" class="xs" #deleteControl>
    <modal-content>
        <p>Delete {{ selectedControl.ControlId }}? This action can't be undone.</p>
    </modal-content>
    <modal-actions>
        <itc-button class="close" type="secondary" (onclick)="closeDelete()">Cancel</itc-button>
        <itc-button
            icon="fa-trash-can"
            type="danger"
            [loading]="isDeleteinProgress"
            (onclick)="deleteSelectedControl()">
            Delete
        </itc-button>
    </modal-actions>
</sm-modal>

<cm-control-editor-modal (controlCloned)="controlCloned($event)"></cm-control-editor-modal>
