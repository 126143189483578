import { ReportTheme, HexColor, DEFAULT_CHART_QUALITY, DEFAULT_CHART_TRANSPARENCY } from './shared';

export class HelveticaEarth implements ReportTheme {
    ThemeName = 'Helvetica Earth';
    // ReportModernizationThemeOriginalName = 'Helvetica Earth';

    DefaultFontName = 'Helvetica';
    DefaultFontSize = 20;
    DefaultTextColor = HexColor(0x40, 0x40, 0x40);

    Header1Size = 32;
    Header2Size = 28;
    Header3Size = 24;
    Header4Size = 22;

    HasBottomBorder = true;
    LineBottomBorder = true;

    DefaultTableFontSize = 22;
    TableHeaderBackgroundColor = HexColor(0x4c, 0x25, 0x00);
    TableHeaderTextColor = HexColor(0xff, 0xff, 0xff);
    TableRowColor = HexColor(0xff, 0xff, 0xff);
    TableAtlRowColor = HexColor(228, 222, 217);
    TableRowTextColor = HexColor(0x40, 0x40, 0x40);

    CriticalRiskColor = HexColor(0x91, 0x24, 0x3e);
    HighRiskColor = HexColor(0xdd, 0x4b, 0x50);
    MediumRiskColor = HexColor(0xf1, 0x8c, 0x43);
    LowRiskColor = HexColor(0xf8, 0xc8, 0x51);

    GoColor = HexColor(0x51, 0xcc, 0xa7);
    StopColor = HexColor(0xaa, 0x00, 0x00);
    DisabledColor = HexColor(0xc0, 0xc0, 0xc0);

    GraphTitleColor = HexColor(0x4c, 0x25, 0x00);
    GraphSubTitleColor = HexColor(0x4c, 0x25, 0x00);
    XColor = HexColor(0x40, 0x40, 0x40);
    YColor = HexColor(0x40, 0x40, 0x40);
    GraphOutlineColor = HexColor(0x4c, 0x25, 0x00);

    UseNewRiskMeter = true;

    ChartColorsPaletteIntList = [
        HexColor(189, 50, 49),
        HexColor(55, 157, 239),
        HexColor(221, 99, 47),
        HexColor(71, 141, 44),
        HexColor(217, 217, 18),

        HexColor(155, 107, 66),
        HexColor(208, 62, 115),
        HexColor(40, 33, 105),
        HexColor(60, 133, 133),
        HexColor(99, 57, 133),

        HexColor(155, 65, 29),
        HexColor(35, 81, 66),
        HexColor(120, 121, 171),
        HexColor(236, 117, 121),
        HexColor(82, 22, 65),

        HexColor(139, 198, 62),
        HexColor(33, 57, 140),
        HexColor(245, 179, 51),
        HexColor(55, 15, 42),
        HexColor(83, 110, 133),
    ];

    ChartTopFivePaletteIntList = [
        HexColor(155, 65, 29),
        HexColor(35, 81, 66),
        HexColor(120, 121, 171),
        HexColor(236, 117, 121),
        HexColor(82, 22, 65),
        HexColor(245, 178, 51),
    ];

    ChartTwoColorPaletteIntList = [HexColor(155, 65, 29), HexColor(35, 81, 66)];

    ChartQuality = DEFAULT_CHART_QUALITY;
    ChartTransparencyPercent = DEFAULT_CHART_TRANSPARENCY;
}
