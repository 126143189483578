<sds-loader [complete]="!site.siteLoading">
    <div class="bottom" style="border-top: 1px solid #e8e8e8" *ngIf="site.apps.length">
        <ul>
            <li class="appSectionTitle">Organization</li>
            <li [title]="site.site.Organization" *ngIf="site.site.Organization">
                {{ site.site.Organization }}
            </li>
            <li *ngIf="!site.site.Organization">Unassigned</li>
        </ul>
        <ul>
            <li class="appSectionTitle">
                Appliance
                <ng-container *ngIf="site.apps.length > 1">s</ng-container>
            </li>
        </ul>

        <div
            class="tabs"
            *ngIf="site.apps && site.apps.length > 1"
            [class.paged]="site.apps.length > 2">
            <div class="tabs_container">
                <p
                    class="appTab"
                    *ngFor="let app of site.apps; let i = index"
                    (click)="openApp = i"
                    [class.backgroundTab]="openApp != i"
                    [style.display]="i < tabPage ? 'none' : 'block'">
                    <i *ngIf="app.Type == 'Reporter'" class="icon-indoc"></i>
                    <i *ngIf="app.Type == 'Vulnerability Scanner'" class="icon-kvs"></i>
                    <fa-icon
                        *ngIf="app.Type != 'Reporter' && app.Type != 'Vulnerability Scanner'"
                        icon="download"></fa-icon>
                </p>
                <p
                    class="appTab dummyTab"
                    style="
                        color: transparent;
                        background-color: #dadada;
                        border: 1px solid #dadada;
                    ">
                    Dummy Tab
                </p>
            </div>
            <div class="paging-buttons" *ngIf="site.apps.length > 2">
                <button (click)="tab_page('down')" [disabled]="tabPage == 0">&laquo;</button>
                <button (click)="tab_page('up')" [disabled]="tabPage >= site.apps.length - 1">
                    &raquo;
                </button>
            </div>
        </div>

        <div
            *ngFor="let app of site.apps; let i = index"
            [class.openTab]="openApp == i"
            [class.closedTab]="openApp != i">
            <ul>
                <!-- <li class="appSectionTitle">Appliance</li> -->
                <li>Appliance Name: {{ app.Id }}</li>
                <li>
                    Appliance status:
                    <span *ngIf="app.Status == 'Online'" class="color-online">Online</span>
                    <span *ngIf="app.Status == 'Offline'" class="color-offline">Offline</span>
                </li>
                <li>
                    Host Type: {{ app?.IsServer ? 'Server' : app.IsVirtual ? 'Virtual' : 'Server' }}
                </li>
                <li>Last Check-in: {{ app.LastCheckin }}</li>
                <li>Update Status: {{ app.UpdateStatus }}</li>
                <li>
                    Scan Tasks:
                    <div *ngIf="app?.Scans && isEmpty(app?.Scans)" style="display: inline-block">
                        No queued tasks.
                    </div>
                    <div *ngFor="let scan of app?.Scans">
                        <div>
                            {{ scan.name }} ({{
                                scan.progress == -2
                                    ? 'Queued for ' + scan.queuedSpan
                                    : 'Running for ' + scan.runningSpan
                            }})
                        </div>
                        <div
                            class="ui orange active progress"
                            *ngIf="scan.progress > -1"
                            style="width: 50%; margin-bottom: 10px">
                            <div class="bar" [ngStyle]="{ width: scan.progress + '%' }">
                                <div class="progress">{{ scan.progress + '%' }}</div>
                            </div>
                        </div>
                    </div>
                </li>

                <!-- Compliance Manager -->
                <li
                    *ngIf="
                        (app.Type == 'GDPR' ||
                            app.Type == 'HIPAA' ||
                            app.Type == 'CYBERINSURANCE') &&
                        site.assessment
                    "
                    class="appSectionTitle">
                    Assessment
                </li>
                <li
                    *ngIf="
                        (app.Type == 'GDPR' ||
                            app.Type == 'HIPAA' ||
                            app.Type == 'CYBERINSURANCE') &&
                        site.assessment
                    ">
                    Assessment Type: {{ app.Type }}
                </li>
                <li
                    *ngIf="
                        (app.Type == 'GDPR' ||
                            app.Type == 'HIPAA' ||
                            app.Type == 'CYBERINSURANCE') &&
                        site.assessment
                    ">
                    Assessment Status: {{ site.assessment?.Status }}
                </li>
                <li
                    *ngIf="
                        app.Type == 'GDPR' || app.Type == 'HIPAA' || app.Type == 'CYBERINSURANCE'
                    "
                    class="appSectionTitle">
                    Advanced
                </li>
                <li
                    *ngIf="
                        app.Type == 'GDPR' || app.Type == 'HIPAA' || app.Type == 'CYBERINSURANCE'
                    ">
                    Add-on: Cysurance Coverage:
                    {{ site.addons.cyinsurance ? 'Enabled' : 'Not Enabled' }}
                </li>
                <!-- InDoc -->
                <li *ngIf="site.site.IsIndoc && app.Type == 'Reporter'" class="appSectionTitle">
                    Management Plan
                </li>
                <li *ngIf="site.site.IsIndoc && app.Type == 'Reporter'">
                    Network: {{ site.managementPlans.NetworkManagementPlan || 0 }} Issues
                </li>
                <li *ngIf="site.site.IsIndoc && app.Type == 'Reporter'">
                    Security: {{ site.managementPlans.SecurityManagementPlan || 0 }} Issues
                </li>
                <!-- Cyber Hawk -->
                <li *ngIf="app.Type == 'standard'" class="appSectionTitle">Advanced</li>
                <li *ngIf="app.Type == 'standard'">
                    Add-on: ABDS: {{ site.addons.abds ? 'Enabled' : 'Not Enabled' }}
                </li>
            </ul>
        </div>
    </div>
</sds-loader>
