import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Alert, ALERT_STATUSES } from 'app/alerts';
import { NavService } from 'app/core/nav/nav.service';
import { TableSorting } from 'app/shared/sortable-table/sortable-table.component';

@Component({
    selector: 'sds-todo-table',
    templateUrl: './todo-table.component.html',
    styleUrls: ['./todo-table.component.css'],
})
export class TodoTableComponent implements OnInit {
    constructor(
        private navService: NavService,
        private route: ActivatedRoute,
        private router: Router
    ) {}

    @Input() todos: Alert[];
    @Input() forSite: boolean = false;
    @Input() linksRoot: string = '';
    @Input() parentRelative: boolean = false;
    @Output() sortBy = new EventEmitter<string | number>();
    @Input() invertDefault = false;

    sortMethod: string | number;

    ngOnInit() {
        this.navService.saveReturnState('todo');
        this.sortMethod = (this.invertDefault ? '-' : '') + 'Created';
    }

    detail(guid: string) {
        if (this.parentRelative) {
            this.router.navigate(['..', guid], { relativeTo: this.route });
        } else this.router.navigate(['..', 'todo', guid], { relativeTo: this.route });
    }

    getIconClass(alert: Alert) {
        let action = alert.ActionType;

        if (action == 'investigate') return 'search';

        if (alert.ActionType.indexOf('ignore') > -1) return 'ban';

        return 'circle thin';
    }

    isCompleted(alert: Alert): boolean {
        return alert.Status === ALERT_STATUSES.COMPLETED.value ||
            alert.Status === ALERT_STATUSES.TASK_COMPLETE.value
            ? true
            : false;
    }

    onSorted(sorting: TableSorting) {
        let sortMethod = sorting.sortColumn;

        if (sorting.sortDirection == 'desc') sortMethod = '-' + sortMethod;

        this.sortMethod = sortMethod;
        this.sortBy.emit(this.sortMethod);
    }
}
