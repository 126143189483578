import { Component, Input, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

import { Toast, ToastType } from './toast.model';
import { ANIMATE_DURATION } from './toast.config';

const toastClasses: Map<ToastType, string> = new Map<ToastType, string>([
    [ToastType.Error, 'error'],
    [ToastType.Info, 'info'],
    [ToastType.Success, 'success'],
    [ToastType.Warning, 'warning'],
    [ToastType.Download, 'download'],
]);

const iconClasses: Map<ToastType, string> = new Map<ToastType, string>([
    [ToastType.Error, 'minus circle'],
    [ToastType.Info, 'info circle'],
    [ToastType.Success, 'checkmark'],
    [ToastType.Warning, 'warning sign'],
    [ToastType.Download, 'download'],
]);

@Component({
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.css'],
    animations: [
        trigger('toastState', [
            state('visible', style({ opacity: 1 })),
            state('hidden', style({ opacity: 0 })),
            transition('void => visible', animate(ANIMATE_DURATION)),
            transition('visible => hidden', animate(ANIMATE_DURATION)),
        ]),
    ],
})
export class ToastComponent implements OnInit {
    constructor(toast: Toast) {
        this.toastType = toast.type;
        this.title = toast.title;
        this.message = toast.message;

        this.toastClass = toastClasses.get(this.toastType);
        this.iconClass = iconClasses.get(this.toastType);
    }

    toastType: ToastType;
    state: string;

    toastClass: string;
    iconClass: string;
    title: string;
    message: string;

    ngOnInit() {
        this.state = 'visible';
    }

    hide() {
        this.state = 'hidden';
    }
}
