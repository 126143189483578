import {
    AfterViewInit,
    Component,
    Input,
    OnInit,
    OnChanges,
    SimpleChanges,
    ViewChild,
} from '@angular/core';

import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { PatchOp, ScanDataService } from 'app/scans/shared/scan-data.service';
import { NdpService } from '../../../sites/shared/ndp.service';

@Component({
    selector: 'sds-tags-card',
    templateUrl: './tags-card.component.html',
    styleUrls: ['./tags-card.component.css'],
})
export class TagsCardComponent implements OnInit {
    constructor(
        private scanDataService: ScanDataService,
        private ndpService: NdpService,
        private router: Router
    ) { }

    @Input() set tags(is: any) {
        this._tags = is;

        if (this._tags) {
            this._tags = this.sortItems(this._tags);
            this.selectedTags = this._tags.map((tag) => {
                return tag.TagType;
            });
            this.selectValue = this.selectedTags;
        }
    }
    private _siteId: number;
    @Input() set siteId(value: number) {
        if (!value) return;
        this._siteId = value;
        this.getNDPAssessmentId();
    }
    @Input() parentId: string;
    @ViewChild('addTagModal') addModal: any;
    @ViewChild('saveButton') saveButton: any;
    @ViewChild('smartTagSelect') smartTagSelect: any;

    _tags: any;
    availableTags: any[] = [];
    selectedTags: any;
    uid: any;
    type: string;

    updatingTags: boolean;
    editMode: boolean;
    selectValue: string[] = [];

    loadingComplete: boolean;

    assessmentId: number = -1;
    dataExplorerType = 'Indoc';

    ngOnInit() {
        this.dataExplorerType = this.ndpService.getDataExplorerType();
    }

    toggleEditMode() {
        this.editMode = !this.editMode;
    }

    cancelEdits() {
        this.editMode = false;
        this.setSelectValues();
    }

    setSelectValues() {
        if (this.smartTagSelect) this.smartTagSelect.reset();
        this.selectedTags.forEach((tag) => {
            this.smartTagSelect.setMultiSelected(tag);
        });
    }

    selectChange(value: string[]) {
        this.selectValue = value;
    }

    async getNDPAssessmentId() {
        this.assessmentId = await this.ndpService.getAssessmentIdForDataExplorerAndSiteId(this._siteId, this.dataExplorerType);
    }

    ngOnChanges(changes: SimpleChanges) {
        for (let propName in changes) {
            let chng = changes[propName];

            if (propName == 'parentId' && !this.parentId) this.parentId = chng.currentValue;
            else if (propName == 'siteId' && !this.siteId) {
                this._siteId = chng.currentValue;
                this.getNDPAssessmentId();
            }
            else if (propName == 'tags' && !this._tags) {
                this._tags = chng.currentValue;

                if (this._tags) this._tags = this.sortItems(this._tags);
            }
        }

        if (this.parentId && this._siteId) {
            let uid = JSON.parse(window.atob(this.parentId));

            if (uid && uid.User) {
                this.type = 'user';
            }
            if (uid && uid.Computer) {
                this.type = 'computer';
            }

            // initialize selectTags checkboxes
            this.scanDataService
                .getAvailableSmartTagsForItemType(this._siteId, this.type)
                .then((items: any[]) => {
                    this.availableTags = items;

                    if (this._tags) {
                        for (let tag of this._tags) {
                            if (this.availableTags) {
                                for (var i = this.availableTags.length - 1; i >= 0; i--) {
                                    if (tag.TagType == this.availableTags[i].TagType) {
                                        this.availableTags.splice(i, 1);
                                        break;
                                    }
                                }
                            }
                        }
                        setTimeout(() => {
                            this.setSelectValues();
                        }, 1);
                        this.loadingComplete = true;
                    } else this.loadingComplete = true;
                });
        }
    }

    sortItems(items: any[]): any[] {
        return items.sort((a, b) => {
            let cola = a['Name'],
                colb = b['Name'];

            if (typeof cola === 'string') cola = cola.toLocaleLowerCase();
            if (typeof colb === 'string') colb = colb.toLocaleLowerCase();

            return cola > colb ? 1 : cola === colb ? 0 : -1;
        });
    }

    saveTags() {
        this.editMode = true;

        // persist to the database
        let pop: PatchOp = {
            op: 'replace',
            path: '/SmartTags',
            value: (this.selectValue || []).join(','), // join to a comma-separated list of ids
        };

        this.updatingTags = true;
        this.scanDataService
            .patchItem(this._siteId, this.type, this.parentId, [pop], this.assessmentId)
            .then((res) => {
                this.selectedTags = this.sortItems(this.selectValue);
                this.updatingTags = false;
                this.editMode = false;
            })
            .catch((err) => {
                this.selectedTags = this.sortItems(this.selectValue);
                this.updatingTags = false;
                this.editMode = false;
            });
        // this.editMode = false;
        // this._tags = this.sortItems(this._tags);
    }
}
