import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
    HttpErrorResponse,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { environment } from 'environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(public auth: AuthService, public router: Router) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // skip interceptor for vendor portal requests
        try {
            if (request.url.startsWith(environment.apiUrl + 'vendportal'))
                return next.handle(request);
        } catch {}

        let token = this.auth.getToken();

        if (token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.auth.getToken()}`,
                },
            });
        }

        return next.handle(request).pipe(
            tap(
                (event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                        if (event.headers.has('X-New-Token'))
                            this.auth.setToken(event.headers.get('X-New-Token'));
                    }
                },
                (err: any) => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status === 401) this.router.navigateByUrl('/login');
                    }
                }
            )
        );
    }
}
