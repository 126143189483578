import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UiService } from 'app/core/ui.service';
import { AuthService, Roles } from 'app/core/auth';
import { ControlsService } from 'app/sites/shared/controls/controls.service';
import { TableSorting } from 'app/shared/sortable-table/sortable-table.component';
import { ControlTemplate } from 'app/sites/shared/controls/controltemplate.model';
import { Control } from 'app/sites/shared/controls/control.model';
import { SiteService } from 'app/sites/shared/site.service';
import { NotificationService } from '../../../shared/itc/notification/notification.service';

enum templateChange {
    add = 0,
    edit = 2,
}

class controlSelect {
    select: boolean;
    control: Control;
}

@Component({
    selector: 'control-templates',
    templateUrl: './control-templates.component.html',
    styleUrls: ['./control-templates.component.css'],
})
export class ControlTemplatesComponent implements OnInit, AfterViewInit {
    initPage: any;

    constructor(
        private uiService: UiService,
        private authService: AuthService,
        private controlService: ControlsService,
        private notificationService: NotificationService,
        private siteService: SiteService
    ) {}

    loadingComplete: boolean = false;

    breadcrumbs = [
        { path: '../..', text: 'Admin' },
        { path: '..', text: 'Compliance Manager GRC' },
        { path: '.', text: 'Control Templates' },
    ];

    @ViewChild('confirmDeleteTemplateModal', { static: true }) confirmDeleteTemplateModal: any;
    @ViewChild('templateEditModal', { static: true }) templateEditModal: any;
    @ViewChild('cloneControlTemplateModal', { static: true }) cloneControlTemplateModal: any;

    hideSystemTemplates: boolean = false;

    // When the user clicks on the System control templates, to view the details on the viewMode.
    isViewMode: boolean = false;

    templates: ControlTemplate[] = [];
    templatesFiltered: ControlTemplate[] = [];

    templateActive: ControlTemplate = new ControlTemplate();

    templateSearch: string = '';
    templateSortColumn: string = 'Name';
    templateSortDirection: string = 'desc';

    templateEditOption: templateChange = templateChange.add;
    templateEditTitle: string = '';
    templateEditName: string = '';

    pageSize: number = 20;

    templatePage: ControlTemplate[] = [];
    templatePageNumber: number = 0;

    controls: Control[] = [];
    controlsFiltered: Control[] = [];

    controlActive: Control = new Control();

    controlsAddSearch: string = '';

    controlsSortColumn: string = 'Description';
    controlsSortDirection: string = 'desc';

    controlPage: controlSelect[] = [];
    controlPageNumber: number;

    controlTemplateNames: string[] = [];
    clonedControlTemplateName: string;
    /**
     * Used for pagination in the add control template Modal.
     */
    addControlTemplatePaginatorSettings: {
        pageSize: number;
        pageNumber: number;
        lastPage: number;
    } = {
        pageSize: 20,
        pageNumber: 0,
        lastPage: 0,
    };

    /**
     * Used for pagination in the add controls Modal.
     */
    searchControlPaginatorSettings: {
        pageSize: number;
        pageNumber: number;
        lastPage: number;
    } = {
        pageSize: 20,
        pageNumber: 0,
        lastPage: 0,
    };

    /**
     * Used to handle the Modal View.
     */
    viewSettings: {
        isFirstPopUp: boolean;
        isEditMode: boolean;
        editControlName: boolean;
        showAreYouSureDialogue: boolean;
    } = {
        isFirstPopUp: false,
        isEditMode: false,
        editControlName: false,
        showAreYouSureDialogue: false,
    };

    /**
     * Used to validate if the scelected list is changed. (To show a waring message when click on cancel without saving the changes.)
     */
    selectedControlsListCountOnEditInIt: number = 0;

    /**
     * Used to set the template header name upon moving through different Modals.
     */
    tempControlName: string = '';

    addControlFilteredList: controlSelect[] = [];
    searchControlFilteredList: controlSelect[] = [];
    sizes: number[] = [10, 20, 30, 50];

    controlsAll: Control[] = [];

    controlsAdd: boolean = false;

    allControlsAvailableChecked: boolean;
    controlsAvailableChecked: boolean[] = [];

    previousName: string = '';

    controlsSelected: controlSelect[] = [];
    controlsAvailable: controlSelect[] = [];
    controlsGrid: controlSelect[] = [];

    allControlsAvailable: controlSelect[] = [];

    culture: string = 'en-US';

    user: any;
    //allowed roles, root,master, admin;
    isMaster: boolean;
    isAdmin: boolean;
    isROOT: boolean;
    cloning: boolean;

    ngOnInit() {
        this.user = this.authService.getIdentity();
        this.isMaster = this.authService.userIsRole(Roles.Master);
        this.isAdmin = this.authService.userIsRole(Roles.Admin);
        this.isROOT = false;
        console.log('master ' + this.isMaster + ' admin ' + this.isAdmin);
        this.uiService.setTitle('Controls Template');

        this.getTemplates();
        this.getControls();
    }

    ngAfterViewInit() {
        setTimeout(this.delayPageNumber, 300, this.templatePageNumber, 1);
    }

    canModifyTemplates(template: ControlTemplate) {
        if (template && this.isROOT && this.templateIsSystem(template)) return true;

        return this.isAdmin || this.isMaster || this.isROOT;
    }

    templateIsSystem(template: ControlTemplate) {
        return template.IsSystem;
    }

    showTemplateEditModal(templateIn: ControlTemplate, option: templateChange) {
        if (templateIn && templateIn.IsSystem) {
            this.isViewMode = true;
        }

        this.allControlsAvailableChecked = false;

        this.allControlsAvailable = [];

        this.templateEditOption = option;
        this.controlsAdd = false;

        this.addControlTemplatePaginatorSettings.pageSize = 20;
        this.addControlTemplatePaginatorSettings.pageNumber = 0;
        this.viewSettings.showAreYouSureDialogue = false;

        this.viewSettings.editControlName = false;

        this.templateEditName = '';

        this.templateEditTitle =
            this.templateEditOption == templateChange.add
                ? 'Add Control Template'
                : 'Edit Control Template';
        if (this.isViewMode) this.templateEditTitle = 'View Control Template';
        this.viewSettings.isFirstPopUp = true;
        switch (option) {
            case templateChange.add:
                this.controlsAdd = true;

                this.viewSettings.isEditMode = false;

                this.templateActive = new ControlTemplate();
                this.controlsAvailable = [];
                this.controlsAll.filter((c) => {
                    this.allControlsAvailable.push({ select: false, control: c });
                });
                this.controlsAvailable = this.allControlsAvailable.slice();

                this.controlsSelected = [];
                break;
            case templateChange.edit:
                this.controlsAdd = false;
                this.viewSettings.isEditMode = true;

                this.templateActive = templateIn;
                this.templateEditName = this.templateActive.Name;
                this.tempControlName = this.templateEditName;
                this.setControls(this.templateActive);

                let IdArray: any = this.controls.filter((c) => true).map((i) => i.Id);

                this.controlsSelected = [];
                this.controlsAvailable = [];

                this.controlsAll.filter((cb) => {
                    if (IdArray.indexOf(cb.Id) != -1) {
                        this.controlsSelected.push({ select: false, control: cb });
                    } else {
                        this.allControlsAvailable.push({ select: false, control: cb });
                        this.controlsAvailable = this.allControlsAvailable.slice();
                    }
                });
                this.selectedControlsListCountOnEditInIt = this.controlsSelected.length;
                this.previousName = this.templateEditName;
                break;
        }

        setTimeout(this.delayPageNumber, 300, this.controlPageNumber, 1);

        this.controlPages();
        this.pageControlsAddControlTemplate();
        this.templateEditModal.show({ closable: false });
    }

    pageControlsAddControlTemplate(ev?: any) {
        //this.addControlFilteredList = this.controlsSelected;
        if (ev) {
            this.addControlTemplatePaginatorSettings.pageNumber = ev.pageNumber;
            this.addControlTemplatePaginatorSettings.lastPage = ev.lastPage;
            this.addControlTemplatePaginatorSettings.pageSize = ev.pageSize;
            var numberOfRecordsDone = ev.pageNumber * ev.pageSize;

            this.addControlFilteredList = this.controlsSelected.slice(
                numberOfRecordsDone,
                numberOfRecordsDone + ev.pageSize
            );
        } else this.addControlFilteredList = this.controlsSelected;
    }

    searchControlOnPage(ev?: any) {
        if (ev) {
            if (ev.lastPage > 0) {
                if (this.searchControlPaginatorSettings.lastPage != ev.lastPage) {
                    this.controlsAvailableChecked = new Array(ev.lastPage).fill(false);
                }
                this.allControlsAvailableChecked = this.controlsAvailableChecked[ev.pageNumber];
            }

            this.searchControlPaginatorSettings.pageNumber = ev.pageNumber;
            this.searchControlPaginatorSettings.lastPage = ev.lastPage;
            this.searchControlPaginatorSettings.pageSize = ev.pageSize;
            let numberOfRecordsDone = ev.pageNumber * ev.pageSize;

            this.searchControlFilteredList = this.controlsAvailable.slice(
                numberOfRecordsDone,
                numberOfRecordsDone + ev.pageSize
            );
            this.checkChildren();
        } else {
            this.searchControlFilteredList = this.controlsAvailable;
        }
    }

    checkChildren() {
        let allcheck = false;
        for (let index = 0; index < this.searchControlFilteredList.length; index++) {
            if (!this.searchControlFilteredList[index].select) {
                allcheck = true;
                break;
            }
        }
        if (!allcheck) this.allControlsAvailableChecked = true;
    }

    /**
     * This method is called to take back to the Add control Template when don't want to discard the changes.
     */
    clickedNo() {
        this.viewSettings.showAreYouSureDialogue = false;
    }

    /**
     * This method is used to check if the data is edited, called upon canceling the Modal.
     */
    checkIfDataEditedInFirstPopUp() {
        var returnValue =
            this.selectedControlsListCountOnEditInIt != this.controlsSelected.length ||
            this.previousName != this.templateEditName;

        if (returnValue == undefined) return false;
        else return returnValue;
    }

    /**
     * This method is called upon cancel.
     */
    closeTemplateModal() {
        if (this.viewSettings.isEditMode) {
            if (this.checkIfDataEditedInFirstPopUp()) {
                this.viewSettings.showAreYouSureDialogue = true;
            } else {
                this.templateEditModal.hide();
                setTimeout(() => {
                    this.isViewMode = false;
                }, 500);
            }
        } else {
            if (this.templateEditName != '' || this.controlsSelected.length != 0)
                this.viewSettings.showAreYouSureDialogue = true;
            else {
                this.templateEditName = '';
                this.controlsSelected = [];
                this.templateEditModal.hide();
            }
        }
    }

    /**
     * This method is used to discard the made changes when clicked upon cancel.
     */
    clickedYes() {
        if (this.viewSettings.isFirstPopUp) this.templateEditModal.hide();
        else {
            this.allControlsAvailable = this.allControlsAvailable.map((c) => {
                if (c.select) {
                    c.select = false;
                    return c;
                } else return c;
            });
            this.viewSettings.showAreYouSureDialogue = false;
            this.viewSettings.isFirstPopUp = true;
        }
    }

    controlsCheckedAll() {
        this.controlsAvailableChecked[this.searchControlPaginatorSettings.pageNumber] =
            !this.controlsAvailableChecked[this.searchControlPaginatorSettings.pageNumber];
        this.searchControlFilteredList.forEach(
            (c) =>
                (c.select =
                    this.controlsAvailableChecked[this.searchControlPaginatorSettings.pageNumber])
        );
        this.allControlsAvailableChecked =
            this.controlsAvailableChecked[this.searchControlPaginatorSettings.pageNumber];
    }

    controlChecked(index: number) {
        if (this.controlsAdd) {
            var ind = -1;
            ind = this.allControlsAvailable.findIndex((r) => {
                return r == this.searchControlFilteredList[index];
            });
            if (ind > -1)
                this.allControlsAvailable[ind].select = !this.allControlsAvailable[ind].select;

            this.checkAllMark();
            this.controlsAddSearchChanged();
        }
    }

    checkAllMark() {
        for (let index = 0; index < this.searchControlFilteredList.length; index++) {
            if (this.searchControlFilteredList[index].select === false) {
                this.allControlsAvailableChecked = false;
                this.controlsAvailableChecked[this.searchControlPaginatorSettings.pageNumber] =
                    this.allControlsAvailableChecked;
                break;
            }

            if (
                this.searchControlFilteredList.length - 1 === index &&
                !this.allControlsAvailableChecked
            ) {
                this.allControlsAvailableChecked = true;
                this.controlsAvailableChecked[this.searchControlPaginatorSettings.pageNumber] =
                    this.allControlsAvailableChecked;
            }
        }
    }

    controlSelectedRemove(row: controlSelect) {
        this.allControlsAvailable.push(row);
        this.controlsAvailable.push(row);
        this.controlsSelected = this.controlsSelected.filter((c) => c.control.Id != row.control.Id);
    }

    controlsAddShow() {
        this.templateEditTitle = 'Add Controls';
        this.controlsAddSearch = '';
        this.viewSettings.isFirstPopUp = false;
        this.allControlsAvailableChecked = false;
        this.controlsAvailableChecked.fill(false);
        this.controlsAdd = true;
        this.onControlSorted();
        this.controlPageNumber = 1;
        this.controlPages();
    }

    getControlLongDescriptionTooltip(control: Control): string {
        if (this.controlsAddSearch === '') {
            return '';
        }

        let longDescription = control.LongDesc.LocalizedText[this.culture];
        let indexOfSearch = longDescription.indexOf(this.controlsAddSearch);

        if (indexOfSearch < 0) {
            return '';
        }

        let threeWordsAvgLength = 21;
        let searchLength = this.controlsAddSearch.length;
        let substringStart = indexOfSearch - threeWordsAvgLength;
        let substringEnd = indexOfSearch + searchLength + threeWordsAvgLength;
        return `...${longDescription.substring(substringStart, substringEnd)}...`;
    }

    controlsAddCancel() {
        this.allControlsAvailable.forEach((c) => {
            if (c.select) {
                c.select = false;
            }
        });
        this.controlsAvailable = this.allControlsAvailable.slice();
        this.templateEditTitle =
            this.templateEditOption == templateChange.add
                ? 'Add Control Template'
                : 'Edit Control Template';
        this.viewSettings.isFirstPopUp = true;
        this.controlsAdd = false;
        setTimeout(this.delayPageNumber, 300, this.controlPageNumber, 1);
        this.controlPages();
    }

    isSaveDisabled(): boolean {
        return (
            this.controlsSelected.length == 0 ||
            this.templateEditName.length == 0 ||
            this.templateNameExists(this.templateEditName.trim())
        );
    }

    templateNameExists(name: string): boolean {
        return (
            this.templates &&
            this.templates
                .filter((t) => t && this.templateActive && t.Id != this.templateActive.Id)
                .some((t) => t.Name === name)
        );
    }

    isAddDisabled() {
        return this.allControlsAvailable.filter((c) => c.select).length == 0;
    }

    controlsAddSave() {
        let count = 0;

        //Problem is that this allControlsAvailable should not be filtered

        this.allControlsAvailable.forEach((c) => {
            if (c.select) {
                c.select = false;
                this.controlsSelected.push(c);
                count++;
            } else {
                return c;
            }
        });

        this.controlsAvailable = this.allControlsAvailable.slice();

        this.controlsAdd = false;
        this.onControlSorted();
        this.viewSettings.isFirstPopUp = true;
        this.templateEditTitle =
            this.templateEditOption == templateChange.add
                ? 'Add Control Template'
                : 'Edit Control Template';
        this.notificationService.toast.success('Success ', count + ' controls successfully added');
    }

    templateSave() {
        this.controls = [];
        this.controlsSelected.filter((c) => {
            this.controls.push(c.control);
        });

        this.controlsFiltered = [];
        if (this.viewSettings.isEditMode) this.templateActive.Name = this.tempControlName;
        else this.templateActive.Name = this.templateEditName;
        this.templateActive.ControlIds = [];
        this.setTemplateControlIds(this.templateActive);
        this.controlsFiltered = [];
        this.controlsFiltered.push(...this.controls);

        if (this.templateEditOption == templateChange.edit) {
            this.controlService.updateControlTemplate(this.templateActive).then((template) => {
                this.templates = this.templates.filter((t) => t.Id != template.Id);
                this.templates.push(template);
                this.doTemplateSearch();
            });
        } else {
            this.templateActive.AccountId = this.user.accountId;
            this.controlService.addControlTemplate(this.templateActive).then((template) => {
                this.templates.push(template);
                this.doTemplateSearch();
            });
        }

        this.controlsSelected = [];
        this.templateEditName = '';
        this.templateEditModal.hide();
    }

    confirmDeleteTemplate(template: any) {
        this.templateActive = template;
        this.confirmDeleteTemplateModal.show({ closable: false });
    }

    templateDelete() {
        this.confirmDeleteTemplateModal.hide();

        this.controlService.deleteControlTemplate(this.templateActive.Id).then((ret) => {
            this.getTemplates();
            this.templateActive = null;
            this.controlsFiltered = [];
        });
    }

    templateSearchChanged() {
        this.doTemplateSearch();
    }

    doTemplateSearch() {
        this.templatesFiltered = [];

        let checkSearch: boolean = this.templateSearch && this.templateSearch.length > 0;
        let lowSearch: string = this.templateSearch.toLowerCase();

        for (let template of this.templates) {
            let searchOk: boolean = true;
            let systemOK: boolean = true;

            if (
                checkSearch &&
                template.Name.toLowerCase().indexOf(lowSearch) == -1 &&
                template.Id.toString() != lowSearch
            ) {
                searchOk = false;
            }

            if (this.templateIsSystem(template) && this.hideSystemTemplates == true) {
                systemOK = false;
            }

            if (searchOk && systemOK) {
                this.templatesFiltered.push(template);
            }
        }

        this.templatesSort();

        this.templateActive = null;
        this.controlsFiltered = [];
    }

    templatesSort() {
        if (!this.templatesFiltered) return;

        this.templatesFiltered = this.templatesFiltered.sort((a, b) => {
            let cola = a[this.templateSortColumn],
                colb = b[this.templateSortColumn];

            if (typeof cola === 'string') cola = cola.toLocaleLowerCase();
            if (typeof colb === 'string') colb = colb.toLocaleLowerCase();

            if (this.templateSortDirection === 'desc') {
                return cola < colb ? 1 : cola === colb ? 0 : -1;
            } else {
                return cola > colb ? 1 : cola === colb ? 0 : -1;
            }
        });

        setTimeout(this.delayPageNumber, 300, this.templatePageNumber, 1);

        this.templatePages();
    }

    templatePages(ev?: any) {
        if (ev) {
            if (ev.pageNumber != this.templatePageNumber) this.templatePageNumber = ev.pageNumber;

            if (ev.pageSize != this.pageSize && !this.initPage) {
                this.pageSize = ev.pageSize;
                this.templatePageNumber = 0;
            }

            this.initPage = false;
        }

        this.templatePage = this.templatesFiltered.slice(
            this.templatePageNumber * this.pageSize,
            this.templatePageNumber * this.pageSize + this.pageSize
        );
    }

    /**
     * This method is used for sorting the table.
     * @param sorting - hold information about sorting asc or desc.
     */
    sortControlsTemplate(sorting?: TableSorting) {
        if (!sorting) {
            this.templatesFiltered = this.templatesFiltered.sort((a, b) => {
                if (a.Id > b.Id) return 1;
                else return -1;
            });
        } else {
            switch (sorting.sortColumn) {
                case 'Id': {
                    this.templatesFiltered = this.templatesFiltered.sort((a, b) => {
                        if (a.Id > b.Id) return 1;
                        else return -1;
                    });
                    break;
                }
                case 'Name': {
                    this.templatesFiltered = this.templatesFiltered.sort((a, b) =>
                        a.Name.toLowerCase().localeCompare(b.Name.toLowerCase())
                    );
                    break;
                }
                case 'f1': {
                    this.templatesFiltered = this.templatesFiltered.sort((a, b) => {
                        if (a.ControlIds.length > b.ControlIds.length) return 1;
                        else return -1;
                    });
                    break;
                }
            }
            if (sorting.sortDirection == 'desc')
                this.templatesFiltered = this.templatesFiltered.reverse();
        }

        this.templatePages();
    }

    controlsAddSearchChanged() {
        let lowVal: string = this.controlsAddSearch.toLowerCase();

        if (lowVal && lowVal != '') {
            this.controlsAvailable = [];
            this.allControlsAvailable.forEach((c) => {
                let controlId: string = c.control.ControlId.toLowerCase();
                let shortDesc: string =
                    c.control.ShortDesc.LocalizedText[this.culture].toLowerCase();
                let longDesc: string = c.control.LongDesc.LocalizedText[this.culture].toLowerCase();

                if (
                    shortDesc.includes(lowVal) ||
                    controlId.includes(lowVal) ||
                    longDesc.includes(lowVal)
                ) {
                    this.controlsAvailable.push(c);
                }
            });
        } else if (lowVal == '') {
            this.controlsAvailable = this.allControlsAvailable;
        }

        this.controlsSortColumn = 'Description';
        this.controlsSortDirection = 'desc';

        this.onControlSorted();
    }

    /**
     * This method is used to discard the changed control name in add control template modal upon editing a Control Template.
     */
    noChangeTitle() {
        this.templateEditName = this.tempControlName;
        this.viewSettings.editControlName = false;
    }

    /**
     * This method is used to save the changed control name in add control template modal upon editing a Control Template.
     */
    yesChangeTitle() {
        this.tempControlName = this.templateEditName;
        this.viewSettings.editControlName = false;
    }

    onControlSorted(ev?: any) {
        if (ev) {
            this.controlsSortColumn = ev.sortColumn;
            this.controlsSortDirection = ev.sortDirection;
        } else {
            this.controlsSortColumn = 'ControlId';
            this.controlsSortDirection = 'asc';
        }

        this.controlsSort();
    }

    controlsSort() {
        let controls: controlSelect[];

        if (this.controlsAdd) {
            controls = this.controlsAvailable;
        } else {
            controls = this.controlsSelected;
        }

        let cola: string = '';
        let colb: string = '';

        controls = controls.sort((a, b) => {
            if (this.controlsSortColumn == 'Description') {
                cola = a.control.ShortDesc.LocalizedText[this.culture];
                colb = b.control.ShortDesc.LocalizedText[this.culture];
            } else {
                cola = a.control[this.controlsSortColumn];
                colb = b.control[this.controlsSortColumn];
            }

            if (this.controlsSortColumn == 'ControlId') {
                cola = this.fixControlId(cola);
                colb = this.fixControlId(colb);
            }

            if (typeof cola === 'string') cola = cola.toLocaleLowerCase();
            if (typeof colb === 'string') colb = colb.toLocaleLowerCase();

            if (this.controlsSortDirection === 'desc') {
                return cola < colb ? 1 : cola === colb ? 0 : -1;
            } else {
                return cola > colb ? 1 : cola === colb ? 0 : -1;
            }
        });
        this.controlPages();
        if (!this.controlsAdd) {
            this.controlsSelected = controls;
            this.pageControlsAddControlTemplate(this.addControlTemplatePaginatorSettings);
        } else {
            this.controlsAvailable = controls;
            this.searchControlOnPage(this.searchControlPaginatorSettings);
        }
    }

    fixControlId(id: string) {
        let els: string[] = id.split('.');
        for (var i = 0; i < els.length; i++) {
            els[i] = els[i].padStart(3, '0');
        }
        return els.join('.');
    }

    controlPages(ev?: any) {
        if (ev) {
            if (ev.pageNumber != this.controlPageNumber) {
                setTimeout(this.delayPageNumber, 300, this.controlPageNumber, ev.pageNumber);
            }
        }

        if (this.controlsAdd) {
            this.controlsGrid = this.controlsAvailable;
        } else {
            this.controlsGrid = this.controlsSelected;
        }
        this.controlPage = this.controlsGrid;
    }

    getTemplates() {
        this.templateSortColumn = 'Id';
        this.templateSortDirection = 'asc';

        this.controlService.getSystemControlTemplates().then((resSystem) => {
            this.templates = [];
            this.templates.push(...resSystem);
            this.controlService.getControlTemplates().then((resAccount) => {
                this.templates.push(...resAccount);
                this.controlTemplateNames = [];
                this.templates.forEach((sc) => {
                    this.controlTemplateNames.push(sc.Name);
                });

                this.doTemplateSearch();
            });
        });
    }

    getControls() {
        this.controlService.getSystemControlsForIso(this.culture).then((resSystem) => {
            this.controlsAll = [];
            this.controlsAll.push(...resSystem);
            this.controlService.getAccountControlsForIso(this.culture).then((resAccount) => {
                this.controlsAll.push(...resAccount);
                this.loadingComplete = true;
            });
        });
    }

    setControls(template: ControlTemplate) {
        this.controls = [];
        template.ControlIds.forEach((c) => {
            this.controls.push(this.controlsAll.find((ca) => ca.Id == c));
        });
    }

    setTemplateControlIds(template: ControlTemplate) {
        template.ControlIds = [];
        this.controls.forEach((c) => {
            template.ControlIds.push(c.Id);
        });
    }

    hideSystemTemplatesChanged(event) {
        this.hideSystemTemplates = event;
        this.doTemplateSearch();
    }

    delayPageNumber(whichPage, pageno) {
        whichPage = pageno;
    }

    showTemplateCloneModal(template: ControlTemplate) {
        this.templateActive = template;

        this.clonedControlTemplateName = this.templateActive.Name;
        if (
            this.templatePage.findIndex((e) => {
                return e.Name == this.clonedControlTemplateName;
            }) > -1
        ) {
            var temp = this.clonedControlTemplateName;
            let count = 0;
            while (true) {
                this.clonedControlTemplateName =
                    temp + (count == 0 ? ' (copy)' : ' (copy -' + count + ')');
                let i = this.templatePage.findIndex((e) => {
                    return e.Name == this.clonedControlTemplateName;
                });
                if (i == -1) break;
                else count++;
            }
        }
        this.cloneControlTemplateModal.show({ closable: false });
    }

    cloneSelectedControlTemplate() {
        if (
            this.templatePage.findIndex((e) => {
                return e.Name == this.clonedControlTemplateName;
            }) > -1
        ) {
            var temp = this.clonedControlTemplateName;
            let count = 0;
            while (true) {
                this.clonedControlTemplateName =
                    temp + (count == 0 ? ' (copy)' : ' (copy -' + count + ')');
                let i = this.templatePage.findIndex((e) => {
                    return e.Name == this.clonedControlTemplateName;
                });
                if (i == -1) break;
                else count++;
            }
        }
        this.cloning = true;
        this.controlService
            .cloneControlTemplate(this.templateActive.Id, this.clonedControlTemplateName)
            .then((res) => {
                this.getTemplates();
                this.notificationService.toast.success('Success', 'Control Successfully Cloned');
                this.cloneControlTemplateModal.hide();
                this.cloning = false;
            });
    }

    closeClone() {
        this.cloneControlTemplateModal.hide();
    }
}
