import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subject, lastValueFrom } from 'rxjs';

import { environment } from 'environments/environment';
import { AuthHttp } from 'app/core/auth';
import { ErrorService } from 'app/core/error/error.service';
import { MessageService } from 'app/core/message.service';

import { Setting } from './setting.model';
import { SETTINGS, SDSP_SETTINGS } from './constants';

import { GlobalUser } from './global-user.model';
import { Breadcrumb } from 'app/shared/breadcrumbs/breadcrumb.model';
import { TimeZone } from 'app/shared/date.service';

@Injectable({ providedIn: 'root' })
export class SettingService {
    private apiUrl: string;

    private customBrandingChangeSubject: Subject<boolean> = new Subject<boolean>();
    private themeChangeSubject: Subject<string> = new Subject<string>();
    private navHeaderTextChangeSubject: Subject<string> = new Subject<string>();
    private navKaseyaLogoChangeSubject: Subject<boolean> = new Subject<boolean>();
    private itCompleteBreadcrumbs: Subject<Breadcrumb[]> = new Subject<Breadcrumb[]>();

    constructor(
        private http: AuthHttp,
        private messageService: MessageService,
        private E: ErrorService
    ) {
        this.apiUrl = environment.apiUrl;
    }

    getSettings(): Promise<Setting[]> {
        const url: string = this.apiUrl + 'Settings';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as Setting[]);
        //.catch(this.E.error);
    }

    getSetting(settingName: string): Promise<Setting> {
        const url: string = this.apiUrl + `Setting/${settingName}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as Setting);
        //.catch(this.E.error);
    }

    getItCompleteSetting(settingName: string): Promise<Setting> {
        const url: string = this.apiUrl + `ItCompleteSetting/${settingName}`;
        return lastValueFrom(this.http.get(url)).then((res) => res as Setting);
    }

    getLargeSetting(settingName: string): Promise<string> {
        const url: string = this.apiUrl + `LargeSetting/${settingName}`;
        return lastValueFrom(this.http.get(url)).then((res) => res as string);
    }

    getItCompleteLargeSetting(settingName: string): Promise<string> {
        const url: string = this.apiUrl + `ItCompleteLargeSetting/${settingName}`;
        return lastValueFrom(this.http.get(url)).then((res) => res as string);
    }

    updateLargeSetting(settingName: string, val: string): Promise<any> {
        const url: string = this.apiUrl + `LargeSetting/${settingName}`;
        return lastValueFrom(this.http.post(url, { value: val })).then((res) => {
            return res;
        });
    }

    updateItCompleteLargeSetting(settingName: string, val: string): Promise<any> {
        const url: string = this.apiUrl + `ItCompleteLargeSetting/${settingName}`;
        return lastValueFrom(this.http.post(url, { value: val })).then((res) => {
            return res;
        });
    }

    updateItCompleteSettings(settings: Setting[]): Promise<any> {
        const url: string = this.apiUrl + 'ItCompleteSettings';
        return lastValueFrom(this.http.post(url, { settings: settings })).then((res) => {
            // Get localStorage settings to update
            let sets = JSON.parse(localStorage.getItem(SDSP_SETTINGS) || '{}');
            for (let set of settings) {
                sets[set.Name] = set.Value;
            }

            if (!sets.CompanyLogo) sets.CompanyLogo = '';

            // Save back to localStorage
            localStorage.setItem(SDSP_SETTINGS, JSON.stringify(sets));

            // Broadcast new settings
            this.messageService.broadcast(SETTINGS, sets);
            return res;
        });
        //.catch(this.E.error);
    }

    updateSettings(settings: Setting[]): Promise<any> {
        const url: string = this.apiUrl + 'Settings';
        return this.http
            .post(url, { settings: settings })
            .toPromise()
            .then((res) => {
                // Get localStorage settings to update
                let sets = JSON.parse(localStorage.getItem(SDSP_SETTINGS) || '{}');
                for (let set of settings) {
                    sets[set.Name] = set.Value;
                }

                if (!sets.CompanyLogo) sets.CompanyLogo = '';

                // Save back to localStorage
                localStorage.setItem(SDSP_SETTINGS, JSON.stringify(sets));

                // Broadcast new settings
                this.messageService.broadcast(SETTINGS, sets);
                return res;
            });
        //.catch(this.E.error);
    }

    getBranding(): Promise<Setting[]> {
        const url: string = this.apiUrl + 'Settings/Branding';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res as Setting[]);
        //.catch(this.E.error);
    }

    checkSubdomain(subdomain: string): Promise<boolean> {
        const url: string = this.apiUrl + 'Settings/CheckSubdomain?name=' + subdomain;
        return this.http
            .get(url, { responseType: 'text' })
            .toPromise()
            .then((resText) => resText == 'true');
        //.catch(this.E.error);
    }

    getAccountUsers(): Promise<GlobalUser[]> {
        const url: string = this.apiUrl + 'Settings/Users';
        return this.http
            .get(url)
            .toPromise()
            .then((users) => users as GlobalUser[]);
    }

    postGlobalUser(user: GlobalUser): Promise<any> {
        const url: string = this.apiUrl + 'Settings/Users';
        return this.http
            .post(url, user)
            .toPromise()
            .then((res) => res);
    }

    putGlobalUser(user: GlobalUser): Promise<any> {
        const url: string = this.apiUrl + `Settings/Users/${user.Id}`;
        return this.http
            .put(url, user, { observe: 'response' })
            .toPromise()
            .then((res) => res);
    }

    deleteGlobalUser(user: GlobalUser): Promise<any> {
        const url: string = this.apiUrl + `Settings/Users/${user.Id}`;
        return this.http
            .delete(url, { observe: 'response' })
            .toPromise()
            .then((res) => res);
    }

    setGlobalUserSetting(settingName: string, settingValue: string): Promise<any> {
        const url: string = this.apiUrl + `User/Parameter`;
        let userParameter = { parameterName: settingName, parameterValue: settingValue };
        return this.http
            .post(url, userParameter, { observe: 'response' })
            .toPromise()
            .then((res) => res);
    }

    getGlobalUserSetting(settingName: string): Promise<any> {
        const url: string = this.apiUrl + `User/Parameter/${settingName}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    async getIsGlobalUserManagedByAccessGroups(userId: number): Promise<any> {
        const url: string = this.apiUrl + `User/IsManagedByAccessGroups/${userId}`;
        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    getTimeZones(): Promise<TimeZone[]> {
        const url: string = this.apiUrl + `TimeZones`;
        return this.http
            .get(url)
            .toPromise()
            .then((tz) => tz);
    }

    getAccountTimeZone(): Promise<string> {
        const url: string = this.apiUrl + `Settings/TimeZone`;
        return this.http
            .get(url)
            .toPromise()
            .then((zone) => zone);
    }

    getCustomBrandingChangeObservable(): Observable<boolean> {
        return this.customBrandingChangeSubject.asObservable();
    }
    getThemeChangeObservable(): Observable<string> {
        return this.themeChangeSubject.asObservable();
    }
    getNavHeaderTextChangeObservable(): Observable<string> {
        return this.navHeaderTextChangeSubject.asObservable();
    }
    getKaseyaLogoChangeObservable(): Observable<boolean> {
        return this.navKaseyaLogoChangeSubject.asObservable();
    }

    setCustomBranding(customBranding: boolean) {
        this.customBrandingChangeSubject.next(customBranding);
    }
    setTheme(theme: string) {
        this.themeChangeSubject.next(theme);
    }
    setCompanyName(coName: string) {
        this.navHeaderTextChangeSubject.next(coName);
    }
    setKlogo(showLogo: boolean) {
        this.navKaseyaLogoChangeSubject.next(showLogo);
    }

    setAccountTimeZone(zone: string): Promise<any> {
        const url: string = this.apiUrl + `Settings/TimeZone`;
        return this.http
            .put(url, `=${zone}`, {
                headers: new HttpHeaders().append(
                    'Content-Type',
                    'application/x-www-form-urlencoded'
                ),
            }) // weird format to send this in as formdata
            .toPromise() // see https://docs.microsoft.com/en-us/aspnet/web-api/overview/advanced/sending-html-form-data-part-1#sending_simple_types
            .then((res) => res);
    }

    addUserPrivacy(user: GlobalUser, pwd: string): Promise<any> {
        const url: string =
            this.apiUrl +
            `Settings/AddUserPrivacy/${user.Id}`;
        return this.http
            .post(url, { PrivacyCode: pwd })
            .toPromise()
            .then((res) => res);
    }

    removeUserPrivacy(user: GlobalUser): Promise<any> {
        const url: string = this.apiUrl + `Settings/RemoveUserPrivacy/${user.Id}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    k1Ssov2Registration(): Promise<string> {
        const url: string = this.apiUrl + 'k1ssov2register';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res)
            .catch((err) => {
                if (err.status == 302) {
                    window.location.href = err.error;
                }
            });
    }

    k1Ssov2Deregistration(): Promise<string> {
        const url: string = this.apiUrl + 'k1ssov2deregister';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res)
            .catch((err) => {
                if (err.status == 302) {
                    window.location.href = err.error;
                }
            });
    }

    isK1SsoV2Enabled(): Promise<any> {
        const url: string = this.apiUrl + 'k1ssov2enabled';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getK1SsoV2AccessGroups(): Promise<any> {
        const url: string = this.apiUrl + 'k1ssov2accessgroups';

        return lastValueFrom(this.http.get(url)).then((res) => res);
    }

    async syncK1SsoV2AccessGroups(): Promise<any> {
        const url: string = this.apiUrl + 'k1ssov2accessgroups';
        return lastValueFrom(this.http.put(url, {})).then((res) => {});
    }

    removeK1Mapping(): Promise<any> {
        const url: string = this.apiUrl + 'Settings/RemoveUserK1Mapping';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    disableItc(): Promise<any> {
        const url: string = this.apiUrl + 'Settings/DisableItc';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    isItcEnabled(): Promise<any> {
        const url: string = this.apiUrl + 'Settings/IsItcEnabled';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    isBullPhishEnabled(): Promise<any> {
        const url: string = this.apiUrl + 'Settings/IsBullPhishEnabled';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    setBullPhishValue(isEnabled = false): Promise<any> {
        const url: string = this.apiUrl + 'Settings/SetBullPhishGlobal/' + isEnabled;
        return this.http
            .post(url, {})
            .toPromise()
            .then((res) => res);
    }

    requestBullphishEmail(): Promise<any> {
        const url: string = this.apiUrl + 'Settings/RequestBullphishEmail/';
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getAccountApiKeys(): Promise<any> {
        const url: string = this.apiUrl + `Settings/GetApiKeys`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    createGlobalApiKey(name: string): Promise<any> {
        const url: string = this.apiUrl + `Settings/CreateApiKey/${name}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    deleteGlobalApiKey(apiId: number): Promise<any> {
        const url: string = this.apiUrl + `Settings/DeleteApiKey/${apiId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    revokeGlobalApiKey(apiId: number): Promise<any> {
        const url = this.apiUrl + `Settings/RevokeApiKey/${apiId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    importIpFromXml(formdata: FormData, siteId: number): Promise<any> {
        let headers = new HttpHeaders();
        headers.append('FileXML', 'application/xml');
        let options = { headers: headers };
        const url: string = this.apiUrl + `Settings/ImportIpFromXml/${siteId}`;
        return this.http
            .post(url, formdata, options)
            .toPromise()
            .then((res) => res);
    }

    importIpFromTxt(formdata: FormData, siteId: number): Promise<any> {
        let headers = new HttpHeaders();
        headers.append('FileTXT', 'application/txt');
        let options = { headers: headers };
        const url: string = this.apiUrl + `Settings/ImportIpFromTxt/${siteId}`;
        return this.http
            .post(url, formdata, options)
            .toPromise()
            .then((res) => res);
    }

    createGlobalApiKeyForExisting(apiId: number): Promise<any> {
        const url = this.apiUrl + `Settings/CreateApiKeyForExisting/${apiId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    getApiKeyById(apiId: number): Promise<any> {
        const url: string = this.apiUrl + `Settings/GetApiKeyById/${apiId}`;
        return this.http
            .get(url)
            .toPromise()
            .then((res) => res);
    }

    setITCompleteBreadcrumbs(breadcrumbs: Breadcrumb[]) {
        this.itCompleteBreadcrumbs.next(breadcrumbs);
    }
    getITCompleteBreadcrumbs() {
        return this.itCompleteBreadcrumbs.asObservable();
    }
}
