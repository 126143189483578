import { Component, OnInit, ViewChild } from '@angular/core';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    NonNullableFormBuilder,
    ValidationErrors,
    Validators,
} from '@angular/forms';
import { MessageService } from 'app/core/message.service';
import { UiService } from 'app/core/ui.service';
import { PING_SITE, SITE } from 'app/sites/shared/constants';
import { KvsService } from 'app/sites/shared/kvs.service';
import { Site } from 'app/sites/shared/site.model';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { notBlank } from 'app/shared/not-blank.validator';
import { SemanticModalComponent } from 'app/semantic-legacy/semantic-legacy.module';
import { NotificationService } from '../../../../../shared/itc/notification/notification.service';

interface Credential {
    Uid?: string;
    SiteId?: number;
    Name: string;
    Comment: string;
    Type: 'Username + Password' | 'SNMP v1/2c' | 'SNMP v3';
    Value: CredentialValue;
}
interface CredentialValue {
    AllowInsecure: boolean | 'true' | 'false';
    Username?: string;
    Password?: string;
    CommunityString?: string;
    AuthAlgorithm?: 'MD5' | 'SHA1';
    PrivacyAlgorithm?: 'None' | 'AES' | 'DES';
    PrivatePassword?: string;
}

@Component({
    selector: 'kvs-scan-credentials',
    templateUrl: './kvs-scan-credentials.component.html',
    styleUrls: ['./kvs-scan-credentials.component.scss'],
})
export class KvsScanCredentialsComponent implements OnInit {
    breadcrumbs = [
        { path: '../..', text: 'Vulscan' },
        { path: '..', text: 'Settings' },
        { path: '.', text: 'Scan Credentials' },
    ];

    readonly credTypes: string[] = ['Username + Password', 'SNMP v1/2c', 'SNMP v3'];

    showingAddModal = false;
    loadingComplete = false;
    saving = false;
    attemptingDelete = false;
    deleting = false;
    readonly = false;
    credentials: Credential[];
    filteredCredentials: Credential[];
    filteringCredentials = false;
    showingPassword = false;
    showingPrivatePassword = false;
    site: Site;
    msgSub: Subscription;
    editMode = false;
    addCredentialWaiting = false;
    credentialToDelete: Credential;
    oldCredential: Credential;
    submittingForm = false;
    associatedScanTasks: any[];

    ngUnsubscribe$: Subject<any> = new Subject();

    searchKey: FormControl<string> = new FormControl('');
    addCredentialForm: FormGroup; // nonnullable formgroup

    @ViewChild('addCredentialModal', { static: true }) addCredentialModal: SemanticModalComponent;
    @ViewChild('confirmDeleteCredentialModal', { static: true })
    confirmDeleteCredentialModal: SemanticModalComponent;
    @ViewChild('confirmCancelModal', { static: true }) confirmCancelModal: SemanticModalComponent;
    @ViewChild('confirmEditModal', { static: true }) confirmEditModal: SemanticModalComponent;

    constructor(
        private kvsService: KvsService,
        private notificationService: NotificationService,
        private msgService: MessageService,
        private uiService: UiService,
        private fb: NonNullableFormBuilder
    ) {}

    ngOnInit() {
        this.uiService.setTitle('Scan Credentials');

        this.msgSub = this.msgService.on(SITE).subscribe((site: Site) => {
            if (!site) return;

            this.site = site;
            this.getCredentials();
        });

        this.addCredentialForm = this.fb.group({
            Name: ['', [notBlank, Validators.maxLength(128)]],
            Comment: ['', Validators.maxLength(256)],
            Type: [this.credTypes[0], Validators.required],
            Value: this.fb.group({
                UPGroup: this.fb.group({
                    AllowInsecure: ['false', Validators.required],
                    Username: ['', notBlank],
                    Password: ['', this.notBlankAndNeeded],
                }),
                SNMPGroup: this.fb.group({
                    AllowInsecure: ['false', Validators.required],
                    CommunityString: ['', notBlank],
                }),
                SNMP3Group: this.fb.group({
                    AllowInsecure: ['false', Validators.required],
                    AuthAlgorithm: ['SHA1', Validators.required],
                    Username: ['', notBlank],
                    Password: ['', this.notBlankAndNeeded],
                    PrivacyAlgorithm: ['None', Validators.required],
                    PrivatePassword: [{ value: '', disabled: true }, this.validatePrivatePassword],
                }),
            }),
        });

        this.addCredentialForm.get('Value').get('SNMPGroup').disable();
        this.addCredentialForm.get('Value').get('SNMP3Group').disable();

        this.addCredentialForm
            .get('Type')
            .valueChanges.pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((value) => {
                this.resetSections();
                this.submittingForm = false;

                if (value === this.credTypes[0]) {
                    this.addCredentialForm.get('Value').get('UPGroup').enable();
                    this.addCredentialForm.get('Value').get('SNMPGroup').disable();
                    this.addCredentialForm.get('Value').get('SNMP3Group').disable();
                    return;
                }
                if (value === this.credTypes[1]) {
                    this.addCredentialForm.get('Value').get('UPGroup').disable();
                    this.addCredentialForm.get('Value').get('SNMPGroup').enable();
                    this.addCredentialForm.get('Value').get('SNMP3Group').disable();
                    return;
                }
                if (value === this.credTypes[2]) {
                    this.addCredentialForm.get('Value').get('UPGroup').disable();
                    this.addCredentialForm.get('Value').get('SNMPGroup').disable();
                    this.addCredentialForm.get('Value').get('SNMP3Group').enable();
                }
            });

        // disable privatepassword so we don't validate if there's no PrivacyAlgo chosen
        this.addCredentialForm
            .get('Value')
            .get('SNMP3Group')
            .get('PrivacyAlgorithm')
            .valueChanges.pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((v) => {
                let control = this.addCredentialForm
                    .get('Value')
                    .get('SNMP3Group')
                    .get('PrivatePassword');
                if (v === 'None') {
                    control.disable();
                } else {
                    control.enable();
                }
            });

        this.msgService.broadcast(PING_SITE);
    }

    get UPGroup() {
        return this.addCredentialForm.get('Value').get('UPGroup')['controls'];
    }
    get SNMPGroup() {
        return this.addCredentialForm.get('Value').get('SNMPGroup')['controls'];
    }
    get SNMP3Group() {
        return this.addCredentialForm.get('Value').get('SNMP3Group')['controls'];
    }

    getCredentials() {
        if (this.site) {
            this.loadingComplete = false;
            this.kvsService.getCredentials(this.site.Id).then((res) => {
                this.credentials = res;
                this.filteredCredentials = res;
                this.searchKey.setValue('');
                this.onCredentialsSorted({ sortColumn: 'Name', sortDirection: 'asc' });
                this.loadingComplete = true;
            });
        }
    }

    filterCredentials() {
        let filtered = [];
        if (this.searchKey) {
            this.filteringCredentials = true;
            let key = this.searchKey.value.toLowerCase();
            filtered = this.credentials.filter(
                (credential) =>
                    credential.Name.toLowerCase().indexOf(key) >= 0 ||
                    credential.Type.toLowerCase().indexOf(key) >= 0 ||
                    credential.Comment.toLowerCase().indexOf(key) >= 0
            );
        } else {
            filtered = this.credentials;
        }

        this.filteredCredentials = filtered;
        setTimeout(() => {
            this.filteringCredentials = false;
        }, 250);
    }

    resetSections(group?) {
        if (group) {
            this.addCredentialForm.get('Value').get(group).reset();
        } else {
            this.addCredentialForm.get('Value').get('UPGroup').reset();
            this.addCredentialForm.get('Value').get('SNMPGroup').reset();
            this.addCredentialForm.get('Value').get('SNMP3Group').reset();
        }
    }
    resetForm() {
        // this.submittingForm = false;
        this.addCredentialForm.reset();
    }

    showAddCredentialModal() {
        this.editMode = false;
        this.showingAddModal = true;
        this.addCredentialModal.show({ closable: false });
    }

    showCredentialEditModal(credential: any) {
        console.log(credential);
        this.editMode = true;
        this.oldCredential = credential;
        this.resetForm();

        this.addCredentialForm.patchValue({
            Name: credential.Name,
            Comment: credential.Comment,
            Type: credential.Type,
            Value: {
                UPGroup: {
                    AllowInsecure: credential.Value.AllowInsecure || 'false',
                    Username: credential.Value.Username || '',
                },
                SNMPGroup: {
                    AllowInsecure: credential.Value.AllowInsecure || 'false',
                    CommunityString: credential.Value.CommunityString || '',
                },
                SNMP3Group: {
                    AllowInsecure: credential.Value.AllowInsecure || 'false',
                    AuthAlgorithm: credential.Value.AuthAlgorithm || 'SHA1',
                    Username: credential.Value.Username || '',
                    PrivacyAlgorithm: credential.Value.PrivacyAlgorithm || 'none',
                },
            },
        });
        console.log('this.addCredentialForm', this.addCredentialForm);
        this.showingAddModal = true;
        this.addCredentialModal.show({ closable: false });
    }

    onCredentialsSorted(ev?: any) {
        console.log(ev);
        if (ev && ev.sortColumn != undefined) {
            this.filteredCredentials = this.filteredCredentials.sort((a, b) =>
                this.sortByColumn(a, b, ev.sortColumn.replace('-', ''), ev.sortDirection)
            );
        }
    }

    sortByColumn(a, b, sortMethod, sortDirection) {
        if (sortMethod === 'Id') {
            return this.cmp(a[sortMethod], b[sortMethod], sortDirection);
        }
        a[sortMethod] = a[sortMethod] === undefined ? '' : a[sortMethod];
        b[sortMethod] = b[sortMethod] === undefined ? '' : b[sortMethod];
        return this.cmp(a[sortMethod].toUpperCase(), b[sortMethod].toUpperCase(), sortDirection);
    }

    cmp(a, b, sortDirection) {
        if (a > b) {
            return sortDirection == 'asc' ? 1 : -1;
        }
        if (a < b) {
            return sortDirection == 'asc' ? -1 : 1;
        }
        return 0;
    }

    confirmCredentialDelete(credential: any) {
        this.credentialToDelete = credential;
        this.attemptingDelete = true;
        this.kvsService
            .getKvsCredentialScanTasks(this.site.Id, this.credentialToDelete.Uid)
            .then((res) => {
                this.associatedScanTasks = res;
                this.attemptingDelete = false;
                this.confirmDeleteCredentialModal.show({ closable: false });
            });
    }

    deleteCredential() {
        if (this.credentialToDelete) {
            this.deleting = true;
            this.kvsService
                .deleteCredential({
                    credential: this.credentialToDelete,
                    scanTasks: this.associatedScanTasks,
                })
                .then((res) => {
                    this.confirmDeleteCredentialModal.hide();
                    this.getCredentials();
                    this.deleting = false;
                    this.notificationService.toast.success('Success', 'Credential Deleted');
                });
        } else {
            this.deleting = false;
        }
    }

    credentialSaved(evt: any) {
        this.getCredentials();
    }

    warnAndCancel() {
        if (this.addCredentialForm.dirty) {
            this.confirmCancelModal.show({ closable: false });
        } else {
            this.addCredentialModal.hide();
        }
        this.addCredentialForm.reset();
        this.showingAddModal = false;
    }

    confirmEdit() {
        this.saving = true;
        let credentialDetail = this.formatCredential();
        if (credentialDetail.Value.Password === '') {
            delete credentialDetail.Value.Password;
        }
        if (credentialDetail.Value.PrivatePassword === '') {
            delete credentialDetail.Value.PrivatePassword;
        }
        this.kvsService.updateCredential(this.oldCredential, credentialDetail).then((res) => {
            if (res == 'true') {
                this.notificationService.toast.error(
                    'Error',
                    "Credential with the name '" + credentialDetail.Name + "' already exists"
                );
                return;
            }
            this.confirmEditModal.hide();
            this.notificationService.toast.success('Success', 'Credential Saved');
            this.saving = false;
            this.getCredentials();
        });
    }

    saveNewCredential() {
        if (this.addCredentialForm.valid) {
            this.submittingForm = true;
            this.saving = true;
            let credentialDetail = this.formatCredential();
            credentialDetail['SiteId'] = this.site.Id;
            if (!this.editMode) {
                this.kvsService.addCredential(credentialDetail).then((res) => {
                    this.saving = false;
                    this.submittingForm = false;
                    if (res == 'true') {
                        this.notificationService.toast.error(
                            'Error',
                            "Credential with the name '" +
                                credentialDetail.Name +
                                "' already exists"
                        );
                        return;
                    } else {
                        this.addCredentialModal.hide();
                        this.showingAddModal = false;
                        this.resetForm();
                        this.getCredentials();
                        this.notificationService.toast.success('Success', 'Credential Saved');
                    }
                });
            } else {
                if (this.addCredentialForm.dirty) {
                    this.kvsService
                        .getKvsCredentialScanTasks(this.site.Id, this.oldCredential.Uid)
                        .then((res) => {
                            this.submittingForm = false;
                            this.saving = false;
                            this.associatedScanTasks = res;
                            this.showingAddModal = false;
                            if (this.associatedScanTasks.length == 0) {
                                this.addCredentialModal.hide();
                                this.confirmEdit();
                            } else {
                                this.confirmEditModal.show();
                            }
                        });
                } else {
                    this.saving = false;
                    this.submittingForm = false;
                    this.addCredentialModal.hide();
                    this.showingAddModal = false;
                    this.getCredentials();
                    this.notificationService.toast.success('Success', 'Credential Saved');
                }
            }
        }
    }

    formatCredential(): Credential {
        let credentialDetail = this.addCredentialForm.getRawValue();
        credentialDetail['SiteId'] = this.site.Id;
        switch (credentialDetail.Type) {
            case this.credTypes[0]:
                credentialDetail.Value = credentialDetail.Value.UPGroup;
                break;
            case this.credTypes[1]:
                credentialDetail.Value = credentialDetail.Value.SNMPGroup;
                break;
            case this.credTypes[2]:
                credentialDetail.Value = credentialDetail.Value.SNMP3Group;
                break;
        }
        return credentialDetail;
    }

    ngOnDestroy() {
        this.msgSub.unsubscribe();
        this.ngUnsubscribe$.next(void 0);
        this.ngUnsubscribe$.complete();
    }

    /* special Validators */
    notBlankAndNeeded = (control: AbstractControl): ValidationErrors | null => {
        if (this.editMode) {
            return null;
        } // don't validate if in edit mode

        if (!control.value || (control.value && control.value.trim() === '')) {
            return { required: true };
        }
        return null;
    };

    validatePrivatePassword = (control: AbstractControl): ValidationErrors | null => {
        if (this.editMode) {
            return null;
        } // don't validate if in edit mode

        if (
            this.addCredentialForm.get('Value').get('SNMP3Group').get('PrivacyAlgorithm').value !==
                'None' &&
            (!control.value || (control.value && control.value.trim() === ''))
        ) {
            return { privatePasswordRequired: true };
        }
        return null;
    };
}
