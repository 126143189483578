import { Site } from 'app/sites/shared/site.model';

export class Organization {
    public Id: number;
    public AccountId: string;
    public Name: string;
    public Sites: Site[];
    public FilteredSites?: Site[];
    public ItGlueOrganizationId?: number;
    public ItGlueOrganizationName?: string;
    public HasCSPro?: boolean;
    public orgLevelDiscoveryAgents?: boolean;
}
