<sm-modal
    title="Move Site(s)"
    class="md moveSiteModal"
    [overrideOverflow]="sourceOrganizationSites.length && !showOneSite ? false : true"
    #modal>
    <modal-content>
        <div class="ui form" name="moveSiteForm" *ngIf="step == 1">
            <div class="ui two column grid">
                <div class="row" style="padding-bottom: 0">
                    <div class="column">
                        <label class="originLabel">Origin Organization</label>
                    </div>
                    <div class="column">
                        <label class="moveLabel">Move to Organization</label>
                    </div>
                </div>
                <div class="row" style="padding-top: 4px">
                    <div class="column">
                        Select one or more sites under an Organization and move to another
                        Organization from the list on the right.
                    </div>
                    <div class="column">
                        Select an Organization from the list to move selected site(s).
                    </div>
                </div>
                <div class="row" style="padding-top: 0; padding-bottom: 0">
                    <div class="column">
                        <p-dropdown
                            placeholder="Select Origin Organization"
                            id="organizationSelectSource"
                            name="organizationSelectSource"
                            [(ngModel)]="sourceOrganizationName"
                            [options]="nameFilteredOrganization"
                            (onChange)="setSourceOrganizationId($event)">
                            <ng-template let-item pTemplate="item">
                                <span [title]="item">{{ item }}</span>
                            </ng-template>
                        </p-dropdown>

                        <sds-loader [complete]="loadingSitesComplete" class="sitesChoiceLoader">
                            <div
                                class="sitesChoiceContainer"
                                *ngIf="sourceOrganizationSites.length">
                                <div
                                    *ngFor="let site of sourceOrganizationSites; let i = index"
                                    class="siteNameCheckbox">
                                    <itc-checkbox
                                        [(ngModel)]="selectedSites[i]"
                                        (ngModelChange)="onSiteSelected($event)">
                                        {{ site.Name }}
                                    </itc-checkbox>
                                </div>
                            </div>
                        </sds-loader>
                    </div>
                    <div class="column">
                        <p-dropdown
                            placeholder="Select Destination Organization"
                            id="organizationSelectDestination"
                            name="organizationSelectDestination"
                            [(ngModel)]="destinationOrganizationName"
                            [options]="nameOrganization"
                            (onChange)="setDestinationOrganizationId($event)"></p-dropdown>
                    </div>
                </div>
            </div>
        </div>
        <div class="ui form" name="moveSiteConfirmForm" *ngIf="step == 2">
            <div class="ui two column grid">
                <div class="row" style="padding-bottom: 0">
                    <div class="column">
                        <label class="confirmSectionLabel">Origin Organization</label>
                    </div>
                    <div class="column">
                        <label class="confirmSectionLabel">Move to Organization</label>
                    </div>
                </div>
                <div class="row" style="padding-bottom: 0">
                    <div class="column">
                        <label class="confirmMovingSitesLabel">
                            {{ getSourceOrganizationName() }}
                        </label>
                    </div>
                    <div class="column">
                        <label
                            *ngIf="destinationOrganizationName === '__unassigned'"
                            class="confirmDestinationSitesLabel">
                            Unassigned Sites
                        </label>
                        <label
                            *ngIf="!(destinationOrganizationName === '__unassigned')"
                            class="confirmDestinationSitesLabel">
                            {{ destinationOrganizationName }}
                        </label>
                    </div>
                </div>
                <div class="row" style="padding-top: 0">
                    <div class="column" style="padding-left: 54px">
                        <div *ngFor="let site of selectedSiteValues" class="movingSiteItem">
                            {{ site.Name }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="ui form" name="moveSiteCancelForm" *ngIf="step == -1">
            <h2>Confirmation</h2>
            This will cancel the move of any selected Sites to the selected Organization
        </div>
    </modal-content>
    <modal-actions>
        <itc-button
            *ngIf="step == 1"
            type="secondary"
            class="cancel"
            [disabled]="step == 3 && nextLoading"
            (onclick)="goBack()">
            Cancel
        </itc-button>
        <itc-button
            *ngIf="step == 2 || step == -1"
            type="secondary"
            class="back"
            (onclick)="goBack()">
            Back
        </itc-button>
        <itc-button *ngIf="step == -1" type="primary" [loading]="nextLoading" (onclick)="advance()">
            Confirm Cancel
        </itc-button>
        <itc-button
            *ngIf="step == 1"
            type="primary"
            [loading]="nextLoading"
            [disabled]="
                !canProceed ||
                hasViolation ||
                destinationOrganizationName == undefined ||
                sourceOrganizationName == undefined
            "
            (onclick)="advance()">
            Move
        </itc-button>
        <itc-button
            *ngIf="step == 2"
            type="primary"
            [loading]="movingSite"
            [disabled]="!canProceed || hasViolation"
            (onclick)="advance()">
            Confirm Move
        </itc-button>
    </modal-actions>
</sm-modal>
