import { getLocaleDateFormat } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { PrintService } from 'app/sites/shared/print.service';
import { Requirement } from 'app/sites/shared/standards/requirement.model';
import { CM_COLORS } from '../../shared/cm-summary.constants';
import { ControlsAssessmentInfo } from './controlsasessment.model';

@Component({
    selector: 'printable-controls-assessment',
    templateUrl: './printable-controls-assessment.component.html',
    styleUrls: ['./printable-controls-assessment.component.scss'],
})
export class PrintableControlsAssessmentComponent implements OnInit {
    public today = new Date();
    CM_COLORS = CM_COLORS; // need this to be able to pass into template
    controlsAssessmentInfo: ControlsAssessmentInfo;
    culture: string = 'en-US';
    constructor(private printService: PrintService) {}

    ngOnInit() {
        this.controlsAssessmentInfo = this.printService.ControlsAssessmentData;

        Promise.resolve(this.controlsAssessmentInfo).then(() => {
            setTimeout(() => {
                this.printService.onDataReady();
            }, 500);
        });

        //  Promise.all(this.rapidBaselineInfo).then(()=> this.printService.onDataReady());
    }

    getControlRequirementText(standard: any, requirements: Requirement[]) {
        let names: string[] = [];

        requirements.forEach((r) => {
            let vr = standard.Requirements.find((vr) => vr.Id == r.Id);
            if (vr) {
                let tag = r.RequirementId;
                if (!names.find((n) => n == tag)) {
                    names.push(tag);
                }
            }
        });

        if (names.length > 0) {
            names = names.sort();
            let html = names.join('<br/>');
            return html;
        }
        return '';
    }

    getAssigneeName(assignment: any, getAssigner: boolean = false) {
        let rValue = '';
        if (getAssigner) {
            let user = this.controlsAssessmentInfo.SiteUsers.find(
                (x) => x.FirstName + ' ' + x.LastName == assignment.AssignedByDisplayName
            );
            if (user) {
                rValue = assignment.AssignedByDisplayName;
            } else {
                rValue = '(Removed User)';
            }
        } else if (assignment.GroupId > -1) {
            let group = this.controlsAssessmentInfo.Groups.find((x) => x.Id == assignment.GroupId);
            if (group) {
                rValue = group.Name;
            } else {
                rValue = '(Removed Group)';
            }
        } else {
            let user = this.controlsAssessmentInfo.SiteUsers.find(
                (x) => x.FirstName + ' ' + x.LastName == assignment.UserDisplayName
            );
            if (user) {
                rValue = assignment.UserDisplayName;
            } else {
                rValue = '(Removed User)';
            }
        }
        return rValue;
    }

    getDynamicHeight(): string {
        return (
            Math.ceil(this.controlsAssessmentInfo.Results['ALL'].tableArr.length / 6) * 40 + 'px'
        );
    }
}
