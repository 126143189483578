import { Component, Input } from '@angular/core';
import { RFT_URL } from 'app/core/constants';

@Component({
    selector: 'scan-status-tooltips',
    template: `
        <div
            [style.cursor]="url || command ? 'pointer' : 'default'"
            [tooltip]="text"
            [ttLink]="url ? { href: this.url, text: linkText, newTab: true } : null"
            [ttTitle]="title"
            ttPosition="bottom">
            <status-icon  status='{{status}}' ></status-icon>
        </div>
    `,
    styles: [
        'div.help-button { display: inline-block;  cursor: pointer; }',
        'fa-icon { display: flex; width: 16px; height: 16px; }',
    ],
})

export class ScanStatusTooltipsComponent {
    @Input() title: string;
    @Input() text: string;
    @Input() noLink = false;
    @Input() url: string;
    @Input() linkText: string = 'View Scan';
    @Input() command: () => void;
    @Input() status = 'failed';

    rft_url:string = RFT_URL;

    constructor() {}


}
