import { Component, ComponentRef, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { MessageService } from 'app/core/message.service';
import { SiteUsersService } from 'app/sites/shared/site-users.service';
import { SiteService } from 'app/sites/shared/site.service';
import { Site } from 'app/sites/shared/site.model';
import { UiService } from 'app/core/ui.service';

import { PING_SITE, SITE } from 'app/sites/shared/constants';
import { InviteSmeComponent } from 'app/shared/invite-sme/invite-sme.component';
import { InformOldService } from '../../inform/inform.service';
import { NotificationService } from '../../../../shared/itc/notification/notification.service';
import { TableSorting } from '../../../../shared/sortable-table/sortable-table.component';

const CURRENT_WORKSHEETSET: string = '__current';
declare var $: any;

@Component({
    selector: 'sds-invite-management',
    templateUrl: './invite-management.component.html',
    styleUrls: ['./invite-management.component.css'],
})
export class InviteManagementComponent implements OnInit, OnDestroy {
    @ViewChild('inviteSME', { static: true }) inviteSME: ComponentRef<InviteSmeComponent>;
    site: Site;
    inviteButtonLoading: boolean = false;
    allSMEs: any = [];
    forms: string[];
    invites: Map<string, any[]>;
    formFileNames: Map<string, string>;
    loadingComplete: boolean;
    subs: any[] = [];
    siteType: string;

    breadcrumbs = [
        { path: '../..', text: 'Compliance Manager' },
        { path: '..', text: 'Settings' },
        { path: '.', text: 'Manage Invites' },
    ];

    constructor(
        private messageService: MessageService,
        private siteUsersService: SiteUsersService,
        private notificationService: NotificationService,
        private uiService: UiService,
        private siteService: SiteService,
        private informService: InformOldService
    ) {}

    ngOnInit() {
        this.subs.push(
            this.messageService.on(SITE).subscribe((site: Site) => {
                this.onSite(site);
            })
        );

        this.messageService.broadcast(PING_SITE);
    }

    ngOnDestroy() {
        for (let sub of this.subs) {
            sub.unsubscribe();
        }
    }

    onSite(site: Site) {
        if (!site) return;

        this.site = site;
        this.uiService.setTitle('Manage Invites', site.Name);

        if (this.siteService.isComplianceManagerGRC(this.site)) {
            // update breadcrumb text for GRC
            this.breadcrumbs[0].text = 'Compliance Manager GRC';
            this.siteType = 'CM2';
        }

        // this.inviteButtonLoading = true;
        this.siteUsersService
            .getSiteSmes(this.site.Id)
            .then((s) => {
                this.allSMEs = [...s];
            })
            .catch((res) => {
                this.notificationService.toast.error('Error', 'An unspecified error occurred.');
            });
        this.siteUsersService
            .getAllFormSmeInvitations(this.site.Id)
            .then((invites) => {
                let forms = [];
                this.invites = new Map<string, any[]>();
                this.formFileNames = new Map<string, string>();
                for (let inv of invites) {
                    let formName = inv.FormName;
                    this.formFileNames.set(formName, inv.FormFileName);

                    if (!this.invites.get(formName)) {
                        this.invites.set(formName, []);
                        forms.push(formName);
                    }

                    this.invites.get(formName).push(inv.User);
                }

                this.forms = forms;
                this.loadingComplete = true;
            })
            .catch((res) => {
                this.loadingComplete = true;
                this.notificationService.toast.error('Error', 'An unspecified error occurred.');
            });

        if (this.siteType) {
            this.informService
                .getInformsMetadata(this.site.Id, CURRENT_WORKSHEETSET)
                .then((metas) => {
                    let tempMeta = metas;

                    for (let formTemp of tempMeta) {
                        let tempName = formTemp.Filename.replace('.idf4', '');

                        if (this.formFileNames.get(tempName)) {
                            let rs = window.btoa(formTemp.Module + '/' + formTemp.Filename);
                            this.formFileNames.set(tempName, rs);
                        }
                    }
                })
                .catch((err) => {
                    this.loadingComplete = true;
                    console.error(err);
                });
        }
    }

    inviteModalLoading(val) {
        this.inviteButtonLoading = val;
    }

    invitesUpdated(emitVal) {
        this.invites.set(emitVal.formName, emitVal.users);
    }

    sortTable(sorting?: TableSorting) {
        if (!sorting) {
            this.forms = this.forms.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
        } else {
            if (sorting.sortColumn == 'smes') {
                this.forms = this.forms.sort((a, b) => {
                    let aFirst = '';
                    let bFirst = '';
                    if (this.invites.has(a)) {
                        let aInvites = this.invites.get(a);
                        aFirst = aInvites.length
                            ? aInvites[0].FirstName + aInvites[0].LastName + aInvites[0].Username
                            : '';
                    }
                    if (this.invites.has(b)) {
                        let bInvites = this.invites.get(b);
                        bFirst = bInvites.length
                            ? bInvites[0].FirstName + bInvites[0].LastName + bInvites[0].Username
                            : '';
                    }

                    return aFirst.toLowerCase().localeCompare(bFirst.toLowerCase());
                });
            } else {
                this.forms = this.forms.sort((a, b) =>
                    a.toLowerCase().localeCompare(b.toLowerCase())
                );
            }

            if (sorting.sortDirection == 'desc') {
                this.forms.reverse();
            }
        }
    }
}
