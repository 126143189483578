import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './core/auth';
import { DevGuard, ExpiredSiteGuard, ProductGuard, RolesGuard } from './core/auth/guards';

import { AlertComponent } from './alerts/alert/alert.component';
import { ConfirmComponent } from './core/confirm/confirm.component';
import { ErrorComponent } from './core/error/error.component';
import { EulaComponent } from './core/eula/eula.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { LayoutComponent } from './core/layout/layout.component';
import { DownloadComponent } from './download/download.component';
import { CMDownloadComponent } from './cm-download/cm-download.component';
import { LauncherComponent } from './launcher/launcher.component';
import { MsalOauthComponent } from './msal-oauth/msal-oauth.component';

import { SiteComponent, SitesComponent, SiteAuditComponent } from './sites';
import { OrganizationsComponent } from './organizations/organizations.component';
import { OrgDiscoveryAgentsComponent } from './organizations/discovery-agents/org-discovery-agents.component';
import { OrganizationSidebarComponent } from './organizations/organization-sidebar/organization-sidebar.component';
import { ClientReportsComponent } from 'app/sites/site/client/reports/client-reports.component';

import { ResourcesComponent } from './core/resources/resources.component';
import { GlobalTodoWrapperComponent } from './todo/global-todo-wrapper.component';
import { TodoComponent } from './todo/todo.component';

import { ForbiddenComponent } from 'app/core/forbidden/forbidden.component';
import { RouteTestComponent } from 'app/route-test/route-test.component';

import { ItcExampleComponent } from './shared/itc/examples/itc-example.component';

import { OrgRoles, Roles, SiteRoles } from 'app/core/auth/auth.service';
import { PrintLayoutComponent } from './print/print-layout.component';
import { PrintableRapidBaselineComponent } from './sites/site/compliance-manager/rapid-baseline/printable/printable-rapid-baseline.component';
import { PrintableControlsAssessmentComponent } from './sites/site/compliance-manager/controls-assessment/printable/printable-controls-assessment.component';
import { PrintableRequirementsAssessmentComponent } from './sites/site/compliance-manager/requirements-assessment/printable/printable-requirements-assessment.component';
import { PrintableAlertSopComponent } from './alerts/shared/alert-sop-modal/printable-alert-sop-modal/printable-alert-sop.component';

import { DashboardComponent as VendorPortalDashboardComponent } from './vendportal/dashboard/dashboard.component';
import { LoginComponent as VendorPortalLoginComponent } from './vendportal/login/login.component';

import { VpGuard } from './vendportal/vp.guard';
import { LayoutComponent as VendorLayoutComponent } from './vendportal/layout/layout.component';
import { PortalLayoutComponent as VendorPortalLayoutComponent } from './vendportal/portallayout/portal-layout.component';
import { AssessmentComponent as VendorPortalAssessmentComponent } from './vendportal/assessment/assessment.component';
import { PrintableVendorsComponent } from './sites/site/compliance-manager/vendor-management/printable-vendors/printable-vendors.component';
import { PrintableEmployeeTrackerPolicyComponent } from './sites/site/compliance-manager/employee-portal/printable/printable-employee-tracker-policy.component';
import { PrintableEmployeeTrackerTrainingComponent } from './sites/site/compliance-manager/employee-portal/printable/printable-employee-tracker-training.component';
import { PrintableAllVendorsComponent } from './sites/site/compliance-manager/vendor-management/printable-all-vendors/printable-all-vendors.component';
import { PrintableAllTrainingsComponent } from './sites/site/compliance-manager/employee-portal/printable-all-training/printable-all-trainings.component';
import { PrintableAllPoliciesComponent } from './sites/site/compliance-manager/employee-portal/printable-all-policies/printable-all-policies.component';
import { IndocAgentDownloadComponent } from './sites/site/indoc/datacollection/indoc-agent-download/indoc-agent-download.component';

/* NDPro Items */
import { NdpWrapperComponent } from './sites/site/network-detective-pro/ndpro-wrapper.component';
import { IndocCyberattackAgentDownloadComponent } from './sites/site/indoc/datacollection/indoc-cyberattack-agent-download/indoc-cyberattack-agent-download.component';
/*
    Guards:
        DevGuard: Build is dev build and not production, used to only allow on local and staging
        GlobalProductGuard: Checks authService.getProducts() checking if account is provisioned for product
        GlobalSettingsGuard: Special guard for admin settings, just ensuring Master or Admin accounts
        ProductGuard: Checks if site is a certain product
        RolesGuard: Checks against a passed in list of roles that are allowed, others will be blocked.
*/

const routes: Routes = [
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'launcher',
        component: LauncherComponent,
    },
    {
        path: 'download',
        component: DownloadComponent,
    },
    {
        path: 'cm/download',
        component: CMDownloadComponent,
    },
    {
        path: 'download/computer-scanner',
        component: IndocAgentDownloadComponent,
    },
    {
        path: 'download/cybersecurity-computer-scanner',
        component: IndocCyberattackAgentDownloadComponent,
    },
    {
        path: 'eula',
        component: EulaComponent,
    },
    {
        path: 'resources/:product',
        component: ResourcesComponent,
        canActivate: [AuthGuard],
        // canActivate: [AuthGuard, GlobalProductGuard]
    },
    {
        path: 'logout',
        component: LogoutComponent,
    },
    {
        path: 'passwordreset',
        component: LoginComponent,
    },
    {
        path: 'oauth',
        component: MsalOauthComponent,
    },
    {
        path: 'routeTest',
        component: RouteTestComponent,
        canActivate: [AuthGuard, DevGuard],
    },
    {
        path: 'itc-examples',
        component: ItcExampleComponent,
        canActivate: [AuthGuard, DevGuard],
    },
    // {
    //     path: 'front-end-demo',
    //     loadChildren: () =>
    //         import('./frontEndDemo/frontEndDemo.module').then((m) => m.FrontEndDemoModule),
    // },
    {
        path: 'print',
        outlet: 'print',
        component: PrintLayoutComponent,
        children: [
            {
                path: 'rapidBaseline',
                component: PrintableRapidBaselineComponent,
            },
            {
                path: 'controlsAssessment',
                component: PrintableControlsAssessmentComponent,
            },
            {
                path: 'requirementsAssessment',
                component: PrintableRequirementsAssessmentComponent,
            },
            { path: 'vendorsAssessment', component: PrintableVendorsComponent },
            {
                path: 'allVendorsAssessment',
                component: PrintableAllVendorsComponent,
            },
            { path: 'alertSop', component: PrintableAlertSopComponent },
            {
                path: 'employeeTrackerPolicy',
                component: PrintableEmployeeTrackerPolicyComponent,
            },
            {
                path: 'employeeTrackerTraining',
                component: PrintableEmployeeTrackerTrainingComponent,
            },
            {
                path: 'allEmployeeTrainings',
                component: PrintableAllTrainingsComponent,
            },
            {
                path: 'allEmployeePolicies',
                component: PrintableAllPoliciesComponent,
            },
        ],
    },

    {
        path: 'vendor',
        component: VendorLayoutComponent,
        children: [
            { path: 'login', component: VendorPortalLoginComponent },

            //AUTHENTICATED
            {
                path: '',
                canActivateChild: [VpGuard],
                component: VendorPortalLayoutComponent,
                children: [
                    { path: 'home', component: VendorPortalDashboardComponent },
                    {
                        path: 'assessment/:id',
                        component: VendorPortalAssessmentComponent,
                    },
                    {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: '/vendor/login',
                        data: { redirectType: 'catch-all' },
                    },
                ],
            },
            { path: ':inviteguid', component: VendorPortalLoginComponent },
        ],
    },

    {
        path: '',
        component: LayoutComponent,
        children: [
            // UNAUTHENTICATED ROUTES
            {
                path: 'u',
                component: GlobalTodoWrapperComponent,
                children: [
                    {
                        path: 'alert',
                        component: AlertComponent,
                    },
                ],
            },
            {
                path: 'confirm',
                component: ConfirmComponent,
            },
            {
                path: 'error',
                component: ErrorComponent,
            },
            {
                path: 'error/403',
                component: ForbiddenComponent,
            },
            {
                path: 'error/403/:error',
                component: ForbiddenComponent,
            },

            // AUTHENTICATED ROUTES
            {
                path: '',
                canActivateChild: [AuthGuard],
                children: [
                    // SITES
                    //{ path: 'home', component: HomeComponent },
                    {
                        path: 'organizations',
                        component: OrganizationSidebarComponent,
                        children: [
                            {
                                path: '',
                                component: OrganizationsComponent,
                                data: { redirectType: 'route' },
                            },
                            {
                                path: ':organization',
                                component: SitesComponent,
                            },
                            {
                                path: ':organization/discovery-agents',
                                component: OrgDiscoveryAgentsComponent,
                                canActivate: [RolesGuard, ProductGuard],
                                data: {
                                    roles: [Roles.Admin, Roles.Master, OrgRoles.DiscoveryAgent],
                                    product: [
                                        'Compliance Manager GRC CSPro',
                                        'Compliance Manager GRC',
                                        'Network Detective Pro',
                                        'Vulnerability Scanner',
                                        'Indoc',
                                    ],
                                },
                            },
                        ],
                    },
                    {
                        path: 'site/:name',
                        component: SiteComponent,
                        canActivateChild: [ExpiredSiteGuard],
                        children: [
                            { path: '', redirectTo: 'home', pathMatch: 'full' },
                            {
                                path: 'home',
                                loadChildren: () =>
                                    import('./sites/site/home/home.module').then(
                                        (m) => m.HomeModule
                                    ),
                            },
                            {
                                path: 'indoc',
                                loadChildren: () =>
                                    import('./sites/site/indoc/indoc.module').then(
                                        (m) => m.IndocModule
                                    ),
                            },
                            {
                                path: 'network-detective-pro',
                                component: NdpWrapperComponent,
                                loadChildren: () =>
                                    import('./sites/site/network-detective-pro/ndpro.module').then(
                                        (m) => m.NetworkDetectiveProModule
                                    ),
                            },
                            {
                                path: 'cyber-hawk',
                                // canMatch: [ProductGuard],
                                data: { product: 'Cyber Hawk' },
                                loadChildren: () =>
                                    import('./cyber-hawk/cyber-hawk.module').then(
                                        (m) => m.CyberHawkModule
                                    ),
                            },

                            {
                                path: 'audit-log',
                                component: SiteAuditComponent,
                                canActivate: [RolesGuard],
                                data: { roles: [Roles.Master, Roles.Admin, SiteRoles.SiteAdmin] },
                            },
                            {
                                path: 'compliance-manager-grc',
                                loadChildren: () =>
                                    import(
                                        './sites/site/compliance-manager/compliance-manager.module'
                                    ).then((m) => m.ComplianceManagerModule),
                            },

                            {
                                path: 'compliance-manager',
                                loadChildren: () =>
                                    import('./sites/site/audit-guru/cm1.module').then(
                                        (m) => m.ComplianceManager1Module
                                    ),
                            },
                            {
                                path: 'audit-guru',
                                redirectTo: 'compliance-manager',
                            },
                            {
                                path: 'client/reports',
                                component: ClientReportsComponent,
                                // canActivate: [RolesGuard],
                                // data: { roles: [ SiteRoles.Auditor, SiteRoles.SiteAdmin, SiteRoles.Tech, SiteRoles.Client ] }
                            },

                            {
                                path: 'vulnerability-scanner',
                                loadChildren: () =>
                                    import(
                                        './sites/site/vulnerability-scanner/vulscan.module'
                                    ).then((m) => m.VulScanModule),
                            },
                        ],
                    },

                    // // TO DO (global) removed RFTDEV-20299
                    // {
                    //     path: 'todo',
                    //     component: GlobalTodoWrapperComponent,
                    //     children: [
                    //         {
                    //             path: '',
                    //             component: TodoComponent,
                    //             // canActivate: [RolesGuard],
                    //             // data: { roles: [ Roles.Restricted ] }
                    //         },
                    //         {
                    //             path: ':guid',
                    //             component: AlertComponent,
                    //             // canActivate: [RolesGuard],
                    //             // data: { roles: [ Roles.Restricted ] }
                    //         },
                    //     ],
                    // },

                    // SETTINGS
                    {
                        path: 'settings',
                        loadChildren: () =>
                            import('./settings/settings.module').then((m) => m.SettingsModule),
                    },

                    {
                        path: '**',
                        redirectTo: 'organizations',
                        data: { redirectType: 'catch-all' },
                    },
                ],
            },
        ],
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            canceledNavigationResolution: 'computed',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
