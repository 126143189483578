import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../../shared/shared.module';

import { AddSiteComponent } from './add-site/add-site.component';
import { DataFilterComponent } from './data-filter/data-filter.component';
import { DataCollectorInfoComponent } from './data-collector-info/data-collector-info.component';
import { MoveSiteComponent } from './move-site/move-site.component';
import { RestartSiteComponent } from './restart-site/restart-site.component';
import { RiskModalComponent } from './riskModal/risk-modal.component';
import { NotesCardComponent, SopCardComponent } from './notes';
import { DropdownModule } from 'primeng/dropdown';
import { InviteSmeComponent } from '../../shared/invite-sme/invite-sme.component';
import { TodoSharedModule } from 'app/todo/shared/todo-shared.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        SharedModule,
        DropdownModule,
        TodoSharedModule,
    ],
    declarations: [
        AddSiteComponent,
        DataFilterComponent,
        DataCollectorInfoComponent,
        MoveSiteComponent,
        RestartSiteComponent,
        RiskModalComponent,
        NotesCardComponent,
        SopCardComponent,
        InviteSmeComponent,
    ],
    exports: [
        AddSiteComponent,
        DataFilterComponent,
        DataCollectorInfoComponent,
        MoveSiteComponent,
        RestartSiteComponent,
        RiskModalComponent,
        NotesCardComponent,
        SopCardComponent,
        InviteSmeComponent,
    ],
    providers: [DatePipe],
})
export class SitesSharedModule {
    constructor() {
        console.log('SitesSharedModule constructor');
    }
}
