<div class="resourcePageContainer {{ product | lowercase }}" style="height: 100vh; overflow: auto">
    <a
        class="navbar-brand logo-nav"
        href="/"
        style="display: block; padding-left: 20px"
        *ngIf="product == 'Cyber Hawk'">
        <img
            alt="Cyber Hawk"
            id="chlogo"
            src="/assets/img/logo/cyberhawk/cyber-hawk-logo-text-byline.svg"
            width="416"
            style="margin-top: 10px" />
    </a>
    <a
        class="navbar-brand logo-nav"
        href="/"
        style="display: block; padding-left: 20px; padding-top: 20px"
        *ngIf="product != 'Cyber Hawk' && product != 'VulScan' && product != 'GRC'">
        <img
            alt="Compliance Manager"
            id="cmlogo"
            src="/assets/img/logo/compliancemanager/CM-horizontal-full_color_w-RFT_logo_mark_left.png"
            width="500px" />
    </a>
    <a
        class="navbar-brand logo-nav"
        href="/"
        style="display: block; padding-left: 20px; padding-top: 20px"
        *ngIf="product == 'GRC'">
        <img
            alt="Compliance Manager GRC"
            id="cmgrclogo"
            src="/assets/img/logo/compliancemanagergrc/SVG/logo-CMGRC-a-kaseya-company-horizontal-color.svg"
            width="500px" />
    </a>
    <a
        class="navbar-brand logo-nav"
        href="/"
        style="display: block; padding-left: 20px; padding-top: 20px"
        *ngIf="product == 'VulScan'">
        <img
            alt="VulScan"
            id="vslogo"
            src="/assets/img/logo/vulscan/vs/VulScan_Logo_ByRFT_FullColor.png"
            width="300px" />
    </a>

    <div class="categoryContainer">
        <div
            class="category"
            *ngFor="let category of categories"
            [class.active]="selectedCategory == resources[category][0].class"
            [hidden]="!resources[category][0].enabled">
            <p
                *ngIf="resources[category][0].enabled"
                [class.category]="resources[category][0].class"
                (click)="chooseCategory(resources[category][0].class)">
                {{ category }}
            </p>
        </div>
        <div class="category poweredServices">
            <a href="https://poweredservices.kaseya.com/" target="_blank">
                <img src="/assets/img/Powered-Services-Color.png" />
            </a>
        </div>
    </div>

    <div class="resourceOuterContainer" style="padding-bottom: 50px">
        <div
            class="resourceCategory"
            *ngFor="let category of categories"
            [hidden]="
                (selectedCategory != resources[category][0].class && selectedCategory != 'all') ||
                resources[category][0].class === 'all'
            ">
            <ng-container *ngIf="resources[category][0].enabled">
                <div class="resourceCategoryTitle">{{ category }}</div>
                <ng-container *ngFor="let resource of resources[category][0].items">
                    <div
                        *ngIf="resource.type != 'Video'"
                        class="resourceContainer {{ resource.class }}"
                        (click)="customClickCommand(resource.action, resource.link)">
                        <div class="resourceIcon" [class.stockIcon]="!resource.icon">
                            <img
                                *ngIf="resource.icon"
                                width="200"
                                src="{{ rft_url }}/data/resource-images/{{ resource.icon }}" />
                            <img
                                *ngIf="!resource.icon"
                                src="/assets/img/resources-{{ resource.class }}-icon.svg" />
                        </div>
                        <div class="resourceTextContainer">
                            <p class="resourceType">{{ resource.type }}</p>
                            <p class="resourceTitle">{{ resource.title }}</p>
                        </div>
                    </div>
                    <div
                        *ngIf="resource.type == 'Video'"
                        class="resourceContainer {{ resource.class }}">
                        <a class="vid" data-fancybox href="https://vimeo.com/{{ resource.link }}">
                            <img src="{{ rft_url }}/data/resource-images/{{ resource.icon }}" />
                            <div class="resourceTextContainer">
                                <p class="resourceType">{{ resource.type }}</p>
                                <p class="resourceTitle">{{ resource.title }}</p>
                            </div>
                        </a>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>
