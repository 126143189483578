export interface ReportTheme {
    ThemeName: string;
    DefaultFontName: string;
    DefaultFontSize: number;

    DefaultTextColor: number;

    Header1Size: number;
    Header2Size: number;
    Header3Size: number;
    Header4Size: number;

    HasBottomBorder: boolean;
    LineBottomBorder: boolean;

    DefaultTableFontSize: number;

    TableHeaderBackgroundColor: number;
    TableHeaderTextColor: number;
    TableRowColor: number;
    TableAtlRowColor: number;
    TableRowTextColor: number;

    CriticalRiskColor: number;
    HighRiskColor: number;
    MediumRiskColor: number;
    LowRiskColor: number;

    StopColor: number;
    GoColor: number;
    DisabledColor: number;
    GraphTitleColor: number;
    GraphSubTitleColor: number;
    XColor: number;
    YColor: number;
    GraphOutlineColor: number;

    ChartQuality: string;
    ChartTransparencyPercent: number;

    ChartColorsPaletteIntList: number[];
    ChartTopFivePaletteIntList: number[];
    ChartTwoColorPaletteIntList: number[];
    UseNewRiskMeter: boolean;

    /* Report modernization items */
    ReportModernizationEnabled?: boolean;
    UseNewRiskMeter2020?: boolean;
    ReportModernizationTableType?: string;
    ReportModernizationPrimaryColor?: string;
    ReportModernizationSecondaryColor?: string;
    ReportModernizationSecondaryColorLighter?: string;
    ReportModernizationTertiaryColor?: string;
    ReportModernizationHighlightColor?: string;
}

export function HexColor(r: number, g: number, b: number, isString: boolean = false): any {
    if (isString) {
        return '#' + decToHex(r) + decToHex(g) + decToHex(b);
    } else {
        return parseInt('0x' + decToHex(r) + decToHex(g) + decToHex(b), 16);
    }
}

function decToHex(num: number): string {
    let hex = '0' + num.toString(16);
    return hex.slice(-2);
}

export const DEFAULT_CHART_QUALITY: string =
    'Standard2D'; /* Possible Values: Legacy, Standard2D, Transparent3D */
export const DEFAULT_CHART_TRANSPARENCY: number = 20;

export function intToHexColor(color: number): string {
    return '#' + color.toString(16);
}
export function hextToIntColor(color: string): number {
    return parseInt(color.replace('#', ''), 16);
}
