<sds-loader [complete]="loadingComplete">
    <div class="indoc-dashboard">
        <div
            class="inDocScanDates"
            style="float: right; font-size: small; padding-top: 15px; margin-top: 18px"
            *ngIf="scanDate">
            Scan Date: {{ scanDateFormatted }}
            <br />
            Publish Date: {{ publishDateFormatted }}
        </div>
        <sds-breadcrumb-trail [crumbs]="breadcrumbs"></sds-breadcrumb-trail>

        <header class="pageHeader">
            <h1>Dashboard</h1>
        </header>
        <div class="ui grid fluid dashboard">
            <div class="sixteen wide tablet nine wide computer column">
                <div class="ui grid">
                    <!-- Empty / Outdated Scan Messages-->
                    <div class="sixteen wide column" *ngIf="emptyScan">
                        <itc-banner type="warning">
                            There is no InDoc data uploaded for this site. Please use the Reporter
                            to upload data with a scan.
                        </itc-banner>
                    </div>
                    <div class="sixteen wide column" *ngIf="outdatedScan">
                        <itc-banner type="warning">
                            The InDoc data present on the site is out of date. Please use the
                            Reporter to upload a more recent scan.
                        </itc-banner>
                    </div>

                    <!-- Risk Score -->
                    <div
                        class="six wide column"
                        *ngIf="showRiskScore"
                        style="padding-bottom: 8px; padding-right: 10px; padding-left: 0">
                        <sds-risk-score-card [riskScore]="riskScore"></sds-risk-score-card>
                        <!-- End Risk Score Card -->
                    </div>

                    <!-- Notes Card -->
                    <div
                        class="sixteen wide column"
                        *ngIf="showNotes"
                        style="padding-bottom: 8px; padding-right: 10px; padding-left: 0">
                        <sds-notes-card
                            [(notes)]="notes"
                            [itemType]="'Site'"
                            (onUpdate)="saveNotes($event)"></sds-notes-card>
                        <!-- End Notes Card -->
                    </div>

                    <!-- Asset Summary Card-->
                    <div
                        class="sixteen wide column"
                        *ngIf="showAssetSummary && !outdatedScan"
                        style="padding-bottom: 8px; padding-right: 10px; padding-left: 0">
                        <sds-site-dashboard-assets
                            [summary]="summaryInfo"></sds-site-dashboard-assets>
                        <!--<sds-asset-summary-card></sds-asset-summary-card>-->
                    </div>
                </div>
            </div>
            <!-- End 9 wide left column-->

            <div class="sixteen wide tablet seven wide computer column">
                <div class="ui grid">
                    <div
                        class="sixteen wide column"
                        *ngIf="showManagementPlan"
                        style="
                            padding-left: 10px;
                            padding-bottom: 6px;
                            margin-bottom: -20px;
                            padding-right: 0;
                        ">
                        <!-- Management Plan Card -->
                        <sds-management-plans-card [expanded]="true"></sds-management-plans-card>
                    </div>

                    <div
                        class="sixteen wide column"
                        *ngIf="showAudits"
                        style="padding-left: 10px; padding-bottom: 6px; padding-right: 0">
                        <!-- Audit Log Item -->
                        <sds-audit-log-card></sds-audit-log-card>
                    </div>
                </div>
                <!-- End 7 wide right column -->
            </div>
        </div>
        <!-- end .dashboard grid -->
    </div>

    <sm-modal
        title="{{ riskModalInfo?.RiskDisplay }} Risk &nbsp;&nbsp; | &nbsp;&nbsp; {{
            riskModalInfo?.Title
        }} md"
        class="{{ riskModalInfo?.RiskDisplay }}Risk"
        #riskInfoModal>
        <modal-content>
            <div class="riskModalContentContainer" *ngIf="riskModalInfo">
                <div>
                    <p class="riskSectionTitle">Risk Score</p>
                    {{ riskModalInfo?.RiskScore }}
                </div>

                <div style="flex: 1; text-align: left">
                    <p class="riskSectionTitle">Recommendations</p>
                    {{ riskModalInfo?.Recommendation }}
                    <ul class="riskModalIssueData">
                        <li
                            *ngFor="let issue of riskModalInfo?.IssueData"
                            [class.mitigated]="issue.IsMitigated">
                            {{ issue.InfoString }}
                        </li>
                    </ul>
                </div>

                <div>
                    <p class="riskSectionTitle">Severity</p>
                    <img
                        src="/assets/img/risk_{{ riskModalInfo?.SeverityDisplay }}.svg"
                        style="width: 50px"
                        alt="{{ riskModalInfo?.Severity }}"
                        title="{{ riskModalInfo?.Severity }}" />
                </div>

                <div>
                    <p class="riskSectionTitle">Probability</p>
                    <img
                        src="/assets/img/risk_{{ riskModalInfo?.ProbabilityDisplay }}.svg"
                        style="width: 50px"
                        alt="{{ riskModalInfo?.Probability }}"
                        title="{{ riskModalInfo?.Probability }}" />
                </div>
            </div>
        </modal-content>
        <modal-actions>
            <div class="ui cancel button darker">Close</div>
        </modal-actions>
    </sm-modal>
</sds-loader>
