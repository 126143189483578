import { Component, Input, OnInit } from '@angular/core';

@Component({
    templateUrl: './NewInternalVuln.component.html',
    styles: [],
})
export class NewInternalVulnComponent implements OnInit {
    constructor() {}

    @Input()
    set diagData(value) {
        if (!value) return;

        this.diag = value;
    }

    diag: any = {};

    ngOnInit() {}

    cleanNewlines(text: string): string {
        if (text === void 0) return text;
        return text.replace(/([^:\.\n])\n\s*([^\s-])/g, '$1 $2'); // remove newlines not preceded by ':', '.', or '\n' or followed by '-' (lists)
    }

    isValidPort(port: string) {
        if (!port) return false;
        return Number.parseInt(port) > 0;
    }
}
