import { inject } from '@angular/core';
import {
    Router,
    RouterStateSnapshot,
    ActivatedRouteSnapshot,
    UrlTree,
    CanActivateFn,
    CanMatchFn,
    CanMatch,
} from '@angular/router';

import { SiteService } from 'app/sites/shared/site.service';
import { OrganizationsService } from 'app/organizations/organizations.service';
import { Site } from 'app/sites/shared/site.model';
import { Organization } from 'app/organizations/organization.model';

export const ProductGuard: CanActivateFn | CanMatchFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
): Promise<boolean | UrlTree> | boolean | UrlTree => {
    const router = inject(Router);
    const siteService = inject(SiteService);
    const organizationsService = inject(OrganizationsService);

    console.log('Product: ', route.data.product, 'Guard');
    let site: Site;
    let routeSiteName = '';
    let routeOrganizationName = '';
    let splitURL = state.url.split('/');
    splitURL.shift();

    if (splitURL[0] === 'site') {
        routeSiteName = decodeURIComponent(splitURL[1]);
        // It's a site page, so get the site
        site = siteService.getCurrentSite();
        // I had to add this check because we want to make sure the current site we get back is the correct one we're going for
        if (site?.Name == routeSiteName) {
            console.log('Product Guard: Got current site', site);
            return productGuardLogic(router, siteService, route.data.product, site, null);
        } else {
            // no currentsite set, get it from name
            console.log(
                "Product guard: Couldn't find current site, or doesn't match route. Try to get site by name."
            );
            if (routeSiteName != '') {
                return siteService
                    .getSiteByNameLimitedData(routeSiteName)
                    .then((site: Site) => {
                        console.log('Product Guard: Got site by name, saving currentSite', site);
                        siteService.setCurrentSite(site);
                        return productGuardLogic(
                            router,
                            siteService,
                            route.data.product,
                            site,
                            null
                        );
                    })
                    .catch((e) => {
                        console.log('Product Guard: failed to get site');
                        return router.createUrlTree(['/organizations']);
                    });
            } else {
                console.log('no routeSitename');
                return router.createUrlTree(['/organizations']);
            }
        }
    } else if (splitURL[0] === 'organizations') {
        // It's an organization page, so get the organization
        routeOrganizationName = decodeURIComponent(splitURL[1]);
        let selectedOrg: Organization = organizationsService.getSelectedOrganization();
        if (selectedOrg && Object.entries(selectedOrg).length) {
            console.log('Product Guard: Got selected Org', selectedOrg);
            return productGuardLogic(router, siteService, route.data.product, null, selectedOrg);
        } else {
            console.log(
                "Product guard: Couldn't find organization. Try to get organization from all."
            );
            return organizationsService
                .getOrganizations()
                .then((o) => {
                    let selectedOrg = o.find((o) => o.Name === routeOrganizationName);
                    if (selectedOrg) {
                        console.log(
                            'Product Guard: Got selected organization from all',
                            selectedOrg
                        );
                        return productGuardLogic(
                            router,
                            siteService,
                            route.data.product,
                            null,
                            selectedOrg
                        );
                    } else {
                        console.log(
                            `Product Guard: Selected organization (${routeOrganizationName}) not available in list of organizations`
                        );
                        return router.createUrlTree(['/organizations']);
                    }
                })
                .catch(() => {
                    console.log('Product Guard: failed to get organization');
                    return router.createUrlTree(['/organizations']);
                });
        }
    }
};

// canActivateChild(
//     route: ActivatedRouteSnapshot,
//     state: RouterStateSnapshot
// ): Promise<boolean | UrlTree> | boolean | UrlTree {
//     return this.canActivate(route, state);
// }

// I have to pass in the router and siteservice or there's an issue with the inject not being during construction
// but these guards have no constructor to do it, but I don't want to have to keep copying the logic
function productGuardLogic(
    router: Router,
    siteService: SiteService,
    product: string | string[],
    site: Site,
    org: Organization
): boolean | UrlTree {
    let hasAccess = false;
    let errorCode = '';
    if (!Array.isArray(product)) {
        product = [product];
    }
    if (site) {
        product.forEach((p) => {
            switch (p) {
                case 'Indoc':
                case 'InDoc':
                    hasAccess = siteService.isIndoc(site);
                    errorCode = 'RFTE110';
                    break;
                case 'Network Detective Pro':
                    hasAccess = siteService.isNDPro(site);
                    errorCode = 'RFTE1119';
                    break;
                case 'Cyber Hawk':
                    hasAccess = siteService.isCyberHawk(site);
                    errorCode = 'RFTE111';
                    break;
                case 'Compliance Manager':
                    hasAccess = siteService.isComplianceManager(site);
                    errorCode = 'RFTE112';
                    break;
                case 'Compliance Manager GRC':
                    hasAccess = siteService.isComplianceManagerGRC(site);
                    errorCode = 'RFTE114'; // being superstitious and not using 13
                    break;
                case 'Compliance Manager GRC CSPro':
                    hasAccess = siteService.isCSPro(site);
                    errorCode = 'RFTE116';
                    break;
                case 'Vulnerability Scanner':
                    hasAccess = siteService.isKVS(site);
                    errorCode = 'RFTE115';
                    break;
            }
        });
    } else if (org) {
        for (let p of product) {
            switch (p) {
                case 'Compliance Manager GRC':
                    hasAccess = org.Sites.some((s) => siteService.isComplianceManagerGRC(s));
                    errorCode = 'RFTE114'; // being superstitious and not using 13
                    break;
                case 'Compliance Manager GRC CSPro':
                    hasAccess = org.Sites.some((s) => siteService.isCSPro(s));
                    errorCode = 'RFTE117';
                    break;
                case 'Vulnerability Scanner':
                    hasAccess = org.Sites.some((s) => siteService.isKVS(s));
                    errorCode = 'RFTE115';
                    break;
                case 'Network Detective Pro':
                    hasAccess = org.Sites.some((s) => siteService.isNDPro(s));
                    errorCode = 'RFTE118';
                    break;
                case 'Indoc':
                    hasAccess = org.Sites.some((s) => siteService.isIndoc(s));
                    errorCode = 'RFTE110';
                    break;
                case 'Cyber Hawk':
                    hasAccess = org.Sites.some((s) => siteService.isCyberHawk(s));
                    errorCode = 'RFTE111';
                    break;
                case 'Compliance Manager':
                    hasAccess = org.Sites.some((s) => siteService.isComplianceManager(s));
                    errorCode = 'RFTE112';
                    break;
                default:
                    console.log('could not find product to search for:', p);
            }
            if (hasAccess) {
                return true;
            }
        }
        if (!hasAccess && product.length > 1) {
            errorCode = 'RFTE118';
        }
    }
    console.log('Product Guard: Result: ', hasAccess);
    if (!hasAccess) {
        console.log('Product Guard: Redirecting User to Error Page');
        return router.createUrlTree(['/error', '403', errorCode]);
    } else {
        return hasAccess;
    }
}
