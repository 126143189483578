import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MessageService } from 'app/core/message.service';
import { Notes } from '../../../shared/notes/notes.model';
import { NotesService } from '../../../shared/notes/notes.service';
import { Site } from 'app/sites/shared/site.model';
import { ScanDataComputer, ScanDataService } from 'app/scans';
import { TagsService } from 'app/tags';
import { RelatedItemsService } from 'app/sites/shared/site-related-items.service';
import { UiService } from 'app/core/ui.service';
import { PING_SITE, SITE } from 'app/sites/shared/constants';
import { SiteService } from 'app/sites/shared/site.service';
import { NdpService } from '../../../shared/ndp.service';
import { ITGSidebarDevice } from 'app/sites/shared/itglue-sidebar/itglue-sidebar.model';

@Component({
    selector: 'sds-site-data-computer-detail',
    templateUrl: './site-data-computer-detail.component.html',
    styleUrls: ['./site-data-computer-detail.component.css'],
})
export class SiteDataComputerDetailComponent implements OnInit, OnDestroy {
    constructor(
        private messageService: MessageService,
        private notesService: NotesService,
        private scanDataService: ScanDataService,
        private relatedItemsService: RelatedItemsService,
        private tagsService: TagsService,
        private route: ActivatedRoute,
        private uiService: UiService,
        private siteService: SiteService,
        private ndpService: NdpService,
        private router: Router
    ) {}

    site: Site;
    notes: Notes = new Notes({
        Id: '',
        SiteId: -1,
        ItemId: '{"Computer":{"Domain":"","ComputerName":""}}',
        Body: '',
        PrivateBody: '',
        Title: '',
        Category: 'Notes',
    });
    SOPs: Notes[] = [];
    tags: any[] = [];
    computerId: any;
    selectedTab: string = 'system';
    itemId: string;

    itemJSON: string;

    computer: ScanDataComputer;
    //loginType = 'InteractiveLogon';
    loginType = 'All';
    users: any[] = [];

    loadingComplete: boolean;
    sub: any;

    breadcrumbs = [
        { text: 'Network Detective' },
        { text: 'Data Explorer' },
        { path: '../..', text: 'Computers', link: true },
        { text: 'Detail' },
    ];

    relatedItems: any[] = [];

    riskScore: number = 0;

    assessmentId: number = -1;
    dataExplorerType = 'Indoc';
    deviceData: ITGSidebarDevice;

    ngOnInit() {
        this.dataExplorerType = this.ndpService.getDataExplorerType();
        this.route.params.subscribe((params: Params) => {
            if (params['id'] == this.computerId) return;

            this.computerId = params['id'];

            if (this.site) this.getComputerDetails();
        });

        this.sub = this.messageService.on(SITE).subscribe((site) => this.onSite(site));
        this.messageService.broadcast(PING_SITE);

        this.ndpService.createDataExplorerBreadcrumbs('Computers', 'computers');
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    onSite(site: Site) {
        if (site && (!this.site || site.Id != this.site.Id)) {
            this.site = site;
            this.uiService.setTitle('Detail', site.Name);
            if (this.computerId) this.getComputerDetails();
        }
    }

    async getComputerDetails() {
        this.loadingComplete = false;
        this.assessmentId = await this.ndpService.getAssessmentIdForDataExplorer(
            this.site,
            this.dataExplorerType
        );
        this.scanDataService
            .getComputerById(this.site.Id, this.computerId, this.assessmentId)
            .then((computer) => {
                this.processResult(computer);
            })
            .catch((err) => {
                this.loadingComplete = true;
                console.error(err);
            });
    }

    processResult(computer: ScanDataComputer) {
        let a = 1;
        for (var i = 0, len = computer.Name.length; i < len; i++) {
            let c = computer.Name.charCodeAt(i),
                h = 0;
            if (i > 0) h = computer.Name.charCodeAt(i - 1);
            a = (a + c * h) * 5;
        }
        console.log(a);
        this.riskScore = a % 100;

        this.processDetail(computer);

        this.loadingComplete = true;
    }

    processDetail(computer: ScanDataComputer) {
        this.computer = computer;
        console.log(this.computer);
        this.deviceData = {
            title: `${computer.Domain}${computer ? '\\' : ''}${computer.Name}`,
            siteId: this.site.Id,
            objectId: this.computerId,
            objectType: 'computers',
            serialNo: computer?.Details.System.Profile.ServiceTag,
            hostName:
                (computer?.Domain ?? '') + (computer?.Domain ? '\\' : '') + (computer?.Name ?? ''),
        };

        let initSite = this.site;
        this.itemJSON = window.atob(this.computerId);
        this.itemId = JSON.stringify(JSON.parse(this.itemJSON)['Computer'])
            .replace(/^{/, '')
            .replace(/}$/, '');
        this.setUserTotals(this.loginType);

        if (this.site && initSite.Id == this.site.Id && this.computer.Notes != null) {
            this.notes = this.computer.Notes;
        } else {
            this.notes = new Notes({
                Id: '',
                SiteId: this.site.Id,
                ItemId: this.itemJSON,
                Body: '',
                PrivateBody: '',
                Title: '',
                Category: 'Notes',
            });
        }

        if (this.site && initSite.Id == this.site.Id && this.computer.Procedures != null) {
            this.SOPs = this.computer.Procedures;
        } else {
            this.SOPs = [];
        }
    }

    setUserTotals(ev: string) {
        if (this.computer && this.computer.Details.Users) {
            if (ev == 'InteractiveLogon') this.users = this.computer.Details.Users.InteractiveLogon;
            else if (ev == 'NetworkLogon') this.users = this.computer.Details.Users.NetworkLogon;
            else if (ev == 'RemoteLogon') this.users = this.computer.Details.Users.RemoteLogon;
            else
                this.users = this.combineUserTotals(
                    this.computer.Details.Users.InteractiveLogon.concat(
                        this.computer.Details.Users.NetworkLogon
                    ).concat(this.computer.Details.Users.RemoteLogon)
                );

            this.users = this.sortUsers(this.users);
        }
    }

    sortUsers(users: any[]): any[] {
        return users.sort((a, b) => {
            let cola = a['UserName'],
                colb = b['UserName'];

            if (typeof cola === 'string') cola = cola.toLocaleLowerCase();
            if (typeof colb === 'string') colb = colb.toLocaleLowerCase();

            return cola > colb ? 1 : cola === colb ? 0 : -1;
        });
    }

    combineUserTotals(users: any) {
        let result: any,
            results: any[] = [];

        users.map((user) => {
            result = results.find((u) => u.UserName == user.UserName);

            if (!result)
                results.push({
                    UserName: user.UserName,
                    Success30: Number(user.Success30),
                    Failed30: Number(user.Failed30),
                    Success7: Number(user.Success7),
                    Failed7: Number(user.Failed7),
                    Success24: Number(user.Success24),
                    Failed24: Number(user.Failed24),
                });
            else {
                result.Success30 += Number(user.Success30);
                result.Failed30 += Number(user.Failed30);
                result.Success7 += Number(user.Success7);
                result.Failed7 += Number(user.Failed7);
                result.Success24 += Number(user.Success24);
                result.Failed24 += Number(user.Failed24);
            }
        });

        return results;
    }

    saveNotes(notes: Notes) {
        let pop = this.scanDataService.getPatchOp('replace', '/Notes', JSON.stringify(notes));
        this.scanDataService
            .patchItem(this.site.Id, 'computer', this.computerId, [pop], this.assessmentId)
            .then((updatedObject: ScanDataComputer) => {
                updatedObject.Notes.PrivateBody = this.notes.PrivateBody; // so it is not replaced with the encrypted value
                this.processDetail(updatedObject);
            })
            .catch((err) => {
                console.error(err);
            });
    }

    saveProcedures(procedures: Notes[]) {
        let pop = this.scanDataService.getPatchOp(
            'replace',
            '/Procedures',
            JSON.stringify(procedures)
        );
        this.scanDataService
            .patchItem(this.site.Id, 'computer', this.computerId, [pop], this.assessmentId)
            .then((updatedObject: ScanDataComputer) => {
                this.processDetail(updatedObject);
            })
            .catch((err) => {
                console.error(err);
            });
    }
}
