<sds-breadcrumb-trail [crumbs]="breadcrumbs"></sds-breadcrumb-trail>

<header class="pageHeader">
    <h1>Scan Results</h1>
    <help-button
        title="Scan Results"
        text="Once you have completed a vulnerability scan, you can view detailed results from this dashboard."
        url="/vs/help/vulscan/view-vs-scan-results.htm"></help-button>
</header>
<div *ngIf="selectedDevice">
    {{ selectedDevice }} [
    <a (click)="deselectDevice()" style="cursor: pointer">Return to all devices</a>
    ]
    <br />
    <br />
    <table>
        <tr>
            <td>
                <kvs-vulnerabilities-donut-chart
                    [data]="vulnerabiltyStatistics"></kvs-vulnerabilities-donut-chart>
            </td>
            <td>
                <wj-flex-chart
                    #chart
                    class="detailChart"
                    [chartType]="'Bar'"
                    [bindingX]="'port'"
                    [stacking]="'Stacked'"
                    [itemsSource]="severityByPort"
                    [palette]="chartColors"
                    [options]="{ groupWidth: 20 }">
                    <wj-flex-chart-axis
                        wjProperty="axisX"
                        [itemFormatter]="axisFormatter"
                        [axisLine]="true"></wj-flex-chart-axis>
                    <wj-flex-chart-legend position="None"></wj-flex-chart-legend>
                    <wj-flex-chart-series [name]="'None'" [binding]="'none'"></wj-flex-chart-series>
                    <wj-flex-chart-series [name]="'Low'" [binding]="'low'"></wj-flex-chart-series>
                    <wj-flex-chart-series [name]="'Med'" [binding]="'med'"></wj-flex-chart-series>
                    <wj-flex-chart-series [name]="'High'" [binding]="'high'"></wj-flex-chart-series>
                    <wj-flex-chart-series
                        [name]="'Critical'"
                        [binding]="'critical'"></wj-flex-chart-series>
                </wj-flex-chart>
            </td>
        </tr>
    </table>
    <br />
</div>

<div class="customContext" #context_quickFilter>
    <div class="contextItem">
        <itc-button
            type="tertiary"
            size="small"
            icon="filter"
            (onclick)="doQuickFilter(contextFilterItem)">
            Quick Filter
        </itc-button>
    </div>
</div>

<div class="itc tabs container" *ngIf="!selectedDevice">
    <div class="tabs_header">
        <div class="tab" [routerLink]="['../', 'by-issue']" [queryParams]="{filter: 'true'}" [class.tab-active]="byIssue">
            By Issue
        </div>
        <div class="tab" [routerLink]="['../', 'by-device']" [queryParams]="{filter: 'true'}" [class.tab-active]="!byIssue">
            By Device
        </div>
    </div>
    <div class="tabs_content">
        <sds-loader [complete]="loadingComplete" [hideVisibility]="true">
            <div class="flex-cols" style="align-content: space-around">
                <div class="column">
                    <div class="searchOption" style="display: flex">
                        <div style="display: grid; align-content: center">
                            <label for="selectScanDateRange">Scan Date Range:</label>
                        </div>
                        <sm-select
                            class="form-control"
                            name="selectScanDateRange"
                            id="selectScanDateRange"
                            [(model)]="scanDateRange"
                            (modelChange)="onDateRangeChange()">
                            <option value="1">Last 24 Hours</option>
                            <option value="5">Last 5 Days</option>
                            <option value="7">Last 7 Days</option>
                            <option value="14">Last 14 Days</option>
                            <option value="30">Last 30 Days</option>
                            <option value="60">Last 60 Days</option>
                            <option value="90">Last 90 Days</option>
                            <option value="-1">All</option>
                        </sm-select>
                    </div>
                    <div class="searchOption" style="display: flex">
                        <div style="display: grid; align-content: center">
                            <label for="selectCvssRange">CVSS Filter:</label>
                        </div>
                        <sm-select
                            class="form-control"
                            name="selectCvssRange"
                            id="selectCvssRange"
                            style="display: inline-block"
                            [(model)]="cvssRange"
                            (modelChange)="onCvssChange()">
                            <option value="-1">All</option>
                            <option value="9">Critical (9.0+)</option>
                            <option value="7">High (7.0+)</option>
                            <option value="4">Medium (4.0+)</option>
                            <option value="1">Low (1.0+)</option>
                        </sm-select>
                    </div>
                    <div class="searchOption" style="display: flex">
                        <label for="selectScanType">Scan Type:</label>
                        <sm-select
                            class="form-control input-sm"
                            name="selectScanType"
                            id="selectScanType"
                            [(model)]="scanType"
                            (modelChange)="onScanTypeChange()">
                            <option selected value="ALL">All</option>
                            <option value="EVS">External</option>
                            <option value="IVS">Internal</option>
                            <option value="AGT">Discovery Agent</option>
                        </sm-select>
                    </div>
                    <div class="searchOption">
                        <div style="display: flex">
                            <div style="display: grid; align-content: center">
                                <label for="textboxQuickFilter">Quick Filter:</label>
                            </div>
                            <input
                                type="text"
                                id="textboxQuickFilter"
                                [(ngModel)]="quickFilter"
                                (ngModelChange)="onQuickFilterChange()"
                                debounce="420" />
                        </div>
                        <div style="margin-left: 120px">
                            <itc-checkbox
                                label="CISA’s Known Exploited Vulnerabilities"
                                [(ngModel)]="knownExploitedVulnerabilities"
                                (ngModelChange)="onKevFilterChecked()"></itc-checkbox>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <div *ngIf="!selectedDevice" style="display: flex; justify-content: center">
                        <kvs-vulnerabilities-donut-chart
                            [data]="vulnerabiltyStatistics"></kvs-vulnerabilities-donut-chart>
                    </div>
                </div>
                <div class="column right-buttons" style="width: 260px" *ngIf="!isReadOnly">
                    <itc-dropdown-button
                        [loading]="generatingReport"
                        classList="generateButton"
                        style="display: flex; flex: 0">
                        <button-content>
                            <itc-button type="primary" icon="fa-download">Generate Reports</itc-button>
                        </button-content>
                        <dropdown-content>
                            <ul>
                                <li>
                                    <itc-button
                                        type="ghost darkText"
                                        (onclick)="showReportConfirmation('word')">
                                        Vulnerability Scan Report (.docx)
                                    </itc-button>
                                </li>
                                <li>
                                    <itc-button
                                        type="ghost darkText"
                                        (onclick)="showReportConfirmation('excel')">
                                        Vulnerability Scan Excel Export (.xlsx)
                                    </itc-button>
                                </li>
                                <li>
                                    <itc-button
                                        type="ghost darkText"
                                        (onclick)="generateCsvReport()">
                                        Vulnerability Scan Export (.csv)
                                    </itc-button>
                                </li>
                            </ul>
                        </dropdown-content>
                    </itc-dropdown-button>
                </div>
            </div>
        </sds-loader>
    </div>
</div>

<div class="sixteen wide column grid" *ngIf="loadingComplete">
    <table
        sortable-table
        class="itc-table bulkTable"
        (sorted)="onSorted($event)"
        style="margin-top: 0">
        <thead>
            <tr>
                <th *ngIf="!isReadOnly" style="padding-left: 0">
                    <itc-bulk-actions
                        [menuOptions]="bulkActions"
                        [selectedCount]="selectedIssues.length"
                        [totalCount]="filteredScanResults.length"
                        (checkboxClicked)="checkAllIssues($event)"></itc-bulk-actions>
                </th>
                <th *ngIf="!byIssue && !selectedDevice" sortable-column="Ip">IP Address</th>
                <th *ngIf="!byIssue && !selectedDevice" sortable-column="Hostname">Hostname</th>
                <th *ngIf="!byIssue && !selectedDevice" sortable-column="MacAddress">
                    MAC Address
                </th>
                <th sortable-column="Cvss">Severity/CVSS</th>
                <th sortable-column="Issue">Issue</th>
                <th *ngIf="byIssue" sortable-column="AffectedNodesLength">Affected Devices</th>
                <th sortable-column="LastDetected">Last Detected</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let scanResult of filteredScanResults; let i = index">
                <td *ngIf="!isReadOnly" (click)="checkIssue(scanResult)">
                    <itc-checkbox [checked]="scanResult.Checked"></itc-checkbox>
                </td>

                <td *ngIf="!byIssue && !selectedDevice">
                    <a
                        (contextmenu)="contextMenu($event, scanResult.Ip)"
                        (click)="
                            showHostModal(scanResult.Ip, scanResult.Hostname, scanResult.MacAddress)
                        "
                        style="cursor: pointer">
                        {{ scanResult.Ip }}
                    </a>
                </td>
                <td *ngIf="!byIssue && !selectedDevice">
                    <a
                        (contextmenu)="contextMenu($event, scanResult.Hostname)"
                        (click)="
                            showHostModal(scanResult.Ip, scanResult.Hostname, scanResult.MacAddress)
                        "
                        style="cursor: pointer">
                        {{ scanResult.Hostname }}
                    </a>
                </td>
                <td *ngIf="!byIssue && !selectedDevice">
                    <a
                        (contextmenu)="contextMenu($event, scanResult.MacAddress)"
                        (click)="
                            showHostModal(scanResult.Ip, scanResult.Hostname, scanResult.MacAddress)
                        "
                        style="cursor: pointer">
                        {{ scanResult.MacAddress }}
                    </a>
                </td>
                <td>{{ scanResult.Cvss }}</td>
                <td *ngIf="byIssue">
                    <a (click)="showOidModal(scanResult.Oid)">{{ scanResult.Issue }}</a>
                    <br />
                    OID: {{ scanResult.Oid }}
                    <br *ngIf="scanResult.Cves" />
                    {{ scanResult.Cves ? 'CVE: ' + scanResult.Cves : '' }}
                    <br />
                    <itc-tag
                        class="exclude-border"
                        *ngIf="scanResult.KnownExploitedVulnerability.IsKnownExploitedVulnerability"
                        type="solid"
                        status="critical">
                        Known Exploited Vulnerability
                    </itc-tag>
                </td>
                <td *ngIf="!byIssue">
                    <a
                        (click)="
                            showOidModalByIp(scanResult.Oid, scanResult.Ip, scanResult.Hostname)
                        ">
                        {{ scanResult.Issue }}
                    </a>
                    <br />
                    OID: {{ scanResult.Oid }}
                    <br *ngIf="scanResult.Cves" />
                    {{ scanResult.Cves ? 'CVE: ' + scanResult.Cves : '' }}
                    <br />
                    <itc-tag
                        class="exclude-border"
                        *ngIf="scanResult.KnownExploitedVulnerability.IsKnownExploitedVulnerability"
                        type="solid"
                        status="critical">
                        Known Exploited Vulnerability
                    </itc-tag>
                </td>
                <td *ngIf="byIssue">
                    {{ scanResult.AffectedNodesLength }}
                </td>
                <td>{{ scanResult.LastDetected | accountDateTime }}</td>
            </tr>
        </tbody>
    </table>
</div>

<sm-modal class="sm" title="Generate Report Confirmation" #generateReportModal>
    <modal-content>
        <div>
            If this is your first VulScan Report Generation, please review the report settings and
            save the
            <b>Text</b>
            ,
            <b>My Logo</b>
            ,
            <b>Theme</b>
            , and
            <b>Cover Image</b>
            settings.
        </div>
    </modal-content>
    <modal-actions>
        <itc-button type="secondary" (onclick)="generateReportModal.hide()">Cancel</itc-button>
        <itc-button type="tertiary" (onclick)="redirectToReportPref()" [loading]="generatingReport">
            Set Report Settings
        </itc-button>
        <itc-button
            type="primary"
            icon="fa-download"
            (onclick)="generateVulScanReport()"
            [loading]="generatingReport">
            Generate Reports
        </itc-button>
    </modal-actions>
</sm-modal>

<kvs-issues-modal
    #issuesModal
    [site]="site"
    [connection]="conn"
    [loadingTicketInfoComplete]="loadingTicketInfoComplete"
    [(ticketMap)]="psaTicketMap"
    [byIssue]="byIssue"
    (ShowAddFalsePositiveModal)="showAddFalsePositiveModal($event)"></kvs-issues-modal>

<kvs-issues-add-false-positive-modal
    [site]="site"
    [byIssue]="byIssue"
    #addFalsePositiveModal
    (RefreshResults)="refreshResults()"></kvs-issues-add-false-positive-modal>
