export const CONNECTION_TYPES = {
    ATWS: 'Autotask SOAP',
    CW: 'ConnectWise',
    TP: 'TigerPaw',
    KBMS: 'Kaseya BMS',
    CWREST: 'ConnectWise REST',
    ITG: 'IT Glue',
    ATREST: 'Autotask',
    DWID: 'Dark Web ID',
    DUC: 'Datto Unified Continuity',
};
