<div class="videoPlayerContainer" *ngIf="video">
    <itc-button
        type="ghost"
        icon="fa-chevron-left"
        class="backButton"
        (onclick)="playerClosed.emit(true)">
        Back to Tutorials
    </itc-button>

    <div class="videoEmbed ui placeholder">
        <iframe
            [src]="videoSource"
            width="885"
            height="498"
            frameborder="0"
            allow="fullscreen"
            allowfullscreen></iframe>
    </div>
    <div class="videoInfo">
        <div>
            <h5 class="title">{{ video.title }}</h5>
            <itc-button type="secondary" (onclick)="openInVimeo(video.id)">
                Open in Vimeo
            </itc-button>
        </div>
        <p class="caption">{{ video.caption }}</p>
    </div>
</div>
