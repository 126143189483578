import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { ColorPickerModule } from 'ngx-color-picker';

import { SharedModule } from 'app/shared/shared.module';
import { ScansModule } from 'app/scans';

import { SitesComponent } from './sites.component';
import { SiteListComponent } from './site-list/site-list.component';
import { SiteComponent } from './site/site.component';
import { SiteSettingsComponent } from './site/settings/site-settings.component';

import { RiskSnapshotComponent } from './site/risk-snapshot/risk-snapshot.component';
import { ReportPreferencesOldComponent } from './site/settings/reports/report-preferences-old.component';
import { ReportStyleDialogComponent } from './site/settings/reports/style-dialog.component';
import { ScanStatusTooltipsComponent } from './site-dashboard/ScanStatus-Tooltips';
import { DragScrollModule } from 'ngx-drag-scroll';

import { NgxTimeoutComponent } from '../core/ngx-timeout/ngx-timeout.component';
import { InviteManagementComponent } from './site/settings/invites/invite-management.component';
import { SiteItCompleteComponent } from './site/settings/itcomplete/itcomplete.component';
import { ClientViewSettingsComponent } from './site/settings/client-view/client-view-settings.component';
import { ClientReportsComponent } from './site/client/reports/client-reports.component';
import { SiteDetailsComponent } from './site-details/site-details.component';
import { SiteCardComponent } from './site/site-card/site-card.component';
import { SiteGridComponent } from './site-grid/site-grid.component';
import { OrganizationsModule } from 'app/organizations/organizations.module';

import { SitesSharedModule } from './shared/sites-shared.module';

import { ClientViewWrapRoutingComponent } from './site/settings/client-view/client-view-wrap-routing/client-view-wrap-routing.component';

import { AllSiteComponent } from './site-dashboard/all-site/all-site.component';
import { ComplianceManagerGrcComponent } from './site-dashboard/compliance-manager-grc/compliance-manager-grc.component';
import { ComplianceManagerLegacyComponent } from './site-dashboard/compliance-manager-legacy/compliance-manager-legacy.component';
import { NetworkDetectiveProComponent } from './site-dashboard/network-detective-pro/network-detective-pro.component';
import { CyberHawkComponent } from './site-dashboard/cyber-hawk/cyber-hawk.component';
import { IndocComponent } from './site-dashboard/indoc/indoc.component';
import { VulscanComponent } from './site-dashboard/vulscan/vulscan.component';
import { AddEditUserFormComponent } from 'app/core/add-edit-user-form/add-edit-user-form.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        ColorPickerModule,
        ScansModule,
        SharedModule,
        DragScrollModule,
        OrganizationsModule,
        SitesSharedModule,
        AddEditUserFormComponent,
    ],
    declarations: [
        SitesComponent,
        SiteListComponent,
        SiteComponent,
        SiteSettingsComponent,
        RiskSnapshotComponent,
        ReportPreferencesOldComponent,
        ReportStyleDialogComponent,
        NgxTimeoutComponent,
        InviteManagementComponent,
        SiteItCompleteComponent,
        ClientViewSettingsComponent,
        ClientReportsComponent,
        SiteDetailsComponent,
        SiteCardComponent,
        SiteGridComponent,
        ClientViewWrapRoutingComponent,
        AllSiteComponent,
        ComplianceManagerGrcComponent,
        ComplianceManagerLegacyComponent,
        ScanStatusTooltipsComponent,
        NetworkDetectiveProComponent,
        CyberHawkComponent,
        IndocComponent,
        VulscanComponent,
    ],
    providers: [DatePipe],
})
export class SitesModule {}
